import { Injectable, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { cloneDeep } from 'lodash';
import { DynamicComponentService } from '../dynamic-component-service/dynamic-component.service';

@Injectable({
  providedIn: 'root'
})
export class RenderComponentService {

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private dynamicComponentService: DynamicComponentService
  ) { }

  /**
   * Add the provided component to the provided container
   *
   * @param {*} component
   * component which is being to be created
   * @param {ViewContainerRef} componentContainerRef
   * @param {object} [inputs]
   * @memberof RenderComponentService
   */
  renderComponentAndAddToContainer(componentClassName: string, viewContainerRef: ViewContainerRef, componentInputs?: object) {
    const clonedComponentInputs = cloneDeep(componentInputs);
    if (viewContainerRef && componentClassName) {
      /* get component */
      const component = this.dynamicComponentService.getComponent(componentClassName);
      if (component) {
        /* render component */
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        if (componentFactory) {
          /* render component inside the container */
          const componentRef = viewContainerRef.createComponent(componentFactory);
          /* set component sample inputs to viewed component */
          if (clonedComponentInputs) {
            Object.entries(clonedComponentInputs).forEach(([key, value]) => {
              if (value) {
                componentRef.instance[key] = value;
              }
            });
          }
        }
      }
    }
  }
}
