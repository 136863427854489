import { Validators } from '@angular/forms';
import {
  ActionBehavior,
  CrudFormConfig,
  CrudFormLabelItem,
  CrudFormSelectItem,
  CrudTableViewConfig,
  CrudTableViewIconColumn,
  CrudViewColumnType,
  CrudViewFormItemType,
  FormLayout,
  HeadingType,
  InputSize
} from '@rappider/rappider-components/utils';
import { ProjectExportStatus } from '../../definitions/project-export/project-export-status.enum';

export const PROJECT_EXPORT_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormLabelItem>{
      title: 'SHARED.VERSION',
      fieldName: 'versionNumber',
      type: CrudViewFormItemType.Label,
      size: InputSize.Large,
      placeholder: 'SHARED.VERSION',
      hideWhenNoValue: true
    },
    <CrudFormLabelItem>{
      title: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.APP_TYPE',
      fieldName: 'appType',
      type: CrudViewFormItemType.Label,
      size: InputSize.Large,
      placeholder: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.APP_TYPE',
      hideWhenNoValue: true
    },
    <CrudFormLabelItem>{
      title: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.FRONTEND_FRAMEWORK_TITLE',
      fieldName: 'frontendFramework',
      type: CrudViewFormItemType.Label,
      size: InputSize.Large,
      placeholder: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.FRONTEND_FRAMEWORK_TITLE',
      hideWhenNoValue: true
    },
    <CrudFormLabelItem>{
      title: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.BACKEND_FRAMEWORK_TITLE',
      fieldName: 'backendFramework',
      type: CrudViewFormItemType.Label,
      size: InputSize.Large,
      placeholder: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.BACKEND_FRAMEWORK_TITLE',
      hideWhenNoValue: true
    }
  ],
  validators: [
  ],
  itemSettings: {
    inputComponentSize: {
      xs: 24
    },
    labelComponentSize: {
      xs: 24
    }
  },
  submitButton: {
    visible: true,
    text: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.EXPORT_PROJECT'
  },
  formLayout: FormLayout.Vertical
};

export const PROJECT_EXPORT_LIST_CONFIG: CrudTableViewConfig = {
  title: {
    content: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.PROJECT_EXPORT_HISTORY',
    type: HeadingType.H5
  },
  searchable: false,
  columnVisibilitySelectable: false,
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.APP_TYPE',
      fieldName: 'appType',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.FRONTEND_FRAMEWORK',
      fieldName: 'frontendFramework',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.BACKEND_FRAMEWORK',
      fieldName: 'backendFramework',
      type: CrudViewColumnType.Text
    },
    {
      title: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.VERSION_NUMBER',
      fieldName: 'versionNumber',
      type: CrudViewColumnType.Text
    },
    {
      title: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.EXPORTED_DATE',
      fieldName: 'createdDate',
      type: CrudViewColumnType.DateTime
    },
    {
      title: 'SHARED.CREATED_BY',
      fieldName: 'createdBy',
      type: CrudViewColumnType.Text
    }
  ]
};

export const LAST_PROJECT_EXPORT_LIST_CONFIG: CrudTableViewConfig = {
  title: {
    content: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.LAST_EXPORTED_VERSION',
    type: HeadingType.H5
  },
  searchable: false,
  columnVisibilitySelectable: false,
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.APP_TYPE',
      fieldName: 'appType',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.FRONTEND_FRAMEWORK',
      fieldName: 'frontendFramework',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.BACKEND_FRAMEWORK',
      fieldName: 'backendFramework',
      type: CrudViewColumnType.Text
    },
    {
      title: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.VERSION_NUMBER',
      fieldName: 'versionNumber',
      type: CrudViewColumnType.Text
    },
    {
      title: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.EXPORTED_DATE',
      fieldName: 'createdDate',
      type: CrudViewColumnType.DateTime
    },
    {
      title: 'SHARED.CREATED_BY',
      fieldName: 'createdBy',
      type: CrudViewColumnType.Text
    },
    <CrudTableViewIconColumn>{
      title: 'Status',
      fieldName: 'statusCheck',
      type: CrudViewColumnType.Icon
    }
  ],
};
