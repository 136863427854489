import { createReducer, on } from '@ngrx/store';
import { ContainerTemplateWithRelations } from '@rappider/rappider-sdk';

/* actions */
import * as ContainerTemplateActions from './container-template.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

/* state key */
export const featureKey = 'containerTemplate';

/* state interface */
export interface State {
  data: ContainerTemplateWithRelations[] | null;
  loading: boolean;
  copyContainerTemplateLoading: boolean;
  error: {
    error: any;
    key: string;
    timestamp: number;
  };
}

/* initial values */
export const initialState: State = {
  data: null,
  loading: false,
  copyContainerTemplateLoading: false,
  error: null
};

export const reducer = createReducer(
  initialState,

  on(ContainerTemplateActions.GetContainerTemplates, (state, action) => ({
    ...state,
    loading: true
  })),

  on(ContainerTemplateActions.GetContainerTemplatesSuccessful, (state, action) => ({
    ...state,
    data: action.payload.containerTemplates,
    loading: false
  })),

  on(ContainerTemplateActions.CreateContainerTemplate, (state, action) => ({
    ...state,
    loading: true
  })),

  on(ContainerTemplateActions.CreateContainerTemplateSuccessful, (state, action) => ({
    ...state,
    data: action.payload.containerTemplates,
    loading: false
  })),

  on(ContainerTemplateActions.CopyTemplateToPageContainer, (state, action) => ({
    ...state,
    copyContainerTemplateLoading: true
  })),

  on(ContainerTemplateActions.CopyTemplateToPageContainerSuccessful, (state, action) => ({
    ...state,
    copyContainerTemplateLoading: false
  })),

  on(ContainerTemplateActions.ErrorAction, (state, action) => ({
    ...state,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    }
  })),
  on(ContainerTemplateActions.ResetStateToInitial, () => ({ ...initialState })),

  on(ContainerTemplateActions.ResetStateToInitialChangeProject, () => initialState)
);
