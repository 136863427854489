/* eslint-disable */
import { ComponentDefinition } from '../index';

declare var Object: any;
export interface ComponentSampleInterface {
  id?: string;
  title?: string;
  description?: string;
  inputs?: any;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  componentDefinitionId?: string;
  componentDefinition?: ComponentDefinition;
}

export class ComponentSample implements ComponentSampleInterface {
  'id'?: string;
  'title': string;
  'description': string;
  'inputs': any;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  'componentDefinitionId': string;
  componentDefinition: ComponentDefinition;
  constructor(data?: ComponentSampleInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ComponentSample`.
   */
  public static getModelName() {
    return 'ComponentSample';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ComponentSample for dynamic purposes.
   **/
  public static factory(data: ComponentSampleInterface): ComponentSample {
    return new ComponentSample(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ComponentSample',
      plural: 'ComponentSamples',
      path: 'component-samples',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        title: {
          name: 'title',
          type: 'string',
        },
        description: {
          name: 'description',
          type: 'string',
        },
        inputs: {
          name: 'inputs',
          type: 'any',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
        componentDefinitionId: {
          name: 'componentDefinitionId',
          type: 'string',
        },
      },
      relations: {
        componentDefinition: {
          name: 'componentDefinition',
          type: 'ComponentDefinition',
          model: 'ComponentDefinition',
          relationType: 'belongsTo',
          keyFrom: 'componentDefinitionId',
          keyTo: 'id',
        },
      },
    };
  }
}
