import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppSearch, ChangeAppSearchVisibility } from '../../state/app.actions';
import { Subscription } from 'rxjs';
import { debounce } from 'lodash';
import { Router } from '@angular/router';

@Component({
  selector: 'rappider-app-search',
  templateUrl: './app-search.component.html',
  styleUrls: ['./app-search.component.scss']
})
export class AppSearchComponent implements OnInit, OnDestroy {

  selectedResult = null;
  searchText = '';
  searchResults = [];

  loading = false;
  subscriptions: Subscription[];

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.selectedResult && this.searchResults?.length) {
      this.onSearchResultClick(this.selectedResult.url);
      this.store.dispatch(new ChangeAppSearchVisibility());
    } else if (event.key === 'ArrowDown' && this.searchResults?.length) {
      let index = this.searchResults.findIndex(result => result.url === this.selectedResult.url);
      index++;
      this.selectedResult = this.searchResults?.[index] || this.searchResults[0];
      document.getElementById(this.selectedResult.url).scrollIntoView({ behavior: 'smooth' });
    } else if (event.key === 'ArrowUp' && this.searchResults?.length) {
      let index = this.searchResults.findIndex(result => result.url === this.selectedResult.url);
      index--;
      this.selectedResult = this.searchResults?.[index] || this.searchResults[this.searchResults.length - 1];
      document.getElementById(this.selectedResult.url).scrollIntoView({ behavior: 'smooth' });
    }
  }

  constructor(
    private store: Store<any>,
    private router: Router
  ) {
    this.onSearch = debounce(this.onSearch, 1000);
  }

  ngOnInit(): void {
    this.subscribeToData();
    const interval = setInterval(() => {
      const searchInput = document.getElementById('search-input');
      if (searchInput) {
        searchInput.focus();
        clearInterval(interval);
      }
    }, 100);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToSearchResults()
    ];
  }

  subscribeToSearchResults() {
    return this.store.select(state => state.app.appSearch.searchResults)
      .subscribe(searchResults => {
        this.searchResults = searchResults;
        if (this.searchResults?.length) {
          this.selectedResult = this.searchResults[0];
        }
      });
  }

  onSearchAreaClick(e: Event) {
    e.stopPropagation();
  }

  onOutsideOfSearchAreaClick() {
    this.store.dispatch(new ChangeAppSearchVisibility());
    this.searchText = '';
  }

  onSearchTextChange() {
    this.loading = true;
    this.onSearch();
  }

  onSearch() {
    if (this.searchText === '' || this.searchText == null) {
      this.searchResults = [];
    } else {
      this.store.dispatch(new AppSearch({ searchText: this.searchText }));
    }
    this.loading = false;
  }

  onSearchResultClick(url: string) {
    if (url) {
      this.router.navigate([url]);
    }
  }

}
