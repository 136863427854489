<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayBreadcrumb]="displayBreadcrumb"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton"></rappider-title-toolbar>

<section>
  <rappider-crud-view-edit-form [config]="EDIT_CODE_FORM_CONFIG"
                                [data]="uiDataUpdateFunction"
                                (formSubmit)="onEditCode($event)"></rappider-crud-view-edit-form>
</section>
