export enum ModuleDeleteOptions {
  DeleteModuleWithPages = 'delete-module-with-pages',
  TransferPagesToAnotherModule = 'transfer-pages-to-another-module'
}

export const moduleDeleteOptions = [
  {
    key: 'Delete All Related Pages',
    value: ModuleDeleteOptions.DeleteModuleWithPages,
  },
  {
    key: 'Transfer Pages To Another Module And Delete Only This Module',
    value: ModuleDeleteOptions.TransferPagesToAnotherModule,
  }
];

