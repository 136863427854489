/* eslint-disable */

declare var Object: any;
export interface PhoneNumberInterface {
  id?: string;
  countryCode: string;
  number: string;
  type?: string;
  isVerified: boolean;
  isDefault?: boolean;
  needsVerification: boolean;
  personId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
}

export class PhoneNumber implements PhoneNumberInterface {
  'id'?: string;
  'countryCode': string;
  'number': string;
  'type': string;
  'isVerified': boolean;
  'isDefault': boolean;
  'needsVerification': boolean;
  'personId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  constructor(data?: PhoneNumberInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PhoneNumber`.
   */
  public static getModelName() {
    return 'PhoneNumber';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of PhoneNumber for dynamic purposes.
   **/
  public static factory(data: PhoneNumberInterface): PhoneNumber {
    return new PhoneNumber(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'PhoneNumber',
      plural: 'PhoneNumbers',
      path: 'phone-numbers',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        countryCode: {
          name: 'countryCode',
          type: 'string',
        },
        number: {
          name: 'number',
          type: 'string',
        },
        type: {
          name: 'type',
          type: 'string',
        },
        isVerified: {
          name: 'isVerified',
          type: 'boolean',
          default: false,
        },
        isDefault: {
          name: 'isDefault',
          type: 'boolean',
          default: false,
        },
        needsVerification: {
          name: 'needsVerification',
          type: 'boolean',
          default: false,
        },
        personId: {
          name: 'personId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
      },
      relations: {},
    };
  }
}
