import { createSelector } from '@ngrx/store';
import { ProjectModelWithRelations } from '@rappider/rappider-sdk';

export const getProjectDefaultModuleSelector = createSelector(
  state => state['projectSetting']?.data || [],
  (projectSettings: ProjectModelWithRelations[]) => {
    const projectSettingDefaultModule = projectSettings.find(projectSetting => projectSetting.key === 'defaultModuleId');
    return projectSettingDefaultModule;
  }
);
