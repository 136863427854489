import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ProjectModelRelationWithRelations, ProjectModelWithRelations, ProjectWithRelations } from '@rappider/rappider-sdk';
import { ActionResponse } from '@rappider/rappider-components/utils';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { DeleteProjectModelRelation } from 'libs/project/src/lib/states/project-model-relation-state/project-model-relation.actions';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { Subscription } from 'rxjs';
import { PROJECT_MODEL_RELATION_LIST_CONFIG } from './config/project-model-relation-list-config';
import { getProjectModelsWithFields } from '../../utils/get-project-model-with-fields';

@Component({
  selector: 'rappider-project-model-relation-list',
  templateUrl: './project-model-relation-list.component.html',
  styleUrls: ['./project-model-relation-list.component.scss']
})
export class ProjectModelRelationListComponent implements OnInit, OnDestroy {
  /* list grid config */
  PROJECT_MODEL_RELATION_LIST_CONFIG = PROJECT_MODEL_RELATION_LIST_CONFIG;
  /* subscriptions */
  subscriptions: Subscription[];
  /* project model relations */
  projectModelRelations: any[];
  /* active project model id */
  activeProjectModelId: string;
  /* project models with relaitons */
  projectModels: ProjectModelWithRelations[];
  /* relation loading  */
  isLoading: boolean;

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.getProjectModelIdFromUrl();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToProjectModels(),
      this.subscribeToRelationLoading()
    ];
  }

  subscribeToProjectModels() {
    return this.store.select(<any>getProjectModelsWithFields).subscribe((data) => {
      this.projectModels = data?.projectModels;
      this.mapProjectModelRelations(data?.projectModelRelations);
    });
  }

  subscribeToRelationLoading() {
    return this.store.select(state => state.projectModelRelation.loading).subscribe(loading => {
      this.isLoading = loading;
    });
  }

  getProjectModelIdFromUrl() {
    this.activeProjectModelId = this.activatedRoute.snapshot.params['projectModelId'];
  }

  mapProjectModelRelations(projectModelRelations: ProjectModelRelationWithRelations[]) {
    this.projectModelRelations = projectModelRelations?.filter(relation => relation.sourceModelId === this.activeProjectModelId) // filter active project model's relations
      ?.map((projectModelRelation: ProjectModelRelationWithRelations) => {
        const { sourceModelId, targetModelId, throughModelId } = projectModelRelation; // get sourceModelId, targetModelId, throughModelId
        const findProjectModelName = (id) => this.projectModels?.find(projectModel => projectModel.id === id)?.name; // find project model name by id
        const findFieldName = (projectModel, fieldId) => projectModel?.fields?.find(field => field.id === fieldId)?.name; // find project model field name by id
        if (throughModelId) {
          const sourceModelName = findProjectModelName(sourceModelId);
          const targetModelName = findProjectModelName(targetModelId);
          const throughModelName = findProjectModelName(throughModelId);

          const throughModelSourceFieldName = `${findProjectModelName(throughModelId)}.${findFieldName(this.projectModels?.find(projectModel => projectModel.id === throughModelId), projectModelRelation.keyFromId)}`;
          const throughModelTargetFieldName = `${findProjectModelName(throughModelId)}.${findFieldName(this.projectModels?.find(projectModel => projectModel.id === throughModelId), projectModelRelation.keyToId)}`;

          return {
            ...projectModelRelation,
            sourceModel: sourceModelName,
            targetModel: targetModelName,
            throughModel: throughModelName,
            targetField: throughModelTargetFieldName,
            sourceField: throughModelSourceFieldName
          };
        } else {
          const sourceModelName = findProjectModelName(sourceModelId);
          const targetModelName = findProjectModelName(targetModelId);

          const targetFieldName = findFieldName(this.projectModels?.find(projectModel => projectModel.id === targetModelId), projectModelRelation.keyToId);
          const sourceFieldName = findFieldName(this.projectModels?.find(projectModel => projectModel.id === sourceModelId), projectModelRelation.keyFromId);

          return {
            ...projectModelRelation,
            sourceModel: sourceModelName,
            targetModel: targetModelName,
            targetField: targetFieldName,
            sourceField: sourceFieldName
          };
        }
      });
  }

  /**
   * navigates to create relation - fires when clicked add relation button
   *
   * @memberof ProjectModelRelationListComponent
   */
  navigateRelationCreatePage() {
    this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_RELATION_CREATE}/${this.activeProjectModelId}` }));
  }

  /**
   * deleted model relation by relation id - fires when clicked delete
   *
   * @param {ActionResponse} actionResponse
   * @memberof ProjectModelRelationListComponent
   */
  onDeleteModelRelation(actionResponse: ActionResponse) {
    const modelRelation = <ProjectModelRelationWithRelations>actionResponse.data;
    /* deletes relation by relation id */
    if (actionResponse.action.name === 'DELETE_ITEM') {
      this.store.dispatch(new DeleteProjectModelRelation({ projectModelRelationId: modelRelation.id }));
    }
  }
}
