import { Injectable } from '@angular/core';
import { toInteger } from 'lodash';
import {
  MARGIN_STYLE_NAME,
  MARGIN_STYLE_NAME_X,
  HEIGHT_STYLE_NAME,
  HEIGHT_STYLE_NAME_X,
  WIDTH_STYLE_NAME,
  WIDTH_STYLE_NAME_X,
  PADDING_STYLE_NAME,
  PADDING_STYLE_NAME_X,
  BORDER_RADIUS_NAME,
  BORDER_RADIUS_NAME_X,
  multipliedValue,
  getStyleVariableName
} from 'libs/shared/src/lib/definitions';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {


  /**
   * takes fieldName value and coefficient and returns multiplied values.(ex. takes --margin-x and returns
   * --margin-x,--margin-2x ...)
   *
   * @param {string} value
   * @param {string} fieldName
   * @param {number} coefficient
   * @returns
   * @memberof ThemeService
   */
  getMultipliedValue(value: string, styleVariableName: string, coefficient: number) {
    const coefficientValue = toInteger(value?.match(/\d+/g)?.[0]);
    const multipliedValueObject = {};
    for (let i = 2; i <= coefficient; i++) {
      const styleVariableNameKey = getStyleVariableName(styleVariableName, i);
      multipliedValueObject[styleVariableNameKey] = multipliedValue(i, coefficientValue);
    }
    return multipliedValueObject;
  }

  /**
   * get theme data
   *
   * @param {object} data
   * @return {*}
   * @memberof ThemeService
   */
  getThemeData(data: object) {
    if (data) {
      const themeData = {
        ...data,
        ...this.getMultipliedValue(data?.[MARGIN_STYLE_NAME_X], MARGIN_STYLE_NAME, 8),
        ...this.getMultipliedValue(data?.[HEIGHT_STYLE_NAME_X], HEIGHT_STYLE_NAME, 5),
        ...this.getMultipliedValue(data?.[WIDTH_STYLE_NAME_X], WIDTH_STYLE_NAME, 5),
        ...this.getMultipliedValue(data?.[PADDING_STYLE_NAME_X], PADDING_STYLE_NAME, 5),
        ...this.getMultipliedValue(data?.[BORDER_RADIUS_NAME_X], BORDER_RADIUS_NAME, 2),
      };
      return themeData;
    }
  }
}
