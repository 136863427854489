import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { PATH_DEFINITIONS, UIWorkflowItemTypeValues } from '@rappider/shared/definitions';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { Subscription } from 'rxjs';
import { UiDataStoreWithRelations, UiWorkflowStepFunction, UiWorkflowStepFunctionWithRelations } from '@rappider/rappider-sdk';
import { UI_STEP_FUNCTION_LIST_CONFIG } from '@rappider/shared/configs';
import { DeleteUIWorkflowStepFunction, GetPostDataTransformationData, GetPreDataTransformationData, TogglePostDataTransformationModalVisibility, TogglePreDataTransformationModalVisibility } from 'libs/project/src/lib/states/ui-step-functions/ui-step-function.actions';
import { PostDataTransformationData, PreDataTransformationData } from '@rappider/shared/interfaces';
import { NotificationService } from '@rappider/services';
import { CrudTableViewConfig } from '@rappider/rappider-components/utils';

@Component({
  selector: 'rappider-ui-step-function-list',
  templateUrl: './ui-step-function-list.component.html',
  styleUrls: ['./ui-step-function-list.component.scss']
})
export class UIStepFunctionListComponent implements OnInit, OnDestroy {
  /* ui data store id */
  @Input() uiDataStore: UiDataStoreWithRelations;
  /* ui step function list config */
  @Input() listConfig: CrudTableViewConfig;

  /* subscriptions */
  subscriptions: Subscription[];
  /* ui workflow step function */
  uiWorkflowStepFunctions: UiWorkflowStepFunctionWithRelations[];
  /* ui workflow step function list data displayed in the list */
  uiWorkflowStepFunctionListData: UiWorkflowStepFunction[];
  /* data transformations' loading state */
  isDataTransformationsLoading: boolean;
  /* ui step function data loading state */
  isUIStepFunctionsLoading = false;

  isPreDataTransformationModalVisible = false;
  preSourceJsonSchema;
  preTargetJsonSchema;
  preDataTransformationId: string;

  isPostDataTransformationModalVisible = false;
  postSourceJsonSchema;
  postTargetJsonSchema;
  postDataTransformationId: string;

  dataTransformationFlag = false;

  constructor(
    private store: Store<any>,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToUIStepFunctions(),
      this.subscribeToUiStepFunctionsLoading(),
      this.subscribeToDataTransformationsLoading(),
      this.subscribeToPreDataTransformationModalVisibility(),
      this.subscribeToPostDataTransformationModalVisibility(),
      this.subscribeToPreDataTransformationData(),
      this.subscribeToPostDataTransformationData()
    ];
  }

  navigateToUIStepFunctionCreate() {
    this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.UI_STEP_FUNCTION_CREATE}/${this.uiDataStore?.id}` }));
  }

  subscribeToUIStepFunctions() {
    return this.store.select(state => state.uiWorkflowStepFunction?.data).subscribe((uiStepFunctions: UiWorkflowStepFunctionWithRelations[]) => {
      const uiWorkflowStepFunctions = uiStepFunctions.filter(uiStepFunction => uiStepFunction.uiDataStoreId === this.uiDataStore?.id);

      this.uiWorkflowStepFunctions = uiWorkflowStepFunctions;
      this.uiWorkflowStepFunctionListData = uiWorkflowStepFunctions.map(item => ({
        ...item,
        subscribedEvents: item.subscribedEvents?.map(subscribedEvent => subscribedEvent.name).join(', '),
        publishedEventsOnSuccess: item.publishedEventsOnSuccess?.map(publishedEventOnSuccess => publishedEventOnSuccess.name).join(', '),
        publishedEventsOnFailure: item.publishedEventsOnFailure?.map(publishedEventOnFailure => publishedEventOnFailure.name).join(', ')
      }));
    });
  }

  subscribeToUiStepFunctionsLoading() {
    return this.store.select(state => state.uiWorkflowStepFunction?.loading).subscribe((loading: boolean) => {
      this.isUIStepFunctionsLoading = loading;
    });
  }

  subscribeToDataTransformationsLoading() {
    return this.store.select(state => state.uiWorkflowStepFunction?.dataTransformationsLoading).subscribe((isDataTransformationsLoading: boolean) => {
      this.isDataTransformationsLoading = isDataTransformationsLoading;
    });
  }

  subscribeToPreDataTransformationModalVisibility() {
    return this.store.select(state => state.uiWorkflowStepFunction?.preDataTransformationModalVisibility).subscribe((visibility: boolean) => {
      this.isPreDataTransformationModalVisible = visibility;
    });
  }

  subscribeToPostDataTransformationModalVisibility() {
    return this.store.select(state => state.uiWorkflowStepFunction?.postDataTransformationModalVisibility).subscribe((visibility: boolean) => {
      this.isPostDataTransformationModalVisible = visibility;
    });
  }

  onUiStepFunctionDeleteClick(uiWorkflowStepFunction: UiWorkflowStepFunction) {
    this.store.dispatch(new DeleteUIWorkflowStepFunction({ uiWorkflowStepFunction }));
  }

  // #region DATA TRANSFORMATION

  // #region PRE DATA TRANSFORMATION

  handlePreDataTransformationModalVisibility() {
    this.store.dispatch(new TogglePreDataTransformationModalVisibility());
  }

  onPreDataTransformationClick(selectedUIStepFunction: UiWorkflowStepFunctionWithRelations) {
    if (selectedUIStepFunction.type === UIWorkflowItemTypeValues.PreDefinedTemplate) {
      this.notificationService.createNotification(
        'warning',
        'Warning',
        'Data transformation cannot be added to step functions with the type Predefined Template.'
      );
    } else {
      const uiStepFunctionWithRelations = this.uiWorkflowStepFunctions.find(uiStepFunction => uiStepFunction.id === selectedUIStepFunction.id);
      if (uiStepFunctionWithRelations.endpointId
        || uiStepFunctionWithRelations.workflowServiceId
        || uiStepFunctionWithRelations.workflowStepFunctionId
        || (!uiStepFunctionWithRelations.mode && uiStepFunctionWithRelations.publishedEventsOnSuccess?.length)) {
        this.store.dispatch(new GetPreDataTransformationData({ uiStepFunction: uiStepFunctionWithRelations }));
        this.handlePreDataTransformationModalVisibility();
      } else {
        this.notificationService.createNotification(
          'warning',
          'Warning',
          'UI Step Function must contains one of the following to be able to add Pre Data Transformation; Published Event On Success, Workflow Service, Endpoint or Workflow step function'
        );
      }
    }
  }

  subscribeToPreDataTransformationData() {
    return this.store.select(state => state.uiWorkflowStepFunction?.preDataTransformationData).subscribe((preDTData: PreDataTransformationData) => {
      this.preSourceJsonSchema = preDTData?.preSourceJsonSchema;
      this.preTargetJsonSchema = preDTData?.preTargetJsonSchema;
      this.preDataTransformationId = preDTData?.preDataTransformationId;
    });
  }


  // #endregion PRE DATA TRANSFORMATION

  // #region POST DATA TRANSFORMATION

  handlePostDataTransformationModalVisibility() {
    this.store.dispatch(new TogglePostDataTransformationModalVisibility());
  }

  onPostDataTransformationClick(selectedUIStepFunction: UiWorkflowStepFunctionWithRelations) {
    if (selectedUIStepFunction.type === UIWorkflowItemTypeValues.PreDefinedTemplate) {
      this.notificationService.createNotification('warning', 'Warning', 'Data transformation cannot be added to step functions with the type Predefined Template.');
    } else {
      const uiStepFunctionWithRelations = this.uiWorkflowStepFunctions.find(uiStepFunction => uiStepFunction.id === selectedUIStepFunction.id);
      if ((uiStepFunctionWithRelations.endpointId
        || uiStepFunctionWithRelations.workflowServiceId
        || uiStepFunctionWithRelations.workflowStepFunctionId)
        && uiStepFunctionWithRelations.publishedEventsOnSuccess?.length) {
        this.store.dispatch(new GetPostDataTransformationData({ uiStepFunction: uiStepFunctionWithRelations }));
        this.handlePostDataTransformationModalVisibility();
      } else {
        this.notificationService.createNotification(
          'warning',
          'Warning',
          'UI Step Function must contains one of the following to be able to add Post Data Transformation; Published Event On Success, Workflow Service, Endpoint or Workflow step function'
        );
      }
    }
  }

  subscribeToPostDataTransformationData() {
    return this.store.select(state => state.uiWorkflowStepFunction?.postDataTransformationData).subscribe((postDTData: PostDataTransformationData) => {
      this.postSourceJsonSchema = postDTData?.postSourceJsonSchema;
      this.postTargetJsonSchema = postDTData?.postTargetJsonSchema;
      this.postDataTransformationId = postDTData?.postDataTransformationId;
    });
  }
  // #endregion POST DATA TRANSFORMATION

  setStepsFlag(key: string) {
    if (key === 'data-transformation') {
      this.dataTransformationFlag = true;
    }
  }

  onShowStepsFlag(value: { flag: boolean; key: string }) {
    if (value.key === 'data-transformation') {
      this.dataTransformationFlag = value.flag;
    }
  }

  // #endregion DATA TRANSFORMATION
}
