export const ENVIRONMENT_VARIABLES_COLUMNS = [
  {
    'fieldName': 'key',
    'placeholder': 'Key',
    'typeAndFormat': {
      'type': 'string'
    },
    'config': {
      'placeholder': 'Key'
    }
  },
  {
    'fieldName': 'value',
    'placeholder': 'Value',
    'typeAndFormat': {
      'type': 'string'
    },
    'config': {
      'placeholder': 'Value'
    }
  }
];
