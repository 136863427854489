<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>

<section>
  <rappider-data-schema-element-row [data]="dataSchemaFields"
                                    [dataSchemaId]="parentDataSchemaId"
                                    [activeProjectId]="activeProject?.id"
                                    [dataSchemaPageType]="DataSchemaPageType"
                                    [isAddButtonVisible]="isDataSchemaElementRowAddButtonVisible"
                                    (saveButtonClick)="onDataSchemaSave($event)"></rappider-data-schema-element-row>
</section>
