
<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton"></rappider-title-toolbar>
<section>

<rappider-list-grid [config]="PROJECT_TEST_MEMBERS_CONFIG"
                          [data]="testUserDisplayData"
                          [loading]="isLoading"   
                          (listActionClick)="addTestUser($event)"
                          (columnActionClick)="onItemClick($event)"
                         ></rappider-list-grid>

<rappider-test-users-modals [testUserModalData]="testUserModalData"         
                            (onModalButtonClick)="onItemClick($event)"
                            [modalConfigs]="modalConfigs"
                         ></rappider-test-users-modals>
</section>
