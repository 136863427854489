/* eslint-disable @typescript-eslint/no-use-before-define */
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { KeyValue } from '@angular/common';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DataSchemaInterface } from '@rappider/api-sdk';
import { ButtonComponentConfig, ButtonType } from '@rappider/rappider-components/utils';
import { RappiderCustomFunction } from '@rappider/shared/interfaces';
import { isEqual } from 'lodash';
import { DataMapping } from '../../models/data-mapping';
import { FunctionButtonText, FunctionMode } from './models/data-mapping-create';

@Component({
  selector: 'rappider-data-mapping-create',
  templateUrl: './data-mapping-create.component.html',
  styleUrls: ['./data-mapping-create.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DataMappingCreateComponent),
      multi: true
    }
  ]
})
export class DataMappingCreateComponent implements OnInit, ControlValueAccessor {

  @Input() targetDataSchema: DataSchemaInterface;

  @Input() sourceDataSchema: DataSchemaInterface;

  @Input() functions: KeyValue<string, string>[] = [];

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter<{ sourceFields?: any[]; functionId?: string; targetField?: any }>();

  _value: DataMapping;

  isCustomFunctionAreaVisible = true;

  customFunctionValue: RappiderCustomFunction;

  get value() {
    return this._value;
  }

  set value(value: DataMapping) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
  }

  sourceFieldValue: [{ title: string; id: string }];

  sourceFields = [];

  sourceFieldModalVisible = false;

  functionValue = null;

  functionMode: FunctionMode;

  FunctionModes = FunctionMode;

  assignFunction = {
    key: 'assign',
    value: 'value'
  };

  functionButtonText = FunctionButtonText.CustomFunction;

  customFunctionButton: ButtonComponentConfig = {
    type: ButtonType.Default,
    icon: {
      name: 'fas fa-plus'
    }
  };

  addSourceFieldButton: ButtonComponentConfig = {
    text: 'PROJECT_MODULE.DATA_MAPPING_CREATE_COMPONENT.ADD_SOURCE_FIELD',
    type: ButtonType.Default,
    icon: {
      name: 'fas fa-plus'
    }
  };
  ngOnInit(): void {
    this.setInitialValueForFunction();
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: { sourceFields: any[]; functionId: string; targetField: any }) {
    this._value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onBlur() {
    this.blur.emit(this.value);
  }

  onFunctionSelect() {
    if (this.value?.functionId) {
      this.functionMode = this.FunctionModes.Label;
    }
  }

  onSourceFieldAdd() {
    this.sourceFields.push(this.sourceFieldValue);
    this.sourceFieldValue = null;
    this.onSourceFieldChange();
    this.value.sourceFields = this.sourceFields;
    this.handleSourceModalVisibility(false);
    this.sourceFields = [...this.sourceFields];
  }

  onAddSourceFieldClick() {
    this.handleSourceModalVisibility(true);
  }

  handleSourceModalVisibility(visibility: boolean) {
    this.sourceFieldModalVisible = visibility;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.sourceFields, event.previousIndex, event.currentIndex);
    /* To trigger change detection for custom function */
    this.sourceFields = [...this.sourceFields];
  }

  setInitialValueForFunction() {
    if (this.functions?.length) {
      this.functions.push(this.assignFunction);
    } else {
      this.functions = [this.assignFunction];
    }
  }

  getFunctionKeyByValue() {
    return this.functions?.find(functionObject => functionObject.value === this.value.functionId).key;
  }

  onSourceFieldChange() {
    if (this.sourceFields.length > 1) {
      this.value.functionId = null;
      this.functions = this.functions.filter(functionObject => !isEqual(this.assignFunction, functionObject));
      if (this.functionMode !== this.FunctionModes.Custom) {
        this.functionMode = this.FunctionModes.Edit;
      }
    } else {
      const assignFunction = this.functions.find(item => item.value === this.assignFunction.value);
      if (!assignFunction) {
        this.functions.push(this.assignFunction);
        this.functions = [...this.functions];
      }
    }
  }

  onEditFunctionClick() {
    this.functionMode = this.FunctionModes.Edit;
  }

  deleteItem(data: any) {
    this.sourceFields = this.sourceFields.filter(item => !isEqual(item, data));
    this.value.sourceFields = this.sourceFields;
    this.onSourceFieldChange();
  }

  enableCustomFunction() {
    if (this.functionMode !== this.FunctionModes.Custom) {
      this.functionMode = this.FunctionModes.Custom;
      this.functionButtonText = FunctionButtonText.SelectFunction;
    } else {
      this.functionMode = this.FunctionModes.Edit;
      this.functionButtonText = FunctionButtonText.CustomFunction;
    }
  }

  onCustomFunctionValueChange() {
    this.value = { ...this.value };
  }
}
