import { CrudFormConfig, CrudFormRadioGroupItem, CrudFormSelectItem, CrudFormSwitchItem, CrudFormTextAreaItem, CrudViewFormItemType, FormLayout, InputSize } from '@rappider/rappider-components/utils';
import { Validators } from '@angular/forms';
import { PROJECT_KEY_REGEX, PROJECT_NAME_REGEX } from '../../definitions/regex';
import { METADATA_CREATE_FORM_CONFIG } from '../metadata/metadata-create-form-config';
import { APP_TYPES, FRONTEND_FRAMEWORK_TYPES, BACKEND_FRAMEWORK_TYPES } from '../../definitions/project-version-definitions';
import { projectCreateSteps } from './project-create-steps';

export const PROJECT_CREATE_FORM_CONFIG: CrudFormConfig = {
  items: [
    {
      section: 'Project Information',
      title: projectCreateSteps.step1.label,
      fieldName: 'name',
      type: CrudViewFormItemType.TextBox,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          type: Validators.pattern(PROJECT_NAME_REGEX),
          errorKey: 'pattern',
          errorMessage: 'PROJECT_MODULE.PROJECT_NAME_ERROR'
        }
      ]
    },
    <CrudFormTextAreaItem>{
      section: 'Project Information',
      title: projectCreateSteps.step2.label,
      labelDescription: projectCreateSteps.step2.description,
      description: 'Shift ⇧ + Enter ↵ to make a line break',
      fieldName: 'description',
      type: CrudViewFormItemType.TextArea,
      minRows: 1,
      maxRows: 10,
      autosize: true,
    },
    {
      section: 'Project Information',
      title: projectCreateSteps.step3.label,
      labelDescription: projectCreateSteps.step3.description,
      fieldName: 'targetAudienceDescription',
      type: CrudViewFormItemType.TextBox,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    <CrudFormRadioGroupItem>{
      section: 'Project Information',
      title: projectCreateSteps.step4.label,
      labelDescription: projectCreateSteps.step4.description,
      fieldName: 'hasAuthenticationModule',
      type: CrudViewFormItemType.RadioGroup,
      options: [
        {
          label: 'Yes',
          value: true
        },
        {
          label: 'No',
          value: false
        }
      ],
      default: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    <CrudFormRadioGroupItem>{
      section: 'Project Information',
      title: projectCreateSteps.step5.label,
      labelDescription: projectCreateSteps.step5.description,
      fieldName: 'hasPaymentModule',
      type: CrudViewFormItemType.RadioGroup,
      options: [
        {
          label: 'Yes',
          value: true
        },
        {
          label: 'No',
          value: false
        }
      ],
      default: false,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    <CrudFormSwitchItem>{
      section: 'Project Information',
      title: 'AI Assistant',
      labelDescription: 'Would you like Rapider AI help to create the models and pages?',
      fieldName: 'isCreatedByAI',
      type: CrudViewFormItemType.Switch,
      text: {
        text: 'Build my project with Rapider AI'
      }
    },
    {
      section: 'Project Information',
      title: 'SHARED.KEY',
      fieldName: 'key',
      type: CrudViewFormItemType.TextBox,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          type: Validators.pattern(PROJECT_KEY_REGEX),
          errorKey: 'pattern',
          errorMessage: 'PROJECT_MODULE.PROJECT_KEY_ERROR'
        }
      ],
      visible: false
    },
    <CrudFormSelectItem>{
      section: 'Project Settings',
      title: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.APP_TYPE',
      fieldName: 'appType',
      type: CrudViewFormItemType.Select,
      size: InputSize.Large,
      placeholder: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.SELECT_APP_TYPE',
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ],
      options: [
        { key: APP_TYPES.WEB.key, value: APP_TYPES.WEB.value },
      ],
      default: APP_TYPES.WEB.value,
      visible: false
    },
    <CrudFormSelectItem>{
      section: 'Project Settings',
      title: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.FRONTEND_FRAMEWORK_TITLE',
      fieldName: 'frontendFramework',
      type: CrudViewFormItemType.Select,
      size: InputSize.Large,
      placeholder: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.SELECT_FRONTEND_FRAMEWORK',
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ],
      options: [
        { key: FRONTEND_FRAMEWORK_TYPES.ANGULAR.key, value: FRONTEND_FRAMEWORK_TYPES.ANGULAR.value },
      ],
      default: FRONTEND_FRAMEWORK_TYPES.ANGULAR.value,
      visible: false
    },
    <CrudFormSelectItem>{
      section: 'Project Settings',
      title: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.BACKEND_FRAMEWORK_TITLE',
      fieldName: 'backendFramework',
      type: CrudViewFormItemType.Select,
      size: InputSize.Large,
      placeholder: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.SELECT_BACKEND_FRAMEWORK',
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ],
      options: [
        { key: BACKEND_FRAMEWORK_TYPES.LOOPBACK4.key, value: BACKEND_FRAMEWORK_TYPES.LOOPBACK4.value },
        { key: BACKEND_FRAMEWORK_TYPES.SQLALCHEMY.key, value: BACKEND_FRAMEWORK_TYPES.SQLALCHEMY.value },
      ],
      default: BACKEND_FRAMEWORK_TYPES.LOOPBACK4.value,
      visible: false
    },
    {
      ...METADATA_CREATE_FORM_CONFIG,
      visible: false
    },
  ],
  itemSettings: {
    inputComponentSize: {
      xs: 24
    },
    labelComponentSize: {
      xs: 24
    }
  },
  submitButton: {
    text: 'Submit'
  },
  formLayout: FormLayout.Vertical
};

