import { CrudTableViewConfig, CrudViewColumnType } from '@rappider/rappider-components/utils';

export const UI_STEP_FUNCTIONS_EMPTY_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'name',
  searchable: false,
  columnVisibilitySelectable: false,
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false,
    },
  ],
  itemActions: []
};
