export const BOOTSTRAP_SCREEN_SIZES = {
  XS: {
    title: 'xs',
    minPoint: 0,
    maxPoint: 575,
    range: '0-575'
  },
  SM: {
    title: 'sm',
    minPoint: 576,
    maxPoint: 767,
    range: '576-767'
  },
  MD: {
    title: 'md',
    minPoint: 768,
    maxPoint: 991,
    range: '768-991'
  },
  LG: {
    title: 'lg',
    minPoint: 992,
    maxPoint: 1199,
    range: '992-1199'
  },
  XL: {
    title: 'xl',
    minPoint: 1200,
    range: '1200+'
  }
};
