<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>

<section>
  <rappider-list-grid [config]="DATA_SCHEMA_LIST_CONFIG"
                      [data]="dataSchemas"
                      [loading]="loading"
                      (columnActionClick)="onDataSchemaDelete($event)"
                      (listActionClick)="onDataSchemaRefresh($event)"></rappider-list-grid>
</section>
