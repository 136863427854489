<div *ngIf="backgroundImage"
     class="container-area"
     [style.width]="backgroundImage?.width">
  <rappider-image [source]="backgroundImage?.source"
                  [alternateText]="backgroundImage?.alternateText"
                  [width]="backgroundImage?.width"
                  [height]="backgroundImage?.height"
                  [placeholder]="backgroundImage?.placeholder"
                  [fallback]="backgroundImage?.fallback"
                  [disablePreview]="backgroundImage?.disablePreview"
                  [borderSettings]="backgroundImage?.borderSettings"
                  [customSizeSettings]="backgroundImage?.width"
                  [shadowSettings]="backgroundImage?.shadowSettings"
                  [marginSettings]="backgroundImage?.marginSettings"
                  [paddingSettings]="backgroundImage?.paddingSettings"></rappider-image>
  <div class="content-area"
       [style.align-items]="horizontalContentPlacement"
       [style.text-align]="horizontalContentPlacement"
       [style.justify-content]="verticalContentPlacement">
    <div *ngIf="title"
         class="title">
      <rappider-heading [content]="title.content"
                        [type]="title.type"></rappider-heading>
    </div>

    <hr *ngIf="lineBackgroundColor || lineWidth"
        class="line"
        [style.background-color]="lineBackgroundColor"
        [style.width]="lineWidth"
        [style.height]="lineHeight">

    <div *ngIf="content"
         class="content">
      <rappider-text [content]="content.content"
                     [textMode]="content.textMode"
                     [text]="content.text"
                     [typography]="content.typography"
                     [colorSettings]="content.colorSettings"></rappider-text>
    </div>

    <div *ngIf="button"
         class="button-area">
      <rappider-button [type]="button.type"
                       [shape]="button.shape"
                       [text]="button.text"
                       [size]="button.size"
                       [transparent]="button.transparent"
                       [loading]="button.loading"
                       [block]="button.block"
                       [disabled]="button.disabled"
                       [colorType]="button.colorType"
                       [icon]="button.icon"
                       [key]="button.key"
                       [formButtonType]="button?.formButtonType"
                       [borderSettings]="button?.borderSettings"
                       [customSizeSettings]="button?.customSizeSettings"
                       [customColorSettings]="button?.customColorSettings"
                       [shadowSettings]="button?.shadowSettings"
                       [paddingSettings]="button?.paddingSettings"
                       [marginSettings]="button?.marginSettings"
                       (click)="onButtonClick()"></rappider-button>
    </div>
  </div>
</div>
