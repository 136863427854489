import { ButtonType, ActionBehavior } from '@rappider/rappider-components/utils';
import { ProjectRoleEvents } from './project-role-events.enum';

export const ASSIGN_MANAGER_CONFIG = {
  text: 'PROJECT_MODULE.PROJECT_MEMBERS_COMPONENT.ASSIGN_AS_MANAGER',
  name: ProjectRoleEvents.AssignManager,
  behavior: ActionBehavior.Emit,
  buttonType: ButtonType.Default,
  icon: { name: 'far fa-user-cog' }
};

export const UNASSIGN_MANAGER_CONFIG = {
  text: 'PROJECT_MODULE.PROJECT_MEMBERS_COMPONENT.UNASSIGN_AS_MANAGER',
  name: ProjectRoleEvents.UnassignManager,
  behavior: ActionBehavior.Emit,
  buttonType: ButtonType.Default,
  icon: { name: 'far fa-user-cog' }
};

export const EDIT_ROLES_CONFIG = {
  text: 'SHARED.EDIT',
  name: ProjectRoleEvents.EditRoles,
  behavior: ActionBehavior.Emit,
  buttonType: ButtonType.Default,
  icon: { name: 'far fa-edit' }
};
