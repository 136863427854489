export const uiDataUpdateFunctionGetWithDetailsFilter = {
    include: [
        {
            relation: 'dataMaps',
            scope: {
                include: [
                    {
                        relation: 'sourceTreeFields',
                        scope: {
                            include: [
                                {
                                    relation: 'nodes',
                                    scope: {
                                        include: ['field'],
                                        order: ['index ASC']
                                    }
                                }
                            ]
                        }
                    },
                    {
                        relation: 'targetTreeField',
                        scope: {
                            include: [
                                {
                                    relation: 'nodes',
                                    scope: {
                                        include: ['field'],
                                        order: ['index ASC']
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        {
            relation: 'event',
            scope: {
                include: [
                    { relation: 'payload' },
                    { relation: 'uiDataStore' }
                ]
            }
        }
    ]
};
