<rappider-internal-title-toolbar [mainTitle]="mainTitle"
                                 [options]="title"
                                 [displayToolbar]="displayToolbar"
                                 [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-internal-title-toolbar>

<section>
  <rappider-list-grid [config]="PAGE_LAYOUT_LIST_CONFIG"
                      [data]="layouts"
                      [loading]="loading"
                      (columnActionClick)="onColumnActionClick($event)"></rappider-list-grid>
</section>
