import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as PermissionActions from './permission.actions';
import * as RoleActions from '../role-crud-state/role-crud.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PermissionControllerService } from '@rappider/rappider-sdk';
import { NotificationService } from 'libs/components/src/lib/services';

@Injectable()
export class PermissionEffects {
  constructor(
    private actions$: Actions,
    private permissionController: PermissionControllerService,
    private notificationService: NotificationService
  ) { }

  getPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PermissionActions.GetPermissions,
        RoleActions.GetRoles
      ),
      switchMap(() => this.permissionController.findByAdmin().pipe(
        map((permissions) => PermissionActions.GetPermissionsSuccessful({ permissions: permissions })),
        catchError((error) =>
          [
            PermissionActions.GetPermissionsFailure({ error: error, key: 'GetPermissions', timestamp: Date.now() })
          ]
        )
      )
      )
    )
  );

  createPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PermissionActions.CreatePermission),
      switchMap((action) => this.permissionController.createByAdmin({ body: action.permission }).pipe(
        map((createdPermission) => {
          this.notificationService.createNotification(
            'success',
            'Success',
            'Permission Created Successfully'
          );
          return PermissionActions.CreatePermissionSuccessful({ permission: createdPermission });
        }),
        catchError((error) => {
          this.notificationService.createNotification(
            'error',
            'Error',
            'Permission Could not Created'
          );
          return [
            PermissionActions.CreatePermissionFailure({ error: error, key: 'CreatePermission', timestamp: Date.now() })
          ];
        })
      ))
    )
  );

  updatePermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PermissionActions.UpdatePermission),
      switchMap((action) => this.permissionController.updateByIdByAdmin({ id: action.id, body: action.permission }).pipe(
        map(() => {
          this.notificationService.createNotification(
            'success',
            'Success',
            'Permission Updated Successfully'
          );
          return PermissionActions.UpdatePermissionSuccessful({ id: action.id, permission: action.permission });
        }),
        catchError((error) => {
          this.notificationService.createNotification(
            'error',
            'Error',
            'Permission Could not Updated'
          );
          return [
            PermissionActions.UpdatePermissionFailure({ error: error, key: 'UpdatePermission', timestamp: Date.now() })
          ];
        })
      ))
    )
  );

  deletePermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PermissionActions.DeletePermission),
      switchMap((action) => this.permissionController.deleteByIdByAdmin({ id: action.id }).pipe(
        map(() => {
          this.notificationService.createNotification(
            'success',
            'Success',
            'Permission Deleted Successfully'
          );
          return PermissionActions.DeletePermissionSuccessful({ id: action.id });
        }),
        catchError((error) => {
          this.notificationService.createNotification(
            'error',
            'Error',
            'Permission Could not Deleted'
          );
          return [
            PermissionActions.DeletePermissionFailure({ error: error, key: 'DeletePermission', timestamp: Date.now() })
          ];
        })
      ))
    )
  );
}
