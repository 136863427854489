import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RappiderPreviewerComponent } from './previewer.component';
import { RappiderModalModule } from '../modal/modal.module';
import { RappiderButtonModule } from '../button/button.module';
import { RappiderImageModule } from '../image/image.module';
import { RappiderPdfViewerModule } from '../pdf-viewer/pdf-viewer.module';

@NgModule({
  declarations: [
    RappiderPreviewerComponent
  ],
  imports: [
    CommonModule,
    RappiderModalModule,
    RappiderButtonModule,
    RappiderImageModule,
    RappiderPdfViewerModule
  ],
  exports: [
    RappiderPreviewerComponent
  ]
})
export class RappiderPreviewerModule { }
