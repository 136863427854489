import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { UiDataSelectorControllerService } from '@rappider/rappider-sdk';
import * as UIDataSelectorActions from './ui-data-selector.actions';
import { catchError, mergeMap } from 'rxjs/operators';
import { NotificationService } from '@rappider/services';
@Injectable()
export class UIDataSelectorEffects {

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private uiDataSelectorApi: UiDataSelectorControllerService,
    private notificationService: NotificationService
  ) { }

  getUIDataSelectors$ = createEffect(() => this.actions$.pipe(
    ofType(UIDataSelectorActions.GetUIDataSelectors),
    mergeMap(action => {
      const params = {
        filter: {
          include: ['uiDataStoreFieldSelectors']
        }
      };
      return this.uiDataSelectorApi.find(params).pipe(
        mergeMap((uiDataSelectors) => [
          UIDataSelectorActions.GetUIDataSelectorsSuccessful({ payload: { uiDataSelectors: uiDataSelectors } })
        ]),
        catchError((error) => [
          UIDataSelectorActions.Error({ payload: { error: error } })
        ])
      );
    }
    )));

  getUIDataSelectorById$ = createEffect(() => this.actions$.pipe(
    ofType(UIDataSelectorActions.GetUIDataSelectorById),
    mergeMap(action => {
      const params = {
        id: action.payload.uiDataSelectorId,
        filter: {
          include: ['uiDataStoreFieldSelectors']
        }
      };
      return this.uiDataSelectorApi.findById(params).pipe(
        mergeMap((uiDataSelector) => [
          UIDataSelectorActions.GetUIDataSelectorByIdSuccessful({ payload: { uiDataSelector: uiDataSelector, replace: action.payload.replace } })
        ]),
        catchError((error) => [
          UIDataSelectorActions.Error({ payload: { error: error } })
        ])
      );
    })
  ));

  createUIDataSelector$ = createEffect(() => this.actions$.pipe(
    ofType(UIDataSelectorActions.CreateUIDataSelector),
    mergeMap(action => {
      const params = {
        body: action.payload.uiDataSelector
      };
      return this.uiDataSelectorApi.create(params).pipe(
        mergeMap((uiDataSelector: any) => [
          UIDataSelectorActions.GetUIDataSelectorById({ payload: { uiDataSelectorId: uiDataSelector.id, replace: false } })
        ]),
        catchError((error) => {
          this.notificationService.createNotification(
            'error',
            'Error',
            error?.error?.error?.message
          );
          return [
            UIDataSelectorActions.Error({ payload: { error: error } })
          ];
        })
      );
    })
  ));

  updateUIDataSelector$ = createEffect(() => this.actions$.pipe(
    ofType(UIDataSelectorActions.UpdateUIDataSelector),
    mergeMap(action => {
      const params = {
        id: action.payload.uiDataSelectorId,
        body: action.payload.uiDataSelector
      };
      return this.uiDataSelectorApi.updateById(params).pipe(
        mergeMap(() => [
          UIDataSelectorActions.GetUIDataSelectorById({ payload: { uiDataSelectorId: action.payload.uiDataSelectorId, replace: true } })
        ]),
        catchError((error) => [
          UIDataSelectorActions.Error({ payload: { error: error } })
        ])
      );
    })
  ));

  deleteUIDataSelector$ = createEffect(() => this.actions$.pipe(
    ofType(UIDataSelectorActions.DeleteUIDataSelector),
    mergeMap(action => {
      const params = {
        id: action.payload.uiDataSelectorId
      };
      return this.uiDataSelectorApi.deleteById(params).pipe(
        mergeMap(() => [
          UIDataSelectorActions.DeleteUIDataSelectorSuccessful({ payload: { uiDataSelectorId: action.payload.uiDataSelectorId } })
        ]),
        catchError((error) => [
          UIDataSelectorActions.Error({ payload: { error: error } })
        ])
      );
    })
  ));
}
