import { createSelector } from '@ngrx/store';
import { ModuleWithRelations } from '@rappider/rappider-sdk';
import { EntityActivityInformation } from '@rappider/shared/interfaces';
import { cloneDeep, orderBy } from 'lodash';

/* if there are no project settings and no default module */
function sortModulesByDate(modules: ModuleWithRelations[]) {
  return orderBy(modules, 'createdDate', 'asc');
}

/* if there are project settings and default module */
function sortModules(modules: ModuleWithRelations[], defaultModuleId: string) {
  if (modules?.length && defaultModuleId) {
    return modules.sort((firstModule, secondModule) => {
      if (firstModule.id === defaultModuleId) {
        return -1;
      } else if (secondModule.id === defaultModuleId) {
        return 1;
      } else {
        const caseInsensitiveFirstModuleName = firstModule.name.toUpperCase();
        const caseInsensitiveSecondModuleName = secondModule.name.toUpperCase();
        if (caseInsensitiveFirstModuleName < caseInsensitiveSecondModuleName) {
          return -1;
        } else if (caseInsensitiveFirstModuleName > caseInsensitiveSecondModuleName) {
          return 1;
        } else {
          return 0;
        }
      }
    });
  } else {
    return modules;
  }
}

function addActivityInformation<T extends
  {
    createdBy?: string; createdDate?: string | Date; updatedBy?: string; updatedDate?: string | Date;
  }>(
    entities: T[]
  ): (T & EntityActivityInformation)[] {
  return entities?.map(entity => {
    const lastActivityBy = entity.updatedBy || entity.createdBy || null;
    const lastActivityDateString = entity.updatedDate || entity.createdDate;
    const lastActivityDate = lastActivityDateString ? new Date(lastActivityDateString) : null;
    return {
      ...entity,
      lastActivityBy,
      lastActivityDate
    };
  }) ?? [];
}

export const getPagesWithHomePageAndModules = createSelector(
  state => state['page']?.data,
  state => state['projectSetting']?.data,
  state => state['module']?.data,
  state => state['category']?.data,
  (pages: any[], projectSettings: any[], modules: any[], categories: any[]) => {
    // if project settings exist
    if (projectSettings?.length > 0) {
      const homePageId = projectSettings?.find(projectSetting => projectSetting.key === 'homePageId')?.value;
      const defaultModuleId = projectSettings?.find(projectSetting => projectSetting.key === 'defaultModuleId')?.value;
      let sortedModules = sortModules(cloneDeep(modules), defaultModuleId);
      sortedModules = addActivityInformation(sortedModules);

      if (pages && homePageId) {
        const homePage = pages.find(page => page.id === homePageId);

        if (homePage) {
          const updatedPages = orderBy(
            [...pages.filter(page => page.id !== homePage.id), { ...homePage, isHomepage: true }],
            'title'
          );

          const pagesWithHomePageAndModules = sortedModules?.map(m => ({
            ...m,
            key: m.id,
            children: addActivityInformation(
              orderBy(updatedPages.filter(page => page.moduleId === m?.id).map(page => ({ ...page, key: page.id })), 'createdDate', 'desc')
            ),
            category: categories?.find(category => category.id === m.categoryId)
          }));

          return pagesWithHomePageAndModules;
        }
      }
      return [];
    } else {
      // if project settings doesn't exist
      let sortedModules = sortModulesByDate(cloneDeep(modules));
      sortedModules = addActivityInformation(sortedModules);

      if (pages) {
        const sortedPages = orderBy(pages, 'createdDate', 'desc');
        const pagesWithHomePageAndModules = sortedModules?.map(m => ({
          ...m,
          key: m.id,
          children: addActivityInformation(
            sortedPages.filter(page => page.moduleId === m?.id).map(page => ({ ...page, key: page.id }))
          ),
          category: categories?.find(category => category.id === m.categoryId)
        }));
        return pagesWithHomePageAndModules;
      }
      return [];
    }
  }
);
