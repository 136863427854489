<!-- TITLE AREA -->
<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>
<!-- / TITLE AREA -->
<!-- ----------------------------------------------------------------------------------------- -->

<!-- ----------------------------------------------------------------------------------------- -->
<!-- SKELETON -->
<section *ngIf="loading">
  <nz-skeleton [nzActive]="true"></nz-skeleton>
</section>
<!-- / SKELETON -->
<!-- ----------------------------------------------------------------------------------------- -->

<!-- ----------------------------------------------------------------------------------------- -->
<!-- PROJECT PERSON LIST -->
<section *ngIf="!loading">
  <rappider-list-grid [config]="PROJECT_PERSON_LIST_CONFIG"
                      [data]="projectPersons"></rappider-list-grid>
</section>
<!-- / PROJECT PERSON LIST -->
<!-- ----------------------------------------------------------------------------------------- -->
