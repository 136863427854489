<div>
  <!-- TODO: bu componentin aciklamasi/help kismi icin guidance implemente edilecek -->
  <!-- <div [ngClass]="isGuidanceExpanded ? 'data-schema-display-component' : null"> -->
  <!-- <div class="guidance-area">
    <rappider-guidance [isToggleButtonVisible]="false"
                       [isGuidanceExpanded]="isGuidanceExpanded"></rappider-guidance>
  </div> -->

  <div>
    <section *ngIf="mode === DataSchemaDisplayComponentMode.GenerateCRUDPages">
      <nz-steps [nzCurrent]="currentStep">
        <nz-step nzTitle="Edit Data Schema Fields"></nz-step>
        <nz-step nzTitle="Generate CRUD Pages"></nz-step>
      </nz-steps>
    </section>

    <ng-container *ngIf="currentStep === 0">
      <rappider-alert [type]="settingsFirstStepModalAlertConfig.type"
                      [description]="settingsFirstStepModalAlertConfig.description"
                      [title]="settingsFirstStepModalAlertConfig.title"
                      [closeable]="settingsFirstStepModalAlertConfig.closeable"
                      [showIcon]="settingsFirstStepModalAlertConfig.showIcon">
      </rappider-alert>
      <nz-table class="data-schema-field-table"
                #dataSchemaFieldTable
                [nzData]="activeDataSchema?.fields"
                [nzOuterBordered]="false"
                [nzPageSize]="100"
                [nzShowPagination]="false">
        <thead>
          <tr>
            <th nzWidth="150px">Name</th>
            <th nzWidth="150px">Type</th>
            <th>UI Data Selector</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let dataSchemaField of dataSchemaFieldTable.data">
            <td>
              <div class="data-schema-field-name-area">
                {{dataSchemaField.name}}
              </div>
            </td>

            <td>
              <div class="data-schema-field-name-area">
                {{setDataSchemaFieldType(dataSchemaField)}}
              </div>
            </td>

            <td class="ui-data-selector-area">
              <rappider-select [(ngModel)]="dataSchemaField.uiDataSelector"
                               [options]="uiDataSelectorOptions"></rappider-select>
            </td>

            <td>
              <div class="data-schema-field-edit-button-area">
                <rappider-button [text]="ActionButtonConfig.EditSettingsButton.text"
                                 (confirm)="setEditDataSchemaFieldModal('editSettings',dataSchemaField)"></rappider-button>

                <rappider-button [text]="ActionButtonConfig.EditOptionsButton.text"
                                 (confirm)="setEditDataSchemaFieldModal('editOptions',dataSchemaField)"></rappider-button>
              </div>
            </td>
          </tr>
        </tbody>
      </nz-table>

      <div class="footer-area action-button-margin-top">

        <div class="buttons-area">
          <ng-container *ngIf="mode !== DataSchemaDisplayComponentMode.GenerateCRUDPages">
            <rappider-button [text]="ActionButtonConfig.SaveButton.text"
                             [type]="ActionButtonConfig.SaveButton.type"
                             (confirm)="onSaveDataSchemaFieldDetails()"></rappider-button>
          </ng-container>

        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="currentStep === 1">
      <rappider-alert [type]="settingsSecondStepModalAlertConfig.type"
                      [description]="settingsSecondStepModalAlertConfig.description"
                      [title]="settingsSecondStepModalAlertConfig.title"
                      [closeable]="settingsSecondStepModalAlertConfig.closeable"
                      [showIcon]="settingsSecondStepModalAlertConfig.showIcon">
      </rappider-alert>
      <div class="crud-pages-layout-area">
        <div class="crud-pages-layout-item">
          <rappider-label [content]="'Layout to apply:' | translate"></rappider-label>
          <rappider-select [options]="layoutSelectOptions"
                           [(ngModel)]="selectedLayoutId"></rappider-select>
        </div>
      </div>

    </ng-container>

    <ng-template #editSettingsModalTemplate
                 let-params>
      <rappider-code-editor [(ngModel)]="params.dataSchemaField.uiDataSelectorSettings"
                            [title]=" activeDataSchema?.name + ' ' + params?.dataSchemaField?.name +' Field Settings'"
                            [settings]="CodemirrorSettings">
      </rappider-code-editor>
      <rappider-alert [type]="settingsSecondStepModalAlertConfig.type"
                      [description]="settingsSecondStepModalAlertConfig.description"
                      [title]="settingsSecondStepModalAlertConfig.title"
                      [closeable]="settingsSecondStepModalAlertConfig.closeable"
                      [showIcon]="settingsSecondStepModalAlertConfig.showIcon"></rappider-alert>
    </ng-template>

    <ng-template #editOptionsModalTemplate
                 let-params>
      <rappider-crud-view-edit-form #enumDataForm
                                    [config]="DATA_SCHEMA_FIELD_ENUM_DATA_CONFIG"
                                    [data]="params.dataSchemaField.uiDataSelectorEnumData"
                                    [submitButtonLoading]="isLoading"
                                    (formSubmit)="onEnumDataFormSubmit($event,params.dataSchemaField)"></rappider-crud-view-edit-form>
    </ng-template>
  </div>
</div>
