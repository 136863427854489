import { Validators } from '@angular/forms';
import { CrudFormMonacoCodeEditorItem, CrudViewFormItemType } from '@rappider/rappider-components/utils';

export const MONACO_MARKDOWN_CONFIG = <CrudFormMonacoCodeEditorItem>{
  title: 'SHARED.DESCRIPTION',
  fieldName: 'description',
  type: CrudViewFormItemType.MonacoCodeEditor,
  showRequiredSign: false,
  editorOptions: {
    theme: 'vs-dark',
    language: 'markdown',
    lineNumbers: { renderType: 0 },
    minimap: {
      enabled: false,
    },
  },
  sizeSettings: {
    minHeight: '200px'
  },
};

export const MONACO_MARKDOWN_WITH_REQUIRED_CONFIG = <CrudFormMonacoCodeEditorItem>{
  title: 'SHARED.DESCRIPTION',
  fieldName: 'description',
  type: CrudViewFormItemType.MonacoCodeEditor,
  showRequiredSign: false,
  editorOptions: {
    theme: 'vs',
    language: 'markdown',
    lineNumbers: { renderType: 0 },
    minimap: {
      enabled: false,
    },
  },
  sizeSettings: {
    minHeight: '200px'
  },
  validators: [
    {
      type: Validators.required,
      errorKey: 'required',
      errorMessage: 'ERRORS.DESCRIPTION_REQUIRED'
    }
  ]
};
