import { KeyValue } from '@angular/common';
import { ProjectFilter } from './project-filter.enum';

export const ProjectFilterOptions: KeyValue<string, ProjectFilter>[] = [
  {
    key: 'All Projects',
    value: ProjectFilter.All
  },
  {
    key: 'Starred Projects',
    value: ProjectFilter.Starred
  },
  {
    key: 'Projects Owned By Me',
    value: ProjectFilter.OwnedByMe
  },
  {
    key: 'Shared Projects',
    value: ProjectFilter.Shared
  }
];
