import { Component, Input } from '@angular/core';

@Component({
  selector: 'rappider-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class RappiderStepsComponent {
  @Input() steps: any[];
  @Input() current = 0;
}
