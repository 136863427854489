import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ThemeMode } from '@rappider/models';
import { PROJECT_MENU_CONFIG } from '@rappider/shared/configs';
import { FullPaths } from '@rappider/shared/definitions';

@Component({
  selector: 'rappider-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent {
  @Input() theme?: ThemeMode;

  ThemeMode = ThemeMode;
  features = PROJECT_MENU_CONFIG;
  FullPaths = FullPaths;

  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

}
