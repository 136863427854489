import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataEventListComponent } from './components/data-event-list/data-event-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { DataEventCreateComponent } from './components/data-event-create/data-event-create.component';
import { RouterModule, Routes } from '@angular/router';
import { PAGE_DEFINITIONS } from '@rappider/shared/definitions';
import { DataEventEditComponent } from './components/data-event-edit/data-event-edit.component';
import { DataEventPayloadComponent } from './components/data-event-payload/data-event-payload.component';
import { DataSchemaModule } from '../data-schema/data-schema.module';
import { DataEventDetailComponent } from './components/data-event-detail/data-event-detail.component';
import { UIDataUpdateFunctionModule } from '../ui-data-update-function/ui-data-update-function.module';
import {
  RappiderListGridModule,
  RappiderEditFormModule,
  RappiderTitleBarModule,
  RappiderTitleToolbarModule,
  RappiderSpinModule,
} from '@rappider/rappider-components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { DataEventsComponent } from './components/data-events/data-events.component';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { RappiderButtonModule } from '@rappider/rappider-components';
import { RappiderInputGroupModule } from '@rappider/rappider-components';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

const routes: Routes = [
  {
    path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_DATA_EVENTS.URL}`,
    component: DataEventsComponent,
  },
  {
    path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_EVENT_CREATE.URL}/:dataStoreId`,
    component: DataEventCreateComponent,
  },
  {
    path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_EVENT_DETAIL.URL}/:id`,
    component: DataEventDetailComponent,
  },
];

@NgModule({
  declarations: [
    DataEventListComponent,
    DataEventCreateComponent,
    DataEventEditComponent,
    DataEventPayloadComponent,
    DataEventDetailComponent,
    DataEventsComponent,
  ],
  exports: [
    DataEventListComponent,
    DataEventCreateComponent,
    DataEventEditComponent,
    DataEventDetailComponent,
    DataEventsComponent,
  ],
  imports: [
    CommonModule,
    NzTabsModule,
    TranslateModule,
    RouterModule.forChild(routes),
    DataSchemaModule,
    UIDataUpdateFunctionModule,
    RappiderListGridModule,
    RappiderEditFormModule,
    RappiderTitleBarModule,
    RappiderTitleToolbarModule,
    RappiderSpinModule,
    NzCollapseModule,
    RappiderButtonModule,
    RappiderInputGroupModule,
    FormsModule,
    ReactiveFormsModule,
    NzToolTipModule
  ],
})
export class DataEventsModule { }
