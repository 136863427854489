<div class="data-mapping">
  <div class="schema-area dt-section-border-right">
    <div *ngIf="monacoEditorVisiblity"
         class="dt-section-border-bottom dt-section-padding"
         joyrideStep="mapFirstStep"
         [title]="'DTMapFirstStepTitle' | translate"
         [text]="'DTMapFirstStepContent' | translate">
      <div class="dt-title-margin">
        <rappider-heading type="h6"
                          content="Source Sample"></rappider-heading>
      </div>
      <div class="source-schema-editor">
        <rappider-monaco-code-editor [(ngModel)]="sourceSchemaSample"
                                     [options]="jsonSampleEditorOptions"
                                     [showSelectedKeyPath]="true"
                                     [autoFormat]="true"
                                     [borderSettings]="{border: 'none'}"
                                     (selectedKeyFullPathChange)="onSourceKeySelected($event)"></rappider-monaco-code-editor>
      </div>
    </div>
    <div *ngIf="monacoEditorVisiblity"
         class="schema-tree dt-section-padding"
         joyrideStep="mapSecondStep"
         [title]="'DTMapSecondStepTitle' | translate"
         [text]="'DTMapSecondStepContent' | translate">
      <div class="section-title-bar dt-title-margin">
        <h6>{{displayTargetSchemaSample ? 'Target Schema Sample' :'Target Schema Tree'
          | translate}}
        </h6>
        <rappider-button size="small"
                         [text]="displayTargetSchemaSample ? 'Display Tree': 'Display Sample'"
                         (click)="onClickTargetSchemaSwitcher()"></rappider-button>
      </div>
      <ng-container *ngIf="displayTargetSchemaSample; else targetSchemaTreeTemplate">
        <div class="target-schema-editor">
          <rappider-monaco-code-editor [ngModel]="targetSchemaSample"
                                       [options]="jsonSampleEditorOptions"
                                       [showSelectedKeyPath]="true"
                                       [loading]="targetSchemaSampleLoading"
                                       [autoFormat]="true"
                                       [borderSettings]="{border: 'none'}"
                                       (selectedKeyFullPathChange)="onSelectedTargetFieldChange($event)"></rappider-monaco-code-editor>
        </div>
      </ng-container>
      <ng-template #targetSchemaTreeTemplate>
        <rappider-schema-tree #schemaTreeSelect
                              [(ngModel)]="selectedTargetField"
                              [isMultiple]="false"
                              [tree]="targetTree"
                              (ngModelChange)="updateSelectedMappingPreview()"></rappider-schema-tree>
      </ng-template>
    </div>
    <!-- <div *ngIf="monacoEditorVisiblity"
         class="selected-mapping-preview dt-section-padding"
         joyrideStep="mapThirdStep"
         [title]="'DTMapThirdStepTitle' | translate"
         [text]="'DTMapThirdStepContent' | translate">
      <div class="dt-title-margin">
        <rappider-heading type="h6"
                          content="Mapping Preview"></rappider-heading>
      </div>
      <div class="mapping-preview-editor">
        <rappider-monaco-code-editor [(ngModel)]="selectedMappingPreview"
                                [options]="selectedMappingPreviewEditorOptions"
                                [borderSettings]="{border: 'none'}"></rappider-monaco-code-editor>
      </div>
      <div class="assign-button-wrapper">
        <button nz-button
                (click)="onClickAddDataMapping()">
          {{value?.script ? isPreviewCopied ? 'Copied!': 'Copy' : 'Assing' | translate}}
        </button>
      </div>
    </div> -->
  </div>
  <div *ngIf="monacoEditorVisiblity"
       class="mapping-script">
    <div class="dt-section-border-bottom dt-section-padding"
         joyrideStep="mapFourthStep"
         [title]="'DTMapFourthStepTitle' | translate"
         [text]="'DTMapFourthStepContent' | translate">
      <div class="section-title-bar dt-title-margin">
        <rappider-heading type="h6"
                          content="Mapping Script"></rappider-heading>
        <rappider-button size="small"
                         text="Import Sample"
                         (click)="onClickImportSample()"></rappider-button>
      </div>
      <div class="mapping-script-editor">
        <rappider-jsonata-editor [(ngModel)]="value.script"
                                 (ngModelChange)="onMappingScriptValueChange()"></rappider-jsonata-editor>
      </div>
    </div>
    <div class="jsonata-output dt-section-padding"
         joyrideStep="mapFifthStep"
         [title]="'DTMapFourthStepTitle' | translate"
         [text]="'DTMapFourthStepTitle' | translate">
      <div class="dt-title-margin">
        <rappider-heading type="h6"
                          content="Output"></rappider-heading>
      </div>
      <div class="output-preview">
        <rappider-monaco-code-editor [(ngModel)]="mappingScriptOutput"
                                     [options]="outputPreviewEditorOptions"
                                     [autoFormat]="true"
                                     [borderSettings]="{border: 'none'}"></rappider-monaco-code-editor>
      </div>
    </div>
  </div>
</div>