/* eslint-disable */
import { ProjectFile, Project, Metadata } from '../index';

declare var Object: any;
export interface ProjectFolderInterface {
  id?: string;
  name?: string;
  folderPath?: string;
  isRoot?: boolean;
  isPublic: boolean;
  parentFolderId?: string;
  projectId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  files?: ProjectFile[];
  subFolders?: ProjectFolder[];
  parentFolder?: ProjectFolder;
  project?: Project;
  metadata?: Metadata[];
}

export class ProjectFolder implements ProjectFolderInterface {
  'id'?: string;
  'name': string;
  'folderPath': string;
  'isRoot': boolean;
  'isPublic': boolean;
  'parentFolderId': string;
  'projectId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  files: ProjectFile[];
  subFolders: ProjectFolder[];
  parentFolder: ProjectFolder;
  project: Project;
  metadata: Metadata[];
  constructor(data?: ProjectFolderInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ProjectFolder`.
   */
  public static getModelName() {
    return 'ProjectFolder';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ProjectFolder for dynamic purposes.
   **/
  public static factory(data: ProjectFolderInterface): ProjectFolder {
    return new ProjectFolder(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ProjectFolder',
      plural: 'ProjectFolders',
      path: 'project-folders',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        folderPath: {
          name: 'folderPath',
          type: 'string',
        },
        isRoot: {
          name: 'isRoot',
          type: 'boolean',
        },
        isPublic: {
          name: 'isPublic',
          type: 'boolean',
        },
        parentFolderId: {
          name: 'parentFolderId',
          type: 'string',
        },
        projectId: {
          name: 'projectId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
      },
      relations: {
        files: {
          name: 'files',
          type: 'ProjectFile[]',
          model: 'ProjectFile',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'parentFolderId',
        },
        subFolders: {
          name: 'subFolders',
          type: 'ProjectFolder[]',
          model: 'ProjectFolder',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'parentFolderId',
        },
        parentFolder: {
          name: 'parentFolder',
          type: 'ProjectFolder',
          model: 'ProjectFolder',
          relationType: 'belongsTo',
          keyFrom: 'parentFolderId',
          keyTo: 'id',
        },
        project: {
          name: 'project',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
          keyFrom: 'projectId',
          keyTo: 'id',
        },
        metadata: {
          name: 'metadata',
          type: 'Metadata[]',
          model: 'Metadata',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'projecFolderId',
        },
      },
    };
  }
}
