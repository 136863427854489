import { Validators } from '@angular/forms';

export const ROW_FORM_KEY_TYPE_COLUMNS = [
  {
    fieldName: 'key',
    placeholder: 'SHARED.KEY',
    unique: true,
    validators: [
      {
        type: Validators.required,
        errorKey: 'required',
        errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
      }
    ]
  }
];
