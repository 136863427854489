import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { MODEL_ENDPOINT_LIST_CONFIG, CustomEndpointListFieldName } from '@rappider/shared/configs';
import { ProjectModelEndpoint, ProjectModelEndpointWithRelations, ProjectWithRelations } from '@rappider/rappider-sdk';
import { ActionResponse, } from '@rappider/rappider-components/utils';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { AddQueryParam, Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { DeleteProjectModelEndpoint } from 'libs/project/src/lib/states/project-model-endpoint-state/project-model-endpoint.actions';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'rappider-model-endpoint-list',
  templateUrl: './model-endpoint-list.component.html',
  styleUrls: ['./model-endpoint-list.component.scss']
})

export class ModelEndpointListComponent implements OnChanges {
  /* list config */
  MODEL_ENDPOINT_LIST_CONFIG = cloneDeep(MODEL_ENDPOINT_LIST_CONFIG);

  @Input() isLoading = false;
  @Input() activeProject: ProjectWithRelations;
  @Input() projectModelEndpoints: ProjectModelEndpointWithRelations;
  @Input() activeProjectModelId: string;

  constructor(
    private store: Store<any>
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeProjectModelId) {
      this.setProjectModelEndpointListConfig();
    }
  }

  setProjectModelEndpointListConfig() {
    this.MODEL_ENDPOINT_LIST_CONFIG.searchable = this.activeProjectModelId ? true : false;
    if (!this.activeProjectModelId) {
      this.MODEL_ENDPOINT_LIST_CONFIG.listActions = this.MODEL_ENDPOINT_LIST_CONFIG.listActions.filter(action => action.name !== 'createCustomEndpoint');
    }
    this.MODEL_ENDPOINT_LIST_CONFIG = { ...this.MODEL_ENDPOINT_LIST_CONFIG };
  }

  /**
   * filters deleted item from custom endpoints
   *
   * @param {string} deletedItemId
   * @memberof ModelEndpointListComponent
   */
  filterDeletedItem(deletedEndpoint: ProjectModelEndpoint) {
    const projectModel = this.projectModelEndpoints.find(projectModel => deletedEndpoint.modelId === projectModel.id);
    projectModel.children = projectModel.children.filter(child => child.id !== deletedEndpoint.id);
  }

  /**
  * navigate to the create custom endpoint page
  *
  * @memberof ModelEndpointListComponent
  */
  navigateToCreateEndpointPage(projectModelId: string) {
    this.store.dispatch(new AddQueryParam({ key: 'redirectURL', value: `projects/model-data-field-list/${projectModelId}?tab=endpoints` }));
    this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.CUSTOM_ENDPOINT_CREATE}/${projectModelId}` }));
  }

  onListActionClick(actionResponse: ActionResponse) {
    if (actionResponse.action.name === 'createCustomEndpoint') {
      this.navigateToCreateEndpointPage(this.activeProjectModelId);
    }
  }

  onColumnActionClick(actionResponse: ActionResponse) {
    const endpoint = <ProjectModelEndpoint>actionResponse.data;

    if (actionResponse.action.name === CustomEndpointListFieldName.DeleteCustomEndpoint) {
      this.store.dispatch(DeleteProjectModelEndpoint({ payload: { endpointId: endpoint.id, modelId: this.activeProjectModelId } }));
    } else if (actionResponse.action.name === CustomEndpointListFieldName.EditCustomEndpoint) {
      if (endpoint.isCustomEndpoint) {
        this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.CUSTOM_ENDPOINT_EDIT}/${endpoint.id}` }));
      } else {
        this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.ENDPOINT_DETAIL}/${endpoint.id}` }));
      }
    }
  }
}
