import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { FormsModule } from '@angular/forms';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { RappiderChatModule, RappiderLottiePlayerModule } from '@rappider/rappider-components';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { Route, RouterModule } from '@angular/router';
import { AuthGuard } from '@rappider/authentication/guards';
import { VerifyPersonGuard, ActiveProjectGuard } from '@rappider/shared/guards';
import { AIAssistantComponent } from './ai-assistant/ai-assistant.component';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { NzMessageServiceModule } from 'ng-zorro-antd/message';

const routes: Route[] = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: PATH_DEFINITIONS.AI.ASSISTANT,
      },
      {
        path: 'assistant',
        component: AIAssistantComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { noInnerContentPadding: true }
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NzInputModule,
    NzIconModule,
    NzButtonModule,
    FormsModule,
    NzRadioModule,
    RappiderLottiePlayerModule,
    NzToolTipModule,
    RappiderChatModule,
    NzButtonModule,
    NzMessageServiceModule
  ],
  declarations: [
    AIAssistantComponent
  ],
  exports: [
    AIAssistantComponent
  ]
})
export class AiIntegrationModule { }
