import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isActivePersonManager } from './selectors/is-active-person-manager.selector';

@Injectable({
  providedIn: 'root'
})
export class ProjectManagerGuard implements CanActivate {
  constructor(
    private store: Store<any>
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot): Observable<boolean> {
    console.log('PROJECT MANAGER GUARD');
    return this.store.select(<any>isActivePersonManager).pipe(
      map(isManager => isManager)
    );
  }

}
