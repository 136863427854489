import { createSelector } from '@ngrx/store';
import { NewUiDataUpdateFunction, UiDataEventWithRelations, UiDataStoreWithRelations } from '@rappider/rappider-sdk';
export const uiDataUpdateFunctionListDataSelector = createSelector(
  state => state['uiDataStore']?.data,
  state => state['uiDataEvent']?.data,
  state => state['uiDataUpdateFunction']?.data,
  (uiDataStores: UiDataStoreWithRelations[], uiDataEvents: UiDataEventWithRelations[], uiDataUpdateFunctions: NewUiDataUpdateFunction[]) => {
    const uiDataUpdateFunctionListData = uiDataStores.map(uiDataStore => {
      const storeId = uiDataStore.id;
      const relevantUpdateFunctions = uiDataUpdateFunctions.filter(uiDataUpdateFunction => uiDataUpdateFunction.uiDataStoreId === storeId);

      const uiDataUpdateFunctionsFiltered = relevantUpdateFunctions.map(uiDataUpdateFunction => {
        const eventData = uiDataEvents.find(event => event.id === uiDataUpdateFunction.uiDataEventId);
        if (!eventData) {
          return null; // If event data doesn't exist, skip this update function
        }
        return {
          ...uiDataUpdateFunction,
          dataEventName: eventData.name,
        };
      }).filter(Boolean); // Filter out null values

      return {
        ...uiDataStore,
        uiDataUpdateFunctions: uiDataUpdateFunctionsFiltered,
      };
    });

    return uiDataUpdateFunctionListData;
  }
);
