<section class="heading-area">
  <rappider-heading [content]="eventMode === EventMode.Create ? 'Create ' + workflowWrapperHeading.content : 'Edit ' + workflowWrapperHeading.content"
                    [type]="workflowWrapperHeading.type"></rappider-heading>
</section>

<div *ngIf="eventMode === EventMode.Create"
     [@inOutAnimation]
     class="event-create">
  <rappider-workflow-event-create [submitButtonLoading]="submitButtonLoading"
                                  (eventNameChange)="onEventNameChange($event)"
                                  (createWorkflowEvent)="onCreateWorkflowEventFormSubmit($event)"></rappider-workflow-event-create>
</div>

<div *ngIf="eventMode === EventMode.Edit"
     [@inOutAnimation]
     class="event-edit">
  <rappider-workflow-event-edit [workflowEvent]="workflowEvent"
                                [submitButtonLoadig]="submitButtonLoading"
                                (eventNameChange)="onEventNameChange($event)"
                                (editWorkflowEvent)="onEditWorkflowEventFormSubmit($event)"></rappider-workflow-event-edit>
</div>