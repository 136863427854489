export enum SelectMode {
  Single = 'default',
  Multiple = 'multiple',
  Tags = 'tags',
  OptionSelect = 'optionSelect'
}

export const SelectModeOptions = [
  {
    key: 'Single',
    value: 'default'
  },
  {
    key: 'Multiple',
    value: 'multiple'
  },
  {
    key: 'Tags',
    value: 'tags'
  },
  {
    key: 'OptionSelect',
    value: 'optionSelect'
  }
];
