import { createAction, createReducer, on, props } from '@ngrx/store';
import { MenuConfig } from '@rappider/rappider-components/utils';
import { DiagramTreeItemConnector } from '../../utils/interfaces/diagram-editor/diagram-tree-item-connector.interface';
import { DiagramTreeItem } from '../../utils/interfaces/diagram-editor/diagram-tree-item.interface';
import { WorkflowItemInDiagram } from '../../utils/workflow-item-in-diagram.interface';
import * as DiagramActions from './diagram.actions';
import * as UIWorkflowStepFunctionActions from 'libs/project/src/lib/states/ui-step-functions/ui-step-function.actions';
import { UiWorkflowStepFunctionWithRelations } from '@rappider/rappider-sdk';

export const diagramFeatureKey = 'diagram';

export interface DiagramState {
  activeItem: WorkflowItemInDiagram;
  diagramTree: DiagramTreeItem[];
  diagramTreeConnectors: DiagramTreeItemConnector[];
  workflowMenu: MenuConfig;
  loading: boolean;
  error: {
    timestamp?: number;
    key?: string;
    error?: unknown;
  };
}

export const initialState: DiagramState = {
  activeItem: null,
  diagramTree: null,
  diagramTreeConnectors: null,
  workflowMenu: null,
  loading: true,
  error: {}
};

const GetPreDTSourceAndTargetSchemaData = createAction(
  UIWorkflowStepFunctionActions.ActionTypes.GetPreDTSourceAndTargetSchemaData,
  props<{ payload: { uiStepFunction: UiWorkflowStepFunctionWithRelations } }>()
);

const GetPostDTSourceAndTargetSchemaData = createAction(
  UIWorkflowStepFunctionActions.ActionTypes.GetPostDTSourceAndTargetSchemaData,
  props<{ payload: { uiStepFunction: UiWorkflowStepFunctionWithRelations } }>()
);

export const reducer = createReducer(
  initialState,
  on(DiagramActions.SetActiveDiagramItem, (state, action) => ({
    ...state,
    activeItem: action.payload.activeItem,
  })),

  on(DiagramActions.BuildDiagramTree, (state, action) => ({
    ...state,
    loading: true
  })),

  on(DiagramActions.BuildDiagramTreeSuccessful, (state, action) => ({
    ...state,
    diagramTree: action.payload.diagramTree,
    diagramTreeConnectors: action.payload.connectors,
    loading: false
  })),

  on(DiagramActions.PopulateWorkflowMenu, (state, action) => ({
    ...state,
    loading: true
  })),

  on(DiagramActions.PopulateWorkflowMenuSuccessful, (state, action) => ({
    ...state,
    workflowMenu: action.payload.workflowMenu,
    loading: false
  })),

  on(DiagramActions.ResetStateToInitial, () => initialState),

  on(DiagramActions.ResetStateToInitialChangeProject, () => initialState),

  on(DiagramActions.DuplicateWorkflow, (state) => ({
    ...state,
    loading: true
  })),
  on(GetPreDTSourceAndTargetSchemaData, (state, action) => {
    if (state.activeItem?.item?.id === action.payload.uiStepFunction.id) {
      return {
        ...state,
        activeItem: {
          ...state.activeItem,
          item: action.payload.uiStepFunction
        }
      };
    } else {
      return state;
    }
  }),
  on(GetPostDTSourceAndTargetSchemaData, (state, action) => {
    if (state.activeItem?.item?.id === action.payload.uiStepFunction.id) {
      return {
        ...state,
        activeItem: {
          ...state.activeItem,
          item: action.payload.uiStepFunction
        }
      };
    } else {
      return state;
    }
  }),
  on(DiagramActions.DuplicateWorkflowSuccessful, (state) => ({
    ...state,
    loading: false
  })),
  on(DiagramActions.DuplicateWorkflowFailure, (state, action) => ({
    ...state,
    loading: false,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    }
  })),

);
