<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>

<section>
  <div class="add-project-script">
    <div class="form-container">
      <form nz-form
            nzLayout="horizontal"
            [formGroup]="addProjectScriptForm"
            (ngSubmit)="submitAddProjectScriptForm()">
        <!-- Type -->
        <nz-form-item>
          <nz-form-label [nzLg]="6"
                         [nzXs]="24">{{ 'SHARED.TYPE' | translate }}</nz-form-label>
          <nz-form-control [nzLg]="18"
                           [nzXs]="24"
                           [nzErrorTip]="errorTemplate">
            <rappider-select formControlName="type"
                             [options]="typeOptions"
                             (ngModelChange)="onTypeChange($event)">
            </rappider-select>
          </nz-form-control>
        </nz-form-item>

        <!-- Location -->
        <nz-form-item>
          <nz-form-label [nzLg]="6"
                         [nzXs]="24">{{ 'SHARED.LOCATION' | translate }}</nz-form-label>
          <nz-form-control [nzLg]="18"
                           [nzXs]="24"
                           [nzErrorTip]="errorTemplate">
            <rappider-select formControlName="location"
                             [options]="SCRIPT_LOCATION_OPTIONS">
            </rappider-select>
          </nz-form-control>
        </nz-form-item>

        <!-- Source Type -->
        <nz-form-item>
          <nz-form-label [nzLg]="6"
                         [nzXs]="24">
            {{ 'PROJECT_MODULE.ADD_PROJECT_SCRIPT_COMPONENT.SOURCE_TYPE' | translate }}
          </nz-form-label>
          <nz-form-control [nzLg]="18"
                           [nzXs]="24"
                           [nzErrorTip]="errorTemplate">
            <rappider-radio formControlName="sourceType"
                            [options]="SOURCE_TYPE_OPTIONS">
            </rappider-radio>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="sourceType === 'file'">
          <nz-form-label [nzLg]="6"
                         [nzXs]="24"
                         [nzNoColon]="true">
          </nz-form-label>
          <nz-form-control [nzLg]="18"
                           [nzXs]="24">
            <div class="file-button-group">
              <div class="button">
                <button nz-button
                        type="button"
                        [disabled]="true"
                        (click)="handleFileUploadVisibility(true)">
                  {{ 'PROJECT_MODULE.ADD_PROJECT_SCRIPT_COMPONENT.ADD_NEW_FILE' | translate }}
                </button>
              </div>
              <div class="button">
                <button nz-button
                        type="button"
                        (click)="handleFileSelectVisibility(true)">
                  {{ 'PROJECT_MODULE.ADD_PROJECT_SCRIPT_COMPONENT.SELECT_A_FILE_FROM_ASSETS' | translate }}
                </button>
              </div>
            </div>
            <div *ngIf="!selectedFile && addProjectScriptFormSubmitted"
                 class="error">
              <ng-container>
                <small>- {{ 'PROJECT_MODULE.ADD_PROJECT_SCRIPT_COMPONENT.SELECT_A_FILE' | translate }}</small>
              </ng-container>
            </div>
          </nz-form-control>
        </nz-form-item>

        <!-- ADD NEW FILE MODAL -->
        <rappider-modal *ngIf="activeProjectId && sourceType === 'file'"
                        [(visible)]="fileUploadModal.visible"
                        [title]="'PROJECT_MODULE.ADD_PROJECT_SCRIPT_COMPONENT.SELECT_EXISTING_FILE' | translate"
                        [okText]="'SHARED.ADD' | translate"
                        [cancelText]="'SHARED.CANCEL' | translate"
                        [fullScreen]="true"
                        (cancelClick)="handleFileUploadVisibility(false)"
                        (okClick)="onUploadFileSave()">
          <nz-form-label [nzLg]="24"
                         [nzXs]="24">
            {{ 'PROJECT_MODULE.ADD_PROJECT_SCRIPT_COMPONENT.SELECT_EXISTING_FILE' | translate }}
          </nz-form-label>
          <nz-form-control [nzSm]="24"
                           [nzXs]="24">
            <rappider-spin [spinning]="false">
              <ng-container *ngIf="!selectedFile?.title; else fileNameTmpl">
                <rappider-upload-file (fileUpload)="uploadFile($event)"></rappider-upload-file>
              </ng-container>

              <ng-template #fileNameTmpl>
                <section class="uploaded-file-area">
                  <rappider-label [content]="selectedFile.title"></rappider-label>
                  <rappider-button [text]="'Remove'"
                                   (click)="onFileDelete()"></rappider-button>
                </section>
              </ng-template>
            </rappider-spin>
          </nz-form-control>
        </rappider-modal>

        <!-- TITLE AND CODE MIRROR FOR CUSTOM SCRIPT -->
        <ng-container *ngIf="content.visible">
          <nz-form-item>
            <nz-form-label [nzLg]="6"
                           [nzXs]="24">{{ 'SHARED.TITLE' | translate }}</nz-form-label>
            <nz-form-control [nzLg]="18"
                             [nzXs]="24">
              <rappider-textbox formControlName="title"></rappider-textbox>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzLg]="6"
                           [nzXs]="24">{{ 'SHARED.CONTENT' | translate }}</nz-form-label>
            <nz-form-control [nzLg]="24"
                             [nzXs]="24"
                             [nzErrorTip]="errorTemplate">
              <rappider-spin [spinning]="!content.codemirrorVisible">
                <rappider-code-editor *ngIf="content.codemirrorVisible"
                                      formControlName="content"
                                      [settings]="contentCodemirrorSettings"></rappider-code-editor>
              </rappider-spin>
            </nz-form-control>
          </nz-form-item>
        </ng-container>

        <!-- FILE SELECT MODAL -->
        <rappider-modal *ngIf="activeProjectId"
                        [(visible)]="fileModal.visible"
                        [title]="'SHARED.FILES' | translate"
                        [footer]="null"
                        [fullScreen]="true">
          <rappider-spin [spinning]="false">
            <rappider-file-list formControlName="file"
                                [projectId]="activeProjectId"
                                [externalScriptType]="type"
                                (fileSelect)="onFileSelect($event)"></rappider-file-list>
          </rappider-spin>
        </rappider-modal>

        <!-- SELECTED FILE -->
        <nz-form-item *ngIf="selectedFile && isFileSubmitted">
          <nz-form-label [nzLg]="6"
                         [nzXs]="24"
                         [nzNoColon]="true">
          </nz-form-label>
          <div class="file-label">
            <rappider-input-label [title]="selectedFile.title"></rappider-input-label>
          </div>
          <div class="file-delete-button">
            <button nz-button
                    type="button"
                    nzType="default"
                    nzShape="circle"
                    (click)="onFileDelete()">
              <i class="far fa-trash"></i>
            </button>
          </div>
        </nz-form-item>

        <!-- SUBMIT BUTTON -->
        <nz-form-item>
          <nz-form-label [nzLg]="6"
                         [nzXs]="24"
                         [nzNoColon]="true">
          </nz-form-label>
          <nz-form-control [nzLg]="6"
                           [nzXs]="24">
            <div class="button">
              <button nz-button
                      type="submit"
                      nzType="primary">
                {{ 'SHARED.SUBMIT' | translate }}
              </button>
            </div>
          </nz-form-control>
        </nz-form-item>
        <ng-template #errorTemplate>
          <small>{{ 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE' | translate }}</small>
        </ng-template>
      </form>
    </div>
  </div>
</section>