/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { NewUiWorkflowStepFunctionUiDataStoreSubscription } from '../models/new-ui-workflow-step-function-ui-data-store-subscription';
import { UiWorkflowStepFunctionUiDataStoreSubscription } from '../models/ui-workflow-step-function-ui-data-store-subscription';
import { UiWorkflowStepFunctionUiDataStoreSubscriptionPartial } from '../models/ui-workflow-step-function-ui-data-store-subscription-partial';
import { UiWorkflowStepFunctionUiDataStoreSubscriptionWithRelations } from '../models/ui-workflow-step-function-ui-data-store-subscription-with-relations';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class UiWorkflowStepFunctionUiDataStoreSubscriptionControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation uiWorkflowStepFunctionUiDataStoreSubscriptionControllerCount
   */
  static readonly UiWorkflowStepFunctionUiDataStoreSubscriptionControllerCountPath = '/ui-workflow-step-function-ui-data-store-subscriptions/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params?: {
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, UiWorkflowStepFunctionUiDataStoreSubscriptionControllerService.UiWorkflowStepFunctionUiDataStoreSubscriptionControllerCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params?: {
    where?: any;
  }): Observable<LoopbackCount> {

    return this.count$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation uiWorkflowStepFunctionUiDataStoreSubscriptionControllerFindById
   */
  static readonly UiWorkflowStepFunctionUiDataStoreSubscriptionControllerFindByIdPath = '/ui-workflow-step-function-ui-data-store-subscriptions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<UiWorkflowStepFunctionUiDataStoreSubscriptionWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, UiWorkflowStepFunctionUiDataStoreSubscriptionControllerService.UiWorkflowStepFunctionUiDataStoreSubscriptionControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UiWorkflowStepFunctionUiDataStoreSubscriptionWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
    filter?: any;
  }): Observable<UiWorkflowStepFunctionUiDataStoreSubscriptionWithRelations> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<UiWorkflowStepFunctionUiDataStoreSubscriptionWithRelations>) => r.body as UiWorkflowStepFunctionUiDataStoreSubscriptionWithRelations)
    );
  }

  /**
   * Path part for operation uiWorkflowStepFunctionUiDataStoreSubscriptionControllerDeleteById
   */
  static readonly UiWorkflowStepFunctionUiDataStoreSubscriptionControllerDeleteByIdPath = '/ui-workflow-step-function-ui-data-store-subscriptions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, UiWorkflowStepFunctionUiDataStoreSubscriptionControllerService.UiWorkflowStepFunctionUiDataStoreSubscriptionControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: string;
  }): Observable<any> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation uiWorkflowStepFunctionUiDataStoreSubscriptionControllerUpdateById
   */
  static readonly UiWorkflowStepFunctionUiDataStoreSubscriptionControllerUpdateByIdPath = '/ui-workflow-step-function-ui-data-store-subscriptions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    body?: UiWorkflowStepFunctionUiDataStoreSubscriptionPartial
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, UiWorkflowStepFunctionUiDataStoreSubscriptionControllerService.UiWorkflowStepFunctionUiDataStoreSubscriptionControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    body?: UiWorkflowStepFunctionUiDataStoreSubscriptionPartial
  }): Observable<any> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation uiWorkflowStepFunctionUiDataStoreSubscriptionControllerFind
   */
  static readonly UiWorkflowStepFunctionUiDataStoreSubscriptionControllerFindPath = '/ui-workflow-step-function-ui-data-store-subscriptions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<UiWorkflowStepFunctionUiDataStoreSubscriptionWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, UiWorkflowStepFunctionUiDataStoreSubscriptionControllerService.UiWorkflowStepFunctionUiDataStoreSubscriptionControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UiWorkflowStepFunctionUiDataStoreSubscriptionWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<UiWorkflowStepFunctionUiDataStoreSubscriptionWithRelations>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UiWorkflowStepFunctionUiDataStoreSubscriptionWithRelations>>) => r.body as Array<UiWorkflowStepFunctionUiDataStoreSubscriptionWithRelations>)
    );
  }

  /**
   * Path part for operation uiWorkflowStepFunctionUiDataStoreSubscriptionControllerCreate
   */
  static readonly UiWorkflowStepFunctionUiDataStoreSubscriptionControllerCreatePath = '/ui-workflow-step-function-ui-data-store-subscriptions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    body?: NewUiWorkflowStepFunctionUiDataStoreSubscription
  }): Observable<StrictHttpResponse<UiWorkflowStepFunctionUiDataStoreSubscription>> {

    const rb = new RequestBuilder(this.rootUrl, UiWorkflowStepFunctionUiDataStoreSubscriptionControllerService.UiWorkflowStepFunctionUiDataStoreSubscriptionControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UiWorkflowStepFunctionUiDataStoreSubscription>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    body?: NewUiWorkflowStepFunctionUiDataStoreSubscription
  }): Observable<UiWorkflowStepFunctionUiDataStoreSubscription> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<UiWorkflowStepFunctionUiDataStoreSubscription>) => r.body as UiWorkflowStepFunctionUiDataStoreSubscription)
    );
  }

}
