/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccessTokenWithRelations } from '../models/access-token-with-relations';
import { RevokeTokensRequestDto } from '../models/revoke-tokens-request-dto';

@Injectable({
  providedIn: 'root',
})
export class AccessTokenControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation accessTokenControllerDecodeJwt
   */
  static readonly AccessTokenControllerDecodeJwtPath = '/access-tokens/decode-jwt';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `decodeJWT()` instead.
   *
   * This method doesn't expect any request body.
   */
  decodeJWT$Response(params?: {
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, AccessTokenControllerService.AccessTokenControllerDecodeJwtPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `decodeJWT$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  decodeJWT(params?: {
  }): Observable<any> {

    return this.decodeJWT$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation accessTokenControllerRevokeTokens
   */
  static readonly AccessTokenControllerRevokeTokensPath = '/access-tokens/revoke-tokens';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `revokeTokens()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  revokeTokens$Response(params?: {
    body?: RevokeTokensRequestDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccessTokenControllerService.AccessTokenControllerRevokeTokensPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `revokeTokens$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  revokeTokens(params?: {
    body?: RevokeTokensRequestDto
  }): Observable<void> {

    return this.revokeTokens$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation accessTokenControllerRevokeAllUserTokens
   */
  static readonly AccessTokenControllerRevokeAllUserTokensPath = '/access-tokens/revoke-user-tokens/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `revokeAllUserTokens()` instead.
   *
   * This method doesn't expect any request body.
   */
  revokeAllUserTokens$Response(params: {
    userId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccessTokenControllerService.AccessTokenControllerRevokeAllUserTokensPath, 'delete');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `revokeAllUserTokens$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  revokeAllUserTokens(params: {
    userId: string;
  }): Observable<void> {

    return this.revokeAllUserTokens$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation accessTokenControllerFindByUserId
   */
  static readonly AccessTokenControllerFindByUserIdPath = '/access-tokens/user-tokens/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByUserId()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByUserId$Response(params: {
    userId: string;
  }): Observable<StrictHttpResponse<Array<AccessTokenWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, AccessTokenControllerService.AccessTokenControllerFindByUserIdPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccessTokenWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByUserId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByUserId(params: {
    userId: string;
  }): Observable<Array<AccessTokenWithRelations>> {

    return this.findByUserId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AccessTokenWithRelations>>) => r.body as Array<AccessTokenWithRelations>)
    );
  }

}
