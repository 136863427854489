export enum ProjectExportEventType {

  /* start project exportation */
  StartProjectExportation = 'start-project-exportation',

  /* export backend project events */
  ExportBackendProject = 'export-backend-project',
  ExportBackendProjectSuccessful = 'export-backend-project-successful',
  ExportBackendProjectFailure = 'export-backend-project-failure',

  /* export frontend project events */
  ExportFrontendProject = 'export-frontend-project',
  ExportFrontendProjectSuccessful = 'export-frontend-project-successful',
  ExportFrontendProjectFailure = 'export-frontend-project-failure',

  // #region Export Angular

  /* angular frontend project export events */
  ExportAngular = 'export-angular',
  ExportAngularSuccessful = 'export-angular-successful',
  ExportAngularFailure = 'export-angular-failure',

  /* update angular components events */
  AngularUpdateRappiderComponents = 'angular-update-rappider-components',
  AngularUpdateRappiderComponentsSuccessful = 'angular-update-rappider-components-successful',
  AngularUpdateRappiderComponentsFailure = 'angular-update-rappider-components-failure',

  /* copy angular components events */
  AngularCopyRappiderComponentsFolderToProject = 'angular-copy-rappider-components-folder-to-project',
  AngularCopyRappiderComponentsFolderToProjectSuccessful = 'angular-copy-rappider-components-folder-to-project-successful',
  AngularCopyRappiderComponentsFolderToProjectFailure = 'angular-copy-rappider-components-folder-to-project-failure',

  /* update angular rappider styles events  */
  AngularUpdateRappiderStyles = 'angular-update-rappider-styles',
  AngularUpdateRappiderStylesSuccessful = 'angular-update-rappider-styles-successful',
  AngularUpdateRappiderStylesFailure = 'angular-update-rappider-styles-failure',

  /* copy angular styles events */
  AngularCopyRappiderStylesFolderToProject = 'angular-copy-rappider-styles-folder-to-project',
  AngularCopyRappiderStylesFolderToProjectSuccessful = 'angular-copy-rappider-styles-folder-to-project-successful',
  AngularCopyRappiderStylesFolderToProjectFailure = 'angular-copy-rappider-styles-folder-to-project-failure',

  // #endregion

  // #region Export Loopback 4

  /* loopback4 backend project export events */
  ExportLoopback4 = 'export-loopback4',
  ExportLoopback4Successful = 'export-loopback4-successful',
  ExportLoopback4Failure = 'export-loopback4-failure',

  /* installing project dependencies events */
  Loopback4InstallProjectDependencies = 'loopback4-install-project-dependencies',
  Loopback4InstallProjectDependenciesSuccessful = 'loopback4-install-project-dependencies-successful',
  Loopback4InstallProjectDependenciesFailure = 'loopback4-install-project-dependencies-failure',

  /* generate open api spec file events */
  Loopback4GenerateOpenApiSpecFile = 'loopback4-generate-open-api-spec-file',
  Loopback4GenerateOpenApiSpecFileSuccessful = 'loopback4-generate-open-api-spec-file-successful',
  Loopback4GenerateOpenApiSpecFileFailure = 'loopback4-generate-open-api-spec-file-failure',

  /* uninstall project dependencies events */
  Loopback4UninstallProjectDependencies = 'loopback4-uninstall-project-dependencies',
  Loopback4UninstallProjectDependenciesSuccessful = 'loopback4-uninstall-project-dependencies-successful',
  Loopback4UninstallProjectDependenciesFailure = 'loopback4-uninstall-project-dependencies-failure',

  /* delete dist folder events */
  Loopback4DeleteDistFolder = 'loopback4-delete-dist-folder',
  Loopback4DeleteDistFolderSuccessful = 'loopback4-delete-dist-folder-successful',
  Loopback4DeleteDistFolderFailure = 'loopback4-delete-dist-folder-failure',

  /* generate angular sdk */
  Loopback4GenerateAngularSDK = 'loopback4-generate-angular-sdk',
  Loopback4GenerateAngularSDKSuccessful = 'loopback4-generate-angular-sdk-successful',
  Loopback4GenerateAngularSDKFailure = 'loopback4-generate-angular-sdk-failure',

  // #endregion

  // #region Export SQL Alchemy

  /* sqlalchemy backend project export events */
  ExportSQLAlchemy = 'export-sqlalchemy',
  ExportSQLAlchemySuccessful = 'export-sqlalchemy-successful',
  ExportSQLAlchemyFailure = 'export-sqlalchemy-failure',

  // #endregion

  /* format exported projects */
  FormatExportedProjects = 'format-exported-projects',
  FormatExportedProjectsSuccessful = 'format-exported-projects-successful',
  FormatExportedProjectsFailure = 'format-exported-projects-failure',

  /* project exportation status events */
  ProjectExportationSuccessful = 'project-exportation-successful',
  ProjectExportationFailure = 'project-exportation-failure',
}
