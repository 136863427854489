<rappider-title-toolbar [options]="title?.options"
                        [mainTitle]="title?.mainTitle"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton"></rappider-title-toolbar>

<section>
  <rappider-spin [spinning]="!(targetDataSchema && sourceDataSchema)">
    <rappider-data-mappings [(ngModel)]="dataMappings"
                            [targetDataSchema]="targetDataSchema"
                            [sourceDataSchema]="sourceDataSchema"
                            [functions]="functions"
                            [uiDataEvent]="uiDataEvent"
                            (dataMappingAdd)="addDataMapping($event)"
                            (dataMappingDelete)="deleteDataMapping($event)"></rappider-data-mappings>
  </rappider-spin>
</section>
