import { ButtonType } from '../../button';

export const buttonTypeSelectOptions = [
    {
        key: 'Default',
        value: ButtonType.Default
    },
    {
        key: 'Primary',
        value: ButtonType.Primary
    },
    {
        key: 'Dashed',
        value: ButtonType.Dashed
    },
    {
        key: 'Link',
        value: ButtonType.Link
    }
];
