<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton"></rappider-title-toolbar>

<section>
  <rappider-spin [spinning]="loading">
    <rappider-list-grid [config]="OPENAPI_LIST_CONFIG"
                        [data]="openApiData"
                        (listActionClick)="onListActionClick($event)"
                        (columnActionClick)="onColumnActionClick($event)"></rappider-list-grid>
  </rappider-spin>
</section>

<nz-modal [(nzVisible)]="isImportOpenApiSpecModalVisible"
          nzWidth="70%"
          [nzTitle]="'PROJECT_MODULE.OPENAPI_MODULE.IMPORT_OPENAPI_COMPONENT.IMPORT_OPENAPI_API' | translate"
          [nzOkText]="'SHARED.SAVE' | translate"
          [nzOkLoading]="loading"
          (nzOnCancel)="toggleImportOpenApiSpecModalVisibility()"
          (nzOnOk)="onSaveImportOpenApiData()">
  <ng-container *nzModalContent>
    <rappider-import-openapi #importOpenApiComponent></rappider-import-openapi>
  </ng-container>
</nz-modal>
