/* angular */
import { createAction, props } from '@ngrx/store';
import { ProjectDocument } from '@rappider/rappider-sdk';

/* action types */
export enum ActionTypes {
  GetProjectDocuments = '[ProjectDocument] GetProjectDocuments',
  GetProjectDocumentsSuccessful = '[ProjectDocument] GetProjectDocumentsSuccessful',
  GetProjectDocumentUpload = '[ProjectDocument] GetProjectDocumentUpload',
  GetProjectDocumentUploadSuccessful = '[ProjectDocument] GetProjectDocumentUploadSuccessful',
  GetProjectDocumentUploadFailure = '[ProjectDocument] GetProjectDocumentUploadFailure',
  CreateProjectDocument = '[ProjectDocument] CreateProjectDocument',
  CreateProjectDocumentSuccessful = '[ProjectDocument] CreateProjectDocumentSuccessful',
  CreateProjectDocumentUpload = '[ProjectDocument] CreateProjectDocumentUpload',
  CreateProjectDocumentUploadSuccessful = '[ProjectDocument] CreateProjectDocumentUploadSuccessful',
  CreateProjectDocumentUploadFailure = '[ProjectDocument] CreateProjectDocumentUploadFailure',
  UpdateProjectDocument = '[ProjectDocument] UpdateProjectDocument',
  UpdateProjectDocumentSuccessful = '[ProjectDocument] UpdateProjectDocumentSuccessful',
  DeleteProjectDocument = '[ProjectDocument] DeleteProjectDocument',
  DeleteProjectDocumentSuccessful = '[ProjectDocument] DeleteProjectDocumentSuccessful',
  ProjectDocumentModalVisibility = '[ProjectDocument] ProjectDocumentModalVisibility',
  ErrorAction = '[ProjectDocument] ErrorAction'
}

/* actions */

export const GetProjectDocuments = createAction(ActionTypes.GetProjectDocuments);

export const GetProjectDocumentsSuccessful = createAction(
  ActionTypes.GetProjectDocumentsSuccessful,
  props<{ payload: { projectDocuments: ProjectDocument[] } }>()
);

export const CreateProjectDocument = createAction(
  ActionTypes.CreateProjectDocument,
);

export const CreateProjectDocumentSuccessful = createAction(
  ActionTypes.CreateProjectDocumentSuccessful,
  props<{ payload: { projectDocuments: ProjectDocument[] } }>()
);

export const CreateProjectDocumentUpload = createAction(
  ActionTypes.CreateProjectDocumentUpload,
  props<{ payload: { projectDocuments: any } }>()
);

export const CreateProjectDocumentUploadSuccessful = createAction(
  ActionTypes.CreateProjectDocumentUploadSuccessful,
  props<{ payload: { projectDocuments: any } }>()
);

export const CreateProjectDocumentUploadFailure = createAction(
  ActionTypes.CreateProjectDocumentUploadFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const GetProjectDocumentUpload = createAction(
  ActionTypes.GetProjectDocumentUpload,
  props<{ payload: { id: string } }>()
);

export const GetProjectDocumentUploadSuccessful = createAction(
  ActionTypes.GetProjectDocumentUploadSuccessful,
  props<{ payload: { source: any } }>()
);

export const GetProjectDocumentUploadFailure = createAction(
  ActionTypes.GetProjectDocumentUploadFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const UpdateProjectDocument = createAction(
  ActionTypes.UpdateProjectDocument,
);

export const UpdateProjectDocumentSuccessful = createAction(
  ActionTypes.UpdateProjectDocumentSuccessful,
  props<{ payload: { projectDocuments: ProjectDocument[] } }>()
);

export const DeleteProjectDocument = createAction(
  ActionTypes.DeleteProjectDocument,
  props<{ payload: { projectDocumentId: string } }>()
);

export const DeleteProjectDocumentSuccessful = createAction(
  ActionTypes.DeleteProjectDocumentSuccessful,
  props<{ payload: { projectDocuments: ProjectDocument[] } }>()
);

export const ProjectDocumentModalVisibility = createAction(
  ActionTypes.ProjectDocumentModalVisibility,
  props<{ payload: { modalVisibility: boolean } }>()
);

export const ErrorAction = createAction(
  ActionTypes.ErrorAction,
  props<{ payload: { error: any; key: string; timestamp: number } }>()
);
