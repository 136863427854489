import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RappiderJsonInputComponent } from './json-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RappiderButtonModule } from '../button/button.module';
import { RappiderMonacoEditorModule } from './../monaco-editor/monaco-editor.module';


@NgModule({
  declarations: [
    RappiderJsonInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RappiderButtonModule,
    RappiderMonacoEditorModule
  ],
  exports: [
    RappiderJsonInputComponent
  ]
})
export class RappiderJsonInputModule { }
