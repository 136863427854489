<!-- loading skeleton -->
<section *ngIf="!page">
  <nz-skeleton [nzActive]="true"></nz-skeleton>
</section>
<div *ngIf="page">
  <rappider-internal-title-toolbar [mainTitle]="mainTitle"
                                   [options]="title"
                                   [displayToolbar]="displayToolbar"
                                   [displayToolbarBackButton]="displayToolbarBackButton">

  </rappider-internal-title-toolbar>

  <section>
    <div class="mb-3 mt-3">
      <nz-card>
        <h5>{{ page.title }}</h5>
        <hr>
        <div class="field-container"><span class="field">{{ 'SHARED.TITLE' | translate }}:</span> {{ page.title }}
        </div>
        <div class="field-container"><span class="field">{{ 'SHARED.PATH' | translate }}:</span> {{ page.slug }}</div>
        <div class="field-container"><span class="field">{{ 'SHARED.PUBLIC' | translate }}:</span> {{ (page.isPublic ?
          'SHARED.PUBLIC' : 'SHARED.SECRET') | translate}}</div>
        <div class="field-container"><span class="field">{{ 'SHARED.ACTIVE' | translate }}:</span> {{ (page.isActive ?
          'SHARED.ACTIVE' : 'SHARED.INACTIVE') | translate }}</div>
        <div *ngIf="page.description"
             class="field-container"><span class="field">{{ 'SHARED.DESCRIPTION' | translate }}:</span> {{
          page.description }}</div>
        <div class="field-container"><span class="field">
            {{ 'SHARED.KEYWORDS' | translate }}:</span>
          <span *ngFor="let keyword of page.keywords; let i = index">
            {{ keyword }}
            <span *ngIf="page.keywords?.length > (i + 1)">, </span>
          </span>
        </div>
        <div class="field-container"><span class="field">Page ID:</span> {{ page.id }}</div>
        <div class="field-container"
             *ngIf="page.projectId"><span class="field">Project ID:</span> {{ page.projectId }}</div>
        <div *ngIf="page.components?.length"
             class="field-container"><span class="field">
            {{ 'PAGES_MODULE.PAGES_DETAIL.PAGE_COMPONENTS_TITLE' | translate }}:</span>
          <span *ngFor="let pageComponent of page.components; let i = index">
            {{ pageComponent.name }}
            <span *ngIf="page.components?.length > (i + 1)">, </span>
          </span>
        </div>
      </nz-card>
    </div>
    <div class="row">
      <div class="col-md-6 mb-3"
           *ngFor="let component of page.components; ">
        <nz-card>
          <h5>{{ component.name }}</h5>
          <hr>
          <div class="field-container">
            <span class="field">
              {{ 'SHARED.INPUTS' | translate}}:</span>
            <pre>{{ component.inputs | json }}</pre>
          </div>
          <div class="field-container">
            <span class="field">
              {{ 'SHARED.INDEX' | translate}}:</span> {{ component.index }}
          </div>
          <div class="field-container">
            <span class="field">
              {{ 'SHARED.CREATED_DATE' | translate }}:</span> {{ component.createdDate | formatDate}}
          </div>
          <div class="field-container">
            <span class="field">
              {{ 'SHARED.CREATED_BY' | translate }}:
            </span> {{ component.createdBy }}
          </div>
          <div class="field-container">
            <span class="field">
              {{ 'SHARED.UPDATED_DATE' | translate }}:
            </span> {{ component.updatedDate | formatDate}}
          </div>
          <div class="field-container">
            <span class="field">
              {{ 'SHARED.UPDATED_BY' | translate }}:
            </span> {{ component.updatedBy }}
          </div>
        </nz-card>
        <nz-card>
          <h5>
            {{ 'PAGES_MODULE.PAGES_DETAIL.INPUTS_DEFINITIONS' | translate }}
          </h5>
          <hr>
          <nz-table #componentInputDefinitions
                    [nzData]="component.componentDefinition?.inputDefinitions"
                    [nzShowPagination]="false">
            <thead>
              <tr>
                <th>
                  {{ 'SHARED.CATEGORY' | translate }}
                </th>
                <th>
                  {{ 'SHARED.TITLE' | translate }}
                </th>
                <th>
                  {{ 'SHARED.FIELD_NAME' | translate }}
                </th>
                <th>
                  {{ 'SHARED.FIELD_TYPE' | translate }}
                </th>
                <th>
                  {{ 'SHARED.DESCRIPTION' | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let inputDefinition of componentInputDefinitions.data">
                <td>{{ inputDefinition.category }}</td>
                <td>{{ inputDefinition.fieldDefinition?.title }}</td>
                <td>{{ inputDefinition.fieldDefinition?.fieldName }}</td>
                <td>{{ inputDefinition.fieldDefinition?.fieldType.type }}</td>
                <td>{{ inputDefinition.description | json}}</td>
              </tr>
            </tbody>
          </nz-table>
        </nz-card>

        <nz-card>
          <h5>
            {{ 'PAGES_MODULE.PAGES_DETAIL.OUTPUTS_DEFINITIONS' | translate }}
          </h5>
          <hr>
          <nz-table #componentOutputsDefinitions
                    [nzData]="component.componentDefinition?.outputDefinitions"
                    [nzShowPagination]="false">
            <thead>
              <tr>
                <th> {{ 'SHARED.KEY' | translate }}</th>
                <th> {{ 'SHARED.TYPE_ID' | translate }}</th>
                <th> {{ 'SHARED.DESCRIPTION' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let outputDefinition of componentOutputsDefinitions.data">
                <td>{{ outputDefinition.key }}</td>
                <td>{{ outputDefinition.fieldTypeId }}</td>
                <td>{{ outputDefinition.description }}</td>
              </tr>
            </tbody>
          </nz-table>
        </nz-card>
      </div>
    </div>
  </section>
</div>
