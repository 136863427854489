import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { JsonValidationService, NotificationService } from '@rappider/services';
import { CUSTOM_FUNCTION_LIST_CONFIG, LABEL_CARDS_LIST_CONFIG } from '../../utils/custom-function-modal-wrapper.configs';
import { CardDataMapConfig, ListMode } from '@rappider/rappider-components';
import { ButtonComponentConfig, ButtonType, CrudFormConfig, HeadingType, ModalComponentConfig } from '@rappider/rappider-components/utils';
import { cloneDeep } from 'lodash';
import { CREATE_ITEM_TITLE, CUSTOM_FUNCTION_CREATE_OR_UPDATE_FORM_CONFIG, MODEL_NAME_SINGULAR } from '../../utils/custom-function.configs';
import { PROGRAMMING_LANGUAGES } from '../../utils/programming-languages';
import { CustomFunctionDefinition, CustomFunctionDefinitionPartial, CustomFunctionDefinitionWithRelations, NewCustomFunctionDefinition } from '@rappider/rappider-sdk';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { CreateCustomFunction, DeleteCustomFunction, UpdateCustomFunction } from '../../state/custom-function.actions';
import { WorkflowFunctionTypeOptions } from 'libs/workflow-step-function/src/lib/components/workflow-step-function-form/utils/workflow-functions-options.enum';
import { getWorkflowFunctionsAndTemplates } from './utils/get-custom-functions-and-templates-selector';
@Component({
  selector: 'rappider-custom-function-crud-modal-wrapper',
  templateUrl: './custom-function-crud-modal-wrapper.component.html',
  styleUrls: ['./custom-function-crud-modal-wrapper.component.scss']
})
export class CustomFunctionCrudModalWrapperComponent implements OnInit, OnChanges, OnDestroy {

  @Input() activeCustomFunctionId: string;
  @Input() mode: string;

  @Output() activeCustomFunctionEmit = new EventEmitter<CustomFunctionDefinitionWithRelations>();

  activeCustomFunction: CustomFunctionDefinitionWithRelations;
  subscriptions: Subscription[];
  customFunctions: CustomFunctionDefinition[];
  templateWorkflowFunctions: CustomFunctionDefinition[];
  displayedFunctions: CustomFunctionDefinitionWithRelations[];
  customFunctionModalVisibility: boolean;
  modalConfig: ModalComponentConfig = {
    okText: 'Select',
    closeIconVisibility: true,
    fullScreen: true,
    title: 'Service & Workflow Functions Management'
  };
  buttonConfig: ButtonComponentConfig = {
    text: 'Select Workflow Function From Library'
  };
  programmingLanguages = PROGRAMMING_LANGUAGES;
  modelNameSingular = '';
  createItemTitle = '';
  mainTitleConfig = { content: 'Rappider Workflow Functions', type: HeadingType.H3 };
  isCustomFunctionsLoading = true;
  ListMode = ListMode.Card;
  selectedCustomFunctionName: string;
  showCreateButton = false;
  showCreateButtonEmit = true;
  showEditButtonEmit = true;
  displayToolbar = false;
  isListGridActionButtonsEmit = true;
  footerButtonConfig: ButtonComponentConfig = {
    text: 'Go Back',
    type: ButtonType.Default
  };

  /* Crud configs */
  listGridConfig = CUSTOM_FUNCTION_LIST_CONFIG;
  cardListConfig: CardDataMapConfig = LABEL_CARDS_LIST_CONFIG;
  createItemFormConfig: CrudFormConfig = cloneDeep(CUSTOM_FUNCTION_CREATE_OR_UPDATE_FORM_CONFIG);
  updateItemFormConfig: CrudFormConfig = cloneDeep(CUSTOM_FUNCTION_CREATE_OR_UPDATE_FORM_CONFIG);

  crudVisibility: boolean;
  createVisibility: boolean;
  editVisibility: boolean;
  isGoBackModalFooter: boolean;
  WorkflowFunctionTypeOptions = WorkflowFunctionTypeOptions;

  constructor(
    private store: Store<any>,
    private translateService: TranslateService,
    private jsonValidationService: JsonValidationService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
    this.setTranslatedTitles();
    this.changeCustomFunction();
    this.handleConfigWithSelectedId();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeCustomFunctionId) {
      if (this.customFunctions && this.templateWorkflowFunctions) {
        this.handleDisplayedFunctions();
        this.changeCustomFunction();
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToWorkflowFunctionsAndTemplates(),
      this.subscribeToCustomFunctionsLoading(),
      this.subscribeToLastCreatedOrEditedCustomFunction()
    ];
  }

  subscribeToWorkflowFunctionsAndTemplates() {
    return this.store.select(<any>getWorkflowFunctionsAndTemplates).subscribe((data) => {
      this.customFunctions = data.workflowFunctions;
      this.templateWorkflowFunctions = data.templateWorkflowFunctions;
      this.handleDisplayedFunctions();
    });
  }

  handleDisplayedFunctions() {
    if (this.mode && this.mode === WorkflowFunctionTypeOptions.RappiderServices) {
      this.templateWorkflowFunctions = this.templateWorkflowFunctions.map((templateWorkflowFunction) => {
        const languageKey = this.programmingLanguages.find(language => language.value === templateWorkflowFunction.programmingLanguage);
        templateWorkflowFunction = {
          ...templateWorkflowFunction,
          programmingLanguage: languageKey?.key
        };
        return templateWorkflowFunction;
      });
      this.templateWorkflowFunctions = this.templateWorkflowFunctions.map(conf => ({
        ...conf,
        selected: false
      }));
      this.templateWorkflowFunctions = [...this.templateWorkflowFunctions];
      this.displayedFunctions = this.templateWorkflowFunctions;
    } else if (this.mode && this.mode === WorkflowFunctionTypeOptions.WorkflowFunctions) {
      this.customFunctions = this.customFunctions.map((customFunction) => {
        const languageKey = this.programmingLanguages.find(language => language.value === customFunction.programmingLanguage);
        customFunction = {
          ...customFunction,
          programmingLanguage: languageKey?.key
        };
        return customFunction;
      });
      this.customFunctions = this.customFunctions.map(conf => ({
        ...conf,
        selected: false
      }));
      this.customFunctions = [...this.customFunctions];
      this.displayedFunctions = this.customFunctions;
    }
    if (this.activeCustomFunctionId) {
      this.activeCustomFunction = this.displayedFunctions.find(displayedFunction => displayedFunction.id === this.activeCustomFunctionId);
      this.activeCustomFunction = {
        ...this.activeCustomFunction as CustomFunctionDefinitionWithRelations,
        code: this.activeCustomFunction?.code as string,
        requestJSONSample: JSON.stringify(this.activeCustomFunction?.requestJSONSample),
        responseJSONSample: JSON.stringify(this.activeCustomFunction?.responseJSONSample)
      };
    }
  }

  subscribeToCustomFunctionsLoading() {
    return this.store.select(state => state.customFunction?.isLoading).subscribe((isLoading: boolean) => {
      this.isCustomFunctionsLoading = isLoading;
    });
  }

  subscribeToLastCreatedOrEditedCustomFunction() {
    return this.store.select(state => state.customFunction.lastCreatedOrEdited).subscribe((customFunction) => {
      if (customFunction) {
        this.activeCustomFunctionId = !this.activeCustomFunctionId ? customFunction?.id : this.activeCustomFunctionId;
        this.handleConfigWithSelectedId();
      }
    });
  }

  changeCustomFunction() {
    this.activeCustomFunction = this.displayedFunctions?.find(displayedFunction => displayedFunction.id === this.activeCustomFunctionId);
    this.handleConfigWithSelectedId();
    this.activeCustomFunctionEmit.emit(this.activeCustomFunction);
    if (this.activeCustomFunction?.id) {
      this.buttonConfig.text = 'Change';
      this.selectedCustomFunctionName = `${this.activeCustomFunction?.serviceName}.${this.activeCustomFunction?.functionName}`;
    } else {
      this.buttonConfig.text = this.mode === WorkflowFunctionTypeOptions.WorkflowFunctions ? 'Select Workflow Function From Library' : 'Select Rappider Service From Library';
      this.selectedCustomFunctionName = '';
    }
  }

  onSelectCustomFunction() {
    this.changeCustomFunction();
    this.closeCustomFunctionCrudListModal();
  }

  onCloseModalAndClearSelectedCustomFunction() {
    this.closeCustomFunctionCrudListModal();
    if (!this.displayedFunctions.some(displayedFunction => displayedFunction.id === this.activeCustomFunction?.id)) {
      this.activeCustomFunctionId = null;
      this.changeCustomFunction();
    }
  }

  onOpenCustomFunctionCrudListModal() {
    this.customFunctionModalVisibility = true;
    this.crudVisibility = true;
    this.isGoBackModalFooter = false;
    this.mainTitleConfig.content = this.mode === WorkflowFunctionTypeOptions.WorkflowFunctions ? 'Rappider Workflow Functions' : 'Rappider Workflow Services';
  }

  closeCustomFunctionCrudListModal() {
    this.customFunctionModalVisibility = false;
    this.crudVisibility = false;
    this.createVisibility = false;
    this.editVisibility = false;
    this.isGoBackModalFooter = false;
    this.mainTitleConfig.content = this.mode === WorkflowFunctionTypeOptions.WorkflowFunctions ? 'Rappider Workflow Functions' : 'Rappider Workflow Services';
  }

  setTranslatedTitles() {
    this.createItemTitle = this.translateService.instant(CREATE_ITEM_TITLE);
    this.modelNameSingular = this.translateService.instant(MODEL_NAME_SINGULAR);
  }

  onListCustomFunctionSelected(selectedCustomFunction) {
    this.displayedFunctions.forEach(displayedFunction => displayedFunction.selected = false);
    if (this.activeCustomFunctionId === selectedCustomFunction?.id) {
      this.activeCustomFunctionId = null;
    } else {
      this.activeCustomFunctionId = selectedCustomFunction?.id;
      this.handleConfigWithSelectedId();
    }
  }

  handleConfigWithSelectedId() {
    const selectedTemplateConfig = this.displayedFunctions.find(displayedFunction => displayedFunction?.id === this.activeCustomFunctionId);
    if (selectedTemplateConfig) {
      selectedTemplateConfig.selected = true;
    }
  }

  onTitleBarActionButtonClicked(clickedActionButton) {
    if (clickedActionButton.key === 'create-item') {
      this.crudVisibility = false;
      this.createVisibility = true;
      this.isGoBackModalFooter = true;
      this.mainTitleConfig.content = 'Create Workflow Function';
    }
  }

  onCreateCustomFunctionFormSubmit(newCustomFunction: any) {
    const requestJSONSampleData = this.jsonValidationService.validateStringifiedJson(newCustomFunction.requestJSONSample, true);
    const responseJSONSampleData = this.jsonValidationService.validateStringifiedJson(newCustomFunction.responseJSONSample, true);

    if (!requestJSONSampleData.isJsonValid || !responseJSONSampleData.isJsonValid) {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'Wrong JSON format'
      );
    } else {
      const createBody: NewCustomFunctionDefinition = {
        ...newCustomFunction,
        requestJSONSample: requestJSONSampleData.dataAsJson,
        responseJSONSample: responseJSONSampleData.dataAsJson
      };
      this.store.dispatch(CreateCustomFunction({ customFunction: createBody, navigateAfterSubmit: false }));
      this.createVisibility = false;
      this.crudVisibility = true;
      this.isGoBackModalFooter = false;
      this.mainTitleConfig.content = this.mode === WorkflowFunctionTypeOptions.WorkflowFunctions ? 'Rappider Workflow Functions' : 'Rappider Workflow Services';
    }
  }

  onListCustomFunctionActionClicked(clickedActionButton) {
    if (clickedActionButton.action.name === 'edit-item') {
      this.activeCustomFunctionId = clickedActionButton.data.id;
      this.crudVisibility = false;
      this.editVisibility = true;
      this.isGoBackModalFooter = true;
      this.mainTitleConfig.content = 'Edit Workflow Function';
    }
  }

  onUpdateCustomFunctionFormSubmit(updatedCustomFunction: any) {
    if (updatedCustomFunction && this.activeCustomFunctionId) {
      const requestJSONSchemaData = this.jsonValidationService.validateStringifiedJson(updatedCustomFunction.requestJSONSample, true);
      const responseJSONSchemaData = this.jsonValidationService.validateStringifiedJson(updatedCustomFunction.responseJSONSample, true);

      if (!requestJSONSchemaData.isJsonValid || !responseJSONSchemaData.isJsonValid) {
        this.notificationService.createNotification(
          'error',
          'SHARED.ERROR',
          'Wrong JSON format'
        );
      } else {
        const updateBody: CustomFunctionDefinitionPartial = {
          ...updatedCustomFunction,
          requestJSONSample: requestJSONSchemaData.dataAsJson,
          responseJSONSample: responseJSONSchemaData.dataAsJson
        };
        this.store.dispatch(UpdateCustomFunction({ id: this.activeCustomFunctionId as string, customFunction: updateBody, navigateAfterSubmit: false }));
        this.editVisibility = false;
        this.crudVisibility = true;
        this.isGoBackModalFooter = false;
        this.mainTitleConfig.content = this.mode === WorkflowFunctionTypeOptions.WorkflowFunctions ? 'Rappider Workflow Functions' : 'Rappider Workflow Services';
      }
    }
  }

  onCustomFunctionDeleted(clickedActionButton) {
    if (clickedActionButton.action.name === 'delete-item') {
      this.store.dispatch(DeleteCustomFunction({ id: clickedActionButton.data.id }));
      this.activeCustomFunctionId = null;
    }
  }

  onGoBackToCrudListOfCustomFunctions() {
    this.editVisibility = false;
    this.createVisibility = false;
    this.crudVisibility = true;
    this.isGoBackModalFooter = false;
    this.mainTitleConfig.content = this.mode === WorkflowFunctionTypeOptions.WorkflowFunctions ? 'Rappider Workflow Functions' : 'Rappider Workflow Services';
  }
}
