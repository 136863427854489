/* eslint-disable */
import { ProjectRole, Person } from '../index';

declare var Object: any;
export interface PersonProjectRoleInterface {
  id?: string;
  projectRoleId?: string;
  personId?: string;
  projectRole?: ProjectRole;
  person?: Person;
}

export class PersonProjectRole implements PersonProjectRoleInterface {
  'id'?: string;
  'projectRoleId': string;
  'personId': string;
  projectRole: ProjectRole;
  person: Person;
  constructor(data?: PersonProjectRoleInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PersonProjectRole`.
   */
  public static getModelName() {
    return 'PersonProjectRole';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of PersonProjectRole for dynamic purposes.
   **/
  public static factory(data: PersonProjectRoleInterface): PersonProjectRole {
    return new PersonProjectRole(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'PersonProjectRole',
      plural: 'PersonProjectRoles',
      path: 'person-project-roles',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        projectRoleId: {
          name: 'projectRoleId',
          type: 'string',
        },
        personId: {
          name: 'personId',
          type: 'string',
        },
      },
      relations: {
        projectRole: {
          name: 'projectRole',
          type: 'ProjectRole',
          model: 'ProjectRole',
          relationType: 'belongsTo',
          keyFrom: 'projectRoleId',
          keyTo: 'id',
        },
        person: {
          name: 'person',
          type: 'Person',
          model: 'Person',
          relationType: 'belongsTo',
          keyFrom: 'personId',
          keyTo: 'id',
        },
      },
    };
  }
}
