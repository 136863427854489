import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RappiderNotificationComponent } from './notification.component';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { RappiderLottiePlayerModule } from '../lottie-player/lottie-player.module';
import { TranslateModule } from '@ngx-translate/core';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
  declarations: [
    RappiderNotificationComponent
  ],
  imports: [
    CommonModule,
    NzBadgeModule,
    NzDropDownModule,
    NzListModule,
    NzAvatarModule,
    RappiderLottiePlayerModule,
    TranslateModule,
    NzIconModule
  ],
  exports: [
    RappiderNotificationComponent
  ]
})
export class RappiderNotificationModule { }
