/* eslint-disable @typescript-eslint/no-use-before-define */
import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DataSchemaInterface } from '@rappider/api-sdk';
import { CODEMIRROR_JSON_SETTINGS } from '@rappider/shared/definitions';
import { RappiderCustomFunction } from '@rappider/shared/interfaces';

@Component({
  selector: 'rappider-custom-function',
  templateUrl: './custom-function.component.html',
  styleUrls: ['./custom-function.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFunctionComponent),
      multi: true
    }
  ]
})
export class CustomFunctionComponent implements OnChanges, ControlValueAccessor {

  @Input() parameters: [DataSchemaInterface[]];

  parametersName = '';

  codemirrorJsonSettings = CODEMIRROR_JSON_SETTINGS;

  initialValue: RappiderCustomFunction = {
    name: '',
    code: '',
    parameters: []
  };

  /**
   * locale value for codemirror component which helds code for rappider custom function
   *
   * @type {string}
   * @memberof CustomFunctionComponent
   */
  code: string;

  /**
   * locale value for function name
   *
   * @type {string}
   * @memberof CustomFunctionComponent
   */
  name: string;

  _value: RappiderCustomFunction;

  get value() {
    return this._value;
  }

  set value(value: RappiderCustomFunction) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
  }


  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: RappiderCustomFunction) {
    this._value = value || this.initialValue;
    this.setValues();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setLocaleCodeValue() {
    this.code = this.value?.code || '';
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.parameters) {
      this.getParametersNameAndParameters();
    }
  }

  setValues() {
    this.getParametersNameAndParameters();
    this.setLocaleCodeValue();
  }

  /**
   * Gets parameters name to display => function(parameterName1,paramaterName2) and gets paramaters to equalize it to value.parameter
   *
   * @memberof CustomFunctionComponent
   */
  getParametersNameAndParameters() {
    let paramaterName = '';
    const parameters = [];
    let isFirstParameter = true;
    /* parameters variable is tree type. I iterate parameters variable to find every
     * parameter then set parameters name for visual display
     */
    this.parameters?.forEach(paramater => {
      paramater.forEach((item, index) => {
        /* if paramater.length === index + 1 it means that it is the selected paramater.
         *  Because paramater is helded in database like variable1.variable2.variable3 and we only needed variable3.
         */
        if (paramater.length === (index + 1)) {
          /* if paramater is first paramater we shouldn't add ',' so its checks that then add title of parameter */
          paramaterName = paramaterName + (!isFirstParameter ? ', ' : '') + item.name;
          /* push item to paramaters. */
          parameters.push(item);
        }
      });
      isFirstParameter = false;
    });
    this.parametersName = paramaterName;
    this.value = { ...this.value, parameters: parameters };
  }

  onValueChange() {
    this.value = { ...this.value };
  }
}
