import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjectInterface, ProjectPackageInterface } from '@rappider/api-sdk';
import { ActionResponse, BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Subscription } from 'rxjs';
import { DeleteProjectPackage, GetProjectPackages } from '../../states/project-package-state/project-package.actions';
import { PROJECT_PACKAGE_LIST_CONFIG_FOR_FRONTEND, PROJECT_PACKAGE_LIST_CONFIG_FOR_BACKEND } from './config/project-package-list-config';
import { ProjectPackagesList } from './utils/project-package-list.enum';
import { ProjectPackage, ProjectPackageWithRelations } from '@rappider/rappider-sdk';
import { ProjectPackagesCreateProjectType } from './utils/project-package-create-project-type.enum';
import { Navigate } from '@rappider/shared';

@Component({
  selector: 'rappider-project-package-list',
  templateUrl: './project-package-list.component.html',
  styleUrls: ['./project-package-list.component.scss']
})
export class ProjectPackageListComponent implements OnInit, OnDestroy {
  /* list config for frontend */
  PROJECT_PACKAGE_LIST_CONFIG_FOR_FRONTEND = PROJECT_PACKAGE_LIST_CONFIG_FOR_FRONTEND;
  /* list config for backend */
  PROJECT_PACKAGE_LIST_CONFIG_FOR_BACKEND = PROJECT_PACKAGE_LIST_CONFIG_FOR_BACKEND;
  PROJECT_PACKAGE_LIST_CONFIG = PROJECT_PACKAGE_LIST_CONFIG_FOR_FRONTEND;
  /* main title */
  mainTitle: HeadingComponentConfig;
  /* title */
  title: string | string[] | BreadcrumbOption[];
  /* subscriptions */
  subscriptions: Subscription[];
  /* project id */
  activeProjectId: string;
  /* project packages */
  projectPackagesForFrontend: ProjectPackageWithRelations[];
  projectPackagesForBackend: ProjectPackageWithRelations[];
  displayToolbar = false;
  displayToolbarBackButton = false;
  projectSettingTabs = [ProjectPackagesList.ProjectPackagesForFrontend, ProjectPackagesList.ProjectPackagesForBackend];
  isCreatePackageForBackend: boolean;
  projectPackageCreateModalVisibility: boolean;
  loading: boolean;

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToProjectPackages(),
      this.subscribeToProjectPackagesLoadingState()
    ];
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectInterface) => {
      if (activeProject) {
        this.mainTitle = {
          content: 'PROJECT_MODULE.PROJECT_PACKAGE_LIST_COMPONENT.PROJECT_PACKAGE_LIST',
          type: defaultToolbarTitleHeadingSize
        };
        this.title = [
          {
            label: activeProject.name,
            redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${activeProject?.id}`
          },
          {
            label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_PACKAGE_LIST.PAGE_TITLE
          }
        ];
        this.activeProjectId = activeProject.id;
      }
    });
  }

  subscribeToProjectPackages() {
    return this.store.select(state => state.projectPackage.data).subscribe((projectPackages: ProjectPackage[]) => {
      this.projectPackagesForFrontend = projectPackages.filter(projectpackage => projectpackage.projectType === ProjectPackagesCreateProjectType.Frontend);
      this.projectPackagesForBackend = projectPackages.filter(projectpackage => projectpackage.projectType === ProjectPackagesCreateProjectType.Backend);
    });
  }

  subscribeToProjectPackagesLoadingState() {
    return this.store.select(state => state.projectPackage.loading).subscribe((loading: boolean) => {
      this.loading = loading;
    });
  }

  onColumnActionClick(actionResponse: ActionResponse) {
    const projectPackage = <ProjectPackageInterface>actionResponse.data;

    if (actionResponse.action.name === 'editProjectPackage') {
      this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_PACKAGE_EDIT}/${projectPackage.id}` }));
    }
    if (actionResponse.action.name === 'DELETE_ITEM') {
      this.store.dispatch(new DeleteProjectPackage({ projectPackageId: projectPackage.id }));
    }
  }

  onTabChange(tab) {
    const tabTitle = tab.tab.nzTitle;
    if (tabTitle === ProjectPackagesList.ProjectPackagesForFrontend) {
      this.isCreatePackageForBackend = false;
      this.PROJECT_PACKAGE_LIST_CONFIG = this.PROJECT_PACKAGE_LIST_CONFIG_FOR_FRONTEND;
    }
    if (tabTitle === ProjectPackagesList.ProjectPackagesForBackend) {
      this.isCreatePackageForBackend = true;
      this.PROJECT_PACKAGE_LIST_CONFIG = this.PROJECT_PACKAGE_LIST_CONFIG_FOR_BACKEND;
    }
  }

  onListGridListActionDropdownItemClick() {
    this.projectPackageCreateModalVisibility = true;
  }

  onCloseProjectPackageCreateModalVisibilityChange(visibility) {
    this.projectPackageCreateModalVisibility = visibility;
  }
}
