export const PAGE_TEMPLATES_IMAGES = [
    '/assets/img/page-templates/profile-template1-thumbnail.png',
    '/assets/img/page-templates/profile-template3-thumbnail.png',
    '/assets/img/page-templates/home-page-thumbnail.png',
    '/assets/img/page-templates/blog-detail-thumbnail.png',
    '/assets/img/page-templates/blog-page-thumbnail.png',
    '/assets/img/page-templates/course-detail-thumbnail.png',
    '/assets/img/page-templates/course-list-thumbnail.png',
    '/assets/img/page-templates/document-detail-thumbnail.png',
    '/assets/img/page-templates/document-library-thumbnail.png',
    '/assets/img/page-templates/faq-thumbnail.png',
    '/assets/img/page-templates/instructor-history-thumbnail.png',
    '/assets/img/page-templates/instructor-page-thumbnail.png',
    '/assets/img/page-templates/student-history-thumbnail.png',
    '/assets/img/page-templates/video-detail-thumbnail.png',
    '/assets/img/page-templates/video-library-thumbnail.png',
    '/assets/img/page-templates/achievement-page-thumbnail.png',
    '/assets/img/page-templates/my-exams-thumbnail.png',
    '/assets/img/page-templates/my-surveys-thumbnail.png',
    '/assets/img/page-templates/calender-page-thumbnail.png',
    '/assets/img/page-templates/certificate-page-thumbnail.png',
    '/assets/img/page-templates/gamification-page-thumbnail.png',
    '/assets/img/page-templates/my-groups-thumbnail.png',
    '/assets/img/page-templates/learning-paths-thumbnail.png',
    '/assets/img/page-templates/my-reports-thumbnail.png',
    '/assets/img/page-templates/point-activity-thumbnail.png',
    '/assets/img/page-templates/error-page-thumbnail.png',
    '/assets/img/page-templates/edit-thumbnail.png',
    '/assets/img/page-templates/create-thumbnail.png',
    '/assets/img/page-templates/detail-thumbnail.png',
    '/assets/img/page-templates/list-thumbnail.png',
    '/assets/img/page-templates/step-5-thumbnail.png',
    '/assets/img/page-templates/step-3-thumbnail.png',
    '/assets/img/page-templates/step-4-thumbnail.png',
    '/assets/img/page-templates/step-2-thumbnail.png',
    '/assets/img/page-templates/step-1-thumbnail.png',
    '/assets/img/page-templates/step-3-empty-thumbnail.png',
    '/assets/img/page-templates/step-4-empty-thumbnail.png',
    '/assets/img/page-templates/step-2-empty-thumbnail.png',
    '/assets/img/page-templates/search-result-thumbnail.png',
    '/assets/img/page-templates/employer-thumbnail.png',
    '/assets/img/page-templates/apply-for-job-thumbnail.png',
    '/assets/img/page-templates/fg5-thumbnail.png',
    '/assets/img/page-templates/hero1-thumbnail.png',
    '/assets/img/page-templates/hero2-thumbnail.png',
    '/assets/img/page-templates/hero3-thumbnail.png',
    '/assets/img/page-templates/hero4-thumbnail.png',
    '/assets/img/page-templates/hero5-thumbnail.png',
    '/assets/img/page-templates/hero6-thumbnail.png',
    '/assets/img/page-templates/hero7-thumbnail.png',
    '/assets/img/page-templates/hero8-thumbnail.png',
    '/assets/img/page-templates/hero9-thumbnail.png',
    '/assets/img/page-templates/hero10-thumbnail.png',
    '/assets/img/page-templates/hero11-thumbnail.png',
    '/assets/img/page-templates/hero12-thumbnail.png',
    '/assets/img/page-templates/hero13-thumbnail.png',
    '/assets/img/page-templates/hero15-thumbnail.png',
    '/assets/img/page-templates/job-list-thumbnail.png',
    '/assets/img/page-templates/job-listing-thumbnail.png',
    '/assets/img/page-templates/job-listing-grid-thumbnail.png',
    '/assets/img/page-templates/fg1-thumbnail.png',
    '/assets/img/page-templates/fg2-thumbnail.png',
    '/assets/img/page-templates/fg3-thumbnail.png',
    '/assets/img/page-templates/fg4-thumbnail.png',
    '/assets/img/page-templates/fg5-thumbnail.png',
    '/assets/img/page-templates/fg6-thumbnail.png',
    '/assets/img/page-templates/fg7-thumbnail.png',
    '/assets/img/page-templates/fg8-thumbnail.png',
    '/assets/img/page-templates/fg9-thumbnail.png',
    '/assets/img/page-templates/cg1-thumbnail.png',
    '/assets/img/page-templates/cg2-thumbnail.png',
    '/assets/img/page-templates/cg3-thumbnail.png',
    '/assets/img/page-templates/cg4-thumbnail.png',
    '/assets/img/page-templates/cg5-thumbnail.png',
    '/assets/img/page-templates/cg6-thumbnail.png',
    '/assets/img/page-templates/cl2-thumbnail.png',
    '/assets/img/page-templates/cl3-thumbnail.png',
    '/assets/img/page-templates/cl7-thumbnail.png',
    '/assets/img/page-templates/fn1-thumbnail.png',
    '/assets/img/page-templates/fs2-thumbnail.png',
    '/assets/img/page-templates/fs3-thumbnail.png',
    '/assets/img/page-templates/fs4-thumbnail.png',
    '/assets/img/page-templates/fs5-thumbnail.png',
    '/assets/img/page-templates/landing-page-thumbnail.png',
    '/assets/img/page-templates/ib1-thumbnail.png',
    '/assets/img/page-templates/ib2-thumbnail.png',
    '/assets/img/page-templates/ib3-thumbnail.png',
    '/assets/img/page-templates/ib4-thumbnail.png',
    '/assets/img/page-templates/ib5-thumbnail.png',
    '/assets/img/page-templates/ib6-thumbnail.png',
    '/assets/img/page-templates/ib7-thumbnail.png',
    '/assets/img/page-templates/job-details-thumbnail.png',
    '/assets/img/page-templates/error-thumbnail.png',
    '/assets/img/page-templates/martfury-home-thumbnail.png',
    '/assets/img/page-templates/jobs-home-thumbnail.png',
    '/assets/img/page-templates/cg7-thumbnail.png',
    '/assets/img/page-templates/cl4-thumbnail.png',
    '/assets/img/page-templates/cl5-thumbnail.png',
    '/assets/img/page-templates/cl6-thumbnail.png',
    '/assets/img/page-templates/t1-thumbnail.png',
    '/assets/img/page-templates/t2-thumbnail.png',
    '/assets/img/page-templates/t3-thumbnail.png',
    '/assets/img/page-templates/t5-thumbnail.png',
    '/assets/img/page-templates/t6-thumbnail.png',
    '/assets/img/page-templates/pc1-thumbnail.png',
    '/assets/img/page-templates/pc2-thumbnail.png',
    '/assets/img/page-templates/ts1-thumbnail.png',
    '/assets/img/page-templates/cta1-thumbnail.png',
    '/assets/img/page-templates/gc1-thumbnail.png',
    '/assets/img/page-templates/blank-thumbnail.png',
    '/assets/img/page-templates/cc1-thumbnail.png',
    '/assets/img/page-templates/cc2-thumbnail.png',
    '/assets/img/page-templates/cc3-thumbnail.png',
    '/assets/img/page-templates/cc4-thumbnail.png',
    '/assets/img/page-templates/faq1-thumbnail.png',
    '/assets/img/page-templates/comments-thumbnail.png',
    '/assets/img/page-templates/up1-thumbnail.png',
    '/assets/img/page-templates/up2-thumbnail.png',
    '/assets/img/page-templates/sc1-thumbnail.png',
    '/assets/img/page-templates/cs1-thumbnail.png',
    '/assets/img/page-templates/cs2-thumbnail.png',
    '/assets/img/page-templates/cs3-thumbnail.png',
    '/assets/img/page-templates/cs4-thumbnail.png',
    '/assets/img/page-templates/cs5-thumbnail.png',
    '/assets/img/page-templates/cs6-thumbnail.png',
    '/assets/img/page-templates/cs7-thumbnail.png',
    '/assets/img/page-templates/cs8-thumbnail.png',
    '/assets/img/page-templates/Authentication-Component-1-thumbnail.png',
    '/assets/img/page-templates/Authentication-Component-2-thumbnail.png',
    '/assets/img/page-templates/Authentication-Component-3-thumbnail.png',
    '/assets/img/page-templates/Feedback-Component-1-thumbnail.png',
    '/assets/img/page-templates/Feedback-Component-2-thumbnail.png',
    '/assets/img/page-templates/Filter-Component-1-thumbnail.png',
    '/assets/img/page-templates/Contact-Sections-Component-1-thumbnail.png',
    '/assets/img/page-templates/Search-Component-1-thumbnail.png',
    '/assets/img/page-templates/Footer-Component-1-thumbnail.png',
    '/assets/img/page-templates/Footer-Component-2-thumbnail.png',
    '/assets/img/page-templates/Search-Result-Template-Thumbnail.png',
    '/assets/img/page-templates/Subscribe-Component-1-thumbnail.png',
    '/assets/img/page-templates/Subscribe-Component-2-thumbnail.png',
    '/assets/img/page-templates/Subscribe-Component-3-thumbnail.png',
    '/assets/img/page-templates/post-job-thumbnail.png',
    '/assets/img/page-templates/upload-resume-thumbnail.png',
    '/assets/img/page-templates/blog-detail-1-thumbnail.png',
    '/assets/img/page-templates/blog-detail-2-thumbnail.png',
    '/assets/img/page-templates/right-sidebar-thumbnail.png',
    '/assets/img/page-templates/small-thumb-thumbnail.png',
    '/assets/img/page-templates/listing-thumbnail.png',
    '/assets/img/page-templates/grid-thumbnail.png',
    '/assets/img/page-templates/left-side-bar-thumbnail.png',
    '/assets/img/page-templates/martfury-2-thumbnail.png',
    '/assets/img/page-templates/martfury-3-thumbnail.png',
    '/assets/img/page-templates/martfury-4-thumbnail.png',
    '/assets/img/page-templates/contact-page-thumbnail.png',
    '/assets/img/page-templates/vendor-dashboard-free-thumbnail.png',
    '/assets/img/page-templates/vendor-dashboard-pro-thumbnail.png',
    '/assets/img/page-templates/vendor-store-thumbnail.png',
    '/assets/img/page-templates/become-a-vendor-thumbnail.png',
    '/assets/img/page-templates/404-thumbnail.png',
    '/assets/img/page-templates/coming-soon-thumbnail.png',
    '/assets/img/page-templates/faq-2-thumbnail.png',
    '/assets/img/page-templates/about-us-thumbnail.png',
    '/assets/img/page-templates/product-detail-thumbnail.png',
    '/assets/img/page-templates/shop-default-thumbnail.png',
];
