import { CustomFunctionModule } from 'libs/custom-function/src/lib/custom-function.module';
/* angular imports */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
/* components */
import { CustomEndpointListComponent } from './components/custom-endpoint-list/custom-endpoint-list.component';
import { CustomEndpointCreateComponent } from './components/custom-endpoint-create/custom-endpoint-create.component';
import { CustomEndpointEditComponent } from './components/custom-endpoint-edit/custom-endpoint-edit.component';
/* internal modules */
import {
  RappiderTitleModule,
  RappiderEditFormModule,
  RappiderListGridModule,
  RappiderParagraphModule,
  RappiderHeadingModule,
  RappiderTitleToolbarModule,
  RappiderSpinModule,
  RappiderButtonModule,
  RappiderDividerModule,
  RappiderInputGroupModule,
  RappiderInternalTitleToolbarModule
} from '@rappider/rappider-components';
/* guards */
import { AuthGuard } from '@rappider/authentication/guards';
import { ActiveProjectGuard, PersonGuard, VerifyPersonGuard } from '@rappider/shared/guards';
import { PAGE_DEFINITIONS } from '@rappider/shared/definitions';
import { EndpointDetailComponent } from './components/endpoint-detail/endpoint-detail.component';
/* external modules */
import { TranslateModule } from '@ngx-translate/core';
import { ModelEndpointListComponent } from './components/model-endpoint-list/model-endpoint-list.component';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';


const routes: Routes = [
  {
    path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.CUSTOM_ENDPOINT_CREATE.URL}/:projectModelId`,
    component: CustomEndpointCreateComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, PersonGuard, VerifyPersonGuard],
    data: { title: 'Create Endpoint' }
  },
  {
    path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.CUSTOM_ENDPOINT_EDIT.URL}/:id`,
    component: CustomEndpointEditComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, PersonGuard, VerifyPersonGuard],
    data: { title: 'Update Endpoint' }
  },
  {
    path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.ENDPOINT_DETAIL.URL}/:id`,
    component: EndpointDetailComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, PersonGuard, VerifyPersonGuard],
    data: { title: 'Endpoint Details' }
  },
  {
    path: 'projects/endpoints',
    component: CustomEndpointListComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, PersonGuard, VerifyPersonGuard],
    data: { title: 'API Endpoints' }
  }
];

@NgModule({
  declarations: [
    CustomEndpointListComponent,
    CustomEndpointCreateComponent,
    CustomEndpointEditComponent,
    EndpointDetailComponent,
    ModelEndpointListComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    RappiderTitleModule,
    RappiderInternalTitleToolbarModule,
    RappiderEditFormModule,
    RappiderListGridModule,
    RappiderParagraphModule,
    RappiderHeadingModule,
    RappiderTitleToolbarModule,
    RappiderSpinModule,
    NzTableModule,
    RappiderButtonModule,
    RappiderDividerModule,
    NzCollapseModule,
    RappiderInputGroupModule,
    FormsModule,
    ReactiveFormsModule,
    NzSkeletonModule,
    CustomFunctionModule
  ],
  exports: [
    RouterModule,
    CustomEndpointListComponent,
    CustomEndpointCreateComponent,
    CustomEndpointEditComponent,
    EndpointDetailComponent,
    ModelEndpointListComponent
  ]
})
export class CustomEndpointModule { }
