import { Component, Input } from '@angular/core';
import {
  AvatarSize, HeadingComponentConfig, HeadingType, ImageComponentConfig,
  ProgressStatus, ProgressType, TextComponentConfig
} from '@rappider/rappider-components/utils';

@Component({
  selector: 'rappider-example-template-one',
  templateUrl: './example-template-one.component.html',
  styleUrls: ['./example-template-one.component.scss']
})
export class ExampleTemplateOneComponent {

  @Input() showTooltip = true;

  current = 0;

  heading: HeadingComponentConfig = {
    content: 'LOREM',
    type: HeadingType.H1
  };

  paragraph: TextComponentConfig = {
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis pulvinar lorem in facilisis viverra. Aenean a nulla sed.'
  };

  imageComponentConfig: ImageComponentConfig = {
    source: 'https://images.unsplash.com/photo-1599009434802-ca1dd09895e7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
    customSizeSettings: {
      width: '75%',
      height: '100%'
    }
  };

  stepsComponentConfig = {
    steps: [
      { title: '' },
      { title: '' },
      { title: '' }
    ]
  };

  cardFourComponentConfigs = [
    {
      avatar: {
        size: AvatarSize.Large,
        icon: {
          name: 'fa-solid fa-cat',
          type: 'FONT_AWESOME'
        }
      },
      title: 'Ide tortor ute eros neque mollis to'
    },
    {
      avatar: {
        size: AvatarSize.Large,
        icon: {
          name: 'fa-solid fa-cat',
          type: 'FONT_AWESOME'
        }
      },
      title: 'Sed to lacus orci, tincidunt  dui vel porta'
    },
    {
      avatar: {
        size: AvatarSize.Large,
        icon: {
          name: 'fa-solid fa-cat',
          type: 'FONT_AWESOME'
        }
      },
      title: ' Proin faucibus leo non aliquet volutpat'
    }
  ];

  reviewResultComponentConfig = {
    title: {
      type: HeadingType.H2,
      content: 'Fames'
    },
    progresses: [
      {
        percent: 100,
        status: ProgressStatus.Normal,
        type: ProgressType.Line,
        showInfo: true,
      },
      {
        percent: 75,
        status: ProgressStatus.Normal,
        type: ProgressType.Line,
        showInfo: true,
      },
      {
        percent: 50,
        status: ProgressStatus.Normal,
        type: ProgressType.Line,
        showInfo: true,
      },
      {
        percent: 25,
        status: ProgressStatus.Normal,
        type: ProgressType.Line,
        showInfo: true,
      }
    ]
  };

  listGridComponentConfig = {
    data: [
      {
        name: 'Jane Doe',
        age: 20,
        address: 'London No. 1 Lake Park'
      },
      {
        name: 'Sarah Holland',
        age: 21,
        address: 'London No. 1 Lake Park'
      },
      {
        name: 'John Lawrence',
        age: 22,
        address: 'London No. 1 Lake Park'
      },
      {
        name: 'Jacop Gomez',
        age: 23,
        address: 'London No. 1 Lake Park'
      }
    ],
    config: {
      columns: [
        {
          title: 'Name',
          fieldName: 'name',
          type: 'text'
        },
        {
          title: 'Age',
          fieldName: 'age',
          type: 'number'
        },
        {
          title: 'Address',
          fieldName: 'address',
          type: 'text'
        }
      ],
      showPagination: true,
      pageSize: 2,
      nzFrontPagination: true
    }
  };

  progressComponentConfig = {
    percent: 75,
    successPercent: 5,
    showInfo: true,
    status: ProgressStatus.Normal,
    strokeWidth: 6,
    width: 150,
    type: ProgressType.Circle
  };

  increaseStep() {
    this.current++;
  }

  decreaseStep() {
    this.current--;
  }
}
