import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'apps/rappider/src/environments/environment';
import { NPMPackage } from '../../models/npm-package-model';

@Injectable({
  providedIn: 'root'
})
export class NpmPackageSearchService {

  constructor(
    private http: HttpClient
  ) { }

  getNpmPackages(searchValue: string) {
    const options = {
      params: { q: [searchValue] }
    };
    return this.http.get<NPMPackage[]>(environment.npmSearchApiUrl, options);
  }

  async getNpmPackageVersions(name: string) {
    const url = `https://registry.npmjs.org/${name}`;
    const res = await fetch(url);
    const data = await res.json();
    return data;
  }

  sortPackageVersions(packageVersions: any): any {
    // Filter out versions with hyphen ("-")
    const filteredVersions = Object.keys(packageVersions.versions).filter(version => !version.includes('-'));

    // Convert valid versions to array of objects
    const objectsArray = filteredVersions.map(version => Object.assign({ version }, packageVersions[version]));

    // Sorting the array of objects by version field in descending order
    objectsArray.sort(this.compareVersionsDesc);

    // Reconstruct the sorted object
    const sortedObject: any = {};
    objectsArray.forEach(obj => {
      sortedObject[obj.version] = obj;
    });

    return sortedObject;
  }

  compareVersionsDesc(a: { version: string }, b: { version: string }) {
    const versionA = a.version.split('.').map(Number);
    const versionB = b.version.split('.').map(Number);

    return versionB.reduce((acc, segmentB, index) => {
      const segmentA = versionA[index] || 0;
      if (acc === 0) {
        return segmentA === segmentB ? 0 : segmentA > segmentB ? -1 : 1;
      }
      return acc;
    }, 0);
  }
}
