import { Injectable } from '@angular/core';
import { Comment, CommentControllerService, CommentWithRelations } from '@rappider/rappider-sdk';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { CommentCategory } from 'libs/comment/src/lib/utils/comment-category.enum';

import * as CommentActions from './comment.actions';
import * as ContentEditorActions from 'libs/content-editor/src/lib/state/content-editor.actions';

@Injectable()
export class CommentEffects {
  constructor(
    private actions$: Actions,
    private commentApi: CommentControllerService,
  ) { }

  getComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CommentActions.GetComments
      ),
      switchMap(() => {
        const filter = {
          include: [
            {
              relation: 'replies',
              scope: {
                order: 'createdDate ASC'
              }
            }
          ],
        };
        return this.commentApi.find({ filter }).pipe(
          map((comments) => CommentActions.GetCommentsSuccessful({ comments })),
          catchError((error) =>
            [
              CommentActions.GetCommentsFailure({ error, key: 'GetComments', date: Date.now() })
            ]
          )
        );
      })
    )
  );

  updateComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CommentActions.UpdateComment
      ),
      switchMap((action) => {
        const { createdBy, createdById, createdDate, id, ...body } = action.comment as CommentWithRelations;
        const commentUpdateBody = body;

        return this.commentApi.updateById({ id: action.id, body: commentUpdateBody }).pipe(
          map(() => CommentActions.UpdateCommentSuccessful({
            id: action.id,
            comment: action.comment as Comment
          })),
          catchError((error) =>
            [
              CommentActions.UpdateCommentFailure({ error, key: 'UpdateComment', date: Date.now() })
            ]
          )
        );
      })
    )
  );

  updateReply$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CommentActions.UpdateReply
      ),
      switchMap((action) => this.commentApi.updateById({ id: action.replyId, body: action.reply }).pipe(
          map(() => CommentActions.UpdateReplySuccessful({
            parentCommentId: action.parentCommentId,
            replyId: action.replyId,
            reply: action.reply as Comment
          })),
          catchError((error) =>
            [
              CommentActions.UpdateReplyFailure({ error, key: 'UpdateReply', date: Date.now() })
            ]
          )
        ))
    )
  );

  deleteComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CommentActions.DeleteComment
      ),
      switchMap((action) => this.commentApi.deleteById({ id: action.id }).pipe(
        map(() => CommentActions.DeleteCommentSuccessful({ id: action.id })),
        catchError((error) =>
          [
            CommentActions.DeleteCommentFailure({ error, key: 'DeleteComment', date: Date.now() })
          ]
        )
      ))
    )
  );

  replyComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CommentActions.ReplyComment
      ),
      mergeMap((action) => this.commentApi.reply({ parentCommentId: action.parentCommentId, body: action.reply }).pipe(
        map((comment) => CommentActions.ReplyCommentSuccessful({ comment }))
      )),
      catchError((error) =>
        [
          CommentActions.ReplyCommentFailure({ error, key: 'ReplyComment', date: Date.now() })
        ]
      )
    )
  );

  replyCommentsSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CommentActions.ReplyCommentSuccessful
      ),
      map((action) => CommentActions.GetComments())
    )
  );

  deleteReply$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CommentActions.DeleteReply
      ),
      switchMap((action) => this.commentApi.deleteById({ id: action.replyId }).pipe(
        map(() => CommentActions.DeleteReplySuccessful({ parentCommentId: action.parentCommentId, replyId: action.replyId }))
      )),
      catchError((error) =>
        [
          CommentActions.DeleteReplyFailure({ error, key: 'DeleteReply', date: Date.now() })
        ]
      )
    )
  );

}
