import { Store } from '@ngrx/store';
import { PAGE_DEFINITIONS, PATH_DEFINITIONS, defaultToolbarTitleHeadingSize } from '@rappider/shared/definitions';
import { Component, OnInit } from '@angular/core';
import { PROJECT_COMMENTS_CONFIG } from './config/project-comments-config';
import { ActionResponse, BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { Subscription } from 'rxjs';
import { CommentWithRelations, ProjectWithRelations } from '@rappider/rappider-sdk';
import { GetAdminProjects } from '../../state/admin-projects/admin-projects.actions';
import { ActivatedRoute } from '@angular/router';
import { DeleteAdminComment, GetAdminComments } from '../../state/admin-comment/admin-comment.actions';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';

@Component({
  selector: 'rappider-project-comments',
  templateUrl: './project-comments.component.html',
  styleUrls: ['./project-comments.component.scss']
})
export class ProjectCommentsComponent implements OnInit {

  /* list grid config */
  PROJECT_COMMENTS_CONFIG = PROJECT_COMMENTS_CONFIG;

  /* for title-toolbar */
  mainTitle: HeadingComponentConfig = {
    content: 'Admin Dashboard',
    type: defaultToolbarTitleHeadingSize
  };
  title: string | string[] | BreadcrumbOption[];
  displayToolbar = false;
  displayToolbarBackButton = false;

  subscriptions: Subscription[] = [];

  projectId: string;
  selectedProject: ProjectWithRelations;
  comments: CommentWithRelations[];
  isLoading: boolean;

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getProjectId();
    this.subscribeToData();
  }

  getProjectId() {
    this.projectId = this.activatedRoute.snapshot.params['id'];
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToSelectedProject(),
      this.subscribeToLoading(),
      this.subscribeToComments()
    ];
  }

  subscribeToSelectedProject() {
    return this.store.select(state => state.adminProjects?.data).subscribe((projects: ProjectWithRelations[]) => {
      if (projects && this.projectId) {
        this.selectedProject = projects.find(module => module.id === this.projectId);
        this.setTitle();
      }
    });
  }

  subscribeToLoading() {
    return this.store.select(state => state.adminComment?.isLoading).subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
  }

  subscribeToComments() {
    return this.store.select(state => state.adminComment?.data).subscribe((comments: CommentWithRelations[]) => {
      if (comments?.length && this.projectId) {
        this.comments = comments.filter(comment => comment?.projectId === this.projectId);
      }
    });
  }


  setTitle() {
    this.title = [
      {
        label: 'Admin Dashboard',
        redirectUrl: PAGE_DEFINITIONS.ADMIN_DASHBOARD.URL
      },
      {
        label: 'Projects',
        redirectUrl: PATH_DEFINITIONS.ADMIN_DASHBOARD.PROJECTS
      },
      {
        label: 'Project Detail',
        redirectUrl: `${PATH_DEFINITIONS.ADMIN_DASHBOARD.PROJECT_DETAIL}/${this.selectedProject?.id}`
      },
      {
        label: this.selectedProject?.name
      },
      {
        label: 'Comments'
      }
    ];
  }

  onColumnActionClick(actionResponse: ActionResponse) {
    const comment = <CommentWithRelations>actionResponse.data;
    if (actionResponse.action.name === 'DELETE') {
      this.store.dispatch(DeleteAdminComment({ commentId: comment.id }));
    } else if (actionResponse.action.name === 'EDIT') {
      const url = `${PATH_DEFINITIONS.ADMIN_DASHBOARD.PROJECT_DETAIL}/${this.selectedProject.id}/${PAGE_DEFINITIONS.ADMIN_DASHBOARD.CHILDREN.COMMENT_EDIT}/${comment.id}`;
      this.store.dispatch(new Navigate({ url: url }));
    }
  }

}
