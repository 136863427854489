/* eslint-disable */
import { Category, ComponentDefinition } from '../index';

declare var Object: any;
export interface ComponentDefinitionSubCategoryInterface {
  id?: string;
  categoryId?: string;
  componentDefinitionId?: string;
  category?: Category;
  component?: ComponentDefinition;
}

export class ComponentDefinitionSubCategory
  implements ComponentDefinitionSubCategoryInterface {
  'id': string;
  'categoryId': string;
  'componentDefinitionId': string;
  category: Category;
  component: ComponentDefinition;
  constructor(data?: ComponentDefinitionSubCategoryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ComponentDefinitionSubCategory`.
   */
  public static getModelName() {
    return 'ComponentDefinitionSubCategory';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ComponentDefinitionSubCategory for dynamic purposes.
   **/
  public static factory(
    data: ComponentDefinitionSubCategoryInterface
  ): ComponentDefinitionSubCategory {
    return new ComponentDefinitionSubCategory(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ComponentDefinitionSubCategory',
      plural: 'ComponentDefinitionSubCategories',
      path: 'component-definition-sub-categories',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        categoryId: {
          name: 'categoryId',
          type: 'string',
        },
        componentDefinitionId: {
          name: 'componentDefinitionId',
          type: 'string',
        },
      },
      relations: {
        category: {
          name: 'category',
          type: 'Category',
          model: 'Category',
          relationType: 'belongsTo',
          keyFrom: 'categoryId',
          keyTo: 'id',
        },
        component: {
          name: 'component',
          type: 'ComponentDefinition',
          model: 'ComponentDefinition',
          relationType: 'belongsTo',
          keyFrom: 'componentDefinitionId',
          keyTo: 'id',
        },
      },
    };
  }
}
