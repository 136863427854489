import { KeyValue } from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyType, PropertyFormat } from '@rappider/rappider-components/utils';
import { AddFieldValue, FORMAT_OPTIONS, TYPE_OPTIONS } from '../../../shared';
import { DEFAULT_ADD_FIELD_VALUE_DATA } from './utils/default-add-field-value-data';

@Component({
  selector: 'rappider-add-field',
  templateUrl: './add-field.component.html',
  styleUrls: ['./add-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => AddFieldComponent),
      multi: true
    }
  ]
})
export class AddFieldComponent implements ControlValueAccessor {
  @Input() typeSelectOptions: KeyValue<string, string>[] = TYPE_OPTIONS;
  @Input() formatSelectOptions: KeyValue<string, string>[] = FORMAT_OPTIONS;

  _value: AddFieldValue;

  PropertyType = PropertyType;
  PropertyFormat = PropertyFormat;

  get value() {
    return this._value;
  }

  set value(value: AddFieldValue) {
    this._value = value;
    this.configureFormatOptionsData();
    this.onChange(value);
    this.onTouched();
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: AddFieldValue): void {
    this._value = value;
    if (value == null) {
      this._value = DEFAULT_ADD_FIELD_VALUE_DATA;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
     * clears the value when new type selected and sets the selected type
     *
     * @param {string} type
     * @memberof AddFieldComponent
     */
  onTypeSelect(type: string) {
    this.onFormatSelect(null);
    this.value = {
      ...this.value,
      type: type
    };
  }

  /**
   * clears value when new format selected and sets the selected format
   *
   * @param {string} format
   * @memberof AddFieldComponent
   */
  onFormatSelect(format: string) {
    this.value = {
      ...this.value,
      format: format
    };

  }

  /**
   * configures format select options
   * if type is number filter uuid format
   * if type is string shows all format options
   *
   * @memberof AddFieldComponent
   */
  configureFormatOptionsData() {
    if (this.value.type === this.PropertyType.String) {
      this.formatSelectOptions = FORMAT_OPTIONS;
    } else if (this.value.type === this.PropertyType.Integer) {
      const NUMBER_FORMAT_OPTIONS = this.formatSelectOptions.filter(item => item.value !== this.PropertyFormat.UUID);
      this.formatSelectOptions = NUMBER_FORMAT_OPTIONS;
    }
  }

  onInputValueChange() {
    this.value = { ...this.value };
  }
}
