import { Validators } from '@angular/forms';
import { ActionBehavior, CrudFormButtonItem, CrudFormConfig, CrudFormDocumentUploadItem, CrudFormItem, CrudFormRichTextEditorItem, CrudFormSelectCardItem, CrudFormTextBoxItem, CrudViewFormItemType, FormLayout, HeadingType, ItemPerRow, RichTextEditorType, SelectMode } from '@rappider/rappider-components/utils';
import { MONACO_MARKDOWN_CONFIG } from '../../monaco-markdown-config';

export const PROJECT_DOCUMENT_CREATE_OR_EDIT_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormTextBoxItem>{
      type: CrudViewFormItemType.TextBox,
      fieldName: 'title',
      title: 'SHARED.TITLE',
      showRequiredSign: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'This field is required'
        }
      ]
    },
    <CrudFormDocumentUploadItem>{
      type: CrudViewFormItemType.DocumentUpload,
      fieldName: 'files',
      title: 'File',
      previewerConfig: {
        modalConfig: {
          width: '100%',
          title: 'Preview',
          okText: 'Select'
        },
        buttonConfig: {
          text: '',
          icon: { name: '	far fa-eye' },
          borderSettings: {
            border: '0'
          },
          customColorSettings: {
            backgroundColor: 'var(--section-background-color)',
            color: 'var(--primary-color)'
          },
          shadowSettings: {
            boxShadow: '0 0 0 var(--section-background-color)'
          }
        }
      },
      showRequiredSign: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'This field is required'
        }
      ]
    }
  ],
  itemSettings: {
    inputComponentSize: {
      xs: 24
    }
  }
};

