import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TokenService } from '@rappider/authentication/services';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QueryParam } from '@rappider/shared/definitions';
import { RouterStateService } from '@rappider/services';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private tokenService: TokenService,
    private routerStateService: RouterStateService,
    private store: Store<any>
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    /* check if user is already in state */
    console.log('AUTH GUARD');
    return this.store.select(s => s.auth.user).pipe(
      map(user => {
        if (user?.id) {
          return true;
        } else {
          /* get access token */
          const authenticationToken = this.tokenService.getAuthenticationToken();
          if (!authenticationToken) {
            this.routerStateService.addQueryParam(QueryParam.RedirectUrl, state.url);
            this.routerStateService.navigate(PATH_DEFINITIONS.AUTH.LOGIN_PATH);
          }
          return !!authenticationToken;
        }
      })
    );
  }

}
