/* eslint-disable */
import { Project, RappiderFunction, DataSchemaTreeField } from '../index';

declare var Object: any;
export interface DataMappingInterface {
  id?: string;
  type?: string;
  projectId?: string;
  uiDataUpdateFunctionId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  functionId?: string;
  project?: Project;
  function?: RappiderFunction;
  sourceTreeFields?: DataSchemaTreeField[];
  targetTreeField?: DataSchemaTreeField;
}

export class DataMapping implements DataMappingInterface {
  'id': string;
  'type': string;
  'projectId': string;
  'uiDataUpdateFunctionId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  'functionId': string;
  project: Project;
  function: RappiderFunction;
  sourceTreeFields: DataSchemaTreeField[];
  targetTreeField: DataSchemaTreeField;
  constructor(data?: DataMappingInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `DataMapping`.
   */
  public static getModelName() {
    return 'DataMapping';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of DataMapping for dynamic purposes.
   **/
  public static factory(data: DataMappingInterface): DataMapping {
    return new DataMapping(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'DataMapping',
      plural: 'DataMappings',
      path: 'data-mappings',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        type: {
          name: 'type',
          type: 'string',
        },
        projectId: {
          name: 'projectId',
          type: 'string',
        },
        uiDataUpdateFunctionId: {
          name: 'uiDataUpdateFunctionId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
        functionId: {
          name: 'functionId',
          type: 'string',
        },
      },
      relations: {
        project: {
          name: 'project',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
          keyFrom: 'projectId',
          keyTo: 'id',
        },
        function: {
          name: 'function',
          type: 'RappiderFunction',
          model: 'RappiderFunction',
          relationType: 'belongsTo',
          keyFrom: 'functionId',
          keyTo: 'id',
        },
        sourceTreeFields: {
          name: 'sourceTreeFields',
          type: 'DataSchemaTreeField[]',
          model: 'DataSchemaTreeField',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'sourceToDataMappingId',
        },
        targetTreeField: {
          name: 'targetTreeField',
          type: 'DataSchemaTreeField',
          model: 'DataSchemaTreeField',
          relationType: 'hasOne',
          keyFrom: 'id',
          keyTo: 'targetToDataMappingId',
        },
      },
    };
  }
}
