import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as MetadataActions from './metadata.actions';
import { mergeMap } from 'rxjs/operators';
import { MetadataApi } from '@rappider/api-sdk';
import { cloneDeep } from 'lodash';

@Injectable()
export class MetadataEffects {

  constructor(
    private actions$: Actions,
    private metadataApi: MetadataApi
  ) { }


  createMetadata$ = createEffect(() => this.actions$.pipe(
    ofType<MetadataActions.CreateMetadata>(MetadataActions.ActionTypes.CreateMetadata),
    mergeMap((action: any) => {
      const payload = cloneDeep(action.payload.metadata);
      payload.forEach(metadata => {
        delete metadata.id;
      });
      return this.metadataApi.bulkCreate(payload).pipe(
        mergeMap(() => [new MetadataActions.CreateMetadataSuccessful({ metadata: payload })])
      );
    })
  ));


  updateMetadata$ = createEffect(() => this.actions$.pipe(
    ofType<MetadataActions.UpdateMetadata>(MetadataActions.ActionTypes.UpdateMetadata),
    mergeMap((action: any) => {
      const payload = action.payload.metadata;
      return this.metadataApi.bulkUpdate(payload).pipe(
        mergeMap(() => [new MetadataActions.UpdateMetadataSuccessful({ metadata: payload })])
      );
    })
  ));


  deleteMetadata$ = createEffect(() => this.actions$.pipe(
    ofType<MetadataActions.DeleteMetadata>(MetadataActions.ActionTypes.DeleteMetadata),
    mergeMap((action: any) => {
      const payload = action.payload.metadata;
      return this.metadataApi.bulkDelete(payload).pipe(
        mergeMap(() => [new MetadataActions.DeleteMetadataSuccessful({ metadata: payload })])
      );
    })
  ));

}
