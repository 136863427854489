import {
  CrudViewFormItemType,
  CrudFormConfig,
  CrudFormTextBoxItem,
  FormLayout
} from '@rappider/rappider-components/utils';
import { Validators } from '@angular/forms';
import { METADATA_CREATE_FORM_CONFIG } from '../metadata/metadata-create-form-config';

export const CREATE_FOLDER_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormTextBoxItem>{
      title: 'Folder Name',
      fieldName: 'folderName',
      placeholder: 'Folder Name',
      type: CrudViewFormItemType.TextBox,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'This field is required.'
        }
      ]
    },
    METADATA_CREATE_FORM_CONFIG
  ],
  itemSettings: {
    inputComponentSize: {
      xs: 24
    },
    labelComponentSize: {
      xs: 24
    }
  },
  submitButton: {
    visible: false
  },
  formLayout: FormLayout.Vertical
};
