import { Validators } from '@angular/forms';
import { CrudFormConfig, CrudViewFormItemType, CrudFormTextBoxItem, FormLayout, PropertyType } from '@rappider/rappider-components/utils';

export const DATA_SCHEMA_FIELD_ENUM_DATA_CONFIG: CrudFormConfig = {
  items: [
    {
      title: 'Name',
      fieldName: 'name',
      type: CrudViewFormItemType.TextBox,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    <CrudFormTextBoxItem>{
      title: 'Enum Data',
      fieldName: 'options',
      type: CrudViewFormItemType.RowForm,
      hasInıtialEmptyRow: true,
      addButtonText: 'PROJECT_MODULE.DATA_SCHEMA_ENUM_DATA.ADD_ENUM_DEFINITION',
      addButtonVisible: true,
      orderable: true,
      columns: [
        {
          fieldName: 'key',
          placeholder: 'key',
          unique: true,
          typeAndFormat: {
            type: PropertyType.String
          },
          validators: [
            {
              type: Validators.required,
              errorKey: 'required',
              errorMessage: 'SHARED.REQUIRED'
            }
          ]
        },
        {
          fieldName: 'value',
          placeholder: 'value',
          unique: true,
          typeAndFormat: {
            type: PropertyType.String
          },
          validators: [
            {
              type: Validators.required,
              errorKey: 'required',
              errorMessage: 'SHARED.REQUIRED'
            }
          ]
        }
      ]
    },
  ],
  formLayout: FormLayout.Horizontal,
  submitButton: {
    visible: false
  }
};
