import { createAction, props } from '@ngrx/store';
import { CommentPartial, CommentWithRelations } from '@rappider/rappider-sdk';

/* action types */
export enum ActionTypes {
  GetAdminComments = '[AdminComment] GetAdminComments',
  GetAdminCommentsSuccessful = '[AdminComment] GetAdminCommentsSuccessful',
  GetAdminCommentsFailure = '[AdminComment] GetAdminCommentsFailure',
  DeleteAdminComment = '[AdminComment] DeleteAdminComment',
  DeleteAdminCommentSuccessful = '[AdminComment] DeleteAdminCommentSuccessful',
  DeleteAdminCommentFailure = '[AdminComment] DeleteAdminCommentFailure',
  UpdateAdminComment = '[AdminComment] UpdateAdminComment',
  UpdateAdminCommentSuccessful = '[AdminComment] UpdateAdminCommentSuccessful',
  UpdateAdminCommentFailure = '[AdminComment] UpdateAdminCommentFailure',
}

/* get */
export const GetAdminComments = createAction(ActionTypes.GetAdminComments);

export const GetAdminCommentsSuccessful = createAction(
  ActionTypes.GetAdminCommentsSuccessful,
  props<{ comments: CommentWithRelations[] }>()
);

export const GetAdminCommentsFailure = createAction(
  ActionTypes.GetAdminCommentsFailure,
  props<{ error: any; key: string; date: number }>()
);

/* delete */
export const DeleteAdminComment = createAction(
  ActionTypes.DeleteAdminComment,
  props<{ commentId: string }>()
);

export const DeleteAdminCommentSuccessful = createAction(
  ActionTypes.DeleteAdminCommentSuccessful,
  props<{ commentId: string }>()
);

export const DeleteAdminCommentFailure = createAction(
  ActionTypes.DeleteAdminCommentFailure,
  props<{ error: any; key: string }>()
);

/* update */
export const UpdateAdminComment = createAction(
  ActionTypes.UpdateAdminComment,
  props<{ commentId: string; comment: CommentPartial }>()
);

export const UpdateAdminCommentSuccessful = createAction(
  ActionTypes.UpdateAdminCommentSuccessful,
  props<{ comment: any }>()
);

export const UpdateAdminCommentFailure = createAction(
  ActionTypes.UpdateAdminCommentFailure,
  props<{ error: any; key: string }>()
);
