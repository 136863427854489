/* angular imports */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

/* guards */
import { AuthGuard } from '@rappider/authentication/guards';
import { ActiveProjectGuard, VerifyPersonGuard } from '@rappider/shared/guards';
import { UIStepFunctionListComponent } from './components/ui-step-function-list/ui-step-function-list.component';

/* external modules */
import { TranslateModule } from '@ngx-translate/core';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';

/* internal modules */
import { UIStepFunctionCreateComponent } from './components/ui-step-function-create/ui-step-function-create.component';
import { UIStepFunctionEditComponent } from './components/ui-step-function-edit/ui-step-function-edit.component';
import { UiStepFunctionListDetailComponent } from './components/ui-step-function-list-detail/ui-step-function-list-detail.component';
import { UiStepFunctionsComponent } from './ui-step-functions/ui-step-functions.component';
import {
  RappiderListGridModule,
  RappiderEditFormModule,
  RappiderTitleBarModule,
  RappiderHeadingModule,
  RappiderTextModule,
  RappiderCrudDetailModule,
  RappiderTitleToolbarModule,
  RappiderTextboxModule,
  RappiderButtonModule,
  RappiderSelectModule,
  RappiderCodeEditorModule,
  RappiderRadioGroupModule,
  RappiderIconModule,
  RappiderModalModule,
  RappiderInputTemplateModule,
  RappiderSpinModule,
  RappiderInputGroupModule,
  RappiderMonacoEditorModule
} from '@rappider/rappider-components';

import { PAGE_DEFINITIONS } from '@rappider/shared/definitions';
import { UiStepFunctionDetailComponent } from './components/ui-step-function-detail/ui-step-function-detail.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTransformationModule } from '@rappider/data-transformation';
import { SubscribeToUiDataStoreComponent } from './subscribe-to-ui-data-store/subscribe-to-ui-data-store.component';
import { UIDataUpdateFunctionModule } from '../ui-data-update-function/ui-data-update-function.module';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { UiStepFunctionIfConditionComponent } from './components/ui-step-function-if-condition/ui-step-function-if-condition.component';
import { FilterFindModule } from 'libs/data-transformation/src/lib/modules/filter-find/filter-find.module';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { RappiderInputGroupComponent } from 'libs/components/src/lib/components/input-group/input-group.component';
import { NzPopoverModule } from 'ng-zorro-antd/popover';


const routes: Routes = [
  {
    path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_STEP_FUNCTION_CREATE.URL}/:dataStoreId`,
    component: UIStepFunctionCreateComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
  },
  {
    path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_STEP_FUNCTION_UPDATE.URL}/:id`,
    component: UIStepFunctionEditComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
  },
  {
    path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_STEP_FUNCTION_DETAIL.URL}/:id`,
    component: UiStepFunctionDetailComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
  },
  {
    path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_STEP_FUNCTIONS.URL}`,
    component: UiStepFunctionsComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
  }
];

@NgModule({
  declarations: [
    UIStepFunctionListComponent,
    UIStepFunctionCreateComponent,
    UIStepFunctionEditComponent,
    UiStepFunctionDetailComponent,
    SubscribeToUiDataStoreComponent,
    UiStepFunctionIfConditionComponent,
    UiStepFunctionListDetailComponent,
    UiStepFunctionsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    FormsModule,
    NzFormModule,
    NzButtonModule,
    ReactiveFormsModule,
    RappiderRadioGroupModule,
    RappiderTextboxModule,
    RappiderListGridModule,
    RappiderEditFormModule,
    RappiderTitleBarModule,
    RappiderHeadingModule,
    RappiderTextModule,
    RappiderCrudDetailModule,
    RappiderTitleToolbarModule,
    RappiderSelectModule,
    RappiderCodeEditorModule,
    RappiderModalModule,
    DataTransformationModule,
    RappiderIconModule,
    RappiderButtonModule,
    UIDataUpdateFunctionModule,
    NzIconModule,
    NzCollapseModule,
    FilterFindModule,
    NzToolTipModule,
    RappiderInputTemplateModule,
    RappiderSpinModule,
    RappiderInputGroupModule,
    RappiderMonacoEditorModule,
    NzPopoverModule,
    RappiderTextboxModule
  ],
  exports: [
    UIStepFunctionListComponent,
    UIStepFunctionCreateComponent,
    UIStepFunctionEditComponent,
    UiStepFunctionsComponent
  ]
})
export class UIStepFunctionModule { }
