import { createAction, props } from '@ngrx/store';
import { WorkflowEvent, WorkflowStepFunction, WorkflowStepFunctionWithRelations } from '@rappider/rappider-sdk';
import { WorkflowStepFunctionCreateDto } from 'libs/rappider-sdk/src/lib/models/workflow-step-function-create-dto';
import { WorkflowStepFunctionUpdateDtoPartial } from 'libs/rappider-sdk/src/lib/models/workflow-step-function-update-dto-partial';

/* action types */
export enum ActionTypes {
  CreateWorkflowStepFunction = '[WorkflowStepFunction] CreateWorkflowStepFunction',
  CreateWorkflowStepFunctionSuccessful = '[WorkflowStepFunction] CreateWorkflowStepFunctionSuccessful',
  CreateWorkflowStepFunctionFailure = '[WorkflowStepFunction] CreateWorkflowStepFunctionFailure',
  GetRappiderWorkflowStepFunctions = '[WorkflowStepFunction] GetRappiderWorkflowStepFunctions',
  GetRappiderWorkflowStepFunctionsSuccessful = '[WorkflowStepFunction] GetRappiderWorkflowStepFunctionsSuccessful',
  GetRappiderWorkflowStepFunctionsFailure = '[WorkflowStepFunction] GetRappiderWorkflowStepFunctionsFailure',
  GetWorkflowStepFunction = '[WorkflowStepFunction] GetWorkflowStepFunction',
  GetWorkflowStepFunctionSuccessful = '[WorkflowStepFunction] GetWorkflowStepFunctionSuccessful',
  GetWorkflowStepFunctionFailure = '[WorkflowStepFunction] GetWorkflowStepFunctionFailure',
  UpdateWorkflowStepFunction = '[WorkflowStepFunction] UpdateWorkflowStepFunction',
  UpdateWorkflowStepFunctionSuccessful = '[WorkflowStepFunction] UpdateWorkflowStepFunctionSuccessful',
  UpdateWorkflowStepFunctionFailure = '[WorkflowStepFunction] UpdateWorkflowStepFunctionFailure',
  DeleteWorkflowStepFunction = '[WorkflowStepFunction] DeleteWorkflowStepFunction',
  DeleteWorkflowStepFunctionSuccessful = '[WorkflowStepFunction] DeleteWorkflowStepFunctionSuccessful',
  DeleteWorkflowStepFunctionFailure = '[WorkflowStepFunction] DeleteWorkflowStepFunctionFailure',
  ErrorAction = '[WorkflowStepFunction] ErrorAction',
  /* Pre Data Transformation */
  GetPreDataTransformationData = '[WorkflowStepFunction] GetPreDataTransformationData',
  GetPreDataTransformationDataSuccessful = '[WorkflowStepFunction] GetPreDataTransformationDataSuccessful',
  GetPreDataTransformationDataFailure = '[WorkflowStepFunction] GetPreDataTransformationDataFailure',
  CreatePreDataTransformation = '[WorkflowStepFunction] CreatePreDataTransformation',
  CreatePreDataTransformationSuccessful = '[WorkflowStepFunction] CreatePreDataTransformationSuccessful',
  CreatePreDataTransformationFailure = '[WorkflowStepFunction] CreatePreDataTransformationFailure',
  GetPreDTSourceAndTargetSchemaData = '[WorkflowStepFunction] GetPreDTSourceAndTargetSchemaData',
  GetPreDTSourceAndTargetSchemaDataSuccessful = '[WorkflowStepFunction] GetPreDTSourceAndTargetSchemaDataSuccessful',
  GetPreDTSourceAndTargetSchemaDataFailure = '[WorkflowStepFunction] GetPreDTSourceAndTargetSchemaDataFailure',
  /* Post Data Transformation */
  GetPostDataTransformationData = '[WorkflowStepFunction] GetPostDataTransformationData',
  GetPostDataTransformationDataSuccessful = '[WorkflowStepFunction] GetPostDataTransformationDataSuccessful',
  GetPostDataTransformationDataFailure = '[WorkflowStepFunction] GetPostDataTransformationDataFailure',
  CreatePostDataTransformation = '[WorkflowStepFunction] CreatePostDataTransformation',
  CreatePostDataTransformationSuccessful = '[WorkflowStepFunction] CreatePostDataTransformationSuccessful',
  CreatePostDataTransformationFailure = '[WorkflowStepFunction] CreatePostDataTransformationFailure',
  GetPostDTSourceAndTargetSchemaData = '[WorkflowStepFunction] GetPostDTSourceAndTargetSchemaData',
  GetPostDTSourceAndTargetSchemaDataSuccessful = '[WorkflowStepFunction] GetPostDTSourceAndTargetSchemaDataSuccessful',
  GetPostDTSourceAndTargetSchemaDataFailure = '[WorkflowStepFunction] GetPostDTSourceAndTargetSchemaDataFailure',
  AddPublishedEventOnSuccessToWorkflowStepFunction = '[WorkflowStepFunction] AddPublishedEventOnSuccessToWorkflowStepFunction',
  AddPublishedEventOnFailureToWorkflowStepFunction = '[WorkflowStepFunction] AddPublishedEventOnFailureToWorkflowStepFunction'
}

export const CreateWorkflowStepFunction = createAction(
  ActionTypes.CreateWorkflowStepFunction,
  props<{ payload: { workflowStepFunction: WorkflowStepFunctionCreateDto; placeholderDataId?: string; navigateAfterCreate?: boolean } }>()
);

export const CreateWorkflowStepFunctionSuccessful = createAction(
  ActionTypes.CreateWorkflowStepFunctionSuccessful,
  props<{ payload: { workflowStepFunction: WorkflowStepFunctionWithRelations; placeholderDataId?: string } }>()
);

export const CreateWorkflowStepFunctionFailure = createAction(
  ActionTypes.CreateWorkflowStepFunctionFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const GetRappiderWorkflowStepFunctions = createAction(
  ActionTypes.GetRappiderWorkflowStepFunctions,
);
export const GetRappiderWorkflowStepFunctionsSuccessful = createAction(
  ActionTypes.GetRappiderWorkflowStepFunctionsSuccessful,
  props<{ payload: { workflowStepFunctions: WorkflowStepFunctionWithRelations[] } }>()
);
export const GetRappiderWorkflowStepFunctionsFailure = createAction(
  ActionTypes.GetRappiderWorkflowStepFunctionsFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const GetWorkflowStepFunction = createAction(
  ActionTypes.GetWorkflowStepFunction,
);

export const GetWorkflowStepFunctionSuccessful = createAction(
  ActionTypes.GetWorkflowStepFunctionSuccessful,
  props<{ payload: { workflowStepFunctions: WorkflowStepFunction[] } }>()
);

export const GetWorkflowStepFunctionFailure = createAction(
  ActionTypes.GetWorkflowStepFunctionFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const UpdateWorkflowStepFunction = createAction(
  ActionTypes.UpdateWorkflowStepFunction,
  props<{ payload: { workflowStepFunctionId: string; workflowStepFunctionBody?: WorkflowStepFunctionUpdateDtoPartial } }>()
);

export const UpdateWorkflowStepFunctionSuccessful = createAction(
  ActionTypes.UpdateWorkflowStepFunctionSuccessful,
  props<{ payload: { workflowStepFunction: Partial<WorkflowStepFunctionWithRelations>; workflowStepFunctionId: string } }>()
);

export const UpdateWorkflowStepFunctionFailure = createAction(
  ActionTypes.UpdateWorkflowStepFunctionFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const DeleteWorkflowStepFunction = createAction(
  ActionTypes.DeleteWorkflowStepFunction,
  props<{ payload: { workflowStepFunctionId: string } }>()
);

export const DeleteWorkflowStepFunctionSuccessful = createAction(
  ActionTypes.DeleteWorkflowStepFunctionSuccessful,
  props<{ payload: { workflowStepFunctionId: string } }>()
);

export const DeleteWorkflowStepFunctionFailure = createAction(
  ActionTypes.DeleteWorkflowStepFunctionFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const AddPublishedEventOnSuccessToWorkflowStepFunction = createAction(
  ActionTypes.AddPublishedEventOnSuccessToWorkflowStepFunction,
  props<{ payload: { event: WorkflowEvent; workflowStepFunctionId: string } }>()
);

export const AddPublishedEventOnFailureToWorkflowStepFunction = createAction(
  ActionTypes.AddPublishedEventOnFailureToWorkflowStepFunction,
  props<{ payload: { event: WorkflowEvent; workflowStepFunctionId: string } }>()
);

export const ErrorAction = createAction(
  ActionTypes.ErrorAction,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

/* PRE DATA TRANSFORMATION */
export const GetPreDataTransformationData = createAction(
  ActionTypes.GetPreDataTransformationData,
  props<{ payload: { workflowStepFunction: WorkflowStepFunctionWithRelations } }>()
);

export const GetPreDataTransformationDataSuccessful = createAction(
  ActionTypes.GetPreDataTransformationDataSuccessful,
  props<{ payload: { preDataTransformationId: string; sourceJsonSchema: any; targetJsonSchema: any } }>()
);

export const GetPreDataTransformationDataFailure = createAction(
  ActionTypes.GetPreDataTransformationDataFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const CreatePreDataTransformation = createAction(
  ActionTypes.CreatePreDataTransformation,
  props<{ payload: { workflowStepFunction: WorkflowStepFunctionWithRelations } }>()
);

export const CreatePreDataTransformationSuccessful = createAction(
  ActionTypes.CreatePreDataTransformationSuccessful,
  props<{ payload: { preDataTransformationId: string; workflowStepFunction: WorkflowStepFunctionWithRelations } }>()
);

export const CreatePreDataTransformationFailure = createAction(
  ActionTypes.CreatePreDataTransformationFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const GetPreDTSourceAndTargetSchemaData = createAction(
  ActionTypes.GetPreDTSourceAndTargetSchemaData,
  props<{ payload: { workflowStepFunction: WorkflowStepFunctionWithRelations; sourceSchema: any; targetSchema: any } }>()
);

export const GetPreDTSourceAndTargetSchemaDataSuccessful = createAction(
  ActionTypes.GetPreDTSourceAndTargetSchemaDataSuccessful,
  props<{ payload: { preDataTransformationId: string; preSourceJsonSchema: any; preTargetJsonSchema: any } }>()
);

export const GetPreDTSourceAndTargetSchemaDataFailure = createAction(
  ActionTypes.GetPreDTSourceAndTargetSchemaDataFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);
/* PRE DATA TRANSFORMATION */

/* POST DATA TRANSFORMATION */
export const GetPostDataTransformationData = createAction(
  ActionTypes.GetPostDataTransformationData,
  props<{ payload: { workflowStepFunction: WorkflowStepFunctionWithRelations } }>()
);

export const GetPostDataTransformationDataSuccessful = createAction(
  ActionTypes.GetPostDataTransformationDataSuccessful,
  props<{ payload: { postDataTransformationId: string; sourceJsonSchema: any; targetJsonSchema: any } }>()
);

export const GetPostDataTransformationDataFailure = createAction(
  ActionTypes.GetPostDataTransformationDataFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const CreatePostDataTransformation = createAction(
  ActionTypes.CreatePostDataTransformation,
  props<{ payload: { workflowStepFunction: WorkflowStepFunctionWithRelations } }>()
);

export const CreatePostDataTransformationSuccessful = createAction(
  ActionTypes.CreatePostDataTransformationSuccessful,
  props<{ payload: { postDataTransformationId: string; workflowStepFunction: WorkflowStepFunctionWithRelations } }>()
);

export const CreatePostDataTransformationFailure = createAction(
  ActionTypes.CreatePostDataTransformationFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const GetPostDTSourceAndTargetSchemaData = createAction(
  ActionTypes.GetPostDTSourceAndTargetSchemaData,
  props<{ payload: { workflowStepFunction: WorkflowStepFunctionWithRelations; sourceSchema: any; targetSchema: any } }>()
);

export const GetPostDTSourceAndTargetSchemaDataSuccessful = createAction(
  ActionTypes.GetPostDTSourceAndTargetSchemaDataSuccessful,
  props<{ payload: { postDataTransformationId: string; postSourceJsonSchema: any; postTargetJsonSchema: any } }>()
);

export const GetPostDTSourceAndTargetSchemaDataFailure = createAction(
  ActionTypes.GetPostDTSourceAndTargetSchemaDataFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);
/* POST DATA TRANSFORMATION */
