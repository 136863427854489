import { Injectable } from '@angular/core';
import {
  EnvironmentVariableInterface,
  MessageTemplateInterface,
  PersonInterface,
  ProjectApi,
  ProjectRoleApi,
  ProjectRoleInterface,
  ProjectSettingApi,
  ProjectSettingInterface,
  ProjectTheme
} from '@rappider/api-sdk';
import { Person, ProjectControllerService } from '@rappider/rappider-sdk';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(
    private oldProjectApi: ProjectApi,
    private projectApi: ProjectControllerService,
    private projectRoleApi: ProjectRoleApi,
    private projectSettingApi: ProjectSettingApi
  ) { }

  getProjectMembersWithRoles(): Observable<Person[]> {
    return this.projectApi.findProjectMembersWithTheirRoles();
  }

  getProjectMessageTemplates(projectId: string): Observable<MessageTemplateInterface[]> {
    const filter = {
      include: ['_dataFields']
    };
    return this.oldProjectApi.getMessageTemplates(projectId, filter);
  }

  getProjectPersonsInProjectRoleByRoleId(roleId: string): Observable<PersonInterface[]> {
    return this.projectRoleApi.getPeople(roleId);
  }

  getProjectRolesByProjectId(projectId: string): Observable<ProjectRoleInterface[]> {
    return this.oldProjectApi.getRoles(projectId);
  }

  getEnvironmentVariablesByProjectId(projectId: string): Observable<EnvironmentVariableInterface[]> {
    return this.oldProjectApi.getEnvironmentVariables(projectId);
  }

  getProjectSettingsByProjectId(projectId: string): Observable<ProjectSettingInterface[]> {
    return this.oldProjectApi.getProjectSettings(projectId);
  }

  getProjectThemeByProjectId(projectId: string): Observable<ProjectTheme> {
    return this.oldProjectApi.getTheme(projectId);
  }
}
