/* eslint-disable */

declare var Object: any;
export interface MessageInterface {
  id?: string;
  environmentKey?: string;
  projectId?: string;
  messageType: string;
  receiver: string;
  sender: string;
  subject?: string;
  messageBody: string;
  createdDate?: Date;
}

export class Message implements MessageInterface {
  'id'?: string;
  'environmentKey': string;
  'projectId': string;
  'messageType': string;
  'receiver': string;
  'sender': string;
  'subject': string;
  'messageBody': string;
  'createdDate': Date;
  constructor(data?: MessageInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Message`.
   */
  public static getModelName() {
    return 'Message';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Message for dynamic purposes.
   **/
  public static factory(data: MessageInterface): Message {
    return new Message(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Message',
      plural: 'Messages',
      path: 'messages',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        environmentKey: {
          name: 'environmentKey',
          type: 'string',
        },
        projectId: {
          name: 'projectId',
          type: 'string',
        },
        messageType: {
          name: 'messageType',
          type: 'string',
        },
        receiver: {
          name: 'receiver',
          type: 'string',
        },
        sender: {
          name: 'sender',
          type: 'string',
        },
        subject: {
          name: 'subject',
          type: 'string',
        },
        messageBody: {
          name: 'messageBody',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
      },
      relations: {},
    };
  }
}
