/* eslint-disable no-shadow */
import { Action } from '@ngrx/store';
import { ProjectRoleInterface } from '@rappider/api-sdk';
import { Pagination } from '@rappider/shared/interfaces';

export enum ActionTypes {
  LoadModule = '[ProjectRole] LoadModule',
  EnableProjectRolesLoading = '[ProjectRole] EnableProjectRolesLoading',
  DisableProjectRolesLoading = '[ProjectRole] DisableProjectRolesLoading',
  SetPagination = '[ProjectRole] SetPagination',
  GetTotalCount = '[ProjectRole] GetTotalCount',
  GetProjectRoles = '[ProjectRole] GetProjectRoles',
  GetProjectRolesFailure = '[ProjectRole] GetProjectRolesFailure',
  SetProjectRoles = '[ProjectRole] SetProjectRoles',
  CreateProjectRole = '[ProjectRole] CreateProjectRole',
  CreateProjectRoleFailure = '[ProjectRole] CreateProjectRoleFailure',
  UpdateProjectRole = '[ProjectRole] UpdateProjectRole',
  UpdateProjectRoleFailure = '[ProjectRole] UpdateProjectRoleFailure',
  DeleteProjectRole = '[ProjectRole] DeleteProjectRole',
  DeleteProjectRoleFailure = '[ProjectRole] DeleteProjectRoleFailure',
  ProjectRoleError = '[ProjectRole] ProjectRoleError',
}

export class LoadModule implements Action {
  readonly type = ActionTypes.LoadModule;
}

export class EnableProjectRolesLoading implements Action {
  readonly type = ActionTypes.EnableProjectRolesLoading;
}

export class DisableProjectRolesLoading implements Action {
  readonly type = ActionTypes.DisableProjectRolesLoading;
}

export class GetProjectRoles implements Action {
  readonly type = ActionTypes.GetProjectRoles;
}

export class GetProjectRolesFailure implements Action {
  readonly type = ActionTypes.GetProjectRolesFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

/**
 * sets pagination and gets data after the action if specified
 *
 * @export
 * @class SetPagination
 * @implements {Action}
 */
export class SetPagination implements Action {
  readonly type = ActionTypes.SetPagination;
  constructor(public payload: { pagination: Pagination; getDataAfter?: boolean }) { }
}

export class GetTotalCount implements Action {
  readonly type = ActionTypes.GetTotalCount;
}

export class SetProjectRoles implements Action {
  readonly type = ActionTypes.SetProjectRoles;
  constructor(public payload: { projectRoles: ProjectRoleInterface[] }) { }
}

export class CreateProjectRole implements Action {
  readonly type = ActionTypes.CreateProjectRole;
  constructor(public payload: { projectRole: ProjectRoleInterface }) { }
}

export class CreateProjectRoleFailure implements Action {
  readonly type = ActionTypes.CreateProjectRoleFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class UpdateProjectRole implements Action {
  readonly type = ActionTypes.UpdateProjectRole;
  constructor(public payload: { projectRole: ProjectRoleInterface; projectRoleId: string }) { }
}

export class UpdateProjectRoleFailure implements Action {
  readonly type = ActionTypes.UpdateProjectRoleFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class DeleteProjectRole implements Action {
  readonly type = ActionTypes.DeleteProjectRole;
  constructor(public payload: { projectRoleId: string }) { }
}

export class DeleteProjectRoleFailure implements Action {
  readonly type = ActionTypes.DeleteProjectRoleFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class ProjectRoleError implements Action {
  readonly type = ActionTypes.ProjectRoleError;
  constructor(public payload: { errorOn: string; error?: any }) { }
}

export type Actions =
  LoadModule
  | EnableProjectRolesLoading
  | DisableProjectRolesLoading
  | SetPagination
  | GetTotalCount
  | GetProjectRoles
  | GetProjectRolesFailure
  | SetProjectRoles
  | CreateProjectRole
  | CreateProjectRoleFailure
  | UpdateProjectRole
  | UpdateProjectRoleFailure
  | DeleteProjectRole
  | DeleteProjectRoleFailure
  | ProjectRoleError
  ;
