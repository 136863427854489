/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CalculateUiDataStoresStateJsonSchemasResponseDto } from '../models/calculate-ui-data-stores-state-json-schemas-response-dto';
import { LastProcessInformation } from '../models/last-process-information';
import { NewUiDataStore } from '../models/new-ui-data-store';
import { UiDataStore } from '../models/ui-data-store';
import { UiDataStorePartial } from '../models/ui-data-store-partial';
import { UiDataStoreWithRelations } from '../models/ui-data-store-with-relations';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class UiDataStoreControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation uiDataStoreControllerCalculateAllUiDataStoreStateJsonSchemas
   */
  static readonly UiDataStoreControllerCalculateAllUiDataStoreStateJsonSchemasPath = '/ui-data-stores/calculate-state-json-schemas';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calculateAllUIDataStoreStateJSONSchemas()` instead.
   *
   * This method doesn't expect any request body.
   */
  calculateAllUIDataStoreStateJSONSchemas$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CalculateUiDataStoresStateJsonSchemasResponseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataStoreControllerService.UiDataStoreControllerCalculateAllUiDataStoreStateJsonSchemasPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CalculateUiDataStoresStateJsonSchemasResponseDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calculateAllUIDataStoreStateJSONSchemas$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  calculateAllUIDataStoreStateJSONSchemas(params?: {
  }): Observable<Array<CalculateUiDataStoresStateJsonSchemasResponseDto>> {

    return this.calculateAllUIDataStoreStateJSONSchemas$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CalculateUiDataStoresStateJsonSchemasResponseDto>>) => r.body as Array<CalculateUiDataStoresStateJsonSchemasResponseDto>)
    );
  }

  /**
   * Path part for operation uiDataStoreControllerCount
   */
  static readonly UiDataStoreControllerCountPath = '/ui-data-stores/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params?: {
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataStoreControllerService.UiDataStoreControllerCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params?: {
    where?: any;
  }): Observable<LoopbackCount> {

    return this.count$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation uiDataStoreControllerCreateUiDataStoreByProjectModel
   */
  static readonly UiDataStoreControllerCreateUiDataStoreByProjectModelPath = '/ui-data-stores/create-by-project-model/{projectModelId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUIDataStoreByProjectModel()` instead.
   *
   * This method doesn't expect any request body.
   */
  createUIDataStoreByProjectModel$Response(params: {
    projectModelId: string;
  }): Observable<StrictHttpResponse<UiDataStore>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataStoreControllerService.UiDataStoreControllerCreateUiDataStoreByProjectModelPath, 'post');
    if (params) {
      rb.path('projectModelId', params.projectModelId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UiDataStore>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createUIDataStoreByProjectModel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createUIDataStoreByProjectModel(params: {
    projectModelId: string;
  }): Observable<UiDataStore> {

    return this.createUIDataStoreByProjectModel$Response(params).pipe(
      map((r: StrictHttpResponse<UiDataStore>) => r.body as UiDataStore)
    );
  }

  /**
   * Path part for operation uiDataStoreControllerFindLatestChangeTimestamps
   */
  static readonly UiDataStoreControllerFindLatestChangeTimestampsPath = '/ui-data-stores/latest-change-timestamps';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findLatestChangeTimestamps()` instead.
   *
   * This method doesn't expect any request body.
   */
  findLatestChangeTimestamps$Response(params?: {
  }): Observable<StrictHttpResponse<LastProcessInformation>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataStoreControllerService.UiDataStoreControllerFindLatestChangeTimestampsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LastProcessInformation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findLatestChangeTimestamps$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findLatestChangeTimestamps(params?: {
  }): Observable<LastProcessInformation> {

    return this.findLatestChangeTimestamps$Response(params).pipe(
      map((r: StrictHttpResponse<LastProcessInformation>) => r.body as LastProcessInformation)
    );
  }

  /**
   * Path part for operation uiDataStoreControllerFindById
   */
  static readonly UiDataStoreControllerFindByIdPath = '/ui-data-stores/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<UiDataStore>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataStoreControllerService.UiDataStoreControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UiDataStore>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
    filter?: any;
  }): Observable<UiDataStore> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<UiDataStore>) => r.body as UiDataStore)
    );
  }

  /**
   * Path part for operation uiDataStoreControllerDeleteById
   */
  static readonly UiDataStoreControllerDeleteByIdPath = '/ui-data-stores/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataStoreControllerService.UiDataStoreControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: string;
  }): Observable<any> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation uiDataStoreControllerUpdateById
   */
  static readonly UiDataStoreControllerUpdateByIdPath = '/ui-data-stores/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    body?: UiDataStorePartial
  }): Observable<StrictHttpResponse<UiDataStore>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataStoreControllerService.UiDataStoreControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UiDataStore>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    body?: UiDataStorePartial
  }): Observable<UiDataStore> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<UiDataStore>) => r.body as UiDataStore)
    );
  }

  /**
   * Path part for operation uiDataStoreControllerFind
   */
  static readonly UiDataStoreControllerFindPath = '/ui-data-stores';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<UiDataStoreWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataStoreControllerService.UiDataStoreControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UiDataStoreWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<UiDataStoreWithRelations>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UiDataStoreWithRelations>>) => r.body as Array<UiDataStoreWithRelations>)
    );
  }

  /**
   * Path part for operation uiDataStoreControllerCreate
   */
  static readonly UiDataStoreControllerCreatePath = '/ui-data-stores';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    body?: NewUiDataStore
  }): Observable<StrictHttpResponse<UiDataStore>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataStoreControllerService.UiDataStoreControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UiDataStore>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    body?: NewUiDataStore
  }): Observable<UiDataStore> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<UiDataStore>) => r.body as UiDataStore)
    );
  }

}
