import { DEFAULT_PAGINATION } from '@rappider/shared/definitions';
import { Pagination } from '@rappider/shared/interfaces';
import * as MessageTemplateActions from './message-template.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

export const messageTemplateFeatureKey = 'messageTemplate';

export interface State {
  data: any[];
  pagination: Pagination;
  loading: boolean;
}

export const initialState: State = {
  data: null,
  pagination: {
    currentPageNumber: DEFAULT_PAGINATION.ACTIVE_PAGE_NUMBER,
    pageSize: DEFAULT_PAGINATION.PAGE_SIZE,
    totalCount: null,
    totalPageNumber: null
  },
  loading: false
};

export function reducer(
  state: State = initialState,
  action: MessageTemplateActions.Actions | AuthenticationActions.Actions
): State {
  switch (action.type) {

    case MessageTemplateActions.ActionTypes.GetMessageTemplates:
      return {
        ...state,
        loading: true
      };

    case MessageTemplateActions.ActionTypes.GetMessageTemplatesSuccessful:
      return {
        ...state,
        data: action.payload.messageTemplates,
        loading: false
      };

    case MessageTemplateActions.ActionTypes.GetMessageTemplatesFailure:
      return {
        ...state,
        loading: false
      };

    case MessageTemplateActions.ActionTypes.CreateMessageTemplate:
      return {
        ...state,
        loading: true
      };

    case MessageTemplateActions.ActionTypes.CreateMessageTemplateSuccessful:
      return {
        ...state,
        data: [
          ...state.data,
          action.payload.messageTemplate
        ],
        loading: false
      };

    case MessageTemplateActions.ActionTypes.CreateMessageTemplateFailure:
      return {
        ...state,
        loading: false
      };

    case MessageTemplateActions.ActionTypes.UpdateMessageTemplate:
      return {
        ...state,
        loading: true
      };

    case MessageTemplateActions.ActionTypes.UpdateMessageTemplateSuccessful: {
      const updatedMessageTemplate = {
        ...state.data.find(messageTemplate => messageTemplate.id === action.payload.messageTemplate.id),
        ...action.payload.messageTemplate
      };
      return {
        ...state,
        data: [
          ...state.data.filter(messageTemplate => messageTemplate.id !== action.payload.messageTemplate.id),
          updatedMessageTemplate
        ],
        loading: false,
      };
    }

    case MessageTemplateActions.ActionTypes.UpdateMessageTemplateFailure:
      return {
        ...state,
        loading: false
      };

    case MessageTemplateActions.ActionTypes.DeleteMessageTemplate:
      return {
        ...state,
        loading: true
      };

    case MessageTemplateActions.ActionTypes.DeleteMessageTemplateSuccessful:
      return {
        ...state,
        data: state.data.filter(messageTemplate => messageTemplate.id !== action.payload.messageTemplateId),
        loading: false,
      };

    case MessageTemplateActions.ActionTypes.DeleteMessageTemplateFailure:
      return {
        ...state,
        loading: false
      };

    case MessageTemplateActions.ActionTypes.SetPagination:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload.pagination
        }
      };

    case MessageTemplateActions.ActionTypes.PaginationReset:
      return {
        ...state,
        pagination: {
          currentPageNumber: DEFAULT_PAGINATION.ACTIVE_PAGE_NUMBER,
          pageSize: DEFAULT_PAGINATION.PAGE_SIZE,
          totalCount: null,
          totalPageNumber: null
        }
      };

    case AuthenticationActions.ActionTypes.Logout:
      return initialState;

    case AuthenticationActions.ActionTypes.ChangeActiveProjectSuccessful:
      return initialState;

    default:
      return state;
  }
}
