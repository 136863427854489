import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ProjectDocumentControllerService } from '@rappider/rappider-sdk';
import { NotificationService } from '@rappider/services';
import { Navigate } from '@rappider/shared';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import * as ProjectDocumentActions from './project-document.actions';

@Injectable()
export class ProjectDocumentEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private notificationService: NotificationService,
    private projectDocumentApi: ProjectDocumentControllerService
  ) { }

  getProjectDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProjectDocumentActions.GetProjectDocuments
      ),
      withLatestFrom(
        this.store.select(state => state.activeProject.data?.id)
      ),
      mergeMap(([action, activeProjectId]) => {

        const filter = {
          where: { projectId: activeProjectId }
        };

        return this.projectDocumentApi.find({ filter }).pipe(
          map((projectDocuments) => ProjectDocumentActions.GetProjectDocumentsSuccessful({ payload: { projectDocuments } })),
          catchError(error => {
            this.notificationService.createNotification(
              'error',
              'SHARED.COULDNT_GET',
              'SHARED.COULDNT_GET'
            );

            return [
              ProjectDocumentActions.ErrorAction({
                payload: {
                  error: error,
                  key: 'GetProjectDocuments',
                  timestamp: Date.now()
                }
              })
            ];
          })
        );
      })
    )
  );

  // createProjectDocument$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(
  //       ProjectDocumentActions.CreateProjectDocument
  //     ),
  //     withLatestFrom(
  //       this.store.select(state => state.activeProject.data?.id),
  //       this.store.select(state => state.projectDocument?.data)
  //     ),
  //     mergeMap(([action, activeProjectId, projectDocuments]) => {
  //       const projectDocument = {
  //         ...action.payload.projectDocument,
  //         projectId: activeProjectId
  //       };

  //       return this.projectDocumentApi.create({ body: projectDocument }).pipe(
  //         map(projectDocument => {
  //           const newProjectDocuments = [
  //             ...projectDocuments,
  //             projectDocument
  //           ];
  //           return ProjectDocumentActions.CreateProjectDocumentSuccessful({ payload: { projectDocuments: newProjectDocuments } });
  //         }), catchError(error => {
  //           this.notificationService.createNotification(
  //             'error',
  //             'SHARED.COULDNT_CREATED',
  //             'SHARED.COULDNT_CREATED'
  //           );

  //           return [
  //             ProjectDocumentActions.ErrorAction({
  //               payload: {
  //                 error: error,
  //                 key: 'CreateProjectDocuments',
  //                 timestamp: Date.now()
  //               }
  //             })
  //           ];
  //         })
  //       );
  //     })
  //   )
  // );

  createProjectDocumentSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProjectDocumentActions.CreateProjectDocumentSuccessful
      ), mergeMap((action) => {
        this.notificationService.createNotification(
          'success',
          'SHARED.SUCCESSFULLY_CREATED',
          'SHARED.SUCCESSFULLY_CREATED'
        );
        return [new Navigate({ url: PATH_DEFINITIONS.PROJECTS.PROJECT_DOCUMENT_LIST })];
      })
    )
  );

  createProjectDocumentUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProjectDocumentActions.CreateProjectDocumentUpload
      ),
      mergeMap((action) => {
        const params = {
          body: action.payload.projectDocuments
        };
        const outputObject = {
          body: {
            title: params.body.title,
            files: params.body.files
          }
        };
        return this.projectDocumentApi.uploadDocument(params).pipe(
          mergeMap((uploadedDocument: any) => {
            this.notificationService.createNotification(
              'success',
              'Success',
              'SHARED.SUCCESSFULLY_CREATED'
            );
            return [
              ProjectDocumentActions.CreateProjectDocumentUploadSuccessful({ payload: { projectDocuments: uploadedDocument } }),
              new Navigate({ url: PATH_DEFINITIONS.PROJECTS.PROJECT_DOCUMENT_LIST })
            ];
          }), catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'SHARED.COULDNT_CREATED'
            );
            return [
              ProjectDocumentActions.CreateProjectDocumentUploadFailure({ payload: { error, key: 'CreateProjectDocumentUploadFailure', timestamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

  getProjectDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProjectDocumentActions.ActionTypes.GetProjectDocumentUpload
      ),
      mergeMap((action) =>
        this.projectDocumentApi.getDocument({ id: (action as { payload: { id: string } }).payload.id }).pipe(
          map((projectDocuments: any) =>
            ProjectDocumentActions.GetProjectDocumentUploadSuccessful({ payload: { source: projectDocuments } })
          ),
          catchError((error) => [
            ProjectDocumentActions.CreateProjectDocumentUploadFailure({ payload: { error, key: 'CreateProjectDocumentUploadFailure', timestamp: Date.now() } })
          ])
        )
      )
    )
  );

  // updateProjectDocument$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(
  //       ProjectDocumentActions.UpdateProjectDocument
  //     ),
  //     withLatestFrom(
  //       this.store.select(state => state.projectDocument?.data)
  //     ),
  //     mergeMap(([action, projectDocuments]) => {
  //       const params = {
  //         id: action.payload.projectDocumentId,
  //         body: action.payload.projectDocument
  //       };

  //       return this.projectDocumentApi.updateById(params).pipe(
  //         map(() => {
  //           projectDocuments = projectDocuments.filter(document => document.id !== action.payload.projectDocumentId);

  //           const newProjectDocuments = [
  //             ...projectDocuments,
  //             action.payload.projectDocument
  //           ];

  //           return ProjectDocumentActions.UpdateProjectDocumentSuccessful({ payload: { projectDocuments: newProjectDocuments } });
  //         }), catchError(error => {
  //           this.notificationService.createNotification(
  //             'error',
  //             'SHARED.COULDNT_UPDATED',
  //             'SHARED.COULDNT_UPDATED'
  //           );

  //           return [
  //             ProjectDocumentActions.ErrorAction({
  //               payload: {
  //                 error: error,
  //                 key: 'UpdateProjectDocument',
  //                 timestamp: Date.now()
  //               }
  //             })
  //           ];
  //         })
  //       );
  //     })
  //   )
  // );

  updateProjectDocumentSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProjectDocumentActions.UpdateProjectDocumentSuccessful
      ), mergeMap((action) => {
        this.notificationService.createNotification(
          'success',
          'SHARED.SUCCESSFULLY_UPDATED',
          'SHARED.SUCCESSFULLY_UPDATED'
        );
        return [new Navigate({ url: PATH_DEFINITIONS.PROJECTS.PROJECT_DOCUMENT_LIST })];
      })
    )
  );

  deleteProjectDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProjectDocumentActions.DeleteProjectDocument
      ),
      withLatestFrom(
        this.store.select(state => state.projectDocument?.data)
      ),
      mergeMap(([action, projectDocuments]) => this.projectDocumentApi.deleteById({ id: action.payload.projectDocumentId }).pipe(
        map(() => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFULLY_DELETED',
            'SHARED.SUCCESSFULLY_DELETED'
          );

          const newProjectDocuments = projectDocuments.filter(document => document.id !== action.payload.projectDocumentId);
          return ProjectDocumentActions.DeleteProjectDocumentSuccessful({ payload: { projectDocuments: newProjectDocuments } });
        }), catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.COULDNT_DELETED',
            'SHARED.COULDNT_DELETED'
          );

          return [
            ProjectDocumentActions.ErrorAction({
              payload: {
                error: error,
                key: 'DeleteProjectDocument',
                timestamp: Date.now()
              }
            })
          ];
        })
      ))
    )
  );
}
