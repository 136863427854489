import {
  ActionBehavior,
  CrudFormButtonItem,
  CrudFormConfig,
  CrudFormConfigInputChangeReaction,
  CrudFormRichTextEditorItem,
  CrudFormSelectItem,
  CrudFormTextBoxItem,
  CrudTableViewConfig,
  CrudViewColumnType,
  CrudViewFormItemType,
  FormLayout,
  InputSize,
  ButtonType,
  HeadingType
} from '@rappider/rappider-components/utils';
import { Validators } from '@angular/forms';
import { PATH_DEFINITIONS } from '../../definitions/path-definition';
import { DATA_FIELD_NAME_REGEX, KEBAB_CASE_REGEX } from '../../definitions/regex';
import { POP_CONFIRM_DELETE_ACTION } from '../pop-confirm-button/pop-confirm-button-config';
import { MessageTemplateType } from './../../../../../project/src/lib/components/message-template/utils/message-template-type.enum';

export const AVAILABLE_DATA_FIELD_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'title',
  title: {
    content: 'PROJECT_MODULE.PROJECT_MESSAGE_TEMPLATE_COMPONENT.AVAILABLE_DATA_FIELD_LIST',
    type: HeadingType.H5
  },
  columns: [
    {
      title: 'SHARED.KEY',
      fieldName: 'fieldName',
      type: CrudViewColumnType.Text,
      visible: true
    },
    {
      title: 'SHARED.TITLE',
      fieldName: 'title',
      type: CrudViewColumnType.Text,
      visible: true
    }
  ],
  listActions: [],
  itemActions: []
};

export const CREATE_OR_EDIT_MESSAGE_TEMPLATE_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormTextBoxItem>{
      title: 'SHARED.KEY',
      fieldName: 'key',
      type: CrudViewFormItemType.TextBox,
      index: 1,
      size: InputSize.Default,
      placeholder: 'e.g. send-register-message',
      showRequiredSign: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          type: Validators.pattern(KEBAB_CASE_REGEX),
          errorKey: 'pattern',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.MUST_BE_KEBAB_CASE'
        }
      ],
      cssStyle: { width: '33%' }

    },
    <CrudFormSelectItem>{
      title: 'SHARED.TYPE',
      fieldName: 'type',
      index: 3,
      type: CrudViewFormItemType.Select,
      size: InputSize.Default,
      placeholder: 'SHARED.TYPE',
      showRequiredSign: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ],
      options: [
        { key: 'E-mail', value: MessageTemplateType.Email },
        { key: 'Sms', value: MessageTemplateType.Sms },
        { key: 'Other', value: MessageTemplateType.Other }
      ],
      cssStyle: { width: '33%' }
    },
  ],
  validators: [
  ],
  itemSettings: {
    inputComponentSize: {
      xs: 24
    }
  },
  submitButton: {
    type: ButtonType.Primary
  },
  inputChangeReaction: CrudFormConfigInputChangeReaction.Blur,
  formLayout: FormLayout.Vertical,
};

export const QUILL_MENTION_CONFIG = <CrudFormRichTextEditorItem>{
  title: 'PROJECT_MODULE.PROJECT_MESSAGE_TEMPLATE_COMPONENT.TEMPLATE_BODY',
  fieldName: 'template',
  index: 5,
  type: CrudViewFormItemType.RichTextEditor,
};

export const TEXTBOX_MENTION_CONFIG = <CrudFormTextBoxItem>{
  title: 'SHARED.SUBJECT',
  fieldName: 'subject',
  type: CrudViewFormItemType.TextBox,
  index: 4,
  placeholder: 'SHARED.SUBJECT',
  showRequiredSign: true,
  validators: [
    {
      type: Validators.required,
      errorKey: 'required',
      errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
    }
  ],
  mentionPrefix: '#'
};

export const ENVIRONMENT_KEY_SELECTBOX_ITEM = <CrudFormSelectItem>{
  title: 'PROJECT_MODULE.PROJECT_ENVIRONMENT_VARIABLES_COMPONENT.ENVIRONMENT',
  fieldName: 'environmentKey',
  index: 2,
  type: CrudViewFormItemType.Select,
  size: InputSize.Default,
  placeholder: 'PROJECT_MODULE.PROJECT_ENVIRONMENT_VARIABLES_COMPONENT.ENVIRONMENT',
  validators: [
    {
      type: Validators.required,
      errorKey: 'required',
      errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
    }
  ],
  options: [],
  cssStyle: { width: '33%' }
};

export const MESSAGE_TEMPLATE_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'key',
  columns: [
    {
      title: 'SHARED.ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'SHARED.KEY',
      fieldName: 'key',
      type: CrudViewColumnType.Text,
      visible: true
    },
    {
      title: 'SHARED.TYPE',
      fieldName: 'type',
      type: CrudViewColumnType.Text,
      visible: true
    },
    {
      title: 'SHARED.CREATED_BY',
      fieldName: 'createdBy',
      type: CrudViewColumnType.Text,
      visible: true
    },
    {
      title: 'SHARED.CREATED_DATE',
      fieldName: 'createdDate',
      type: CrudViewColumnType.DateTime,
      visible: true
    }
  ],
  listActions: [
    {
      name: 'PROJECT_MODULE.PROJECT_MESSAGE_TEMPLATE_COMPONENT.CREATE_MESSAGE_TEMPLATE',
      text: 'PROJECT_MODULE.PROJECT_MESSAGE_TEMPLATE_COMPONENT.CREATE_MESSAGE_TEMPLATE',
      buttonType: ButtonType.Default,
      icon: { name: 'fas fa-plus' },
      behavior: ActionBehavior.Route,
      redirectUrl: PATH_DEFINITIONS.PROJECTS.PROJECT_MESSAGE_TEMPLATE
    }
  ],
  itemActions: [
    {
      text: 'SHARED.EDIT',
      behavior: ActionBehavior.Route,
      redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_EDIT_MESSAGE_TEMPLATE}/{{id}}`,
      icon: { name: 'far fa-edit' }
    },
    {
      text: POP_CONFIRM_DELETE_ACTION.text,
      name: POP_CONFIRM_DELETE_ACTION.name,
      popconfirmTitle: POP_CONFIRM_DELETE_ACTION.popconfirmTitle,
      emitWithoutPopconfirm: POP_CONFIRM_DELETE_ACTION.emitWithoutPopconfirm,
      behavior: POP_CONFIRM_DELETE_ACTION.behavior,
      icon: POP_CONFIRM_DELETE_ACTION.icon,
      popConfirmCancelText: POP_CONFIRM_DELETE_ACTION.popConfirmCancelText,
      popConfirmOkText: POP_CONFIRM_DELETE_ACTION.popConfirmOkText,
      popConfirmOkDanger: POP_CONFIRM_DELETE_ACTION.popConfirmOkDanger,
    }
  ]
};
