import {
  CrudViewFormItemType,
  CrudFormConfig,
  CrudFormTextBoxItem,
  TextBoxType,
  FormLayout,
  CrudFormButtonItem,
  ActionBehavior,
  InputSize,
  ButtonType
} from '@rappider/rappider-components/utils';
import { Validators } from '@angular/forms';
import { PATH_DEFINITIONS } from '../../definitions/path-definition';

export const LOGIN_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormTextBoxItem>{
      title: 'Username',
      fieldName: 'username',
      placeholder: 'Username',
      type: CrudViewFormItemType.TextBox,
      required: true,
      index: 1,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'AUTHENTICATION_MODULE.LOGIN_COMPONENT.USERNAME_REQUIRED'
        }
      ],
      size: InputSize.Large
    },
    <CrudFormTextBoxItem>{
      title: 'Password',
      fieldName: 'password',
      placeholder: 'Password',
      type: CrudViewFormItemType.TextBox,
      textType: TextBoxType.Password,
      index: 4,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'AUTHENTICATION_MODULE.LOGIN_COMPONENT.PASSWORD_REQUIRED'
        }
      ],
      size: InputSize.Large
    },
    <CrudFormButtonItem>{
      text: 'AUTHENTICATION_MODULE.FORGOT_PASSWORD',
      type: CrudViewFormItemType.Button,
      behavior: ActionBehavior.Route,
      redirectUrl: `${PATH_DEFINITIONS.AUTH.FORGOT_PASSWORD}`,
      buttonType: ButtonType.Link,
      index: 5,
      cssStyle: {
        padding: 0,
        float: 'right'
      }
    }
  ],
  itemSettings: {
    inputComponentSize: {
      xs: 24
    },
    labelComponentSize: {
      xs: 24
    }
  },
  submitButton: {
    text: 'AUTHENTICATION_MODULE.LOGIN',
    size: 'large',
    block: true,
    type: ButtonType.Primary
  },
  formLayout: FormLayout.Vertical
};

export const LOGIN_FEEDBACK_CONFIG = {
  title: 'AUTHENTICATION_MODULE.LOGGING_IN',
  icon: 'fas fa-spinner fa-spin',
};
