import {
  ButtonType,
  ActionBehavior,
  CrudTableViewConfig,
  CrudViewColumnType
} from '@rappider/rappider-components/utils';

export const DATA_MAPPINGS_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'sourceFields',
  columns: [
    {
      title: 'PROJECT_MODULE.DATA_MAPPING_CREATE_COMPONENT.SOURCE_FIELDS',
      fieldName: 'sourceFields',
      type: CrudViewColumnType.Text
    },
    {
      title: 'PROJECT_MODULE.DATA_MAPPING_CREATE_COMPONENT.FUNCTION',
      fieldName: 'function',
      type: CrudViewColumnType.Text
    },
    {
      title: 'PROJECT_MODULE.DATA_MAPPING_CREATE_COMPONENT.TARGET_FIELD',
      fieldName: 'targetField',
      type: CrudViewColumnType.Text
    }
  ],
  searchable: false,
  columnVisibilitySelectable: false,
  listActions: [
    {
      text: 'PROJECT_MODULE.DATA_MAPPINGS_COMPONENT.ADD_DATA_MAPPING',
      buttonType: ButtonType.Default,
      behavior: ActionBehavior.Emit,
      icon: { name: 'fas fa-plus' }
    }
  ],
  itemActions: [
    {
      text: 'SHARED.DELETE',
      name: 'SHARED.DELETE',
      behavior: ActionBehavior.Emit,
      icon: { name: 'far fa-trash' }
    }
  ]
};
