import { IconType, ImportProjectModelOption, PATH_DEFINITIONS, QUERY_PARAM_DEFINITIONS } from '@rappider/shared/definitions';
import {
  ButtonType,
  ActionBehavior,
  CrudTableViewConfig,
  CrudViewColumnType,
  ListGridOrderType,
  HorizontalPosition,
  DropdownMenuPlacement,
  MenuActionBehavior
} from '@rappider/rappider-components/utils';

export const PROJECT_MODEL_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'name',
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewColumnType.Link,
      routerLink: `${PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_DATA_FIELD_LIST}/{{id}}`,
      queryParams: QUERY_PARAM_DEFINITIONS.PROJECT.DATA_FIELD_LIST_COMPONENT.FIELDS_TAB
    },
    {
      title: 'PROJECT_MODULE.PROJECT_MODEL_LIST_COMPONENT.DATA_TABLE_NAME',
      fieldName: 'dataTableName',
      type: CrudViewColumnType.Text
    },
    {
      title: 'PROJECT_MODULE.PROJECT_MODEL_LIST_COMPONENT.BASE_PATH',
      fieldName: 'basePath',
      type: CrudViewColumnType.Text
    },
    {
      title: 'PROJECT_MODULE.PROJECT_MODEL_LIST_COMPONENT.IS_STRICT',
      fieldName: 'isStrict',
      type: CrudViewColumnType.Boolean
    },
    {
      title: 'Project Id',
      fieldName: 'projectId',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'PROJECT_MODULE.PROJECT_MODEL_LIST_COMPONENT.DATA_STORE_RELATED',
      fieldName: 'generateUIDataStore',
      type: CrudViewColumnType.Boolean
    },
    {
      title: 'PROJECT_MODULE.PROJECT_MODEL_LIST_COMPONENT.CREATED_BY',
      fieldName: 'createdBy',
      type: CrudViewColumnType.Text
    }
  ],
  listActions: [
    {
      text: 'PROJECT_MODULE.PROJECT_MODEL_CREATE_COMPONENT.ADD_PROJECT_MODEL',
      buttonType: ButtonType.Default,
      icon: { name: 'fas fa-plus' },
      behavior: ActionBehavior.Route,
      redirectUrl: PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_CREATE,
    },
    {
      text: 'Create Project Model',
      name: 'createProjectModel',
      buttonType: ButtonType.Default,
      icon: {
        name: 'down',
        type: IconType.NgZorro
      },
      behavior: ActionBehavior.DropDownMenu,
      horizontalPosition: HorizontalPosition.Right,
      dropdownConfig: {
        label: 'Create Project Model',
        placement: DropdownMenuPlacement.BottomLeft,
        items: [
          {
            key: ImportProjectModelOption.ImportFromJson,
            label: 'Import From JSON Data',
            actionBehavior: MenuActionBehavior.Emit
          },
          {
            key: ImportProjectModelOption.ImportFromPostgreSQL,
            label: 'Import From PostgreSQL Metadata',
            actionBehavior: MenuActionBehavior.Emit
          },
          {
            key: ImportProjectModelOption.ImportFromOpenApiSpecs,
            label: 'Import From OpenAPI Specs',
            actionBehavior: MenuActionBehavior.Emit
          }
        ]
      }
    }
  ],
  itemActions: [
    {
      text: 'Edit',
      name: 'EDIT',
      behavior: ActionBehavior.Route,
      redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_EDIT}/{{id}}`,
      icon: { name: 'far fa-edit' },
    },
    {
      text: 'Delete',
      icon: { name: 'far fa-trash' },
      name: 'DELETE',
      behavior: ActionBehavior.Emit
    }
  ],
  handleVisibilityFn: (data, item, action) => {
    if (action.name === 'EDIT' && item.isUpdatable) {
      return true;
    }
    if (action.name === 'DELETE' && item.isDeletable) {
      return true;
    }
  },
  order: {
    fieldName: 'name',
    type: ListGridOrderType.Ascending
  }
};
