/* Angular */
import { createAction, createReducer, on } from '@ngrx/store';

/* Actions */
import * as ProcessInfoActions from './process-info.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import { ProcessInfoWithRelations } from '@rappider/rappider-sdk';

/* State key */
export const featureKey = 'processInfo';

const ChangeActiveProject = createAction(AuthenticationActions.ActionTypes.ChangeActiveProject);
const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

/* State interface */
export interface State {
  data: ProcessInfoWithRelations[];
  lastPollingDate?: string;
  error: any;
}

/* Initial values */
export const initialState: State = {
  data: [],
  lastPollingDate: null,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(ProcessInfoActions.GetProcessInfos, (state, action) => ({
    ...state
  })),
  on(ProcessInfoActions.GetProcessInfosSuccessful, (state, action) => ({
    ...state,
    data: action.payload.processInfos,
    lastPollingDate: new Date().toUTCString()
  })),
  on(ProcessInfoActions.UpdateLastPollingDateToNow, (state, action) => ({
    ...state,
    lastPollingDate: new Date().toUTCString()
  })),
  on(ProcessInfoActions.AddProcessInfo, (state, action) => ({
    ...state,
    data: [
      ...state.data.filter(datum => datum.id !== action.payload.processInfo.id),
      action.payload.processInfo
    ]
  })),
  on(ProcessInfoActions.Error, (state, action) => ({
    ...state,
    error: action.payload.error
  })),
  on(ChangeActiveProject, () => initialState),
  on(Logout, () => initialState)
);
