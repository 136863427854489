<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton"></rappider-title-toolbar>
<section>
  <div class="search-collapse">
    <rappider-input-group [(ngModel)]="searchText"
                          [textbox]="inputGroupConfig.textbox"
                          [suffixIcon]="inputGroupConfig.suffixIcon"
                          (ngModelChange)="onSearchTextChange()"></rappider-input-group>
    <div class="collapse-toggle">
      <rappider-button class="collapse-button"
                       [text]="expandButtonConfig.text"
                       [key]="expandButtonConfig.key"
                       [icon]="expandButtonConfig.icon"
                       (confirm)="toggleCollapsePanelVisibility(expandButtonConfig.key)">
      </rappider-button>&nbsp;&nbsp;
      <rappider-button [text]="collapseButtonConfig.text"
                       [key]="collapseButtonConfig.key"
                       [icon]="collapseButtonConfig.icon"
                       (confirm)="toggleCollapsePanelVisibility(collapseButtonConfig.key)">
      </rappider-button>
    </div>
  </div>
</section>
<nz-collapse>
  <nz-collapse-panel *ngFor="let data of searchText ? searchResults: uiWorkflowStepFunctionsListData"
                     [nzHeader]="data.uiDataStore?.name"
                     [nzActive]="data.active"
                     [nzActive]="collapseToggle"
                     [nzExtra]="data?.uiDataStore?.isReadOnly ? null : createButtonTemplate">
    <div *ngIf="data?.uiStepFunctions?.length > 0; else uiStepFunctionsEmptyList">
      <rappider-ui-step-function-list-detail [config]="UI_STEP_FUNCTIONS_LIST_CONFIG"
                                             [uiStepFunctionData]="data.uiStepFunctions"
                                             [isLoading]="isDataTransformationsLoading || loading"
                                             (uiStepFunctionPreData)="onPreDataTransformationClick($event)"
                                             (uiStepFunctionDelete)="onUiStepFunctionDeleteClick($event)"
                                             (uiStepFunctionPostData)="onPostDataTransformationClick($event)"></rappider-ui-step-function-list-detail>
    </div>
    <ng-template #uiStepFunctionsEmptyList>
      <rappider-ui-step-function-list-detail [config]="UI_STEP_FUNCTIONS_EMPTY_LIST_CONFIG"
                                             (uiStepFunctionCreate)="navigateToUIStepFunctionCreate(data.uiDataStore?.id)"></rappider-ui-step-function-list-detail>
    </ng-template>
    <ng-template #createButtonTemplate>
      <rappider-button nz-tooltip
                       [nzTooltipTitle]="'Add UI Step Function'"
                       [icon]="createUiStepFunctionButton.icon"
                       [size]="createUiStepFunctionButton.size"
                       (click)="navigateToUIStepFunctionCreate(data.uiDataStore?.id)"></rappider-button>
    </ng-template>
  </nz-collapse-panel>
</nz-collapse>
<rappider-modal *ngIf="preSourceJsonSchema && preDataTransformationId && isPreDataTransformationModalVisible"
                [(visible)]="isPreDataTransformationModalVisible"
                [title]="preDataTransformationTitleTemplate"
                [fullScreen]="true"
                (cancelClick)="handlePreDataTransformationModalVisibility()"
                (okClick)="handlePreDataTransformationModalVisibility()">
  <rappider-spin [spinning]="isDataTransformationsLoading && !preDataTransformationId">
    <rappider-data-transformation *ngIf="preSourceJsonSchema && preDataTransformationId && isPreDataTransformationModalVisible"
                                  [dataTransformationId]="preDataTransformationId"
                                  [schema]="preSourceJsonSchema"
                                  [dataTransformationFlag]="dataTransformationFlag"
                                  (showStepsFlag)="onShowStepsFlag($event)"
                                  [targetSchema]="preTargetJsonSchema"></rappider-data-transformation>
  </rappider-spin>
</rappider-modal>
<ng-template #preDataTransformationTitleTemplate>
  <div class="dt-modal-title">
    <span>Pre Data Transformation</span>
    <span class="info-icon"
          nz-icon
          title="Show Help"
          nzType="info-circle"
          (click)="setStepsFlag('data-transformation')"></span>
  </div>
</ng-template>

<rappider-modal *ngIf="postSourceJsonSchema && postDataTransformationId && isPostDataTransformationModalVisible"
                [(visible)]="isPostDataTransformationModalVisible"
                [title]="postDataTransformationTitleTemplate"
                [fullScreen]="true"
                (cancelClick)="handlePostDataTransformationModalVisibility()"
                (okClick)="handlePostDataTransformationModalVisibility()">
  <rappider-spin [spinning]="isDataTransformationsLoading && !postDataTransformationId">
    <rappider-data-transformation *ngIf="postSourceJsonSchema && postDataTransformationId && isPostDataTransformationModalVisible"
                                  [dataTransformationId]="postDataTransformationId"
                                  [schema]="postSourceJsonSchema"
                                  [dataTransformationFlag]="dataTransformationFlag"
                                  (showStepsFlag)="onShowStepsFlag($event)"
                                  [targetSchema]="postTargetJsonSchema"></rappider-data-transformation>
  </rappider-spin>
</rappider-modal>
<ng-template #postDataTransformationTitleTemplate>
  <div class="dt-modal-title">
    <span>Post Data Transformation</span>
    <span class="info-icon"
          nz-icon
          title="Show Help"
          nzType="info-circle"
          (click)="setStepsFlag('data-transformation')"></span>
  </div>
</ng-template>
