<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"></rappider-title-toolbar>

<section class="all-projects">
  <rappider-list-grid [config]="ALL_PROJECTS_CONFIG"
                      [data]="allProjects"
                      [loading]="isLoading"
                      [pageNumber]="pageIndex"
                      (columnActionClick)="onColumnActionClick($event)"
                      (paginationChange)="paginationChange($event)"
                      (searchTextChange)="searchTextChange($event)"
                      (columnItemClick)="onColumnItemClick($event)"></rappider-list-grid>
</section>
<rappider-modal [visible]="modalVisibility"
                [title]="modalTitle"
                [okText]="'Submit'"
                [closeIconVisibility]="false"
                [okDisabled]="!isFormValid"
                (cancelClick)="formCancel()"
                (okClick)="formSubmit()">
                <rappider-crud-view-edit-form [config]="ADMIN_PROJECTS_FORM_CONFIG"
                                              [data]="projectData"
                                              [submitted]="isFormSubmit"
                                              (validityChange)="onValidityChange($event)"
                                              (formValueChange)="onFormValueChange($event)"></rappider-crud-view-edit-form>
</rappider-modal>
