import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { WorkflowStepFunctionPublishedEventOnFailure, WorkflowStepFunctionPublishedEventOnFailureControllerService, WorkflowStepFunctionPublishedEventOnFailureWithRelations } from '@rappider/rappider-sdk';
import { NotificationService } from '@rappider/services';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import * as WorkflowStepFunctionPublishedEventOnFailureActions from './workflow-step-function-published-event-on-failure.actions';
import * as WorkflowStepFunctionActions from '../workflow-step-function/workflow-step-function.actions';

@Injectable()
export class WorkflowStepFunctionPublishedEventOnFailureEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private notificationService: NotificationService,
    private workflowStepFunctionPublishedEventOnFailureApi: WorkflowStepFunctionPublishedEventOnFailureControllerService,
  ) { }

  createWorkflowStepFunctionPublishedEventOnFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowStepFunctionPublishedEventOnFailureActions.CreateWorkflowStepFunctionPublishedEventOnFailure
      ),
      mergeMap((action) => {
        const params = {
          body: action.payload.workflowStepFunctionPublishedEventOnFailure
        };

        return this.workflowStepFunctionPublishedEventOnFailureApi.create(params).pipe(
          map((workflowStepFunctionPublishedEventOnFailure: WorkflowStepFunctionPublishedEventOnFailure) => {
            this.notificationService.createNotification(
              'success',
              'success',
              'SHARED.SUCCESSFULLY_CREATED'
            );
            return WorkflowStepFunctionPublishedEventOnFailureActions.CreateWorkflowStepFunctionPublishedEventOnFailureSuccessful({ payload: { workflowStepFunctionPublishedEventOnFailure } });
          }), catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'SHARED.COULDNT_CREATED'
            );
            return [
              WorkflowStepFunctionPublishedEventOnFailureActions.CreateWorkflowStepFunctionPublishedEventOnFailureFailure({ payload: { error, key: 'CreateWorkflowStepFunctionPublishedEventOnFailureFailure', timestamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

  getWorkflowStepFunctionPublishedEventOnFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowStepFunctionActions.GetWorkflowStepFunctionSuccessful
      ),
      withLatestFrom(
        this.store.select(state => state.workflowStepFunction?.data)
      ),
      mergeMap(([action, workflowStepFunction]) => {
        const workflowStepFunctionIds = workflowStepFunction.map(workflowStepFunctionItem => workflowStepFunctionItem.id);
        const params = {
          filter: {
            where: {
              workflowStepFunctionId: { inq: workflowStepFunctionIds }
            }
          }
        };
        return this.workflowStepFunctionPublishedEventOnFailureApi.find(params).pipe(
          map((workflowStepFunctionPublishedEventOnFailures: WorkflowStepFunctionPublishedEventOnFailureWithRelations[]) =>
            WorkflowStepFunctionPublishedEventOnFailureActions.GetWorkflowStepFunctionPublishedEventOnFailureSuccessful({ payload: { workflowStepFunctionPublishedEventOnFailures } })
          ),
          catchError((error) => [
            WorkflowStepFunctionPublishedEventOnFailureActions.GetWorkflowStepFunctionPublishedEventOnFailureFailure({ payload: { error, key: 'GetWorkflowStepFunctionPublishedEventOnFailureFailure', timestamp: Date.now() } })
          ])
        );
      })
    )
  );

  updateWorkflowStepFunctionPublishedEventOnFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowStepFunctionPublishedEventOnFailureActions.UpdateWorkflowStepFunctionPublishedEventOnFailure
      ),
      mergeMap((action) => {
        const params = {
          id: action.payload.workflowStepFunctionPublishedEventOnFailureId,
          body: action.payload.workflowStepFunctionPublishedEventOnFailureBody
        };

        return this.workflowStepFunctionPublishedEventOnFailureApi.updateById(params).pipe(
          map(() => {
            this.notificationService.createNotification(
              'success',
              'success',
              'SHARED.SUCCESSFULLY_UPDATED'
            );
            const workflowStepFunctionPublishedEventOnFailureBody = {
              ...action.payload.workflowStepFunctionPublishedEventOnFailureBody
            } as WorkflowStepFunctionPublishedEventOnFailureWithRelations;
            return WorkflowStepFunctionPublishedEventOnFailureActions.UpdateWorkflowStepFunctionPublishedEventOnFailureSuccessful({ payload: { workflowStepFunctionPublishedEventOnFailureId: action.payload.workflowStepFunctionPublishedEventOnFailureId, workflowStepFunctionPublishedEventOnFailureBody } });
          }), catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'SHARED.COULDNT_UPDATED'
            );
            return [
              WorkflowStepFunctionPublishedEventOnFailureActions.UpdateWorkflowStepFunctionPublishedEventOnFailureFailure({ payload: { error, key: 'UpdateWorkflowStepFunctionPublishedEventOnFailureFailure', timestamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

  deleteWorkflowStepFunctionPublishedEventOnFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowStepFunctionPublishedEventOnFailureActions.DeleteWorkflowStepFunctionPublishedEventOnFailure
      ),
      mergeMap((action) => {
        const params = {
          id: action.payload.workflowStepFunctionPublishedEventOnFailureId
        };
        return this.workflowStepFunctionPublishedEventOnFailureApi.deleteById(params).pipe(
          map(() => {
            this.notificationService.createNotification(
              'success',
              'SHARED.SUCCESS',
              'SHARED.SUCCESSFULLY_DELETED'
            );
            return WorkflowStepFunctionPublishedEventOnFailureActions.DeleteWorkflowStepFunctionPublishedEventOnFailureSuccessful({ payload: { workflowStepFunctionPublishedEventOnFailureId: action.payload.workflowStepFunctionPublishedEventOnFailureId } });
          }, catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'SHARED.COULDNT_DELETED'
            );
            return [
              WorkflowStepFunctionPublishedEventOnFailureActions.DeleteWorkflowStepFunctionPublishedEventOnFailureFailure({ payload: { error, key: 'DeleteWorkflowStepFunctionPublishedEventOnFailureFailure', timestamp: Date.now() } })
            ];
          }))
        );
      })
    )
  );
}
