<!-- ----------------------------------------------------------------------------------------- -->
<!-- TITLE -->
<rappider-title-toolbar *ngIf="title"
                        [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>
<!-- / TITLE -->
<!-- ----------------------------------------------------------------------------------------- -->

<!-- ----------------------------------------------------------------------------------------- -->
<!-- LOADING SKELETON -->
<section *ngIf="loading || !projectVersions">
  <nz-skeleton [nzActive]="true"></nz-skeleton>
</section>
<!-- / LOADING SKELETON -->
<!-- ----------------------------------------------------------------------------------------- -->

<!-- ----------------------------------------------------------------------------------------- -->
<!-- PROJECT VERSIONS -->
<section *ngIf="!loading && projectVersions">
  <rappider-list-grid [config]="PROJECT_VERSION_LIST_CONFIG"
                      [data]="projectVersions"></rappider-list-grid>
</section>
<!-- ----------------------------------------------------------------------------------------- -->
<!-- / PROJECT VERSIONS -->
