import { createSelector } from '@ngrx/store';
import { ModuleWithRelations, ProjectSetting } from '@rappider/rappider-sdk';

/**
 * Selector to get modules with an indication of which module is the default.
 * @param state - The state of the store.
 * @returns  An array of modules with an additional property `isDefaultModule` indicating if the module is the default.
*/
export const getModulesWithDefaultModule = createSelector(
  state => state['module']?.data,
  state => state['projectSetting']?.data,
  (modules: ModuleWithRelations[], projectSettings: ProjectSetting[]) => {
    if (modules?.length && projectSettings?.length) {
      const defaultModuleId = projectSettings?.find(setting => setting.key === 'defaultModuleId')?.value;
      if (!defaultModuleId) {
        return (modules as Array<ModuleWithRelations>) || [];
      } else {
        return modules.map(module => ({
          ...module,
          isDefaultModule: module.id === defaultModuleId
        })) as Array<ModuleWithRelations & { isDefaultModule?: boolean }>;
      }
    } else {
      return modules?.map(module => ({ ...module, isDefaultModule: false })) as Array<ModuleWithRelations & { isDefaultModule?: boolean }> || [];
    }
  }
);

