import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ProjectExternalScriptApi, ProjectExternalScriptInterface, ProjectFileInterface, ProjectInterface } from '@rappider/api-sdk';
import { BreadcrumbOption, CodeMirrorMode, CodeMirrorSettings, CodeMirrorTheme, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import {
  PAGE_DEFINITIONS,
  ExternalScriptType,
  ProjectScriptTypeOptionsEnum,
  EXTERNAL_SCRIPT_TYPE_OPTIONS,
  SCRIPT_LOCATION_OPTIONS,
  SOURCE_TYPE_OPTIONS,
  defaultToolbarTitleHeadingSize,
  PATH_DEFINITIONS
} from '@rappider/shared/definitions';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormService } from '@rappider/services';
import { ActivatedRoute } from '@angular/router';
import { UpdateProjectScript } from '../../states/project-script-state/project-script.actions';

@Component({
  selector: 'rappider-project-script-edit',
  templateUrl: './project-script-edit.component.html',
  styleUrls: ['./project-script-edit.component.scss']
})
export class ProjectScriptEditComponent implements OnInit, OnDestroy {
  /* main title */
  mainTitle: HeadingComponentConfig;
  /* title of the page */
  title: string[] | BreadcrumbOption[];
  /* active project id */
  activeProjectId: string;
  /* subscriptions */
  subscriptions: Subscription[];
  /* user input for file type */
  type: ExternalScriptType;
  /* type options for selectbox */
  typeOptions = EXTERNAL_SCRIPT_TYPE_OPTIONS;
  /* location options for selectbox */
  SCRIPT_LOCATION_OPTIONS = SCRIPT_LOCATION_OPTIONS;
  /* asset type options for selectbox */
  SOURCE_TYPE_OPTIONS = SOURCE_TYPE_OPTIONS;
  /* user input for asset type */
  sourceType: ProjectScriptTypeOptionsEnum;
  /* content object */
  content: {
    visible?: boolean;
    codemirrorVisible?: boolean;
  } = {};
  /* file modal object */
  fileModal: {
    visible?: boolean;
  } = {};
  /* file upload modal */
  fileUploadModal: {
    visible?: boolean;
  } = {};
  /* selected file */
  selectedFile: ProjectFileInterface;
  /* Reactive Form */
  updateProjectScriptForm: FormGroup;
  /* form submit state */
  editProjectScriptFormSubmitted = false;
  /* external sciprt id */
  externalScriptId: string;
  /* external script */
  externalScript: ProjectExternalScriptInterface;

  /* codemirror settings */
  contentCodemirrorSettings: CodeMirrorSettings = {
    mode: CodeMirrorMode.Javascript,
    lineNumbers: true,
    autoCloseBrackets: true,
    theme: CodeMirrorTheme.Default
  };
  displayToolbar = false;
  displayToolbarBackButton = false;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<any>,
    private projectExternalScriptApi: ProjectExternalScriptApi,
    private formService: FormService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getExternalScriptIdFromUrl();
    this.getExternalScriptById();
    this.buildForm();
    this.subscribeToData();
    this.subscribeToLoadingState();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.getProjectId(),
      this.setAssetValidators().subscribe(),
      this.subscribeToLoadingState()
    ];
  }

  getExternalScriptIdFromUrl() {
    this.externalScriptId = this.activatedRoute.snapshot.params['id'];
  }

  /**
   * builds form
   *
   * @memberof AddProjectScriptComponent
   */
  buildForm() {
    this.updateProjectScriptForm = this.formBuilder.group({
      type: [null, Validators.required],
      location: [null, Validators.required],
      sourceType: [null, Validators.required],
      title: [null, Validators.required],
      content: [null],
      projectFile: [null]
    });
  }

  getProjectId() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectInterface) => {
      if (activeProject) {
        this.activeProjectId = activeProject.id;
        this.mainTitle = {
          content: 'PROJECT_MODULE.ADD_PROJECT_SCRIPT_COMPONENT.EDIT_PROJECT_SCRIPT',
          type: defaultToolbarTitleHeadingSize
        };
        this.title = [
          {
            label: activeProject.name,
            redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${activeProject?.id}`
          },
          {
            label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_SCRIPT_LIST.PAGE_TITLE,
            redirectUrl: PATH_DEFINITIONS.PROJECTS.PROJECT_SCRIPT_LIST

          },
          {
            label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.EDIT_PROJECT_SCRIPT.PAGE_TITLE
          }
        ];
      }
    });
  }
  subscribeToLoadingState() {
    return this.store.select(state => state.projectScript.loading).subscribe((isProjectScriptFormLoading: boolean) => {
      this.editProjectScriptFormSubmitted = isProjectScriptFormLoading;
    });
  }
  /**
   * get external script by id
   *
   * @memberof ProjectScriptEditComponent
   */
  getExternalScriptById() {
    const filter = { include: ['projectFile'] };

    this.projectExternalScriptApi.findById(this.externalScriptId, filter).subscribe((externalScript: ProjectExternalScriptInterface) => {
      this.externalScript = {
        type: externalScript.type,
        location: externalScript.location,
        sourceType: externalScript.sourceType,
        title: externalScript.title,
        content: externalScript.content || null,
        projectFile: externalScript.projectFile || null
      };
      this.setSelectedFile(externalScript.projectFile);

      this.setFormValues();
    });
  }

  /**
   * set form values to external script values
   *
   * @memberof ProjectScriptEditComponent
   */
  setFormValues() {
    this.updateProjectScriptForm.setValue(this.externalScript);
  }

  /**
   * handles validator by source type
   *
   * @returns
   * @memberof AddProjectScriptComponent
   */
  setAssetValidators() {
    const scriptTitle = this.updateProjectScriptForm.get('title');
    const content = this.updateProjectScriptForm.get('content');

    return this.updateProjectScriptForm.get('sourceType').valueChanges.pipe(
      map(sourceType => {
        this.sourceType = sourceType;
        if (sourceType === ProjectScriptTypeOptionsEnum.Custom) {
          content.setValidators([Validators.required]);
          scriptTitle.setValidators([Validators.required]);
          this.showContent();
        } else if (sourceType === ProjectScriptTypeOptionsEnum.File) {
          this.hideContent();
          content.setValidators(null);
          scriptTitle.setValidators(null);
        }
        content.updateValueAndValidity();
        scriptTitle.updateValueAndValidity();
      })
    );
  }

  showContent() {
    this.content.visible = true;
    /* TODO: temporary solution. Codemirror fix needed. (without timeout codemirror doesn't rendered properly.) */
    setTimeout(() => this.content.codemirrorVisible = true, 500);
  }

  hideContent() {
    this.content.visible = false;
    this.content.codemirrorVisible = false;
  }

  setSelectedFile(selectedFile: ProjectFileInterface) {
    this.selectedFile = selectedFile;
    this.updateProjectScriptForm.get('projectFile').setValue(this.selectedFile);
  }

  onTypeChange(type: ExternalScriptType) {
    this.type = type;
  }

  submitUpdateProjectScriptForm() {
    const updateProjectScriptForm = this.updateProjectScriptForm;
    const updateProjectScriptFormValue = this.updateProjectScriptForm.value;

    let projectExternalScript = {
      ...updateProjectScriptFormValue,
      projectId: this.activeProjectId
    };

    this.formService.checkFormValidation(this.updateProjectScriptForm);

    if (updateProjectScriptForm.valid) {
      /* if source type is file */
      if (this.sourceType === ProjectScriptTypeOptionsEnum.File) {
        projectExternalScript = {
          ...projectExternalScript,
          title: this.selectedFile.title
        };

        /* gets file id */
        const projectFileId = this.updateProjectScriptForm.value.projectFile?.id;
        /* if file exists */
        if (projectFileId) {
          /* content and file value delete ( content deleted because doesn't need content if source type is file) */
          delete projectExternalScript.content;
          delete projectExternalScript.projectFile;
          /* add projectFileId to form value */
          projectExternalScript.projectFileId = projectFileId;
        } else {
          return;
        }
      } else {
        /* if type is content, delete file because doesn't needed when type is content */
        delete projectExternalScript.projectFile;
      }
      this.store.dispatch(new UpdateProjectScript({ id: this.externalScriptId, projectScript: projectExternalScript }));
    }
  }

}
