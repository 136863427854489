import { PropertyType } from '@rappider/rappider-components/utils';

export const TYPE_OPTIONS = [
  {
    key: 'SHARED.STRING',
    value: PropertyType.String
  },
  {
    key: 'SHARED.NUMBER',
    value: PropertyType.Integer
  },
  {
    key: 'SHARED.BOOLEAN',
    value: PropertyType.Boolean
  },
  {
    key: 'SHARED.ARRAY',
    value: PropertyType.Array
  },
  {
    key: 'SHARED.OBJECT',
    value: PropertyType.Object
  }
];
