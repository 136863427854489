/* eslint-disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Person } from '../../models/Person';
import { SocketConnection } from '../../sockets/socket.connections';
import { User } from '../../models/User';
import { Tenant } from '../../models/Tenant';
import { RoleMapping } from '../../models/RoleMapping';
import { Role } from '../../models/Role';
import { PhoneNumber } from '../../models/PhoneNumber';
import { PersonProject } from '../../models/PersonProject';
import { Project } from '../../models/Project';
import { PersonProjectRole } from '../../models/PersonProjectRole';
import { ProjectRole } from '../../models/ProjectRole';
import { Metadata } from '../../models/Metadata';

/**
 * Api services for the `Person` model.
 */
@Injectable()
export class PersonApi extends BaseLoopBackApi {
  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SocketConnection) protected connection: SocketConnection,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http, connection, models, auth, errorHandler);
  }

  /**
   * Fetches belongsTo relation user.
   *
   * @param {any} id Person id
   *
   * @param {boolean} refresh
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public getUser(
    id: any,
    refresh: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/user';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null)
      _urlParams.refresh = refresh;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Fetches belongsTo relation tenant.
   *
   * @param {any} id Person id
   *
   * @param {boolean} refresh
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public getTenant(
    id: any,
    refresh: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/tenant';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null)
      _urlParams.refresh = refresh;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Find a related item by id for authorities.
   *
   * @param {any} id Person id
   *
   * @param {any} fk Foreign key for authorities
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public findByIdAuthorities(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/authorities/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Delete a related item by id for authorities.
   *
   * @param {any} id Person id
   *
   * @param {any} fk Foreign key for authorities
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdAuthorities(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/authorities/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Update a related item by id for authorities.
   *
   * @param {any} id Person id
   *
   * @param {any} fk Foreign key for authorities
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public updateByIdAuthorities(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/authorities/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Add a related item by id for authorities.
   *
   * @param {any} id Person id
   *
   * @param {any} fk Foreign key for authorities
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public linkAuthorities(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() + '/people/:id/authorities/rel/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Remove the authorities relation to an item by id.
   *
   * @param {any} id Person id
   *
   * @param {any} fk Foreign key for authorities
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public unlinkAuthorities(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() + '/people/:id/authorities/rel/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Check the existence of authorities relation to an item by id.
   *
   * @param {any} id Person id
   *
   * @param {any} fk Foreign key for authorities
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public existsAuthorities(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'HEAD';
    let _url: string =
      LoopBackConfig.getPath() + '/people/:id/authorities/rel/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Find a related item by id for phoneNumbers.
   *
   * @param {any} id Person id
   *
   * @param {any} fk Foreign key for phoneNumbers
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public findByIdPhoneNumbers(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() + '/people/:id/phone-numbers/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Delete a related item by id for phoneNumbers.
   *
   * @param {any} id Person id
   *
   * @param {any} fk Foreign key for phoneNumbers
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdPhoneNumbers(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() + '/people/:id/phone-numbers/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Update a related item by id for phoneNumbers.
   *
   * @param {any} id Person id
   *
   * @param {any} fk Foreign key for phoneNumbers
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public updateByIdPhoneNumbers(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() + '/people/:id/phone-numbers/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Find a related item by id for projects.
   *
   * @param {any} id Person id
   *
   * @param {any} fk Foreign key for projects
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public findByIdProjects(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/projects/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Delete a related item by id for projects.
   *
   * @param {any} id Person id
   *
   * @param {any} fk Foreign key for projects
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdProjects(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/projects/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Update a related item by id for projects.
   *
   * @param {any} id Person id
   *
   * @param {any} fk Foreign key for projects
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public updateByIdProjects(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/projects/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Add a related item by id for projects.
   *
   * @param {any} id Person id
   *
   * @param {any} fk Foreign key for projects
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public linkProjects(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() + '/people/:id/projects/rel/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Remove the projects relation to an item by id.
   *
   * @param {any} id Person id
   *
   * @param {any} fk Foreign key for projects
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public unlinkProjects(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() + '/people/:id/projects/rel/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Check the existence of projects relation to an item by id.
   *
   * @param {any} id Person id
   *
   * @param {any} fk Foreign key for projects
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public existsProjects(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'HEAD';
    let _url: string =
      LoopBackConfig.getPath() + '/people/:id/projects/rel/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Find a related item by id for roles.
   *
   * @param {any} id Person id
   *
   * @param {any} fk Foreign key for roles
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public findByIdRoles(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/roles/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Delete a related item by id for roles.
   *
   * @param {any} id Person id
   *
   * @param {any} fk Foreign key for roles
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdRoles(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/roles/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Update a related item by id for roles.
   *
   * @param {any} id Person id
   *
   * @param {any} fk Foreign key for roles
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public updateByIdRoles(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/roles/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Add a related item by id for roles.
   *
   * @param {any} id Person id
   *
   * @param {any} fk Foreign key for roles
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public linkRoles(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/roles/rel/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Remove the roles relation to an item by id.
   *
   * @param {any} id Person id
   *
   * @param {any} fk Foreign key for roles
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public unlinkRoles(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/roles/rel/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Check the existence of roles relation to an item by id.
   *
   * @param {any} id Person id
   *
   * @param {any} fk Foreign key for roles
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public existsRoles(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'HEAD';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/roles/rel/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Find a related item by id for metadata.
   *
   * @param {any} id Person id
   *
   * @param {any} fk Foreign key for metadata
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public findByIdMetadata(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/metadata/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Delete a related item by id for metadata.
   *
   * @param {any} id Person id
   *
   * @param {any} fk Foreign key for metadata
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdMetadata(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/metadata/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Update a related item by id for metadata.
   *
   * @param {any} id Person id
   *
   * @param {any} fk Foreign key for metadata
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public updateByIdMetadata(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/metadata/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Queries authorities of Person.
   *
   * @param {any} id Person id
   *
   * @param {object} filter
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public getAuthorities(
    id: any,
    filter: LoopBackFilter = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/authorities';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null)
      _urlParams.filter = filter;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in authorities of this model.
   *
   * @param {any} id Person id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public createAuthorities(
    id: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/authorities';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Deletes all authorities of this model.
   *
   * @param {any} id Person id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteAuthorities(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/authorities';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Counts authorities of Person.
   *
   * @param {any} id Person id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` -
   */
  public countAuthorities(
    id: any,
    where: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() + '/people/:id/authorities/count';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null)
      _urlParams.where = where;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Queries phoneNumbers of Person.
   *
   * @param {any} id Person id
   *
   * @param {object} filter
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public getPhoneNumbers(
    id: any,
    filter: LoopBackFilter = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/phone-numbers';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null)
      _urlParams.filter = filter;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in phoneNumbers of this model.
   *
   * @param {any} id Person id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public createPhoneNumbers(
    id: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/phone-numbers';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Deletes all phoneNumbers of this model.
   *
   * @param {any} id Person id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deletePhoneNumbers(
    id: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/phone-numbers';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Counts phoneNumbers of Person.
   *
   * @param {any} id Person id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` -
   */
  public countPhoneNumbers(
    id: any,
    where: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() + '/people/:id/phone-numbers/count';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null)
      _urlParams.where = where;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Queries projects of Person.
   *
   * @param {any} id Person id
   *
   * @param {object} filter
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public getProjects(
    id: any,
    filter: LoopBackFilter = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/projects';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null)
      _urlParams.filter = filter;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in projects of this model.
   *
   * @param {any} id Person id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public createProjects(
    id: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/projects';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Deletes all projects of this model.
   *
   * @param {any} id Person id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteProjects(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/projects';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Counts projects of Person.
   *
   * @param {any} id Person id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` -
   */
  public countProjects(
    id: any,
    where: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/projects/count';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null)
      _urlParams.where = where;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Queries roles of Person.
   *
   * @param {any} id Person id
   *
   * @param {object} filter
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public getRoles(
    id: any,
    filter: LoopBackFilter = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/roles';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null)
      _urlParams.filter = filter;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in roles of this model.
   *
   * @param {any} id Person id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public createRoles(
    id: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/roles';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Deletes all roles of this model.
   *
   * @param {any} id Person id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteRoles(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/roles';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Counts roles of Person.
   *
   * @param {any} id Person id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` -
   */
  public countRoles(
    id: any,
    where: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/roles/count';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null)
      _urlParams.where = where;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Queries metadata of Person.
   *
   * @param {any} id Person id
   *
   * @param {object} filter
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public getMetadata(
    id: any,
    filter: LoopBackFilter = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/metadata';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null)
      _urlParams.filter = filter;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in metadata of this model.
   *
   * @param {any} id Person id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public createMetadata(
    id: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/metadata';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Deletes all metadata of this model.
   *
   * @param {any} id Person id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteMetadata(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/metadata';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Counts metadata of Person.
   *
   * @param {any} id Person id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` -
   */
  public countMetadata(
    id: any,
    where: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/metadata/count';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null)
      _urlParams.where = where;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Patch an existing model instance or insert a new one into the data source.
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - Model instance data
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public patchOrCreate(
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PATCH';
    let _url: string = LoopBackConfig.getPath() + '/people';
    let _routeParams: any = {};
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   * @param {any} id Person id
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - An object of model property name/value pairs
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public patchAttributes(
    id: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PATCH';
    let _url: string = LoopBackConfig.getPath() + '/people/:id';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    console.log('here');
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * <em>
   * (The remote method definition does not provide any description.)
   * </em>
   *
   * @param {object} body
   *
   * @param {object} req
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public delete(
    body: any = {},
    req: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string = LoopBackConfig.getPath() + '/people/delete';
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in authorities of this model.
   *
   * @param {any} id Person id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public createManyAuthorities(
    id: any,
    data: any[] = [],
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/authorities';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in phoneNumbers of this model.
   *
   * @param {any} id Person id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public createManyPhoneNumbers(
    id: any,
    data: any[] = [],
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/phone-numbers';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in projects of this model.
   *
   * @param {any} id Person id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public createManyProjects(
    id: any,
    data: any[] = [],
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/projects';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in roles of this model.
   *
   * @param {any} id Person id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public createManyRoles(
    id: any,
    data: any[] = [],
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/roles';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in metadata of this model.
   *
   * @param {any} id Person id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Person` object.)
   * </em>
   */
  public createManyMetadata(
    id: any,
    data: any[] = [],
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string = LoopBackConfig.getPath() + '/people/:id/metadata';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Person`.
   */
  public getModelName() {
    return 'Person';
  }
}
