/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GitActionWorflowHistoryItemResponseDto } from '../models/git-action-worflow-history-item-response-dto';
import { GitActionWorflowRunDetailResponseDto } from '../models/git-action-worflow-run-detail-response-dto';
import { GitInfoWithRelations } from '../models/git-info-with-relations';

@Injectable({
  providedIn: 'root',
})
export class GitInfoControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation gitInfoControllerGetGitActionWorkflowHistory
   */
  static readonly GitInfoControllerGetGitActionWorkflowHistoryPath = '/git-action-workflow-history';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGitActionWorkflowHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGitActionWorkflowHistory$Response(params?: {
    limit?: number;
  }): Observable<StrictHttpResponse<Array<GitActionWorflowHistoryItemResponseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, GitInfoControllerService.GitInfoControllerGetGitActionWorkflowHistoryPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GitActionWorflowHistoryItemResponseDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGitActionWorkflowHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGitActionWorkflowHistory(params?: {
    limit?: number;
  }): Observable<Array<GitActionWorflowHistoryItemResponseDto>> {

    return this.getGitActionWorkflowHistory$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GitActionWorflowHistoryItemResponseDto>>) => r.body as Array<GitActionWorflowHistoryItemResponseDto>)
    );
  }

  /**
   * Path part for operation gitInfoControllerGetGitActionWorflowRunByRunId
   */
  static readonly GitInfoControllerGetGitActionWorflowRunByRunIdPath = '/git-action-workflow-run-details/{runId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGitActionWorflowRunByRunId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGitActionWorflowRunByRunId$Response(params: {
    runId: string;
  }): Observable<StrictHttpResponse<GitActionWorflowRunDetailResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, GitInfoControllerService.GitInfoControllerGetGitActionWorflowRunByRunIdPath, 'get');
    if (params) {
      rb.path('runId', params.runId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GitActionWorflowRunDetailResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGitActionWorflowRunByRunId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGitActionWorflowRunByRunId(params: {
    runId: string;
  }): Observable<GitActionWorflowRunDetailResponseDto> {

    return this.getGitActionWorflowRunByRunId$Response(params).pipe(
      map((r: StrictHttpResponse<GitActionWorflowRunDetailResponseDto>) => r.body as GitActionWorflowRunDetailResponseDto)
    );
  }

  /**
   * Path part for operation gitInfoControllerFindOne
   */
  static readonly GitInfoControllerFindOnePath = '/git-infos';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOne$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<GitInfoWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, GitInfoControllerService.GitInfoControllerFindOnePath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GitInfoWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOne(params?: {
    filter?: any;
  }): Observable<GitInfoWithRelations> {

    return this.findOne$Response(params).pipe(
      map((r: StrictHttpResponse<GitInfoWithRelations>) => r.body as GitInfoWithRelations)
    );
  }

}
