import { Component, OnInit, Output, EventEmitter, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ButtonComponentConfig, ButtonType } from '@rappider/rappider-components/utils';
import { NzTreeNode } from 'ng-zorro-antd/tree';
import { JoyrideService } from 'ngx-joyride';

@Component({
  selector: 'rappider-transformation-info-bar',
  templateUrl: './transformation-info-bar.component.html',
  styleUrls: ['./transformation-info-bar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => TransformationInfoBarComponent),
      multi: true
    }
  ]
})
export class TransformationInfoBarComponent implements ControlValueAccessor, OnChanges {

  @Input() tree: NzTreeNode[];
  @Input() sortStepsOptPlaceFlag: boolean;

  @Output() operationFieldChange = new EventEmitter();
  @Output() showStepsFlag = new EventEmitter<{ flag: boolean; key: string }>();

  isChangeOperationModalFieldVisible = false;
  localOperationField;
  isButtonHidden = true;
  _value: any;

  title: string;

  changeOperationButton: ButtonComponentConfig = {
    text: 'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_LIST_COMPONENT.CHANGE_OPERATION_FIELD',
    type: ButtonType.Default,
    icon: {
      name: 'far fa-edit '
    }
  };

  constructor(
    private readonly joyrideService: JoyrideService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sortStepsOptPlaceFlag) {
      this.showStep();
    }
  }

  showStep() {
    if (this.sortStepsOptPlaceFlag) {
      this.joyrideService.startTour(
        { steps: ['sortFirstStep'] }
      );
    }
  }

  onDone() {
    this.sortStepsOptPlaceFlag = false;
    this.showStepsFlag.emit({ flag: this.sortStepsOptPlaceFlag, key: 'sort-opt-place' });
  }

  get value() {
    return this._value;
  }

  set value(value: any) {
    this._value = value;
    this.setOperatingField();
    this.onChange(value);
    this.onTouched();
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: any) {
    this._value = value;
    this.setOperatingField();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setOperatingField() {
    this.localOperationField = this.value?.map(item => item.name)?.join('.');
    if (this.localOperationField) {
      this.title = `You are operating in ${this.localOperationField}`;
    } else {
      this.title = 'You are operating in root';
    }
  }

  changeOperationField() {
    this.value = this.localOperationField;
    this.localOperationField = null;
    this.operationFieldChange.emit();
    this.setOperatingField();
    this.isChangeOperationModalFieldVisible = false;
  }

  closeChangeOperationFieldModal() {
    this.localOperationField = null;
    this.isChangeOperationModalFieldVisible = false;
  }

  onOperationFieldChange(operationField) {
    this.localOperationField = operationField;
  }

  onChangeOperationButtonClick() {
    this.isChangeOperationModalFieldVisible = true;
  }

}
