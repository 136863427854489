import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CommentControllerService, NewPageComment, PageCommentControllerService } from '@rappider/rappider-sdk';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import * as CommentActions from './../comment/comment.actions';
import * as PageCommentActions from './page-comment.actions';

@Injectable()
export class PageCommentEffects {
  constructor(
    private store: Store<any>,
    private actions$: Actions,
    private pageCommentApi: PageCommentControllerService,
    private commentApi: CommentControllerService
  ) { }

  getPageComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PageCommentActions.GetPageComments
      ),
      mergeMap((action) => this.pageCommentApi.find().pipe(
          map((pageComments) => PageCommentActions.GetPageCommentsSuccessful({ pageComments })),
          catchError((error) =>
            [
              PageCommentActions.GetPageCommentsFailure({ error, key: 'GetPageComments', date: Date.now() })
            ]
          )
        ))
    )
  );

  createPageComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PageCommentActions.CreatePageComment
      ),
      withLatestFrom(
        this.store.select(state => state.contentEditor?.page?.id),
        this.store.select(state => state.contentEditor?.activeContentTreeItemId)
      ),
      switchMap(([action, pageId, activeContentTreeItemId]) => {
        const commentBody: NewPageComment = {
          ...action.pageComment,
          contentTreeItemId: activeContentTreeItemId,
          pageId: pageId
        };

        return this.commentApi.createPageComment({ body: commentBody }).pipe(
          map((commentAndPageComment) => PageCommentActions.CreatePageCommentSuccessful({ comment: commentAndPageComment.comment, pageComment: commentAndPageComment.pageComment }))
        );
      }), catchError((error) =>
        [
          PageCommentActions.UpdatePageCommentFailure({ error, key: 'CreatePageComment', date: Date.now() })
        ]
      )
    )
  );

  createPageCommentSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PageCommentActions.CreatePageCommentSuccessful
      ),
      map((action) => CommentActions.SetComment({ comment: action.comment }))
    )
  );

  updatePageComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PageCommentActions.UpdatePageComment
      ),
      switchMap((action) =>
        /* update page comment's comment */
        [
          CommentActions.UpdateComment({ id: action.commentId, comment: action.comment }),
          PageCommentActions.UpdatePageCommentSuccessful()
        ]

      ), catchError((error) =>
        [
          PageCommentActions.UpdatePageCommentFailure({ error, key: 'UpdatePageComment', date: Date.now() })
        ]
      )
    )
  );

  deletePageComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PageCommentActions.DeletePageComment
      ),
      switchMap((action) => this.commentApi.deleteById({ id: action.commentId }).pipe(
        switchMap(() => this.pageCommentApi.deleteById({ id: action.pageCommentId }).pipe(
          map(() => PageCommentActions.DeletePageCommentSuccessful({ commentId: action.pageCommentId })),
          catchError((error) =>
            [
              PageCommentActions.DeletePageCommentFailure({ error, key: 'DeletePageComment', date: Date.now() })
            ]
          )
        )), catchError((error) =>
          [
            CommentActions.DeleteCommentFailure({ error, key: 'DeleteComment', date: Date.now() })
          ]
        )
      ))
    )
  );

  deletePageCommentSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PageCommentActions.DeletePageCommentSuccessful
      ),
      map(() => CommentActions.GetComments())
    )
  );
}
