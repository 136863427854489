import { createSelector } from '@ngrx/store';
import { Project } from '../sdk/models';

export const getTenantProjects = createSelector(
  state => state['auth']?.tenant?.id,
  state => state['project']?.data,
  (
    tenantId: string,
    projects: Project[]) => {
    if (projects?.length && tenantId) {
      return projects.filter(project => project.tenantId === tenantId);
    }
  }
);
