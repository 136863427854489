import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { TestUserModalTitle } from '../models/project-test-members-events.enum';
import { TestUser, TestUserModalConfigs } from '../models/project-test-members.interface';
import { TEST_USER_FORM_ITEMS_CONFIG } from './test-users-modals-form.config';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'rappider-test-users-modals',
  templateUrl: './test-users-modals.component.html',
  styleUrls: ['./test-users-modals.component.scss']
})
export class TestUsersModalsComponent implements OnInit, OnChanges {

  @Input() modalConfigs: TestUserModalConfigs ;
  @Input() testUserModalData: TestUser;
  @Output() validityChange = new EventEmitter<boolean>();
  @Output() formSubmit = new EventEmitter<any>();

  TEST_USER_FORM_ITEMS_CONFIG = TEST_USER_FORM_ITEMS_CONFIG;
  projectRoles: KeyValue<string, string>[];
  modalTitle: any = [];
  constructor(
    private store: Store<any>
  ) {}

  ngOnInit(){
    this.subscribeToData();
    this.setModalTitle();
  }

  ngOnChanges(changes: SimpleChanges): void {
   if(changes.testUserModalData){
    this.setModalTitle();
   }
  }

  subscribeToData(){
   this.subscribeToProjectRole();
  }

  onModalButtonClick(){
    this.modalConfigs.isSubmitted= true;
    if(this.modalConfigs.isFormValid){
    if (this.modalConfigs.isSubmitted && this.modalConfigs.isFormValid ) {
      this.modalConfigs.isVisible = false;
      this.testUserModalClose();
    }else {
      this.modalConfigs.isVisible = true;
    }
  };
}

  testUserModalSubmit(event){
    this.testUserModalData={
      ...this.testUserModalData,
      ...event
    };
  }

  testUserValidtyCheck(validData: boolean){
    this.modalConfigs.isFormValid = validData;
  }

  testUserModalClose(){
    this.modalConfigs.isVisible = false;
    this.modalConfigs.isSubmitted = false;
    this.testUserModalData = null;
  }

  setModalTitle(){
    if(this.testUserModalData === null){
     this.modalTitle = TestUserModalTitle.AddModalTitle;
    }else{
      this.modalTitle = TestUserModalTitle.EditModalTitle;
    };
  }

// Getting Roles From state

  subscribeToProjectRole() {
    return this.store.select(state => state.projectRole?.data).subscribe(projectRoleState => {
      if (projectRoleState) {
        this.projectRoles = projectRoleState?.map(projectRole => ({
          key: projectRole?.title,
          value: projectRole?.id,
        })
        );
      }
      this.setMultipleSelectItem();
    });
  }

  setMultipleSelectItem(){
      this.TEST_USER_FORM_ITEMS_CONFIG.items = this.TEST_USER_FORM_ITEMS_CONFIG.items.map(item => {
        if (item.fieldName === 'selectedRoles') {
          return {
            ...item,
            options: this.projectRoles
          };
        } else {
          return item;
        }
      });
  }

}
