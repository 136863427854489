/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ThemeMode } from '@rappider/models';
import { HeadingType } from '@rappider/rappider-components/utils';
@Component({
  selector: 'rappider-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.scss']
})
export class ShowcaseComponent implements OnInit, OnDestroy {

  ngOnInit(): void {
    document.body.classList.add(ThemeMode.Light);
  }

  ngOnDestroy(): void {
    document.body.classList.remove(ThemeMode.Light);
  }

  // Data for charts
  enrollmentData = [
    { type: 'Children', value: 400000 },
    { type: 'Adults', value: 600000 },
    { type: 'Elderly', value: 150000 },
    { type: 'Disabled', value: 50000 }
  ];

  enrollmentGrowthData = [
    { year: '2020', value: 100000 },
    { year: '2021', value: 105000 },
    { year: '2022', value: 110000 },
    { year: '2023', value: 120000 }
  ];

  serviceUtilizationData = [
    { type: 'Primary Care Visits', value: 300000 },
    { type: 'Hospital Admissions', value: 250000 },
    { type: 'Emergency Room Visits', value: 500000 }
  ];

  // Configurations for charts
  enrollmentPieConfig = {
    type: 'interval',
    autoFit: true,
    data: [
      { item: 'Children', count: 400000, percent: 400000 / 1200000 },
      { item: 'Adults', count: 600000, percent: 600000 / 1200000 },
      { item: 'Elderly', count: 150000, percent: 150000 / 1200000 },
      { item: 'Disabled', count: 50000, percent: 50000 / 1200000 },
    ],
    encode: { y: 'percent', color: 'item' },
    transform: [{ type: 'stackY' }],
    coordinate: { type: 'theta', outerRadius: 0.8 },
    legend: {
      color: { position: 'bottom', layout: { justifyContent: 'center' } },
    },
    labels: [
      {
        position: 'outside',
        text: (data) => `${data.item}: ${data.count} | ${(data.percent * 100).toFixed(2)}%`,
      },
    ],
    tooltip: {
      items: [
        (data) => ({
          name: data.item,
          value: `${(data.percent * 100).toFixed(2)}%`,
        }),
      ],
    },
  };

  enrollmentPieContent = {
    title: {
      type: HeadingType.H2,
      content: 'Program Enrollment and Demographics',
    },
    subtitle: {
      type: HeadingType.H4,
      content: 'Enrollment Statistics',
    },
    description: {
      content: 'Total Beneficiaries: 1,200,000',
    }
  };

  enrollmentGrowthLineConfig = {
    type: 'view',
    autoFit: true,
    data: this.enrollmentGrowthData,
    encode: { x: 'year', y: 'value' },
    scale: { x: { range: [0, 1] }, y: { domainMin: 90000, nice: true } },
    children: [
      { type: 'line', labels: [{ text: 'value', style: { dx: -10, dy: -12 } }] },
      { type: 'point', style: { fill: 'white' }, tooltip: false },
    ],
  };

  enrollmentGrowthLineContent = {
    title: {
      type: HeadingType.H2,
      content: 'Yearly Enrollment Growth',
    },
    subtitle: {
      type: HeadingType.H4,
      content: 'Growth over the past four years',
    }
  };

  // serviceUtilizationBarConfig = {
  //   data: this.serviceUtilizationData,
  //   xField: 'type',
  //   yField: 'value',
  //   seriesField: 'type',
  //   legend: { position: 'top-left' },
  // };

  utilizationOfServicesBarConfig = {
    type: 'interval',
    autoFit: true,
    data: [
      { service: 'Emergency Room Visits', year: '2021', value: 500000 },
      { service: 'Emergency Room Visits', year: '2022', value: 480000 },
      { service: 'Emergency Room Visits', year: '2023', value: 510000 },
      { service: 'Hospital Admissions', year: '2021', value: 230000 },
      { service: 'Hospital Admissions', year: '2022', value: 240000 },
      { service: 'Hospital Admissions', year: '2023', value: 250000 },
      { service: 'Primary Care Visits', year: '2021', value: 280000 },
      { service: 'Primary Care Visits', year: '2022', value: 290000 },
      { service: 'Primary Care Visits', year: '2023', value: 300000 },
    ],
    encode: { x: 'service', y: 'value', color: 'year' },
    transform: [{ type: 'dodgeX' }],
    coordinate: { transform: [{ type: 'transpose' }] },
    interaction: { elementHighlight: { background: true } },
    legend: {
      position: 'bottom',
      layout: { justifyContent: 'center' },
    },
    axis: {
      x: { title: 'Service Type' },
      y: { title: 'Visits/Admissions per Year' },
    },
  };

  utilizationOfServicesBarContent = {
    title: {
      type: HeadingType.H2,
      content: 'Utilization of Services',
    },
    subtitle: {
      type: HeadingType.H4,
      content: 'Service Utilization Rates',
    },
    description: {
      content: 'Comparison of different types of service utilization',
    }
  };

  programEnrollmentBarConfig = {
    type: 'interval',
    autoFit: true,
    data: [
      { category: 'Non-ABD Adults', ageGroup: 'Age > 20', value: 686255 },
      { category: 'Non-ABD Adults', ageGroup: 'Age < 21', value: 24719 },
      { category: 'Non-ABD Children', ageGroup: 'Age > 20', value: 0 },
      { category: 'Non-ABD Children', ageGroup: 'Age < 21', value: 816774 },
      {
        category: 'Aged Blind and Disabled',
        ageGroup: 'Age > 20',
        value: 272680,
      },
      { category: 'Aged Blind and Disabled', ageGroup: 'Age < 21', value: 30611 },
    ],
    encode: { x: 'category', y: 'value', color: 'ageGroup' },
    transform: [{ type: 'dodgeX' }],
    coordinate: { transform: [{ type: 'transpose' }] },
    interaction: { elementHighlight: { background: true } },
    scale: {
      value: {
        min: 10000,
        max: 700000,
        tickInterval: 10000
      }
    }
  };


  programEnrollmentBarContent = {
    title: {
      type: HeadingType.H2,
      content: 'Program Enrollment and Demographics',
    },
    subtitle: {
      type: HeadingType.H4,
      content: 'Enrollment Statistics July 2024',
    },
    description: {
      content: 'Total Beneficiaries: 1,831,040',
    }
  };


  yearlyEnrollmentGrowthConfig = {
    type: 'interval',
    autoFit: true,
    data: [
      { year: '2023', value: 2232891 },
      { year: '2022', value: 2091531 },
      { year: '2021', value: 1926351 },
      { year: '2020', value: 1685556 },
    ],
    encode: { x: 'year', y: 'value' },
    coordinate: { transform: [{ type: 'transpose' }] }
  };

  yearlyEnrollmentGrowthContent = {
    title: {
      type: HeadingType.H2,
      content: 'Yearly Enrollment Growth',
    }
  };


  newProgramEnrollmentConfig = {
    type: 'interval',
    autoFit: true,
    data: [
      { category: 'MLTSS: Caregiver Supports', enrollments: 20000 },
      { category: 'Behavioral Health Integration', enrollments: 45000 },
      { category: 'Community Health Worker (CHW) Pilot', enrollments: 5000 },
      { category: 'Behavioral Health Promoting Interoperability Program (BH PIP)', enrollments: 15000 },
    ],
    encode: {
      x: 'category',
      y: 'enrollments'
    },
    transform: [
      { type: 'sortX', by: 'y', reverse: true }
    ],
    coordinate: {
      transform: [{ type: 'transpose' }]
    },
    axis: {
      x: {
        label: {
          autoRotate: false,
          title: 'Program'
        }
      },
      y: {
        label: {
          formatter: '.0f',
          title: 'Number of Enrollments'
        }
      }
    },
    labels: [
      {
        text: 'enrollments',
        formatter: '.0f',
        textAlign: 'center',
        fill: '#000',
        dx: 5
      }
    ]
  };


  newProgramEnrollmentContent = {
    title: {
      type: HeadingType.H2,
      content: 'New Program Enrollment To Date',
    }
  };


  predictedEnrollmentLineChartConfig = {
    type: 'line',
    autoFit: true,
    data: [
      { year: '2024', enrollment: 2414762 },
      { year: '2025', enrollment: 2583795 }
    ],
    encode: {
      x: 'year',
      y: 'enrollment'
    },
    axis: {
      x: {
        title: 'Year',
        label: {
          formatter: '.0f',
          autoRotate: false
        }
      },
      y: {
        title: 'Predicted Enrollment',
        label: {
          formatter: '~s', // Uses SI notation (e.g., 2M for 2,000,000)
        }
      }
    },
    lineStyle: {
      stroke: '#5B8FF9', // Line color
      lineWidth: 2
    },
    point: {
      visible: true,
      shape: 'circle',
      size: 4,
      style: {
        fill: '#5B8FF9',
        stroke: '#fff',
        lineWidth: 1
      }
    },
    tooltip: {
      showMarkers: true,
      shared: true
    }
  };

  predictedEnrollmentLineChartContent = {
    title: {
      type: HeadingType.H2,
      content: 'Predicted Enrollment Growth',
    }
  };


  highCostConfig = {
    type: 'view',
    autoFit: true,
    data: {
      type: 'inline',
      value: [
        { x: 'Surgeries', value: 10000 },
        { x: 'Specialty Drugs', value: 50000000 },
      ],
      transform: [
        {
          type: 'custom',
          callback: (data) => {
            const sum = data.reduce((r, curr) => r + curr.value, 0);
            return data
              .map((d) => ({
                ...d,
                percentage: d.value / sum,
              }))
              .reduce((r, curr) => {
                const v = r.length ? r[r.length - 1].accumulate : 0;
                const accumulate = v + curr.percentage;
                r.push({
                  ...curr,
                  accumulate,
                });
                return r;
              }, []);
          },
        },
      ],
    },
    children: [
      {
        type: 'interval',
        encode: { x: 'x', y: 'value' },
        scale: { x: { padding: 0.5 }, y: { domainMax: 50000000, tickCount: 5 } },
        style: { fill: (d) => (d.percentage < 0.1 ? '#E24B26' : '#78B3F0') },
        axis: { x: { title: null }, y: { title: 'Cost (in dollars)' } },
        labels: [
          {
            text: (d) => `${(d.percentage * 100).toFixed(2)}%`,
            textBaseline: 'bottom',
          },
        ],
      },
      {
        type: 'line',
        encode: { x: 'x', y: 'accumulate' },
        scale: { y: { independent: true, domainMin: 0, tickCount: 5 } },
        axis: {
          y: {
            position: 'right',
            title: 'Cumulative Percentage',
            grid: null,
            labelFormatter: (d) => `${(d * 100).toFixed(0)}%`,
          },
        },
        tooltip: {
          items: [
            { channel: 'y', valueFormatter: (d) => `${(d * 100).toFixed(2)}%` },
          ],
        },
      },
      {
        type: 'point',
        encode: { x: 'x', y: 'accumulate', shape: 'diamond' },
        scale: { y: { independent: true, domainMin: 0 } },
        axis: { y: null },
        tooltip: null,
      },
    ],
    title: 'Pareto Chart of High-Cost Services',
  };

  highCostContent = {
    title: {
      type: HeadingType.H2,
      content: 'High-Cost Services',
    },
    subtitle: {
      type: HeadingType.H4,
      content: 'Surgeries: 10,000 procedures/year',
    },
    description: {
      content: 'Specialty Drugs: $50 million spent/year',
    }
  };

  preventiveCareOptimizationConfig = {
    'type': 'view',
    'width': 600,
    'height': 300,
    'autoFit': true,
    'paddingRight': 60,
    'coordinate': { 'transform': [{ 'type': 'transpose' }] },
    'children': [
      {
        'type': 'interval',
        'data': [
          { 'text': 'At-Risk Beneficiaries', 'value': 20000 },
          { 'text': 'Contacted', 'value': 15000 },
          { 'text': 'Scheduled Appointments', 'value': 11250 }
        ],
        'encode': { 'x': 'text', 'y': 'value', 'color': 'text', 'shape': 'funnel' },
        'transform': [{ 'type': 'symmetryY' }],
        'scale': { 'x': { 'paddingOuter': 0, 'paddingInner': 0 } },
        'axis': false,
        'legend': false,
        'labels': [
          {
            'text': (d) => `${d.text} ${d.value}`,
            'position': 'inside',
            'fontSize': 20
          },
          {
            'text': '',
            'render': (d, data, i) =>
              i !== 0
                ? '<div style=\'height:1px;width:30px;background:#aaa;margin:0 20px;\'></div>'
                : '',
            'position': 'top-right'
          },
          {
            'text': (d, i) => (i !== 0 ? 'Conversion Rate' : ''),
            'position': 'top-right',
            'textAlign': 'left',
            'textBaseline': 'middle',
            'fill': '#aaa',
            'dx': 60
          },
          {
            'text': (d, i, data) =>
              i !== 0 ? ((data[i].value / data[i - 1].value) * 100).toFixed(2) + '%' : '',
            'position': 'top-right',
            'textAlign': 'left',
            'textBaseline': 'middle',
            'dx': 60,
            'dy': 15
          }
        ]
      },
      {
        'type': 'connector',
        'data': [{ 'startX': 'At-Risk Beneficiaries', 'startY': 'Scheduled Appointments', 'endX': 0, 'endY': 11250 }],
        'encode': { 'x': 'startX', 'x1': 'startY', 'y': 'endX', 'y1': 'endY' },
        'style': {
          'stroke': '#aaa',
          'markerEnd': false,
          'connectLength1': -12,
          'offset2': -20
        },
        'labels': [
          {
            'text': 'Conversion Rate',
            'position': 'left',
            'textAlign': 'start',
            'textBaseline': 'middle',
            'fill': '#aaa',
            'dx': 10
          },
          {
            'text': '75.00 %',
            'position': 'left',
            'textAlign': 'start',
            'dy': 15,
            'dx': 10,
            'fill': '#000'
          }
        ]
      }
    ]
  };

  preventiveCareOptimizationContent = {
    title: {
      type: HeadingType.H2,
      content: 'Preventive Care Optimization',
    },
    subtitle: {
      type: HeadingType.H4,
      content: 'At-Risk Beneficiaries: 20,000 identified for missing preventive care.',
    },
    description: {
      content: 'Outreach Success Rate: 75% of contacted beneficiaries scheduled appointments.',
    }
  };

  realTimeBudgetConfig = {
    type: 'gauge',
    autoFit: true,
    data: {
      value: {
        target: 4.8,
        total: 5.0,
        name: 'Budget',
        thresholds: [4.0, 5.0, 5.0]
      }
    },
    scale: {
      color: {
        range: ['#F4664A', '#FAAD14', 'green']
      }
    },
    style: {
      arcStyle: (target) => {
        if (target < 2.5) {
          return <any>{ fill: '#F4664A' };
        }
        if (target < 4.0) {
          return { fill: '#FAAD14' };
        }
        return { fill: 'green' };
      },
      textContent: (target, total) => `Current Spend: $${target}B\nBudget Utilization: ${(target / total) * 100}%`
    },
    legend: false,
  };

  realTimeBudgetContent = {
    title: {
      type: HeadingType.H2,
      content: 'Real-Time Budget Tracking',
    },
    subtitle: {
      type: HeadingType.H4,
      content: 'Annual Budget: $5 billion',
    },
    description: {
      content: 'Current Spend: $4.8 billion',
    }
  };

  costPerBeneficiaryPredictionConfig = {
    type: 'line',
    data: [
      { year: '2023', cost: 4000 },
      { year: '2024', cost: 4200 }
    ],
    encode: {
      x: 'year',
      y: 'cost'
    },
    scale: {
      x: { range: [0, 1] },
      y: { nice: true }
    },
    axis: {
      x: { title: 'Year' },
      y: { title: 'Cost per Beneficiary ($)' }
    },
    tooltip: {
      showMarkers: true
    },
    legend: false
  };

  costPerBeneficiaryPredictionContent = {
    title: {
      type: HeadingType.H2,
      content: 'Cost per Beneficiary Prediction',
    },
    subtitle: {
      type: HeadingType.H4,
      content: 'Current: $4,000 per beneficiary/year',
    },
    description: {
      content: 'Predicted Next Year: $4,200 per beneficiary/year',
    }
  };

  administrativeEfficiencyConfig = {
    'type': 'interval',
    'autoFit': true,
    'data': [
      { 'category': 'Average Application Processing Time', 'time': 15 },
      { 'category': 'Claims Processing Time', 'time': 10 }
    ],
    'encode': {
      'x': 'category',
      'y': 'time'
    },
    'transform': [
      { 'type': 'sortX', 'by': 'y', 'reverse': true }
    ],
    'coordinate': {
      'transform': [{ 'type': 'transpose' }]
    },
    'axis': {
      'x': {
        'label': {
          'autoRotate': false,
          'title': 'Processing Time Category'
        }
      },
      'y': {
        'label': {
          'formatter': '.0f',
          'title': 'Time (days)'
        }
      }
    },
    'labels': [
      {
        'text': 'time',
        'formatter': '.0f',
        'textAlign': 'center',
        'fill': '#000',
        'dx': 5
      }
    ]
  };

  administrativeEfficiencyContent = {
    title: {
      type: HeadingType.H2,
      content: 'Administrative Efficiency',
    },
  };

  waiversConfig = {
    'type': 'view',
    'width': 1000,
    'height': 60,
    'coordinate': { 'transform': [{ 'type': 'transpose' }] },
    'interaction': { 'tooltip': false },
    'children': [
      {
        'type': 'interval',
        'data': [3, 2],  // 3 başarılı sonuç ve 2 kalan (5 - 3)
        'encode': { y: (d) => d, color: (d, idx) => idx },
        'scale': {
          y: { domain: [0, 5] },  // Toplam aktif projeler
          color: { range: ['#d0d0d0', '#007bff'] }  // Mavi başarılı sonuçlar, gri kalan projeler
        },
        'style': {
          'x': 140  // Barı sağ tarafa kaydırmak için x konumu
        },
        'axis': false,
        'legend': false,
      },
      {
        'type': 'text',
        'style': {
          'text': '60%',  // Başarı oranı
          'x': '50%',
          'y': '50%',
          'textAlign': 'center',
          'fontSize': 16,
          'fontStyle': 'bold',
          'fill': '#fff'  // Siyah renk metin için
        }
      },
      {
        'type': 'text',
        'style': {
          'text': 'Active Projects',  // Sol taraf metni
          'x': 0,  // Barın sol tarafına biraz uzaklık
          'y': '50%',
          'textAlign': 'left',
          'fontSize': 16,
          'fontStyle': 'bold',
          'fill': '#000000'  // Siyah renk metin için
        }
      }
    ]
  };

  predictiveAnaliyticsConfig = {
    'type': 'view',
    'width': 900,
    'height': 600,
    'children': [
      {
        'type': 'line',
        'data': [
          { 'category': 'Actual Enrollment', 'value': 100, 'type': 'Actual' },
          { 'category': 'Predicted Enrollment', 'value': 105, 'type': 'Predicted' },
          { 'category': 'Actual Budget Shortfall', 'value': 180, 'type': 'Actual' },
          { 'category': 'Predicted Budget Shortfall', 'value': 200, 'type': 'Predicted' }
        ],
        'encode': {
          'x': 'category',
          'y': 'value',
          'color': 'type'
        },
        'scale': {
          'color': { 'range': ['#007bff', '#ff4d4d'] }  // Mavi tahminler için, kırmızı gerçekler için
        },
        'legend': { 'position': 'top' }
      }
    ]
  };

  scenarioAnalysisConfig = {
    'type': 'view',
    'width': 750,
    'height': 500,
    'children': [
      {
        'type': 'line',
        'data': [
          { 'month': 'Jan', 'currentEnrollment': 5000, 'projectedEnrollment': 5500 },
          { 'month': 'Feb', 'currentEnrollment': 5200, 'projectedEnrollment': 5720 },
          { 'month': 'Mar', 'currentEnrollment': 5400, 'projectedEnrollment': 5940 },
          { 'month': 'Apr', 'currentEnrollment': 5600, 'projectedEnrollment': 6160 },
          { 'month': 'May', 'currentEnrollment': 5800, 'projectedEnrollment': 6380 },
          { 'month': 'Jun', 'currentEnrollment': 6000, 'projectedEnrollment': 6600 }
        ],
        'encode': {
          'x': 'month',
          'y': ['currentEnrollment', 'projectedEnrollment'],
          'color': 'type'
        },
        'scale': {
          'color': { 'range': ['#007bff', '#ff4d4d'] }  // Mavi mevcut durum için, kırmızı tahminler için
        },
        'axis': {
          'x': { 'title': 'Month' },
          'y': { 'title': 'Enrollment' }
        },
        'legend': { 'position': 'top' }
      }
    ]
  };

  scenarioAnalysisContent = {
    title: {
      type: HeadingType.H2,
      content: 'Scenario Analysis',
    },
    subtitle: {
      type: HeadingType.H4,
      content: 'Policy Change Impact:',
    },
    description: {
      content: '10% increase in child enrollment expected due to new legislation',
    }
  };

  automatedBudgetAnalysisConfig = {
    'type': 'view',
    'autoFit': true,
    'data': [
      { 'category': 'Historical', 'amount': 4.9 },
      { 'category': 'Current', 'amount': 5 }
    ],
    'children': [
      {
        'type': 'line',
        'data': [
          { 'time': 'Historical', 'amount': 4.9 },
          { 'time': 'Current', 'amount': 5 }
        ],
        'encode': {
          'x': 'time',
          'y': 'amount',
          'color': () => 'Budget'
        },
        'scale': {
          'y': {
            'domain': [0, 6],  // Eksen sınırlarını belirleyin
            'formatter': (val) => `$${val}B`
          }
        },
        'axis': {
          'x': { 'title': 'Time' },
          'y': { 'title': 'Budget Amount' }
        }
      }
    ]
  };

  programIntegrityConfig = {
    type: 'interval',
    autoFit: true,
    data: [
      { item: 'Completed Audits', count: 200, percent: 1 },
      { item: 'Non-Compliance', count: 4, percent: 0.02 },
      { item: 'Compliance', count: 196, percent: 0.98 },
    ],
    encode: { y: 'percent', color: 'item' },
    transform: [{ type: 'stackY' }],
    coordinate: { type: 'theta', outerRadius: 0.8 },
    legend: {
      color: { position: 'bottom', layout: { justifyContent: 'center' } },
    },
    labels: [
      {
        position: 'outside',
        text: (data) => `${data.item}: ${data.percent * 100}%`,
      },
    ],
    tooltip: {
      items: [
        (data) => ({
          name: data.item,
          value: `${data.percent * 100}%`,
        }),
      ],
    },
  };

  programIntegrityContent = {
    title: {
      type: HeadingType.H2,
      content: 'Program Integrity',
    },
    subtitle: {
      type: HeadingType.H4,
      content: 'Audits Completed: 200',
    },
    description: {
      content: 'Compliance with CMS Guidelines: 98%',
    }
  };

  beneficiarySatisfactionConfig = {
    type: 'interval',
    autoFit: true,
    data: [
      { item: 'Satisfaction', percent: 0.85 },
      { item: 'Dissatisfaction', percent: 0.15 },
    ],
    encode: { y: 'percent', color: 'item' },
    transform: [{ type: 'stackY' }],
    coordinate: { type: 'theta', outerRadius: 0.8 },
    legend: {
      color: { position: 'bottom', layout: { justifyContent: 'center' } },
    },
    labels: [
      {
        position: 'outside',
        text: (data) => `${data.item}: ${data.percent * 100}%`,
      },
    ],
    tooltip: {
      items: [
        (data) => ({
          name: data.item,
          value: `${data.percent * 100}%`,
        }),
      ],
    },
  };

  beneficiarySatisfactionContent = {
    title: {
      type: HeadingType.H2,
      content: 'Beneficiary Satisfaction:',
    },
    subtitle: {
      type: HeadingType.H4,
      content: 'Satisfaction Rate: 85%',
    },
    description: {
      content: 'Surveys Completed: 10,000',
    }
  };

  providerFeedback = {
    'type': 'view',
    'autoFit': true,
    'data': [
      { 'feedback': 'Positive', 'percent': 0.75 },
      { 'feedback': 'Negative', 'percent': 0.15 },
      { 'feedback': 'Neutral', 'percent': 0.10 }
    ],
    'children': [
      {
        'type': 'interval',
        'encode': {
          'x': 'feedback',
          'y': 'percent',
          'color': 'feedback'
        },
        'transform': [
          { 'type': 'stackY' }  // Y ekseninde yığma
        ],
        'scale': {
          'y': {
            'domain': [0, 1],
            'formatter': (val) => `${val * 100}%`
          }
        },
        'axis': {
          'x': {
            'labelSpacing': 4,
            'labelTransform': 'rotate(0)'  // X eksenindeki etiketler döndürülmez
          },
          'y': {
            'labelFormatter': '~s'
          }
        }
      }
    ]
  };

  providerFeedbackContent = {
    title: {
      type: HeadingType.H2,
      content: 'Provider Feedback',
    }
  };

  engagementConfig = {
    'type': 'interval',
    'autoFit': true,
    'data': [
      { 'action': 'Messages Sent', 'pv': 50000 },
      { 'action': 'Responses', 'pv': 30000 }
    ],
    'encode': { x: 'action', y: 'pv', color: 'action', shape: 'funnel' },
    'transform': [{ 'type': 'symmetryY' }],
    'scale': { x: { 'padding': 0 } },
    'coordinate': { 'transform': [{ 'type': 'transpose' }] },
    'animate': { 'enter': { 'type': 'fadeIn' } },
    'axis': false,
    'labels': [
      {
        'text': (d) => `${d.action}\n${d.pv}`,
        'position': 'inside',
        'transform': [{ 'type': 'contrastReverse' }]
      }
    ]
  };

  engagementContent = {
    title: {
      type: HeadingType.H2,
      content: 'Beneficiary and Provider Engagement',
    }
  };

  stateAndRegionalComparisonConfig = {
    'type': 'interval',
    'autoFit': true,
    'data': [
      { 'state': 'State A', 'enrollment': 300000 },
      { 'state': 'State B', 'enrollment': 500000 },
      { 'state': 'State C', 'enrollment': 400000 }
    ],
    'encode': {
      'x': 'state',
      'y': 'enrollment',
      'color': 'state'
    },
    'scale': {
      'y': { 'nice': true },
      'x': { 'padding': 0 }
    },
    'axis': {
      'x': { 'title': 'State' },
      'y': { 'title': 'Enrollment', 'labelFormatter': '~s' }
    },
    'legend': {
      'color': { 'position': 'top' }
    }
  };

  stateAndRegionalComparisonContent = {
    title: {
      type: HeadingType.H2,
      content: 'State and Regional Comparisons:',
    }
  };

  ruralVsUrbanConfig = {
    type: 'interval',
    width: 500,
    height: 500,
    autoFit: true,
    data: [
      { location: 'Rural', accessRate: 75 },
      { location: 'Urban', accessRate: 95 },
    ],
    encode: {
      x: 'location', // X ekseninde 'location' gösterilecek (Rural, Urban)
      y: 'accessRate', // Y ekseninde 'accessRate' gösterilecek (erişim oranı %)
      color: 'location' // Her bar'ın rengi 'location'a göre belirlenecek
    },
    axis: {
      y: { title: 'Average Healthcare Access' }, // Y ekseni için başlık
      x: { title: 'Location' } // X ekseni için başlık
    },
    label: {
      position: 'middle', // Etiketlerin konumu bar'ların ortasında olacak
      style: {
        fill: '#FFFFFF', // Etiketlerin rengi beyaz olacak
        opacity: 0.6,
      },
    },
    tooltip: {
      showMarkers: false,
      shared: true,
    },
    interactions: [{ type: 'active-region' }],
  };

  ruralVsUrbanContent = {
    title: {
      type: HeadingType.H2,
      content: 'Rural vs. Urban:'
    }
  };

  emergingIssuesConfig = {
    type: 'view',
    children: [
      // Spike in ER Visits Line Chart
      {
        type: 'line',
        data: [
          { time: 'Month-1', value: 90, category: 'ER Visits' },
          { time: 'Month-2', value: 100, category: 'ER Visits' },
          { time: 'Month-3', value: 110, category: 'ER Visits' }
        ],
        encode: { x: 'time', y: 'value', color: 'category' },
        scale: { y: { nice: true } },
        axis: { y: { title: 'ER Visits' }, x: { title: 'Time' } },
        line: { shape: 'smooth' },
      },
      // Drop in Provider Participation Line Chart
      {
        type: 'line',
        data: [
          { time: 'Month-1', value: 100, category: 'Provider Participation' },
          { time: 'Month-2', value: 95, category: 'Provider Participation' },
          { time: 'Month-3', value: 90, category: 'Provider Participation' }
        ],
        encode: { x: 'time', y: 'value', color: 'category' },
        scale: { y: { nice: true } },
        axis: { y: { title: 'Provider Participation' }, x: { title: 'Time' } },
        line: { shape: 'smooth' },
      },
      // KPI Cards for Real-Time Alerts
      {
        type: 'text',
        style: {
          text: 'ER Visits: 10% Increase',
          x: '10%',
          y: '20%',
          fontSize: 14,
          fontStyle: 'bold',
          fill: 'red' // Alert Color
        },
      },
      {
        type: 'text',
        style: {
          text: 'Provider Participation: 5% Decrease',
          x: '10%',
          y: '30%',
          fontSize: 14,
          fontStyle: 'bold',
          fill: 'orange' // Alert Color
        },
      }
    ]
  };

  emergingIssuesContent = {
    title: {
      type: HeadingType.H2,
      content: 'Emerging Issues:'
    }
  };

  smartEnrollmentConfig = {
    type: 'line',
    autoFit: true,
    data: [
      { year: '2020', value: 2100000 },
      { year: '2021', value: 2200000 },
      { year: '2022', value: 2300000 },
      { year: '2023', value: 2350000 },
      { year: '2024', value: 2414762 }, // Tahmin edilen değer
      { year: '2025', value: 2583795 }, // Tahmin edilen değer
    ],
    encode: {
      x: 'year',
      y: 'value',
    },
    scale: {
      y: { min: 2000000, max: 2600000 },
    },
    axis: {
      x: {
        title: 'Year',
      },
      y: {
        title: 'Enrollment Numbers',
      },
    },
    tooltip: {
      shared: true,
    },
    interactions: [{ type: 'element-active' }],
    style: {
      lineWidth: 2,
    },
    label: {
      position: 'middle',
      style: {
        fill: '#595959',
        opacity: 0.6,
        fontSize: 12,
      },
    },
    title: {
      text: 'Smart Enrollment Forecasting: Predicted Enrollment',
      style: {
        fontSize: 14,
        fontWeight: 'bold',
      },
    },
  };

  claimsFraudConfig = {
    type: 'heatmap',
    autoFit: true,
    data: [
      { region: 'North', claims: 800, savings: 8000000 },
      { region: 'South', claims: 600, savings: 5000000 },
      { region: 'East', claims: 700, savings: 4000000 },
      { region: 'West', claims: 400, savings: 3000000 },
    ],
    encode: {
      x: 'region',
      y: 'claims',
      color: 'savings',
    },
    scale: {
      color: { type: 'linear', min: 3000000, max: 8000000 },
    },
    axis: {
      x: {
        title: 'Region',
      },
      y: {
        title: 'Anomalous Claims Detected',
      },
    },
    tooltip: {
      showMarkers: false,
      shared: true,
      items: [
        { channel: 'y', name: 'Claims Detected' },
        { channel: 'color', name: 'Fraud Savings', valueFormatter: (d) => `$${(d / 1000000).toFixed(2)}M` },
      ],
    },
    color: {
      range: ['#D3E5FF', '#08306B'],
    },
    style: {
      stroke: '#fff',
      lineWidth: 1,
    },
    title: {
      text: 'Heatmap of Fraud Detection by Region',
      style: {
        fontSize: 14,
        fontWeight: 'bold',
      },
    },
  };

  spendingTrendAnalyticsConfig = {
    type: 'interval',
    autoFit: true,
    data: [
      { category: 'Hospital Care', amount: 2 }, // 2 milyar
      { category: 'Prescription Drugs', amount: 1.5 }, // 1.5 milyar
      { category: 'Long-Term Care', amount: 1 } // 1 milyar
    ],
    encode: { x: 'category', y: 'amount' },
    adjust: [{ type: 'stack' }],
    axis: {
      x: {
        title: null,
        label: {
          autoHide: true,
          autoRotate: true
        }
      },
      y: {
        title: 'Spending ($ Billion)',
        label: {
          formatter: (v: any) => `${v}B` // Y ekseninde milyar birimini gösterir
        }
      }
    },
    color: {
      field: 'category',
      range: ['#5B8FF9', '#61DDAA', '#65789B'] // Kategorilere göre farklı renkler
    },
    legend: {
      position: 'top'
    }
  };

  // <!-- SUMMARY CHART 1 -->
  totalEnrollmentPieChartConfig = {
    type: 'interval',
    autoFit: true,
    data: [
      { item: 'Non ABD Children', count: 813930, percent: 813930 / 1824332 },
      { item: 'Non ABD Adults', count: 706398, percent: 706398 / 1824332 },
      { item: 'Aged', count: 103801, percent: 103801 / 1824332 },
      { item: 'Blind', count: 837, percent: 837 / 1824332 },
      { item: 'Disabled', count: 199366, percent: 199366 / 1824332 },
    ],
    encode: { y: 'percent', color: 'item' },
    transform: [{ type: 'stackY' }],
    coordinate: { type: 'theta', outerRadius: 0.8 },
    legend: {
      color: { position: 'bottom', layout: { justifyContent: 'center' } },
    },
    labels: [
      {
        position: 'outside',
        text: (data) => `${data.item}: ${data.count.toLocaleString()} | ${(data.percent * 100).toFixed(2)}%`,
      },
    ],
    tooltip: {
      items: [
        (data) => ({
          name: data.item,
          value: `${(data.percent * 100).toFixed(2)}%`,
        }),
      ],
    },
  };

  totalEnrollmentPieChartContent = {
    title: {
      type: HeadingType.H2,
      content: 'Total Current Enrollment',
    },
    subtitle: {
      type: HeadingType.H4,
      content: '1,824,332',
    }
  };


  // <!-- SUMMARY CHART 3 -->
  applicationPieConfig = {
    type: 'interval',
    autoFit: true,
    data: [
      { item: 'Approved', count: 151250, percent: 151250 / 275000 },
      { item: 'Denied', count: 123750, percent: 123750 / 275000 },
    ],
    encode: { y: 'percent', color: 'item' },
    transform: [{ type: 'stackY' }],
    coordinate: { type: 'theta', outerRadius: 0.8 },
    legend: {
      color: { position: 'bottom', layout: { justifyContent: 'center' } },
    },
    labels: [
      {
        position: 'spider',
        text: (data) => `${data.item}: ${data.count.toLocaleString()} | ${(data.percent * 100).toFixed(2)}%`,
      },
    ],
    tooltip: {
      items: [
        (data) => ({
          name: data.item,
          value: `${(data.percent * 100).toFixed(2)}%`,
        }),
      ],
    },
  };

  applicationPieConfigContent = {
    title: {
      type: HeadingType.H2,
      content: 'New Application BY 2024',
    },
    subtitle: {
      type: HeadingType.H4,
      content: '275,000',
    }
  };


  /* <!-- SUMMARY CHART 4 -->  */
  newProgramEnrollmentBarConfig = {
    'type': 'interval',
    'autoFit': true,
    'data': [
      { 'program': 'MLTSS: Caregiver Supports', 'value': 45000, 'color': '#A3C1AD' },
      { 'program': 'Behavioral Health Integration', 'value': 35000, 'color': '#7D8491' },
      { 'program': 'Community Health Worker (CHW) Pilot', 'value': 60000, 'color': '#A3C1AD' },
      { 'program': 'Behavioral Health Promoting Interoperability Program (BH PIP)', 'value': 50000, 'color': '#7D8491' }
    ],
    'encode': { 'x': 'program', 'y': 'value', 'color': 'color' },
    'coordinate': { 'transform': [{ 'type': 'transpose' }] },
  };

  newProgramEnrollmentBarContent = {
    title: {
      type: HeadingType.H2,
      content: 'New Program Enrollment To Date',
    }
  };

  // <!-- SUMMARY CHART 5 -->
  serviceUtilizationToDateBarConfig = {
    'type': 'interval',
    'autoFit': true,
    'data': [
      { 'program': 'MLTSS: Caregiver Supports', 'value': 24000000, 'color': '#A3C1AD' },
      { 'program': 'Behavioral Health Integration', 'value': 20000000, 'color': '#7D8491' },
      { 'program': 'Community Health Worker (CHW) Pilot', 'value': 8000000, 'color': '#A3C1AD' },
      { 'program': 'Behavioral Health Promoting Interoperability Program (BH PIP)', 'value': 6000000, 'color': '#7D8491' }
    ],
    'encode': { 'x': 'program', 'y': 'value', 'color': 'color' },
    'coordinate': { 'transform': [{ 'type': 'transpose' }] },
  };

  serviceUtilizationToDateBarContent = {
    title: {
      type: HeadingType.H2,
      content: 'New Program Service Utilization To Date',
    }
  };

}
