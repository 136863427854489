import { Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  constructor() { }

  removeParamsFromUrl(url: string, params: Record<string, string>) {
    // remove query params from url
    const urlWithoutQueryParams = url.split('?')[0] || url;
    // hold query params for use after remove params
    const queryParams = url.split('?')[1];
    // split url to segments
    const urlSegments = urlWithoutQueryParams.split('/');
    // remove params from url segments
    const urlSegmentsWithoutParams = urlSegments.filter(urlSegment => !Object.values(params).some(param => param === urlSegment));
    // when remove params join url segments with '/'
    const urlWithoutParams = urlSegmentsWithoutParams.join('/');
    // if there is queryParams assign queryParams to url urlSegmentsWithoutParams
    return queryParams ? urlWithoutParams + `?${queryParams}` : urlWithoutParams;
  }

}
