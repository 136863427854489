import { PropertyType, RowFormColumn } from '@rappider/rappider-components/utils';

export const CUSTOM_ENDPOINT_PARAMS_AND_QUERY_PARAMS_ROW_FORM_CONFIG: RowFormColumn[] = [
  {
    fieldName: 'id',
    typeAndFormat: {
      type: PropertyType.String
    },
    visible: false
  },
  {
    fieldName: 'name',
    typeAndFormat: {
      type: PropertyType.String
    },
    config: {
      placeholder: 'Name'
    },
    unique: true
  },
  {
    fieldName: 'typeId',
    typeAndFormat: {
      type: PropertyType.String
    },
    visible: false,
  }
];
