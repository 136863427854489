/* eslint-disable */
import { HttpRequest } from '../index';

declare var Object: any;
export interface DynamicInputInterface {
  id?: string;
  name?: string;
  tags?: string;
  inputType?: string;
  pageComponentId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  httpRequest?: HttpRequest;
}

export class DynamicInput implements DynamicInputInterface {
  'id'?: string;
  'name': string;
  'tags': string;
  'inputType': string;
  'pageComponentId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  httpRequest: HttpRequest;
  constructor(data?: DynamicInputInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `DynamicInput`.
   */
  public static getModelName() {
    return 'DynamicInput';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of DynamicInput for dynamic purposes.
   **/
  public static factory(data: DynamicInputInterface): DynamicInput {
    return new DynamicInput(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'DynamicInput',
      plural: 'DynamicInputs',
      path: 'dynamic-inputs',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        tags: {
          name: 'tags',
          type: 'string',
        },
        inputType: {
          name: 'inputType',
          type: 'string',
        },
        pageComponentId: {
          name: 'pageComponentId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
      },
      relations: {
        httpRequest: {
          name: 'httpRequest',
          type: 'HttpRequest',
          model: 'HttpRequest',
          relationType: 'hasOne',
          keyFrom: 'id',
          keyTo: 'httpRequestId',
        },
      },
    };
  }
}
