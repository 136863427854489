import {
  ButtonType,
  ActionBehavior,
  CrudViewColumnType
} from '@rappider/rappider-components/utils';

export const WORKFLOW_STEP_FUNCTION_DATA_TRANSFORMATIONS_CONFIG = {
  defaultSearchField: 'name',
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewColumnType.Text,
    }
  ],
  listActions: [
    {
      text: 'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_DETAIL_COMPONENT.ADD_TRANSFORMATION',
      buttonType: ButtonType.Default,
      behavior: ActionBehavior.Emit
    }
  ],
  itemActions: [
    {
      text: 'SHARED.DELETE',
      name: 'DELETE_RELATION',
      behavior: ActionBehavior.Emit,
      icon: { name: 'far fa-trash' }
    }
  ]
};
