import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DataMappingWrapperComponent } from './data-mapping-wrapper/data-mapping-wrapper.component';
import { RappiderButtonModule, RappiderHeadingModule, RappiderJsonataEditorModule, RappiderListGridModule, RappiderMonacoEditorModule, RappiderSelectModule, RappiderSpinModule } from '@rappider/rappider-components';
import { FormsModule } from '@angular/forms';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { CreateDataMappingComponent } from './create-data-mapping/create-data-mapping.component';
import { RappiderModalModule } from '@rappider/rappider-components';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { JoyrideModule } from 'ngx-joyride';

const routes: Routes = [

];

@NgModule({
  declarations: [
    DataMappingWrapperComponent,
    CreateDataMappingComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    RappiderListGridModule,
    FormsModule,
    SharedComponentsModule,
    RappiderModalModule,
    DragDropModule,
    RappiderButtonModule,
    TranslateModule,
    RappiderHeadingModule,
    RappiderSelectModule,
    NzDropDownModule,
    RappiderJsonataEditorModule,
    NzButtonModule,
    RappiderMonacoEditorModule,
    JoyrideModule.forChild(),
    RappiderSpinModule
  ],
  exports: [
    DataMappingWrapperComponent,
    CreateDataMappingComponent
  ]
})
export class DataMappingModule { }
