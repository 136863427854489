import { FeedbackButtonActionBehavior } from '@rappider/rappider-components/utils';

export const VERIFY_EMAIL_ADDRESS_CONFIG = {
  title: 'AUTHENTICATION_MODULE.EMAIL_VERIFY_COMPONENT.VERIFY_EMAIL_ADDRESS.VERIFY_EMAIL_ADDRESS_TITLE',
  icon: 'far fa-envelope',
  subTitle: 'AUTHENTICATION_MODULE.EMAIL_VERIFY_COMPONENT.VERIFY_EMAIL_DESCRIPTION',
  buttons: [{
    content: 'SHARED.GO_TO_LOGIN_PAGE',
    actionBehavior: FeedbackButtonActionBehavior.Route,
    redirectUrl: '/'
  },
  {
    content: 'AUTHENTICATION_MODULE.EMAIL_VERIFY_COMPONENT.VERIFY_EMAIL_ADDRESS.VERIFY_EMAIL_ADDRESS_RESEND_BUTTON',
    actionBehavior: FeedbackButtonActionBehavior.Emit
  }]
};

export const RESEND_EMAIL_VERIFY_CONFIG = {
  title: 'AUTHENTICATION_MODULE.EMAIL_VERIFY_COMPONENT.RESEND_EMAIL_VERIFY_INFO.SENT_VERIFICATION_EMAIL_TITLE',
  icon: 'far fa-envelope',
  subTitle: 'AUTHENTICATION_MODULE.EMAIL_VERIFY_COMPONENT.RESEND_EMAIL_VERIFY_INFO.SENT_VERIFICATION_EMAIL_DESCRIPTION'
};
