<div class="notification-wrapper">
  <a class="notification-link"
     nz-dropdown
     nzTrigger="click"
     [nzDropdownMenu]="popNotification"
     [nzPlacement]="'bottomRight'"
     [(nzVisible)]="notificationVisible">
    <nz-badge [nzCount]="unreadNotificationCount"
              [nzOverflowCount]="maxVisibleNotifications"
              nzSize="small">
      <i class="icon"
         nz-icon
         nzType="bell"
         theme="outline"></i>
    </nz-badge>
  </a>
  <nz-dropdown-menu #popNotification="nzDropdownMenu">
    <div nz-menu
         class="pop-notification">
      <div class="notification-top-area">
        <p class="notification-title">
          <i class="icon"
             nz-icon
             nzType="bell"
             theme="outline"></i>
          <span class="notification-title-text">Notifications</span>
        </p>
        <a nz-button
           nzType="default"
           nzSize="small">
          <small>View All</small>
        </a>
      </div>
      <div>
        <nz-list class="ant-list-item-links"
                 [nzDataSource]="notificationList"
                 [nzRenderItem]="item"
                 [nzItemLayout]="'horizontal'">
          <ng-template #item
                       let-item>
            <nz-list-item>
              <ng-container *ngIf="item.status === NotificationStatus.Read; else unread">
                <a class="read-background"
                   (click)="onSelectNotification(item)">
                  <div class="notification-item">
                    <nz-avatar class="notification-item-avatar" [nzIcon]="item.icon"
                               style="color: {{item.color}}; background-color: {{item.backgroundColor}};"></nz-avatar>
                    <div class="notification-item-text-wrapper">
                      <p class="notification-item-text read-paragraph">{{item.title}}</p>
                      <p class="notification-item-text read-paragraph">
                        <small>{{item.time}}</small>
                      </p>
                    </div>
                  </div>
                </a>
              </ng-container>
              <ng-template #unread>
                <a class="unread-background"
                   (click)="onSelectNotification(item)">
                  <div class="notification-item">
                    <nz-avatar class="notification-item-avatar" [nzIcon]="item.icon"
                               style="color: {{item.color}}; background-color: {{item.backgroundColor}};"></nz-avatar>
                    <div class="notification-item-text-wrapper">
                      <p class="notification-item-text unread-paragraph">{{item.title}}</p>
                      <p class="notification-item-text unread-paragraph">
                        <small>{{item.time}}</small>
                      </p>
                    </div>
                  </div>
                </a>
              </ng-template>
            </nz-list-item>
          </ng-template>

          <nz-list-empty *ngIf="!notificationList?.length"
                         [nzNoResult]="emptyNotificationTemplate"></nz-list-empty>
          <ng-template #emptyNotificationTemplate>
            <div class="empty-notification">
              <div class="animation">
                <rappider-lottie-player [options]="emptyNotificationAnimation"
                                        [width]="'100%'"
                                        [height]="'100%'"></rappider-lottie-player>
              </div>
              <p>{{'There are currently no new notifications' | translate}}</p>
            </div>
          </ng-template>
        </nz-list>
      </div>
    </div>
  </nz-dropdown-menu>
</div>
