<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>

<section>
  <rappider-spin [spinning]="!activeProjectId">
    <nz-select class="search-area"
               nzPlaceHolder="Type package name to check if it exists (e.g., lodash)"
               nzAllowClear
               nzShowSearch
               nzServerSearch
               [(ngModel)]="selectedPackage"
               (nzOnSearch)="onSearchValueChange($event)"
               (ngModelChange)="onSelectPackage($event)">
      <ng-container *ngFor="let package of npmPackages">
        <nz-option *ngIf="!isLoading"
                   [nzValue]="package.value"
                   [nzLabel]="package.key"></nz-option>
      </ng-container>
      <nz-option *ngIf="isLoading"
                 nzDisabled
                 nzCustomContent>
        <rappider-icon class="loading-icon"
                       [name]="loadingIcon.name"
                       [type]="loadingIcon.type"></rappider-icon>
        {{"SHARED.LOADING" | translate}}
      </nz-option>
    </nz-select>

    <div class="package-details-area">
      <rappider-label *ngIf="packageDetails"
                      content="Package Details"></rappider-label>

      <div *ngIf="packageDetails; else noPackageTmpl">
        <section>
          <div *ngFor="let item of packageDetails"
               class="item-area">
            <div class="property-title col-2">
              <rappider-label [content]="item.key | translate"></rappider-label>
            </div>

            <div class="property-value col-10">
              <div *ngFor="let value of item.value">
                <rappider-text *ngIf="item.key !== 'SHARED.VERSION'"
                               [text]="value"></rappider-text>
                <rappider-select *ngIf="item.key === 'SHARED.VERSION'"
                                 [grouppedOptions]="value"
                                 [ngModel]="selectedVersion"
                                 [placeholder]="selectedVersion"
                                 [cssStyle]="{width: '300px'}"
                                 (valueChange)="onVersionChange($event)"></rappider-select>
              </div>
            </div>
          </div>
        </section>
      </div>

      <ng-template #noPackageTmpl>
        <nz-form-item>
          <rappider-alert [type]="projectPackageInfoAlertConfig.type"
                          [title]="projectPackageInfoAlertConfig.title"
                          [description]="projectPackageInfoAlertConfig.description"
                          [closeable]="projectPackageInfoAlertConfig.closeable"
                          [showIcon]="projectPackageInfoAlertConfig.showIcon"></rappider-alert>
        </nz-form-item>
      </ng-template>
    </div>
    <div *ngIf="isCreatePackageForBackend">
      <nz-form-item class="form-item">
        <nz-form-label>
          {{ 'Expose To Frontend' | translate}}
        </nz-form-label>
        <label nz-checkbox
               [(ngModel)]="exposeToFrontend"
               (ngModelChange)="clickExposeToFrontend($event)"></label>
      </nz-form-item>
    </div>
    <div *ngIf="!isCreatePackageForBackend">
      <nz-form-item class="form-item">
        <nz-form-label>
          {{ 'Expose To Backend' | translate}}
        </nz-form-label>
        <label nz-checkbox
               [(ngModel)]="exposeToBackend"
               (ngModelChange)="clickExposeToBackend($event)"></label>
      </nz-form-item>
    </div>

    <div *ngIf="!defaultSaveButtonDisabled">
      <rappider-button [text]="addProjectPackageButtonConfig.text "
                       [type]="addProjectPackageButtonConfig.type"
                       [disabled]="selectedVersion === 'Choose Version'"
                       (click)="onAddProjectPackage()"></rappider-button>
    </div>

  </rappider-spin>
</section>