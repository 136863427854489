import { CrudViewFormItemType } from '@rappider/rappider-components/utils';
import { DataSchemaFieldWithRelations } from '@rappider/rappider-sdk';
import { TextAreaConfig } from './text-area.config';
import { CheckboxListConfig } from './checkbox-list.config';
import { setNumberArrayConfig } from './number-array.config';
import { setDateArrayConfig } from './date-picker-array.config';
import { MonacoEditorConfig } from './monaco-editor.config';

export function setUIDataSelectorConfig(dataSchemaField: DataSchemaFieldWithRelations) {
  /* if dataSchemaField has no uiDataSelector */
  if (!dataSchemaField.uiDataSelector) {
    /* special condition for description field */
    if (dataSchemaField.name.toLocaleLowerCase().includes('description')) {
      dataSchemaField.uiDataSelector = TextAreaConfig.uiDataSelector;
      dataSchemaField.uiDataSelectorSettings = TextAreaConfig.settings;
    } else if (dataSchemaField.type.name === 'string') {
      /* if type is string, default selector is Textbox */
      /* if type is string [], default selector is StringArray */
      dataSchemaField.uiDataSelector = !dataSchemaField.isArray ? CrudViewFormItemType.TextBox
        : dataSchemaField.uiDataSelector = CrudViewFormItemType.StringArray;
    } else if (dataSchemaField.type.name === 'number') {
      /* if type is number, default selector is Number */
      /* if type is number [], default selector is RowForm */
      dataSchemaField.uiDataSelector = !dataSchemaField.isArray ? CrudViewFormItemType.Number
        : CrudViewFormItemType.InlineRowForm;
      dataSchemaField.uiDataSelectorSettings = dataSchemaField.isArray ? setNumberArrayConfig(dataSchemaField.name) : null;
    } else if (dataSchemaField.type.name === 'Date') {
      /* if type is Date, default selector is DatePicker */
      /* if type is Date [], default selector is RowForm */
      dataSchemaField.uiDataSelector = !dataSchemaField.isArray ? CrudViewFormItemType.DatePicker
        : CrudViewFormItemType.InlineRowForm;
      dataSchemaField.uiDataSelectorSettings = dataSchemaField.isArray ? setDateArrayConfig(dataSchemaField.name) : null;
    } else if (dataSchemaField.type.name === 'boolean') {
      /* if type is boolean, default selector is Checkbox */
      /* if type is boolean [], default selector is CheckboxList */
      dataSchemaField.uiDataSelector = !dataSchemaField.isArray ? CrudViewFormItemType.CheckBox
        : CrudViewFormItemType.CheckboxList;
      dataSchemaField.uiDataSelectorSettings = dataSchemaField.isArray ? CheckboxListConfig : null;
    } else if (dataSchemaField.type.name === 'any') {
      /* if type is any/object */
      dataSchemaField.uiDataSelector = CrudViewFormItemType.MonacoCodeEditor;
      dataSchemaField.uiDataSelectorSettings = MonacoEditorConfig;
    } else {
      dataSchemaField.uiDataSelector = null;
    }
  }
  return dataSchemaField;
}
