import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportOpenapiComponent } from './components/import-openapi/import-openapi.component';
import { OpenapiEndpointListComponent } from './components/openapi-endpoint-list/openapi-endpoint-list.component';
import { OpenapiListComponent } from './components/openapi-list/openapi-list.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@rappider/authentication/guards';
import { PAGE_DEFINITIONS } from '@rappider/shared/definitions';
import { ActiveProjectGuard, VerifyPersonGuard } from '@rappider/shared/guards';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RappiderListGridModule, RappiderTitleToolbarModule, RappiderTextboxModule, RappiderTextModule, RappiderCodeEditorModule, RappiderSpinModule, RappiderTextareaModule } from '@rappider/rappider-components';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { RappiderLabelModule } from '../../../../../components/src/lib/components/label/label.module';

const routes: Routes = [
  {
    path: PAGE_DEFINITIONS.PROJECTS.CHILDREN.OPENAPI_LIST.URL,
    component: OpenapiListComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
  },
  {
    path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.OPENAPI_ENDPOINT_LIST.URL}/:id`,
    component: OpenapiEndpointListComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
  }
];

@NgModule({
  declarations: [
    ImportOpenapiComponent,
    OpenapiEndpointListComponent,
    OpenapiListComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    RappiderListGridModule,
    RappiderTitleToolbarModule,
    RappiderTextboxModule,
    RappiderTextModule,
    RappiderCodeEditorModule,
    RappiderSpinModule,
    RappiderTextareaModule,
    NzModalModule,
    RappiderLabelModule
  ]
})
export class RappiderOpenapiModule { }
