import { ItemPerRow, HeadingType, SelectMode } from '@rappider/rappider-components/utils';

export const templateCardsConfig = {
    itemCountPerRow: ItemPerRow.Three,
    showTitleOnImage: false,
    showDescriptionOnImage: false,
    showCheckMarkOnCard: true,
    title: {
        mainTitle: {
            content: 'Page Templates',
            type: HeadingType.H2
        }
    },
    paginationConfig: {
        pageIndex: 1,
        total: 3,
        pageSize: 6,
        showPagination: true
    },
    selectConfig: {
        options: [],
        settings: {
            mode: SelectMode.Multiple,
            maxTagCount: 1
        },
        placeholder: 'Filter Tags'
    },
    items: []
};
