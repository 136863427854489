/* angular libs */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormService, LanguageService } from './services';
import { HttpClientModule } from '@angular/common/http';

/* 3rd party */
import { TranslateModule, TranslateService } from '@ngx-translate/core';

/* pipes */
import { FormatDatePipe } from './pipes/format-date.pipe';

/* directives */
import { UnwrapTagDirective } from './directives/unwrap-tag.directive';
import { ResizableDirective } from './directives/resizable';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';

/* services */
import { BrowserStorageManagementService } from './services/browser-storage-management-service/browser-storage-management.service';

/* state Services ( facade ) */
import { RouterStateService } from './services/router-state/router-state.service';
import { FromNowPipe } from './pipes/from-now/from-now.pipe';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';
import { SafeUrlPipe } from './pipes/safe-url/safe-url.pipe';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule
  ],
  providers: [
    FormService,
    BrowserStorageManagementService,
    LanguageService,
    TranslateService,
    RouterStateService,
  ],
  declarations: [
    FormatDatePipe,
    UnwrapTagDirective,
    ResizableDirective,
    FromNowPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    ClickStopPropagationDirective
  ],
  exports: [
    FormatDatePipe,
    UnwrapTagDirective,
    ResizableDirective,
    FromNowPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    ClickStopPropagationDirective
  ]
})
export class SharedModule {
}
