/* eslint-disable */
import { Project } from '../index';

declare var Object: any;
export interface KeyValueInterface {
  id?: string;
  key?: string;
  value?: any;
  projectId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  project?: Project;
}

export class KeyValue implements KeyValueInterface {
  'id'?: string;
  'key': string;
  'value': any;
  'projectId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  project: Project;
  constructor(data?: KeyValueInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `KeyValue`.
   */
  public static getModelName() {
    return 'KeyValue';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of KeyValue for dynamic purposes.
   **/
  public static factory(data: KeyValueInterface): KeyValue {
    return new KeyValue(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'KeyValue',
      plural: 'KeyValues',
      path: 'key-values',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        key: {
          name: 'key',
          type: 'string',
        },
        value: {
          name: 'value',
          type: 'any',
        },
        projectId: {
          name: 'projectId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
      },
      relations: {
        project: {
          name: 'project',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
          keyFrom: 'projectId',
          keyTo: 'id',
        },
      },
    };
  }
}
