/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BulkUpdateResultDto } from '../models/bulk-update-result-dto';
import { ComponentOutputDefinition } from '../models/component-output-definition';
import { ComponentOutputDefinitionPartial } from '../models/component-output-definition-partial';
import { ComponentOutputDefinitionWithRelations } from '../models/component-output-definition-with-relations';
import { NewComponentOutputDefinition } from '../models/new-component-output-definition';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class ComponentOutputDefinitionControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation componentOutputDefinitionControllerBulkCreate
   */
  static readonly ComponentOutputDefinitionControllerBulkCreatePath = '/component-output-definitions/bulk-create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkCreate$Response(params?: {
    body?: Array<NewComponentOutputDefinition>
  }): Observable<StrictHttpResponse<Array<ComponentOutputDefinition>>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentOutputDefinitionControllerService.ComponentOutputDefinitionControllerBulkCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ComponentOutputDefinition>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkCreate(params?: {
    body?: Array<NewComponentOutputDefinition>
  }): Observable<Array<ComponentOutputDefinition>> {

    return this.bulkCreate$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ComponentOutputDefinition>>) => r.body as Array<ComponentOutputDefinition>)
    );
  }

  /**
   * Path part for operation componentOutputDefinitionControllerBulkDelete
   */
  static readonly ComponentOutputDefinitionControllerBulkDeletePath = '/component-output-definitions/bulk-delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkDelete$Response(params?: {
    body?: Array<any>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentOutputDefinitionControllerService.ComponentOutputDefinitionControllerBulkDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkDelete(params?: {
    body?: Array<any>
  }): Observable<void> {

    return this.bulkDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation componentOutputDefinitionControllerCustomBulkUpdate
   */
  static readonly ComponentOutputDefinitionControllerCustomBulkUpdatePath = '/component-output-definitions/bulk-update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customBulkUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customBulkUpdate$Response(params?: {
    body?: Array<any>
  }): Observable<StrictHttpResponse<BulkUpdateResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentOutputDefinitionControllerService.ComponentOutputDefinitionControllerCustomBulkUpdatePath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulkUpdateResultDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customBulkUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customBulkUpdate(params?: {
    body?: Array<any>
  }): Observable<BulkUpdateResultDto> {

    return this.customBulkUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<BulkUpdateResultDto>) => r.body as BulkUpdateResultDto)
    );
  }

  /**
   * Path part for operation componentOutputDefinitionControllerCount
   */
  static readonly ComponentOutputDefinitionControllerCountPath = '/component-output-definitions/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params?: {
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentOutputDefinitionControllerService.ComponentOutputDefinitionControllerCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params?: {
    where?: any;
  }): Observable<LoopbackCount> {

    return this.count$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation componentOutputDefinitionControllerFindById
   */
  static readonly ComponentOutputDefinitionControllerFindByIdPath = '/component-output-definitions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<ComponentOutputDefinition>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentOutputDefinitionControllerService.ComponentOutputDefinitionControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ComponentOutputDefinition>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
    filter?: any;
  }): Observable<ComponentOutputDefinition> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<ComponentOutputDefinition>) => r.body as ComponentOutputDefinition)
    );
  }

  /**
   * Path part for operation componentOutputDefinitionControllerDeleteById
   */
  static readonly ComponentOutputDefinitionControllerDeleteByIdPath = '/component-output-definitions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentOutputDefinitionControllerService.ComponentOutputDefinitionControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: string;
  }): Observable<void> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation componentOutputDefinitionControllerUpdateById
   */
  static readonly ComponentOutputDefinitionControllerUpdateByIdPath = '/component-output-definitions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    body?: ComponentOutputDefinitionPartial
  }): Observable<StrictHttpResponse<ComponentOutputDefinition>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentOutputDefinitionControllerService.ComponentOutputDefinitionControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ComponentOutputDefinition>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    body?: ComponentOutputDefinitionPartial
  }): Observable<ComponentOutputDefinition> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<ComponentOutputDefinition>) => r.body as ComponentOutputDefinition)
    );
  }

  /**
   * Path part for operation componentOutputDefinitionControllerFind
   */
  static readonly ComponentOutputDefinitionControllerFindPath = '/component-output-definitions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<ComponentOutputDefinitionWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentOutputDefinitionControllerService.ComponentOutputDefinitionControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ComponentOutputDefinitionWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<ComponentOutputDefinitionWithRelations>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ComponentOutputDefinitionWithRelations>>) => r.body as Array<ComponentOutputDefinitionWithRelations>)
    );
  }

  /**
   * Path part for operation componentOutputDefinitionControllerCreate
   */
  static readonly ComponentOutputDefinitionControllerCreatePath = '/component-output-definitions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    body?: NewComponentOutputDefinition
  }): Observable<StrictHttpResponse<ComponentOutputDefinition>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentOutputDefinitionControllerService.ComponentOutputDefinitionControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ComponentOutputDefinition>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    body?: NewComponentOutputDefinition
  }): Observable<ComponentOutputDefinition> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<ComponentOutputDefinition>) => r.body as ComponentOutputDefinition)
    );
  }

}
