import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TimelineItem } from '../../utils/timeline/timeline-item.interface';

@Component({
  selector: 'rappider-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class RappiderTimelineComponent implements OnInit, OnChanges {

  @Input() items: TimelineItem[] = [];
  @Input() reverseTreeItem = false;

  isTimelineItemsHasIcon = false;

  ngOnInit(): void {
    this.setIsTimelineItemsHasIconStatus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      this.setIsTimelineItemsHasIconStatus();
    }
  }

  setIsTimelineItemsHasIconStatus() {
    this.isTimelineItemsHasIcon = this.items?.some(item => item.icon);
  }

}
