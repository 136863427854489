import { DEFAULT_PAGINATION } from '@rappider/shared/definitions';
import { Pagination } from '@rappider/shared/interfaces';
import * as EnvironmentVariableActions from './environment-variable.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import { EnvironmentVariable } from '@rappider/rappider-sdk';
import { orderBy } from 'lodash';

export const environmentVariableFeatureKey = 'environmentVariable';

export interface State {
  data: EnvironmentVariable[];
  environments: string[];
  activeEnvironment: string;
  loading: boolean;
}

export const initialState: State = {
  data: null,
  environments: null,
  activeEnvironment: 'DEV',
  loading: false
};

export function reducer(
  state: State = initialState,
  action: EnvironmentVariableActions.Actions | AuthenticationActions.Actions
): State {
  switch (action.type) {
    case EnvironmentVariableActions.ActionTypes.DeleteEnvironmentVariable:
      return {
        ...state,
        loading: true
      };

    case EnvironmentVariableActions.ActionTypes.CreateEnvironmentVariable:
      return {
        ...state,
        loading: true
      };

    case EnvironmentVariableActions.ActionTypes.UpdateEnvironmentVariable:
      return {
        ...state,
        loading: true
      };

    case EnvironmentVariableActions.ActionTypes.UpdateEnvironmentVariableSuccessful:
      {
        const updatedEnvironmentVariable = {
          ...state.data.find(item => item.id === action.payload.environmentVariableId),
          ...action.payload.environmentVariable
        };

        return {
          ...state,
          data: orderBy(
            [
              ...state.data.filter(item => item.id !== action.payload.environmentVariableId),
              updatedEnvironmentVariable
            ],
            'createdDate', 'asc'
          ),
          loading: false
        };
      }

    case EnvironmentVariableActions.ActionTypes.GetEnvironmentVariables:
      return {
        ...state,
        loading: true
      };

    case EnvironmentVariableActions.ActionTypes.GetEnvironmentVariablesSuccessful:
      return {
        ...state,
        data: orderBy(action.payload.environmentVariables, 'createdDate', 'asc'),
        loading: false
      };

    case EnvironmentVariableActions.ActionTypes.CreateEnvironmentVariableSuccessful:
      return {
        ...state,
        loading: false,
        data: orderBy(
          [
            ...state.data,
            ...action.payload.environmentVariables
          ],
          'createdDate', 'asc'
        )
      };

    case EnvironmentVariableActions.ActionTypes.DeleteEnvironmentVariableSuccessful:
      return {
        ...state,
        data: state.data.filter(variable => variable.id !== action.payload.environmentVariableId),
        loading: false
      };

    case EnvironmentVariableActions.ActionTypes.SetActiveEnvironment:
      return {
        ...state,
        activeEnvironment: action.payload.environment
      };

    case EnvironmentVariableActions.ActionTypes.SetEnvironments:
      return {
        ...state,
        environments: action.payload.environments
      };

    case EnvironmentVariableActions.ActionTypes.EnvironmentVariableError:
      return {
        ...state,
        loading: false
      };

    case AuthenticationActions.ActionTypes.Logout:
      return initialState;

    case AuthenticationActions.ActionTypes.ChangeActiveProjectSuccessful:
      return initialState;

    default:
      return state;
  }
}
