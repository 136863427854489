import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RappiderIconPickerWrapperComponent } from './icon-picker-wrapper.component';
import { RappiderButtonModule } from '../button/button.module';
import { RappiderModalModule } from '../modal/modal.module';
import { RappiderIconPickerTwoModule } from '../icon-picker-two/icon-picker-two.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    RappiderIconPickerWrapperComponent
  ],
  imports: [
    CommonModule,
    RappiderButtonModule,
    RappiderModalModule,
    RappiderIconPickerTwoModule,
    FormsModule
  ],
  exports: [
    RappiderIconPickerWrapperComponent
  ]
})
export class RappiderIconPickerWrapperModule { }
