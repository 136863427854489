/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PersonProjectWithRelations } from '../models/person-project-with-relations';

@Injectable({
  providedIn: 'root',
})
export class PersonProjectControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation personProjectControllerFavorite
   */
  static readonly PersonProjectControllerFavoritePath = '/person-projects/favorite/{projectId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `favorite()` instead.
   *
   * This method doesn't expect any request body.
   */
  favorite$Response(params: {
    projectId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PersonProjectControllerService.PersonProjectControllerFavoritePath, 'patch');
    if (params) {
      rb.path('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `favorite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  favorite(params: {
    projectId: string;
  }): Observable<void> {

    return this.favorite$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation personProjectControllerUnfavorite
   */
  static readonly PersonProjectControllerUnfavoritePath = '/person-projects/unfavorite/{projectId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unfavorite()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfavorite$Response(params: {
    projectId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PersonProjectControllerService.PersonProjectControllerUnfavoritePath, 'patch');
    if (params) {
      rb.path('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unfavorite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfavorite(params: {
    projectId: string;
  }): Observable<void> {

    return this.unfavorite$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation personProjectControllerFind
   */
  static readonly PersonProjectControllerFindPath = '/person-projects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<PersonProjectWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, PersonProjectControllerService.PersonProjectControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PersonProjectWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<PersonProjectWithRelations>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PersonProjectWithRelations>>) => r.body as Array<PersonProjectWithRelations>)
    );
  }

  /**
   * Path part for operation personProjectControllerUnassignPersonFromProject
   */
  static readonly PersonProjectControllerUnassignPersonFromProjectPath = '/unassign-person/{personId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unassignPersonFromProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  unassignPersonFromProject$Response(params: {
    personId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PersonProjectControllerService.PersonProjectControllerUnassignPersonFromProjectPath, 'post');
    if (params) {
      rb.path('personId', params.personId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unassignPersonFromProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unassignPersonFromProject(params: {
    personId: string;
  }): Observable<void> {

    return this.unassignPersonFromProject$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
