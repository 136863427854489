/* eslint-disable no-shadow */
import { Action } from '@ngrx/store';
import { MessageTemplateInterface } from '@rappider/api-sdk';
import { MessageTemplateWithRelations } from '@rappider/rappider-sdk';
import { Pagination } from '@rappider/shared/interfaces';

export enum ActionTypes {
  LoadModule = '[MessageTemplate] LoadModule',
  SetPagination = '[MessageTemplate] SetPagination',
  SetPaginationFailure = '[MessageTemplate] SetPaginationFailure',
  GetTotalCount = '[MessageTemplate] GetTotalCount',
  GetTotalCountFailure = '[MessageTemplate] GetTotalCountFailure',
  GetMessageTemplates = '[MessageTemplate] GetMessageTemplates',
  GetMessageTemplatesSuccessful = '[MessageTemplate] GetMessageTemplatesSuccessful',
  GetMessageTemplatesFailure = '[MessageTemplate] GetMessageTemplatesFailure',
  CreateMessageTemplate = '[MessageTemplate] CreateMessageTemplate',
  CreateMessageTemplateSuccessful = '[MessageTemplate] CreateMessageTemplateSuccessful',
  CreateMessageTemplateFailure = '[MessageTemplate] CreateMessageTemplateFailure',
  UpdateMessageTemplate = '[MessageTemplate] UpdateMessageTemplate',
  UpdateMessageTemplateSuccessful = '[MessageTemplate] UpdateMessageTemplateSuccessful',
  UpdateMessageTemplateFailure = '[MessageTemplate] UpdateMessageTemplateFailure',
  DeleteMessageTemplate = '[MessageTemplate] DeleteMessageTemplate',
  DeleteMessageTemplateSuccessful = '[MessageTemplate] DeleteMessageTemplateSuccessful',
  DeleteMessageTemplateFailure = '[MessageTemplate] DeleteMessageTemplateFailure',
  ActiveEnvironmentChange = '[MessageTemplate] ActiveEnvironmentChange',
  PaginationReset = '[MessageTemplate] PaginationReset',
}

export class LoadModule implements Action {
  readonly type = ActionTypes.LoadModule;
}

export class GetMessageTemplates implements Action {
  readonly type = ActionTypes.GetMessageTemplates;
}

export class GetMessageTemplatesFailure implements Action {
  readonly type = ActionTypes.GetMessageTemplatesFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

/**
 * sets pagination and gets data after the action if specified
 *
 * @export
 * @class SetPagination
 * @implements {Action}
 */
export class SetPagination implements Action {
  readonly type = ActionTypes.SetPagination;
  constructor(public payload: { pagination: Pagination; getDataAfter?: boolean }) { }
}

export class SetPaginationFailure implements Action {
  readonly type = ActionTypes.SetPaginationFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class GetTotalCount implements Action {
  readonly type = ActionTypes.GetTotalCount;
}

export class GetTotalCountFailure implements Action {
  readonly type = ActionTypes.GetTotalCountFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class GetMessageTemplatesSuccessful implements Action {
  readonly type = ActionTypes.GetMessageTemplatesSuccessful;
  constructor(public payload: { messageTemplates: MessageTemplateWithRelations[] }) { }
}

export class CreateMessageTemplate implements Action {
  readonly type = ActionTypes.CreateMessageTemplate;
  constructor(public payload: { messageTemplate: MessageTemplateInterface }) { }
}

export class CreateMessageTemplateSuccessful implements Action {
  readonly type = ActionTypes.CreateMessageTemplateSuccessful;
  constructor(public payload: { messageTemplate: MessageTemplateInterface }) { }
}

export class CreateMessageTemplateFailure implements Action {
  readonly type = ActionTypes.CreateMessageTemplateFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class UpdateMessageTemplate implements Action {
  readonly type = ActionTypes.UpdateMessageTemplate;
  constructor(public payload: { messageTemplate: MessageTemplateInterface; messageTemplateId: string }) { }
}

export class UpdateMessageTemplateSuccessful implements Action {
  readonly type = ActionTypes.UpdateMessageTemplateSuccessful;
  constructor(public payload: { messageTemplate: MessageTemplateInterface; messageTemplateId: string }) { }
}

export class UpdateMessageTemplateFailure implements Action {
  readonly type = ActionTypes.UpdateMessageTemplateFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}


export class DeleteMessageTemplate implements Action {
  readonly type = ActionTypes.DeleteMessageTemplate;
  constructor(public payload: { messageTemplateId: string }) { }
}

export class DeleteMessageTemplateSuccessful implements Action {
  readonly type = ActionTypes.DeleteMessageTemplateSuccessful;
  constructor(public payload: { messageTemplateId: string }) { }
}

export class DeleteMessageTemplateFailure implements Action {
  readonly type = ActionTypes.DeleteMessageTemplateFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class ActiveEnvironmentChange implements Action {
  readonly type = ActionTypes.ActiveEnvironmentChange;
  constructor(public payload: { environment: string }) { }
}

export class PaginationReset implements Action {
  readonly type = ActionTypes.PaginationReset;
}

export type Actions =
  LoadModule
  | SetPagination
  | SetPaginationFailure
  | GetTotalCount
  | GetTotalCountFailure
  | GetMessageTemplates
  | GetMessageTemplatesSuccessful
  | GetMessageTemplatesFailure
  | CreateMessageTemplate
  | CreateMessageTemplateSuccessful
  | CreateMessageTemplateFailure
  | UpdateMessageTemplate
  | UpdateMessageTemplateSuccessful
  | UpdateMessageTemplateFailure
  | DeleteMessageTemplate
  | DeleteMessageTemplateSuccessful
  | DeleteMessageTemplateFailure
  | ActiveEnvironmentChange
  | PaginationReset
  ;
