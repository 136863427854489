<!-- ----------------------------------------------------------------------------------------- -->
<!-- TITLE AREA -->
<rappider-title-toolbar *ngIf="title"
                        [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton"></rappider-title-toolbar>
<!-- / TITLE AREA -->
<!-- ----------------------------------------------------------------------------------------- -->
<!-- ----------------------------------------------------------------------------------------- -->
<!-- PROJECT EXPORT FORM AREA -->
<section>
  <div class="col-12 col-lg-6">
    <rappider-crud-view-edit-form [config]="PROJECT_VERSION_CREATE_CONFIG"
                                  [data]="projectVersionData"
                                  [submitButtonLoading]="submitButtonLoading"
                                  (fieldValueChange)="onFieldValueChange($event)"
                                  (formSubmit)="onProjectVersionCreateClick($event)"></rappider-crud-view-edit-form>
  </div>
</section>
<!-- ----------------------------------------------------------------------------------------- -->
<!-- / PROJECT EXPORT FORM AREA -->
