import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataOrderService {


  changeOrderByStartAndEndIndex(previousData: any[], changedData: any[], startIndex: number, endIndex: number) {
    const finalData = [
      ...previousData.slice(0, startIndex),
      ...changedData,
      ...previousData.slice(endIndex + 1)
    ];
    console.log(finalData);
    return finalData;
  }
}
