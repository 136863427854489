<!-- ----------------------------------------------------------------------------------------- -->
<!-- TITLE AREA -->
<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>
<!-- / TITLE AREA -->
<!-- ----------------------------------------------------------------------------------------- -->

<!-- ----------------------------------------------------------------------------------------- -->
<section>
  <!-- ----------------------------------------------------------------------------------------- -->

  <nz-card>
    <nz-tabset nzType="card"
               nzAnimated="true"
               nzTabPosition="top"
               class="card-container"
               (nzSelectChange)="onTabChange($event)">
      <nz-tab *ngFor="let projectSettingTab of projectSettingTabs"
              [nzTitle]="projectSettingTab">
        <rappider-list-grid [config]="PROJECT_SETTINGS_LIST_CONFIG"
                            [data]="projectSettingTab === 'Custom Settings' ? customProjectSettings : systemProjectSettings"
                            [loading]="loading || !isLoaded"
                            [customDropdownTemplate]="projectSettingTab === 'System Settings' ? customDropdownTemplate : null"
                            (columnActionClick)="onColumnActionClick($event)"
                            (listActionDropdownItemClick)="onListActionDropdownItemClick($event)"
                            (listActionClick)="openProjectSettingsModal()"></rappider-list-grid>
      </nz-tab>
    </nz-tabset>
  </nz-card>
  <!-- / PROJECT SETTINGS AREA -->
</section>
<!-- ----------------------------------------------------------------------------------------- -->
<!-- ----------------------------------------------------------------------------------------- -->
<!-- CREATE PROJECT SETTING MODAL -->
<rappider-modal [visible]="projectSettingsModal.visible"
                [title]="projectSettingsModal.title | translate"
                [okText]="'SHARED.SAVE' | translate"
                [cancelText]="'SHARED.CANCEL' | translate"
                width="60%"
                (okClick)="onSaveCreateProjectSetting()"
                (cancelClick)="onProjectSettingsModalClose()">

  <rappider-crud-view-edit-form *ngIf="projectSettingsModal.visible"
                                [config]="PROJECT_SETTINGS_FORM_CONFIG"
                                [data]="projectSettingsModal.data"
                                [submitted]="projectSettingsModal.isSubmitted"
                                [submitButtonLoading]="loading"
                                (validityChange)="onProjectSettingsModalIsValid($event)"
                                (formValueChange)="onProjectSettingsModalDataChange($event)">
  </rappider-crud-view-edit-form>
  <rappider-alert *ngIf="!isExposeValid"
                  [type]="projectSettingsFormExposeFieldAlertConfig.type"
                  [title]="projectSettingsFormExposeFieldAlertConfig.title"
                  [description]="projectSettingsFormExposeFieldAlertConfig.description"
                  [closeable]="projectSettingsFormExposeFieldAlertConfig.closeable"
                  [showIcon]="projectSettingsFormExposeFieldAlertConfig.showIcon"></rappider-alert>
</rappider-modal>
<!-- / CREATE PROJECT SETTING MODAL -->

<!-- Custom List Actions for Project Settings -->
<ng-template #customDropdownTemplate>
  <button nz-dropdown
          nz-button
          [nzDropdownMenu]="menu"
          class="custom-list-action-button">
    <span>
      <rappider-icon [name]="systemSettingsListActions.icon.name"
                     [type]="systemSettingsListActions.icon.type"></rappider-icon>
    </span>
    {{systemSettingsListActions.text}}
  </button>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
      <ng-container *ngFor="let depthFirst of systemSettingsListActions.dropdownConfig.items">
        <!-- First submenu if there are child items -->
        <li *ngIf="depthFirst.items && depthFirst.visible && hasVisibleItemsInDepthFirst(depthFirst.items); else depthOneTemplate"
            nz-submenu
            [nzTitle]="depthFirst.label">

          <!-- Second submenu if there are child items -->
          <ul *ngFor="let depthSecond of depthFirst.items">
            <li *ngIf="depthSecond.items && depthSecond.visible && hasVisibleItemsInDepthSecond(depthSecond.items); else depthTwoTemplate"
                nz-submenu
                [nzTitle]="depthSecond.label">

              <!-- Third submenu if there are child items -->
              <ul *ngFor="let depthThird of depthSecond.items">
                <li *ngIf="depthThird.items && depthThird.visible; else depthThreeTemplate"
                    nz-submenu
                    [nzTitle]="depthThird.label">
                </li>

                <!-- if there are no child items, we are in the child item for Third submenu -->
                <ng-template #depthThreeTemplate>
                  <li *ngIf="depthThird.data?.visible"
                      nz-menu-item
                      (click)="onSystemSettingsMenuClicked(depthThird.label, depthThird.data)">{{depthThird.label}}</li>
                </ng-template>
              </ul>
            </li>

            <!-- if there are no child items, we are in the child item for Second submenu -->
            <ng-template #depthTwoTemplate>
              <li *ngIf="depthSecond.data?.visible"
                  nz-menu-item
                  (click)="onSystemSettingsMenuClicked(depthSecond.label, depthSecond.data)">{{depthSecond.label}}</li>
            </ng-template>
          </ul>
        </li>

        <!-- if there are no child items, we are in the child item for First submenu -->
        <ng-template #depthOneTemplate>
          <li *ngIf="depthFirst.data?.visible"
              nz-menu-item
              (click)="onSystemSettingsMenuClicked(depthFirst.label, depthFirst.data)">{{depthFirst.label}}</li>
        </ng-template>
      </ng-container>
    </ul>
  </nz-dropdown-menu>
</ng-template>
