import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { ProjectSettingApi } from '@rappider/api-sdk';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { NotificationService, ProjectService, PaginationService } from '@rappider/services';
import * as ProjectSettingActions from './project-setting.actions';
import { ProjectSettingWithRelations } from '@rappider/rappider-sdk';


@Injectable()
export class ProjectSettingEffects {
  constructor(
    private actions$: Actions,
    private projectSettingApi: ProjectSettingApi,
    private store: Store<any>,
    private notificationService: NotificationService,
    private projectService: ProjectService,
    private paginationService: PaginationService
  ) { }


  loadModule$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectSettingActions.LoadModule>(
      ProjectSettingActions.ActionTypes.LoadModule,
    ),
    concatMap(() => [
      new ProjectSettingActions.GetTotalCount(),
      new ProjectSettingActions.GetProjectSettings()
    ])
  ));


  getTotalCount$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectSettingActions.GetTotalCount>(
      ProjectSettingActions.ActionTypes.GetTotalCount
    ),
    withLatestFrom(
      this.store.select(state => state.activeProject.data),
      this.store.select(state => state.projectSetting.pagination)
    ),
    mergeMap(([action, activeProject, pagination]) => {
      const filter = {
        where: { projectId: activeProject.id }
      };
      return this.projectSettingApi.count(filter.where).pipe(
        map((res: { count: number }) => new ProjectSettingActions.SetPagination({
          pagination: {
            totalCount: res.count,
            totalPageNumber: this.paginationService.getTotalPageNumber(res.count, pagination.pageSize)
          }
        }))
      );
    }), catchError(error => [
      new ProjectSettingActions.ProjectSettingError({ errorOn: 'GetTotalCount', error: error }),
    ])
  ));

  /**
   * Create Project Setting and update state fields
   *
   * @memberof ProjectSettingsEffects
   */

  getProjectSettings$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectSettingActions.GetProjectSettings>(
      ProjectSettingActions.ActionTypes.GetProjectSettings
    ),
    withLatestFrom(
      this.store.select(state => state.activeProject.data?.id),
    ),
    mergeMap(([action, activeProjectId]) => {
      const filter = {
        where: { projectId: activeProjectId },
        order: ['name ASC']
      };
      return this.projectSettingApi.find(filter).pipe(
        mergeMap((projectSettings: ProjectSettingWithRelations[]) => {
          if (projectSettings) {
            return [
              new ProjectSettingActions.GetTotalCount(),
              new ProjectSettingActions.SetProjectSettings({ projectSettings: projectSettings }),
              new ProjectSettingActions.ChangeLastProcessedAction({
                lastProcessedAction: {
                  success: true,
                  action: 'GetProjectSettings',
                  message: 'Project Settings Loaded',
                  timestamp: new Date().getTime(),
                  data: projectSettings
                }
              })
            ];
          } else {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_SETTING_COULDNOT_LOADED'
            );
            return [new ProjectSettingActions.GetProjectSettingsFailure({ error: 'Project setting could not loaded', key: 'GetProjectSettingsFailure', timestamp: Date.now() })];
          }
        })
      );
    }), catchError(error => [
      new ProjectSettingActions.ProjectSettingError({ errorOn: 'GetProjectSettings', error: error }),
    ])
  ));


  setPagination$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectSettingActions.SetPagination>(
      ProjectSettingActions.ActionTypes.SetPagination
    ),
    mergeMap(action => {
      if (action.payload.getDataAfter) {
        return of(new ProjectSettingActions.GetProjectSettings());
      } else {
        return [new ProjectSettingActions.SetPaginationFailure({ error: 'There is no getDataAfter in payload', key: 'SetPaginationFailure', timestamp: Date.now() })];;
      }
    })
  ));

  /**
   * Create Project Setting and update state fields
   *
   * @memberof ProjectSettingsEffects
   */

  createProjectSetting$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectSettingActions.CreateProjectSetting>(
      ProjectSettingActions.ActionTypes.CreateProjectSetting
    ),
    withLatestFrom(
      this.store.select(state => state.auth.activePerson),
      this.store.select(state => state.activeProject.data),
      this.store.select(state => state.projectSetting?.data)
    ),
    mergeMap(([action, activePerson, activeProject, projectSettings]) => {
      const projectSettingData = {
        ...action.payload.projectSetting,
      };

      const isProjectSettingKeyExists = projectSettings.some(projectSetting => projectSetting.key === projectSettingData.key);
      if (isProjectSettingKeyExists) {
        this.notificationService.createNotification(
          'error',
          projectSettingData.key,
          'PROJECT_MODULE.PROJECT_SETTINGS_COMPONENT.KEY_ALREADY_EXISTS'
        );
        return [new ProjectSettingActions.ProjectSettingError({ errorOn: 'CreateProjectSetting', error: 'CreateProjectSetting' })];
      } else {
        return this.projectSettingApi.create(projectSettingData).pipe(
          mergeMap((projectSetting: ProjectSettingWithRelations) => {
            this.notificationService.createNotification(
              'success',
              projectSetting.key,
              'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_SETTING_CREATED'
            );
            return of(new ProjectSettingActions.CreateProjectSettingSuccessful({ projectSetting: projectSetting }));
          }), catchError(error => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              error?.message
            );
            return [
              new ProjectSettingActions.ProjectSettingError({ errorOn: 'CreateProjectSetting', error: error })
            ];
          })
        );
      }
    })
  ));

  /**
   * Update Project Setting and update state fields
   *
   * @memberof ProjectSettingsEffects
   */

  updateProjectSetting$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectSettingActions.UpdateProjectSetting>(
      ProjectSettingActions.ActionTypes.UpdateProjectSetting
    ),
    withLatestFrom(
      this.store.select(state => state.auth.activePerson)
    ),
    mergeMap(([action, person]) => {
      const projectSettingData = {
        ...action.payload.projectSetting,
      };
      return this.projectSettingApi.patchAttributes(action.payload.projectSettingId, projectSettingData).pipe(
        mergeMap(projectSetting => {
          if (projectSetting) {
            this.notificationService.createNotification(
              'success',
              projectSetting.key,
              'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_SETTING_UPDATED'
            );
            return of(new ProjectSettingActions.UpdateProjectSettingSuccessful({ projectSetting: projectSetting, projectSettingId: action.payload.projectSettingId }));
          } else {
            this.notificationService.createNotification(
              'error',
              projectSetting.key,
              'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_SETTING_COULDNOT_UPDATED'
            );
            return [new ProjectSettingActions.UpdateProjectSettingFailure({ error: 'Project setting could not updated', key: 'UpdateProjectSettingFailure', timestamp: Date.now() })];
          }
        })
      );
    }), catchError(error => [
      new ProjectSettingActions.ProjectSettingError({ errorOn: 'UpdateProjectSetting', error: error }),
    ])
  ));

  /**
   * Delete Project Setting
   *
   * @memberof ProjectSettingsEffects
   */

  deleteProjectSetting$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectSettingActions.DeleteProjectSetting>(
      ProjectSettingActions.ActionTypes.DeleteProjectSetting
    ),
    mergeMap(action => {
      if (action.payload.projectSettingId) {
        return this.projectSettingApi.deleteById(action.payload.projectSettingId).pipe(
          mergeMap(projectSetting => {
            if (projectSetting) {
              this.notificationService.createNotification(
                'success',
                'SHARED.SUCCESSFUL',
                'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_SETTING_DELETED'
              );
              return of(new ProjectSettingActions.DeleteProjectSettingSuccessful({ projectSettingId: action.payload.projectSettingId }));
            } else {
              this.notificationService.createNotification(
                'error',
                'SHARED.ERROR',
                'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_SETTING_COULDNOT_DELETED'
              );
              return [new ProjectSettingActions.DeleteProjectSettingFailure({ error: 'Project setting could not deleted', key: 'DeleteProjectSettingFailure', timestamp: Date.now() })];
            }
          }),
          catchError(error => [
            new ProjectSettingActions.ProjectSettingError({ errorOn: 'DeleteProjectSetting', error: error }),
          ])
        );
      }
    })
  ));

}
