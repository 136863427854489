export function getCreationInformationFromEntity(data: Record<string, unknown>, date: string) {
  return (data?.createdBy && data?.createdDate) ?
    ('Created By: ' + data?.createdBy + ' at ' + date)
    :
    ((data?.createdBy && !data?.createdDate)) ?
      ('Created By: ' + data.createdBy)
      :
      (((!data?.createdBy && data?.createdDate))) ?
        ('Created at ' + date)
        :
        '';
}
