/* eslint-disable */
import { UIDataStore, UIDataUpdateFunction, DataSchema } from '../index';

declare var Object: any;
export interface UIDataEventInterface {
  id?: string;
  name: string;
  description?: string;
  uiDataStoreId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  dataSchemaId?: string;
  uiDataStore?: UIDataStore;
  uiDataUpdateFunction?: UIDataUpdateFunction;
  payload?: DataSchema;
}

export class UIDataEvent implements UIDataEventInterface {
  'id': string;
  'name': string;
  'description': string;
  'uiDataStoreId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  'dataSchemaId': string;
  uiDataStore: UIDataStore;
  uiDataUpdateFunction: UIDataUpdateFunction;
  payload: DataSchema;
  constructor(data?: UIDataEventInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UIDataEvent`.
   */
  public static getModelName() {
    return 'UIDataEvent';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of UIDataEvent for dynamic purposes.
   **/
  public static factory(data: UIDataEventInterface): UIDataEvent {
    return new UIDataEvent(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'UIDataEvent',
      plural: 'UIDataEvents',
      path: 'ui-data-events',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        description: {
          name: 'description',
          type: 'string',
        },
        uiDataStoreId: {
          name: 'uiDataStoreId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
        dataSchemaId: {
          name: 'dataSchemaId',
          type: 'string',
        },
      },
      relations: {
        uiDataStore: {
          name: 'uiDataStore',
          type: 'UIDataStore',
          model: 'UIDataStore',
          relationType: 'belongsTo',
          keyFrom: 'uiDataStoreId',
          keyTo: 'id',
        },
        uiDataUpdateFunction: {
          name: 'uiDataUpdateFunction',
          type: 'UIDataUpdateFunction',
          model: 'UIDataUpdateFunction',
          relationType: 'hasOne',
          keyFrom: 'id',
          keyTo: 'uiDataEventId',
        },
        payload: {
          name: 'payload',
          type: 'DataSchema',
          model: 'DataSchema',
          relationType: 'belongsTo',
          keyFrom: 'dataSchemaId',
          keyTo: 'id',
        },
      },
    };
  }
}
