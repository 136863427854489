<rappider-select [(ngModel)]="dataSchemaElementRow.typeId"
                 [grouppedOptions]="typeOptions"
                 [settings]="selectSettings"
                 [placeholder]="'SHARED.TYPE'"
                 [cssStyle]="{'width':'100%'}"
                 [buttons]="buttons"
                 [disabled]="disabled"
                 (buttonClick)="onInputDefinitionSelectboxButtonClick($event)"
                 (searchText)="onSearchTextChange($event)"
                 (ngModelChange)="onValueChange($event)"></rappider-select>

<!-- DATA SCHEMA DEFINITION MODALS -->

<ng-template #createDataSchemaTemplate>
  <rappider-data-schema-create-edit-form [dataSchemaFieldPageType]="dataSchemaFieldPageType"
                                         (formSubmit)="onCreateDataSchemaFormSubmit($event)">
  </rappider-data-schema-create-edit-form>
</ng-template>

<ng-template #dataSchemaFieldsTemplate>
  <rappider-data-schema-element-row [addButtonText]="'COMPONENT_BROWSER_MODULE.COMPONENT_DEFINITION_DETAIL_PAGE_COMPONENT.ADD_INPUT_DEFINITION'"
                                    [data]="parentDataSchema.fields"
                                    [type]="dataSchemaFieldRowType"
                                    [dataSchemaPageType]="dataSchemaFieldPageType"
                                    [activeProjectId]="activeProjectId"
                                    (saveButtonClick)="onDataSchemaFieldModalSaved($event)"
                                    (cancelButtonClick)="onDataSchemaFieldModalCancel()">
  </rappider-data-schema-element-row>
</ng-template>

<ng-template #editDataSchemaTemplate>
  <rappider-data-schema-create-edit-form [dataSchemaFieldPageType]="dataSchemaFieldPageType"
                                         [data]="parentDataSchema"
                                         [typeSelector]="false"
                                         (formSubmit)="onEditDataSchemaFormSubmit($event)">
  </rappider-data-schema-create-edit-form>
</ng-template>

<ng-template #editDataSchemaModalTitleTemplate>
  <ng-container>
    <span> {{parentDataSchema.name}} </span>
    <rappider-icon class="edit-icon"
                   [name]="editIcon.name"
                   [theme]="editIcon.theme"
                   [type]="editIcon.type"
                   (click)="showEditDataSchemaModal()"></rappider-icon>
  </ng-container>
</ng-template>

<ng-template #dataSchemaEnumDataTemplate>
  <rappider-crud-view-edit-form [config]="dataSchemaEnumDataConfig"
                                [data]="parentDataSchema?.enumData"
                                (formSubmit)="onDataSchemaEnumDataSave($event)">
  </rappider-crud-view-edit-form>
</ng-template>

<!-- /DATA SCHEMA DEFINITION MODALS -->
