import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActionResponse, CrudTableViewConfig } from '@rappider/rappider-components/utils';
import { UiDataEvent } from '@rappider/rappider-sdk';

@Component({
  selector: 'rappider-data-event-list',
  templateUrl: './data-event-list.component.html',
  styleUrls: ['./data-event-list.component.scss'],
})
export class DataEventListComponent {
  @Input() uiDataEvents: UiDataEvent[];
  @Input() config: CrudTableViewConfig;
  @Input() loadingState: boolean;

  @Output() createEvent = new EventEmitter<void>();
  @Output() editEvent = new EventEmitter<UiDataEvent>();
  @Output() deleteEvent = new EventEmitter<UiDataEvent>();

  onItemActionClick(actionResponse: ActionResponse) {
    const uiDataEvent: UiDataEvent = actionResponse.data;
    if (actionResponse.action.name === 'Edit') {
      this.editEvent.emit(uiDataEvent);
    } else if (actionResponse.action.name === 'DELETE_ITEM') {
      this.deleteEvent.emit(uiDataEvent);
    }
  }

  navigateCreateDataEventPage() {
    this.createEvent.emit();
  }
}
