/* eslint-disable no-shadow */
import { Action } from '@ngrx/store';
import { EnvironmentVariable } from '@rappider/rappider-sdk';
import { Pagination } from '@rappider/shared/interfaces';

export enum ActionTypes {
  LoadModule = '[EnvironmentVariable] LoadModule',
  GetEnvironmentVariables = '[EnvironmentVariable] GetEnvironmentVariables',
  CreateEnvironmentVariable = '[EnvironmentVariable] CreateEnvironmentVariable',
  CreateEnvironmentVariableSuccessful = '[EnvironmentVariable] CreateEnvironmentVariableSuccessful',
  UpdateEnvironmentVariable = '[EnvironmentVariable] UpdateEnvironmentVariable',
  UpdateEnvironmentVariableSuccessful = '[EnvironmentVariable] UpdateEnvironmentVariableSuccessful',
  DeleteEnvironmentVariable = '[EnvironmentVariable] DeleteEnvironmentVariable',
  DeleteEnvironmentVariableSuccessful = '[EnvironmentVariable] DeleteEnvironmentVariableSuccessful',
  EnvironmentVariableError = '[EnvironmentVariable] EnvironmentVariableError',
  ActiveEnvironmentChange = '[EnvironmentVariable] ActiveEnvironmentChange',
  SetActiveEnvironment = '[EnvironmentVariable] SetActiveEnvironment',
  GetEnvironments = '[EnvironmentVariable] GetEnvironments',
  SetEnvironments = '[EnvironmentVariable] SetEnvironments',
  GetEnvironmentVariablesSuccessful = '[EnvironmentVariable] GetEnvironmentVariablesSuccessful',
}

export class LoadModule implements Action {
  readonly type = ActionTypes.LoadModule;
}


export class GetEnvironmentVariables implements Action {
  readonly type = ActionTypes.GetEnvironmentVariables;
}

export class GetEnvironmentVariablesSuccessful implements Action {
  readonly type = ActionTypes.GetEnvironmentVariablesSuccessful;
  constructor(
    public payload: {
      environmentVariables: EnvironmentVariable[];
    }
  ) { }
}
export class CreateEnvironmentVariable implements Action {
  readonly type = ActionTypes.CreateEnvironmentVariable;
  constructor(public payload: { environmentVariable: EnvironmentVariable; environmentKey: string }) { }
}

export class CreateEnvironmentVariableSuccessful implements Action {
  readonly type = ActionTypes.CreateEnvironmentVariableSuccessful;
  constructor(public payload: { environmentVariables: EnvironmentVariable[] }) { }
}

export class UpdateEnvironmentVariable implements Action {
  readonly type = ActionTypes.UpdateEnvironmentVariable;
  constructor(public payload: { environmentVariable: Partial<EnvironmentVariable>; environmentVariableId: string }) { }
}

export class UpdateEnvironmentVariableSuccessful implements Action {
  readonly type = ActionTypes.UpdateEnvironmentVariableSuccessful;
  constructor(public payload: { environmentVariable: Partial<EnvironmentVariable>; environmentVariableId: string }) { }
}

export class DeleteEnvironmentVariable implements Action {
  readonly type = ActionTypes.DeleteEnvironmentVariable;
  constructor(public payload: { environmentVariableId: string }) { }
}

export class DeleteEnvironmentVariableSuccessful implements Action {
  readonly type = ActionTypes.DeleteEnvironmentVariableSuccessful;
  constructor(public payload: { environmentVariableId: string }) { }
}

export class EnvironmentVariableError implements Action {
  readonly type = ActionTypes.EnvironmentVariableError;
  constructor(public payload: { errorOn: string; error?: any }) { }
}

export class SetActiveEnvironment implements Action {
  readonly type = ActionTypes.SetActiveEnvironment;
  constructor(public payload: { environment: string }) { }
}

export class ActiveEnvironmentChange implements Action {
  readonly type = ActionTypes.ActiveEnvironmentChange;
  constructor(public payload: { environment: string }) { }
}

export class GetEnvironments implements Action {
  readonly type = ActionTypes.GetEnvironments;
}

export class SetEnvironments implements Action {
  readonly type = ActionTypes.SetEnvironments;
  constructor(public payload: { environments: string[] }) { }
}

export type Actions =
  LoadModule
  | GetEnvironmentVariables
  | GetEnvironmentVariablesSuccessful
  | CreateEnvironmentVariable
  | CreateEnvironmentVariableSuccessful
  | UpdateEnvironmentVariable
  | UpdateEnvironmentVariableSuccessful
  | DeleteEnvironmentVariable
  | DeleteEnvironmentVariableSuccessful
  | EnvironmentVariableError
  | ActiveEnvironmentChange
  | SetActiveEnvironment
  | GetEnvironments
  | SetEnvironments
  ;
