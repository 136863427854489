import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Components
import { RappiderCrudFieldsDisplayComponent } from './crud-fields-display.component';

// Modules
import { RappiderLabelModule } from '../label/label.module';
import { RappiderTextModule } from '../text/text.module';
import { RappiderTableModule } from '../table/table.module';
import { RappiderMarkdownViewerModule } from '../markdown-viewer/markdown-viewer.module';

// Translate
import { TranslateModule } from '@ngx-translate/core';

/* Antd */
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { RappiderEditFormModule } from '../edit-form/edit-form.module';

@NgModule({
  declarations: [
    RappiderCrudFieldsDisplayComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RappiderLabelModule,
    RappiderTextModule,
    TranslateModule,
    NzCollapseModule,
    NzToolTipModule,
    NzSkeletonModule,
    RappiderTableModule,
    RappiderMarkdownViewerModule,
    RappiderEditFormModule
],
  exports: [
    RappiderCrudFieldsDisplayComponent
  ]
})
export class RappiderCrudFieldsDisplayModule { }
