import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { defaultToolbarTitleHeadingSize, ImportProjectModelOption, PAGE_DEFINITIONS, PATH_DEFINITIONS, TITLE_CASE_DATA_FIELD_NAME_REGEX } from '@rappider/shared/definitions';
import { Subscription } from 'rxjs';
import { PROJECT_MODEL_LIST_CONFIG } from './config/project-model-list';
import { ProjectModel, ProjectWithRelations } from '@rappider/rappider-sdk';
import {
  CreateProjectModelsFromJSON,
  CreateProjectModelsFromPostgreMetadata,
  DeleteProjectModel,
} from '../../states/project-model-state/project-model.actions';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbOption, CODEMIRROR_JSON_SETTINGS, DropdownMenuItem, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { JsonValidationService, NotificationService } from '@rappider/services';
import { isArray } from 'lodash';
import { getProjectModelsWithFields } from 'libs/shared/src/lib/state-selectors/get-models-with-fields-selector';
import { getProjectModelsWithFieldsLoading } from 'libs/shared/src/lib/state-selectors/get-models-with-fields-loading';

@Component({
  selector: 'rappider-project-model-list',
  templateUrl: './project-model-list.component.html',
  styleUrls: ['./project-model-list.component.scss'],
})
export class ProjectModelListComponent implements OnInit, OnDestroy {
  @ViewChild('deleteConfirmationTemplate', { static: true }) deleteConfirmationTemplate: TemplateRef<{}>;

  /* list grid config */
  PROJECT_MODEL_LIST_CONFIG = PROJECT_MODEL_LIST_CONFIG;
  /* json codemirror settings */
  CODEMIRROR_JSON_SETTINGS = CODEMIRROR_JSON_SETTINGS;
  /* main title */
  mainTitle: HeadingComponentConfig = {
    content: 'PROJECT_MODULE.PROJECT_MODEL_LIST_COMPONENT.PROJECT_MODEL_LIST',
    type: defaultToolbarTitleHeadingSize
  };
  /* page breadcrumb title */
  title: string | string[] | BreadcrumbOption[];
  /* subscriptions */
  subscriptions: Subscription[];
  /* active project */
  activeProject: ProjectWithRelations;
  /* project models */
  projectModels: ProjectModel[];
  /* deleted project model */
  deletedProjectModel: ProjectModel;

  isImportProjectModelModalVisible = false;
  importProjectModelModalData: {
    modalTitle: string;
    codeEditorTitle: string;
    projectModelName: string;
    data: any;
    importType: ImportProjectModelOption;
  } = null;
  ImportProjectModelOption = ImportProjectModelOption;

  isLoading = false;
  displayToolbar = false;
  displayToolbarBackButton = false;

  constructor(
    private store: Store<any>,
    private nzModalService: NzModalService,
    private translateService: TranslateService,
    private jsonValidationService: JsonValidationService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToProjectModelsAndFields(),
      this.subscribeToProjectModelWithFieldsLoadingState()
    ];
  }

  /**
   * subscribe to active project to set the title
   *
   * @returns
   * @memberof ProjectModelListComponent
   */
  subscribeToActiveProject() {
    return this.store.select((state) => state.activeProject.data).subscribe((activeProject: ProjectWithRelations) => {
      this.activeProject = activeProject;
      if (activeProject) {
        this.setTitle();
      }
    });
  }

  subscribeToProjectModelsAndFields() {
    return this.store.select(<any>getProjectModelsWithFields).subscribe(projectModels => {
      this.projectModels = projectModels;
    });
  }

  subscribeToProjectModelWithFieldsLoadingState() {
    return this.store.select(<any>getProjectModelsWithFieldsLoading).subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
  }

  setTitle() {
    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
      },
      {
        label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_MODEL_LIST.PAGE_TITLE,
      }
    ];
  }

  onClickDelete(event) {
    this.showFileDeleteConfirmationModal(event.data);
  }

  showFileDeleteConfirmationModal(projectModel: ProjectModel) {
    this.deletedProjectModel = projectModel;
    if (projectModel.fields || projectModel.relations) {
      this.nzModalService.error({
        nzTitle: this.translateService.instant(
          'PROJECT_MODULE.PROJECT_MODEL_LIST_COMPONENT.ARE_YOU_SURE_DELETE_PROJECT_MODEL'
        ),
        nzContent: this.deleteConfirmationTemplate,
        nzOkText: this.translateService.instant('SHARED.DELETE'),
        nzCancelText: this.translateService.instant('SHARED.CANCEL'),
        nzClosable: false,
        nzOkDanger: true,
        nzOnOk: () => {
          this.onProjectModelDelete(projectModel.id);
        }
      });
    }
  }

  onProjectModelDelete(projectModelId) {
    this.store.dispatch(new DeleteProjectModel({ id: projectModelId }));
  }

  onSaveImportProjectModelData(modalData: any) {
    const jsonValidationData = this.jsonValidationService.validateStringifiedJson(modalData.data);

    if (jsonValidationData.isJsonValid) {
      if (modalData.importType === ImportProjectModelOption.ImportFromJson) {
        this.store.dispatch(new CreateProjectModelsFromJSON({
          modelName: modalData.projectModelName,
          exampleData: jsonValidationData.dataAsJson
        }));
      } else if (modalData.importType === ImportProjectModelOption.ImportFromOpenApiSpecs) {
        /* TODO: dispatch create from openapi action */
      } else if (modalData.importType === ImportProjectModelOption.ImportFromPostgreSQL) {
        if (!isArray(jsonValidationData.dataAsJson)) {
          this.store.dispatch(new CreateProjectModelsFromPostgreMetadata({
            postgreMetadata: jsonValidationData.dataAsJson
          }));
        } else {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'Must be an object'
          );
        }
      }
    } else {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'ERRORS.WRONG_JSON_FORMAT'
      );
    }
  }

  /**
   * returns true if model name is not empty and does not match the given pattern
   *
   * @return {*}
   * @memberof ProjectModelListComponent
   */
  validateModelNameForJsonData(projectModelName: string) {
    return (projectModelName && !(projectModelName?.match(TITLE_CASE_DATA_FIELD_NAME_REGEX)));
  }

  onListActionDropdownItemClick(action: DropdownMenuItem) {
    this.isImportProjectModelModalVisible = true;

    if (action.key === ImportProjectModelOption.ImportFromJson) {
      this.importProjectModelModalData = {
        modalTitle: 'Import From JSON Data',
        codeEditorTitle: 'JSON Data',
        data: '',
        projectModelName: '',
        importType: ImportProjectModelOption.ImportFromJson
      };
    } else if (action.key === ImportProjectModelOption.ImportFromOpenApiSpecs) {
      this.importProjectModelModalData = {
        modalTitle: 'Import From OpenAPI Specs',
        codeEditorTitle: 'OpenApi Specs',
        data: '',
        projectModelName: '',
        importType: ImportProjectModelOption.ImportFromOpenApiSpecs
      };
    } else if (action.key === ImportProjectModelOption.ImportFromPostgreSQL) {
      this.importProjectModelModalData = {
        modalTitle: 'Import From PostgreSQL Metadata',
        codeEditorTitle: 'PostgreSQL Metadata',
        data: '',
        projectModelName: '',
        importType: ImportProjectModelOption.ImportFromPostgreSQL
      };
    }
  }
}
