import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { NotificationService } from '@rappider/services';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import {
  CreateAllDataFields,
  DeleteAllDataFields,
  UpdateAllDataFields
} from 'libs/project/src/lib/states/data-schema/data-schema.actions';
import { DataSchemaFieldRowPages } from '@rappider/shared/interfaces';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { Subscription } from 'rxjs/internal/Subscription';
import { DataSchemaElementRowOutput } from '../../../data-schema/components/data-schema-element-row/models/data-schema-element-row';
import { DataSchemaTypes } from '../../../data-schema/models/data-schema-type.enum';
import { UI_DATA_STORE_FIELD_LIST_CONFIG } from './config/ui-data-store-field-list-config';
import { DataSchemaField, DataSchemaFieldBulkUpdateDto, DataSchemaFieldCreateDto, DataSchemaFieldUpdateDto, UiDataStoreWithRelations } from '@rappider/rappider-sdk';

@Component({
  selector: 'rappider-ui-data-store-field-list',
  templateUrl: './ui-data-store-field-list.component.html',
  styleUrls: ['./ui-data-store-field-list.component.scss']
})
export class UIDataStoreFieldListComponent {

  @Input() uiDataStoreFields: DataSchemaField[];
  @Input() uiDataStore: UiDataStoreWithRelations;
  @Input() activeProjectId: string;
  @Input() parentDataSchemaId: string;
  @Input() loading = false;

  /* subscriptions */
  subscriptions: Subscription[];
  /* page title */
  title: string[];
  /* active project */
  activeProject: {
    id: string;
    name: string;
  };
  /* list config */
  UI_DATA_STORE_FIELD_LIST_CONFIG = UI_DATA_STORE_FIELD_LIST_CONFIG;
  /* data store fields ( held in dataSchemaField ) */
  dataStoreFields: DataSchemaField[];
  /* data schema type */
  dataSchemaType = DataSchemaTypes.UIDataStoreState;
  /* data schema field row page type - will be used in filter */
  DataSchemaPageType = DataSchemaFieldRowPages.DataStore;

  constructor(
    private store: Store<any>,
    private notificationService: NotificationService
  ) { }

  navigateCreateDataStoreFieldPage() {
    this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_FIELD_CREATE}/${this.uiDataStore?.id}` }));
  }

  onDataSchemaSave(dataSchemaFieldDifferences: DataSchemaElementRowOutput) {
    if (
      dataSchemaFieldDifferences?.created?.length === 0 &&
      dataSchemaFieldDifferences?.updated?.length === 0 &&
      dataSchemaFieldDifferences?.deleted?.length === 0
    ) {
      this.notificationService.createNotification(
        'warning',
        'Warning',
        'There is no change in the fields in this Data store.'
      );
    } else {
      if (dataSchemaFieldDifferences?.updated?.length) {
        const updatedDataSchemaFields = dataSchemaFieldDifferences.updated.map(updatedField => {
          const { id, ...data } = updatedField;
          return {
            id,
            data: data as DataSchemaFieldUpdateDto
          };
        }) as DataSchemaFieldBulkUpdateDto[];
        this.store.dispatch(
          new UpdateAllDataFields({ updatedDataSchemaFields: updatedDataSchemaFields, parentDataSchemaId: this.parentDataSchemaId })
        );
      }
      if (dataSchemaFieldDifferences?.created?.length) {
        const createRequestBody = dataSchemaFieldDifferences.created.map(createdField => ({
          ...createdField,
          parentDataSchemaId: this.parentDataSchemaId
        })) as DataSchemaFieldCreateDto[];

        const filteredPayload = createRequestBody.map(obj => {
          // Check all properties except 'typeId', 'parentDataSchemaId', and 'name'
          const { typeId, parentDataSchemaId, name, ...optionalFields } = obj;

          // Create a new object to store the non-null fields
          const filteredObj = { typeId, parentDataSchemaId, name };

          // Check the optional fields and add the non-null ones
          for (const [key, value] of Object.entries(optionalFields)) {
            if (value !== null) {
              filteredObj[key] = value;
            }
          }
          return filteredObj;
        });

        // Dispatch Create Action
        this.store.dispatch(
          new CreateAllDataFields({ dataSchemaFields: filteredPayload, parentDataSchemaId: this.parentDataSchemaId })
        );
      }
      if (dataSchemaFieldDifferences?.deleted?.length) {
        const deleteRequestBody = dataSchemaFieldDifferences.deleted.map((item: DataSchemaField) => item.id);
        this.store.dispatch(
          new DeleteAllDataFields({ dataSchemaFieldIds: deleteRequestBody, parentDataSchemaId: this.parentDataSchemaId }
          ));
      }
    }
  }

  onDataSchemaCancel() {
    this.notificationService.createNotification(
      'success',
      'SHARED.SUCCESS',
      'PROJECT_MODULE.UI_DATA_STORE_FIELD_LIST_COMPONENT.CHANGES_SUCCESSFULLY_DISCARTED'
    );
  }
}
