import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as RoleActions from './role-crud.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RoleControllerService, RoleWithRelations } from '@rappider/rappider-sdk';
import { NotificationService } from 'libs/components/src/lib/services';
import { GetPeopleByAdmin } from '../person-state/person.actions';

@Injectable()
export class RoleCrudEffects {
  constructor(
    private actions$: Actions,
    private roleController: RoleControllerService,
    private notificationService: NotificationService
  ) { }

  getRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        RoleActions.GetRoles,
        GetPeopleByAdmin
      ),
      switchMap(() => this.roleController.findByAdmin({ filter: { include: ['permissions'] } }).pipe(
        map((roles: RoleWithRelations[]) => RoleActions.GetRolesSuccessful({ roles: roles })),
        catchError((error) =>
          [
            RoleActions.GetRolesFailure({ error: error, key: 'GetRoles', timestamp: Date.now() })
          ]
        )
      )
      )
    )
  );

  createRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActions.CreateRole),
      switchMap((action) => this.roleController.createByAdmin({ body: action.role }).pipe(
        map((createdRole: RoleWithRelations) => {
          this.notificationService.createNotification(
            'success',
            'Success',
            'Role Created Successfully'
          );
          return RoleActions.CreateRoleSuccessful({ role: createdRole });
        }),
        catchError((error) => {
          const errorMessage = `${error?.error?.error?.details[0]?.code} ${error?.error?.error?.details[0]?.message}`;
          this.notificationService.createNotification(
            'error',
            'Error',
            errorMessage || 'Role Could not Created'
          );
          return [
            RoleActions.CreateRoleFailure({ error: error, key: 'CreateRole', timestamp: Date.now() })
          ];
        })
      ))
    )
  );

  updateRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActions.UpdateRole),
      switchMap((action) => this.roleController.updateByIdByAdmin({ id: action.id, body: action.role }).pipe(
        map((updatedRole: RoleWithRelations) => {
          this.notificationService.createNotification(
            'success',
            'Success',
            'Role Updated Successfully'
          );
          return RoleActions.UpdateRoleSuccessful({ id: action.id, role: updatedRole });
        }),
        catchError((error) => {
          this.notificationService.createNotification(
            'error',
            'Error',
            'Role Could not Updated'
          );
          return [
            RoleActions.UpdateRoleFailure({ error: error, key: 'UpdateRole', timestamp: Date.now() })
          ];
        })
      ))
    )
  );

  deleteRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActions.DeleteRole),
      switchMap((action) => this.roleController.deleteByIdByAdmin({ id: action.id }).pipe(
        map(() => {
          this.notificationService.createNotification(
            'success',
            'Success',
            'Role Deleted Successfully'
          );
          return RoleActions.DeleteRoleSuccessful({ id: action.id })
            ;
        }),
        catchError((error) => {
          this.notificationService.createNotification(
            'error',
            'Error',
            'Role Could not Deleted'
          );
          return [
            RoleActions.DeleteRoleFailure({ error: error, key: 'DeleteRole', timestamp: Date.now() })
          ];
        })
      ))
    )
  );
}
