import { createSelector } from '@ngrx/store';
import { ComponentDefinition, ComponentDefinitionWithRelations, DataSchema, DataSchemaWithRelations } from '@rappider/rappider-sdk';
import { getDataSchemasWithDetailsSelector } from 'libs/project/src/lib/states/selectors/get-data-schemas-with-details.selector';

export const getComponentDefinitionsWithDetailsSelector = createSelector(
  state => state['componentDefinition']?.data,
  getDataSchemasWithDetailsSelector,
  (componentDefinitions: ComponentDefinition[], dataSchemas: DataSchemaWithRelations[]) => {
    if (!componentDefinitions?.length && !dataSchemas?.length) {
      return [];
    }
    const mappedDataSchemas = dataSchemas.reduce((acc, dataSchema) => acc.set(dataSchema.id, dataSchema), new Map<string, DataSchema>());
    const componentDefinitionsWithRelations: ComponentDefinitionWithRelations[] = componentDefinitions?.map(componentDefinition => ({
      ...componentDefinition,
      inputDefinitions: componentDefinition.inputDefinitions?.map(inputDefinition => ({
        ...inputDefinition,
        type: mappedDataSchemas.get(inputDefinition.typeId)
      })),
      outputDefinitions: componentDefinition.outputDefinitions?.map(outputDefinition => ({
        ...outputDefinition,
        type: mappedDataSchemas.get(outputDefinition.typeId)
      }))
    }));
    return componentDefinitionsWithRelations;
  }
);
