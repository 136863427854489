import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjectInterface, ProjectPackageInterface } from '@rappider/api-sdk';
import { BreadcrumbOption, DropdownMenuItem, HeadingComponentConfig, IconComponentConfig, IconType } from '@rappider/rappider-components/utils';
import { PROJECT_PACKAGE_CREATE_OR_EDIT_CONFIG } from '@rappider/shared/configs';
import { addProjectPackageButtonConfig, defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS, projectPackageInfoAlertConfig } from '@rappider/shared/definitions';
import { FormatDatePipe } from 'libs/shared/src/lib/pipes/format-date.pipe';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { CreateProjectPackage } from '../../states/project-package-state/project-package.actions';
import { NPMPackage } from '../../utils/models/npm-package-model';
import { NpmPackageSearchService } from '../../utils/services/npm-package-search.service.ts/npm-package-search.service';
import { NotificationService } from '@rappider/services';
import { NewProjectPackage, ProjectPackageWithRelations } from '@rappider/rappider-sdk';
import { ProjectPackagesCreateProjectType } from '../project-package-list/utils/project-package-create-project-type.enum';

@Component({
  selector: 'rappider-project-package-create',
  templateUrl: './project-package-create.component.html',
  styleUrls: ['./project-package-create.component.scss']
})
export class ProjectPackageCreateComponent implements OnInit, OnChanges, OnDestroy {

  @Input() isCreatePackageForBackend: boolean;
  @Input() defaultSaveButtonDisabled: boolean;

  @Output() projectPackageCreateModalVisibilityChange = new EventEmitter<boolean>();

  /* create config */
  PROJECT_PACKAGE_CREATE_CONFIG = PROJECT_PACKAGE_CREATE_OR_EDIT_CONFIG;
  /* main title */
  mainTitle: HeadingComponentConfig;
  /* title */
  title: string | string[] | BreadcrumbOption[];
  /* subscriptions */
  subscriptions: Subscription[];
  /* active project */
  activeProject: ProjectInterface;
  /* project id */
  activeProjectId: string;
  projectPackages: ProjectPackageWithRelations[];

  searchChange$ = new Subject<string>();
  selectedPackage: NPMPackage;
  npmPackages: KeyValue<string, NPMPackage>[] = [];
  packageDetails: KeyValue<string, any>[];
  isLoading = false;
  versions = {
    placement: 'bottomRight',
    items: [],
    labelMode: 'static-label',
  };
  selectedVersion = 'Choose Version';

  options = [
    {
      label: '',
      value: ''
    }
  ];

  loadingIcon: IconComponentConfig = {
    name: 'loading',
    type: IconType.NgZorro
  };
  projectPackageInfoAlertConfig = projectPackageInfoAlertConfig;
  addProjectPackageButtonConfig = addProjectPackageButtonConfig;
  exposeToFrontend: boolean;
  exposeToBackend: boolean;
  displayToolbar = false;
  displayToolbarBackButton = false;

  constructor(
    private store: Store<any>,
    private npmPackageSearchService: NpmPackageSearchService,
    private formatDatePipe: FormatDatePipe,
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
    this.getNpmPackages();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isCreatePackageForBackend) {
      this.clickExposeToFrontend(!this.isCreatePackageForBackend);
      this.clickExposeToBackend(this.isCreatePackageForBackend);
      this.setTitle();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToProjectPackages()
    ];
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectInterface) => {
      if (activeProject) {
        this.activeProject = activeProject;
        this.activeProjectId = activeProject.id;
        this.setTitle();
      }
    });
  }

  setTitle() {
    this.mainTitle = {
      content: this.isCreatePackageForBackend ? 'Create Project Package For Backend' : 'Create Project Package For Frontend',
      type: defaultToolbarTitleHeadingSize
    };
    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
      },
      {
        label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_PACKAGE_LIST.PAGE_TITLE,
        redirectUrl: PATH_DEFINITIONS.PROJECTS.PROJECT_PACKAGE_LIST
      },
      {
        label: this.isCreatePackageForBackend ? 'Create Project Package For Backend' : 'Create Project Package For Frontend'
      }
    ];
  }

  subscribeToProjectPackages() {
    return this.store.select(state => state?.projectPackage?.data).subscribe((projectPackages: ProjectPackageWithRelations[]) => {
      if (projectPackages) {
        this.projectPackages = projectPackages;
      }
    });
  }

  onSearchValueChange(searchValue: string) {
    this.isLoading = true;
    this.searchChange$.next(searchValue);
  }

  /**
   * gets npm packages from npm search api with debounce time of 1.5 seconds
   *
   * @memberof ProjectPackageCreateComponent
   */
  getNpmPackages() {
    const npmPackages$: Observable<any> = this.searchChange$
      .asObservable().pipe(
        debounceTime(1500),
        switchMap((searchValue) => {
          if (searchValue) {
            return this.npmPackageSearchService.getNpmPackages(searchValue);
          } else {
            return of(null);
          }
        })
      );

    this.setNpmPackageSelectOptions(npmPackages$);
  }

  /**
   * subscribes to npm packages and maps data as key-value array for selectbox
   *
   * @param {Observable<any>} packages
   * @memberof ProjectPackageCreateComponent
   */
  setNpmPackageSelectOptions(packages: Observable<any>) {
    packages.subscribe(packages => {
      this.npmPackages = packages?.results?.map(result => ({
        key: result.package.name,
        value: result.package
      })) ?? [];
      this.isLoading = false;
    });
  }

  setPackageDetailData(selectedPackage?: NPMPackage, npmPackageVersions?: any) {
    if (selectedPackage && npmPackageVersions) {
      const values = Object.values(npmPackageVersions.versions);

      values.forEach((item, index) => {
        this.options[index] = { label: item['version'], value: item['version'] };
        this.versions.items.push({ label: item['version'] });
      });

      this.packageDetails = [
        {
          key: 'SHARED.NAME',
          value: [selectedPackage.name]
        },
        {
          key: 'SHARED.VERSION',
          value: [this.options]
        },
        {
          key: 'SHARED.DATE',
          value: [this.formatDatePipe.transform(selectedPackage.date)]
        },
        {
          key: 'SHARED.DESCRIPTION',
          value: [selectedPackage.description]
        },
        {
          key: 'SHARED.AUTHOR',
          value: Object.values(selectedPackage.author ?? {})
        },
        {
          key: 'SHARED.PUBLISHER',
          value: Object.values(selectedPackage.publisher ?? {})
        },
        {
          key: 'SHARED.LINKS',
          value: Object.values(selectedPackage.links ?? {})
        },
        {
          key: 'SHARED.KEYWORDS',
          value: selectedPackage.keywords
        }
      ];

      this.selectedVersion = values[0]['version'];

    } else {
      this.packageDetails = null;
      this.options = [];
    }
  }

  /**
   * triggered when a package selected
   *
   * @param {NPMPackage} selectedPackage
   * @memberof ProjectPackageCreateComponent
   */
  async onSelectPackage(selectedPackage: NPMPackage) {
    if (selectedPackage) {
      const npmPackageVersions = await this.npmPackageSearchService.getNpmPackageVersions(selectedPackage?.name);
      const sortedNpmPackageVersions = {
        ...npmPackageVersions,
        versions: this.npmPackageSearchService.sortPackageVersions(npmPackageVersions)
      };
      this.setPackageDetailData(selectedPackage, sortedNpmPackageVersions);
    } else {
      this.setPackageDetailData(null, {});
    }
  }

  onAddProjectPackage() {
    if (!this.exposeToBackend && !this.exposeToFrontend) {
      this.notificationService.createNotification(
        'error',
        'Error',
        'At least one of the Expose To Frontend or Expose To Backend options must be selected.',
        {
          nzPlacement: 'topRight'
        }
      );
    }
    if (this.selectedPackage) {
      const projectPackage = <NewProjectPackage>{
        name: this.selectedPackage.name,
        version: this.selectedVersion,
        exposeToFrontend: this.exposeToFrontend,
        exposeToBackend: this.exposeToBackend
      };

      const isProjectPackageNameExistForFrontend = this.projectPackages.some(projectPackage =>
        projectPackage.name === this.selectedPackage.name && this.exposeToFrontend === (projectPackage.projectType === ProjectPackagesCreateProjectType.Frontend));
      const isProjectPackageNameExistForBackend = this.projectPackages.some(projectPackage =>
        projectPackage.name === this.selectedPackage.name && this.exposeToBackend === (projectPackage.projectType === ProjectPackagesCreateProjectType.Backend));

      if (!isProjectPackageNameExistForFrontend && this.exposeToFrontend && !this.exposeToBackend) {
        const updatedProjectPackage = {
          ...projectPackage,
          exposeToBackend: false
        };
        this.store.dispatch(new CreateProjectPackage({ projectPackage: updatedProjectPackage }));
        this.projectPackageCreateModalVisibilityChange.emit(false);
      } else if (isProjectPackageNameExistForFrontend && this.exposeToFrontend && !this.exposeToBackend) {
        this.notificationService.createNotification(
          'error',
          'Error',
          'This package already exists in Frontend.',
          {
            nzPlacement: 'topRight'
          }
        );
      } else if (isProjectPackageNameExistForFrontend && !isProjectPackageNameExistForBackend && this.exposeToFrontend) {
        this.notificationService.createNotification(
          'error',
          'Error',
          'This package already exists in Frontend.',
          {
            nzPlacement: 'topRight'
          }
        );
      }


      if (!isProjectPackageNameExistForBackend && this.exposeToBackend && !this.exposeToFrontend) {
        const updatedProjectPackage = {
          ...projectPackage,
          exposeToFrontend: false
        };
        this.store.dispatch(new CreateProjectPackage({ projectPackage: updatedProjectPackage }));
        this.resetPackageAndVersionDetails();
        this.projectPackageCreateModalVisibilityChange.emit(false);
      } else if (isProjectPackageNameExistForBackend && this.exposeToBackend && !this.exposeToFrontend) {
        this.notificationService.createNotification(
          'error',
          'Error',
          'This package already exists in Backend.',
          {
            nzPlacement: 'topRight'
          }
        );
      } else if (isProjectPackageNameExistForBackend && !isProjectPackageNameExistForFrontend && this.exposeToBackend) {
        this.notificationService.createNotification(
          'error',
          'Error',
          'This package already exists in Backend.',
          {
            nzPlacement: 'topRight'
          }
        );
      }

      if (!isProjectPackageNameExistForFrontend && !isProjectPackageNameExistForBackend && this.exposeToBackend && this.exposeToFrontend) {
        this.store.dispatch(new CreateProjectPackage({ projectPackage: projectPackage }));
        this.projectPackageCreateModalVisibilityChange.emit(false);
      } else if (isProjectPackageNameExistForBackend && isProjectPackageNameExistForFrontend && this.exposeToFrontend && this.exposeToBackend) {
        this.notificationService.createNotification(
          'error',
          'Error',
          'This package already exists in Frontend and Backend.',
          {
            nzPlacement: 'topRight'
          }
        );
      }
    } else {
      this.notificationService.createNotification(
        'error',
        'Error',
        'The project package must be selected.',
        {
          nzPlacement: 'topRight'
        }
      );
    }
  }

  onCancel() {
    this.resetPackageAndVersionDetails();
  }

  resetPackageAndVersionDetails() {
    this.selectedVersion = this.selectedPackage = this.packageDetails = null;
  }

  onVersionChange(value: any) {
    this.selectedVersion = value;
  }

  clickExposeToFrontend(exposeToFrontend) {
    this.exposeToFrontend = exposeToFrontend;
  }

  clickExposeToBackend(exposeToBackend) {
    this.exposeToBackend = exposeToBackend;
  }
}
