<div class="main-layout">
  <!-- HEADER AREA -->
  <div *ngIf="isHeaderVisible" class="header-layout">
    <rappider-header [activePath]="activePath"
                     [routePageTitle]="routePageTitle"
                     [showPageTitle]="showPageTitle"
                     [theme]="selectedTheme"
                     (menuToggled)="onMenuToggled($event)"
                     (lastActivePath)="activePath"
                     (codeViewToggled)="onCodeViewToggled($event)"
                     (rightSidebarToggleButtonClick)="onRightSidebarToggle($event)"></rappider-header>
  </div>
  <div class="content-layout">
    <!-- TODO: Use the width from the styles -->
    <div class="left-side-container"
         *ngIf="isMenuVisible"
         [style.width]="menuWidth()">
      <div class="menu-container"
           [style.width]="menuWidth()">
        <!-- Workspace menu -->
        <div class="workspace-menu">
          <rappider-menu *ngIf="workSpaceMenu.items?.length && isPersonVerified"
                         [isDisabled]="!activeProject"
                         [isCollapsed]="isWorkSpaceMenuCollapsed"
                         [config]="getWorkSpaceMenu()"
                         [showLabels]="!isWorkSpaceMenuCollapsed"
                         [paddingValue]="rappiderThemeSettings.workspaceMenuPadding"
                         (menuItemClick)="workSpaceMenuItemClicked($event)"></rappider-menu>
          <rappider-menu *ngIf="workSpaceFooterMenu.items?.length && isPersonVerified"
                         [isCollapsed]="isWorkSpaceMenuCollapsed"
                         [config]="workSpaceFooterMenu"
                         [showLabels]="!isWorkSpaceMenuCollapsed"
                         [paddingValue]="rappiderThemeSettings.workspaceMenuPadding"
                         (menuItemClick)="workSpaceMenuItemClicked($event)"></rappider-menu>
        </div>
        <!-- Pages menu -->
        <div *ngIf="pagesMenu"
             class="pages-menu">
          <rappider-menu *ngIf="pagesMenu?.items?.length"
                         [config]="pagesMenu"></rappider-menu>
        </div>
      </div>
    </div>
    <!-- Inner content area (without side menu) -->
    <div class="inner-layout layout"
         [ngClass]="{'inner-layout-with-right-sidebar': rightSidebarVisibility && validateRightSidebarVisibilityByActivePath(), 'inner-layout-max-height' : !isLayoutsVisible}"
         [style.padding-left]="menuWidth()">
      <div class="inner-content-wrapper layout"
           [ngClass]="{'p-0': noInnerContentPadding}">

        <!-- PERSON VERIFICATION ALERT  -->
        <div *ngIf="isAlertVisible && activePerson && !activePerson.isVerified && PERSON_VERIFICATION_ALERT_CONFIG"
             class="mb-3">
          <rappider-alert [type]="PERSON_VERIFICATION_ALERT_CONFIG.type"
                          [title]="PERSON_VERIFICATION_ALERT_CONFIG.title"
                          [description]="PERSON_VERIFICATION_ALERT_CONFIG.description"
                          [showIcon]="PERSON_VERIFICATION_ALERT_CONFIG.showIcon"
                          [closeable]="PERSON_VERIFICATION_ALERT_CONFIG.closeable"
                          [actionConfig]="PERSON_VERIFICATION_ALERT_CONFIG.actionConfig"
                          [actionConfigPlacement]="PERSON_VERIFICATION_ALERT_CONFIG.actionConfigPlacement"
                          (actionClick)="resendVerifyEmailOrSms()">
          </rappider-alert>
        </div>
        <!-- END OF PERSON VERIFICATION ALERT  -->
        <router-outlet (activate)="onRouteChange()"></router-outlet>
      </div>

    </div>

    <div *ngIf="rightSidebarVisibility && activePerson && validateRightSidebarVisibilityByActivePath()"
         class="right-sidebar"
         [@sidebarToggleAnimation]>
      <rappider-right-sidebar [visibility]="rightSidebarVisibility"
                              [activeTabName]="activeRightSidebarTabName"></rappider-right-sidebar>
    </div>

  </div>
  <!-- Footer is at the bottom of the main layout  -->
  <div *ngIf="isFooterVisible" class="footer-layout">
    <rappider-footer [activeProject]="activeProject"
                     [selectedTheme]="selectedTheme"></rappider-footer>
  </div>
</div>

<rappider-app-search *ngIf="appSearchVisibility && activeProject"></rappider-app-search>
<rappider-global-error></rappider-global-error>
