<rappider-modal [(visible)]="projectPackageCreateModalVisibility"
								[fullScreen]="fullScreen"
								[closeIconVisibility]="modalConfig?.closeIconVisibility"
								[footer]="projectPackageCreateFormModalFooter">

	<rappider-project-package-create [isCreatePackageForBackend]="isCreatePackageForBackend"
																	 [defaultSaveButtonDisabled]="defaultSaveButtonDisabled"
																	 (projectPackageCreateModalVisibilityChange)="onCloseProjectPackageCreateModal($event)">

	</rappider-project-package-create>
</rappider-modal>
<ng-template #projectPackageCreateFormModalFooter>
	<button nz-button
					nzType="default"
					(click)="onCloseProjectPackageCreateModal(false)">{{'Close' | translate}}</button>
	<button nz-button
					nzType="primary"
					(click)="onCreateProjectPackage()">{{'Create Project Package' | translate}}</button>
</ng-template>
