import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActiveProjectActions from 'libs/project/src/lib/states/active-project-state/active-project.actions';
import { map, mergeMap } from 'rxjs/operators';
import * as DbDiagramActions from './wf-diagram.actions';
@Injectable()
export class WFDiagramEffects {
  constructor(
    private actions$: Actions,
  ) { }

}
