<nz-card class="weather-condition">
  <div>
    <div>
      <rappider-heading *ngIf="title"
                        [content]="title.content"
                        [type]="title.type"></rappider-heading>
    </div>
    <div>
      <rappider-heading *ngIf="subtitle"
                        [content]="subtitle.content"
                        [type]="subtitle.type"></rappider-heading>
    </div>
  </div>
  <div class="main">
    <rappider-heading *ngIf="informationText"
                      [content]="informationText.content"
                      [type]="informationText.type"></rappider-heading>
    <rappider-image *ngIf="image"
                    [source]="image?.source"
                    [alternateText]="image?.alternateText"
                    [width]="image?.width"
                    [borderSettings]="image?.borderSettings"
                    [customSizeSettings]="image?.width"
                    [shadowSettings]="image?.shadowSettings"
                    [marginSettings]="image?.marginSettings"
                    [paddingSettings]="image?.paddingSettings"></rappider-image>
  </div>
  <div *ngFor="let content of topAdditionalContents"
       class="additional-content">
    <rappider-icon *ngIf="content.icon && content.icon?.name"
                   [name]="content.icon.name"
                   [type]="content.icon.type"
                   [theme]="content.icon.theme"
                   [color]="content.icon.color"
                   [size]="content.icon.size"></rappider-icon>
    <rappider-text *ngIf="content.label"
                   [content]="content.label.content"
                   [textMode]="content.label.textMode"
                   [text]="content.label.text"
                   [typography]="content.label.typography"
                   [colorSettings]="content.label.colorSettings"></rappider-text>
  </div>
  <div *ngIf="slider"
       class="slider">
    <rappider-slider [readonly]="slider.readonly"
                     [dualMode]="slider.dualMode"
                     [minRange]="slider.minRange"
                     [maxRange]="slider.maxRange"
                     [marks]="slider.marks"
                     [step]="slider.step"
                     [cssStyle]="slider.cssStyle"
                     [cssClass]="slider.cssClass"
                     (valueChange)="onSliderValueChange($event)"></rappider-slider>
  </div>
  <div *ngFor="let content of bottomAdditionalContents"
       class="additional-area">
    <rappider-text *ngIf="content.label"
                   class="text-left"
                   [content]="content.label.content"
                   [textMode]="content.label.textMode"
                   [text]="content.label.text"
                   [typography]="content.label.typography"
                   [colorSettings]="content.label.colorSettings"></rappider-text>
    <rappider-icon *ngIf="content.icon && content.icon?.name"
                   class="text-center"
                   [name]="content.icon.name"
                   [type]="content.icon.type"
                   [theme]="content.icon.theme"
                   [color]="content.icon.color"
                   [size]="content.icon.size"></rappider-icon>
    <rappider-text *ngIf="content.additionalText"
                   class="text-right"
                   [content]="content.additionalText.content"
                   [textMode]="content.additionalText.textMode"
                   [text]="content.additionalText.text"
                   [typography]="content.additionalText.typography"
                   [colorSettings]="content.additionalText.colorSettings"></rappider-text>
  </div>

  <div *ngIf="button && button.type">
    <rappider-divider [text]="divider?.text"></rappider-divider>
    <rappider-button *ngIf="button"
                     [type]="button.type"
                     [shape]="button.shape"
                     [text]="button.text"
                     [size]="button.size"
                     [transparent]="button.transparent"
                     [loading]="button.loading"
                     [block]="button.block"
                     [disabled]="button.disabled"
                     [colorType]="button.colorType"
                     [formButtonType]="button?.formButtonType"
                     [borderSettings]="button?.borderSettings"
                     [customSizeSettings]="button?.customSizeSettings"
                     [customColorSettings]="button?.customColorSettings"
                     [shadowSettings]="button?.shadowSettings"
                     [paddingSettings]="button?.paddingSettings"
                     [marginSettings]="button?.marginSettings"
                     [icon]="button.icon"></rappider-button>
  </div>
</nz-card>
