/* eslint-disable */
import {
  Tenant,
  Page,
  EnvironmentVariable,
  MessageTemplate,
  ProjectSetting,
  ProjectFile,
  Person,
  ProjectRole,
  ProjectFolder,
  ProjectVersion,
  Metadata,
  ProjectTheme,
  ProjectModel,
  UIDataStore,
  ProjectPackage,
  RappiderFunction,
  PersonInterface
} from '../index';

declare var Object: any;
export interface ProjectInterface {
  id?: string;
  name: string;
  key?: string;
  cssStyle?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  isDeleted?: boolean;
  deletedDate?: Date;
  deletedBy?: string;
  deletedById?: string;
  tenantId?: string;
  tenant?: Tenant;
  pages?: Page[];
  environmentVariables?: EnvironmentVariable[];
  messageTemplates?: MessageTemplate[];
  projectSettings?: ProjectSetting[];
  files?: ProjectFile[];
  members?: Person[];
  roles?: ProjectRole[];
  folders?: ProjectFolder[];
  versions?: ProjectVersion[];
  metadata?: Metadata[];
  theme?: ProjectTheme;
  models?: ProjectModel[];
  uiDataStores?: UIDataStore[];
  packages?: ProjectPackage[];
  functions?: RappiderFunction[];
  imagePlaceHolderUrl?: string; /* an image url to display in the project list card. TODO: Manually added. Add to the model */

  people?: PersonInterface[]; /* TODO: Manually added. Add to the model */
}

export class Project implements ProjectInterface {
  'id': string;
  'name': string;
  'key': string;
  'cssStyle'?: string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  'isDeleted': boolean;
  'deletedDate': Date;
  'deletedBy': string;
  'deletedById': string;
  'tenantId': string;
  tenant: Tenant;
  pages: Page[];
  environmentVariables: EnvironmentVariable[];
  messageTemplates: MessageTemplate[];
  projectSettings: ProjectSetting[];
  files: ProjectFile[];
  members: Person[];
  roles: ProjectRole[];
  folders: ProjectFolder[];
  versions: ProjectVersion[];
  metadata: Metadata[];
  theme: ProjectTheme;
  models: ProjectModel[];
  uiDataStores: UIDataStore[];
  packages: ProjectPackage[];
  functions: RappiderFunction[];
  constructor(data?: ProjectInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Project`.
   */
  public static getModelName() {
    return 'Project';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Project for dynamic purposes.
   **/
  public static factory(data: ProjectInterface): Project {
    return new Project(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Project',
      plural: 'Projects',
      path: 'projects',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        key: {
          name: 'key',
          type: 'string',
        },
        cssStyle: {
          name: 'key',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
        isDeleted: {
          name: 'isDeleted',
          type: 'boolean',
          default: false,
        },
        deletedDate: {
          name: 'deletedDate',
          type: 'Date',
        },
        deletedBy: {
          name: 'deletedBy',
          type: 'string',
        },
        deletedById: {
          name: 'deletedById',
          type: 'string',
        },
        tenantId: {
          name: 'tenantId',
          type: 'string',
        },
      },
      relations: {
        tenant: {
          name: 'tenant',
          type: 'Tenant',
          model: 'Tenant',
          relationType: 'belongsTo',
          keyFrom: 'tenantId',
          keyTo: 'id',
        },
        pages: {
          name: 'pages',
          type: 'Page[]',
          model: 'Page',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'projectId',
        },
        environmentVariables: {
          name: 'environmentVariables',
          type: 'EnvironmentVariable[]',
          model: 'EnvironmentVariable',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'projectId',
        },
        messageTemplates: {
          name: 'messageTemplates',
          type: 'MessageTemplate[]',
          model: 'MessageTemplate',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'projectId',
        },
        projectSettings: {
          name: 'projectSettings',
          type: 'ProjectSetting[]',
          model: 'ProjectSetting',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'projectId',
        },
        files: {
          name: 'files',
          type: 'ProjectFile[]',
          model: 'ProjectFile',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'projectId',
        },
        members: {
          name: 'members',
          type: 'Person[]',
          model: 'Person',
          relationType: 'hasMany',
          modelThrough: 'PersonProject',
          keyThrough: 'personId',
          keyFrom: 'id',
          keyTo: 'projectId',
        },
        roles: {
          name: 'roles',
          type: 'ProjectRole[]',
          model: 'ProjectRole',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'projectId',
        },
        folders: {
          name: 'folders',
          type: 'ProjectFolder[]',
          model: 'ProjectFolder',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'projectId',
        },
        versions: {
          name: 'versions',
          type: 'ProjectVersion[]',
          model: 'ProjectVersion',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'projectId',
        },
        metadata: {
          name: 'metadata',
          type: 'Metadata[]',
          model: 'Metadata',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'projectId',
        },
        theme: {
          name: 'theme',
          type: 'ProjectTheme',
          model: 'ProjectTheme',
          relationType: 'hasOne',
          keyFrom: 'id',
          keyTo: 'projectId',
        },
        models: {
          name: 'models',
          type: 'ProjectModel[]',
          model: 'ProjectModel',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'projectId',
        },
        uiDataStores: {
          name: 'uiDataStores',
          type: 'UIDataStore[]',
          model: 'UIDataStore',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'projectId',
        },
        packages: {
          name: 'packages',
          type: 'ProjectPackage[]',
          model: 'ProjectPackage',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'projectId',
        },
        functions: {
          name: 'functions',
          type: 'RappiderFunction[]',
          model: 'RappiderFunction',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'projectId',
        },
      },
    };
  }
}
