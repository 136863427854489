import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  constructor(
    private router: Router
  ) { }

  getRouteDataByFieldName(dataFieldName: string): any {
    const root = this.router.routerState.snapshot.root;
    return this.getLastChildByActivatedRouteSnapshot(root).data[dataFieldName];
  }

  private getLastChildByActivatedRouteSnapshot(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (route.firstChild) {
      return this.getLastChildByActivatedRouteSnapshot(route.firstChild);
    } else {
      return route;
    }
  }

}
