import { Injectable } from '@angular/core';
import { CategorizedData } from '../../models/categorized-data';
import { ComponentDefinitionInterface, Category } from '../../sdk/models';
import { MetadataService } from '../metadata-service/metadata.service';
import * as lodash from 'lodash';
import { CategorizedComponentDefinitionGroup } from '../../interfaces/categorized-component-definition-group';
import { COMPONENT_DEFINITION_DEFAULT_IMAGE_EXTENSION } from '../../definitions/component-definition-default-image-extension';
import { ComponentDefinitionWithRelations } from '@rappider/rappider-sdk';
@Injectable({
  providedIn: 'root'
})
export class ComponentDefinitionService {

  constructor(
    private metadataService: MetadataService
  ) { }

  /**
   * Extracts the main and subcategories of component definitions
   * Returns sorted and distinct categories
   *
   * @param {ComponentDefinitionInterface[]} componentDefinitions
   * @return {*}
   * @memberof ComponentDefinitionService
   */
  getSortedCategoriesOfComponentDefinitions(componentDefinitions: ComponentDefinitionWithRelations[] | ComponentDefinitionInterface[]):
    Category[] {
    const categories = (componentDefinitions as ComponentDefinitionWithRelations[]).reduce((acc, curr) => {
      if (curr.mainCategory?.id && !acc.some(category => category.id === curr.mainCategory?.id)) {
        acc.push(curr.mainCategory);
      }
      if (curr.subCategories?.length) {
        const filteredSubCategories = curr.subCategories.filter(category => !acc.some(addedCategory => addedCategory.id === category.id));
        acc.push(...filteredSubCategories);
      }
      return acc;
    }, []);
    /* sort categories */
    const sortedCategories = lodash.sortBy(categories,
      category => category.order, /* sort by order */
      category => category.title /* then sort by title */
    );

    return sortedCategories;
  }
  /**
   * group component definitions by their categories
   *
   * @param {ComponentDefinitionWithRelations[]} componentDefinitions
   * @memberof ComponentDefinitionService
   */
  categorizeComponentDefinitions(componentDefinitions: ComponentDefinitionWithRelations[]): CategorizedComponentDefinitionGroup[] {

    /* handle when componentDefinitions data is broken or empty array */
    if (!componentDefinitions) {
      return null;
    } else if (!componentDefinitions.length) {
      return [];
    }

    /* get sorted, distinct categories of the component definitions */
    const sortedCategories = this.getSortedCategoriesOfComponentDefinitions(componentDefinitions);

    /* categorize component definitions which have any category */
    const categorizedComponentDefinitions = sortedCategories.map(category => {

      /* get component definitions of the category */
      const componentDefinitionsByCategory = componentDefinitions
        .filter(componentDefinition =>
          componentDefinition.mainCategory?.id === category.id ||
          componentDefinition.mainCategory?.parentCategoryId === category.id ||
          componentDefinition.subCategory?.some(subCategory => subCategory.id === category.id)
        )
        .sort((pre, next) => pre.title.localeCompare(next.title))
        .map(componentDefinition => ({
          isMainCategory: componentDefinition.mainCategory?.id === category.id,
          componentDefinition: componentDefinition
        }));

      return <CategorizedData>{
        title: category.title,
        id: category.id,
        name: category.name,
        description: category.description,
        data: componentDefinitionsByCategory,
        isExpanded: !!this.metadataService.getValueByKey(category.metadata, 'isExpanded')
      };

    });

    /* get uncategorized component definitions */
    const uncategorizedComponentDefinitions = componentDefinitions
      .filter(componentDefinition => !componentDefinition.mainCategory && !componentDefinition.subCategories?.length)
      .sort((pre, next) => pre.title.localeCompare(next.title))
      .map(componentDefinition => ({
        isMainCategory: false,
        componentDefinition: componentDefinition
      }));

    /* push uncategorized component definitions to array */
    if (uncategorizedComponentDefinitions.length) {
      categorizedComponentDefinitions.push(<CategorizedData>{
        title: 'SHARED.OTHERS',
        name: 'other',
        description: 'COMPONENT_BROWSER_MODULE.COMPONENT_DEFINITIONS_CATEGORIES.OTHER_DESCRIPTION',
        data: uncategorizedComponentDefinitions,
        isExpanded: false
      });
    }

    return categorizedComponentDefinitions;
  }

  /**
   * gets the componentDefinitions by fieldName matches with search text's words
   *
   * @param {ComponentDefinitionInterface[]} componentDefinitions
   * @param {string} searchText
   * @returns
   * @memberof ComponentDefinitionSearchService
   */
  getMatchedComponentDefinitionsBySearchText(componentDefinitions: ComponentDefinitionInterface[], searchText: string): CategorizedData[] {

    const searchFields = [
      {
        title: 'Class Name',
        fieldName: 'className'
      },
      {
        title: 'Title',
        fieldName: 'title'
      },
      {
        title: 'Module Name',
        fieldName: 'moduleName'
      }
    ];

    const words = searchText?.split(' ').map(word => word.toLowerCase()).filter(x => x);
    return words?.length
      ? searchFields.map(searchField => <CategorizedData>{
        title: searchField.title,
        data: componentDefinitions.filter(componentDefinition => {
          const fieldValue = componentDefinition[searchField.fieldName]?.toLowerCase();
          /* if component definition's field contains any search words in */
          return words.some(word => fieldValue.includes(word));
        })
      })
      : [];
  }

  getDefaultPreviewImageUrl(componentDefinition: ComponentDefinitionWithRelations) {
    return `/assets/components/preview/${componentDefinition.selector}.${COMPONENT_DEFINITION_DEFAULT_IMAGE_EXTENSION}`;
  }

  getDefaultExplanationImageUrl(componentDefinition: ComponentDefinitionWithRelations) {
    return `/assets/components/explanation/${componentDefinition.selector}.${COMPONENT_DEFINITION_DEFAULT_IMAGE_EXTENSION}`;
  }

}
