import { CrudFormSelectItem, CrudViewFormItemType } from '@rappider/rappider-components/utils';

export const PROJECT_MODEL_ENDPOINT_FORM_ITEM =
  <CrudFormSelectItem>
  {
    title: 'PROJECT_MODULE.UI_STEP_FUNCTION_CREATE_OR_EDIT_COMPONENT.PROJECT_MODEL_ENDPOINT',
    fieldName: 'endpointId',
    type: CrudViewFormItemType.Select,
    index: 6,
  };
