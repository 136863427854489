/* eslint-disable */
import { Project, MessageTemplateDataField } from '../index';

declare var Object: any;
export interface MessageTemplateInterface {
  id?: string;
  key: string;
  environmentKey: string;
  type: string;
  subject: string;
  template: string;
  noInputsSupported: boolean;
  projectId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  dataFields?: Array<MessageTemplateDataField>;
  project?: Project;
  _dataFields?: MessageTemplateDataField[];
}

export class MessageTemplate implements MessageTemplateInterface {
  'id'?: string;
  'key': string;
  'environmentKey': string;
  'type': string;
  'subject': string;
  'template': string;
  'noInputsSupported': boolean;
  'projectId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  'dataFields': Array<MessageTemplateDataField>;
  project: Project;
  _dataFields: MessageTemplateDataField[];
  constructor(data?: MessageTemplateInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MessageTemplate`.
   */
  public static getModelName() {
    return 'MessageTemplate';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of MessageTemplate for dynamic purposes.
   **/
  public static factory(data: MessageTemplateInterface): MessageTemplate {
    return new MessageTemplate(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'MessageTemplate',
      plural: 'MessageTemplates',
      path: 'message-templates',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        key: {
          name: 'key',
          type: 'string',
        },
        environmentKey: {
          name: 'environmentKey',
          type: 'string',
        },
        type: {
          name: 'type',
          type: 'string',
        },
        subject: {
          name: 'subject',
          type: 'string',
        },
        template: {
          name: 'template',
          type: 'string',
        },
        noInputsSupported: {
          name: 'noInputsSupported',
          type: 'boolean',
          default: false,
        },
        projectId: {
          name: 'projectId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
        dataFields: {
          name: 'dataFields',
          type: 'Array&lt;any&gt;',
        },
      },
      relations: {
        project: {
          name: 'project',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
          keyFrom: 'projectId',
          keyTo: 'id',
        },
        _dataFields: {
          name: '_dataFields',
          type: 'MessageTemplateDataField[]',
          model: 'MessageTemplateDataField',
          relationType: 'embedsMany',
          keyFrom: 'dataFields',
          keyTo: 'id',
        },
      },
    };
  }
}
