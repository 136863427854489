import { AbstractControl, ValidatorFn } from '@angular/forms';

export function projectNameValidator(projectNames: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (value && projectNames?.length) {
      if (projectNames.map(name => name.toLowerCase()).includes(value.toLowerCase())) {
        return { 'projectNameExists': true };
      }
    }
    return null;
  };
}
