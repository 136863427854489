import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SUPPORTED_LANGUAGES, DEFAULT_LANGUAGE } from '../../definitions';
import { Language } from '../../models/language/language';

export let ACTIVE_LANGUAGE: Language;

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  SUPPORTED_LANGUAGES = SUPPORTED_LANGUAGES;
  DEFAULT_LANGUAGE = DEFAULT_LANGUAGE;

  constructor(
    private translateService: TranslateService
  ) { }

  initLanguagePack() {
    this.SUPPORTED_LANGUAGES.forEach(element => {
      this.translateService.addLangs([element.name]);
    });
    const browserLang = this.translateService.getBrowserLang();
    this.changeLanguage(browserLang);

  }

  getBrowserLang() {
    return this.translateService.getBrowserLang();
  }

  changeLanguage(languageName: string) {
    const activeLanguage = this.SUPPORTED_LANGUAGES.find(lang => lang.name === languageName) || this.DEFAULT_LANGUAGE;
    this.translateService.use(activeLanguage.name);
    ACTIVE_LANGUAGE = activeLanguage;
  }

  getActiveLanguage(): Language {
    return ACTIVE_LANGUAGE;
    /* get current language name */
    const currentLanguageName = this.translateService.currentLang;
    /* get selected language object */
    const activeLanguage = this.SUPPORTED_LANGUAGES.find(language => language.name === currentLanguageName);
    console.log(currentLanguageName);
    return activeLanguage;
  }

}
