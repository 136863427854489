import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RappiderFunction } from '../../../shared/interfaces/data-mapping.interface';
import { DEFAULT_FUNCTION } from './utils/default-function';
import { FunctionButtonText } from './utils/function-button-text.enum';
import { FunctionMode } from './utils/function-mode.enum';

@Component({
  selector: 'rappider-rappider-function',
  templateUrl: './rappider-function.component.html',
  styleUrls: ['./rappider-function.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => RappiderFunctionComponent),
      multi: true
    }
  ]
})
export class RappiderFunctionComponent implements ControlValueAccessor {

  @Input() sourceFields: string[];
  @Input() functions;

  sourceFieldParameters: string;

  functionButtonText = FunctionButtonText.CustomFunction;

  FunctionModes = FunctionMode;
  functionButton = {
    icon: {
      name: 'fas fa-plus'
    }
  };
  functionMode = FunctionMode.Edit;;

  ASSIGN_FUNCTION = DEFAULT_FUNCTION;

  _value: RappiderFunction = {};

  get value() {
    return this._value;
  }

  set value(value: RappiderFunction) {
    this._value = value || {};
    this.onChange(value);
    this.onTouched();
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: RappiderFunction) {
    this._value = value || {};
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  /* whenever function select, removes custom function */
  onFunctionSelect() {
    /* TODO: Not a good UX, maybe isCustom flag should added to function model? */
  }

  handleFunctionState() {
    if (this.value.isCustom) {
      if (!this.value?.code) {
        this.value.customFunction = null;
        this.value.name = 'assign';
      }
      this.functionButtonText = FunctionButtonText.CustomFunction;
    } else {
      this.functionButtonText = FunctionButtonText.SelectFunction;
      delete this.value.name;
    }
    this.value.isCustom = !this.value.isCustom;
  }

}
