import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { ProjectWithRelations } from '@rappider/rappider-sdk';
import { PROJECT_DOCUMENT_CREATE_OR_EDIT_CONFIG } from '@rappider/shared/configs';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { CreateProjectDocumentUpload } from 'libs/project/src/lib/states/project-document-state/project-document.actions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rappider-project-document-create',
  templateUrl: './project-document-create.component.html',
  styleUrls: ['./project-document-create.component.scss']
})
export class ProjectDocumentCreateComponent implements OnInit, OnDestroy {
  /* create form config */
  PROJECT_DOCUMENT_CREATE_CONFIG = PROJECT_DOCUMENT_CREATE_OR_EDIT_CONFIG;

  /* main title */
  mainTitle: HeadingComponentConfig;
  /* page title */
  title: BreadcrumbOption[];
  /* subscriptions */
  subscriptions: Subscription[];
  /* active project */
  activeProject: ProjectWithRelations;
  /* loading state */
  loading: boolean;
  displayToolbar = false;
  displayToolbarBackButton = false;

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToProjectDocumentLoading()
    ];
  }

  setTitle() {
    this.mainTitle = {
      content: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_DOCUMENT_CREATE.PAGE_TITLE,
      type: defaultToolbarTitleHeadingSize
    };

    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
      },
      {
        label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_DOCUMENT_CREATE.PAGE_TITLE
      }
    ];
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((project: ProjectWithRelations) => {
      if (project) {
        this.activeProject = project;
        this.setTitle();
      } else {
        this.activeProject = null;
      }
    });
  }

  subscribeToProjectDocumentLoading() {
    return this.store.select(state => state.projectDocument?.loading).subscribe(loading => {
      this.loading = loading;
    });
  }

  onCreateProjectDocument(projectDocument) {
    this.store.dispatch(CreateProjectDocumentUpload({ payload: { projectDocuments: projectDocument } }));
  }

}
