import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { defaultToolbarTitleHeadingSize, PATH_DEFINITIONS, ProjectModelEditOptions, QUERY_PARAM_DEFINITIONS } from '@rappider/shared/definitions';
import { ProjectInterface } from 'libs/shared/src/lib/sdk/models';
import { Subscription } from 'rxjs';
import { DATA_FIELD_LIST_CONFIG, GENERATE_DATA_STORE, blackListedFieldNamesForCrudPageConfig } from './config/data-field-list-config';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionResponse, BreadcrumbOption, ButtonComponentConfig, HeadingComponentConfig, OrderChangeOutput, DropdownMenuItem } from '@rappider/rappider-components/utils';
import { DataSchemaWithRelations, PageWithRelations, ProjectModel, ProjectModelField, ProjectModelFieldControllerService, ProjectModelWithRelations } from '@rappider/rappider-sdk';
import { CreateCrudPagesForProjectModel, GenerateDataStore, } from '../../states/project-model-state/project-model.actions';
import { DataSchemaDisplayComponentMode } from '../../modules/data-schema/components/data-schema-display/utils/data-schema-field-edit-component-mode.enum';
import { NotificationService } from '@rappider/services';
import { getProjectAndProjectModelsLoading } from './utils/loading-selector';
import { getProjectModelsWithFieldsAndSchemas } from './utils/get-project-model-with-fields-and-schemas';
import { ProjectModelFieldActions } from '@rappider/project-model-field';
import { BulkUpdateProjectModelFields } from 'libs/project-model-field/src/lib/state/project-model-field.actions';
import { ActionButtonConfig } from '../../modules/data-schema/components/data-schema-display/utils/action-buttons.config';
import { DataSchemaDisplayComponent } from '../../modules/data-schema/components/data-schema-display/data-schema-display.component';

@Component({
  selector: 'rappider-data-field-list',
  templateUrl: './data-field-list.component.html',
  styleUrls: ['./data-field-list.component.scss']
})
export class DataFieldListComponent implements OnInit, OnDestroy {

  @ViewChild(DataSchemaDisplayComponent) dataSchemaDisplay: DataSchemaDisplayComponent;

  DATA_FIELD_LIST_CONFIG = DATA_FIELD_LIST_CONFIG;
  /* main title */
  mainTitle: HeadingComponentConfig = {
    content: 'PROJECT_MODULE.PROJECT_MODEL_DATA_FIELD_LIST_COMPONENT.DATA_MODEL_DETAILS',
    type: defaultToolbarTitleHeadingSize
  };
  /* subscriptions */
  subscriptions: Subscription[];
  /* page title */
  title: string[] | BreadcrumbOption[];
  /* active project id */
  activeProjectId: string;
  /* list config */
  /* select component options for project models */
  projectModelSelectOptions: { key: string; value: string }[];
  /* active project */
  activeProject: {
    id: string;
    name: string;
  };
  layouts: PageWithRelations[];
  /* project model id */
  projectModelId: string;
  /* active project model */
  activeProjectModel: ProjectModelWithRelations;
  /* flag for loading state of create crud pages endpoint */
  isCrudPagesLoading = false;
  /*  */
  isDataSchemaFieldModalVisible = false;
  /* project model loading state */
  isProjectModelsLoading = false;
  /* data schemas loading state */
  isDataSchemasLoading = false;
  /*  */
  activeDataSchema: DataSchemaWithRelations;
  /*  */
  dataSchemas: DataSchemaWithRelations[];
  /*  */
  dataSchemaDisplayComponentMode: DataSchemaDisplayComponentMode;
  isLoading: boolean;
  isProjectModelLoaded: boolean;
  isLoaded: boolean;

  QUERY_PARAM_DEFINITIONS = QUERY_PARAM_DEFINITIONS;
  displayToolbar = false;
  displayToolbarBackButton = false;
  titleBarActionButtons: ButtonComponentConfig[] = [];
  generatingDataStoreModelIds: Array<string>;

  isProjectModelFieldsLoading: boolean;
  isProjectModelFieldsLoaded: boolean;
  isFieldsLoadingForModel: boolean;

  replaceExistingCrudPages = false;
  showReplaceModal = false;
  ActionButtonConfig = ActionButtonConfig;
  currentStep = 0;

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getProjectModelIdFromUrl();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * sets/updates title bar action button config
   * there is only one button, so we dont spread the array
   *
   * @memberof DataFieldListComponent
   */

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToProjectModels(),
      this.subscribeToProjectModelsLoading(),
      this.subscribeToDataSchemasLoading(),
      this.subscribeToGenerateCrudPagesForProjectModelLoading(),
      this.subscribeToGeneratingDataStoreModelIds(),
      this.subscribeToLoading(),
      this.subscribeToProjectModelsLoaded(),
      this.subscribeToProjectModelFieldsLoading(),
      this.subscribeToProjectModelFieldsLoaded(),
      this.subscribeToFieldsLoadingProjectModelIds(),
      this.subscribeToLayouts()
    ];
  }

  /**
   * subscribe to active project to set the title
   *
   * @returns
   * @memberof DataFieldListComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectInterface) => {
      this.activeProject = {
        id: activeProject?.id,
        name: activeProject?.name
      };
    });
  }

  subscribeToGenerateCrudPagesForProjectModelLoading() {
    return this.store.select(state => state.projectModel?.isCrudPagesLoading).subscribe((isCrudPagesLoading: boolean) => {
      if (this.isCrudPagesLoading && !isCrudPagesLoading) {
        this.toggleEditDataSchemaFieldModalVisibility();
      }
      this.isCrudPagesLoading = isCrudPagesLoading;
    });
  }

  subscribeToGeneratingDataStoreModelIds() {
    return this.store.select(state => state.projectModel?.generatingDataStoreModelIds).subscribe((generatingDataStoreModelIds: Array<string>) => {
      this.generatingDataStoreModelIds = generatingDataStoreModelIds;

      if (this.activeProjectModel) {
        const isDataStoreCurrentlyGenerating = generatingDataStoreModelIds.some(generatingDataStoreModelId => generatingDataStoreModelId === this.activeProjectModel.id);
        if (isDataStoreCurrentlyGenerating) {
          this.notificationService.createNotification(
            'info',
            this.activeProjectModel.name,
            'Generating Data Store',
          );
        }
      }
    });
  }

  getProjectModelIdFromUrl() {
    this.projectModelId = this.activatedRoute.snapshot.params['projectModelId'];
    if (!this.activatedRoute.snapshot.queryParams['tab']) {
      this.router.navigate([`${PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_DATA_FIELD_LIST}`, this.activatedRoute.snapshot.params['projectModelId']], { queryParams: { tab: this.QUERY_PARAM_DEFINITIONS.PROJECT.DATA_FIELD_LIST_COMPONENT.FIELDS_TAB.tab } });
    }
  }

  subscribeToProjectModels() {
    return this.store.select(<any>getProjectModelsWithFieldsAndSchemas).subscribe((projectModelsWithFieldsAndSchemas) => {
      if (projectModelsWithFieldsAndSchemas) {
        this.activeProjectModel = projectModelsWithFieldsAndSchemas.projectModels.find(projectModel => projectModel.id === this.projectModelId);
        this.activeProjectModel.fields = this.activeProjectModel.fields?.map(projectModelField => ({
          ...projectModelField,
          type: projectModelField.isArray && !projectModelField.type.includes('[]') ? `${projectModelField.type} []` : projectModelField.type
        }));
        this.dataSchemas = projectModelsWithFieldsAndSchemas.dataSchemas;
        this.activeDataSchema = projectModelsWithFieldsAndSchemas.dataSchemas?.find(dataSchema => dataSchema.id === this.activeProjectModel?.relationedTypeId);
        if (this.activeDataSchema) {
          this.activeDataSchema = {
            ...this.activeDataSchema,
            fields: this.activeDataSchema.fields?.filter(field =>
              !field.isNavigationalProperty &&
              !(field.isId || field.isMixin) &&
              !blackListedFieldNamesForCrudPageConfig.includes(field.name)
            )
          };
        }
        this.setEditModelDropdownOptions();
        this.setTitle();
      } else {
        this.activeProjectModel = null;
      }
    });
  }

  subscribeToProjectModelsLoading() {
    return this.store.select(state => state.projectModel?.loading).subscribe((isLoading: boolean) => {
      this.isProjectModelsLoading = isLoading;
    });
  }

  subscribeToProjectModelFieldsLoading() {
    return this.store.select(state => state.projectModelField?.isLoading).subscribe((isLoading: boolean) => {
      this.isProjectModelFieldsLoading = isLoading;
    });
  }

  subscribeToDataSchemasLoading() {
    return this.store.select(state => state.dataSchema?.loading).subscribe((isLoading: boolean) => {
      this.isDataSchemasLoading = isLoading;
    });
  }

  subscribeToLayouts() {
    return this.store.select(state => state.layout?.data).subscribe(layouts => {
      this.layouts = layouts ?? [];
    });
  }

  setEditModelDropdownOptions() {
    this.DATA_FIELD_LIST_CONFIG.listActions = this.DATA_FIELD_LIST_CONFIG.listActions.map(listAction => {
      if (listAction.name === 'editDataModel') {
        if (this.activeProjectModel?.generateUIDataStore || !this.activeProjectModel?.isDeletable) {
          return {
            ...listAction,
            dropdownConfig: {
              ...listAction.dropdownConfig,
              items: listAction.dropdownConfig.items.filter(action => action.key !== 'GenerateDataStore')
            }
          };
        } else {
          return {
            ...listAction,
            dropdownConfig: {
              ...listAction.dropdownConfig,
              items: listAction.dropdownConfig.items.some(action =>
                action.key === 'GenerateDataStore') ? listAction.dropdownConfig.items : [...listAction.dropdownConfig.items, GENERATE_DATA_STORE]
            }
          };
        }
      } else {
        return listAction;
      }
    });
  }

  subscribeToLoading() {
    return this.store.select(<any>getProjectAndProjectModelsLoading).subscribe(loading => {
      this.isLoading = loading;
    });
  }

  subscribeToProjectModelFieldsLoaded() {
    return this.store.select(state => state.projectModelField.isLoaded).subscribe(isLoaded => {
      this.isProjectModelFieldsLoaded = isLoaded;
    });
  }

  subscribeToFieldsLoadingProjectModelIds() {
    return this.store.select(state => state.projectModelField.fieldsLoadingModelIds).subscribe((fieldsLoadingModelIds: string[]) => {
      this.isFieldsLoadingForModel = fieldsLoadingModelIds?.some(id => id === this.projectModelId);
    });
  }

  subscribeToProjectModelsLoaded() {
    return this.store.select(state => state.projectModel.isLoaded).subscribe(isLoaded => {
      this.isLoaded = isLoaded;
    });
  }

  setTitle() {
    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
      },
      {
        label: 'PROJECT_MODULE.PROJECT_MODEL_DATA_FIELD_LIST_COMPONENT.DATA_MODELS',
        redirectUrl: PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_LIST
      },
      {
        label: this.activeProjectModel?.name,
      },
      {
        label: 'PROJECT_MODULE.PROJECT_MODEL_DATA_FIELD_LIST_COMPONENT.DATA_MODEL_DETAILS'
      }
    ];
  }

  navigateCreateDataFieldPage() {
    this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_DATA_FIELD_CREATE}/${this.projectModelId}` }));
  }

  /**
   * event that will be fired whenever list order changed
   *
   * @param {OrderChangeOutput} orderChangeData
   * @memberof DataFieldListComponent
   */
  onOrderChange(orderChangeData: OrderChangeOutput) {
    /* start index of ordered item */
    let startIndex = orderChangeData.orderStartIndex;

    /* new indices of changed data */
    const updatedProjectModelDataFields = orderChangeData.data.map((item: ProjectModelField) => {
      const dataFieldWithNewIndex = {
        id: item.id,
        index: startIndex
      };
      startIndex++;
      return dataFieldWithNewIndex;
    });

    this.store.dispatch(BulkUpdateProjectModelFields({ payload: { projectModelId: this.projectModelId, projectModelFields: updatedProjectModelDataFields } }));
  }

  onDeleteDataFieldClick(response: ActionResponse) {
    const dataFieldToDelete = <ProjectModelField>response.data;

    this.store.dispatch(ProjectModelFieldActions.DeleteProjectModelField(
      {
        payload: {
          projectModelFieldId: dataFieldToDelete.id,
          projectModelId: this.projectModelId,
          relationedTypeId: this.activeProjectModel?.relationedTypeId
        }
      }
    ));
  }

  createCrudPagesForProjectModel() {
    this.store.dispatch(new CreateCrudPagesForProjectModel({ projectModel: this.activeProjectModel, replaceExistingCrudPages: this.replaceExistingCrudPages }));
  }

  toggleEditDataSchemaFieldModalVisibility() {
    this.isDataSchemaFieldModalVisible = !this.isDataSchemaFieldModalVisible;
  }

  setEditDataSchemaFieldModalTitle() {
    return `${this.activeProjectModel.name} Data Schema Fields`;
  }
  onListActionDropdownItemClick(action: DropdownMenuItem) {
    if (action.key === ProjectModelEditOptions.EditDataSchemaOptions) {
      this.dataSchemaDisplayComponentMode = DataSchemaDisplayComponentMode.Edit;
      this.toggleEditDataSchemaFieldModalVisibility();
    } else if (action.key === ProjectModelEditOptions.GenerateCrudPages) {
      if (this.activeProjectModel.isEachCRUDPageGenerated) {
        this.openReplaceModal();
      } else {
        this.openGenerateCrudPageModal();
      }
    } else if (action.key === ProjectModelEditOptions.GenerateDataStore) {
      this.store.dispatch(new GenerateDataStore({
        projectModelId: this.projectModelId
      }));
    }
  }

  openReplaceModal() {
    this.showReplaceModal = true;
  }

  closeReplaceModal() {
    this.showReplaceModal = false;
  }

  openGenerateCrudPageModal() {
    this.closeReplaceModal();
    this.dataSchemaDisplayComponentMode = DataSchemaDisplayComponentMode.GenerateCRUDPages;
    this.toggleEditDataSchemaFieldModalVisibility();
  }

  changeReplacePageOption(replaceExistingCrudPages: boolean) {
    this.replaceExistingCrudPages = replaceExistingCrudPages;
    this.openGenerateCrudPageModal();
  }

  onCurrentStepChange(currentStep) {
    this.currentStep = currentStep;
  }

  onClickSkip() {
    this.dataSchemaDisplay.onClickSkip();
  }

  onClickSave() {
    this.dataSchemaDisplay.onClickSave();
  }

  onClickBack() {
    this.dataSchemaDisplay.onClickBack();
  }

  generateCRUDPages() {
    this.dataSchemaDisplay.generateCRUDPages();
  }

  onClickHelp() {
    this.dataSchemaDisplay.onClickHelp();
  }
}
