/* eslint-disable */
import { Component, SlotDefinition } from '../index';

declare var Object: any;
export interface ComponentSlotInterface {
  id?: string;
  slotIndex?: number;
  parentComponentId?: string;
  childComponentId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  slotDefinitionId?: string;
  parentComponent?: Component;
  childComponent?: Component;
  slotDefinition?: SlotDefinition;
}

export class ComponentSlot implements ComponentSlotInterface {
  'id'?: string;
  'slotIndex': number;
  'parentComponentId': string;
  'childComponentId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  'slotDefinitionId': string;
  parentComponent: Component;
  childComponent: Component;
  slotDefinition: SlotDefinition;
  constructor(data?: ComponentSlotInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ComponentSlot`.
   */
  public static getModelName() {
    return 'ComponentSlot';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ComponentSlot for dynamic purposes.
   **/
  public static factory(data: ComponentSlotInterface): ComponentSlot {
    return new ComponentSlot(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ComponentSlot',
      plural: 'ComponentSlots',
      path: 'component-slots',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        slotIndex: {
          name: 'slotIndex',
          type: 'number',
        },
        parentComponentId: {
          name: 'parentComponentId',
          type: 'string',
        },
        childComponentId: {
          name: 'childComponentId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
        slotDefinitionId: {
          name: 'slotDefinitionId',
          type: 'string',
        },
      },
      relations: {
        parentComponent: {
          name: 'parentComponent',
          type: 'Component',
          model: 'Component',
          relationType: 'belongsTo',
          keyFrom: 'parentComponentId',
          keyTo: 'id',
        },
        childComponent: {
          name: 'childComponent',
          type: 'Component',
          model: 'Component',
          relationType: 'belongsTo',
          keyFrom: 'childComponentId',
          keyTo: 'id',
        },
        slotDefinition: {
          name: 'slotDefinition',
          type: 'SlotDefinition',
          model: 'SlotDefinition',
          relationType: 'belongsTo',
          keyFrom: 'slotDefinitionId',
          keyTo: 'id',
        },
      },
    };
  }
}
