<rappider-modal [(visible)]="this.modalConfigs.isVisible"
                width="60%"
                [title]="modalTitle"
                (okClick)="onModalButtonClick()"
                (cancelClick)="testUserModalClose()"     
                [okText]="'Save' | translate">
                <rappider-crud-view-edit-form *ngIf="this.modalConfigs.isVisible"
                [config]="TEST_USER_FORM_ITEMS_CONFIG"
                [data]="this.testUserModalData"
                [submitted]="this.modalConfigs.isSubmitted"
                (formValueChange)="testUserModalSubmit($event)"
                (validityChange)="testUserValidtyCheck($event)"
                ></rappider-crud-view-edit-form>
</rappider-modal>
