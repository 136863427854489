<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>
<nz-card>
  <nz-tabset nzType="card"
             nzAnimated="true"
             nzTabPosition="top"
             class="card-container"
             (nzSelectChange)="onTabChange($event)">
    <nz-tab *ngFor="let projectSettingTab of projectSettingTabs"
            [nzTitle]="projectSettingTab">
      <rappider-list-grid [config]="PROJECT_PACKAGE_LIST_CONFIG"
                          [data]="isCreatePackageForBackend ? projectPackagesForBackend : projectPackagesForFrontend"
                          [loading]="loading"
                          (listActionClick)="onListGridListActionDropdownItemClick()"
                          (columnActionClick)="onColumnActionClick($event)"></rappider-list-grid>
    </nz-tab>
  </nz-tabset>
</nz-card>
<rappider-project-package-create-modal [projectPackageCreateModalVisibility]="projectPackageCreateModalVisibility"
                                       [isCreatePackageForBackend]="isCreatePackageForBackend"
                                       (projectPackageCreateModalVisibilityChange)="onCloseProjectPackageCreateModalVisibilityChange($event)"></rappider-project-package-create-modal>
