<ng-container *ngIf="customTemplate && customButton?.text; else defaultTpl">
  <nz-back-top [nzTemplate]="tpl"
               [nzTarget]="target"
               [nzDuration]="duration"
               [nzVisibilityHeight]="visibilityHeight">
    <ng-template #tpl>
      <rappider-button [text]="customButton.text"
                       [type]="customButton.type"
                       [shape]="customButton.shape"
                       [size]="customButton.size"
                       [colorType]="customButton.colorType"
                       [borderSettings]="customButton.borderSettings"
                       [marginSettings]="customButton.marginSettings"
                       [paddingSettings]="customButton.paddingSettings"
                       [customColorSettings]="customButton.customColorSettings"
                       [customSizeSettings]="customButton.customSizeSettings"></rappider-button>
    </ng-template>
  </nz-back-top>
</ng-container>

<ng-template #defaultTpl>
  <nz-back-top></nz-back-top>
</ng-template>