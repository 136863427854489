import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActiveProjectActions from 'libs/project/src/lib/states/active-project-state/active-project.actions';
import { map, mergeMap } from 'rxjs/operators';
import * as DbDiagramActions from './db-diagram.actions';
import { DbDiagramNode, DbDiagramNodeControllerService, Project } from '@rappider/rappider-sdk';
@Injectable()
export class DBDiagramEffects {
  constructor(
    private actions$: Actions,
    private dbDiagramNodeApi: DbDiagramNodeControllerService
  ) { }

  getDbDiagramNode$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ActiveProjectActions.SetActiveProject>(
        ActiveProjectActions.ActionTypes.SetActiveProject
      ),
      mergeMap((action) => this.dbDiagramNodeApi.find({ filter: { where: { projectId: action.payload.project.id } } }).pipe(
        map((dbDiagramNodes: DbDiagramNode[]) => DbDiagramActions.GetDBDiagramNodesSuccessful({ payload: { dbDiagramNodes } })
        )
      )
      )
    )
  );

  createDbDiagramNode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DbDiagramActions.CreateDBDiagramNode
      ),
      mergeMap((action) => this.dbDiagramNodeApi.create({ body: action.payload.dbDiagramNode }).pipe(
        map((dbDiagramNode: DbDiagramNode) => DbDiagramActions.CreateDBDiagramNodeSuccessful({ payload: { dbDiagramNode } })
        )
      )
      )
    )
  );

  updateDbDiagramNode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DbDiagramActions.UpdateDBDiagramNode
      ),
      mergeMap((action) => this.dbDiagramNodeApi.updateById({ id: action.payload.id, body: action.payload.dbDiagramNode }).pipe(
        map(() => DbDiagramActions.UpdateDBDiagramNodeSuccessful({ payload: { id: action.payload.id, dbDiagramNode: action.payload.dbDiagramNode } })
        )
      )
      )
    )
  );
}
