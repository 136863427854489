import { IconType } from '../icon';

export const defaultPreviousButton = {
    icon: {
        name: 'left-circle',
        type: IconType.NgZorro
    }
};

export const defaultNextButton = {
    icon: {
        name: 'right-circle',
        type: IconType.NgZorro
    }
};
