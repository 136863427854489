/* angular */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
/* ng zorro */

/* internal modules */
import { AuthGuard } from '@rappider/authentication/guards';
import { PAGE_DEFINITIONS } from '@rappider/shared/definitions';
import { ActiveProjectGuard, VerifyPersonGuard } from '@rappider/shared/guards';
import {
  RappiderListGridModule,
  RappiderTitleModule,
  RappiderEditFormModule,
  RappiderTitleToolbarModule,
  RappiderSpinModule
} from '@rappider/rappider-components';
/* components */
import { ProjectModelRelationCreateComponent } from './components/project-model-relation-create/project-model-relation-create.component';
import { ProjectModelRelationListComponent } from './components/project-model-relation-list/project-model-relation-list.component';
import { ProjectModelRelationEditComponent } from './components/project-model-relation-edit/project-model-relation-edit.component';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';

const routes: Routes = [
  {
    path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_MODEL_RELATION_CREATE.URL}/:projectModelId`,
    component: ProjectModelRelationCreateComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
  },
  {
    path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_MODEL_RELATION_LIST.URL}/:projectModelId`,
    component: ProjectModelRelationListComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
  },
  {
    path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_MODEL_RELATION_EDIT.URL}/:projectModelId`,
    component: ProjectModelRelationEditComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
  }
];

@NgModule({
  declarations: [
    ProjectModelRelationCreateComponent,
    ProjectModelRelationEditComponent,
    ProjectModelRelationListComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    RappiderListGridModule,
    RappiderTitleModule,
    RappiderEditFormModule,
    RappiderTitleToolbarModule,
    RappiderSpinModule,
    NzSkeletonModule
  ],
  exports: [
    ProjectModelRelationCreateComponent,
    ProjectModelRelationEditComponent,
    ProjectModelRelationListComponent
  ]
})
export class ProjectModelRelationModule { }
