import { Action } from '@ngrx/store';

export enum ActionTypes {
  CreateMetadata = '[Metadata] CreateMetadata',
  CreateMetadataSuccessful = '[Metadata] CreateMetadataSuccessful',
  UpdateMetadata = '[Metadata] UpdateMetadata',
  UpdateMetadataSuccessful = '[Metadata] UpdateMetadataSuccessful',
  DeleteMetadata = '[Metadata] DeleteMetadata',
  DeleteMetadataSuccessful = '[Metadata] DeleteMetadataSuccessful',
}

export class CreateMetadata implements Action {
  readonly type = ActionTypes.CreateMetadata;
  constructor(public payload: { metadata: object[] }) { }
}

export class CreateMetadataSuccessful implements Action {
  readonly type = ActionTypes.CreateMetadataSuccessful;
  constructor(public payload: { metadata: object[] }) { }
}

export class UpdateMetadata implements Action {
  readonly type = ActionTypes.UpdateMetadata;
  constructor(public payload: { metadata: object[] }) { }
}

export class UpdateMetadataSuccessful implements Action {
  readonly type = ActionTypes.UpdateMetadataSuccessful;
  constructor(public payload: { metadata: object[] }) { }
}

export class DeleteMetadata implements Action {
  readonly type = ActionTypes.DeleteMetadata;
  constructor(public payload: { metadata: string[] }) { }
}

export class DeleteMetadataSuccessful implements Action {
  readonly type = ActionTypes.DeleteMetadataSuccessful;
  constructor(public payload: { metadata: string[] }) { }
}

export type Actions =
  CreateMetadata
  | CreateMetadataSuccessful
  | UpdateMetadata
  | UpdateMetadataSuccessful
  | DeleteMetadata
  | DeleteMetadataSuccessful
  ;
