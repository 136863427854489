import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzFormatEmitEvent, NzTreeComponent, NzTreeNode } from 'ng-zorro-antd/tree';

@Component({
  selector: 'rappider-schema-tree',
  templateUrl: './schema-tree.component.html',
  styleUrls: ['./schema-tree.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => SchemaTreeComponent),
      multi: true
    }
  ]
})
export class SchemaTreeComponent implements ControlValueAccessor {

  @ViewChild('nzTree') nzTree: NzTreeComponent;

  /**
   * this tree converted from json schema with the function called buildTreeFromJSONSchema from SchemaTreeService
   *
   * @type {NzTreeNode}
   * @memberof SchemaTreeComponent
   */
  @Input() tree: NzTreeNode[];
  @Input() isMultiple = true;
  @Input() isNewItemButtonVisible = false;

  @Output() selectedKeyAsFieldTree = new EventEmitter<any[]>();
  @Output() addNewItemToNodeClick = new EventEmitter<NzTreeNode>();

  selectedNodes: any;

  nodes: NzTreeNode[];

  /**
   * Depends on isMultiple variable, this value can be string or string[]
   *
   * @type {(string[] | string)}
   * @memberof SchemaTreeComponent
   */
  _value: string[] | string;

  get value() {
    return this._value;
  }

  set value(value: string[] | string) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
    this.setSelectedNodes(value);
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: string[] | string) {
    this._value = value;
    this.setSelectedNodes(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onCheckboxChange(event: NzFormatEmitEvent) {
    this.selectedNodes = event.keys;
    if (this.isMultiple) {
      this.value = event.keys;
    } else {
      /* if isMultiple is equal to false, we only need the first item of this array, therefore we take index 0 */
      this.value = event.keys[0];
    }
  }

  getSelectedPropertyOriginDataByKey(key: string | string[]) {
    let fieldTree;
    if (this.isMultiple) {
      fieldTree = (<string[]>key).map(fieldKey => this.nzTree.getTreeNodeByKey(fieldKey).origin);
    } else {
      fieldTree = this.nzTree.getTreeNodeByKey(<string>key).origin;
    }
    return fieldTree;
  }

  onAddItemToNodeClick(node: NzTreeNode) {
    this.addNewItemToNodeClick.emit(node);
  }

  setSelectedNodes(value: string | string[]) {
    if (!value?.length) {
      this.selectedNodes = [];
    }
  }

  getTitle(node: NzTreeNode) {
    const title = node?.origin?.type === 'array' ? node.title + '[]' : node.title;
    return title;
  }

}
