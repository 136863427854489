import { MessageAuthorType } from '@rappider/rappider-components/utils';
import { ChatMessage, Project } from '@rappider/rappider-sdk';
import { projectCreateSteps } from 'libs/shared/src/lib/configs/project';

export function initializeChatByProject(project: Project): ChatMessage[] {
  return [
    {
      content: `${projectCreateSteps.step1.label} ${projectCreateSteps.step1.description}`,
      role: 'assistant',
      timestamp: project.createdDate
    },
    {
      content: project?.name ?? '',
      role: 'user',
      timestamp: project.createdDate
    },
    {
      content: `${projectCreateSteps.step2.label} ${projectCreateSteps.step2.description}`,
      role: 'assistant',
      timestamp: project.createdDate
    },
    {
      content: project?.description ?? '',
      role: 'user',
      timestamp: project.createdDate
    },
    {
      content: `${projectCreateSteps.step3.label} ${projectCreateSteps.step3.description}`,
      role: 'assistant',
      timestamp: project.createdDate
    },
    {
      content: project?.targetAudienceDescription ?? '',
      role: 'user',
      timestamp: project.createdDate
    },
    {
      content: `${projectCreateSteps.step4.label} ${projectCreateSteps.step4.description}`,
      role: 'assistant',
      timestamp: project.createdDate
    },
    {
      content: project?.hasAuthenticationModule ? 'Yes' : 'No',
      role: 'user',
      timestamp: project.createdDate
    },
    {
      content: `${projectCreateSteps.step5.label} ${projectCreateSteps.step5.description}`,
      role: 'assistant',
      timestamp: project.createdDate
    },
    {
      content: project?.hasPaymentModule ? 'Yes' : 'No',
      role: 'user',
      timestamp: project.createdDate
    }
  ];
}
