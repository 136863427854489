export const DATA_FIELD_DEFAULT_FNS = [
  {
    key: 'guid',
    value: 'guid'
  },
  {
    key: 'uuid',
    value: 'uuid'
  },
  {
    key: 'uuidv4',
    value: 'uuidv4'
  },
  {
    key: 'now',
    value: 'now'
  }
];
