import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CommentControllerService, DiagramCommentControllerService, DiagramCommentWithRelations, NewDiagramComment } from '@rappider/rappider-sdk';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { CommentCategory } from '../../utils/comment-category.enum';

import * as CommentActions from '../comment/comment.actions';
import * as DiagramCommentActions from './diagram-comment.actions';

@Injectable()
export class DiagramCommentEffects {
  constructor(
    private store: Store<any>,
    private actions$: Actions,
    private diagramCommentApi: DiagramCommentControllerService,
    private commentApi: CommentControllerService
  ) { }

  getDiagramComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DiagramCommentActions.GetDiagramComments,
        CommentActions.GetCommentsSuccessful
      ),
      withLatestFrom(
        this.store.select(state => state.comment?.data)
      ),
      switchMap(([action, comments]) => {
        const filter = {
          where: {
            commentId: { inq: comments?.map(comment => comment.id) }
          }
        };
        return this.diagramCommentApi.find({ filter }).pipe(
          map((comments: DiagramCommentWithRelations[]) => DiagramCommentActions.GetDiagramCommentsSuccessful({ comments })
          ), catchError((error) => [
            DiagramCommentActions.GetDiagramCommentsFailure({ error, key: 'GetDiagramComments', timestamp: Date.now() })
          ])
        );
      })
    )
  );

  createDiagramComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DiagramCommentActions.CreateDiagramComment
      ),
      mergeMap((action) => this.commentApi.createDiagramComment({ body: action.comment }).pipe(
        map((res) => DiagramCommentActions.CreateDiagramCommentSuccessful({ comment: res.comment, diagramComment: res.diagramComment }))
      ))
    )
  );

  createDiagramCommentSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DiagramCommentActions.CreateDiagramCommentSuccessful
      ),
      map((action) => CommentActions.SetComment({ comment: action.comment }))
    )
  );

  createUIDiagramComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DiagramCommentActions.CreateUIDiagramComment
      ),
      withLatestFrom(
        this.store.select(state => state.diagram?.activeItem)
      ),
      mergeMap(([action, activeDiagramItem]) => {
        const uiDiagramComment: NewDiagramComment = {
          ...action.comment,
          relatedEntityId: activeDiagramItem.item.id,
          category: activeDiagramItem.type === 'uiStepFunction' || activeDiagramItem.type === 'uiStepFunctionWithRelations' ? CommentCategory.UIWorkflowStepFunction : CommentCategory.UIDataEvent
        };
        return [DiagramCommentActions.CreateDiagramComment({ comment: uiDiagramComment })];
      })
    )
  );

  updateDiagramComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DiagramCommentActions.UpdateDiagramComment
      ),
      map((action) => CommentActions.UpdateComment({
        id: action.commentId,
        comment: action.comment
      })), catchError((error) =>
        [
          DiagramCommentActions.UpdateDiagramCommentFailure({ error, key: 'UpdateDiagramComment', timestamp: Date.now() })
        ]
      )
    )
  );

  updateDiagramCommentSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CommentActions.UpdateCommentSuccessful
      ),
      map(() => DiagramCommentActions.UpdateDiagramCommentSuccessful())
    )
  );

  deleteDiagramComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DiagramCommentActions.DeleteDiagramComment
      ),
      switchMap((action) => this.diagramCommentApi.deleteById({ id: action.diagrmaCommentId }).pipe(
        map(() => DiagramCommentActions.DeleteDiagramCommentSuccessful({
          diagramCommentId: action.diagrmaCommentId,
          commentId: action.commentId
        })), catchError((error) => [
          DiagramCommentActions.DeleteDiagramCommentFailure({ error, key: 'DeleteDiagramComment', timestamp: Date.now() })
        ])
      ))
    )
  );

  deleteDiagramCommentSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DiagramCommentActions.DeleteDiagramCommentSuccessful
      ),
      map((action) => CommentActions.DeleteComment({ id: action.commentId }))
    )
  );
}
