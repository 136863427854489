/* eslint-disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataSchema } from '../../models/DataSchema';
import { SocketConnection } from '../../sockets/socket.connections';
import { Project } from '../../models/Project';
import { UIDataStore } from '../../models/UIDataStore';
import { UIDataEvent } from '../../models/UIDataEvent';
import { DataSchemaField } from '../../models/DataSchemaField';
import { DataSchemaDataDefinition } from '../../models/DataSchemaDataDefinition';

/**
 * Api services for the `DataSchema` model.
 */
@Injectable()
export class DataSchemaApi extends BaseLoopBackApi {
  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SocketConnection) protected connection: SocketConnection,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http, connection, models, auth, errorHandler);
  }

  /**
   * Fetches belongsTo relation project.
   *
   * @param {any} id DataSchema id
   *
   * @param {boolean} refresh
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `DataSchema` object.)
   * </em>
   */
  public getProject(
    id: any,
    refresh: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string = LoopBackConfig.getPath() + '/data-schemas/:id/project';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null)
      _urlParams.refresh = refresh;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Fetches belongsTo relation uiDataStore.
   *
   * @param {any} id DataSchema id
   *
   * @param {boolean} refresh
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `DataSchema` object.)
   * </em>
   */
  public getUiDataStore(
    id: any,
    refresh: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() + '/data-schemas/:id/ui-data-store';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null)
      _urlParams.refresh = refresh;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Fetches belongsTo relation uiDataEvent.
   *
   * @param {any} id DataSchema id
   *
   * @param {boolean} refresh
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `DataSchema` object.)
   * </em>
   */
  public getUiDataEvent(
    id: any,
    refresh: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() + '/data-schemas/:id/ui-data-event';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null)
      _urlParams.refresh = refresh;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Find a related item by id for fields.
   *
   * @param {any} id DataSchema id
   *
   * @param {any} fk Foreign key for fields
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `DataSchema` object.)
   * </em>
   */
  public findByIdFields(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() + '/data-schemas/:id/fields/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Delete a related item by id for fields.
   *
   * @param {any} id DataSchema id
   *
   * @param {any} fk Foreign key for fields
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdFields(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() + '/data-schemas/:id/fields/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Update a related item by id for fields.
   *
   * @param {any} id DataSchema id
   *
   * @param {any} fk Foreign key for fields
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `DataSchema` object.)
   * </em>
   */
  public updateByIdFields(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() + '/data-schemas/:id/fields/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Fetches belongsTo relation defaultDataDefinition.
   *
   * @param {any} id DataSchema id
   *
   * @param {boolean} refresh
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `DataSchema` object.)
   * </em>
   */
  public getDefaultDataDefinition(
    id: any,
    refresh: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() + '/data-schemas/:id/default-data-definition';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null)
      _urlParams.refresh = refresh;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Find a related item by id for dataDefinitions.
   *
   * @param {any} id DataSchema id
   *
   * @param {any} fk Foreign key for dataDefinitions
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `DataSchema` object.)
   * </em>
   */
  public findByIdDataDefinitions(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() + '/data-schemas/:id/data-definitions/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Delete a related item by id for dataDefinitions.
   *
   * @param {any} id DataSchema id
   *
   * @param {any} fk Foreign key for dataDefinitions
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdDataDefinitions(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() + '/data-schemas/:id/data-definitions/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Update a related item by id for dataDefinitions.
   *
   * @param {any} id DataSchema id
   *
   * @param {any} fk Foreign key for dataDefinitions
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `DataSchema` object.)
   * </em>
   */
  public updateByIdDataDefinitions(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() + '/data-schemas/:id/data-definitions/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Queries fields of DataSchema.
   *
   * @param {any} id DataSchema id
   *
   * @param {object} filter
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `DataSchema` object.)
   * </em>
   */
  public getFields(
    id: any,
    filter: LoopBackFilter = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string = LoopBackConfig.getPath() + '/data-schemas/:id/fields';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null)
      _urlParams.filter = filter;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in fields of this model.
   *
   * @param {any} id DataSchema id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `DataSchema` object.)
   * </em>
   */
  public createFields(
    id: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string = LoopBackConfig.getPath() + '/data-schemas/:id/fields';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Deletes all fields of this model.
   *
   * @param {any} id DataSchema id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteFields(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string = LoopBackConfig.getPath() + '/data-schemas/:id/fields';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Counts fields of DataSchema.
   *
   * @param {any} id DataSchema id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` -
   */
  public countFields(
    id: any,
    where: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() + '/data-schemas/:id/fields/count';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null)
      _urlParams.where = where;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Queries dataDefinitions of DataSchema.
   *
   * @param {any} id DataSchema id
   *
   * @param {object} filter
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `DataSchema` object.)
   * </em>
   */
  public getDataDefinitions(
    id: any,
    filter: LoopBackFilter = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() + '/data-schemas/:id/data-definitions';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null)
      _urlParams.filter = filter;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in dataDefinitions of this model.
   *
   * @param {any} id DataSchema id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `DataSchema` object.)
   * </em>
   */
  public createDataDefinitions(
    id: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() + '/data-schemas/:id/data-definitions';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Deletes all dataDefinitions of this model.
   *
   * @param {any} id DataSchema id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteDataDefinitions(
    id: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() + '/data-schemas/:id/data-definitions';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Counts dataDefinitions of DataSchema.
   *
   * @param {any} id DataSchema id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` -
   */
  public countDataDefinitions(
    id: any,
    where: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() + '/data-schemas/:id/data-definitions/count';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null)
      _urlParams.where = where;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Patch an existing model instance or insert a new one into the data source.
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - Model instance data
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `DataSchema` object.)
   * </em>
   */
  public patchOrCreate(
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PATCH';
    let _url: string = LoopBackConfig.getPath() + '/data-schemas';
    let _routeParams: any = {};
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   * @param {any} id DataSchema id
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - An object of model property name/value pairs
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `DataSchema` object.)
   * </em>
   */
  public patchAttributes(
    id: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PATCH';
    let _url: string = LoopBackConfig.getPath() + '/data-schemas/:id';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * <em>
   * (The remote method definition does not provide any description.)
   * </em>
   *
   * @param {string} dataSchemaId
   *
   * @param {object} req
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `DataSchema` object.)
   * </em>
   */
  public getWithDetailsById(
    id: any = {},
    req: any = {},
    customHeaders?: Function
  ): Observable<any> {
    /* DON'T OVERRIDE THIS CODE */
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() + '/data-schemas/get-with-details-by-id/:id';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in fields of this model.
   *
   * @param {any} id DataSchema id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `DataSchema` object.)
   * </em>
   */
  public createManyFields(
    id: any,
    data: any[] = [],
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string = LoopBackConfig.getPath() + '/data-schemas/:id/fields';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in dataDefinitions of this model.
   *
   * @param {any} id DataSchema id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `DataSchema` object.)
   * </em>
   */
  public createManyDataDefinitions(
    id: any,
    data: any[] = [],
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() + '/data-schemas/:id/data-definitions';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `DataSchema`.
   */
  public getModelName() {
    return 'DataSchema';
  }
}
