import {
  ButtonType,
  CrudViewColumnType,
  CrudTableViewConfig,
  Action,
  ActionBehavior,
  CrudFormConfig,
  CrudViewFormItemType
} from '@rappider/rappider-components/utils';
import { PATH_DEFINITIONS } from '../../definitions/path-definition';
import { Validators } from '@angular/forms';

export const PROJECT_ROLES_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'title',
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'Role',
      fieldName: 'title',
      type: CrudViewColumnType.Text,
      visible: true
    }
  ],
  listActions: [],
  itemActions: []
};

export const CREATE_NEW_ROLE_BUTTON_CONFIG: Action = {
  text: 'PROJECT_MODULE.PROJECT_ROLES_COMPONENT.CREATE_NEW_ROLE',
  behavior: ActionBehavior.Emit,
  buttonType: ButtonType.Default,
  icon: { name: 'far fa-plus' }
};

export const EDIT_ROLE_BUTTON_CONFIG: Action[] = [
  {
    text: 'SHARED.EDIT',
    name: 'EDIT_ROLE',
    icon: { name: 'far fa-edit' },
    behavior: ActionBehavior.Emit
  },
  {
    text: 'SHARED.DELETE',
    name: 'DELETE_ROLE',
    behavior: ActionBehavior.Emit,
    icon: { name: 'far fa-trash' }
  },
];

export const CREATE_OR_EDIT_PROJECT_ROLES_CONFIG: CrudFormConfig = {
  items: [
    {
      title: 'SHARED.TITLE',
      fieldName: 'title',
      showRequiredSign: true,
      type: CrudViewFormItemType.TextBox,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'This field is required'
        }
      ]
    }
  ],
  submitButton: { visible: false }
};

export const ALLOWED_USERS_FOR_INVITE = ['PROJECT_MANAGER', 'PROJECT_OWNER'];
