import { DropdownMenuComponentConfig, DropdownType, IconType } from '@rappider/rappider-components/utils';

export const THEME_SETTING_BUTTONS_CONFIG: DropdownMenuComponentConfig = {
  icon: {
    name: 'fa light fa-ellipsis',
    type: IconType.FontAwesome
  },
  items: [
    {
      label: 'Reset Theme as Default',
      key: 'resetThemeAsDefault',
      popconfirmTitle: 'Are you sure to reset theme as detault? You will lose all your theme data.'
    },
    {
      label: 'Set as Active Theme',
      key: 'setAsActiveTheme',
    }
  ],
  dropdownType: DropdownType.Button
};
