import { FeedbackButtonActionBehavior } from '@rappider/rappider-components/utils';
import { PATH_DEFINITIONS } from '../../definitions/path-definition';

const HOME_PAGE_BUTTON = {
  content: 'APP_MODULE.APP_COMPONENT.HOME_PAGE',
  actionBehavior: FeedbackButtonActionBehavior.Route,
  redirectUrl: PATH_DEFINITIONS.HOME_PAGE
};

const PROFILE_PAGE_BUTTON = {
  content: 'Go to Profile Page',
  actionBehavior: FeedbackButtonActionBehavior.Route,
  redirectUrl: PATH_DEFINITIONS.PROJECTS.PROJECT_LIST_PATH
};

const RESEND_BUTTON = {
  content: 'AUTHENTICATION_MODULE.EMAIL_VERIFY_COMPONENT.VERIFY_EMAIL_ADDRESS.VERIFY_EMAIL_ADDRESS_RESEND_BUTTON',
  actionBehavior: FeedbackButtonActionBehavior.Emit
};

export const VERIFY_EMAIL_SUCCESS_CONFIG = {
  icon: {
    name: 'far fa-check-circle text-success'
  },
  title: 'Good to go!',
  description: 'Your account has been verified.',
  buttons: [
    PROFILE_PAGE_BUTTON
  ]
};

export const VERIFY_EMAIL_FAILURE_CONFIG_WITHOUT_RESEND_BUTTON = {
  title: 'Oops!',
  icon: {
    name: 'fas fa-exclamation-triangle text-danger'
  },
  description: 'We couldn\'t verify your e-mail',
  buttons: [
    HOME_PAGE_BUTTON
  ]
};

export const VERIFY_EMAIL_FAILURE_CONFIG_WITH_RESEND_BUTTON = {
  ...VERIFY_EMAIL_FAILURE_CONFIG_WITHOUT_RESEND_BUTTON,
  buttons: [
    ...VERIFY_EMAIL_FAILURE_CONFIG_WITHOUT_RESEND_BUTTON.buttons,
    RESEND_BUTTON
  ]
};

export const VERIFY_EMAIL_NO_TOKEN_CONFIG = {
  title: 'Oops!',
  icon: {
    name: 'fas fa-exclamation-triangle text-danger'
  },
  description: 'We couldn\'t verify your e-mail. The token is invalid or not found. If you think that there is a problem then please contact with Rappider Support.',
  buttons: [
    HOME_PAGE_BUTTON
  ]
};
