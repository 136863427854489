<ng-container *ngIf="value">
  <div class="item">
    <div class="title-area">
      <div class="title">
        {{ 'PROJECT_MODULE.DATA_MAPPING_CREATE_COMPONENT.SOURCE_FIELDS' | translate }}
      </div>
      <rappider-button [text]="addSourceFieldButton.text"
                       [type]="addSourceFieldButton.type"
                       [icon]="addSourceFieldButton.icon"
                       (click)="onAddSourceFieldClick()"></rappider-button>
    </div>
    <ng-container *ngIf="sourceFields.length; else addSourceFieldTmpl">
      <div cdkDropList
           class="example-list"
           (cdkDropListDropped)="drop($event)">
        <div class="example-box"
             *ngFor="let item of sourceFields"
             cdkDrag>
          <div class="item-area">
            <ng-container *ngFor="let field of item; let i = index">
              <ng-container *ngIf="i !== item.length - 1; else lastFieldTmpl">
                {{ field.name }}.
              </ng-container>
              <ng-template #lastFieldTmpl>
                {{ field.name }}
              </ng-template>
            </ng-container>
          </div>

          <div class="button-area">
            <button nz-button
                    (click)="deleteItem(item)">
              <i class="far fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #addSourceFieldTmpl>
      {{ 'PROJECT_MODULE.DATA_MAPPING_CREATE_COMPONENT.DONT_HAVE_SOURCE_FIELD' | translate }}
    </ng-template>
  </div>
  <!-- Function -->
  <div class="item">
    <div class="title-area">
      <div class="title">
        {{ 'PROJECT_MODULE.DATA_MAPPING_CREATE_COMPONENT.FUNCTION' | translate }}
      </div>
      <div class="add-button">
        <rappider-button [text]="functionButtonText"
                         [type]="customFunctionButton.type"
                         [icon]="customFunctionButton.icon"
                         (click)="enableCustomFunction()"></rappider-button>
      </div>
    </div>

    <div>
      <ng-container *ngIf="functionMode === FunctionModes.Edit">
        <rappider-select [(ngModel)]="value.functionId"
                         [options]="functions"
                         (ngModelChange)="onFunctionSelect()"></rappider-select>
      </ng-container>
      <ng-container *ngIf="functionMode === FunctionModes.Label || !functionMode">
        {{ getFunctionKeyByValue() }}
        <button nz-button
                nzType="link"
                (click)="onEditFunctionClick()"><i class="far fa-edit"></i></button>
      </ng-container>
      <ng-container *ngIf="functionMode === FunctionModes.Custom">
        <rappider-custom-function [(ngModel)]="value.customFunction"
                                  [parameters]="sourceFields"
                                  (ngModelChange)="onCustomFunctionValueChange()">
        </rappider-custom-function>
      </ng-container>
    </div>
  </div>
  <!-- Target Field -->
  <div class="item">
    <div class="title-area">
      <div class="title">
        {{ 'PROJECT_MODULE.DATA_MAPPING_CREATE_COMPONENT.ADD_TARGET_FIELD' | translate }}
      </div>
    </div>
    <div class="content">
      <rappider-data-schema-select [(ngModel)]="value.targetField"
                                   [dataSchema]="targetDataSchema"></rappider-data-schema-select>
    </div>
  </div>
</ng-container>

<!-- Add Source Field Modal-->
<rappider-modal [(visible)]="sourceFieldModalVisible"
                [title]="'PROJECT_MODULE.DATA_MAPPING_CREATE_COMPONENT.ADD_SOURCE_FIELD' | translate"
                (cancelClick)="handleSourceModalVisibility(false)"
                (okClick)="onSourceFieldAdd()">
  <rappider-data-schema-select [(ngModel)]="sourceFieldValue"
                               [dataSchema]="sourceDataSchema"></rappider-data-schema-select>
</rappider-modal>
