
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { PersonInvitationControllerService, PersonInvitationWithRelations } from '@rappider/rappider-sdk';
import { NotificationService } from '@rappider/services';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import * as UserInvitationActions from './user-invitation.actions';


@Injectable()
export class UserInvitationEffects {
  constructor(
    private actions$: Actions,
    private personInvitationApi: PersonInvitationControllerService,
    private store: Store<any>,
    private notificationService: NotificationService
  ) { }

  getInvitedUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserInvitationActions.GetInvitedUsers
      ),
      withLatestFrom(
        this.store.select(state => state.activeProject.data?.id)
      ),
      mergeMap(([action, activeProjectId]) => {
        const filter = {
          where: {
            invitedProjectId: activeProjectId
          }
        };

        return this.personInvitationApi.find({ filter }).pipe(
          map((invitedUsers) => UserInvitationActions.GetInvitedUsersSuccessful({ payload: { invitedUsers } })), catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'SHARED.ERROR'
            );
            return [
              UserInvitationActions.GetInvitedUsersFailure({ payload: { error, key: 'GetInvitedUsersFailure', timestamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

  inviteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserInvitationActions.InviteUser
      ),
      mergeMap((action) => {
        const params = {
          body: {
            email: action.payload.invitedUser.email,
            invitedFirstName: action.payload.invitedUser.invitedFirstName,
            invitedLastName: action.payload.invitedUser.invitedLastName,
            isManager: action.payload.invitedUser.isManager
          }
        };
        return this.personInvitationApi.invite(params).pipe(
          mergeMap((invitedUser: PersonInvitationWithRelations) => {
            this.notificationService.createNotification(
              'success',
              'SHARED.SUCCESS',
              'An invitation has been sent to ' + `${action.payload.invitedUser.invitedFirstName} ${action.payload.invitedUser.invitedLastName}`
            );
            const publishedActions: Action[] = [
              UserInvitationActions.InviteUserSuccessful({
                payload: {
                  invitedUser: invitedUser
                }
              })
            ];
            return publishedActions;
          }), catchError(error => {
            this.notificationService.createNotification(
              'error',
              action.payload.invitedUser.invitedFirstName,
              'PROJECT_MODULE.PROJECT_NOTIFICATIONS.INVITE_USER_FAILURE'
            );
            return [
              UserInvitationActions.InviteUserFailure({ payload: { error, key: 'UserInvitation', timestamp: Date.now() } })
            ];
          }
          ));
      }
      )
    )
  );

  resendInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserInvitationActions.ResendInvitation
      ),
      mergeMap((action) => {
        const params = {
          id: action.payload.invitationId
        };
        return this.personInvitationApi.resendInvitation(params).pipe(
          map(() => {
            this.notificationService.createNotification(
              'success',
              'SHARED.SUCCESS',
              'This invitation was successfully resent'
            );
            return UserInvitationActions.GetInvitedUsersById({ payload: { invitationId: action.payload.invitationId } });
          }, catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'This invitation could not be resent'
            );
            return [
              UserInvitationActions.ResendInvitationFailure({ payload: { error, key: 'ResendInvitationFailure', timestamp: Date.now() } })
            ];
          }))
        );
      })
    )
  );

  cancelInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserInvitationActions.CancelInvitation
      ),
      mergeMap((action) => {
        const params = {
          id: action.payload.invitationId
        };
        return this.personInvitationApi.cancelInvitation(params).pipe(
          map(() => {
            this.notificationService.createNotification(
              'success',
              'SHARED.SUCCESS',
              'This invitation has been successfully canceled'
            );
            return UserInvitationActions.GetInvitedUsersById({ payload: { invitationId: action.payload.invitationId } });
          }, catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'This invitation could not be canceled'
            );
            return [
              UserInvitationActions.CancelInvitationFailure({ payload: { error, key: 'CancelInvitationFailure', timestamp: Date.now() } })
            ];
          }))
        );
      })
    )
  );

  invitationGetById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserInvitationActions.GetInvitedUsersById
      ),
      mergeMap((action) => {
        const params = {
          id: action.payload.invitationId
        };
        return this.personInvitationApi.findById(params).pipe(
          map((invitedUser) => UserInvitationActions.GetInvitedUsersByIdSuccessful({ payload: { invitedUser } })
            , catchError((error) => {
              this.notificationService.createNotification(
                'error',
                'SHARED.ERROR',
                'The invited user could not be loaded'
              );
              return [
                UserInvitationActions.GetInvitedUsersByIdFailure({ payload: { error, key: 'GetInvitedUsersByIdFailure', timestamp: Date.now() } })
              ];
            }))
        );
      })
    )
  );

}
