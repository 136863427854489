/* eslint-disable */
import { DataSchema } from '../index';

declare var Object: any;
export interface DataSchemaDataDefinitionInterface {
  id?: string;
  title?: string;
  dataType?: string;
  dataKeyFieldName?: string;
  dataValueFieldName?: string;
  data?: any;
  dataSchemaId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  schema?: DataSchema;
}

export class DataSchemaDataDefinition
  implements DataSchemaDataDefinitionInterface {
  'id'?: string;
  'title': string;
  'dataType': string;
  'dataKeyFieldName': string;
  'dataValueFieldName': string;
  'data': any;
  'dataSchemaId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  schema: DataSchema;
  constructor(data?: DataSchemaDataDefinitionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `DataSchemaDataDefinition`.
   */
  public static getModelName() {
    return 'DataSchemaDataDefinition';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of DataSchemaDataDefinition for dynamic purposes.
   **/
  public static factory(
    data: DataSchemaDataDefinitionInterface
  ): DataSchemaDataDefinition {
    return new DataSchemaDataDefinition(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'DataSchemaDataDefinition',
      plural: 'DataSchemaDataDefinitions',
      path: 'DataSchemaDataDefinitions',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        title: {
          name: 'title',
          type: 'string',
        },
        dataType: {
          name: 'dataType',
          type: 'string',
        },
        dataKeyFieldName: {
          name: 'dataKeyFieldName',
          type: 'string',
        },
        dataValueFieldName: {
          name: 'dataValueFieldName',
          type: 'string',
        },
        data: {
          name: 'data',
          type: 'any',
        },
        dataSchemaId: {
          name: 'dataSchemaId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
      },
      relations: {
        schema: {
          name: 'schema',
          type: 'DataSchema',
          model: 'DataSchema',
          relationType: 'belongsTo',
          keyFrom: 'dataSchemaId',
          keyTo: 'id',
        },
      },
    };
  }
}
