export enum TestUserPageEvents {
  EditTestUser = 'edit',
  DeleteTestUser = 'delete',
  AddTestUser = 'addTestUser'
}

export enum TestUserModalTitle {
  AddModalTitle = 'Create Test User',
  EditModalTitle = 'Edit Test User'
}
