import { createSelector } from '@ngrx/store';
import { Component, ComponentDefinition } from '@rappider/rappider-sdk';
import { ContentTree } from 'libs/content-tree-renderer/src/lib/models';
import { mergeComponentsAndDefinitionsToContentTree } from '../state/content-editor-helper-functions';
import { getComponentDefinitionsWithDetailsSelector } from '@rappider/shared';

export const contentTreeWithRelations = createSelector(
  state => state['contentEditor'].page?.contentTree,
  state => state['contentEditor'].page?.components,
  getComponentDefinitionsWithDetailsSelector,
  (contentTree: ContentTree, components: Component[], componentDefinitions: ComponentDefinition[]) => {
    if (contentTree) {
      /* if the page has no component, return just contentTree */
      if (components?.length) {
        if (componentDefinitions?.length) {
          return mergeComponentsAndDefinitionsToContentTree(contentTree[0], componentDefinitions, components);
        }
      } else {
        return contentTree;
      }
    }
  }
);
