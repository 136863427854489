/* eslint-disable */
import { Component, Page } from '../index';

declare var Object: any;
export interface PageComponentInterface {
  id?: string;
  componentId?: string;
  pageId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  component?: Component;
  page?: Page;
}

export class PageComponent implements PageComponentInterface {
  'id'?: string;
  'componentId': string;
  'pageId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  component: Component;
  page: Page;
  constructor(data?: PageComponentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PageComponent`.
   */
  public static getModelName() {
    return 'PageComponent';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of PageComponent for dynamic purposes.
   **/
  public static factory(data: PageComponentInterface): PageComponent {
    return new PageComponent(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'PageComponent',
      plural: 'PageComponents',
      path: 'page-components',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        componentId: {
          name: 'componentId',
          type: 'string',
        },
        pageId: {
          name: 'pageId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
      },
      relations: {
        component: {
          name: 'component',
          type: 'Component',
          model: 'Component',
          relationType: 'belongsTo',
          keyFrom: 'componentId',
          keyTo: 'id',
        },
        page: {
          name: 'page',
          type: 'Page',
          model: 'Page',
          relationType: 'belongsTo',
          keyFrom: 'pageId',
          keyTo: 'id',
        },
      },
    };
  }
}
