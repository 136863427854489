/* eslint-disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi } from '../core/base.service';
import { LoopBackAuth } from '../core/auth.service';
import { ErrorHandler } from '../core/error.service';
import { SocketConnection } from '../../sockets/socket.connections';
import { LoopBackConfig } from '../../lb.config';
import { Observable } from 'rxjs';

/**
 * Api services for the `WorkflowStepFunction` model.
 */
@Injectable()
export class WorkflowStepFunctionApi extends BaseLoopBackApi {
  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SocketConnection) protected connection: SocketConnection,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http, connection, models, auth, errorHandler);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Workflow`.
   */
  public getModelName() {
    return 'WorkflowStepFunction';
  }

  public patchAttributes(
    id: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PATCH';
    let _url: string =
      LoopBackConfig.getPath() + '/workflow-step-functions/:id';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }
}
