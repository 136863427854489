<rappider-modal [(visible)]="visibility"
                [title]="modalConfig?.title"
                [okText]="modalConfig?.okText"
                [cancelText]="modalConfig?.cancelText"
                [width]="modalConfig?.width"
                [okDisabled]="modalConfig?.okDisabled"
                [cancelDisabled]="modalConfig?.cancelDisabled"
                [footer]="modalConfig?.footer"
                [okLoading]="modalConfig?.okLoading"
                [okDanger]="modalConfig?.okDanger"
                [mask]="modalConfig?.mask"
                [maskClosable]="modalConfig?.maskClosable"
                [className]="modalConfig?.className"
                [bodyStyle]="modalConfig?.bodyStyle"
                [fullScreen]="modalConfig?.fullScreen"
                [closeIconVisibility]="modalConfig?.closeIconVisibility"
                (okClick)="onSelectClick()"
                (cancelClick)="closeModalAndClearSelectedTemplate()">
  <rappider-cards [title]="cardsConfig?.title"
                  [items]="cardsConfig?.items"
                  [itemCountPerRow]="cardsConfig?.itemCountPerRow"
                  [showTitleOnImage]="cardsConfig?.showTitleOnImage"
                  [showDescriptionOnImage]="cardsConfig?.showDescriptionOnImage"
                  [selectConfig]="cardsConfig?.selectConfig"
                  [paginationConfig]="cardsConfig?.paginationConfig"
                  [showCheckMarkOnCard]="cardsConfig?.showCheckMarkOnCard"
                  [isDynamicPagination]="cardsConfig?.isDynamicPagination"
                  (pageIndexChange)="onPageIndexChange($event)"
                  (searchTextChange)="onSearchTextChange($event)"
                  (tagFilterChange)="onTagFilterChange($event)"
                  (cardClick)="onTemplateSelect($event)"></rappider-cards>
</rappider-modal>
<div class="select-button-area">
  <rappider-text *ngIf="textConfig?.text"
                 [content]="textConfig?.content"
                 [text]="textConfig?.text"
                 [textMode]="textConfig?.textMode"
                 [typography]="textConfig?.typography"
                 [colorSettings]="textConfig?.colorSettings"></rappider-text>
  <rappider-button [text]="buttonConfig?.text"
                   [key]="buttonConfig?.key"
                   [type]="buttonConfig?.type"
                   [shape]="buttonConfig?.shape"
                   [size]="buttonConfig?.size"
                   [transparent]="buttonConfig?.transparent"
                   [loading]="buttonConfig?.loading"
                   [block]="buttonConfig?.block"
                   [disabled]="buttonConfig?.disabled"
                   [icon]="buttonConfig?.icon"
                   [colorType]="buttonConfig?.colorType"
                   [popconfirmTitle]="buttonConfig?.popconfirmTitle"
                   [emitWithoutPopconfirm]="buttonConfig?.emitWithoutPopconfirm"
                   [iconPlacement]="buttonConfig?.iconPlacement"
                   [formButtonType]="buttonConfig?.formButtonType"
                   [borderSettings]="buttonConfig?.borderSettings"
                   [marginSettings]="buttonConfig?.marginSettings"
                   [paddingSettings]="buttonConfig?.paddingSettings"
                   [shadowSettings]="buttonConfig?.shadowSettings"
                   [customColorSettings]="buttonConfig?.customColorSettings"
                   [customSizeSettings]="buttonConfig?.customSizeSettings"
                   [tooltipText]="buttonConfig?.tooltipText"
                   (click)="onSelectButtonClick()"></rappider-button>
</div>
