import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ButtonType, IconType } from '@rappider/rappider-components/utils';
import {
  successfulLottieAnimation,
  failureLottieAnimation,
  ProjectExportProcesses,
  ProjectExportProcessStatus
} from '@rappider/shared/definitions';
import { ProjectExportProcess } from '@rappider/shared/interfaces';

@Component({
  selector: 'rappider-project-export-details',
  templateUrl: './project-export-details.component.html',
  styleUrls: ['./project-export-details.component.scss']
})

export class ProjectExportDetailsComponent implements OnChanges {

  @Input() processes: ProjectExportProcess[];

  @Output() status = new EventEmitter<string>();

  displayedProcesses = [];
  currentMessage: string;
  currentStatus: string;
  currentProcessAnimation: {
    options: {
      path: string;
    };
    height: string;
    width: string;
  };

  isExportDetailsCollapsed = false;
  exportDetailsTogglerButton = {
    text: 'PROJECT_MODULE.PROJECT_VERSION_DETAIL_COMPONENT.HIDE_EXPORT_DETAILS',
    type: ButtonType.Default,
    icon: {
      name: 'up',
      type: IconType.NgZorro
    }
  };

  ProjectExportProcessStatus = ProjectExportProcessStatus;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.processes) {
      this.displayedProcesses = this.processes?.map((process, index) => {

        switch (process.status) {

          case ProjectExportProcessStatus.Processing: {
            this.currentMessage = process.message;
            this.currentStatus = process.status;
            const animation = (index === (this.processes.length - 1))
              ? ProjectExportProcesses.find(processingProcess => processingProcess.key === process.key)?.animation
              : successfulLottieAnimation;
            this.currentProcessAnimation = animation;
            /* return pairs */
            return {
              status: process.status,
              message: process.message,
              animation
            };
          }

          case ProjectExportProcessStatus.Successful: {
            this.currentMessage = process.message;
            this.currentStatus = process.status;
            /* return pairs */
            return {
              status: process.status,
              message: process.message,
              animation: successfulLottieAnimation
            };
          }

          case ProjectExportProcessStatus.Failure: {
            this.currentMessage = process.message;
            this.currentStatus = process.status;
            /* return pairs */
            return {
              status: process.status,
              message: process.message,
              animation: failureLottieAnimation
            };
          }

        }
      });
    }
    this.status.emit(this.processes.slice(-1)[0].status);
  }

  /**
   * this function avoids re-rendering the list unnecessarily,
   * ngFOR only re-render items when 'status' change
   *
   * @param {*} displayedProcess
   * @return {*}
   * @memberof ProjectExportDetailsComponent
   */
  trackProcess(process) {
    return process.status;
  }

  onProjectExportDetailsTogglerClick() {
    this.isExportDetailsCollapsed = !this.isExportDetailsCollapsed;
    if (this.isExportDetailsCollapsed) {
      this.exportDetailsTogglerButton.text = 'PROJECT_MODULE.PROJECT_VERSION_DETAIL_COMPONENT.SHOW_EXPORT_DETAILS';
      this.exportDetailsTogglerButton.icon.name = 'down';
    } else {
      this.exportDetailsTogglerButton.text = 'PROJECT_MODULE.PROJECT_VERSION_DETAIL_COMPONENT.HIDE_EXPORT_DETAILS';
      this.exportDetailsTogglerButton.icon.name = 'up';
    }
  }

  handleExportDetailsHeight() {
    return this.isExportDetailsCollapsed ? '0px' : 'unset';
  }

}
