import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';

import { RappiderMarkdownViewerComponent } from './markdown-viewer.component';


@NgModule({
  declarations: [
    RappiderMarkdownViewerComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NzSkeletonModule
  ],
  exports: [
    RappiderMarkdownViewerComponent
  ]
})
export class RappiderMarkdownViewerModule { }
