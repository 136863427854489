<section *ngIf="visibility && activePerson"
         class="sidebar-wrapper sidebar-section">
  <ng-container *ngIf="activeTabName === AppRightSidebarTab.Guidance">
    <rappider-guidance *ngIf="isContentExist; else noGuidanceContentTemplate"
                       [isGuidanceExpanded]="visibility"
                       [currentUrl]="currentUrl"
                       (pathLength)="getPathLength($event)"></rappider-guidance>
    <ng-template #noGuidanceContentTemplate>
      <div class="no-guidance-content-template">
        <nz-empty [nzNotFoundContent]="'Guidance Unavailable' | translate"></nz-empty>
      </div>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="activeTabName === AppRightSidebarTab.Chat || !activeTabName">
    <rappider-ai-assistant [isComponentInSidebar]="true"></rappider-ai-assistant>
  </ng-container>
</section>
