export const PROGRAMMING_LANGUAGES = [
  {
    key: 'NodeJS 16',
    value: 'nodejs:16'
  },
  {
    key: 'NodeJS 18',
    value: 'nodejs:18'
  },
  {
    key: 'NodeJS 20',
    value: 'nodejs:20'
  },
  {
    key: 'Python 3.12',
    value: 'python:3.12'
  },
  // {
  //   key: 'PHP 8.1',
  //   value: 'php:8.1',
  // },
  // {
  //   key: 'PHP 8.0',
  //   value: 'php:8.0'
  // },
  // {
  //   key: 'PHP 7.4',
  //   value: 'php:7.4'
  // },
  // {
  //   key: 'PHP 7.3',
  //   value: 'php:7.3'
  // },
  // {
  //   key: 'PYTHON 3.10',
  //   value: 'python:3.10'
  // },
  // {
  //   key: 'PYTHON 3',
  //   value: 'python:3'
  // },
  // {
  //   key: 'DotNET 6.0',
  //   value: 'dotnet:6.0'
  // },
  // {
  //   key: 'DotNET 3.1',
  //   value: 'dotnet:3.1'
  // },
  // {
  //   key: 'DotNET 2.2',
  //   value: 'dotnet:2.2'
  // },
  // {
  //   key: 'GO 1.20',
  //   value: 'go:1.20'
  // },
  // {
  //   key: 'GO 1.17',
  //   value: 'go:1.17'
  // },
  // {
  //   key: 'SWIFT 5.4',
  //   value: 'swift:5.4'
  // },
  // {
  //   key: 'SWIFT 5.3',
  //   value: 'swift:5.3'
  // },
  // {
  //   key: 'SWIFT 5.1',
  //   value: 'swift:5.1'
  // },
  // {
  //   key: 'JAVA 8',
  //   value: 'java:8'
  // },
  // {
  //   key: 'RUBY 2.5',
  //   value: 'ruby:2.5'
  // },
  // {
  //   key: 'RUST 1.34',
  //   value: 'rust:1.34'
  // },
  // {
  //   key: 'BLACKBOX',
  //   value: 'blackbox'
  // },
  // {
  //   key: 'SEQUENCE',
  //   value: 'sequence'
  // },
];

export enum ProgrammingLanguage {
  NodeJs_16 = 'nodejs:16',
  NodeJs_18 = 'nodejs:18',
  NodeJs_20 = 'nodejs:20',
  Python_3_12 = 'python:3.12'
}
