<div class="title-wrapper">
  <rappider-paragraph [content]="selectedTemplate?.name"
                      [typography]="selectedTemplateNameConfig.typography"
                      [colorSettings]="selectedTemplateNameConfig.colorSettings">
  </rappider-paragraph>
</div>
<div class="preview-wrapper">
  <div class="preview-title">
    <rappider-paragraph [content]="selectedTemplate?.description"
                        [typography]="selectedTemplateDescriptionConfig.typography"
                        [colorSettings]="selectedTemplateDescriptionConfig.colorSettings">
    </rappider-paragraph>
    <rappider-divider class="rappider-divider"></rappider-divider>
  </div>
  <div class="preview-content-wrapper">
    <div class="preview-content" *ngFor="let thumbnail of selectedTemplate?.thumbnails">
      <rappider-image [source]="thumbnail.url"></rappider-image>
      <div class="paragraphs">
        <rappider-paragraph *ngIf="thumbnail.title"
                            [content]="thumbnail.title"
                            [typography]="thumbnailTitleConfig.typography"
                            [colorSettings]="thumbnailTitleConfig.colorSettings">
        </rappider-paragraph>
        <rappider-paragraph *ngIf="thumbnail.description"
                            [content]="thumbnail.description"
                            [typography]="thumbnailDescriptionConfig.typography"
                            [colorSettings]="thumbnailDescriptionConfig.colorSettings">
        </rappider-paragraph>
      </div>
    </div>
  </div>
</div>
<div class="buttons-wrapper">
  <rappider-button [text]="'&lt; Back'" (click)="backButtonClick()"></rappider-button>

      <rappider-button [text]="'Build with template'"
                       [type]="'primary'"
                      (click)="buildWithTemplateClick()">
      </rappider-button>
</div>
