<rappider-title-toolbar [mainTitle]="titleToolbarConfig?.mainTitle"
                        [options]="titleToolbarConfig?.options">
</rappider-title-toolbar>

<section>
  <rappider-spin [spinning]="loading">
    <rappider-crud-view-edit-form [config]="PROJECT_THEME_CREATE_FORM_CONFIG"
                                  [submitButtonLoading]="submitButtonLoading"
                                  (formSubmit)="createProjectTheme($event)">
    </rappider-crud-view-edit-form>
  </rappider-spin>
</section>