import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RappiderCodeEditorComponent } from './code-editor.component';
import { FormsModule } from '@angular/forms';
import { RappiderButtonModule } from '../button/button.module';
import { RappiderMonacoEditorModule } from '../monaco-editor/monaco-editor.module';


@NgModule({
  declarations: [
    RappiderCodeEditorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RappiderButtonModule,
    RappiderMonacoEditorModule
  ],
  exports: [
    RappiderCodeEditorComponent
  ]
})
export class RappiderCodeEditorModule { }
