import { Validators } from '@angular/forms';
import { ButtonType, CrudFormConfig, CrudViewFormItemType } from '@rappider/rappider-components/utils';
import { TITLE_CASE_DATA_FIELD_NAME_REGEX } from '@rappider/shared/definitions';
import { MONACO_MARKDOWN_CONFIG } from '../monaco-markdown-config';
import { CrudFormInputGroupItem } from 'libs/components/src/lib/utils/edit-form/crud-form-input-group-item.interface';

export enum UIDataStoreTypes {
  Branch = 'branch',
  Root = 'root'
}

export const UI_DATA_STORE_CREATE_OR_EDIT_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormInputGroupItem>{
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewFormItemType.InputGroup,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          type: Validators.pattern(TITLE_CASE_DATA_FIELD_NAME_REGEX),
          errorKey: 'pattern',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.MUST_START_WITH_CAPITAL_LETTER'
        }
      ]
    },
    MONACO_MARKDOWN_CONFIG
  ],
  submitButton: {
    type: ButtonType.Primary
  }
};

