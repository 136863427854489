/* eslint-disable */
import {
  Page,
  DynamicInput,
  ComponentSlot,
  ComponentDefinition,
  ComponentDataSubscription,
  ComponentStyleSheet,
} from '../index';

declare var Object: any;
export interface ComponentInterface {
  id?: string;
  name?: string;
  inputs?: any;
  styles?: string;
  cssStyle?: string;
  cssClass?: string;
  index?: number;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  componentDefinitionId?: string;
  pages?: Page[];
  dynamicInputs?: DynamicInput[];
  childComponents?: Component[];
  componentSlots?: ComponentSlot[];
  componentDefinition?: ComponentDefinition;
  dataSubscriptions?: ComponentDataSubscription[];
  styleSheets?: ComponentStyleSheet[];
}

export class Component implements ComponentInterface {
  'id'?: string;
  'name'?: string;
  'inputs': any;
  'styles': string;
  'cssStyle'?: string;
  'cssClass'?: string;
  'index': number;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  'componentDefinitionId': string;
  pages: Page[];
  dynamicInputs: DynamicInput[];
  childComponents: Component[];
  componentSlots: ComponentSlot[];
  componentDefinition: ComponentDefinition;
  dataSubscriptions: ComponentDataSubscription[];
  styleSheets: ComponentStyleSheet[];
  constructor(data?: ComponentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Component`.
   */
  public static getModelName() {
    return 'Component';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Component for dynamic purposes.
   **/
  public static factory(data: ComponentInterface): Component {
    return new Component(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Component',
      plural: 'Components',
      path: 'components',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        inputs: {
          name: 'inputs',
          type: 'any',
        },
        styles: {
          name: 'styles',
          type: 'string',
        },
        cssStyle: {
          name: 'cssStyle',
          type: 'string',
        },
        cssClass: {
          name: 'cssClass',
          type: 'string',
        },
        index: {
          name: 'index',
          type: 'number',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
        componentDefinitionId: {
          name: 'componentDefinitionId',
          type: 'string',
        },
      },
      relations: {
        pages: {
          name: 'pages',
          type: 'Page[]',
          model: 'Page',
          relationType: 'hasMany',
          modelThrough: 'PageComponent',
          keyThrough: 'pageId',
          keyFrom: 'id',
          keyTo: 'componentId',
        },
        dynamicInputs: {
          name: 'dynamicInputs',
          type: 'DynamicInput[]',
          model: 'DynamicInput',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'pageComponentId',
        },
        childComponents: {
          name: 'childComponents',
          type: 'Component[]',
          model: 'Component',
          relationType: 'hasMany',
          modelThrough: 'ComponentSlot',
          keyThrough: 'childComponentId',
          keyFrom: 'id',
          keyTo: 'parentComponentId',
        },
        componentSlots: {
          name: 'componentSlots',
          type: 'ComponentSlot[]',
          model: 'ComponentSlot',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'parentComponentId',
        },
        componentDefinition: {
          name: 'componentDefinition',
          type: 'ComponentDefinition',
          model: 'ComponentDefinition',
          relationType: 'belongsTo',
          keyFrom: 'componentDefinitionId',
          keyTo: 'id',
        },
        dataSubscriptions: {
          name: 'dataSubscriptions',
          type: 'ComponentDataSubscription[]',
          model: 'ComponentDataSubscription',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'componentId',
        },
        styleSheets: {
          name: 'styleSheets',
          type: 'ComponentStyleSheet[]',
          model: 'ComponentStyleSheet',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'componentId',
        },
      },
    };
  }
}
