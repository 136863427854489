import { createSelector } from '@ngrx/store';
import { DataSchemaWithRelations, ProjectModelField, ProjectModelWithRelations } from '@rappider/rappider-sdk';
import { orderBy } from 'lodash';
import { getDataSchemasWithDetailsSelector } from '../../../states/selectors/get-data-schemas-with-details.selector';

export const getProjectModelsWithFieldsAndSchemas = createSelector(
  state => state['projectModel']?.data,
  state => state['projectModelField']?.data,
  state => state['projectModelField']?.isLoaded,
  state => state['projectModel']?.isLoaded,
  getDataSchemasWithDetailsSelector,
  state => state['dataSchema']?.isLoaded,
  (
    projectModels: ProjectModelWithRelations[],
    projectModelFields: ProjectModelField[],
    isProjectModelsLoaded: boolean,
    isProjectModelFieldsLoaded: boolean,
    dataSchemas: DataSchemaWithRelations[],
    isDataSchemasLoaded: boolean
  ) => {
    if (isProjectModelFieldsLoaded && isProjectModelsLoaded && isDataSchemasLoaded) {
      const projectModelWithFields = projectModels.map(projectModel => (
        {
          ...projectModel,
          fields: orderBy(projectModelFields.filter(projectModelField => projectModelField.projectModelId === projectModel.id), 'index', 'asc')
        }
      ));
      const projectModelWithFieldsAndSchemas = {
        projectModels: projectModelWithFields,
        dataSchemas: dataSchemas
      };
      return projectModelWithFieldsAndSchemas;
    }
  }
);
