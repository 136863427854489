/* eslint-disable */

declare var Object: any;
export interface EnvironmentVariableInterface {
  id?: string;
  environmentKey: string;
  key: string;
  value?: any;
  projectId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
}

export class EnvironmentVariable implements EnvironmentVariableInterface {
  'id'?: string;
  'environmentKey': string;
  'key': string;
  'value': any;
  'projectId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  constructor(data?: EnvironmentVariableInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `EnvironmentVariable`.
   */
  public static getModelName() {
    return 'EnvironmentVariable';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of EnvironmentVariable for dynamic purposes.
   **/
  public static factory(
    data: EnvironmentVariableInterface
  ): EnvironmentVariable {
    return new EnvironmentVariable(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'EnvironmentVariable',
      plural: 'EnvironmentVariables',
      path: 'environment-variables',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        environmentKey: {
          name: 'environmentKey',
          type: 'string',
        },
        key: {
          name: 'key',
          type: 'string',
        },
        value: {
          name: 'value',
          type: 'any',
        },
        projectId: {
          name: 'projectId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
      },
      relations: {},
    };
  }
}
