import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ButtonComponentConfig, ButtonType } from '@rappider/rappider-components/utils';
import { cloneDeep } from 'lodash';
import { SchemaTreeService } from '../../../services/schema-tree/schema-tree.service';
import { SchemaTreeSelectComponent } from '../../shared-components/schema-tree-select/schema-tree-select.component';
import { JoyrideService } from 'ngx-joyride';

@Component({
  selector: 'rappider-sort-row-element',
  templateUrl: './sort-row-element.component.html',
  styleUrls: ['./sort-row-element.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => SortRowElementComponent),
      multi: true
    }
  ]
})
export class SortRowElementComponent implements ControlValueAccessor, OnChanges {

  @ViewChild('treeSelectComponent') treeSelectComponent: SchemaTreeSelectComponent;

  @Input() tree;
  @Input() sortStepsSelectOptFlag: boolean;

  @Output() showStepsFlag = new EventEmitter<{ flag: boolean; key: string }>();

  treeSelectFlag = false;

  localValue = [];
  directionOptions = [
    {
      key: 'DESC',
      value: 'desc'
    },
    {
      key: 'ASC',
      value: 'asc'
    }
  ];

  addFieldButton: ButtonComponentConfig = {
    text: 'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_LIST_COMPONENT.ADD_SORT_ELEMENT',
    type: ButtonType.Default,
    icon: {
      name: 'fas fa-plus'
    }
  };

  _value;

  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
    this.onChange(this._value);
    this.onTouched();
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value): void {
    this._value = value;
    this.setValueToLocalVariable(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  constructor(private schemaTreeService: SchemaTreeService,
    private readonly joyrideService: JoyrideService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sortStepsSelectOptFlag) {
      this.showSteps();
    }
  }

  showSteps() {
    if (this.sortStepsSelectOptFlag && (this.value == null || this.value.length === 0)) {
      this.joyrideService.startTour(
        { steps: ['sortSecondStep'] }
      );
      this.sortStepsSelectOptFlag = false;
      this.showStepsFlag.emit({ flag: this.sortStepsSelectOptFlag, key: 'sort-select-opt' });
    }
    if (this.value?.length > 0) {
      this.joyrideService.startTour(
        { steps: ['sortThirdStep', 'sortFourthStep', 'sortFifthStep'] }
      );
      this.sortStepsSelectOptFlag = false;
      this.showStepsFlag.emit({ flag: this.sortStepsSelectOptFlag, key: 'sort-select-opt' });
    }
  }

  setValueToLocalVariable(value) {
    this.localValue = cloneDeep(value);
    this.localValue?.forEach(element => {
      element.sourceField = element.sourceField?.map(sourceField => sourceField.name).join('.');
    });
  }

  removeField(deletedElementIndex) {
    if (this.localValue.length > 1) {
      this.localValue.splice(deletedElementIndex, 1);
      this.value.splice(deletedElementIndex, 1);
    }
  }

  addField() {
    const newElement = {
      direction: null,
      sourceField: []
    };
    this.localValue = [
      ...this.localValue,
      newElement
    ];
    this.value = [
      ...this.value,
      { ...newElement }
    ];

    if (this.value.length > 0 && this.sortStepsSelectOptFlag) {
      this.joyrideService.startTour(
        { steps: ['sortThirdStep', 'sortFourthStep', 'sortFifthStep'] }
      );
      this.sortStepsSelectOptFlag = false;
      this.showStepsFlag.emit({ flag: this.sortStepsSelectOptFlag, key: 'sort-select-opt' });
    }
  }

  onSourceFieldChange(event, index) {
    this.value[index].sourceField = this.schemaTreeService.getFieldTreeFromField(event, this.treeSelectComponent);
  }

  onDirectionChange(event, index) {
    this.value[index].direction = event;
  }

}
