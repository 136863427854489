import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RappiderEditFormModule, RappiderHeadingModule } from '@rappider/rappider-components';
import { WorkflowEventWrapperComponent } from './components/workflow-event-wrapper/workflow-event-wrapper.component';
import { WorkflowEventCreateComponent } from './components/workflow-event-create/workflow-event-create.component';
import { WorkflowEventEditComponent } from './components/workflow-event-edit/workflow-event-edit.component';

@NgModule({
  declarations: [
    WorkflowEventCreateComponent,
    WorkflowEventWrapperComponent,
    WorkflowEventEditComponent
  ],
  imports: [
    CommonModule,
    RappiderHeadingModule,
    RappiderEditFormModule
  ],
  exports: [
    WorkflowEventCreateComponent,
    WorkflowEventWrapperComponent,
    WorkflowEventEditComponent
  ],
})
export class WorkflowEventModule { }
