import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RappiderStepsComponent } from './steps.component';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { RappiderButtonModule } from '../button/button.module';
import { RappiderIconModule } from '../icon/icon.module';

@NgModule({
  declarations: [
    RappiderStepsComponent
  ],
  imports: [
    CommonModule,
    NzStepsModule,
    RappiderButtonModule,
    RappiderIconModule
  ],
  exports: [
    RappiderStepsComponent
  ]
})
export class RappiderStepsModule { }
