import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { WorkflowStepFunctionPublishedEventOnSuccess, WorkflowStepFunctionPublishedEventOnSuccessControllerService, WorkflowStepFunctionPublishedEventOnSuccessWithRelations } from '@rappider/rappider-sdk';
import { NotificationService } from '@rappider/services';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import * as WorkflowStepFunctionPublishedEventOnSuccessActions from './workflow-step-function-published-event-on-success.actions';
import * as WorkflowStepFunctionActions from '../workflow-step-function/workflow-step-function.actions';

@Injectable()
export class WorkflowStepFunctionPublishedEventOnSuccessEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private notificationService: NotificationService,
    private workflowStepFunctionPublishedEventOnSuccessApi: WorkflowStepFunctionPublishedEventOnSuccessControllerService,
  ) { }

  createWorkflowStepFunctionPublishedEventOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowStepFunctionPublishedEventOnSuccessActions.CreateWorkflowStepFunctionPublishedEventOnSuccess
      ),
      mergeMap((action) => {
        const params = {
          body: action.payload.workflowStepFunctionPublishedEventOnSuccess
        };

        return this.workflowStepFunctionPublishedEventOnSuccessApi.create(params).pipe(
          map((workflowStepFunctionPublishedEventOnSuccess: WorkflowStepFunctionPublishedEventOnSuccess) => {
            this.notificationService.createNotification(
              'success',
              'success',
              'SHARED.SUCCESSFULLY_CREATED'
            );
            return WorkflowStepFunctionPublishedEventOnSuccessActions.CreateWorkflowStepFunctionPublishedEventOnSuccessSuccessful({ payload: { workflowStepFunctionPublishedEventOnSuccess } });
          }), catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'SHARED.COULDNT_CREATED'
            );
            return [
              WorkflowStepFunctionPublishedEventOnSuccessActions.CreateWorkflowStepFunctionPublishedEventOnSuccessFailure({ payload: { error, key: 'CreateWorkflowStepFunctionPublishedEventOnSuccessFailure', timestamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

  getWorkflowStepFunctionPublishedEventOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowStepFunctionActions.GetWorkflowStepFunctionSuccessful
      ),
      withLatestFrom(
        this.store.select(state => state.workflowStepFunction?.data)
      ),
      mergeMap(([action, workflowStepFunction]) => {
        const workflowStepFunctionIds = action.payload.workflowStepFunctions.map(workflowStepFunctionItem => workflowStepFunctionItem.id);
        const params = {
          filter: {
            where: {
              workflowStepFunctionId: { inq: workflowStepFunctionIds }
            }
          }
        };
        return this.workflowStepFunctionPublishedEventOnSuccessApi.find(params).pipe(
          map((workflowStepFunctionPublishedEventOnSuccesss: WorkflowStepFunctionPublishedEventOnSuccessWithRelations[]) =>
            WorkflowStepFunctionPublishedEventOnSuccessActions.GetWorkflowStepFunctionPublishedEventOnSuccessSuccessful({ payload: { workflowStepFunctionPublishedEventOnSuccesss } })
          ),
          catchError((error) => [
            WorkflowStepFunctionPublishedEventOnSuccessActions.GetWorkflowStepFunctionPublishedEventOnSuccessFailure({ payload: { error, key: 'GetWorkflowStepFunctionPublishedEventOnSuccessFailure', timestamp: Date.now() } })
          ])
        );
      })
    )
  );

  updateWorkflowStepFunctionPublishedEventOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowStepFunctionPublishedEventOnSuccessActions.UpdateWorkflowStepFunctionPublishedEventOnSuccess
      ),
      mergeMap((action) => {
        const params = {
          id: action.payload.workflowStepFunctionPublishedEventOnSuccessId,
          body: action.payload.workflowStepFunctionPublishedEventOnSuccessBody
        };

        return this.workflowStepFunctionPublishedEventOnSuccessApi.updateById(params).pipe(
          map(() => {
            this.notificationService.createNotification(
              'success',
              'success',
              'SHARED.SUCCESSFULLY_UPDATED'
            );
            const workflowStepFunctionPublishedEventOnSuccessBody = {
              ...action.payload.workflowStepFunctionPublishedEventOnSuccessBody
            } as WorkflowStepFunctionPublishedEventOnSuccessWithRelations;
            return WorkflowStepFunctionPublishedEventOnSuccessActions.UpdateWorkflowStepFunctionPublishedEventOnSuccessSuccessful({ payload: { workflowStepFunctionPublishedEventOnSuccessId: action.payload.workflowStepFunctionPublishedEventOnSuccessId, workflowStepFunctionPublishedEventOnSuccessBody } });
          }), catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'SHARED.COULDNT_UPDATED'
            );
            return [
              WorkflowStepFunctionPublishedEventOnSuccessActions.UpdateWorkflowStepFunctionPublishedEventOnSuccessFailure({ payload: { error, key: 'UpdateWorkflowStepFunctionPublishedEventOnSuccessFailure', timestamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

  deleteWorkflowStepFunctionPublishedEventOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowStepFunctionPublishedEventOnSuccessActions.DeleteWorkflowStepFunctionPublishedEventOnSuccess
      ),
      mergeMap((action) => {
        const params = {
          id: action.payload.workflowStepFunctionPublishedEventOnSuccessId
        };
        return this.workflowStepFunctionPublishedEventOnSuccessApi.deleteById(params).pipe(
          map(() => {
            this.notificationService.createNotification(
              'success',
              'SHARED.SUCCESS',
              'SHARED.SUCCESSFULLY_DELETED'
            );
            return WorkflowStepFunctionPublishedEventOnSuccessActions.DeleteWorkflowStepFunctionPublishedEventOnSuccessSuccessful({ payload: { workflowStepFunctionPublishedEventOnSuccessId: action.payload.workflowStepFunctionPublishedEventOnSuccessId } });
          }, catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'SHARED.COULDNT_DELETED'
            );
            return [
              WorkflowStepFunctionPublishedEventOnSuccessActions.DeleteWorkflowStepFunctionPublishedEventOnSuccessFailure({ payload: { error, key: 'DeleteWorkflowStepFunctionPublishedEventOnSuccessFailure', timestamp: Date.now() } })
            ];
          }))
        );
      })
    )
  );
}
