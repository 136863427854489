import { createAction, props } from '@ngrx/store';
import { NewRoleByAdmin, Role, RolePartialByAdmin, RoleWithRelations } from '@rappider/rappider-sdk';

export enum ActionTypes {
  GetRoles = '[Roles] GetRoles',
  GetRolesSuccessful = '[Roles] GetRolesSuccessful',
  GetRolesFailure = '[Roles] GetRolesFailure',
  CreateRole = '[Roles] CreateRole',
  CreateRoleSuccessful = '[Roles] CreateRoleSuccessful',
  CreateRoleFailure = '[Roles] CreateRoleFailure',
  UpdateRole = '[Roles] UpdateRole',
  UpdateRoleSuccessful = '[Roles] UpdateRoleSuccessful',
  UpdateRoleFailure = '[Roles] UpdateRoleFailure',
  DeleteRole = '[Roles] DeleteRole',
  DeleteRoleSuccessful = '[Roles] DeleteRoleSuccessful',
  DeleteRoleFailure = '[Roles] DeleteRoleFailure',
  ChangeRoleModalVisibility = '[Roles] ChangeRoleModalVisibility',
}

/* get */
export const GetRoles = createAction(ActionTypes.GetRoles);

export const GetRolesSuccessful = createAction(
  ActionTypes.GetRolesSuccessful,
  props<{ roles: RoleWithRelations[] }>()
);

export const GetRolesFailure = createAction(
  ActionTypes.GetRolesFailure,
  props<{ error: any; key: string; timestamp: number }>()
);

/* create */
export const CreateRole = createAction(
  ActionTypes.CreateRole,
  props<{ role: NewRoleByAdmin }>()
);

export const CreateRoleSuccessful = createAction(
  ActionTypes.CreateRoleSuccessful,
  props<{ role: Role }>()
);

export const CreateRoleFailure = createAction(
  ActionTypes.CreateRoleFailure,
  props<{ error: any; key: string; timestamp: number }>()
);

/* update */
export const UpdateRole = createAction(
  ActionTypes.UpdateRole,
  props<{ id: string; role: RolePartialByAdmin }>()
);

export const UpdateRoleSuccessful = createAction(
  ActionTypes.UpdateRoleSuccessful,
  props<{ id: string; role: RolePartialByAdmin }>()
);

export const UpdateRoleFailure = createAction(
  ActionTypes.UpdateRoleFailure,
  props<{ error: any; key: string; timestamp: number }>()
);

/* delete */
export const DeleteRole = createAction(
  ActionTypes.DeleteRole,
  props<{ id: string }>()
);

export const DeleteRoleSuccessful = createAction(
  ActionTypes.DeleteRoleSuccessful,
  props<{ id: string }>()
);

export const DeleteRoleFailure = createAction(
  ActionTypes.DeleteRoleFailure,
  props<{ error: any; key: string; timestamp: number }>()
);

/* modal visibility */
export const ChangeRoleModalVisibility = createAction(
  ActionTypes.ChangeRoleModalVisibility,
  props<{ visibility: boolean }>()
);
