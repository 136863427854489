/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AssignProjectManagerAuthorityToPersonDto } from '../models/assign-project-manager-authority-to-person-dto';
import { ChangePersonRolesByAdminRequestDto } from '../models/change-person-roles-by-admin-request-dto';
import { NewRoleMapping } from '../models/new-role-mapping';
import { RoleMapping } from '../models/role-mapping';
import { RoleMappingPartial } from '../models/role-mapping-partial';
import { RoleMappingWithRelations } from '../models/role-mapping-with-relations';
import { UnassignProjectManagerAuthorityFromPersonDto } from '../models/unassign-project-manager-authority-from-person-dto';

@Injectable({
  providedIn: 'root',
})
export class RoleMappingControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation roleMappingControllerUpdatePersonRoleAssignmentsByAdmin
   */
  static readonly RoleMappingControllerUpdatePersonRoleAssignmentsByAdminPath = '/admin/role-mappings/assign/{personId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePersonRoleAssignmentsByAdmin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePersonRoleAssignmentsByAdmin$Response(params: {
    personId: string;
    body?: ChangePersonRolesByAdminRequestDto
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, RoleMappingControllerService.RoleMappingControllerUpdatePersonRoleAssignmentsByAdminPath, 'patch');
    if (params) {
      rb.path('personId', params.personId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePersonRoleAssignmentsByAdmin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePersonRoleAssignmentsByAdmin(params: {
    personId: string;
    body?: ChangePersonRolesByAdminRequestDto
  }): Observable<any> {

    return this.updatePersonRoleAssignmentsByAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation roleMappingControllerAssignProjectManagerAuthorityToPerson
   */
  static readonly RoleMappingControllerAssignProjectManagerAuthorityToPersonPath = '/role-mappings/assign-project-manager-authority-to-person';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignProjectManagerAuthorityToPerson()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignProjectManagerAuthorityToPerson$Response(params?: {
    body?: AssignProjectManagerAuthorityToPersonDto
  }): Observable<StrictHttpResponse<RoleMapping>> {

    const rb = new RequestBuilder(this.rootUrl, RoleMappingControllerService.RoleMappingControllerAssignProjectManagerAuthorityToPersonPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RoleMapping>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `assignProjectManagerAuthorityToPerson$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  assignProjectManagerAuthorityToPerson(params?: {
    body?: AssignProjectManagerAuthorityToPersonDto
  }): Observable<RoleMapping> {

    return this.assignProjectManagerAuthorityToPerson$Response(params).pipe(
      map((r: StrictHttpResponse<RoleMapping>) => r.body as RoleMapping)
    );
  }

  /**
   * Path part for operation roleMappingControllerUnassignProjectManagerAuthorityFromPerson
   */
  static readonly RoleMappingControllerUnassignProjectManagerAuthorityFromPersonPath = '/role-mappings/unassign-project-manager-authority-to-person';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unassignProjectManagerAuthorityFromPerson()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unassignProjectManagerAuthorityFromPerson$Response(params?: {
    body?: UnassignProjectManagerAuthorityFromPersonDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RoleMappingControllerService.RoleMappingControllerUnassignProjectManagerAuthorityFromPersonPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unassignProjectManagerAuthorityFromPerson$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  unassignProjectManagerAuthorityFromPerson(params?: {
    body?: UnassignProjectManagerAuthorityFromPersonDto
  }): Observable<void> {

    return this.unassignProjectManagerAuthorityFromPerson$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation roleMappingControllerSoftDeleteById
   */
  static readonly RoleMappingControllerSoftDeleteByIdPath = '/role-mappings/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `softDeleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  softDeleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RoleMappingControllerService.RoleMappingControllerSoftDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `softDeleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  softDeleteById(params: {
    id: string;
  }): Observable<void> {

    return this.softDeleteById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation roleMappingControllerUpdateById
   */
  static readonly RoleMappingControllerUpdateByIdPath = '/role-mappings/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    body?: RoleMappingPartial
  }): Observable<StrictHttpResponse<RoleMapping>> {

    const rb = new RequestBuilder(this.rootUrl, RoleMappingControllerService.RoleMappingControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RoleMapping>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    body?: RoleMappingPartial
  }): Observable<RoleMapping> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<RoleMapping>) => r.body as RoleMapping)
    );
  }

  /**
   * Path part for operation roleMappingControllerFind
   */
  static readonly RoleMappingControllerFindPath = '/role-mappings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<RoleMappingWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, RoleMappingControllerService.RoleMappingControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RoleMappingWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<RoleMappingWithRelations>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RoleMappingWithRelations>>) => r.body as Array<RoleMappingWithRelations>)
    );
  }

  /**
   * Path part for operation roleMappingControllerCreate
   */
  static readonly RoleMappingControllerCreatePath = '/role-mappings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    body?: NewRoleMapping
  }): Observable<StrictHttpResponse<RoleMapping>> {

    const rb = new RequestBuilder(this.rootUrl, RoleMappingControllerService.RoleMappingControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RoleMapping>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    body?: NewRoleMapping
  }): Observable<RoleMapping> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<RoleMapping>) => r.body as RoleMapping)
    );
  }

}
