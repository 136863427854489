import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ButtonType, IconType } from '@rappider/rappider-components/utils';
import { NewProjectRequirement, ProjectRequirement } from '@rappider/rappider-sdk';
import { Subscription } from 'rxjs';
import { CreateProjectRequirement, ToggleProjectRequirementsVisibility } from '../../states/project-requirements/project-requirements.actions';
import { ProjectRequirementStatus } from './models/project-requirement-status.enum';

@Component({
  selector: 'rappider-project-requirements',
  templateUrl: './project-requirements.component.html',
  styleUrls: ['./project-requirements.component.scss']
})
export class ProjectRequirementsComponent implements OnInit, OnDestroy {

  @Input() redirectButtonVisibility = true;
  @Input() detailVisibility = false;

  projectRequirements: ProjectRequirement[];
  visibility = false;
  newProjectRequirement: string;
  newProjectRequirementButton = {
    icon: {
      name: 'plus',
      type: IconType.NgZorro
    },
    type: ButtonType.Default
  };
  ProjectRequirementStatus = ProjectRequirementStatus;

  subscriptions: Subscription[];

  constructor(private store: Store<any>, private router: Router) { }

  ngOnInit(): void {
    this.subscriptions = [
      this.subscribeToProjectRequirementVisibility(),
      this.subscribeToProjectRequirements()
    ];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToProjectRequirementVisibility() {
    return this.store.select(state => state.projectRequirements?.projectRequirementsVisibility)
      .subscribe(visibility => this.visibility = visibility);
  }

  subscribeToProjectRequirements() {
    return this.store.select(state => state.projectRequirements?.data)
      .subscribe(projectRequirements => this.projectRequirements = projectRequirements);
  }

  onClose() {
    this.store.dispatch(ToggleProjectRequirementsVisibility());
  }

  onProjectRequirementActionButtonClick(projectRequirementAction) {
    if (projectRequirementAction.redirectUrl) {
      this.router.navigate([projectRequirementAction.redirectUrl]);
    }
  }

  createNewProjectRequirementButtonClick() {
    const projectRequirement: NewProjectRequirement = {
      title: this.newProjectRequirement,
      index: this.projectRequirements.length + 1,
      status: ProjectRequirementStatus.InProgress
    };
    this.store.dispatch(CreateProjectRequirement({ payload: { projectRequirement } }));
    this.newProjectRequirement = null;
  }

  redirectRequirementsDetailPage() {
    this.router.navigateByUrl('/project-requirements');
  }
}
