export const SOURCE_TYPE_OPTIONS = [
  {
    key: 'File',
    value: 'file'
  },
  {
    key: 'Custom',
    value: 'custom'
  }
];
