import { Validators } from '@angular/forms';
import {
  CodeMirrorMode,
  CrudFormCodeMirrorItem,
  CrudFormConfig,
  CrudFormSelectItem,
  CrudViewFormItemType,
  SelectMode
} from '@rappider/rappider-components/utils';
import { CAMEL_CASE_REGEX } from '../../../definitions/regex';

export const UI_STEP_FUNCTION_CREATE_OR_EDIT_CONFIG: CrudFormConfig = {
  items: [
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewFormItemType.TextBox,
      index: 0,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          type: Validators.pattern(CAMEL_CASE_REGEX),
          errorKey: 'pattern',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.MUST_START_WITH_LOWERCASE_LETTER'
        }
      ]
    },
    {
      title: 'SHARED.TYPE',
      fieldName: 'integrationOperatorType',
      type: CrudViewFormItemType.Select,
      index: 1,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    <CrudFormSelectItem>
    {
      title: 'PROJECT_MODULE.UI_STEP_FUNCTION_COMPONENT.SUBSCRIBED_EVENTS',
      fieldName: 'subscribedEvents',
      type: CrudViewFormItemType.Select,
      index: 2,
      settings: {
        mode: SelectMode.Multiple
      },
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    <CrudFormSelectItem>
    {
      title: 'PROJECT_MODULE.UI_STEP_FUNCTION_COMPONENT.PUBLISHED_EVENTS_ON_SUCCESS',
      fieldName: 'publishedEventsOnSuccess',
      type: CrudViewFormItemType.Select,
      index: 3,
      settings: {
        mode: SelectMode.Multiple
      }
    },
    <CrudFormSelectItem>
    {
      title: 'PROJECT_MODULE.UI_STEP_FUNCTION_COMPONENT.PUBLISHED_EVENTS_ON_FAILURE',
      fieldName: 'publishedEventsOnFailure',
      type: CrudViewFormItemType.Select,
      index: 4,
      settings: {
        mode: SelectMode.Multiple
      }
    },
    <CrudFormSelectItem>
    {
      title: 'PROJECT_MODULE.UI_STEP_FUNCTION_CREATE_OR_EDIT_COMPONENT.PROJECT_MODEL_FOR_ENDPOINT',
      fieldName: 'projectModel',
      type: CrudViewFormItemType.Select,
      index: 5,
    },
    <CrudFormCodeMirrorItem>
    {
      title: 'SHARED.CODE',
      fieldName: 'code',
      type: CrudViewFormItemType.CodeMirror,
      settings: {
        mode: CodeMirrorMode.Javascript,
        lineNumbers: true,
        autoCloseBrackets: true
      }
    }
  ],
  itemSettings: {
    inputComponentSize: {
      xs: 24
    }
  }
};
