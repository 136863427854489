import { Validators } from '@angular/forms';
import { ButtonType, CodeMirrorMode, CrudFormCodeMirrorItem, CrudFormConfig, CrudFormInlineRowFormItem, CrudFormLabelFunctionItem, CrudFormMonacoCodeEditorItem, CrudFormRowFormItem, CrudFormSelectItem, CrudViewFormItemType } from '@rappider/rappider-components/utils';
import { DATA_FIELD_NAME_REGEX, KEBAB_CASE_REGEX } from '@rappider/shared/definitions';
import { CUSTOM_ENDPOINT_PARAMS_AND_QUERY_PARAMS_ROW_FORM_CONFIG } from './custom-endpoint-query-params-row-form.config';
import { MOCK_RESPONSE_FORM_ITEM } from './mock-response-form-item.config';

export const CUSTOM_ENDPOINT_CREATE_OR_EDIT_CONFIG: CrudFormConfig = {
  items: [
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      showRequiredSign: true,
      type: CrudViewFormItemType.TextBox,
      index: 1,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          type: Validators.pattern(DATA_FIELD_NAME_REGEX),
          errorKey: 'pattern',
          errorMessage: 'PROJECT_MODULE.CUSTOM_ENDPOINT_CREATE_OR_EDIT_COMPONENT.ENDPOINT_NAME_PATTERN_ERROR'
        }
      ]
    },
    <CrudFormSelectItem>
    {
      title: 'PROJECT_MODULE.CUSTOM_ENDPOINT_CREATE_OR_EDIT_COMPONENT.HTTP_METHOD',
      fieldName: 'method',
      type: CrudViewFormItemType.Select,
      showRequiredSign: true,
      index: 2,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    {
      title: 'SHARED.PATH',
      fieldName: 'path',
      type: CrudViewFormItemType.TextBox,
      index: 3,
      validators: [
        {
          type: Validators.pattern(KEBAB_CASE_REGEX),
          errorKey: 'pattern',
          errorMessage: 'PROJECT_MODULE.CUSTOM_ENDPOINT_CREATE_OR_EDIT_COMPONENT.ENDPOINT_PATH_PATTERN_ERROR'
        }
      ]
    },
    <CrudFormInlineRowFormItem>
    {
      title: 'PROJECT_MODULE.CUSTOM_ENDPOINT_CREATE_OR_EDIT_COMPONENT.PATH_PARAMS',
      fieldName: 'params',
      index: 4,
      columns: CUSTOM_ENDPOINT_PARAMS_AND_QUERY_PARAMS_ROW_FORM_CONFIG,
      type: CrudViewFormItemType.InlineRowForm,
      orderNumbersVisibility: true
    },
    <CrudFormInlineRowFormItem>
    {
      title: 'PROJECT_MODULE.CUSTOM_ENDPOINT_CREATE_OR_EDIT_COMPONENT.QUERY_PARAMS',
      fieldName: 'queryParams',
      index: 5,
      columns: CUSTOM_ENDPOINT_PARAMS_AND_QUERY_PARAMS_ROW_FORM_CONFIG,
      type: CrudViewFormItemType.InlineRowForm,
      orderNumbersVisibility: true
    },
    <CrudFormLabelFunctionItem>
    {
      title: 'PROJECT_MODULE.CUSTOM_ENDPOINT_CREATE_OR_EDIT_COMPONENT.PATH_PREVIEW',
      fieldName: 'pathPreview',
      type: CrudViewFormItemType.LabelFunction,
      index: 6
    },
    {
      title: 'Authentication Required',
      fieldName: 'isAuthenticationRequired',
      type: CrudViewFormItemType.Switch,
      index: 7
    },
    <CrudFormMonacoCodeEditorItem>{
      title: 'Description',
      type: CrudViewFormItemType.MonacoCodeEditor,
      fieldName: 'description',
      showRequiredSign: false,
      editorOptions:
      {
        theme: 'vs',
        language: 'markdown',
        lineNumbers: { renderType: 0 },
        minimap: {
          enabled: false,
        },
      },
      sizeSettings: {
        minHeight: '200px'
      },
      index: 8
    },
    // <CrudFormMonacoCodeEditorItem>
    // {
    //   title: 'SHARED.CODE',
    //   fieldName: 'code',
    //   type: CrudViewFormItemType.MonacoCodeEditor,
    //   autoFormat: true,
    //   sizeSettings: {
    //     minHeight: '300px'
    //   },
    //   editorOptions: {
    //     theme: 'vs-dark',
    //     language: 'javascript'
    //   }
    // },
    {
      title: 'PROJECT_MODULE.CUSTOM_ENDPOINT_CREATE_OR_EDIT_COMPONENT.IS_MOCKED',
      fieldName: 'isMocked',
      type: CrudViewFormItemType.Switch
    },
    // MOCK_RESPONSE_FORM_ITEM,
    // <CrudFormMonacoCodeEditorItem>
    // {
    //   title: 'PROJECT_MODULE.CUSTOM_ENDPOINT_CREATE_OR_EDIT_COMPONENT.RESPONSE_DATA_SAMPLE',
    //   fieldName: 'responseData',
    //   type: CrudViewFormItemType.MonacoCodeEditor,
    //   autoFormat: true,
    //   sizeSettings: {
    //     minHeight: '300px'
    //   },
    //   editorOptions: {
    //     theme: 'vs-dark',
    //     language: 'json'
    //   }
    // },
    // <CrudFormMonacoCodeEditorItem>
    // {
    //   title: 'PROJECT_MODULE.CUSTOM_ENDPOINT_CREATE_OR_EDIT_COMPONENT.REQUEST_DATA_SAMPLE',
    //   fieldName: 'requestData',
    //   type: CrudViewFormItemType.MonacoCodeEditor,
    //   autoFormat: true,
    //   sizeSettings: {
    //     minHeight: '300px'
    //   },
    //   editorOptions: {
    //     theme: 'vs-dark',
    //     language: 'json'
    //   }
    // }
  ],
  itemSettings: {
    inputComponentSize: {
      xs: 24
    }
  },
  submitButton: {
    type: ButtonType.Primary,
    text: 'Save',
    visible: false
  }
};
