/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Comment } from '../models/comment';
import { CommentPartial } from '../models/comment-partial';
import { LastProcessInformation } from '../models/last-process-information';
import { NewCommentInUiDataEvent } from '../models/new-comment-in-ui-data-event';
import { NewUiDataEvent } from '../models/new-ui-data-event';
import { UiDataEvent } from '../models/ui-data-event';
import { UiDataEventDuplicateWorkfloFromIdsDto } from '../models/ui-data-event-duplicate-workflo-from-ids-dto';
import { UiDataEventDuplicateWorkflowDto } from '../models/ui-data-event-duplicate-workflow-dto';
import { UiDataEventDuplicateWorkflowFromIdsResponseDto } from '../models/ui-data-event-duplicate-workflow-from-ids-response-dto';
import { UiDataEventPartial } from '../models/ui-data-event-partial';
import { UiDataEventWithRelations } from '../models/ui-data-event-with-relations';
import { UiWorkflowStepFunction } from '../models/ui-workflow-step-function';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class UiDataEventControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation uiDataEventControllerCount
   */
  static readonly UiDataEventControllerCountPath = '/ui-data-events/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params?: {
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataEventControllerService.UiDataEventControllerCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params?: {
    where?: any;
  }): Observable<LoopbackCount> {

    return this.count$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation uiDataEventControllerDuplicateWorkflow
   */
  static readonly UiDataEventControllerDuplicateWorkflowPath = '/ui-data-events/duplicate-workflow';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `duplicateWorkflow()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  duplicateWorkflow$Response(params?: {
    body?: UiDataEventDuplicateWorkflowDto
  }): Observable<StrictHttpResponse<UiWorkflowStepFunction>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataEventControllerService.UiDataEventControllerDuplicateWorkflowPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UiWorkflowStepFunction>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `duplicateWorkflow$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  duplicateWorkflow(params?: {
    body?: UiDataEventDuplicateWorkflowDto
  }): Observable<UiWorkflowStepFunction> {

    return this.duplicateWorkflow$Response(params).pipe(
      map((r: StrictHttpResponse<UiWorkflowStepFunction>) => r.body as UiWorkflowStepFunction)
    );
  }

  /**
   * Path part for operation uiDataEventControllerDuplicateWorkflowWithIds
   */
  static readonly UiDataEventControllerDuplicateWorkflowWithIdsPath = '/ui-data-events/duplicate-workflow-from-ids';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `duplicateWorkflowWithIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  duplicateWorkflowWithIds$Response(params?: {
    body?: UiDataEventDuplicateWorkfloFromIdsDto
  }): Observable<StrictHttpResponse<UiDataEventDuplicateWorkflowFromIdsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataEventControllerService.UiDataEventControllerDuplicateWorkflowWithIdsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UiDataEventDuplicateWorkflowFromIdsResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `duplicateWorkflowWithIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  duplicateWorkflowWithIds(params?: {
    body?: UiDataEventDuplicateWorkfloFromIdsDto
  }): Observable<UiDataEventDuplicateWorkflowFromIdsResponseDto> {

    return this.duplicateWorkflowWithIds$Response(params).pipe(
      map((r: StrictHttpResponse<UiDataEventDuplicateWorkflowFromIdsResponseDto>) => r.body as UiDataEventDuplicateWorkflowFromIdsResponseDto)
    );
  }

  /**
   * Path part for operation uiDataEventControllerFindLatestChangeTimestamps
   */
  static readonly UiDataEventControllerFindLatestChangeTimestampsPath = '/ui-data-events/latest-change-timestamps';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findLatestChangeTimestamps()` instead.
   *
   * This method doesn't expect any request body.
   */
  findLatestChangeTimestamps$Response(params?: {
  }): Observable<StrictHttpResponse<LastProcessInformation>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataEventControllerService.UiDataEventControllerFindLatestChangeTimestampsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LastProcessInformation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findLatestChangeTimestamps$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findLatestChangeTimestamps(params?: {
  }): Observable<LastProcessInformation> {

    return this.findLatestChangeTimestamps$Response(params).pipe(
      map((r: StrictHttpResponse<LastProcessInformation>) => r.body as LastProcessInformation)
    );
  }

  /**
   * Path part for operation uiDataEventControllerFindStarterTemplateEvents
   */
  static readonly UiDataEventControllerFindStarterTemplateEventsPath = '/ui-data-events/starter-template-events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findStarterTemplateEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  findStarterTemplateEvents$Response(params?: {
  }): Observable<StrictHttpResponse<Array<UiDataEvent>>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataEventControllerService.UiDataEventControllerFindStarterTemplateEventsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UiDataEvent>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findStarterTemplateEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findStarterTemplateEvents(params?: {
  }): Observable<Array<UiDataEvent>> {

    return this.findStarterTemplateEvents$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UiDataEvent>>) => r.body as Array<UiDataEvent>)
    );
  }

  /**
   * Path part for operation uiDataEventControllerFindComments
   */
  static readonly UiDataEventControllerFindCommentsPath = '/ui-data-events/{id}/comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findComments()` instead.
   *
   * This method doesn't expect any request body.
   */
  findComments$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<Array<Comment>>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataEventControllerService.UiDataEventControllerFindCommentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Comment>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findComments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findComments(params: {
    id: string;
    filter?: any;
  }): Observable<Array<Comment>> {

    return this.findComments$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Comment>>) => r.body as Array<Comment>)
    );
  }

  /**
   * Path part for operation uiDataEventControllerCreateComment
   */
  static readonly UiDataEventControllerCreateCommentPath = '/ui-data-events/{id}/comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createComment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createComment$Response(params: {
    id: string;
    body?: NewCommentInUiDataEvent
  }): Observable<StrictHttpResponse<Comment>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataEventControllerService.UiDataEventControllerCreateCommentPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Comment>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createComment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createComment(params: {
    id: string;
    body?: NewCommentInUiDataEvent
  }): Observable<Comment> {

    return this.createComment$Response(params).pipe(
      map((r: StrictHttpResponse<Comment>) => r.body as Comment)
    );
  }

  /**
   * Path part for operation uiDataEventControllerDeleteComment
   */
  static readonly UiDataEventControllerDeleteCommentPath = '/ui-data-events/{id}/comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteComment()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteComment$Response(params: {
    id: string;
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataEventControllerService.UiDataEventControllerDeleteCommentPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteComment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteComment(params: {
    id: string;
    where?: any;
  }): Observable<LoopbackCount> {

    return this.deleteComment$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation uiDataEventControllerUpdateComment
   */
  static readonly UiDataEventControllerUpdateCommentPath = '/ui-data-events/{id}/comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateComment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateComment$Response(params: {
    id: string;
    where?: any;
    body?: CommentPartial
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataEventControllerService.UiDataEventControllerUpdateCommentPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateComment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateComment(params: {
    id: string;
    where?: any;
    body?: CommentPartial
  }): Observable<LoopbackCount> {

    return this.updateComment$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation uiDataEventControllerFindById
   */
  static readonly UiDataEventControllerFindByIdPath = '/ui-data-events/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<UiDataEvent>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataEventControllerService.UiDataEventControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UiDataEvent>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
    filter?: any;
  }): Observable<UiDataEvent> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<UiDataEvent>) => r.body as UiDataEvent)
    );
  }

  /**
   * Path part for operation uiDataEventControllerDeleteById
   */
  static readonly UiDataEventControllerDeleteByIdPath = '/ui-data-events/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataEventControllerService.UiDataEventControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: string;
  }): Observable<any> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation uiDataEventControllerUpdateById
   */
  static readonly UiDataEventControllerUpdateByIdPath = '/ui-data-events/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    body?: UiDataEventPartial
  }): Observable<StrictHttpResponse<UiDataEvent>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataEventControllerService.UiDataEventControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UiDataEvent>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    body?: UiDataEventPartial
  }): Observable<UiDataEvent> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<UiDataEvent>) => r.body as UiDataEvent)
    );
  }

  /**
   * Path part for operation uiDataEventControllerFind
   */
  static readonly UiDataEventControllerFindPath = '/ui-data-events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<UiDataEventWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataEventControllerService.UiDataEventControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UiDataEventWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<UiDataEventWithRelations>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UiDataEventWithRelations>>) => r.body as Array<UiDataEventWithRelations>)
    );
  }

  /**
   * Path part for operation uiDataEventControllerCreate
   */
  static readonly UiDataEventControllerCreatePath = '/ui-data-events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    options?: any;
    body?: NewUiDataEvent
  }): Observable<StrictHttpResponse<Array<UiDataEventWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataEventControllerService.UiDataEventControllerCreatePath, 'post');
    if (params) {
      rb.query('options', params.options, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UiDataEventWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    options?: any;
    body?: NewUiDataEvent
  }): Observable<Array<UiDataEventWithRelations>> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UiDataEventWithRelations>>) => r.body as Array<UiDataEventWithRelations>)
    );
  }

}
