import { KeyValue } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { Project, UiDataStore, UiWorkflowStepFunctionWithRelations } from '@rappider/rappider-sdk';
import { defaultToolbarTitleHeadingSize, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rappider-ui-step-function-detail',
  templateUrl: './ui-step-function-detail.component.html',
  styleUrls: ['./ui-step-function-detail.component.scss']
})
export class UiStepFunctionDetailComponent implements OnInit, OnDestroy {
  /* main title of the page */
  mainTitle: HeadingComponentConfig = {
    content: 'PROJECT_MODULE.UI_STEP_FUNCTION_DETAIL_COMPONENT.UI_STEP_FUNCTION_DETAILS',
    type: defaultToolbarTitleHeadingSize
  };
  /* title breadcrumb */
  title: BreadcrumbOption[];
  /* subscriptions */
  subscriptions: Subscription[];
  /* active project */
  activeProject: Project;
  /* current ui step function */
  uiStepFunction: UiWorkflowStepFunctionWithRelations;
  /* current ui step function's id */
  uiStepFunctionId: string;
  /* ui workflow step function data to display */
  uiStepFunctionData: KeyValue<string, any>[];
  displayToolbar = false;
  displayToolbarBackButton = false;
  uiDataStoreId: string;
  uiDataStores: UiDataStore;

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.getUiStepFunctionIdFromUrl();
    this.subscribeToData();
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToUiStepFunctions(),
      this.subscribeToUiDataStores()
    ];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  getUiStepFunctionIdFromUrl() {
    this.uiStepFunctionId = this.activatedRoute.snapshot.params['id'];
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: Project) => {
      if (activeProject) {
        this.activeProject = activeProject;
      } else {
        this.activeProject = null;
      }
    });
  }

  subscribeToUiDataStores() {
    return this.store.select(state => state.uiDataStore.data).subscribe((uiDataStores) => {
      this.uiDataStores = uiDataStores;
      this.setTitle();
    });
  }

  subscribeToUiStepFunctions() {
    return this.store.select(state => state.uiWorkflowStepFunction?.data).subscribe((uiStepFunctions: UiWorkflowStepFunctionWithRelations[]) => {
      this.uiStepFunction = uiStepFunctions?.find(uiStepFunction => uiStepFunction.id === this.uiStepFunctionId);
      this.setUiStepFunctionDetailsData();
      this.setTitle();
    });
  }

  setTitle() {
    const activeDataStore = this.uiDataStores?.find(uiDataStore => uiDataStore.id === this.uiStepFunction?.uiDataStoreId);
    this.title = [
      {
        label: this.activeProject?.name
      },
      {
        label: activeDataStore?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_DETAIL}/${this.uiStepFunction?.uiDataStoreId}`, queryParams: { tab: 'ui-step-functions' }
      },
      {
        label: 'PROJECT_MODULE.UI_STEP_FUNCTION_DETAIL_COMPONENT.UI_STEP_FUNCTION_DETAILS'
      },
      {
        label: this.uiStepFunction?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.UI_STEP_FUNCTION_UPDATE}/${this.uiStepFunction?.id}`
      }
    ];
  }

  setUiStepFunctionDetailsData() {
    this.uiStepFunctionData = [
      {
        key: this.translateService.instant('SHARED.NAME'),
        value: [this.uiStepFunction?.name]
      },
      {
        key: this.translateService.instant('SHARED.OPERATOR'),
        value: [this.uiStepFunction?.integrationOperatorType]
      },
      {
        key: this.translateService.instant('PROJECT_MODULE.UI_STEP_FUNCTION_COMPONENT.SUBSCRIBED_EVENTS'),
        value: this.uiStepFunction?.subscribedEvents?.map(item => item.name)
      },
      {
        key: this.translateService.instant('PROJECT_MODULE.UI_STEP_FUNCTION_COMPONENT.PUBLISHED_EVENTS_ON_SUCCESS'),
        value: this.uiStepFunction?.publishedEventsOnSuccess?.map(item => item.name)
      },
      {
        key: this.translateService.instant('PROJECT_MODULE.UI_STEP_FUNCTION_COMPONENT.PUBLISHED_EVENTS_ON_FAILURE'),
        value: this.uiStepFunction?.publishedEventsOnFailure?.map(item => item.name)
      },
      {
        key: this.translateService.instant('SHARED.UI_DATA_STORE'),
        value: [this.uiStepFunction?.uiDataStore?.name]
      },
      {
        key: this.translateService.instant('PROJECT_MODULE.UI_STEP_FUNCTION_CREATE_OR_EDIT_COMPONENT.PROJECT_MODEL_ENDPOINT'),
        value: [this.uiStepFunction?.endpoint?.name]
      }
    ];
  }

}
