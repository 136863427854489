<rappider-title-toolbar *ngIf="titleToolbarConfig"
                        [mainTitle]="titleToolbarConfig.mainTitle"
                        [options]="titleToolbarConfig.options">
</rappider-title-toolbar>

<section>
  <rappider-spin [spinning]="loading">
    <rappider-crud-view-edit-form [config]="PROJECT_THEME_EDIT_FORM_CONFIG"
                                  [data]="projectTheme"
                                  [submitButtonLoading]="submitButtonLoading"
                                  (formSubmit)="updateProjectTheme($event)">
    </rappider-crud-view-edit-form>
  </rappider-spin>
</section>