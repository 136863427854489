import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { UIDataStoreApi, ProjectInterface, UIDataStoreInterface } from '@rappider/api-sdk';
import { BreadcrumbOption, HeadingComponentConfig, HeadingType } from '@rappider/rappider-components/utils';
import { UiDataStorePartial } from '@rappider/rappider-sdk';
import { UI_DATA_STORE_CREATE_OR_EDIT_CONFIG } from '@rappider/shared/configs';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { UpdateUIDataStore } from 'libs/project/src/lib/states/ui-data-store/ui-data-store.actions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rappider-ui-data-store-edit',
  templateUrl: './ui-data-store-edit.component.html',
  styleUrls: ['./ui-data-store-edit.component.scss']
})
export class UiDataStoreEditComponent implements OnInit, OnDestroy {

  /* form config */
  UI_DATA_STORE_EDIT_CONFIG = UI_DATA_STORE_CREATE_OR_EDIT_CONFIG;
  /* project id */
  projectId: string;
  /* main title */
  mainTitle: HeadingComponentConfig;
  /* page title */
  title: BreadcrumbOption[];
  /* subscriptions */
  subscriptions: Subscription[];
  /* ui data store id */
  uiDataStoreId: string;
  /* ui data store to edit */
  uiDataStore: UIDataStoreInterface;
  /* active project name */
  projectName: string;
  displayToolbar = false;
  displayToolbarBackButton = false;
  /* ui data store loading */
  isLoading: boolean;

  constructor(
    private uiDataStoreApi: UIDataStoreApi,
    private store: Store<any>,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getUIDataStoreIdFromUrl();
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach((subscription) => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToLoading(),
      this.subscribeToUIDataStores(),
    ];
  }

  getUIDataStoreIdFromUrl() {
    this.uiDataStoreId = this.activatedRoute.snapshot.params['id'];
    this.subscribeToData();
  }

  subscribeToUIDataStores() {
    return this.store.select(state => state.uiDataStore.data).subscribe(uiDataStores => {
      if (uiDataStores?.length) {
        this.uiDataStore = uiDataStores?.find(uiDataStore => uiDataStore.id === this.uiDataStoreId);
        this.setTitle();
      } else {
        this.uiDataStore = undefined;
      }
    });
  }

  setTitle() {
    this.mainTitle = {
      content: 'PROJECT_MODULE.UI_DATA_STORE_EDIT_COMPONENT.EDIT_UI_DATA_STORE',
      type: defaultToolbarTitleHeadingSize
    };
    this.title = [
      {
        label: this.projectName,
        redirectUrl: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_DETAIL.URL}/${this.projectId}`
      },
      {
        label: 'UI Data Stores',
        redirectUrl: PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_LIST
      },
      {
        label: this.uiDataStore.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_DETAIL}/${this.uiDataStore?.id}`
      },
      {
        label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_DATA_STORE_EDIT.PAGE_TITLE
      }
    ];
  }

  /**
   * subscribe to active project
   *
   * @returns
   * @memberof UiDataStoreEditComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectInterface) => {
      if (activeProject) {
        this.projectId = activeProject.id;
        this.projectName = activeProject.name;
      }
    });
  }

  subscribeToLoading() {
    return this.store.select(state => state.uiDataStore.loading).subscribe((loading: boolean) => {
      this.isLoading = loading;
    });
  }

  onUIDataStoreUpdate(uiDataStore: UiDataStorePartial) {
    this.store.dispatch(new UpdateUIDataStore({ id: this.uiDataStoreId, uiDataStore: uiDataStore }));
  }

}
