<div *ngIf="!loading">

  <rappider-title-toolbar [mainTitle]="mainTitleConfig"
                          [options]="titleConfig"
                          [displayToolbar]="displayToolbar"></rappider-title-toolbar>

  <section>
    <form nz-form
          [formGroup]="updateCustomFunctionForm"
          [nzLayout]="formLayout"
          (ngSubmit)="onUpdateCustomFunction()">

      <nz-form-item>
        <nz-form-label nzRequired
                       [nzSpan]="this.formLayout === 'horizontal' ? 6 : null">
          {{ 'Service Name' | translate}}
        </nz-form-label>
        <nz-form-control [nzErrorTip]="nameFieldErrorTemplates"
                         [nzSpan]="this.formLayout === 'horizontal' ? 18 : null">
          <rappider-textbox formControlName="serviceName"></rappider-textbox>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzRequired
                       [nzSpan]="this.formLayout === 'horizontal' ? 6 : null">
          {{ 'Function Name' | translate}}
        </nz-form-label>
        <nz-form-control [nzErrorTip]="requiredErrorTemplate"
                         [nzSpan]="this.formLayout === 'horizontal' ? 18 : null">
          <rappider-textbox formControlName="functionName"></rappider-textbox>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSpan]="this.formLayout === 'horizontal' ? 6 : null">
          {{ 'Description' | translate}}
        </nz-form-label>
        <nz-form-control [nzSpan]="this.formLayout === 'horizontal' ? 18 : null">
          <rappider-textarea formControlName="description"
                             [minRows]="5"></rappider-textarea>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSpan]="this.formLayout === 'horizontal' ? 6 : null">
          {{ 'Programming Language' | translate}}
        </nz-form-label>
        <nz-form-control [nzSpan]="this.formLayout === 'horizontal' ? 18 : null">
          <rappider-select [options]="programmingLanguageOptions"
                           formControlName="programmingLanguage"
                           (valueChange)="onProgrammingLanguageChange($event)"></rappider-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSpan]="this.formLayout === 'horizontal' ? 6 : null">
          {{ 'Packages' | translate}}
        </nz-form-label>
        <nz-form-control [nzSpan]="this.formLayout === 'horizontal' ? 18 : null">
          <rappider-inline-row-form [columns]="packageColumns"
                                    formControlName="packages"></rappider-inline-row-form>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSpan]="this.formLayout === 'horizontal' ? 6 : null"
                       [nzNoColon]="true">
        </nz-form-label>
        <nz-form-control [nzSpan]="this.formLayout === 'horizontal' ? 18 : null">
          <rappider-button [text]="'Add Packages'"
                           [size]="'small'"
                           (confirm)="openModal()"></rappider-button>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSpan]="this.formLayout === 'horizontal' ? 6 : null">
          {{ 'Environment Variables' | translate }}
        </nz-form-label>
        <nz-form-control [nzSpan]="this.formLayout === 'horizontal' ? 18 : null">
          <rappider-custom-function-tab-list-wrapper [displayedEnvironmentVariables]="displayedEnvironmentVariables"
                                                     (activeEnvironmentVariableTab)="onHandleActiveEnvironmentVariableTabList($event)"
                                                     (createFormSubmit)="onCreateFormSubmit($event)"
                                                     (editFormSubmit)="onEditFormSubmit($event)"
                                                     (deleteFormItem)="onDeleteFormItem($event)"></rappider-custom-function-tab-list-wrapper>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSpan]="this.formLayout === 'horizontal' ? 6 : null">
          {{ 'Code and Payload Configurations' | translate }}
        </nz-form-label>
        <nz-form-control [nzSpan]="this.formLayout === 'horizontal' ? 18 : null">
          <nz-tabset nzType="card"
                     nzAnimated="true"
                     nzTabPosition="top"
                     nzSize="default"
                     [nzSelectedIndex]="'0'"
                     class="card-container">
            <nz-tab [nzTitle]="'Code'"
                    (nzSelect)="changeActiveCodeEditorTab(customFunctionCreateEditFormItem.Code)">

              <nz-form-item>
                <nz-form-control [nzErrorTip]="requiredErrorTemplate">
                  <div class="code-editor-wrapper">
                    <rappider-monaco-code-editor *ngIf="codeEditorVisible"
                                                 [options]="editorConfig"
                                                 [borderSettings]="{border: 'none'}"
                                                 formControlName="code"></rappider-monaco-code-editor>
                  </div>
                </nz-form-control>
              </nz-form-item>

            </nz-tab>
            <nz-tab [nzTitle]="'Request JSON Sample'"
                    (nzSelect)="changeActiveCodeEditorTab(customFunctionCreateEditFormItem.RequestJSONSchema)">

              <nz-form-item>
                <nz-form-control [nzErrorTip]="updateCustomFunctionForm.get('requestJSONSample')?.errors?.error">
                  <div class="code-editor-wrapper">
                    <rappider-monaco-code-editor *ngIf="requestJSONSchemaVisible"
                                                 [options]="MONACO_EDITOR_JSON_CONFIG"
                                                 [borderSettings]="{border: 'none'}"
                                                 formControlName="requestJSONSample"></rappider-monaco-code-editor>
                    {{MONACO_EDITOR_JAVASCRIPT_CONFIG | json}}
                  </div>
                </nz-form-control>
              </nz-form-item>

            </nz-tab>
            <nz-tab [nzTitle]="'Response JSON Sample'"
                    (nzSelect)="changeActiveCodeEditorTab(customFunctionCreateEditFormItem.ResponseJSONSchema)">
              <nz-form-item>
                <nz-form-control [nzErrorTip]="updateCustomFunctionForm.get('responseJSONSample')?.errors?.error">
                  <div class="code-editor-wrapper">
                    <rappider-monaco-code-editor *ngIf="responseJSONSchemaVisible"
                                                 [options]="MONACO_EDITOR_JSON_CONFIG"
                                                 [borderSettings]="{border: 'none'}"
                                                 formControlName="responseJSONSample"></rappider-monaco-code-editor>
                  </div>
                </nz-form-control>
              </nz-form-item>
            </nz-tab>
          </nz-tabset>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSpan]="this.formLayout === 'horizontal' ? 6 : null"
                       [nzNoColon]="true">
        </nz-form-label>
        <nz-form-control [nzSpan]="this.formLayout === 'horizontal' ? 18 : null">
          <button nz-button
                  [disabled]="!updateCustomFunctionForm.valid"
                  [nzLoading]="isCustomFunctionLoading"
                  type="submit"
                  nzType="primary">
            {{ 'Save' | translate}}
          </button>
        </nz-form-control>
      </nz-form-item>

      <ng-template #nameFieldErrorTemplates>
        <small *ngIf="getNameFieldErrorsByErrorKey('required')">
          - {{ 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE' | translate}}
          <br />
        </small>
        <small *ngIf="getNameFieldErrorsByErrorKey('pattern')">
          - {{'ERRORS.PATTERN_MESSAGES.MUST_START_WITH_CAPITAL_LETTER' | translate }}
        </small>
      </ng-template>
      <ng-template #requiredErrorTemplate>
        <small>
          - {{ 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE' | translate}}
        </small>
      </ng-template>
    </form>
  </section>

  <rappider-modal [(visible)]="isModalOpen"
                  [title]="'Add Package'"
                  width="40%"
                  (okClick)="(selectedVersion) ? onModalOk() : selectVersionAlert()"
                  (cancelClick)="onModalCancel()">
    <rappider-spin>
      <nz-select class="search-area"
                 nzPlaceHolder="Search Packages"
                 nzAllowClear
                 nzShowSearch
                 nzServerSearch
                 [(ngModel)]="selectedPackage"
                 (nzOnSearch)="onSearchValueChange($event)"
                 (ngModelChange)="onSelectPackage($event)">
        <ng-container *ngFor="let package of npmPackages">
          <nz-option *ngIf="!isLoading"
                     [nzValue]="package.value"
                     [nzLabel]="package.key"></nz-option>
        </ng-container>
        <nz-option *ngIf="isLoading"
                   nzDisabled
                   nzCustomContent>
          <rappider-icon class="loading-icon"
                         [name]="loadingIcon.name"
                         [type]="loadingIcon.type"></rappider-icon>
          {{"SHARED.LOADING" | translate}}
        </nz-option>
      </nz-select>

      <div class="package-details-area">
        <rappider-label *ngIf="packageDetails"
                        content="Package Details"></rappider-label>

        <!-- <rappider-crud-detail *ngIf="packageDetails; else noPackageTmpl"
                              [data]="packageDetails"></rappider-crud-detail> -->

        <div *ngIf="packageDetails; else noPackageTmpl">
          <section>
            <div *ngFor="let item of packageDetails"
                 class="item-area">
              <div class="property-title col-2">
                <rappider-label [content]="item.key | translate"></rappider-label>
              </div>

              <div class="property-value col-10">
                <div *ngFor="let value of item.value">
                  <rappider-text *ngIf="item.key !== 'SHARED.VERSION'"
                                 [text]="value"></rappider-text>
                  <rappider-select *ngIf="item.key === 'SHARED.VERSION'"
                                   [grouppedOptions]="value"
                                   [placeholder]="selectedVersion"
                                   (valueChange)="onVersionChange($event)"></rappider-select>
                </div>
              </div>
            </div>
          </section>
        </div>

        <ng-template #noPackageTmpl>
          <rappider-alert [type]="projectPackageInfoAlertConfig.type"
                          [title]="projectPackageInfoAlertConfig.title"
                          [description]="projectPackageInfoAlertConfig.description"
                          [closeable]="projectPackageInfoAlertConfig.closeable"
                          [showIcon]="projectPackageInfoAlertConfig.showIcon"></rappider-alert>
        </ng-template>
      </div>
    </rappider-spin>
  </rappider-modal>
</div>