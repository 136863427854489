import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  PersonInterface,
  ProjectInterface,
  ProjectRoleInterface,
} from '@rappider/api-sdk';
import {
  PROJECT_PERSON_LIST_CONFIG,
  INVITE_USER_BUTTON_CONFIG,
  ALLOWED_USERS_FOR_INVITE,
} from '@rappider/shared/configs';
import { ProjectService } from '@rappider/services';
import { LoadModule } from '../../states/project-member-state/project-member.actions';
import { BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { defaultToolbarTitleHeadingSize, PATH_DEFINITIONS } from '@rappider/shared/definitions';

@Component({
  selector: 'rappider-project-member-list',
  templateUrl: './project-member-list.component.html',
  styleUrls: ['./project-member-list.component.scss']
})
export class ProjectMemberListComponent implements OnInit, OnDestroy {

  mainTitle: HeadingComponentConfig;
  title: string | string[] | BreadcrumbOption[];
  roleId: string;
  personId: string;
  /* Project Roles */
  projectRoles: ProjectRoleInterface[];
  /* persons of the project */
  projectPersons: PersonInterface[];
  personProjectRoles: ProjectRoleInterface[];
  PROJECT_PERSON_LIST_CONFIG = PROJECT_PERSON_LIST_CONFIG;
  activeProject: ProjectInterface;
  subscriptions: Subscription[] = [];
  loading: boolean;
  displayToolbar = false;
  displayToolbarBackButton = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<any>,
    private projectService: ProjectService
  ) { }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit() {
    this.getRoleIdFromUrl();
    if (this.roleId) {
      this.subscribeToData();
    }
  }

  /**
   * Takes role id from url
   *
   * @memberof ProjectMemberListComponent
   */
  getRoleIdFromUrl() {
    this.roleId = this.activatedRoute.snapshot.params.roleId;
  }

  /**
   * Subscribe all data
   *
   * @memberof AppComponent
   */
  subscribeToData() {
    this.subscriptions = [
      this.subscribeToPerson(),
      this.subscribeToActiveProject(),
      this.subscribeToProjectMembers(),
      this.subscribeToPersonProjectRoles(),
    ];
  }

  subscribeToProjectMembers() {
    return this.store.select(state => state.projectMember).subscribe((projectMemberState) => {
      if (projectMemberState.data?.length) {
        this.projectPersons = projectMemberState.data;
      } else {
        this.projectPersons = [];
      }
      this.loading = projectMemberState.loading;
    });
  }

  /**
 * project roles of the person
 *
 * @return {*}
 * @memberof ProjectMemberListComponent
 */
  subscribeToPerson() {
    return this.store.select(state => state.auth.activePerson).subscribe((person: PersonInterface) => {
      if (person) {
        this.personId = person.id;
      }
    });
  }

  /**
 * Subscribe active project
 *
 * @return {*}
 * @memberof ProjectMemberListComponent
 */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject) => {
      if (activeProject) {
        this.activeProject = activeProject;
        this.getProjectMembers();
      }
    });
  }

  /**
   * Get Persons In Role
   *
   * @param {string} roleId
   * @memberof ProjectMemberListComponent
   */
  getProjectMembers() {
    this.store.dispatch(new LoadModule());
  }

  subscribeToPersonProjectRoles() {
    return this.store.select(state => state.auth.roles).subscribe(roles => {
      if (roles) {
        this.personProjectRoles = roles;
        const activeProjectRoleTitle = this.projectRoles?.find(role => role.id === this.roleId).title;
        if (this.activeProject && this.personProjectRoles) {
          this.mainTitle = {
            content: 'SHARED.MEMBERS',
            type: defaultToolbarTitleHeadingSize
          };
          this.title = [
            {
              label: this.activeProject.name,
              redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
            },
            {
              label: 'SHARED.MEMBERS',
              redirectUrl: PATH_DEFINITIONS.PROJECTS.PROJECT_ROLE_LIST
            },
            {
              label: activeProjectRoleTitle
            }
          ];
          this.addManagerActions();
        }
      }
    });
  }

  /**
   * If active user is manager or project owner, changes list grid config
   *
   * @memberof ProjectMemberListComponent
   */
  addManagerActions() {
    if (this.roleId && this.activeProject && this.personProjectRoles) {
      const roles = this.personProjectRoles.filter(role => role.projectId === this.activeProject.id);
      if (roles.filter(role => ALLOWED_USERS_FOR_INVITE.includes(role.title.toLowerCase()))) {
        this.PROJECT_PERSON_LIST_CONFIG = {
          ...this.PROJECT_PERSON_LIST_CONFIG,
          listActions: [
            ...this.PROJECT_PERSON_LIST_CONFIG.listActions,
            INVITE_USER_BUTTON_CONFIG
          ]
        };
      } else {
        this.PROJECT_PERSON_LIST_CONFIG = PROJECT_PERSON_LIST_CONFIG;
      }
    }
  }

}
