<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton"></rappider-title-toolbar>
<section>
  <rappider-crud-view-edit-form *ngIf="dataEventOptions?.length; else noEventTml"
                                [config]="UI_DATA_UPDATE_FUNCTION_CREATE_FORM_CONFIG"
                                (formSubmit)="onCreateUIDataUpdateFunction($event)"></rappider-crud-view-edit-form>
</section>

<ng-template #noEventTml>
  <rappider-alert [type]="alertConfig?.type"
                  [title]="alertConfig?.title"
                  [showIcon]="alertConfig?.showIcon"></rappider-alert>
</ng-template>