export function separetaStringAsUnitAndNumber(value: string) {
  // use parseInt to separete the number from the value
  const number = parseInt(value, 10);
  // use regex to separate the unit from the value
  const unit = value.match(/[a-zA-Z]+/g)[0];

  return { number, unit };
}

export function projectThemeFontSizeDataTransformationTitleFontSize(value: string) {
  const separatedValue = separetaStringAsUnitAndNumber(value);

  return `${separatedValue.number * (1.15)}${separatedValue.unit}`;
}

export function projectThemeFontSizeDataTransformationSubtextFontSize(value: string) {
  const separatedValue = separetaStringAsUnitAndNumber(value);

  return `${separatedValue.number * (0.93)}${separatedValue.unit}`;
}

export function projectThemeFontSizeDataTransformationH1FontSize(value: string) {
  const separatedValue = separetaStringAsUnitAndNumber(value);

  return `${separatedValue.number * (2.15)}${separatedValue.unit}`;
}

export function projectThemeFontSizeDataTransformationH2FontSize(value: string) {
  const separatedValue = separetaStringAsUnitAndNumber(value);

  return `${separatedValue.number * (1.72)}${separatedValue.unit}`;
}

export function projectThemeFontSizeDataTransformationH3FontSize(value: string) {
  const separatedValue = separetaStringAsUnitAndNumber(value);

  return `${separatedValue.number * (1.58)}${separatedValue.unit}`;
}

export function projectThemeFontSizeDataTransformationH4FontSize(value: string) {
  const separatedValue = separetaStringAsUnitAndNumber(value);

  return `${separatedValue.number * (1.36)}${separatedValue.unit}`;
}

export function projectThemeFontSizeDataTransformationH5FontSize(value: string) {
  const separatedValue = separetaStringAsUnitAndNumber(value);

  return `${separatedValue.number * (1.15)}${separatedValue.unit}`;
}

export function projectThemeFontSizeDataTransformationH6FontSize(value: string) {
  const separatedValue = separetaStringAsUnitAndNumber(value);

  return `${separatedValue.number * (1)}${separatedValue.unit}`;
}

// function for color transformation
export function projectThemeColorTransformation(value: string, amount) {
  let usePound = false;

  if (value[0] === '#') {
    value = value.slice(1);
    usePound = true;
  }

  const num = parseInt(value, 16);

  let r = (num >> 16) + amount;

  if (r > 255) {
    r = 255;
  } else if (r < 0) {
    r = 0;
  }

  let b = ((num >> 8) & 0x00FF) + amount;

  if (b > 255) {
    b = 255;
  } else if (b < 0) {
    b = 0;
  }

  let g = (num & 0x0000FF) + amount;

  if (g > 255) {
    g = 255;
  } else if (g < 0) {
    g = 0;
  }

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

export function projectThemeColorTransformationOpacity(value: string, opacity: number) {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return value + _opacity.toString(16).toUpperCase();
}

// posivite number for ligthen, negative number for darken
export function projectThemePrimaryHoverColor(value: string) {
  return projectThemeColorTransformation(value, 30);
}

export function projectThemeFocusColor(value: string) {
  return projectThemeColorTransformationOpacity(value, 0.5);
}

export function projectThemeComponentHeaderBackgroundColor(value: string) {
  return projectThemeColorTransformation(value, -20);
}

export function projectThemeComponentInnerBackgroundColor(value: string) {
  return projectThemeColorTransformation(value, 10);
}

export function projectThemeInputBackgroundColor(value: string) {
  return projectThemeColorTransformation(value, 35);
}

export function projectThemeNavbarBackgroundColor(value: string) {
  return projectThemeColorTransformation(value, -30);
}

export function projectThemeFooterBackgroundColor(value: string) {
  return projectThemeColorTransformation(value, -30);
}

export function projectThemeSectionBackgroundColor(value: string) {
  return projectThemeColorTransformation(value, 30);
}

export function projectThemeTableHeaderBackgroundColor(value: string) {
  return projectThemeColorTransformation(value, 20);
}

export function projectThemeTableBackgroundColor(value: string) {
  return projectThemeColorTransformation(value, 20);
}

export function projectThemeTableHoverBackgroundColor(value: string) {
  return projectThemeColorTransformation(value, -30);
}
