import { AlertTypes, AlertConfig, ButtonSize, HeadingType, TextMode, ActionConfigPlacement } from '@rappider/rappider-components/utils';

export const PERSON_EMAIL_VERIFY_ALERT_CONFIG: AlertConfig = {
  type: AlertTypes.Warning,
  title: {
    type: HeadingType.H4,
    content: 'AUTHENTICATION_MODULE.EMAIL_VERIFY_COMPONENT.VERIFY_EMAIL'
  },
  description: {
    text: 'AUTHENTICATION_MODULE.EMAIL_VERIFY_COMPONENT.VERIFY_EMAIL_DESCRIPTION'
  },
  showIcon: true,
  actionConfigPlacement: ActionConfigPlacement.End,
  closeable: false,
  actionConfig: {
    size: ButtonSize.Small,
    text: 'AUTHENTICATION_MODULE.EMAIL_VERIFY_COMPONENT.CLICK_HERE_VERIFY_EMAIL'
  }
};

export const PERSON_PHONE_NUMBER_VERIFY_ALERT_CONFIG: AlertConfig = {
  type: AlertTypes.Warning,
  title: {
    type: HeadingType.H2,
    content: 'AUTHENTICATION_MODULE.PHONE_NUMBER_VERIFY_COMPONENT.VERIFY_PHONE_NUMBER'
  },
  description: {
    text: 'AUTHENTICATION_MODULE.PHONE_NUMBER_VERIFY_COMPONENT.VERIFY_PHONE_NUMBER_DESCRIPTION'
  },
  showIcon: true,
  closeable: false,
  actionConfig: {
    size: ButtonSize.Small,
    text: 'Go To Verify Page'
  },
  actionConfigPlacement: ActionConfigPlacement.End,
};
