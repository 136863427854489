/* eslint-disable */
import { Project, Component, Metadata, Outlet } from '../index';

declare var Object: any;
export interface PageInterface {
  id?: string;
  title: string;
  description?: string;
  keywords?: Array<string>;
  isActive?: boolean;
  isPublic?: boolean;
  slug?: string;
  type: string;
  projectId?: string;
  layoutId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  isDeleted?: boolean;
  deletedDate?: Date;
  deletedBy?: string;
  deletedById?: string;
  project?: Project;
  layout?: Page;
  components?: Component[];
  metadata?: Metadata[];
  outlets?: Outlet[];
  data?: PageInterface;
  selectTemplate?: string;
  moduleId?: string;
}

export class Page implements PageInterface {
  'id'?: string;
  'title': string;
  'description': string;
  'keywords': Array<string>;
  'isActive': boolean;
  'isPublic': boolean;
  'slug': string;
  'type': string;
  'projectId': string;
  'layoutId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  'isDeleted': boolean;
  'deletedDate': Date;
  'deletedBy': string;
  'deletedById': string;
  project: Project;
  layout: Page;
  components: Component[];
  metadata: Metadata[];
  outlets: Outlet[];
  moduleId: string;
  constructor(data?: PageInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Page`.
   */
  public static getModelName() {
    return 'Page';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Page for dynamic purposes.
   **/
  public static factory(data: PageInterface): Page {
    return new Page(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Page',
      plural: 'Pages',
      path: 'pages',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        title: {
          name: 'title',
          type: 'string',
        },
        description: {
          name: 'description',
          type: 'string',
        },
        keywords: {
          name: 'keywords',
          type: 'Array&lt;any&gt;',
        },
        isActive: {
          name: 'isActive',
          type: 'boolean',
        },
        isPublic: {
          name: 'isPublic',
          type: 'boolean',
        },
        slug: {
          name: 'slug',
          type: 'string',
        },
        type: {
          name: 'type',
          type: 'string',
          default: 'PAGE',
        },
        projectId: {
          name: 'projectId',
          type: 'string',
        },
        layoutId: {
          name: 'layoutId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
        isDeleted: {
          name: 'isDeleted',
          type: 'boolean',
        },
        deletedDate: {
          name: 'deletedDate',
          type: 'Date',
        },
        deletedBy: {
          name: 'deletedBy',
          type: 'string',
        },
        deletedById: {
          name: 'deletedById',
          type: 'string',
        },
      },
      relations: {
        project: {
          name: 'project',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
          keyFrom: 'projectId',
          keyTo: 'id',
        },
        layout: {
          name: 'layout',
          type: 'Page',
          model: 'Page',
          relationType: 'belongsTo',
          keyFrom: 'layoutId',
          keyTo: 'id',
        },
        components: {
          name: 'components',
          type: 'Component[]',
          model: 'Component',
          relationType: 'hasMany',
          modelThrough: 'PageComponent',
          keyThrough: 'componentId',
          keyFrom: 'id',
          keyTo: 'pageId',
        },
        metadata: {
          name: 'metadata',
          type: 'Metadata[]',
          model: 'Metadata',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'pageId',
        },
        outlets: {
          name: 'outlets',
          type: 'Outlet[]',
          model: 'Outlet',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'outletId',
        },
      },
    };
  }
}
