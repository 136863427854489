import { createSelector } from '@ngrx/store';
import { Page } from '@rappider/rappider-sdk';

export const getProjectSettingsWithModulesAndPages = createSelector(
  state => state['projectSetting']?.data,
  state => state['projectSetting']?.isLoaded,
  state => state['module']?.data,
  state => state['module']?.isLoaded,
  state => state['page']?.data,
  state => state['page']?.isLoaded,
  (
    projectSettings: any[],
    projectSettingsIsLoaded: boolean,
    modules: any[],
    modulesIsLoaded: boolean,
    pages: Page[],
    pagesIsLoaded: boolean) => {
    if (projectSettingsIsLoaded && modulesIsLoaded && pagesIsLoaded) {
      return {
        projectSettings: projectSettings,
        modules: modules,
        pages: pages,
        isLoaded: true
      };
    } else {
      return {
        isLoaded: false
      };
    }
  }
);
