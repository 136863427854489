export enum UIStepFunctionListFieldName {
  Name = 'name',
  IntegrationOperatorType = 'integrationOperatorType',
  SubscribedEvents = 'subscribedEvents',
  PublishedEvents = 'publishedEvents',
  Edit = 'edit',
  Delete = 'delete',
  PreDataTransformation = 'preDataTransformation',
  PostDataTransformation = 'postDataTransformation'
}
