/* eslint-disable */
import { Person } from '../index';
import { UsernameType } from '../../definitions/username-types';

declare var Object: any;
export interface UserInterface {
  id?: any;
  realm?: string;
  username?: string;
  password?: string;
  usernameType?: UsernameType;
  provider?: string;
  accessTokens?: any[];
  people?: Person[];
}

export class User implements UserInterface {
  'id': any;
  'realm': string;
  'username': string;
  'password': string;
  'usernameType': UsernameType;
  accessTokens: any[];
  people: Person[];
  constructor(data?: UserInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `User`.
   */
  public static getModelName() {
    return 'User';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of User for dynamic purposes.
   **/
  public static factory(data: UserInterface): User {
    return new User(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'User',
      plural: 'Users',
      path: 'users',
      idName: 'id',
      properties: {
        realm: {
          name: 'realm',
          type: 'string',
        },
        username: {
          name: 'username',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        password: {
          name: 'password',
          type: 'string',
        },
      },
      relations: {
        accessTokens: {
          name: 'accessTokens',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'userId',
        },
        people: {
          name: 'people',
          type: 'Person[]',
          model: 'Person',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'userId',
        },
      },
    };
  }
}
