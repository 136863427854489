/* eslint-disable */
import { Project, ProjectModelField } from '../index';
import { ProjectModelRelation } from './ProjectModelRelation';

declare var Object: any;
export interface ProjectModelInterface {
  id?: string;
  name: string;
  dataTableName?: string;
  hiddenProperties?: Array<any>;
  scope?: Array<any>;
  isStrict?: boolean;
  generateUIDataStore?: boolean;
  description?: string;
  projectId?: string;
  basePath?: string;
  project?: Project;
  fields?: ProjectModelField[];
  relations?: ProjectModelRelation[];
}

export class ProjectModel implements ProjectModelInterface {
  'id': string;
  'name': string;
  'dataTableName': string;
  'hiddenProperties': Array<string>;
  'scope': Array<string>;
  'isStrict': boolean;
  'generateUIDataStore': boolean;
  'description': string;
  'projectId': string;
  'basePath': string;
  project: Project;
  fields: ProjectModelField[];
  relations: ProjectModelRelation[];
  constructor(data?: ProjectModelInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ProjectModel`.
   */
  public static getModelName() {
    return 'ProjectModel';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ProjectModel for dynamic purposes.
   **/
  public static factory(data: ProjectModelInterface): ProjectModel {
    return new ProjectModel(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ProjectModel',
      plural: 'ProjectModels',
      path: 'project-models',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        dataTableName: {
          name: 'dataTableName',
          type: 'string',
        },
        hiddenProperties: {
          name: 'hiddenProperties',
          type: 'Array&lt;any&gt;',
        },
        scope: {
          name: 'scope',
          type: 'Array&lt;any&gt;',
        },
        isStrict: {
          name: 'isStrict',
          type: 'boolean',
        },
        generateUIDataStore: {
          name: 'generateUIDataStore',
          type: 'boolean',
        },
        description: {
          name: 'description',
          type: 'string',
        },
        projectId: {
          name: 'projectId',
          type: 'string',
        },
        basePath: {
          name: 'basePath',
          type: 'string',
        },
      },
      relations: {
        project: {
          name: 'project',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
          keyFrom: 'projectId',
          keyTo: 'id',
        },
        fields: {
          name: 'fields',
          type: 'ProjectModelField[]',
          model: 'ProjectModelField',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'projectModelId',
        },
        relations: {
          name: 'relations',
          type: 'ProjectModelRelation[]',
          model: 'ProjectModelRelation',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'projectModelId',
        },
      },
    };
  }
}
