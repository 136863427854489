import { KeyValue } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { EditFormService } from '@rappider/services';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { BreadcrumbOption, CrudFormSelectItem, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { Subscription } from 'rxjs';
import { UpdateProjectModelRelation } from 'libs/project/src/lib/states/project-model-relation-state/project-model-relation.actions';
import { ProjectModel, ProjectModelRelationUpdateDto, ProjectModelRelationWithRelations, ProjectModelWithRelations, ProjectWithRelations } from '@rappider/rappider-sdk';
import { PROJECT_MODEL_RELATION_UPDATE_CONFIG } from '../../utils/project-model-relation-update-form.config';
import { THROUGH_MODEL_FORM_ITEM } from '../../utils/through-model-form-item.config';
import { ProjectModelRelationCreateOrEditFieldName } from '../../utils/project-model-relation-form-field-name.enum';
import { ProjectModelRelationType } from '@rappider/shared/configs';
import { getProjectModelsWithFields } from 'libs/shared/src/lib/state-selectors/get-models-with-fields-selector';

@Component({
  selector: 'rappider-project-model-relation-edit',
  templateUrl: './project-model-relation-edit.component.html',
  styleUrls: ['./project-model-relation-edit.component.scss']
})
export class ProjectModelRelationEditComponent implements OnInit, OnDestroy {
  /* edit form config */
  PROJECT_MODEL_RELATION_UPDATE_CONFIG = PROJECT_MODEL_RELATION_UPDATE_CONFIG;
  /* through model form item */
  THROUGH_MODEL_FORM_ITEM = {
    ...THROUGH_MODEL_FORM_ITEM,
    disabled: true
  };

  /* main title */
  mainTitle: HeadingComponentConfig = {
    content: 'PROJECT_MODULE.PROJECT_MODEL_RELATION_EDIT_COMPONENT.EDIT_MODEL_RELATION',
    type: defaultToolbarTitleHeadingSize
  };
  /* page breadcrumb */
  title: BreadcrumbOption[] | string | string[];
  /* subscriptions */
  subscriptions: Subscription[];
  /* active project */
  activeProject: ProjectWithRelations;
  /* project model relation data */
  projectModelRelation: ProjectModelRelationWithRelations;
  /* project model id */
  modelRelationId: string;
  /* project models */
  projectModels: ProjectModelWithRelations[];
  /* select options for project models */
  projectModelSelectOption: { key: string; value: string }[];
  /* source model id */
  sourceModelId: string;
  /* source model */
  sourceModel: ProjectModel;
  /* selet options for keyTo and keyFrom form items */
  keyToAndKeyFromSelectOptions: KeyValue<string, string>[];
  /*  */
  activeRelationType: ProjectModelRelationType;
  /* project model ids mapped to project model field key-values */
  projectModelFieldMapping = new Map<string, KeyValue<string, string>[]>();

  isLoading: boolean;
  displayToolbar = false;
  displayToolbarBackButton = false;

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private editFormService: EditFormService
  ) { }

  ngOnInit(): void {
    this.getProjectModelIdFromUrl();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToProjectModelRelations(),
      this.subscribeToProjectModelRelationLoading(),
      this.subscribeToProjectModelsAndFields(),
    ];
  }

  /**
   *get project model id from url
   *
   * @memberof ProjectModelRelationEditComponent
   */
  getProjectModelIdFromUrl() {
    this.modelRelationId = this.activatedRoute.snapshot.params.projectModelId;
  }

  /**
   *subscribes to active project to get project id and project name
   *
   * @return {*}
   * @memberof ProjectModelRelationEditComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectWithRelations) => {
      if (activeProject) {
        this.activeProject = activeProject;
      } else {
        this.activeProject = null;
      }
    });
  }

  subscribeToProjectModelRelations() {
    return this.store.select(state => state.projectModelRelation?.data).subscribe((projectModelRelations: ProjectModelRelationWithRelations[]) => {
      this.projectModelRelation = projectModelRelations?.find(relation => relation?.id === this.modelRelationId);
      if (this.projectModelRelation) {
        this.sourceModelId = this.projectModelRelation?.sourceModelId;
        this.activeRelationType = this.projectModelRelation?.type as ProjectModelRelationType;
        this.setEditConfigByRelationType();
      }
    });
  }

  subscribeToProjectModelsAndFields() {
    return this.store.select(<any>getProjectModelsWithFields).subscribe(projectModels => {
      this.projectModels = projectModels;
      this.sourceModel = projectModels?.find(model => model.id === this.sourceModelId);
      if (this.sourceModel) {
        this.setTitle();
      }
      this.projectModelSelectOption = projectModels?.map((projectModel: ProjectModel) => ({
        key: projectModel?.name,
        value: projectModel?.id
      }));
      projectModels?.forEach(projectModel => {
        this.projectModelFieldMapping.set(projectModel?.id, projectModel?.fields?.map(field => ({ key: field.name, value: field.id })));
      });
      if (this.projectModelFieldMapping) {
        this.setKeyToAndKeyFromItemsByRelationType();
      }
      this.setSourceAndTargetModelSelectOptions();
    });
  }

  setKeyToAndKeyFromItemsByRelationType() {
    if (this.activeRelationType !== ProjectModelRelationType.HasManyThrough) {
      this.setKeyFromSelectOptions(this.projectModelRelation?.sourceModelId);
      this.setKeyToSelectOptions(this.projectModelRelation?.targetModelId);
    } else {
      this.setThroughModelFieldsSelectOptions(this.projectModelRelation?.throughModelId);
    }
  }

  subscribeToProjectModelRelationLoading() {
    return this.store.select(state => state.projectModelRelation?.loading).subscribe((loading: boolean) => {
      this.isLoading = loading;
    });
  }

  /**
   * set page title
   *
   * @memberof ProjectModelRelationEditComponent
   */
  setTitle() {
    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
      },
      {
        label: this.sourceModel?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_DATA_FIELD_LIST}/${this.sourceModel?.id}`, queryParams: { tab: 'fields' }
      },
      {
        label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_MODEL_RELATION_EDIT.PAGE_TITLE
      },
      {
        label: this.projectModelRelation?.name
      }
    ];
  }

  setSourceAndTargetModelSelectOptions() {
    if (this.projectModelSelectOption) {
      /* gets the target model select options */
      const sourceModelFormItem = <CrudFormSelectItem>(
        this.PROJECT_MODEL_RELATION_UPDATE_CONFIG.items.find(
          item => item.fieldName === ProjectModelRelationCreateOrEditFieldName.SourceModelId
        )
      );
      /* set target model form item options */
      sourceModelFormItem.options = [...this.projectModelSelectOption];

      /* gets the target model select options */
      const targetModelFormItem = <CrudFormSelectItem>(
        this.PROJECT_MODEL_RELATION_UPDATE_CONFIG.items.find(
          item => item.fieldName === ProjectModelRelationCreateOrEditFieldName.TargetModelId
        )
      );
      /* set target model form item options */
      targetModelFormItem.options = [...this.projectModelSelectOption];
    }
  }

  onFieldValueChange(data: any) {
    if (data.type) {
      this.activeRelationType = data.type;
      this.setEditConfigByRelationType();
    } else if (data.throughModelId) {
      this.setThroughModelFieldsSelectOptions(data.throughModelId);
    } else if (data.sourceModelId) {
      /* if type is not hasManyThrough set key from select options */
      if (this.activeRelationType !== ProjectModelRelationType.HasManyThrough) {
        this.setKeyFromSelectOptions(data.sourceModelId);
      }
    } else if (data.targetModelId) {
      /* if type is not hasManyThrough set key to select options */
      if (this.activeRelationType !== ProjectModelRelationType.HasManyThrough) {
        this.setKeyToSelectOptions(data.targetModelId);
      }
    }
  }

  setKeyFromSelectOptions(sourceModelId: string) {
    const keyFromFormItem = <CrudFormSelectItem>(
      this.PROJECT_MODEL_RELATION_UPDATE_CONFIG.items.find(
        item => item.fieldName === ProjectModelRelationCreateOrEditFieldName.KeyFromId
      )
    );
    keyFromFormItem.options = this.projectModelFieldMapping.get(sourceModelId);
    this.PROJECT_MODEL_RELATION_UPDATE_CONFIG = { ...this.PROJECT_MODEL_RELATION_UPDATE_CONFIG };
  }

  setKeyToSelectOptions(targetModelId: string) {
    const keyToFormItem = <CrudFormSelectItem>(
      this.PROJECT_MODEL_RELATION_UPDATE_CONFIG.items.find(
        item => item.fieldName === ProjectModelRelationCreateOrEditFieldName.KeyToId
      )
    );
    keyToFormItem.options = this.projectModelFieldMapping.get(targetModelId);
    this.PROJECT_MODEL_RELATION_UPDATE_CONFIG = { ...this.PROJECT_MODEL_RELATION_UPDATE_CONFIG };
  }

  setThroughModelFieldsSelectOptions(throughModelId: string) {
    this.setKeyToSelectOptions(throughModelId);
    this.setKeyFromSelectOptions(throughModelId);
  }

  setEditConfigByRelationType() {
    if (this.activeRelationType === ProjectModelRelationType.HasManyThrough) {
      /* set through model's select options */
      const throughModelSelectOptions = this.projectModelSelectOption;
      this.THROUGH_MODEL_FORM_ITEM.options = throughModelSelectOptions;

      /* add through models to config */
      this.PROJECT_MODEL_RELATION_UPDATE_CONFIG = this.editFormService.addNewItemToConfig(
        this.THROUGH_MODEL_FORM_ITEM,
        this.PROJECT_MODEL_RELATION_UPDATE_CONFIG
      );

      this.PROJECT_MODEL_RELATION_UPDATE_CONFIG = {
        ...this.PROJECT_MODEL_RELATION_UPDATE_CONFIG
      };
    } else {
      /* remove through model form item from config */
      this.PROJECT_MODEL_RELATION_UPDATE_CONFIG = this.editFormService.deleteItemFromConfigByFieldName(
        THROUGH_MODEL_FORM_ITEM.fieldName,
        this.PROJECT_MODEL_RELATION_UPDATE_CONFIG
      );
    }
  }

  /**
   * project model relation edit form submit function
   *
   * @param {ProjectModelRelation} projectModelRelation
   * @memberof ProjectModelRelationEditComponent
   */
  onEditModelRelationFormSubmit(projectModelRelation: ProjectModelRelationUpdateDto) {
    const projectModelRelationUpdateBody: ProjectModelRelationUpdateDto = {
      name: projectModelRelation.name
    };

    this.store.dispatch(new UpdateProjectModelRelation({
      projectModelRelationId: this.modelRelationId,
      projectModelRelation: projectModelRelationUpdateBody,
      sourceModelId: this.sourceModelId
    }
    ));
  }
}
