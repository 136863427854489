<ul nz-menu
    class="header-menu-ul-container"
    nzMode="horizontal"
    [nzTheme]="theme === ThemeMode.Dark ? 'dark' : 'light'">

  <li [nzTooltipTitle]="'Data Models' | translate"
      nzTooltipPlacement="bottom"
      nz-button
      nz-tooltip
      [routerLink]="FullPaths.DatabaseDiagram">
    <a>
      <i class="fa-light fa-database"></i>
    </a>
  </li>

  <li [nzTooltipTitle]="'API Endpoints' | translate"
      nzTooltipPlacement="bottom"
      nz-button
      nz-tooltip
      [routerLink]="FullPaths.Endpoint">
    <a>
      <i class="fa-light fa-server"></i>
    </a>
  </li>


  <li [nzTooltipTitle]="'User Interface | Pages' | translate"
      nzTooltipPlacement="bottom"
      nz-button
      nz-tooltip
      [routerLink]="FullPaths.Pages">
    <a>
      <i class="fa-light fa-browsers"></i>
    </a>
  </li>


  <li [nzTooltipTitle]="'User Interface | Theme' | translate"
      nzTooltipPlacement="bottom"
      nz-button
      nz-tooltip
      [routerLink]="FullPaths.ThemeList">
    <a>
      <i class="fa-light fa-palette"></i>
    </a>
  </li>

  <li [nzTooltipTitle]="'UI Business Process' | translate"
      nzTooltipPlacement="bottom"
      nz-button
      nz-tooltip
      [routerLink]="FullPaths.DiagramEditor">
    <a>
      <i class="fa-sharp fa-light fa-arrow-progress"></i>
    </a>
  </li>


  <li [nzTooltipTitle]="'UI Data Stores' | translate"
      nzTooltipPlacement="bottom"
      nz-button
      nz-tooltip
      [routerLink]="FullPaths.UIDataStores">
    <a>
      <i class="fa-light fa-cabinet-filing"></i>
    </a>
  </li>


  <li [nzTooltipTitle]="'Server Side Workflows' | translate"
      nzTooltipPlacement="bottom"
      nz-button
      nz-tooltip
      [routerLink]="FullPaths.WorkflowDiagram">
    <a>
      <i class="fa-light fa-diagram-project"></i>
    </a>
  </li>

  <li [nzTooltipTitle]="'Project Settings' | translate"
      nzTooltipPlacement="bottom"
      nz-button
      nz-tooltip
      [routerLink]="FullPaths.ProjectSettings">
    <a>
      <i class="fa-light fa-toolbox"></i>
    </a>
  </li>

  <li [nzTooltipTitle]="'Environment Variables' | translate"
      nzTooltipPlacement="bottom"
      nz-button
      nz-tooltip
      [routerLink]="FullPaths.EnvironmentVariables">
    <a>
      <i class="fa-light fa-wrench"></i>
    </a>
  </li>



  <li [nzTooltipTitle]="'Project Packages' | translate"
      nzTooltipPlacement="bottom"
      nz-button
      nz-tooltip
      [routerLink]="FullPaths.ProjectPackages">
    <a>
      <i class="fa-light fa-cubes"></i>
    </a>
  </li>


  <li [nzTooltipTitle]="'Versions' | translate"
      nzTooltipPlacement="bottom"
      nz-button
      nz-tooltip
      [routerLink]="FullPaths.Versions">
    <a>
      <i class="fa-sharp fa-light fa-code-branch"></i>
    </a>
  </li>

  <li nz-submenu
      class="header-menu-more-menu-item">
    <span title><a><i class="fa-light fa-xl fa-ellipsis-stroke"></i></a></span>
    <ul>
      <li *ngFor="let feature of features; let i = index"
          nz-submenu
          class="header-menu-submenu-container-item"
          nzPlacement="bottom"
          [nzTitle]="feature.title">
        <ul class="header-menu-submenu-container">
          <ng-container *ngFor="let subFeature of feature.subFeatures">
            <li *ngIf="subFeature?.isVisible !== false"
                nz-menu-item
                [routerLink]="subFeature.path"
                class="header-menu-submenu-item">{{subFeature.title}}
            </li>
          </ng-container>
        </ul>
      </li>

    </ul>
  </li>

</ul>