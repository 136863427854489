import { createAction, props } from '@ngrx/store';
import { CommentPartial, CommentWithRelations, NewPageComment, PageCommentPartial, PageCommentWithRelations } from '@rappider/rappider-sdk';

export enum ActionTypes {
  GetPageComments = '[PageComment] GetPageComments',
  GetPageCommentsSuccessful = '[PageComment] GetPageCommentsSuccessful',
  GetPageCommentsFailure = '[PageComment] GetPageCommentsFailure',
  CreatePageComment = '[PageComment] CreatePageComment',
  CreatePageCommentSuccessful = '[PageComment] CreatePageCommentSuccessful',
  CreatePageCommentFailure = '[PageComment] CreatePageCommentFailure',
  UpdatePageComment = '[PageComment] UpdatePageComment',
  UpdatePageCommentSuccessful = '[PageComment] UpdatePageCommentSuccessful',
  UpdatePageCommentFailure = '[PageComment] UpdatePageCommentFailure',
  DeletePageComment = '[PageComment] DeletePageComment',
  DeletePageCommentSuccessful = '[PageComment] DeletePageCommentSuccessful',
  DeletePageCommentFailure = '[PageComment] DeletePageCommentFailure'
}

/* GET */
export const GetPageComments = createAction(
  ActionTypes.GetPageComments
);

export const GetPageCommentsSuccessful = createAction(
  ActionTypes.GetPageCommentsSuccessful,
  props<{ pageComments: PageCommentWithRelations[] }>()
);

export const GetPageCommentsFailure = createAction(
  ActionTypes.GetPageCommentsFailure,
  props<{ error: any; key: string; date: number }>()
);

/* CREATE */
export const CreatePageComment = createAction(
  ActionTypes.CreatePageComment,
  props<{ pageComment: NewPageComment }>()
);

export const CreatePageCommentSuccessful = createAction(
  ActionTypes.CreatePageCommentSuccessful,
  props<{ comment: CommentWithRelations; pageComment: PageCommentWithRelations }>()
);

export const CreatePageCommentFailure = createAction(
  ActionTypes.CreatePageCommentFailure,
  props<{ error: any; key: string; date: number }>()
);

/* EDIT */
/* update page comment's comment */
export const UpdatePageComment = createAction(
  ActionTypes.UpdatePageComment,
  props<{ commentId: string; comment: CommentPartial }>()
);

export const UpdatePageCommentSuccessful = createAction(ActionTypes.UpdatePageCommentSuccessful);

export const UpdatePageCommentFailure = createAction(
  ActionTypes.UpdatePageCommentFailure,
  props<{ error: any; key: string; date: number }>()
);

/* DELETE */
export const DeletePageComment = createAction(
  ActionTypes.DeletePageComment,
  props<{ commentId: string; pageCommentId: string }>()
);

export const DeletePageCommentSuccessful = createAction(
  ActionTypes.DeletePageCommentSuccessful,
  props<{ commentId: string }>()
);

export const DeletePageCommentFailure = createAction(
  ActionTypes.DeletePageCommentFailure,
  props<{ error: any; key: string; date: number }>()
);
