import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DataSchemaInterface, ProjectInterface } from '@rappider/api-sdk';
import { DataSchemaService, NotificationService } from '@rappider/services';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Subscription } from 'rxjs';
import { DATA_SCHEMA_LIST_CONFIG } from './configs/data-schema-list-config';
import { DeleteDataSchema, GetDataSchemas } from '../../../../states/data-schema/data-schema.actions';
import { ActionResponse, BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { DataSchemaFieldRowPages } from '@rappider/shared/interfaces';
import { getDataSchemasWithDetailsSelector } from 'libs/project/src/lib/states/selectors/get-data-schemas-with-details.selector';
import { DataSchemaWithRelations } from '@rappider/rappider-sdk';

@Component({
  selector: 'rappider-data-schema-list',
  templateUrl: './data-schema-list.component.html',
  styleUrls: ['./data-schema-list.component.scss']
})
export class DataSchemaListComponent implements OnInit, OnDestroy {

  /* list config */
  DATA_SCHEMA_LIST_CONFIG = DATA_SCHEMA_LIST_CONFIG;
  /* main title */
  mainTitle: HeadingComponentConfig = {
    content: 'PROJECT_MODULE.DATA_SCHEMA_LIST_COMPONENT.DATA_SCHEMA_LIST',
    type: defaultToolbarTitleHeadingSize
  };
  /* page breadcrumb */
  title: string[] | BreadcrumbOption[];
  /* data schemas */
  dataSchemas: DataSchemaWithRelations[];
  /* project id */
  projectId: string;
  /* subscriptions */
  subscriptions: Subscription[];
  displayToolbar = false;
  displayToolbarBackButton = false;
  dataSchemaFieldRowPages = DataSchemaFieldRowPages.UserGenerated;
  loading = false;

  constructor(
    private store: Store<any>,
    private notificationService: NotificationService,
    private dataSchemaService: DataSchemaService
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToDataSchema(),
      this.subscribeToDataSchemaLoadingState()
    ];
  }

  /**
   * subscribe to active project to set the title
   *
   * @returns
   * @memberof DataSchemaCreateComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectInterface) => {
      if (activeProject) {
        this.title = [
          {
            label: activeProject.name,
            redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${activeProject?.id}`
          },
          {
            label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_SCHEMA_LIST.PAGE_TITLE
          }
        ];
        this.projectId = activeProject.id;
      }
    });
  }

  /**
   * subscribe to data schemas
   *
   * @returns
   * @memberof DataSchemaListComponent
   */
  subscribeToDataSchema() {
    return this.store.select(<any>getDataSchemasWithDetailsSelector).subscribe((dataSchemas: DataSchemaWithRelations[]) => {
      this.dataSchemas = this.dataSchemaService
        .getDataSchemasByDataSchemaFieldRowPages(this.projectId, this.dataSchemaFieldRowPages, dataSchemas);
      this.dataSchemas = this.dataSchemas.map(dataSchema => ({
        ...dataSchema,
        category: this.changedCategoryNameAsUserFriendly(dataSchema?.category)
      }));
    });
  }

  subscribeToDataSchemaLoadingState() {
    return this.store.select(state => state.dataSchema.loading).subscribe((loading: boolean) => {
      this.loading = loading;
    });
  }

  getDataSchemas() {
    this.store.dispatch(new GetDataSchemas());
  }

  onDataSchemaDelete(actionResponse: ActionResponse) {
    const dataSchema = <DataSchemaInterface>actionResponse.data;
    this.store.dispatch(new DeleteDataSchema({ dataSchemaId: dataSchema.id }));
  }

  onDataSchemaRefresh(actionResponse: ActionResponse) {
    if (actionResponse.action.name === 'SHARED.REFRESH') {
      this.getDataSchemas();
    }
  }

  changedCategoryNameAsUserFriendly(category: string) {
    switch (category) {
      case 'project-model':
        return 'Project Model Schema';
      case 'component-config':
        return 'Component Config Schema';
      case 'component-io-type':
        return 'Component Input / Output Type';
      case 'ui-data-event-payload':
        return 'UI Data Event Payload Schema';
      case 'ui-data-store-state':
        return 'UI Data Store State';
      case 'user-generated':
        return 'User Generated Schema';
      default:
        return category;
    }
  }

}
