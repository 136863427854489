import { ActionBehavior, CrudTableViewConfig, CrudViewColumnType, HeadingType } from '@rappider/rappider-components/utils';
import { AllProjectsItemActions } from './all-projects-item-actions.enum';

export const ALL_PROJECTS_CONFIG: CrudTableViewConfig = {
  pageSize: 10,
  showPagination: true,
  title: {
    type: HeadingType.H3,
    content: 'Projects'
  },
  itemActionsLength: 5,
  nzFrontPagination: false,
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'Name',
      fieldName: 'name',
      type: CrudViewColumnType.ActionLink,
      routerLink: '/admin-dashboard/project-detail/{{id}}'
    },
    {
      title: 'Created By',
      fieldName: 'createdBy',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Created Date',
      fieldName: 'createdDate',
      type: CrudViewColumnType.Date
    },
    {
      title: 'Pending Reviews For Pages',
      fieldName: 'pagesInReview',
      type: CrudViewColumnType.Text
    }
  ],
  itemActions: [
    {
      text: 'Dashboard',
      icon: { name: 'fa-solid fa-list' },
      name: AllProjectsItemActions.Dashboard,
      behavior: ActionBehavior.Emit
    },
    {
      text: 'Settings',
      icon: { name: 'fa-regular fa-gear' },
      name: AllProjectsItemActions.ChangeSettings,
      behavior: ActionBehavior.Emit
    }
    // currently delete and edit are not possible
    // {
    //   text: 'Edit',
    //   name: AllProjectsItemActions.Edit,
    //   behavior: ActionBehavior.Emit,
    //   icon: { name: 'far fa-edit' },
    // },
    // {
    //   text: 'Delete',
    //   icon: { name: 'far fa-trash' },
    //   name: AllProjectsItemActions.Delete,
    //   behavior: ActionBehavior.Emit
    // }
  ],
};
