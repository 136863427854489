import { PAGE_DEFINITIONS } from '../page-definition';

export const AUTH_REDIRECTION_DEFINITIONS = {
  REGISTER: {
    REGISTER_REDIRECTION_PATH: `/${PAGE_DEFINITIONS.AUTH.URL}/${PAGE_DEFINITIONS.AUTH.CHILDREN.REGISTER.URL}`,
  },
  LOGIN: {
    LOGIN_REDIRECTION_PATH: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_LIST.URL}`,
    LOGIN_WITH_NO_PROJECTS_REDIRECTION_PATH: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_CREATE.URL}`,
    LOGIN_WITH_PROJECT_LIST_REDIRECTION_PATH: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_LIST.URL}`,
    LOGIN_WITH_NO_TENANT_REDIRECTION_PATH: `/${PAGE_DEFINITIONS.TENANT.URL}/${PAGE_DEFINITIONS.TENANT.CHILDREN.TENANT_EDIT.URL}`,
    LOGIN_WITH_EMAIL_VERIFY_REDIRECTION_PATH: `/${PAGE_DEFINITIONS.AUTH.URL}/${PAGE_DEFINITIONS.AUTH.CHILDREN.ACCOUNT_VERIFICATION.URL}`,
    LOGIN_WITH_PHONE_NUMBER_VERIFY_REDIRECTION_PATH: `/${PAGE_DEFINITIONS.AUTH.URL}/${PAGE_DEFINITIONS.AUTH.CHILDREN.VERIFY_PHONE_NUMBER.URL}`,
    LOGIN_WITH_PERSON_SELECTION_REDIRECTION_PATH: `/${PAGE_DEFINITIONS.AUTH.URL}/${PAGE_DEFINITIONS.AUTH.CHILDREN.PERSON_SELECTION.URL}`,
  },
  LOGOUT: {
    LOGOUT_REDIRECTION_PATH: `/${PAGE_DEFINITIONS.AUTH.URL}/${PAGE_DEFINITIONS.AUTH.CHILDREN.LOGIN.URL}`
  }
};
