/* eslint-disable @typescript-eslint/semi */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SchemaTreeSelectComponent } from '../../modules/shared-components/schema-tree-select/schema-tree-select.component';
import { SchemaTreeService } from '../../services/schema-tree/schema-tree.service';
import { JsonSchema, Operator } from '../../shared';
import { DATA_TRANSFORMATION_LIST_CONFIG } from '../../shared/definitions/data-transformation-list-grid-config';
import { DataTransformationControllerService, DataTransformationItemControllerService, JsonSchemaControllerService, NewDataTransformationItem } from '@rappider/rappider-sdk'
import { NotificationService } from 'libs/components/src/lib/services';
import { ButtonComponentConfig, ButtonSize, ButtonType } from '@rappider/rappider-components/utils';
import { JoyrideService } from 'ngx-joyride';
import { ModalButtonOptions } from 'ng-zorro-antd/modal';
import { isEqual, omitBy } from 'lodash';

export interface TreeNode {
  name?: string;
  key?: string;
  children?: TreeNode[];
};

@Component({
  selector: 'rappider-data-transformation',
  templateUrl: './data-transformation.component.html',
  styleUrls: ['./data-transformation.component.scss']
})
export class DataTransformationComponent implements OnInit, OnChanges {
  @ViewChild('currentTreeSelectComponent') currentTreeSelectComponent: SchemaTreeSelectComponent;
  @ViewChild('treeSelectComponent') treeSelectComponent: SchemaTreeSelectComponent;

  @Input() dataTransformationId: string;
  @Input() schema: JsonSchema;
  @Input() targetSchema: JsonSchema;
  @Input() dataTransformationFlag: boolean;

  @Output() showStepsFlag = new EventEmitter<{ flag: boolean; key: string }>();

  /**
   * We use this variable to make comparisons with the original data.
   * @type {*}
   * @memberof DataTransformationComponent
   */
  tempEditedProcess: any;

  sourceSchema: JsonSchema;
  tree: any;
  currentTree;
  modifiedTree;
  dataTransformationItems: any;
  DATA_TRANSFORMATION_LIST_CONFIG = DATA_TRANSFORMATION_LIST_CONFIG;
  targetTree;

  /* filter | find | sort | map */
  addedOperationType;

  /* filter | find | sort | map */
  editedOperationType;

  /* Filter */
  isAddFilterModalVisible = false;
  newFilterProcess;
  isEditFilterModalVisible = false;
  editedFilterProcess;

  /* Data Mapping */
  isAddDataMappingModalVisible = false;
  addedDataMappingProcess;
  isEditDataMappingModalVisible = false;
  editedDataMappingProcess;
  dataMappingOutput = null;

  /* sort */
  isAddSortModalVisible = false;
  newSortProcess;
  isEditSortModalVisible = false;
  editedSortProcess;

  dtStep = 'dtFirstStep';

  /* flags */
  filterFindStepsFlag = false;
  mapStepsFlag = false;
  sortStepsFlag = false;

  /* DT Functions */
  functions;

  addDataTransformationItemButton: ButtonComponentConfig = {
    type: ButtonType.Default,
    size: ButtonSize.Small
  };

  constructor(
    private schemaTreeService: SchemaTreeService,
    private dataTransformationApi: DataTransformationControllerService,
    private dataTransformationItemApi: DataTransformationItemControllerService,
    private notificationService: NotificationService,
    private jsonSchemaApi: JsonSchemaControllerService,
    private readonly joyrideService: JoyrideService
  ) { }

  ngOnInit(): void {
    this.getSchema();
    this.getDataTransformation();
    this.getFunctions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataTransformationFlag) {
      this.showSteps();
    }
    if (changes.schema || changes.targetSchema) {
      this.getSchema();
      this.getDataTransformation();
      this.getFunctions();
    }
  }

  showSteps() {
    if (this.dataTransformationFlag) {
      this.joyrideService.startTour(
        { steps: ['dtFirstStep', 'dtSecondStep'] }
      );
    }
    this.dataTransformationFlag = false;
    this.showStepsFlag.emit({ flag: this.dataTransformationFlag, key: 'data-transformation' });
  }

  /* TODO: Remove this fn. it's unneccessary and shouldn't be here */
  getSchema() {
    this.tree = this.schemaTreeService.buildTreeFromJSONSchema(this.schema);
    this.currentTree = this.tree;

    if (this.targetSchema) {
      this.targetTree = this.schemaTreeService.buildTreeFromJSONSchema(this.targetSchema);
    }
  }

  getDataTransformation() {
    this.dataTransformationApi.findById({ id: this.dataTransformationId, filter: { include: ['items'] } }).subscribe(dataTransformation => {
      this.dataTransformationItems = dataTransformation.items?.map(item => {
        const flatOperationField = item.config.operationField?.map(item => item.name)?.join('.');

        /* We add the script field only if the type of operation is "map" */
        if (item.config.operator === 'map') {
          const formattedScript = item.config.script.startsWith('\r\n') ?
            item.config.script : '\r\n' + item.config.script;
          return {
            ...item,
            flatOperationField: flatOperationField || 'Root',
            operator: item.config.operator,
            config: {
              ...item.config,
              script: formattedScript,
              flatOperationField: flatOperationField
            }
          };
        } else {
          return {
            ...item,
            flatOperationField: flatOperationField || 'Root',
            operator: item.config.operator,
            config: {
              ...item.config,
              flatOperationField: flatOperationField
            }
          };
        }
      }) || [];
    });
  }

  getFunctions() {
    this.dataTransformationApi.getDataTransformationItemOperationFunctions().subscribe(functions => {
      this.functions = functions;
    });
  }

  buildCurrentTree(operationField, jsonSchema) {
    const operationFieldNode = this.treeSelectComponent.getSelectedTreeNodeAsTree(operationField);
    if (operationFieldNode) {
      return this.schemaTreeService.buildTreeNodeFromSchema(
        this.schemaTreeService.getDefinitionByRefName(
          operationFieldNode?.origin?.data?.items?.$ref || operationFieldNode?.origin?.data.$ref,
          jsonSchema
        ),
        null,
        jsonSchema
      );
    } else {
      return this.schemaTreeService.buildTreeFromJSONSchema(jsonSchema);
    }
  }

  getOperationFieldAsString(operationField: any[]) {
    return operationField?.map(item => item.name)?.join('.');
  }

  onColumnActionClick(event) {
    const dataTransformationProcess = event.data;
    const dtItemIndex = event.rowIndex;
    if (event.action.name === 'Edit') {
      this.buildTreeAccordingToPreviousMapOperation(dataTransformationProcess, dtItemIndex);
      /* we assign the data to be updated to the tempEditedProcess variable for comparison. */
      if (
        (dataTransformationProcess.operator === Operator.Filter || dataTransformationProcess.operator === Operator.Find)
      ) {
        this.editedFilterProcess = dataTransformationProcess;
        this.tempEditedProcess = dataTransformationProcess;
        this.openEditFilterModal();
      } else if (dataTransformationProcess.operator === Operator.Map) {
        this.editedDataMappingProcess = dataTransformationProcess;
        this.tempEditedProcess = dataTransformationProcess;
        this.openEditDataMappingModal();
      } else if (dataTransformationProcess.operator === Operator.Sort) {
        this.editedSortProcess = dataTransformationProcess;
        this.tempEditedProcess = dataTransformationProcess;
        this.openEditSortModal();
      }
    } else if (event.action.name === 'Delete') {
      this.dataTransformationItemApi.deleteById({ id: dataTransformationProcess.id }).subscribe(() => {
        this.dataTransformationItems = this.dataTransformationItems.filter(item => item.id !== dataTransformationProcess.id);
      });
    }
    this.editedOperationType = dataTransformationProcess.operator;
  }

  buildSourceSchemaAccordingToPreviousMapOperation(dtItemIndex) {
    const previousMapOperation = this.dataTransformationItems?.filter(dtItem => dtItem.config.operator !== 'sort')?.[dtItemIndex - 1];
    if (previousMapOperation) {
      try {
        this.sourceSchema = JSON.parse(previousMapOperation.config.targetJsonSchema);
      } catch {
        this.sourceSchema = previousMapOperation.config.targetJsonSchema;
      }
    } else {
      this.sourceSchema = this.schema;
    }
  }

  buildTreeAccordingToPreviousMapOperation(dataTransformationProcess, dtItemIndex) {
    const previousMapOperation = this.dataTransformationItems.find((dtItem, index) => index < dtItemIndex && dtItem.config.operator !== 'sort');
    if (previousMapOperation) {
      /* TOOD: REMOVE json.parse when backend fixed */
      try {
        this.currentTree = this.schemaTreeService.buildTreeFromJSONSchema(JSON.parse(previousMapOperation.config.targetJsonSchema));
      } catch {
        this.currentTree = this.schemaTreeService.buildTreeFromJSONSchema(previousMapOperation.config.targetJsonSchema);
      }

    } else if (dataTransformationProcess?.config?.operationField?.length > 0) {
      this.currentTree = this.buildCurrentTree(
        this.getOperationFieldAsString(dataTransformationProcess.config?.operationField),
        this.schema
      );
    } else {
      this.currentTree = this.tree;
    }
  }

  // #region Filter Find


  // #region filter-find modal handling

  openAddFilterModal(operation) {
    this.addedOperationType = operation;
    this.newFilterProcess = {
      config: {
        operator: operation,
        operationField: [],
        operation: {
          logic: null
        }
      }
    };
    this.buildTreeAccordingToPreviousMapOperation(this.newFilterProcess, this.dataTransformationItems.length);
    this.isAddFilterModalVisible = true;
  }

  closeAddFilterModal() {
    this.currentTree = this.tree;
    this.newFilterProcess = null;
    this.isAddFilterModalVisible = false;
  }

  openEditFilterModal() {
    this.isEditFilterModalVisible = true;
  }

  closeEditFilterModal() {
    this.isEditFilterModalVisible = false;
    this.editedFilterProcess = null;
    this.tempEditedProcess = null;
  }

  // #endregion filter-find modal handling

  saveFilter() {

    const changedFields = omitBy(this.editedFilterProcess, (value, key) => isEqual(value, this.tempEditedProcess[key]));

    const editedFilterProcess = {
      ...changedFields,
      config: this.editedFilterProcess.config
    };

    this.dataTransformationItemApi.updateById({ id: this.editedFilterProcess.id, body: editedFilterProcess }).subscribe(editedFilterProcessResponse => {
      const dataTransformationItem = {
        ...editedFilterProcess,
        flatOperationField: editedFilterProcess.config.operationField.map(item => item.name).join('.'),
        operator: editedFilterProcess.config.operator
      };
      this.dataTransformationItems = this.dataTransformationItems.filter(item => item.id !== this.editedFilterProcess.id);
      this.dataTransformationItems.push(dataTransformationItem)
      this.closeEditFilterModal();
    });
  }

  newFilter() {
    const filterReqBody = {
      body: <NewDataTransformationItem>{
        config: this.newFilterProcess.config,
        dataTransformationId: this.dataTransformationId,
        index: this.dataTransformationItems.length
      }
    };
    this.dataTransformationItemApi.create(filterReqBody).subscribe(dataTransformationItem => {
      this.dataTransformationItems = [
        ...this.dataTransformationItems,
        {
          ...dataTransformationItem,
          flatOperationField: dataTransformationItem.config.operationField.map(item => item.name).join('.'),
          operator: dataTransformationItem.config.operator
        }
      ];
      this.closeAddFilterModal();
    })
  }
  // #endregion

  // #region Map

  // #region data mapping modal handling

  openAddDataMappingModal() {
    this.addedOperationType = 'map';
    this.addedDataMappingProcess = {
      config: {
        operator: 'map',
        script: null
      }
    };
    this.buildSourceSchemaAccordingToPreviousMapOperation(this.dataTransformationItems?.length || 0);
    this.isAddDataMappingModalVisible = true;
  }

  closeAddDataMapping() {
    this.isAddDataMappingModalVisible = false;
    this.addedDataMappingProcess = null;
    this.dataMappingOutput = null;
  }

  openEditDataMappingModal() {
    this.isEditDataMappingModalVisible = true;
    const dtIndex = this.dataTransformationItems?.filter(dtItem => dtItem.config.operator !== 'sort')
      ?.findIndex(dtItem => dtItem.id === this.editedDataMappingProcess.id);
    this.buildSourceSchemaAccordingToPreviousMapOperation(dtIndex);
  }

  closeEditDataMappingModal() {
    this.isEditDataMappingModalVisible = false;
    this.editedDataMappingProcess = null;
    this.tempEditedProcess = null;
    this.dataMappingOutput = null;
  }

  // #endregion data mapping modal handling

  addDataMapping() {
    const body = {
      schemaName: 'dtOutput' + this.dataTransformationItems.length,
      data: JSON.parse(this.dataMappingOutput)
    };

    this.jsonSchemaApi.generateJsonSchemaByJsonData({ body: body }).subscribe(res => {
      const params = {
        body: {
          config: {
            ...this.addedDataMappingProcess.config,
            targetJsonSchema: JSON.stringify(res)
          },
          dataTransformationId: this.dataTransformationId,
          index: this.dataTransformationItems.length
        }
      };

      this.dataTransformationItemApi.create(params).subscribe(res => {

        const formattedScript = res.config.script.startsWith('\r\n') ?
          res.config.script : '\r\n' + res.config.script;

        this.dataTransformationItems = [
          ...this.dataTransformationItems,
          {
            ...res,
            config: {
              ...res.config,
              script: formattedScript
            },
            operator: res.config.operator,
            flatOperationField: res.config.operationField?.map(item => item.name).join('.')
          }
        ];
        this.closeAddDataMapping();
      });
    })
  }

  onDataMappingOutputChange(output) {
    this.dataMappingOutput = output;
  }

  onDataMappingAdd() {
    this.addedDataMappingProcess = { ...this.addedDataMappingProcess };
  }

  editDataMapping() {
    delete this.editedDataMappingProcess.flatOperationField;
    delete this.editedDataMappingProcess.config.flatOperationField;
    const body = {
      schemaName: 'dtOutput' + this.dataTransformationItems.length,
      data: JSON.parse(this.dataMappingOutput)
    };

    this.jsonSchemaApi.generateJsonSchemaByJsonData({ body: body }).subscribe(res => {

      const changedFields = omitBy(this.editedDataMappingProcess, (value, key) => isEqual(value, this.tempEditedProcess[key]));

      const reqBody = {
        id: this.editedDataMappingProcess.id,
        body: {
          ...changedFields,
          dataTransformationId: this.editedDataMappingProcess.dataTransformationId,
          config: {
            ...this.editedDataMappingProcess.config,
            targetJsonSchema: res
          }
        }
      };
      this.dataTransformationItemApi.updateById(reqBody).subscribe(() => {

        const formattedScript = reqBody.body.config.script.startsWith('\r\n') ?
          reqBody.body.config.script : '\r\n' + reqBody.body.config.script;

        this.dataTransformationItems = [
          ...<any[]>this.dataTransformationItems?.filter((item: any) => item.id !== reqBody.id),
          {
            ...reqBody.body,
            config: {
              ...reqBody.body.config,
              script: formattedScript
            },
            operator: reqBody.body.config.operator,
            flatOperationField: reqBody.body.config?.operationField?.map(item => item.name)?.join('.')
          }
        ];
        this.closeEditDataMappingModal();
      });
    });
  }

  onOperationFieldChange(process) {
    this.currentTree = this.buildCurrentTree(
      this.getOperationFieldAsString(process.config.operationField),
      this.schema
    );
    process.flatOperationField = process.config.operationField.map(item => item.name).join('.');
  }

  onDataMappingEdit() {
    this.editedDataMappingProcess = { ...this.editedDataMappingProcess };
  }
  // #endregion

  /**
   * isEditMode add mi edit mi olduğunu anlamak için
   *
   * @param {*} jsonSchema
   * @param {*} isEditMode
   * @memberof DataTransformationComponent
   */
  onJsonSchemaModified(jsonSchemaInformation: { jsonSchema: any; schemaType: 'target' | 'source' }, isEditMode = true,) {
    let operationField;
    if (isEditMode) {
      operationField = this.editedDataMappingProcess?.flatOperationField;
    } else {
      operationField = this.addedDataMappingProcess.flatOperationField || this.getOperationFieldAsString(
        this.addedDataMappingProcess.config?.operationField
      ) || [];
    }
    if (jsonSchemaInformation.schemaType === 'target') {
      this.targetTree = this.buildCurrentTree(operationField, jsonSchemaInformation.jsonSchema);
    } else {
      this.modifiedTree = this.buildCurrentTree(operationField, jsonSchemaInformation.jsonSchema);
    }
  }

  // #region SORT

  // #region sort modal handling

  openAddSortModal() {
    this.isAddSortModalVisible = true;
    this.addedOperationType = 'sort';
    this.newSortProcess = {
      config: {
        operator: 'sort',
        operationField: [],
        operations: []
      }
    };
    this.buildTreeAccordingToPreviousMapOperation(this.newSortProcess, this.dataTransformationItems.length);
  }

  closeAddSortModal() {
    this.isAddSortModalVisible = false;
    this.newSortProcess = null;
  }

  openEditSortModal() {
    this.isEditSortModalVisible = true;
  }

  closeEditSortModal() {
    this.isEditSortModalVisible = false;
    this.editedSortProcess = null;
    this.tempEditedProcess = null;
  }

  // #endregion sort modal handling


  newSort() {
    const params = {
      body: {
        config: this.newSortProcess.config,
        dataTransformationId: this.dataTransformationId,
        index: this.dataTransformationItems.length
      }
    }

    this.dataTransformationItemApi.create(params).subscribe(() => {
      this.notificationService.createNotification(
        'success',
        'SHARED.SUCCESS',
        'Data transformation item created successfuly'
      );
      const dataTransformationItem = {
        ...params.body,
        flatOperationField: params.body.config.operationField.map(item => item.name).join('.'),
        operator: params.body.config.operator
      };
      this.dataTransformationItems = [
        ...this.dataTransformationItems,
        dataTransformationItem
      ];
      this.closeAddSortModal();
    });

  }

  editSort() {
    const params = {
      id: this.editedSortProcess.id,
      body: {
        config: this.editedSortProcess.config,
        dataTransformationId: this.dataTransformationId,
      }
    };

    delete params.body.config.flatOperationField;

    this.dataTransformationItemApi.updateById(params).subscribe(() => {
      this.notificationService.createNotification(
        'success',
        'SHARED.SUCCESS',
        'Data transformation item updated successfuly'
      );

      const dataTransformationItem = {
        ...params.body,
        flatOperationField: params.body.config.operationField.map(item => item.name).join('.'),
        operator: params.body.config.operator
      };

      this.dataTransformationItems = [
        ...this.dataTransformationItems.filter(item => item.id !== params.id),
        dataTransformationItem
      ]
      this.closeEditSortModal();
    });
  }

  // #endregion SORT

  setStepsFlag(key: string) {
    if (key === 'filter-find') {
      this.filterFindStepsFlag = true;
    } else if (key === 'map') {
      this.mapStepsFlag = true;
    } else {
      this.sortStepsFlag = true;
    }
  }

  onShowStepsFlag(value: { flag: boolean; key: string }) {
    if (value.key === 'map') {
      this.mapStepsFlag = value.flag;
    } else if (value.key === 'filter-find') {
      this.filterFindStepsFlag = value.flag;
    } else {
      this.sortStepsFlag = value.flag;
    }
  }
}
