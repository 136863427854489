/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Comment } from '../models/comment';
import { CommentPartial } from '../models/comment-partial';
import { LastProcessInformation } from '../models/last-process-information';
import { NewCommentInUiWorkflowStepFunction } from '../models/new-comment-in-ui-workflow-step-function';
import { NewUiWorkflowStepFunction } from '../models/new-ui-workflow-step-function';
import { UiWorkflowStepFunction } from '../models/ui-workflow-step-function';
import { UiWorkflowStepFunctionCreateDto } from '../models/ui-workflow-step-function-create-dto';
import { UiWorkflowStepFunctionGetJsonSchemaDto } from '../models/ui-workflow-step-function-get-json-schema-dto';
import { UiWorkflowStepFunctionPartial } from '../models/ui-workflow-step-function-partial';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class UiWorkflowStepFunctionControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation uiWorkflowStepFunctionControllerCalculateJsonSchema
   */
  static readonly UiWorkflowStepFunctionControllerCalculateJsonSchemaPath = '/ui-workflow-step-functions/calculate-json-schema';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calculateJsonSchema()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calculateJsonSchema$Response(params?: {
    body?: UiWorkflowStepFunctionGetJsonSchemaDto
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, UiWorkflowStepFunctionControllerService.UiWorkflowStepFunctionControllerCalculateJsonSchemaPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calculateJsonSchema$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calculateJsonSchema(params?: {
    body?: UiWorkflowStepFunctionGetJsonSchemaDto
  }): Observable<any> {

    return this.calculateJsonSchema$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation uiWorkflowStepFunctionControllerCount
   */
  static readonly UiWorkflowStepFunctionControllerCountPath = '/ui-workflow-step-functions/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params?: {
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, UiWorkflowStepFunctionControllerService.UiWorkflowStepFunctionControllerCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params?: {
    where?: any;
  }): Observable<LoopbackCount> {

    return this.count$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation uiWorkflowStepFunctionControllerGetJsonSchema
   */
  static readonly UiWorkflowStepFunctionControllerGetJsonSchemaPath = '/ui-workflow-step-functions/get-json-schema/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getJSONSchema()` instead.
   *
   * This method doesn't expect any request body.
   */
  getJSONSchema$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, UiWorkflowStepFunctionControllerService.UiWorkflowStepFunctionControllerGetJsonSchemaPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getJSONSchema$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getJSONSchema(params: {
    id: string;
  }): Observable<any> {

    return this.getJSONSchema$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation uiWorkflowStepFunctionControllerFindLatestChangeTimestamps
   */
  static readonly UiWorkflowStepFunctionControllerFindLatestChangeTimestampsPath = '/ui-workflow-step-functions/latest-change-timestamps';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findLatestChangeTimestamps()` instead.
   *
   * This method doesn't expect any request body.
   */
  findLatestChangeTimestamps$Response(params?: {
  }): Observable<StrictHttpResponse<LastProcessInformation>> {

    const rb = new RequestBuilder(this.rootUrl, UiWorkflowStepFunctionControllerService.UiWorkflowStepFunctionControllerFindLatestChangeTimestampsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LastProcessInformation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findLatestChangeTimestamps$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findLatestChangeTimestamps(params?: {
  }): Observable<LastProcessInformation> {

    return this.findLatestChangeTimestamps$Response(params).pipe(
      map((r: StrictHttpResponse<LastProcessInformation>) => r.body as LastProcessInformation)
    );
  }

  /**
   * Path part for operation uiWorkflowStepFunctionControllerFindServices
   */
  static readonly UiWorkflowStepFunctionControllerFindServicesPath = '/ui-workflow-step-functions/services';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findServices()` instead.
   *
   * This method doesn't expect any request body.
   */
  findServices$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<UiWorkflowStepFunction>>> {

    const rb = new RequestBuilder(this.rootUrl, UiWorkflowStepFunctionControllerService.UiWorkflowStepFunctionControllerFindServicesPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UiWorkflowStepFunction>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findServices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findServices(params?: {
    filter?: any;
  }): Observable<Array<UiWorkflowStepFunction>> {

    return this.findServices$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UiWorkflowStepFunction>>) => r.body as Array<UiWorkflowStepFunction>)
    );
  }

  /**
   * Path part for operation uiWorkflowStepFunctionControllerFindComments
   */
  static readonly UiWorkflowStepFunctionControllerFindCommentsPath = '/ui-workflow-step-functions/{id}/comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findComments()` instead.
   *
   * This method doesn't expect any request body.
   */
  findComments$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<Array<Comment>>> {

    const rb = new RequestBuilder(this.rootUrl, UiWorkflowStepFunctionControllerService.UiWorkflowStepFunctionControllerFindCommentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Comment>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findComments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findComments(params: {
    id: string;
    filter?: any;
  }): Observable<Array<Comment>> {

    return this.findComments$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Comment>>) => r.body as Array<Comment>)
    );
  }

  /**
   * Path part for operation uiWorkflowStepFunctionControllerCreateComment
   */
  static readonly UiWorkflowStepFunctionControllerCreateCommentPath = '/ui-workflow-step-functions/{id}/comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createComment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createComment$Response(params: {
    id: string;
    body?: NewCommentInUiWorkflowStepFunction
  }): Observable<StrictHttpResponse<Comment>> {

    const rb = new RequestBuilder(this.rootUrl, UiWorkflowStepFunctionControllerService.UiWorkflowStepFunctionControllerCreateCommentPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Comment>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createComment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createComment(params: {
    id: string;
    body?: NewCommentInUiWorkflowStepFunction
  }): Observable<Comment> {

    return this.createComment$Response(params).pipe(
      map((r: StrictHttpResponse<Comment>) => r.body as Comment)
    );
  }

  /**
   * Path part for operation uiWorkflowStepFunctionControllerDeleteComment
   */
  static readonly UiWorkflowStepFunctionControllerDeleteCommentPath = '/ui-workflow-step-functions/{id}/comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteComment()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteComment$Response(params: {
    id: string;
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, UiWorkflowStepFunctionControllerService.UiWorkflowStepFunctionControllerDeleteCommentPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteComment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteComment(params: {
    id: string;
    where?: any;
  }): Observable<LoopbackCount> {

    return this.deleteComment$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation uiWorkflowStepFunctionControllerUpdateComment
   */
  static readonly UiWorkflowStepFunctionControllerUpdateCommentPath = '/ui-workflow-step-functions/{id}/comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateComment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateComment$Response(params: {
    id: string;
    where?: any;
    body?: CommentPartial
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, UiWorkflowStepFunctionControllerService.UiWorkflowStepFunctionControllerUpdateCommentPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateComment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateComment(params: {
    id: string;
    where?: any;
    body?: CommentPartial
  }): Observable<LoopbackCount> {

    return this.updateComment$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation uiWorkflowStepFunctionControllerFindById
   */
  static readonly UiWorkflowStepFunctionControllerFindByIdPath = '/ui-workflow-step-functions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<UiWorkflowStepFunction>> {

    const rb = new RequestBuilder(this.rootUrl, UiWorkflowStepFunctionControllerService.UiWorkflowStepFunctionControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UiWorkflowStepFunction>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
    filter?: any;
  }): Observable<UiWorkflowStepFunction> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<UiWorkflowStepFunction>) => r.body as UiWorkflowStepFunction)
    );
  }

  /**
   * Path part for operation uiWorkflowStepFunctionControllerDeleteById
   */
  static readonly UiWorkflowStepFunctionControllerDeleteByIdPath = '/ui-workflow-step-functions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, UiWorkflowStepFunctionControllerService.UiWorkflowStepFunctionControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: string;
  }): Observable<any> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation uiWorkflowStepFunctionControllerCustomUpdateById
   */
  static readonly UiWorkflowStepFunctionControllerCustomUpdateByIdPath = '/ui-workflow-step-functions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customUpdateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customUpdateById$Response(params: {
    id: string;
    body?: UiWorkflowStepFunctionPartial
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, UiWorkflowStepFunctionControllerService.UiWorkflowStepFunctionControllerCustomUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customUpdateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customUpdateById(params: {
    id: string;
    body?: UiWorkflowStepFunctionPartial
  }): Observable<any> {

    return this.customUpdateById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation uiWorkflowStepFunctionControllerFind
   */
  static readonly UiWorkflowStepFunctionControllerFindPath = '/ui-workflow-step-functions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<UiWorkflowStepFunction>>> {

    const rb = new RequestBuilder(this.rootUrl, UiWorkflowStepFunctionControllerService.UiWorkflowStepFunctionControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UiWorkflowStepFunction>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<UiWorkflowStepFunction>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UiWorkflowStepFunction>>) => r.body as Array<UiWorkflowStepFunction>)
    );
  }

  /**
   * Path part for operation uiWorkflowStepFunctionControllerCreate
   */
  static readonly UiWorkflowStepFunctionControllerCreatePath = '/ui-workflow-step-functions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    body?: NewUiWorkflowStepFunction
  }): Observable<StrictHttpResponse<UiWorkflowStepFunctionCreateDto>> {

    const rb = new RequestBuilder(this.rootUrl, UiWorkflowStepFunctionControllerService.UiWorkflowStepFunctionControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UiWorkflowStepFunctionCreateDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    body?: NewUiWorkflowStepFunction
  }): Observable<UiWorkflowStepFunctionCreateDto> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<UiWorkflowStepFunctionCreateDto>) => r.body as UiWorkflowStepFunctionCreateDto)
    );
  }

}
