<rappider-internal-title-toolbar [mainTitle]="mainTitle"
                                 [options]="title"
                                 [displayToolbar]="displayToolbar"
                                 [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-internal-title-toolbar>

<section *ngIf="activeProject">
  <rappider-crud-view-edit-form [config]="PAGE_CREATE_FORM_CONFIG"
                                [data]="pageCreateData"
                                [submitted]="isPageCreateFormSubmitted"
                                [submitButtonLoading]="submitButtonLoading"
                                (buttonClick)="onCreatePageFormButtonClick($event)"
                                (fieldValueChange)="onCreatePageFieldValueChange($event)"
                                (submitButtonClick)="submitButtonClick($event)"
                                (formSubmit)="createPage($event)">
  </rappider-crud-view-edit-form>
</section>
