export const SupportedTypes = [
  'string',
  'integer',
  'boolean',
  'array',
  'number',
  'null',
  'object',
  'date',
  'enum'
];

export const SupportedFormats = [
  'date-time',
  'uuid'
];
