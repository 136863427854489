import { createSelector } from '@ngrx/store';
import { ProjectModelWithRelations, UiDataEventWithRelations, UiDataStoreWithRelations, UiWorkflowStepFunctionWithRelations } from '@rappider/rappider-sdk';

export const uiStepFunctionEditData = createSelector(
  state => state['projectModel']?.loading,
  state => state['projectModel']?.data,
  state => state['uiDataStore']?.loading,
  state => state['uiDataStore']?.data,
  state => state['uiDataEvent']?.loading,
  state => state['uiDataEvent']?.data,
  state => state['uiWorkflowStepFunction']?.loading,
  state => state['uiWorkflowStepFunction']?.data,
  (
    isProjectModelsLoading: boolean,
    projectModels: ProjectModelWithRelations[],
    isUIDataStoresLoading: boolean,
    uiDataStores: UiDataStoreWithRelations[],
    isUIDataEventsLoading: boolean,
    uiDataEvents: UiDataEventWithRelations[],
    isUIStepFunctionLoading: boolean,
    uiStepFunctions: UiWorkflowStepFunctionWithRelations[]
  ) => {
    if (isProjectModelsLoading || isUIDataStoresLoading || isUIDataEventsLoading || isUIStepFunctionLoading) {
      return {
        isLoading: true,
        data: null
      };
    } else {
      return {
        isLoading: false,
        data: {
          projectModels: projectModels,
          uiDataStores: uiDataStores,
          uiDataEvents: uiDataEvents,
          uiStepFunctions: uiStepFunctions
        }
      };
    }
  }
);
