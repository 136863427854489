import { createAction, createReducer, on } from '@ngrx/store';
import { ProjectDocument } from '@rappider/rappider-sdk';
/* actions */
import * as ProjectDocumentActions from './project-document.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

/* state key */
export const featureKey = 'projectDocument';

/* state interface */
export interface State {
  data: ProjectDocument[];
  loading: boolean;
  source: any;
  error: {
    error: any;
    key: string;
    timestamp: number;
  };
  modalVisibility: boolean;
}

/* initial values */
export const initialState: State = {
  data: null,
  loading: false,
  error: null,
  source: null,
  modalVisibility: false
};

const ChangeActiveProjectSuccessful = createAction(AuthenticationActions.ActionTypes.ChangeActiveProjectSuccessful);
const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

export const reducer = createReducer(
  initialState,

  on(ProjectDocumentActions.GetProjectDocuments, (state) => ({
    ...state,
    loading: true
  })),

  on(ProjectDocumentActions.GetProjectDocumentsSuccessful, (state, action) => ({
    ...state,
    loading: false,
    data: action.payload.projectDocuments
  })),

  on(ProjectDocumentActions.CreateProjectDocument, (state) => ({
    ...state,
    loading: true
  })),

  on(ProjectDocumentActions.CreateProjectDocumentSuccessful, (state, action) => ({
    ...state,
    loading: false,
    data: action.payload.projectDocuments
  })),

  on(ProjectDocumentActions.CreateProjectDocumentUpload, (state) => ({
    ...state,
    loading: true
  })),

  on(ProjectDocumentActions.CreateProjectDocumentUploadSuccessful, (state, action) => ({
    ...state,
    loading: false,
    data: [
      ...state.data,
      action.payload.projectDocuments
    ],
  })),

  on(ProjectDocumentActions.CreateProjectDocumentUploadFailure, (state, action) => ({
    ...state,
    loading: false,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    }
  })),

  on(ProjectDocumentActions.GetProjectDocumentUploadSuccessful, (state, action) => ({
    ...state,
    loading: false,
    source: action.payload.source,
    modalVisibility: true
  })),

  on(ProjectDocumentActions.ProjectDocumentModalVisibility, (state, action) => ({
    ...state,
    modalVisibility: action.payload.modalVisibility
  })),

  on(ProjectDocumentActions.UpdateProjectDocument, (state) => ({
    ...state,
    loading: true
  })),

  on(ProjectDocumentActions.UpdateProjectDocumentSuccessful, (state, action) => ({
    ...state,
    loading: false,
    data: action.payload.projectDocuments
  })),

  on(ProjectDocumentActions.DeleteProjectDocument, (state) => ({
    ...state,
    loading: true
  })),

  on(ProjectDocumentActions.DeleteProjectDocumentSuccessful, (state, action) => ({
    ...state,
    loading: false,
    data: action.payload.projectDocuments
  })),

  on(ProjectDocumentActions.ErrorAction, (state, action) => ({
    ...state,
    loading: false,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    }
  })),
  on(ChangeActiveProjectSuccessful, () => (initialState)),
  on(Logout, () => (initialState))
);
