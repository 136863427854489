import { failureLottieAnimation } from '../../lottie-animations/failure-lottie-animation';
import { successfulLottieAnimation } from '../../lottie-animations/successful-lottie-animation';
import { ProjectExportEventType } from '../project-export-event-type.enum';
import { ProjectExportProcessStatus } from '../project-export-process-status.enum';

export const AngularProjectExportProcesses = [

  // #region Export Angular Process

  {
    status: ProjectExportProcessStatus.Processing,
    key: ProjectExportEventType.ExportAngular,
    animation: {
      options: {
        path: 'assets/animations/frontend-exporting.json'
      },
      height: '100%',
      width: '100%'
    }
  },
  {
    status: ProjectExportProcessStatus.Successful,
    key: ProjectExportEventType.ExportAngularSuccessful,
    animation: successfulLottieAnimation
  },
  {
    status: ProjectExportProcessStatus.Processing,
    key: ProjectExportEventType.ExportAngularFailure,
    animation: failureLottieAnimation
  },

  // #region Angular Update Rappider Components

  {
    status: ProjectExportProcessStatus.Processing,
    key: ProjectExportEventType.AngularUpdateRappiderComponents,
    animation: {
      options: {
        path: 'assets/animations/angular-components-updating.json'
      },
      height: '100%',
      width: '100%'
    }
  },
  {
    status: ProjectExportProcessStatus.Successful,
    key: ProjectExportEventType.AngularUpdateRappiderComponentsSuccessful,
    animation: successfulLottieAnimation
  },
  {
    status: ProjectExportProcessStatus.Processing,
    key: ProjectExportEventType.AngularUpdateRappiderComponentsFailure,
    animation: failureLottieAnimation
  },

  // #endregion

  // #region Angular Copy Rappider Components Folder To Project

  {
    status: ProjectExportProcessStatus.Processing,
    key: ProjectExportEventType.AngularCopyRappiderComponentsFolderToProject,
    animation: {
      options: {
        path: 'assets/animations/rappider-components-copying.json'
      },
      height: '100%',
      width: '100%'
    }
  },
  {
    status: ProjectExportProcessStatus.Successful,
    key: ProjectExportEventType.AngularCopyRappiderComponentsFolderToProjectSuccessful,
    animation: successfulLottieAnimation
  },
  {
    status: ProjectExportProcessStatus.Processing,
    key: ProjectExportEventType.AngularCopyRappiderComponentsFolderToProjectFailure,
    animation: failureLottieAnimation
  },

  // #endregion

  // #region Angular Update Rappider Styles

  {
    status: ProjectExportProcessStatus.Processing,
    key: ProjectExportEventType.AngularUpdateRappiderStyles,
    animation: {
      options: {
        path: 'assets/animations/angular-styles-updating.json'
      },
      height: '100%',
      width: '100%'
    }
  },
  {
    status: ProjectExportProcessStatus.Successful,
    key: ProjectExportEventType.AngularUpdateRappiderStylesSuccessful,
    animation: successfulLottieAnimation
  },
  {
    status: ProjectExportProcessStatus.Processing,
    key: ProjectExportEventType.AngularUpdateRappiderStylesFailure,
    animation: failureLottieAnimation
  },

  // #endregion

  // #region Angular Copy Rappider Styles Folder To Project

  {
    status: ProjectExportProcessStatus.Processing,
    key: ProjectExportEventType.AngularCopyRappiderStylesFolderToProject,
    animation: {
      options: {
        path: 'assets/animations/rappider-styles-copying.json'
      },
      height: '100%',
      width: '100%'
    }
  },
  {
    status: ProjectExportProcessStatus.Successful,
    key: ProjectExportEventType.AngularCopyRappiderStylesFolderToProjectSuccessful,
    animation: successfulLottieAnimation
  },
  {
    status: ProjectExportProcessStatus.Processing,
    key: ProjectExportEventType.AngularCopyRappiderStylesFolderToProjectFailure,
    animation: failureLottieAnimation
  },

  // #endregion

  // #endregion

];
