/* eslint-disable */
import { ComponentDefinition, FieldType } from '../index';

declare var Object: any;
export interface ComponentOutputDefinitionInterface {
  id?: string;
  title?: string;
  fieldName?: string;
  description?: string;
  index?: number;
  componentDefinitionId?: string;
  fieldTypeId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  componentDefinition?: ComponentDefinition;
  fieldType?: FieldType;
}

export class ComponentOutputDefinition
  implements ComponentOutputDefinitionInterface {
  'id'?: string;
  'title': string;
  'fieldName': string;
  'description': string;
  'index': number;
  'componentDefinitionId': string;
  'fieldTypeId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  componentDefinition: ComponentDefinition;
  fieldType: FieldType;
  constructor(data?: ComponentOutputDefinitionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ComponentOutputDefinition`.
   */
  public static getModelName() {
    return 'ComponentOutputDefinition';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ComponentOutputDefinition for dynamic purposes.
   **/
  public static factory(
    data: ComponentOutputDefinitionInterface
  ): ComponentOutputDefinition {
    return new ComponentOutputDefinition(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ComponentOutputDefinition',
      plural: 'ComponentOutputDefinitions',
      path: 'component-output-definitions',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        title: {
          name: 'title',
          type: 'string',
        },
        fieldName: {
          name: 'fieldName',
          type: 'string',
        },
        description: {
          name: 'description',
          type: 'string',
        },
        index: {
          name: 'index',
          type: 'number',
        },
        componentDefinitionId: {
          name: 'componentDefinitionId',
          type: 'string',
        },
        fieldTypeId: {
          name: 'fieldTypeId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
      },
      relations: {
        componentDefinition: {
          name: 'componentDefinition',
          type: 'ComponentDefinition',
          model: 'ComponentDefinition',
          relationType: 'belongsTo',
          keyFrom: 'componentDefinitionId',
          keyTo: 'id',
        },
        fieldType: {
          name: 'fieldType',
          type: 'FieldType',
          model: 'FieldType',
          relationType: 'belongsTo',
          keyFrom: 'fieldTypeId',
          keyTo: 'id',
        },
      },
    };
  }
}
