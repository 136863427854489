import { createSelector } from '@ngrx/store';
import { DataSchema, DataSchemaField, DataSchemaFieldWithRelations } from '@rappider/rappider-sdk';

/**
 * Retrieves the data schemas with details from the state.
 *
 * @param state - The state object containing the data schema data.
 * @returns An array of data schemas with their details.
 */
export const getDataSchemasWithDetailsSelector = createSelector(
  state => state['dataSchema']?.data,
  (dataSchemas: DataSchema[]) => {
    if (!dataSchemas?.length) {
      return [];
    }
    // Map of data schemas with their corresponding IDs to improve performance when accessing them.
    const mappedDataSchemas = dataSchemas.reduce((acc, dataSchema) => acc.set(dataSchema.id, dataSchema), new Map<string, DataSchema>());

    function getType(field: DataSchemaField, parentDataSchemaIds: string[]): DataSchemaFieldWithRelations {
      const { typeId, parentDataSchemaId } = field;
      const parentIds = [...parentDataSchemaIds, parentDataSchemaId];
      const dataSchema = mappedDataSchemas.get(typeId);
      if (dataSchema) {
        return {
          ...field,
          type: dataSchema.fields?.length ? {
            ...dataSchema,
            fields: parentIds.includes(dataSchema.id) ? dataSchema.fields : dataSchema.fields.map(field => getType(field, parentIds))
          } : dataSchema
        };
      } else {
        return field;
      }
    }

    const dataSchemaWithDetails = dataSchemas.map(dataSchema => ({
      ...dataSchema,
      fields: dataSchema.fields?.map((field: DataSchemaField) => getType(field, []))
    }));

    return dataSchemaWithDetails;
  }
);
