/* eslint-disable */
import {
  DataTransformation,
  Workflow,
  WorkflowStepFunctionPublishedEvent,
  WorkflowStepFunctionSubscribedEvent,
} from '../index';

declare var Object: any;
export interface WorkflowStepFunctionInterface {
  id?: any;
  name?: string;
  code?: any;
  type?: string;
  workflowId?: string;
  workflow?: Workflow;
  dataTransformations?: DataTransformation[];
  publishedEvents?: WorkflowStepFunctionPublishedEvent[];
  subscribedEvents?: WorkflowStepFunctionSubscribedEvent[];
}

export class WorkflowStepFunction implements WorkflowStepFunctionInterface {
  'id'?: any;
  'name'?: string;
  'code'?: any;
  'type'?: string;
  'workflowId'?: string;
  workflow: Workflow;
  dataTransformations?: DataTransformation[];
  publishedEvents?: WorkflowStepFunction[];
  subscribedEvents?: WorkflowStepFunctionSubscribedEvent[];
  constructor(data?: WorkflowStepFunctionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `WorkflowStepFunction`.
   */
  public static getModelName() {
    return 'WorkflowStepFunction';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of WorkflowStepFunction for dynamic purposes.
   **/
  public static factory(
    data: WorkflowStepFunctionInterface
  ): WorkflowStepFunction {
    return new WorkflowStepFunction(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'WorkflowStepFunction',
      plural: 'WorkflowStepFunctions',
      path: 'workflow-step-functions',
      idName: 'id',
      properties: {
        name: {
          name: 'name',
          type: 'string',
        },
        code: {
          name: 'code',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        type: {
          name: 'type',
          type: 'string',
        },
        workflowId: {
          name: 'workflowId',
          type: 'string',
        },
      },
      relations: {
        workflow: {
          name: 'workflow',
          type: 'Workflow',
          model: 'Workflow',
          relationType: 'belongsTo',
          keyFrom: 'workflowId',
          keyTo: 'id',
        },
        dataTransformations: {
          name: 'dataTransformations',
          type: 'DataTransformation[]',
          model: 'DataTransformation',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'workflowStepFunctionId',
        },
        publishedEvents: {
          name: 'WorkflowStepFunctionPublishedEvents',
          type: 'WorkflowStepFunctionPublishedEvent[]',
          model: 'WorkflowStepFunctionPublishedEvent',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'workflowStepFunctionId',
        },
        subscribedEvents: {
          name: 'WorkflowStepFunctionSubscribedEvents',
          type: 'WorkflowStepFunctionSubscribedEvent[]',
          model: 'WorkflowStepFunctionSubscribedEvents',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'workflowStepFunctionId',
        },
      },
    };
  }
}
