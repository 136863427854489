/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ApiResultDto } from '../models/api-result-dto';
import { ChangeActivePersonRequestDto } from '../models/change-active-person-request-dto';
import { ChangeActivePersonResponseDto } from '../models/change-active-person-response-dto';
import { ChangeActiveProjectRequestDto } from '../models/change-active-project-request-dto';
import { ChangeActiveProjectResponseDto } from '../models/change-active-project-response-dto';
import { ChangePassword } from '../models/change-password';
import { ChangePasswordByToken } from '../models/change-password-by-token';
import { LoginCredentials } from '../models/login-credentials';
import { LoginResponseDto } from '../models/login-response-dto';
import { PersonWithRelations } from '../models/person-with-relations';
import { RegisterByCredentialsRequestDto } from '../models/register-by-credentials-request-dto';
import { User } from '../models/user';
import { UserWithRelations } from '../models/user-with-relations';

@Injectable({
  providedIn: 'root',
})
export class UserControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation userControllerChangeActiveProjectByAdmin
   */
  static readonly UserControllerChangeActiveProjectByAdminPath = '/admin/users/change-active-project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeActiveProjectByAdmin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeActiveProjectByAdmin$Response(params?: {
    body?: ChangeActiveProjectRequestDto
  }): Observable<StrictHttpResponse<ChangeActiveProjectResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.UserControllerChangeActiveProjectByAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeActiveProjectResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changeActiveProjectByAdmin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeActiveProjectByAdmin(params?: {
    body?: ChangeActiveProjectRequestDto
  }): Observable<ChangeActiveProjectResponseDto> {

    return this.changeActiveProjectByAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeActiveProjectResponseDto>) => r.body as ChangeActiveProjectResponseDto)
    );
  }

  /**
   * Path part for operation userControllerAuthenticateByThirdPartyAccountCallback
   */
  static readonly UserControllerAuthenticateByThirdPartyAccountCallbackPath = '/auth/{thirdPartyAuthProvider}/callback';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticateByThirdPartyAccountCallback()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticateByThirdPartyAccountCallback$Response(params: {
    thirdPartyAuthProvider: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.UserControllerAuthenticateByThirdPartyAccountCallbackPath, 'get');
    if (params) {
      rb.path('thirdPartyAuthProvider', params.thirdPartyAuthProvider, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authenticateByThirdPartyAccountCallback$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticateByThirdPartyAccountCallback(params: {
    thirdPartyAuthProvider: string;
  }): Observable<void> {

    return this.authenticateByThirdPartyAccountCallback$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation userControllerAuthenticateByThirdPartyAccount
   */
  static readonly UserControllerAuthenticateByThirdPartyAccountPath = '/auth/{thirdPartyAuthProvider}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticateByThirdPartyAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticateByThirdPartyAccount$Response(params: {
    thirdPartyAuthProvider: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.UserControllerAuthenticateByThirdPartyAccountPath, 'get');
    if (params) {
      rb.path('thirdPartyAuthProvider', params.thirdPartyAuthProvider, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authenticateByThirdPartyAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticateByThirdPartyAccount(params: {
    thirdPartyAuthProvider: string;
  }): Observable<void> {

    return this.authenticateByThirdPartyAccount$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation userControllerChangeActivePerson
   */
  static readonly UserControllerChangeActivePersonPath = '/users/change-active-person';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeActivePerson()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeActivePerson$Response(params?: {
    body?: ChangeActivePersonRequestDto
  }): Observable<StrictHttpResponse<ChangeActivePersonResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.UserControllerChangeActivePersonPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeActivePersonResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changeActivePerson$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeActivePerson(params?: {
    body?: ChangeActivePersonRequestDto
  }): Observable<ChangeActivePersonResponseDto> {

    return this.changeActivePerson$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeActivePersonResponseDto>) => r.body as ChangeActivePersonResponseDto)
    );
  }

  /**
   * Path part for operation userControllerChangeActiveProject
   */
  static readonly UserControllerChangeActiveProjectPath = '/users/change-active-project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeActiveProject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeActiveProject$Response(params?: {
    body?: ChangeActiveProjectRequestDto
  }): Observable<StrictHttpResponse<ChangeActiveProjectResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.UserControllerChangeActiveProjectPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeActiveProjectResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changeActiveProject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeActiveProject(params?: {
    body?: ChangeActiveProjectRequestDto
  }): Observable<ChangeActiveProjectResponseDto> {

    return this.changeActiveProject$Response(params).pipe(
      map((r: StrictHttpResponse<ChangeActiveProjectResponseDto>) => r.body as ChangeActiveProjectResponseDto)
    );
  }

  /**
   * Path part for operation userControllerChangePassword
   */
  static readonly UserControllerChangePasswordPath = '/users/change-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePassword$Response(params?: {
    body?: ChangePassword
  }): Observable<StrictHttpResponse<ApiResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.UserControllerChangePasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResultDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePassword(params?: {
    body?: ChangePassword
  }): Observable<ApiResultDto> {

    return this.changePassword$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResultDto>) => r.body as ApiResultDto)
    );
  }

  /**
   * Path part for operation userControllerChangePasswordByVerificationToken
   */
  static readonly UserControllerChangePasswordByVerificationTokenPath = '/users/change-password-by-verification-token';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changePasswordByVerificationToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePasswordByVerificationToken$Response(params?: {
    body?: ChangePasswordByToken
  }): Observable<StrictHttpResponse<ApiResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.UserControllerChangePasswordByVerificationTokenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResultDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changePasswordByVerificationToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePasswordByVerificationToken(params?: {
    body?: ChangePasswordByToken
  }): Observable<ApiResultDto> {

    return this.changePasswordByVerificationToken$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResultDto>) => r.body as ApiResultDto)
    );
  }

  /**
   * Path part for operation userControllerForgotPassword
   */
  static readonly UserControllerForgotPasswordPath = '/users/forgot-password/{username}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `forgotPassword()` instead.
   *
   * This method doesn't expect any request body.
   */
  forgotPassword$Response(params: {
    username: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.UserControllerForgotPasswordPath, 'get');
    if (params) {
      rb.path('username', params.username, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `forgotPassword$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  forgotPassword(params: {
    username: string;
  }): Observable<void> {

    return this.forgotPassword$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation userControllerGetUserByAuthenticationToken
   */
  static readonly UserControllerGetUserByAuthenticationTokenPath = '/users/get-user-by-authentication-token';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserByAuthenticationToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserByAuthenticationToken$Response(params?: {
  }): Observable<StrictHttpResponse<UserWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.UserControllerGetUserByAuthenticationTokenPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserByAuthenticationToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserByAuthenticationToken(params?: {
  }): Observable<UserWithRelations> {

    return this.getUserByAuthenticationToken$Response(params).pipe(
      map((r: StrictHttpResponse<UserWithRelations>) => r.body as UserWithRelations)
    );
  }

  /**
   * Path part for operation userControllerLoginByCredentials
   */
  static readonly UserControllerLoginByCredentialsPath = '/users/login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loginByCredentials()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loginByCredentials$Response(params?: {
    body?: LoginCredentials
  }): Observable<StrictHttpResponse<LoginResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.UserControllerLoginByCredentialsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loginByCredentials$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loginByCredentials(params?: {
    body?: LoginCredentials
  }): Observable<LoginResponseDto> {

    return this.loginByCredentials$Response(params).pipe(
      map((r: StrictHttpResponse<LoginResponseDto>) => r.body as LoginResponseDto)
    );
  }

  /**
   * Path part for operation userControllerFindPeople
   */
  static readonly UserControllerFindPeoplePath = '/users/people';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findPeople()` instead.
   *
   * This method doesn't expect any request body.
   */
  findPeople$Response(params?: {
  }): Observable<StrictHttpResponse<PersonWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.UserControllerFindPeoplePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PersonWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findPeople$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findPeople(params?: {
  }): Observable<PersonWithRelations> {

    return this.findPeople$Response(params).pipe(
      map((r: StrictHttpResponse<PersonWithRelations>) => r.body as PersonWithRelations)
    );
  }

  /**
   * Path part for operation userControllerRegisterByCredentials
   */
  static readonly UserControllerRegisterByCredentialsPath = '/users/register';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerByCredentials()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerByCredentials$Response(params?: {
    body?: RegisterByCredentialsRequestDto
  }): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.UserControllerRegisterByCredentialsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `registerByCredentials$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerByCredentials(params?: {
    body?: RegisterByCredentialsRequestDto
  }): Observable<User> {

    return this.registerByCredentials$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

}
