/* eslint-disable no-shadow */
import { Action } from '@ngrx/store';
import { Pagination } from '@rappider/shared/interfaces';

export enum ActionTypes {
  LoadModule = '[ProjectVersion] LoadModule',
  EnableProjectVersionsLoading = '[ProjectVersion] EnableProjectVersionsLoading',
  DisableProjectVersionsLoading = '[ProjectVersion] DisableProjectVersionsLoading',
  SetPagination = '[ProjectVersion] SetPagination',
  SetPaginationFailure = '[ProjectVersion] SetPaginationFailure',
  GetTotalCount = '[ProjectVersion] GetTotalCount',
  GetProjectVersions = '[ProjectVersion] GetProjectVersions',
  GetProjectVersionsFailure = '[ProjectVersion] GetProjectVersionsFailure',
  SetProjectVersions = '[ProjectVersion] SetProjectVersions',
  CreateProjectVersion = '[ProjectVersion] CreateProjectVersion',
  CreateProjectVersionFailure = '[ProjectVersion] CreateProjectVersionFailure',
  UpdateProjectVersion = '[ProjectVersion] UpdateProjectVersion',
  DeleteProjectVersion = '[ProjectVersion] DeleteProjectVersion',
  ExportProject = '[ProjectVersion] ExportProject',
  ProjectVersionError = '[ProjectVersion] ProjectVersionError',
}

export class LoadModule implements Action {
  readonly type = ActionTypes.LoadModule;
}

export class EnableProjectVersionsLoading implements Action {
  readonly type = ActionTypes.EnableProjectVersionsLoading;
}

export class DisableProjectVersionsLoading implements Action {
  readonly type = ActionTypes.DisableProjectVersionsLoading;
}

export class GetProjectVersions implements Action {
  readonly type = ActionTypes.GetProjectVersions;
}

export class GetProjectVersionsFailure implements Action {
  readonly type = ActionTypes.GetProjectVersionsFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

/**
 * sets pagination and gets data after the action if specified
 *
 * @export
 * @class SetPagination
 * @implements {Action}
 */
export class SetPagination implements Action {
  readonly type = ActionTypes.SetPagination;
  constructor(public payload: { pagination: Pagination; getDataAfter?: boolean }) { }
}

export class SetPaginationFailure implements Action {
  readonly type = ActionTypes.SetPaginationFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class GetTotalCount implements Action {
  readonly type = ActionTypes.GetTotalCount;
}

export class SetProjectVersions implements Action {
  readonly type = ActionTypes.SetProjectVersions;
  constructor(public payload: { projectVersions: any[] }) { }
}

export class CreateProjectVersion implements Action {
  readonly type = ActionTypes.CreateProjectVersion;
  constructor(public payload: { projectVersion: any }) { }
}

export class CreateProjectVersionFailure implements Action {
  readonly type = ActionTypes.CreateProjectVersionFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class UpdateProjectVersion implements Action {
  readonly type = ActionTypes.UpdateProjectVersion;
  constructor(public payload: { projectVersion: any; projectVersionId: string }) { }
}

export class DeleteProjectVersion implements Action {
  readonly type = ActionTypes.DeleteProjectVersion;
  constructor(public payload: { projectVersionId: string }) { }
}

export class ProjectVersionError implements Action {
  readonly type = ActionTypes.ProjectVersionError;
  constructor(public payload: { errorOn: string; error?: any }) { }
}

export class ExportProject implements Action {
  readonly type = ActionTypes.ExportProject;
  constructor(public payload: { projectVersionId: string; projectExportData: any }) { }
}

export type Actions =
  LoadModule
  | EnableProjectVersionsLoading
  | DisableProjectVersionsLoading
  | SetPagination
  | SetPaginationFailure
  | GetTotalCount
  | GetProjectVersions
  | GetProjectVersionsFailure
  | SetProjectVersions
  | CreateProjectVersion
  | CreateProjectVersionFailure
  | UpdateProjectVersion
  | DeleteProjectVersion
  | ProjectVersionError
  | ExportProject
  ;
