import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { WorkflowEvent, WorkflowEventPartial } from '@rappider/rappider-sdk';
import { WORKFLOW_EVENT_CREATE_OR_EDIT_CONFIG } from 'libs/shared/src/lib/configs/project/workflow';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'rappider-workflow-event-edit',
  templateUrl: './workflow-event-edit.component.html',
  styleUrls: ['./workflow-event-edit.component.scss']
})
export class WorkflowEventEditComponent implements OnChanges {
  @Input() workflowEvent: WorkflowEvent;
  @Input() submitButtonLoadig: boolean;

  @Output() editWorkflowEvent = new EventEmitter<{ workflowEventId: string; workflowEventBody?: WorkflowEventPartial }>();
  @Output() eventNameChange = new EventEmitter<string>();

  WORKFLOW_EVENT_EDIT_CONFIG = cloneDeep(WORKFLOW_EVENT_CREATE_OR_EDIT_CONFIG);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.workflowEvent) {
      this.workflowEvent = {
        ...this.workflowEvent,
        inputDataSample: this.workflowEvent?.inputDataSample ? JSON.stringify(this.workflowEvent.inputDataSample) : null
      };
    }
  }

  onEditWorkflowEventFormSubmit(workflowEvent: WorkflowEventPartial) {
    this.editWorkflowEvent.emit({
      workflowEventId: this.workflowEvent.id,
      workflowEventBody: workflowEvent
    });
  }

  onFieldValueChange(field: Record<string, string>) {
    if (Object.keys(field).includes('name')) {
      this.eventNameChange.emit(field.name);
    }
  }
}
