import { createAction, props } from '@ngrx/store';
import { NewWorkflowStepFunctionPublishedEventOnFailure, WorkflowStepFunctionPublishedEventOnFailure, WorkflowStepFunctionPublishedEventOnFailurePartial, WorkflowStepFunctionPublishedEventOnFailureWithRelations } from '@rappider/rappider-sdk';

/* action types */
export enum ActionTypes {
  CreateWorkflowStepFunctionPublishedEventOnFailure = '[WorkflowStepFunctionPublishedEventOnFailure] CreateWorkflowStepFunctionPublishedEventOnFailure',
  CreateWorkflowStepFunctionPublishedEventOnFailureSuccessful = '[WorkflowStepFunctionPublishedEventOnFailure] CreateWorkflowStepFunctionPublishedEventOnFailureSuccessful',
  CreateWorkflowStepFunctionPublishedEventOnFailureFailure = '[WorkflowStepFunctionPublishedEventOnFailure] CreateWorkflowStepFunctionPublishedEventOnFailureFailure',
  GetWorkflowStepFunctionPublishedEventOnFailure = '[WorkflowStepFunctionPublishedEventOnFailure] GetWorkflowStepFunctionPublishedEventOnFailure',
  GetWorkflowStepFunctionPublishedEventOnFailureSuccessful = '[WorkflowStepFunctionPublishedEventOnFailure] GetWorkflowStepFunctionPublishedEventOnFailureSuccessful',
  GetWorkflowStepFunctionPublishedEventOnFailureFailure = '[WorkflowStepFunctionPublishedEventOnFailure] GetWorkflowStepFunctionPublishedEventOnFailureFailure',
  UpdateWorkflowStepFunctionPublishedEventOnFailure = '[WorkflowStepFunctionPublishedEventOnFailure] UpdateWorkflowStepFunctionPublishedEventOnFailure',
  UpdateWorkflowStepFunctionPublishedEventOnFailureSuccessful = '[WorkflowStepFunctionPublishedEventOnFailure] UpdateWorkflowStepFunctionPublishedEventOnFailureSuccessful',
  UpdateWorkflowStepFunctionPublishedEventOnFailureFailure = '[WorkflowStepFunctionPublishedEventOnFailure] UpdateWorkflowStepFunctionPublishedEventOnFailureFailure',
  DeleteWorkflowStepFunctionPublishedEventOnFailure = '[WorkflowStepFunctionPublishedEventOnFailure] DeleteWorkflowStepFunctionPublishedEventOnFailure',
  DeleteWorkflowStepFunctionPublishedEventOnFailureSuccessful = '[WorkflowStepFunctionPublishedEventOnFailure] DeleteWorkflowStepFunctionPublishedEventOnFailureSuccessful',
  DeleteWorkflowStepFunctionPublishedEventOnFailureFailure = '[WorkflowStepFunctionPublishedEventOnFailure] DeleteWorkflowStepFunctionPublishedEventOnFailureFailure',
  AddWorkflowStepFunctionPublishedEventOnFailureSuccessful = '[WorkflowStepFunctionPublishedEventOnFailure] AddWorkflowStepFunctionPublishedEventOnFailureSuccessful',
}

export const CreateWorkflowStepFunctionPublishedEventOnFailure = createAction(
  ActionTypes.CreateWorkflowStepFunctionPublishedEventOnFailure,
  props<{ payload: { workflowStepFunctionPublishedEventOnFailure: NewWorkflowStepFunctionPublishedEventOnFailure } }>()
);

export const CreateWorkflowStepFunctionPublishedEventOnFailureSuccessful = createAction(
  ActionTypes.CreateWorkflowStepFunctionPublishedEventOnFailureSuccessful,
  props<{ payload: { workflowStepFunctionPublishedEventOnFailure: WorkflowStepFunctionPublishedEventOnFailure } }>()
);

export const CreateWorkflowStepFunctionPublishedEventOnFailureFailure = createAction(
  ActionTypes.CreateWorkflowStepFunctionPublishedEventOnFailureFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const AddWorkflowStepFunctionPublishedEventOnFailureSuccessful = createAction(
  ActionTypes.AddWorkflowStepFunctionPublishedEventOnFailureSuccessful,
  props<{ payload: { workflowStepFunctionPublishedEventOnFailure: WorkflowStepFunctionPublishedEventOnFailure[] } }>()
);

export const GetWorkflowStepFunctionPublishedEventOnFailure = createAction(
  ActionTypes.GetWorkflowStepFunctionPublishedEventOnFailure,
);

export const GetWorkflowStepFunctionPublishedEventOnFailureSuccessful = createAction(
  ActionTypes.GetWorkflowStepFunctionPublishedEventOnFailureSuccessful,
  props<{ payload: { workflowStepFunctionPublishedEventOnFailures: WorkflowStepFunctionPublishedEventOnFailureWithRelations[] } }>()
);

export const GetWorkflowStepFunctionPublishedEventOnFailureFailure = createAction(
  ActionTypes.GetWorkflowStepFunctionPublishedEventOnFailureFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const UpdateWorkflowStepFunctionPublishedEventOnFailure = createAction(
  ActionTypes.UpdateWorkflowStepFunctionPublishedEventOnFailure,
  props<{ payload: { workflowStepFunctionPublishedEventOnFailureId: string; workflowStepFunctionPublishedEventOnFailureBody?: WorkflowStepFunctionPublishedEventOnFailurePartial } }>()
);

export const UpdateWorkflowStepFunctionPublishedEventOnFailureSuccessful = createAction(
  ActionTypes.UpdateWorkflowStepFunctionPublishedEventOnFailureSuccessful,
  props<{ payload: { workflowStepFunctionPublishedEventOnFailureId: string; workflowStepFunctionPublishedEventOnFailureBody?: WorkflowStepFunctionPublishedEventOnFailureWithRelations } }>()
);

export const UpdateWorkflowStepFunctionPublishedEventOnFailureFailure = createAction(
  ActionTypes.UpdateWorkflowStepFunctionPublishedEventOnFailureFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const DeleteWorkflowStepFunctionPublishedEventOnFailure = createAction(
  ActionTypes.DeleteWorkflowStepFunctionPublishedEventOnFailure,
  props<{ payload: { workflowStepFunctionPublishedEventOnFailureId: string } }>()
);

export const DeleteWorkflowStepFunctionPublishedEventOnFailureSuccessful = createAction(
  ActionTypes.DeleteWorkflowStepFunctionPublishedEventOnFailureSuccessful,
  props<{ payload: { workflowStepFunctionPublishedEventOnFailureId: string } }>()
);

export const DeleteWorkflowStepFunctionPublishedEventOnFailureFailure = createAction(
  ActionTypes.DeleteWorkflowStepFunctionPublishedEventOnFailureFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

