import { createAction, props } from '@ngrx/store';
import { CreateUiDataSelectorRequestDto, UiDataSelectorWithRelations, UpdateUiDataSelectorRequestDto } from '@rappider/rappider-sdk';

export enum ActionTypes {
  GetUIDataSelectors = '[UIDataSelector] GetUIDataSelectors',
  GetUIDataSelectorsSuccessful = '[UIDataSelector] GetUIDataSelectorsSuccessful',
  GetUIDataSelectorById = '[UIDataSelector] GetUIDataSelectorById',
  GetUIDataSelectorByIdSuccessful = '[UIDataSelector] GetUIDataSelectorByIdSuccessful',
  CreateUIDataSelector = '[UIDataSelector] CreateUIDataSelector',
  CreateUIDataSelectorSuccessful = '[UIDataSelector] CreateUIDataSelectorSuccessful',
  UpdateUIDataSelector = '[UIDataSelector] UpdateUIDataSelector',
  UpdateUIDataSelectorSuccessful = '[UIDataSelector] UpdateUIDataSelectorSuccessful',
  DeleteUIDataSelector = '[UIDataSelector] DeleteUIDataSelector',
  DeleteUIDataSelectorSuccessful = '[UIDataSelector] DeleteUIDataSelectorSuccessful',
  Error = '[UIDataSelector] Error',
}

export const GetUIDataSelectors = createAction(ActionTypes.GetUIDataSelectors);

export const GetUIDataSelectorsSuccessful = createAction(
  ActionTypes.GetUIDataSelectorsSuccessful,
  props<{ payload: { uiDataSelectors: UiDataSelectorWithRelations[] } }>()
);

export const GetUIDataSelectorById = createAction(
  ActionTypes.GetUIDataSelectorById,
  props<{ payload: { uiDataSelectorId: string; replace?: boolean } }>()
);

export const GetUIDataSelectorByIdSuccessful = createAction(
  ActionTypes.GetUIDataSelectorByIdSuccessful,
  props<{ payload: { uiDataSelector: UiDataSelectorWithRelations; replace?: boolean } }>()
);

export const CreateUIDataSelector = createAction(
  ActionTypes.CreateUIDataSelector,
  props<{ payload: { uiDataSelector: CreateUiDataSelectorRequestDto } }>()
);

export const CreateUIDataSelectorSuccessful = createAction(
  ActionTypes.CreateUIDataSelectorSuccessful,
  props<{ payload: { uiDataSelector: UiDataSelectorWithRelations } }>()
);

export const UpdateUIDataSelector = createAction(
  ActionTypes.UpdateUIDataSelector,
  props<{ payload: { uiDataSelector: UpdateUiDataSelectorRequestDto; uiDataSelectorId: string } }>()
);

export const UpdateUIDataSelectorSuccessful = createAction(
  ActionTypes.UpdateUIDataSelectorSuccessful,
  props<{ payload: { uiDataSelector: UiDataSelectorWithRelations } }>()
);

export const DeleteUIDataSelector = createAction(
  ActionTypes.DeleteUIDataSelector,
  props<{ payload: { uiDataSelectorId: string } }>()
);

export const DeleteUIDataSelectorSuccessful = createAction(
  ActionTypes.DeleteUIDataSelectorSuccessful,
  props<{ payload: { uiDataSelectorId: string } }>()
);

export const Error = createAction(
  ActionTypes.Error,
  props<{ payload: { error: any } }>()
);
