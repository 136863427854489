<div class="item">
  <div class="title-area">
    <div class="title">
      {{ 'PROJECT_MODULE.DATA_MAPPING_CREATE_COMPONENT.SOURCE_FIELDS' | translate }}
    </div>
    <div class="button-area">
      <rappider-button [text]="addSourceFieldButton.text"
                       [type]="addSourceFieldButton.type"
                       [icon]="addSourceFieldButton.icon"
                       (click)="handleAddSourceFieldModalVisibility(true)"></rappider-button>
      <rappider-button [text]="addStaticFieldButton.text"
                       [type]="addStaticFieldButton.type"
                       [icon]="addStaticFieldButton.icon"
                       (click)="handleAddStaticFieldModalVisibility(true)"></rappider-button>
    </div>
  </div>
  <ng-container *ngIf="selectedSourceFields?.length > 0; else addSourceFieldTmpl">
    <div cdkDropList
         class="example-list"
         (cdkDropListDropped)="drop($event)">
      <div class="example-box"
           *ngFor="let item of selectedSourceFields; let i = index"
           cdkDrag>
        <div class="item-area">
          {{ item }}
        </div>

        <div class="button-area">
          <rappider-button [text]="deleteButton.text"
                           [icon]="deleteButton.icon"
                           (click)="deleteItem(i)">
          </rappider-button>
        </div>
      </div>
    </div>
  </ng-container>

  <rappider-rappider-function [(ngModel)]="value.function"
                              [sourceFields]="selectedSourceFields"
                              [functions]="functions">
  </rappider-rappider-function>

  <ng-template #addSourceFieldTmpl>
    {{ 'PROJECT_MODULE.DATA_MAPPING_CREATE_COMPONENT.DONT_HAVE_SOURCE_FIELD' | translate }}
  </ng-template>
</div>

<div class="item">
  <div class="title-area">
    <div class="title">
      {{ 'PROJECT_MODULE.DATA_MAPPING_CREATE_COMPONENT.ADD_TARGET_FIELD' | translate }}
    </div>
  </div>
  <div class="content">
    <rappider-schema-tree-select [(ngModel)]="value.targetFieldTree"
                                 [multipleSelect]="false"
                                 [tree]="targetTree || tree">
    </rappider-schema-tree-select>
  </div>
</div>

<!-- Add Source Field Modal-->
<rappider-modal [(visible)]="addSourceFieldModalVisible"
                [title]="'PROJECT_MODULE.DATA_MAPPING_CREATE_COMPONENT.ADD_SOURCE_FIELD' | translate"
                (cancelClick)="handleAddSourceFieldModalVisibility(false)"
                (okClick)="onSourceFieldAdd()">
  <rappider-schema-tree-select #schemaTreeSelect
                               [(ngModel)]="addedSourceFieldValue"
                               [multipleSelect]="false"
                               [tree]="tree"></rappider-schema-tree-select>
</rappider-modal>

<!-- Add Static Field Modal-->
<rappider-modal [(visible)]="isAddStaticFieldModalVisible"
                title="Add Static Field"
                (cancelClick)="handleAddStaticFieldModalVisibility(false)"
                (okClick)="onStaticFieldAdd()">
  <rappider-add-parameter-value [(ngModel)]="addedStaticField"
                                [showCodemirrorForObjectAndArray]="true"></rappider-add-parameter-value>
</rappider-modal>
