import { ExternalScriptType } from './external-script-type.enum';

export const EXTERNAL_SCRIPT_TYPE_OPTIONS = [
  {
    key: 'Style',
    value: ExternalScriptType.Style
  },
  {
    key: 'Script',
    value: ExternalScriptType.Script
  }
];
