import { Injectable } from '@angular/core';
import { UIDataUpdateFunctionInterface } from '@rappider/api-sdk';
import { UiDataUpdateFunction } from '@rappider/rappider-sdk';

@Injectable({
  providedIn: 'root'
})
export class DataMappingService {


  getDataMappingsFromUIDataUpdateFunction(uiDataUpdateFunction: UiDataUpdateFunction) {
    return uiDataUpdateFunction?.dataMaps?.map(dataMap => {
      const dataMapItem = {
        sourceFields: dataMap.sourceTreeFields?.map(sourceTreeField => sourceTreeField.nodes.map(node => node.field)),
        targetField: dataMap.targetTreeField.nodes?.map(node => node.field),
        functionId: dataMap.functionId,
        id: dataMap.id
      };
      return dataMapItem;
    });
  }
}
