import { createReducer, on } from '@ngrx/store';

import { orderBy } from 'lodash';
import { RoleWithRelations } from '@rappider/rappider-sdk';

import * as RoleActions from './role-crud.actions';

export const featureKey = 'roleManagement';

export interface State {
  data: RoleWithRelations[];
  loading: boolean;
  modalVisibility: boolean;
}

export const initialState: State = {
  data: null,
  loading: false,
  modalVisibility: false
};

export const reducer = createReducer(
  initialState,

  /* get */
  on(RoleActions.GetRoles, (state) => ({
    ...state,
    loading: true
  })),

  on(RoleActions.GetRolesSuccessful, (state, action) => ({
    ...state,
    data: orderBy(action.roles, 'createdDate', 'desc'),
    loading: false
  })),

  on(RoleActions.GetRolesFailure, (state) => ({
    ...state,
    loading: false
  })),

  /* create */
  on(RoleActions.CreateRole, (state) => ({
    ...state,
    loading: true
  })),

  on(RoleActions.CreateRoleSuccessful, (state, action) => ({
    ...state,
    data: orderBy([
      ...state.data,
      action.role
    ], 'createdDate', 'desc'),
    loading: false,
    modalVisibility: false
  })),

  on(RoleActions.CreateRoleFailure, (state) => ({
    ...state,
    loading: false,
    modalVisibility: false
  })),

  /* update */
  on(RoleActions.UpdateRole, (state) => ({
    ...state,
    loading: true
  })),

  on(RoleActions.UpdateRoleSuccessful, (state, action) => {
    const updatedRole = {
      ...state.data.find(role => role.id === action.id),
      ...action.role
    };

    return {
      ...state,
      data: orderBy([
        ...state.data.filter(role => role.id !== action.id),
        updatedRole
      ], 'createdDate', 'desc'),
      loading: false,
      modalVisibility: false
    };
  }),

  on(RoleActions.UpdateRoleFailure, (state) => ({
    ...state,
    loading: false,
    modalVisibility: false
  })),

  /* delete */
  on(RoleActions.DeleteRole, (state) => ({
    ...state,
    loading: true
  })),

  on(RoleActions.DeleteRoleSuccessful, (state, action) => ({
    ...state,
    data: [
      ...state.data.filter(role => role.id !== action.id)
    ],
    loading: false
  })),

  on(RoleActions.DeleteRoleFailure, (state) => ({
    ...state,
    loading: false
  })),

  /* modal visibility */
  on(RoleActions.ChangeRoleModalVisibility, (state, action) => ({
    ...state,
    modalVisibility: action.visibility
  })),

);
