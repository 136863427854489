import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalComponentConfig } from '@rappider/rappider-components/utils';
import { ProjectPackageCreateComponent } from '../project-package-create/project-package-create.component';

@Component({
  selector: 'rappider-project-package-create-modal',
  templateUrl: './project-package-create-modal.component.html',
  styleUrls: ['./project-package-create-modal.component.scss']
})
export class ProjectPackageCreateModalComponent {
  @ViewChild(ProjectPackageCreateComponent) projectPackageCreateComponent: ProjectPackageCreateComponent;
  @Input() isCreatePackageForBackend: boolean;
  @Input() projectPackageCreateModalVisibility: boolean;

  @Output() projectPackageCreateModalVisibilityChange = new EventEmitter<boolean>();

  modalConfig: ModalComponentConfig = {
    closeIconVisibility: false,
  };
  defaultSaveButtonDisabled = true;
  fullScreen = true;

  onCreateProjectPackage() {
    this.projectPackageCreateComponent.onAddProjectPackage();
  }

  onCloseProjectPackageCreateModal(visibility) {
    this.projectPackageCreateComponent.onCancel();
    this.projectPackageCreateModalVisibilityChange.emit(visibility);
  }

}
