import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Project, UiDataStore, UiDataStoreWithRelations } from '@rappider/rappider-sdk';
import {
  BreadcrumbOption,
  ButtonComponentConfig,
  ButtonSize,
  ButtonType,
  HeadingComponentConfig,
  IconType,
  InputGroupComponentConfig,
} from '@rappider/rappider-components/utils';
import { RouterStateService } from '@rappider/services';
import {
  defaultToolbarTitleHeadingSize,
  PATH_DEFINITIONS
} from '@rappider/shared/definitions';
import {
  DeleteUIDataEvent,
  UpdateUIDataEvent,
} from 'libs/project/src/lib/states/ui-data-event-state/ui-data-event.actions';
import { Subscription } from 'rxjs';
import { UiDataEvent } from '@rappider/rappider-sdk';
import { toLower } from 'lodash';
import { uiDataEventListDataSelector } from 'libs/project/src/lib/states/selectors/ui-data-event-list-data.selector';
import { DATA_EVENTS_EMPTY_LIST_CONFIG, DATA_EVENTS_LIST_CONFIG } from '../data-event-list/config/data-events-list.config';

@Component({
  selector: 'rappider-data-events',
  templateUrl: './data-events.component.html',
  styleUrls: ['./data-events.component.scss'],
})
export class DataEventsComponent implements OnInit, OnDestroy {
  /* list grid config */
  DATA_EVENTS_LIST_CONFIG = DATA_EVENTS_LIST_CONFIG;
  DATA_EVENTS_EMPTY_LIST_CONFIG = DATA_EVENTS_EMPTY_LIST_CONFIG;
  /* Main Title of the Page  */
  mainTitle: HeadingComponentConfig = {
    content: 'PROJECT_MODULE.UI_DATA_EVENTS_COMPONENT.UI_DATA_EVENTS',
    type: defaultToolbarTitleHeadingSize,
  };
  /* Sub Title of the Page */
  title: BreadcrumbOption[];
  /* Subscriptions */
  subscriptions: Subscription[];
  /* Ui Data Stores With Relations */
  uiDataStores: UiDataStoreWithRelations[];
  /* Active Project Title */
  activeProject: Project;
  /* Variable that holds the state of the panels */
  panels = {};
  /* Text to Search */
  searchText: string;
  loadingState: boolean;

  displayToolbar = false;
  displayToolbarBackButton = false;
  isPanelsOpenedInitially = false;

  /* Filter events based on the text to be searched */
  searchResults: UiDataStore[] = [];

  /* Add Data Event Button Config */
  addDataEventButtonConfig: ButtonComponentConfig = {
    type: ButtonType.Default,
    size: ButtonSize.ExtraSmall,
    icon: { name: 'fas fa-plus' },
  };

  /* Collapse Button and Expand Button Config */
  collapseAndExpandButtons = {
    collapseButton: {
      text: 'Collapse All',
      key: 'collapseAll',
      type: ButtonType.Default,
      icon: {
        name: 'fas fa-minus'
      }
    },
    expandButton: {
      text: 'Expand All',
      key: 'expandAll',
      type: ButtonType.Default,
      icon: {
        name: 'fas fa-plus'
      }
    },
  };

  /* Search Bar Config */
  inputGroupConfig: InputGroupComponentConfig = {
    textbox: {
      placeholder: 'Search in Data Events',
    },
    suffixIcon: {
      name: 'fas fa-search',
      type: IconType.FontAwesome,
    },
  };

  constructor(
    private store: Store<any>,
    private routerStateService: RouterStateService,
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToUIDataStoresAndUIDataEvents(),
      this.subscribeToLoadingState()
    ];
  }

  subscribeToUIDataStoresAndUIDataEvents() {
    return this.store.select(<any>uiDataEventListDataSelector).subscribe((uiDataEventListData: UiDataStoreWithRelations[]) => {
      this.uiDataStores = uiDataEventListData?.filter(uiDataStore => uiDataStore.events);
      if (!this.isPanelsOpenedInitially && this.uiDataStores?.length) {
        this.uiDataStores?.forEach((uiDataStore) => {
          this.panels[uiDataStore.id] = true;
        });
        this.isPanelsOpenedInitially = true;
      }
    });
  }

  /**
   * subscribe to active project to set the title
   *
   * @returns
   * @memberof DataEventsComponent
   */
  subscribeToActiveProject() {
    return this.store.select((state) => state.activeProject.data).subscribe((activeProject: Project) => {
      this.activeProject = activeProject;
      this.setTitle();
    });
  }

  subscribeToLoadingState() {
    return this.store.select(state => state.uiDataEvent?.loading).subscribe(loadingState => {
      this.loadingState = loadingState;
    });
  }

  onUIDataEventDelete(uiDataEvent: UiDataEvent) {
    this.store.dispatch(
      new DeleteUIDataEvent({ uiDataEvent: uiDataEvent })
    );
  }

  onUIDataEventEdit(uiDataEvent: UiDataEvent) {
    const uiDataEventId = uiDataEvent.id;
    this.store.dispatch(
      new UpdateUIDataEvent({ uiDataEventId: uiDataEventId, uiDataEvent: uiDataEvent, navigateAfterUpdate: true })
    );
  }

  navigateCreateDataEventPage(dataStoreId: string) {
    this.routerStateService.navigate(
      `${PATH_DEFINITIONS.PROJECTS.DATA_EVENT_CREATE}/${dataStoreId}`
    );
  }

  setTitle() {
    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
      },
      {
        label: 'PROJECT_MODULE.UI_DATA_EVENTS_COMPONENT.UI_DATA_EVENTS'
      },
    ];
  }

  collapseOrExpandAll(key: string) {
    if (key === this.collapseAndExpandButtons.collapseButton.key) {
      this.collapseAll();
    } else if (key === this.collapseAndExpandButtons.expandButton.key) {
      this.expandAll();
    }
  }

  collapseAll() {
    Object.keys(this.panels).forEach((key) => (this.panels[key] = false));
  }

  expandAll() {
    Object.keys(this.panels).forEach((key) => (this.panels[key] = true));
  }

  onSearchTextChange() {
    this.searchResults = this.uiDataStores.map(uiDataStore => ({
      ...uiDataStore,
      events: uiDataStore.events.filter(event => toLower(event.name).includes(toLower(this.searchText)))
    }));
    this.searchResults.sort(this.sortByEmptyListData);
  }

  sortByEmptyListData(emptyData, data) {
    if (emptyData.events.length === 0 && data.events.length > 0) {
      return 1;
    } else if (emptyData.events.length > 0 && data.events.length === 0) {
      return -1;
    } else {
      return 0;
    }
  }

}
