import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { DataTransformationControllerService, GetJsonSchemaDto, WorkflowStepFunction, WorkflowStepFunctionControllerService, WorkflowStepFunctionUpdateDtoPartial, WorkflowStepFunctionWithRelations } from '@rappider/rappider-sdk';
import { NotificationService } from '@rappider/services';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { v4 } from 'uuid';

import * as WorkflowStepFunctionActions from './workflow-step-function.actions';
import * as RouterActions from '@rappider/shared';
import * as WorkflowStepFunctionSubscribedEventActions from 'libs/project/src/lib/states/workflow-step-function-subscribed-event-state/workflow-step-function-subscribed-event.actions';
import * as WorkflowEventFailureActions from '../workflow-step-function-published-event-on-failure-state/workflow-step-function-published-event-on-failure.actions';
import * as WorkflowEventSuccessActions from '../workflow-step-function-published-event-on-success-state/workflow-step-function-published-event-on-success.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import * as WorkflowEventActions from 'libs/project/src/lib/states/workflow-event/workflow-event.actions';

@Injectable()
export class WorkflowStepFunctionEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private notificationService: NotificationService,
    private workflowStepFunctionApi: WorkflowStepFunctionControllerService,
    private dataTransformationApi: DataTransformationControllerService,
  ) { }

  getRappiderWorkflowStepFunctions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthenticationActions.SetActivePerson>(
        AuthenticationActions.ActionTypes.SetActivePerson
      ),
      mergeMap((action) => {
        if (action.payload.person) {
          return this.workflowStepFunctionApi.findServices().pipe(
            map((workflowStepFunctions) => WorkflowStepFunctionActions.GetRappiderWorkflowStepFunctionsSuccessful({ payload: { workflowStepFunctions } })),
            catchError((error) => [
              WorkflowStepFunctionActions.GetRappiderWorkflowStepFunctionsFailure({
                payload: {
                  error,
                  key: 'GetRappiderWorkflowStepFunctionsFailure',
                  timestamp: Date.now()
                }
              }),
            ])
          );
        } else {
          return [
            WorkflowStepFunctionActions.GetRappiderWorkflowStepFunctionsFailure({
              payload: {
                error: null,
                key: 'GetRappiderWorkflowStepFunctionsFailure',
                timestamp: Date.now()
              }
            }),
          ];
        }
      })
    )
  );

  createWorkflowStepFunction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowStepFunctionActions.CreateWorkflowStepFunction
      ),
      withLatestFrom(
        this.store.select(state => state.workflowEvent.data),
        this.store.select(state => state.workflowStepFunction.data)
      ),
      mergeMap(([action, workflowEvents, workflowStepFunctions]) => {
        const params = {
          body: action.payload.workflowStepFunction
        };
        const subscribedEvents = workflowEvents?.filter(workflowEventItem => action.payload.workflowStepFunction.subscribedEventIds?.includes(workflowEventItem.id));
        const publishedEventsOnSuccess = workflowEvents?.filter(workflowEventItem => action.payload.workflowStepFunction?.publishedEventOnSuccessIds?.includes(workflowEventItem.id));
        const publishedEventsOnFailure = workflowEvents?.filter(workflowEventItem => action.payload.workflowStepFunction?.publishedEventOnFailureIds?.includes(workflowEventItem.id));
        const isWorkflowStepFunctionNameExist = workflowStepFunctions?.some(workflowStepFunction => (action.payload.workflowStepFunction?.workflowStepFunction as any).name === workflowStepFunction.name);

        if (isWorkflowStepFunctionNameExist) {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            `${(action.payload.workflowStepFunction.workflowStepFunction as any).name} already exists.`
          );
          return [
            WorkflowStepFunctionActions.CreateWorkflowStepFunctionFailure({ payload: { error: 'Name Exist', key: 'CreateWorkflowStepFunctionFailure', timestamp: Date.now() } })
          ];
        } else {
          return this.workflowStepFunctionApi.create(params).pipe(
            mergeMap((response) => {
              const workflowStepFunction: WorkflowStepFunctionWithRelations = {
                subscribedEvents: subscribedEvents,
                publishedEventsOnFailure: publishedEventsOnFailure,
                publishedEventsOnSuccess: publishedEventsOnSuccess,
                ...response.workflowStepFunction as WorkflowStepFunctionWithRelations
              };
              // remove unnecessary field
              delete workflowStepFunction.workflowStepFunction;

              this.notificationService.createNotification(
                'success',
                'SHARED.SUCCESSFUL',
                `${workflowStepFunction.name} Successfully Created`
              );
              const actions = [
                WorkflowStepFunctionActions.CreateWorkflowStepFunctionSuccessful({ payload: { workflowStepFunction: workflowStepFunction, placeholderDataId: action.payload.placeholderDataId } })
              ] as Action[];
              if (action.payload.workflowStepFunction.subscribedEventIds?.length) {
                actions.push(
                  WorkflowStepFunctionSubscribedEventActions.AddWorkflowStepFunctionSubscribedEventSuccessful({
                    payload: {
                      workflowStepFunctionSubscribedEvent: response.subscribedEvents
                    }
                  })
                );
              }
              if (action.payload.workflowStepFunction.publishedEventOnFailureIds?.length) {
                actions.push(
                  WorkflowEventFailureActions.AddWorkflowStepFunctionPublishedEventOnFailureSuccessful({
                    payload: {
                      workflowStepFunctionPublishedEventOnFailure: response.publishedEventsOnFailure
                    }
                  })
                );
              }
              if (action.payload.workflowStepFunction.publishedEventOnSuccessIds?.length) {
                actions.push(
                  WorkflowEventSuccessActions.AddWorkflowStepFunctionPublishedEventOnSuccessSuccessful({
                    payload: {
                      workflowStepFunctionPublishedEventOnSuccess: response.publishedEventsOnSuccess
                    }
                  })
                );
              }
              if (action.payload.navigateAfterCreate) {
                actions.push(
                  new RouterActions.Navigate({ url: PATH_DEFINITIONS.WORKFLOW_STEP_FUNCTION.WORKFLOW_STEP_FUNCTION_LIST })
                );
              }
              return actions;
            }), catchError((error) => {
              this.notificationService.createNotification(
                'error',
                'SHARED.ERROR',
                'SHARED.COULDNT_CREATED'
              );
              return [
                WorkflowStepFunctionActions.CreateWorkflowStepFunctionFailure({ payload: { error, key: 'CreateWorkflowStepFunctionFailure', timestamp: Date.now() } })
              ];
            })
          );
        }
      })
    )
  );

  getWorkflowStepFunction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowStepFunctionActions.GetWorkflowStepFunction
      ),
      withLatestFrom(
        this.store.select(state => state.activeProject.data?.id)
      ),
      mergeMap(([action, activeProjectId]) => {
        const params = {
          filter: {
            where: {
              projectId: activeProjectId
            },
            include: ['subscribedEvents', 'publishedEventsOnSuccess', 'publishedEventsOnFailure']
          }
        };
        return this.workflowStepFunctionApi.find(params).pipe(
          map((workflowStepFunctions: WorkflowStepFunction[]) =>
            WorkflowStepFunctionActions.GetWorkflowStepFunctionSuccessful({ payload: { workflowStepFunctions } })
          ),
          catchError((error) => ([
            WorkflowStepFunctionActions.GetWorkflowStepFunctionFailure({ payload: { error, key: 'GetWorkflowStepFunctionFailure', timestamp: Date.now() } })
          ]))
        );
      })
    )
  );

  updateWorkflowStepFunction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowStepFunctionActions.UpdateWorkflowStepFunction
      ),
      withLatestFrom(
        this.store.select(state => state.workflowEvent.data),
        this.store.select(state => state.workflowStepFunctionPublishedEventOnSuccess.data),
        this.store.select(state => state.workflowStepFunctionPublishedEventOnFailure.data),
        this.store.select(state => state.workflowStepFunctionSubscribedEvent.data),
        this.store.select(state => state.workflowStepFunction.data)
      ),
      mergeMap(([action, workflowEvents, workflowStepFunctionPublishedEventOnSuccess, workflowStepFunctionPublishedEventOnFailure, workflowStepFunctionSubscribedEvent, workflowStepFunctions]) => {
        const params = {
          id: action.payload.workflowStepFunctionId,
          body: action.payload.workflowStepFunctionBody
        };
        return this.workflowStepFunctionApi.updateById(params).pipe(
          mergeMap(() => {
            const subscribedEvents = workflowEvents?.filter(workflowEventItem => action.payload.workflowStepFunctionBody?.subscribedEventIds?.includes(workflowEventItem.id));
            const publishedEventsOnSuccess = workflowEvents?.filter(workflowEventItem => action.payload.workflowStepFunctionBody?.publishedEventOnSuccessIds?.includes(workflowEventItem.id));
            const publishedEventsOnFailure = workflowEvents?.filter(workflowEventItem => action.payload.workflowStepFunctionBody?.publishedEventOnFailureIds?.includes(workflowEventItem.id));
            const workflowStepFunction = workflowStepFunctions.find(workflowStepFunction => action.payload?.workflowStepFunctionId === workflowStepFunction.id);

            // #region Subscribed Event Start
            // created
            const createdSubscribedEvents = action.payload.workflowStepFunctionBody?.subscribedEventIds?.filter(id => !workflowStepFunctionSubscribedEvent?.some(event => event?.workflowEventId === id))
              ?.map(id => ({
                workflowEventId: id,
                workflowStepFunctionId: action.payload.workflowStepFunctionId,
                id: v4()
              }));
            const createSubscribedEventActions = createdSubscribedEvents
              ?.map(event => WorkflowStepFunctionSubscribedEventActions.CreateWorkflowStepFunctionSubscribedEventSuccessful({ payload: { workflowStepFunctionSubscribedEvent: event } }));

            // removed
            const removedSubscribedEvents = workflowStepFunctionSubscribedEvent
              ?.filter(subscribeEvent => subscribeEvent.workflowStepFunctionId === action.payload?.workflowStepFunctionId && !subscribedEvents?.some(event => event.id === subscribeEvent.workflowEventId));
            const removeSubscribedEventActions = removedSubscribedEvents
              ?.map(subscribeEvent => WorkflowStepFunctionSubscribedEventActions.DeleteWorkflowStepFunctionSubscribedEventSuccessful({ payload: { workflowStepFunctionSubscribedEventId: subscribeEvent.id } })) || [];
            // #endregion Subscribed Event End

            // #region Published Event on Success Event Start
            // created
            const createdPublishedEventOnSuccessIds = action.payload?.workflowStepFunctionBody?.publishedEventOnSuccessIds?.filter(publishedEventOnSuccessId => !workflowStepFunction?.publishedEventsOnSuccess
              ?.map(publishedEventOnSuccess => publishedEventOnSuccess.id)?.includes(publishedEventOnSuccessId));
            const createdPublishedEventOnSuccess = createdPublishedEventOnSuccessIds?.map(publishedEventOnSuccessId => ({
              workflowEventId: publishedEventOnSuccessId,
              workflowStepFunctionId: action.payload.workflowStepFunctionId,
              id: v4()
            }));
            const createPublishedEventOnSuccessActions = createdPublishedEventOnSuccess
              ?.map(eventOnSuccess => WorkflowEventSuccessActions.CreateWorkflowStepFunctionPublishedEventOnSuccessSuccessful({ payload: { workflowStepFunctionPublishedEventOnSuccess: eventOnSuccess } }));

            // removed
            const removedPublishedEventOnSuccessIds = workflowStepFunction?.publishedEventsOnSuccess?.map(publishedEventOnSuccess => publishedEventOnSuccess.id)
              ?.filter(publishedEventOnSuccessId => !action.payload?.workflowStepFunctionBody?.publishedEventOnSuccessIds?.includes(publishedEventOnSuccessId));
            const removedPublishedEventsOnSuccess = workflowStepFunctionPublishedEventOnSuccess?.filter(publishedEventSuccess => removedPublishedEventOnSuccessIds
              ?.includes(publishedEventSuccess.workflowEventId) && publishedEventSuccess?.workflowStepFunctionId === action.payload?.workflowStepFunctionId);
            const removePublishedEventOnSuccessActions = removedPublishedEventsOnSuccess
              ?.map(removedPublishedEventOnSuccess => WorkflowEventSuccessActions.DeleteWorkflowStepFunctionPublishedEventOnSuccessSuccessful({ payload: { workflowStepFunctionPublishedEventOnSuccessId: removedPublishedEventOnSuccess.id } }));
            // #endregion Published Event on Success Event End

            // #region Published Event on Failure Event Start
            // created
            const createdPublishedEventOnFailureIds = action.payload?.workflowStepFunctionBody?.publishedEventOnFailureIds?.filter(publishedEventOnFailureId => !workflowStepFunction.publishedEventsOnFailure
              ?.map(publishedEventOnFailure => publishedEventOnFailure.id)?.includes(publishedEventOnFailureId));
            const createdPublishedEventOnFailure = createdPublishedEventOnFailureIds?.map(publishedEventOnFailureId => ({
              workflowEventId: publishedEventOnFailureId,
              workflowStepFunctionId: action.payload.workflowStepFunctionId,
              id: v4()
            }));
            const createPublishedEventOnFailureActions = createdPublishedEventOnFailure
              ?.map(eventOnFailure => WorkflowEventFailureActions.CreateWorkflowStepFunctionPublishedEventOnFailureSuccessful({ payload: { workflowStepFunctionPublishedEventOnFailure: eventOnFailure } }));

            // removed
            const removedPublishedEventOnFailureIds = workflowStepFunction?.publishedEventsOnFailure?.map(publishedEventOnFailure => publishedEventOnFailure.id)
              ?.filter(publishedEventOnFailureId => !action.payload?.workflowStepFunctionBody.publishedEventOnFailureIds.includes(publishedEventOnFailureId));
            const removedPublishedEventOnFailure = workflowStepFunctionPublishedEventOnFailure?.filter(publishedEventOnFailure => removedPublishedEventOnFailureIds
              ?.includes(publishedEventOnFailure.workflowEventId) && publishedEventOnFailure?.workflowStepFunctionId === action.payload?.workflowStepFunctionId);
            const removePublishedEventOnFailureActions = removedPublishedEventOnFailure
              ?.map(removedPublishedEventOnFailure => WorkflowEventFailureActions.DeleteWorkflowStepFunctionPublishedEventOnFailureSuccessful({ payload: { workflowStepFunctionPublishedEventOnFailureId: removedPublishedEventOnFailure.id } }));
            // #endregion Published Event on Failure Event End

            const workflowStepFunctionWithEvents: any = {
              ...action.payload.workflowStepFunctionBody.workflowStepFunction,
              subscribedEvents: subscribedEvents,
              publishedEventsOnSuccess: publishedEventsOnSuccess,
              publishedEventsOnFailure: publishedEventsOnFailure
            };
            this.notificationService.createNotification(
              'success',
              'SHARED.SUCCESSFUL',
              `${workflowStepFunctionWithEvents.name} Successfully Updated`
            );
            return [
              WorkflowStepFunctionActions.UpdateWorkflowStepFunctionSuccessful({ payload: { workflowStepFunction: workflowStepFunctionWithEvents, workflowStepFunctionId: action.payload.workflowStepFunctionId } }),
              ...removePublishedEventOnSuccessActions,
              ...removePublishedEventOnFailureActions,
              ...removeSubscribedEventActions,
              ...createPublishedEventOnSuccessActions,
              ...createPublishedEventOnFailureActions,
              ...createSubscribedEventActions
            ];
          }), catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'SHARED.COULDNT_UPDATED'
            );
            return [
              WorkflowStepFunctionActions.UpdateWorkflowStepFunctionFailure({ payload: { error, key: 'UpdateWorkflowStepFunctionFailure', timestamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

  deleteWorkflowStepFunction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowStepFunctionActions.DeleteWorkflowStepFunction
      ),
      withLatestFrom(
        this.store.select(state => state.workflowStepFunction.data)
      ),
      mergeMap(([action, workflowStepFunction]) => {
        const params = {
          id: action.payload.workflowStepFunctionId
        };
        const workflowStepFunctionName = workflowStepFunction.find(workflowStepFunctionItem => workflowStepFunctionItem.id === params.id).name;

        return this.workflowStepFunctionApi.deleteById(params).pipe(
          map(() => {
            this.notificationService.createNotification(
              'success',
              'SHARED.SUCCESSFUL',
              `${workflowStepFunctionName} Successfully Deleted`
            );
            return WorkflowStepFunctionActions.DeleteWorkflowStepFunctionSuccessful({ payload: { workflowStepFunctionId: action.payload.workflowStepFunctionId } });
          }, catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'SHARED.COULDNT_DELETED'
            );
            return [
              WorkflowStepFunctionActions.DeleteWorkflowStepFunctionFailure({ payload: { error, key: 'DeleteWorkflowStepFunctionFailure', timestamp: Date.now() } })
            ];
          }))
        );
      })
    )
  );

  // #region PRE DATA TRANSFORMATION
  getPreDataTransformationData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowStepFunctionActions.GetPreDataTransformationData
      ),
      withLatestFrom(
        this.store.select(state => state.customFunction),
      ),
      mergeMap(([action, customFunction]) => {
        const customFunctions = customFunction?.data;
        const templateWorkflowFunctions = customFunction?.templateWorkflowFunctions;

        const workflowStepFunction = <WorkflowStepFunctionWithRelations>{
          ...action.payload.workflowStepFunction,
          customFunctionDefinition: customFunctions.find(customFunction => customFunction.id === action.payload.workflowStepFunction.customFunctionDefinitionId) || templateWorkflowFunctions.find(customFunction => customFunction.id === action.payload.workflowStepFunction.customFunctionDefinitionId)
        };

        if (action.payload.workflowStepFunction.preDataTransformationId) {
          return [
            WorkflowStepFunctionActions.GetPreDTSourceAndTargetSchemaData({
              payload: {
                workflowStepFunction,
                sourceSchema: workflowStepFunction.subscribedEvents[0].inputDataSample,
                targetSchema: workflowStepFunction.customFunctionDefinition.requestJSONSample
              }
            })
          ];
        } else {
          return [
            WorkflowStepFunctionActions.CreatePreDataTransformation({ payload: { workflowStepFunction } })
          ];
        }
      })
    )
  );

  createPreDataTransformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowStepFunctionActions.CreatePreDataTransformation
      ),
      mergeMap((action) => {
        const body = {
          inputVariableName: 'data',
          outputVariableName: 'payload'
        };
        const workflowStepFunction = action.payload.workflowStepFunction;

        return this.dataTransformationApi.create({ body }).pipe(
          map((dataTransformation) => WorkflowStepFunctionActions.CreatePreDataTransformationSuccessful({
            payload: {
              preDataTransformationId: dataTransformation.id,
              workflowStepFunction: workflowStepFunction
            }
          }))
        );
      }), catchError((error) => ([
        WorkflowStepFunctionActions.CreatePreDataTransformationFailure({
          payload: {
            error,
            key: 'CreatePreDataTransformation',
            timestamp: Date.now()
          }
        })
      ]))
    )
  );

  /**
   * update workflow step function after successfully creating DT
   *
   * @memberof WorkflowStepFunctionEffects
   */
  createPreDataTransformationSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowStepFunctionActions.CreatePreDataTransformationSuccessful
      ),
      mergeMap((action) => {
        const workflowStepFunctionId = action.payload.workflowStepFunction.id;
        const workflowStepFunctionWithPreDT = <WorkflowStepFunctionUpdateDtoPartial>{
          preDataTransformationId: action.payload.preDataTransformationId
        };

        return this.workflowStepFunctionApi.updateById({
          id: workflowStepFunctionId,
          body: {
            workflowStepFunction: workflowStepFunctionWithPreDT
          }
        }).pipe(
          map(() => {
            const workflowStepFunction = {
              ...action.payload.workflowStepFunction,
              preDataTransformationId: action.payload.preDataTransformationId
            };

            return WorkflowStepFunctionActions.GetPreDTSourceAndTargetSchemaData({
              payload: {
                workflowStepFunction,
                sourceSchema: workflowStepFunction.subscribedEvents[0].inputDataSample,
                targetSchema: workflowStepFunction.customFunctionDefinition.requestJSONSample
              }
            });
          })
        );
      }), catchError((error) => ([
        WorkflowStepFunctionActions.ErrorAction({
          payload: {
            error,
            key: 'UpdateWorkflowStepFunctionWithPreDT',
            timestamp: Date.now()
          }
        })
      ]))
    )
  );

  /**
   * source schema: event's input data sample
   * target schema: custom function's request schema
   *
   * @memberof WorkflowStepFunctionEffects
   */
  getPreDTSourceAndTargetSchemaData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowStepFunctionActions.GetPreDTSourceAndTargetSchemaData
      ),
      mergeMap((action) => {
        const preSourceSchemaBody = <GetJsonSchemaDto>{
          schemaName: `${action.payload.workflowStepFunction.subscribedEvents[0].name} Input Data Sample`,
          dataToCalculate: action.payload.workflowStepFunction.subscribedEvents[0].inputDataSample
        };

        return this.dataTransformationApi.getJSONSchema({ body: preSourceSchemaBody }).pipe(
          map((preSourceJsonSchema) => WorkflowStepFunctionActions.GetPreDTSourceAndTargetSchemaDataSuccessful({
            payload: {
              preDataTransformationId: action.payload.workflowStepFunction.preDataTransformationId,
              preSourceJsonSchema: preSourceJsonSchema,
              preTargetJsonSchema: action.payload.workflowStepFunction.customFunctionDefinition.requestJSONSample
            }
          }))
        );
      })
    )
  );

  /**
   * end get pre dt data process after getting source schema, target schema, and dt id
   *
   * @memberof WorkflowStepFunctionEffects
   */
  getPreDataTransformationDataSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowStepFunctionActions.GetPreDTSourceAndTargetSchemaDataSuccessful
      ),
      mergeMap((action) => [
        WorkflowStepFunctionActions.GetPreDataTransformationDataSuccessful({
          payload: {
            preDataTransformationId: action.payload.preDataTransformationId,
            sourceJsonSchema: action.payload.preSourceJsonSchema,
            targetJsonSchema: action.payload.preTargetJsonSchema
          }
        })
      ])
    )
  );
  // #endregion PRE DATA TRANSFORMATION

  // #region POST DATA TRANSFORMATION
  getPostDataTransformationData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowStepFunctionActions.GetPostDataTransformationData
      ),
      withLatestFrom(
        this.store.select(state => state.customFunction)
      ),
      mergeMap(([action, customFunction]) => {
        const customFunctions = customFunction?.data;
        const templateWorkflowFunctions = customFunction?.templateWorkflowFunctions;

        const workflowStepFunction = <WorkflowStepFunctionWithRelations>{
          ...action.payload.workflowStepFunction,
          customFunctionDefinition: customFunctions.find(customFunction => customFunction.id === action.payload.workflowStepFunction.customFunctionDefinitionId) || templateWorkflowFunctions.find(customFunction => customFunction.id === action.payload.workflowStepFunction.customFunctionDefinitionId)
        };
        if (workflowStepFunction.publishedEventsOnSuccess?.length) {
          if (action.payload.workflowStepFunction.postDataTransformationId) {
            return [
              WorkflowStepFunctionActions.GetPostDTSourceAndTargetSchemaData({
                payload: {
                  workflowStepFunction,
                  sourceSchema: workflowStepFunction.subscribedEvents[0].inputDataSample,
                  targetSchema: workflowStepFunction.customFunctionDefinition.responseJSONSample
                }
              })
            ];
          } else {
            return [
              WorkflowStepFunctionActions.CreatePostDataTransformation({ payload: { workflowStepFunction } })
            ];
          }
        } else {
          this.notificationService.createNotification(
            'warning',
            'Warning',
            'A published event is required to add post-data transformation.'
          );
          return [
            WorkflowStepFunctionActions.ErrorAction({
              payload: {
                error: 'PublishedEventIsNeededForPostDt',
                key: 'PublishedEventIsNeededForPostDt',
                timestamp: Date.now()
              }
            })
          ];
        }
      })
    )
  );

  /**
   * update workflow step function after successfully creating DT
   *
   * @memberof WorkflowStepFunctionEffects
   */
  createPostDataTransformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowStepFunctionActions.CreatePostDataTransformation
      ),
      mergeMap((action) => {
        const body = {
          inputVariableName: 'data',
          outputVariableName: 'payload'
        };
        const workflowStepFunction = action.payload.workflowStepFunction;

        return this.dataTransformationApi.create({ body }).pipe(
          map((dataTransformation) => WorkflowStepFunctionActions.CreatePostDataTransformationSuccessful({
            payload: {
              postDataTransformationId: dataTransformation.id,
              workflowStepFunction: workflowStepFunction
            }
          }))
        );
      }), catchError((error) => ([
        WorkflowStepFunctionActions.CreatePostDataTransformationFailure({
          payload: {
            error,
            key: 'CreatePostDataTransformation',
            timestamp: Date.now()
          }
        })
      ]))
    )
  );

  createPostDataTransformationSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowStepFunctionActions.CreatePostDataTransformationSuccessful
      ),
      mergeMap((action) => {
        const workflowStepFunctionId = action.payload.workflowStepFunction.id;
        const workflowStepFunctionWithPostDT = <WorkflowStepFunctionUpdateDtoPartial>{
          postDataTransformationId: action.payload.postDataTransformationId
        };

        return this.workflowStepFunctionApi.updateById({
          id: workflowStepFunctionId,
          body: {
            workflowStepFunction: workflowStepFunctionWithPostDT
          }
        }).pipe(
          map(() => {
            const workflowStepFunction = {
              ...action.payload.workflowStepFunction,
              postDataTransformationId: action.payload.postDataTransformationId
            };

            return WorkflowStepFunctionActions.GetPostDTSourceAndTargetSchemaData({
              payload: {
                workflowStepFunction,
                sourceSchema: workflowStepFunction.subscribedEvents[0].inputDataSample,
                targetSchema: workflowStepFunction.customFunctionDefinition.responseJSONSample
              }
            });
          })
        );
      }), catchError((error) => ([
        WorkflowStepFunctionActions.ErrorAction({
          payload: {
            error,
            key: 'UpdateWorkflowStepFunctionWithPostDT',
            timestamp: Date.now()
          }
        })
      ]))
    )
  );

  /**
   * source schema: custom function's response schema
   * target schema: published events' input data sample
   *
   * @memberof WorkflowStepFunctionEffects
   */
  getPostDTSourceAndTargetSchemaData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowStepFunctionActions.GetPostDTSourceAndTargetSchemaData
      ),
      mergeMap((action) => {
        const postTargeteSchemaBody = <GetJsonSchemaDto>{
          schemaName: `${action.payload.workflowStepFunction.publishedEventsOnSuccess[0].name} Input Data Sample`,
          dataToCalculate: action.payload.workflowStepFunction.publishedEventsOnSuccess[0].inputDataSample
        };

        return this.dataTransformationApi.getJSONSchema({ body: postTargeteSchemaBody }).pipe(
          map((postTargetJsonSchema) => WorkflowStepFunctionActions.GetPostDTSourceAndTargetSchemaDataSuccessful({
            payload: {
              postDataTransformationId: action.payload.workflowStepFunction.postDataTransformationId,
              postSourceJsonSchema: action.payload.workflowStepFunction.customFunctionDefinition.responseJSONSample,
              postTargetJsonSchema: postTargetJsonSchema
            }
          }))
        );
      })
    )
  );

  /**
  * end get post dt data process after getting source schema, target schema, and dt id
  *
  * @memberof WorkflowStepFunctionEffects
  */
  getPostDataTransformationDataSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowStepFunctionActions.GetPostDTSourceAndTargetSchemaDataSuccessful
      ),
      mergeMap((action) => [
        WorkflowStepFunctionActions.GetPostDataTransformationDataSuccessful({
          payload: {
            postDataTransformationId: action.payload.postDataTransformationId,
            sourceJsonSchema: action.payload.postSourceJsonSchema,
            targetJsonSchema: action.payload.postTargetJsonSchema
          }
        })
      ])
    )
  );
  // #endregion POST DATA TRANSFORMATION

  handleWorkflowEventUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowEventActions.UpdateWorkflowEventSuccessful
      ),
      map(action => WorkflowStepFunctionActions.GetWorkflowStepFunction())
    )
  );

}
