import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@rappider/authentication/guards';
import { PAGE_DEFINITIONS } from '@rappider/shared/definitions';
import { ActiveProjectGuard, VerifyPersonGuard } from '@rappider/shared/guards';
import { RappiderEditFormModule, RappiderImageModule, RappiderListGridModule, RappiderTitleToolbarModule } from '@rappider/rappider-components';
/* Components */
import { ProjectDocumentListComponent } from './components/project-document-list/project-document-list.component';
import { ProjectDocumentCreateComponent } from './components/project-document-create/project-document-create.component';
import { ProjectDocumentEditComponent } from './components/project-document-edit/project-document-edit.component';
import { RappiderDocumentUploadModule } from 'libs/components/src/lib/components/document-upload/document-upload.module';
import { RappiderPreviewerModule } from 'libs/components/src/lib/components/previewer/previewer.module';

const routes: Routes = [
  {
    path: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_DOCUMENT_LIST.URL,
    component: ProjectDocumentListComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
  },
  {
    path: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_DOCUMENT_CREATE.URL,
    component: ProjectDocumentCreateComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
  },
  {
    path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_DOCUMENT_EDIT.URL}/:id`,
    component: ProjectDocumentEditComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
  }
];


@NgModule({
  declarations: [
    ProjectDocumentListComponent,
    ProjectDocumentCreateComponent,
    ProjectDocumentEditComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    RappiderListGridModule,
    RappiderTitleToolbarModule,
    RappiderEditFormModule,
    RappiderDocumentUploadModule,
    RappiderPreviewerModule,
    RappiderImageModule
  ]
})
export class RappiderProjectDocumentModule { }
