import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ERRORS } from '@rappider/shared/configs';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';

@Component({
  selector: 'rappider-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

  error: any; // eslint-disable-line @typescript-eslint/no-explicit-any

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.getErrorKeyFromUrl();
  }

  /**
   * Get errorkey from the url and set
   *
   * @memberof ErrorPageComponent
   */
  getErrorKeyFromUrl() {
    const errorKey = this.activatedRoute.snapshot.params.errorKey;
    if (errorKey) {
      this.setErrorMessage(errorKey);
    }
  }

  /**
   * Sets error message
   *
   * @param {string} errorKey
   * @memberof ErrorPageComponent
   */
  setErrorMessage(errorKey: string) {
    const error = ERRORS.find(e => errorKey === e.key);
    if (error) {
      this.error = error;
    } else {
      /* If the error key does not match the key of error objects in the error list, it goes to page 404. */
      this.router.navigateByUrl(PATH_DEFINITIONS.ERROR.NOT_FOUND_PATH);
    }
  }

}
