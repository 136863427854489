/* eslint-disable @typescript-eslint/no-use-before-define */
import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DataSchemaInterface } from '@rappider/api-sdk';
import { NzTreeComponent, NzTreeNode, NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { DataSchemaSelectBlurOutput } from './models/data-schema-select-blur-output';
import { DataSchemaSelectValue } from './models/data-schema-select-value';
import { NO_FIELD_ALERT } from './models/data-schema-select';

@Component({
  selector: 'rappider-data-schema-select',
  templateUrl: './data-schema-select.component.html',
  styleUrls: ['./data-schema-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DataSchemaSelectComponent),
      multi: true
    }
  ]
})
export class DataSchemaSelectComponent implements OnInit, OnChanges, ControlValueAccessor {

  @ViewChild('nzTree') nzTree: NzTreeComponent;

  @Input() dataSchema: DataSchemaInterface;
  @Input() isNoFieldAlertShown = true;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter<DataSchemaSelectBlurOutput[]>();

  noFieldAlert = NO_FIELD_ALERT;
  _value: DataSchemaSelectValue[];

  get value() {
    return this._value;
  }

  set value(value: DataSchemaSelectValue[]) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
  }

  selectedNode: any;

  nodes: NzTreeNode[];


  ngOnInit(): void {
    this.nodes = <NzTreeNode[]>this.buildTree(this.dataSchema);
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: DataSchemaSelectValue[]) {
    this._value = value;
    this.setSelectedNode();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onTreeValueChange(key: string) {
    this.getSelectedTree(key);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSchema) {
      /* rebuild the tree if a new dataSchema input is recieved */
      this.nodes = <NzTreeNode[]>this.buildTree(changes.dataSchema.currentValue);
    }
  }

  /**
   * builds tree from given data schema. propertyKeyId created to distinguish properties with same name
   *
   * @param {DataSchemaInterface} dataSchema
   * @param {string} [parentKeyId]
   * @return {*}
   * @memberof DataSchemaSelectComponent
   */
  buildTree(dataSchema: DataSchemaInterface, parentKeyId?: string) {
    const nodes: NzTreeNodeOptions[] = dataSchema?.fields?.map(field => {
      const propertyKeyId = parentKeyId ? (parentKeyId + '.' + field.id) : field.id;
      if (!(field.type && field.type.fields?.length) || field.isArray) {
        return { title: field.name, key: propertyKeyId, isLeaf: true, data: field };
      } else {
        return { title: field.name, key: propertyKeyId, data: field, children: this.buildTree(field.type, propertyKeyId) };
      }
    });
    return nodes;
  }

  getSelectedTree(key: string) {
    let treeFieldNodes: DataSchemaSelectValue[] = [];
    if (key) {
      let isRoot = false;
      let currentNode = this.nzTree.getTreeNodeByKey(key)?.origin;
      treeFieldNodes = [
        currentNode.data
      ];
      while (!isRoot) {
        currentNode = this.nzTree.getTreeNodeByKey(this.nzTree.getTreeNodeByKey(currentNode?.key).getParentNode()?.key);
        if (currentNode) {
          treeFieldNodes.push(currentNode.origin.data);
        } else {
          isRoot = true;
        }
      }
      treeFieldNodes.reverse();
    }
    this.value = treeFieldNodes;
  }

  setSelectedNode() {
    if (this.value?.length) {
      this.selectedNode = this.value[this.value?.length - 1].title;
    } else {
      this.selectedNode = null;
    }
  }

}
