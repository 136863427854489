import { createSelector } from '@ngrx/store';
import { UIDataEventInterface } from '@rappider/api-sdk';
import { NewUiDataUpdateFunction, UiDataStoreWithRelations } from '@rappider/rappider-sdk';
export const uiDataUpdateFunctionListDataSelector = createSelector(
  state => state['uiDataEvent']?.data,
  state => state['uiDataUpdateFunction']?.data,
  state => state['uiDataStore']?.data,
  (uiDataEvents: UIDataEventInterface[], uiDataUpdateFunctions: NewUiDataUpdateFunction[],uiDataStores: UiDataStoreWithRelations[]) => {
    const uiDataUpdateFunctionListData = uiDataUpdateFunctions.map(updateFunction => {
      const eventData = uiDataEvents.find(event => event.id === updateFunction.uiDataEventId);
      const uiDataStoreName = uiDataStores.find(uiDataStore => uiDataStore.id === updateFunction.uiDataStoreId)?.name;
      if (!eventData) {
        return null; // If event data doesn't exist, skip this update function
      }
      return {
        ...updateFunction,
        dataEventName: eventData.name,
        uiDataStoreName
      };
    }).filter(Boolean); // Filter out null values

    return uiDataUpdateFunctionListData;
  }
);
