import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormLayout, SelectComponentConfig } from '@rappider/rappider-components/utils';
import { ProjectModelFieldWithRelations } from '@rappider/rappider-sdk';
import { ProjectModelFieldUpdateData } from '../../utils/project-model-field-update-data';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DATA_FIELD_DEFAULT_FNS, DATA_FIELD_TYPES } from 'libs/shared/src/lib/definitions/model-data-field';

@Component({
  selector: 'rappider-update-project-model-field-form',
  templateUrl: './update-project-model-field-form.component.html',
  styleUrls: ['./update-project-model-field-form.component.scss']
})
export class UpdateProjectModelFieldFormComponent implements OnInit {
  /* editing project model field */
  @Input() projectModelField!: ProjectModelFieldWithRelations;
  /* loading state */
  @Input() loading!: boolean;
  /* layout of the form 'horizontal' or 'vertical' */
  @Input() formLayout: FormLayout = FormLayout.Vertical;
  @Input() isSubmitButtonFloat = true;
  @Input() submitButtonLoading: boolean;

  @Output() updateProjectModelField = new EventEmitter<ProjectModelFieldUpdateData>();

  /* form group */
  updateProjectModelFieldForm: FormGroup;

  monacoEditorConfig = {
    options: {
      theme: 'vs-dark',
      language: 'markdown',
    }
  };

  typeSelectConfig: SelectComponentConfig = {
    options: DATA_FIELD_TYPES,
    settings: {
      searchable: true
    }
  };

  defaultFnSelectConfig: SelectComponentConfig = {
    options: DATA_FIELD_DEFAULT_FNS
  };

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.updateProjectModelFieldForm = this.formBuilder.group({
      name: [this.projectModelField?.name, Validators.required],
      type: [this.projectModelField?.type, Validators.required],
      default: [this.projectModelField?.default],
      defaultFn: [this.projectModelField?.defaultFn],
      isArray: [this.projectModelField?.isArray],
      isRequired: [this.projectModelField?.isRequired],
      isHidden: [this.projectModelField?.isHidden],
      isUnique: [this.projectModelField?.isUnique],
      description: [this.projectModelField?.description]
    });
  }

  onUpdateProjectModelFieldFormSubmit() {
    this.updateProjectModelField.emit({
      projectModelField: this.updateProjectModelFieldForm.value,
      projectModelFieldId: this.projectModelField.id
    });
  }

}
