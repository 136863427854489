<div *ngIf="viewMode === ViewMode.List">
  <rappider-crud-view [modelNameSingular]="modelNameSingular"
                      [mainTitleConfig]="mainTitleConfig!"
                      [breadCrumbOptions]="titleConfig"
                      [viewMode]="viewMode"
                      [listMode]="ListMode.Grid"
                      [showCreateButton]="true"
                      [createButtonTitle]="createItemTitle"
                      [displayToolbar]="false"
                      [listGridData]="customFunctions"
                      [listGridConfig]="listGridConfig"
                      [cardListConfig]="cardListConfig"
                      [createFormConfig]="createItemFormConfig!"
                      [updateFormConfig]="updateItemFormConfig!"
                      [updateFormData]="activeItem"
                      [isListGridDataLoading]="isCustomFunctionsLoading"
                      [updateFormSubmitButtonLoading]="isCustomFunctionsLoading"
                      [createFormSubmitButtonLoading]="isCustomFunctionsLoading"
                      (createFormSubmit)="onCreateFormSubmit($event)"
                      (updateFormSubmit)="onUpdateFormSubmit($event)"
                      (itemDeleted)="onItemDeleted($event)">
  </rappider-crud-view>
</div>

<div *ngIf="viewMode === ViewMode.Create">
  <rappider-custom-function-create [mainTitleConfig]="mainTitleConfig!"
                                   [titleConfig]="titleConfig"
                                   [displayToolbar]="false"
                                   (createFormSubmit)="onCreateFormSubmit($event)"></rappider-custom-function-create>
</div>

<div *ngIf="viewMode === ViewMode.Edit || viewMode === ViewMode.Update">
  <rappider-custom-function-edit [mainTitleConfig]="mainTitleConfig!"
                                 [titleConfig]="titleConfig"
                                 [displayToolbar]="false"
                                 [updateFormData]="activeItem"
                                 (updateFormSubmit)="onUpdateFormSubmit($event)"></rappider-custom-function-edit>
</div>
