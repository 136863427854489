<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"></rappider-title-toolbar>

<section class="project-page-list">
  <rappider-list-grid [config]="PROJECT_PAGES_CONFIG"
                      [loading]="loading"
                      [data]="pages"
                      (columnActionClick)="onColumnActionClick($event)"></rappider-list-grid>
</section>

<rappider-modal [visible]="pageStatusChangeModalVisibility"
                [title]="pageStatusChangeModal.title"
                [width]="pageStatusChangeModal.width"
                [okText]="pageStatusChangeModal.okText"
                [cancelText]="pageStatusChangeModal.cancelText"
                [fullScreen]="pageStatusChangeModal.fullScreen"
                (okClick)="onSavePageDevelopmentStatus()"
                (cancelClick)="pageStatusChangeModalVisibilityChange()">

  <label class="mb-2">{{ 'Page Status' | translate }}</label>
  <rappider-select [(ngModel)]="pageStatus"
                   [options]="pageStatusConfig"
                   (ngModelChange)="statusChange($event)"></rappider-select>
</rappider-modal>
