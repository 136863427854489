<div *ngIf="currentStatus === ProjectExportProcessStatus.Processing"
     class="current-wrapper">
  <div class="current-process">
    <div class="process-wrapper">
      <rappider-lottie-player [options]="currentProcessAnimation?.options"
                              [height]="currentProcessAnimation?.height"
                              [width]="currentProcessAnimation?.width"></rappider-lottie-player>
    </div>
    <div class="process-message">
      <h6>{{ currentMessage }}</h6>
    </div>
  </div>
</div>


<div *ngIf="displayedProcesses.length > 1"
     class="completed-steps">
  <h5>{{ 'Completed Steps' | translate }}:</h5>
  <rappider-button [text]="exportDetailsTogglerButton.text"
                   [icon]="exportDetailsTogglerButton.icon"
                   (click)="onProjectExportDetailsTogglerClick()"></rappider-button>
</div>
<hr class="completed-steps-divider">

<div class="export-details"
     [style.max-height]="handleExportDetailsHeight()">
  <div *ngIf="displayedProcesses.length > 1"
       class="wrapper">
    <ng-container *ngFor="let process of displayedProcesses let i = index; trackBy: trackProcess">
      <div *ngIf="currentMessage !== process.message || 
    (currentMessage === process.message && process.status !== ProjectExportProcessStatus.Processing)"
           class="process">
        <div class="process-wrapper">
          <rappider-lottie-player [options]="process?.animation?.options"
                                  [height]="process?.animation?.height"
                                  [width]="process?.animation?.width"></rappider-lottie-player>
        </div>
        <div *ngIf="process.message !== currentMessage || currentStatus !== ProjectExportProcessStatus.Processing"
             class="process-message">
          <h6>{{ process?.message }}</h6>
        </div>
      </div>
    </ng-container>
  </div>
</div>