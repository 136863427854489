/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MessageTemplate } from '../models/message-template';
import { MessageTemplatePartial } from '../models/message-template-partial';
import { MessageTemplateWithRelations } from '../models/message-template-with-relations';
import { NewMessageTemplate } from '../models/new-message-template';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class MessageTemplateControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation messageTemplateControllerCount
   */
  static readonly MessageTemplateControllerCountPath = '/message-templates/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params?: {
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, MessageTemplateControllerService.MessageTemplateControllerCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params?: {
    where?: any;
  }): Observable<LoopbackCount> {

    return this.count$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation messageTemplateControllerFindLibrary
   */
  static readonly MessageTemplateControllerFindLibraryPath = '/message-templates/library';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findLibrary()` instead.
   *
   * This method doesn't expect any request body.
   */
  findLibrary$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MessageTemplate>>> {

    const rb = new RequestBuilder(this.rootUrl, MessageTemplateControllerService.MessageTemplateControllerFindLibraryPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MessageTemplate>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findLibrary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findLibrary(params?: {
  }): Observable<Array<MessageTemplate>> {

    return this.findLibrary$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MessageTemplate>>) => r.body as Array<MessageTemplate>)
    );
  }

  /**
   * Path part for operation messageTemplateControllerFindById
   */
  static readonly MessageTemplateControllerFindByIdPath = '/message-templates/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<MessageTemplate>> {

    const rb = new RequestBuilder(this.rootUrl, MessageTemplateControllerService.MessageTemplateControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageTemplate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
    filter?: any;
  }): Observable<MessageTemplate> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<MessageTemplate>) => r.body as MessageTemplate)
    );
  }

  /**
   * Path part for operation messageTemplateControllerDeleteById
   */
  static readonly MessageTemplateControllerDeleteByIdPath = '/message-templates/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MessageTemplateControllerService.MessageTemplateControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: string;
  }): Observable<void> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation messageTemplateControllerUpdateById
   */
  static readonly MessageTemplateControllerUpdateByIdPath = '/message-templates/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    body?: MessageTemplatePartial
  }): Observable<StrictHttpResponse<MessageTemplate>> {

    const rb = new RequestBuilder(this.rootUrl, MessageTemplateControllerService.MessageTemplateControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageTemplate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    body?: MessageTemplatePartial
  }): Observable<MessageTemplate> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<MessageTemplate>) => r.body as MessageTemplate)
    );
  }

  /**
   * Path part for operation messageTemplateControllerFind
   */
  static readonly MessageTemplateControllerFindPath = '/message-templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<MessageTemplateWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, MessageTemplateControllerService.MessageTemplateControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MessageTemplateWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<MessageTemplateWithRelations>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MessageTemplateWithRelations>>) => r.body as Array<MessageTemplateWithRelations>)
    );
  }

  /**
   * Path part for operation messageTemplateControllerCreate
   */
  static readonly MessageTemplateControllerCreatePath = '/message-templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    body?: NewMessageTemplate
  }): Observable<StrictHttpResponse<MessageTemplate>> {

    const rb = new RequestBuilder(this.rootUrl, MessageTemplateControllerService.MessageTemplateControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageTemplate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    body?: NewMessageTemplate
  }): Observable<MessageTemplate> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<MessageTemplate>) => r.body as MessageTemplate)
    );
  }

}
