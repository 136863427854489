import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjectInterface } from '@rappider/api-sdk';
import { PROJECT_MODEL_DATA_FIELD_CREATE_OR_EDIT_CONFIG } from '@rappider/shared/configs';
import { defaultToolbarTitleHeadingSize, PATH_DEFINITIONS, PROJECT_MODEL_FIELD_DEFAULT_DATA } from '@rappider/shared/definitions';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ProjectModelField, ProjectModelWithRelations } from '@rappider/rappider-sdk';
import { BreadcrumbOption, CrudFormInputTemplateItem, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { toLower } from 'lodash';
import { NotificationService } from '@rappider/services';
import { getProjectAndProjectModelsLoading } from '../data-field-list/utils/loading-selector';
import { nameExistsValidator } from 'libs/shared/src/lib/functions/name-exists-validator';
import { ProjectModelFieldActions } from '@rappider/project-model-field';
import { getProjectModelsWithFields } from '../../modules/project-model-relation/utils/get-project-model-with-fields';


@Component({
  selector: 'rappider-project-model-data-field-create',
  templateUrl: './project-model-data-field-create.component.html',
  styleUrls: ['./project-model-data-field-create.component.scss']
})
export class ProjectModelDataFieldCreateComponent implements OnInit, OnDestroy {

  /* form config */
  PROJECT_MODEL_DATA_FIELD_CREATE_CONFIG = PROJECT_MODEL_DATA_FIELD_CREATE_OR_EDIT_CONFIG;
  /* subscriptions */
  subscriptions: Subscription[];
  /* main title */
  mainTitle: HeadingComponentConfig = {
    content: 'PROJECT_MODULE.PROJECT_MODEL_DATA_FIELD_LIST_COMPONENT.ADD_PROJECT_MODEL_DATA_FIELD',
    type: defaultToolbarTitleHeadingSize
  };
  /* page title */
  title: string[] | BreadcrumbOption[];
  /* project model id */
  projectModelId: string;
  /* default data for project model field */
  PROJECT_MODEL_FIELD_DEFAULT_DATA = PROJECT_MODEL_FIELD_DEFAULT_DATA;
  /* active project */
  activeProject: {
    name?: string;
    id?: string;
  };
  /* active project model */
  projectModel: ProjectModelWithRelations;
  /* project models loading state */
  isLoading: boolean;
  /* project model loaded state */
  isLoaded: boolean;

  displayToolbar = false;
  displayToolbarBackButton = false;
  isFormLoading = false;
  fieldsAndRelations: string[];

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.getProjectModelIdFromUrl();
    this.subscribeToData();
    this.setDataFieldInputAreaByType(this.PROJECT_MODEL_FIELD_DEFAULT_DATA.type);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToProjectModelsAndFields(),
      this.subscribeToProjectModelsLoading(),
      this.subscribeToProjectModelsLoaded()
    ];
  }

  /**
   * subscribe to active project to set the title
   *
   * @returns
   * @memberof ProjectModelDataFieldCreateComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectInterface) => {
      if (activeProject) {
        this.activeProject = {
          id: activeProject.id,
          name: activeProject.name
        };
        this.setTitle();
      }
    });
  }

  subscribeToProjectModelsAndFields() {
    return this.store.select(<any>getProjectModelsWithFields).subscribe(data => {
      if (data) {
        const activeProjectModel = data.projectModels.find(projectModel => projectModel.id === this.projectModelId);
        this.projectModel = { ...activeProjectModel };
        this.setTitle();
        const activeProjectModelFields = activeProjectModel?.fields?.map(field => field?.name);
        const activeProjectModelRelations = data?.projectModelRelations?.filter(relation => relation?.sourceModelId === activeProjectModel?.id).map(relation => relation?.name);
        if (activeProjectModelFields || activeProjectModelRelations) {
          const activeProjectModelFieldsAndRelations = [
            ...activeProjectModelFields || [],
            ...activeProjectModelRelations || []
          ];
          this.fieldsAndRelations = activeProjectModelFieldsAndRelations;
        }
      }
    });
  }

  subscribeToProjectModelsLoading() {
    return this.store.select(<any>getProjectAndProjectModelsLoading).subscribe(loading => {
      this.isLoading = loading;
    });
  }

  subscribeToProjectModelsLoaded() {
    return this.store.select(state => state.projectModel.isLoaded).subscribe(isLoaded => {
      this.isLoaded = isLoaded;
    });
  }

  getProjectModelIdFromUrl() {
    this.projectModelId = this.activatedRoute.snapshot.params['projectModelId'];
  }

  onDataFieldValueChange(data) {
    if (data.type) {
      this.setDataFieldInputAreaByType(data.type);
    }
  }

  setDataFieldInputAreaByType(type) {
    const defaultFormItem = <CrudFormInputTemplateItem>
      this.PROJECT_MODEL_DATA_FIELD_CREATE_CONFIG.items.find(item => item.fieldName === 'default');

    defaultFormItem.typeAndFormat = {
      type: toLower(type),
      format: null
    };
    this.PROJECT_MODEL_DATA_FIELD_CREATE_CONFIG = { ...this.PROJECT_MODEL_DATA_FIELD_CREATE_CONFIG };
  }

  onFormSubmit(projectModelDataField: ProjectModelField) {
    if (this.fieldsAndRelations.includes(projectModelDataField.name)) {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'Name Already Exists Field Or Relation'
      );
    } else {
      this.isFormLoading = true;
      this.store.dispatch(ProjectModelFieldActions.CreateProjectModelField({ payload: { projectModelField: { ...projectModelDataField, projectModelId: this.projectModelId }, navigateAfterCreate: true, relationedTypeId: this.projectModel.relationedTypeId } }));
    }
  }

  setTitle() {
    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
      },
      {
        label: 'PROJECT_MODULE.PROJECT_MODEL_DATA_FIELD_LIST_COMPONENT.DATA_MODELS',
        redirectUrl: PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_LIST
      },
      {
        label: this.projectModel?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_DATA_FIELD_LIST}/${this.projectModel?.id}`, queryParams: { tab: 'fields' }
      },
      {
        label: 'PROJECT_MODULE.PROJECT_MODEL_DATA_FIELD_LIST_COMPONENT.ADD_PROJECT_MODEL_DATA_FIELD'
      }
    ];
  }

}
