<section>
  <div class="container-block">
    <div *ngIf="avatar"
         class="user-image">
      <rappider-avatar [text]="avatar.text"
                       [iconName]="avatar.iconName"
                       [shape]="avatar.shape"
                       [imageUrl]="avatar.imageUrl"
                       [altText]="avatar.altText"
                       [cssStyle]="avatar.cssStyle"
                       [size]="avatar.size"
                       [borderSettings]="avatar.borderSettings"
                       [sizeSettings]="avatar.sizeSettings"
                       [colorSettings]="avatar.colorSettings"
                       [boxShadowSettings]="avatar.boxShadowSettings"
                       [paddingSettings]="avatar.paddingSettings"
                       [marginSettings]="avatar.marginSettings"
                       [description]="avatar.description"></rappider-avatar>
    </div>
    <div class="container-area">
      <div *ngIf="title"
           class="title">
        <rappider-heading [content]="title.content"
                          [type]="title.type"></rappider-heading>
      </div>
      <div class="content">
        <span [innerHTML]="content"></span>
      </div>
      <div *ngIf="subtitle"
           class="sub-title">
        <rappider-heading [content]="subtitle.content"
                          [type]="subtitle.type"></rappider-heading>
      </div>
    </div>
  </div>
</section>
