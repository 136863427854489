/* eslint-disable */
import { DataMapping, DataSchemaTreeFieldNode } from '../index';

declare var Object: any;
export interface DataSchemaTreeFieldInterface {
  id?: string;
  dataMappingId?: string;
  sourceToDataMappingId?: string;
  targetToDataMappingId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  dataMapping?: DataMapping;
  nodes?: DataSchemaTreeFieldNode[];
}

export class DataSchemaTreeField implements DataSchemaTreeFieldInterface {
  'id': string;
  'dataMappingId': string;
  'sourceToDataMappingId': string;
  'targetToDataMappingId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  dataMapping: DataMapping;
  nodes: DataSchemaTreeFieldNode[];
  constructor(data?: DataSchemaTreeFieldInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `DataSchemaTreeField`.
   */
  public static getModelName() {
    return 'DataSchemaTreeField';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of DataSchemaTreeField for dynamic purposes.
   **/
  public static factory(
    data: DataSchemaTreeFieldInterface
  ): DataSchemaTreeField {
    return new DataSchemaTreeField(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'DataSchemaTreeField',
      plural: 'DataSchemaTreeFields',
      path: 'data-schema-tree-fields',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        dataMappingId: {
          name: 'dataMappingId',
          type: 'string',
        },
        sourceToDataMappingId: {
          name: 'sourceToDataMappingId',
          type: 'string',
        },
        targetToDataMappingId: {
          name: 'targetToDataMappingId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
          default: new Date(0),
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
      },
      relations: {
        dataMapping: {
          name: 'dataMapping',
          type: 'DataMapping',
          model: 'DataMapping',
          relationType: 'belongsTo',
          keyFrom: 'dataMappingId',
          keyTo: 'id',
        },
        nodes: {
          name: 'nodes',
          type: 'DataSchemaTreeFieldNode[]',
          model: 'DataSchemaTreeFieldNode',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'dataSchemaTreeFieldId',
        },
      },
    };
  }
}
