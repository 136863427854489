import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isActivePersonProjectOwner } from './selectors/is-active-person-project-owner.selector';

@Injectable({
  providedIn: 'root'
})
export class ProjectOwnerGuard implements CanActivate {

  constructor(
    private store: Store<any>
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot): Observable<boolean> {

    console.log('PROJECT OWNER GUARD');
    return this.store.select(<any>isActivePersonProjectOwner).pipe(
      map((isOwner: boolean) => <boolean>isOwner)
    );
  }

}


