/* eslint-disable */
import { KeyValue, HttpRequest } from '../index';

declare var Object: any;
export interface FieldDataDefinitionInterface {
  id?: string;
  name: string;
  componentType: string;
  dataType: string;
  customData?: any;
  dataFieldName: string;
  dataKeyFieldName: string;
  dataValueFieldName: string;
  keyValueId?: string;
  httpRequestId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  keyValueData?: KeyValue;
  httpRequestData?: HttpRequest;
}

export class FieldDataDefinition implements FieldDataDefinitionInterface {
  'id'?: string;
  'name': string;
  'componentType': string;
  'dataType': string;
  'customData': any;
  'dataFieldName': string;
  'dataKeyFieldName': string;
  'dataValueFieldName': string;
  'keyValueId': string;
  'httpRequestId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  keyValueData: KeyValue;
  httpRequestData: HttpRequest;
  constructor(data?: FieldDataDefinitionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FieldDataDefinition`.
   */
  public static getModelName() {
    return 'FieldDataDefinition';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of FieldDataDefinition for dynamic purposes.
   **/
  public static factory(
    data: FieldDataDefinitionInterface
  ): FieldDataDefinition {
    return new FieldDataDefinition(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'FieldDataDefinition',
      plural: 'FieldDataDefinitions',
      path: 'field-data-definitions',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        componentType: {
          name: 'componentType',
          type: 'string',
        },
        dataType: {
          name: 'dataType',
          type: 'string',
        },
        customData: {
          name: 'customData',
          type: 'any',
        },
        dataFieldName: {
          name: 'dataFieldName',
          type: 'string',
        },
        dataKeyFieldName: {
          name: 'dataKeyFieldName',
          type: 'string',
        },
        dataValueFieldName: {
          name: 'dataValueFieldName',
          type: 'string',
        },
        keyValueId: {
          name: 'keyValueId',
          type: 'string',
        },
        httpRequestId: {
          name: 'httpRequestId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
      },
      relations: {
        keyValueData: {
          name: 'keyValueData',
          type: 'KeyValue',
          model: 'KeyValue',
          relationType: 'belongsTo',
          keyFrom: 'keyValueId',
          keyTo: 'id',
        },
        httpRequestData: {
          name: 'httpRequestData',
          type: 'HttpRequest',
          model: 'HttpRequest',
          relationType: 'belongsTo',
          keyFrom: 'httpRequestId',
          keyTo: 'id',
        },
      },
    };
  }
}
