import { Component, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { TabsetComponentConfig, TabsetPosition, TabsetSize, TabsetType } from '../../utils/tabset';
import { ButtonComponentConfig } from '../../utils/button';

interface TabsInterface {
  template: TemplateRef<any>;
}

@Component({
  selector: 'rappider-tabset',
  templateUrl: './tabset.component.html',
  styleUrls: ['./tabset.component.scss']
})
export class RappiderTabsetComponent {

  /* rappider-tabset array as TabsetComponentConfig */
  @Input() tabs: TabsetComponentConfig[];
  /* Current tab's index, default 0 */
  @Input() selectedIndex: number;
  /* preset tab bar size */
  @Input() tabsetSize: TabsetSize;
  /* Position of tabs - 'top' | 'right' | 'bottom' | 'left' */
  @Input() tabsetPosition = TabsetPosition.Top;
  /* Basic style of tabs - 'line' | 'card' | 'editable-card' */
  @Input() tabsetType: TabsetType;
  /* The gap between tabs */
  @Input() tabsetBarGutter = 0;
  /* Whether hide all tabs */
  @Input() hideAll: boolean;
  /* Centers tabs */
  @Input() center: boolean;
  /* Extra Content */
  @Input() extraContent: ButtonComponentConfig;

  @Output() selectedIndexChange = new EventEmitter<number>();
  @Output() selectChange = new EventEmitter<{ index: number; tab: TabsetComponentConfig }>();
  @Output() extraContentConfirm = new EventEmitter();

  closeTab({ index }: { index: number }): void {
    this.tabs.splice(index, 1);
  }

  newTab(): void {
    this.tabs.push({ title: 'New Tab', content: 'New Content' });
    this.selectedIndex = this.tabs.length;
  }

  onExtraContentConfirm() {
    this.extraContentConfirm.emit();
  }

  onSelectedIndexChange(value: number) {
    this.selectedIndexChange.emit(value);
  }

  onSelectChange(index: number, tab: TabsetComponentConfig) {
    this.selectChange.emit({ index: index, tab: tab });
  }

}
