import * as UIDataStoreActions from './ui-data-store.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import { UiDataStoreWithRelations } from '@rappider/rappider-sdk';
import { cloneDeep, orderBy } from 'lodash';

export const uiDataStoreFeatureKey = 'uiDataStore';

export interface State {
  data: UiDataStoreWithRelations[];
  loading: boolean;
  isLoaded: boolean;
  error: {
    timestamp?: number;
    key?: string;
    error?: any;
  };
  fieldsLoadingDataStoreIds: string[];
  fieldsLoadingByModelIds: string[];
}

export const initialState: State = {
  data: [],
  loading: true,
  isLoaded: false,
  error: {},
  fieldsLoadingDataStoreIds: [],
  fieldsLoadingByModelIds: []
};

export function reducer(
  state: State = initialState,
  action: UIDataStoreActions.Actions | AuthenticationActions.Actions
): State {
  switch (action.type) {

    case UIDataStoreActions.ActionTypes.SetUIDataStores:
      return {
        ...state,
        data: action.payload.uiDataStores,
        isLoaded: true
      };

    case UIDataStoreActions.ActionTypes.CreateUIDataStoresAccordingToIdSuccessful:
      return {
        ...state,
        data: [
          ...state.data.filter(uiDataStore => uiDataStore.id !== action.payload.uiDataStore.id),
          action.payload.uiDataStore
        ],
        loading: false
      };

    case UIDataStoreActions.ActionTypes.GetUIDataStoreFieldsLoadingByModelId:
      return {
        ...state,
        loading: false,
        fieldsLoadingByModelIds: state.fieldsLoadingByModelIds.filter(id => id !== action.payload.projectModelId)
      };

    case UIDataStoreActions.ActionTypes.SetLoadingState:
      return {
        ...state,
        loading: action.payload.loading
      };

    case UIDataStoreActions.ActionTypes.CreateUIDataStore:
      return {
        ...state,
        loading: true
      };

    case UIDataStoreActions.ActionTypes.CreateUIDataStoreSuccessful:
      return {
        ...state,
        loading: false,
        data: [
          ...state.data,
          action.payload.uiDataStore
        ]
      };

    case UIDataStoreActions.ActionTypes.GetUIDataStores:
      return {
        ...state,
        loading: true,
        isLoaded: false,
        fieldsLoadingDataStoreIds: [],
        fieldsLoadingByModelIds: []
      };

    case UIDataStoreActions.ActionTypes.GetUIDataStoreSuccessful:
      return {
        ...state,
        loading: false,
        data: orderBy([
          ...state.data,
          action.payload.uiDataStore
        ], 'createdDate', 'asc')
      };

    case UIDataStoreActions.ActionTypes.UpdateUIDataStore:
      return {
        ...state,
        loading: true
      };

    case UIDataStoreActions.ActionTypes.UpdateUIDataStoreSuccessful:
      {
        const updatedUIDataStore = {
          ...cloneDeep(state.data.find(uiDataStore => uiDataStore.id === action.payload.id)),
          ...action.payload.uiDataStore
        };

        return {
          ...state,
          data: orderBy([
            ...state.data.filter(uiDataStore => uiDataStore.id !== action.payload.id),
            updatedUIDataStore
          ], 'createdDate', 'asc'),
          loading: false,
        };
      }

    case UIDataStoreActions.ActionTypes.DeleteUIDataStore:
      return {
        ...state,
        loading: true
      };

    case UIDataStoreActions.ActionTypes.GetAutoGeneratedDataStoreSuccessful:
      return {
        ...state,
        data: [
          ...state.data,
          action.payload.uiDataStore
        ]

      };

    case UIDataStoreActions.ActionTypes.ErrorAction:
      {
        return {
          ...state,
          error: {
            ...state.error,
            timestamp: action.payload.timestamp,
            key: action.payload.key,
            error: action.payload.error
          },
          loading: false,
        };
      }

    case UIDataStoreActions.ActionTypes.GetUIDataStoreByProjectModelId:
      return {
        ...state,
        fieldsLoadingByModelIds: [
          ...state.fieldsLoadingByModelIds,
          action.payload.projectModelId
        ]
      };

    case AuthenticationActions.ActionTypes.Logout:
      return initialState;

    case AuthenticationActions.ActionTypes.ChangeActiveProjectSuccessful:
      return initialState;

    default:
      return state;
  }
}
