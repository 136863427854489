import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ProjectExternalScriptApi, ProjectExternalScriptInterface } from '@rappider/api-sdk';
import { ProjectExternalScriptControllerService } from '@rappider/rappider-sdk';
import { NotificationService } from '@rappider/services';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import * as ProjectScriptActions from './project-script.actions';
import { UpdateProjectScriptSuccessful, CreateProjectScriptSuccessful } from './project-script.actions';

@Injectable()
export class ProjectScriptEffects {
  constructor(
    private store: Store<any>,
    private actions$: Actions,
    private projectScriptApi: ProjectExternalScriptApi,
    private projectExternalScriptControllerService: ProjectExternalScriptControllerService,
    private notificationService: NotificationService
  ) { }

  /**
   * gets project scripts with project files by active project id
   *
   * @memberof ProjectScriptEffects
   */

  getProjectScripts$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectScriptActions.GetProjectScripts>(
      ProjectScriptActions.ActionTypes.GetProjectScripts
    ),
    withLatestFrom(
      this.store.select(state => state.activeProject.data?.id)
    ),
    mergeMap(([action, activeProjectId]) => {
      if (activeProjectId) {
        /* includes project files to project scipts */
        const params = { filter: { where: { 'projectId': activeProjectId }, include: ['projectFile'] } };
        return this.projectExternalScriptControllerService.find(params).pipe(
          mergeMap((projectScripts: any[]) => [
            new ProjectScriptActions.SetProjectScripts({ projectScripts: projectScripts }),
            new ProjectScriptActions.SetProjectScriptsSuccess()
          ])
        );
      }
    }), catchError(error => {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'SHARED.COULDNT_LOAD'
      );

      return [
        new ProjectScriptActions.ErrorAction({ error: error, key: 'GetProjectScripts', timestamp: Date.now() })
      ];
    })
  ));


  createProjectScript$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectScriptActions.CreateProjectScript>(
      ProjectScriptActions.ActionTypes.CreateProjectScript
    ),
    mergeMap((action) => {
      const projectScript = action.payload.projectScript;

      return this.projectScriptApi.create(projectScript).pipe(
        mergeMap((script) => {
          this.notificationService.createNotification(
            'success',
            projectScript.title || 'SHARED.SUCCESSFUL',
            'SHARED.SUCCESSFULLY_CREATED'
          );

          return [
            new ProjectScriptActions.CreateProjectScriptSuccessful({ projectScript: script }),
            new Navigate({ url: PATH_DEFINITIONS.PROJECTS.PROJECT_SCRIPT_LIST })
          ];
        }), catchError(error => {
          this.notificationService.createNotification(
            'error',
            projectScript.title || 'SHARED.SUCCESSFUL',
            'SHARED.COULDNT_CREATED'
          );

          return [new ProjectScriptActions.ErrorAction({ error: error, key: 'CreateProjectScript', timestamp: Date.now() })];
        })
      );
    })
  ));


  updateProjectScript$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectScriptActions.UpdateProjectScript>(
      ProjectScriptActions.ActionTypes.UpdateProjectScript
    ),
    mergeMap((action) => {
      const projectScriptBody = action.payload.projectScript;
      const projectScriptId = action.payload.id;

      return this.projectScriptApi.patchAttributes(projectScriptId, projectScriptBody).pipe(
        map((projectScript: ProjectExternalScriptInterface) => {
          /* if source is custom title shows in the notification, if source is file file's title shows in the notification */
          this.notificationService.createNotification(
            'success',
            projectScript.title || projectScript.projectFile.title,
            'SHARED.SUCCESSFULLY_UPDATED'
          );

          return new ProjectScriptActions.UpdateProjectScriptSuccessful({ projectScript });
        }, catchError(error => {
          /* if source is custom title shows in the notification, if source is file file's title shows in the notification */
          this.notificationService.createNotification(
            'error',
            projectScriptBody.title || projectScriptBody.projectFile.title,
            'SHARED.COULDNT_UPDATED'
          );

          return [new ProjectScriptActions.ErrorAction({ error: error, key: 'UpdateProjectScipt', timestamp: Date.now() })];
        })
        )
      );
    })
  ));


  navigateToListPage$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectScriptActions.UpdateProjectScriptSuccessful>(
      ProjectScriptActions.ActionTypes.UpdateProjectScriptSuccessful
    ),
    map(() => new Navigate({ url: PATH_DEFINITIONS.PROJECTS.PROJECT_SCRIPT_LIST }))
  ));


  deleteProjectScript$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectScriptActions.DeleteProjectScript>(
      ProjectScriptActions.ActionTypes.DeleteProjectScript
    ),
    withLatestFrom(
      this.store.select(state => state.projectScript?.data)
    ),
    mergeMap(([action, projectScripts]) => {
      const projectScriptId = action.payload.projectScriptId;
      const deletedProjectScript = projectScripts.find(
        (projectScript: ProjectExternalScriptInterface) => projectScript.id === projectScriptId
      );

      return this.projectScriptApi.deleteById(projectScriptId).pipe(
        map(() => {
          /* if source is custom title shows in the notification, if source is file file's title shows in the notification */
          this.notificationService.createNotification(
            'success',
            deletedProjectScript.title || deletedProjectScript.projectFile.title,
            'SHARED.SUCCESSFULLY_DELETED'
          );

          return new ProjectScriptActions.GetProjectScripts();
        }, catchError(error => {
          /* if source is custom title shows in the notification, if source is file file's title shows in the notification */
          this.notificationService.createNotification(
            'error',
            deletedProjectScript.title || deletedProjectScript.projectFile.title,
            'SHARED.COULDNT_DELETED'
          );

          return [new ProjectScriptActions.ErrorAction({ error: error, key: 'DeleteProjectScript', timestamp: Date.now() })];
        })
        )
      );
    })
  ));

}
