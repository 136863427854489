// eslint-disable-next-line no-shadow
export enum ViewMode {
    Create = 'create',
    List = 'list',
    Update = 'update',
    Edit = 'edit', // alias for update
    Delete = 'delete',
    ViewItem = 'view-item',
    Search = 'search',
    Custom = 'custom'
}
