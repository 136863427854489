export const pageConfigFieldNames = {
  title: 'title',
  description: 'description',
  layoutId: 'layoutId',
  moduleId: 'moduleId',
  isPublic: 'isPublic',
  isActive: 'isActive',
  slug: 'slug',
  keywords: 'keywords'
};
