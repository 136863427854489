import { Component, Input } from '@angular/core';
import { ButtonComponentConfig, ButtonType, CardOneComponentConfig, HeadingComponentConfig, HeadingType, TextComponentConfig } from '@rappider/rappider-components/utils';

@Component({
  selector: 'rappider-example-template-two',
  templateUrl: './example-template-two.component.html',
  styleUrls: ['./example-template-two.component.scss']
})
export class ExampleTemplateTwoComponent {

  @Input() showTooltip = true;

  cardOne: CardOneComponentConfig = {
    image: {
      source: 'https://images.unsplash.com/photo-1599009434802-ca1dd09895e7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
    },
    titles: [{
      content: 'Title',
      type: HeadingType.H1
    }],
    currency: 'USD',
    price: 999,
    finalPrice: 99,
    descriptions: [{
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
    }]
  };

  titles: HeadingComponentConfig[] = [
    {
      content: 'LOREM IPSUM DOLOR',
      type: HeadingType.H1
    },
    {
      content: 'Cras Leo Arcu',
      type: HeadingType.H4
    }
  ];

  paragraph: TextComponentConfig = {
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis pulvinar lorem in facilisis viverra. Cras leo arcu, sodales nec mattis eu, tincidunt at dui. Nunc viverra luctus arcu ac tristique. Fusce nulla velit, ornare tempus efficitur vel, semper feugiat nibh.'
  };

  buttons: ButtonComponentConfig[] = [
    {
      type: ButtonType.Default,
      text: 'Button',
      block: true
    },
    {
      type: ButtonType.Primary,
      text: 'Button',
      block: true
    }
  ];

}
