import { InputGroupComponentConfig } from '@rappider/rappider-components/utils';
import { IconType } from '../../../definitions/icon-type.enum';

export const endpointListSearchBarComponentConfig: InputGroupComponentConfig = {
  textbox: {
    placeholder: 'Search in path'
  },
  suffixIcon: {
    name: 'fas fa-search',
    type: IconType.FontAwesome
  }
};
