import { HeaderMenuItem } from './header-menu-item.enum';

export const contentEditorHeaderMenuItems = [
    HeaderMenuItem.GlobalStyle,
    HeaderMenuItem.TemplateSelector,
    HeaderMenuItem.Theme
];

export const headerMenuItems = [
    ...contentEditorHeaderMenuItems
];
