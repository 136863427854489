import { Validators } from '@angular/forms';
import { CrudFormSelectItem, CrudViewFormItemType } from '@rappider/rappider-components/utils';

export const THROUGH_MODEL_FORM_ITEM =
  <CrudFormSelectItem>
  {
    index: 4,
    title: 'PROJECT_MODULE.PROJECT_MODEL_RELATION_LIST_COMPONENT.THROUGH_MODEL',
    fieldName: 'throughModelId',
    type: CrudViewFormItemType.Select,
    validators: [
      {
        type: Validators.required,
        errorKey: 'required',
        errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
      }
    ]
  };
