import { Component, OnInit } from '@angular/core';
import { ADMIN_COMMENT_EDIT_FORM_CONFIG } from './config/edit-comment-config';
import { BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { PAGE_DEFINITIONS, PATH_DEFINITIONS, defaultToolbarTitleHeadingSize } from '@rappider/shared/definitions';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { CommentWithRelations, ProjectWithRelations } from '@rappider/rappider-sdk';
import { UpdateAdminComment } from '../../state/admin-comment/admin-comment.actions';

@Component({
  selector: 'rappider-comment-edit',
  templateUrl: './comment-edit.component.html',
  styleUrls: ['./comment-edit.component.scss']
})
export class CommentEditComponent implements OnInit {

  ADMIN_COMMENT_EDIT_FORM_CONFIG = ADMIN_COMMENT_EDIT_FORM_CONFIG;

  mainTitle: HeadingComponentConfig = {
    content: 'Admin Dashboard',
    type: defaultToolbarTitleHeadingSize
  };
  title: BreadcrumbOption[] = [];

  commentId: string;
  editedComment: CommentWithRelations;
  subscriptions: Subscription[];
  isLoading = false;
  projectId: string;
  project: ProjectWithRelations;

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.commentId = this.getCommentId();
    this.projectId = this.getProjectId();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToProjectLoading(),
      this.subscribeToComments(),
      this.subscribeToProjects()
    ];
  }

  getCommentId() {
    return this.activatedRoute.snapshot.params['commentId'];
  }

  subscribeToComments() {
    return this.store.select(state => state.adminComment.data).subscribe((comments: CommentWithRelations[]) => {
      if (this.commentId) {
        this.editedComment = comments?.find(comment => comment.id === this.commentId);
      }
    });
  }

  subscribeToProjects() {
    return this.store.select(state => state.adminProjects.data).subscribe((projects: ProjectWithRelations[]) => {
      if (this.projectId) {
        this.project = projects?.find(project => project.id === this.projectId);
        if (this.project) {
          this.setTitle();
        }
      }
    });
  }

  getProjectId() {
    return this.activatedRoute.snapshot.params['id'];
  }


  subscribeToProjectLoading() {
    return this.store.select(state => state.adminComment?.isLoading).subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  setTitle() {
    this.title = [
      {
        label: 'Admin Dashboard',
        redirectUrl: PAGE_DEFINITIONS.ADMIN_DASHBOARD.URL
      },
      {
        label: 'Projects',
        redirectUrl: PATH_DEFINITIONS.ADMIN_DASHBOARD.PROJECTS
      },
      {
        label: 'Project Detail',
        redirectUrl: `${PATH_DEFINITIONS.ADMIN_DASHBOARD.PROJECT_DETAIL}/${this.project?.id}`
      },
      {
        label: this.project?.name
      },
      {
        label: 'Comment Edit'
      }
    ];
  }

  editComment(data: any) {
    this.store.dispatch(UpdateAdminComment({ commentId: this.editedComment.id, comment: data }));
  }
}
