import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterFindRowElementComponent } from './filter-find-row-element/filter-find-row-element.component';
import {
  RappiderButtonModule,
  RappiderCheckboxModule,
  RappiderDatePickerModule,
  RappiderHeadingModule,
  RappiderIconModule,
  RappiderInputTemplateModule,
  RappiderMonacoEditorModule,
  RappiderNumberInputModule,
  RappiderRadioGroupModule,
  RappiderSelectModule,
  RappiderTextboxModule
} from '@rappider/rappider-components';
import { FormsModule } from '@angular/forms';
import { FilterFindRowComponent } from './filter-find-row/filter-find-row.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { FilterWrapperComponent } from './filter-wrapper/filter-wrapper.component';
import { RappiderModalModule } from '@rappider/rappider-components';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { JoyrideModule } from 'ngx-joyride';


@NgModule({
  declarations: [
    FilterFindRowElementComponent,
    FilterFindRowComponent,
    FilterWrapperComponent
  ],
  imports: [
    CommonModule,
    RappiderRadioGroupModule,
    FormsModule,
    RappiderButtonModule,
    RappiderSelectModule,
    TranslateModule,
    SharedComponentsModule,
    RappiderCheckboxModule,
    RappiderDatePickerModule,
    RappiderNumberInputModule,
    RappiderTextboxModule,
    RappiderHeadingModule,
    RappiderModalModule,
    NzSelectModule,
    RappiderInputTemplateModule,
    RappiderIconModule,
    RappiderMonacoEditorModule,
    NzIconModule,
    JoyrideModule.forChild()
  ],
  exports: [
    FilterFindRowElementComponent,
    FilterFindRowComponent,
    FilterWrapperComponent
  ]
})
export class FilterFindModule { }
