import { Component, forwardRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RappiderFunctionParameterType } from '../../../shared/interfaces/data-mapping.interface';
import { ButtonComponentConfig, ButtonType, PropertyType } from '@rappider/rappider-components/utils';
import { SchemaTreeSelectComponent } from '../../shared-components/schema-tree-select/schema-tree-select.component';
import { SchemaTreeService } from '../../../services/schema-tree/schema-tree.service';
import { NotificationService } from 'libs/components/src/lib/services';

@Component({
  selector: 'rappider-create-data-mapping',
  templateUrl: './create-data-mapping.component.html',
  styleUrls: ['./create-data-mapping.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => CreateDataMappingComponent),
      multi: true
    }
  ]
})
export class CreateDataMappingComponent implements ControlValueAccessor, OnChanges {

  @ViewChild('schemaTreeSelect') schemaTreeSelectComponent: SchemaTreeSelectComponent;

  @Input() selectedSourceFields: string[] = [];
  @Input() selectedTargetField = '';
  @Input() functions;

  /**
   * this tree converted from json schema with the function called buildTreeFromJSONSchema from SchemaTreeService
   *
   * @memberof CreateDataMappingComponent
   */
  @Input() tree;

  @Input() targetTree;

  defaultDataMapping = {
    function: {
      parameters: []
    }
  };

  addedSourceFieldValue: string;

  addedStaticField;

  addSourceFieldModalVisible = false;

  isAddStaticFieldModalVisible = false;

  _value: any;

  deleteButton: ButtonComponentConfig = {
    text: 'SHARED.DELETE',
    icon:
    {
      name: 'far fa-trash'
    }
  };
  addSourceFieldButton: ButtonComponentConfig = {
    text: 'PROJECT_MODULE.DATA_MAPPING_CREATE_COMPONENT.ADD_SOURCE_FIELD',
    type: ButtonType.Default,
    icon: {
      name: 'fas fa-plus'
    }
  };

  addStaticFieldButton: ButtonComponentConfig = {
    text: 'PROJECT_MODULE.DATA_MAPPING_CREATE_COMPONENT.ADD_STATIC_FIELD',
    type: ButtonType.Default,
    icon: {
      name: 'fas fa-plus'
    }
  };


  constructor(
    private schemaTreeService: SchemaTreeService,
    private notificationService: NotificationService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedSourceFields?.currentValue) {
      this.setPreselectedSourceFields();
    }
  }

  set value(value) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
  }

  get value() {
    return this._value;
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value) {
    this._value = value || this.defaultDataMapping;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedSourceFields, event.previousIndex, event.currentIndex);
    /* To trigger change detection for custom function */
    this.selectedSourceFields = [...this.selectedSourceFields];
  }

  deleteItem(index: number) {
    this.selectedSourceFields = this.selectedSourceFields.filter((item, itemIndex) => index !== itemIndex);
    this.value.function.parameters.splice(index, 1);
  }

  handleAddSourceFieldModalVisibility(visibility: boolean) {
    this.addSourceFieldModalVisible = visibility;
    if (!visibility) {
      this.addedSourceFieldValue = null;
    }
  }

  onSourceFieldAdd() {
    if (this.selectedSourceFields && this.addedSourceFieldValue) {
      this.selectedSourceFields.push(this.addedSourceFieldValue);
      const parameter = {
        type: RappiderFunctionParameterType.SourceField,
        fieldTree: this.schemaTreeService.getFieldTreeFromField(this.addedSourceFieldValue, this.schemaTreeSelectComponent)
      };
      try {
        this.value.function.parameters.push(
          parameter
        );
      } catch {
        this.value.function.parameters = [
          parameter
        ];
      }
    } else {
      this.selectedSourceFields = [];
    }
    this.addedSourceFieldValue = null;
    this.handleAddSourceFieldModalVisibility(false);
  }

  setPreselectedSourceFields() {
    if (this.selectedSourceFields.length > 0) {
      const parameters = this.selectedSourceFields.map(sourceField => ({
        type: RappiderFunctionParameterType.SourceField,
        fieldTree: this.schemaTreeService.getFieldTreeFromField(sourceField, this.schemaTreeSelectComponent)
      })
      );
      this.value.function.parameters = parameters;
    }
  }

  handleAddStaticFieldModalVisibility(visibility: boolean) {
    this.isAddStaticFieldModalVisible = visibility;
    if (!visibility) {
      this.addedStaticField = null;
    }
  }

  onStaticFieldAdd() {
    try {
      if (this.addedStaticField.type === PropertyType.Object
        || this.addedStaticField.type === PropertyType.Array
      ) {
        this.addedStaticField.value = JSON.parse(this.addedStaticField.value);
      }
      const staticField = {
        type: 'static',
        valueFormat: this.addedStaticField.format,
        valueType: this.addedStaticField.type,
        value: this.addedStaticField.value,
        key: this.addedStaticField.key
      };
      this.value.function.parameters = [
        ...this.value.function.parameters,
        staticField
      ];
      console.log(staticField);
      this.selectedSourceFields = [
        ...(this.selectedSourceFields || []),
        staticField.key
      ];
      this.handleAddStaticFieldModalVisibility(false);
    } catch (error) {
      this.notificationService.createNotification(
        'error',
        'Error',
        'DATA_TRANSFORMATION_MODULE.CREATE_DATA_MAPPING_COMPONENT.VALUE_IS_NOT_VALID'
      );
    }
  }

  onStaticFieldChange(staticField) {
    this.addedStaticField = {
      type: 'static',
      valueFormat: staticField.format,
      valueType: staticField.type,
      value: staticField.value
    };
  }
}
