export const projectCreateSteps = {
  step1: {
    label: 'What is the name of the project that you would like to create?',
    description: ''
  },
  step2: {
    label: 'Can you provide a description of what you envision for this project?',
    description: 'Your description should be more than 500 characters in length. Please provide a comprehensive description of your project and ensure your description is detailed, addressing all facets of your vision, goals, and any specific functionalities or features you have in mind.'
  },
  step3: {
    label: 'Could you please describe the target audience for this project?',
    description: 'Consider factors such as their demographics, behaviors, needs, challenges, and any specific preferences they might have.'
  },
  step4: {
    label: 'Do you wish to include an authentication module in your project?',
    description: 'This feature allows users to securely log in, manage their profiles, and ensures restricted access where needed.'
  },
  step5: {
    label: 'Would you like to integrate a payment module into your project?',
    description: 'This feature facilitates secure payment processing, supports various payment methods, and ensures smooth financial operations.'
  }
};

export const chatSessionLoadingMessageAfterProjectCreate = `
<i class="fa-sharp fa-solid fa-spinner-third fa-spin"></i> &nbsp;
Rappider AI is currently generating your project. Please be patient as this might take a few minutes.
`;

export const chatSessionLoadingMessageWithoutIcon = 'Rappider AI is currently generating your project. Please be patient as this might take a few minutes.';

export const chatSessionLoadingSuccessMessageAfterProjectCreate = 'Okay, we have generated your project.';
