<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>

<section>
  <div class="add-project-script">
    <div class="form-container">
      <form nz-form
            [formGroup]="updateProjectScriptForm"
            (ngSubmit)="submitUpdateProjectScriptForm()"
            nzLayout="horizontal">
        <!-- Type -->
        <nz-form-item>
          <nz-form-label [nzLg]="6"
                         [nzXs]="24">{{ 'SHARED.TYPE' | translate }}</nz-form-label>
          <nz-form-control [nzLg]="18"
                           [nzXs]="24"
                           [nzErrorTip]="errorTemplate">
            <rappider-select formControlName="type"
                             [options]="typeOptions"
                             [disabled]="externalScript?.sourceType === 'file'"
                             (ngModelChange)="onTypeChange($event)">
            </rappider-select>
          </nz-form-control>
        </nz-form-item>

        <!-- Location -->
        <nz-form-item>
          <nz-form-label [nzLg]="6"
                         [nzXs]="24">{{ 'SHARED.LOCATION' | translate }}</nz-form-label>
          <nz-form-control [nzLg]="18"
                           [nzXs]="24"
                           [nzErrorTip]="errorTemplate">
            <rappider-select formControlName="location"
                             [options]="SCRIPT_LOCATION_OPTIONS">
            </rappider-select>
          </nz-form-control>
        </nz-form-item>

        <!-- TITLE AND CODE MIRROR FOR CUSTOM SCRIPT -->
        <ng-container *ngIf="content.visible">
          <nz-form-item>
            <nz-form-label [nzLg]="6"
                           [nzXs]="24">{{ 'SHARED.TITLE' | translate }}</nz-form-label>
            <nz-form-control [nzLg]="18"
                             [nzXs]="24">
              <rappider-textbox formControlName="title"></rappider-textbox>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzLg]="6"
                           [nzXs]="24">{{ 'SHARED.CONTENT' | translate }}</nz-form-label>
            <nz-form-control [nzLg]="24"
                             [nzXs]="24"
                             [nzErrorTip]="errorTemplate">
              <rappider-spin [spinning]="!content.codemirrorVisible">
                <rappider-code-editor *ngIf="content.codemirrorVisible"
                                      formControlName="content"
                                      [settings]="contentCodemirrorSettings"></rappider-code-editor>
              </rappider-spin>
            </nz-form-control>
          </nz-form-item>
        </ng-container>

        <!-- SELECTED FILE -->
        <nz-form-item *ngIf="selectedFile">
          <nz-form-label [nzLg]="6"
                         [nzXs]="24">
            {{'PROJECT_MODULE.ADD_PROJECT_SCRIPT_COMPONENT.SELECTED_FILE' | translate}}
          </nz-form-label>

          <nz-form-control [nzLg]="18"
                           [nzXs]="24">
            <section class="file-label">
              <rappider-input-label [title]="selectedFile.title"></rappider-input-label>
            </section>
          </nz-form-control>
        </nz-form-item>

        <!-- SUBMIT BUTTON -->
        <nz-form-item>
          <nz-form-label [nzLg]="6"
                         [nzXs]="24"
                         [nzNoColon]="true">
          </nz-form-label>
          <nz-form-control [nzLg]="6"
                           [nzXs]="24">
            <div class="button">
              <button nz-button
                      nzType="primary"
                      type="submit"
                      [nzLoading]="editProjectScriptFormSubmitted">
                {{'SHARED.SUBMIT' | translate}}
              </button>
            </div>
          </nz-form-control>
        </nz-form-item>
        <ng-template #errorTemplate>
          <small>{{ 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE' | translate }}</small>
        </ng-template>
      </form>
    </div>
  </div>
</section>