import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TitleToolbarComponentConfig } from '@rappider/rappider-components/utils';
import { Project, ProjectTheme } from '@rappider/rappider-sdk';
import { defaultToolbarTitleHeadingSize, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Subscription } from 'rxjs';
import { GetProjectThemes, UpdateProjectTheme } from '../../../../../project/src/lib/states/project-theme-state/project-theme.actions';
import { PROJECT_THEME_EDIT_FORM_CONFIG } from './project-theme-edit-form-config';
import { NotificationService } from '@rappider/services';

@Component({
  selector: 'rappider-project-theme-edit',
  templateUrl: './project-theme-edit.component.html',
  styleUrls: ['./project-theme-edit.component.scss']
})
export class ProjectThemeEditComponent implements OnInit {

  loading = false;
  PROJECT_THEME_EDIT_FORM_CONFIG = PROJECT_THEME_EDIT_FORM_CONFIG;
  titleToolbarConfig: TitleToolbarComponentConfig;
  activeProject: Project;
  projectTheme: ProjectTheme;
  subscriptions: Subscription[] = [];
  submitButtonLoading = false;
  filteredProjectThemeNames: string[] = [];

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.subscribeToData();
    this.getActiveProjectThemeIdFromUrl();
  }

  subscribeToData() {
    this.subscriptions.push(
      this.subscribeToActiveProject(),
      this.subscribeToLoading()
    );
  }

  getActiveProjectThemeIdFromUrl() {
    const activeThemeId = this.activatedRoute.snapshot.params['id'];
    if (activeThemeId) {
      this.subscriptions.push(
        this.subscribeToProjectTheme(activeThemeId)
      );
    }
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe(activeProject => {
      this.activeProject = activeProject;
      if (activeProject) {
        this.store.dispatch(new GetProjectThemes());
      }
    });
  }

  subscribeToLoading() {
    return this.store.select(state => state.projectTheme.loading).subscribe(loading => {
      this.submitButtonLoading = loading;
    });
  }

  subscribeToProjectTheme(themeId: string) {
    return this.store.select(state => state.projectTheme?.data).subscribe(projectThemes => {
      this.filteredProjectThemeNames = projectThemes.filter(projectTheme => projectTheme.id !== themeId)
        .map(projectTheme => projectTheme.name);
      this.projectTheme = projectThemes?.find(projectTheme => projectTheme.id === themeId);
      if (this.projectTheme) {
        this.setTitleToolbarConfig();
      }
    });
  }

  setTitleToolbarConfig() {
    this.titleToolbarConfig = {
      mainTitle: {
        content: 'Themes',
        type: defaultToolbarTitleHeadingSize
      },
      options: [
        {
          label: this.activeProject.name,
          redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject.id}`
        },
        {
          label: 'Project Themes',
          redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_THEME_LIST_PATH}`
        },
        {
          label: this.projectTheme.name
        },
        {
          label: 'Edit Project Theme'
        }
      ]
    };
    this.loading = false;
  }

  updateProjectTheme(formData: any) {
    if (this.filteredProjectThemeNames.includes(formData.name)) {
      this.notificationService.createNotification('error', 'Error', 'Name Already Exists in Themes');
    } else {
      this.store.dispatch(new UpdateProjectTheme({
        projectThemeId: this.projectTheme.id,
        projectTheme: {
          ...formData
        },
        navigateToProjectThemeList: true
      }));
    }
  }

}
