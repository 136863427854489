
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

/* This component depends on a 3rd part package: marked to compile markdown to html */
import { marked } from 'marked';

@Component({
  selector: 'rappider-markdown-viewer',
  templateUrl: 'markdown-viewer.component.html',
  styleUrls: ['./markdown-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RappiderMarkdownViewerComponent implements OnInit, OnChanges {
  @Input() markdownText: string | undefined;

  markDownPreviewValue = '';

  ngOnInit(): void {
    this.setMarkDownPreviewValue(this.markdownText);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.markdownText) {
      this.setMarkDownPreviewValue(this.markdownText);
    }
  }

  setMarkDownPreviewValue(markdownText: string) {
      this.markDownPreviewValue = marked.parse(markdownText, { breaks: true });
  }
}
