<nz-tree *ngIf="tree"
         #nzTree
         class="tree-select"
         [nzSelectedKeys]="selectedNodes"
         [nzCheckedKeys]="selectedNodes"
         [nzData]="tree"
         [nzMultiple]="isMultiple"
         nzShowIcon
         nzShowSearch
         nzPlaceHolder="Please select"
         [nzTreeTemplate]="nzTreeTemplate"
         (nzClick)="onCheckboxChange($event)">
  <ng-template #nzTreeTemplate
               let-node
               let-origin="origin">
    <div style="display: flex; align-items: center;">
      <span>{{ getTitle(node) }}</span>
      <i *ngIf="!node.isLeaf && isNewItemButtonVisible"
         style="margin-left:15px"
         nz-icon
         nzType="plus"
         (click)="onAddItemToNodeClick(node)"></i>
    </div>
  </ng-template>
</nz-tree>