<section>
  <div *ngFor="let item of data"
       class="item-area">
    <div class="property-title">
      <rappider-label [content]="item.key | translate"></rappider-label>
    </div>

    <div class="property-value">
      <div *ngFor="let value of item.value">
        <rappider-text [text]="value"></rappider-text>
      </div>
    </div>
  </div>
</section>
