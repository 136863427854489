import { Injectable } from '@angular/core';
import { BUFFER_MS } from '../../definitions/timestamp-buffer';

@Injectable({
  providedIn: 'root'
})
export class StateService {


  /**
   * Adds a buffer and compares the current time with the time in the incoming parameter
   *
   * @param {number} timestamp
   * @return {*}
   * @memberof StateService
   */
  getBufferedTimestamp(timestamp: number) {
    return new Date().getTime() < timestamp + BUFFER_MS;
  }

  /* TODO: this will checked with time stamp */
  checkIfDataIsOld(data: any[]) {
    return true;
  }

}
