/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PersonInvitation } from '../models/person-invitation';
import { PersonInvitationInviteRequestDto } from '../models/person-invitation-invite-request-dto';
import { PersonInvitationWithRelations } from '../models/person-invitation-with-relations';

@Injectable({
  providedIn: 'root',
})
export class PersonInvitationControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation personInvitationControllerAcceptInvitationByToken
   */
  static readonly PersonInvitationControllerAcceptInvitationByTokenPath = '/person-invitations/accept/{token}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acceptInvitationByToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptInvitationByToken$Response(params: {
    token: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, PersonInvitationControllerService.PersonInvitationControllerAcceptInvitationByTokenPath, 'patch');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `acceptInvitationByToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptInvitationByToken(params: {
    token: string;
  }): Observable<any> {

    return this.acceptInvitationByToken$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation personInvitationControllerCancelInvitation
   */
  static readonly PersonInvitationControllerCancelInvitationPath = '/person-invitations/cancel/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelInvitation()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelInvitation$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, PersonInvitationControllerService.PersonInvitationControllerCancelInvitationPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cancelInvitation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelInvitation(params: {
    id: string;
  }): Observable<any> {

    return this.cancelInvitation$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation personInvitationControllerDeclineInvitationByToken
   */
  static readonly PersonInvitationControllerDeclineInvitationByTokenPath = '/person-invitations/decline/{token}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `declineInvitationByToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  declineInvitationByToken$Response(params: {
    token: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, PersonInvitationControllerService.PersonInvitationControllerDeclineInvitationByTokenPath, 'patch');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `declineInvitationByToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  declineInvitationByToken(params: {
    token: string;
  }): Observable<any> {

    return this.declineInvitationByToken$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation personInvitationControllerInvite
   */
  static readonly PersonInvitationControllerInvitePath = '/person-invitations/invite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invite()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invite$Response(params?: {
    body?: PersonInvitationInviteRequestDto
  }): Observable<StrictHttpResponse<PersonInvitation>> {

    const rb = new RequestBuilder(this.rootUrl, PersonInvitationControllerService.PersonInvitationControllerInvitePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PersonInvitation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `invite$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invite(params?: {
    body?: PersonInvitationInviteRequestDto
  }): Observable<PersonInvitation> {

    return this.invite$Response(params).pipe(
      map((r: StrictHttpResponse<PersonInvitation>) => r.body as PersonInvitation)
    );
  }

  /**
   * Path part for operation personInvitationControllerResendInvitation
   */
  static readonly PersonInvitationControllerResendInvitationPath = '/person-invitations/resend/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resendInvitation()` instead.
   *
   * This method doesn't expect any request body.
   */
  resendInvitation$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, PersonInvitationControllerService.PersonInvitationControllerResendInvitationPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resendInvitation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resendInvitation(params: {
    id: string;
  }): Observable<any> {

    return this.resendInvitation$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation personInvitationControllerFindById
   */
  static readonly PersonInvitationControllerFindByIdPath = '/person-invitations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<PersonInvitationWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, PersonInvitationControllerService.PersonInvitationControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PersonInvitationWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
    filter?: any;
  }): Observable<PersonInvitationWithRelations> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<PersonInvitationWithRelations>) => r.body as PersonInvitationWithRelations)
    );
  }

  /**
   * Path part for operation personInvitationControllerFind
   */
  static readonly PersonInvitationControllerFindPath = '/person-invitations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<PersonInvitationWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, PersonInvitationControllerService.PersonInvitationControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PersonInvitationWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<PersonInvitationWithRelations>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PersonInvitationWithRelations>>) => r.body as Array<PersonInvitationWithRelations>)
    );
  }

}
