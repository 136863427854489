import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  getBase64DataByFile(file: File): Promise<string> {
    const fileReader = new FileReader();
    return new Promise(resolve => {
      fileReader.onload = (event) => {
        let data = null;
        if (fileReader.result) {
          data = <string>fileReader.result;
        }
        resolve(data);
      };
      fileReader.readAsDataURL(file);
    });
  }

  downloadFile(data: Blob, fileName?: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const url = window.URL.createObjectURL(data);
    a.href = url;
    if (fileName) {
      a.download = fileName;
    }
    a.click();
    window.URL.revokeObjectURL(url);
  }

}
