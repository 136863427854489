import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddQueryParam, Navigate, RemoveQueryParam } from '../../states/router/router.actions';

@Injectable({
  providedIn: 'root'
})
export class RouterStateService {

  constructor(
    private store: Store<any>
  ) { }

  /**
   * navigates to specified url
   *
   * @param {string} url
   * @memberof RouterStateService
   */
  navigate(url: string) {
    this.store.dispatch(new Navigate({ url }));
  }

  /**
   * adds query param
   *
   * @param {string} url
   * @memberof RouterStateService
   */
  addQueryParam(key: string, value: string) {
    this.store.dispatch(new AddQueryParam({ key, value }));
  }


  /**
   * gets query param and remove it after getting if removeAfter is marked as true
   *
   * @param {string} queryParamKey query param name
   * @param {boolean} [removeAfter] the query param will be removed after getting
   * @return {*}  {Observable<string>}
   * @memberof RouterStateService
   */
  getQueryParamByKey(key: string, removeAfter?: boolean): Observable<string> {
    return this.store.select(state => state.router.queryParams).pipe(
      map(queryParams => {
        if (Object.entries(queryParams)?.length) {
          if (removeAfter) {
            this.store.dispatch(new RemoveQueryParam({ key }));
          }
        }
        return queryParams && queryParams[key];
      })
    );
  }

}
