import { HeadingComponentConfig, HeadingType, TextComponentConfig } from '@rappider/rappider-components/utils';
import { AlertTypes } from 'libs/components/src/lib/utils/alert';

export const projectPackageInfoAlertConfig = {
  type: AlertTypes.Info,
  title: <HeadingComponentConfig>{
    content: 'PROJECT_MODULE.PROJECT_PACKAGE_CREATE_COMPONENT.PLEASE_SELECT_A_PACKAGE',
    type: HeadingType.H6
  },
  description: <TextComponentConfig>{
    text: 'PROJECT_MODULE.PROJECT_PACKAGE_CREATE_COMPONENT.SELECT_PACKAGE_DESCRIPTION'
  },
  closeable: false,
  showIcon: true
};
