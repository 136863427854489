import { ButtonComponentConfig } from '@rappider/rappider-components/utils';
import { IconType } from '../../definitions/icon-type.enum';

export const AddPageButtonConfig: ButtonComponentConfig = {
  text: 'PAGES_MODULE.PAGE_LIST_COMPONENT.ADD_PAGE',
  icon: {
    name: 'plus',
    type: IconType.NgZorro
  }
};
