<div class="title-toolbar">
  <rappider-title-toolbar [mainTitle]="mainTitle"
                          [options]="title"
                          [displayToolbar]="displayToolbar"
                          [displayToolbarBackButton]="displayToolbarBackButton">
  </rappider-title-toolbar>
</div>

<section class="available-data-fields">
  <rappider-list-grid [config]="PROJECT_COMMENTS_CONFIG"
                      [data]="comments"
                      [loading]="isLoading"
                      [showEllipsisTextWithPopover]="true"
                      (columnActionClick)="onColumnActionClick($event)"></rappider-list-grid>
</section>