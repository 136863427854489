import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Route, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  RappiderTitleToolbarModule,
  RappiderButtonModule,
  RappiderCardCcsModule,
  RappiderTextModule,
  RappiderListGridModule,
  RappiderEditFormModule,
  RappiderCrudViewModule,
  RappiderMenuModule,
  RappiderInputGroupModule,
  RappiderAvatarListModule,
  RappiderSelectModule,
  RappiderTextboxModule,
  RappiderInlineRowFormModule,
  RappiderCodeEditorModule,
  RappiderModalModule,
  RappiderSpinModule,
  RappiderIconModule,
  RappiderLabelModule,
  RappiderCrudDetailModule,
  RappiderAlertModule,
  RappiderMonacoEditorModule,
  RappiderTextareaModule
} from '@rappider/rappider-components';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';

import { CustomFunctionCrudComponent } from './components/custom-function-crud/custom-function-crud.component';
import { CustomFunctionCreateComponent } from './components/custom-function-create/custom-function-create.component';
import { CustomFunctionEditComponent } from './components/custom-function-edit/custom-function-edit.component';
import { PAGE_DEFINITIONS } from '@rappider/shared/definitions';

/* guards */
import { AuthGuard } from '@rappider/authentication/guards';
import { ActiveProjectGuard, VerifyPersonGuard } from '@rappider/shared/guards';
import { CustomFunctionCrudModalWrapperComponent } from './components/custom-function-crud-modal-wrapper/custom-function-crud-modal-wrapper.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { CustomFunctionTabListWrapperComponent } from './components/custom-function-tab-list-wrapper/custom-function-tab-list-wrapper.component';
import { CustomFunctionCreateWrapperComponent } from './components/wrapper/custom-function-create-wrapper/custom-function-create-wrapper.component';
import { CustomFunctionEditWrapperComponent } from './components/wrapper/custom-function-edit-wrapper/custom-function-edit-wrapper.component';

const routes: Route[] = [
  {
    path: `${PAGE_DEFINITIONS.WORKFLOW_FUNCTION.URL}`,
    component: CustomFunctionCrudComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
  },
  {
    path: `${PAGE_DEFINITIONS.WORKFLOW_FUNCTION.URL}/:viewMode`,
    component: CustomFunctionCrudComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
  },
  {
    path: `${PAGE_DEFINITIONS.WORKFLOW_FUNCTION.URL}/:viewMode/:itemId`,
    component: CustomFunctionCrudComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    RappiderTextareaModule,
    RappiderTitleToolbarModule,
    RappiderButtonModule,
    RappiderCardCcsModule,
    TranslateModule.forChild(),
    RappiderTextModule,
    RappiderListGridModule,
    RappiderEditFormModule,
    RappiderCrudViewModule,
    NzModalModule,
    RappiderMenuModule,
    NzCollapseModule,
    RappiderInputGroupModule,
    RappiderAvatarListModule,
    FormsModule,
    RappiderCodeEditorModule,
    RappiderModalModule,
    RappiderSelectModule,
    NzFormModule,
    ReactiveFormsModule,
    RappiderLabelModule,
    RappiderTextboxModule,
    NzButtonModule,
    RappiderIconModule,
    RappiderInlineRowFormModule,
    RappiderSpinModule,
    RappiderCrudDetailModule,
    NzSelectModule,
    RappiderAlertModule,
    RappiderMonacoEditorModule,
    NzTabsModule
  ],
  declarations: [
    CustomFunctionCrudComponent,
    CustomFunctionCreateComponent,
    CustomFunctionEditComponent,
    CustomFunctionCrudModalWrapperComponent,
    CustomFunctionTabListWrapperComponent,
    CustomFunctionCreateWrapperComponent,
    CustomFunctionEditWrapperComponent
  ],
  exports: [
    CustomFunctionCrudComponent,
    CustomFunctionCreateComponent,
    CustomFunctionEditComponent,
    CustomFunctionCrudModalWrapperComponent,
    CustomFunctionCreateWrapperComponent,
    CustomFunctionEditWrapperComponent
  ]
})
export class CustomFunctionModule { }
