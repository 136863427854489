import { CrudTableViewConfig, CrudViewColumnType, ActionBehavior } from '@rappider/rappider-components/utils';
import { PATH_DEFINITIONS } from '../../definitions/path-definition';

export const tenantListGridConfig: CrudTableViewConfig = {
  defaultSearchField: 'title',
  columns: [
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewColumnType.Link,
      routerLink: `${PATH_DEFINITIONS.TENANTS.TENANT_DETAIL_PATH}/{{id}}`
    },
    {
      title: 'SHARED.CREATED_DATE',
      fieldName: 'createdDate',
      type: CrudViewColumnType.Date
    }
  ],
  listActions: [],
  itemActions: [
    {
      text: 'Go To Projects',
      icon: { name: 'fal fa-folder-open' },
      behavior: ActionBehavior.Route,
      redirectUrl: ''
    },
    {
      text: 'Go to Members',
      icon: { name: 'fal fa-users' },
      behavior: ActionBehavior.Route,
      redirectUrl: ''
    }
  ]
};
