
export const checkboxListDirectionOptions = [
  {
    key: 'Row',
    value: 'row'
  },
  {
    key: 'Column',
    value: 'column'
  }
];
