import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataSchemaCreateComponent } from './components/data-schema-create/data-schema-create.component';
import { RouterModule, Routes } from '@angular/router';
import { PAGE_DEFINITIONS } from '@rappider/shared/definitions';
import { ActiveProjectGuard, VerifyPersonGuard } from '@rappider/shared/guards';
import { AuthGuard } from '@rappider/authentication/guards';
import { DataSchemaListComponent } from './components/data-schema-list/data-schema-list.component';
import { DataSchemaEditComponent } from './components/data-schema-edit/data-schema-edit.component';
import { DataSchemaElementCreateComponent } from './components/data-schema-element-create/data-schema-element-create.component';
import { DataSchemaElementRowComponent } from './components/data-schema-element-row/data-schema-element-row.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  RappiderListGridModule,
  RappiderTitleModule,
  RappiderTextboxModule,
  RappiderCodeEditorModule,
  RappiderButtonModule,
  RappiderSelectModule,
  RappiderEditFormModule,
  RappiderIconModule,
  RappiderRowFormModule,
  RappiderNumberInputModule,
  RappiderCheckboxModule,
  RappiderTextareaModule,
  RappiderTitleToolbarModule,
  RappiderSpinModule,
  RappiderAlertModule,
  RappiderCheckboxListModule,
  RappiderLabelModule
} from '@rappider/rappider-components';

import { DataSchemaSelectboxComponent } from './components/data-schema-selectbox/data-schema-selectbox.component';
import { DataSchemaCreateEditFormComponent } from './components/data-schema-create-edit-form/data-schema-create-edit-form.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { RappiderModalModule } from '@rappider/rappider-components';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { DataSchemaDisplayComponent } from './components/data-schema-display/data-schema-display.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { GuidanceModule } from '@rappider/guidance';
import { NzIconModule } from 'ng-zorro-antd/icon';

const routes: Routes = [
  {
    path: PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_SCHEMA_CREATE.URL,
    component: DataSchemaCreateComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
  },
  {
    path: PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_SCHEMA_LIST.URL,
    component: DataSchemaListComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
    data: { title: 'Data Schemas' }
  },
  {
    path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_SCHEMA_EDIT.URL}/:id`,
    component: DataSchemaEditComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
  },
  {
    path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_SCHEMA_ELEMENT_CREATE.URL}/:parentDataSchemaId`,
    component: DataSchemaElementCreateComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
  }
];

@NgModule({
  declarations: [
    DataSchemaCreateComponent,
    DataSchemaListComponent,
    DataSchemaEditComponent,
    DataSchemaElementCreateComponent,
    DataSchemaElementRowComponent,
    DataSchemaSelectboxComponent,
    DataSchemaCreateEditFormComponent,
    DataSchemaDisplayComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RappiderModalModule,
    NzButtonModule,
    NzTableModule,
    NzPopoverModule,
    NzPopconfirmModule,
    NzInputModule,
    RappiderNumberInputModule,
    RappiderCheckboxModule,
    RouterModule.forChild(routes),
    FormsModule,
    TranslateModule,
    DragDropModule,
    RappiderListGridModule,
    RappiderTitleModule,
    RappiderTextboxModule,
    RappiderCodeEditorModule,
    RappiderButtonModule,
    RappiderSelectModule,
    RappiderEditFormModule,
    RappiderIconModule,
    RappiderRowFormModule,
    RappiderTextareaModule,
    NzToolTipModule,
    RappiderTitleToolbarModule,
    RappiderSpinModule,
    NzModalModule,
    RappiderAlertModule,
    NzStepsModule,
    GuidanceModule,
    RappiderSpinModule,
    RappiderCheckboxListModule,
    RappiderLabelModule,
    NzIconModule
  ],
  exports: [
    DataSchemaElementRowComponent,
    DataSchemaDisplayComponent
  ]
})
export class DataSchemaModule { }
