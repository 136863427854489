import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjectInterface } from '@rappider/api-sdk';
import { BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { DataSchemaWithRelations } from '@rappider/rappider-sdk';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { DataSchemaFieldRowPages } from '@rappider/shared/interfaces';
import { CreateUserGeneratedDataSchema } from 'libs/project/src/lib/states/data-schema/data-schema.actions';
import { Subscription } from 'rxjs';
import { DATA_SCHEMA_CREATE_OR_EDIT_CONFIG } from '../../../configs/data-schema-create-or-edit-config';
import { DataSchemaTypes } from '../../models/data-schema-type.enum';

@Component({
  selector: 'rappider-data-schema-create',
  templateUrl: './data-schema-create.component.html',
  styleUrls: ['./data-schema-create.component.scss']
})
export class DataSchemaCreateComponent implements OnInit, OnDestroy {

  /* form config */
  DATA_SCHEMA_CREATE_CONFIG = DATA_SCHEMA_CREATE_OR_EDIT_CONFIG;
  /* subscriptions */
  subscriptions: Subscription[];
  /* main title */
  mainTitle: HeadingComponentConfig;
  /* page title */
  title: string[] | BreadcrumbOption[];
  /* active project id */
  projectId: string;
  dataSchemaFieldPageType = DataSchemaFieldRowPages.DataStore;
  displayToolbar = false;
  displayToolbarBackButton = false;
  /* submit button loading state */
  submitButtonLoading = false;

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach((subscription) => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToLoading()
    ];
  }

  /**
   * subscribe to active project to set the title
   *
   * @returns
   * @memberof DataSchemaCreateComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectInterface) => {
      if (activeProject) {
        this.mainTitle = {
          content: 'PROJECT_MODULE.DATA_SCHEMA_CREATE_COMPONENT.ADD_DATA_SCHEMA',
          type: defaultToolbarTitleHeadingSize
        };
        this.title = [
          {
            label: activeProject.name,
            redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${activeProject?.id}`
          },
          {
            label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_SCHEMA_LIST.PAGE_TITLE,
            redirectUrl: PATH_DEFINITIONS.PROJECTS.DATA_SCHEMA_LIST
          },
          {
            label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_SCHEMA_CREATE.PAGE_TITLE
          }
        ];
        this.projectId = activeProject.id;
      }
    });
  }

  subscribeToLoading() {
    return this.store.select(state => state.dataSchema.loading).subscribe((loading: boolean) => {
      if (!loading) {
        this.submitButtonLoading = loading;
      }
    });
  }

  createDataSchema(dataSchema: DataSchemaWithRelations) {
    this.submitButtonLoading = true;
    const dataSchemaRequest = {
      ...dataSchema,
      projectId: this.projectId,
      isPrimitive: false,
      type: DataSchemaTypes.Model
    };
    this.store.dispatch(new CreateUserGeneratedDataSchema({
      dataSchema: dataSchemaRequest,
      navigateToDataSchemaList: true
    }));
  }
}
