<section *ngIf="isProjectModelsLoading">
  <nz-skeleton [nzActive]="true"
               [nzLoading]="isProjectModelsLoading"
               [nzTitle]="false"
               [nzParagraph]="{ rows: 2 }">
  </nz-skeleton>
</section>
<rappider-title-toolbar *ngIf="!isProjectModelsLoading"
                        [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>

<section>
  <rappider-spin [spinning]="isFormLoading">
    <rappider-crud-view-edit-form [config]="CUSTOM_ENDPOINT_CREATE_CONFIG"
                                  [data]="defaultCustomEndpoint"
                                  [submitButtonLoading]="isSubmitButtonLoading"
                                  (fieldValueChange)="onCreateCustomEndpointFormValueChange($event)"></rappider-crud-view-edit-form>

    <rappider-custom-function-create-wrapper [visibleFields]="visibleFields"
                                             [enableSubmitButton]="isEnableSubmitButton"
                                             [customFunction]="customFunction"
                                             [submitButtonLoading]="isSubmitButtonLoading"
                                             (createFormSubmit)="onCreateFormSubmit($event)"></rappider-custom-function-create-wrapper>
  </rappider-spin>
</section>