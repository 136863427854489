export function setDateArrayConfig(fieldname: string) {
  return {
    columns: [
      {
        typeAndFormat: {
          type: 'date'
        },
        fieldName: fieldname
      }
    ]
  };
}
