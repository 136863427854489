import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActionResponse, CrudTableViewConfig } from '@rappider/rappider-components/utils';
import { UiDataUpdateFunction } from 'libs/rappider-sdk/src/lib/models';
import { CustomUIDataUpdateFunction } from './utils/custom-ui-data-update-function.interface';

@Component({
  selector: 'rappider-ui-data-update-function-page',
  templateUrl: './ui-data-update-function-page.component.html',
  styleUrls: ['./ui-data-update-function-page.component.scss']
})
export class UiDataUpdateFunctionPageComponent {
  @Input() config: CrudTableViewConfig;
  @Input() uiDataUpdateFunctions: CustomUIDataUpdateFunction[];
  @Input() loading: boolean;

  @Output() uiDataUpdateFunctionCreate = new EventEmitter();
  @Output() uiDataUpdateFunctionDelete = new EventEmitter<UiDataUpdateFunction>();

  onAddUIDataUpdateFunctionClick() {
    this.uiDataUpdateFunctionCreate.emit();
  }

  onDeleteUIDataUpdateFunctionClick(value: ActionResponse) {
    const uiDataUpdateFunction: UiDataUpdateFunction = value.data;
    if (value.action.name === 'DELETE_ITEM') {
      this.uiDataUpdateFunctionDelete.emit(uiDataUpdateFunction);
    }
  }
}
