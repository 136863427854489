import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { ProjectModel, ProjectWithRelations } from '@rappider/rappider-sdk';
import { PROJECT_MODEL_CREATE_OR_EDIT_CONFIG } from '@rappider/shared/configs';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { UpdateProjectModel } from '../../states/project-model-state/project-model.actions';
import { ProjectModelState } from '../../states/project-model-state/project-model.reducer';
import { Project } from '@rappider/api-sdk';
import { StringUtilityService } from '@rappider/services';
import { plural } from 'pluralize';

@Component({
  selector: 'rappider-project-model-edit',
  templateUrl: './project-model-edit.component.html',
  styleUrls: ['./project-model-edit.component.scss']
})
export class ProjectModelEditComponent implements OnInit, OnDestroy {

  /* edit form config  */
  PROJECT_MODEL_EDIT_CONFIG = cloneDeep(PROJECT_MODEL_CREATE_OR_EDIT_CONFIG);
  /* subscription */
  subscriptions: Subscription[];
  /* main title */
  mainTitle: HeadingComponentConfig;
  /* page title */
  title: BreadcrumbOption[] | string | string[];
  /* project model data */
  projectModel: ProjectModel;
  /* project model id */
  projectModelId: string;
  /* active project */
  activeProject: Project;

  /* loading state of project model */
  isLoading: boolean;
  /* submit button loading state */
  submitButtonLoading = false;
  displayToolbar = false;
  displayToolbarBackButton = false;
  isLoaded: boolean;

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
    this.getProjectModelIdFromUrl();
    this.getProjectModel(this.projectModelId);
    this.removeGenerateUiDataStoreCheckbox();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToProjectModelLoading(),
      this.subscribeToProjectModelLoaded()
    ];
  }

  getProjectModelIdFromUrl() {
    this.projectModelId = this.activatedRoute.snapshot.params['id'];
  }
  /**
   * subscribe to active project to set the title
   *
   * @returns
   * @memberof ProjectModelListComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject) => {
      if (activeProject) {
        this.activeProject = activeProject;
      }
    });
  }

  subscribeToProjectModelLoading() {
    return this.store.select(state => state.projectModel?.loading).subscribe(loadingState => {
      this.isLoading = loadingState;
      if (!loadingState) {
        this.submitButtonLoading = loadingState;
      }
    });
  }

  subscribeToProjectModelLoaded() {
    return this.store.select(state => state.projectModel?.isLoaded).subscribe(isLoaded => {
      this.isLoaded = isLoaded;
    });
  }

  /**
   * removes generate ui data store checkbox from edit config
   *
   * @memberof ProjectModelEditComponent
   */
  removeGenerateUiDataStoreCheckbox() {
    const projectModelEditConfigItems = this.PROJECT_MODEL_EDIT_CONFIG.items.filter(formItem => formItem.fieldName !== 'generateUIDataStore');
    this.PROJECT_MODEL_EDIT_CONFIG.items = projectModelEditConfigItems;
  }

  /**
   * get project model by project model id
   *
   * @param {string} projectModelId
   * @memberof ProjectModelEditComponent
   */
  getProjectModel(projectModelId: string) {
    this.store.select(state => state.projectModel.data).subscribe((projectModels: ProjectModel[]) => {
      this.projectModel = projectModels?.find(projectModel => projectModel.id === projectModelId);
      if (this.projectModel) {
        this.setTitle();
      }
    });
  }

  setTitle() {
    this.mainTitle = {
      content: 'PROJECT_MODULE.PROJECT_MODEL_EDIT_COMPONENT.EDIT_PROJECT_MODEL',
      type: defaultToolbarTitleHeadingSize
    };
    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
      },
      {
        label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_MODEL_LIST.PAGE_TITLE,
        redirectUrl: PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_LIST
      },
      {
        label: this.projectModel?.name
      },
      {
        label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_MODEL_EDIT.PAGE_TITLE
      }
    ];
  }

  /**
   * project model form submit function
   *
   * @param {ProjectModel} projectModel
   * @memberof ProjectModelEditComponent
   */
  onEditProjectModelFormSubmit(projectModel: ProjectModel) {
    this.submitButtonLoading = true;
    this.store.dispatch(new UpdateProjectModel({ id: this.projectModelId, projectModel, navigateAfterUpdate: true }));
  }

  onProjectModelFieldValueChange(projectModel: ProjectModel) {
    this.projectModel = {
      ...this.projectModel,
      ...projectModel
    };

    if (projectModel.name || projectModel.name === '') {
      this.projectModel = {
        ...this.projectModel,
        name: StringUtilityService.toTitleCase(projectModel.name).replace(/\s/g, '') || '',
        basePath: StringUtilityService.toKebabCase(plural(projectModel.name)) || '',
        dataTableName: StringUtilityService.toPascalCase(projectModel.name) || '',
      };
    }
  }

}
