<div class="custom-function">
  <div class="border">
    <div class="predefined-text">
      function &nbsp;
      ({{getSourceFieldName()}})
      <span [innerHTML]="'{'"></span>
    </div>
    <div class="codemirror border">
      <rappider-code-editor [(ngModel)]="value"
                            [settings]="codemirrorJsonSettings">
      </rappider-code-editor>
    </div>
    <div class="predefined-text">
      <span [innerHTML]="'}'"></span>
    </div>
  </div>
</div>