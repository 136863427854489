export const US_PHONE_MASK = '(000) 000-0000';

export const US_PHONE_REGEX = /^\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})$/;

export const EMAIL_REGEX =
  /[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*/;

export const PASSWORD_REGEX = /^(?=\S*[A-Z])(?=\S*\d)(?=\S*([^\w\s]|[_]))\S{8,}$/;

export const SPECIAL_CHARACTER_REGEX = /(?=\S*([^\w\s]|[_]))/;

export const EMAIL_AND_US_PHONE_REGEX = /^\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})$|[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*/;

export const MENTION_ALLOWED_CHARS = /^[A-Za-z\sÅÄÖåäö]*$/;

export const VERSION_MASK = [/[0-9]/, '.', /[0-9]/, '.', /[0-9]/];

export const PAGE_PATH_REGEX = /^[a-z][a-z0-9\-/:]*$/;

export const PROJECT_SETTING_AND_ENVIRONMENT_VARIABLE_KEY_REGEX = /^[a-zA-Z\_][a-zA-Z0-9\_]+$/;

export const PROJECT_KEY_REGEX = /^[a-z][a-z0-9\-]*$/;

export const PROJECT_NAME_REGEX = /^[a-zA-Z][a-zA-Z0-9\W]*$/;

export const PHONE_NUMBER_CLEANSE_REGEX = /[\-\(\)\s]/g;

export const DATA_FIELD_NAME_REGEX = /^[a-z][0-9a-zA-Z_]*$/;

export const TITLE_CASE_DATA_FIELD_NAME_REGEX = /^[A-Z][0-9a-zA-Z_]*$/;

/* allows lower case and underscore for first letter, rest is camel case or snake case.
Used in project settings config for key validation */
export const CAMEL_CASE_OR_SNAKE_CASE_REGEX = /^[a-z_][0-9a-zA-Z_]*$/;

export const KEBAB_CASE_REGEX = /^[a-z][a-z0-9-]*$/;

export const CAMEL_CASE_REGEX = /^[a-z][a-zA-Z0-9]*$/;

export const PASCAL_CASE_ONLY_LETTER_REGEX = /^[A-Z][a-zA-Z]*$/;

export const PASCAL_CASE_REGEX = /^[A-Z][a-zA-Z0-9]*$/;

export const TITLE_CASE_REGEX = /^[A-Z][0-9a-zA-Z_\s]*$/;

export const NO_SPACE_REGEX = /^\S*$/;

export const ROLE_AND_PERMISSION_KEY_REGEX = /^[a-z][a-z:\-0-9]*$/;
