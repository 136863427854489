import { AvatarComponentConfig, AvatarShape } from '../avatar';
import { ButtonType } from '../button';
import { IconType } from '../icon';
import { InputGroupComponentConfig } from '../input-group';

export const defaultInputGroupConfig: InputGroupComponentConfig = {
  textbox: {
    placeholder: 'Send a message'
  },
  suffixButton: {
    type: ButtonType.Default,
    icon: {
      name: 'fa-solid fa-paper-plane-top',
      type: IconType.FontAwesome
    }
  }
};

export const defaultSenderAvatarConfig: AvatarComponentConfig = {
  icon: {
    name: 'fa-regular fa-user',
    type: IconType.FontAwesome
  },
  shape: AvatarShape.Square
};

export const defaultReceiverAvatarConfig: AvatarComponentConfig = {
  icon: {
    name: 'fa-solid fa-user',
    type: IconType.FontAwesome
  },
  shape: AvatarShape.Square
};
