import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DocumentListService {

  compareBytes(bytes1, bytes2) {
    if (bytes1.length !== bytes2.length) {
      return false;
    }

    for (let i = 0; i < bytes1.length; i++) {
      if (bytes1[i] !== bytes2[i]) {
        return false;
      }
    }

    return true;
  }

  convertArrayBufferToBinary(arrayBuffer) {
    const chunkSize = 1024; // Adjust this value as needed
    const uint8Array = new Uint8Array(arrayBuffer);
    let binary = '';

    for (let i = 0; i < uint8Array.length; i += chunkSize) {
      const chunk = uint8Array.subarray(i, i + chunkSize);
      binary += String.fromCharCode.apply(null, chunk);
    }

    return binary;
  }

  arrayBufferToBase64(arrayBuffer: ArrayBuffer) {
    const binary = this.convertArrayBufferToBinary(arrayBuffer);
    const uint8Array = new Uint8Array(arrayBuffer);
    const headerBytes = uint8Array.subarray(0, 4);

    // PDF: 25 50 44 46
    if (this.compareBytes(headerBytes, [0x25, 0x50, 0x44, 0x46])) {
      const base64String = btoa(binary);
      return 'data:application/pdf;base64,' + base64String;
    } else {
      const base64String = btoa(binary);
      return 'data:image/png;base64,' + base64String;
    }
  }

  displayDocumentFromArrayBuffer(arrayBuffer: ArrayBuffer) {
    return this.arrayBufferToBase64(arrayBuffer);
  }

  blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject(new Error('Failed to convert Blob to Base64.'));
        }
      };

      reader.onerror = () => {
        reject(new Error('Error reading Blob.'));
      };

      reader.readAsDataURL(blob);
    });
  }
}
