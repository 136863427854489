import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NewUiDataUpdateFunction, UiDataEventWithRelations, UiDataUpdateFunction } from '@rappider/rappider-sdk';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { uiDataUpdateFunctionListDataSelector } from 'libs/project/src/lib/states/selectors/ui-data-update-function-list-data.selector';
import { DeleteUIDataUpdateFunction } from 'libs/project/src/lib/states/ui-data-update-functions/ui-data-update-function.actions';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { Subscription } from 'rxjs';
import { UI_DATA_UPDATE_FUNCTION_LIST_CONFIG } from './config/ui-data-update-function-list-config';

@Component({
  selector: 'rappider-ui-data-update-function-list',
  templateUrl: './ui-data-update-function-list.component.html',
  styleUrls: ['./ui-data-update-function-list.component.scss']
})
export class UiDataUpdateFunctionListComponent implements OnInit, OnDestroy {
  @Input() listConfig = UI_DATA_UPDATE_FUNCTION_LIST_CONFIG;

  @Input() uiDataStoreId: string;

  updateFunctions: { dataEventName: string; id: string; generatedBy?: string }[];
  uiDataUpdateFunctions: NewUiDataUpdateFunction[];
  subscriptions: Subscription[];
  loading: boolean;

  constructor(
    private store: Store<any>,
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToDataEvents(),
      this.subscribeToUIDataUpdateFunctions(),
      this.subscribeToUIDataUpdateFunctionsLoadingState()
    ];
  }

  subscribeToDataEvents() {
    return this.store.select(state => state.uiDataEvent?.data).subscribe((uiDataEvents: UiDataEventWithRelations[]) => {
      this.updateFunctions = uiDataEvents?.filter(uiDataEvent => (uiDataEvent.uiDataStoreId === this.uiDataStoreId && !!uiDataEvent.uiDataUpdateFunction))
        .map(dataEvent => ({
          id: dataEvent?.uiDataUpdateFunction?.id,
          dataEventName: dataEvent?.name,
          generatedBy: dataEvent?.uiDataUpdateFunction?.generatedBy,
        }));
    });

  }
  subscribeToUIDataUpdateFunctions() {
    return this.store.select(<any>uiDataUpdateFunctionListDataSelector).subscribe(uiDataUpdateFunctions => {
      this.uiDataUpdateFunctions = uiDataUpdateFunctions;
      this.uiDataUpdateFunctions = this.uiDataUpdateFunctions.filter(uiDataUpdateFunction => uiDataUpdateFunction.uiDataStoreId === this.uiDataStoreId);
    });
  }

  subscribeToUIDataUpdateFunctionsLoadingState() {
    return this.store.select(state => state.uiDataUpdateFunction.loading).subscribe((loading: boolean) => {
      this.loading = loading;
    });
  }

  onCreateUpdateFunction() {
    this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.UI_DATA_UPDATE_FUNCTION_CREATE}/${this.uiDataStoreId}` }));
  }

  deleteUIDataUpdateFunction(updateFunction: UiDataUpdateFunction) {
    const uiDataUpdateFunction = updateFunction;
    this.store.dispatch(new DeleteUIDataUpdateFunction({ dataEventName: uiDataUpdateFunction?.dataEventName, id: uiDataUpdateFunction.id }));
  }
}

