import { Validators } from '@angular/forms';
import { CrudFormConfig, CrudFormSelectItem, CrudViewFormItemType } from '@rappider/rappider-components/utils';

export const PROJECT_PACKAGE_CREATE_OR_EDIT_CONFIG: CrudFormConfig = {
  items: [
    {
      title: 'PROJECT_MODULE.PROJECT_PACKAGE_LIST_COMPONENT.PROJECT_PACKAGE_NAME',
      fieldName: 'name',
      type: CrudViewFormItemType.TextBox,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    <CrudFormSelectItem>{
      title: 'PROJECT_MODULE.PROJECT_PACKAGE_LIST_COMPONENT.PROJECT_PACKAGE_VERSION',
      fieldName: 'version',
      type: CrudViewFormItemType.Select,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ],
      options: null
    }
  ]
};
