import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageTemplateInterface, MessageTemplateApi, MessageTemplateDataFieldInterface } from '@rappider/api-sdk';
import { UpdateMessageTemplate } from '../../states/message-template-state/message-template.actions';
import { AVAILABLE_DATA_FIELD_LIST_CONFIG } from '@rappider/shared/configs';
import { BreadcrumbOption, CrudFormConfig, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { NotificationService, MentionTextConverterService, MessageTemplateService } from '@rappider/services';
import { Subscription } from 'rxjs';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Project } from '@rappider/rappider-sdk';

@Component({
  selector: 'rappider-edit-message-template',
  templateUrl: './edit-message-template.component.html',
  styleUrls: ['./edit-message-template.component.scss']
})
export class EditMessageTemplateComponent implements OnInit, OnDestroy {

  mainTitle: HeadingComponentConfig;
  title: string | string[] | BreadcrumbOption[];
  formValue: any;
  AVAILABLE_DATA_FIELD_LIST_CONFIG = AVAILABLE_DATA_FIELD_LIST_CONFIG;
  messageTemplateId: string;
  messageTemplate: MessageTemplateInterface;
  mentionAvailableDataFieldNames: string[];
  availableDataFields: MessageTemplateDataFieldInterface[];
  MESSAGE_TEMPLATE_EDIT_CONFIG: CrudFormConfig = <CrudFormConfig>{};
  environments: string[];
  subscriptions: Subscription[] = [];
  displayToolbar = false;
  displayToolbarBackButton = false;
  submitButtonLoading = false;
  activeProject: Project;

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private messageTemplateApi: MessageTemplateApi,
    private notificationService: NotificationService,
    private mentionTextConverterService: MentionTextConverterService,
    private messageTemplateService: MessageTemplateService
  ) { }

  ngOnInit() {
    this.getMessageTemplateId();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(subscription => subscription.unsubscribe());
  }


  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToLoading(),
      this.subscribeToEnvironments(),
      this.subscribeToMessageTemplates()
    ];
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: Project) => {
      if (activeProject) {
        this.activeProject = activeProject;
      }
    });
  }

  subscribeToEnvironments() {
    return this.store.select(state => state.environmentVariable.environments).subscribe(environments => {
      this.environments = environments;
    });
  }

  subscribeToLoading() {
    return this.store.select(state => state.messageTemplate.loading).subscribe(loading => {
      this.submitButtonLoading = loading;
    });
  }

  /**
   * if url has a template id, this component update component
   * else this component create component
   * @memberof MessageTemplateComponent
   */
  getMessageTemplateId() {
    this.messageTemplateId = this.activatedRoute.snapshot.params.templateId;
  }

  subscribeToMessageTemplates() {
    return this.store.select(state => state.messageTemplate?.data).subscribe(messageTemplates => {
      if (messageTemplates && this.messageTemplateId) {
        this.messageTemplate = messageTemplates.find(messageTemplate => messageTemplate.id === this.messageTemplateId);
        this.setTitle();
        this.availableDataFields = this.messageTemplate.dataFields;
        if (this.availableDataFields) {
          this.mentionAvailableDataFieldNames = this.messageTemplate.dataFields.map((data) => data.fieldName);
          const template = this.mentionTextConverterService.convertMessageTemplateToMention(
            this.messageTemplate?.template, this.mentionAvailableDataFieldNames);
          this.messageTemplate.template = template;
          this.setFormConfig(this.mentionAvailableDataFieldNames);
        } else {
          this.setFormConfig();
        }
      }
    });
  }

  setTitle() {
    this.mainTitle = {
      content: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_EDIT_MESSAGE_TEMPLATE.PAGE_TITLE,
      type: defaultToolbarTitleHeadingSize
    };
    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
      },
      {
        label: 'SHARED.TEMPLATES',
        redirectUrl: PATH_DEFINITIONS.PROJECTS.PROJECT_MESSAGE_TEMPLATE_LIST
      },
      {
        label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_EDIT_MESSAGE_TEMPLATE.PAGE_TITLE
      },
      {
        label: this.messageTemplate.key
      }
    ];
  }

  /**
   * Set form config
   *
   * @memberof MessageTemplateComponent
   */
  setFormConfig(mentionAvailableDataFieldNames?: any) {
    if (this.environments) {
      if (mentionAvailableDataFieldNames) {
        this.MESSAGE_TEMPLATE_EDIT_CONFIG = this.messageTemplateService.
          setMessageTemplateFormConfig(this.environments, mentionAvailableDataFieldNames);
      } else {
        this.MESSAGE_TEMPLATE_EDIT_CONFIG = this.messageTemplateService.
          setMessageTemplateFormConfig(this.environments);
      }
    }
  }


  /**
   * Create or update message template
   *
   * @param {*} data
   * @memberof MessageTemplateComponent
   */
  editMessageTemplate(formData: any) {
    const updatedTemplate = {
      id: this.messageTemplate.id,
      ...formData,
      template: this.mentionTextConverterService.convertMentionToTemplate(formData.template)
    };
    this.store.dispatch(new UpdateMessageTemplate({ messageTemplate: updatedTemplate, messageTemplateId: this.messageTemplate.id }));
  }

  onSubmitButtonClick(formData: any) {
    if (
      !formData.environmentKey
      || !formData.key
      || !formData.subject
      || !formData.template
      || !formData.type
    ) {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'SHARED.ALL_FIELDS_REQUIRED'
      );
    }
  }

}
