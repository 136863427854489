export enum UIDataUpdateFunctionGeneratedBy {
  User = 'user',
  Rappider = 'rappider'
}

export const uiDataUpdateFunctionGeneratedByOptions = [
  {
    key: 'User',
    value: UIDataUpdateFunctionGeneratedBy.User
  },
  {
    key: 'Rappider',
    value: UIDataUpdateFunctionGeneratedBy.Rappider
  }
];
