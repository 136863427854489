/* UUID 9 is set for the parent folderid for agile project management assets  */
/* All assets (e.g. images) that are uploaded in APG pages (e.g. issue creation) will be saved to this folder by projectId */
export const parentFolderIdForAgileProjectManagementAssets = '00000000-0000-0000-0000-000000000009';


export const priorityMap = [
    {
        key: 'Highest',
        value: 10
    },
    {
        key: 'High',
        value: 20
    },
    {
        key: 'Medium',
        value: 30
    },
    {
        key: 'Low',
        value: 40
    },
    {
        key: 'Lowest',
        value: 50
    }
];
