import { createSelector } from '@ngrx/store';

export const getProjectModelsWithFieldsLoading = createSelector(
  state => state['projectModelField']?.isLoaded,
  state => state['projectModel']?.isLoaded,
  state => state['projectModel']?.loading,
  (isProjectModelFieldsIsLoaded: boolean,
    isProjectModelsIsLoaded: boolean,
    isProjectModelLoading: boolean) => !(isProjectModelFieldsIsLoaded && isProjectModelsIsLoaded && !isProjectModelLoading)
);
