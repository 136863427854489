export const getPageByDetailsFilter = {
  include: [
    {
      relation: 'components',
      scope: {
        order: ['index ASC'],
        include: [
          {
            relation: 'condition'
          },
          {
            relation: 'componentDefinition',
          },
          {
            relation: 'outputEvents',
            scope: {
              include: [
                {
                  relation: 'outputDefinition',
                },
                {
                  relation: 'event',
                },
              ],
            },
          },
          {
            relation: 'dataSubscriptions',
            scope: {
              include: [
                {
                  relation: 'uiDataStore',
                },
                {
                  relation: 'inputDefinition',
                  scope: {
                    include: [
                      {
                        relation: 'type',
                      },
                    ],
                  },
                },
                {
                  relation: 'dataMapping',
                  scope: {
                    include: [
                      {
                        relation: 'targetTreeField',
                        scope: {
                          include: [
                            {
                              relation: 'nodes',
                              scope: {
                                order: ['index ASC'],
                                include: [
                                  {
                                    relation: 'field',
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        relation: 'sourceTreeFields',
                        scope: {
                          include: [
                            {
                              relation: 'nodes',
                              scope: {
                                order: ['index ASC'],
                                include: [
                                  {
                                    relation: 'field',
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            relation: 'childComponents',
            scope: {
              order: ['index ASC'],
            },
          },
          {
            relation: 'componentSlots',
            scope: {
              order: ['slotIndex ASC'],
              include: [
                {
                  relation: 'slotDefinition',
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
