import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { DataSchemaWithRelations, Project } from '@rappider/rappider-sdk';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { UpdateDataSchema } from 'libs/project/src/lib/states/data-schema/data-schema.actions';
import { Subscription } from 'rxjs';
import { DATA_SCHEMA_CREATE_OR_EDIT_CONFIG } from '../../../configs/data-schema-create-or-edit-config';
import { selectDataSchemaById } from './selectors/selected-data-schema.selector';

@Component({
  selector: 'rappider-data-schema-edit',
  templateUrl: './data-schema-edit.component.html',
  styleUrls: ['./data-schema-edit.component.scss']
})
export class DataSchemaEditComponent implements OnInit, OnDestroy {

  /* form config */
  DATA_SCHEMA_EDIT_CONFIG = DATA_SCHEMA_CREATE_OR_EDIT_CONFIG;
  /* subscriptions */
  subscriptions: Subscription[];
  /* main title */
  mainTitle: HeadingComponentConfig;
  /* page title */
  title: BreadcrumbOption[] | string[];
  /* active project id */
  projectId: string;
  /* data schema id */
  dataSchemaId: string;
  /* data schema to update */
  dataSchema: DataSchemaWithRelations;
  /* active project name */
  projectName: string;
  displayToolbar = false;
  displayToolbarBackButton = false;
  /* submit button loading state */
  submitButtonLoading = false;

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getDataSchemaIdFromUrl();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach((subscription) => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToLoading(),
      this.subscribeToDataSchemas()
    ];
  }

  /**
   * subscribe to active project to set the title
   *
   * @returns
   * @memberof DataSchemaCreateComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: Project) => {
      if (activeProject) {
        this.projectId = activeProject.id;
        this.projectName = activeProject.name;
      }
    });
  }

  subscribeToDataSchemas() {
    return this.store.select(<any>selectDataSchemaById(this.dataSchemaId)).subscribe((dataSchema: DataSchemaWithRelations) => {
      if (dataSchema) {
        this.dataSchema = dataSchema;
        this.setTitle();
      }
    });
  }

  subscribeToLoading() {
    return this.store.select(state => state.dataSchema.loading).subscribe((loading: boolean) => {
      if (!loading) {
        this.submitButtonLoading = loading;
      }
    });
  }

  getDataSchemaIdFromUrl() {
    this.dataSchemaId = this.activatedRoute.snapshot.params['id'];
  }

  setTitle() {
    this.mainTitle = {
      content: 'PROJECT_MODULE.DATA_SCHEMA_EDIT_COMPONENT.EDIT_DATA_SCHEMA',
      type: defaultToolbarTitleHeadingSize
    };
    this.title = [
      {
        label: this.projectName,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.projectId}`
      },
      {
        label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_SCHEMA_LIST.PAGE_TITLE,
        redirectUrl: PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_SCHEMA_LIST.URL
      },
      {
        label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_SCHEMA_EDIT.PAGE_TITLE,
      },
      {
        label: this.dataSchema.name
      }
    ];
  }

  updateDataSchema(dataSchema: DataSchemaWithRelations) {
    this.submitButtonLoading = true;
    this.store.dispatch(new UpdateDataSchema({ id: this.dataSchemaId, dataSchema: dataSchema, navigateToDataSchemaList: true }));
  }

}
