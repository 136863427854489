import { CrudTableViewConfig, CrudViewColumnType, InputSize } from '@rappider/rappider-components/utils';
import { PATH_DEFINITIONS } from '../../definitions/path-definition';

export const COMPONENT_DEFINITIONS_GROUP_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'title',
  columns: [
    {
      title: 'Title',
      fieldName: 'title',
      type: CrudViewColumnType.Link,
      routerLink: `${PATH_DEFINITIONS.COMPONENT_BROWSER.COMPONENT_DEFINITION_DETAIL_PATH}/{{id}}`,
    },
    {
      title: 'Description',
      fieldName: 'description',
      type: CrudViewColumnType.Text
    }
  ],
  searchable: false,
  columnVisibilitySelectable: false,
  size: InputSize.Small
};
