import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjectModel, ProjectModelPartial } from '@rappider/rappider-sdk';
import { ProjectInterface } from '@rappider/api-sdk';
import { PROJECT_MODEL_CREATE_OR_EDIT_CONFIG } from '@rappider/shared/configs';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { CreateProjectModel } from '../../states/project-model-state/project-model.actions';
import { StringUtilityService } from '@rappider/services';
import { plural } from 'pluralize';
import { BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';

@Component({
  selector: 'rappider-project-model-create',
  templateUrl: './project-model-create.component.html',
  styleUrls: ['./project-model-create.component.scss']
})
export class ProjectModelCreateComponent implements OnInit, OnDestroy {

  /* edit form config */
  PROJECT_MODEL_CREATE_CONFIG = cloneDeep(PROJECT_MODEL_CREATE_OR_EDIT_CONFIG);
  /* subscription */
  subscriptions: Subscription[];
  /* active project id */
  projectId: string;
  /* main title */
  mainTitle: HeadingComponentConfig;
  /* page title */
  title: string | string[] | BreadcrumbOption[];
  /* default data */
  projectModelDefaultData: ProjectModelPartial = {
    basePath: null,
    dataTableName: null,
    generateUIDataStore: true,
    isStrict: false
  };
  /* loading state of the project model */
  isLoading: boolean;
  displayToolbar = false;
  displayToolbarBackButton = false;
  /* submit button loading state */
  submitButtonLoading = false;
  isLoaded: boolean;

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToProjectModelLoading(),
      this.subscribeToProjectModelLoaded()
    ];
  }
  /**
   * subscribe to active project to set the title
   *
   * @returns
   * @memberof ProjectModelListComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectInterface) => {
      if (activeProject) {
        this.mainTitle = {
          content: 'PROJECT_MODULE.PROJECT_MODEL_CREATE_COMPONENT.ADD_PROJECT_MODEL',
          type: defaultToolbarTitleHeadingSize
        };
        this.title = [
          {
            label: activeProject.name,
            redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${activeProject?.id}`
          },
          {
            label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_MODEL_LIST.PAGE_TITLE,
            redirectUrl: PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_LIST
          },
          {
            label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_MODEL_CREATE.PAGE_TITLE
          }
        ];
        this.projectId = activeProject.id;
      }
    });
  }

  subscribeToProjectModelLoading() {
    return this.store.select(state => state.projectModel?.loading).subscribe(loadingState => {
      this.isLoading = loadingState;
      if (!loadingState) {
        this.submitButtonLoading = loadingState;
      }
    });
  }

  subscribeToProjectModelLoaded() {
    return this.store.select(state => state.projectModel?.isLoaded).subscribe(isLoaded => {
      this.isLoaded = isLoaded;
    });
  }

  onProjectModelFieldValueChange(projectModel: ProjectModel) {
    this.projectModelDefaultData = {
      ...this.projectModelDefaultData,
      ...projectModel
    };

    if (projectModel.name) {
      this.projectModelDefaultData = {
        ...this.projectModelDefaultData,
        name: StringUtilityService.toTitleCase(projectModel.name).replace(/\s/g, ''),
        basePath: StringUtilityService.toKebabCase(plural(projectModel.name)),
        dataTableName: StringUtilityService.toPascalCase(projectModel.name),
      };
    } else if (projectModel.name === '') {
      this.projectModelDefaultData = {
        ...this.projectModelDefaultData,
        basePath: '',
        dataTableName: '',
      };
    }
  }

  /**
   * project model create form submit function
   *
   * @param {ProjectModel} projectModel
   * @memberof ProjectModelCreateComponent
   */
  onFormSubmit(projectModel: ProjectModel) {
    /* if scope is null assign an empty array */
    projectModel.scope = projectModel.scope?.filter(item => item) || [];
    this.submitButtonLoading = true;
    this.store.dispatch(new CreateProjectModel({ projectModel, navigateAfterCreate: true }));
  }

}
