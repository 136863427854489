import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';


import { NzTabsModule } from 'ng-zorro-antd/tabs';

import { MONACO_EDITOR_CONFIG, MonacoEditorConfig } from './monaco-editor.config';
import { MonacoDiffEditorComponent } from './components/monaco-diff-editor/monaco-diff-editor.component';
import { MonacoEditorComponent } from './components/monaco-editor/monaco-editor.component';
import { RappiderRadioGroupModule } from '../radio-group/radio-group.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RappiderSpinModule } from '../spin/spin.module';
import { RappiderButtonModule } from '../button/button.module';
import { CodeEditorComponent } from './code-editor.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RappiderRadioGroupModule,
    TranslateModule,
    RappiderSpinModule,
    RappiderButtonModule
  ],
  declarations: [
    // MonacoDiffEditorComponent,
    MonacoEditorComponent,
    CodeEditorComponent
  ],
  exports: [
    MonacoEditorComponent,
    CodeEditorComponent
    // MonacoDiffEditorComponent
  ]
})
export class RappiderMonacoEditorModule {
  public static forRoot(config: MonacoEditorConfig = {}): ModuleWithProviders<RappiderMonacoEditorModule> {
    return {
      ngModule: RappiderMonacoEditorModule,
      providers: [
        { provide: MONACO_EDITOR_CONFIG, useValue: config }
      ]
    };
  }
}
