import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ENVIRONMENT_VARIABLES } from '../../utils/environment-variables.config';
import { EnvironmentVariable } from '@rappider/rappider-sdk';
import { CUSTOM_FUNCTION_CREATE_OR_EDIT_ENVIRONMENT_VARIABLE_FORM_CONFIG, CUSTOM_FUNCTION_ENVIRONMENT_VARIABLES_CONFIG } from './utils/custom-function-environment-variables-config';
import { CrudFormInputTemplateItem, PropertyType } from '@rappider/rappider-components/utils';
import { ModalTypes } from './utils/custom-function-modal-type.enum';
import { assignTypeAccordingToValue } from '../../utils/assign-type-according-to-value.function';
import { ACTIVE_ENVIRONMENT_VARIABLE } from '../../utils/active-environment-variable.config';
import { ActionMode } from './utils/action-mode.enum';

@Component({
  selector: 'rappider-custom-function-tab-list-wrapper',
  templateUrl: './custom-function-tab-list-wrapper.component.html',
  styleUrls: ['./custom-function-tab-list-wrapper.component.scss']
})
export class CustomFunctionTabListWrapperComponent {

  @Input() displayedEnvironmentVariables: EnvironmentVariable[];

  @Output() createFormSubmit = new EventEmitter();
  @Output() editFormSubmit = new EventEmitter();
  @Output() deleteFormItem = new EventEmitter();
  @Output() activeEnvironmentVariableTab = new EventEmitter();

  environments = ENVIRONMENT_VARIABLES;
  environmentVariablesConfig = CUSTOM_FUNCTION_ENVIRONMENT_VARIABLES_CONFIG;
  loading: boolean;
  environmentVariableModal = {
    title: 'Environment Variable',
    visible: false,
    isSubmitted: false,
    isValid: false,
    data: {
      key: null,
      type: null,
      value: null,
    },
    environment: null,
    editingEnvironmentVariableId: null
  };
  createOrEditEnvironmentVariableFormConfig = CUSTOM_FUNCTION_CREATE_OR_EDIT_ENVIRONMENT_VARIABLE_FORM_CONFIG;
  activeEnvironmentVariable = ACTIVE_ENVIRONMENT_VARIABLE;
  activeFormType = PropertyType.String;
  modalType: ModalTypes;
  editableModalData: EnvironmentVariable;

  constructor() { }

  setActiveEnvironment(activeEnvironment) {
    this.activeEnvironmentVariableTab.emit(activeEnvironment);
  }

  onColumnActionClick(actionResponse) {
    if (actionResponse.action.name === ActionMode.Edit) {
      this.modalType = ModalTypes.Edit;
      this.environmentVariableModal.visible = true;
      this.environmentVariableModal.data = actionResponse.data;
      const formValue = this.createOrEditEnvironmentVariableFormConfig.items.find(item => item.fieldName === 'value') as CrudFormInputTemplateItem;
      formValue.typeAndFormat = { type: assignTypeAccordingToValue(actionResponse.data.value) };
      this.activeFormType = assignTypeAccordingToValue(actionResponse.data.value);
      this.editableModalData = actionResponse.data;
    }
    if (actionResponse.action.name === ActionMode.Delete) {
      this.deleteFormItem.emit(actionResponse.data);
    }
  }

  openCreateEnvironmentVariableModal(activeEnvironmentVariable) {
    this.modalType = ModalTypes.Create;
    this.environmentVariableModal.data = { key: null, type: null, value: null };
    this.activeEnvironmentVariable = activeEnvironmentVariable;
    this.environmentVariableModal.visible = true;
  }

  onEnvironmentVariableModalSubmit() {
    if (this.environmentVariableModal.isValid) {
      this.environmentVariableModal.visible = false;
      if (this.modalType === ModalTypes.Create) {
        this.createFormSubmit.emit({ ...this.environmentVariableModal.data, activeEnvironmentVariable: this.activeEnvironmentVariable });
      }
      if (this.modalType === ModalTypes.Edit) {
        this.editFormSubmit.emit({ ...this.environmentVariableModal.data, editableModalData: this.editableModalData });
      }
    }
  }

  closeEnvironmentVariableModal() {
    this.environmentVariableModal.visible = false;
  }

  onEnvironmentModalDataValidityChange(isValid) {
    this.environmentVariableModal.isValid = isValid;
  }

  onEnvironmentVariableModalDataChange(formData) {
    if (formData.type !== this.activeFormType) {
      const formValue = this.createOrEditEnvironmentVariableFormConfig.items.find(item => item.fieldName === 'value') as CrudFormInputTemplateItem;
      formValue.typeAndFormat = { type: formData.type };
      this.createOrEditEnvironmentVariableFormConfig = { ...this.createOrEditEnvironmentVariableFormConfig };
      this.activeFormType = formData.type;
      this.environmentVariableModal.data = { key: formData.key, type: formData.type, value: this.resetFormValue(formData.type) };
    } else {
      this.environmentVariableModal.data = formData;
    }
  }

  resetFormValue(type) {
    if (type === PropertyType.Number) {
      return 0;
    } else if (type === PropertyType.Boolean) {
      return false;
    } else if (type === PropertyType.Date) {
      return null;
    } else if (type === PropertyType.String) {
      return '';
    } else if (type === PropertyType.Array) {
      return [];
    } else if (type === PropertyType.Object) {
      return {};
    } else {
      return null;
    }
  }

}
