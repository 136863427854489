<div class="title-toolbar">
  <rappider-title-toolbar [mainTitle]="mainTitle"
                          [options]="title"
                          [displayToolbar]="displayToolbar"
                          [displayToolbarBackButton]="displayToolbarBackButton">
  </rappider-title-toolbar>
</div>
<section class="text-editor">
  <rappider-crud-view-edit-form [config]="CREATE_MESSAGE_TEMPLATE_CONFIG"
                                [data]="messageTemplateFormData"
                                [submitButtonLoading]="submitButtonLoading"
                                (submitButtonClick)="onSubmitButtonClick($event)"
                                (formSubmit)="createMessageTemplate($event)">
  </rappider-crud-view-edit-form>
</section>
<section class="available-data-fields">
  <rappider-list-grid [config]="AVAILABLE_DATA_FIELD_LIST_CONFIG"
                      [data]="availableDataFields"></rappider-list-grid>
</section>