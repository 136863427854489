import { Language } from '../models/language/language';

/* EVERY SUPPORTED LANGUAGE OBJECTS */
export const ENGLISH_LANGUAGE: Language = {
  title: 'English',
  shortTitle: 'EN',
  selector: 'ENGLISH',
  name: 'en'
};

export const TURKISH_LANGUAGE: Language = {
  title: 'Turkish',
  shortTitle: 'TR',
  selector: 'TURKISH',
  name: 'tr'
};

/* DEFAULT LANGUAGE VARIABLE */
export const DEFAULT_LANGUAGE = ENGLISH_LANGUAGE;

/* SUPPORTED LANGUAGES ARRAY */
export const SUPPORTED_LANGUAGES: Language[] = [
  ENGLISH_LANGUAGE,
  // TURKISH_LANGUAGE
];
