/* eslint-disable no-shadow */
import { Action } from '@ngrx/store';
import { ProjectSettingWithRelations } from '@rappider/rappider-sdk';
import { LastProcessedAction, Pagination } from '@rappider/shared/interfaces';

export enum ActionTypes {
  LoadModule = '[ProjectSetting] LoadModule',
  SetPagination = '[ProjectSetting] SetPagination',
  SetPaginationFailure = '[ProjectSetting] SetPaginationFailure',
  GetTotalCount = '[ProjectSetting] GetTotalCount',
  GetProjectSettings = '[ProjectSetting] GetProjectSettings',
  GetProjectSettingsFailure = '[ProjectSetting] GetProjectSettingsFailure',
  SetProjectSettings = '[ProjectSetting] SetProjectSettings',
  UpsertProjectSetting = '[ProjectSetting] UpsertProjectSetting',
  CreateProjectSetting = '[ProjectSetting] CreateProjectSetting',
  CreateProjectSettingSuccessful = '[ProjectSetting] CreateProjectSettingSuccessful',
  UpdateProjectSetting = '[ProjectSetting] UpdateProjectSetting',
  UpdateProjectSettingSuccessful = '[ProjectSetting] UpdateProjectSettingSuccessful',
  UpdateProjectSettingFailure = '[ProjectSetting] UpdateProjectSettingFailure',
  DeleteProjectSetting = '[ProjectSetting] DeleteProjectSetting',
  DeleteProjectSettingSuccessful = '[ProjectSetting] DeleteProjectSettingSuccessful',
  DeleteProjectSettingFailure = '[ProjectSetting] DeleteProjectSettingFailure',
  ProjectSettingError = '[ProjectSetting] ProjectSettingError',
  ChangeLastProcessedAction = '[ProjectSetting] ChangeLastProcessedAction',
}

export class LoadModule implements Action {
  readonly type = ActionTypes.LoadModule;
}

export class GetProjectSettings implements Action {
  readonly type = ActionTypes.GetProjectSettings;
}

export class GetProjectSettingsFailure implements Action {
  readonly type = ActionTypes.GetProjectSettingsFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

/**
 * sets pagination and gets data after the action if specified
 *
 * @export
 * @class SetPagination
 * @implements {Action}
 */
export class SetPagination implements Action {
  readonly type = ActionTypes.SetPagination;
  constructor(public payload: { pagination: Pagination; getDataAfter?: boolean }) { }
}

export class SetPaginationFailure implements Action {
  readonly type = ActionTypes.SetPaginationFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class GetTotalCount implements Action {
  readonly type = ActionTypes.GetTotalCount;
}

export class SetProjectSettings implements Action {
  readonly type = ActionTypes.SetProjectSettings;
  constructor(public payload: { projectSettings: ProjectSettingWithRelations[] }) { }
}

export class UpsertProjectSetting implements Action {
  readonly type = ActionTypes.UpsertProjectSetting;
  constructor(public payload: { projectSetting: ProjectSettingWithRelations }) { }
}

export class CreateProjectSetting implements Action {
  readonly type = ActionTypes.CreateProjectSetting;
  constructor(public payload: { projectSetting: ProjectSettingWithRelations }) { }
}

export class CreateProjectSettingSuccessful implements Action {
  readonly type = ActionTypes.CreateProjectSettingSuccessful;
  constructor(public payload: { projectSetting: ProjectSettingWithRelations }) { }
}

export class UpdateProjectSetting implements Action {
  readonly type = ActionTypes.UpdateProjectSetting;
  constructor(public payload: { projectSetting: Partial<ProjectSettingWithRelations>; projectSettingId: string }) { }
}

export class UpdateProjectSettingSuccessful implements Action {
  readonly type = ActionTypes.UpdateProjectSettingSuccessful;
  constructor(public payload: { projectSetting: ProjectSettingWithRelations; projectSettingId: string }) { }
}

export class UpdateProjectSettingFailure implements Action {
  readonly type = ActionTypes.UpdateProjectSettingFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class DeleteProjectSetting implements Action {
  readonly type = ActionTypes.DeleteProjectSetting;
  constructor(public payload: { projectSettingId: string }) { }
}

export class DeleteProjectSettingSuccessful implements Action {
  readonly type = ActionTypes.DeleteProjectSettingSuccessful;
  constructor(public payload: { projectSettingId: string }) { }
}

export class DeleteProjectSettingFailure implements Action {
  readonly type = ActionTypes.DeleteProjectSettingFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class ProjectSettingError implements Action {
  readonly type = ActionTypes.ProjectSettingError;
  constructor(public payload: { errorOn: string; error?: any }) { }
}

export class ChangeLastProcessedAction implements Action {
  readonly type = ActionTypes.ChangeLastProcessedAction;
  constructor(public payload: { lastProcessedAction: LastProcessedAction }) { }
}

export type Actions =
  LoadModule
  | SetPagination
  | SetPaginationFailure
  | GetTotalCount
  | GetProjectSettings
  | GetProjectSettingsFailure
  | SetProjectSettings
  | UpsertProjectSetting
  | CreateProjectSetting
  | CreateProjectSettingSuccessful
  | UpdateProjectSetting
  | UpdateProjectSettingSuccessful
  | UpdateProjectSettingFailure
  | DeleteProjectSetting
  | DeleteProjectSettingSuccessful
  | DeleteProjectSettingFailure
  | ProjectSettingError
  | ChangeLastProcessedAction
  ;
