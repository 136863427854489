/* angular imports */
import { createAction, props } from '@ngrx/store';
import { ProjectModelField as ProjectModelField, ProjectModelFieldPartial as ProjectModelFieldPartial, NewProjectModelField as NewProjectModelField } from '@rappider/rappider-sdk';

/* action types */
export enum ActionTypes {
  GetProjectModelFields = '[ProjectModelField] GetProjectModelFields',
  GetProjectModelFieldsSuccessful = '[ProjectModelField] GetProjectModelFieldsSuccessful',
  GetProjectModelFieldsFailure = '[ProjectModelField] GetProjectModelFieldsFailure',
  CreateProjectModelField = '[ProjectModelField] CreateProjectModelField',
  CreateProjectModelFieldSuccessful = '[ProjectModelField] CreateProjectModelFieldSuccessful',
  CreateProjectModelFieldFailure = '[ProjectModelField] CreateProjectModelFieldFailure',
  UpdateProjectModelField = '[ProjectModelField] UpdateProjectModelField',
  UpdateProjectModelFieldSuccessful = '[ProjectModelField] UpdateProjectModelFieldSuccessful',
  UpdateProjectModelFieldFailure = '[ProjectModelField] UpdateProjectModelFieldFailure',
  DeleteProjectModelField = '[ProjectModelField] DeleteProjectModelField',
  DeleteProjectModelFieldSuccessful = '[ProjectModelField] DeleteProjectModelFieldSuccessful',
  DeleteProjectModelFieldFailure = '[ProjectModelField] DeleteProjectModelFieldFailure',
  ErrorAction = '[ProjectModelField] ErrorAction',
  ResetStateToInitial = '[ProjectModelField] ResetStateToInitial',
  ResetStateToInitialChangeProject = '[ProjectModelField] ResetStateToInitialChangeProject',
  GetProjectModelFieldsByModelId = '[ProjectModelField] GetProjectModelFieldsByModelId',
  GetProjectModelFieldsByModelIdSuccessful = '[ProjectModelField] GetProjectModelFieldsByModelIdSuccessful',
  GetProjectModelFieldsByModelIdFailure = '[ProjectModelField] GetProjectModelFieldsByModelIdFailure',
  BulkUpdateProjectModelFields = '[ProjectModelField] BulkUpdateProjectModelFields',
  BulkUpdateProjectModelFieldsSuccessful = '[ProjectModelField] BulkUpdateProjectModelFieldsSuccessful',
  BulkUpdateProjectModelFieldsFailure = '[ProjectModelField] BulkUpdateProjectModelFieldsFailure',
}

/* actions */

export const GetProjectModelFields = createAction(ActionTypes.GetProjectModelFields);

export const GetProjectModelFieldsSuccessful = createAction(
  ActionTypes.GetProjectModelFieldsSuccessful,
  props<{ payload: { projectModelFields: ProjectModelField[] } }>()
);

export const GetProjectModelFieldsFailure = createAction(
  ActionTypes.GetProjectModelFieldsFailure,
  props<{ error: any; key: string; timeStamp: number }>()
);

export const GetProjectModelFieldsByModelId = createAction(
  ActionTypes.GetProjectModelFieldsByModelId,
  props<{ payload: { projectModelId: string } }>()
);

export const GetProjectModelFieldsByModelIdSuccessful = createAction(
  ActionTypes.GetProjectModelFieldsByModelIdSuccessful,
  props<{ payload: { projectModelFields: ProjectModelField[]; projectModelId: string } }>()
);

export const GetProjectModelFieldsByModelIdFailure = createAction(
  ActionTypes.GetProjectModelFieldsByModelIdFailure,
  props<{ payload: { error: any; key: string; timeStamp: number } }>()
);

export const CreateProjectModelField = createAction(
  ActionTypes.CreateProjectModelField,
  props<{ payload: { projectModelField: NewProjectModelField; navigateAfterCreate?: boolean; relationedTypeId?: string } }>()
);

export const CreateProjectModelFieldSuccessful = createAction(
  ActionTypes.CreateProjectModelFieldSuccessful,
  props<{ payload: { projectModelField: ProjectModelField } }>()
);

export const CreateProjectModelFieldFailure = createAction(ActionTypes.CreateProjectModelFieldFailure);

export const UpdateProjectModelField = createAction(
  ActionTypes.UpdateProjectModelField,
  props<{ payload: { projectModelFieldId: string; projectModelField: ProjectModelFieldPartial; projectModelId: string | undefined } }>()
);

export const UpdateProjectModelFieldSuccessful = createAction(
  ActionTypes.UpdateProjectModelFieldSuccessful,
  props<{ payload: { projectModelFieldId: string; projectModelField: ProjectModelFieldPartial; projectModelId: string | undefined } }>()
);

export const UpdateProjectModelFieldFailure = createAction(ActionTypes.UpdateProjectModelFieldFailure);

export const DeleteProjectModelField = createAction(
  ActionTypes.DeleteProjectModelField,
  props<{ payload: { projectModelFieldId: string; projectModelId: string | undefined; relationedTypeId?: string } }>()
);

export const DeleteProjectModelFieldSuccessful = createAction(
  ActionTypes.DeleteProjectModelFieldSuccessful,
  props<{ payload: { projectModelFieldId: string; projectModelId: string | undefined } }>()
);

export const DeleteProjectModelFieldFailure = createAction(ActionTypes.DeleteProjectModelFieldFailure);

export const BulkUpdateProjectModelFields = createAction(
  ActionTypes.BulkUpdateProjectModelFields,
  props<{ payload: { projectModelId: string; projectModelFields: any[] } }>()
);

export const BulkUpdateProjectModelFieldsSuccessful = createAction(
  ActionTypes.BulkUpdateProjectModelFieldsSuccessful,
  props<{ payload: { updatedProjectModelFields: ProjectModelField[] } }>()
);

export const BulkUpdateProjectModelFieldsFailure = createAction(
  ActionTypes.BulkUpdateProjectModelFieldsFailure,
  props<{ payload: { error: any; key: string; timeStamp: number } }>()
);

export const ErrorAction = createAction(
  ActionTypes.ErrorAction,
  props<{ payload: { error: any; key: string; timeStamp: number } }>()
);


