import { Component, Input, OnInit } from '@angular/core';
import { Store, createSelector } from '@ngrx/store';
import {
  AvatarComponentConfig, AvatarShape, ButtonComponentConfig, ButtonSize,
  ButtonType, IChatMessage, IconType, MessageAuthorType
} from '@rappider/rappider-components/utils';
import { ChatMessage, KeyValuePair, Page, Project } from '@rappider/rappider-sdk';
import { Subscription, from, timer } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Navigate, RemoveQueryParam } from 'libs/shared/src/lib/states/router/router.actions';
import { PageDevelopmentStatus } from '@rappider/admin-dashboard';
import { AIMessageAuthorType } from '../utils/ai-assistant.enums';
import * as moment from 'moment';
import { CreateChatMessage } from '../state/ai-assistant.actions';
import { v4 } from 'uuid';
import { KeyValue } from '@angular/common';
import { orderBy } from 'lodash';

@Component({
  selector: 'rappider-ai-assistant',
  templateUrl: './ai-assistant.component.html',
  styleUrls: ['./ai-assistant.component.scss']
})
export class AIAssistantComponent implements OnInit {

  @Input() isComponentInSidebar = false;

  receiverAvatar: AvatarComponentConfig = {
    shape: AvatarShape.Square,
    imageUrl: '/assets/img/logo/rappider-logo.png'
  };
  senderAvatar: AvatarComponentConfig = {
    shape: AvatarShape.Square,
    icon: {
      name: 'fa-regular fa-user',
      type: IconType.FontAwesome
    },
    colorSettings: {
      backgroundColor: 'var(--section-background-color)'
    }
  };
  displayedMessages: IChatMessage[] = [];
  chatSessionMessages: ChatMessage[] = [];
  activeProject?: Project;
  subscriptions: Subscription[] = [];
  pages!: Page;
  isTyping = false;
  isMessagesLoaded = false;
  quickAnswerOptions?: KeyValue<string, string>[];

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToPages(),
      this.subscribeToAIAssistantMessages(),
      this.subscribeToIsLoaded(),
      this.subscribeToAIAssistantTypingStatus()
    ];
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data)
      .subscribe(activeProject => this.activeProject = activeProject);
  }

  subscribeToPages() {
    return this.store.select(state => state.page?.data).subscribe(pages => {
      this.pages = pages;
      this.controlPage();
    });
  }

  subscribeToIsLoaded() {
    return this.store.select(state => state.aiAssistant.isLoaded)
      .subscribe(isLoaded => this.isMessagesLoaded = isLoaded);
  }

  subscribeToAIAssistantTypingStatus() {
    return this.store.select(state => state.aiAssistant.isAssistantTyping)
      .subscribe(isAssistantTyping => this.isTyping = isAssistantTyping);
  }

  subscribeToAIAssistantMessages() {
    return this.store.select(createSelector(
      state => state.aiAssistant.messages,
      state => state.aiAssistant.unsynchronizedMessages,
      (messages: any , unsynchronizedMessages: any) => orderBy(
        [
          ...(messages || []),
          ...(unsynchronizedMessages || [])
        ],
        ['timestamp'],
        'asc'
      )
    )).subscribe((messages: ChatMessage[]) => {
      const filteredMessages = messages?.filter((message: any) => message.displayToUser !== false);
      if (messages?.length && this.chatSessionMessages?.length) {
        if (filteredMessages && filteredMessages?.length) {
          const newMessages = filteredMessages.filter(filteredMessage => !this.chatSessionMessages?.some(message => message.content === filteredMessage.content && message.timestamp === filteredMessage.timestamp));
          this.chatSessionMessages = messages;
          let lastMessagePostedOnUI = { role: null };


          // Use RxJS to handle the delay and appending of new messages
          from(newMessages).pipe(
            concatMap((message: any) => {
              /* 5 sn delay between two consequent AI messages */
              // eslint-disable-next-line max-len
              const isConsequentAIMessage = (lastMessagePostedOnUI?.role === AIMessageAuthorType.Assistant) && (message?.role === AIMessageAuthorType.Assistant);
              const isInLast5Minutes = moment.utc(message.timestamp).isAfter(moment.utc().subtract(5, 'minutes'));
              const delayTime = isConsequentAIMessage && isInLast5Minutes ? 5000 : 0;
              // update the last message posted on UI
              lastMessagePostedOnUI = message;
              return timer(delayTime)
                .pipe(
                  concatMap(() => from([message]))
                );
            }
            )
          ).subscribe({
            next: (message: any) => {
              const formattedMessage = {
                ...message,
                content: this.formatMessage(message.content),
                messageAuthorType: message.role === 'user' ? MessageAuthorType.Sender : MessageAuthorType.Receiver,
                date: message.timestamp,
                additionalButton: this.buildNavigateButtonByMessage(message),
                metadata: message.metadata
              };

              // Append the message with a delay
              this.displayedMessages = [
                ...this.displayedMessages,
                formattedMessage
              ];
            },
            complete: () => {
              this.controlPage();
              this.buildQuickAnswerOptions();
            }
          });

        }
      } else {
        this.displayedMessages = filteredMessages.map(message => ({
          ...message,
          content: this.formatMessage(message.content),
          messageAuthorType: message.role === 'user' ? MessageAuthorType.Sender : MessageAuthorType.Receiver,
          date: message.timestamp,
          additionalButton: this.buildNavigateButtonByMessage(message),
          metadata: message.metadata
        }));
        // [Bug]: Sometimes, the UI is showing the same message twice
        // This is a UI data mapping issue, it should have been fixed in the code of incorrect data mapping
        // However I am adding this fix for a quick solution
        // Temporary Fix:
        // If the role of the last two messages are 'user' and they have the same content then remove the last message as it is a duplicate
        const l = this.displayedMessages.length;
        if (this.displayedMessages.length > 2 &&
          this.displayedMessages[l - 1].role === 'user' &&
          this.displayedMessages[l - 2].role === 'user' &&
          this.displayedMessages[l - 1].content === this.displayedMessages[l - 2].content) {
          this.displayedMessages.pop();
        }
        this.controlPage();
        this.buildQuickAnswerOptions();
      }
    }, catchError(error => error));
  }

  onMessageSent(message: string) {
    const createdMessage = {
      keyForUI: v4(),
      content: message,
      role: 'user',
      timestamp: new Date().toISOString()
    };

    this.store.dispatch(CreateChatMessage({ payload: { message: createdMessage } }));
  }

  buildQuickAnswerOptions() {
    if (this.displayedMessages?.length) {
      const quickAnswerMetadata = this.displayedMessages[this.displayedMessages.length - 1]?.metadata?.find((metadata: KeyValuePair) => metadata.key === 'message-options');
      if (quickAnswerMetadata) {
        this.quickAnswerOptions = quickAnswerMetadata.value;
      } else {
        this.quickAnswerOptions = [];
      }
    }
  }

  buildNavigateButtonByMessage(message: any): ButtonComponentConfig | undefined {
    const button: ButtonComponentConfig = {
      type: ButtonType.Default,
      size: ButtonSize.ExtraSmall
    };
    const pageMetadata = message?.metadata?.find((metadata: any) => metadata.key === 'page-created');
    const modelMetadata = message?.metadata?.find((metadata: any) => metadata.key === 'model');
    const themeMetadata = message?.metadata?.find((metadata: any) => metadata.key === 'theme');
    if (pageMetadata) {
      button.text = 'Go to page';
      button.key = `${PATH_DEFINITIONS.CONTENT_EDITOR.CONTENT_EDITOR_PATH}/${pageMetadata.value}`;
      return button;
    } else if (modelMetadata) {
      button.text = 'Go to model';
      button.key = `${PATH_DEFINITIONS.DATABASE_DIAGRAM_EDITOR.DATABASE_DIAGRAM_EDITOR_PATH}`;
      return button;
    } else if (themeMetadata) {
      button.text = 'Go to theme';
      button.key = `${PATH_DEFINITIONS.PROJECTS.PROJECT_THEME}/${themeMetadata.value}`;
      return button;
    } else {
      return undefined;
    }
  }

  onMessageButtonClick(message: any) {
    if (message?.additionalButton?.key) {
      this.store.dispatch(new Navigate({ url: message.additionalButton.key }));
    }
  }

  formatMessage(message: any) {
    if (!message) {
      return '';
    } else {
      let formattedMessage = message.replace(/\n/g, '<br>');
      formattedMessage = formattedMessage.replace(/```json/g, '<pre><code>');
      formattedMessage = formattedMessage.replace(/```/g, '</code></pre>');
      return formattedMessage;
    }
  }

  controlPage() {
    this.displayedMessages.forEach(message => {
      const pageMetadata = message?.metadata?.find((metadata: any) => metadata.key === 'page-created');
      if (pageMetadata && message?.additionalButton) {
        const page = this.pages?.find((p: Page) => p.id === pageMetadata?.value);
        message.additionalButton.tooltipText = page?.developmentStatus === PageDevelopmentStatus.InReview ?
          'This page is in review.' : undefined;
        message.additionalButton.disabled = page?.developmentStatus === PageDevelopmentStatus.InReview;
      }
    });
  }

  onFullScreenButtonClick() {
    this.store.dispatch(new RemoveQueryParam({ key: 'activeRightSidebarTab' }));
    this.store.dispatch(new Navigate({ url: PATH_DEFINITIONS.AI.ASSISTANT }));
  }

  onQuickAnswerButtonClick(option: string) {
    this.onMessageSent(option);
  }

}
