export const ABSTRACT_IMAGES = [
    '/assets/img/abstracts/aaron-burden-5UB407aLWJQ-unsplash.jpg',
    '/assets/img/abstracts/annie-spratt-jhw1cRdWkEI-unsplash.jpg',
    '/assets/img/abstracts/chris-barbalis-uZAuwQJJ-4s-unsplash.jpg',
    '/assets/img/abstracts/elizabeth-lies-ZWPerNlqUu0-unsplash.jpg',
    '/assets/img/abstracts/joanna-kosinska-Uvl3W4XWd4U-unsplash.jpg',
    '/assets/img/abstracts/kalle-kortelainen-3picpvVQiVM-unsplash.jpg',
    '/assets/img/abstracts/luca-bravo-xnqVGsbXgV4-unsplash.jpg',
    '/assets/img/abstracts/mike-newbry-7VyXToCotr4-unsplash.jpg',
    '/assets/img/abstracts/morgan-1TteudFnKoI-unsplash.jpg',
    '/assets/img/abstracts/nattu-adnan-Ai2TRdvI6gM-unsplash.jpg',
    '/assets/img/abstracts/pawel-czerwinski-h19obIsuogA-unsplash.jpg',
    '/assets/img/abstracts/pipe-a-HO-uMoDyjA8-unsplash.jpg',
    '/assets/img/abstracts/ryo-yoshitake-cusz0Bg-5mQ-unsplash.jpg',
    '/assets/img/abstracts/scott-webb-OxHPDs4WV8Y-unsplash.jpg',
    '/assets/img/abstracts/sharon-mccutcheon-dG9fUG0Jpos-unsplash.jpg',
    '/assets/img/abstracts/silvio-kundt-Fixg8KipOg8-unsplash.jpg',
    '/assets/img/abstracts/sumner-mahaffey-7Y0NshQLohk-unsplash.jpg',
    '/assets/img/abstracts/w-qjCHPZbeXCQ-unsplash.jpg'
];
