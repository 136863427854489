<!-- TYPE SELECT AREA -->

<div class="items-area margin-space">
    <div class="label col-1">
        <rappider-label [content]="'SHARED.TYPE' | translate"></rappider-label>
    </div>

    <div class="form-item col-11">
        <rappider-select [(ngModel)]="value.type"
                         [options]="typeSelectOptions"
                         (ngModelChange)="onTypeSelect($event)"></rappider-select>
    </div>
</div>

<!-- FORMAT SELECT AREA -->

<div *ngIf="value.type === PropertyType.String || value.type === PropertyType.Integer"
     class="items-area margin-space">
    <div class="label col-1">
        <rappider-label [content]="'SHARED.FORMAT' | translate"></rappider-label>
    </div>

    <div class="col-11">
        <rappider-select [(ngModel)]="value.format"
                         [options]="formatSelectOptions"
                         (ngModelChange)="onFormatSelect($event)"></rappider-select>
    </div>
</div>

<div class="items-area margin-space">
    <div class="label col-1">
        <rappider-label [content]="'SHARED.KEY' | translate"></rappider-label>
    </div>

    <div class="col-11">
        <rappider-textbox [(ngModel)]="value.key"
                          (ngModelChange)="onInputValueChange()"></rappider-textbox>
    </div>
</div>
