import { CrudTableViewConfig, CrudViewColumnType, HeadingType } from '@rappider/rappider-components/utils';
import { PATH_DEFINITIONS } from '../../definitions/path-definition';

export const MATCHED_COMPONENT_DEFINITION_LIST_CONFIG: CrudTableViewConfig = {
  title: {
    content: 'Matched Components',
    type: HeadingType.H5
  },
  defaultSearchField: 'title',
  columns: [
    {
      title: 'SHARED.TITLE',
      fieldName: 'title',
      type: CrudViewColumnType.Link,
      routerLink: `${PATH_DEFINITIONS.COMPONENT_BROWSER.COMPONENT_DEFINITION_DETAIL_PATH}/{{id}}`,
    },
    {
      title: 'SHARED.CLASS_NAME',
      fieldName: 'className',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.MODULE_NAME',
      fieldName: 'moduleName',
      type: CrudViewColumnType.Text
    }
  ],
  columnVisibilitySelectable: false,
  searchable: false
};
