import { Injectable, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService, NzNotificationDataOptions } from 'ng-zorro-antd/notification';

const DEFAULT_NOTIFICATION_PLACEMENT = 'bottomRight';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private nzNotificationService: NzNotificationService,
    private translateService: TranslateService
  ) { }

  createNotification(type: string, title: string, content: string, options: NzNotificationDataOptions = {}): void {
    this.nzNotificationService.create(
      type,
      title ? this.translateService.instant(title) || title : '',
      content ? this.translateService.instant(content) || content : '',
      { nzPlacement: DEFAULT_NOTIFICATION_PLACEMENT, ...options }
    );
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  createNotificationTemplate(template: TemplateRef<{}>, options: NzNotificationDataOptions = {}) {
    this.nzNotificationService.template(template, { nzPlacement: DEFAULT_NOTIFICATION_PLACEMENT, ...options });
  }

  /**
   * Remove the notification with the specified id. When the id is empty, remove all notifications
   *
   * @memberof NotificationService
   */
  removeNotifications(id?: string) {
    this.nzNotificationService.remove(id);
  }

}
