import { WorkflowStepFunction } from '../index';
import { Project } from './Project';

declare let Object: any;

export interface WorkflowInterface {
    'id'?: string;
    'projectId'?: string;
    workflowStepFunctions: WorkflowStepFunction[];
    project: Project;
}

export class Workflow implements WorkflowInterface {
    'id'?: string;
    'projectId'?: string;
    workflowStepFunctions: WorkflowStepFunction[];
    project: Project;
    constructor(data?: WorkflowInterface) {
        Object.assign(this, data);
    }
    /**
     * The name of the model represented by this $resource,
     * i.e. `Workflow`.
     */
    public static getModelName() {
        return 'Workflow';
    }
    /**
    * @method factory
    * @author Jonathan Casarrubias
    * @license MIT
    * This method creates an instance of Workflow for dynamic purposes.
    **/
    public static factory(data: WorkflowInterface): Workflow {
        return new Workflow(data);
    }
    /**
    * @method getModelDefinition
    * @author Julien Ledun
    * @license MIT
    * This method returns an object that represents some of the model
    * definitions.
    **/
    public static getModelDefinition() {
        return {
            name: 'Workflow',
            plural: 'Workflows',
            path: 'workflows',
            idName: 'id',
            properties: {
                'id': {
                    name: 'id',
                    type: 'string'
                },
                'projectId': {
                    name: 'projectId',
                    type: 'string'
                }
            },
            relations: {
                workflowStepFunctions: {
                    name: 'workflowStepFunctions',
                    type: 'WorkflowStepFunction[]',
                    model: 'WorkflowStepFunction',
                    relationType: 'hasMany',
                    keyFrom: 'id',
                    keyTo: 'workflowId'
                },
                project: {
                    name: 'project',
                    type: 'Project',
                    model: 'Project',
                    relationType: 'belongsTo',
                    keyFrom: 'projectId',
                    keyTo: 'id'
                }
            }
        };
    }
}
