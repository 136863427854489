import { Injectable } from '@angular/core';
import { CREATE_OR_EDIT_MESSAGE_TEMPLATE_CONFIG, ENVIRONMENT_KEY_SELECTBOX_ITEM, QUILL_MENTION_CONFIG, TEXTBOX_MENTION_CONFIG } from '../../configs/project';
import { CrudFormConfig } from '@rappider/rappider-components/utils';

@Injectable({
  providedIn: 'root'
})
export class MessageTemplateService {


  setMessageTemplateFormConfig(environmentKeys: string[], mentionAvailableDataFields?: string[]) {
    let FORM_CONFIG: CrudFormConfig = <CrudFormConfig>{};
    ENVIRONMENT_KEY_SELECTBOX_ITEM.options = environmentKeys.map(environmentKey => ({ key: environmentKey, value: environmentKey }));
    if (mentionAvailableDataFields) {
      const quillMention = {
        ...QUILL_MENTION_CONFIG,
        mentionValues: mentionAvailableDataFields,
        mentionSupported: true
      };
      const textBoxMention = {
        ...TEXTBOX_MENTION_CONFIG,
        mentionValues: mentionAvailableDataFields,
        mentionSupported: true
      };
      FORM_CONFIG = {
        ...CREATE_OR_EDIT_MESSAGE_TEMPLATE_CONFIG,
        items: [...CREATE_OR_EDIT_MESSAGE_TEMPLATE_CONFIG.items, { ...textBoxMention }, { ...quillMention }, ENVIRONMENT_KEY_SELECTBOX_ITEM]
      };
    } else {
      FORM_CONFIG = {
        ...CREATE_OR_EDIT_MESSAGE_TEMPLATE_CONFIG,
        items: [
          ...CREATE_OR_EDIT_MESSAGE_TEMPLATE_CONFIG.items,
          TEXTBOX_MENTION_CONFIG,
          QUILL_MENTION_CONFIG,
          ENVIRONMENT_KEY_SELECTBOX_ITEM]
      };
    }
    return FORM_CONFIG;
  }

}
