import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CodeMirrorMode, FormLayout, IconComponentConfig, IconType } from '@rappider/rappider-components/utils';
import { PROGRAMMING_LANGUAGES, ProgrammingLanguage } from '../../../utils/programming-languages';
import { PACKAGE_COLUMNS } from '../../../utils/package-columns.config';
import { ENVIRONMENT_VARIABLES_COLUMNS } from '../../../utils/environment-variables-columns.config';
import { NPMPackage } from 'libs/project/src/lib/utils/models/npm-package-model';
import { KeyValue } from '@angular/common';
import { ProjectPackageInterface } from '@rappider/api-sdk';
import { PASCAL_CASE_REGEX, addProjectPackageButtonConfig, projectPackageInfoAlertConfig } from '@rappider/shared/definitions';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { MONACO_EDITOR_JAVASCRIPT_CONFIG, MONACO_EDITOR_JSON_CONFIG } from 'libs/shared/src/lib/configs/monaco-editor-language-and-mode-config';
import { ENVIRONMENT_VARIABLES } from '../../../utils/environment-variables.config';
import { ACTIVE_ENVIRONMENT_VARIABLE } from '../../../utils/active-environment-variable.config';
import { EnvironmentVariable } from '@rappider/rappider-sdk';
import { CustomFunctionCreateEditFormItem } from '../../../utils/custom-function-crate-edit-form-item.enum';
import { NpmPackageSearchService } from 'libs/project/src/lib/utils/services/npm-package-search.service.ts/npm-package-search.service';
import { Store } from '@ngrx/store';
import { NotificationService } from '@rappider/services';
import { isJsonValid } from '@rappider/shared/validators';
import { ThemeMode } from '@rappider/models';
import { MonacoEditorTheme } from 'libs/shared/src/lib/configs/monaco-editor-theme';
import { sortingByNumber } from '../../../utils/sorting-by-number';
import { debounceTime, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { updateCodeExampleOnLanguage } from '../../utils/custom-function-language-monaco-config';

@Component({
  selector: 'rappider-custom-function-create-wrapper',
  templateUrl: './custom-function-create-wrapper.component.html',
  styleUrls: ['./custom-function-create-wrapper.component.scss']
})
export class CustomFunctionCreateWrapperComponent implements OnInit, OnChanges {

  @Input() visibleFields: string[];
  @Input() customFunction: any;
  @Input() enableSubmitButton: boolean;
  @Input() submitButtonLoading: boolean;

  @Output() createFormSubmit = new EventEmitter<any>();

  createCustomFunctionForm: FormGroup;
  formLayout: FormLayout = FormLayout.Horizontal;
  programmingLanguageOptions = PROGRAMMING_LANGUAGES;
  packageColumns = PACKAGE_COLUMNS;
  environmentVariablesColumns = ENVIRONMENT_VARIABLES_COLUMNS;
  isModalOpen = false;
  selectedPackage: NPMPackage;
  npmPackages: KeyValue<string, NPMPackage>[] = [];
  searchChange$ = new Subject<string>();
  projectPackages: ProjectPackageInterface[];
  packageDetails: KeyValue<string, any>[];
  isLoading = false;
  selectedVersion = null;
  isCustomFunctionLoading = false;

  projectPackageInfoAlertConfig = projectPackageInfoAlertConfig;
  addProjectPackageButtonConfig = addProjectPackageButtonConfig;

  subscriptions: Subscription[];

  codeMirrorSettings = {
    mode: CodeMirrorMode.Javascript,
    lineNumbers: true,
    autoCloseBrackets: true
  };

  loadingIcon: IconComponentConfig = {
    name: 'loading',
    type: IconType.NgZorro
  };

  versions = {
    placement: 'bottomRight',
    items: [],
    labelMode: 'static-label',
  };

  options = [
    {
      label: '',
      value: ''
    }
  ];

  MONACO_EDITOR_JAVASCRIPT_CONFIG = MONACO_EDITOR_JAVASCRIPT_CONFIG;
  MONACO_EDITOR_JSON_CONFIG = MONACO_EDITOR_JSON_CONFIG;

  environments = ENVIRONMENT_VARIABLES;
  activeEnvironmentVariable = ACTIVE_ENVIRONMENT_VARIABLE;
  displayedEnvironmentVariables: EnvironmentVariable[];
  grouppedEnvironmentVariables = {};
  customFunctionCreateEditFormItem = CustomFunctionCreateEditFormItem;
  codeEditorVisible = false;
  requestJSONSchemaVisible = false;
  responseJSONSchemaVisible = false;
  mockResponseVisible = false;
  monacoEditorLoadTime = 50; // Average render time for monaco editor
  editorConfig = MONACO_EDITOR_JAVASCRIPT_CONFIG;

  constructor(
    private store: Store<any>,
    private npmPackageSearchService: NpmPackageSearchService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private httpClient: HttpClient
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
    this.initializeForm();
    this.getNpmPackages();
    this.changeActiveCodeEditorTab(CustomFunctionCreateEditFormItem.Code);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customFunction) {
      this.setFormValues();
    }
    if (changes.visibleFields) {
      this.updateForm();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToProjectPackages(),
      this.subscribeToCustomFunctionLoading(),
      this.subscribeToPreferredThemeAndUpdateMonacoTheme()
    ];
  }

  subscribeToProjectPackages() {
    return this.store.select(state => state?.projectPackage?.data).subscribe((projectPackages: ProjectPackageInterface[]) => {
      if (projectPackages) {
        this.projectPackages = projectPackages;
      }
    });
  }

  subscribeToCustomFunctionLoading() {
    return this.store.select(state => state.customFunction?.isLoading).subscribe((isLoading: boolean) => {
      this.isCustomFunctionLoading = isLoading;
    });
  }

  subscribeToPreferredThemeAndUpdateMonacoTheme() {
    return this.store.select(state => state.auth?.activePerson?.preferredTheme).subscribe(preferredTheme => {
      if (preferredTheme === ThemeMode.Light) {
        this.MONACO_EDITOR_JAVASCRIPT_CONFIG.theme = MonacoEditorTheme.Light;
        this.MONACO_EDITOR_JSON_CONFIG.theme = MonacoEditorTheme.Light;
      } else {
        this.MONACO_EDITOR_JAVASCRIPT_CONFIG.theme = MonacoEditorTheme.Dark;
        this.MONACO_EDITOR_JSON_CONFIG.theme = MonacoEditorTheme.Dark;
      }
      this.MONACO_EDITOR_JAVASCRIPT_CONFIG = { ...this.MONACO_EDITOR_JAVASCRIPT_CONFIG };
      this.MONACO_EDITOR_JSON_CONFIG = { ...this.MONACO_EDITOR_JSON_CONFIG };
    });
  }

  setPackageDetailData(selectedPackage: NPMPackage, npmPackageVersions: any) {
    if (selectedPackage && npmPackageVersions) {
      const values = Object.values(npmPackageVersions.versions);

      values.forEach((item, index) => {
        this.options[index] = { label: item['version'], value: item['version'] };
        this.versions.items.push({ label: item['version'] });
      });
      this.packageDetails = [
        {
          key: 'SHARED.NAME',
          value: [selectedPackage.name]
        },
        {
          key: 'SHARED.VERSION',
          value: [this.options.sort((a, b) => sortingByNumber(a.value, b.value))]
        },
        {
          key: 'SHARED.DESCRIPTION',
          value: [selectedPackage.description]
        },
        {
          key: 'SHARED.AUTHOR',
          value: Object.values(selectedPackage.author ?? {})
        },
        {
          key: 'SHARED.PUBLISHER',
          value: Object.values(selectedPackage.publisher ?? {})
        },
        {
          key: 'SHARED.LINKS',
          value: Object.values(selectedPackage.links ?? {})
        },
        {
          key: 'SHARED.KEYWORDS',
          value: selectedPackage.keywords
        }
      ];

      this.selectedVersion = values[0]['version'];

    } else {
      this.packageDetails = null;
    }
  }

  onProgrammingLanguageChange(value: string) {
    const language = value.replace(':', '');
    const url = `/assets/code/custom-function-definition/code-examples/${language}.txt`;
    this.httpClient.get(url, { responseType: 'text' }).subscribe((data) => {
      this.createCustomFunctionForm.get('code').setValue(data);
    });
    const editorConfig = updateCodeExampleOnLanguage(language);
    this.editorConfig = editorConfig;

  }

  getFormControls() {
    const formControls = {
      serviceName: [null, [Validators.required, Validators.pattern(PASCAL_CASE_REGEX)]],
      functionName: [null, [Validators.required]],
      programmingLanguage: [ProgrammingLanguage.NodeJs_20, []],
      packages: [null, []],
      DEV: [[], []],
      QA: [[], []],
      PROD: [[], []],
      environmentVariables: [null, []],
      // eslint-disable-next-line no-sparse-arrays
      code: [, [Validators.required]],
      requestJSONSample: [null, [isJsonValid('requestJSONSample', true)]],
      responseJSONSample: [null, [isJsonValid('responseJSONSample', true)]],
      mockResponse: [null, [isJsonValid('mockResponse', true)]]
    };

    const controls = {};

    for (const key in formControls) {
      if (this.visibleFields.includes(key)) {
        controls[key] = formControls[key];
      }
    }

    return controls;
  }

  /**
   * this function set serviceName and functionName values according to custom endpoint name
   */
  setFormValues() {
    if (this.customFunction && this.customFunction?.serviceName) {
      this.createCustomFunctionForm.get('serviceName').patchValue(this.customFunction.serviceName);
      this.createCustomFunctionForm.get('functionName').patchValue(this.customFunction.functionName);
    }
  }

  initializeForm() {
    const controls = this.getFormControls();
    this.createCustomFunctionForm = this.formBuilder.group(controls);
    this.onProgrammingLanguageChange(ProgrammingLanguage.NodeJs_20);
  }

  updateForm() {
    const currentValues = this.createCustomFunctionForm?.value;
    const controls = this.getFormControls();

    for (const key in controls) {
      if (this.createCustomFunctionForm?.contains(key)) {
        this.createCustomFunctionForm.get(key).setValidators(controls[key][1]);
        this.createCustomFunctionForm.get(key).updateValueAndValidity();
      } else {
        this.createCustomFunctionForm?.addControl(key, this.formBuilder.control(controls[key][0], controls[key][1]));
      }
    }

    for (const key in this.createCustomFunctionForm?.controls) {
      if (!controls[key]) {
        this.createCustomFunctionForm.removeControl(key);
      }
    }

    this.createCustomFunctionForm?.patchValue(currentValues);

    if (!this.visibleFields.find(visibleField => visibleField === 'mockResponse')) {
      setTimeout(() => {
        this.codeEditorVisible = true;
        this.requestJSONSchemaVisible = false;
        this.responseJSONSchemaVisible = false;
        this.mockResponseVisible = false;
      }, this.monacoEditorLoadTime);
    }
  }

  onCreateCustomFunction() {
    if (this.createCustomFunctionForm.valid) {
      const updatedFormBeforeSubmit = [
        ...(this.createCustomFunctionForm.get('DEV')?.value?.map(devValue => ({
          environmentKey: 'dev',
          key: devValue.key,
          value: devValue.value
        })) || []),
        ...(this.createCustomFunctionForm.get('QA')?.value?.map(qaValue => ({
          environmentKey: 'qa',
          key: qaValue.key,
          value: qaValue.value
        })) || []),
        ...(this.createCustomFunctionForm.get('PROD')?.value?.map(prodValue => ({
          environmentKey: 'prod',
          key: prodValue.key,
          value: prodValue.value
        })) || []),
      ];
      this.createCustomFunctionForm.get('environmentVariables').setValue(updatedFormBeforeSubmit);
      const createForm = this.createCustomFunctionForm.value;
      delete createForm.DEV;
      delete createForm.PROD;
      delete createForm.QA;
      this.createFormSubmit.emit(createForm);
    }
  }

  getNameFieldErrorsByErrorKey(errorKey: string) {
    const control = this.createCustomFunctionForm.get(CustomFunctionCreateEditFormItem.ServiceName);
    const isDirty = control?.dirty;
    const errors = control?.errors;
    return isDirty && errors && control.hasError(errorKey);
  }

  openModal() {
    this.isModalOpen = true;
  }

  onModalOk() {
    this.isModalOpen = false;

    if (this.createCustomFunctionForm.value.packages) {
      const isProjectPackageNameExist = this.createCustomFunctionForm.value.packages.some(projectPackage => projectPackage.packageName === this.selectedPackage.name);
      if (!isProjectPackageNameExist) {
        this.createCustomFunctionForm.patchValue({
          packages: [
            ...(this.createCustomFunctionForm?.value?.packages ? <any[]>this.createCustomFunctionForm?.value?.packages : []),
            { 'packageName': this.selectedPackage.name, 'versionNumber': this.selectedVersion }
          ]
        });
      } else {
        this.notificationService.createNotification(
          'error',
          'Error',
          'This package already exists in this custom function.'
        );
      }
    } else {
      this.createCustomFunctionForm.patchValue({
        packages: [
          ...(this.createCustomFunctionForm?.value?.packages ? <any[]>this.createCustomFunctionForm?.value?.packages : []),
          { 'packageName': this.selectedPackage.name, 'versionNumber': this.selectedVersion }
        ]
      });
    }

    this.selectedPackage = null;
    this.packageDetails = null;
    this.selectedVersion = null;
  }

  selectVersionAlert() {
    this.notificationService.createNotification(
      'warning',
      'Warning',
      'Please select a package version!'
    );
  }

  onModalCancel() {
    this.isModalOpen = false;
    this.selectedPackage = null;
    this.packageDetails = null;
    this.selectedVersion = null;
  }

  onSearchValueChange(searchValue: string) {
    if (searchValue === '') {
      this.isLoading = false;
    } else {
      this.isLoading = true;
    }
    this.searchChange$.next(searchValue);
  }

  async onSelectPackage(selectedPackage: NPMPackage) {
    if (selectedPackage) {
      const npmPackageVersions = await this.npmPackageSearchService.getNpmPackageVersions(selectedPackage?.name);
      const sortedNpmPackageVersions = {
        ...npmPackageVersions,
        versions: this.npmPackageSearchService.sortPackageVersions(npmPackageVersions)
      };
      this.setPackageDetailData(selectedPackage, sortedNpmPackageVersions);
    } else {
      this.setPackageDetailData(null, {});
    }
  }

  getNpmPackages() {
    const npmPackages$: Observable<any> = this.searchChange$
      .asObservable().pipe(
        debounceTime(1500),
        switchMap((searchValue) => {
          if (searchValue) {
            return this.npmPackageSearchService.getNpmPackages(searchValue);
          } else {
            return of(null);
          }
        })
      );

    this.setNpmPackageSelectOptions(npmPackages$);
  }

  setNpmPackageSelectOptions(packages: Observable<any>) {
    packages.subscribe(packages => {
      this.npmPackages = packages?.results?.map(result => ({
        key: result.package.name,
        value: result.package
      })) ?? [];
      this.isLoading = false;
    });
  }

  onVersionChange(value: any) {
    this.selectedVersion = value;
  }

  selectEnvironment(activeEnvironmentVariable) {
    this.activeEnvironmentVariable = activeEnvironmentVariable;
  }

  onCreateFormSubmit(formData) {
    this.environments.forEach(environment => {
      if (environment === formData.activeEnvironmentVariable) {
        this.createCustomFunctionForm.get(environment).setValue(
          [...this.createCustomFunctionForm.get(environment).value, { key: formData.key, type: formData.type, value: formData.value }]
        );
      } else {
        this.createCustomFunctionForm.get(environment).setValue(
          [...this.createCustomFunctionForm.get(environment).value, { key: formData.key, type: null, value: null }]
        );
      }
    });
    this.grouppedEnvironmentVariables = {
      DEV: this.createCustomFunctionForm.get('DEV').value,
      QA: this.createCustomFunctionForm.get('QA').value,
      PROD: this.createCustomFunctionForm.get('PROD').value
    };
    this.onHandleActiveEnvironmentVariableTabList(this.activeEnvironmentVariable);
  }

  onEditFormSubmit(formData) {
    const formValue = this.createCustomFunctionForm.get(this.activeEnvironmentVariable).value;
    const updatedDevValue = formValue.map(item => {
      if (item.key === formData.editableModalData.key) {
        item.key = formData.key;
        item.type = formData.type;
        item.value = formData.value;
      }
      return item;
    });
    this.createCustomFunctionForm.get(this.activeEnvironmentVariable).setValue(updatedDevValue);
    this.grouppedEnvironmentVariables = {
      ...this.grouppedEnvironmentVariables,
      [this.activeEnvironmentVariable]: this.createCustomFunctionForm.get(this.activeEnvironmentVariable).value
    };
    this.onHandleActiveEnvironmentVariableTabList(this.activeEnvironmentVariable);
  }

  onDeleteFormItem(formData) {
    const formValue = this.createCustomFunctionForm.get(this.activeEnvironmentVariable).value;
    const updatedFromValue = formValue.filter(item => item.key !== formData.key);
    this.createCustomFunctionForm.get(this.activeEnvironmentVariable).setValue(updatedFromValue);
    this.grouppedEnvironmentVariables = {
      ...this.grouppedEnvironmentVariables,
      [this.activeEnvironmentVariable]: this.createCustomFunctionForm.get(this.activeEnvironmentVariable).value
    };
    this.onHandleActiveEnvironmentVariableTabList(this.activeEnvironmentVariable);
  }

  onHandleActiveEnvironmentVariableTabList(activeEnvironment) {
    this.activeEnvironmentVariable = activeEnvironment;
    this.displayedEnvironmentVariables = this.grouppedEnvironmentVariables[activeEnvironment];
  }

  changeActiveCodeEditorTab(activeTab) {
    if (activeTab === CustomFunctionCreateEditFormItem.Code) {
      setTimeout(() => {
        this.codeEditorVisible = true;
        this.requestJSONSchemaVisible = false;
        this.responseJSONSchemaVisible = false;
      }, this.monacoEditorLoadTime);
    } else if (activeTab === CustomFunctionCreateEditFormItem.RequestJSONSchema) {
      setTimeout(() => {
        this.codeEditorVisible = false;
        this.requestJSONSchemaVisible = true;
        this.responseJSONSchemaVisible = false;
      }, this.monacoEditorLoadTime);
    } else if (activeTab === CustomFunctionCreateEditFormItem.ResponseJSONSchema) {
      setTimeout(() => {
        this.codeEditorVisible = false;
        this.requestJSONSchemaVisible = false;
        this.responseJSONSchemaVisible = true;
      }, this.monacoEditorLoadTime);
    } else if (activeTab === CustomFunctionCreateEditFormItem.MockResponse) {
      setTimeout(() => {
        this.codeEditorVisible = false;
        this.requestJSONSchemaVisible = false;
        this.responseJSONSchemaVisible = false;
        this.mockResponseVisible = true;
      }, this.monacoEditorLoadTime);
    }
  }

}
