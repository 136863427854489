export enum UserSelect {
  All = 'all',
  Auto = 'auto',
  Inherit = 'inherit',
  Initial = 'initial',
  None = 'none',
  Revert = 'revert',
  Text = 'text',
  Unset = 'unset'
}
