<div class="project-model-field-container">
  <section class="project-model-name">
    <!-- <div *ngIf="mode !== 'read'">
      <rappider-button [icon]="backButtonConfig.icon!"
                       [size]="backButtonConfig.size!"
                       (click)="setModeToRead()"></rappider-button>
    </div> -->

    <div *ngIf="projectModel"
         class="name-section">
      <rappider-heading [content]="mode === ComponentMode.Create ? projectModel.name + ' / New field' : projectModel.name"
                        [type]="projectModelHeadingType"></rappider-heading>
      <div>
        <rappider-button *ngIf="projectModel.isUpdatable"
                         class="edit-icon"
                         nz-tooltip
                         [nzTooltipTitle]="'Edit Project Model'"
                         [size]="editButtonConfig.size"
                         [icon]="editButtonConfig.icon"
                         (click)="onClickProjectModelUpdate()"></rappider-button>
      </div>
    </div>

    <rappider-heading *ngIf="!projectModel"
                      [content]="'Please select a model from left side' | translate"
                      [type]="projectModelHeadingType"></rappider-heading>
  </section>

  <div *ngIf="mode === ComponentMode.Read"
       [@inOutAnimation]
       class="field-wrapper">
    <nz-skeleton [nzLoading]="projectModelFieldsLoading"
                 [nzActive]="true">
      <table class="w-100 h-100">
        <tbody class="field-section"
               cdkDropList
               cdkDropListLockAxis="y"
               (cdkDropListDropped)="drop($event)">
          <tr *ngFor="let projectModelField of projectModel?.fields"
              cdkDrag
              class="row-area"
              style="height: 45px;">
            <td class="field-area">
              <div class="field">
                <!-- <ng-container *ngIf="projectModelField.isUpdatable; else notDraggable"> -->
                <i cdkDragHandle
                   [ngClass]="projectModelField.isUpdatable ? 'active-drag-handle' : 'disabled-drag-handle'"
                   [cdkDragHandleDisabled]="!projectModelField.isUpdatable"
                   class="fas fa-bars cursor-pointer"></i>
                <!-- </ng-container> -->
                <ng-template #notDraggable>
                  <i cdkNotDraggable
                     class="fas fa-bars cursor-pointer"></i>
                </ng-template>
                <div class="name-area">
                  {{ projectModelField?.name }}
                </div>
              </div>
            </td>

            <td class="type-area">
              {{ projectModelField?.isArray === true ? projectModelField?.type + ' []' : projectModelField?.type }}
            </td>

            <td>
              <div class="buttons-area">
                <div *ngIf="projectModelField?.isUpdatable"
                     class="field-action-button">
                  <rappider-button [icon]="editButtonConfig.icon"
                                   [size]="'small'"
                                   (click)="onEditFieldClick(projectModelField)"></rappider-button>
                </div>

                <div *ngIf="projectModelField.isDeletable"
                     class="field-action-button">
                  <rappider-button [icon]="deleteButtonConfig.icon"
                                   [size]="deleteButtonConfig.size"
                                   [popconfirmTitle]="deleteButtonConfig.popconfirmTitle"
                                   [popconfirmOkDanger]="deleteButtonConfig.popconfirmOkDanger"
                                   [popconfirmOkText]="deleteButtonConfig.popconfirmOkText"
                                   [emitWithoutPopconfirm]="deleteButtonConfig.emitWithoutPopconfirm"
                                   (confirm)="onDeleteProjectModelField(projectModelField)"></rappider-button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </nz-skeleton>

    <div *ngIf="projectModel"
         class="new-field-button">
      <rappider-button text="New Field"
                       [type]="newButtonType"
                       (click)="onNewFieldClick()"></rappider-button>
    </div>
  </div>

  <div *ngIf="mode === ComponentMode.Create"
       [@inOutAnimation]
       class="new-field-wrapper">
    <rappider-new-project-model-field-form [loading]="loading"
                                           [submitButtonLoading]="submitButtonLoading"
                                           (newProjectModelFieldClick)="onNewProjectModelFieldClick($event)"></rappider-new-project-model-field-form>
  </div>

  <div *ngIf="mode === ComponentMode.Update"
       [@inOutAnimation]
       class="new-field-wrapper">
    <rappider-update-project-model-field-form [projectModelField]="activeProjectModelField"
                                              [submitButtonLoading]="submitButtonLoading"
                                              (updateProjectModelField)="onUpdateProjectModelField($event)"></rappider-update-project-model-field-form>
  </div>
</div>
