import { Component, Output, EventEmitter, Input } from '@angular/core';
import { WorkflowEventPartial } from '@rappider/rappider-sdk';
import { WORKFLOW_EVENT_CREATE_OR_EDIT_CONFIG } from 'libs/shared/src/lib/configs/project/workflow';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'rappider-workflow-event-create',
  templateUrl: './workflow-event-create.component.html',
  styleUrls: ['./workflow-event-create.component.scss']
})
export class WorkflowEventCreateComponent {
  @Input() submitButtonLoading: boolean;

  @Output() createWorkflowEvent = new EventEmitter<WorkflowEventPartial>();
  @Output() eventNameChange = new EventEmitter<string>();

  WORKFLOW_EVENT_CREATE_CONFIG = cloneDeep(WORKFLOW_EVENT_CREATE_OR_EDIT_CONFIG);

  onCreateWorkflowEventFormSubmit(workflowEvent: WorkflowEventPartial) {
    this.createWorkflowEvent.emit(workflowEvent);
  }

  onFieldValueChange(field: Record<string, string>) {
    if (Object.keys(field).includes('name')) {
      this.eventNameChange.emit(field.name);
    }
  }
}
