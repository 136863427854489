import { Validators } from '@angular/forms';
import { CrudFormCheckboxListItem, CrudFormConfig, CrudViewFormItemType } from '@rappider/rappider-components/utils';

export const UI_DATA_UPDATE_FUNCTION_CREATE_FORM_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormCheckboxListItem>{
      title: 'PROJECT_MODULE.UI_DATA_EVENT_UPDATE_FUNCTION_LIST_COMPONENT.DATA_EVENT_NAME',
      fieldName: 'uiDataEventId',
      direction: 'column',
      type: CrudViewFormItemType.CheckboxList,
      isSelectAllButtonVisible: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    }
  ]
};
