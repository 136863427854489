import { UIWorkflowItemIntegrationOperatorType } from './ui-workflow-step-function-type.enum';

// eslint-disable-next-line no-shadow
export enum UIWorkflowItemTypeValues {
  PreDefinedTemplate = 'pre-defined-template',
  ConfigurationDriven = 'configuration-driven'
}

export const UIWorkflowItemIntegrationOperatorTypes: { title: string; key: string }[] = [
  {
    title: 'Merge Map',
    key: UIWorkflowItemIntegrationOperatorType.MergeMap
  },
  {
    title: 'Switch Map',
    key: UIWorkflowItemIntegrationOperatorType.SwitchMap
  },
  {
    title: 'Exhaust Map',
    key: UIWorkflowItemIntegrationOperatorType.ExhaustMap
  },
  {
    title: 'Map',
    key: UIWorkflowItemIntegrationOperatorType.Map
  },
  {
    title: 'Tap',
    key: UIWorkflowItemIntegrationOperatorType.Tap
  }
];

// eslint-disable-next-line @typescript-eslint/naming-convention
export const UIWorkflowItemTypes: { title: string; key: string }[] = [
  {
    title: 'Pre Defined Template',
    key: UIWorkflowItemTypeValues.PreDefinedTemplate
  },
  {
    title: 'Configuration Driven',
    key: UIWorkflowItemTypeValues.ConfigurationDriven
  }
];
