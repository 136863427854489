import { Injectable } from '@angular/core';
import { Category, CategoryControllerService, CategoryWithRelations } from '@rappider/rappider-sdk';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    private categoryApi: CategoryControllerService
  ) { }


  /**
   * type for Category Type
   *
   * @param {string} type
   * @returns
   * @memberof CategoryService
   */
  getCategoriesByTypeAsKeyValue(type: string) {
    /* get Categories */
    return this.categoryApi.find({
      filter: {
        where: { type: type },
        fields: { id: true, title: true }
      }
    }).pipe(
      map(categories => {
        /* set categories to local variable */
        const categoriesAsKeyValue = categories.map((category: CategoryWithRelations) => ({
          key: category.title,
          value: category.id
        }));
        /* return categoriesAsKeyValue */
        return categoriesAsKeyValue;
      })
    );
  }

  buildCategoryTreeFromCategoryList(categories: Category[]): NzTreeNodeOptions[] {
    const tree = [];
    const map = {};

    // Create first level nodes
    categories?.forEach(category => {
      const node: any = {
        key: category.id,
        ...category
      };
      if (!node.parentCategoryId) {
        // Mark nodes without ParentCategoryId as root
        tree.push(node);
      } else {
        // Mark the nodes with ParentCategoryId on the map
        if (!map[node.parentCategoryId]) {
          map[node.parentCategoryId] = [];
        }
        map[node.parentCategoryId].push({
          ...node,
          key: node.id,
        });
      }
    });

    // Add child-level nodes to parent nodes
    function addChildren(node) {
      if (map[node.id]) {
        node.children = map[node.id];
        node.children?.forEach(child => {
          addChildren(child);
        });
      } else {
        node.isLeaf = true;
      }
    }
    tree.forEach(node => addChildren(node));

    return tree;
  };
}
