import { Validators } from '@angular/forms';
import { CrudFormConfig, CrudFormSelectItem, CrudViewFormItemType } from '@rappider/rappider-components/utils';

export const UI_DATA_STORE_FIELD_CREATE_OR_EDIT_CONFIG: CrudFormConfig = {
  items: [
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewFormItemType.TextBox,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    <CrudFormSelectItem>
    {
      title: 'SHARED.TYPE',
      fieldName: 'type',
      type: CrudViewFormItemType.TextBox,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    {
      title: 'PROJECT_MODULE.UI_DATA_STORE_FIELD_LIST_COMPONENT.INITIAL_VALUE',
      fieldName: 'initialValue',
      type: CrudViewFormItemType.TextBox
    },
    {
      title: 'SHARED.DESCRIPTION',
      fieldName: 'description',
      type: CrudViewFormItemType.TextArea
    }
  ]
};
