import { PropertyFormat } from '@rappider/rappider-components/utils';

export const FORMAT_OPTIONS = [
  {
    key: 'SHARED.NO_FORMAT',
    value: null
  },
  {
    key: 'SHARED.DATE',
    value: PropertyFormat.DateTime
  },
  {
    key: 'SHARED.UUID',
    value: PropertyFormat.UUID
  }
];
