<rappider-spin [spinning]="!dataEvents?.length">
  <form nz-form
        [formGroup]="uiDataUpdateFunctionForm"
        nzLayout="vertical">

    <nz-form-item *ngIf="dataEventSelectable">
      <nz-form-label [nzLg]="6"
                     [nzXs]="24">{{ 'PROJECT_MODULE.UI_DATA_EVENT_UPDATE_FUNCTION_LIST_COMPONENT.DATA_EVENT_NAME' |
        translate }}</nz-form-label>
      <nz-form-control [nzLg]="6"
                       [nzXs]="24"
                       [nzErrorTip]="errorTemplate">
        <rappider-select formControlName="dataEventId"
                         [options]="dataEventOptions">
        </rappider-select>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzLg]="6"
                     [nzXs]="24">{{ 'SHARED.GENERATED_BY' | translate }}</nz-form-label>
      <nz-form-control [nzLg]="6"
                       [nzXs]="24"
                       [nzErrorTip]="errorTemplate">
        <rappider-radio formControlName="generatedBy"
                        [options]="generatedByOptions">
        </rappider-radio>
      </nz-form-control>
    </nz-form-item>

    <ng-container
                  *ngIf="uiDataUpdateFunctionForm.get('generatedBy').value === UIDataUpdateFunctionGeneratedBy.User; else dataMappingTemplate">
      <nz-form-item>
        <nz-form-label [nzLg]="6"
                       [nzXs]="24">{{ 'SHARED.CODE' | translate }}</nz-form-label>
        <nz-form-control [nzLg]="24"
                         [nzXs]="24"
                         [nzErrorTip]="errorTemplate">
          <rappider-code-editor formControlName="code"
                                [settings]="codemirrorSettings">
          </rappider-code-editor>
        </nz-form-control>
      </nz-form-item>
    </ng-container>

    <ng-template #dataMappingTemplate>
      <ng-container *ngIf="sourceDataSchema">
        <nz-form-item>
          <nz-form-label [nzLg]="6"
                         [nzXs]="24">{{ 'PROJECT_MODULE.UI_DATA_EVENT_UPDATE_FUNCTION_CREATE_COMPONENT.DATA_MAPPINGS' |
            translate }}</nz-form-label>
          <nz-form-control [nzLg]="24"
                           [nzXs]="24"
                           [nzErrorTip]="errorTemplate">
            <rappider-data-mappings *ngIf="targetDataSchema && sourceDataSchema"
                                    formControlName="dataMappings"
                                    [targetDataSchema]="targetDataSchema"
                                    [sourceDataSchema]="sourceDataSchema"
                                    [functions]="functions"
                                    (dataMappingAdd)="addDataMapping($event)"></rappider-data-mappings>
          </nz-form-control>
        </nz-form-item>
      </ng-container>
    </ng-template>
    <div *ngIf="getSaveButtonVisibilityState()"
         class="button-area">
      <button nz-button
              nzType="primary"
              (click)="onSubmit()"> {{ 'SHARED.SAVE' | translate }} </button>
    </div>
  </form>

  <ng-template #errorTemplate>
    {{ 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE' | translate }}
  </ng-template>
</rappider-spin>