import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProjectPackageApi } from '@rappider/api-sdk';
import { NotificationService } from '@rappider/services';
import * as ProjectPackageActions from './project-package.actions';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { ProjectPackageControllerService, ProjectPackageWithRelations } from '@rappider/rappider-sdk';

@Injectable()
export class ProjectPackageEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private projectPackageApi: ProjectPackageControllerService,
    private notificationService: NotificationService
  ) { }


  getProjectPackages$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectPackageActions.GetProjectPackages>(
      ProjectPackageActions.ActionTypes.GetProjectPackages
    ),
    withLatestFrom(
      this.store.select(state => state.activeProject.data?.id)
    ),
    mergeMap(([action, activeProjectId]) => {
      const params = {
        filter: {
          where: { projectId: activeProjectId }
        }
      };
      return this.projectPackageApi.find(params).pipe(
        mergeMap((projectPackages: ProjectPackageWithRelations[]) => [
          new ProjectPackageActions.SetProjectPackages({ projectPackages: projectPackages }),
          new ProjectPackageActions.SetLoadingState({ loading: false })
        ])
      );
    }), catchError(error => {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'PROJECT_MODULE.PROJECT_PACKAGE_CREATE_COMPONENT.PROJECT_PACKAGE_COULDNT_LOADED'
      );

      return [new ProjectPackageActions.ErrorAction({ error: error, key: 'GetProjectPackages', timestamp: Date.now() })];
    })
  ));


  createProjectPackage$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectPackageActions.CreateProjectPackage>(
      ProjectPackageActions.ActionTypes.CreateProjectPackage
    ),
    withLatestFrom(
      this.store.select(state => state.activeProject.data?.id)
    ),
    mergeMap(([action, activeProjectId]) => {
      const params = {
        body: {
          ...action.payload.projectPackage
        }
      };
      return this.projectPackageApi.create(params).pipe(
        map((projectPackage) => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFUL',
            'PROJECT_MODULE.PROJECT_PACKAGE_CREATE_COMPONENT.PROJECT_PACKAGE_CREATED'
          );
          return new ProjectPackageActions.CreateProjectPackageSuccessful({ projectPackage });

        }), catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PROJECT_MODULE.PROJECT_PACKAGE_CREATE_COMPONENT.PROJECT_PACKAGE_COULDNT_CREATED'
          );
          return [new ProjectPackageActions.ErrorAction({ error: error, key: 'CreateProjectPackage', timestamp: Date.now() })];
        })
      );
    })
  ));


  updateProjectPackage$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectPackageActions.UpdateProjectPackage>(
      ProjectPackageActions.ActionTypes.UpdateProjectPackage
    ),
    mergeMap((action) => {
      const params = action.payload;
      return this.projectPackageApi.updateById(params).pipe(
        mergeMap(() => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFUL',
            'PROJECT_MODULE.PROJECT_PACKAGE_EDIT_COMPONENT.PROJECT_PACKAGE_UPDATED'
          );
          return [new ProjectPackageActions.UpdateProjectPackageSuccessful({ id: params.id, updatedPackage: params.body }), new Navigate({ url: PATH_DEFINITIONS.PROJECTS.PROJECT_PACKAGE_LIST })];
        }),
        catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PROJECT_MODULE.PROJECT_PACKAGE_EDIT_COMPONENT.PROJECT_PACKAGE_COULDNT_UPDATED'
          );
          return [new ProjectPackageActions.ErrorAction({ error: error, key: 'UpdateProjectPackage', timestamp: Date.now() })];
        })
      );
    })
  ));


  deleteProjectPackage$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectPackageActions.DeleteProjectPackage>(
      ProjectPackageActions.ActionTypes.DeleteProjectPackage
    ),
    withLatestFrom(
      this.store.select(state => state.projectPackage?.data)
    ),
    mergeMap(([action]) => {
      const params = {
        id: action.payload.projectPackageId
      };
      return this.projectPackageApi.deleteById(params).pipe(
        map(() => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFUL',
            'PROJECT_MODULE.PROJECT_PACKAGE_LIST_COMPONENT.PROJECT_PACKAGE_DELETED'
          );
          return new ProjectPackageActions.GetProjectPackages();
        }, catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PROJECT_MODULE.PROJECT_PACKAGE_LIST_COMPONENT.PROJECT_PACKAGE_COULDNT_DELETED'
          );
          return [new ProjectPackageActions.ErrorAction({ error: error, key: 'DeleteProjectPackage', timestamp: Date.now() })];
        }))
      );
    })
  ));
}
