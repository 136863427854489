import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ProjectModelFieldEffects } from './state/project-model-field.effects';
import * as fromProjectModelFieldState from './state/project-model-field.reducer';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromProjectModelFieldState.featureKey,
      fromProjectModelFieldState.reducer,
      { initialState: fromProjectModelFieldState.initialState },
    ),
    EffectsModule.forFeature([ProjectModelFieldEffects]),
    NzCollapseModule
  ],
  declarations: [
  ]
})
export class ProjectModelFieldModule { }
