<div class="data-transformation-wrapper">
  <div class="title-toolbar-wrapper">
    <div class="title">
      <h5> {{'Data Transformation' | translate}}</h5>
    </div>
    <div class="toolbar"
         joyrideStep="dtSecondStep"
         [title]="'DTSecondStepTitle' | translate"
         [text]="'DTSecondStepContent' | translate">
      <rappider-button [text]="'Add Filter' | translate"
                       [size]="addDataTransformationItemButton.size"
                       [type]="addDataTransformationItemButton.type"
                       (click)="openAddFilterModal('filter')"></rappider-button>
      <rappider-button [text]="'Add Find' | translate"
                       [size]="addDataTransformationItemButton.size"
                       [type]="addDataTransformationItemButton.type"
                       (click)="openAddFilterModal('find')"></rappider-button>
      <rappider-button [text]="'Add Map' | translate"
                       [size]="addDataTransformationItemButton.size"
                       [type]="addDataTransformationItemButton.type"
                       (click)="openAddDataMappingModal()"></rappider-button>
      <rappider-button [text]="'Add Sort' | translate"
                       [size]="addDataTransformationItemButton.size"
                       [type]="addDataTransformationItemButton.type"
                       (click)="openAddSortModal()"></rappider-button>
    </div>
  </div>
  <div class="content"
       joyrideStep="dtFirstStep"
       [title]="'DTFirstStepTitle' | translate"
       [text]="'DTFirstStepContent' | translate">

    <rappider-list-grid [loading]="!dataTransformationItems"
                        [data]="dataTransformationItems"
                        [config]="DATA_TRANSFORMATION_LIST_CONFIG"
                        (columnActionClick)="onColumnActionClick($event)">
    </rappider-list-grid>
  </div>

  <!-- sort -->

  <rappider-modal *ngIf="isAddSortModalVisible"
                  [(visible)]="isAddSortModalVisible"
                  [title]="sortTitleTemplate"
                  [fullScreen]="true"
                  (cancelClick)="closeAddSortModal()"
                  (okClick)="newSort()">
    <rappider-sort-wrapper *ngIf="newSortProcess"
                           [tree]="tree"
                           [currentTree]="currentTree"
                           [sortStepsFlag]="sortStepsFlag"
                           [(ngModel)]="newSortProcess.config"
                           (showStepsFlag)="onShowStepsFlag($event)"
                           (operationFieldChange)="onOperationFieldChange(newSortProcess)">
    </rappider-sort-wrapper>
    <!-- addedOperationType -->
  </rappider-modal>
  <ng-template #sortTitleTemplate>
    <div class="template-title-area">
      <span>Sort</span>
      <span class="info-icon"
            nz-icon
            title="Show Help"
            nzType="info-circle"
            (click)="setStepsFlag('sort')"></span>
    </div>
  </ng-template>

  <rappider-modal *ngIf="isEditSortModalVisible"
                  [(visible)]="isEditSortModalVisible"
                  [title]="sortEditTitleTemplate"
                  [fullScreen]="true"
                  (cancelClick)="closeEditSortModal()"
                  (okClick)="editSort()">
    <rappider-sort-wrapper *ngIf="editedSortProcess"
                           [tree]="tree"
                           [sortStepsFlag]="sortStepsFlag"
                           [currentTree]="currentTree"
                           [(ngModel)]="editedSortProcess.config"
                           (showStepsFlag)="onShowStepsFlag($event)"
                           (operationFieldChange)="onOperationFieldChange(editedSortProcess)">
    </rappider-sort-wrapper>
  </rappider-modal>
  <ng-template #sortEditTitleTemplate>
    <div class="template-title-area">
      <span>Sort</span>
      <span class="info-icon"
            nz-icon
            title="Show Help"
            nzType="info-circle"
            (click)="setStepsFlag('sort')"></span>
    </div>
  </ng-template>

  <!-- sort end -->


  <!-- /******* Beginning of Filter Find ***********/ -->
  <!-- Add Filter / Find -->
  <rappider-modal *ngIf="isAddFilterModalVisible"
                  [(visible)]="isAddFilterModalVisible"
                  [title]="filterFindTitleTemplate"
                  [fullScreen]="true"
                  className="filter-find-modal"
                  (cancelClick)="closeAddFilterModal()"
                  (okClick)="newFilter()">
    <rappider-filter-wrapper *ngIf="newFilterProcess"
                             [(ngModel)]="newFilterProcess.config"
                             [currentTree]="currentTree"
                             [tree]="tree"
                             [jsonSchema]="schema"
                             [functions]="functions.filter"
                             [filterFindStepsFlag]="filterFindStepsFlag"
                             (showStepsFlag)="onShowStepsFlag($event)"
                             (operationFieldChange)="onOperationFieldChange(newFilterProcess)">
    </rappider-filter-wrapper>
  </rappider-modal>
  <ng-template #filterFindTitleTemplate>
    <div class="template-title-area">
      <span>{{addedOperationType}}</span>
      <span class="info-icon"
            nz-icon
            title="Show Help"
            nzType="info-circle"
            (click)="setStepsFlag('filter-find')"></span>
    </div>
  </ng-template>

  <!-- Edit Filter / Find -->
  <rappider-modal *ngIf="isEditFilterModalVisible"
                  [(visible)]="isEditFilterModalVisible"
                  [title]="filterFindEditTitleTemplate"
                  [fullScreen]="true"
                  className="filter-find-modal"
                  (cancelClick)="closeEditFilterModal()"
                  (okClick)="saveFilter()">
    <rappider-filter-wrapper *ngIf="isEditFilterModalVisible"
                             [(ngModel)]="editedFilterProcess.config"
                             [currentTree]="currentTree"
                             [tree]="tree"
                             [jsonSchema]="schema"
                             [functions]="functions.filter"
                             [filterFindStepsFlag]="filterFindStepsFlag"
                             (showStepsFlag)="onShowStepsFlag($event)"
                             (operationFieldChange)="onOperationFieldChange(editedFilterProcess)">
    </rappider-filter-wrapper>
  </rappider-modal>
  <ng-template #filterFindEditTitleTemplate>
    <div class="template-title-area">
      <span>{{editedOperationType}}</span>
      <span class="info-icon"
            nz-icon
            nzType="info-circle"
            (click)="setStepsFlag('filter-find')"></span>
    </div>
  </ng-template>
  <!-- /******* End Of Filter Find ***********/ -->

  <!-- /******* Beginning of Map ***********/ -->
  <!-- Edit Map -->
  <rappider-modal *ngIf="isEditDataMappingModalVisible"
                  [(visible)]="isEditDataMappingModalVisible"
                  [title]="mapEditTitleTemplate"
                  [fullScreen]="true"
                  [className]="'data-mapping-wrapper-modal'"
                  (cancelClick)="closeEditDataMappingModal()"
                  (okClick)="editDataMapping()">
    <rappider-data-mapping-wrapper *ngIf="isEditDataMappingModalVisible && editedDataMappingProcess && schema"
                                   [(ngModel)]="editedDataMappingProcess.config"
                                   [targetTree]="targetTree"
                                   [sourceSchema]="sourceSchema"
                                   [targetSchema]="targetSchema"
                                   [mapStepsFlag]="mapStepsFlag"
                                   (showStepsFlag)="onShowStepsFlag($event)"
                                   (dataMappingOutputChange)="onDataMappingOutputChange($event)"
                                   (ngModelChange)="onDataMappingEdit()">
    </rappider-data-mapping-wrapper>
  </rappider-modal>
  <ng-template #mapEditTitleTemplate>
    <div class="template-title-area">
      <span>Edit Data Mapping</span>
      <span class="info-icon"
            nz-icon
            title="Show Help"
            nzType="info-circle"
            (click)="setStepsFlag('map')"></span>
    </div>
  </ng-template>

  <!-- Add Map -->
  <rappider-modal *ngIf="isAddDataMappingModalVisible"
                  [(visible)]="isAddDataMappingModalVisible"
                  [title]="mapTitleTemplate"
                  [fullScreen]="true"
                  [className]="'data-mapping-wrapper-modal'"
                  (cancelClick)="closeAddDataMapping()"
                  (okClick)="addDataMapping()">
    <rappider-data-mapping-wrapper *ngIf="isAddDataMappingModalVisible && addedDataMappingProcess"
                                   [(ngModel)]="addedDataMappingProcess.config"
                                   [targetTree]="targetTree"
                                   [sourceSchema]="sourceSchema"
                                   [targetSchema]="targetSchema"
                                   [mapStepsFlag]="mapStepsFlag"
                                   (showStepsFlag)="onShowStepsFlag($event)"
                                   (dataMappingOutputChange)="onDataMappingOutputChange($event)"
                                   (ngModelChange)="onDataMappingAdd()">
    </rappider-data-mapping-wrapper>
  </rappider-modal>
  <ng-template #mapTitleTemplate>
    <div class="template-title-area">
      <span>Add Data Mapping</span>
      <span class="info-icon"
            nz-icon
            title="Show Help"
            nzType="info-circle"
            (click)="setStepsFlag('map')"></span>
    </div>
  </ng-template>

  <!-- /******* End Of Map ***********/ -->
</div>

<!-- this components used for getting NzTreeNode[], it didn't displayed in page.-->
<rappider-schema-tree-select #treeSelectComponent
                             [tree]="tree"
                             [ngStyle]="{'display':'none'}"
                             [multipleSelect]="false">
</rappider-schema-tree-select>

<!-- this component used for getting NzTreeNode[], it didn't displayed in page.-->
<rappider-schema-tree-select #currentTreeSelectComponent
                             [tree]="currentTree"
                             [ngStyle]="{'display':'none'}"
                             [multipleSelect]="false">
</rappider-schema-tree-select>
