import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationListItem } from './utils/notification-list-item.interface';
import { NotificationStatus } from './utils/notification-status.enum';
import { NotificationAnimation } from './utils/notification-animation.inteface';

@Component({
  selector: 'rappider-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class RappiderNotificationComponent {
  @Input() unreadNotificationCount: number;
  @Input() maxVisibleNotifications: number;
  @Input() notificationList: NotificationListItem[] = [];
  @Input() emptyNotificationAnimation: NotificationAnimation;

  @Output() selectNotification = new EventEmitter<NotificationListItem>();

  NotificationStatus = NotificationStatus;
  notificationVisible: boolean;

  onSelectNotification(notification) {
    this.selectNotification.emit(notification);
    this.notificationVisible = false;
  }
}
