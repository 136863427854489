<rappider-internal-title-toolbar [mainTitle]="mainTitle"
                                 [options]="title"
                                 [displayToolbar]="displayToolbar"
                                 [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-internal-title-toolbar>

<rappider-spin [spinning]="!layout">
  <section>
    <rappider-crud-view-edit-form [config]="PAGE_LAYOUT_EDIT_FORM_CONFIG"
                                  [data]="layout"
                                  (fieldValueChange)="onFieldValueChange($event)"
                                  [submitButtonLoading]="loading"
                                  (formSubmit)="editLayout($event)">
    </rappider-crud-view-edit-form>
  </section>
</rappider-spin>
