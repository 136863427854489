import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectInterface } from '@rappider/api-sdk';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { PAGE_DEFINITIONS, PATH_DEFINITIONS, QUERY_PARAM_DEFINITIONS } from '@rappider/shared/definitions';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { PROJECT_HOME_FEATURES_CONFIG } from '@rappider/shared/configs';
import { Person, Tenant } from '@rappider/rappider-sdk';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { ButtonComponentConfig } from '@rappider/rappider-components/utils';

@Component({
  selector: 'rappider-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit, OnDestroy {

  project: ProjectInterface;
  tenant: Tenant;
  projectId: string;
  mainTitle = 'PROJECT_MODULE.PROJECT_DETAIL_COMPONENT.PROJECT_DETAIL';
  title: string | string[];
  activePerson: Person;
  projectMembersWithRoles: any[];
  canSeeAllSubFeature = true;

  QUERY_PARAM_DEFINITIONS = QUERY_PARAM_DEFINITIONS;

  subscriptions: Subscription[] = [];
  features = PROJECT_HOME_FEATURES_CONFIG;

  editButton: ButtonComponentConfig = {
    text: 'Edit Project'
  };

  projectListButton: ButtonComponentConfig = {
    text: 'Change Project'
  };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<any>
  ) { }

  ngOnInit() {
    /* get project id by url and set into projectId variable */
    this.projectId = this.getProjectIdByUrl();
    /* if there is projectId id, get project details by projectId */
    if (this.projectId) {
      this.subscribeToData();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(subscription => subscription.unsubscribe());
    this.setCursorTypeToAuto();
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToRouterEvents(),
      this.subscribeToActiveProject(),
      this.subscribeToTenant(),
      this.subscribeToActivePerson(),
      this.subscribeToProjectMembers()
    ];
  }

  // #region SUBSCRIPTIONS
  subscribeToRouterEvents() {
    return this.router.events.subscribe((e: RouterEvent) => {
      this.handleLoadingOnNavigation(e);
    });
  }

  subscribeToActivePerson() {
    return this.store.select(state => state?.auth?.activePerson).subscribe((activePerson: Person) => {
      if (activePerson) {
        this.activePerson = activePerson;
      }
    });
  }

  subscribeToProjectMembers() {
    return this.store.select(state => state?.projectMember?.data).subscribe((projectMembers: any[]) => {
      if (projectMembers?.length > 0) {
        this.projectMembersWithRoles = projectMembers;
        if (this.activePerson) {
          this.checkVisibility();
        }
      }
    });
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe(activeProject => {
      this.project = activeProject;
      if (this.project) {
        this.title = [this.project.name, PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_DETAIL.PAGE_TITLE];
      }
    });
  }

  subscribeToTenant() {
    return this.store.select(state => state.activeProject.data?.tenant).subscribe(tenant => {
      this.tenant = tenant;
    });
  }
  // #endregion SUBSCRIPTIONS

  private handleLoadingOnNavigation(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      document.body.style.cursor = 'wait';
    }
    if (event instanceof NavigationEnd) {
      this.setCursorTypeToAuto();
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.setCursorTypeToAuto();
    }
    if (event instanceof NavigationError) {
      this.setCursorTypeToAuto();
    }
  }
  private setCursorTypeToAuto() {
    document.body.style.cursor = 'auto';
  }

  checkVisibility() {
    const activePersonWithRole = this.projectMembersWithRoles.find(projectMemberWithRole => projectMemberWithRole?.id === this.activePerson?.id);
    if (activePersonWithRole?.authorities?.length > 0) {
      this.canSeeAllSubFeature = true;
    } else {
      this.canSeeAllSubFeature = false;
    }
  }

  getProjectIdByUrl(): string {
    return this.activatedRoute.snapshot.params.projectId;
  }

  navigateEditProject() {
    this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_EDIT_PATH}/${this.projectId}` }));
  }

  navigateToProjectList() {
    this.store.dispatch(new Navigate({ url: PATH_DEFINITIONS.PROJECTS.PROJECT_LIST_PATH }));
  }

  navigateToLivePreview() {
    this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_LIVE_PREVIEW_PATH}/${this.projectId}` }));
  }
}
