/* eslint-disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ComponentDefinition } from '../../models/ComponentDefinition';
import { SocketConnection } from '../../sockets/socket.connections';
import { ComponentSample } from '../../models/ComponentSample';
import { Category } from '../../models/Category';
import { SlotDefinition } from '../../models/SlotDefinition';
import { ComponentInputDefinition } from '../../models/ComponentInputDefinition';
import { ComponentOutputDefinition } from '../../models/ComponentOutputDefinition';
import { ComponentDefinitionSubCategory } from '../../models/ComponentDefinitionSubCategory';
import { ComponentStyleSheetDefinition } from '../../models/ComponentStyleSheetDefinition';

/**
 * Api services for the `ComponentDefinition` model.
 */
@Injectable()
export class ComponentDefinitionApi extends BaseLoopBackApi {
  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SocketConnection) protected connection: SocketConnection,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http, connection, models, auth, errorHandler);
  }

  /**
   * Find a related item by id for samples.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {any} fk Foreign key for samples
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public findByIdSamples(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() + '/component-definitions/:id/samples/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Delete a related item by id for samples.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {any} fk Foreign key for samples
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdSamples(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() + '/component-definitions/:id/samples/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Update a related item by id for samples.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {any} fk Foreign key for samples
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public updateByIdSamples(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() + '/component-definitions/:id/samples/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Fetches belongsTo relation mainCategory.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {boolean} refresh
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public getMainCategory(
    id: any,
    refresh: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() + '/component-definitions/:id/main-category';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null)
      _urlParams.refresh = refresh;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Find a related item by id for slotDefinitions.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {any} fk Foreign key for slotDefinitions
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public findByIdSlotDefinitions(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/slot-definitions/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Delete a related item by id for slotDefinitions.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {any} fk Foreign key for slotDefinitions
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdSlotDefinitions(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/slot-definitions/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Update a related item by id for slotDefinitions.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {any} fk Foreign key for slotDefinitions
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public updateByIdSlotDefinitions(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/slot-definitions/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Find a related item by id for inputDefinitions.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {any} fk Foreign key for inputDefinitions
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public findByIdInputDefinitions(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/input-definitions/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Delete a related item by id for inputDefinitions.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {any} fk Foreign key for inputDefinitions
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdInputDefinitions(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/input-definitions/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Update a related item by id for inputDefinitions.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {any} fk Foreign key for inputDefinitions
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public updateByIdInputDefinitions(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/input-definitions/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Find a related item by id for outputDefinitions.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {any} fk Foreign key for outputDefinitions
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public findByIdOutputDefinitions(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/output-definitions/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Delete a related item by id for outputDefinitions.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {any} fk Foreign key for outputDefinitions
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdOutputDefinitions(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/output-definitions/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Update a related item by id for outputDefinitions.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {any} fk Foreign key for outputDefinitions
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public updateByIdOutputDefinitions(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/output-definitions/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Find a related item by id for subCategories.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {any} fk Foreign key for subCategories
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public findByIdSubCategories(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/sub-categories/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Delete a related item by id for subCategories.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {any} fk Foreign key for subCategories
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdSubCategories(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/sub-categories/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Update a related item by id for subCategories.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {any} fk Foreign key for subCategories
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public updateByIdSubCategories(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/sub-categories/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Add a related item by id for subCategories.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {any} fk Foreign key for subCategories
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public linkSubCategories(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/sub-categories/rel/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Remove the subCategories relation to an item by id.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {any} fk Foreign key for subCategories
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public unlinkSubCategories(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/sub-categories/rel/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Check the existence of subCategories relation to an item by id.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {any} fk Foreign key for subCategories
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public existsSubCategories(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'HEAD';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/sub-categories/rel/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Find a related item by id for styleSheetDefinitions.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {any} fk Foreign key for styleSheetDefinitions
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public findByIdStyleSheetDefinitions(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/style-sheet-definitions/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Delete a related item by id for styleSheetDefinitions.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {any} fk Foreign key for styleSheetDefinitions
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdStyleSheetDefinitions(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/style-sheet-definitions/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Update a related item by id for styleSheetDefinitions.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {any} fk Foreign key for styleSheetDefinitions
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public updateByIdStyleSheetDefinitions(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/style-sheet-definitions/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Queries samples of ComponentDefinition.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {object} filter
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public getSamples(
    id: any,
    filter: LoopBackFilter = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() + '/component-definitions/:id/samples';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null)
      _urlParams.filter = filter;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in samples of this model.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public createSamples(
    id: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() + '/component-definitions/:id/samples';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Deletes all samples of this model.
   *
   * @param {any} id ComponentDefinition id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteSamples(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() + '/component-definitions/:id/samples';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Counts samples of ComponentDefinition.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` -
   */
  public countSamples(
    id: any,
    where: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() + '/component-definitions/:id/samples/count';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null)
      _urlParams.where = where;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Queries slotDefinitions of ComponentDefinition.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {object} filter
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public getSlotDefinitions(
    id: any,
    filter: LoopBackFilter = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() + '/component-definitions/:id/slot-definitions';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null)
      _urlParams.filter = filter;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in slotDefinitions of this model.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public createSlotDefinitions(
    id: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() + '/component-definitions/:id/slot-definitions';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Deletes all slotDefinitions of this model.
   *
   * @param {any} id ComponentDefinition id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteSlotDefinitions(
    id: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() + '/component-definitions/:id/slot-definitions';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Counts slotDefinitions of ComponentDefinition.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` -
   */
  public countSlotDefinitions(
    id: any,
    where: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/slot-definitions/count';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null)
      _urlParams.where = where;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Queries inputDefinitions of ComponentDefinition.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {object} filter
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public getInputDefinitions(
    id: any,
    filter: LoopBackFilter = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() + '/component-definitions/:id/input-definitions';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null)
      _urlParams.filter = filter;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in inputDefinitions of this model.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public createInputDefinitions(
    id: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() + '/component-definitions/:id/input-definitions';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Deletes all inputDefinitions of this model.
   *
   * @param {any} id ComponentDefinition id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteInputDefinitions(
    id: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() + '/component-definitions/:id/input-definitions';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Counts inputDefinitions of ComponentDefinition.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` -
   */
  public countInputDefinitions(
    id: any,
    where: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/input-definitions/count';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null)
      _urlParams.where = where;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Queries outputDefinitions of ComponentDefinition.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {object} filter
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public getOutputDefinitions(
    id: any,
    filter: LoopBackFilter = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/output-definitions';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null)
      _urlParams.filter = filter;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in outputDefinitions of this model.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public createOutputDefinitions(
    id: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/output-definitions';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Deletes all outputDefinitions of this model.
   *
   * @param {any} id ComponentDefinition id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteOutputDefinitions(
    id: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/output-definitions';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Counts outputDefinitions of ComponentDefinition.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` -
   */
  public countOutputDefinitions(
    id: any,
    where: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/output-definitions/count';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null)
      _urlParams.where = where;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Queries subCategories of ComponentDefinition.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {object} filter
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public getSubCategories(
    id: any,
    filter: LoopBackFilter = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() + '/component-definitions/:id/sub-categories';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null)
      _urlParams.filter = filter;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in subCategories of this model.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public createSubCategories(
    id: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() + '/component-definitions/:id/sub-categories';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Deletes all subCategories of this model.
   *
   * @param {any} id ComponentDefinition id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteSubCategories(
    id: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() + '/component-definitions/:id/sub-categories';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Counts subCategories of ComponentDefinition.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` -
   */
  public countSubCategories(
    id: any,
    where: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/sub-categories/count';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null)
      _urlParams.where = where;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Queries styleSheetDefinitions of ComponentDefinition.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {object} filter
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public getStyleSheetDefinitions(
    id: any,
    filter: LoopBackFilter = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/style-sheet-definitions';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null)
      _urlParams.filter = filter;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in styleSheetDefinitions of this model.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public createStyleSheetDefinitions(
    id: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/style-sheet-definitions';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Deletes all styleSheetDefinitions of this model.
   *
   * @param {any} id ComponentDefinition id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteStyleSheetDefinitions(
    id: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/style-sheet-definitions';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Counts styleSheetDefinitions of ComponentDefinition.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` -
   */
  public countStyleSheetDefinitions(
    id: any,
    where: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/style-sheet-definitions/count';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null)
      _urlParams.where = where;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Patch an existing model instance or insert a new one into the data source.
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - Model instance data
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public patchOrCreate(
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PATCH';
    let _url: string = LoopBackConfig.getPath() + '/component-definitions';
    let _routeParams: any = {};
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - An object of model property name/value pairs
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public patchAttributes(
    id: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PATCH';
    let _url: string = LoopBackConfig.getPath() + '/component-definitions/:id';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * <em>
   * (The remote method definition does not provide any description.)
   * </em>
   *
   * @param {string} id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public getByIdWithDetails(
    id: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/get-by-id-with-details/:id';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * <em>
   * (The remote method definition does not provide any description.)
   * </em>
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public getAllWithDetails(customHeaders?: Function): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() + '/component-definitions/get-all-with-details';
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Fetches belongsTo relation componentDefinition.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {any} nk Foreign key for samples.
   *
   * @param {boolean} refresh
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public getSamplesComponentDefinition(
    id: any,
    nk: any,
    refresh: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/samples/:nk/component-definition';
    let _routeParams: any = {
      id: id,
      nk: nk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null)
      _urlParams.refresh = refresh;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Fetches belongsTo relation componentDefinition.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {any} nk Foreign key for slotDefinitions.
   *
   * @param {boolean} refresh
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public getSlotDefinitionsComponentDefinition(
    id: any,
    nk: any,
    refresh: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/slot-definitions/:nk/component-definition';
    let _routeParams: any = {
      id: id,
      nk: nk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null)
      _urlParams.refresh = refresh;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Fetches belongsTo relation componentDefinition.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {any} nk Foreign key for inputDefinitions.
   *
   * @param {boolean} refresh
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public getInputDefinitionsComponentDefinition(
    id: any,
    nk: any,
    refresh: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/input-definitions/:nk/component-definition';
    let _routeParams: any = {
      id: id,
      nk: nk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null)
      _urlParams.refresh = refresh;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Fetches belongsTo relation componentDefinition.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {any} nk Foreign key for outputDefinitions.
   *
   * @param {boolean} refresh
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public getOutputDefinitionsComponentDefinition(
    id: any,
    nk: any,
    refresh: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/output-definitions/:nk/component-definition';
    let _routeParams: any = {
      id: id,
      nk: nk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null)
      _urlParams.refresh = refresh;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Fetches belongsTo relation fieldType.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {any} nk Foreign key for outputDefinitions.
   *
   * @param {boolean} refresh
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public getOutputDefinitionsFieldType(
    id: any,
    nk: any,
    refresh: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/output-definitions/:nk/field-type';
    let _routeParams: any = {
      id: id,
      nk: nk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null)
      _urlParams.refresh = refresh;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in samples of this model.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public createManySamples(
    id: any,
    data: any[] = [],
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() + '/component-definitions/:id/samples';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in slotDefinitions of this model.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public createManySlotDefinitions(
    id: any,
    data: any[] = [],
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() + '/component-definitions/:id/slot-definitions';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in inputDefinitions of this model.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public createManyInputDefinitions(
    id: any,
    data: any[] = [],
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() + '/component-definitions/:id/input-definitions';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in outputDefinitions of this model.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public createManyOutputDefinitions(
    id: any,
    data: any[] = [],
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/output-definitions';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in subCategories of this model.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public createManySubCategories(
    id: any,
    data: any[] = [],
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() + '/component-definitions/:id/sub-categories';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in styleSheetDefinitions of this model.
   *
   * @param {any} id ComponentDefinition id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentDefinition` object.)
   * </em>
   */
  public createManyStyleSheetDefinitions(
    id: any,
    data: any[] = [],
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-definitions/:id/style-sheet-definitions';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `ComponentDefinition`.
   */
  public getModelName() {
    return 'ComponentDefinition';
  }
}
