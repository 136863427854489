import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { JsonSchema } from '../../../shared';
import * as jsf from 'json-schema-faker';
import { JoyrideService } from 'ngx-joyride';
@Component({
  selector: 'rappider-filter-wrapper',
  templateUrl: './filter-wrapper.component.html',
  styleUrls: ['./filter-wrapper.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => FilterWrapperComponent),
      multi: true
    }
  ]
})
export class FilterWrapperComponent implements OnChanges, ControlValueAccessor {

  @Input() tree;
  @Input() currentTree;
  @Input() jsonSchema: any;
  @Input() functions;
  @Input() filterFindStepsFlag: boolean;

  @Output() operationFieldChange = new EventEmitter();
  @Output() showStepsFlag = new EventEmitter<{ flag: boolean; key: string }>();

  operationField: string;
  filterOperationFlag = false;

  _value;

  title: string;
  isChangeOperationModalFieldVisible = false;
  localOperationField;
  jsonSchemaCollapsed = false;
  jsonSampleByJsonSchema: string;

  exampleJsonOptions = {
    theme: 'vs-default',
    language: 'json',
    minimap: { autohide: true, enabled: false },
    lineNumbers: { renderType: 0 },
  };

  constructor(private readonly joyrideService: JoyrideService) { }

  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
    this.onChange(this._value);
    this.onTouched();
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value): void {
    this._value = value;
    this.setOperatingField();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.process) {
      this.setOperatingField();
    }
    if (changes.jsonSchema && this.jsonSchema) {
      this.setJsonSample();
    }
    if (changes.filterFindStepsFlag) {
      this.showFilterSteps();
    }
  }

  showFilterSteps() {
    if (this.filterFindStepsFlag) {
      this.joyrideService.startTour(
        { steps: ['filterFirstStep'] }
      );
    }
  }

  onDone() {
    this.filterOperationFlag = true;
  }

  onShowStepsFlag(value: { flag: boolean; key: string }) {
    if (value.key === 'filter-find') {
      this.filterOperationFlag = value.flag;
    }
    if (!this.filterOperationFlag) {
      this.filterFindStepsFlag = false;
      this.showStepsFlag.emit({ flag: this.filterFindStepsFlag, key: 'filter-find' });
    }
  }

  setJsonSample() {
    try {
      const jsonSample = jsf.JSONSchemaFaker.generate(this.jsonSchema);
      this.jsonSampleByJsonSchema = JSON.stringify(jsonSample);
    } catch (e) {
      this.jsonSampleByJsonSchema = null;
      throw e;
    }

  }

  setOperatingField() {
    this.operationField = this.value?.operationField?.map(item => item.name)?.join('.');
    if (this.operationField) {
      this.title = `You are operating in ${this.operationField}`;
    } else {
      this.title = 'You are operating in root';
    }
  }

  onChanges() {
    this.value = { ...this.value };
  }

  onChangeOperationButtonClick() {
    this.isChangeOperationModalFieldVisible = true;
  }

  closeChangeOperationFieldModal() {
    this.localOperationField = null;
    this.isChangeOperationModalFieldVisible = false;
  }

  changeOperationField() {
    this.value.operationField = this.localOperationField;
    this.localOperationField = null;
    this.value.operation = {
      logic: null,
      operations: []
    };
    this.setOperatingField();
    this.isChangeOperationModalFieldVisible = false;
  }

  onOperationFieldChange() {
    this.value.operation = {
      logic: null,
      operations: []
    };
    this.operationFieldChange.emit();
  }
}
