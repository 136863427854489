import { ActionBehavior, ButtonType, CrudTableViewConfig, CrudViewColumnType, HorizontalPosition } from '@rappider/rappider-components/utils';
import { PATH_DEFINITIONS } from '../../../definitions/path-definition';
import { POP_CONFIRM_DELETE_ACTION } from '../../pop-confirm-button/pop-confirm-button-config';

export const PROJECT_DOCUMENT_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'name',
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'Title',
      fieldName: 'title',
      type: CrudViewColumnType.Text
    }
  ],
  listActions: [
    {
      text: 'PROJECT_MODULE.PROJECT_DOCUMENT_MODULE.ADD_PROJECT_DOCUMENT',
      buttonType: ButtonType.Default,
      icon: {
        name: 'fas fa-plus'
      },
      behavior: ActionBehavior.Route,
      redirectUrl: PATH_DEFINITIONS.PROJECTS.PROJECT_DOCUMENT_CREATE
    }
  ],
  itemActions: [
    {
      text: 'Preview',
      name: 'preview',
      behavior: ActionBehavior.Emit,
      icon: { name: '	far fa-eye' }
    },
    {
      text: POP_CONFIRM_DELETE_ACTION.text,
      name: POP_CONFIRM_DELETE_ACTION.name,
      popconfirmTitle: POP_CONFIRM_DELETE_ACTION.popconfirmTitle,
      emitWithoutPopconfirm: POP_CONFIRM_DELETE_ACTION.emitWithoutPopconfirm,
      behavior: POP_CONFIRM_DELETE_ACTION.behavior,
      icon: POP_CONFIRM_DELETE_ACTION.icon,
      popConfirmCancelText: POP_CONFIRM_DELETE_ACTION.popConfirmCancelText,
      popConfirmOkText: POP_CONFIRM_DELETE_ACTION.popConfirmOkText,
      popConfirmOkDanger: POP_CONFIRM_DELETE_ACTION.popConfirmOkDanger,
    }
  ],
};
