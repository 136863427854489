export enum CustomEndpointListFieldName {
  Name = 'name',
  Description = 'description',
  Method = 'method',
  Path = 'path',
  Code = 'code',
  CreateCustomEndpoint = 'createCustomEndpoint',
  DeleteCustomEndpoint = 'deleteCustomEndpoint',
  EditCustomEndpoint = 'editCustomEndpoint',
}
