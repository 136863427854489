/* eslint-disable no-shadow */
import { Action } from '@ngrx/store';
import { Project } from '@rappider/rappider-sdk';

export enum ActionTypes {
  GetActiveProject = '[ActiveProject] GetActiveProject',
  GetActiveProjectFailure = '[ActiveProject] GetActiveProjectFailure',
  SetActiveProject = '[ActiveProject] SetActiveProject',
  ChangeActiveProject = '[ActiveProject] ChangeActiveProject',
  ChangeActiveProjectVerified = '[ActiveProject] ChangeActiveProjectVerified',
  ActiveProjectError = '[ActiveProject] ActiveProjectError',
  ClearActiveProject = '[ActiveProject] ClearActiveProject',
  GetRootFolder = '[ActiveProject] GetRootFolder',
  SetRootFolder = '[ActiveProject] SetRootFolder',
}

export class GetActiveProject implements Action {
  readonly type = ActionTypes.GetActiveProject;
  constructor(public payload: { projectId: string; navigateToProjectDetail?: boolean; navigateAIAssistantPage?: boolean }) { }
}

export class GetActiveProjectFailure implements Action {
  readonly type = ActionTypes.GetActiveProjectFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class SetActiveProject implements Action {
  readonly type = ActionTypes.SetActiveProject;
  constructor(public payload: { project: Project }) { }
}

export class ClearActiveProject implements Action {
  readonly type = ActionTypes.ClearActiveProject;
}

export class ChangeActiveProject implements Action {
  readonly type = ActionTypes.ChangeActiveProject;
  constructor(public payload: { project: Project }) { }
}

export class ChangeActiveProjectVerified implements Action {
  readonly type = ActionTypes.ChangeActiveProjectVerified;
  constructor(public payload: { project: Project; navigateToProjectDetail?: boolean; navigateToAIAssistantPage?: boolean }) { }
}

export class ActiveProjectError implements Action {
  readonly type = ActionTypes.ActiveProjectError;
  constructor(public payload: { errorOn: string; error: any }) { }
}

export class GetRootFolder implements Action {
  readonly type = ActionTypes.GetRootFolder;
}

export class SetRootFolder implements Action {
  readonly type = ActionTypes.SetRootFolder;
  constructor(public payload: { rootFolder: any }) { }
}

export type Actions =
  GetActiveProject
  | GetActiveProjectFailure
  | SetActiveProject
  | ChangeActiveProject
  | ChangeActiveProjectVerified
  | ActiveProjectError
  | ClearActiveProject
  | GetRootFolder
  | SetRootFolder
  ;
