/* eslint-disable no-shadow */
import { KeyValue } from '@angular/common';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  Navigate = '[Router] Navigate',
  NavigateFailure = '[Router] NavigateFailure',
  RouteToUrl = '[Router] RouteToUrl',
  AddQueryParam = '[Router] AddQueryParam',
  RemoveQueryParam = '[Router] RemoveQueryParam',
  ClearQueryParams = '[Router] ClearQueryParams',
}

export class Navigate implements Action {
  readonly type = ActionTypes.Navigate;
  constructor(public payload: { url: string }) { }
}

export class NavigateFailure implements Action {
  readonly type = ActionTypes.NavigateFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class RouteToUrl implements Action {
  readonly type = ActionTypes.RouteToUrl;
  constructor(public payload: { url: string }) { }
}

export class AddQueryParam implements Action {
  readonly type = ActionTypes.AddQueryParam;
  constructor(public payload: KeyValue<string, string>) { }
}

export class RemoveQueryParam implements Action {
  readonly type = ActionTypes.RemoveQueryParam;
  constructor(public payload: { key: string }) { }
}

export class ClearQueryParams implements Action {
  readonly type = ActionTypes.ClearQueryParams;
}

export type Actions =
  Navigate
  | NavigateFailure
  | RouteToUrl
  | AddQueryParam
  | RemoveQueryParam
  | ClearQueryParams
  ;

