<rappider-internal-title-toolbar [mainTitle]="mainTitle"
                                 [options]="title"
                                 [displayToolbar]="displayToolbar"
                                 [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-internal-title-toolbar>

<section>
  <rappider-crud-view-edit-form [config]="PAGE_LAYOUT_CREATE_FORM_CONFIG"
                                [data]="layoutCreateData"
                                (fieldValueChange)="onCreateLayoutFormValueChange($event)"
                                [submitButtonLoading]="loading"
                                (formSubmit)="createLayout($event)">
  </rappider-crud-view-edit-form>
</section>
