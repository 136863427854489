import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CommentControllerService } from '@rappider/rappider-sdk';

import * as AdminCommentActions from './admin-comment.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { NotificationService } from 'libs/components/src/lib/services';

import * as PersonActions from '../person-state/person.actions';

@Injectable()
export class AdminCommentEffects {
  constructor(
    private actions$: Actions,
    private commentApi: CommentControllerService,
    private notificationService: NotificationService
  ) { }

  getComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AdminCommentActions.GetAdminComments,
        PersonActions.InitAdminDashboard
      ),
      switchMap(() => this.commentApi.findByAdmin().pipe(
        map((comments) => AdminCommentActions.GetAdminCommentsSuccessful({ comments })),
        catchError((error) =>
          [
            AdminCommentActions.GetAdminCommentsFailure({ error, key: 'GetComments', date: Date.now() })
          ]
        )
      ))
    )
  );

  deleteComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AdminCommentActions.DeleteAdminComment
      ),
      switchMap((action) => this.commentApi.deleteById({ id: action.commentId }).pipe(
        map(() => {
          this.notificationService.createNotification(
            'success',
            'Success',
            'Comment Deleted Successfully'
          );
          return AdminCommentActions.DeleteAdminCommentSuccessful({ commentId: action.commentId });
        }),
        catchError((error) => {
          this.notificationService.createNotification(
            'error',
            'Error',
            'Comment Could not Deleted'
          );
          return [
            AdminCommentActions.DeleteAdminCommentFailure({ error, key: 'DeleteComment' })
          ];
        }
        )
      ))
    )
  );

  updateComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AdminCommentActions.UpdateAdminComment
      ),
      switchMap((action) => this.commentApi.updateById({ id: action.commentId, body: action.comment }).pipe(
        map((response) => {
          this.notificationService.createNotification(
            'success',
            'Success',
            'Comment Updated Successfully'
          );
          return AdminCommentActions.UpdateAdminCommentSuccessful({ comment: response });
        }),
        catchError((error) => {
          this.notificationService.createNotification(
            'error',
            'Error',
            'Comment Could not Updated'
          );
          return [
            AdminCommentActions.UpdateAdminCommentFailure({ error, key: 'UpdateComment' })
          ];
        }
        )
      ))
    )
  );
}
