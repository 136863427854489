import { createAction, props } from '@ngrx/store';
import { Person } from '@rappider/rappider-sdk';
import { StateError } from 'libs/shared/src/lib/interfaces/state-error.interface';

/* action types */
export enum ActionTypes {
  InitAdminDashboard = '[AdminDashboard] InitAdminDashboard',
  GetPerson = '[Person] GetPerson',
  GetPeopleByAdmin = '[Person] GetPeopleByAdmin',
  GetPeopleByAdminSuccessful = '[Person] GetPeopleByAdminSuccessful',
  GetPersonFailure = '[Person] GetPersonFailure',
  ChangeRolesByAdmin = '[Person] ChangeRolesByAdmin',
  ChangeRolesByAdminSuccessful = '[Person] ChangeRolesByAdminSuccessful',
  ChangeRolesByAdminFailure = '[Person] ChangeRolesByAdminFailure',
  ChangePersonModalVisibility = '[Person] ChangePersonModalVisibility',
  GetUpdatedPersonSuccessful = '[Person] GetUpdatedPersonSuccessful',
  GetPeopleCount = '[Person] GetPeopleCount',
  GetPeopleCountSuccessful = '[Person] GetPeopleCountSuccessful',
  GetPeopleCountFailure = '[Person] GetPeopleCountFailure',
}

export const InitAdminDashboard = createAction(
  ActionTypes.InitAdminDashboard,
);

/* get */
export const GetPerson = createAction(
  ActionTypes.GetPerson,
);

export const GetPeopleByAdmin = createAction(
  ActionTypes.GetPeopleByAdmin,
  props<{ pageIndex: number; pageSize: number; searchText?: string }>()
);

export const GetUpdatedPersonSuccessful = createAction(
  ActionTypes.GetUpdatedPersonSuccessful,
  props<{ updatedPerson: Person }>()
);

export const GetPeopleByAdminSuccessful = createAction(
  ActionTypes.GetPeopleByAdminSuccessful,
  props<{ payload: { people: Person[] } }>()
);

export const GetPersonFailure = createAction(
  ActionTypes.GetPersonFailure,
  props<StateError>()
);

/* change (assign & unassign) roles to person */
export const ChangeRolesByAdmin = createAction(
  ActionTypes.ChangeRolesByAdmin,
  props<{ personId: string; roleIds: string[] }>()
);

export const ChangeRolesByAdminSuccessful = createAction(
  ActionTypes.ChangeRolesByAdminSuccessful,
  props<{ personId: string; roleIds: string[] }>()
);

export const ChangeRolesByAdminFailure = createAction(
  ActionTypes.ChangeRolesByAdminFailure,
  props<StateError>()
);

/* get person count */
export const GetPeopleCount = createAction(
  ActionTypes.GetPeopleCount,
  props<{ pageIndex?: number; pageSize?: number; searchText?: string }>()
);

export const GetPeopleCountSuccessful = createAction(
  ActionTypes.GetPeopleCountSuccessful,
  props<{ pageIndex: number; pageSize: number; searchText?: string; count?: number }>()
);

export const GetPeopleCountFailure = createAction(
  ActionTypes.GetPeopleCountFailure,
  props<{ error: any; key: string }>()
);

/* modal visibility */
export const ChangePersonModalVisibility = createAction(
  ActionTypes.ChangePersonModalVisibility,
  props<{ visibility: boolean }>()
);
