import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, createSelector } from '@ngrx/store';
import { BreadcrumbOption, ButtonComponentConfig, ButtonSize, HeadingComponentConfig, IconType } from '@rappider/rappider-components/utils';
import { defaultToolbarTitleHeadingSize, PATH_DEFINITIONS, QUERY_PARAM_DEFINITIONS } from '@rappider/shared/definitions';
import { DeleteUIDataEvent, UpdateUIDataEvent } from 'libs/project/src/lib/states/ui-data-event-state/ui-data-event.actions';
import { Subscription } from 'rxjs';
import { ProjectWithRelations, UiDataEventWithRelations, UiDataStoreWithRelations } from '@rappider/rappider-sdk';
import { DATA_EVENT_LIST_CONFIG } from '../../../data-events/components/data-event-list/config/data-event-list-config';
import { RouterStateService } from '@rappider/services';
import { cloneDeep, orderBy } from 'lodash';
import { DataSchemaCategory } from '../../../data-schema/models/data-schema-category.enum';
import { UI_DATA_STORE_DETAIL_LIST_CREATE_CONFIG, UI_DATA_STORE_DETAIL_LIST_UPDATE_CONFIG } from '../ui-data-store-list/config/ui-data-store-detail-list-config';
import { UI_STEP_FUNCTION_LIST_CONFIG } from '@rappider/shared/configs';
import { UI_DATA_UPDATE_FUNCTION_LIST_CONFIG } from '../../../ui-data-update-function/components/ui-data-update-function-list/config/ui-data-update-function-list-config';
import { getDataSchemasWithDetailsSelector } from 'libs/project/src/lib/states/selectors/get-data-schemas-with-details.selector';

@Component({
  selector: 'rappider-ui-data-store-detail',
  templateUrl: './ui-data-store-detail.component.html',
  styleUrls: ['./ui-data-store-detail.component.scss']
})
export class UiDataStoreDetailComponent implements OnInit, OnDestroy {
  /* list grid config */
  DATA_EVENT_LIST_CONFIG = DATA_EVENT_LIST_CONFIG;
  UI_DATA_STORE_DETAIL_LIST_CREATE_CONFIG = UI_DATA_STORE_DETAIL_LIST_CREATE_CONFIG;
  UI_DATA_STORE_DETAIL_LIST_UPDATE_CONFIG = UI_DATA_STORE_DETAIL_LIST_UPDATE_CONFIG;
  QUERY_PARAM_DEFINITIONS = QUERY_PARAM_DEFINITIONS;
  UI_STEP_FUNCTION_LIST_CONFIG = UI_STEP_FUNCTION_LIST_CONFIG;
  UI_DATA_UPDATE_FUNCTION_LIST_CONFIG = UI_DATA_UPDATE_FUNCTION_LIST_CONFIG;

  /* main title */
  mainTitle: HeadingComponentConfig = {
    content: 'PROJECT_MODULE.UI_DATA_STORE_DETAIL_COMPONENT.UI_DATA_STORE_DETAIL',
    type: defaultToolbarTitleHeadingSize
  };
  /* title breadcrumb */
  title: BreadcrumbOption[];
  uiDataStoreId: string;
  uiDataStore: UiDataStoreWithRelations;
  subscriptions: Subscription[];
  activeProject: ProjectWithRelations;
  uiDataEvents: UiDataEventWithRelations[];

  uiDataEventsLoading: boolean;
  uiDataStoresLoading: boolean;
  uiStepFunctionsLoading: boolean;
  isFieldsLoading: boolean;

  displayToolbar = false;
  displayToolbarBackButton = false;

  notCreatedFields = [];
  notCreatedFieldsLength: number;
  notUpdatedFields = [];
  notUpdatedFieldsLength: number;
  notCreatedFieldsVisible = false;
  notUpdatedFieldsVisible = false;
  dataSchemaLoading;
  closeButton: ButtonComponentConfig = {
    icon: {
      name: 'fa-solid fa-xmark'
    },
    size: ButtonSize.ExtraSmall
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<any>,
    private router: Router,
    private routerStateService: RouterStateService
  ) { }

  ngOnInit(): void {
    this.getUIDataStoreIdFromUrl();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToUiDataEventsLoading(),
      this.subscribeToUiDataStoresLoading(),
      this.subscribeToUIDataStoreWithRelations(),
      this.subscribeToUIDataEvents(),
      this.subscribeToQueryParams(),
      this.subscribeToFieldsLoadingByModelIds(),
      this.subscribeToDataSchemasLoading()
    ];
  }

  getUIDataStoreIdFromUrl() {
    this.uiDataStoreId = this.activatedRoute.snapshot.params['id'];
  }

  subscribeToDataSchemasLoading() {
    return this.store.select(state => state.dataSchema.isLoaded).subscribe(isLoading => {
      this.dataSchemaLoading = isLoading;
    });
  }

  subscribeToQueryParams() {
    return this.activatedRoute.queryParams.subscribe(queryParams => {
      if (!Object.keys(queryParams).length) {
        this.router.navigate([], { queryParams: QUERY_PARAM_DEFINITIONS.PROJECT.UI_DATA_STORE_DETAIL.FIELDS_TAB });
      }
    });
  }

  subscribeToFieldsLoadingByModelIds() {
    return this.store.select(state => state.uiDataStore.fieldsLoadingByModelIds).subscribe((fieldsLoadingByModelIds: string[]) => {
      this.isFieldsLoading = fieldsLoadingByModelIds?.some(id => id === this.uiDataStore.projectModelId);
    });
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectWithRelations) => {
      this.activeProject = activeProject;
      if (this.uiDataStore && this.activeProject) {
        this.setTitle();
      }
    });
  }

  subscribeToUIDataEvents() {
    return this.store.select(state => state.uiDataEvent?.data).subscribe((uiDataEvents: UiDataEventWithRelations[]) => {
      this.uiDataEvents = orderBy(uiDataEvents?.filter(uiDataEvent => uiDataEvent.uiDataStoreId === this.uiDataStoreId), 'index', 'asc');
    });
  }

  subscribeToUIDataStoreWithRelations() {
    return this.store.select(
      createSelector(
        state => <any>state['dataSchema'].isLoading,
        <any>getDataSchemasWithDetailsSelector,
        state => <any[]>state['uiDataStore'].data,
        state => state['uiDataStore'].loading,
        state => state['dataSchema'].notCreatedFields,
        state => state['dataSchema'].notUpdatedFields,
        (
          dataSchemasLoading, dataSchemas: any[], uiDataStores: any[],
          uiDataStoresLoading, notCreatedFields: any[], notUpdatedFields: any[]
        ) => {
          if (!(dataSchemasLoading || uiDataStoresLoading)) {
            const uiDataStore = cloneDeep(uiDataStores?.find(dataStore => dataStore.id === this.uiDataStoreId));
            uiDataStore.schema = dataSchemas?.find(dataSchema => dataSchema.id === uiDataStore.dataSchemaId &&
              dataSchema.category === DataSchemaCategory.UiDataStoreState);
            this.notCreatedFields = notCreatedFields.filter(notCreatedField =>
              notCreatedField.parentDataSchemaId === uiDataStore.dataSchemaId);
            this.notUpdatedFields = notUpdatedFields.filter(notUpdatedField =>
              notUpdatedField.parentDataSchemaId === uiDataStore.dataSchemaId);
            return uiDataStore;
          }
        }
      )
    ).subscribe(uiDataStore => {
      this.uiDataStore = uiDataStore;
      if (this.notCreatedFieldsLength !== this.notCreatedFields?.length && this.notCreatedFields?.length) {
        this.notCreatedFieldsLength = this.notCreatedFields?.length;
        this.notCreatedFieldsVisible = true;
      }
      if (this.notUpdatedFieldsLength !== this.notUpdatedFields?.length && this.notUpdatedFields?.length) {
        this.notUpdatedFieldsLength = this.notUpdatedFields?.length;
        this.notUpdatedFieldsVisible = true;
      }
      this.setTitle();
      this.setDataEventListConfigByUIDataStore();
      this.setUIStepFunctionListConfigByUIDataStore();
      this.setUIDataUpdateFunctionListConfigByUIDataStore();
    });
  }

  subscribeToUiDataEventsLoading() {
    return this.store.select(state => state.uiDataEvent?.loading).subscribe(isLoading => {
      this.uiDataEventsLoading = isLoading;
    });
  }

  subscribeToUiDataStoresLoading() {
    return this.store.select(state => state.uiDataStore?.loading).subscribe(isLoading => {
      this.uiDataStoresLoading = isLoading;
    });
  }

  subscribeToUiStepFunctionsLoading() {
    return this.store.select(state => state.uiWorkflowStepFunction?.loading).subscribe(isLoading => {
      this.uiStepFunctionsLoading = isLoading;
    });
  }

  setTitle() {
    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`,
      },
      {
        label: 'PROJECT_MODULE.UI_DATA_STORE_LIST_COMPONENT.UI_DATA_STORE_LIST',
        redirectUrl: PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_LIST
      },
      {
        label: this.uiDataStore?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_DETAIL}/${this.uiDataStore?.id}`
      }
    ];
  }

  onUIDataEventDelete(uiDataEvent: UiDataEventWithRelations) {
    this.store.dispatch(
      new DeleteUIDataEvent({ uiDataEvent: uiDataEvent })
    );
  }

  onUIDataEventEdit(uiDataEvent: UiDataEventWithRelations) {
    const uiDataEventId = uiDataEvent.id;
    this.store.dispatch(
      new UpdateUIDataEvent({ uiDataEventId: uiDataEventId, uiDataEvent: uiDataEvent, navigateAfterUpdate: true })
    );
  }

  navigateCreateDataEventPage(dataStoreId: string) {
    this.routerStateService.navigate(
      `${PATH_DEFINITIONS.PROJECTS.DATA_EVENT_CREATE}/${dataStoreId}`
    );
  }

  closeNotCreatedfields() {
    this.notCreatedFieldsVisible = false;
  }

  closeNotUpdatedfields() {
    this.notUpdatedFieldsVisible = false;
  }

  setDataEventListConfigByUIDataStore() {
    this.DATA_EVENT_LIST_CONFIG = this.setListConfigByUIDataStore(
      this.DATA_EVENT_LIST_CONFIG,
      ['createDataEvent'],
      ['Edit', 'DELETE_ITEM']
    );
  }

  setUIStepFunctionListConfigByUIDataStore() {
    this.UI_STEP_FUNCTION_LIST_CONFIG = this.setListConfigByUIDataStore(
      this.UI_STEP_FUNCTION_LIST_CONFIG,
      ['createUIStepFunction'],
      ['preDataTransformation', 'postDataTransformation', 'edit', 'DELETE_ITEM']
    );
  }

  setUIDataUpdateFunctionListConfigByUIDataStore() {
    this.UI_DATA_UPDATE_FUNCTION_LIST_CONFIG = this.setListConfigByUIDataStore(
      this.UI_DATA_UPDATE_FUNCTION_LIST_CONFIG,
      ['createUIDataUpdateFunction'],
      ['editCode', 'addDataMapping', 'DELETE_ITEM']
    );
  }

  setListConfigByUIDataStore(listConfig: any, listActionNamesToFilter: string[], itemActionNamestoFilter: string[]) {
    return {
      ...listConfig,
      listActions: this.uiDataStore?.isReadOnly ?
        listConfig.listActions.filter(action => !listActionNamesToFilter.includes(action.name)) : listConfig.listActions,
      handleVisibilityFn: (data, item, action) => {
        if (this.uiDataStore?.isReadOnly && itemActionNamestoFilter.includes(action.name)) {
          return false;
        }
        if (item.isUpdatable === false && action.name === 'Edit') {
          return false;
        }
        if (item.isDeletable === false && action.name === 'DELETE_ITEM') {
          return false;
        }
        return true;
      }
    };
  }

}
