<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [titleBarActionButtons]="titleBarActionButtons"
                        (titleBarActionButtonClick)="onTitleBarActionButtonClick($event)"></rappider-title-toolbar>

<ng-template #iframeTemplate>
  <ng-container *ngIf="livePreviewInformation?.deployed_build?.status === LivePreviewInformationStatus.Success 
                || livePreviewInformation?.previous_deployed_build?.status === LivePreviewInformationStatus.Success">
    <div class="live-preview-iframe">
      <iframe [src]="frontendURL | safeUrl"
              [ngClass]="!isDeploymentLogVisible ? 'full-witdh-preview-area' : ''"
              class="live-preview"
              #iframe
              scrolling="yes"
              frameborder="0"
              (load)="onIFrameContentLoaded()"></iframe>
    </div>
  </ng-container>
</ng-template>

<div class="live-preview-wrapper">
  <ng-container [ngSwitch]="livePreviewInformation?.latest_build?.status">
    <div class="live-preview-content-area">

      <ng-container *ngSwitchCase="LivePreviewInformationStatus.InProgress">
        <div *ngIf="livePreviewInformation?.deployed_build?.status === LivePreviewInformationStatus.Success"
             class="animation live-preview-in-progress-content-area">
          <rappider-alert class="live-preview-fail-info-alert"
                          [showIcon]="true"
                          type="info"
                          [title]="'A new build of version ' + displayedBuild?.version_branch + ' is in-progress.'"
                          description="You are previewing the previous build."></rappider-alert>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="LivePreviewInformationStatus.Fail">
        <rappider-alert class="live-preview-fail-info-alert"
                        [showIcon]="true"
                        [type]="'info'"
                        [title]="'An error occured while building the version of ' + displayedBuild.version_branch"
                        [description]="'You are previewing the previous build.'"></rappider-alert>
      </ng-container>

      <div *ngIf="(!livePreviewInformation?.previous_deployed_build && !livePreviewInformation?.deployed_build)
      && livePreviewInformation?.latest_build?.status === LivePreviewInformationStatus.InProgress"
           class="animation live-preview-in-progress-content-area">
        <rappider-alert class="live-preview-fail-info-alert"
                        [showIcon]="true"
                        type="info"
                        [title]="'Deployment In Progress'"
                        description="We are deploying your project."></rappider-alert>
      </div>

      <ng-container [ngTemplateOutlet]="iframeTemplate"></ng-container>
    </div>
  </ng-container>

  <section class="log-area"
           *ngIf="isDeploymentLogVisible">
    <ng-container [ngTemplateOutlet]="deploymentInfoTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      build: displayedBuild,
                      timestamp: livePreviewInformation?.last_live_preview_timestamp
                    }
                  }"></ng-container>
    <!-- SET LATEST_BUILD AS 'build' VARIABLE IN TEMPLATE -->
    <ng-template #deploymentInfoTemplate
                 let-build="data.build"
                 let-timestamp="data.timestamp">
      <div class="deployment-log-body">
        <div class="log-tabs">
          <nz-tabset class="tabset-overflow"
                     nzSize="small">
            <nz-tab nzTitle="Build Info">
              <div class="deploy-time-and-version">
                <div class="build-info-item">
                  <strong>
                    Status:
                  </strong>
                  <div class="icon-text">
                    <div>
                      {{setDeployStatusIcon(build?.status).text}}
                    </div>
                    <rappider-icon [name]="setDeployStatusIcon(build?.status).icon"></rappider-icon>
                  </div>
                </div>

                <div class="build-info-item">
                  <strong>
                    Version:
                  </strong>
                  {{build?.version_branch ?? '-'}}
                </div>

                <div class="build-info-item">
                  <strong>
                    Started at:
                  </strong>
                  {{ build?.start_time ? moment(build?.start_time).calendar() : '-' }}
                </div>

                <div class="build-info-item">
                  <strong>
                    Ended at:
                  </strong>
                  {{ build?.end_time ? moment(build?.end_time).calendar() : '-' }}
                </div>

                <div class="build-info-item"
                     *ngIf="build?.index_created_date">
                  <strong>
                    Files Deployed At:
                  </strong>
                  {{ build?.index_created_date ? (build?.index_created_date | formatDate) : '-' }}
                </div>

                <div class="build-steps">
                  <strong>
                    Build & Deploy Steps
                  </strong>
                  <hr class="build-steps-ruler">
                  <rappider-timeline *ngIf="timelineItems?.length"
                                     [items]="timelineItems"
                                     [reverseTreeItem]="true"></rappider-timeline>
                  <div *ngIf="!timelineItems?.length">
                    Loading <i class="fa-duotone fa-gear fa-spin"></i>
                  </div>
                </div>

              </div>

            </nz-tab>

            <nz-tab nzTitle="UI Logs">
              <pre class="log-details">
                <ng-container *ngIf="build?.status === 'in-progress'">
                  Build in progress...
                </ng-container>
                <ng-container *ngIf="build?.status !== 'in-progress'">
                  {{ build?.build_logs?.frontend ?? 'No logs were found for UI' }}
                </ng-container>
              </pre>
            </nz-tab>

            <nz-tab nzTitle="BE Logs">
              <pre class="log-details">
                <ng-container *ngIf="build?.status === 'in-progress'">
                  Build in progress...
                </ng-container>
                <ng-container *ngIf="build?.status !== 'in-progress'">
                  {{ build?.build_logs?.backend ?? 'No logs were found for BE' }}
                </ng-container>
              </pre>
            </nz-tab>

          </nz-tabset>
        </div>
      </div>
    </ng-template>
  </section>
</div>