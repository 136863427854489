import { Validators } from '@angular/forms';
import { CrudFormConfig, CrudFormTextBoxItem, CrudViewFormItemType, FormLayout, IconType, NgZorroIconTheme } from 'libs/components/src/lib/utils';

export const CREATE_DATA_SCHEMA_BUTTON = {
  text: 'PROJECT_MODULE.DATA_SCHEMA_MODULE.DATA_SCHEMA_SELECTBOX.CREATE_DATA_SCHEMA',
  key: 'create'
};

export const EDIT_DATA_SCHEMA_BUTTON = {
  text: 'PROJECT_MODULE.DATA_SCHEMA_MODULE.DATA_SCHEMA_SELECTBOX.EDIT_DATA_SCHEMA',
  key: 'edit'
};

export const EDIT_ICON = {
  name: 'edit',
  type: IconType.NgZorro,
  theme: NgZorroIconTheme.Outline
};

export const DATA_SCHEMA_ENUM_DATA_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormTextBoxItem>{
      title: 'Enum Data',
      fieldName: 'data',
      type: CrudViewFormItemType.RowForm,
      hasInitialEmptyRow: true,
      addButton: {
        text: 'PROJECT_MODULE.DATA_SCHEMA_ENUM_DATA.ADD_ENUM_DEFINITION'
      },
      addButtonVisible: true,
      orderable: true,
      columns: [
        {
          fieldName: 'key',
          placeholder: 'key',
          unique: true,
          validators: [
            {
              type: Validators.required,
              errorKey: 'required',
              errorMessage: 'SHARED.REQUIRED'
            }
          ],
          config: {
            placeholder: 'Key'
          }
        },
        {
          fieldName: 'value',
          placeholder: 'value',
          unique: true,
          validators: [
            {
              type: Validators.required,
              errorKey: 'required',
              errorMessage: 'SHARED.REQUIRED'
            }
          ],
          config: {
            placeholder: 'Value'
          }
        }
      ]
    },
  ],
  formLayout: FormLayout.Horizontal
};
