/* eslint-disable */
import { DataSchemaTreeField, DataSchemaField } from '../index';

declare var Object: any;
export interface DataSchemaTreeFieldNodeInterface {
  id?: string;
  index?: number;
  dataSchemaTreeFieldId?: string;
  dataSchemaFieldId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  treeField?: DataSchemaTreeField;
  field?: DataSchemaField;
}

export class DataSchemaTreeFieldNode
  implements DataSchemaTreeFieldNodeInterface {
  'id': string;
  'index': number;
  'dataSchemaTreeFieldId': string;
  'dataSchemaFieldId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  treeField: DataSchemaTreeField;
  field: DataSchemaField;
  constructor(data?: DataSchemaTreeFieldNodeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `DataSchemaTreeFieldNode`.
   */
  public static getModelName() {
    return 'DataSchemaTreeFieldNode';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of DataSchemaTreeFieldNode for dynamic purposes.
   **/
  public static factory(
    data: DataSchemaTreeFieldNodeInterface
  ): DataSchemaTreeFieldNode {
    return new DataSchemaTreeFieldNode(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'DataSchemaTreeFieldNode',
      plural: 'DataSchemaTreeFieldNodes',
      path: 'data-schema-tree-field-nodes',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        index: {
          name: 'index',
          type: 'number',
        },
        dataSchemaTreeFieldId: {
          name: 'dataSchemaTreeFieldId',
          type: 'string',
        },
        dataSchemaFieldId: {
          name: 'dataSchemaFieldId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
      },
      relations: {
        treeField: {
          name: 'treeField',
          type: 'DataSchemaTreeField',
          model: 'DataSchemaTreeField',
          relationType: 'belongsTo',
          keyFrom: 'dataSchemaTreeFieldId',
          keyTo: 'id',
        },
        field: {
          name: 'field',
          type: 'DataSchemaField',
          model: 'DataSchemaField',
          relationType: 'belongsTo',
          keyFrom: 'dataSchemaFieldId',
          keyTo: 'id',
        },
      },
    };
  }
}
