import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IChatMessage, MessageAuthorType, defaultInputGroupConfig, defaultReceiverAvatarConfig, defaultSenderAvatarConfig } from '../../utils/chat';
import { AvatarComponentConfig } from '../../utils/avatar';
import { KeyValue } from '@angular/common';
import { ButtonComponentConfig, ButtonSize, ButtonType } from '../../utils/button';

@Component({
  selector: 'rappider-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class RappiderChatComponent implements OnInit, OnChanges {

  @Input() messages: IChatMessage[];
  @Input() isTyping: boolean;
  @Input() senderAvatar: AvatarComponentConfig;
  @Input() receiverAvatar: AvatarComponentConfig;
  @Input() sendButtonDisabled: boolean;
  @Input() displaySkeleton: boolean;
  @Input() fullScreenButtonVisibility: boolean;
  @Input() quickAnswerOptions?: KeyValue<string, string>[];

  @Output() messageSent = new EventEmitter<string>();
  @Output() messageButtonClick = new EventEmitter<IChatMessage>();
  @Output() fullScreenButtonClick = new EventEmitter<IChatMessage>();
  @Output() quickAnswerButtonClick = new EventEmitter<string>();

  inputGroupConfig = defaultInputGroupConfig;
  inputValue = '';
  MessageAuthorType = MessageAuthorType;
  quickAnswerButtons: ButtonComponentConfig[];

  ngOnInit(): void {
    this.initDefaults();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.messages && this.messages?.length) {
      if (!changes.messages?.previousValue || changes.messages?.previousValue?.length !== this.messages?.length) {
        /* wait until messages will be creating in DOM */
        setTimeout(() => {
          this.scrollToBottom();
        }, 100);
      }
    }
    if (changes.senderAvatar || changes.receiverAvatar) {
      this.initDefaults();
    }
    if (changes.textboxDisabled) {
      this.inputGroupConfig.suffixButton.disabled = this.sendButtonDisabled;
    }
    if (changes.quickAnswerOptions) {
      this.quickAnswerButtons = this.quickAnswerOptions?.map(option => ({
        key: option.value,
        text: option.key,
        type: ButtonType.Default,
        block: false,
        size: ButtonSize.Small,
        borderSettings: {
          borderRadius: '0px'
        }
      })) || [];
    }
  }

  initDefaults() {
    if (!this.senderAvatar) {
      this.senderAvatar = defaultSenderAvatarConfig;
    }
    if (!this.receiverAvatar) {
      this.receiverAvatar = defaultReceiverAvatarConfig;
    }
  }

  scrollToBottom() {
    const element = document.getElementById('chat-wrapper');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', inline: 'end', block: 'end' });
    }
  }

  onEnterClick() {
    if (!this.sendButtonDisabled && this.inputValue !== '' && this.inputValue !== null && this.inputValue !== undefined) {
      this.messageSent.emit(this.inputValue);
      this.inputValue = '';
    }
  }

  onButtonClick() {
    if (!this.sendButtonDisabled && this.inputValue !== '' && this.inputValue !== null && this.inputValue !== undefined) {
      this.messageSent.emit(this.inputValue);
      this.inputValue = '';
    }
  }

  /* this function avoids re-rendering the list unnecessarily */
  trackMessages(index, item) {
    return index;
  }

  onMessageButtonClick(message: IChatMessage) {
    this.messageButtonClick.emit(message);
  }

  onContinueMessageButtonClick(messageContent: string) {
    this.messageSent.emit(messageContent);
  }

  onFullScreenButtonClick() {
    this.fullScreenButtonClick.emit();
  }

  onQuickAnswerButtonClick(button: ButtonComponentConfig) {
    this.quickAnswerButtonClick.emit(button.key);
  }

}
