import {
  CrudFormTextBoxItem,
  CrudFormPhoneNumberItem,
  CrudViewFormItemType,
  TextBoxType,
  CrudFormInputValidatorInfoItem,
  FormLayout,
  CrudFormConfig,
  InputSize,
  CrudFormSelectItem,
  countries
} from '@rappider/rappider-components/utils';
import { Validators } from '@angular/forms';
import { EMAIL_REGEX, PASSWORD_REGEX, SPECIAL_CHARACTER_REGEX, US_PHONE_MASK } from '../../definitions/regex';
import { COUNTRIES } from '../../data/countries';
import { INDUSTRIES } from '../../data/industries';
import { LEAD_SOURCES } from '../../data/lead-sources';

export const CHANGE_PASSWORD_WITH_CURRENT_PASSWORD_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormTextBoxItem>{
      title: 'SHARED.CURRENT_PASSWORD',
      fieldName: 'currentPassword',
      type: CrudViewFormItemType.TextBox,
      textType: TextBoxType.Password,
      index: 1,
      placeholder: 'SHARED.CURRENT_PASSWORD',
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    <CrudFormTextBoxItem>{
      title: 'SHARED.NEW_PASSWORD',
      fieldName: 'password',
      type: CrudViewFormItemType.TextBox,
      textType: TextBoxType.Password,
      index: 2,
      placeholder: 'SHARED.NEW_PASSWORD',
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          type: Validators.pattern(PASSWORD_REGEX),
          errorKey: 'pattern'
        }
      ]
    },
    <CrudFormInputValidatorInfoItem>{
      relatedFieldName: 'password',
      type: CrudViewFormItemType.InputValidatorInfo,
      index: 3,
      patternInfos: [
        {

          pattern: /[1-9]/,
          message: 'ERRORS.PATTERN_MESSAGES.PASSWORD_PATTERN_MESSAGES.ONE_DIGIT'
        },
        {
          pattern: /[A-Z]/,
          message: 'ERRORS.PATTERN_MESSAGES.PASSWORD_PATTERN_MESSAGES.ONE_UPPERCASE'
        },
        {
          pattern: SPECIAL_CHARACTER_REGEX,
          message: 'ERRORS.PATTERN_MESSAGES.PASSWORD_PATTERN_MESSAGES.SPECIAL_CHARACTER'
        },
        {
          pattern: /.{8,}/,
          message: 'ERRORS.PATTERN_MESSAGES.PASSWORD_PATTERN_MESSAGES.MINIMUM_LENGTH'
        }
      ]
    },
    <CrudFormTextBoxItem>{
      title: 'SHARED.CONFIRM_NEW_PASSWORD',
      fieldName: 'confirmPassword',
      type: CrudViewFormItemType.TextBox,
      textType: TextBoxType.Password,
      index: 4,
      placeholder: 'SHARED.CONFIRM_NEW_PASSWORD',
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          errorKey: 'mustMatch',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.MUST_MATCH_PASSWORD'
        }
      ]
    }
  ],
  validators: [
    {
      name: 'MustMatch',
      validatorKey: 'mustMatch',
      params: {
        field1: 'password',
        field2: 'confirmPassword'
      }
    }
  ],
  itemSettings: {
    labelComponentSize: {
      xs: 24
    },
    inputComponentSize: {
      xs: 24
    },

  },
  formLayout: FormLayout.Vertical,
  submitButton: {
    text: 'SHARED.CHANGE_PASSWORD',
  }
};

export const CHANGE_PROFILE_INFORMATIONS_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormTextBoxItem>{
      title: 'SHARED.FIRST_NAME',
      fieldName: 'firstName',
      type: CrudViewFormItemType.TextBox,
      textType: TextBoxType.Text,
      index: 1,
      showRequiredSign: true,
      placeholder: 'SHARED.FIRST_NAME',
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    <CrudFormTextBoxItem>{
      title: 'SHARED.LAST_NAME',
      fieldName: 'lastName',
      type: CrudViewFormItemType.TextBox,
      textType: TextBoxType.Text,
      index: 2,
      showRequiredSign: true,
      placeholder: 'SHARED.LAST_NAME',
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    <CrudFormTextBoxItem>{
      title: 'Email',
      fieldName: 'email',
      type: CrudViewFormItemType.TextBox,
      textType: TextBoxType.Text,
      index: 3,
      showRequiredSign: true,
      placeholder: 'Email',
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          type: Validators.pattern(EMAIL_REGEX),
          errorKey: 'pattern',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.VALID_EMAIL_MESSAGE'
        }
      ]
    },
    <CrudFormTextBoxItem>{
      title: 'Company Name',
      fieldName: 'companyName',
      type: CrudViewFormItemType.TextBox,
      textType: TextBoxType.Text,
      index: 4,
      showRequiredSign: true,
      placeholder: 'Company Name',
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    <CrudFormSelectItem>{
      title: 'Industry',
      fieldName: 'industry',
      type: CrudViewFormItemType.Select,
      options: INDUSTRIES.map(industry => ({
        key: industry,
        value: industry
      })),
      index: 5,
      showRequiredSign: true,
      placeholder: 'Industry',
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    <CrudFormSelectItem>{
      title: 'Country',
      fieldName: 'country',
      type: CrudViewFormItemType.Select,
      options: COUNTRIES.map(country => ({
        key: country.name,
        value: country.name
      })),
      index: 6,
      showRequiredSign: true,
      placeholder: 'Select a country',
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    <CrudFormTextBoxItem>{
      title: 'Job Title',
      fieldName: 'jobTitle',
      type: CrudViewFormItemType.TextBox,
      textType: TextBoxType.Text,
      index: 7,
      showRequiredSign: true,
      placeholder: 'Job title',
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    <CrudFormPhoneNumberItem>{
      title: 'Phone Number',
      fieldName: 'phoneNumber',
      type: CrudViewFormItemType.PhoneNumber,
      textType: TextBoxType.Text,
      index: 8,
      showRequiredSign: true,
      placeholder: 'Phone number',
      selectConfig: {
        grouppedOptions: []
      },
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    <CrudFormSelectItem>{
      title: 'How did you hear about us?',
      fieldName: 'leadSource',
      type: CrudViewFormItemType.Select,
      options: LEAD_SOURCES,
      index: 9,
      placeholder: 'Select an option',
      showRequiredSign: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
  ],
  itemSettings: {
    labelComponentSize: {
      xs: 24
    },
    inputComponentSize: {
      xs: 24
    },

  },
  formLayout: FormLayout.Vertical,
  submitButton: {
    text: 'SHARED.SAVE',
    size: 'default'
  }
};
