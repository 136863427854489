import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'fromNow'
})
export class FromNowPipe implements PipeTransform {

  transform(date: string | number | Date, suffix: boolean = false): string {
    return moment(date).fromNow(suffix);
  }

}
