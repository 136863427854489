<!-- <section> -->
<rappider-spin [spinning]="!project">
  <ng-container *ngIf="project">
    <!-- project information -->
    <nz-card class="project-details-card">
      <div class="title-bar">
        <h2>{{ project.name }}</h2>

        <div class="project-edit-page-buttons">
          <!-- <rappider-button [text]="'Live Preview'"
                           (click)="navigateToLivePreview()">
          </rappider-button>

          <button nz-button
                  nz-dropdown
                  nzTrigger="click"
                  [nzDropdownMenu]="projectSettingsDropdownMenuTempate">
            <rappider-icon [name]="'fas fa-gear'"></rappider-icon>
          </button>

          <nz-dropdown-menu #projectSettingsDropdownMenuTempate="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item
                  (click)="navigateToProjectList()">{{ projectListButton.text }}</li>
              <li nz-menu-item
                  (click)="navigateEditProject()">{{ editButton.text }}</li>
            </ul>
          </nz-dropdown-menu> -->
          <rappider-button [text]="projectListButton.text"
                           (click)="navigateToProjectList()">
          </rappider-button>
          <rappider-button [text]="editButton.text"
                           (click)="navigateEditProject()">
          </rappider-button>
        </div>
      </div>

      <hr>

      <span *ngIf="tenant?.name"
            class="field-container">
        <span class="field">Tenant Name:</span>
        {{ tenant?.name }}
      </span>

      <span class="field-container">
        <span class="field">{{ 'Created By' | translate }} </span>
        {{ project.createdBy || '-' }}
        <span class="field">{{ 'at' | translate }} </span>
        {{ project.createdDate | formatDate}}
      </span>
    </nz-card>
    <!-- features and links -->
    <section>
      <div class="feature-card-container row">
        <div *ngFor="let feature of features; let i = index"
             class="feature-card  col-lg-3 col-md-3 col-sm-6 col-xs-12">
          <div class="feature-card-content">
            <i [class]="feature.icon"></i>
            <h5 class="feature-title">
              {{feature.title}}
            </h5>
            <p class="feature-description">
              {{feature.description}}
            </p>
            <div class="feature-links">
              <ng-container *ngFor="let subFeature of feature.subFeatures">
                <a *ngIf="subFeature?.isVisible !== false && subFeature?.title !== 'Invite Users'; else inviteUserTemplate"
                   class="feature-link"
                   [routerLink]="subFeature.path">{{subFeature.title}}</a>
                <ng-template #inviteUserTemplate>
                  <a *ngIf="canSeeAllSubFeature && subFeature?.isVisible !== false"
                     class="feature-link"
                     [routerLink]="subFeature.path">{{subFeature.title}}</a>
                </ng-template>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </section>
  </ng-container>
</rappider-spin>
<!-- </section> -->