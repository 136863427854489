/* eslint-disable */

declare var Object: any;
export interface VerificationTokenInterface {
  id: string;
  token: string;
  tokenType: string;
  assetType?: string;
  asset: any;
  userId?: string;
  personId?: string;
  isVerified: boolean;
  verifiedOn?: Date;
  verifiedIp?: string;
  expirationDate?: Date;
  isActive?: boolean;
  createdDate?: Date;
}

export class VerificationToken implements VerificationTokenInterface {
  'id': string;
  'token': string;
  'tokenType': string;
  'assetType': string;
  'asset': any;
  'userId': string;
  'personId': string;
  'isVerified': boolean;
  'verifiedOn': Date;
  'verifiedIp': string;
  'expirationDate': Date;
  'isActive': boolean;
  'createdDate': Date;
  constructor(data?: VerificationTokenInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `VerificationToken`.
   */
  public static getModelName() {
    return 'VerificationToken';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of VerificationToken for dynamic purposes.
   **/
  public static factory(data: VerificationTokenInterface): VerificationToken {
    return new VerificationToken(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'VerificationToken',
      plural: 'VerificationTokens',
      path: 'verification-tokens',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        token: {
          name: 'token',
          type: 'string',
        },
        tokenType: {
          name: 'tokenType',
          type: 'string',
        },
        assetType: {
          name: 'assetType',
          type: 'string',
        },
        asset: {
          name: 'asset',
          type: 'any',
        },
        userId: {
          name: 'userId',
          type: 'string',
        },
        personId: {
          name: 'personId',
          type: 'string',
        },
        isVerified: {
          name: 'isVerified',
          type: 'boolean',
          default: false,
        },
        verifiedOn: {
          name: 'verifiedOn',
          type: 'Date',
        },
        verifiedIp: {
          name: 'verifiedIp',
          type: 'string',
        },
        expirationDate: {
          name: 'expirationDate',
          type: 'Date',
        },
        isActive: {
          name: 'isActive',
          type: 'boolean',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
      },
      relations: {},
    };
  }
}
