import { UsernameType } from 'libs/shared/src/lib/definitions/username-types';

export const environment = {
  production: false,
  projectId: '00000000-0000-0000-0000-000000000001',
  apiBaseUrl: 'https://dev.api.rappider.com',
  npmSearchApiUrl: 'https://api.npms.io/v2/search',
  stripePublishableKey: 'pk_test_51LpZfVFhItefV5kPUn4PURfG4M30WDTHJNvLMsq6yzzueY6rXlqn4dsujXPsGDQzVU9rc1NjKD81Ldw2Mnr9ND2S00W6WhAYwS',
  isRegistrationEnabled: true,
  googleFontApiUrl: 'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyBnJmg-UoTB-0s_vVRc27CgWkEf00QbSsI',
  usernameType: UsernameType.Email,
  logActionsToConsole: false,
  maxTryCountForRecursiveAPICalls: 10,
  delayTimeForRecursiveApiCalls: 5000,
  maxDelayTimeForRecursiveApiCalls: 30000,
  delayTimeForNotificationApiCall: 180000,
  maxVisibleNotifications: 50,
  isNotificationPollingActive: true,
  delayTimeForDeployManagementInformationApiCall: 5000,
  successNotificationVisibilityDuration: 7500,
  maxNumberOfApiCallsForDeployInformation: 10,
  livePreviewInformationFilePath: 'live_preview_info.json',
  projectIdOfTemplatesForRappider: '00000000-0000-0000-0000-000000000002'
};
