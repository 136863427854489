<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>

<section>
  <rappider-spin [spinning]="(!projectId || !dataSchema) && !submitButtonLoading">
    <rappider-crud-view-edit-form [config]="DATA_SCHEMA_EDIT_CONFIG"
                                  [data]="dataSchema"
                                  [submitButtonLoading]="submitButtonLoading"
                                  (formSubmit)="updateDataSchema($event)"></rappider-crud-view-edit-form>
  </rappider-spin>
</section>
