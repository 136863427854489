<div class="card-one-list row" [ngStyle]="{'height': maxHeight}">
    <ng-container *ngFor="let item of dataToDisplay">
        <rappider-card-one [class]="calculateColumns()"
                           [data]="item.data"
                           [image]="item.image"
                           [imageTags]="item.imageTags"
                           [imageButtons]="item.imageButtons"
                           [imageButtonPlacement]="item?.imageButtonPlacement"
                           [rate]="item.rate"
                           [titles]="item.titles"
                           [currency]="item.currency"
                           [finalPrice]="item.finalPrice"
                           [price]="item.price"
                           [additionalTags]="item.additionalTags"
                           [additionalButtons]="item.additionalButtons"
                           [descriptions]="item.descriptions"
                           [avatar]="item.avatar"
                           [avatarButton]="item.avatarButton"
                           [avatarPlacement]="item.avatarPlacement"
                           [isSelected]="item.isSelected"
                           [selectedCardIcon]="item.selectedCardIcon"
                           [showTitleOnImage]="showTitleOnImage"
                           [showDescriptionOnImage]="showDescriptionOnImage"
                           [showPopover]="showPopover"
                           [showPopoverImage]="showPopoverImage"
                           (cardClick)="onCardClick($event, item)"
                           (imageButtonClick)="onImageButtonClick($event, item)"
                           (additionalButtonClick)="onAdditionalButtonClick($event, item)"></rappider-card-one>
    </ng-container>
    <rappider-pagination *ngIf="pagination"
                         [(pageIndex)]="paginationPageIndex"
                         [(pageSize)]="pageSize"
                         [total]="items?.length"
                         (pageIndexChange)="onPageIndexChange($event)"></rappider-pagination>
</div>
