/* eslint-disable */
import {
  ComponentSample,
  Category,
  SlotDefinition,
  ComponentInputDefinition,
  ComponentOutputDefinition,
  ComponentStyleSheetDefinition,
} from '../index';

declare var Object: any;
export interface ComponentDefinitionInterface {
  id?: string;
  className: string;
  selector: string;
  moduleName: string;
  tags?: Array<any>;
  filePath: string;
  title: string;
  description: string;
  hasUserGeneratedSlots?: boolean;
  noInputsSupported?: boolean;
  defaultInputs?: any;
  explanationImgUrl?: string;
  displayInCategoryShowcase?: boolean;
  categoryId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  previewImgUrls?: string[];
  previewIconUrl?: string;
  samples?: ComponentSample[];
  mainCategory?: Category;
  slotDefinitions?: SlotDefinition[];
  inputDefinitions?: ComponentInputDefinition[];
  outputDefinitions?: ComponentOutputDefinition[];
  subCategories?: Category[];
  styleSheetDefinitions?: ComponentStyleSheetDefinition[];
  isDeprecated?: boolean
}

export class ComponentDefinition implements ComponentDefinitionInterface {
  'id'?: string;
  'className': string;
  'selector': string;
  'moduleName': string;
  'tags': Array<string>;
  'filePath': string;
  'title': string;
  'description': string;
  'hasUserGeneratedSlots': boolean;
  'noInputsSupported': boolean;
  'defaultInputs': any;
  'explanationImgUrl': string;
  'displayInCategoryShowcase': boolean;
  'categoryId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  'previewImgUrls': string[];
  'previewIconUrl'?: string;
  samples: ComponentSample[];
  mainCategory: Category;
  slotDefinitions: SlotDefinition[];
  inputDefinitions: ComponentInputDefinition[];
  outputDefinitions: ComponentOutputDefinition[];
  subCategories: Category[];
  styleSheetDefinitions: ComponentStyleSheetDefinition[];
  constructor(data?: ComponentDefinitionInterface) {
    Object.assign(this, data);
  };
  'isDeprecated': boolean;
  /**
   * The name of the model represented by this $resource,
   * i.e. `ComponentDefinition`.
   */
  public static getModelName() {
    return 'ComponentDefinition';
  }

  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ComponentDefinition for dynamic purposes.
   **/
  public static factory(
    data: ComponentDefinitionInterface
  ): ComponentDefinition {
    return new ComponentDefinition(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ComponentDefinition',
      plural: 'ComponentDefinitions',
      path: 'component-definitions',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        className: {
          name: 'className',
          type: 'string',
        },
        selector: {
          name: 'selector',
          type: 'string',
        },
        moduleName: {
          name: 'moduleName',
          type: 'string',
        },
        tags: {
          name: 'tags',
          type: 'Array&lt;any&gt;',
        },
        filePath: {
          name: 'filePath',
          type: 'string',
        },
        title: {
          name: 'title',
          type: 'string',
        },
        description: {
          name: 'description',
          type: 'string',
        },
        hasUserGeneratedSlots: {
          name: 'hasUserGeneratedSlots',
          type: 'boolean',
          default: false,
        },
        noInputsSupported: {
          name: 'noInputsSupported',
          type: 'boolean',
        },
        defaultInputs: {
          name: 'defaultInputs',
          type: 'any',
        },
        explanationImgUrl: {
          name: 'explanationImgUrl',
          type: 'string',
        },
        displayInCategoryShowcase: {
          name: 'displayInCategoryShowcase',
          type: 'boolean',
        },
        categoryId: {
          name: 'categoryId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
        previewImgUrls: {
          name: 'previewImgUrls',
          type: 'string[]',
        },
        previewIconUrl: {
          name: 'previewIconUrl',
          type: 'string'
        }
      },
      relations: {
        samples: {
          name: 'samples',
          type: 'ComponentSample[]',
          model: 'ComponentSample',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'componentDefinitionId',
        },
        mainCategory: {
          name: 'mainCategory',
          type: 'Category',
          model: 'Category',
          relationType: 'belongsTo',
          keyFrom: 'categoryId',
          keyTo: 'id',
        },
        slotDefinitions: {
          name: 'slotDefinitions',
          type: 'SlotDefinition[]',
          model: 'SlotDefinition',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'componentDefinitionId',
        },
        inputDefinitions: {
          name: 'inputDefinitions',
          type: 'ComponentInputDefinition[]',
          model: 'ComponentInputDefinition',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'componentDefinitionId',
        },
        outputDefinitions: {
          name: 'outputDefinitions',
          type: 'ComponentOutputDefinition[]',
          model: 'ComponentOutputDefinition',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'componentDefinitionId',
        },
        subCategories: {
          name: 'subCategories',
          type: 'Category[]',
          model: 'Category',
          relationType: 'hasMany',
          modelThrough: 'ComponentDefinitionSubCategory',
          keyThrough: 'categoryId',
          keyFrom: 'id',
          keyTo: 'componentDefinitionId',
        },
        styleSheetDefinitions: {
          name: 'styleSheetDefinitions',
          type: 'ComponentStyleSheetDefinition[]',
          model: 'ComponentStyleSheetDefinition',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'componentDefinitionId',
        },
      },
    };
  }
}
