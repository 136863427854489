/* eslint-disable */
import { Metadata, ComponentDefinition } from '../index';

declare var Object: any;
export interface CategoryInterface {
  id?: string;
  title?: string;
  name?: string;
  description?: string;
  online?: boolean;
  slug?: string;
  order?: number;
  type?: string;
  parentCategoryId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  parentCategory?: Category;
  categories?: Category[];
  metadata?: Metadata[];
  components?: ComponentDefinition[];
}

export class Category implements CategoryInterface {
  'id'?: string;
  'title': string;
  'name': string;
  'online': boolean;
  'description': string;
  'slug': string;
  'order': number;
  'type'?: string;
  'parentCategoryId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  parentCategory: Category;
  categories: Category[];
  metadata: Metadata[];
  components: ComponentDefinition[];
  constructor(data?: CategoryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Category`.
   */
  public static getModelName() {
    return 'Category';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Category for dynamic purposes.
   **/
  public static factory(data: CategoryInterface): Category {
    return new Category(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Category',
      plural: 'Categories',
      path: 'categories',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        title: {
          name: 'title',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        description: {
          name: 'description',
          type: 'string',
        },
        online: {
          name: 'online',
          type: 'boolean',
        },
        slug: {
          name: 'slug',
          type: 'string',
        },
        order: {
          name: 'order',
          type: 'number',
        },
        type: {
          name: 'type',
          type: 'string',
        },
        parentCategoryId: {
          name: 'parentCategoryId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
      },
      relations: {
        parentCategory: {
          name: 'parentCategory',
          type: 'Category',
          model: 'Category',
          relationType: 'belongsTo',
          keyFrom: 'parentCategoryId',
          keyTo: 'id',
        },
        categories: {
          name: 'categories',
          type: 'Category[]',
          model: 'Category',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'parentCategoryId',
        },
        metadata: {
          name: 'metadata',
          type: 'Metadata[]',
          model: 'Metadata',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'categoryId',
        },
        components: {
          name: 'components',
          type: 'ComponentDefinition[]',
          model: 'ComponentDefinition',
          relationType: 'hasMany',
          modelThrough: 'ComponentDefinitionSubCategory',
          keyThrough: 'componentDefinitionId',
          keyFrom: 'id',
          keyTo: 'categoryId',
        },
      },
    };
  }
}
