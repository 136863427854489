import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RappiderSliderComponent } from './slider.component';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { RappiderButtonModule } from '../button/button.module';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { RappiderNumberInputModule } from '../number-input/number-input.module';


@NgModule({
  declarations: [
    RappiderSliderComponent
  ],
  exports: [
    RappiderSliderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NzSliderModule,
    RappiderButtonModule,
    NzGridModule,
    RappiderNumberInputModule
  ]
})
export class RappiderSliderModule { }
