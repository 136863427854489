import { ProjectExport } from '@rappider/rappider-sdk';
import { DEFAULT_PAGINATION } from '@rappider/shared/definitions';
import { Pagination } from '@rappider/shared/interfaces';
import * as ProjectExportActions from './project-export.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

export const ProjectExportFeatureKey = 'projectExport';

export interface State {
  data: ProjectExport[];
  pagination: Pagination;
  loading: boolean;
  isExportContinue: string;
  error: any;
}

export const initialState: State = {
  data: null,
  pagination: {
    currentPageNumber: DEFAULT_PAGINATION.ACTIVE_PAGE_NUMBER,
    pageSize: DEFAULT_PAGINATION.PAGE_SIZE,
    totalCount: null,
    totalPageNumber: null
  },
  isExportContinue: null,
  loading: false,
  error: null
};

export function reducer(
  state: State = initialState,
  action: ProjectExportActions.Actions | AuthenticationActions.Actions
): State {
  switch (action.type) {

    case ProjectExportActions.ActionTypes.SetProjectExports:
      return {
        ...state,
        data: action.payload.projectExports,
        loading: false
      };

    case ProjectExportActions.ActionTypes.SetPagination:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload.pagination
        }
      };

    case ProjectExportActions.ActionTypes.ExportProject:
      return {
        ...state,
        isExportContinue: action.payload.projectVersionId,
        loading: true
      };

    case ProjectExportActions.ActionTypes.DeleteProjectVersionId:
      return {
        ...state,
        isExportContinue: null
      };

    case ProjectExportActions.ActionTypes.GetProjectExports:
      return {
        ...state,
        loading: true
      };

    case ProjectExportActions.ActionTypes.ProjectExportError:
      return {
        ...state,
        error: {
          error: action.payload.error,
          key: action.payload.errorOn
        },
        loading: false
      };

    case AuthenticationActions.ActionTypes.Logout:
      return initialState;

    case AuthenticationActions.ActionTypes.ChangeActiveProjectSuccessful:
      return initialState;

    default:
      return state;
  }
}
