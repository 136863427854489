import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchemaTreeSelectComponent } from './schema-tree-select/schema-tree-select.component';
import { SchemaTreeComponent } from './schema-tree/schema-tree.component';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { FormsModule } from '@angular/forms';
import { RappiderFunctionComponent } from './rappider-function/rappider-function.component';
import {
  RappiderButtonModule,
  RappiderCheckboxModule,
  RappiderCodeEditorModule,
  RappiderDatePickerModule,
  RappiderHeadingModule,
  RappiderLabelModule,
  RappiderNumberInputModule,
  RappiderSelectModule,
  RappiderTextboxModule,
  RappiderInputTemplateModule
} from '@rappider/rappider-components';
import { TranslateModule } from '@ngx-translate/core';
import { CustomFunctionComponent } from './custom-function/custom-function.component';
import { RappiderModalModule } from '@rappider/rappider-components';
import { AddParameterValueComponent } from './add-parameter-value/add-parameter-value.component';
import { SelectOperationFieldComponent } from './select-operation-field/select-operation-field.component';
import { TransformationInfoBarComponent } from './transformation-info-bar/transformation-info-bar.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { AddFieldComponent } from './add-field/add-field.component';
import { MapCustomFunctionComponent } from './map-custom-function/map-custom-function.component';
import { JoyrideModule } from 'ngx-joyride';


@NgModule({
  declarations: [
    SchemaTreeSelectComponent,
    SchemaTreeComponent,
    RappiderFunctionComponent,
    CustomFunctionComponent,
    AddParameterValueComponent,
    SelectOperationFieldComponent,
    TransformationInfoBarComponent,
    AddFieldComponent,
    MapCustomFunctionComponent
  ],
  imports: [
    CommonModule,
    NzTreeModule,
    FormsModule,
    TranslateModule,
    NzTreeSelectModule,
    RappiderModalModule,
    NzSelectModule,
    RappiderSelectModule,
    RappiderButtonModule,
    RappiderTextboxModule,
    RappiderLabelModule,
    RappiderNumberInputModule,
    RappiderCheckboxModule,
    RappiderDatePickerModule,
    RappiderCodeEditorModule,
    RappiderHeadingModule,
    NzIconModule,
    RappiderInputTemplateModule,
    JoyrideModule.forChild()
  ],
  exports: [
    SchemaTreeSelectComponent,
    SchemaTreeComponent,
    RappiderFunctionComponent,
    AddParameterValueComponent,
    SelectOperationFieldComponent,
    TransformationInfoBarComponent,
    AddFieldComponent
  ]
})
export class SharedComponentsModule { }
