import { createAction, props } from '@ngrx/store';
import { CommentPartial, CommentWithRelations, DiagramComment, DiagramCommentWithRelations, NewDiagramComment } from '@rappider/rappider-sdk';

export enum ActionTypes {
  GetDiagramComments = '[DiagramComment] GetDiagramComments',
  GetDiagramCommentsSuccessful = '[DiagramComment] GetDiagramCommentsSuccessful',
  GetDiagramCommentsFailure = '[DiagramComment] GetDiagramCommentsFailure',
  CreateDiagramComment = '[DiagramComment] CreateDiagramComment',
  CreateDiagramCommentSuccessful = '[DiagramComment] CreateDiagramCommentSuccessful',
  CreateDiagramCommentFailure = '[DiagramComment] CreateDiagramCommentFailure',
  UpdateDiagramComment = '[DiagramComment] UpdateDiagramComment',
  UpdateDiagramCommentSuccessful = '[DiagramComment] UpdateDiagramCommentSuccessful',
  UpdateDiagramCommentFailure = '[DiagramComment] UpdateDiagramCommentFailure',
  DeleteDiagramComment = '[DiagramComment] DeleteDiagramComment',
  DeleteDiagramCommentSuccessful = '[DiagramComment] DeleteDiagramCommentSuccessful',
  DeleteDiagramCommentFailure = '[DiagramComment] DeleteDiagramCommentFailure',
  CreateUIDiagramComment = '[DiagramComment] CreateUIDiagramComment'
}

export const GetDiagramComments = createAction(ActionTypes.GetDiagramComments);

export const GetDiagramCommentsSuccessful = createAction(
  ActionTypes.GetDiagramCommentsSuccessful,
  props<{ comments: DiagramCommentWithRelations[] }>()
);

export const GetDiagramCommentsFailure = createAction(
  ActionTypes.GetDiagramCommentsFailure,
  props<{ error: any; key: string; timestamp: number }>()
);

export const CreateDiagramComment = createAction(
  ActionTypes.CreateDiagramComment,
  props<{ comment: NewDiagramComment }>()
);

export const CreateDiagramCommentSuccessful = createAction(
  ActionTypes.CreateDiagramCommentSuccessful,
  props<{ comment: CommentWithRelations; diagramComment: DiagramCommentWithRelations }>()
);

export const CreateDiagramCommentFailure = createAction(
  ActionTypes.CreateDiagramCommentFailure,
  props<{ error: any; key: string; timestamp: number }>()
);

export const UpdateDiagramComment = createAction(
  ActionTypes.UpdateDiagramComment,
  props<{ commentId: string; comment: CommentPartial }>()
);

export const UpdateDiagramCommentSuccessful = createAction(
  ActionTypes.UpdateDiagramCommentSuccessful
);

export const UpdateDiagramCommentFailure = createAction(
  ActionTypes.UpdateDiagramCommentFailure,
  props<{ error: any; key: string; timestamp: number }>()
);

export const DeleteDiagramComment = createAction(
  ActionTypes.DeleteDiagramComment,
  props<{ commentId: string; diagrmaCommentId: string }>()
);

export const DeleteDiagramCommentSuccessful = createAction(
  ActionTypes.DeleteDiagramCommentSuccessful,
  props<{ commentId: string; diagramCommentId: string }>()
);

export const DeleteDiagramCommentFailure = createAction(
  ActionTypes.DeleteDiagramCommentFailure,
  props<{ error: any; key: string; timestamp: number }>()
);

export const CreateUIDiagramComment = createAction(
  ActionTypes.CreateUIDiagramComment,
  props<{ comment: NewDiagramComment }>()
);

