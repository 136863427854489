/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ComponentDefinition } from '../models/component-definition';
import { ComponentDefinitionUpdateDto } from '../models/component-definition-update-dto';
import { ComponentDefinitionWithRelations } from '../models/component-definition-with-relations';
import { DataSchemaField } from '../models/data-schema-field';
import { NewComponentDefinition } from '../models/new-component-definition';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class ComponentDefinitionControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation componentDefinitionControllerCount
   */
  static readonly ComponentDefinitionControllerCountPath = '/component-definitions/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params?: {
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentDefinitionControllerService.ComponentDefinitionControllerCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params?: {
    where?: any;
  }): Observable<LoopbackCount> {

    return this.count$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation componentDefinitionControllerGetAllWithDetails
   */
  static readonly ComponentDefinitionControllerGetAllWithDetailsPath = '/component-definitions/get-all-with-details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllWithDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllWithDetails$Response(params?: {
  }): Observable<StrictHttpResponse<ComponentDefinitionWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentDefinitionControllerService.ComponentDefinitionControllerGetAllWithDetailsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ComponentDefinitionWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllWithDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllWithDetails(params?: {
  }): Observable<ComponentDefinitionWithRelations> {

    return this.getAllWithDetails$Response(params).pipe(
      map((r: StrictHttpResponse<ComponentDefinitionWithRelations>) => r.body as ComponentDefinitionWithRelations)
    );
  }

  /**
   * Path part for operation componentDefinitionControllerGetBrokenFieldsFromComponentDefinitionId
   */
  static readonly ComponentDefinitionControllerGetBrokenFieldsFromComponentDefinitionIdPath = '/component-definitions/get-broken-fields/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBrokenFieldsFromComponentDefinitionId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBrokenFieldsFromComponentDefinitionId$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<Array<DataSchemaField>>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentDefinitionControllerService.ComponentDefinitionControllerGetBrokenFieldsFromComponentDefinitionIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DataSchemaField>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBrokenFieldsFromComponentDefinitionId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBrokenFieldsFromComponentDefinitionId(params: {
    id: string;
  }): Observable<Array<DataSchemaField>> {

    return this.getBrokenFieldsFromComponentDefinitionId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DataSchemaField>>) => r.body as Array<DataSchemaField>)
    );
  }

  /**
   * Path part for operation componentDefinitionControllerGetByIdWithDetails
   */
  static readonly ComponentDefinitionControllerGetByIdWithDetailsPath = '/component-definitions/get-by-id-with-details/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getByIdWithDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByIdWithDetails$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<ComponentDefinitionWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentDefinitionControllerService.ComponentDefinitionControllerGetByIdWithDetailsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ComponentDefinitionWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getByIdWithDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByIdWithDetails(params: {
    id: string;
  }): Observable<ComponentDefinitionWithRelations> {

    return this.getByIdWithDetails$Response(params).pipe(
      map((r: StrictHttpResponse<ComponentDefinitionWithRelations>) => r.body as ComponentDefinitionWithRelations)
    );
  }

  /**
   * Path part for operation componentDefinitionControllerDeprecateById
   */
  static readonly ComponentDefinitionControllerDeprecateByIdPath = '/component-definitions/{id}/deprecate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deprecateById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deprecateById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentDefinitionControllerService.ComponentDefinitionControllerDeprecateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deprecateById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deprecateById(params: {
    id: string;
  }): Observable<void> {

    return this.deprecateById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation componentDefinitionControllerFindById
   */
  static readonly ComponentDefinitionControllerFindByIdPath = '/component-definitions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<ComponentDefinition>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentDefinitionControllerService.ComponentDefinitionControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ComponentDefinition>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
    filter?: any;
  }): Observable<ComponentDefinition> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<ComponentDefinition>) => r.body as ComponentDefinition)
    );
  }

  /**
   * Path part for operation componentDefinitionControllerDeleteById
   */
  static readonly ComponentDefinitionControllerDeleteByIdPath = '/component-definitions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentDefinitionControllerService.ComponentDefinitionControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: string;
  }): Observable<void> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation componentDefinitionControllerUpdateById
   */
  static readonly ComponentDefinitionControllerUpdateByIdPath = '/component-definitions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    body?: ComponentDefinitionUpdateDto
  }): Observable<StrictHttpResponse<ComponentDefinition>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentDefinitionControllerService.ComponentDefinitionControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ComponentDefinition>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    body?: ComponentDefinitionUpdateDto
  }): Observable<ComponentDefinition> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<ComponentDefinition>) => r.body as ComponentDefinition)
    );
  }

  /**
   * Path part for operation componentDefinitionControllerFind
   */
  static readonly ComponentDefinitionControllerFindPath = '/component-definitions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<ComponentDefinition>>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentDefinitionControllerService.ComponentDefinitionControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ComponentDefinition>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<ComponentDefinition>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ComponentDefinition>>) => r.body as Array<ComponentDefinition>)
    );
  }

  /**
   * Path part for operation componentDefinitionControllerCreate
   */
  static readonly ComponentDefinitionControllerCreatePath = '/component-definitions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    body?: NewComponentDefinition
  }): Observable<StrictHttpResponse<ComponentDefinition>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentDefinitionControllerService.ComponentDefinitionControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ComponentDefinition>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    body?: NewComponentDefinition
  }): Observable<ComponentDefinition> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<ComponentDefinition>) => r.body as ComponentDefinition)
    );
  }

}
