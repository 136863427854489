export const LEAD_SOURCES = [
  { 'key': 'Internet Search', 'value': 'Internet Search' },
  { 'key': 'Social Media', 'value': 'Social Media' },
  { 'key': 'Friend or Family Referral', 'value': 'Friend or Family Referral' },
  { 'key': 'Online Advertisement', 'value': 'Online Advertisement' },
  { 'key': 'Television Advertisement', 'value': 'Television Advertisement' },
  { 'key': 'Radio Advertisement', 'value': 'Radio Advertisement' },
  { 'key': 'Print Advertisement', 'value': 'Print Advertisement' },
  { 'key': 'Email Newsletter', 'value': 'Email Newsletter' },
  { 'key': 'Event or Trade Show', 'value': 'Event or Trade Show' },
  { 'key': 'Direct Mail', 'value': 'Direct Mail' },
  { 'key': 'Podcast', 'value': 'Podcast' },
  { 'key': 'Blog or Article', 'value': 'Blog or Article' },
  { 'key': 'Webinar or Online Course', 'value': 'Webinar or Online Course' },
  { 'key': 'Other', 'value': 'Other' }
];
