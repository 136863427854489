/* eslint-disable no-shadow */
import { Action } from '@ngrx/store';
import { ProjectTheme, ProjectThemePartial } from '@rappider/rappider-sdk';

export enum ActionTypes {
  GetProjectThemes = '[ProjectTheme] GetProjectThemes',
  GetProjectThemesSuccessful = '[ProjectTheme] GetProjectThemesSuccessful',
  GetProjectThemesFailure = '[ProjectTheme] GetProjectThemesFailure',
  CreateProjectTheme = '[ProjectTheme] CreateProjectTheme',
  CreateProjectThemeSuccessful = '[ProjectTheme] CreateProjectThemeSuccessful',
  UpdateProjectTheme = '[ProjectTheme] UpdateProjectTheme',
  UpdateProjectThemeSuccessful = '[ProjectTheme] UpdateProjectThemeSuccessful',
  DeleteProjectTheme = '[ProjectTheme] DeleteProjectTheme',
  DeleteProjectThemeSuccessful = '[ProjectTheme] DeleteProjectThemeSuccessful',
  ProjectThemeError = '[ProjectTheme] ProjectThemeError',
}

export class GetProjectThemes implements Action {
  readonly type = ActionTypes.GetProjectThemes;
}

export class GetProjectThemesSuccessful implements Action {
  readonly type = ActionTypes.GetProjectThemesSuccessful;
  constructor(public payload: { projectThemes: ProjectTheme[] }) { }
}

export class GetProjectThemesFailure implements Action {
  readonly type = ActionTypes.GetProjectThemesFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class CreateProjectTheme implements Action {
  readonly type = ActionTypes.CreateProjectTheme;
  constructor(public payload: { projectTheme: ProjectTheme }) { }
}

export class CreateProjectThemeSuccessful implements Action {
  readonly type = ActionTypes.CreateProjectThemeSuccessful;
  constructor(public payload: { projectTheme: ProjectTheme }) { }
}

export class UpdateProjectTheme implements Action {
  readonly type = ActionTypes.UpdateProjectTheme;
  constructor(public payload: { projectThemeId: string; projectTheme: ProjectThemePartial; navigateToProjectThemeList?: boolean }) { }
}

export class UpdateProjectThemeSuccessful implements Action {
  readonly type = ActionTypes.UpdateProjectThemeSuccessful;
  constructor(public payload: { updatedProjectTheme: ProjectTheme }) { }
}

export class DeleteProjectTheme implements Action {
  readonly type = ActionTypes.DeleteProjectTheme;
  constructor(public payload: { projectThemeId: string }) { }
}

export class DeleteProjectThemeSuccessful implements Action {
  readonly type = ActionTypes.DeleteProjectThemeSuccessful;
  constructor(public payload: { deletedProjectThemeId: string }) { }
}

export class ProjectThemeError implements Action {
  readonly type = ActionTypes.ProjectThemeError;
  constructor(public payload: { errorOn: string; error?: any }) { }
}

export type Actions =
  GetProjectThemes
  | GetProjectThemesSuccessful
  | GetProjectThemesFailure
  | CreateProjectTheme
  | CreateProjectThemeSuccessful
  | UpdateProjectTheme
  | UpdateProjectThemeSuccessful
  | DeleteProjectTheme
  | DeleteProjectThemeSuccessful
  | ProjectThemeError
  ;
