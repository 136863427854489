import { FullPaths } from '../../definitions';

export const PROJECT_HOME_FEATURES_CONFIG = [
  /* Project Settings */
  {
    icon: 'fa-solid fa-sliders-up fa-3x',
    title: 'Project Settings',
    description: 'Enable/disable features, add project or environment variables to control your project by conditions and environments',
    subFeatures: [
      {
        title: 'Project Settings & Variables',
        path: FullPaths.ProjectSettings
      },
      {
        title: 'Environment Variables',
        path: FullPaths.EnvironmentVariables
      },
      {
        title: 'Project Documents',
        path: FullPaths.ProjectDocument
      },
      {
        title: 'Project Management',
        path: FullPaths.AgileProjectManagement,
        isVisible: false
      },
      {
        title: 'Comments',
        path: FullPaths.ClientComments
      },
      {
        title: 'Categories',
        path: FullPaths.Categories
      },
      {
        title: 'Assets',
        path: FullPaths.Assets
      }
    ]
  },
  /* Data Modeling */
  {
    icon: 'fa-solid fa-database fa-3x',
    title: 'Data Modeling',
    description: `Create data models for your project and manage your data easy and fast. APIs, SDK & administration pages code
          can be automatically generated by using your data models.`,
    subFeatures: [
      {
        title: 'Data Modeling Diagram',
        path: FullPaths.DatabaseDiagram
      },
      {
        title: 'Data Models',
        path: FullPaths.DataModels
      },
      {
        title: 'Data Transfer Schemas',
        path: FullPaths.DataSchemas
      },
      {
        title: 'OpenAPIs',
        path: FullPaths.OpenApi,
        isVisible: false
      },
      {
        title: 'Endpoints',
        path: FullPaths.Endpoint
      }
    ]
  },
  /* 'User Interface  */
  {
    icon: 'fa-solid fa-browsers fa-3x',
    title: 'User Interface',
    description: 'Add, pages, modules and layouts to your application. Manage page content with components by content editor.',
    subFeatures: [
      {
        title: 'Pages',
        path: FullPaths.Pages
      },
      {
        title: 'Modules',
        path: FullPaths.Modules
      },
      {
        title: 'Layouts',
        path: FullPaths.Layouts
      },
      {
        title: 'Theme',
        path: FullPaths.ThemeList
      },
      {
        title: 'Styles',
        path: FullPaths.CSStyleCustumization
      }
    ]
  },
  /* Business Process Design */
  {
    icon: 'fa-solid fa-gears fa-3x',
    title: 'Business Process Design',
    description: 'Design you application logic by adding events and step functions.',
    subFeatures: [
      {
        title: 'Frontend Process Diagram',
        path: FullPaths.DiagramEditor
      },
      {
        title: 'Server-Side Process Diagram',
        path: FullPaths.WorkflowDiagram
      },
      {
        title: 'Client (UI) Data Stores',
        path: FullPaths.UIDataStores
      },
      {
        title: 'Events',
        path: FullPaths.Events
      },
      {
        title: 'Client (UI) Data Store Update Functions',
        path: FullPaths.UIDataStoreUpdateFunctions
      },
      {
        title: 'Client (UI) Step Functions',
        path: FullPaths.UIStepFunctions
      },
      {
        title: 'Workflow Functions',
        path: FullPaths.WorkflowFunctions
      }
    ]
  },
  /* Messaging */
  {
    icon: 'fa-solid fa-envelope fa-3x',
    title: 'Messaging',
    description: 'Manage messaging and message templates which will be used to generate email and short text messages content',
    subFeatures: [
      {
        title: 'Message Templates',
        path: FullPaths.MessageTemplates
      }
    ]
  },
  /* External Packages & Scripts */
  {
    icon: 'fa-solid fa-layer-plus fa-3x',
    title: 'External Packages & Scripts',
    description: 'Add your custom scripts and styles. You can add 3rd party scripts to the project here.',
    subFeatures: [
      {
        title: 'Project Packages',
        path: FullPaths.ProjectPackages
      },
      {
        title: 'Embedded Scripts',
        path: FullPaths.EmbeddedScripts
      }
    ]
  },
  /* Versioning & Code Generation */
  {
    icon: 'fa-solid fa-code-branch fa-3x',
    title: 'Versioning & Code Generation',
    description: 'Generate, version and save your code',
    subFeatures: [
      {
        title: 'Versions',
        path: FullPaths.Versions
      },
    ]
  },
  /* Users */
  {
    icon: 'fa-solid fa-users fa-3x',
    title: 'Users',
    description: 'Add, delete and invite users to the project development',
    subFeatures: [
      {
        title: 'Users & Members',
        path: FullPaths.ProjectMembers
      },
      {
        title: 'Test Users',
        path: FullPaths.ProjectTestMembers,
        isVisible: false
      },
      {
        title: 'Roles',
        path: FullPaths.ProjectRoles
      },
      {
        title: 'Invite Users',
        path: FullPaths.InviteUsers
      }
    ]
  }
];


export const PROJECT_MENU_CONFIG = [
  /* Data Modeling */
  {
    title: 'Data Modeling',
    description: `Create data models for your project and manage your data easy and fast. APIs, SDK & administration pages code
          can be automatically generated by using your data models.`,
    subFeatures: [
      {
        title: 'Data Modeling Diagram',
        path: FullPaths.DatabaseDiagram
      },
      {
        title: 'Data Models',
        path: FullPaths.DataModels
      },
      {
        title: 'Data Transfer Schemas',
        path: FullPaths.DataSchemas
      },
      {
        title: 'OpenAPIs',
        path: FullPaths.OpenApi,
        isVisible: false
      },
      {
        title: 'Endpoints',
        path: FullPaths.Endpoint
      }
    ]
  },
  {
    title: 'User Interface',
    description: 'Add, pages, modules and layouts to your application. Manage page content with components by content editor.',
    subFeatures: [
      {
        title: 'Pages',
        path: FullPaths.Pages
      },
      {
        title: 'Modules',
        path: FullPaths.Modules
      },
      {
        title: 'Layouts',
        path: FullPaths.Layouts
      },
      {
        title: 'Theme',
        path: FullPaths.ThemeList
      },
      {
        title: 'Styles',
        path: FullPaths.CSStyleCustumization
      }
    ]
  },
  /* Business Process Design */
  {
    title: 'Business Process',
    description: 'Design you application logic by adding events and step functions.',
    subFeatures: [
      {
        title: 'Business Processes',
        path: FullPaths.DiagramEditor
      },
      {
        title: 'Client (UI) Data Stores',
        path: FullPaths.UIDataStores
      },
      {
        title: 'Events',
        path: FullPaths.Events
      },
      {
        title: 'Client (UI) Data Store Update Functions',
        path: FullPaths.UIDataStoreUpdateFunctions
      },
      {
        title: 'Client (UI) Step Functions',
        path: FullPaths.UIStepFunctions
      },
      {
        title: 'Server Step Functions',
        path: FullPaths.ServerStepFunctions
      }
    ]
  },
  /* Project Settings */
  {
    title: 'Settings',
    description: 'Enable/disable features, add project or environment variables to control your project by conditions and environments',
    subFeatures: [
      {
        title: 'Project Settings & Variables',
        path: FullPaths.ProjectSettings
      },
      {
        title: 'Environment Variables',
        path: FullPaths.EnvironmentVariables
      },
      {
        title: 'Message Templates',
        path: FullPaths.MessageTemplates
      },
      {
        title: 'Embedded Scripts',
        path: FullPaths.EmbeddedScripts
      },
      {
        title: 'Project Packages',
        path: FullPaths.ProjectPackages
      },
      {
        title: 'Versions',
        path: FullPaths.Versions
      },
    ]
  },
  /* Users */
  {
    title: 'Other',
    description: 'Add, delete and invite users to the project development',
    subFeatures: [
      {
        title: 'Users & Members',
        path: FullPaths.ProjectMembers
      },
      {
        title: 'Test Users',
        path: FullPaths.ProjectTestMembers
      },
      {
        title: 'Roles',
        path: FullPaths.ProjectRoles
      },
      {
        title: 'Invite Users',
        path: FullPaths.InviteUsers
      },
      {
        title: 'Project Documents',
        path: FullPaths.ProjectDocument
      },
      {
        title: 'Project Management',
        path: FullPaths.AgileProjectManagement,
        isVisible: false
      },
    ]
  }
];
