import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SubscribeToUiDataStoreService {

  setUIDataSubscriptionBody(uiDataSubscriptionData) {
    return uiDataSubscriptionData?.map(data => ({
      uiDataStoreId: data.uiDataStore.id,
      dataSchemaFieldIds: data.dataSchemaField.map(subscriptionTreeNode => subscriptionTreeNode.id)
    }));
  }
}
