import { Validators } from '@angular/forms';
import { ButtonType, CrudFormConfig, CrudViewFormItemType, FormLayout } from '@rappider/rappider-components/utils';
import { KEBAB_CASE_REGEX, PASCAL_CASE_ONLY_LETTER_REGEX, TITLE_CASE_DATA_FIELD_NAME_REGEX } from '../../definitions/regex';
import { MONACO_MARKDOWN_CONFIG } from '../monaco-markdown-config';

export const PROJECT_MODEL_CREATE_OR_EDIT_CONFIG: CrudFormConfig = {
  items: [
    {
      title: 'PROJECT_MODULE.PROJECT_MODEL_CREATE_COMPONENT.PROJECT_MODEL_NAME',
      fieldName: 'name',
      type: CrudViewFormItemType.TextBox,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          type: Validators.pattern(TITLE_CASE_DATA_FIELD_NAME_REGEX),
          errorKey: 'pattern',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.PROJECT_MODEL_NAME_ERROR'
        }
      ],
      showRequiredSign: true
    },
    {
      title: 'PROJECT_MODULE.PROJECT_MODEL_CREATE_COMPONENT.BASE_PATH',
      fieldName: 'basePath',
      type: CrudViewFormItemType.TextBox,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          type: Validators.pattern(KEBAB_CASE_REGEX),
          errorKey: 'pattern',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.MUST_BE_KEBAB_CASE'
        }
      ],
      showRequiredSign: true
    },
    {
      title: 'PROJECT_MODULE.PROJECT_MODEL_CREATE_COMPONENT.DATA_TABLE_NAME',
      fieldName: 'dataTableName',
      type: CrudViewFormItemType.TextBox,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ],
      showRequiredSign: true
    },
    {
      title: 'PROJECT_MODULE.PROJECT_MODEL_LIST_COMPONENT.SCOPE',
      fieldName: 'scope',
      type: CrudViewFormItemType.StringArray
    },
    {
      title: 'PROJECT_MODULE.PROJECT_MODEL_LIST_COMPONENT.IS_STRICT',
      fieldName: 'isStrict',
      type: CrudViewFormItemType.CheckBox
    },
    {
      title: 'PROJECT_MODULE.PROJECT_MODEL_LIST_COMPONENT.GENERATE_UI_DATA_STORE',
      fieldName: 'generateUIDataStore',
      type: CrudViewFormItemType.CheckBox
    },
    {
      title: 'SHARED.DESCRIPTION',
      fieldName: 'description',
      type: CrudViewFormItemType.TextArea
    }
  ],
  formLayout: FormLayout.Vertical,
  submitButton: {
    type: ButtonType.Primary
  }
};

