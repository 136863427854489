import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TitleToolbarComponentConfig } from '@rappider/rappider-components/utils';
import { Subscription } from 'rxjs';
import { Module, Project } from '@rappider/rappider-sdk';
import { Router, NavigationEnd } from '@angular/router';
import { PAGE_DEFINITIONS, PATH_DEFINITIONS, defaultToolbarTitleHeadingSize } from '@rappider/shared/definitions';

@Component({
  selector: 'rappider-module',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.scss']
})
export class ModuleComponent implements OnInit, OnDestroy {
  titleToolbarConfig: TitleToolbarComponentConfig;
  subscriptions: Subscription[] = [];

  activeProject: Project;
  modules: Module[];
  activeModule: Module;
  url: string;

  constructor(
    private store: Store<any>,
    private router: Router
  ) {
    this.subscriptions.push(this.subscribeToRouterEvents());
  }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions.push(
      this.subscribeToActiveProject(),
      this.subscribeToModules(),
    );
  }

  subscribeToRouterEvents() {
    return this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
        this.setTitle();
      }
    });
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe(activeProject => {
      this.activeProject = activeProject;
      if (this.activeProject) {
        this.setTitle();
      }
    });
  }

  subscribeToModules() {
    return this.store.select(state => state.module?.data).subscribe((modules: Module[]) => {
      if (modules) {
        this.modules = modules;
        if (this.modules) {
          this.setTitle();
        }
      }
    });
  }

  setTitle() {
    if (this.url) {
      if (this.url === '/modules/list' && this.activeProject) {
        this.titleToolbarConfig = {
          mainTitle: {
            content: PAGE_DEFINITIONS.MODULE.CHILDREN.MODULE_LIST.PAGE_TITLE,
            type: defaultToolbarTitleHeadingSize
          },
          options: [
            {
              label: this.activeProject?.name,
              redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
            },
            {
              label: PAGE_DEFINITIONS.MODULE.CHILDREN.MODULE_LIST.PAGE_TITLE
            }
          ]
        };
      } else if (this.url === '/modules/create' && this.activeProject) {
        this.titleToolbarConfig = {
          mainTitle: {
            content: PAGE_DEFINITIONS.MODULE.CHILDREN.MODULE_CREATE.PAGE_TITLE,
            type: defaultToolbarTitleHeadingSize
          },
          options: [
            {
              label: this.activeProject?.name,
              redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
            },
            {
              label: PAGE_DEFINITIONS.MODULE.CHILDREN.MODULE_LIST.PAGE_TITLE,
              redirectUrl: PATH_DEFINITIONS.MODULE.MODULE_LIST_PATH
            },
            {
              label: PAGE_DEFINITIONS.MODULE.CHILDREN.MODULE_CREATE.PAGE_TITLE
            }
          ]
        };
      } else if (this.url.includes('edit') && this.activeProject && this.modules) {
        const moduleId = this.url.split('/')[3];
        this.activeModule = this.modules.find(module => module.id === moduleId);
        this.titleToolbarConfig = {
          mainTitle: {
            content: PAGE_DEFINITIONS.MODULE.CHILDREN.MODULE_EDIT.PAGE_TITLE,
            type: defaultToolbarTitleHeadingSize
          },
          options: [
            {
              label: this.activeProject?.name,
              redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
            },
            {
              label: PAGE_DEFINITIONS.MODULE.NAME,
              redirectUrl: PATH_DEFINITIONS.MODULE.MODULE_LIST_PATH
            },
            {
              label: this.activeModule?.name,
            },
            {
              label: PAGE_DEFINITIONS.MODULE.CHILDREN.MODULE_EDIT.PAGE_TITLE
            }
          ]
        };
      } else if (this.url.includes('detail') && this.activeProject && this.modules) {
        const moduleId = this.url.split('/')[3];
        this.activeModule = this.modules.find(module => module.id === moduleId);
        this.titleToolbarConfig = {
          mainTitle: {
            content: PAGE_DEFINITIONS.MODULE.CHILDREN.MODULE_DETAIL.PAGE_TITLE,
            type: defaultToolbarTitleHeadingSize
          },
          options: [
            {
              label: this.activeProject?.name,
              redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
            },
            {
              label: PAGE_DEFINITIONS.MODULE.NAME,
              redirectUrl: PATH_DEFINITIONS.MODULE.MODULE_LIST_PATH
            },
            {
              label: this.activeModule?.name
            },
            {
              label: PAGE_DEFINITIONS.MODULE.CHILDREN.MODULE_DETAIL.PAGE_TITLE
            }
          ]
        };
      }
    }
  }
}
