import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { NzTreeNode } from 'ng-zorro-antd/tree';
import { SchemaTreeSelectComponent } from '../../shared-components/schema-tree-select/schema-tree-select.component';
import { Clipboard } from '@angular/cdk/clipboard';
import * as jsf from 'json-schema-faker';
import * as jsonata from 'jsonata';
import { JsonSchemaService } from 'libs/components/src/lib/services';
import { JoyrideService } from 'ngx-joyride';
import { formatJsonata } from '@stedi/prettier-plugin-jsonata/dist/lib';

@Component({
  selector: 'rappider-data-mapping-wrapper',
  templateUrl: './data-mapping-wrapper.component.html',
  styleUrls: ['./data-mapping-wrapper.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => DataMappingWrapperComponent),
      multi: true
    }
  ]
})
export class DataMappingWrapperComponent implements OnInit, OnChanges, ControlValueAccessor {

  @ViewChild('schemaTreeSelect') schemaTreeSelectComponent: SchemaTreeSelectComponent;

  /**
   * this tree converted from json schema with the function called buildTreeFromJSONSchema from SchemaTreeService
   *
   * @type {NzTreeNode[]}
   * @memberof DataMappingWrapperComponent
   */
  @Input() targetTree: NzTreeNode;
  /* source json schema */
  @Input() sourceSchema: any;
  /* targetSchema */
  @Input() targetSchema: any;
  @Input() mapStepsFlag: boolean;

  @Output() dataMappingOutputChange = new EventEmitter();
  @Output() showStepsFlag = new EventEmitter<{ flag: boolean; key: string }>();

  selectedTargetField: string;
  selectedSourceField: string;
  selectedMappingPreview: string;
  isPreviewCopied = false;
  monacoEditorVisiblity = false;

  _value: any;
  get value() {
    return this._value;
  }

  set value(value: any) {
    this._value = {
      ...value,
      script: value?.script || ''
    };
    this.setPreviewOfJsonataConfig();
    this.onChange(value);
    this.onTouched();
  }

  mappingScriptOutput = null;
  sourceSchemaSample: string;
  displayTargetSchemaSample = true;
  targetSchemaSampleLoading = false;
  targetSchemaSample: string;

  outputPreviewEditorOptions = {
    theme: 'vs-default',
    language: '',
    minimap: { autohide: true, enabled: false },
    lineNumbers: { renderType: 0 },
    readOnly: true
  };

  selectedMappingPreviewEditorOptions = {
    theme: 'vs-default',
    language: '',
    minimap: { autohide: true, enabled: false },
    lineNumbers: { renderType: 0 },
  };

  jsonSampleEditorOptions = {
    theme: 'vs-default',
    language: 'json',
    minimap: { autohide: true, enabled: false },
    lineNumbers: { renderType: 0 }
  };

  constructor(
    private clipboard: Clipboard,
    private jsonSchemaService: JsonSchemaService,
    private readonly joyrideService: JoyrideService
  ) { }

  ngOnInit(): void {
    /* for render issues */
    setTimeout(() => {
      this.monacoEditorVisiblity = true;
    }, 100);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sourceSchema && this.sourceSchema) {
      if (typeof this.sourceSchema === 'string') {
        this.sourceSchema = JSON.parse(this.sourceSchema);
      }
      this.setSourceJsonSample();
    }
    if (changes.targetSchema && this.targetSchema) {
      this.targetSchemaSampleLoading = true;
      if (typeof this.targetSchema === 'string') {
        this.targetSchema = JSON.parse(this.targetSchema);
      }
      this.setTargetJsonSample();
    }
    if (changes.mapStepsFlag) {
      this.showSteps();
    }
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: any) {
    this._value = {
      ...value,
      script: value?.script || ''
    };
    this.setPreviewOfJsonataConfig();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setSourceJsonSample() {
    this.jsonSchemaService.generateJsonSampleFromJsonSchema(this.sourceSchema).then(res => {
      if (res) {
        // this.sourceSchemaSample = JSON.stringify(field.isArray ? [sample] : sample) || '';
        this.sourceSchemaSample = JSON.stringify(res);
      }
    }, (e) => {
      this.sourceSchemaSample = `Error: \n\n ${e}`;
    });
  }

  setTargetJsonSample() {
    this.jsonSchemaService.generateJsonSampleFromJsonSchema(this.targetSchema).then(res => {
      if (res) {
        // this.targetSchemaSample = JSON.stringify(field.isArray ? [sample] : sample) || '';
        this.targetSchemaSample = JSON.stringify(res);
      }
      this.targetSchemaSampleLoading = false;
    }, (e) => {
      this.targetSchemaSample = `Error: \n\n ${e}`;
      this.targetSchemaSampleLoading = false;
    });
  }

  resetSelectedFields() {
    this.selectedSourceField = null;
    this.selectedTargetField = null;
  }

  onClickAddDataMapping() {
    if (this.selectedMappingPreview) {
      if (this.value.script) {
        this.clipboard.copy(this.selectedMappingPreview);
        this.onPreviewCopied();
      } else {
        this.value.script = (this.value.script ? this.value.script + '\n' : '') + this.selectedMappingPreview;
        this.resetSelectedFields();
        this.selectedMappingPreview = null;
      }
    }
  }

  updateSelectedMappingPreview() {
    if (this.selectedTargetField && this.selectedSourceField) {
      const selectedTargetFieldWithoutFirstElement = cloneDeep(this.selectedTargetField.split('.'));

      const valueAssignedObject = this.assingValueToNestedField(selectedTargetFieldWithoutFirstElement, this.selectedSourceField);
      if (valueAssignedObject) {
        this.selectedMappingPreview = this.removeLastWordQuotesInJson(valueAssignedObject);
      }
    }
  }

  removeLastWordQuotesInJson(obj) {
    const stringifiedJson = JSON.stringify(obj);
    const w = stringifiedJson.split('"');
    const w1 = w.pop();
    const w2 = w.pop();
    return w.join('"') + w2 + w1;
  }


  assingValueToNestedField(keys: string[], value: string) {
    const object = {};
    let current = object;
    keys.forEach((key, i) => {
      if (i !== keys?.length - 1) {
        current[key] = {};
        current = current[key];
      }
    });
    current[keys[keys?.length - 1]] = value;
    return object;
  }

  onMappingScriptValueChange() {
    this.setPreviewOfJsonataConfig();
    this.value = {
      ...this.value,
      script: this.value.script,
      operator: 'map'
    };
  }

  async setPreviewOfJsonataConfig() {
    if (this.value.script && this.value.script !== '') {
      try {
        const exampleJsonObject = JSON.parse(this.sourceSchemaSample);
        const output = await jsonata(this.value.script).evaluate(exampleJsonObject);
        if (output == null) {
          this.mappingScriptOutput = 'Invalid Script. Check the key that entered from Source Sample or Enter data in JSON Format';
        } else {
          this.mappingScriptOutput = JSON.stringify(output);
        }
        this.dataMappingOutputChange.emit(this.mappingScriptOutput);
      } catch (e) {
        this.mappingScriptOutput = (await e)?.message;
      }
    } else {
      this.mappingScriptOutput = 'Enter data in JSON Format or Copy/Paste a key from Source Sample to see Output';
    }
  }

  onSourceKeySelected(key: string) {
    this.selectedSourceField = key;
    this.updateSelectedMappingPreview();
  }

  onSelectedTargetFieldChange(key: string) {
    this.selectedTargetField = key;
    this.updateSelectedMappingPreview();
  }

  onPreviewCopied() {
    this.isPreviewCopied = true;
    setTimeout(() => this.isPreviewCopied = false, 2000);
  }

  showSteps() {
    if (this.mapStepsFlag) {
      this.joyrideService.startTour(
        { steps: ['mapFirstStep', 'mapSecondStep', 'mapThirdStep', 'mapFourthStep', 'mapFifthStep'] }
      );
      this.mapStepsFlag = false;
      this.showStepsFlag.emit({ flag: this.mapStepsFlag, key: 'map' });
    }
  }

  onClickTargetSchemaSwitcher() {
    this.displayTargetSchemaSample = !this.displayTargetSchemaSample;
  }

  async onClickImportSample() {
    this.value.script = await formatJsonata(this.targetSchemaSample);
  }
}
