import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { formatJsonata } from '@stedi/prettier-plugin-jsonata/dist/lib';
import { ButtonComponentConfig, ButtonSize } from '../../utils/button';
import { IconType } from '../../utils/icon';

@Component({
  selector: 'rappider-jsonata-editor',
  templateUrl: './jsonata-editor.component.html',
  styleUrls: ['./jsonata-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => RappiderJsonataEditorComponent),
      multi: true
    }
  ]
})
export class RappiderJsonataEditorComponent implements ControlValueAccessor {

  editorOptions = {
    theme: 'vs-default',
    language: 'jsonata',
    minimap: { autohide: true, enabled: false },
    lineNumbers: { renderType: 0 }
  };

  toolbarButtons: ButtonComponentConfig[] = [
    {
      key: 'format-jsonata',
      text: 'Format Jsonata',
      icon: {
        name: 'fa-regular fa-align-left',
        type: IconType.FontAwesome
      },
      size: ButtonSize.Small
    }
  ];

  _value: string;

  get value() {
    return this._value;
  }

  set value(value: string) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: any): void {
    this._value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onToolbarButtonClick(button: ButtonComponentConfig) {
    if (button.key === 'format-jsonata') {
      this.formatJsonata();
    }
  }

  async formatJsonata() {
    this.value = await formatJsonata(this.value);
  }

}
