import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjectInterface } from '@rappider/api-sdk';
import { BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { StringUtilityService } from '@rappider/services';
import { PAGE_LAYOUT_CREATE_FORM_CONFIG } from '@rappider/shared/configs';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Subscription } from 'rxjs';
import { CreateLayout } from '../../state/layout-state/layout.actions';
import { Module, NewPage } from '@rappider/rappider-sdk';

@Component({
  selector: 'rappider-page-layout-create',
  templateUrl: './page-layout-create.component.html',
  styleUrls: ['./page-layout-create.component.scss']
})
export class PageLayoutCreateComponent implements OnInit, OnDestroy {
  /* main title */
  mainTitle: HeadingComponentConfig;
  /* component title */
  title: string | string[] | BreadcrumbOption[];
  /* active project information */
  activeProject: ProjectInterface;
  /* page create config */
  PAGE_LAYOUT_CREATE_FORM_CONFIG = PAGE_LAYOUT_CREATE_FORM_CONFIG;
  /* module options */
  moduleOptions: { key: string; value: string }[];
  /* subscriptions */
  subscriptions: Subscription[];

  layoutCreateData = {
    name: null,
    moduleId: ''
  };

  displayToolbar = false;
  displayToolbarBackButton = false;

  hasNameChanged = false;
  loading: boolean;

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private store: Store<any>,
    // eslint-disable-next-line no-empty-function
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToModules(),
      this.subscribeToLoading()
    ];
  }

  /**
   * subscribe to active project
   *
   * @return {*}
   * @memberof PageLayoutCreateComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe(activeProject => {
      this.activeProject = activeProject;
      if (activeProject) {
        /* sets the title */
        this.mainTitle = {
          content: PAGE_DEFINITIONS.PAGES.CHILDREN.PAGE_LAYOUTS_CREATE.PAGE_TITLE,
          type: defaultToolbarTitleHeadingSize
        };
        this.title = [
          {
            label: activeProject.name,
            redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${activeProject?.id}`
          },
          {
            label: PAGE_DEFINITIONS.PAGES.CHILDREN.PAGE_LAYOUTS_LIST.PAGE_TITLE,
            redirectUrl: PATH_DEFINITIONS.PAGES.PAGE_LAYOUT_LIST_PATH
          },
          {
            label: PAGE_DEFINITIONS.PAGES.CHILDREN.PAGE_LAYOUTS_CREATE.PAGE_TITLE
          }
        ];
      }
    });
  }

  subscribeToModules() {
    return this.store.select(state => state.module?.data).subscribe((modules: Module[]) => {
      this.moduleOptions = modules?.map(module => ({
        key: module.name,
        value: module.id
      })) ?? [];
      if (modules) {
        this.setDefaultModuleInDefaultPageData(modules);
      }
    });
  }

  subscribeToLoading() {
    return this.store.select(state => state.layout.loading).subscribe(loading => {
      this.loading = loading;
    });
  }

  setDefaultModuleInDefaultPageData(modules: Module[]) {
    const defaultModule = modules?.find(module => module.name === 'Main') ?? modules[0];

    this.layoutCreateData.moduleId = defaultModule.id;
    this.layoutCreateData = { ...this.layoutCreateData };
  }

  onCreateLayoutFormValueChange(layout) {
    if ((layout.title || layout.title === '') && !this.hasNameChanged) {
      this.layoutCreateData = {
        ...this.layoutCreateData,
        name: StringUtilityService.toPascalCase(layout.title),
      };
    }
    if (layout.name) {
      this.layoutCreateData = {
        ...this.layoutCreateData,
        name: layout.name,
      };
      this.hasNameChanged = true;
    }
  }

  /**
   * on form submit function for page layout create - triggered when clicked save
   *
   * @param {PageInterface} layoutData
   * @memberof PageLayoutCreateComponent
   */
  createLayout(layout: NewPage) {
    if (this.activeProject && layout) {
      layout = {
        ...layout,
        moduleId: this.layoutCreateData.moduleId
      };
      this.store.dispatch(new CreateLayout({ layout: layout }));
    }
  }
}
