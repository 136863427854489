import { createAction, props } from '@ngrx/store';
import { PersonInvitationInviteRequestDto, PersonInvitationWithRelations } from '@rappider/rappider-sdk';

export enum ActionTypes {
  GetInvitedUsers = '[UserInvitation] GetInvitedUsers',
  GetInvitedUsersSuccessful = '[UserInvitation] GetInvitedUsersSuccessful',
  GetInvitedUsersFailure = '[UserInvitation] GetInvitedUsersFailure',
  GetInvitedUsersById = '[UserInvitation] GetInvitedUsersById',
  GetInvitedUsersByIdSuccessful = '[UserInvitation] GetInvitedUsersByIdSuccessful',
  GetInvitedUsersByIdFailure = '[UserInvitation] GetInvitedUsersByIdFailure',
  InviteUser = '[UserInvitation] InviteUser',
  InviteUserSuccessful = '[UserInvitation] InviteUserSuccessful',
  InviteUserFailure = '[UserInvitation] InviteUserFailure',
  ResendInvitation = '[UserInvitation] ResendInvitation',
  ResendInvitationFailure = '[UserInvitation] ResendInvitationFailure',
  CancelInvitation = '[UserInvitation] CancelInvitation',
  CancelInvitationFailure = '[UserInvitation] CancelInvitationFailure'
}

export const GetInvitedUsers = createAction(ActionTypes.GetInvitedUsers);

export const GetInvitedUsersSuccessful = createAction(
  ActionTypes.GetInvitedUsersSuccessful,
  props<{ payload: { invitedUsers: PersonInvitationWithRelations[] } }>()
);

export const GetInvitedUsersFailure = createAction(
  ActionTypes.GetInvitedUsersFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const GetInvitedUsersById = createAction(
  ActionTypes.GetInvitedUsersById,
  props<{ payload: { invitationId: string } }>()
);

export const GetInvitedUsersByIdSuccessful = createAction(
  ActionTypes.GetInvitedUsersByIdSuccessful,
  props<{ payload: { invitedUser: PersonInvitationWithRelations } }>()
);

export const GetInvitedUsersByIdFailure = createAction(
  ActionTypes.GetInvitedUsersByIdFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const InviteUser = createAction(
  ActionTypes.InviteUser,
  props<{ payload: { invitedUser: PersonInvitationInviteRequestDto } }>()
);

export const InviteUserSuccessful = createAction(
  ActionTypes.InviteUserSuccessful,
  props<{ payload: { invitedUser: PersonInvitationWithRelations } }>()
);

export const InviteUserFailure = createAction(
  ActionTypes.InviteUserFailure,
  props<{ payload: { error: any; key: string; timestamp: number } }>()
);

export const ResendInvitation = createAction(
  ActionTypes.ResendInvitation,
  props<{ payload: { invitationId: string } }>()
);

export const ResendInvitationFailure = createAction(
  ActionTypes.ResendInvitationFailure,
  props<{ payload: { error: any; key: string; timestamp: number } }>()
);

export const CancelInvitation = createAction(
  ActionTypes.CancelInvitation,
  props<{ payload: { invitationId: string } }>()
);

export const CancelInvitationFailure = createAction(
  ActionTypes.CancelInvitationFailure,
  props<{ payload: { error: any; key: string; timestamp: number } }>()
);
