<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton"
                        [displayBreadcrumb]="displayBreadcrumb"
                        [titleBarActionButtons]="titleBarActionButtons"
                        (titleBarActionButtonClick)="onTitleBarActionButtonClick()"></rappider-title-toolbar>

<div *ngIf="addUiStepFunctionButton?.visible"
     class="add-step-function-button">
  <rappider-button [text]="addUiStepFunctionButton.text"
                   [size]="addUiStepFunctionButton.size"
                   [block]="addUiStepFunctionButton.block"
                   [icon]="addUiStepFunctionButton.icon"
                   (click)="onAddUiStepFunctionToDataEvent()"></rappider-button>
</div>

<rappider-spin [spinning]="!uiDataEvent">
  <rappider-crud-view-edit-form [config]="UI_DATA_EVENT_EDIT_CONFIG"
                                [data]="uiDataEvent"
                                (fieldValueChange)="onDataEventFieldValueChange($event)"
                                (formSubmit)="onUpdateUIDataEvent($event)"></rappider-crud-view-edit-form>
</rappider-spin>
