<rappider-page-wrapper [titleToolbarConfig]="titleToolbarConfig">
  <section class="d-flex">
    <rappider-button text="Create a New Theme"
                     (click)="navigateToCreateThemePage()"></rappider-button>
  </section>
  <rappider-spin [spinning]="loading">
    <section>
      <div class="theme-list-wrapper row">
        <div *ngFor="let theme of themes"
             class="col-lg-4 col-md-6 col-sm-12 pb-4">
          <section class="theme-card">
            <div class="theme-preview-thumbnail"
                 [id]="theme.id"
                 (click)="navigateToProjectTheme(theme.id)">
              <rappider-example-template-two [showTooltip]="false"></rappider-example-template-two>
              <div *ngIf="theme.id === activeProject?.activeProjectThemeId"
                   class="active-theme-icon">
                <rappider-icon [name]="activeThemeIcon.name"
                               [type]="activeThemeIcon.type"></rappider-icon>
              </div>
            </div>
            <div class="theme-card-body">
              <rappider-tag *ngIf="theme.id === activeProject?.activeProjectThemeId"
                            [text]="tagConfig.text"
                            [color]="tagConfig.color"></rappider-tag>
              <h4>
                {{theme.name}}
              </h4>
              <h6>
                {{
                theme.description?.length > 36
                ? (theme.description | slice:0:36) + '...'
                : theme.description
                }}
              </h6>
            </div>
            <div class="theme-card-footer">
              <button nz-button
                      nz-dropdown
                      nzTrigger="click"
                      [nzDropdownMenu]="menu">
                <div class="button-with-icon">
                  <i class="fa-solid fa-ellipsis"></i>
                  <p class="mr-2">More Settings</p>
                </div>
              </button>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu>
                  <li *ngIf="theme.id !== activeProject?.activeProjectThemeId"
                      nz-menu-item
                      (click)="onProjectThemeCardActionClick(ProjectThemeActionKeys.SetAsActive, theme.id)">
                    <div class="button-with-icon justify-content-start">
                      <i class="fa-light fa-circle-check"></i>
                      <p>Set as active</p>
                    </div>
                  </li>
                  <li nz-menu-item
                      (click)="onProjectThemeCardActionClick(ProjectThemeActionKeys.Edit, theme.id)">
                    <div class="button-with-icon justify-content-start">
                      <i class="fa-light far fa-edit"></i>
                      <p>Edit</p>
                    </div>
                  </li>
                  <li *ngIf="theme.id !== activeProject?.activeProjectThemeId"
                      nz-menu-item
                      nz-popconfirm
                      nzPopconfirmTitle="Are you sure you want to delete this project theme?"
                      (nzOnConfirm)="onProjectThemeCardActionClick(ProjectThemeActionKeys.Delete, theme.id)">
                    <div class="button-with-icon justify-content-start">
                      <i class="fa-light far fa-trash"></i>
                      <p>Delete</p>
                    </div>
                  </li>
                </ul>
              </nz-dropdown-menu>
              <button nz-button
                      (click)="navigateToProjectTheme(theme.id)">
                <div class="button-with-icon">
                  <i class="fa-solid fa-arrow-up-right-from-square"></i>
                  <p class="mr-2">Go to Theme</p>
                </div>
              </button>
            </div>
          </section>
        </div>
      </div>
    </section>
  </rappider-spin>
</rappider-page-wrapper>