export const PROJECT_DETAIL_CONFIG = [
  {
    icon: {
      type: 'FONT_AWESOME',
      name: 'fa-solid fa-comment'
    },
    title: 'Project Comments',
    path: 'comments'
  },
  {
    icon: {
      name: 'fa-solid fa-file-lines',
      type: 'FONT_AWESOME'
    },
    title: 'Project Pages',
    path: 'project-pages'
  },
  {
    icon: {
      name: 'fa-solid fa-user-group',
      type: 'FONT_AWESOME'
    },
    title: 'Project Members',
    path: 'project-members'
  }
];
