<div class="form-area">
  <form nz-form
        [formGroup]="updateProjectModelFieldForm"
        [nzLayout]="formLayout"
        (ngSubmit)="onUpdateProjectModelFieldFormSubmit()">

    <!-- Name -->
    <nz-form-item>
      <nz-form-label nzRequired>
        {{ 'SHARED.NAME' | translate }}
      </nz-form-label>
      <nz-form-control>
        <rappider-textbox formControlName="name"></rappider-textbox>
      </nz-form-control>
    </nz-form-item>

    <!-- Base Path -->
    <nz-form-item>
      <nz-form-label nzRequired>
        {{ 'SHARED.TYPE' | translate }}
      </nz-form-label>
      <nz-form-control>
        <rappider-select formControlName="type"
                         [options]="typeSelectConfig.options"
                         [settings]="typeSelectConfig.settings"></rappider-select>
      </nz-form-control>
    </nz-form-item>

    <!-- Is Array -->
    <nz-form-item>
      <nz-form-label>
        {{ 'SHARED.IS_ARRAY' | translate }}
      </nz-form-label>
      <nz-form-control>
        <rappider-switch formControlName="isArray"></rappider-switch>
      </nz-form-control>
    </nz-form-item>

    <nz-collapse nzGhost>
      <nz-collapse-panel nzHeader="Advanced Settings (Optional)"
                         nzActive="false">
        <!-- Default -->
        <nz-form-item>
          <nz-form-label>
            {{ 'SHARED.DEFAULT' | translate }}
          </nz-form-label>
          <nz-form-control>
            <rappider-textbox formControlName="default"></rappider-textbox>
          </nz-form-control>
        </nz-form-item>

        <!-- Default Function -->
        <nz-form-item>
          <nz-form-label>
            {{ 'PROJECT_MODULE.PROJECT_MODEL_DATA_FIELD_CREATE_COMPONENT.DEFAULT_FN' | translate }}
          </nz-form-label>
          <nz-form-control>
            <rappider-select formControlName="defaultFn"
                             [options]="defaultFnSelectConfig.options"></rappider-select>
          </nz-form-control>
        </nz-form-item>

        <!-- Required -->
        <nz-form-item>
          <nz-form-label>
            {{ 'SHARED.REQUIRED' | translate }}
          </nz-form-label>
          <nz-form-control>
            <rappider-switch formControlName="isRequired"></rappider-switch>
          </nz-form-control>
        </nz-form-item>

        <!-- Hidden -->
        <nz-form-item>
          <nz-form-label>
            {{ 'SHARED.HIDDEN' | translate }}
          </nz-form-label>
          <nz-form-control>
            <rappider-switch formControlName="isHidden"></rappider-switch>
          </nz-form-control>
        </nz-form-item>

        <!-- Unique -->
        <nz-form-item>
          <nz-form-label>
            {{ 'PROJECT_MODULE.PROJECT_MODEL_DATA_FIELD_CREATE_COMPONENT.UNIQUE' | translate }}
          </nz-form-label>
          <nz-form-control>
            <rappider-switch formControlName="isUnique"></rappider-switch>
          </nz-form-control>
        </nz-form-item>

        <!-- Description -->
        <nz-form-item>
          <nz-form-label>
            {{ 'SHARED.DESCRIPTION' | translate }}
          </nz-form-label>
          <nz-form-control>
            <div style="height: 200px;">
              <rappider-monaco-code-editor formControlName="description"
                                           [options]="monacoEditorConfig.options"></rappider-monaco-code-editor>
            </div>
          </nz-form-control>
        </nz-form-item>
      </nz-collapse-panel>
    </nz-collapse>

    <!-- Submit Button -->
    <nz-form-item [ngClass]="isSubmitButtonFloat ? 'float-submit-button' : 'submit-button'">
      <nz-form-control>
        <button nz-button
                type="submit"
                nzType="primary"
                [nzLoading]="submitButtonLoading">
          {{ 'SHARED.SUBMIT' | translate }}
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</div>
