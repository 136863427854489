import { failureLottieAnimation } from '../../lottie-animations/failure-lottie-animation';
import { successfulLottieAnimation } from '../../lottie-animations/successful-lottie-animation';
import { ProjectExportEventType } from '../project-export-event-type.enum';
import { ProjectExportProcessStatus } from '../project-export-process-status.enum';

export const SQLAlchemyProjectExportProcesses = [

  {
    status: ProjectExportProcessStatus.Processing,
    key: ProjectExportEventType.ExportSQLAlchemy,
    animation: {
      options: {
        path: 'assets/animations/backend-exporting.json'
      },
      height: '100%',
      width: '100%'
    }
  },
  {
    status: ProjectExportProcessStatus.Successful,
    key: ProjectExportEventType.ExportSQLAlchemySuccessful,
    animation: successfulLottieAnimation
  },
  {
    status: ProjectExportProcessStatus.Failure,
    key: ProjectExportEventType.ExportSQLAlchemyFailure,
    animation: failureLottieAnimation
  }

];
