export const MARGIN_STYLE_NAME = '--margin';
export const HEIGHT_STYLE_NAME = '--height';
export const WIDTH_STYLE_NAME = '--width';
export const PADDING_STYLE_NAME = '--padding';
export const BORDER_RADIUS_NAME = '--border-radius';
export const FONT_SIZE_NAME = '--font-size';

export const MARGIN_STYLE_NAME_X = '--margin-x';
export const HEIGHT_STYLE_NAME_X = '--height-x';
export const WIDTH_STYLE_NAME_X = '--width-x';
export const PADDING_STYLE_NAME_X = '--padding-x';
export const BORDER_RADIUS_NAME_X = '--border-radius-x';
export const FONT_SIZE_NAME_X = '--font-size-x';

export const multipliedValue = (i, coefficientValue) => `${i * coefficientValue}px`;
export const getStyleVariableName = (styleVariableName, i) => `${styleVariableName}-${i}x`;
