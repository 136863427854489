import { PATH_DEFINITIONS } from '../../definitions/path-definition';
import { FeedbackButtonActionBehavior, IconComponentConfig, IconType } from '@rappider/rappider-components/utils';

export const FORGOT_PASSWORD_SUCCESS_FEEDBACK_CONFIG = {
  title: 'SHARED.DONE!',
  icon: <IconComponentConfig>{
    name: 'far fa-check-circle text-success',
    type: IconType.FontAwesome,
    color: 'green'
  },
  subTitle: 'AUTHENTICATION_MODULE.FORGOT_PASSWORD_COMPONENT.RENEWAL_LINK_MESSAGE',
  buttons: [
    {
      content: 'SHARED.LOGIN_PAGE',
      actionBehavior: FeedbackButtonActionBehavior.Route,
      redirectUrl: PATH_DEFINITIONS.AUTH.LOGIN_PATH
    }
  ]
};

export const FORGOT_PASSWORD_ERROR_FEEDBACK_CONFIG = {
  title: 'SHARED.ERROR',
  icon: <IconComponentConfig>{
    name: 'fal fa-exclamation-circle',
    type: IconType.FontAwesome,
    color: 'red'
  },
  subTitle: 'AUTHENTICATION_MODULE.FORGOT_PASSWORD_COMPONENT.PASSWORD_LINK_SEND_ERROR',
  buttons: [
    {
      content: 'SHARED.LOGIN_PAGE',
      actionBehavior: FeedbackButtonActionBehavior.Route,
      redirectUrl: PATH_DEFINITIONS.AUTH.LOGIN_PATH
    }
  ]
};
