import { createAction, props } from '@ngrx/store';
import { PageWithRelations } from '@rappider/rappider-sdk';
import { StateError } from 'libs/shared/src/lib/interfaces/state-error.interface';

/* action types */
export enum ActionTypes {
  GetAdminPagesByFilter = '[AdminPage] GetAdminPagesByFilter',
  GetAdminPagesByFilterSuccessful = '[AdminPage] GetAdminPagesByFilterSuccessful',
  GetAdminPagesByFilterFailure = '[AdminPage] GetAdminPagesByFilterFailure',
}

export const GetAdminPagesByFilter = createAction(
  ActionTypes.GetAdminPagesByFilter,
  props<{ payload: { filter: any } }>()
);

export const GetAdminPagesByFilterSuccessful = createAction(
  ActionTypes.GetAdminPagesByFilterSuccessful,
  props<{ payload: { pages: PageWithRelations[] } }>()
);

export const GetAdminPagesByFilterFailure = createAction(
  ActionTypes.GetAdminPagesByFilterFailure,
  props<StateError>()
);
