import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class NgrxHelperService {


  /**
   * Bu fonksiyon CRUD işlemlerinden sonra bize state'i güncellemek için yardımcı olur.
   * TODO: Belki daha güzel hale getirilebilir
   *
   * @param {any[]} dataArray
   * @param {number} index
   * @param {*} newData
   * @returns
   * @memberof ngrxHelperService
   */
  updateStateField(dataArray: any[], index: number, newData: any) {
    const newDataArray = cloneDeep(dataArray);
    newDataArray.splice(index, 1, newData);
    return newDataArray;
  }

}
