import { SelectableOption } from '@rappider/rappider-components/utils';

export const SLOT_TYPE_CONFIG: SelectableOption[] = [
  {
    key: 'Doesnt Have Slots',
    value: null
  },
  {
    key: 'Has Named (Developer Generated) Slots',
    value: true
  },
  {
    key: 'End User Generated Slots',
    value: false
  }
];
