/* eslint-disable no-empty-function */
/* eslint-disable no-shadow */
import { Action } from '@ngrx/store';
import {
  ProjectModelField,
  ProjectModelWithRelations,
  ProjectModel,
  UiDataStoreWithRelations,
  NewProjectModel,
  ProjectModelPartial,
  ProjectModelRelation
} from '@rappider/rappider-sdk';

export enum ActionTypes {
  /* Project Model Actions */
  GetProjectModels = '[ProjectModel] GetProjectModels',
  GetProjectModelsSuccessful = '[ProjectModel] GetProjectModelsSuccessful',
  GetProjectModelWithModelId = '[ProjectModel] GetProjectModelWithModelId',
  GetProjectModelWithModelIdSuccessful = '[ProjectModel] GetProjectModelWithModelIdSuccessful',
  GetProjectModelWithModelIdFailure = '[ProjectModel] GetProjectModelWithModelIdFailure',
  CreateProjectModel = '[ProjectModel] CreateProjectModel',
  CreateProjectModelSuccessful = '[ProjectModel] CreateProjectModelSuccessful',
  UpdateProjectModel = '[ProjectModel] UpdateProjectModel',
  UpdateProjectModelSuccessful = '[ProjectModel] UpdateProjectModelSuccessful',
  DeleteProjectModel = '[ProjectModel] DeleteProjectModel',
  DeleteProjectModelSuccessful = '[ProjectModel] DeleteProjectModelSuccessful',
  CreateProjectModelsFromJSON = '[ProjectModel] CreateProjectModelsFromJSON',
  CreateProjectModelsFromJSONSuccessful = '[ProjectModel] CreateProjectModelsFromJSONSuccessful',
  ToggleCreateProjectModelsFromJSONModal = '[ProjectModel] ToggleCreateProjectModelsFromJSONModal',
  GenerateDataStore = '[ProjectModel] GenerateDataStore',
  GenerateDataStoreSuccessful = '[ProjectModel] GenerateDataStoreSuccessful',
  GenerateDataStoreFailure = '[ProjectModel] GenerateDataStoreFailure',
  CreateProjectModelsFromPostgreMetadata = '[ProjectModel] CreateProjectModelsFromPostgreMetadata',
  CreateProjectModelsFromPostgreMetadataSuccessful = '[ProjectModel] CreateProjectModelsFromPostgreMetadataSuccessful',
  /* Project Model Field Actions */
  CreateProjectModelField = '[ProjectModel] CreateProjectModelField',
  CreateProjectModelFieldSuccessful = '[ProjectModel] CreateProjectModelFieldSuccessful',
  CreateProjectModelFieldFailure = '[ProjectModel] CreateProjectModelFieldFailure',
  UpdateProjectModelField = '[ProjectModel] UpdateProjectModelField',
  UpdateProjectModelFieldSuccessful = '[ProjectModel] UpdateProjectModelFieldSuccessful',
  UpdateProjectModelFieldFailure = '[ProjectModel] UpdateProjectModelFieldFailure',
  DeleteProjectModelField = '[ProjectModel] DeleteProjectModelField',
  DeleteProjectModelFieldSuccessful = '[ProjectModel] DeleteProjectModelFieldSuccessful',
  DeleteProjectModelFieldFailure = '[ProjectModel] DeleteProjectModelFieldFailure',
  /* Project Model Relation Actions */
  UpdateProjectModelWithRelations = '[ProjectModel] UpdateProjectModelWithRelations',
  /* Create Crud Pages From Models Actions */
  CreateCrudPagesForProjectModel = '[ProjectModel] CreateCrudPagesForProjectModel',
  CreateCrudPagesForProjectModelSuccessful = '[ProjectModel] CreateCrudPagesForProjectModelSuccessful',
  CreateCrudPagesForProjectModelFailure = '[ProjectModel] CreateCrudPagesForProjectModelFailure',
  /* Error action */
  ErrorAction = '[ProjectModel] ErrorAction',
}

export class GetProjectModels implements Action {
  readonly type = ActionTypes.GetProjectModels;
}

export class GetProjectModelsSuccessful implements Action {
  readonly type = ActionTypes.GetProjectModelsSuccessful;
  constructor(public payload: { projectModels: ProjectModelWithRelations[] }) { }
}

export class GetProjectModelWithModelId implements Action {
  readonly type = ActionTypes.GetProjectModelWithModelId;
  constructor(public payload: { id: string; tryCount: number; isCrudPagesGenerated: boolean }) { }
}

export class GetProjectModelWithModelIdSuccessful implements Action {
  readonly type = ActionTypes.GetProjectModelWithModelIdSuccessful;
  constructor(public payload: { projectModel: ProjectModelWithRelations }) { }
}

export class GetProjectModelWithModelIdFailure implements Action {
  readonly type = ActionTypes.GetProjectModelWithModelIdFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class CreateProjectModel implements Action {
  readonly type = ActionTypes.CreateProjectModel;
  constructor(public payload: { projectModel: NewProjectModel; navigateAfterCreate: boolean }) { }
}

export class CreateProjectModelSuccessful implements Action {
  readonly type = ActionTypes.CreateProjectModelSuccessful;
  constructor(public payload: { projectModel: ProjectModel }) { }
}

export class UpdateProjectModel implements Action {
  readonly type = ActionTypes.UpdateProjectModel;
  constructor(public payload: { id: string; projectModel: ProjectModelPartial; navigateAfterUpdate: boolean }) { }
}

export class UpdateProjectModelSuccessful implements Action {
  readonly type = ActionTypes.UpdateProjectModelSuccessful;
  constructor(public payload: { id: string; projectModel: ProjectModel }) { }
}

export class UpdateProjectModelWithRelations implements Action {
  readonly type = ActionTypes.UpdateProjectModelWithRelations;
  constructor(public payload: { projectModelRelations: ProjectModelRelation[] }) { }
}

export class DeleteProjectModel implements Action {
  readonly type = ActionTypes.DeleteProjectModel;
  constructor(public payload: { id: string }) { }
}

export class DeleteProjectModelSuccessful implements Action {
  readonly type = ActionTypes.DeleteProjectModelSuccessful;
  constructor(public payload: { id: string }) { }
}

export class CreateProjectModelField implements Action {
  readonly type = ActionTypes.CreateProjectModelField;
  constructor(public payload: { projectModelId: string; projectModelField: ProjectModelField }) { }
}

export class CreateProjectModelFieldSuccessful implements Action {
  readonly type = ActionTypes.CreateProjectModelFieldSuccessful;
  constructor(public payload: { createdProjectModelField: ProjectModelField }) { }
}

export class CreateProjectModelFieldFailure implements Action {
  readonly type = ActionTypes.CreateProjectModelFieldFailure;
  constructor(public payload: { error: any }) { }
}

export class UpdateProjectModelField implements Action {
  readonly type = ActionTypes.UpdateProjectModelField;
  constructor(public payload: { projectModelFieldId: string; projectModelField: ProjectModelField; relationedTypeId: string }) { }
}

export class UpdateProjectModelFieldSuccessful implements Action {
  readonly type = ActionTypes.UpdateProjectModelFieldSuccessful;
  constructor(public payload: { updatedProjectModelField: ProjectModelField }) { }
}

export class UpdateProjectModelFieldFailure implements Action {
  readonly type = ActionTypes.UpdateProjectModelFieldFailure;
  constructor(public payload: { error: any }) { }
}

export class DeleteProjectModelField implements Action {
  readonly type = ActionTypes.DeleteProjectModelField;
  constructor(public payload: { deletedProjectModelField: ProjectModelField; projectModelId: string }) { }
}

export class DeleteProjectModelFieldSuccessful implements Action {
  readonly type = ActionTypes.DeleteProjectModelFieldSuccessful;
  constructor(public payload: { deletedProjectModelFieldId: string; projectModelId: string }) { }
}

export class DeleteProjectModelFieldFailure implements Action {
  readonly type = ActionTypes.DeleteProjectModelFieldFailure;
  constructor(public payload: { error: any }) { }
}

export class CreateProjectModelsFromJSON implements Action {
  readonly type = ActionTypes.CreateProjectModelsFromJSON;
  constructor(public payload: { modelName: string; exampleData: any }) { }
}

export class CreateProjectModelsFromJSONSuccessful implements Action {
  readonly type = ActionTypes.CreateProjectModelsFromJSONSuccessful;
}

export class CreateProjectModelsFromPostgreMetadata implements Action {
  readonly type = ActionTypes.CreateProjectModelsFromPostgreMetadata;
  constructor(public payload: { postgreMetadata: any }) { }
}

export class CreateProjectModelsFromPostgreMetadataSuccessful implements Action {
  readonly type = ActionTypes.CreateProjectModelsFromPostgreMetadataSuccessful;
}

export class ToggleCreateProjectModelsFromJSONModal implements Action {
  readonly type = ActionTypes.ToggleCreateProjectModelsFromJSONModal;
}

export class CreateCrudPagesForProjectModel implements Action {
  readonly type = ActionTypes.CreateCrudPagesForProjectModel;
  constructor(public payload: { projectModel: ProjectModelWithRelations; layoutId?: string; replaceExistingCrudPages?: boolean }) { }
}

export class CreateCrudPagesForProjectModelSuccessful implements Action {
  readonly type = ActionTypes.CreateCrudPagesForProjectModelSuccessful;
  constructor(public payload: { projectModel: ProjectModelWithRelations }) { }
}

export class CreateCrudPagesForProjectModelFailure implements Action {
  readonly type = ActionTypes.CreateCrudPagesForProjectModelFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class ErrorAction implements Action {
  readonly type = ActionTypes.ErrorAction;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class GenerateDataStore implements Action {
  readonly type = ActionTypes.GenerateDataStore;
  constructor(public payload: { projectModelId: string }) { }
}

export class GenerateDataStoreSuccessful implements Action {
  readonly type = ActionTypes.GenerateDataStoreSuccessful;
  constructor(public payload: { projectModelId: string; generatedUIDataStore: UiDataStoreWithRelations }) { }
}

export class GenerateDataStoreFailure implements Action {
  readonly type = ActionTypes.GenerateDataStoreFailure;
  constructor(public payload: { error: any; key: string; timestamp: number; projectModelId: string }) { }
}

export type Actions = GetProjectModels
  | GetProjectModelsSuccessful
  | GetProjectModelWithModelId
  | GetProjectModelWithModelIdSuccessful
  | GetProjectModelWithModelIdFailure
  | CreateProjectModel
  | CreateProjectModelSuccessful
  | UpdateProjectModel
  | UpdateProjectModelSuccessful
  | DeleteProjectModel
  | DeleteProjectModelSuccessful
  | CreateProjectModelField
  | CreateProjectModelFieldSuccessful
  | CreateProjectModelFieldFailure
  | UpdateProjectModelField
  | UpdateProjectModelFieldSuccessful
  | UpdateProjectModelFieldFailure
  | UpdateProjectModelWithRelations
  | DeleteProjectModelField
  | DeleteProjectModelFieldSuccessful
  | DeleteProjectModelFieldFailure
  | CreateProjectModelsFromJSON
  | CreateProjectModelsFromJSONSuccessful
  | ToggleCreateProjectModelsFromJSONModal
  | CreateCrudPagesForProjectModel
  | CreateCrudPagesForProjectModelSuccessful
  | CreateCrudPagesForProjectModelFailure
  | GenerateDataStore
  | GenerateDataStoreSuccessful
  | GenerateDataStoreFailure
  | CreateProjectModelsFromPostgreMetadata
  | CreateProjectModelsFromPostgreMetadataSuccessful
  | ErrorAction;
