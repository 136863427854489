import { createAction, props } from '@ngrx/store';
import { NewPageVariable, PageVariable, PartialPageVariable } from '@rappider/rappider-sdk';

export enum ActionTypes {
  GetPageVariables = '[PageVariables] GetPageVariables',
  GetPageVariablesSuccessful = '[PageVariables] GetPageVariablesSuccessful',
  CreatePageVariable = '[PageVariables] CreatePageVariable',
  CreatePageVariableSuccessful = '[PageVariables] CreatePageVariableSuccessful',
  UpdatePageVariables = '[PageVariables] UpdatePageVariables',
  UpdatePageVariablesSuccessful = '[PageVariables] UpdatePageVariablesSuccessful',
  DeletePageVariable = '[PageVariables] DeletePageVariable',
  DeletePageVariableSuccessful = '[PageVariables] DeletePageVariableSuccessful',
  Error = '[PageVariables] Error'
}

export const GetPageVariables = createAction(ActionTypes.GetPageVariables);

export const GetPageVariablesSuccessful = createAction(
  ActionTypes.GetPageVariablesSuccessful,
  props<{ payload: { pageVariables: PageVariable[] } }>()
);

export const CreatePageVariable = createAction(
  ActionTypes.CreatePageVariable,
  props<{ payload: { pageVariable: NewPageVariable } }>()
);

export const CreatePageVariableSuccessful = createAction(
  ActionTypes.CreatePageVariableSuccessful,
  props<{ payload: { pageVariable: PageVariable } }>()
);

export const UpdatePageVariables = createAction(
  ActionTypes.UpdatePageVariables,
  props<{ payload: { pageVariable: PartialPageVariable; pageVariableId: string } }>()
);

export const UpdatePageVariablesSuccessful = createAction(
  ActionTypes.UpdatePageVariablesSuccessful,
  props<{ payload: { pageVariable: PageVariable; pageVariableId: string } }>()
);

export const DeletePageVariable = createAction(
  ActionTypes.DeletePageVariable,
  props<{ payload: { pageVariableId: string } }>()
);

export const DeletePageVariableSuccessful = createAction(
  ActionTypes.DeletePageVariableSuccessful,
  props<{ payload: { pageVariableId: string } }>()
);

export const Error = createAction(
  ActionTypes.Error,
  props<{ payload: { error: any } }>()
);
