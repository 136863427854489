export enum CustomFunctionCreateEditFormItem {
  ServiceName = 'serviceName',
  FunctionName = 'functionName',
  ProgrammingLanguage = 'programmingLanguage',
  // AddPackage = 'addPackage',
  Packages = 'packages',
  Code = 'code',
  RequestJSONSchema = 'requestJSONSchema',
  ResponseJSONSchema = 'responseJSONSchema',
  MockResponse = 'mockResponse'
}
