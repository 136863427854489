/* actions */
import { createAction, createReducer, on } from '@ngrx/store';
import * as AIAssistantActions from './ai-assistant.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import { orderBy } from 'lodash';
import { ChatMessage } from '@rappider/rappider-sdk';
import { UnsynchronizedMessage } from '../utils/unsynchronized-message.interface';

/* state key */
export const featureKey = 'aiAssistant';

/* state interface */
export interface State {
  chatSessionId: string;
  isAssistantTyping: boolean;
  messages: ChatMessage[];
  isLoaded: boolean;
  unsynchronizedMessages: UnsynchronizedMessage[];
  error?: {
    error: any;
    key: string;
  };
}

export const initialState: State = {
  chatSessionId: '',
  isAssistantTyping: false,
  messages: [],
  unsynchronizedMessages: [],
  isLoaded: false,
  error: undefined
};

const ChangeActiveProject = createAction(AuthenticationActions.ActionTypes.ChangeActiveProject);
const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

export const reducer = createReducer(
  initialState,

  on(AIAssistantActions.GetChatSessionByActiveProject, (state, action) => ({
    ...state,
    isLoaded: false,
  })),
  on(AIAssistantActions.GetChatSessionByActiveProjectSuccessful, (state, action) => ({
    ...state,
    chatSessionId: action.payload.chatSession.id,
    isAssistantTyping: !!action.payload.chatSession.isAssistantTyping,
    messages: orderBy(action.payload.chatSession.messages, ['timestamp'], 'asc'),
    isLoaded: true
  })),
  on(AIAssistantActions.GetChatMessagesByTimestampSuccessful, (state, action) => ({
    ...state,
    messages: orderBy(
      [
        ...state.messages,
        ...(action.payload.chatSession.messages || [])
      ],
      ['timestamp'],
      'asc'
    ),
    unsynchronizedMessages: state.unsynchronizedMessages.filter(message => !message.syncInNextPolling),
    isAssistantTyping: !!action.payload.chatSession.isAssistantTyping,
  })),
  on(AIAssistantActions.CreateChatMessage, (state, action) => ({
    ...state,
    unsynchronizedMessages: [
      ...state.unsynchronizedMessages,
      action.payload.message
    ]
  })),
  on(AIAssistantActions.CreateChatMessageSuccessful, (state, action) => {
    const createdMessage = state.unsynchronizedMessages.find((message: any) => (action.payload.message as any).keyForUI === message.keyForUI);
    return {
      ...state,
      isAssistantTyping: true,
      unsynchronizedMessages: [
        ...state.unsynchronizedMessages.filter((message: any) => (action.payload.message as any).keyForUI !== message.keyForUI),
        {
          ...createdMessage,
          syncInNextPolling: true
        } as UnsynchronizedMessage
      ]
    };
  }),
  on(AIAssistantActions.CreateChatMessageFailure, (state, action) => ({
    ...state,
    messages: state.messages.filter((message: any) => message.keyForUI !== action.payload.messageKey || !message.keyForUI),
    unsynchronizedMessages: state.unsynchronizedMessages.filter((message: any) => message.keyForUI !== action.payload.messageKey)
  })),
  on(AIAssistantActions.Error, (state, action) => ({
    ...state,
    error: action.payload.error
  })),

  on(ChangeActiveProject, () => (initialState)),
  on(Logout, () => (initialState))
);
