import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import {
  ButtonType,
  ActionBehavior,
  CrudViewColumnType,
  CrudTableViewConfig
} from '@rappider/rappider-components/utils';
import { POP_CONFIRM_DELETE_ACTION } from 'libs/shared/src/lib/configs/pop-confirm-button/pop-confirm-button-config';

export const PROJECT_MODEL_RELATION_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'name',
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'PROJECT_MODULE.PROJECT_MODEL_RELATION_LIST_COMPONENT.RELATION_NAME',
      fieldName: 'name',
      type: CrudViewColumnType.Link,
      routerLink: `${PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_RELATION_EDIT}/{{id}}`
    },
    {
      title: 'PROJECT_MODULE.PROJECT_MODEL_RELATION_LIST_COMPONENT.TYPE',
      fieldName: 'type',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Source Model',
      fieldName: 'sourceModel',
      type: CrudViewColumnType.Text
    },
    {
      title: 'PROJECT_MODULE.PROJECT_MODEL_RELATION_LIST_COMPONENT.TARGET_MODEL',
      fieldName: 'targetModel',
      type: CrudViewColumnType.Text
    },
    {
      title: 'PROJECT_MODULE.PROJECT_MODEL_RELATION_LIST_COMPONENT.THROUGH_MODEL',
      fieldName: 'throughModel',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Source Field',
      fieldName: 'sourceField',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Target Field',
      fieldName: 'targetField',
      type: CrudViewColumnType.Text
    }
  ],
  listActions: [
    {
      text: 'PROJECT_MODULE.PROJECT_MODEL_RELATION_CREATE_COMPONENT.ADD_MODEL_RELATION',
      buttonType: ButtonType.Default,
      behavior: ActionBehavior.Emit,
      icon: { name: 'fas fa-plus' }
    }
  ],
  itemActions: [
    {
      text: POP_CONFIRM_DELETE_ACTION.text,
      name: POP_CONFIRM_DELETE_ACTION.name,
      popconfirmTitle: POP_CONFIRM_DELETE_ACTION.popconfirmTitle,
      emitWithoutPopconfirm: POP_CONFIRM_DELETE_ACTION.emitWithoutPopconfirm,
      behavior: POP_CONFIRM_DELETE_ACTION.behavior,
      icon: POP_CONFIRM_DELETE_ACTION.icon,
      popConfirmCancelText: POP_CONFIRM_DELETE_ACTION.popConfirmCancelText,
      popConfirmOkText: POP_CONFIRM_DELETE_ACTION.popConfirmOkText,
      popConfirmOkDanger: POP_CONFIRM_DELETE_ACTION.popConfirmOkDanger,
    }
  ]
};
