import { createAction, props } from '@ngrx/store';
import { NewWorkflowEvent, WorkflowEvent, WorkflowEventPartial, WorkflowEventWithRelations } from '@rappider/rappider-sdk';

/* action types */
export enum ActionTypes {
  CreateWorkflowEvent = '[WorkflowEvent] CreateWorkflowEvent',
  CreateWorkflowEventSuccessful = '[WorkflowEvent] CreateWorkflowEventSuccessful',
  CreateWorkflowEventFailure = '[WorkflowEvent] CreateWorkflowEventFailure',
  GetWorkflowEvent = '[WorkflowEvent] GetWorkflowEvent',
  GetWorkflowEventSuccessful = '[WorkflowEvent] GetWorkflowEventSuccessful',
  GetWorkflowEventFailure = '[WorkflowEvent] GetWorkflowEventFailure',
  UpdateWorkflowEvent = '[WorkflowEvent] UpdateWorkflowEvent',
  UpdateWorkflowEventSuccessful = '[WorkflowEvent] UpdateWorkflowEventSuccessful',
  UpdateWorkflowEventFailure = '[WorkflowEvent] UpdateWorkflowEventFailure',
  DeleteWorkflowEvent = '[WorkflowEvent] DeleteWorkflowEvent',
  DeleteWorkflowEventSuccessful = '[WorkflowEvent] DeleteWorkflowEventSuccessful',
  DeleteWorkflowEventFailure = '[WorkflowEvent] DeleteWorkflowEventFailure',
}

export const CreateWorkflowEvent = createAction(
  ActionTypes.CreateWorkflowEvent,
  /** placeholder data used for diagram */
  props<{ payload: { workflowEvent: NewWorkflowEvent; placeholderData?: { id: string; eventId: string; stepFunctionId: string; type: 'success' | 'failure' | 'subscribe' } } }>()
);

export const CreateWorkflowEventSuccessful = createAction(
  ActionTypes.CreateWorkflowEventSuccessful,
  props<{ payload: { workflowEvent: WorkflowEvent; createdPlaceholderId?: string } }>()
);

export const CreateWorkflowEventFailure = createAction(
  ActionTypes.CreateWorkflowEventFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const GetWorkflowEvent = createAction(
  ActionTypes.GetWorkflowEvent,
);

export const GetWorkflowEventSuccessful = createAction(
  ActionTypes.GetWorkflowEventSuccessful,
  props<{ payload: { workflowEvents: WorkflowEventWithRelations[] } }>()
);

export const GetWorkflowEventFailure = createAction(
  ActionTypes.GetWorkflowEventFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const UpdateWorkflowEvent = createAction(
  ActionTypes.UpdateWorkflowEvent,
  props<{ payload: { workflowEventId: string; workflowEventBody?: WorkflowEventPartial } }>()
);

export const UpdateWorkflowEventSuccessful = createAction(
  ActionTypes.UpdateWorkflowEventSuccessful,
  props<{ payload: { workflowEventId: string; workflowEventBody?: WorkflowEventPartial } }>()
);

export const UpdateWorkflowEventFailure = createAction(
  ActionTypes.UpdateWorkflowEventFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const DeleteWorkflowEvent = createAction(
  ActionTypes.DeleteWorkflowEvent,
  props<{ payload: { workflowEventId: string } }>()
);

export const DeleteWorkflowEventSuccessful = createAction(
  ActionTypes.DeleteWorkflowEventSuccessful,
  props<{ payload: { workflowEventId: string } }>()
);

export const DeleteWorkflowEventFailure = createAction(
  ActionTypes.DeleteWorkflowEventFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

