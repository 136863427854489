import { Injectable } from '@angular/core';
import { DEFAULT_PAGINATION } from 'libs/shared/src/lib/definitions';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {


  /**
   * gets skipped size by page size and page number.
   *
   * @memberof PaginationService
   */
  getSkippedSizeByPagination(pageNumber = 1, pageSize = DEFAULT_PAGINATION.PAGE_SIZE) {
    return (pageNumber - 1) * pageSize;
  }

  getTotalPageNumber(totalCount: number, pageSize = DEFAULT_PAGINATION.PAGE_SIZE) {
    return Math.ceil(totalCount / pageSize);
  }

}
