import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFunctionComponent } from './custom-function/custom-function.component';
import { RappiderCodeEditorModule, RappiderTextboxModule } from '@rappider/rappider-components';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    CustomFunctionComponent
  ],
  imports: [
    CommonModule,
    RappiderTextboxModule,
    RappiderCodeEditorModule,
    FormsModule
  ],
  exports: [
    CustomFunctionComponent
  ]
})
export class CustomFunctionModule { }
