import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzTreeNode } from 'ng-zorro-antd/tree';
import { SchemaTreeService } from '../../../services/schema-tree/schema-tree.service';
import { SchemaTreeSelectComponent } from '../schema-tree-select/schema-tree-select.component';

@Component({
  selector: 'rappider-select-operation-field',
  templateUrl: './select-operation-field.component.html',
  styleUrls: ['./select-operation-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => SelectOperationFieldComponent),
      multi: true
    }
  ]
})
export class SelectOperationFieldComponent implements ControlValueAccessor {

  @ViewChild('treeSelectComponent') treeSelectComponent: SchemaTreeSelectComponent;

  @Input() tree: NzTreeNode[];

  _value;

  operationFieldKey: string;

  constructor(
    private schemaTreeService: SchemaTreeService
  ) { }

  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
    this.onChange(this._value);
    this.onTouched();
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value): void {
    this._value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setOperationField(operationFieldKey) {
    this.value = this.schemaTreeService.getFieldTreeFromField(operationFieldKey, this.treeSelectComponent);
  }

}
