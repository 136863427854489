<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>

<section>

  <div class="page-toolbar">
    <rappider-button [text]="addProjectScriptButton.text"
                     [type]="addProjectScriptButton.type"
                     [icon]="addProjectScriptButton.icon"
                     (click)="navigateAddProjectScript()"></rappider-button>
  </div>

  <rappider-list-grid [config]="PROJECT_SCRIPT_LIST_CONFIG"
                      [data]="scriptListGridData"
                      [loading]="scriptListLoadingState"
                      (columnActionClick)="deleteScript($event)"></rappider-list-grid>
</section>
