import { FlexAlignContentOption } from './flex-align-content-option.enum';
import { FlexAlignItemsOption } from './flex-align-items-option.enum';
import { FlexDirectionOption } from './flex-direction-option.enum';
import { FlexJustifyContentOption } from './flex-justify-content-option.enum';
import { FlexWrapOption } from './flex-wrap-option.enum';
import { ContentTreeItemType } from './content-tree-item-type.enum';
import { ContentTreeContainerDisplayMode } from './content-tree-container-display-mode.enum';

export const defaultContainer = {
    type: ContentTreeItemType.Container,
    displayMode: ContentTreeContainerDisplayMode.Block,
    flexOptions: {
        flexAlignContent: FlexAlignContentOption.FlexStart,
        flexAlignItems: FlexAlignItemsOption.FlexStart,
        flexDirection: FlexDirectionOption.Horizantal,
        flexJustifyContent: FlexJustifyContentOption.FlexStart,
        flexWrap: FlexWrapOption.Wrap
    },
    columnWidth: 12,
    autoColumnWidth: false,
    cssStyle: '',
    cssClasses: [],
    children: [],
    isMainContainer: false,
};
