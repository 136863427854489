import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuidanceComponent } from './guidance/guidance.component';
import { HttpClientModule } from '@angular/common/http';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { RappiderHtmlViewerModule } from '@rappider/rappider-components';

@NgModule({
  declarations: [
    GuidanceComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    NzIconModule,
    NzButtonModule,
    RappiderHtmlViewerModule
  ],
  exports: [
    GuidanceComponent
  ]
})
export class GuidanceModule { }
