<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton"
                        [displayBreadcrumb]="displayBreadcrumb"></rappider-title-toolbar>
<rappider-spin [spinning]="loading">
  <form nz-form
        [formGroup]="createUIStepFunctionForm"
        [nzLayout]="formLayout"
        (ngSubmit)="onCreateUIStepFunction()">
    <!-- UI Data Store -->
    <nz-form-item *ngIf="showUIDataStoreSelection">
      <nz-form-label nzRequired
                     [nzSpan]="calculateFormLayout().label">
        {{ 'SHARED.UI_DATA_STORE' | translate }}
      </nz-form-label>
      <nz-form-control [nzErrorTip]="requiredErrorTemplate"
                       [nzSpan]="calculateFormLayout().input">
        <rappider-select [options]="uiDataStoreSelectOptions"
                         [disabled]="createUIStepFunctionForm.get('uiDataStore').disabled"
                         formControlName="uiDataStore"></rappider-select>
      </nz-form-control>
    </nz-form-item>

    <!-- Name -->
    <nz-form-item>
      <nz-form-label nzRequired
                     [nzSpan]="calculateFormLayout().label">
        {{ 'SHARED.NAME' | translate }}
      </nz-form-label>
      <nz-form-control [nzErrorTip]="nameFieldErrorTemplates"
                       [nzSpan]="calculateFormLayout().input">
        <rappider-textbox formControlName="name"
                          (ngModelChange)="onNameChange($event)"></rappider-textbox>
      </nz-form-control>
    </nz-form-item>

    <!-- Subscribed Events -->
    <nz-form-item>
      <nz-form-label nzRequired
                     [nzSpan]="calculateFormLayout().label">
        {{ 'PROJECT_MODULE.UI_STEP_FUNCTION_COMPONENT.SUBSCRIBED_EVENTS' | translate }}
        <rappider-icon class="info-icon"
                       nz-popover
                       nzPopoverPlacement="rightBottom"
                       [nzPopoverTitle]="FormItemsInfoMessages.subscribedEventsInfo.title"
                       [name]="infoIconConfig.name"
                       [color]="infoIconConfig.color"></rappider-icon>
      </nz-form-label>
      <nz-form-control [nzErrorTip]="requiredErrorTemplate"
                       [nzSpan]="calculateFormLayout().input">
        <rappider-select [options]="subscribedEventsSelectConfig.options"
                         [settings]="subscribedEventsSelectConfig.settings"
                         formControlName="subscribedEvents"
                         [disabled]="createUIStepFunctionForm.get('subscribedEvents').disabled || createUIStepFunctionForm?.value.type === uIWorkflowItemTypeValues.PreDefinedTemplate"
                         (ngModelChange)="onSubscribedEventsChange()"></rappider-select>
      </nz-form-control>
    </nz-form-item>

    <nz-collapse nzGhost="true">
      <nz-collapse-panel nzHeader="Advanced Settings (Optional)"
                         nzActive="false">
        <!-- Type -->
        <nz-form-item>
          <nz-form-label [nzSpan]="calculateFormLayout().label">
            {{ 'SHARED.TYPE' | translate }}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="requiredErrorTemplate"
                           [nzSpan]="calculateFormLayout().input">
            <rappider-select [options]="uiStepFunctionTypes"
                             formControlName="type"></rappider-select>
          </nz-form-control>
        </nz-form-item>

        <!-- Operation Type -->
        <nz-form-item *ngIf="createUIStepFunctionForm?.value.type === uIWorkflowItemTypeValues.ConfigurationDriven">
          <nz-form-label [nzSpan]="calculateFormLayout().label">
            {{ 'Integration Operator Type' | translate }}
            <rappider-icon class="info-icon"
                           nz-popover
                           nzPopoverPlacement="rightBottom"
                           [nzPopoverTitle]="FormItemsInfoMessages.integrationOperatorTypeInfo.title"
                           [name]="infoIconConfig.name"
                           [color]="infoIconConfig.color"></rappider-icon>
          </nz-form-label>
          <nz-form-control [nzErrorTip]="requiredErrorTemplate"
                           [nzSpan]="calculateFormLayout().input">
            <rappider-select [options]="uiStepFunctionIntegrationOperatorTypes"
                             [disabled]="createUIStepFunctionForm.get('integrationOperatorType').disabled"
                             formControlName="integrationOperatorType"></rappider-select>
          </nz-form-control>
        </nz-form-item>

        <!-- Published Events Success -->
        <nz-form-item *ngIf="createUIStepFunctionForm?.value.type === uIWorkflowItemTypeValues.ConfigurationDriven">
          <nz-form-label [nzSpan]="calculateFormLayout().label">
            {{ 'PROJECT_MODULE.UI_STEP_FUNCTION_COMPONENT.PUBLISHED_EVENTS_ON_SUCCESS' | translate }}
            <rappider-icon class="info-icon"
                           nz-popover
                           nzPopoverPlacement="rightBottom"
                           [nzPopoverTitle]="FormItemsInfoMessages.publishedEventsOnSuccessInfo.title"
                           [name]="infoIconConfig.name"
                           [color]="infoIconConfig.color"></rappider-icon>
          </nz-form-label>
          <nz-form-control [nzSpan]="calculateFormLayout().input">
            <rappider-ui-step-function-if-condition *ngIf="subscribedEventId || subscribedFieldIds; else selectSubscribedEvent"
                                                    formControlName="publishedEventsOnSuccess"
                                                    [subscribedEventId]="subscribedEventId"
                                                    [subscribedFieldIds]="subscribedFieldIds"
                                                    [disabled]="createUIStepFunctionForm.get('publishedEventsOnSuccess').disabled"
                                                    [uiDataEvents]="uiDataEventsForSelect">
            </rappider-ui-step-function-if-condition>
            <ng-template #selectSubscribedEvent>
              {{
              'PROJECT_MODULE.UI_STEP_FUNCTION_CREATE_OR_EDIT_COMPONENT.PLEASE_SELECT_SUBSCRIBED_EVENTS_OR_DATA_SUBSCRIPTION'
              | translate
              }}
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <!-- Published Events Failure -->
        <nz-form-item *ngIf="createUIStepFunctionForm?.value.type === uIWorkflowItemTypeValues.ConfigurationDriven">
          <nz-form-label [nzSpan]="calculateFormLayout().label">
            {{ 'PROJECT_MODULE.UI_STEP_FUNCTION_COMPONENT.PUBLISHED_EVENTS_ON_FAILURE' | translate }}
            <rappider-icon class="info-icon"
                           nz-popover
                           nzPopoverPlacement="rightBottom"
                           [nzPopoverTitle]="FormItemsInfoMessages.publishedEventsOnFailureInfo.title"
                           [name]="infoIconConfig.name"
                           [color]="infoIconConfig.color"></rappider-icon>
          </nz-form-label>
          <nz-form-control [nzSpan]="calculateFormLayout().input">
            <rappider-select [options]="publishedEventsOnFailureSelectConfig.options"
                             [settings]="publishedEventsOnFailureSelectConfig.settings"
                             [disabled]="createUIStepFunctionForm.get('publishedEventsOnFailure').disabled"
                             formControlName="publishedEventsOnFailure"></rappider-select>
          </nz-form-control>
        </nz-form-item>

        <!-- Subscribe to UI data store -->
        <nz-form-item *ngIf="createUIStepFunctionForm?.value.type === uIWorkflowItemTypeValues.ConfigurationDriven">
          <nz-form-label [nzSpan]="calculateFormLayout().label">
            {{ 'PROJECT_MODULE.SUBSCRIBE_TO_UI_DATA_STORE_COMPONENT.UI_DATA_STORE_SUBSCRIPTIONS' | translate }}
            <rappider-icon class="info-icon"
                           nz-popover
                           nzPopoverPlacement="rightBottom"
                           [nzPopoverTitle]="FormItemsInfoMessages.uiDataStoreSubscriptionsInfo.title"
                           [name]="infoIconConfig.name"
                           [color]="infoIconConfig.color"></rappider-icon>
          </nz-form-label>
          <nz-form-control [nzSpan]="calculateFormLayout().input">
            <rappider-subscribe-to-ui-data-store [uiDataStores]="uiDataStores"
                                                 formControlName="uiDataSubscriptionBody"
                                                 [disabled]="createUIStepFunctionForm.get('uiDataSubscriptionBody').disabled"
                                                 (ngModelChange)="onSubscriptionChange()">
            </rappider-subscribe-to-ui-data-store>
          </nz-form-control>
        </nz-form-item>

        <!-- Step Function Mode Select -->
        <nz-form-item *ngIf="createUIStepFunctionForm?.value.type === uIWorkflowItemTypeValues.ConfigurationDriven">
          <nz-form-label [nzSpan]="calculateFormLayout().label">
            {{ 'Step Function Mode' | translate }}
          </nz-form-label>
          <nz-form-control [nzSpan]="calculateFormLayout().input">
            <rappider-select [ngModel]="uiWorkflowStepFunctionModeRadioGroupValue"
                             [options]="uiWorkflowStepFunctionModeRadioGroupOptions"
                             [disabled]="createUIStepFunctionForm.get('mode').disabled"
                             formControlName="mode"
                             (ngModelChange)="stepFunctionModeSelectValueChange($event)"></rappider-select>
          </nz-form-control>
        </nz-form-item>

        <!-- Project Model -->
        <nz-form-item
                      *ngIf="uiWorkflowStepFunctionModeRadioGroupValue === StepFunctionTypeOptions.Endpoint && createUIStepFunctionForm?.value.type === uIWorkflowItemTypeValues.ConfigurationDriven">
          <nz-form-label nzRequired
                         [nzSpan]="calculateFormLayout().label">
            {{ 'PROJECT_MODULE.UI_STEP_FUNCTION_CREATE_OR_EDIT_COMPONENT.PROJECT_MODEL_FOR_ENDPOINT' | translate }}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="nameFieldErrorTemplates"
                           [nzSpan]="calculateFormLayout().input">
            <rappider-select [options]="projectModelOptions"
                             [disabled]="createUIStepFunctionForm.get('projectModel').disabled"
                             formControlName="projectModel"
                             (ngModelChange)="onProjectModelValueChange($event)"></rappider-select>
          </nz-form-control>
        </nz-form-item>

        <!-- Endpoint ID -->
        <nz-form-item
                      *ngIf="uiWorkflowStepFunctionModeRadioGroupValue === StepFunctionTypeOptions.Endpoint && createUIStepFunctionForm?.value.type === uIWorkflowItemTypeValues.ConfigurationDriven">
          <nz-form-label nzRequired
                         [nzSpan]="calculateFormLayout().label">
            {{ 'PROJECT_MODULE.UI_STEP_FUNCTION_CREATE_OR_EDIT_COMPONENT.PROJECT_MODEL_ENDPOINT' | translate }}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="nameFieldErrorTemplates"
                           [nzSpan]="calculateFormLayout().input">
            <rappider-select [options]="endpointOptions"
                             [disabled]="createUIStepFunctionForm.get('endpointId').disabled"
                             formControlName="endpointId"></rappider-select>
          </nz-form-control>
        </nz-form-item>

        <!-- Custom Code -->
        <nz-form-item
                      *ngIf="uiWorkflowStepFunctionModeRadioGroupValue === StepFunctionTypeOptions.CustomCode && createUIStepFunctionForm?.value.type === uIWorkflowItemTypeValues.ConfigurationDriven">
          <nz-form-label [nzSpan]="calculateFormLayout().label">
            {{ 'SHARED.CODE' | translate }}
          </nz-form-label>
          <nz-form-control [nzSpan]="calculateFormLayout().input">
            <div style="height: 200px;">
              <rappider-monaco-code-editor [options]="monacoEditorSettings.editorOptions"
                                           formControlName="code"></rappider-monaco-code-editor>
            </div>
          </nz-form-control>
        </nz-form-item>

        <!-- Services -->
        <nz-form-item
                      *ngIf="uiWorkflowStepFunctionModeRadioGroupValue === StepFunctionTypeOptions.WorkflowServices && createUIStepFunctionForm?.value.type === uIWorkflowItemTypeValues.ConfigurationDriven">
          <nz-form-label [nzSpan]="calculateFormLayout().label">
            {{ 'Rappider Services' | translate }}
          </nz-form-label>
          <nz-form-control [nzSpan]="calculateFormLayout().input">
            <rappider-select [options]="serviceOptions"
                             formControlName="workflowServiceId"></rappider-select>
          </nz-form-control>
        </nz-form-item>

        <!-- Workflow Step Function -->
        <nz-form-item
                      *ngIf="uiWorkflowStepFunctionModeRadioGroupValue === StepFunctionTypeOptions.WorkflowStepFunctions && createUIStepFunctionForm?.value.type === uIWorkflowItemTypeValues.ConfigurationDriven">
          <nz-form-label [nzSpan]="calculateFormLayout().label">
            {{ 'Workflow Step Functions' | translate }}
          </nz-form-label>
          <nz-form-control [nzSpan]="calculateFormLayout().input">
            <rappider-select [options]="workflowStepFunctionOptions"
                             formControlName="workflowStepFunctionId"></rappider-select>
          </nz-form-control>
        </nz-form-item>

        <!-- Server-side workflow event -->
        <nz-form-item
                      *ngIf="uiWorkflowStepFunctionModeRadioGroupValue === StepFunctionTypeOptions.WorkflowEvents && createUIStepFunctionForm?.value.type === uIWorkflowItemTypeValues.ConfigurationDriven">
          <nz-form-label [nzSpan]="calculateFormLayout().label">
            {{ 'Server-Side Workflow Events' | translate }}
          </nz-form-label>
          <nz-form-control [nzSpan]="calculateFormLayout().input">
            <rappider-select [options]="serverSideWorkflowEventsOptions"
                             formControlName="workflowEventId"></rappider-select>
          </nz-form-control>
        </nz-form-item>
      </nz-collapse-panel>
    </nz-collapse>

    <!-- Submit Button -->
    <nz-form-item [ngClass]="isSubmitButtonFloat ? 'float-submit-button' : 'submit-button'">
      <nz-form-control [nzSpan]="calculateFormLayout().input">
        <button nz-button
                type="submit"
                nzType="primary"
                [nzLoading]="loading">
          {{ 'SHARED.SUBMIT' | translate }}
        </button>
      </nz-form-control>
    </nz-form-item>

    <!-- Error Templates -->
    <ng-template #nameFieldErrorTemplates>
      <small *ngIf="getNameFieldErrorsByErrorKey('required')">
        - {{ 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'| translate }}
        <br />
      </small>
      <small *ngIf="getNameFieldErrorsByErrorKey('pattern')">
        - {{'ERRORS.PATTERN_MESSAGES.MUST_START_WITH_LOWERCASE_LETTER' | translate }}
      </small>
    </ng-template>
    <ng-template #requiredErrorTemplate>
      <small>
        - {{ 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'|translate }}
      </small>
    </ng-template>
  </form>
</rappider-spin>