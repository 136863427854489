/* eslint-disable */
/**
 * @module SDKModule
 * @author Jonathan Casarrubias <t:@johncasarrubias> <gh:jonathan-casarrubias>
 * @license MIT 2016 Jonathan Casarrubias
 * @version 2.1.0
 * @description
 * The SDKModule is a generated Software Development Kit automatically built by
 * the LoopBack SDK Builder open source module.
 *
 * The SDKModule provides Angular 2 >= RC.5 support, which means that NgModules
 * can import this Software Development Kit as follows:
 *
 *
 * APP Route Module Context
 * ============================================================================
 * import { NgModule }       from '@angular/core';
 * import { BrowserModule }  from '@angular/platform-browser';
 * // App Root
 * import { AppComponent }   from './app.component';
 * // Feature Modules
 * import { SDK[Browser|Node|Native]Module } from './shared/sdk/sdk.module';
 * // Import Routing
 * import { routing }        from './app.routing';
 * @NgModule({
 *  imports: [
 *    BrowserModule,
 *    routing,
 *    SDK[Browser|Node|Native]Module.forRoot()
 *  ],
 *  declarations: [ AppComponent ],
 *  bootstrap:    [ AppComponent ]
 * })
 * export class AppModule { }
 *
 **/
import { ErrorHandler } from './services/core/error.service';
import { LoopBackAuth } from './services/core/auth.service';
import { LoggerService } from './services/custom/logger.service';
import { SDKModels } from './services/custom/SDKModels';
import { InternalStorage, SDKStorage } from './storage/storage.swaps';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CookieBrowser } from './storage/cookie.browser';
import { StorageBrowser } from './storage/storage.browser';
import { SocketBrowser } from './sockets/socket.browser';
import { SocketDriver } from './sockets/socket.driver';
import { SocketConnection } from './sockets/socket.connections';
import { RealTime } from './services/core/real.time';
import { UserApi } from './services/custom/User';
import { RoleMappingApi } from './services/custom/RoleMapping';
import { RoleApi } from './services/custom/Role';
import { ProjectApi } from './services/custom/Project';
import { TenantApi } from './services/custom/Tenant';
import { PersonApi } from './services/custom/Person';
import { PageApi } from './services/custom/Page';
import { ComponentApi } from './services/custom/Component';
import { PageComponentApi } from './services/custom/PageComponent';
import { DynamicInputApi } from './services/custom/DynamicInput';
import { ComponentSampleApi } from './services/custom/ComponentSample';
import { FieldTypeApi } from './services/custom/FieldType';
import { ComponentSlotApi } from './services/custom/ComponentSlot';
import { CategoryApi } from './services/custom/Category';
import { HttpRequestApi } from './services/custom/HttpRequest';
import { ComponentDefinitionApi } from './services/custom/ComponentDefinition';
import { SlotDefinitionApi } from './services/custom/SlotDefinition';
import { ComponentInputDefinitionApi } from './services/custom/ComponentInputDefinition';
import { ComponentOutputDefinitionApi } from './services/custom/ComponentOutputDefinition';
import { FieldDefinitionApi } from './services/custom/FieldDefinition';
import { KeyValueApi } from './services/custom/KeyValue';
import { FieldDataDefinitionApi } from './services/custom/FieldDataDefinition';
import { MessageLogsApi } from './services/custom/MessageLogs';
import { MessageApi } from './services/custom/Message';
import { EnvironmentVariableApi } from './services/custom/EnvironmentVariable';
import { MessageTemplateApi } from './services/custom/MessageTemplate';
import { SettingsApi } from './services/custom/Settings';
import { VerificationTokenApi } from './services/custom/VerificationToken';
import { PhoneNumberApi } from './services/custom/PhoneNumber';
import { ProjectSettingApi } from './services/custom/ProjectSetting';
import { ProjectFileApi } from './services/custom/ProjectFile';
import { PersonProjectApi } from './services/custom/PersonProject';
import { ProjectRoleApi } from './services/custom/ProjectRole';
import { ProjectFolderApi } from './services/custom/ProjectFolder';
import { MessageTemplateDataFieldApi } from './services/custom/MessageTemplateDataField';
import { PersonProjectRoleApi } from './services/custom/PersonProjectRole';
import { ProjectVersionApi } from './services/custom/ProjectVersion';
import { MetadataApi } from './services/custom/Metadata';
import { ProjectThemeApi } from './services/custom/ProjectTheme';
import { ComponentDefinitionSubCategoryApi } from './services/custom/ComponentDefinitionSubCategory';
import { ProjectExportApi } from './services/custom/ProjectExport';
import { ProjectExternalScriptApi } from './services/custom/ProjectExternalScript';
import { UserInvitationApi } from './services/custom/UserInvitation';
import { OutletApi } from './services/custom/Outlet';
import { ProjectModelApi } from './services/custom/ProjectModel';
import { ProjectModelFieldApi } from './services/custom/ProjectModelField';
import { ProjectModelRelationApi } from './services/custom/ProjectModelRelation';
import { UIDataStoreApi } from './services/custom/UIDataStore';
import { DataFieldApi } from './services/custom/DataField';
import { UIDataEventApi } from './services/custom/UIDataEvent';
import { UIDataUpdateFunctionApi } from './services/custom/UIDataUpdateFunction';
import { DataSchemaApi } from './services/custom/DataSchema';
import { DataSchemaFieldApi } from './services/custom/DataSchemaField';
import { DataMappingApi } from './services/custom/DataMapping';
import { LibraryFunctionApi } from './services/custom/LibraryFunction';
import { DataFieldSchemaApi } from './services/custom/DataFieldSchema';
import { ProjectPackageApi } from './services/custom/ProjectPackage';
import { RappiderFunctionApi } from './services/custom/RappiderFunction';
import { ComponentDataSubscriptionApi } from './services/custom/ComponentDataSubscription';
import { DataSchemaTreeFieldApi } from './services/custom/DataSchemaTreeField';
import { DataSchemaTreeFieldNodeApi } from './services/custom/DataSchemaTreeFieldNode';
import { ComponentStyleSheetDefinitionApi } from './services/custom/ComponentStyleSheetDefinition';
import { ComponentStyleSheetApi } from './services/custom/ComponentStyleSheet';
import { DataSchemaDataDefinitionApi } from './services/custom/DataSchemaDataDefinition';
import {
  DataTransformationApi,
  WorkflowApi,
  WorkflowEventApi,
  WorkflowStepFunctionApi,
  WorkflowStepFunctionPublishedEventApi,
  WorkflowStepFunctionSubscribedEventApi,
} from './services/custom';
import { WorkflowStepFunction } from './models/WorkflowStepFunction';
/**
 * @module SDKBrowserModule
 * @description
 * This module should be imported when building a Web Application in the following scenarios:
 *
 *  1.- Regular web application
 *  2.- Angular universal application (Browser Portion)
 *  3.- Progressive applications (Angular Mobile, Ionic, WebViews, etc)
 **/
@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  exports: [],
  providers: [ErrorHandler, SocketConnection],
})
export class SDKBrowserModule {
  static forRoot(
    internalStorageProvider: any = {
      provide: InternalStorage,
      useClass: CookieBrowser,
    }
  ): ModuleWithProviders<SDKBrowserModule> {
    return {
      ngModule: SDKBrowserModule,
      providers: [
        LoopBackAuth,
        LoggerService,
        SDKModels,
        RealTime,
        UserApi,
        RoleMappingApi,
        RoleApi,
        ProjectApi,
        TenantApi,
        PersonApi,
        PageApi,
        ComponentApi,
        PageComponentApi,
        DynamicInputApi,
        ComponentSampleApi,
        FieldTypeApi,
        ComponentSlotApi,
        CategoryApi,
        HttpRequestApi,
        ComponentDefinitionApi,
        SlotDefinitionApi,
        ComponentInputDefinitionApi,
        ComponentOutputDefinitionApi,
        FieldDefinitionApi,
        KeyValueApi,
        FieldDataDefinitionApi,
        MessageLogsApi,
        MessageApi,
        EnvironmentVariableApi,
        MessageTemplateApi,
        SettingsApi,
        VerificationTokenApi,
        PhoneNumberApi,
        ProjectSettingApi,
        ProjectFileApi,
        PersonProjectApi,
        ProjectRoleApi,
        ProjectFolderApi,
        MessageTemplateDataFieldApi,
        PersonProjectRoleApi,
        ProjectVersionApi,
        MetadataApi,
        ProjectThemeApi,
        ComponentDefinitionSubCategoryApi,
        ProjectExportApi,
        ProjectExternalScriptApi,
        UserInvitationApi,
        OutletApi,
        ProjectModelApi,
        ProjectModelFieldApi,
        ProjectModelRelationApi,
        UIDataStoreApi,
        DataFieldApi,
        UIDataEventApi,
        UIDataUpdateFunctionApi,
        DataSchemaApi,
        DataSchemaFieldApi,
        DataMappingApi,
        LibraryFunctionApi,
        DataFieldSchemaApi,
        ProjectPackageApi,
        RappiderFunctionApi,
        ComponentDataSubscriptionApi,
        DataSchemaTreeFieldApi,
        DataSchemaTreeFieldNodeApi,
        ComponentStyleSheetDefinitionApi,
        ComponentStyleSheetApi,
        DataSchemaDataDefinitionApi,
        WorkflowApi,
        WorkflowStepFunctionApi,
        WorkflowEventApi,
        WorkflowStepFunctionPublishedEventApi,
        WorkflowStepFunctionSubscribedEventApi,
        DataTransformationApi,
        internalStorageProvider,
        { provide: SDKStorage, useClass: StorageBrowser },
        { provide: SocketDriver, useClass: SocketBrowser },
      ],
    };
  }
}
/**
 * Have Fun!!!
 * - Jon
 **/
export * from './models/index';
export * from './services/index';
export * from './lb.config';
export * from './storage/storage.swaps';
export { CookieBrowser } from './storage/cookie.browser';
export { StorageBrowser } from './storage/storage.browser';
