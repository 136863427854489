export enum FunctionMode {
  Edit = 'edit',
  Label = 'label',
  Custom = 'custom'
}

export enum FunctionButtonText {
  CustomFunction = 'Add Custom Function',
  SelectFunction = 'Select Function'
}
