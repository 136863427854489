/* eslint-disable no-shadow */
import { Action } from '@ngrx/store';
import { NewProjectPackage, ProjectPackage, ProjectPackagePartial, ProjectPackageWithRelations } from '@rappider/rappider-sdk';

export enum ActionTypes {
  SetLoadingState = '[ProjectPackage] SetLoadingState',
  GetProjectPackages = '[ProjectPackage] GetProjectPackages',
  SetProjectPackages = '[ProjectPackage] SetProjectPackages',
  CreateProjectPackage = '[ProjectPackage] CreateProjectPackage',
  CreateProjectPackageSuccessful = '[ProjectPackage] CreateProjectPackageSuccessful',
  DeleteProjectPackage = '[ProjectPackage] DeleteProjectPackage',
  UpdateProjectPackage = '[ProjectPackage] UpdateProjectPackage',
  UpdateProjectPackageSuccessful = '[ProjectPackage] UpdateProjectPackageSuccessful',
  ErrorAction = '[ProjectPackage] ErrorAction'
}

export class SetLoadingState implements Action {
  readonly type = ActionTypes.SetLoadingState;
  constructor(public payload: { loading: boolean }) { }
}

export class GetProjectPackages implements Action {
  readonly type = ActionTypes.GetProjectPackages;
}

export class SetProjectPackages implements Action {
  readonly type = ActionTypes.SetProjectPackages;
  constructor(public payload: { projectPackages: ProjectPackageWithRelations[] }) { }
}

export class CreateProjectPackage implements Action {
  readonly type = ActionTypes.CreateProjectPackage;
  constructor(public payload: { projectPackage: NewProjectPackage }) { }
}

export class CreateProjectPackageSuccessful implements Action {
  readonly type = ActionTypes.CreateProjectPackageSuccessful;
  constructor(public payload: { projectPackage: ProjectPackage[] }) { }
}

export class DeleteProjectPackage implements Action {
  readonly type = ActionTypes.DeleteProjectPackage;
  constructor(public payload: { projectPackageId: string }) { }
}

export class UpdateProjectPackage implements Action {
  readonly type = ActionTypes.UpdateProjectPackage;
  constructor(public payload: { id: string; body: ProjectPackagePartial }) { }
}

export class UpdateProjectPackageSuccessful implements Action {
  readonly type = ActionTypes.UpdateProjectPackageSuccessful;
  constructor(public payload: { id: string; updatedPackage: ProjectPackagePartial }) { }
}

export class ErrorAction implements Action {
  readonly type = ActionTypes.ErrorAction;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export type Actions =
  SetLoadingState
  | GetProjectPackages
  | SetProjectPackages
  | CreateProjectPackage
  | CreateProjectPackageSuccessful
  | DeleteProjectPackage
  | UpdateProjectPackage
  | UpdateProjectPackageSuccessful
  | ErrorAction
  ;
