/* eslint-disable no-shadow */
import { Action } from '@ngrx/store';
import { LastProcessedAction, Pagination } from '@rappider/shared/interfaces';
import { Metadata, NewPage, Page, PageBulkUpdateDto, PagePartial, PageWithRelations } from '@rappider/rappider-sdk';

export enum ActionTypes {
  EnablePageLoading = '[Page] EnablePageLoading',
  DisablePageLoading = '[Page] DisablePageLoading',
  SetPagination = '[Page] SetPagination',
  GetTotalCount = '[Page] GetTotalCount',
  GetTotalCountFailure = '[Page] GetTotalCountFailure',
  GetPages = '[Page] GetPages',
  GetPageById = '[Page] GetPageById',
  GetPageByIdSuccessful = '[Page] GetPageByIdSuccessful',
  GetPagesByModuleId = '[Page] GetPagesByModuleId',
  GetPagesByModuleIdSuccessful = '[Page] GetPagesByModuleIdSuccessful',
  SetPages = '[Page] SetPages',
  CreatePage = '[Page] CreatePage',
  UpdatePage = '[Page] UpdatePage',
  BulkUpdatePages = '[Page] BulkUpdatePages',
  UpdatePageSuccessful = '[Page] UpdatePageSuccessful',
  BulkUpdatePagesSuccessful = '[Page] BulkUpdatePagesSuccessful',
  DeletePage = '[Page] DeletePage',
  DeletePageSuccessful = '[Page] DeletePageSuccessful',
  BulkDeletePages = '[Page] BulkDeletePages',
  BulkDeletePagesSuccessful = '[Page] BulkDeletePagesSuccessful',
  PageError = '[Page] PageError',
  SetHomePage = '[Page] SetHomePage',
  DuplicatePage = '[Page] DuplicatePage',
  ChangeLastProcessedAction = '[Page] ChangeLastProcessedAction',
  GetTemplatePages = '[Page] GetTemplatePages',
  GetTemplatePagesSuccessful = '[Page] GetTemplatePagesSuccessful',
  UpdatePageWithLifecycleActions = '[Page] UpdatePageWithLifecycleActions',
  UpdatePageWithLifecycleActionsSuccessful = '[Page] UpdatePageWithLifecycleActionsSuccessful',
  GetTemplatePagesPagination = '[Page] GetTemplatePagesPagination',
  GetTemplatePagesPaginationSuccessful = '[Page] GetTemplatePagesPaginationSuccessful',
  ChangeDefaultModule = '[Page] ChangeDefaultModule',
}

export class EnablePageLoading implements Action {
  readonly type = ActionTypes.EnablePageLoading;
}

export class DisablePageLoading implements Action {
  readonly type = ActionTypes.DisablePageLoading;
}

export class GetPages implements Action {
  readonly type = ActionTypes.GetPages;
}

export class GetPageById implements Action {
  readonly type = ActionTypes.GetPageById;
  constructor(public payload: { pageId: string }) { }
}

export class GetPageByIdSuccessful implements Action {
  readonly type = ActionTypes.GetPageByIdSuccessful;
  constructor(public payload: { page: Page }) { }
}

/**
 * sets pagination and gets data after the action if specified
 *
 * @export
 * @class SetPagination
 * @implements {Action}
 */
export class SetPagination implements Action {
  readonly type = ActionTypes.SetPagination;
  constructor(public payload: { pagination: Pagination; getDataAfter?: boolean }) { }
}

export class GetTotalCount implements Action {
  readonly type = ActionTypes.GetTotalCount;
}

export class GetTotalCountFailure implements Action {
  readonly type = ActionTypes.GetTotalCountFailure;
}

export class SetPages implements Action {
  readonly type = ActionTypes.SetPages;
  constructor(public payload: { pages: Page[] }) { }
}

export class CreatePage implements Action {
  readonly type = ActionTypes.CreatePage;
  constructor(public payload: { page: NewPage }) { }
}

export class UpdatePage implements Action {
  readonly type = ActionTypes.UpdatePage;
  constructor(public payload: {
    page: PagePartial;
    pageId: string;
    navigateToPageList?: boolean;
    updateInContentEditor?: boolean;
  }) { }
}

export class UpdatePageSuccessful implements Action {
  readonly type = ActionTypes.UpdatePageSuccessful;
  constructor(public payload: {
    page: PagePartial;
    pageId: string;
  }) { }
}

export class BulkUpdatePages implements Action {
  readonly type = ActionTypes.BulkUpdatePages;
  constructor(public payload: {
    body: PageBulkUpdateDto[];
    pageNames: string[];
    moduleName: string;
  }) { }
}

export class BulkUpdatePagesSuccessful implements Action {
  readonly type = ActionTypes.BulkUpdatePagesSuccessful;
  constructor(public payload: {
    body: PageBulkUpdateDto[];
  }) { }
}

export class DeletePage implements Action {
  readonly type = ActionTypes.DeletePage;
  constructor(public payload: { pageId: string }) { }
}

export class DeletePageSuccessful implements Action {
  readonly type = ActionTypes.DeletePageSuccessful;
  constructor(public payload: { pageId: string }) { }
}

export class BulkDeletePages implements Action {
  readonly type = ActionTypes.BulkDeletePages;
  constructor(public payload: {
    pageNames: string[];
    pageIds: string[];
  }) { }
}

export class BulkDeletePagesSuccessful implements Action {
  readonly type = ActionTypes.BulkDeletePagesSuccessful;
  constructor(public payload: {
    pageIds: string[];
  }) { }
}


export class PageError implements Action {
  readonly type = ActionTypes.PageError;
  constructor(public payload: { errorOn: string; error: any }) { }
}

export class SetHomePage implements Action {
  readonly type = ActionTypes.SetHomePage;
  constructor(public payload: { pageId: string }) { }
}

export class DuplicatePage implements Action {
  readonly type = ActionTypes.DuplicatePage;
  constructor(public payload: { pageId: string; moduleId: string; title: string; description: string }) { }
}

export class ChangeLastProcessedAction implements Action {
  readonly type = ActionTypes.ChangeLastProcessedAction;
  constructor(public payload: {
    lastProcessedAction: LastProcessedAction;
  }) { }
}

export class GetTemplatePages implements Action {
  readonly type = ActionTypes.GetTemplatePages;
  constructor(public payload: { pageIndex: number; pageSize: number; searchText?: string; tags?: string[]; isDynamicPagination?: boolean }) { }
}

export class GetTemplatePagesSuccessful implements Action {
  readonly type = ActionTypes.GetTemplatePagesSuccessful;
  constructor(public payload: { templatePages: PageWithRelations[] }) { }
}

export class UpdatePageWithLifecycleActions implements Action {
  readonly type = ActionTypes.UpdatePageWithLifecycleActions;
  constructor(public payload: { pageId: string; page: PagePartial }) { }
}

export class UpdatePageWithLifecycleActionsSuccessful implements Action {
  readonly type = ActionTypes.UpdatePageWithLifecycleActionsSuccessful;
  constructor(public payload: { pageId: string; page: PageWithRelations }) { }
}

export class GetTemplatePagesPagination implements Action {
  readonly type = ActionTypes.GetTemplatePagesPagination;
  constructor(public payload: { pageIndex?: number; pageSize?: number; searchText?: string; tags?: string[] }) { }
}
export class GetTemplatePagesPaginationSuccessful implements Action {
  readonly type = ActionTypes.GetTemplatePagesPaginationSuccessful;
  constructor(public payload: { pageIndex: number; pageSize: number; searchText?: string; count?: number; templatePagesTags?: string[]; tags?: string[]; isDynamicPagination?: boolean }) { }
}

export class GetPagesByModuleId implements Action {
  readonly type = ActionTypes.GetPagesByModuleId;
  constructor(public payload: { moduleId: string }) { }
}

export class GetPagesByModuleIdSuccessful implements Action {
  readonly type = ActionTypes.GetPagesByModuleIdSuccessful;
  constructor(public payload: { pages: PageWithRelations[] }) { }
}

export class ChangeDefaultModule implements Action {
  readonly type = ActionTypes.ChangeDefaultModule;
  constructor(public payload: { moduleId: string }) { }
}

export type Actions =
  | EnablePageLoading
  | DisablePageLoading
  | SetPagination
  | GetTotalCount
  | GetPages
  | GetPageById
  | GetPageByIdSuccessful
  | SetPages
  | CreatePage
  | UpdatePage
  | UpdatePageSuccessful
  | BulkUpdatePages
  | BulkUpdatePagesSuccessful
  | DeletePage
  | DeletePageSuccessful
  | BulkDeletePages
  | BulkDeletePagesSuccessful
  | PageError
  | SetHomePage
  | DuplicatePage
  | ChangeLastProcessedAction
  | GetTemplatePages
  | GetTemplatePagesSuccessful
  | UpdatePageWithLifecycleActions
  | UpdatePageWithLifecycleActionsSuccessful
  | GetTemplatePagesPagination
  | GetTemplatePagesPaginationSuccessful
  | GetPagesByModuleId
  | GetPagesByModuleIdSuccessful
  | GetTotalCountFailure
  | ChangeDefaultModule
  ;
