<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>

<section>
  <rappider-crud-view-edit-form [config]="ADMIN_PROJECT_EDIT_FORM_CONFIG"
                                [data]="project"
                                [submitButtonLoading]="isLoading"
                                (fieldValueChange)="onFieldValueChange($event)"
                                (formSubmit)="editProject($event)">
  </rappider-crud-view-edit-form>
</section>