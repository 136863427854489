import { KeyValue } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Module } from '@rappider/rappider-sdk';
import { FormatDatePipe } from 'libs/shared/src/lib/pipes/format-date.pipe';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rappider-module-detail',
  templateUrl: './module-detail.component.html',
  styleUrls: ['./module-detail.component.scss']
})
export class ModuleDetailComponent implements OnInit, OnDestroy {
  module: Module;
  moduleData: KeyValue<string, any>[];
  moduleId: string;
  subscriptions: Subscription[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<any>,
    private formatDatePipe: FormatDatePipe
  ) { }

  ngOnInit(): void {
    this.getModuleIdFromUrl();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToModules()
    ];
  }

  getModuleIdFromUrl() {
    this.moduleId = this.activatedRoute.snapshot.params['id'];
  }

  /**
   * subscribe to modules to filter the current module
   *
   * @return {*}
   * @memberof ModuleDetailComponent
   */
  subscribeToModules() {
    return this.store.select(state => state.module?.data).subscribe((modules: Module[]) => {
      if (modules) {
        this.module = modules.find(module => module.id === this.moduleId);
        this.setModuleData();
      }
    });
  }

  setModuleData() {
    this.moduleData = [
      {
        key: 'SHARED.TITLE',
        value: [this.module.title]
      },
      {
        key: 'SHARED.NAME',
        value: [this.module.name]
      },
      {
        key: 'SHARED.SLUG',
        value: [this.module.slug]
      },
      {
        key: 'SHARED.DESCRIPTION',
        value: [this.module.description]
      },
      {
        key: 'SHARED.CREATED_BY',
        value: [this.module.createdBy]
      },
      {
        key: 'SHARED.CREATED_DATE',
        value: [this.formatDatePipe.transform(this.module.createdDate)]
      }
    ];
  }

}
