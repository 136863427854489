import { Injectable } from '@angular/core';
import { PropertyType } from '@rappider/rappider-components/utils';
import { isArray } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class InputTemplateService {

  constructor() { }

  setInputTypeByValue(value: any) {
    if (typeof value === PropertyType.String) {
      return PropertyType.String;
    } else if (typeof value === PropertyType.Number) {
      return PropertyType.Integer;
    } else if (typeof value === PropertyType.Boolean) {
      return PropertyType.Boolean;
    } else if (isArray(value)) {
      return PropertyType.Array;
    } else {
      return PropertyType.Object;
    }
  }
}
