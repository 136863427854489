<div class="import-external-api-area">
  <div class="label-area">
    <rappider-label class="col-1"
                    [content]="'SHARED.NAME' | translate"></rappider-label>
    <rappider-textbox [(ngModel)]="createOpenApiData.name"></rappider-textbox>
  </div>

  <div class="label-area">
    <rappider-label class="col-1"
                    [content]="'SHARED.DESCRIPTION' | translate"></rappider-label>
    <rappider-textarea [(ngModel)]="createOpenApiData.description"
                       [minRows]="4"></rappider-textarea>
  </div>

  <rappider-code-editor [(ngModel)]="createOpenApiData.content"
                        [settings]="CODEMIRROR_JSON_SETTINGS"
                        [title]="'PROJECT_MODULE.OPENAPI_MODULE.IMPORT_OPENAPI_COMPONENT.OPEN_API_SPEC' | translate">
  </rappider-code-editor>
</div>
