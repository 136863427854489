/* eslint-disable */
import {
  ComponentDefinition,
  DataSchema,
  ComponentDataSubscription,
  DataSchemaDataDefinition,
} from '../index';

declare var Object: any;
export interface ComponentInputDefinitionInterface {
  id?: string;
  description?: string;
  category?: string;
  title?: string;
  fieldName: string;
  uiDataSelector?: string;
  index?: number;
  isArray?: boolean;
  default?: any;
  componentDefinitionId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  typeId?: string;
  activeDataSchemaDataDefinitionId?: string;
  componentDefinition?: ComponentDefinition;
  type?: DataSchema;
  dataSubscription?: ComponentDataSubscription;
  activeDataDefinition?: DataSchemaDataDefinition;
}

export class ComponentInputDefinition
  implements ComponentInputDefinitionInterface {
  'id'?: string;
  'description': string;
  'category': string;
  'title': string;
  'fieldName': string;
  'uiDataSelector': string;
  'index': number;
  'isArray': boolean;
  'default': any;
  'componentDefinitionId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  'typeId': string;
  'activeDataSchemaDataDefinitionId': string;
  componentDefinition: ComponentDefinition;
  type: DataSchema;
  dataSubscription: ComponentDataSubscription;
  activeDataDefinition: DataSchemaDataDefinition;
  constructor(data?: ComponentInputDefinitionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ComponentInputDefinition`.
   */
  public static getModelName() {
    return 'ComponentInputDefinition';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ComponentInputDefinition for dynamic purposes.
   **/
  public static factory(
    data: ComponentInputDefinitionInterface
  ): ComponentInputDefinition {
    return new ComponentInputDefinition(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ComponentInputDefinition',
      plural: 'ComponentInputDefinitions',
      path: 'component-input-definitions',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        description: {
          name: 'description',
          type: 'string',
        },
        category: {
          name: 'category',
          type: 'string',
        },
        title: {
          name: 'title',
          type: 'string',
        },
        fieldName: {
          name: 'fieldName',
          type: 'string',
        },
        uiDataSelector: {
          name: 'uiDataSelector',
          type: 'string',
        },
        index: {
          name: 'index',
          type: 'number',
        },
        isArray: {
          name: 'isArray',
          type: 'boolean',
        },
        default: {
          name: 'default',
          type: 'any',
        },
        componentDefinitionId: {
          name: 'componentDefinitionId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
        typeId: {
          name: 'typeId',
          type: 'string',
        },
        activeDataSchemaDataDefinitionId: {
          name: 'activeDataSchemaDataDefinitionId',
          type: 'string',
        },
      },
      relations: {
        componentDefinition: {
          name: 'componentDefinition',
          type: 'ComponentDefinition',
          model: 'ComponentDefinition',
          relationType: 'belongsTo',
          keyFrom: 'componentDefinitionId',
          keyTo: 'id',
        },
        type: {
          name: 'type',
          type: 'DataSchema',
          model: 'DataSchema',
          relationType: 'belongsTo',
          keyFrom: 'typeId',
          keyTo: 'id',
        },
        dataSubscription: {
          name: 'dataSubscription',
          type: 'ComponentDataSubscription',
          model: 'ComponentDataSubscription',
          relationType: 'hasOne',
          keyFrom: 'id',
          keyTo: 'componentInputDefinitionId',
        },
        activeDataDefinition: {
          name: 'activeDataDefinition',
          type: 'DataSchemaDataDefinition',
          model: 'DataSchemaDataDefinition',
          relationType: 'belongsTo',
          keyFrom: 'activeDataSchemaDataDefinitionId',
          keyTo: 'id',
        },
      },
    };
  }
}
