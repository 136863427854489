<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title">
</rappider-title-toolbar>

<section>
  <rappider-crud-view-edit-form [config]="ADMIN_COMMENT_EDIT_FORM_CONFIG"
                                [data]="editedComment"
                                [submitButtonLoading]="isLoading"
                                (formSubmit)="editComment($event)">
  </rappider-crud-view-edit-form>
</section>