import { Validators } from '@angular/forms';
import { CrudFormConfig, CrudFormTextBoxItem, InputSize, CrudViewFormItemType, TextBoxType, CrudFormSelectItem, SelectMode, FormLayout } from '@rappider/rappider-components/utils';
import { EMAIL_REGEX, PASSWORD_REGEX } from '@rappider/shared/definitions';

export const TEST_USER_FORM_ITEMS_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormTextBoxItem>{
      size: InputSize.Default,
      type: CrudViewFormItemType.TextBox,
      textType: TextBoxType.Text,
      title: 'SHARED.FIRST_NAME',
      fieldName: 'firstName',
      placeholder: 'SHARED.FIRST_NAME',
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'RAPPIDER_COMPONENTS_MODULE.LOGIN_FORM_COMPONENT.REQUIRED_ERROR_TIP'
        }
      ]
    },
    <CrudFormTextBoxItem>{
      title: 'SHARED.LAST_NAME',
      fieldName: 'lastName',
      type: CrudViewFormItemType.TextBox,
      textType: TextBoxType.Text,
      placeholder: 'SHARED.LAST_NAME',
      size: InputSize.Default,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'RAPPIDER_COMPONENTS_MODULE.LOGIN_FORM_COMPONENT.REQUIRED_ERROR_TIP'
        }
      ]
    },
    <CrudFormTextBoxItem>{
      title: 'SHARED.EMAIL',
      fieldName: 'email',
      type: CrudViewFormItemType.TextBox,
      textType: TextBoxType.Text,
      placeholder: 'SHARED.EMAIL',
      size: InputSize.Default,
      validators: [
        {
          type: Validators.pattern(EMAIL_REGEX),
          errorKey: 'pattern',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.VALID_EMAIL_MESSAGE'
        },
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'RAPPIDER_COMPONENTS_MODULE.LOGIN_FORM_COMPONENT.REQUIRED_ERROR_TIP'
        }
      ],
    },
    <CrudFormTextBoxItem>{
      title: 'Password',
      fieldName: 'password',
      type: CrudViewFormItemType.TextBox,
      textType: TextBoxType.Text,
      placeholder: 'Password',
      size: InputSize.Default,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'RAPPIDER_COMPONENTS_MODULE.LOGIN_FORM_COMPONENT.REQUIRED_ERROR_TIP'
        }
      ]
    },
    <CrudFormSelectItem>{
      title: 'PROJECT_MODULE.PROJECT_TEST_MEMBERS_COMPONENT.TEST_USER_ROLES',
      fieldName: 'selectedRoles',
      type: CrudViewFormItemType.Select,
      settings: {
        mode: SelectMode.Multiple,
      },
      placeholder: 'Select Test User Roles',
      size: InputSize.Default,
      description: 'A Test user can have one or several roles',
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'RAPPIDER_COMPONENTS_MODULE.LOGIN_FORM_COMPONENT.REQUIRED_ERROR_TIP'
        }
      ]
    },
  ],
  submitButton: { visible: false },
  itemSettings: {
    inputComponentSize: {
      xs: 24
    }
  },
  formLayout: FormLayout.Horizontal
};
