<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton"></rappider-title-toolbar>

<section>
  <rappider-spin [spinning]="loading">
    <rappider-list-grid [config]="OPENAPI_ENDPOINT_LIST_CONFIG"
                        [data]="openApiEndpointsData"></rappider-list-grid>
  </rappider-spin>
</section>