import { ProjectModelRelationType } from './project-model-relation-type.enum';

export const PROJECT_MODEL_RELATION_TYPE_OPTIONS = [
  {
    key: 'Has Many',
    value: ProjectModelRelationType.HasMany
  },
  {
    key: 'Belongs To',
    value: ProjectModelRelationType.BelongsTo
  },
  {
    key: 'Has One',
    value: ProjectModelRelationType.HasOne
  },
  {
    key: 'Has Many Through',
    value: ProjectModelRelationType.HasManyThrough
  },
  {
    key: 'References Many',
    value: ProjectModelRelationType.ReferencesMany
  }
];
