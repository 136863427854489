import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { RappiderTabsetComponent } from './tabset.component';
import { RappiderIconModule } from '../icon/icon.module';
import { RappiderButtonModule } from '../button/button.module';


@NgModule({
  declarations: [
    RappiderTabsetComponent
  ],
  imports: [
    CommonModule,
    NzTabsModule,
    RappiderIconModule,
    RappiderButtonModule
  ],
  exports: [
    RappiderTabsetComponent
  ]
})
export class RappiderTabsetModule { }
