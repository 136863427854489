<nz-tree-select *ngIf="dataSchema?.fields"
                class="tree-select"
                [(ngModel)]="selectedNode"
                [nzNodes]="nodes"
                [nzDefaultExpandAll]="true"
                nzShowSearch
                nzPlaceHolder="Please select"
                #nzTree
                (ngModelChange)="onTreeValueChange($event)"></nz-tree-select>

<rappider-alert *ngIf="!dataSchema?.fields && isNoFieldAlertShown "
                [type]="noFieldAlert.type"
                [title]="noFieldAlert.title"
                [description]="noFieldAlert.description"
                [closeable]="noFieldAlert.closeable">
</rappider-alert>