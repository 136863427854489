import { createSelector } from '@ngrx/store';
import { ProjectModelEndpointWithRelations, ProjectModelWithRelations } from '@rappider/rappider-sdk';
import { controllerClassNameSuffix } from '@rappider/shared/definitions';
export const customEndpointListDataSelector = createSelector(
  state => state['projectModel']?.data,
  state => state['projectModelEndpoint']?.data,
  (projectModels: ProjectModelWithRelations[], projectModelEndpoints: ProjectModelEndpointWithRelations[]) => {
    const customEndpointListData = projectModels?.map(projectModel => ({
      ...projectModel,
      key: projectModel.id,
      name: projectModel.name + controllerClassNameSuffix, // Added Controller to match its name in swagger
      children: projectModelEndpoints?.filter(projectModelEndpoint => projectModel.id === projectModelEndpoint.modelId).map(projectModelEndpoint => ({
        ...projectModelEndpoint,
        key: projectModelEndpoint.id,
        path: `${projectModel.basePath}${projectModelEndpoint.path ? ('/' + projectModelEndpoint.path) : ''}`
      }))
    }));
    return customEndpointListData;
  }
);
