import { orderBy } from 'lodash';
import { ProjectTheme } from '@rappider/rappider-sdk';
import * as ProjectThemeActions from './project-theme.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

export const projectThemeFeatureKey = 'projectTheme';

export interface State {
  data: ProjectTheme[];
  loading: boolean;
}

export const initialState: State = {
  data: [],
  loading: false
};

export function reducer(
  state: State = initialState,
  action: ProjectThemeActions.Actions | AuthenticationActions.Actions
): State {
  switch (action.type) {

    case ProjectThemeActions.ActionTypes.GetProjectThemes:
      return {
        ...state,
        loading: true
      };

    case ProjectThemeActions.ActionTypes.GetProjectThemesSuccessful:
      return {
        ...state,
        data: orderBy(action.payload.projectThemes, 'createdDate', 'desc'),
        loading: false
      };

    case ProjectThemeActions.ActionTypes.CreateProjectTheme:
      return {
        ...state,
        loading: true
      };

    case ProjectThemeActions.ActionTypes.CreateProjectThemeSuccessful:
      return {
        ...state,
        data: orderBy([
          ...state.data,
          action.payload.projectTheme
        ], 'createdDate', 'desc'),
        loading: false
      };

    case ProjectThemeActions.ActionTypes.UpdateProjectTheme:
      return {
        ...state,
        loading: true
      };

    case ProjectThemeActions.ActionTypes.UpdateProjectThemeSuccessful:
      return {
        ...state,
        loading: false,
        data: orderBy([
          ...state.data.filter(datum => datum.id !== action.payload.updatedProjectTheme.id),
          action.payload.updatedProjectTheme
        ], 'createdDate', 'desc')
      };

    case ProjectThemeActions.ActionTypes.DeleteProjectTheme:
      return {
        ...state,
        loading: true
      };

    case ProjectThemeActions.ActionTypes.DeleteProjectThemeSuccessful:
      return {
        ...state,
        loading: false,
        data: orderBy(state.data.filter(datum => datum.id !== action.payload.deletedProjectThemeId), 'createdDate', 'desc')
      };

    case ProjectThemeActions.ActionTypes.ProjectThemeError:
      return {
        ...state,
        loading: false
      };

    case AuthenticationActions.ActionTypes.Logout:
      return initialState;

    case AuthenticationActions.ActionTypes.ChangeActiveProjectSuccessful:
      return initialState;

    default:
      return state;
  }
}
