import { Store } from '@ngrx/store';
import { ADMIN_DASHBOARD_CONFIG } from './config/admin-dashboard-config';
import { Component, OnInit } from '@angular/core';
import { HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { defaultToolbarTitleHeadingSize } from '@rappider/shared/definitions';
import { InitAdminDashboard } from '../../state/person-state/person.actions';

@Component({
  selector: 'rappider-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {

  /* config */
  adminDashboardConfig = ADMIN_DASHBOARD_CONFIG;

  /* main title for title toolbar */
  mainTitle: HeadingComponentConfig = {
    content: 'Admin Dashboard',
    type: defaultToolbarTitleHeadingSize
  };

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.getInitAdminDashboard();
  }

  getInitAdminDashboard() {
    this.store.dispatch(InitAdminDashboard());
  }
}
