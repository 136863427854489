import * as UserInvitationActions from './user-invitation.actions';

import { createAction, createReducer, on } from '@ngrx/store';
import { PersonInvitationWithRelations } from '@rappider/rappider-sdk';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import { orderBy } from 'lodash';
// // eslint-disable-next-line @typescript-eslint/naming-convention
export const userInvitationFeatureKey = 'userInvitation';

export interface State {
  data: PersonInvitationWithRelations[];
  isLoading: boolean;
  error: {
    error: any;
    key: string;
    timestamp: number;
  };
}

export const initialState: State = {
  data: [],
  isLoading: false,
  error: null
};

const ChangeActiveProjectSuccessful = createAction(AuthenticationActions.ActionTypes.ChangeActiveProjectSuccessful);
const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

export const reducer = createReducer(
  initialState,
  on(UserInvitationActions.GetInvitedUsers, (state) => ({
    ...state,
    isLoading: true
  })),
  on(UserInvitationActions.GetInvitedUsersSuccessful, (state, action) => ({
    ...state,
    data: action.payload.invitedUsers,
    isLoading: false
  })),
  on(UserInvitationActions.GetInvitedUsersFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    }
  })),
  on(UserInvitationActions.InviteUser, (state) => ({
    ...state,
    isLoading: true
  })),
  on(UserInvitationActions.InviteUserFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    }
  })),
  on(UserInvitationActions.InviteUserSuccessful, (state, action) => ({
    ...state,
    data: orderBy([
      ...state.data,
      action.payload.invitedUser
    ], 'createdDate', 'desc'),
    isLoading: false
  })),
  on(UserInvitationActions.ResendInvitation, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(UserInvitationActions.ResendInvitationFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    }
  })),
  on(UserInvitationActions.CancelInvitation, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(UserInvitationActions.CancelInvitationFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    }
  })),
  on(UserInvitationActions.GetInvitedUsersByIdSuccessful, (state, action) => ({
    ...state,
    data: orderBy([
      ...state.data.filter(invitation => invitation.id !== action.payload.invitedUser.id),
      action.payload.invitedUser
    ], 'createdDate', 'desc'),
    isLoading: false
  })),
  on(ChangeActiveProjectSuccessful, () => (initialState)),
  on(Logout, () => (initialState))
);
