<section *ngIf="!activeProjectId">
  <nz-skeleton [nzActive]="true"
               [nzLoading]="!activeProjectId"
               [nzTitle]="false"
               [nzParagraph]="{ rows: 2 }">
  </nz-skeleton>
</section>
<rappider-title-toolbar *ngIf="activeProjectId"
                        [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>
<section>
  <rappider-spin [spinning]="!activeProjectId">
    <rappider-crud-view-edit-form [config]="UI_DATA_STORE_CREATE_CONFIG"
                                  [submitButtonLoading]="submitButtonLoading"
                                  (formSubmit)="onUIDataStoreCreate($event)"></rappider-crud-view-edit-form>
  </rappider-spin>
</section>
