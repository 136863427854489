import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import type { JsonValue } from 'type-fest';
import * as jsf from 'json-schema-faker';

@Injectable({
  providedIn: 'root'
})
export class JsonSchemaService {

  constructor() { }

  /**
   * find latest element in element name array
   *
   * @param {string[]} elementNames
   * @param {*} jsonSchema
   * @return {*}
   * @memberof JsonSchemaService
   */
  findElementInJsonSchema(elementNames: string[], jsonSchema) {
    if (!elementNames?.length && !jsonSchema) {
      return null;
    }

    let currentElement = this.getDefinitionByElementName(elementNames[0], jsonSchema);
    elementNames.forEach((elementName, index) => {
      if (index !== 0) {
        if (currentElement?.properties[elementName]) {
          currentElement = currentElement?.properties[elementName];
          if (currentElement?.$ref) {
            currentElement = this.getDefinitionByRefName(currentElement.$ref, jsonSchema);
          }
        } else if (currentElement?.$ref) {
          currentElement = this.getDefinitionByRefName(currentElement.$ref, jsonSchema);
        } else {
          currentElement = null;
        }
      }
    });
    return currentElement;
  }

  getDefinitionByElementName(definitionName: string, jsonSchema) {
    return cloneDeep(jsonSchema?.definitions?.[definitionName]);
  }

  getDefinitionByRefName($ref: string, jsonSchema) {
    const elementName = this.getRefNameBy$ref($ref);
    return this.getDefinitionByElementName(elementName, jsonSchema);
  }

  getRefNameBy$ref($ref) {
    const elementRefSplittedArray = $ref.split('/');
    const refName = elementRefSplittedArray[elementRefSplittedArray.length - 1];
    return refName;
  }

  /**
   *
   * Generates json sample by JsonSchema w/jsonFaker
   *
   * @param {*} jsonSchema
   * @param {jsf.JSONSchemaFakerOptions} [options]
   * @return {*}  {Promise<JsonValue>}
   * @memberof JsonSchemaService
   */
  generateJsonSampleFromJsonSchema(jsonSchema, options?: jsf.JSONSchemaFakerOptions): Promise<JsonValue> {
    jsf.JSONSchemaFaker.option({
      'alwaysFakeOptionals': true,
      'sortProperties': true,
      'refDepthMin': 1,
      'refDepthMax': 1,
      ...(options || {})
    });
    return jsf.JSONSchemaFaker.resolve(cloneDeep(jsonSchema));
  }
}
