import { DataSchemaField } from '@rappider/rappider-sdk';

export interface DataSchemaElementRow {
  id?: string;
  name?: string;
  typeId: string;
  isArray: boolean;
  default?: string;
  description: string;
  index?: number;
  fieldName?: string;
  uiDataSelector?: string;
  category?: string;
  title?: string;
  isUpdatable?: boolean;
  isDeletable?: boolean;
}

export const INITIAL_ITEM: DataSchemaElementRow = {
  typeId: null,
  isArray: false,
  description: null
};

export interface DataSchemaElementRowOutput {
  created: DataSchemaField[];
  updated: DataSchemaField[];
  deleted: DataSchemaField[];
}

export enum ControlStatus {
  Valid = 'VALID',
  Invalid = 'INVALID'
}
