<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>

<section>
  <rappider-spin [spinning]="!projectId">
    <rappider-data-schema-create-edit-form (formSubmit)="createDataSchema($event)"
                                           [submitButtonLoading]="submitButtonLoading"
                                           [dataSchemaFieldPageType]="dataSchemaFieldPageType">
    </rappider-data-schema-create-edit-form>
  </rappider-spin>
</section>
