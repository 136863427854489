import { Injectable } from '@angular/core';
import { EntityActivityInformation } from '@rappider/shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class EntityActivityInformationService {

  constructor() { }

  addActivityInformation<T extends { createdBy: string; createdDate: Date; updatedBy?: string; updatedDate?: Date }>(
    entities: T[]
  ): (T & EntityActivityInformation)[] {
    return entities.map(entity => {
      const lastActivityBy = entity.updatedBy || entity.createdBy;
      const lastActivityDate = entity.updatedDate || entity.createdDate;
      return {
        ...entity,
        lastActivityBy,
        lastActivityDate
      };
    });
  }
}
