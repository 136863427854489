<rappider-spin [spinning]="!page">
  <rappider-internal-title-toolbar [mainTitle]="mainTitle"
                                   [options]="title"
                                   [displayToolbar]="displayToolbar"
                                   [displayToolbarBackButton]="displayToolbarBackButton">
  </rappider-internal-title-toolbar>
  <section>
    <rappider-crud-view-edit-form [config]="PAGE_EDIT_FORM_CONFIG"
                                  [data]="page"
                                  [submitted]="isPageEditFormSubmitted"
                                  [submitButtonLoading]="submitButtonLoading"
                                  (fieldValueChange)="onFieldValueChange($event)"
                                  (submitButtonClick)="submitButtonClick($event)"
                                  (formSubmit)="editPage($event)">
    </rappider-crud-view-edit-form>
  </section>
</rappider-spin>
