import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjectExternalScriptInterface, ProjectInterface } from '@rappider/api-sdk';
import { ActionResponse, BreadcrumbOption, ButtonComponentConfig, ButtonType, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { Subscription } from 'rxjs';
import { DeleteProjectScript, UpdateProjectScriptSuccessful } from '../../states/project-script-state/project-script.actions';
import { PROJECT_SCRIPT_LIST_CONFIG } from './config/project-script-config';

@Component({
  selector: 'rappider-project-script-list',
  templateUrl: './project-script-list.component.html',
  styleUrls: ['./project-script-list.component.scss']
})
export class ProjectScriptListComponent implements OnInit, OnDestroy {

  addProjectScriptButton: ButtonComponentConfig = {
    text: 'PROJECT_MODULE.ADD_PROJECT_SCRIPT_COMPONENT.ADD_PROJECT_SCRIPT',
    type: ButtonType.Default,
    icon: {
      name: 'fas fa-plus'
    }
  };
  /* main title */
  mainTitle: HeadingComponentConfig;
  /* page title */
  title: string | string[] | BreadcrumbOption[];
  subscriptions: Subscription[];
  activeProjectId: string;
  /* project script list config for list grid */
  PROJECT_SCRIPT_LIST_CONFIG = PROJECT_SCRIPT_LIST_CONFIG;
  /* data to show in list grid */
  scriptListGridData = [];
  /* list grid loading state */
  scriptListLoadingState: boolean;
  displayToolbar = false;
  displayToolbarBackButton = false;
  constructor(
    private store: Store<any>,
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.getProjectId(),
      this.subscribeToProjectScripts(),
      this.subscribeToLoadingState()
    ];
  }

  getProjectId() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectInterface) => {
      if (activeProject) {
        /* set project id */
        this.activeProjectId = activeProject.id;
        /* set title */
        this.mainTitle = {
          content: 'PROJECT_MODULE.PROJECT_SCRIPT_LIST_COMPONENT.SCRIPT_LIST',
          type: defaultToolbarTitleHeadingSize
        };
        this.title = [
          {
            label: activeProject.name,
            redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${activeProject?.id}`
          },
          {
            label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_SCRIPT_LIST.PAGE_TITLE
          }
        ];
      }
    });
  }


  subscribeToLoadingState() {
    return this.store.select(state => state.projectScript?.loading).subscribe((loading: boolean) => {
      this.scriptListLoadingState = loading;
    });
  }

  subscribeToProjectScripts() {
    return this.store.select(state => state.projectScript?.data).subscribe((projectScripts: ProjectExternalScriptInterface[]) => {
      this.scriptListGridData = projectScripts?.map((externalScript: ProjectExternalScriptInterface) => ({
        id: externalScript.id,
        fileName: externalScript.projectFile?.name,
        type: externalScript.type,
        sourceType: externalScript.sourceType,
        title: externalScript.projectFile?.title || externalScript.title,
        createdDate: externalScript.createdDate,
        path: externalScript.projectFile?.path || ''
      }));
    });
  }

  /**
   * Fires when delete button clicked
   *
   * @param {ActionResponse} action
   * @memberof ProjectScriptListComponent
   */
  deleteScript(action: ActionResponse) {
    const scriptId = action.data.id;

    this.store.dispatch(new DeleteProjectScript({ projectScriptId: scriptId }));
  }

  /**
   * navigates to AddProjectScript page
   *
   * @memberof ProjectScriptListComponent
   */
  navigateAddProjectScript() {
    this.store.dispatch(new Navigate({ url: PATH_DEFINITIONS.PROJECTS.ADD_PROJECT_SCRIPT }));
  }
}
