/* eslint-disable */
import { ProjectModel } from '../index';

declare var Object: any;
export interface ProjectModelFieldInterface {
  id?: string;
  name: string;
  type: string;
  isRequired?: boolean;
  isUnique?: boolean;
  default?: any;
  defaultFn?: string;
  isHidden?: boolean;
  index: number;
  description?: string;
  projectModelId?: string;
  model?: ProjectModel;
}

export class ProjectModelField implements ProjectModelFieldInterface {
  'id'?: string;
  'name': string;
  'type': string;
  'isRequired': boolean;
  'isUnique': boolean;
  'default': any;
  'defaultFn': string;
  'isHidden': boolean;
  'index': number;
  'description': string;
  'projectModelId': string;
  model: ProjectModel;
  constructor(data?: ProjectModelFieldInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ProjectModelField`.
   */
  public static getModelName() {
    return 'ProjectModelField';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ProjectModelField for dynamic purposes.
   **/
  public static factory(data: ProjectModelFieldInterface): ProjectModelField {
    return new ProjectModelField(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ProjectModelField',
      plural: 'ProjectModelDataFields',
      path: 'project-model-fields',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        type: {
          name: 'type',
          type: 'string',
        },
        isRequired: {
          name: 'isRequired',
          type: 'boolean',
        },
        isUnique: {
          name: 'isUnique',
          type: 'boolean',
        },
        default: {
          name: 'default',
          type: 'any',
        },
        defaultFn: {
          name: 'defaultFn',
          type: 'string',
        },
        isHidden: {
          name: 'isHidden',
          type: 'boolean',
        },
        index: {
          name: 'index',
          type: 'number',
        },
        description: {
          name: 'description',
          type: 'string',
        },
        projectModelId: {
          name: 'projectModelId',
          type: 'string',
        },
      },
      relations: {
        model: {
          name: 'model',
          type: 'ProjectModel',
          model: 'ProjectModel',
          relationType: 'belongsTo',
          keyFrom: 'projectModelId',
          keyTo: 'id',
        },
      },
    };
  }
}
