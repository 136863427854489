import { createSelector } from '@ngrx/store';
import { ProjectModelField, ProjectModelRelationWithRelations, ProjectModelWithRelations } from '@rappider/rappider-sdk';
import { orderBy } from 'lodash';

export const getProjectModelsWithFields = createSelector(
  state => state['projectModel']?.data,
  state => state['projectModelField']?.data,
  state => state['projectModelField']?.isLoaded,
  state => state['projectModel']?.isLoaded,
  state => state['projectModelRelation']?.data,
  (
    projectModels: ProjectModelWithRelations[],
    projectModelFields: ProjectModelField[],
    isProjectModelsLoaded: boolean,
    isProjectModelFieldsLoaded: boolean,
    projectModelRelations: ProjectModelRelationWithRelations[]
  ) => {
    if (isProjectModelsLoaded && isProjectModelFieldsLoaded) {
      return {
        projectModels: projectModels.map(projectModel => (
          {
            ...projectModel,
            fields: orderBy(projectModelFields.filter(projectModelField => projectModelField.projectModelId === projectModel.id), 'index', 'asc')
          }
        )) as ProjectModelWithRelations[],
        projectModelRelations
      };
    }
  }
);
