<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton"></rappider-title-toolbar>

<section>
  <rappider-list-grid [config]="PROJECT_DOCUMENT_LIST_CONFIG"
                      [data]="projectDocuments"
                      [loading]="loading"
                      (columnActionClick)="onColumnActionClick($event)"></rappider-list-grid>
</section>
<rappider-previewer [source]="source"
                    [visibility]="visibility"
                    [modalConfig]="modalConfig"
                    (visibleChange)="visibleChange($event)"></rappider-previewer>
