import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { ProjectWithRelations } from '@rappider/rappider-sdk';
import { ProjectVersionService, StringUtilityService } from '@rappider/services';
import { PROJECT_EXPORT_CONFIG } from '@rappider/shared/configs';
import { defaultToolbarTitleHeadingSize } from '@rappider/shared/definitions';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { ExportProject } from '../../states/project-export-state/project-export.actions';

@Component({
  selector: 'rappider-project-export',
  templateUrl: './project-export.component.html',
  styleUrls: ['./project-export.component.scss']
})
export class ProjectExportComponent implements OnInit, OnDestroy {

  /* Active Project */
  activeProject: ProjectWithRelations;
  /* main title */
  mainTitle: HeadingComponentConfig;
  /* Component Title */
  title: string | string[];
  /* loading status */
  loading: boolean;
  /*  */
  projectVersionWithDetails: any;

  /* Export project version edit form config */
  PROJECT_EXPORT_CONFIG = cloneDeep(PROJECT_EXPORT_CONFIG);
  /* Latest Version */
  projectVersionId: string;
  subscriptions: Subscription[];
  displayToolbar = false;
  displayToolbarBackButton = false;

  constructor(
    private store: Store<any>,
    private projectVersionService: ProjectVersionService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.getProjectVersionIdFromUrl();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Get Proejct Version Id From Url
   *
   * @memberof ProjectExportComponent
   */
  getProjectVersionIdFromUrl() {
    this.projectVersionId = this.activatedRoute.snapshot.params.projectVersionId;
  }

  /**
   * Subscribe all data
   *
   * @memberof ProjectVersionListComponent
   */
  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToProjectExportLoading()
    ];
  }

  /**
   * subscribe active project
   *
   * @return {*}
   * @memberof ProjectExportComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data)
      .subscribe((activeProject: ProjectWithRelations) => {
        if (activeProject && this.projectVersionId) {
          this.activeProject = {
            ...activeProject,
            appType: StringUtilityService.toPascalCase(activeProject.appType),
            frontendFramework: StringUtilityService.toPascalCase(activeProject.frontendFramework),
            backendFramework: StringUtilityService.toPascalCase(activeProject.backendFramework)
          };
          this.getProjectVersionById();
          this.mainTitle = {
            content: 'PROJECT_MODULE.PROJECT_VERSION_DETAIL_COMPONENT.PROJECT_EXPORT_PAGE',
            type: defaultToolbarTitleHeadingSize
          };
          this.title = [this.activeProject.name, 'PROJECT_MODULE.PROJECT_VERSION_DETAIL_COMPONENT.PROJECT_EXPORT_PAGE'];
        }
      });
  }

  /**
   * subscribe to laoding
   *
   * @return {*}
   * @memberof ProjectExportComponent
   */
  subscribeToProjectExportLoading() {
    return this.store.select(state => state.projectExport.loading).subscribe(loading => {
      this.loading = loading;
    });
  }

  /**
   * Export project
   *
   * @param {*} data
   * @memberof ProjectExportComponent
   */
  onProjectExportClick() {
    if (this.projectVersionId && this.activeProject) {
      this.store.dispatch(
        new ExportProject({ projectVersionId: this.projectVersionId })
      );
    }
  }

  /**
   * Get Project Version By Project Version Id
   *
   * @param {string} projectVersionId
   * @memberof ProjectExportComponent
   */
  getProjectVersionById() {
    this.projectVersionService.getProjectVersionById(this.projectVersionId).subscribe(projectVersion => {
      this.projectVersionWithDetails = {
        versionNumber: projectVersion.versionNumber,
        ...this.activeProject
      };
    });
  }

}
