<media-player *ngIf="visible"
              [src]="source"
              [artist]="artist"
              [artwork]="artwork"
              [autoPlay]="autoPlay"
              [clipEndTime]="clipEndTime"
              [clipStartTime]="clipStartTime"
              [controls]="controls"
              [controlsDelay]="controlsDelay"
              [crossOrigin]="crossOrigin"
              [currentTime]="currentTime"
              [duration]="duration"
              [fullscreenOrientation]="fullscreenOrientation"
              [hideControlsOnMouseLeave]="hideControlsOnMouseLeave"
              [keyDisabled]="keyDisabled"
              [liveEdgeTolerance]="liveEdgeTolerance"
              [loop]="loop"
              [muted]="muted"
              [paused]="paused"
              [playbackRate]="playbackRate"
              [playsInline]="playsInline"
              [poster]="poster"
              [posterLoad]="posterLoad"
              [storage]="storage"
              [streamType]="streamType"
              [title]="mediaTitle"
              [viewType]="viewType"
              [volume]="volume"
              (auto-play)="onAutoPlayChange($event)"
              (auto-play-change)="onAutoPlayChange($event)"
              (auto-play-fail)="onAutoPlayFail($event)"
              (controls-change)="onControlsChange($event)"
              (duration-change)="onDurationChange($event)"
              (emptied)="onEmptied($event)"
              (end)="onEnd($event)"
              (ended)="onEnded($event)"
              (error)="onError($event)"
              (fullscreen-change)="onFullScreenChange($event)"
              (load-start)="onLoadStart($event)"
              (media-type-change)="onMediaTypeChange($event)"
              (pause)="onPaused($event)"
              (picture-in-picture-change)="onPipChange($event)"
              (play)="onPlay($event)"
              (playing)="onPlaying($event)"
              (quality-change)="onQualityChange($event)"
              (rate-change)="onRateChange($event)"
              (seeking)="onSeeking($event)"
              (seeked)="onSeeked($event)"
              (time-change)="onTimeChange($event)"
              (volume-change)="onVolumeChange($event)">
  <media-provider></media-provider>
</media-player>