/* eslint-disable */
import { Injectable } from '@angular/core';
import { User } from '../../models/User';
import { RoleMapping } from '../../models/RoleMapping';
import { Role } from '../../models/Role';
import { Project } from '../../models/Project';
import { Tenant } from '../../models/Tenant';
import { Person } from '../../models/Person';
import { Page } from '../../models/Page';
import { Component } from '../../models/Component';
import { PageComponent } from '../../models/PageComponent';
import { DynamicInput } from '../../models/DynamicInput';
import { ComponentSample } from '../../models/ComponentSample';
import { FieldType } from '../../models/FieldType';
import { ComponentSlot } from '../../models/ComponentSlot';
import { Category } from '../../models/Category';
import { HttpRequest } from '../../models/HttpRequest';
import { ComponentDefinition } from '../../models/ComponentDefinition';
import { SlotDefinition } from '../../models/SlotDefinition';
import { ComponentInputDefinition } from '../../models/ComponentInputDefinition';
import { ComponentOutputDefinition } from '../../models/ComponentOutputDefinition';
import { FieldDefinition } from '../../models/FieldDefinition';
import { KeyValue } from '../../models/KeyValue';
import { FieldDataDefinition } from '../../models/FieldDataDefinition';
import { MessageLogs } from '../../models/MessageLogs';
import { Message } from '../../models/Message';
import { EnvironmentVariable } from '../../models/EnvironmentVariable';
import { MessageTemplate } from '../../models/MessageTemplate';
import { Settings } from '../../models/Settings';
import { VerificationToken } from '../../models/VerificationToken';
import { PhoneNumber } from '../../models/PhoneNumber';
import { ProjectSetting } from '../../models/ProjectSetting';
import { ProjectFile } from '../../models/ProjectFile';
import { PersonProject } from '../../models/PersonProject';
import { ProjectRole } from '../../models/ProjectRole';
import { ProjectFolder } from '../../models/ProjectFolder';
import { MessageTemplateDataField } from '../../models/MessageTemplateDataField';
import { PersonProjectRole } from '../../models/PersonProjectRole';
import { ProjectVersion } from '../../models/ProjectVersion';
import { Metadata } from '../../models/Metadata';
import { ProjectTheme } from '../../models/ProjectTheme';
import { ComponentDefinitionSubCategory } from '../../models/ComponentDefinitionSubCategory';
import { ProjectExport } from '../../models/ProjectExport';
import { ProjectExternalScript } from '../../models/ProjectExternalScript';
import { UserInvitation } from '../../models/UserInvitation';
import { Outlet } from '../../models/Outlet';
import { ProjectModel } from '../../models/ProjectModel';
import { ProjectModelField } from '../../models/ProjectModelField';
import { ProjectModelRelation } from '../../models/ProjectModelRelation';
import { UIDataStore } from '../../models/UIDataStore';
import { DataField } from '../../models/DataField';
import { UIDataEvent } from '../../models/UIDataEvent';
import { UIDataUpdateFunction } from '../../models/UIDataUpdateFunction';
import { DataSchema } from '../../models/DataSchema';
import { DataSchemaField } from '../../models/DataSchemaField';
import { DataMapping } from '../../models/DataMapping';
import { LibraryFunction } from '../../models/LibraryFunction';
import { DataFieldSchema } from '../../models/DataFieldSchema';
import { ProjectPackage } from '../../models/ProjectPackage';
import { RappiderFunction } from '../../models/RappiderFunction';
import { ComponentDataSubscription } from '../../models/ComponentDataSubscription';
import { DataSchemaTreeField } from '../../models/DataSchemaTreeField';
import { DataSchemaTreeFieldNode } from '../../models/DataSchemaTreeFieldNode';
import { ComponentStyleSheetDefinition } from '../../models/ComponentStyleSheetDefinition';
import { ComponentStyleSheet } from '../../models/ComponentStyleSheet';
import { DataSchemaDataDefinition } from '../../models/DataSchemaDataDefinition';
import {
  DataTransformation,
  Workflow,
  WorkflowEvent,
  WorkflowStepFunction,
  WorkflowStepFunctionPublishedEvent,
  WorkflowStepFunctionSubscribedEvent,
} from '../../models';

export interface Models {
  [name: string]: any;
}

@Injectable()
export class SDKModels {
  private models: Models = {
    User: User,
    RoleMapping: RoleMapping,
    Role: Role,
    Project: Project,
    Tenant: Tenant,
    Person: Person,
    Page: Page,
    Component: Component,
    PageComponent: PageComponent,
    DynamicInput: DynamicInput,
    ComponentSample: ComponentSample,
    FieldType: FieldType,
    ComponentSlot: ComponentSlot,
    Category: Category,
    HttpRequest: HttpRequest,
    ComponentDefinition: ComponentDefinition,
    SlotDefinition: SlotDefinition,
    ComponentInputDefinition: ComponentInputDefinition,
    ComponentOutputDefinition: ComponentOutputDefinition,
    FieldDefinition: FieldDefinition,
    KeyValue: KeyValue,
    FieldDataDefinition: FieldDataDefinition,
    MessageLogs: MessageLogs,
    Message: Message,
    EnvironmentVariable: EnvironmentVariable,
    MessageTemplate: MessageTemplate,
    Settings: Settings,
    VerificationToken: VerificationToken,
    PhoneNumber: PhoneNumber,
    ProjectSetting: ProjectSetting,
    ProjectFile: ProjectFile,
    PersonProject: PersonProject,
    ProjectRole: ProjectRole,
    ProjectFolder: ProjectFolder,
    MessageTemplateDataField: MessageTemplateDataField,
    PersonProjectRole: PersonProjectRole,
    ProjectVersion: ProjectVersion,
    Metadata: Metadata,
    ProjectTheme: ProjectTheme,
    ComponentDefinitionSubCategory: ComponentDefinitionSubCategory,
    ProjectExport: ProjectExport,
    ProjectExternalScript: ProjectExternalScript,
    UserInvitation: UserInvitation,
    Outlet: Outlet,
    ProjectModel: ProjectModel,
    ProjectModelField: ProjectModelField,
    ProjectModelRelation: ProjectModelRelation,
    UIDataStore: UIDataStore,
    DataField: DataField,
    UIDataEvent: UIDataEvent,
    UIDataUpdateFunction: UIDataUpdateFunction,
    DataSchema: DataSchema,
    DataSchemaField: DataSchemaField,
    DataMapping: DataMapping,
    LibraryFunction: LibraryFunction,
    DataFieldSchema: DataFieldSchema,
    ProjectPackage: ProjectPackage,
    RappiderFunction: RappiderFunction,
    ComponentDataSubscription: ComponentDataSubscription,
    DataSchemaTreeField: DataSchemaTreeField,
    DataSchemaTreeFieldNode: DataSchemaTreeFieldNode,
    ComponentStyleSheetDefinition: ComponentStyleSheetDefinition,
    ComponentStyleSheet: ComponentStyleSheet,
    DataSchemaDataDefinition: DataSchemaDataDefinition,
    WorkflowStepFunction: WorkflowStepFunction,
    Workflow: Workflow,
    WorkflowStepFunctionPublishedEvent: WorkflowStepFunctionPublishedEvent,
    WorkflowStepFunctionSubscribedEvent: WorkflowStepFunctionSubscribedEvent,
    WorkflowEvent: WorkflowEvent,
    DataTransformation: DataTransformation,
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
