export const GetUIStepFunctionWithDetailsFilter = [
  { relation: 'subscribedEvents' },
  { relation: 'publishedEventsOnFailure' },
  { relation: 'publishedEventsOnSuccess' },
  { relation: 'preDataTransformation' },
  { relation: 'postDataTransformation' },
  { relation: 'endpoint' },
  {
    relation: 'uiDataStoreSubscriptions',
    scope: {
      include: [
        {
          relation: 'dataSchemaTreeField',
          scope: {
            include: [
              {
                relation: 'nodes',
                scope: {
                  include: ['field']
                }
              }
            ]
          }
        },
        {
          relation: 'uiDataStore'
        }
      ]
    }
  }
];

