<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton"
                        [displayBreadcrumb]="displayBreadcrumb"></rappider-title-toolbar>

<div class="detail-page">
  <section>
    <section *ngIf="dataEvent?.description"
             class="section">
      {{ dataEvent.description }}
    </section>
    <nz-tabset nzType="card"
               nzAnimated="true"
               nzTabPosition="top"
               nzSize="default"
               class="card-container">
      <nz-tab [nzTitle]="'PROJECT_MODULE.DATA_EVENT_CREATE_COMPONENT.PAYLOAD' | translate">
        <rappider-spin [spinning]="isLoading">
          <rappider-data-event-payload *ngIf="dataEvent"
                                       [dataEvent]="dataEvent"
                                       [navigateAfterCreate]="true"></rappider-data-event-payload>
        </rappider-spin>
      </nz-tab>
    </nz-tabset>
  </section>
</div>