import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ProjectInterface, ProjectRoleInterface } from '@rappider/api-sdk';
import { SelectableOption, SelectMode, SelectSettings } from '@rappider/rappider-components/utils';
import { ProjectRole } from '@rappider/rappider-sdk';
import { ProjectService } from '@rappider/services';
import { ChangeRoleEvent, RolesModal } from '../models/project-members.interface';
import { Store } from '@ngrx/store';
import { LoadModule } from '../../../states/project-roles-state/project-role.actions';
import { NotificationService } from '@rappider/services';

@Component({
  selector: 'rappider-member-roles',
  templateUrl: './member-roles.component.html',
  styleUrls: ['./member-roles.component.scss']
})
export class MemberRolesComponent implements OnInit, OnChanges {

  @Input() activeProject: ProjectInterface;
  @Input() rolesModalConfig: RolesModal;

  @Output() changeRole = new EventEmitter<ChangeRoleEvent>();

  selectedRoleIds: string[] = [];
  projectRoles: ProjectRoleInterface[] = [];
  selectSettings: SelectSettings = {
    mode: SelectMode.Multiple,
    searchable: true
  };
  selectPlaceholder = 'PROJECT_MODULE.PROJECT_MEMBERS_COMPONENT.CHOOSE_THE_ROLES_TO_ADD';
  roleSelectOptions: SelectableOption[];


  constructor(
    private projectService: ProjectService,
    private store: Store<any>,
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {

    this.store.dispatch(new LoadModule());

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeProject) {
      this.getMemberRoles();
    }
    if (changes.rolesModalConfig) {
      this.selectedRoleIds = (<any>this.rolesModalConfig?.data)?.roles?.map(role => role.id);
    }
  }

  getMemberRoles() {
    this.store.select(state => state.projectRole.data).subscribe(roles => {
      this.projectRoles = roles;
      this.setRoleSelectOptionsFromProjectRoles();
    });
  }

  setRoleSelectOptionsFromProjectRoles() {
    this.roleSelectOptions = this.projectRoles?.map(role => ({
      key: role.title,
      value: role.id
    }));
  }

  onRolesModalSubmit() {
    const selectedRoles = this.projectRoles.filter(role => role.id === this.selectedRoleIds.find(roleId => roleId === role.id));
    const changeRoleEvent: ChangeRoleEvent = {
      selectedRoles: <ProjectRole[]>selectedRoles,
      person: this.rolesModalConfig.data
    };
    this.changeRole.emit(changeRoleEvent);
    this.onRolesModalClose();
  }

  onRolesModalClose() {
    this.rolesModalConfig.visible = false;
    this.rolesModalConfig.data = null;
    this.selectedRoleIds = [];
  }

  setModalTitle() {
    const person = this.rolesModalConfig.data;
    return `${person?.firstName} ${person?.lastName}`;
  }

}
