import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RappiderStripeComponent } from './stripe.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '@environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RappiderButtonModule } from '../button/button.module';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { TranslateModule } from '@ngx-translate/core';
import { RappiderTextboxModule } from '../textbox/textbox.module';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { RappiderIconModule } from '../icon/icon.module';
import { RappiderNumberInputModule } from '../number-input/number-input.module';
import { RappiderTagInputModule } from '../tag-input/tag-input.module';
import { RappiderAlertModule } from '../alert/alert.module';

@NgModule({
  declarations: [
    RappiderStripeComponent
  ],
  imports: [
    CommonModule,
    NgxStripeModule.forRoot(environment['stripePublishableKey']),
    FormsModule,
    ReactiveFormsModule,
    RappiderButtonModule,
    NzFormModule,
    NzInputModule,
    NzSkeletonModule,
    TranslateModule,
    RappiderTextboxModule,
    NzButtonModule,
    RappiderIconModule,
    RappiderNumberInputModule,
    RappiderTagInputModule,
    RappiderAlertModule
  ],
  exports: [
    RappiderStripeComponent
  ]
})
export class RappiderStripeModule { }
