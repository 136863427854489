import { DropdownMenuComponentConfig, MenuActionBehavior } from '@rappider/rappider-components/utils';
import { IconType } from '../../definitions/icon-type.enum';
import { PageListModuleDropdownAction } from '../../../../../pages/src/lib/components/page-list/utils/page-list-module-dropdown-action.enum';

export const PageListModuleDropdownMenuConfig: DropdownMenuComponentConfig = {
  icon: {
    name: 'ellipsis',
    type: IconType.NgZorro
  },
  items: [
    {
      label: 'PAGES_MODULE.PAGE_LIST_COMPONENT.SET_DEFAULT_MODULE',
      key: PageListModuleDropdownAction.SetAsDefaultModule,
      icon: {
        name: 'home',
        type: IconType.NgZorro
      },
      actionBehavior: MenuActionBehavior.Emit
    },
  ]
};
