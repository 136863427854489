/* eslint-disable */
import { WorkflowStepFunction, WorkflowEvent } from '../index';

declare var Object: any;
export interface WorkflowStepFunctionSubscribedEventInterface {
  id?: any;
  workflowEventId?: string;
  workflowStepFunctionId?: string;
  workflowEvent?: WorkflowEvent;
  workflowStepFunction?: WorkflowStepFunction;
}

export class WorkflowStepFunctionSubscribedEvent
  implements WorkflowStepFunctionSubscribedEventInterface {
  'id'?: any;
  'workflowEventId'?: string;
  'workflowStepFunctionId'?: string;
  workflowEvent?: WorkflowEvent;
  workflowStepFunction?: WorkflowStepFunction;
  constructor(data?: WorkflowStepFunctionSubscribedEventInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `WorkflowStepFunctionSubscribedEventInterface`.
   */
  public static getModelName() {
    return 'WorkflowStepFunctionSubscribedEvent';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of WorkflowStepFunctionSubscribedEventInterface for dynamic purposes.
   **/
  public static factory(
    data: WorkflowStepFunctionSubscribedEventInterface
  ): WorkflowStepFunctionSubscribedEvent {
    return new WorkflowStepFunctionSubscribedEvent(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'WorkflowStepFunctionSubscribedEvent',
      plural: 'WorkflowStepFunctionSubscribedEvents',
      path: 'workflow-step-function-subscribed-events',
      idName: 'id',
      properties: {
        workflowEventId: {
          name: 'workflowEventId',
          type: 'string',
        },
        workflowStepFunctionId: {
          name: 'workflowStepFunctionId',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'any',
        },
      },
      relations: {
        workflowEvent: {
          name: 'workflowEvent',
          type: 'WorkflowEvent',
          model: 'WorkflowEvent',
          relationType: 'belongsTo',
          keyFrom: 'workflowEventId',
          keyTo: 'id',
        },
        WorkflowStepFunction: {
          name: 'workflowStepFunction',
          type: 'WorkflowStepFunction',
          model: 'WorkflowStepFunction',
          relationType: 'belongsTo',
          keyFrom: 'workflowStepFunctionId',
          keyTo: 'id',
        },
      },
    };
  }
}
