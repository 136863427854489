import { ProjectWithRelations } from '@rappider/rappider-sdk';

export const CustomProjectWithAI: ProjectWithRelations = {
  id: 'customProjectWithAI',
  name: 'Custom Project with AI',
  description: 'Custom Project with AI',
  thumbnails: [
    {
      'url': 'assets/img/page-templates/blog-detail-1-thumbnail.png',
      'title': 'Welcome to Green Energy Solutions',
      'description': 'Discover the power of green energy and how we are transforming the future with sustainable and renewable energy solutions.'
    },
    {
      'url': 'assets/img/page-templates/blog-detail-2-thumbnail.png',
      'title': 'About Green Energy Solutions',
      'description': 'Learn about our mission, vision, and the dedicated team behind our efforts to promote and implement green energy technologies.'
    },
    {
      'url': 'assets/img/page-templates/blog-detail-thumbnail.png',
      'title': 'Innovative Green Energy Technologies',
      'description': 'Explore our cutting-edge technologies in solar, wind, and other renewable energy sources that drive our sustainable solutions.'
    },
    {
      'url': 'assets/img/page-templates/blog-page-thumbnail.png',
      'title': 'Our Green Energy Projects',
      'description': 'Take a look at our successful green energy projects and how they are making a positive impact on communities and the environment.'
    },
    {
      'url': 'assets/img/page-templates/calender-page-thumbnail.png',
      'title': 'Our Green Energy Services',
      'description': 'Discover the range of services we offer, from consultation and installation to maintenance and support for all your green energy needs.'
    },
    {
      'url': 'assets/img/page-templates/cc1-thumbnail.png',
      'title': 'Benefits of Green Energy',
      'description': 'Understand the environmental, economic, and social benefits of transitioning to green energy and how it can improve your life.'
    },
    {
      'url': 'assets/img/page-templates/cc2-thumbnail.png',
      'title': 'Green Energy Resources',
      'description': 'Access a wealth of resources, including articles, guides, and tools, to help you learn more about green energy and its implementation.'
    },
    {
      'url': 'assets/img/page-templates/cc3-thumbnail.png',
      'title': 'Get in Touch with Us',
      'description': 'Have questions or need more information about our green energy solutions? Contact us for assistance and inquiries.'
    }
  ],
  appType: '',
  backendFramework: '',
  frontendFramework: '',
  key: '',
  roleId: '',
  structureType: '',
  visibility: ''
};
