/* eslint-disable */
import { DataSchema } from '../index';

declare var Object: any;
export interface DataSchemaFieldInterface {
  id?: string;
  name: string;
  isArray?: boolean;
  default?: any;
  index?: number;
  parentDataSchemaId?: string;
  typeId?: string;
  uiDataSelector?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  parentDataSchema?: DataSchema;
  type?: DataSchema;
  isDeletable?: boolean;
  isUpdatable?: boolean;
}

export class DataSchemaField implements DataSchemaFieldInterface {
  'id': string;
  'name': string;
  'isArray': boolean;
  'default': any;
  'index': number;
  'parentDataSchemaId': string;
  'typeId': string;
  'uiDataSelector'?: string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  'isDeletable': boolean;
  'isUpdatable': boolean;
  parentDataSchema: DataSchema;
  type: DataSchema;
  constructor(data?: DataSchemaFieldInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `DataSchemaField`.
   */
  public static getModelName() {
    return 'DataSchemaField';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of DataSchemaField for dynamic purposes.
   **/
  public static factory(data: DataSchemaFieldInterface): DataSchemaField {
    return new DataSchemaField(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'DataSchemaField',
      plural: 'DataSchemaFields',
      path: 'data-schema-fields',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        isArray: {
          name: 'isArray',
          type: 'boolean',
          default: false,
        },
        default: {
          name: 'default',
          type: 'any',
        },
        index: {
          name: 'index',
          type: 'number',
        },
        parentDataSchemaId: {
          name: 'parentDataSchemaId',
          type: 'string',
        },
        typeId: {
          name: 'typeId',
          type: 'string',
        },
        uiDataSelector: {
          name: 'uiDataSelector',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
        isDeletable: {
          name: 'isDeletable',
          type: 'boolean',
        },
        isUpdatable: {
          name: 'isUpdatable',
          type: 'boolean',
        },
      },
      relations: {
        parentDataSchema: {
          name: 'parentDataSchema',
          type: 'DataSchema',
          model: 'DataSchema',
          relationType: 'belongsTo',
          keyFrom: 'parentDataSchemaId',
          keyTo: 'id',
        },
        type: {
          name: 'type',
          type: 'DataSchema',
          model: 'DataSchema',
          relationType: 'belongsTo',
          keyFrom: 'typeId',
          keyTo: 'id',
        },
      },
    };
  }
}
