import { CrudTableViewConfig, CrudViewColumnType, ActionBehavior } from '@rappider/rappider-components/utils';

export const DATA_TRANSFORMATION_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'firstName',
  columns: [
    {
      title: 'Operation Field',
      fieldName: 'flatOperationField',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'operator',
      fieldName: 'operator',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Jsonata Config',
      fieldName: 'config.script',
      type: CrudViewColumnType.Code,
    }
  ],
  listActions: [],
  itemActions: [
    {
      name: 'Edit',
      text: 'SHARED.EDIT',
      icon: { name: 'far fa-edit' },
      behavior: ActionBehavior.Emit
    },
    {
      name: 'Delete',
      text: 'SHARED.DELETE',
      icon: { name: 'far fa-trash' },
      behavior: ActionBehavior.Emit
    }
  ]
};
