/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EnvironmentVariable } from '../models/environment-variable';
import { EnvironmentVariableUpdateDto } from '../models/environment-variable-update-dto';
import { EnvironmentVariableWithRelations } from '../models/environment-variable-with-relations';
import { NewEnvironmentVariable } from '../models/new-environment-variable';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentVariableControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation environmentVariableControllerCount
   */
  static readonly EnvironmentVariableControllerCountPath = '/environment-variables/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params?: {
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, EnvironmentVariableControllerService.EnvironmentVariableControllerCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params?: {
    where?: any;
  }): Observable<LoopbackCount> {

    return this.count$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation environmentVariableControllerCreateEnvironmentVariableForAllEnvironmentKeys
   */
  static readonly EnvironmentVariableControllerCreateEnvironmentVariableForAllEnvironmentKeysPath = '/environment-variables/create-environment-variable-for-all-environment-keys';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createEnvironmentVariableForAllEnvironmentKeys()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createEnvironmentVariableForAllEnvironmentKeys$Response(params?: {
    body?: NewEnvironmentVariable
  }): Observable<StrictHttpResponse<Array<EnvironmentVariable>>> {

    const rb = new RequestBuilder(this.rootUrl, EnvironmentVariableControllerService.EnvironmentVariableControllerCreateEnvironmentVariableForAllEnvironmentKeysPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EnvironmentVariable>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createEnvironmentVariableForAllEnvironmentKeys$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createEnvironmentVariableForAllEnvironmentKeys(params?: {
    body?: NewEnvironmentVariable
  }): Observable<Array<EnvironmentVariable>> {

    return this.createEnvironmentVariableForAllEnvironmentKeys$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EnvironmentVariable>>) => r.body as Array<EnvironmentVariable>)
    );
  }

  /**
   * Path part for operation environmentVariableControllerGetEnvironmentKeys
   */
  static readonly EnvironmentVariableControllerGetEnvironmentKeysPath = '/environment-variables/get-environment-keys';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEnvironmentKeys()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnvironmentKeys$Response(params?: {
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, EnvironmentVariableControllerService.EnvironmentVariableControllerGetEnvironmentKeysPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEnvironmentKeys$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnvironmentKeys(params?: {
  }): Observable<Array<string>> {

    return this.getEnvironmentKeys$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation environmentVariableControllerFindById
   */
  static readonly EnvironmentVariableControllerFindByIdPath = '/environment-variables/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<EnvironmentVariable>> {

    const rb = new RequestBuilder(this.rootUrl, EnvironmentVariableControllerService.EnvironmentVariableControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EnvironmentVariable>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
    filter?: any;
  }): Observable<EnvironmentVariable> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<EnvironmentVariable>) => r.body as EnvironmentVariable)
    );
  }

  /**
   * Path part for operation environmentVariableControllerDeleteById
   */
  static readonly EnvironmentVariableControllerDeleteByIdPath = '/environment-variables/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EnvironmentVariableControllerService.EnvironmentVariableControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: string;
  }): Observable<void> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation environmentVariableControllerUpdateById
   */
  static readonly EnvironmentVariableControllerUpdateByIdPath = '/environment-variables/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    body?: EnvironmentVariableUpdateDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EnvironmentVariableControllerService.EnvironmentVariableControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    body?: EnvironmentVariableUpdateDto
  }): Observable<void> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation environmentVariableControllerFind
   */
  static readonly EnvironmentVariableControllerFindPath = '/environment-variables';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<EnvironmentVariableWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, EnvironmentVariableControllerService.EnvironmentVariableControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EnvironmentVariableWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<EnvironmentVariableWithRelations>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EnvironmentVariableWithRelations>>) => r.body as Array<EnvironmentVariableWithRelations>)
    );
  }

  /**
   * Path part for operation environmentVariableControllerCreate
   */
  static readonly EnvironmentVariableControllerCreatePath = '/environment-variables';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    body?: NewEnvironmentVariable
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, EnvironmentVariableControllerService.EnvironmentVariableControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    body?: NewEnvironmentVariable
  }): Observable<any> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
