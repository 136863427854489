import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BrowserStorageManagementService, RouterStateService } from '../../services';
import { QueryParam, PATH_DEFINITIONS } from '../../definitions';

@Injectable({
  providedIn: 'root'
})
export class ActivePersonGuard implements CanActivate {

  constructor(
    private store: Store<any>,
    private routerStateService: RouterStateService,
    private browserStorageManagementService: BrowserStorageManagementService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    console.log('ACTIVE PERSON GUARD');
    return this.store.select(s => s.auth.activePerson).pipe(
      map(person => {
        if (!person) {
          const activePersonId = this.browserStorageManagementService.getLocalStorageItem('activePersonId');
          if (!activePersonId) {
            this.routerStateService.addQueryParam(QueryParam.RedirectUrl, state.url);
            this.routerStateService.navigate(PATH_DEFINITIONS.AUTH.PERSON_SELECTION_PATH);
          }
          return !!activePersonId;
        }
        return !!person;
      })
    );
  }

}
