import { Injectable } from '@angular/core';
import { ProjectModelEndpointParam, ProjectModelEndpointQueryParam } from '@rappider/rappider-sdk';

@Injectable({
  providedIn: 'root'
})
export class CustomEndpointParamsService {

  /**
   * gets path params and query params to display the full path preview
   *
   * @param {string} path
   * @param {string[]} pathParams
   * @param {string[]} queryParams
   * @return {*}  {string}
   * @memberof CustomEndpointParamsService
   */
  setPathPreview(path: string, params: string[], queryParams: string[]): string {
    let previewParams = path;
    if (params?.length) {
      previewParams = `${previewParams + '/:' + params.join('/:')}`;
    }
    if (queryParams?.length) {
      previewParams = `${previewParams + '?' + queryParams.map((param: string, index: number) => {
        if (index !== queryParams.length - 1) {
          return `${param + '="value"' + '&'}`;
        } else {
          return `${param + '="value"'}`;
        }
      }).join('')}`;
    }

    return previewParams;
  }

  getParamNames(params: ProjectModelEndpointQueryParam[] | ProjectModelEndpointParam[]) {
    if (params) {
      return params.map(param => param.name);
    }
  }

  getTypeIdsOfParams(params: ProjectModelEndpointQueryParam[] | ProjectModelEndpointParam[]) {
    if (params) {
      return params.map(param => param.typeId);
    }
  }

  /**
   * sets type id of given param or query param array
   *
   * @param {(ProjectModelEndpointQueryParam[] | ProjectModelEndpointParam[])} params
   * @param {string} typeId
   * @return {*}
   * @memberof CustomEndpointParamsService
   */
  setParamTypeIds(params: ProjectModelEndpointQueryParam[] | ProjectModelEndpointParam[], typeId: string) {
    return params?.map(param => ({ ...param, typeId: typeId }));
  }

}
