import { createAction, props } from '@ngrx/store';
import { ContainerTemplateCopyToPageContainerDto, ContainerTemplateCreateDto, ContainerTemplateWithRelations } from '@rappider/rappider-sdk';

/* action types */
export enum ActionTypes {
  GetContainerTemplates = '[ContainerTemplate] GetContainerTemplates',
  GetContainerTemplatesSuccessful = '[ContainerTemplate] GetContainerTemplatesSuccessful',
  CreateContainerTemplate = '[ContainerTemplate] CreateContainerTemplate',
  CreateContainerTemplateSuccessful = '[ContainerTemplate] CreateContainerTemplateSuccessful',
  UpdateContainerTemplate = '[ContainerTemplate] UpdateContainerTemplate',
  UpdateContainerTemplateSuccessful = '[ContainerTemplate] UpdateContainerTemplateSuccessful',
  DeleteContainerTemplate = '[ContainerTemplate] DeleteContainerTemplate',
  DeleteContainerTemplateSuccessful = '[ContainerTemplate] DeleteContainerTemplateSuccessful',
  CopyTemplateToPageContainer = '[ContainerTemplate] CopyTemplateToPageContainer',
  CopyTemplateToPageContainerSuccessful = '[ContainerTemplate] CopyTemplateToPageContainerSuccessful',
  ErrorAction = '[ContainerTemplate] ErrorAction',
  ResetStateToInitial = '[ContainerTemplate] ResetStateToInitial',
  ResetStateToInitialChangeProject = '[ContainerTemplate] ResetStateToInitialChangeProject'
}

/* actions */
export const GetContainerTemplates = createAction(ActionTypes.GetContainerTemplates);

export const GetContainerTemplatesSuccessful = createAction(
  ActionTypes.GetContainerTemplatesSuccessful,
  props<{ payload: { containerTemplates: ContainerTemplateWithRelations[] } }>()
);

export const CreateContainerTemplate = createAction(
  ActionTypes.CreateContainerTemplate,
  props<{ payload: { containerTemplate: ContainerTemplateCreateDto } }>()
);

export const CreateContainerTemplateSuccessful = createAction(
  ActionTypes.CreateContainerTemplateSuccessful,
  props<{ payload: { containerTemplates: ContainerTemplateWithRelations[] } }>()
);

export const CopyTemplateToPageContainer = createAction(
  ActionTypes.CopyTemplateToPageContainer,
  props<{ payload: { containerTemplateId: string } }>()
);

export const CopyTemplateToPageContainerSuccessful = createAction(
  ActionTypes.CopyTemplateToPageContainerSuccessful
);

export const ResetStateToInitial = createAction(
  ActionTypes.ResetStateToInitial
);

export const ResetStateToInitialChangeProject = createAction(ActionTypes.ResetStateToInitialChangeProject);

export const ErrorAction = createAction(
  ActionTypes.ErrorAction,
  props<{ payload: { error: any; key: string; timestamp: number } }>()
);
