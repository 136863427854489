import { Validators } from '@angular/forms';
import { PropertyType } from '@rappider/rappider-components/utils';

export const ROW_FORM_KEY_VALUE_COLUMNS = [
  {
    fieldName: 'key',
    unique: true,
    typeAndFormat: {
      type: PropertyType.String
    },
    config: {
      placeholder: 'SHARED.KEY'
    }
  },
  {
    fieldName: 'value',
    typeAndFormat: {
      type: PropertyType.String
    },
    config: {
      placeholder: 'SHARED.VALUE'
    }
  }
];

export const ROW_FORM_ID_KEY_VALUE_COLUMNS = [
  {
    fieldName: 'id',
    visible: false,
    typeAndFormat: {
      type: PropertyType.String
    }
  },
  {
    fieldName: 'key',
    unique: true,
    typeAndFormat: {
      type: PropertyType.String
    },
    config: {
      placeholder: 'SHARED.KEY'
    }
  },
  {
    fieldName: 'value',
    typeAndFormat: {
      type: PropertyType.String
    },
    config: {
      placeholder: 'SHARED.VALUE'
    }
  }
];
