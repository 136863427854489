/* eslint-disable */
import { DataField } from '../index';

declare var Object: any;
export interface LibraryFunctionInterface {
  id: string;
  name: string;
  category: string;
  programmingLanguage: string;
  returnType: string;
  code: string;
  description?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  parameters?: DataField[];
}

export class LibraryFunction implements LibraryFunctionInterface {
  'id': string;
  'name': string;
  'category': string;
  'programmingLanguage': string;
  'returnType': string;
  'code': string;
  'description': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  parameters: DataField[];
  constructor(data?: LibraryFunctionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `LibraryFunction`.
   */
  public static getModelName() {
    return 'LibraryFunction';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of LibraryFunction for dynamic purposes.
   **/
  public static factory(data: LibraryFunctionInterface): LibraryFunction {
    return new LibraryFunction(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'LibraryFunction',
      plural: 'LibraryFunctions',
      path: 'library-functions',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        category: {
          name: 'category',
          type: 'string',
          default: 'ui',
        },
        programmingLanguage: {
          name: 'programmingLanguage',
          type: 'string',
          default: 'typescript',
        },
        returnType: {
          name: 'returnType',
          type: 'string',
        },
        code: {
          name: 'code',
          type: 'string',
        },
        description: {
          name: 'description',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
      },
      relations: {
        parameters: {
          name: 'parameters',
          type: 'DataField[]',
          model: 'DataField',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'libraryFunctionId',
        },
      },
    };
  }
}
