/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { NewPermission } from '../models/new-permission';
import { Permission } from '../models/permission';
import { PermissionPartial } from '../models/permission-partial';
import { PermissionWithRelations } from '../models/permission-with-relations';

@Injectable({
  providedIn: 'root',
})
export class PermissionControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation permissionControllerFindByIdByAdmin
   */
  static readonly PermissionControllerFindByIdByAdminPath = '/admin/permissions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByIdByAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByIdByAdmin$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<PermissionWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, PermissionControllerService.PermissionControllerFindByIdByAdminPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PermissionWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByIdByAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByIdByAdmin(params: {
    id: string;
    filter?: any;
  }): Observable<PermissionWithRelations> {

    return this.findByIdByAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<PermissionWithRelations>) => r.body as PermissionWithRelations)
    );
  }

  /**
   * Path part for operation permissionControllerDeleteByIdByAdmin
   */
  static readonly PermissionControllerDeleteByIdByAdminPath = '/admin/permissions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteByIdByAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteByIdByAdmin$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, PermissionControllerService.PermissionControllerDeleteByIdByAdminPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteByIdByAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteByIdByAdmin(params: {
    id: string;
  }): Observable<any> {

    return this.deleteByIdByAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation permissionControllerUpdateByIdByAdmin
   */
  static readonly PermissionControllerUpdateByIdByAdminPath = '/admin/permissions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateByIdByAdmin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateByIdByAdmin$Response(params: {
    id: string;
    body?: PermissionPartial
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, PermissionControllerService.PermissionControllerUpdateByIdByAdminPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateByIdByAdmin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateByIdByAdmin(params: {
    id: string;
    body?: PermissionPartial
  }): Observable<any> {

    return this.updateByIdByAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation permissionControllerFindByAdmin
   */
  static readonly PermissionControllerFindByAdminPath = '/admin/permissions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByAdmin$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<PermissionWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, PermissionControllerService.PermissionControllerFindByAdminPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PermissionWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByAdmin(params?: {
    filter?: any;
  }): Observable<Array<PermissionWithRelations>> {

    return this.findByAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PermissionWithRelations>>) => r.body as Array<PermissionWithRelations>)
    );
  }

  /**
   * Path part for operation permissionControllerCreateByAdmin
   */
  static readonly PermissionControllerCreateByAdminPath = '/admin/permissions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createByAdmin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createByAdmin$Response(params?: {
    body?: NewPermission
  }): Observable<StrictHttpResponse<Permission>> {

    const rb = new RequestBuilder(this.rootUrl, PermissionControllerService.PermissionControllerCreateByAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Permission>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createByAdmin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createByAdmin(params?: {
    body?: NewPermission
  }): Observable<Permission> {

    return this.createByAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<Permission>) => r.body as Permission)
    );
  }

  /**
   * Path part for operation permissionControllerFind
   */
  static readonly PermissionControllerFindPath = '/permissions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<PermissionWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, PermissionControllerService.PermissionControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PermissionWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<PermissionWithRelations>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PermissionWithRelations>>) => r.body as Array<PermissionWithRelations>)
    );
  }

}
