import { Injectable } from '@angular/core';
import { UiDataEventWithRelations, UiWorkflowStepFunctionWithRelations } from '@rappider/rappider-sdk';
import { DiagramItemType } from '../diagram-item-type';
import { DiagramTreeItem } from '../interfaces/diagram-editor/diagram-tree-item.interface';
import { v4 } from 'uuid';
import { UIDataEventType } from 'libs/shared/src/lib/definitions/ui-data-event/ui-data-event-type.enum';
import { of } from 'rxjs';
import { DiagramTreeItemConnector } from '../interfaces/diagram-editor/diagram-tree-item-connector.interface';
import { WorkflowItemInDiagram } from '../workflow-item-in-diagram.interface';

@Injectable({
  providedIn: 'root'
})
export class DiagramTreeService {

  constructor() { }

  buildDiagramTree(
    uiDataEvents: UiDataEventWithRelations[],
    uiStepFunctions: UiWorkflowStepFunctionWithRelations[]
  ) {
    const connectedNodes: DiagramTreeItemConnector[] = [];
    const starterEvents = this.findStarterEvents(uiDataEvents, uiStepFunctions);

    if (starterEvents) {
      return of({
        diagramTree: starterEvents.map(event => this.buildTreeNodesFromUIDataEvent(event, uiStepFunctions, uiDataEvents, connectedNodes)),
        connectedNodes: connectedNodes
      });
    } else {
      return null;
    }
  }

  /**
   * This function finds the starter events.
   * Starter Events => Events that at least subscribed from one ui step function and didn't published from any step functions.
   *
   * @param {UiDataEventWithRelations[]} uiDataEvents
   * @param {UiWorkflowStepFunctionWithRelations[]} uiStepFunctions
   * @return {*}
   * @memberof DiagramTreeService
   */
  findStarterEvents(uiDataEvents: UiDataEventWithRelations[], uiStepFunctions: UiWorkflowStepFunctionWithRelations[]) {
    const starterEvents = uiDataEvents.filter(
      event => !uiStepFunctions.some(
        stepFunction => stepFunction.publishedEventsOnFailure?.some(
          failureEvent => failureEvent.id === event.id
        ) || stepFunction.publishedEventsOnSuccess?.some(
          successEvent => successEvent.id === event.id)
      ));
    starterEvents.sort(function compare(firstEvent, secondEvent) {
      const isFirstEventHasSubscribedStepFunction = uiStepFunctions.some(stepFunction => stepFunction.subscribedEvents?.some(subscribedEvent => subscribedEvent.id === firstEvent.id));
      const isSecondEventHasSubscribedStepFunction = uiStepFunctions.some(stepFunction => stepFunction.subscribedEvents?.some(subscribedEvent => subscribedEvent.id === secondEvent.id));
      if (isFirstEventHasSubscribedStepFunction && !isSecondEventHasSubscribedStepFunction) {
        return -1;
      }
      if (!isFirstEventHasSubscribedStepFunction && isSecondEventHasSubscribedStepFunction) {
        return 1;
      }
      return 0;
    });
    return starterEvents;
  }

  private buildTreeNodesFromUIDataEvent(
    uiDataEvent: UiDataEventWithRelations,
    uiStepFunctions: UiWorkflowStepFunctionWithRelations[],
    uiDataEvents: UiDataEventWithRelations[],
    connectedNodes: DiagramTreeItemConnector[]
  ): DiagramTreeItem {
    const subscribedUIStepFunctions = uiStepFunctions.filter(stepFunction => stepFunction.subscribedEvents.some(event => !uiDataEvent?.origin ? event.id === uiDataEvent.id : event.id === uiDataEvent.origin.id));

    const childrenNodes = subscribedUIStepFunctions.map(uiStepFunction => {
      let childrenNode;
      if (connectedNodes.every(node => node.from !== uiStepFunction.id && node.to !== uiStepFunction.id)) {
        childrenNode = this.buildTreeNodesFromUIStepFunction(uiStepFunction, uiStepFunctions, uiDataEvents, connectedNodes);
        connectedNodes.push({ from: uiDataEvent.id, to: childrenNode.id });
      } else {
        const replacedId = v4();
        const replacedUIStepFunction = {
          ...uiStepFunction,
          id: replacedId,
          origin: uiStepFunction
        };
        childrenNode = this.buildTreeNodesFromUIStepFunction(replacedUIStepFunction, uiStepFunctions, uiDataEvents, connectedNodes);
        connectedNodes.push({ from: uiDataEvent.id, to: replacedId });
      }
      return childrenNode;
    });

    const node = {
      ...uiDataEvent,
      /* itemType is for specifying workflow diagram item type */
      type: DiagramItemType.UIDataEvent,
      items: childrenNodes,
      item: uiDataEvent
    };

    if (uiDataEvent.type === UIDataEventType.Timer) {
      node.type = DiagramItemType.UIScheduledDataEvent;
    } else {
      node.type = DiagramItemType.UIDataEvent;
    }

    return node;
  }

  private buildTreeNodesFromUIStepFunction(
    uiStepFunction: UiWorkflowStepFunctionWithRelations,
    uiStepFunctions: UiWorkflowStepFunctionWithRelations[],
    uiDataEvents: UiDataEventWithRelations[],
    connectedNodes: DiagramTreeItemConnector[]
  ): DiagramTreeItem {
    const publishedEvents = [
      ...(uiDataEvents.filter(event => uiStepFunction.publishedEventsOnSuccess?.some(publishedEvent => publishedEvent.id === event.id)) ?? []),
      ...(uiDataEvents.filter(event => uiStepFunction.publishedEventsOnFailure?.some(publishedEvent => publishedEvent.id === event.id)) ?? [])
    ];

    if (publishedEvents.length) {
      const childrenNodesForStepFunction = publishedEvents.map(event => {
        let childrenNode;
        if (connectedNodes.every(node => node.from !== event.id && node.to !== event.id)) {
          childrenNode = this.buildTreeNodesFromUIDataEvent(event, uiStepFunctions, uiDataEvents, connectedNodes);
          connectedNodes.push({ from: uiStepFunction.id, to: event.id });
        } else {
          // TODO: Burda n seviye origin basıyor olabiliriz, kontrol edilmeli !! @talhakca
          // console.log(event?.origin);
          const replacedUIDataEventId = v4();
          const replacedUIDataEvent = {
            ...event,
            id: replacedUIDataEventId,
            origin: event
          };
          childrenNode = this.buildTreeNodesFromUIDataEvent(replacedUIDataEvent, uiStepFunctions, uiDataEvents, connectedNodes);
          connectedNodes.push({ from: uiStepFunction.id, to: replacedUIDataEventId });
        }
        return childrenNode;
      });
      return {
        ...uiStepFunction,
        /* itemType is for specifying workflow diagram item type */
        type: uiStepFunction.endpointId
          || uiStepFunction.uiDataStoreSubscriptions?.length
          || uiStepFunction.workflowStepFunctionId
          || uiStepFunction.workflowServiceId
          || uiStepFunction.workflowEventId
          ? DiagramItemType.UIStepFunctionWithRelations : DiagramItemType.UIStepFunction,
        items: childrenNodesForStepFunction,
        item: uiStepFunction
      };
    } else {
      return {
        ...uiStepFunction,
        item: uiStepFunction,
        type: uiStepFunction.endpointId
          || uiStepFunction.uiDataStoreSubscriptions?.length
          || uiStepFunction.workflowStepFunctionId
          || uiStepFunction.workflowServiceId
          || uiStepFunction.workflowEventId
          ? DiagramItemType.UIStepFunctionWithRelations : DiagramItemType.UIStepFunction
      };
    }
  }

  /**
   * sets active item's data depending if it has origin data
   *
   * @memberof DiagramTreeService
   */
  setActiveItemItemData(activeItem: WorkflowItemInDiagram) {
    let item: WorkflowItemInDiagram = null;

    if (activeItem?.item?.origin) {
      item = {
        item: activeItem?.item?.origin,
        type: activeItem?.type
      };
    } else {
      item = activeItem;
    }

    return item;
  }

}
