<input nz-input
       [(ngModel)]="value"
       [placeholder]="placeholder"
       [nzAutocomplete]="auto"
       [ngStyle]="borderStyles"
       [style.border-top]="borderSettings?.borderTop + ' ' + borderSettings?.borderStyle"
       [style.border-right]="borderSettings?.borderRight + ' ' + borderSettings?.borderStyle"
       [style.border-bottom]="borderSettings?.borderBottom + ' ' + borderSettings?.borderStyle"
       [style.border-left]="borderSettings?.borderLeft + ' ' + borderSettings?.borderStyle"
       [style.border-radius]="borderSettings?.borderRadius"
       [style.border-top-left-radius]="borderSettings?.borderTopLeftRadius"
       [style.border-top-right-radius]="borderSettings?.borderTopRightRadius"
       [style.border-bottom-left-radius]="borderSettings?.borderBottomLeftRadius"
       [style.border-bottom-right-radius]="borderSettings?.borderBottomRightRadius"
       [style.border-color]="borderSettings?.borderColor"
       [style.border-style]="borderSettings?.borderStyle"
       [style.width]="sizeSettings?.width"
       [style.height]="sizeSettings?.height"
       [style.min-width]="sizeSettings?.minWidth"
       [style.min-height]="sizeSettings?.minHeight"
       [style.max-width]="sizeSettings?.maxWidth"
       [style.max-height]="sizeSettings?.maxHeight"
       [style.color]="colorSettings?.color"
       [style.background-color]="colorSettings?.backgroundColor"
       [style.boxShadow]="boxShadowSettings?.boxShadow"
       [style.padding]="paddingSettings?.all"
       [style.margin]="marginSettings?.all"
       [style.fontSize]="typographySettings?.fontSize"
       [style.fontWeight]="typographySettings?.fontWeight"
       [style.fontStyle]="typographySettings?.fontStyle"
       [style.textTecoration]="typographySettings?.textDecoration"
       [style.textAlign]="typographySettings?.textAlign"
       [style.textShadow]="typographySettings?.textShadow"
       [style.fontFamily]="typographySettings?.fontFamily"
       [style.fontStretch]="typographySettings?.fontStretch"
       [style.textIndent]="typographySettings?.textIndent"
       [style.letterSpacing]="typographySettings?.letterSpacing"
       [style.lineHeight]="typographySettings?.lineHeight"
       [style.wordSpacing]="typographySettings?.wordSpacing"
       [style.textTransform]="typographySettings?.textTransform"
       [style.userSelect]="typographySettings?.userSelect"
       (blur)="onBlur()"
       (ngModelChange)="onTextboxValueChange($event)" />
<nz-autocomplete #auto
                 [nzBackfill]="backfill"
                 [nzDataSource]="dataSource"
                 [nzDefaultActiveFirstOption]="defaultActiveFirstOption"
                 [nzOverlayClassName]="overlayClassName"
                 [nzOverlayStyle]="overlayStyle">
</nz-autocomplete>