import { Component, Input, OnDestroy, OnInit, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { UpdateGuidanceKey, UpdateGuidanceParam } from '../state/guidance.actions';
import { GuidanceDefinitions } from '../utils/guidance.definition';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UrlService } from '@rappider/services';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'rappider-guidance',
  templateUrl: './guidance.component.html',
  styleUrls: ['./guidance.component.scss']
})
export class GuidanceComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isToggleButtonVisible = true;
  @Input() isGuidanceExpanded = true;
  @Input() currentUrl: string;

  @Output() pathLength = new EventEmitter<boolean>();

  GuidanceDefinitions = GuidanceDefinitions;

  /* guidance component visibility */
  isGuidanceVisible = false;

  params = {};

  /* html contents by page as string array ( item of the array defines one page of the pagination ) */
  guidanceContents: string[] = [];

  subscriptions: Subscription[];

  constructor(
    private store: Store<any>,
    private router: Router,
    private urlService: UrlService,
    private httpClient: HttpClient
  ) { }

  ngOnInit(): void {
    this.subscriptions = [
      this.subscribeToRouterEvents(),
      this.subscribeToGuidanceState()
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentUrl && this.currentUrl) {
      this.updateGuidanceKey();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * subscribes to router events and gets params and url from router events
   *
   * @memberof GuidanceComponent
   */
  subscribeToRouterEvents() {
    return this.router.events.subscribe(event => {
      /* get paramaters from url when ActivationEnd */
      if (event instanceof ActivationEnd && Object.keys(event.snapshot.params).length > 0) {
        this.params = event.snapshot.params;
        this.updateGuidanceParam();
      }
      /* get full url when NavigationEnd */
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        this.updateGuidanceKey();
      }
    });
  }

  subscribeToGuidanceState() {
    return this.store.select(state => state.guidance.guidanceKey).subscribe(guidanceKey => {
      /* get guidance file paths */
      /* there may be multiple paths, there will be pagination if there are multiple paths for multiple contents */
      const guidanceFilePaths = this.GuidanceDefinitions
        .find(guidanceDefinition => guidanceDefinition.key === guidanceKey)
        ?.guidanceFiles
        .map(guidanceFile => guidanceFile.path);
      /* clear guidance contents */
      this.clearGuidanceContents();
      /* get guidance contents */
      this.getGuidanceContents(guidanceFilePaths);
    });
  }

  onClickToggler() {
    this.isGuidanceExpanded = !this.isGuidanceExpanded;
  }

  getGuidanceContents(paths: string[]) {
    /* get guidance contents if guidance file paths exist */
    if (paths?.length > 0) {
      for (const path of paths) {
        this.httpClient.get(path, { responseType: 'text' }).subscribe((content: string) => {
          this.guidanceContents.push(content);
        });
      }
      this.pathLength.emit(true);
    } else {
      this.pathLength.emit(false);
    }
  }

  updateGuidanceKey() {
    const urlWithoutParams = this.urlService.removeParamsFromUrl(this.currentUrl, this.params);
    this.store.dispatch(new UpdateGuidanceKey({ guidanceKey: urlWithoutParams }));
  }

  updateGuidanceParam() {
    this.store.dispatch(new UpdateGuidanceParam({ param: this.params }));
  }

  clearGuidanceContents() {
    this.guidanceContents = [];
  }

  getGuidanceExpansionCSSClass(): string {
    return this.isGuidanceExpanded ? 'guidance-section-visible' : 'guidance-section-not-visible';
  }

  handleTogglerIcon(): string {
    return this.isGuidanceExpanded ? 'right' : 'left';
  }

  handlePaginationVisibility(): boolean {
    return this.guidanceContents.length > 1;
  }

  isGuidanceContentAvailable(): boolean {
    return this.guidanceContents.length > 0;
  }

}
