/* eslint-disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ComponentSlot } from '../../models/ComponentSlot';
import { SocketConnection } from '../../sockets/socket.connections';
import { Component } from '../../models/Component';
import { SlotDefinition } from '../../models/SlotDefinition';

/**
 * Api services for the `ComponentSlot` model.
 */
@Injectable()
export class ComponentSlotApi extends BaseLoopBackApi {
  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SocketConnection) protected connection: SocketConnection,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http, connection, models, auth, errorHandler);
  }

  /**
   * Fetches belongsTo relation parentComponent.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {boolean} refresh
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public getParentComponent(
    id: any,
    refresh: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() + '/component-slots/:id/parent-component';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null)
      _urlParams.refresh = refresh;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Fetches belongsTo relation childComponent.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {boolean} refresh
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public getChildComponent(
    id: any,
    refresh: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() + '/component-slots/:id/child-component';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null)
      _urlParams.refresh = refresh;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Fetches belongsTo relation slotDefinition.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {boolean} refresh
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public getSlotDefinition(
    id: any,
    refresh: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() + '/component-slots/:id/slotDefinition';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null)
      _urlParams.refresh = refresh;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Patch an existing model instance or insert a new one into the data source.
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - Model instance data
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public patchOrCreate(
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PATCH';
    let _url: string = LoopBackConfig.getPath() + '/component-slots';
    let _routeParams: any = {};
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - An object of model property name/value pairs
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public patchAttributes(
    id: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PATCH';
    let _url: string = LoopBackConfig.getPath() + '/component-slots/:id';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Find a related item by id for pages.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {any} fk Foreign key for pages
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public findByIdParentComponentPages(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/pages/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Delete a related item by id for pages.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {any} fk Foreign key for pages
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdParentComponentPages(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/pages/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Update a related item by id for pages.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {any} fk Foreign key for pages
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public updateByIdParentComponentPages(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/pages/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Add a related item by id for pages.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {any} fk Foreign key for pages
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public linkParentComponentPages(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/pages/rel/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Remove the pages relation to an item by id.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {any} fk Foreign key for pages
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public unlinkParentComponentPages(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/pages/rel/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Check the existence of pages relation to an item by id.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {any} fk Foreign key for pages
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public existsParentComponentPages(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'HEAD';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/pages/rel/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Find a related item by id for dynamicInputs.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {any} fk Foreign key for dynamicInputs
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public findByIdParentComponentDynamicInputs(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/dynamic-inputs/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Delete a related item by id for dynamicInputs.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {any} fk Foreign key for dynamicInputs
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdParentComponentDynamicInputs(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/dynamic-inputs/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Update a related item by id for dynamicInputs.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {any} fk Foreign key for dynamicInputs
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public updateByIdParentComponentDynamicInputs(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/dynamic-inputs/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Find a related item by id for childComponents.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {any} fk Foreign key for childComponents
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public findByIdParentComponentChildComponents(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/child-components/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Delete a related item by id for childComponents.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {any} fk Foreign key for childComponents
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdParentComponentChildComponents(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/child-components/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Update a related item by id for childComponents.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {any} fk Foreign key for childComponents
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public updateByIdParentComponentChildComponents(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/child-components/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Add a related item by id for childComponents.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {any} fk Foreign key for childComponents
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public linkParentComponentChildComponents(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/child-components/rel/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Remove the childComponents relation to an item by id.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {any} fk Foreign key for childComponents
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public unlinkParentComponentChildComponents(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/child-components/rel/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Check the existence of childComponents relation to an item by id.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {any} fk Foreign key for childComponents
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public existsParentComponentChildComponents(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'HEAD';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/child-components/rel/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Find a related item by id for componentSlots.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {any} fk Foreign key for componentSlots
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public findByIdParentComponentComponentSlots(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/component-slots/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Delete a related item by id for componentSlots.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {any} fk Foreign key for componentSlots
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdParentComponentComponentSlots(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/component-slots/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Update a related item by id for componentSlots.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {any} fk Foreign key for componentSlots
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public updateByIdParentComponentComponentSlots(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/component-slots/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Queries pages of Component.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {object} filter
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public getParentComponentPages(
    id: any,
    filter: LoopBackFilter = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() + '/component-slots/:id/parent-component/pages';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null)
      _urlParams.filter = filter;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in pages of this model.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public createParentComponentPages(
    id: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() + '/component-slots/:id/parent-component/pages';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Deletes all pages of this model.
   *
   * @param {any} id ComponentSlot id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteParentComponentPages(
    id: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() + '/component-slots/:id/parent-component/pages';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Counts pages of Component.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` -
   */
  public countParentComponentPages(
    id: any,
    where: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/pages/count';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null)
      _urlParams.where = where;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Queries dynamicInputs of Component.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {object} filter
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public getParentComponentDynamicInputs(
    id: any,
    filter: LoopBackFilter = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/dynamic-inputs';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null)
      _urlParams.filter = filter;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in dynamicInputs of this model.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public createParentComponentDynamicInputs(
    id: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/dynamic-inputs';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Deletes all dynamicInputs of this model.
   *
   * @param {any} id ComponentSlot id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteParentComponentDynamicInputs(
    id: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/dynamic-inputs';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Counts dynamicInputs of Component.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` -
   */
  public countParentComponentDynamicInputs(
    id: any,
    where: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/dynamic-inputs/count';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null)
      _urlParams.where = where;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Queries childComponents of Component.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {object} filter
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public getParentComponentChildComponents(
    id: any,
    filter: LoopBackFilter = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/child-components';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null)
      _urlParams.filter = filter;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in childComponents of this model.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public createParentComponentChildComponents(
    id: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/child-components';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Deletes all childComponents of this model.
   *
   * @param {any} id ComponentSlot id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteParentComponentChildComponents(
    id: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/child-components';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Counts childComponents of Component.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` -
   */
  public countParentComponentChildComponents(
    id: any,
    where: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/child-components/count';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null)
      _urlParams.where = where;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Queries componentSlots of Component.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {object} filter
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public getParentComponentComponentSlots(
    id: any,
    filter: LoopBackFilter = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/component-slots';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null)
      _urlParams.filter = filter;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in componentSlots of this model.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public createParentComponentComponentSlots(
    id: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/component-slots';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Deletes all componentSlots of this model.
   *
   * @param {any} id ComponentSlot id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteParentComponentComponentSlots(
    id: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/component-slots';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Counts componentSlots of Component.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` -
   */
  public countParentComponentComponentSlots(
    id: any,
    where: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/component-slots/count';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null)
      _urlParams.where = where;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in pages of this model.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public createManyParentComponentPages(
    id: any,
    data: any[] = [],
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() + '/component-slots/:id/parent-component/pages';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in dynamicInputs of this model.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public createManyParentComponentDynamicInputs(
    id: any,
    data: any[] = [],
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/dynamic-inputs';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in childComponents of this model.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public createManyParentComponentChildComponents(
    id: any,
    data: any[] = [],
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/child-components';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in componentSlots of this model.
   *
   * @param {any} id ComponentSlot id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `ComponentSlot` object.)
   * </em>
   */
  public createManyParentComponentComponentSlots(
    id: any,
    data: any[] = [],
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string =
      LoopBackConfig.getPath() +
      '/component-slots/:id/parent-component/component-slots';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `ComponentSlot`.
   */
  public getModelName() {
    return 'ComponentSlot';
  }
}
