/* eslint-disable */
import { Project } from '../index';

declare var Object: any;
export interface ProjectPackageInterface {
  id?: string;
  name: string;
  version?: string;
  projectId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  project?: Project;
}

export class ProjectPackage implements ProjectPackageInterface {
  'id': string;
  'name': string;
  'version': string;
  'projectId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  project: Project;
  constructor(data?: ProjectPackageInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ProjectPackage`.
   */
  public static getModelName() {
    return 'ProjectPackage';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ProjectPackage for dynamic purposes.
   **/
  public static factory(data: ProjectPackageInterface): ProjectPackage {
    return new ProjectPackage(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ProjectPackage',
      plural: 'ProjectPackages',
      path: 'project-packages',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        version: {
          name: 'version',
          type: 'string',
        },
        projectId: {
          name: 'projectId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
      },
      relations: {
        project: {
          name: 'project',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
          keyFrom: 'projectId',
          keyTo: 'id',
        },
      },
    };
  }
}
