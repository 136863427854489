import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjectInterface } from '@rappider/api-sdk';
import { HeadingComponentConfig } from '@rappider/rappider-components/utils';

import { defaultToolbarTitleHeadingSize, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Subscription } from 'rxjs';
import { TestUserPageEvents } from './models/project-test-members-events.enum';
import { TestUser, TestUserModalConfigs } from './models/project-test-members.interface';
import { PROJECT_TEST_MEMBERS_CONFIG } from './project-test-members.config';
@Component({
  selector: 'rappider-project-test-members',
  templateUrl: './project-test-members.component.html',
  styleUrls: ['./project-test-members.component.scss']
})
export class ProjectTestMembersComponent implements OnInit {

  PROJECT_TEST_MEMBERS_CONFIG = PROJECT_TEST_MEMBERS_CONFIG;
  isLoading = false;
  activeProject: ProjectInterface;
  mainTitle: HeadingComponentConfig = {
    content: 'Test Users',
    type: defaultToolbarTitleHeadingSize
  };
  title = [];
  subscriptions: Subscription[] = [];
  testUserDisplayData: Record<string, unknown>[] = [];
  displayToolbar = false;
  displayToolbarBackButton = false;
  testUserModalData: TestUser = null;
  modalConfigs: TestUserModalConfigs = {
    isFormValid: false,
    isVisible: false,
    isSubmitted: false
  };

  // temporary static users added.
  tempUsers: TestUser[] = [
    { id: 1, firstName: 'Test', lastName: 'Developer', email: 'dev@rappider.com', roles: [{ id: '3e2d21a0-1411-40fb-9948-98bb582a896c', title: 'dev' }], password: 'dc1234' },
    { id: 2, firstName: 'Test', lastName: 'User', email: 'test@gmail.com', roles: [{ id: '873eed09-da7f-4862-bf6f-03ae8f87b32d', title: 'customer' }], password: 'qqweqwe1' },
    { id: 3, firstName: 'Test', lastName: 'User2', email: 'user2gmail.com', roles: [{ id: '873eed09-da7f-4862-bf6f-03ae8f87b32d', title: 'customer' }], password: 'qqweqwe2' },
    { id: 4, firstName: 'Test', lastName: 'User3', email: 'user3@gmail.com', roles: [{ id: 'a6647ce5-05ee-45bb-ad07-a2500607a04f', title: 'test' }], password: 'qqweqwe3' },
    { id: 5, firstName: 'Test', lastName: 'User4', email: 'testuser4@gmail.com', roles: [{ id: '873eed09-da7f-4862-bf6f-03ae8f87b32d', title: 'customer' }], password: 'qqweqwe4' },
  ];

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.setDisplayedData();
    this.subscriptions = [
      this.subscribeToActiveProject()
    ];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectInterface) => {
      /* get active project */
      this.activeProject = activeProject;
      if (activeProject) {
        /* set component title */
        this.title = [
          {
            label: activeProject.name,
            redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${activeProject?.id}`
          },
          {
            label: 'Test Users'
          }
        ];
      }
    });
  }

  setDisplayedData() {
    this.testUserDisplayData = this.tempUsers.map(user => ({
      ...user,
      id: user.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      password: user.password,
      roles: user.roles.map(role => role.title).join(', ')
    }));
  }

  addTestUser(testUser) {
    this.modalConfigs.isVisible = true;
    this.testUserModalData = null;
  }

  editTestUser(testUser) {
    const editedUser = this.tempUsers.find(user => user.id === testUser.data.id);
    this.modalConfigs.isVisible = true;
    this.testUserModalData = {
      id: editedUser.id,
      email: editedUser.email,
      firstName: editedUser.firstName,
      lastName: editedUser.lastName,
      password: editedUser.password,
      selectedRoles: editedUser.roles.map(role => role.id)
    };
  }

  deleteTestUser(testUser: TestUser) { }

  onItemClick(event) {
    if (event.action.name === TestUserPageEvents.EditTestUser) {
      this.editTestUser(event);
    } else if (event.action.name === TestUserPageEvents.DeleteTestUser) {
      this.deleteTestUser(event);
    } else if (event.action.name === TestUserPageEvents.AddTestUser) {
      this.addTestUser(event);
    };
  }

}
