<div id="chat-wrapper"
     class="chat-wrapper">
  <div *ngIf="displaySkeleton && !messages?.length"
       class="container">
    <nz-skeleton *ngFor="let index of [0,1,2,3,4,5]"
                 [nzAvatar]="true"
                 [nzActive]="true"
                 [nzParagraph]="{ rows: 2 }"></nz-skeleton>
  </div>
  <div *ngFor="let message of messages; trackBy: trackMessages; let i = index"
       class="message-wrapper"
       [ngClass]="{
        'receiver-message': message.messageAuthorType === MessageAuthorType.Receiver,
        'sender-message': message.messageAuthorType === MessageAuthorType.Sender
      }">
    <div class="message-container container">
      <div class="avatar-message-wrapper">
        <div class="avatar">
          <ng-container *ngIf="message.messageAuthorType === MessageAuthorType.Receiver">
            <rappider-avatar [text]="receiverAvatar?.text"
                             [icon]="receiverAvatar?.icon"
                             [shape]="receiverAvatar?.shape"
                             [colorSettings]="receiverAvatar?.colorSettings"
                             [imageUrl]="receiverAvatar?.imageUrl">
            </rappider-avatar>
          </ng-container>
          <ng-container *ngIf="message.messageAuthorType === MessageAuthorType.Sender">
            <rappider-avatar [text]="senderAvatar?.text"
                             [icon]="senderAvatar?.icon"
                             [shape]="senderAvatar?.shape"
                             [colorSettings]="senderAvatar?.colorSettings"
                             [imageUrl]="senderAvatar?.imageUrl">
            </rappider-avatar>
          </ng-container>
        </div>
        <div class="message-body-wrapper">
          <div class="message"
               [innerHTML]="message.content | safeHtml">
          </div>
          <!-- Add an additional button to continue, when the AI is on project initialization; if the user doest want to answer questions -->
          <div class="ai-message-continue-button">
            <rappider-button *ngIf="message?.isPartOfSequence && message?.role === 'assistant' && messages.length === i + 1"
                             class="additional-button "
                             [text]="'Continue, with your recommended design'"
                             [icon]="'fa-solid fa-play'"
                             [tooltipText]="'If you want to use the recommended design, you can just click this button so that Rapider AI can decide the best answer for you'"
                             (confirm)="onContinueMessageButtonClick('Continue, with your recommended design')"></rappider-button>
          </div>

          <div *ngIf="message.additionalButton"
               class="additional-button">
            <rappider-button [text]="message.additionalButton.text"
                             [type]="message.additionalButton.type || 'default'"
                             [icon]="message.additionalButton.icon"
                             [tooltipText]="message.additionalButton.tooltipText"
                             [disabled]="message.additionalButton.disabled"
                             (confirm)="onMessageButtonClick(message)"></rappider-button>
          </div>
        </div>
      </div>
      <div *ngIf="message.date"
           class="message-time">
        {{message.date | fromNow}}
      </div>
    </div>
  </div>
  <div class="chat-bottom-controller">
    <div class="back-bottom">
      <button nz-button
              nzShape="circle"
              nzSize="small"
              (click)="scrollToBottom()">
        <i class="fa-regular fa-arrow-down"></i>
      </button>
    </div>
    <div class="input-wrapper container">
      <div *ngIf="isTyping"
           class="loading-wrapper">
        <span>Typing</span> &nbsp;
        <i class="fa-regular fa-ellipsis fa-fade"></i>
      </div>
      <div *ngIf="quickAnswerOptions?.length"
           class="quick-answer-buttons">
        <rappider-button-group [buttons]="quickAnswerButtons"
                               (buttonClick)="onQuickAnswerButtonClick($event)"></rappider-button-group>
      </div>
      <div class="input-container">
        <rappider-input-group [(ngModel)]="inputValue"
                              [textbox]="inputGroupConfig.textbox"
                              [suffixButton]="inputGroupConfig.suffixButton"
                              (enterClick)="onEnterClick()"
                              (buttonClick)="onButtonClick()"></rappider-input-group>
        <button *ngIf="fullScreenButtonVisibility"
                nz-button
                nzSize="large"
                (click)="onFullScreenButtonClick()">
          <i class="fa-light fa-arrow-up-right-from-square"></i>
        </button>
      </div>
    </div>
  </div>
</div>