import { createAction, props } from '@ngrx/store';
import { ProjectModelEndpoint, ProjectModelEndpointCreateDto, ProjectModelEndpointUpdateDto, ProjectModelEndpointWithRelations } from '@rappider/rappider-sdk';

/* action types */
export enum ActionTypes {
  CreateProjectModelEndpoint = '[ProjectModelEndpoint] CreateProjectModelEndpoint',
  CreateProjectModelEndpointSuccessful = '[ProjectModelEndpoint] CreateProjectModelEndpointSuccessful',
  CreateProjectModelEndpointFailure = '[ProjectModelEndpoint] CreateProjectModelEndpointFailure',
  GetProjectModelEndpoints = '[ProjectModelEndpoint] GetProjectModelEndpoints',
  GetProjectModelEndpointsSuccessful = '[ProjectModelEndpoint] GetProjectModelEndpointsSuccessful',
  GetProjectModelEndpointsFailure = '[ProjectModelEndpoint] GetProjectModelEndpointsFailure',
  UpdateProjectModelEndpoint = '[ProjectModelEndpoint] UpdateProjectModelEndpoint',
  UpdateProjectModelEndpointAndCustomFunction = '[ProjectModelEndpoint] UpdateProjectModelEndpointAndCustomFunction',
  UpdateProjectModelEndpointSuccessful = '[ProjectModelEndpoint] UpdateProjectModelEndpointSuccessful',
  UpdateProjectModelEndpointFailure = '[ProjectModelEndpoint] UpdateProjectModelEndpointFailure',
  DeleteProjectModelEndpoint = '[ProjectModelEndpoint] DeleteProjectModelEndpoint',
  DeleteProjectModelEndpointSuccessful = '[ProjectModelEndpoint] DeleteProjectModelEndpointSuccessful',
  DeleteProjectModelEndpointFailure = '[ProjectModelEndpoint] DeleteProjectModelEndpointFailure',
  ResetStateToInitial = '[ProjectModelEndpoint] ResetStateToInitial',
  ResetStateToInitialChangeProject = '[ProjectModelEndpoint] ResetStateToInitialChangeProject',
  GetProjectModelEndpointByModelId = '[ProjectModelEndpoint] GetProjectModelEndpointByModelId',
  GetProjectModelEndpointByModelIdSuccessful = '[ProjectModelEndpoint] GetProjectModelEndpointByModelIdSuccessful',
  GetProjectModelEndpointByModelIdFailure = '[ProjectModelEndpoint] GetProjectModelEndpointByModelIdFailure',
  GetOpenAPIDefinition = '[ProjectModelEndpoint] GetOpenAPIDefinition',
  GetOpenAPIDefinitionSuccessful = '[ProjectModelEndpoint] GetOpenAPIDefinitionSuccessful',
  GetOpenAPIDefinitionFailure = '[ProjectModelEndpoint] GetOpenAPIDefinitionFailure',
  GetProjectModelEndpointById = '[ProjectModelEndpoint] GetProjectModelEndpointById',
  GetProjectModelEndpointByIdSuccessful = '[ProjectModelEndpoint] GetProjectModelEndpointByIdSuccessful',
  GetProjectModelEndpointByIdFailure = '[ProjectModelEndpoint] GetProjectModelEndpointByIdFailure'
}

export const CreateProjectModelEndpoint = createAction(
  ActionTypes.CreateProjectModelEndpoint,
  props<{ payload: { endpoint: ProjectModelEndpointCreateDto; redirectUrl?: string } }>()
);

export const CreateProjectModelEndpointSuccessful = createAction(
  ActionTypes.CreateProjectModelEndpointSuccessful,
  props<{ payload: { endpoint: ProjectModelEndpoint } }>()
);

export const CreateProjectModelEndpointFailure = createAction(
  ActionTypes.CreateProjectModelEndpointFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const GetProjectModelEndpoints = createAction(
  ActionTypes.GetProjectModelEndpoints,
  props<{ payload: { projectModelId: string } }>()
);

export const GetProjectModelEndpointsSuccessful = createAction(
  ActionTypes.GetProjectModelEndpointsSuccessful,
  props<{ payload: { endpoints: ProjectModelEndpointWithRelations[] } }>()
);

export const GetProjectModelEndpointsFailure = createAction(
  ActionTypes.GetProjectModelEndpointsFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const UpdateProjectModelEndpoint = createAction(
  ActionTypes.UpdateProjectModelEndpoint,
  props<{ payload: { endpointId: string; endpoint: ProjectModelEndpointUpdateDto } }>()
);

export const UpdateProjectModelEndpointAndCustomFunction = createAction(
  ActionTypes.UpdateProjectModelEndpointAndCustomFunction,
  props<{ payload: { endpoint: any; customFunctionDefinition: any } }>()
);

export const UpdateProjectModelEndpointSuccessful = createAction(
  ActionTypes.UpdateProjectModelEndpointSuccessful,
  props<{ payload: { endpointId: string; endpointUpdateDto: ProjectModelEndpointUpdateDto } }>()
);

export const UpdateProjectModelEndpointFailure = createAction(
  ActionTypes.UpdateProjectModelEndpointFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const DeleteProjectModelEndpoint = createAction(
  ActionTypes.DeleteProjectModelEndpoint,
  props<{ payload: { endpointId: string; modelId?: string } }>()
);

export const DeleteProjectModelEndpointSuccessful = createAction(
  ActionTypes.DeleteProjectModelEndpointSuccessful,
  props<{ payload: { endpointId: string } }>()
);

export const DeleteProjectModelEndpointFailure = createAction(
  ActionTypes.DeleteProjectModelEndpointFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const GetProjectModelEndpointByModelId = createAction(
  ActionTypes.GetProjectModelEndpointByModelId,
  props<{ payload: { projectModelId: string } }>()
);

export const GetProjectModelEndpointByModelIdSuccessful = createAction(
  ActionTypes.GetProjectModelEndpointByModelIdSuccessful,
  props<{ payload: { endpoints: ProjectModelEndpointWithRelations[] } }>()
);

export const GetProjectModelEndpointByModelIdFailure = createAction(
  ActionTypes.GetProjectModelEndpointByModelIdFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const GetProjectModelEndpointById = createAction(
  ActionTypes.GetProjectModelEndpointById,
  props<{ payload: { endpointId: string } }>()
);

export const GetProjectModelEndpointByIdSuccessful = createAction(
  ActionTypes.GetProjectModelEndpointByIdSuccessful,
  props<{ payload: { updatedEndpoint: ProjectModelEndpointWithRelations } }>()
);

export const GetProjectModelEndpointByIdFailure = createAction(
  ActionTypes.GetProjectModelEndpointByIdFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const GetOpenAPIDefinition = createAction(
  ActionTypes.GetOpenAPIDefinition
);

export const GetOpenAPIDefinitionSuccessful = createAction(
  ActionTypes.GetOpenAPIDefinitionSuccessful,
  props<{ payload: { openAPIDefinition: Record<string, unknown> } }>()
);

export const GetOpenAPIDefinitionFailure = createAction(
  ActionTypes.GetOpenAPIDefinitionFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);
