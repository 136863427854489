/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LastProcessInformation } from '../models/last-process-information';
import { ProjectModelEndpoint } from '../models/project-model-endpoint';
import { ProjectModelEndpointCreateDto } from '../models/project-model-endpoint-create-dto';
import { ProjectModelEndpointUpdateDto } from '../models/project-model-endpoint-update-dto';
import { ProjectModelEndpointWithRelations } from '../models/project-model-endpoint-with-relations';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class ProjectModelEndpointControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation projectModelEndpointControllerCount
   */
  static readonly ProjectModelEndpointControllerCountPath = '/project-model-endpoints/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params?: {
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelEndpointControllerService.ProjectModelEndpointControllerCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params?: {
    where?: any;
  }): Observable<LoopbackCount> {

    return this.count$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation projectModelEndpointControllerGetJsonSchemaOfRequest
   */
  static readonly ProjectModelEndpointControllerGetJsonSchemaOfRequestPath = '/project-model-endpoints/get-json-schema-of-request/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getJSONSchemaOfRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  getJSONSchemaOfRequest$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelEndpointControllerService.ProjectModelEndpointControllerGetJsonSchemaOfRequestPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getJSONSchemaOfRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getJSONSchemaOfRequest(params: {
    id: string;
  }): Observable<any> {

    return this.getJSONSchemaOfRequest$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation projectModelEndpointControllerGetJsonSchemaOfResponse
   */
  static readonly ProjectModelEndpointControllerGetJsonSchemaOfResponsePath = '/project-model-endpoints/get-json-schema-of-response/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getJSONSchemaOfResponse()` instead.
   *
   * This method doesn't expect any request body.
   */
  getJSONSchemaOfResponse$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelEndpointControllerService.ProjectModelEndpointControllerGetJsonSchemaOfResponsePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getJSONSchemaOfResponse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getJSONSchemaOfResponse(params: {
    id: string;
  }): Observable<any> {

    return this.getJSONSchemaOfResponse$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation projectModelEndpointControllerFindLatestChangeTimestamps
   */
  static readonly ProjectModelEndpointControllerFindLatestChangeTimestampsPath = '/project-model-endpoints/latest-change-timestamps';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findLatestChangeTimestamps()` instead.
   *
   * This method doesn't expect any request body.
   */
  findLatestChangeTimestamps$Response(params?: {
  }): Observable<StrictHttpResponse<LastProcessInformation>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelEndpointControllerService.ProjectModelEndpointControllerFindLatestChangeTimestampsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LastProcessInformation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findLatestChangeTimestamps$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findLatestChangeTimestamps(params?: {
  }): Observable<LastProcessInformation> {

    return this.findLatestChangeTimestamps$Response(params).pipe(
      map((r: StrictHttpResponse<LastProcessInformation>) => r.body as LastProcessInformation)
    );
  }

  /**
   * Path part for operation projectModelEndpointControllerFindById
   */
  static readonly ProjectModelEndpointControllerFindByIdPath = '/project-model-endpoints/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<ProjectModelEndpoint>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelEndpointControllerService.ProjectModelEndpointControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectModelEndpoint>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
    filter?: any;
  }): Observable<ProjectModelEndpoint> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectModelEndpoint>) => r.body as ProjectModelEndpoint)
    );
  }

  /**
   * Path part for operation projectModelEndpointControllerDeleteById
   */
  static readonly ProjectModelEndpointControllerDeleteByIdPath = '/project-model-endpoints/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelEndpointControllerService.ProjectModelEndpointControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: string;
  }): Observable<any> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation projectModelEndpointControllerUpdateById
   */
  static readonly ProjectModelEndpointControllerUpdateByIdPath = '/project-model-endpoints/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    body?: ProjectModelEndpointUpdateDto
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelEndpointControllerService.ProjectModelEndpointControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    body?: ProjectModelEndpointUpdateDto
  }): Observable<any> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation projectModelEndpointControllerFind
   */
  static readonly ProjectModelEndpointControllerFindPath = '/project-model-endpoints';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<ProjectModelEndpointWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelEndpointControllerService.ProjectModelEndpointControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectModelEndpointWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<ProjectModelEndpointWithRelations>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectModelEndpointWithRelations>>) => r.body as Array<ProjectModelEndpointWithRelations>)
    );
  }

  /**
   * Path part for operation projectModelEndpointControllerCreate
   */
  static readonly ProjectModelEndpointControllerCreatePath = '/project-model-endpoints';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    body?: ProjectModelEndpointCreateDto
  }): Observable<StrictHttpResponse<ProjectModelEndpointWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelEndpointControllerService.ProjectModelEndpointControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectModelEndpointWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    body?: ProjectModelEndpointCreateDto
  }): Observable<ProjectModelEndpointWithRelations> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectModelEndpointWithRelations>) => r.body as ProjectModelEndpointWithRelations)
    );
  }

}
