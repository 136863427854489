import { CrudTableViewConfig, CrudViewColumnType, ActionBehavior } from '@rappider/rappider-components/utils';

export const PERSON_SELECTION_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'firstName',
  columns: [
    {
      title: 'SHARED.FIRST_NAME',
      fieldName: 'firstName',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.LAST_NAME',
      fieldName: 'lastName',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Tenant',
      fieldName: 'tenantName',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.EMAIL',
      fieldName: 'email',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.CREATED_DATE',
      fieldName: 'createdDate',
      type: CrudViewColumnType.Date
    }
  ],
  listActions: [],
  itemActions: [
    {
      name: 'CHANGE_ACTIVE_PERSON',
      text: 'SHARED.SELECT_ACCOUNT',
      icon: { name: 'far fa-user-check' },
      behavior: ActionBehavior.Emit
    }
  ]
};
