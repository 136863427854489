import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Project } from '@rappider/api-sdk';
import { BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { OpenApiWithRelations } from '@rappider/rappider-sdk';
import { OPENAPI_LIST_CONFIG } from '@rappider/shared/configs';
import { PAGE_DEFINITIONS, defaultToolbarTitleHeadingSize, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { GetOpenAPIsWithRelations, ToggleImportOpenApiSpecModalVisibility, DeleteOpenAPI } from 'libs/project/src/lib/states/openapi-state/openapi.actions';
import { Subscription } from 'rxjs';
import { ImportOpenapiComponent } from '../import-openapi/import-openapi.component';

@Component({
  selector: 'rappider-openapi-list',
  templateUrl: './openapi-list.component.html',
  styleUrls: ['./openapi-list.component.scss']
})
export class OpenapiListComponent implements OnInit, OnDestroy {

  @ViewChild('importOpenApiComponent', { static: false }) importOpenApiComponent: ImportOpenapiComponent;

  /* external api list config */
  OPENAPI_LIST_CONFIG = OPENAPI_LIST_CONFIG;
  /* active project */
  activeProject: Project;
  /* subscriptions */
  subscriptions: Subscription[];
  /* import open api spec modal visibility */
  isImportOpenApiSpecModalVisible: boolean;
  /* loading state */
  loading = false;
  /* external api (openApi) data */
  openApiData: OpenApiWithRelations[];

  /* title toolbar configuration */
  mainTitle: HeadingComponentConfig;
  title: BreadcrumbOption[];
  displayToolbar = false;
  displayToolbarBackButton = false;

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToOpenApiData(),
      this.subscribeToOpenApiLoading(),
      this.subscribeToImportOpenApiSpecModalVisibility()
    ];
  }

  setTitle() {
    this.mainTitle = {
      content: PAGE_DEFINITIONS.PROJECTS.CHILDREN.OPENAPI_LIST.NAME,
      type: defaultToolbarTitleHeadingSize
    };

    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
      },
      {
        label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.OPENAPI_LIST.NAME
      }
    ];
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((project: Project) => {
      if (project) {
        this.activeProject = project;
        this.setTitle();
      } else {
        this.activeProject = null;
      }
    });
  }

  subscribeToOpenApiData() {
    return this.store.select(state => state.openAPI?.data).subscribe((openApis: OpenApiWithRelations[]) => {
      if (openApis) {
        this.openApiData = openApis;
      } else {
        this.openApiData = null;
      }
    });
  }

  subscribeToOpenApiLoading() {
    return this.store.select(state => state.openAPI?.loading).subscribe((loading: boolean) => {
      this.loading = loading;
    });
  }

  subscribeToImportOpenApiSpecModalVisibility() {
    return this.store.select(state => state.openAPI?.importFromOpenApiModalVisibility).subscribe((isVisible: boolean) => {
      this.isImportOpenApiSpecModalVisible = isVisible;
    });
  }

  onListActionClick(listAction) {
    if (listAction.action.name === 'importOpenApiSpec') {
      this.toggleImportOpenApiSpecModalVisibility();
    }
  }

  toggleImportOpenApiSpecModalVisibility() {
    this.store.dispatch(ToggleImportOpenApiSpecModalVisibility());
  }

  onSaveImportOpenApiData() {
    this.importOpenApiComponent.createOpenApi();
  }

  onColumnActionClick(columnAction) {
    const openApi = columnAction.data as OpenApiWithRelations;

    if (columnAction.action.name === 'DELETE_ITEM') {
      this.store.dispatch(DeleteOpenAPI({ payload: { openApiId: openApi.id } }));
    }
  }

}
