/* eslint-disable no-shadow */
import { Action } from '@ngrx/store';
import { Component, ComponentOutputEvent, ComponentOutputEventPartial, ComponentPartial, Page, PagePartial, PageWithRelations, UpdateContainerPropertiesDto } from '@rappider/rappider-sdk';
import { ContentEditorClipboardData } from '../models/content-editor-clipboard-data.interface';
import { ContentTree, ContentTreeItem, ContentTreeItemType } from 'libs/content-tree-renderer/src/lib/models';
import { AddConditionToContainerRequestDto } from 'libs/rappider-sdk/src/lib/models/add-condition-to-container-request-dto';
import { UpdateContainerConditionRequestDto } from 'libs/rappider-sdk/src/lib/models/update-container-condition-request-dto';
import { ContainerRemoveStrategy } from '../models/container-remove-strategy.enum';

export enum ActionTypes {
  ContentEditorError = '[ContentEditor] ContentEditorError',
  LoadPageWithComponents = '[ContentEditor] LoadPageWithComponents',
  LoadPageWithComponentsSuccessful = '[ContentEditor] LoadPageWithComponentsSuccessful',
  CreateComponent = '[ContentEditor] CreateComponent',
  CreateComponentSuccessful = '[ContentEditor] CreateComponentSuccessful',
  UpdateComponent = '[ContentEditor] UpdateComponent',
  UpdateComponentSuccessful = '[ContentEditor] UpdateComponentSuccessful',
  DeleteComponent = '[ContentEditor] DeleteComponent',
  DeleteComponentSuccessful = '[ContentEditor] DeleteComponentSuccessful',
  UpdateComponentInputs = '[ContentEditor] UpdateComponentInputs',
  UpdateComponentInputsFailure = '[ContentEditor] UpdateComponentInputsFailure',
  SetActiveContentTreeItem = '[ContentEditor] SetActiveContentTreeItem',
  CreatePageContainer = '[ContentEditor] CreatePageContainer',
  UpdatePageContainerProperties = '[ContentEditor] UpdatePageContainerProperties',
  DeletePageContainer = '[ContentEditor] DeletePageContainer',
  MoveContentTreeItem = '[ContentEditor] MoveContentTreeItem',
  UpdatePageCss = '[ContentEditor] UpdatePageCss',
  UpdatePageCssSuccesful = '[ContentEditor] UpdatePageCssSuccesful',
  CopyPage = '[ContentEditor] CopyPage',
  CopyPageSuccessful = '[ContentEditor] CopyPageSuccessful',
  CopyTemplateToPageContainer = '[ContentEditor] CopyTemplateToPageContainer',
  CopyTemplateToPageContainerSuccessful = '[ContentEditor] CopyTemplateToPageContainerSuccessful',
  CopyActiveContentTemplateToLocalStorage = '[ContentEditor] CopyActiveContentTemplateToLocalStorage',
  WriteCopiedTemplateTreeFromLocalStorageToState = '[ContentEditor] WriteCopiedTemplateTreeFromLocalStorageToState',
  WriteCopiedTemplateTreeFromLocalStorageToStateFailure = '[ContentEditor] WriteCopiedTemplateTreeFromLocalStorageToStateFailure',
  PasteTemplateFromLocalStorage = '[ContentEditor] PasteTemplateFromLocalStorage',
  ResetContentEditorData = '[ContentEditor] ResetContentEditorData',
  UpdateContentTreeOfPage = '[ContentEditor] UpdateContentTreeOfPage',
  UpdateContentTreeOfPageSuccessful = '[ContentEditor] UpdateContentTreeOfPageSuccessful',
  CreateContainerVisibilityCondition = '[ContentEditor] CreateContainerVisibilityCondition',
  CreateContainerVisibilityConditionSuccessful = '[ContentEditor] CreateContainerVisibilityConditionSuccessful',
  UpdateContainerVisibilityCondition = '[ContentEditor] UpdateContainerVisibilityCondition',
  UpdateContainerVisibilityConditionSuccessful = '[ContentEditor] UpdateContainerVisibilityConditionSuccessful',
  DeleteContainerVisibilityCondition = '[ContentEditor] DeleteContainerVisibilityCondition',
  DeleteContainerVisibilityConditionSuccessful = '[ContentEditor] DeleteContainerVisibilityConditionSuccessful',
  CreateComponentOutputEvent = '[ContentEditor] CreateComponentOutputEvent',
  CreateComponentOutputEventSuccessful = '[ContentEditor] CreateComponentOutputEventSuccessful',
  UpdateComponentOutputEvent = '[ContentEditor] UpdateComponentOutputEvent',
  UpdateComponentOutputEventSuccessful = '[ContentEditor] UpdateComponentOutputEventSuccessful',
  DeleteComponentOutputEvent = '[ContentEditor] DeleteComponentOutputEvent',
  DeleteComponentOutputEventSuccessful = '[ContentEditor] DeleteComponentOutputEventSuccessful',
  SyncPageDataOptimistic = '[ContentEditor] SyncPageDataOptimistic',
  ContentEditorDestroy = '[ContentEditor] ContentEditorDestroy',
  DeletePageComponents = '[ContentEditor] DeletePageComponents',
  DeletePageComponentsSuccessful = '[ContentEditor] DeletePageComponentsSuccessful'
}

export class ContentEditorError implements Action {
  readonly type = ActionTypes.ContentEditorError;
  constructor(public payload: { errorOn: string; error?: any }) { }
}

export class LoadPageWithComponents implements Action {
  readonly type = ActionTypes.LoadPageWithComponents;
  constructor(public payload: { pageId: string }) { }
}

export class LoadPageWithComponentsSuccessful implements Action {
  readonly type = ActionTypes.LoadPageWithComponentsSuccessful;
  constructor(public payload: { page: PageWithRelations }) { }
}

export class CreateComponent implements Action {
  readonly type = ActionTypes.CreateComponent;
  constructor(public payload: {
    componentDefinitionId: string;
    title?: string;
    inputs?: any;
  }) { }
}

export class CreateComponentSuccessful implements Action {
  readonly type = ActionTypes.CreateComponentSuccessful;
  constructor(public payload: {
    createdComponent: { component: Component; componentId: string; id: string; type: ContentTreeItemType };
    pageContainerId: string;
    // the pageComponentId where the component will be related with the Page
    pageComponentId?: string;
  }) { }
}

export class UpdateComponent implements Action {
  readonly type = ActionTypes.UpdateComponent;
  constructor(
    public payload: {
      component: ComponentPartial;
    }
  ) { }
}

export class UpdateComponentSuccessful implements Action {
  readonly type = ActionTypes.UpdateComponentSuccessful;
  constructor(
    public payload: {
      component: Component;
    }
  ) { }
}

export class DeleteComponent implements Action {
  readonly type = ActionTypes.DeleteComponent;
  constructor(public payload: { componentId: string }) { }
}

export class DeleteComponentSuccessful implements Action {
  readonly type = ActionTypes.DeleteComponentSuccessful;
  constructor(public payload: { deletedComponentId: string }) { }
}

export class UpdateComponentInputs implements Action {
  readonly type = ActionTypes.UpdateComponentInputs;
  constructor(
    public payload: {
      componentId: string;
      parentPageComponentContentId: string;
      inputs: { [key: string]: any };
    }
  ) { }
}

export class SetActiveContentTreeItem implements Action {
  readonly type = ActionTypes.SetActiveContentTreeItem;
  constructor(public payload: { contentTreeItemId: string }) { }
}

export class CreatePageContainer implements Action {
  readonly type = ActionTypes.CreatePageContainer;
  // pageContainerId => page container id of created page container inserted in
  constructor(public payload: { pageContainerId: string }) { }
}

export class UpdatePageContainerProperties implements Action {
  readonly type = ActionTypes.UpdatePageContainerProperties;
  constructor(public payload: UpdateContainerPropertiesDto) { }
}

export class DeletePageContainer implements Action {
  readonly type = ActionTypes.DeletePageContainer;
  constructor(public payload: { pageContainerId: string; removeStrategy: ContainerRemoveStrategy }) { }
}

export class MoveContentTreeItem implements Action {
  readonly type = ActionTypes.MoveContentTreeItem;
  constructor(public payload: {
    targetPageContainer: ContentTreeItem;
    targetContentTreeItem: ContentTreeItem;
    targetIndex: number;
  }) { }
}

export class UpdatePageCss implements Action {
  readonly type = ActionTypes.UpdatePageCss;
  constructor(public payload: { pageId: string; css: string }) { }
}

export class UpdatePageCssSuccesful implements Action {
  readonly type = ActionTypes.UpdatePageCssSuccesful;
  constructor(public payload: { pageId: string; css: string }) { }
}

export class UpdateContentTreeOfPage implements Action {
  readonly type = ActionTypes.UpdateContentTreeOfPage;
}

export class UpdateContentTreeOfPageSuccessful implements Action {
  readonly type = ActionTypes.UpdateContentTreeOfPageSuccessful;
  constructor(public payload: { contentTree: any }) { }
}

export class CopyPage implements Action {
  readonly type = ActionTypes.CopyPage;
  constructor(public payload: { targetPageId: string; sourcePageId: string }) { }
}

export class CopyPageSuccessful implements Action {
  readonly type = ActionTypes.CopyPageSuccessful;
  constructor(public payload: { page: Page }) { }
}

export class CopyTemplateToPageContainer implements Action {
  readonly type = ActionTypes.CopyTemplateToPageContainer;
  constructor(public payload: { templateTree: any[]; targetPageId: string; targetContainerId: string }) { }
}

export class CopyTemplateToPageContainerSuccessful implements Action {
  readonly type = ActionTypes.CopyTemplateToPageContainerSuccessful;
  constructor(public payload: { contentTree: ContentTree }) { }
}

export class CopyActiveContentTemplateToLocalStorage implements Action {
  readonly type = ActionTypes.CopyActiveContentTemplateToLocalStorage;
}

/**
 * We want to hold local storage item in state for subscribe to changes
 *
 * @export
 * @class WriteCopiedTemplateTreeFromLocalStorageToState
 * @implements {Action}
 */
export class WriteCopiedTemplateTreeFromLocalStorageToState implements Action {
  readonly type = ActionTypes.WriteCopiedTemplateTreeFromLocalStorageToState;
  constructor(public payload: ContentEditorClipboardData) { }
}

export class WriteCopiedTemplateTreeFromLocalStorageToStateFailure implements Action {
  readonly type = ActionTypes.WriteCopiedTemplateTreeFromLocalStorageToStateFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class PasteTemplateFromLocalStorage implements Action {
  readonly type = ActionTypes.PasteTemplateFromLocalStorage;
}

export class CreateContainerVisibilityCondition implements Action {
  readonly type = ActionTypes.CreateContainerVisibilityCondition;
  constructor(public payload: { pageId: string; body: AddConditionToContainerRequestDto }) { }
}

export class CreateContainerVisibilityConditionSuccessful implements Action {
  readonly type = ActionTypes.CreateContainerVisibilityConditionSuccessful;
  constructor(public payload: { pageContainerId: string; createdVisibilityCondition: any }) { }
}

export class UpdateContainerVisibilityCondition implements Action {
  readonly type = ActionTypes.UpdateContainerVisibilityCondition;
  constructor(public payload: { pageId: string; body: UpdateContainerConditionRequestDto }) { }
}

export class UpdateContainerVisibilityConditionSuccessful implements Action {
  readonly type = ActionTypes.UpdateContainerVisibilityConditionSuccessful;
  constructor(public payload: { pageContainerId: string; updatedVisibilityCondition: any }) { }
}

export class DeleteContainerVisibilityCondition implements Action {
  readonly type = ActionTypes.DeleteContainerVisibilityCondition;
  constructor(public payload: { pageId: string; pageContainerId: string }) { }
}

export class DeleteContainerVisibilityConditionSuccessful implements Action {
  readonly type = ActionTypes.DeleteContainerVisibilityConditionSuccessful;
  constructor(public payload: { pageContainerId: string }) { }
}

export class CreateComponentOutputEvent implements Action {
  readonly type = ActionTypes.CreateComponentOutputEvent;
  constructor(public payload: { componentId: string; createdOutputEvent: ComponentOutputEvent }) { }
}

export class CreateComponentOutputEventSuccessful implements Action {
  readonly type = ActionTypes.CreateComponentOutputEventSuccessful;
  constructor(public payload: { componentId: string; createdOutputEvent: ComponentOutputEvent }) { }
}

export class UpdateComponentOutputEvent implements Action {
  readonly type = ActionTypes.UpdateComponentOutputEvent;
  constructor(public payload: { componentId: string; updatedOutputEventId: string; updatedOutputEvent: ComponentOutputEventPartial }) { }
}

export class UpdateComponentOutputEventSuccessful implements Action {
  readonly type = ActionTypes.UpdateComponentOutputEventSuccessful;
  constructor(public payload: { componentId: string; updatedOutputEventId: string; updatedOutputEvent: ComponentOutputEventPartial }) { }
}

export class DeleteComponentOutputEvent implements Action {
  readonly type = ActionTypes.DeleteComponentOutputEvent;
  constructor(public payload: { componentId: string; eventId: string }) { }
}

export class DeleteComponentOutputEventSuccessful implements Action {
  readonly type = ActionTypes.DeleteComponentOutputEventSuccessful;
  constructor(public payload: { componentId: string; eventId: string }) { }
}


export class SyncPageDataOptimistic implements Action {
  readonly type = ActionTypes.SyncPageDataOptimistic;
  constructor(public payload: { pageData: PagePartial }) { }
}

export class DeletePageComponents implements Action {
  readonly type = ActionTypes.DeletePageComponents;
  constructor(public payload: { componentIds: string[]; pageId: string }) { }
}

export class DeletePageComponentsSuccessful implements Action {
  readonly type = ActionTypes.DeletePageComponentsSuccessful;
  constructor(public payload: { removedComponentIds: string[]; pageId: string }) { }
}

export class ResetContentEditorData implements Action {
  readonly type = ActionTypes.ResetContentEditorData;
}

export class ContentEditorDestroy implements Action {
  readonly type = ActionTypes.ContentEditorDestroy;
  constructor(public payload: { pageId: string; contentTree: ContentTree; lastSavedContentTree: ContentTree; targetPageId?: string }) { }
}

export type Actions =
  | ContentEditorError
  | LoadPageWithComponents
  | LoadPageWithComponentsSuccessful
  | CreateComponent
  | CreateComponentSuccessful
  | UpdateComponent
  | UpdateComponentSuccessful
  | DeleteComponent
  | DeleteComponentSuccessful
  | UpdateComponentInputs
  | SetActiveContentTreeItem
  | CreatePageContainer
  | UpdatePageContainerProperties
  | DeletePageContainer
  | MoveContentTreeItem
  | UpdatePageCss
  | UpdatePageCssSuccesful
  | CopyPage
  | CopyPageSuccessful
  | CopyTemplateToPageContainer
  | CopyTemplateToPageContainerSuccessful
  | CopyActiveContentTemplateToLocalStorage
  | WriteCopiedTemplateTreeFromLocalStorageToState
  | WriteCopiedTemplateTreeFromLocalStorageToStateFailure
  | PasteTemplateFromLocalStorage
  | ResetContentEditorData
  | UpdateContentTreeOfPage
  | UpdateContentTreeOfPageSuccessful
  | CreateContainerVisibilityCondition
  | CreateContainerVisibilityConditionSuccessful
  | UpdateContainerVisibilityCondition
  | UpdateContainerVisibilityConditionSuccessful
  | DeleteContainerVisibilityCondition
  | DeleteContainerVisibilityConditionSuccessful
  | CreateComponentOutputEvent
  | CreateComponentOutputEventSuccessful
  | UpdateComponentOutputEvent
  | UpdateComponentOutputEventSuccessful
  | DeleteComponentOutputEvent
  | DeleteComponentOutputEventSuccessful
  | SyncPageDataOptimistic
  | DeletePageComponents
  | DeletePageComponentsSuccessful
  | ContentEditorDestroy
  ;
