<ng-container *ngFor="let projectRequirement of projectRequirements">
  <div class="project-requirements-wrapper">
    <section class="project-requirement">
      <div class="index">
        {{projectRequirement.index}}
      </div>

      <div class="status">
        <ng-container *ngIf="projectRequirement.status === ProjectRequirementStatus.Success">
          <i class="fa-solid fa-circle-check status-icon success"></i>
        </ng-container>

        <ng-container *ngIf="projectRequirement.status === ProjectRequirementStatus.InProgress">
          <i class="fa-regular fa-spinner-third fa-spin status-icon"></i>
        </ng-container>

        <ng-container *ngIf="projectRequirement.status === ProjectRequirementStatus.Failure">
          <i class="fa-solid fa-circle-xmark status-icon failure"></i>
        </ng-container>
      </div>

      <div class="project-requirement-header">
        <strong>{{projectRequirement.title}}</strong>
      </div>

      <ng-container *ngIf="projectRequirement.description">
        <hr>
        <div class="project-requirement-body">
          <div class="description"
               [innerHTML]="projectRequirement.description">
            {{projectRequirement.description}}
          </div>
        </div>
      </ng-container>
    </section>

    <nz-collapse class="project-requirement-details">
      <nz-collapse-panel nzHeader="Details"
                         [nzActive]="detailVisibility">
        <div class="project-requirement-detail-content">
          <ul>
            <li *ngFor="let projectRequirementInformation of projectRequirement.informations">
              {{projectRequirementInformation}}
            </li>
          </ul>
          <div class="project-requirement-action-buttons">
            <rappider-button *ngFor="let projectRequirementAction of projectRequirement.actions"
                             [text]="projectRequirementAction.text"
                             size="small"
                             [block]="true"
                             (click)="onProjectRequirementActionButtonClick(projectRequirementAction)">
            </rappider-button>
          </div>
        </div>
      </nz-collapse-panel>
    </nz-collapse>
  </div>
</ng-container>

<div class="add-project-requirement">
  <rappider-textbox [(ngModel)]="newProjectRequirement"
                    [placeholder]="'Create a Project Requirement'"></rappider-textbox>
  <rappider-button [icon]="newProjectRequirementButton.icon"
                   [type]="newProjectRequirementButton.type"
                   (click)="createNewProjectRequirementButtonClick()"></rappider-button>
</div>

<div *ngIf="redirectButtonVisibility"
     class="redirect-button pt-3">
  <rappider-button [text]="'SHARED.GO_TO_DETAIL_PAGE' | translate"
                   (click)="redirectRequirementsDetailPage()"></rappider-button>
</div>
