import {
  CrudViewFormItemType,
  CrudFormConfig,
  CrudFormTextBoxItem,
  FormLayout,
  CrudFormButtonItem,
  ActionBehavior,
  ActionView
} from '@rappider/rappider-components/utils';
import { Validators } from '@angular/forms';

export const EDIT_FILE_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormTextBoxItem>{
      title: 'File Name',
      fieldName: 'name',
      placeholder: 'File Name',
      type: CrudViewFormItemType.TextBox,
      showRequiredSign: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'This field is required.'
        }
      ]
    },
    <CrudFormTextBoxItem>{
      title: 'Title',
      fieldName: 'title',
      placeholder: 'Title',
      type: CrudViewFormItemType.TextBox
    },
    {
      title: 'Size',
      fieldName: 'size',
      type: CrudViewFormItemType.Label
    },
    <CrudFormButtonItem>{
      text: 'Remove this file',
      view: ActionView.Icon,
      name: 'REMOVE_FILE',
      icon: { name: 'far fa-trash' },
      type: CrudViewFormItemType.Button,
      behavior: ActionBehavior.Emit,
      showColonSign: false,
      cssStyle: {
        color: 'red',
        'font-size': '13px'
      }
    }
  ],
  itemSettings: {
    inputComponentSize: {
      xs: 24
    },
    labelComponentSize: {
      xs: 24
    }
  },
  submitButton: {
    visible: false
  },
  formLayout: FormLayout.Vertical
};
