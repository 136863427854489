<rappider-list-grid [config]="MODULE_LIST_CONFIG"
                    [data]="modules"
                    [loading]="isLoading || pagesDeleteLoading"
                    (columnActionClick)="onColumnActionClick($event)"
                    (columnItemClick)="onModuleNameClick($event)"></rappider-list-grid>

<rappider-modal [(visible)]="modalVisibility"
                [okDanger]="true"
                [okText]="'SHARED.DELETE'| translate"
                [cancelText]="'SHARED.CANCEL'| translate"
                [okDisabled]="modalOkDisabled"
                (cancelClick)="onCancelModal()"
                (okClick)="onDeleteModal()">
  <div class="modal-content">
    <h4 class="modal-title">
      <rappider-icon [name]="'fas fa-regular fa-circle-xmark'"
                     [color]="'var(--danger-color)'"></rappider-icon>
      {{ modalTitle }}
    </h4>
    <div class="modal-subcontent"
         *ngIf="relatedPages?.length">
      <h6 class="modal-subtitle"><rappider-icon [name]="'fas fa-regular fa-circle-exclamation'"
                       [color]="'var(--warning-color)'"></rappider-icon> Pages Associated with the Module:</h6>
      <ul class="modal-list">
        <li *ngFor="let page of relatedPages">
          {{ page.name }}
        </li>
      </ul>
      <h6 class="modal-subtitle"><rappider-icon [name]="'fas fa-regular fa-circle-exclamation'"
                       [color]="'var(--warning-color)'"></rappider-icon> Select the Action to Perform on the Associated
        Pages:</h6>
      <div class="modal-options">
        <rappider-radio [options]="moduleDeleteOptions"
                        [(ngModel)]="moduleDeleteOption"
                        [cssStyle]="{display: 'flex', flexDirection: 'row', paddingBottom: 'var(--padding-x)'}"
                        (valueChange)="onRadioChange($event)"></rappider-radio>
        <rappider-select *ngIf="moduleDeleteOption === ModalSelectRadioOption.TransferPagesToAnotherModule"
                         [(ngModel)]="selectedModuleId"
                         class="module-select-option"
                         [cssStyle]="{ border: !selectedModuleId || matchingPageNames!.length ? '1px solid var(--danger-color)' : '' }"
                         [placeholder]="'Select Module To Transfer Pages'"
                         [options]="selectModuleOptions"
                         (valueChange)="onModuleChange($event)"></rappider-select>
        <p class="select-error-message"
           *ngIf="moduleDeleteOption === ModalSelectRadioOption.TransferPagesToAnotherModule && !selectedModuleId">
          Please select a module to transfer pages to. This field is required.
        </p>
        <p class="select-error-message"
           *ngIf="moduleDeleteOption === ModalSelectRadioOption.TransferPagesToAnotherModule && selectedModuleId && matchingPageNames?.length">
          The selected module already contains pages with identical slugs:
          {{ matchingPageNames.join(', ') }}.
        </p>
      </div>
    </div>
  </div>
</rappider-modal>