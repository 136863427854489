import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { DiagramTreeService } from '../../utils/services/diagram-tree.service';
import { DiagramEditorWorkflowMenuService } from '../../utils/services/diagram-editor-workflow-menu.service';

import * as DiagramActions from './diagram.actions';
import * as UIDataStoreActions from './../../../../../project/src/lib/states/ui-data-store/ui-data-store.actions';
import * as UIStepFunctionActions from './../../../../../project/src/lib/states/ui-step-functions/ui-step-function.actions';
import * as UIDataEventActions from './../../../../../project/src/lib/states/ui-data-event-state/ui-data-event.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import { UiDataEventControllerService, UiDataEventDuplicateWorkflowFromIdsResponseDto } from '@rappider/rappider-sdk';
import { NotificationService } from '@rappider/services';

@Injectable()
export class DiagramEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private diagramTreeService: DiagramTreeService,
    private diagramEditorWorkflowMenuService: DiagramEditorWorkflowMenuService,
    private uiDataEventControllerService: UiDataEventControllerService,
    private notificationService: NotificationService
  ) { }

  buildDiagramTree$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DiagramActions.BuildDiagramTree,
        UIDataEventActions.ActionTypes.SetUIDataEvents,
        UIDataEventActions.ActionTypes.CreateUIDataEventSuccessful,
        UIDataEventActions.ActionTypes.BulkCreateUIDataEventSuccessful,
        UIDataEventActions.ActionTypes.UpdateUIDataEventSuccessful,
        UIDataEventActions.ActionTypes.DeleteUIDataEventSuccessful,
        UIStepFunctionActions.ActionTypes.GetUIWorkflowStepFunctionsSuccessful,
        UIStepFunctionActions.ActionTypes.GetUpdatedUIWorkflowStepFunctionWithDetailsSuccessful,
        UIStepFunctionActions.ActionTypes.CreateUIWorkflowStepFunctionSuccessful,
        UIStepFunctionActions.ActionTypes.BulkCreateUIWorkflowStepFunctionSuccessful,
        UIStepFunctionActions.ActionTypes.UpdateUIWorkflowStepFunctionSuccessful,
        UIStepFunctionActions.ActionTypes.DeleteUIWorkflowStepFunctionSuccessful,
        UIDataStoreActions.ActionTypes.CreateUIDataStoresAccordingToIdSuccessful
      ),
      withLatestFrom(
        this.store.select(state => state.uiDataEvent?.isLoaded),
        this.store.select(state => state.uiDataEvent?.data),
        this.store.select(state => state.uiWorkflowStepFunction?.isLoaded),
        this.store.select(state => state.uiWorkflowStepFunction?.data)
      ),
      mergeMap(([
        action,
        isUiDataEventsLoaded,
        uiDataEvents,
        isUiStepFunctionsLoaded,
        uiStepFunctions
      ]) => {
        if (isUiDataEventsLoaded && isUiStepFunctionsLoaded) {
          return this.diagramTreeService.buildDiagramTree(uiDataEvents, uiStepFunctions).pipe(
            map(diagramTreeData => DiagramActions.BuildDiagramTreeSuccessful({
              payload: {
                diagramTree: diagramTreeData.diagramTree,
                connectors: diagramTreeData.connectedNodes
              }
            })),
            catchError(error => [
              DiagramActions.ErrorAction({ payload: { error, key: 'BuildDiagramTreeFailure', timeStamp: Date.now() } })
            ])
          );
        } else {
          return [DiagramActions.BuildDiagramTreeFailure({ payload: { error: 'isUiDataEventsLoaded or isUiStepFunctionsLoaded could not found', key: 'BuildDiagramTreeFailure', timeStamp: Date.now() } })];
        }
      })
    )
  );

  populateWorkflowMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DiagramActions.PopulateWorkflowMenu,
        UIDataEventActions.ActionTypes.SetUIDataEvents,
        UIDataEventActions.ActionTypes.CreateUIDataEventSuccessful,
        UIDataEventActions.ActionTypes.BulkCreateUIDataEventSuccessful,
        UIDataEventActions.ActionTypes.UpdateUIDataEventSuccessful,
        UIDataEventActions.ActionTypes.DeleteUIDataEventSuccessful,
        UIStepFunctionActions.ActionTypes.GetUIWorkflowStepFunctionsSuccessful,
        UIStepFunctionActions.ActionTypes.GetUpdatedUIWorkflowStepFunctionWithDetailsSuccessful,
        UIStepFunctionActions.ActionTypes.CreateUIWorkflowStepFunctionSuccessful,
        UIStepFunctionActions.ActionTypes.BulkCreateUIWorkflowStepFunctionSuccessful,
        UIStepFunctionActions.ActionTypes.UpdateUIWorkflowStepFunctionSuccessful,
        UIStepFunctionActions.ActionTypes.DeleteUIWorkflowStepFunctionSuccessful,
        UIDataStoreActions.ActionTypes.CreateUIDataStoresAccordingToIdSuccessful
      ),
      withLatestFrom(
        this.store.select(state => state.uiDataStore?.data),
        this.store.select(state => state.uiDataEvent?.isLoaded),
        this.store.select(state => state.uiDataEvent?.data),
        this.store.select(state => state.uiWorkflowStepFunction?.isLoaded),
        this.store.select(state => state.uiWorkflowStepFunction?.data)
      ),
      mergeMap(([
        action,
        uiDataStores,
        isUiDataEventsLoaded,
        uiDataEvents,
        isUiStepFunctionsLoaded,
        uiStepFunctions
      ]) => {
        if (isUiDataEventsLoaded && isUiStepFunctionsLoaded && uiDataStores.length) {
          return this.diagramEditorWorkflowMenuService
            .populateDiagramWorkflowMenu(uiDataStores, uiStepFunctions, uiDataEvents).pipe(
              map(workflowMenu => DiagramActions.PopulateWorkflowMenuSuccessful({
                payload: { workflowMenu: workflowMenu }
              })),
              catchError(error => [
                DiagramActions.ErrorAction({ payload: { error: error, key: 'PopulateWorkflowMenuFailure', timeStamp: Date.now() } })
              ])
            );
        } else {
          return [DiagramActions.PopulateWorkflowMenuFailure({ payload: { error: 'There is no isUiDataEventsLoaded, isUiStepFunctionsLoaded or uiDataStores', key: 'PopulateWorkflowMenuFailure', timestamp: Date.now() } })];
        }

      })
    )
  );

  /* Old actions can not listen on new reducers. So we use this effect temp. */
  resetStateToInitial$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthenticationActions.Logout>(AuthenticationActions.ActionTypes.Logout),
      mergeMap(action => [
        DiagramActions.ResetStateToInitial()
      ])
    )
  );

  resetStateToInitialChangeProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthenticationActions.ChangeActiveProjectSuccessful>(AuthenticationActions.ActionTypes.ChangeActiveProjectSuccessful),
      mergeMap(action => [
        DiagramActions.ResetStateToInitial()
      ])
    )
  );

  duplicateWorkflow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DiagramActions.DuplicateWorkflow
      ),
      mergeMap((action) => {
        const params = {
          body: action.payload.duplicateWorkflowWithIds
        };
        return this.uiDataEventControllerService.duplicateWorkflowWithIds(params).pipe(
          mergeMap((UiDataEventDuplicateWorkflowFromIdsResponseDto: UiDataEventDuplicateWorkflowFromIdsResponseDto) => {
            this.notificationService.createNotification(
              'success',
              'Success',
              'Workflow Created Successfully'
            );
            const uiDataEventsIds = UiDataEventDuplicateWorkflowFromIdsResponseDto?.uiDataEvents.map(uiDataEvent => uiDataEvent.id);
            const uiWorkflowStepFunctionsIds = UiDataEventDuplicateWorkflowFromIdsResponseDto?.uiStepFunctions.map(uiStepFunction => uiStepFunction.id);
            const publishedActions: Action[] = [
              new UIDataEventActions.GetUIDataEventsAccordingToId({ uiDataEventsIds }),
              new UIStepFunctionActions.GetUIWorkflowStepFunctionsAccordingToId({ uiWorkflowStepFunctionsIds }),
              DiagramActions.DuplicateWorkflowSuccessful()
            ];
            return publishedActions;
          }), catchError(error => {
            this.notificationService.createNotification(
              'error',
              'Failure',
              'Failed to create a Workflow'
            );
            return [
              DiagramActions.DuplicateWorkflowFailure({ payload: { error, key: 'DuplicateWorkflow', timestamp: Date.now() } })
            ];
          }
          ));
      }
      )
    )
  );

}
