import { KeyValue } from '@angular/common';
import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ApiResult } from '@rappider/models';
import { ButtonComponentConfig, ButtonType, IconType } from '@rappider/rappider-components/utils';
import { DataSchema, DataSchemaControllerService, DataSchemaField, DataSchemaWithRelations, UiDataStore } from '@rappider/rappider-sdk';
import { DataSchemaSelectValue } from '../../ui-data-update-function/components/data-schema-select/models/data-schema-select-value';
import { SubscribeToUIDataStoreValue } from '../models/subscribe-to-ui-data-store/subscribe-to-ui-data-store-value.interface';

@Component({
  selector: 'rappider-subscribe-to-ui-data-store',
  templateUrl: './subscribe-to-ui-data-store.component.html',
  styleUrls: ['./subscribe-to-ui-data-store.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => SubscribeToUiDataStoreComponent),
      multi: true
    }
  ]
})
export class SubscribeToUiDataStoreComponent implements OnChanges, ControlValueAccessor {
  @Input() uiDataStores: UiDataStore[];
  @Input() disabled? = false;

  uiDataStoreSelectOptions: KeyValue<string, string>[];
  selectedUiDataStore: UiDataStore;
  selectedUiDataStoreId: string;
  dataSchema: DataSchemaWithRelations;
  /* data Schema Field Ids For Data Subscriptions */
  newSubscription: DataSchemaSelectValue[] = [];
  subscribedFieldData = {
    uiDataStore: null,
    dataSchemaField: null
  };

  isModalVisible = false;
  isDataStoreFieldsLoading = false;

  _value: SubscribeToUIDataStoreValue[];

  deleteIcon = { name: 'far fa-trash', type: IconType.FontAwesome };

  addNewSubscriptionButton: ButtonComponentConfig = {
    text: 'PROJECT_MODULE.SUBSCRIBE_TO_UI_DATA_STORE_COMPONENT.ADD_NEW_SUBSCRIPTION',
    type: ButtonType.Default,
    icon: {
      name: 'fas fa-plus'
    }
  };
  showDuplicatedFieldError = false;
  showRequiredError = false;

  constructor(
    private dataSchemaApi: DataSchemaControllerService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.uiDataStores && changes.uiDataStores.currentValue) {
      this.setUiDataStoreSelectOptions();
    }
  }

  get value(): SubscribeToUIDataStoreValue[] {
    return this._value;
  }

  set value(value: SubscribeToUIDataStoreValue[]) {
    this.handleValue(value);
    this.onChange(value);
    this.onTouched();
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: SubscribeToUIDataStoreValue[]) {
    this.handleValue(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  handleValue(value: SubscribeToUIDataStoreValue[]) {
    if (value) {
      this._value = value;
    } else {
      this._value = [];
    }
  }

  setUiDataStoreSelectOptions() {
    this.uiDataStoreSelectOptions = this.uiDataStores.map((uiDataStore: UiDataStore) => ({
      key: uiDataStore.name,
      value: uiDataStore.id
    }));
  }

  onSelectUiDataStore(uiDataStoreId: string) {
    this.isDataStoreFieldsLoading = true;
    this.selectedUiDataStore = this.uiDataStores.find(uiDataStore => uiDataStore.id === uiDataStoreId);
    this.selectedUiDataStoreId = uiDataStoreId;
    this.setDataSchema(this.selectedUiDataStore?.dataSchemaId);
  }

  setDataSchema(dataSchemaId: string) {
    if (dataSchemaId) {
      this.dataSchemaApi.getWithDetailsById({ id: dataSchemaId }).subscribe((apiResult: ApiResult) => {
        this.dataSchema = apiResult.data;
        if (!this.dataSchema.fields) {
          this.subscribedFieldData = {
            ...this.subscribedFieldData,
            dataSchemaField: null
          };
        }
        this.isDataStoreFieldsLoading = false;
      });
    } else {
      this.dataSchema = null;
    }
  }

  onAddNewSubscriptionButtonClick() {
    this.isModalVisible = true;
  }

  onCancelSubscriptionModal() {
    this.isModalVisible = false;
    this.isDataStoreFieldsLoading = false;
    this.newSubscription = null;
    this.selectedUiDataStore = null;
    this.selectedUiDataStoreId = null;
    this.showDuplicatedFieldError = false;
    this.showRequiredError = false;
  }

  onSaveSubscriptionModal() {
    this.subscribedFieldData = {
      uiDataStore: this.selectedUiDataStore,
      dataSchemaField: this.newSubscription
    };
    const lastSubscribedField = this.subscribedFieldData?.dataSchemaField?.[this.subscribedFieldData?.dataSchemaField?.length - 1];
    const isFieldSubscribedBefore = this.value.some(item => item.dataSchemaField[item.dataSchemaField?.length - 1].id === lastSubscribedField?.id && lastSubscribedField?.id);
    if (isFieldSubscribedBefore) {
      this.showDuplicatedFieldError = true;
      if (this.subscribedFieldData.dataSchemaField?.length && this.subscribedFieldData.uiDataStore) {
        this.showRequiredError = false;
      }
    } else {
      this.showDuplicatedFieldError = false;
      if (!this.subscribedFieldData.dataSchemaField?.length || !this.subscribedFieldData.uiDataStore) {
        this.subscribedFieldData = {
          uiDataStore: null,
          dataSchemaField: null
        };
        this.showRequiredError = true;
      } else {
        this.showRequiredError = false;
        this.value.push(this.subscribedFieldData);
        this.value = [...this.value];
        this.onCancelSubscriptionModal();
      }
    }
  }

  onDeleteSubscription(deletedIndex: number) {
    this.value.splice(deletedIndex, 1);
  }

  getSubscribedTreeAsString(tree: DataSchemaField[]) {
    return tree?.map(node => node.name).join('.');
  }

  onDataSchemaFieldSelect(dataSchemaField: DataSchemaWithRelations) {
    this.subscribedFieldData = {
      ...this.subscribedFieldData,
      dataSchemaField: dataSchemaField
    };
  }

}
