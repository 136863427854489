import { createSelector } from '@ngrx/store';
import { Component, ComponentDefinition } from '@rappider/rappider-sdk';
import { ContentTree, ContentTreeItemType } from 'libs/content-tree-renderer/src/lib/models';
import { TreeService } from 'libs/shared/src/lib/services/tree-service/tree.service';
import { cloneDeep } from 'lodash';
import { mergeComponentsAndDefinitionsToContentTree } from '../state/content-editor-helper-functions';
import { getComponentDefinitionsWithDetailsSelector } from '@rappider/shared';

const treeService = new TreeService();

export const activeContentTreeItemSelector = createSelector(
  state => state['contentEditor'].page?.contentTree,
  state => state['contentEditor'].activeContentTreeItemId,
  state => state['contentEditor'].page?.components,
  getComponentDefinitionsWithDetailsSelector,
  (contentTree: ContentTree, activeContentTreeItemId: string, components: Component[], componentDefinitions: ComponentDefinition[]) => {
    if (contentTree?.length && activeContentTreeItemId) {
      const node = cloneDeep(treeService.findNodeInTree(contentTree[0], activeContentTreeItemId, 'id', ['children'], false));
      if (components?.length && componentDefinitions?.length) {
        const tree = mergeComponentsAndDefinitionsToContentTree(node, componentDefinitions, components);
        return tree[0];
      } else {
        return node;
      }
    }
  }
);
