import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PageControllerService, PageWithRelations } from '@rappider/rappider-sdk';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import * as AdminPagesActions from './admin-page.actions';
import * as AdminProjectsActions from '../admin-projects/admin-projects.actions';
import { PageDevelopmentStatus } from '../../utils/page-development-status.enum';
import { Store } from '@ngrx/store';

@Injectable()
export class AdminPageEffects {
  constructor(
    private store: Store<any>,
    private actions$: Actions,
    private pageApi: PageControllerService,
  ) { }

  getAdminPagesByFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AdminProjectsActions.GetAdminProjectsSuccessful,
      ),
      withLatestFrom(
        this.store.select(state => state.adminProjects.data)
      ),
      mergeMap(([action, adminProjects]) => {
        const projectIds = adminProjects.map(project => project.id);
        const filter = {
          where: {
            developmentStatus: PageDevelopmentStatus.InReview,
            projectId: { inq: projectIds }
          },
          fields: {
            contentTree: false
          },
        };
        return this.pageApi.findByAdmin({ filter }).pipe(
          map((pages: PageWithRelations[]) => AdminPagesActions.GetAdminPagesByFilterSuccessful({ payload: { pages } })
          ),
          catchError((error) => [
            AdminPagesActions.GetAdminPagesByFilterFailure({ payload: { error, key: 'GetAdminPagesByFilterFailure', timestamp: Date.now() } })
          ])
        );
      })
    )
  );

}
