import { ItemPerRow, HeadingType, SelectMode } from '@rappider/rappider-components/utils';
import { BlankTemplate } from '../../project-list/utils/blank-template';
import { CustomProjectWithAI } from '../../project-list/utils/create-with-ai-template';

export const publicProjectsCardsConfig = {
    itemCountPerRow: ItemPerRow.Four,
    showTitleOnImage: false,
    showDescriptionOnImage: false,
    showCheckMarkOnCard: true,
    paginationConfig: {
        pageIndex: 1,
        total: 3,
        pageSize: 8,
        showPagination: true
    },
    selectConfig: {
        options: [],
        settings: {
            mode: SelectMode.Multiple,
            maxTagCount: 1
        },
        placeholder: 'Filter Tags'
    },
    items: [],
    isDynamicPagination: true,
    publicProjectsIsLoading: true
};

export const publicProjectsTopSectionCardsConfig = {
    itemCountPerRow: ItemPerRow.Two,
    showTitleOnImage: false,
    showDescriptionOnImage: false,
    showCheckMarkOnCard: true,
    paginationConfig: {
        pageIndex: 1,
        total: 3,
        pageSize: 8,
        showPagination: true
    },
    selectConfig: {
        options: [],
        settings: {
            mode: SelectMode.Multiple,
            maxTagCount: 1
        },
        placeholder: 'Filter Tags'
    },
    items: [{
        "data": BlankTemplate,
        "titles": [
            {
                "type": "h1",
                "content": "📄"
            },
            {
                "type": "h4",
                "content": "Blank"
            }
        ]
    }, {
        "data": CustomProjectWithAI,
        "titles": [
            {
                "type": "h1",
                "content": "⭐"
            },
            {
                "type": "h4",
                "content": "Build with ai"
            }
        ]
    }],
    isDynamicPagination: false,
    publicProjectsIsLoading: true
};
