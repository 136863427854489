/* eslint-disable */

declare var Object: any;
export interface MetadataInterface {
  id?: string;
  key: string;
  value: any;
  projectId?: string;
  tenantId?: string;
  personId?: string;
  pageId?: string;
  categoryId?: string;
  projectFileId?: string;
  projecFolderId?: string;
  createdDate?: Date;
}

export class Metadata implements MetadataInterface {
  'id'?: string;
  'key': string;
  'value': any;
  'projectId': string;
  'tenantId': string;
  'personId': string;
  'pageId': string;
  'categoryId': string;
  'projectFileId': string;
  'projecFolderId': string;
  'createdDate': Date;
  constructor(data?: MetadataInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Metadata`.
   */
  public static getModelName() {
    return 'Metadata';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Metadata for dynamic purposes.
   **/
  public static factory(data: MetadataInterface): Metadata {
    return new Metadata(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Metadata',
      plural: 'Metadata',
      path: 'metadata',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        key: {
          name: 'key',
          type: 'string',
        },
        value: {
          name: 'value',
          type: 'any',
        },
        projectId: {
          name: 'projectId',
          type: 'string',
        },
        tenantId: {
          name: 'tenantId',
          type: 'string',
        },
        personId: {
          name: 'personId',
          type: 'string',
        },
        pageId: {
          name: 'pageId',
          type: 'string',
        },
        categoryId: {
          name: 'categoryId',
          type: 'string',
        },
        projectFileId: {
          name: 'projectFileId',
          type: 'string',
        },
        projecFolderId: {
          name: 'projecFolderId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
      },
      relations: {},
    };
  }
}
