import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { PROJECT_VERSION_CREATE_CONFIG } from '@rappider/shared/configs';
import { CreateProjectVersion, GetProjectVersions } from '../../states/project-versions-state/project-version.actions';
import { ProjectVersionService } from '@rappider/services';
import { cloneDeep } from 'lodash';
import { NewProjectVersion, ProjectVersion } from '@rappider/rappider-sdk';
import { defaultToolbarTitleHeadingSize, PATH_DEFINITIONS, VERSION_TYPES, ZERO_VERSION_NUMBER } from '@rappider/shared/definitions';
import { BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';

@Component({
  selector: 'rappider-project-version-create',
  templateUrl: './project-version-create.component.html',
  styleUrls: ['./project-version-create.component.scss']
})
export class ProjectVersionCreateComponent implements OnInit, OnDestroy {

  /* edit form config for project version create */
  PROJECT_VERSION_CREATE_CONFIG = cloneDeep(PROJECT_VERSION_CREATE_CONFIG);
  /* project version data */
  projectVersionData: Partial<ProjectVersion> = {
    versionType: VERSION_TYPES.PATCH.value
  };
  /* subscriptions */
  subscriptions: Subscription[];
  /* main title */
  mainTitle: HeadingComponentConfig;
  /* Component Title */
  title: string | string[] | BreadcrumbOption[];
  /* project versions */
  projectVersions: ProjectVersion[];
  /* loading */
  loading: boolean;
  displayToolbar = false;
  displayToolbarBackButton = false;
  /* submit button loading */
  submitButtonLoading = false;

  constructor(
    private store: Store<any>,
    private projectVersionService: ProjectVersionService
  ) { }

  ngOnInit() {
    this.store.dispatch(new GetProjectVersions());
    this.subscribeToData();
    this.getNewVersionNumberInformation(this.projectVersionData.versionType);
    this.updateForm({});
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Subscribe all data
   *
   * @memberof ProjectVersionListComponent
   */
  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToProjectVersions(),
      this.subscribeToProjectVersionLoading()
    ];
  }

  /**
   * subscribe active project
   *
   * @return {*}
   * @memberof ProjectExportComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe(activeProject => {
      if (activeProject) {
        this.mainTitle = {
          content: 'PROJECT_MODULE.PROJECT_VERSIONS_COMPONENT.PROJECT_VERSION_CREATE',
          type: defaultToolbarTitleHeadingSize
        };
        this.title = [
          {
            label: activeProject.name,
            redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${activeProject?.id}`
          },
          {
            label: 'SHARED.VERSIONS',
            redirectUrl: PATH_DEFINITIONS.PROJECTS.PROJECT_VERSION_LIST
          },
          {
            label: 'PROJECT_MODULE.PROJECT_VERSIONS_COMPONENT.PROJECT_VERSION_CREATE'
          }
        ];
      }
    });
  }

  /**
  * subscribe active project
  *
  * @return {*}
  * @memberof ProjectExportComponent
  */
  subscribeToProjectVersionLoading() {
    return this.store.select(state => state.projectVersion.loading).subscribe(projectVersionLoading => {
      this.loading = projectVersionLoading;
      if (!this.loading) {
        this.submitButtonLoading = this.loading;
      }
    });
  }

  /**
  * subscribe active project
  *
  * @return {*}
  * @memberof ProjectExportComponent
  */
  subscribeToProjectVersions() {
    return this.store.select(state => state.projectVersion.data).subscribe(projectVersions => {
      this.projectVersions = projectVersions;
    });
  }

  getLatestProjectVersionNumber(projectVersions: ProjectVersion[]): string {
    return this.projectVersionService.getLatestVersionNumberByVersions(projectVersions);
  }

  /**
   * change version
   *
   * @param {*} data
   * @memberof ProjectExportComponent
   */
  onFieldValueChange(data: any) {
    const versionType = data.versionType;
    if (versionType) {
      /* updating the version type description when the version type changes */
      this.getNewVersionNumberInformation(data.versionType);
    }
    this.updateForm(data);
  }

  getNewVersionNumberInformation(versionType: string) {
    const latestVersionNumber = this.getLatestProjectVersionNumber(this.projectVersions) ?? ZERO_VERSION_NUMBER;
    const newVersionNumber = this.projectVersionService.setVersionNumberByVersionType(latestVersionNumber, versionType);
    this.projectVersionData.versionNumber = newVersionNumber;
  }

  updateForm(changedData = {}) {
    this.projectVersionData = { ...this.projectVersionData, ...changedData };
    this.PROJECT_VERSION_CREATE_CONFIG = { ...this.PROJECT_VERSION_CREATE_CONFIG };
  }

  /**
    * Create Project Version
    *
    * @param {*} projectVersionData
    * @memberof ProjectVersionCreateComponent
    */
  onProjectVersionCreateClick(projectVersionData: NewProjectVersion) {
    this.submitButtonLoading = true;
    this.store.dispatch(new CreateProjectVersion({
      projectVersion: {
        ...this.projectVersionData,
        versionNumber: undefined /* do not pass version-number */
        /* it is gonna be calculated on back-end ( this is fe-side-calculation is giving info to person only ) */
      }
    }));
  }

}
