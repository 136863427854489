<rappider-title-toolbar [mainTitle]="mainTitle"></rappider-title-toolbar>

<section class="admin-dashboard">
  <div class="feature-card-container row">
    <div *ngFor="let feature of adminDashboardConfig"
         class="feature-card col-lg-4 col-md-6 col-sm-12">
      <div class="feature-card-content">
        <div class="feature-icon">
          <rappider-icon [name]="feature.icon.name"
                         [type]="feature.icon.type"></rappider-icon>
        </div>
        <h5 class="feature-title">
          <a [routerLink]="feature.path">
            {{feature.title}}
          </a>
        </h5>
        <p class="feature-description">
          {{feature.description}}
        </p>
        <div class="feature-links">
          <ng-container *ngFor="let subFeature of feature.subFeatures">
            <a class="feature-link"
               [routerLink]="subFeature.path">{{subFeature.title}}</a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>