<div class="rappider-tabset">
  <nz-tabset [(nzSelectedIndex)]="selectedIndex"
             [nzSize]="tabsetSize"
             [nzTabPosition]="tabsetPosition"
             [nzType]="tabsetType"
             [nzTabBarGutter]="tabsetBarGutter"
             [nzCentered]="center"
             [nzTabBarExtraContent]="extraContent ? extraTemplate : null"
             [nzHideAll]="hideAll"
             (nzAdd)="newTab()"
             (nzClose)="closeTab($event)"
             (nzSelectedIndexChange)="onSelectedIndexChange($event)"
             (nzSelectChange)="onSelectChange(selectedIndex, tabs[selectedIndex])">
    <nz-tab *ngFor="let tab of tabs"
            [nzTitle]="titleTemplate"
            [nzDisabled]="tab.disabled"
            [nzForceRender]="tab.forceRender">
      <ng-template #titleTemplate>
        <rappider-icon *ngIf="tab.icon"
                       [name]="tab.icon.name"
                       [type]="tab.icon.type"
                       [theme]="tab.icon.theme"
                       [color]="tab.icon.color"
                       [size]="tab.icon.size"></rappider-icon>
        <span> {{ tab.title }} </span>
      </ng-template>
      <span> {{ tab.content }} </span>
    </nz-tab>
  </nz-tabset>

  <ng-template #extraTemplate>
    <rappider-button [key]="extraContent?.key"
                     [type]="extraContent?.type"
                     [text]="extraContent?.text"
                     [disabled]="extraContent?.disabled"
                     (confirm)="onExtraContentConfirm()">
    </rappider-button>
  </ng-template>
</div>