import { createAction, props } from '@ngrx/store';
import { NewProjectRequirement, ProjectRequirementWithRelations } from '@rappider/rappider-sdk';

export enum ActionTypes {
  GetProjectRequirements = '[ProjectRequirements] GetProjectRequirements',
  GetProjectRequirementsSuccessful = '[ProjectRequirements] GetProjectRequirementsSuccessful',
  CreateProjectRequirement = '[ProjectRequirements] CreateProjectRequirement',
  CreateProjectRequirementSuccessful = '[ProjectRequirements] CreateProjectRequirementSuccessful',
  ToggleProjectRequirementsVisibility = '[ProjectRequirements] ToggleProjectRequirementsVisibility',
  ResetStateToInitial = '[ProjectRequirements] ResetStateToInitial',
  ResetStateToInitialChangeProject = '[ProjectRequirements] ResetStateToInitialChangeProject',
  ErrorAction = '[ProjectRequirements] ErrorAction'
}

export const GetProjectRequirements = createAction(ActionTypes.GetProjectRequirements);

export const GetProjectRequirementsSuccessful = createAction(
  ActionTypes.GetProjectRequirementsSuccessful,
  props<{ payload: { projectRequirements: ProjectRequirementWithRelations[] } }>()
);

export const CreateProjectRequirement = createAction(
  ActionTypes.CreateProjectRequirement,
  props<{ payload: { projectRequirement: NewProjectRequirement } }>()
);

export const CreateProjectRequirementSuccessful = createAction(
  ActionTypes.CreateProjectRequirementSuccessful,
  props<{ payload: { projectRequirement: ProjectRequirementWithRelations } }>()
);

export const ToggleProjectRequirementsVisibility = createAction(ActionTypes.ToggleProjectRequirementsVisibility);

export const ResetStateToInitial = createAction(ActionTypes.ResetStateToInitial);

export const ResetStateToInitialChangeProject = createAction(ActionTypes.ResetStateToInitialChangeProject);

export const ErrorAction = createAction(
  ActionTypes.ErrorAction,
  props<{ payload: { error: any; key: string; timestamp: number } }>()
);
