import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';

/* 3rd Part Modules */
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { TranslateModule } from '@ngx-translate/core';

/* NgZorro Modules */

/* State */
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromModule from './state/module.reducer';
import { ModuleEffects } from './state/module.effects';

/* Internal Modules */
import { SharedModule } from '../../../shared/src/lib/shared.module';
import { PAGE_DEFINITIONS } from '@rappider/shared/definitions';
import {
  RappiderCrudDetailModule,
  RappiderEditFormModule,
  RappiderIconModule,
  RappiderListGridModule,
  RappiderModalModule,
  RappiderPageWrapperModule,
  RappiderRadioModule,
  RappiderSelectModule,
  RappiderSpinModule
} from '@rappider/rappider-components';

/* Components */
import { ModuleComponent } from './layouts/module/module.component';
import { ModuleCreateComponent } from './components/module-create/module-create.component';
import { ModuleEditComponent } from './components/module-edit/module-edit.component';
import { ModuleListComponent } from './components/module-list/module-list.component';
import { ModuleDetailComponent } from './components/module-detail/module-detail.component';
import { FormatDatePipe } from 'libs/shared/src/lib/pipes/format-date.pipe';

const routes: Routes = [
  {
    path: '',
    component: ModuleComponent,
    children: [
      {
        path: PAGE_DEFINITIONS.MODULE.CHILDREN.MODULE_CREATE.URL,
        component: ModuleCreateComponent
      },
      {
        path: PAGE_DEFINITIONS.MODULE.CHILDREN.MODULE_LIST.URL,
        component: ModuleListComponent
      },
      {
        path: `${PAGE_DEFINITIONS.MODULE.CHILDREN.MODULE_EDIT.URL}/:id`,
        component: ModuleEditComponent
      },
      {
        path: `${PAGE_DEFINITIONS.MODULE.CHILDREN.MODULE_DETAIL.URL}/:id`,
        component: ModuleDetailComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule,
    SharedModule,
    RouterModule.forChild(routes),
    RappiderPageWrapperModule,
    RappiderEditFormModule,
    RappiderListGridModule,
    RappiderCrudDetailModule,
    RappiderSpinModule,
    RappiderModalModule,
    RappiderSelectModule,
    RappiderRadioModule,
    RappiderIconModule
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    FormatDatePipe
  ],
  declarations: [
    ModuleComponent,
    ModuleCreateComponent,
    ModuleEditComponent,
    ModuleListComponent,
    ModuleDetailComponent,
  ],
  exports: [
  ]
})
export class ModuleModule { }
