import { CrudFormConfig, CrudFormLabelItem, CrudViewFormItemType, FormLayout } from '@rappider/rappider-components/utils';

export const PAGE_INFORMATION_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormLabelItem>{
      title: 'Title',
      fieldName: 'title',
      type: CrudViewFormItemType.Label
    },
    <CrudFormLabelItem>{
      title: 'Description',
      fieldName: 'description',
      type: CrudViewFormItemType.Label
    },
    <CrudFormLabelItem>{
      title: 'Created Date',
      fieldName: 'createdDate',
      type: CrudViewFormItemType.Label
    },
    <CrudFormLabelItem>{
      title: 'Created By',
      fieldName: 'createdBy',
      type: CrudViewFormItemType.Label
    },
    <CrudFormLabelItem>{
      title: 'Updated Date',
      fieldName: 'updatedDate',
      type: CrudViewFormItemType.Label,
      hideWhenNoValue: true
    },
    <CrudFormLabelItem>{
      title: 'Updated By',
      fieldName: 'updatedBy',
      type: CrudViewFormItemType.Label,
      hideWhenNoValue: true
    }
  ],
  formLayout: FormLayout.Vertical,
  itemSettings: {
    inputComponentSize: { xs: 24, sm: 24 },
    labelComponentSize: { xs: 24 },
  },
  submitButton: {
    visible: false
  }
};
