import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ProjectFileInterface, ProjectInterface } from '@rappider/api-sdk';
import { CodeMirrorSettings, CodeMirrorTheme, CodeMirrorMode, HeadingComponentConfig, BreadcrumbOption } from '@rappider/rappider-components/utils';
import {
  PAGE_DEFINITIONS,
  ExternalScriptType,
  ProjectScriptTypeOptionsEnum,
  EXTERNAL_SCRIPT_TYPE_OPTIONS,
  SCRIPT_LOCATION_OPTIONS,
  SOURCE_TYPE_OPTIONS,
  defaultToolbarTitleHeadingSize,
  PATH_DEFINITIONS
} from '@rappider/shared/definitions';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormService } from '@rappider/services';
import { CreateProjectScript } from '../../states/project-script-state/project-script.actions';

@Component({
  selector: 'rappider-add-project-script',
  templateUrl: './add-project-script.component.html',
  styleUrls: ['./add-project-script.component.scss']
})
export class AddProjectScriptComponent implements OnInit, OnDestroy {
  /* main title */
  mainTitle: HeadingComponentConfig;
  /* title of the page */
  title: string[] | BreadcrumbOption[];
  /* active project id */
  activeProjectId: string;
  /* subscriptions */
  subscriptions: Subscription[];
  /* user input for file type */
  type: ExternalScriptType;
  /* type options for selectbox */
  typeOptions = EXTERNAL_SCRIPT_TYPE_OPTIONS;
  /* location options for selectbox */
  SCRIPT_LOCATION_OPTIONS = SCRIPT_LOCATION_OPTIONS;
  /* asset type options for selectbox */
  SOURCE_TYPE_OPTIONS = SOURCE_TYPE_OPTIONS;
  /* user input for asset type */
  sourceType: ProjectScriptTypeOptionsEnum;
  /* content object */
  content: {
    visible?: boolean;
    codemirrorVisible?: boolean;
  } = {};
  /* file modal object */
  fileModal: {
    visible?: boolean;
  } = {};
  /* file upload modal */
  fileUploadModal: {
    visible?: boolean;
  } = {};
  /* selected file */
  selectedFile: ProjectFileInterface;
  /* is file submitted */
  isFileSubmitted = false;

  /* Reactive Form */
  addProjectScriptForm: FormGroup;
  /* form submit state */
  addProjectScriptFormSubmitted = false;

  /* codemirror settings */
  contentCodemirrorSettings: CodeMirrorSettings = {
    mode: CodeMirrorMode.Javascript,
    lineNumbers: true,
    autoCloseBrackets: true,
    theme: CodeMirrorTheme.Default
  };
  displayToolbar = false;
  displayToolbarBackButton = false;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<any>,
    private formService: FormService
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * builds form
   *
   * @memberof AddProjectScriptComponent
   */
  buildForm() {
    this.addProjectScriptForm = this.formBuilder.group({
      type: [null, Validators.required],
      location: [null, Validators.required],
      sourceType: [null, Validators.required],
      title: [null, Validators.required],
      content: [null],
      file: [null]
    });
  }

  /**
   * handles validator by source type
   *
   * @returns
   * @memberof AddProjectScriptComponent
   */
  setAssetValidators() {
    const scriptTitle = this.addProjectScriptForm.get('title');
    const content = this.addProjectScriptForm.get('content');

    return this.addProjectScriptForm.get('sourceType').valueChanges.pipe(
      map(sourceType => {
        this.sourceType = sourceType;
        if (sourceType === ProjectScriptTypeOptionsEnum.Custom) {
          content.setValidators([Validators.required]);
          scriptTitle.setValidators([Validators.required]);
          this.showContent();
          this.onFileDelete();
        } else if (sourceType === ProjectScriptTypeOptionsEnum.File) {
          this.hideContent();
          content.setValidators(null);
          scriptTitle.setValidators(null);
        }
        content.updateValueAndValidity();
        scriptTitle.updateValueAndValidity();
      })
    );
  }

  subscribeToData() {
    this.subscriptions = [
      this.getProjectId(),
      this.setAssetValidators().subscribe()
    ];
  }

  subscribeToLoadingState() {
    return this.store.select(state => state.projectScript.loading).subscribe((isProjectScriptFormLoading: boolean) => {
      this.addProjectScriptFormSubmitted = isProjectScriptFormLoading;
    });
  }

  getProjectId() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectInterface) => {
      if (activeProject) {
        this.activeProjectId = activeProject.id;
        this.mainTitle = {
          content: 'PROJECT_MODULE.ADD_PROJECT_SCRIPT_COMPONENT.ADD_PROJECT_SCRIPT',
          type: defaultToolbarTitleHeadingSize
        };
        this.title = [
          {
            label: activeProject.name,
            redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${activeProject?.id}`
          },
          {
            label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_SCRIPT_LIST.PAGE_TITLE,
            redirectUrl: PATH_DEFINITIONS.PROJECTS.PROJECT_SCRIPT_LIST
          },
          {
            label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.ADD_PROJECT_SCRIPT.PAGE_TITLE
          }
        ];
      }
    });
  }

  showContent() {
    this.content.visible = true;
    /* TODO: temporary solution. Codemirror fix needed. (without timeout codemirror doesn't rendered properly.) */
    setTimeout(() => this.content.codemirrorVisible = true, 500);
  }

  hideContent() {
    this.content.visible = false;
    this.content.codemirrorVisible = false;
  }

  setFileValueToNull(isFileSubmitted: boolean) {
    if (!isFileSubmitted) {
      this.addProjectScriptForm.get('file').setValue(null);
    }
  }

  handleFileUploadVisibility(visibility: boolean) {
    this.setFileValueToNull(this.isFileSubmitted);
    return this.fileUploadModal.visible = visibility;
  }

  handleFileSelectVisibility(visibility: boolean) {
    this.setFileValueToNull(this.isFileSubmitted);
    return this.fileModal.visible = visibility;
  }

  onFileSelect(selectedFile: ProjectFileInterface) {
    this.selectedFile = selectedFile;
    this.addProjectScriptForm.get('file').setValue(this.selectedFile);
    this.isFileSubmitted = true;
    this.handleFileSelectVisibility(false);
  }

  onFileDelete() {
    const projectScriptFile = this.addProjectScriptForm.get('file');

    this.selectedFile = null;
    this.isFileSubmitted = false;
    projectScriptFile.setValue(this.selectedFile);
    projectScriptFile.updateValueAndValidity();
  }

  onTypeChange(type: ExternalScriptType) {
    this.type = type;
  }

  submitAddProjectScriptForm() {
    this.addProjectScriptFormSubmitted = true;

    const addProjectScriptForm = this.addProjectScriptForm;
    let addProjectScriptFormValue = this.addProjectScriptForm.value;

    this.formService.checkFormValidation(this.addProjectScriptForm);

    if (addProjectScriptForm.valid) {
      /* if source type is file */
      if (this.sourceType === ProjectScriptTypeOptionsEnum.File) {
        /* gets file id */
        const projectFileId = this.addProjectScriptForm.value.file?.id;
        /* if file exists */
        if (projectFileId) {
          /* content and file value delete ( content deleted because doesn't need content if source type is file) */
          delete addProjectScriptFormValue.content;
          delete addProjectScriptFormValue.file;
          /* add projectFileId to form value */
          addProjectScriptFormValue = {
            ...addProjectScriptFormValue,
            projectFileId: projectFileId
          };
        } else {
          /* if doesn't have a file show error. */
          return;
        }
      } else {
        /* if type is content, delete file because doesn't needed when type is content */
        delete this.addProjectScriptForm.value.file;
      }
      /* request body */
      const projectExternalScript = {
        ...addProjectScriptFormValue,
        projectId: this.activeProjectId
      };
      this.store.dispatch(new CreateProjectScript({ projectScript: projectExternalScript }));
    }
  }

  uploadFile(file: any) {
    this.selectedFile = file[0];
    this.selectedFile.title = file[0].name;
    if (this.selectedFile) {
      this.addProjectScriptForm.get('file').setValue(this.selectedFile);
    }
  }

  onUploadFileSave() {
    this.isFileSubmitted = true;
    this.handleFileUploadVisibility(false);
  }
}
