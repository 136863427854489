import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjectInterface } from '@rappider/api-sdk';
import { BreadcrumbOption, HeadingComponentConfig, HeadingType } from '@rappider/rappider-components/utils';
import { NewUiDataStore } from '@rappider/rappider-sdk';
import { UIDataStoreTypes, UI_DATA_STORE_CREATE_OR_EDIT_CONFIG } from '@rappider/shared/configs';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { CrudFormInputGroupItem } from 'libs/components/src/lib/utils/edit-form/crud-form-input-group-item.interface';
import { CreateUIDataStore } from 'libs/project/src/lib/states/ui-data-store/ui-data-store.actions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rappider-ui-data-store-create',
  templateUrl: './ui-data-store-create.component.html',
  styleUrls: ['./ui-data-store-create.component.scss']
})
export class UiDataStoreCreateComponent implements OnInit, OnDestroy {

  /* form config */
  UI_DATA_STORE_CREATE_CONFIG = UI_DATA_STORE_CREATE_OR_EDIT_CONFIG;
  /* active project id */
  activeProjectId: string;
  /* main title */
  mainTitle: HeadingComponentConfig;
  /* breadcrumb page title */
  title: BreadcrumbOption[];
  /* subscriptions */
  subscriptions: Subscription[];
  displayToolbar = false;
  displayToolbarBackButton = false;
  /* submit button loading */
  submitButtonLoading = false;
  constructor(
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
    const configName = this.UI_DATA_STORE_CREATE_CONFIG.items.find(item => item.fieldName === 'name') as CrudFormInputGroupItem;
    configName.suffixText = 'DataStore';
    configName.description = 'We will append the "DataStore" suffix to the name of the UI datastore.';
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach((subscription) => subscription.unsubscribe());
    const configName = this.UI_DATA_STORE_CREATE_CONFIG.items.find(item => item.fieldName === 'name') as CrudFormInputGroupItem;
    configName.suffixText = null;
    configName.description = null;
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToLoading()
    ];
  }

  /**
   * subscribe to active project to set the title
   *
   * @returns
   * @memberof ProjectModelListComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectInterface) => {
      if (activeProject) {
        this.mainTitle = {
          content: 'PROJECT_MODULE.UI_DATA_STORE_CREATE_COMPONENT.UI_DATA_STORE_ADD',
          type: defaultToolbarTitleHeadingSize
        };
        this.title = [
          {
            label: activeProject.name,
            redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${activeProject?.id}`
          },
          {
            label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_DATA_STORE_LIST.PAGE_TITLE,
            redirectUrl: PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_LIST
          },
          {
            label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_DATA_STORE_CREATE.PAGE_TITLE
          }
        ];
        this.activeProjectId = activeProject.id;
      }
    });
  }

  subscribeToLoading() {
    return this.store.select(state => state.uiDataStore.loading).subscribe(loading => {
      this.submitButtonLoading = loading;
    });
  }

  onUIDataStoreCreate(uiDataStore: NewUiDataStore) {
    /* User can only create branch ui data stores. As a result branch type is added manually. */
    uiDataStore = {
      ...uiDataStore,
      type: UIDataStoreTypes.Branch
    };
    this.store.dispatch(new CreateUIDataStore({ uiDataStore: uiDataStore }));
  }
}
