/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { RunCustomFunctionDefinitionRequestDto } from '../models/run-custom-function-definition-request-dto';
import { RunWorkflowEventRequestDto } from '../models/run-workflow-event-request-dto';
import { RunWorkflowServiceRequestDto } from '../models/run-workflow-service-request-dto';
import { RunWorkflowStepFunctionRequestDto } from '../models/run-workflow-step-function-request-dto';
import { TriggeredWorkflowStepFunctionDetails } from '../models/triggered-workflow-step-function-details';

@Injectable({
  providedIn: 'root',
})
export class WorkflowManagementControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation workflowManagementControllerRunCustomFunctionDefinition
   */
  static readonly WorkflowManagementControllerRunCustomFunctionDefinitionPath = '/workflow-management/custom-function-definition/run';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `runCustomFunctionDefinition()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  runCustomFunctionDefinition$Response(params?: {
    publicAPIKey?: string;
    secretAPIKey?: string;
    body?: RunCustomFunctionDefinitionRequestDto
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, WorkflowManagementControllerService.WorkflowManagementControllerRunCustomFunctionDefinitionPath, 'post');
    if (params) {
      rb.header('publicAPIKey', params.publicAPIKey, {});
      rb.header('secretAPIKey', params.secretAPIKey, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `runCustomFunctionDefinition$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  runCustomFunctionDefinition(params?: {
    publicAPIKey?: string;
    secretAPIKey?: string;
    body?: RunCustomFunctionDefinitionRequestDto
  }): Observable<any> {

    return this.runCustomFunctionDefinition$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation workflowManagementControllerRunWorkflowEvent
   */
  static readonly WorkflowManagementControllerRunWorkflowEventPath = '/workflow-management/workflow-events/run';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `runWorkflowEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  runWorkflowEvent$Response(params?: {
    publicAPIKey?: string;
    secretAPIKey?: string;
    body?: RunWorkflowEventRequestDto
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, WorkflowManagementControllerService.WorkflowManagementControllerRunWorkflowEventPath, 'post');
    if (params) {
      rb.header('publicAPIKey', params.publicAPIKey, {});
      rb.header('secretAPIKey', params.secretAPIKey, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `runWorkflowEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  runWorkflowEvent(params?: {
    publicAPIKey?: string;
    secretAPIKey?: string;
    body?: RunWorkflowEventRequestDto
  }): Observable<any> {

    return this.runWorkflowEvent$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation workflowManagementControllerRunWorkflowStepFunctionServiceSync
   */
  static readonly WorkflowManagementControllerRunWorkflowStepFunctionServiceSyncPath = '/workflow-management/workflow-step-function-services/run-sync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `runWorkflowStepFunctionServiceSync()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  runWorkflowStepFunctionServiceSync$Response(params?: {
    publicAPIKey?: string;
    secretAPIKey?: string;
    body?: RunWorkflowServiceRequestDto
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, WorkflowManagementControllerService.WorkflowManagementControllerRunWorkflowStepFunctionServiceSyncPath, 'post');
    if (params) {
      rb.header('publicAPIKey', params.publicAPIKey, {});
      rb.header('secretAPIKey', params.secretAPIKey, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `runWorkflowStepFunctionServiceSync$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  runWorkflowStepFunctionServiceSync(params?: {
    publicAPIKey?: string;
    secretAPIKey?: string;
    body?: RunWorkflowServiceRequestDto
  }): Observable<any> {

    return this.runWorkflowStepFunctionServiceSync$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation workflowManagementControllerGetStepFunctionResponse
   */
  static readonly WorkflowManagementControllerGetStepFunctionResponsePath = '/workflow-management/workflow-step-functions/response/{correlationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStepFunctionResponse()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStepFunctionResponse$Response(params: {
    publicAPIKey?: string;
    secretAPIKey?: string;
    correlationId: string;
  }): Observable<StrictHttpResponse<Array<TriggeredWorkflowStepFunctionDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, WorkflowManagementControllerService.WorkflowManagementControllerGetStepFunctionResponsePath, 'get');
    if (params) {
      rb.header('publicAPIKey', params.publicAPIKey, {});
      rb.header('secretAPIKey', params.secretAPIKey, {});
      rb.path('correlationId', params.correlationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TriggeredWorkflowStepFunctionDetails>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStepFunctionResponse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStepFunctionResponse(params: {
    publicAPIKey?: string;
    secretAPIKey?: string;
    correlationId: string;
  }): Observable<Array<TriggeredWorkflowStepFunctionDetails>> {

    return this.getStepFunctionResponse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TriggeredWorkflowStepFunctionDetails>>) => r.body as Array<TriggeredWorkflowStepFunctionDetails>)
    );
  }

  /**
   * Path part for operation workflowManagementControllerRunWorkflowStepFunctionAsync
   */
  static readonly WorkflowManagementControllerRunWorkflowStepFunctionAsyncPath = '/workflow-management/workflow-step-functions/run-async';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `runWorkflowStepFunctionAsync()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  runWorkflowStepFunctionAsync$Response(params?: {
    publicAPIKey?: string;
    secretAPIKey?: string;
    body?: RunWorkflowStepFunctionRequestDto
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, WorkflowManagementControllerService.WorkflowManagementControllerRunWorkflowStepFunctionAsyncPath, 'post');
    if (params) {
      rb.header('publicAPIKey', params.publicAPIKey, {});
      rb.header('secretAPIKey', params.secretAPIKey, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `runWorkflowStepFunctionAsync$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  runWorkflowStepFunctionAsync(params?: {
    publicAPIKey?: string;
    secretAPIKey?: string;
    body?: RunWorkflowStepFunctionRequestDto
  }): Observable<any> {

    return this.runWorkflowStepFunctionAsync$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation workflowManagementControllerRunWorkflowStepFunctionSync
   */
  static readonly WorkflowManagementControllerRunWorkflowStepFunctionSyncPath = '/workflow-management/workflow-step-functions/run-sync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `runWorkflowStepFunctionSync()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  runWorkflowStepFunctionSync$Response(params?: {
    publicAPIKey?: string;
    secretAPIKey?: string;
    body?: RunWorkflowStepFunctionRequestDto
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, WorkflowManagementControllerService.WorkflowManagementControllerRunWorkflowStepFunctionSyncPath, 'post');
    if (params) {
      rb.header('publicAPIKey', params.publicAPIKey, {});
      rb.header('secretAPIKey', params.secretAPIKey, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `runWorkflowStepFunctionSync$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  runWorkflowStepFunctionSync(params?: {
    publicAPIKey?: string;
    secretAPIKey?: string;
    body?: RunWorkflowStepFunctionRequestDto
  }): Observable<any> {

    return this.runWorkflowStepFunctionSync$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
