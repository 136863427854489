import { Injectable } from '@angular/core';
import { ProjectVersion, ProjectVersionControllerService } from '@rappider/rappider-sdk';
import { orderBy } from 'lodash';
import { Observable } from 'rxjs';
import { ZERO_VERSION_NUMBER, VERSION_TYPES } from '../../definitions/project-version-definitions';


@Injectable({
  providedIn: 'root'
})
export class ProjectVersionService {

  constructor(
    private projectVersionApi: ProjectVersionControllerService,
  ) { }

  /**
   * returns new project version number by update version type and current project version number
   *
   * @param {string} versionType
   * @param {string} version
   * @return {*}
   * @memberof ProjectVersionService
   */
  setVersionNumberByVersionType(version: string, versionType: string) {
    /* Set this as current project version, if it is not exist set as 0.0.0 */
    const projectVersion = version || ZERO_VERSION_NUMBER;
    /* Split project version from dots */
    const splittedProjectVersion = projectVersion.toString().split('.');

    /* get version numbers */
    const majorVersion = parseInt(splittedProjectVersion[0], 10);
    const minorVersion = parseInt(splittedProjectVersion[1], 10);
    const patchVersion = parseInt(splittedProjectVersion[2], 10);

    /* define new project version variable */
    let newProjectVersion;

    switch (versionType) {
      case VERSION_TYPES.MAJOR.value:
        newProjectVersion = `${majorVersion + 1}.0.0`;
        break;

      case VERSION_TYPES.MINOR.value:
        newProjectVersion = `${majorVersion}.${minorVersion + 1}.0`;
        break;

      case VERSION_TYPES.PATCH.value:
        newProjectVersion = `${majorVersion}.${minorVersion}.${patchVersion + 1}`;
        break;
    }
    /* return new project version */
    return newProjectVersion;
  }

  /**
   *
   *
   * @param {any[]} versions
   * @param {string} orderField
   * @return {*}
   * @memberof ProjectVersionService
   */
  getLatestVersionNumberByVersions(projectVersions: ProjectVersion[]) {
    if (projectVersions?.length) {
      const latestVersions = orderBy(projectVersions, 'createdDate', 'desc');
      return latestVersions[0].versionNumber;
    } else {
      return null;
    }
  }

  getProjectVersionById(projectVersionId: string): Observable<ProjectVersion> {
    return this.projectVersionApi.findById({ id: projectVersionId });
  }

}
