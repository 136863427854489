<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>

<section>
  <nz-tabset nzType="card"
             nzAnimated="true"
             nzTabPosition="top"
             nzSize="default"
             [nzSelectedIndex]="selectedEnvironmentTabIndex"
             class="card-container">
    <nz-tab *ngFor="let environment of environments"
            [nzTitle]="environment"
            (nzSelect)="setActiveEnvironment(environment)">
      <rappider-list-grid [loading]="loading"
                          [config]="MESSAGE_TEMPLATE_LIST_CONFIG"
                          [data]="displayedMessageTemplateData"
                          (columnActionClick)="onDeleteMessageTemplate($event)"></rappider-list-grid>
    </nz-tab>
  </nz-tabset>
</section>
