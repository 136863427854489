import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CODEMIRROR_JSON_SETTINGS } from '@rappider/rappider-components/utils';
import { NewOpenApi } from '@rappider/rappider-sdk';
import { JsonValidationService, NotificationService } from '@rappider/services';
import { CreateOpenAPI } from 'libs/project/src/lib/states/openapi-state/openapi.actions';

@Component({
  selector: 'rappider-import-openapi',
  templateUrl: './import-openapi.component.html',
  styleUrls: ['./import-openapi.component.scss']
})
export class ImportOpenapiComponent {
  CODEMIRROR_JSON_SETTINGS = CODEMIRROR_JSON_SETTINGS;

  createOpenApiData: NewOpenApi = {
    content: null,
    name: null,
    description: ''
  };

  constructor(
    private store: Store<any>,
    private jsonValidationService: JsonValidationService,
    private notificationService: NotificationService
  ) { }

  createOpenApi() {
    const jsonData = this.jsonValidationService.validateStringifiedJson(this.createOpenApiData.content);

    if (jsonData.isJsonValid) {
      this.createOpenApiData = {
        ...this.createOpenApiData,
        content: jsonData.dataAsJson
      };

      this.store.dispatch(CreateOpenAPI({ payload: { createOpenApiData: this.createOpenApiData } }));
    } else {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'ERRORS.WRONG_JSON_FORMAT'
      );
    }
  }

}
