import { AbstractControl, ValidatorFn } from '@angular/forms';

// Custom validator to check if JSON is valid
export function isJsonValid(controlName: string, isDataNullable: boolean = false): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    // If the control value is null or empty and isDataNullable is true, consider it as valid
    if (isDataNullable && (!control.value || control.value.trim() === '')) {
      return null;
    }

    try {
      // Attempt to parse the JSON
      JSON.parse(control.value);

      // If successful and the control name matches the provided controlName, it's valid
      if (controlName === control.value) {
        return null;
      }
    } catch (error) {
      // If parsing fails, it's invalid JSON
      return {
        'invalidJson': true,
        error
      };
    }

    // If none of the above conditions met, it's valid
    return null;
  };
}
