import { CodeMirrorMode, CodeMirrorSettings, CodeMirrorTheme } from '@rappider/rappider-components/utils';

export const JSON_CODEMIRROR_SETTINGS: CodeMirrorSettings = {
  mode: { name: CodeMirrorMode.Javascript, json: true },
  theme: CodeMirrorTheme.Default,
  autoRefresh: true,
  lineNumbers: true,
  autoCloseBrackets: true,
  lineWrapping: true,
  foldGutter: true,
  gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
  matchBrackets: true,
  lint: true
};
