import { ButtonType, CrudFormConfig, CrudFormConfigInputChangeReaction, CrudFormRowFormItem, CrudViewFormItemType, PropertyType } from '@rappider/rappider-components/utils';
import {
  projectThemeComponentHeaderBackgroundColor, projectThemeComponentInnerBackgroundColor,
  projectThemeFontSizeDataTransformationH1FontSize, projectThemeFontSizeDataTransformationH2FontSize,
  projectThemeFontSizeDataTransformationH3FontSize, projectThemeFontSizeDataTransformationH4FontSize,
  projectThemeFontSizeDataTransformationH5FontSize, projectThemeFontSizeDataTransformationH6FontSize,
  projectThemeFontSizeDataTransformationSubtextFontSize, projectThemeFontSizeDataTransformationTitleFontSize,
  projectThemeFooterBackgroundColor, projectThemeInputBackgroundColor, projectThemeNavbarBackgroundColor,
  projectThemePrimaryHoverColor, projectThemeSectionBackgroundColor, projectThemeTableBackgroundColor,
  projectThemeTableHeaderBackgroundColor, projectThemeTableHoverBackgroundColor, projectThemeFocusColor
} from './project-theme-data-transformation.function';

export const THEME_FORM_CONFIG: CrudFormConfig = {
  items: [
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PRIMARY_COLOR',
      fieldName: '--primary-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.MAIN_COLORS'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PRIMARY_HOVER_COLOR',
      fieldName: '--primary-hover-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.MAIN_COLORS',
      isLinked: true,
      linkedFieldName: '--primary-color',
      linkDataTransformationFunction: projectThemePrimaryHoverColor
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.FOCUS_COLOR',
      fieldName: '--focus-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.MAIN_COLORS',
      isLinked: true,
      linkedFieldName: '--primary-color',
      linkDataTransformationFunction: projectThemeFocusColor
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.DEFAULT_COLOR',
      fieldName: '--default-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.MAIN_COLORS'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.DANGER_COLOR',
      fieldName: '--danger-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.MAIN_COLORS'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.SUCCESS_COLOR',
      fieldName: '--success-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.MAIN_COLORS'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.INFO_COLOR',
      fieldName: '--info-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.MAIN_COLORS'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.WARNING_COLOR',
      fieldName: '--warning-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.MAIN_COLORS'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.DEFAULT_HOVER_COLOR',
      fieldName: '--default-hover-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.HOVER_COLORS'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.LINK_HOVER_COLOR',
      fieldName: '--link-hover-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.HOVER_COLORS'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.BODY_BACKGROUND_COLOR',
      fieldName: '--body-background-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.BACKGROUND_COLORS'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.COMPONENT_HEADER_BACKGROUND_COLOR',
      fieldName: '--component-header-background-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.BACKGROUND_COLORS',
      isLinked: true,
      linkedFieldName: '--body-background-color',
      linkDataTransformationFunction: projectThemeComponentHeaderBackgroundColor
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.COMPONENT_INNER_BACKGROUND_COLOR',
      fieldName: '--component-inner-background-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.BACKGROUND_COLORS',
      isLinked: true,
      linkedFieldName: '--body-background-color',
      linkDataTransformationFunction: projectThemeComponentInnerBackgroundColor
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.INPUT_BACKGROUND_COLOR',
      fieldName: '--input-background-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.BACKGROUND_COLORS',
      isLinked: true,
      linkedFieldName: '--body-background-color',
      linkDataTransformationFunction: projectThemeInputBackgroundColor
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.NAVBAR_BACKGROUND_COLOR',
      fieldName: '--navbar-background-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.BACKGROUND_COLORS',
      isLinked: true,
      linkedFieldName: '--body-background-color',
      linkDataTransformationFunction: projectThemeNavbarBackgroundColor
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.FOOTER_BACKGROUND_COLOR',
      fieldName: '--footer-background-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.BACKGROUND_COLORS',
      isLinked: true,
      linkedFieldName: '--body-background-color',
      linkDataTransformationFunction: projectThemeFooterBackgroundColor
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.SECTION_BACKGROUND_COLOR',
      fieldName: '--section-background-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.BACKGROUND_COLORS',
      isLinked: true,
      linkedFieldName: '--body-background-color',
      linkDataTransformationFunction: projectThemeSectionBackgroundColor
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PRIMARY_BORDER_COLOR',
      fieldName: '--primary-border-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.BORDER_COLORS'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.SECONDARY_BORDER_COLOR',
      fieldName: '--secondary-border-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.BORDER_COLORS'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.FOOTER_BORDER_COLOR',
      fieldName: '--footer-border-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.BORDER_COLORS'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.TABLE_HEADER_BACKGROUND_COLOR',
      fieldName: '--table-header-background-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.BACKGROUND_COLORS',
      isLinked: true,
      linkedFieldName: '--body-background-color',
      linkDataTransformationFunction: projectThemeTableHeaderBackgroundColor
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.TABLE_BACKGROUND_COLOR',
      fieldName: '--table-background-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.BACKGROUND_COLORS',
      isLinked: true,
      linkedFieldName: '--body-background-color',
      linkDataTransformationFunction: projectThemeTableBackgroundColor
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.TABLE_HOVER_COLOR',
      fieldName: '--table-hover-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.BACKGROUND_COLORS',
      isLinked: true,
      linkedFieldName: '--body-background-color',
      linkDataTransformationFunction: projectThemeTableHoverBackgroundColor
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.ALTERNATIVE_BACKGROUND_COLOR',
      fieldName: '--alternative-background-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.BACKGROUND_COLORS'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PRIMARY_FONT_FAMILY',
      fieldName: '--font-family-primary',
      type: CrudViewFormItemType.TextBox,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.GENERAL_FONT_SETTINGS'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.SECONDARY_FONT_FAMILY',
      fieldName: '--font-family-secondary',
      type: CrudViewFormItemType.TextBox,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.GENERAL_FONT_SETTINGS'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.MAIN_FONT_SIZE',
      fieldName: '--main-font-size',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.GENERAL_FONT_SETTINGS'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.TITLE_FONT_SIZE',
      fieldName: '--title-font-size',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.GENERAL_FONT_SETTINGS',
      isLinked: true,
      linkedFieldName: '--main-font-size',
      linkDataTransformationFunction: projectThemeFontSizeDataTransformationTitleFontSize
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.SUBTEXT_FONT_SIZE',
      fieldName: '--subtext-font-size',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.GENERAL_FONT_SETTINGS',
      isLinked: true,
      linkedFieldName: '--main-font-size',
      linkDataTransformationFunction: projectThemeFontSizeDataTransformationSubtextFontSize
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.H1_FONT_SIZE',
      fieldName: '--h1-font-size',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.HEADING_FONT_SIZES',
      isLinked: true,
      linkedFieldName: '--main-font-size',
      linkDataTransformationFunction: projectThemeFontSizeDataTransformationH1FontSize
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.H2_FONT_SIZE',
      fieldName: '--h2-font-size',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.HEADING_FONT_SIZES',
      isLinked: true,
      linkedFieldName: '--main-font-size',
      linkDataTransformationFunction: projectThemeFontSizeDataTransformationH2FontSize
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.H3_FONT_SIZE',
      fieldName: '--h3-font-size',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.HEADING_FONT_SIZES',
      isLinked: true,
      linkedFieldName: '--main-font-size',
      linkDataTransformationFunction: projectThemeFontSizeDataTransformationH3FontSize
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.H4_FONT_SIZE',
      fieldName: '--h4-font-size',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.HEADING_FONT_SIZES',
      isLinked: true,
      linkedFieldName: '--main-font-size',
      linkDataTransformationFunction: projectThemeFontSizeDataTransformationH4FontSize
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.H5_FONT_SIZE',
      fieldName: '--h5-font-size',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.HEADING_FONT_SIZES',
      isLinked: true,
      linkedFieldName: '--main-font-size',
      linkDataTransformationFunction: projectThemeFontSizeDataTransformationH5FontSize
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.H6_FONT_SIZE',
      fieldName: '--h6-font-size',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.HEADING_FONT_SIZES',
      isLinked: true,
      linkedFieldName: '--main-font-size',
      linkDataTransformationFunction: projectThemeFontSizeDataTransformationH6FontSize
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PRIMARY_TEXT_COLOR',
      fieldName: '--primary-text-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.TEXT_COLORS'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.TEXT_COLOR',
      fieldName: '--text-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.TEXT_COLORS',
      isLinked: true,
      linkedFieldName: '--primary-text-color'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.SECONDARY_TEXT_COLOR',
      fieldName: '--secondary-text-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.TEXT_COLORS',
      isLinked: true,
      linkedFieldName: '--primary-text-color'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.H1_COLOR',
      fieldName: '--h1-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.TEXT_COLORS',
      isLinked: true,
      linkedFieldName: '--primary-text-color'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.H2_COLOR',
      fieldName: '--h2-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.TEXT_COLORS',
      isLinked: true,
      linkedFieldName: '--primary-text-color'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.H3_COLOR',
      fieldName: '--h3-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.TEXT_COLORS',
      isLinked: true,
      linkedFieldName: '--primary-text-color'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.H4_COLOR',
      fieldName: '--h4-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.TEXT_COLORS',
      isLinked: true,
      linkedFieldName: '--primary-text-color'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.H5_COLOR',
      fieldName: '--h5-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.TEXT_COLORS',
      isLinked: true,
      linkedFieldName: '--primary-text-color'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.H6_COLOR',
      fieldName: '--h6-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.TEXT_COLORS',
      isLinked: true,
      linkedFieldName: '--primary-text-color'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.ALTERNATIVE_TEXT_COLOR',
      fieldName: '--alternative-text-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.TEXT_COLORS'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PRIMARY_BUTTON_TEXT_COLOR',
      fieldName: '--primary-button-text-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.TEXT_COLORS'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.SECONDARY_BUTTON_TEXT_COLOR',
      fieldName: '--secondary-button-text-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.TEXT_COLORS'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.DISABLED_COLOR',
      fieldName: '--disabled-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.TEXT_COLORS'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.DISABLED_TEXT_COLOR',
      fieldName: '--disabled-text-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.TEXT_COLORS'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.LINK_COLOR',
      fieldName: '--link-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.TEXT_COLORS'
    },
    {
      title: 'SHARED.MARGIN',
      fieldName: '--margin-x',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'Numbers'
    },
    {
      title: 'SHARED.PADDING',
      fieldName: '--padding-x',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'Numbers'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.CARD_PADDING',
      fieldName: '--card-padding',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'Numbers'
    },
    {
      title: 'SHARED.HEIGHT',
      fieldName: '--height-x',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'Numbers'
    },
    {
      title: 'SHARED.WIDTH',
      fieldName: '--width-x',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'Numbers'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.BORDER_RADIUS',
      fieldName: '--border-radius-x',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'Numbers'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.FOOTER_HEIGHT',
      fieldName: '--footer-height',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'Numbers'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PAGE_CONTAINER_MARGIN',
      fieldName: '--page-container-margin',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PAGE_CONTAINER_SIZE_SETTINGS'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PAGE_CONTAINER_MARGIN_TOP',
      fieldName: '--page-container-margin-top',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PAGE_CONTAINER_SIZE_SETTINGS',
      isLinked: true,
      linkedFieldName: '--page-container-margin'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PAGE_CONTAINER_MARGIN_RIGHT',
      fieldName: '--page-container-margin-right',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PAGE_CONTAINER_SIZE_SETTINGS',
      isLinked: true,
      linkedFieldName: '--page-container-margin'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PAGE_CONTAINER_MARGIN_BOTTOM',
      fieldName: '--page-container-margin-bottom',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PAGE_CONTAINER_SIZE_SETTINGS',
      isLinked: true,
      linkedFieldName: '--page-container-margin'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PAGE_CONTAINER_MARGIN_LEFT',
      fieldName: '--page-container-margin-left',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PAGE_CONTAINER_SIZE_SETTINGS',
      isLinked: true,
      linkedFieldName: '--page-container-margin'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PAGE_CONTAINER_PADDING',
      fieldName: '--page-container-padding',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PAGE_CONTAINER_SIZE_SETTINGS_PADDING'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PAGE_CONTAINER_PADDING_TOP',
      fieldName: '--page-container-padding-top',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PAGE_CONTAINER_SIZE_SETTINGS_PADDING',
      isLinked: true,
      linkedFieldName: '--page-container-padding'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PAGE_CONTAINER_PADDING_RIGHT',
      fieldName: '--page-container-padding-right',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PAGE_CONTAINER_SIZE_SETTINGS_PADDING',
      isLinked: true,
      linkedFieldName: '--page-container-padding'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PAGE_CONTAINER_PADDING_BOTTOM',
      fieldName: '--page-container-padding-bottom',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PAGE_CONTAINER_SIZE_SETTINGS_PADDING',
      isLinked: true,
      linkedFieldName: '--page-container-padding'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PAGE_CONTAINER_PADDING_LEFT',
      fieldName: '--page-container-padding-left',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PAGE_CONTAINER_SIZE_SETTINGS_PADDING',
      isLinked: true,
      linkedFieldName: '--page-container-padding'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.COMPONENT_CONTAINER_MARGIN',
      fieldName: '--component-container-margin',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.COMPONENT_CONTAINER_SIZE_SETTINGS'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.COMPONENT_CONTAINER_MARGIN_TOP',
      fieldName: '--component-container-margin-top',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.COMPONENT_CONTAINER_SIZE_SETTINGS',
      isLinked: true,
      linkedFieldName: '--component-container-margin'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.COMPONENT_CONTAINER_MARGIN_RIGHT',
      fieldName: '--component-container-margin-right',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.COMPONENT_CONTAINER_SIZE_SETTINGS',
      isLinked: true,
      linkedFieldName: '--component-container-margin'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.COMPONENT_CONTAINER_MARGIN_BOTTOM',
      fieldName: '--component-container-margin-bottom',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.COMPONENT_CONTAINER_SIZE_SETTINGS',
      isLinked: true,
      linkedFieldName: '--component-container-margin'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.COMPONENT_CONTAINER_MARGIN_LEFT',
      fieldName: '--component-container-margin-left',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.COMPONENT_CONTAINER_SIZE_SETTINGS',
      isLinked: true,
      linkedFieldName: '--component-container-margin'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.COMPONENT_CONTAINER_PADDING',
      fieldName: '--component-container-padding',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.COMPONENT_CONTAINER_SIZE_SETTINGS_PADDING'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.COMPONENT_CONTAINER_PADDING_TOP',
      fieldName: '--component-container-padding-top',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.COMPONENT_CONTAINER_SIZE_SETTINGS_PADDING',
      isLinked: true,
      linkedFieldName: '--component-container-padding'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.COMPONENT_CONTAINER_PADDING_RIGHT',
      fieldName: '--component-container-padding-right',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.COMPONENT_CONTAINER_SIZE_SETTINGS_PADDING',
      isLinked: true,
      linkedFieldName: '--component-container-padding'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.COMPONENT_CONTAINER_PADDING_BOTTOM',
      fieldName: '--component-container-padding-bottom',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.COMPONENT_CONTAINER_SIZE_SETTINGS_PADDING',
      isLinked: true,
      linkedFieldName: '--component-container-padding'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.COMPONENT_CONTAINER_PADDING_LEFT',
      fieldName: '--component-container-padding-left',
      type: CrudViewFormItemType.DimensionSelect,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.COMPONENT_CONTAINER_SIZE_SETTINGS_PADDING',
      isLinked: true,
      linkedFieldName: '--component-container-padding'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.TAG_COLOR',
      fieldName: '--tag-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.OTHERS'
    },
    {
      title: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PLACEHOLDER_COLOR',
      fieldName: '--placeholder-color',
      type: CrudViewFormItemType.ColorPicker,
      section: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.OTHERS'
    },
    <CrudFormRowFormItem>{
      title: 'Css Variables',
      fieldName: 'customTheme',
      type: CrudViewFormItemType.RowForm,
      saveButton: {
        text: 'Save Custom Css Variable',
        type: ButtonType.Default
      },
      columns: [
        {
          fieldName: 'key',
          typeAndFormat: {
            type: PropertyType.String
          }
        },
        {
          fieldName: 'value',
          typeAndFormat: {
            type: PropertyType.String
          }
        }
      ],
      section: 'Custom Theme'
    }
  ],
  itemSettings: {
    inputComponentSize: {
      sm: 8
    },
    labelComponentSize: {
      sm: 12
    }
  },
  submitButton: {
    visible: false
  },
  inputChangeReaction: CrudFormConfigInputChangeReaction.Blur
};
