/* eslint-disable no-shadow */
import { Action } from '@ngrx/store';
import { NewProjectModelRelation, ProjectModelRelation, ProjectModelRelationUpdateDto } from '@rappider/rappider-sdk';

export enum ActionTypes {
  GetProjectModelRelations = '[ProjectModelRelation] GetProjectModelRelations',
  GetProjectModelRelationsSuccessful = '[ProjectModelRelation] GetProjectModelRelationsSuccessful',
  GetProjectModelRelationsFailure = '[ProjectModelRelation] GetProjectModelRelationsFailure',
  CreateProjectModelRelation = '[ProjectModelRelation] CreateProjectModelRelation',
  CreateProjectModelRelationSuccessful = '[ProjectModelRelation] CreateProjectModelRelationSuccessful',
  CreateProjectModelRelationFailure = '[ProjectModelRelation] CreateProjectModelRelationFailure',
  UpdateProjectModelRelation = '[ProjectModelRelation] UpdateProjectModelRelation',
  UpdateProjectModelRelationSuccessful = '[ProjectModelRelation] UpdateProjectModelRelationSuccessful',
  UpdateProjectModelRelationFailure = '[ProjectModelRelation] UpdateProjectModelRelationFailure',
  DeleteProjectModelRelation = '[ProjectModelRelation] DeleteProjectModelRelation',
  DeleteProjectModelRelationSuccessful = '[Project Model] DeleteProjectModelRelationSuccessful',
  DeleteProjectModelRelationFailure = '[Project Model] DeleteProjectModelRelationFailure',
  ErrorAction = '[ProjectModelRelation] ErrorAction'
}

export class GetProjectModelRelations implements Action {
  readonly type = ActionTypes.GetProjectModelRelations;
  constructor(public payload: { projectModelId: string }) { }
}

export class GetProjectModelRelationsSuccessful implements Action {
  readonly type = ActionTypes.GetProjectModelRelationsSuccessful;
  constructor(public payload: { projectModelRelations: ProjectModelRelation[] }) { }
}

export class GetProjectModelRelationsFailure implements Action {
  readonly type = ActionTypes.GetProjectModelRelationsFailure;
}

export class CreateProjectModelRelation implements Action {
  readonly type = ActionTypes.CreateProjectModelRelation;
  constructor(public payload: { sourceModelId: string; projectModelRelation: NewProjectModelRelation; relationedTypeId?: string; navigateAfterCreate?: boolean }) { }
}

export class CreateProjectModelRelationSuccessful implements Action {
  readonly type = ActionTypes.CreateProjectModelRelationSuccessful;
  constructor(public payload: { projectModelRelations: ProjectModelRelation[] }) { }
}

export class CreateProjectModelRelationFailure implements Action {
  readonly type = ActionTypes.CreateProjectModelRelationFailure;
}

export class UpdateProjectModelRelation implements Action {
  readonly type = ActionTypes.UpdateProjectModelRelation;
  constructor(public payload: { projectModelRelationId: string; projectModelRelation: ProjectModelRelationUpdateDto; sourceModelId: string }) { }
}

export class UpdateProjectModelRelationSuccessful implements Action {
  readonly type = ActionTypes.UpdateProjectModelRelationSuccessful;
  constructor(public payload: { projectModelRelationId: string; projectModelRelation: ProjectModelRelation }) { }
}

export class UpdateProjectModelRelationFailure implements Action {
  readonly type = ActionTypes.UpdateProjectModelRelationFailure;
}
export class DeleteProjectModelRelation implements Action {
  readonly type = ActionTypes.DeleteProjectModelRelation;
  constructor(public payload: { projectModelRelationId: string }) { }
}

export class DeleteProjectModelRelationSuccessful implements Action {
  readonly type = ActionTypes.DeleteProjectModelRelationSuccessful;
  constructor(public payload: { projectModelRelationId: string }) { }
}

export class DeleteProjectModelRelationFailure implements Action {
  readonly type = ActionTypes.DeleteProjectModelRelationFailure;
}

export class ErrorAction implements Action {
  readonly type = ActionTypes.ErrorAction;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export type Actions = GetProjectModelRelations
  | GetProjectModelRelationsSuccessful
  | GetProjectModelRelationsFailure
  | CreateProjectModelRelation
  | CreateProjectModelRelationSuccessful
  | CreateProjectModelRelationFailure
  | UpdateProjectModelRelation
  | UpdateProjectModelRelationSuccessful
  | UpdateProjectModelRelationFailure
  | DeleteProjectModelRelation
  | DeleteProjectModelRelationSuccessful
  | DeleteProjectModelRelationFailure
  | ErrorAction;
