/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AiGenerateComponentDataSubscriptionDto } from '../models/ai-generate-component-data-subscription-dto';
import { ComponentDataSubscription } from '../models/component-data-subscription';
import { ComponentDataSubscriptionPartial } from '../models/component-data-subscription-partial';
import { ComponentDataSubscriptionWithRelations } from '../models/component-data-subscription-with-relations';
import { CreateComponentDataSubscriptionDto } from '../models/create-component-data-subscription-dto';
import { NewComponentDataSubscription } from '../models/new-component-data-subscription';
import { UpdateComponentDataSubscriptionDtoPartial } from '../models/update-component-data-subscription-dto-partial';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class ComponentDataSubscriptionControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation componentDataSubscriptionControllerAdvancedCreate
   */
  static readonly ComponentDataSubscriptionControllerAdvancedCreatePath = '/component-data-subscriptions/advanced-create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `advancedCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  advancedCreate$Response(params?: {
    body?: CreateComponentDataSubscriptionDto
  }): Observable<StrictHttpResponse<ComponentDataSubscription>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentDataSubscriptionControllerService.ComponentDataSubscriptionControllerAdvancedCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ComponentDataSubscription>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `advancedCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  advancedCreate(params?: {
    body?: CreateComponentDataSubscriptionDto
  }): Observable<ComponentDataSubscription> {

    return this.advancedCreate$Response(params).pipe(
      map((r: StrictHttpResponse<ComponentDataSubscription>) => r.body as ComponentDataSubscription)
    );
  }

  /**
   * Path part for operation componentDataSubscriptionControllerAdvancedUpdateById
   */
  static readonly ComponentDataSubscriptionControllerAdvancedUpdateByIdPath = '/component-data-subscriptions/advanced-update/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `advancedUpdateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  advancedUpdateById$Response(params: {
    id: string;
    body?: UpdateComponentDataSubscriptionDtoPartial
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentDataSubscriptionControllerService.ComponentDataSubscriptionControllerAdvancedUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `advancedUpdateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  advancedUpdateById(params: {
    id: string;
    body?: UpdateComponentDataSubscriptionDtoPartial
  }): Observable<any> {

    return this.advancedUpdateById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation componentDataSubscriptionControllerCount
   */
  static readonly ComponentDataSubscriptionControllerCountPath = '/component-data-subscriptions/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params?: {
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentDataSubscriptionControllerService.ComponentDataSubscriptionControllerCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params?: {
    where?: any;
  }): Observable<LoopbackCount> {

    return this.count$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation componentDataSubscriptionControllerGenerateByAi
   */
  static readonly ComponentDataSubscriptionControllerGenerateByAiPath = '/component-data-subscriptions/generate-by-ai';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateByAI()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateByAI$Response(params?: {
    body?: AiGenerateComponentDataSubscriptionDto
  }): Observable<StrictHttpResponse<ComponentDataSubscription>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentDataSubscriptionControllerService.ComponentDataSubscriptionControllerGenerateByAiPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ComponentDataSubscription>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateByAI$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateByAI(params?: {
    body?: AiGenerateComponentDataSubscriptionDto
  }): Observable<ComponentDataSubscription> {

    return this.generateByAI$Response(params).pipe(
      map((r: StrictHttpResponse<ComponentDataSubscription>) => r.body as ComponentDataSubscription)
    );
  }

  /**
   * Path part for operation componentDataSubscriptionControllerGenerateByAiForPage
   */
  static readonly ComponentDataSubscriptionControllerGenerateByAiForPagePath = '/component-data-subscriptions/generate-by-ai-for-page/{pageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateByAIForPage()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateByAIForPage$Response(params: {
    pageId: string;
  }): Observable<StrictHttpResponse<Array<ComponentDataSubscription>>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentDataSubscriptionControllerService.ComponentDataSubscriptionControllerGenerateByAiForPagePath, 'post');
    if (params) {
      rb.path('pageId', params.pageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ComponentDataSubscription>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateByAIForPage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateByAIForPage(params: {
    pageId: string;
  }): Observable<Array<ComponentDataSubscription>> {

    return this.generateByAIForPage$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ComponentDataSubscription>>) => r.body as Array<ComponentDataSubscription>)
    );
  }

  /**
   * Path part for operation componentDataSubscriptionControllerFindById
   */
  static readonly ComponentDataSubscriptionControllerFindByIdPath = '/component-data-subscriptions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<ComponentDataSubscription>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentDataSubscriptionControllerService.ComponentDataSubscriptionControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ComponentDataSubscription>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
    filter?: any;
  }): Observable<ComponentDataSubscription> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<ComponentDataSubscription>) => r.body as ComponentDataSubscription)
    );
  }

  /**
   * Path part for operation componentDataSubscriptionControllerDeleteById
   */
  static readonly ComponentDataSubscriptionControllerDeleteByIdPath = '/component-data-subscriptions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentDataSubscriptionControllerService.ComponentDataSubscriptionControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: string;
  }): Observable<any> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation componentDataSubscriptionControllerUpdateById
   */
  static readonly ComponentDataSubscriptionControllerUpdateByIdPath = '/component-data-subscriptions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    body?: ComponentDataSubscriptionPartial
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentDataSubscriptionControllerService.ComponentDataSubscriptionControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    body?: ComponentDataSubscriptionPartial
  }): Observable<any> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation componentDataSubscriptionControllerFind
   */
  static readonly ComponentDataSubscriptionControllerFindPath = '/component-data-subscriptions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<ComponentDataSubscriptionWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentDataSubscriptionControllerService.ComponentDataSubscriptionControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ComponentDataSubscriptionWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<ComponentDataSubscriptionWithRelations>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ComponentDataSubscriptionWithRelations>>) => r.body as Array<ComponentDataSubscriptionWithRelations>)
    );
  }

  /**
   * Path part for operation componentDataSubscriptionControllerCreate
   */
  static readonly ComponentDataSubscriptionControllerCreatePath = '/component-data-subscriptions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    body?: NewComponentDataSubscription
  }): Observable<StrictHttpResponse<ComponentDataSubscription>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentDataSubscriptionControllerService.ComponentDataSubscriptionControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ComponentDataSubscription>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    body?: NewComponentDataSubscription
  }): Observable<ComponentDataSubscription> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<ComponentDataSubscription>) => r.body as ComponentDataSubscription)
    );
  }

}
