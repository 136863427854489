import { CrudFormItem, CrudViewFormItemType, CrudFormMonacoCodeEditorItem } from '@rappider/rappider-components/utils';

export const MOCK_RESPONSE_FORM_ITEM: CrudFormItem =
  <CrudFormMonacoCodeEditorItem>
  {
    title: 'PROJECT_MODULE.CUSTOM_ENDPOINT_CREATE_OR_EDIT_COMPONENT.MOCK_RESPONSE',
    fieldName: 'mockResponse',
    type: CrudViewFormItemType.MonacoCodeEditor,
    sizeSettings: {
      minHeight: '300px'
    },
    editorOptions: {
      theme: 'vs-dark',
      language: 'json'
    }
  };
