import { RappiderCardFourModule } from './../../../components/src/lib/components/card-four/card-four.module';
import { RappiderReviewResultsModule } from './../../../components/src/lib/components/review-results/review-results.module';
import { RappiderCardTenModule } from './../../../components/src/lib/components/card-ten/card-ten.module';
import { RappiderPageWrapperModule } from './../../../components/src/lib/components/page-wrapper/page-wrapper.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectThemeComponent } from './components/project-theme/project-theme.component';
import { ProjectThemeCreateComponent } from './components/project-theme-create/project-theme-create.component';
import { ProjectThemeEditComponent } from './components/project-theme-edit/project-theme-edit.component';
import { ProjectThemeListComponent } from './components/project-theme-list/project-theme-list.component';
import { RappiderButtonModule } from 'libs/components/src/lib/components/button/button.module';
import { RappiderCardCcsModule } from 'libs/components/src/lib/components/card-ccs/card-ccs.module';
import { ExampleTemplateTwoComponent } from './components/project-theme/example-template-two/example-template-two.component';
import { ExampleTemplateOneComponent } from './components/project-theme/example-template-one/example-template-one.component';
import { RappiderTitleToolbarModule } from 'libs/components/src/lib/components/title-toolbar/title-toolbar.module';
import { RappiderSpinModule } from 'libs/components/src/lib/components/spin/spin.module';
import { RappiderEditFormModule } from 'libs/components/src/lib/components/edit-form/edit-form.module';
import { RappiderDropdownMenuModule } from 'libs/components/src/lib/components/dropdown-menu/dropdown-menu.module';
import { TranslateModule } from '@ngx-translate/core';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { RappiderParagraphModule } from 'libs/components/src/lib/components/paragraph/paragraph.module';
import { RappiderHeadingModule } from 'libs/components/src/lib/components/heading/heading.module';
import { RappiderCardOneModule } from 'libs/components/src/lib/components/card-one/card-one.module';
import { RappiderStripeModule } from 'libs/components/src/lib/components/stripe/stripe.module';
import { RappiderAccordionComponent } from 'libs/components/src/lib/components/accordion/accordion.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { RappiderProgressModule } from 'libs/components/src/lib/components/progress/progress.module';
import { RappiderStepsModule } from 'libs/components/src/lib/components/steps/steps.module';
import { RappiderImageModule } from 'libs/components/src/lib/components/image/image.module';
import { RappiderListGridModule } from 'libs/components/src/lib/components/list-grid/list-grid.module';
import { RappiderAvatarModule } from 'libs/components/src/lib/components/avatar/avatar.module';
import { RappiderRateDisplayModule } from 'libs/components/src/lib/components/rate-display/rate-display.module';
import { RappiderTagModule } from 'libs/components/src/lib/components/tag/tag.module';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { RappiderIconModule } from 'libs/components/src/lib/components/icon/icon.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NzPopconfirmModule,
    NzToolTipModule,
    RappiderPageWrapperModule,
    RappiderButtonModule,
    RappiderCardCcsModule,
    RappiderTitleToolbarModule,
    RappiderSpinModule,
    RappiderEditFormModule,
    RappiderDropdownMenuModule,
    RappiderParagraphModule,
    RappiderHeadingModule,
    RappiderCardOneModule,
    RappiderCardFourModule,
    RappiderCardTenModule,
    RappiderReviewResultsModule,
    RappiderAccordionComponent,
    RappiderStripeModule,
    RappiderProgressModule,
    RappiderStepsModule,
    RappiderImageModule,
    RappiderListGridModule,
    RappiderRateDisplayModule,
    RappiderAvatarModule,
    RappiderTagModule,
    NzButtonModule,
    NzDropDownModule,
    NzPopconfirmModule,
    RappiderIconModule
  ],
  providers: [],
  declarations: [
    ProjectThemeComponent,
    ProjectThemeCreateComponent,
    ProjectThemeEditComponent,
    ProjectThemeListComponent,
    ExampleTemplateOneComponent,
    ExampleTemplateTwoComponent
  ],
  exports: [
    ProjectThemeComponent,
    ProjectThemeCreateComponent,
    ProjectThemeEditComponent,
    ProjectThemeListComponent,
    ExampleTemplateOneComponent,
    ExampleTemplateTwoComponent
  ]
})
export class ThemeModule { }
