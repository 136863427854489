import { CrudTableViewConfig, CrudViewColumnType } from '@rappider/rappider-components/utils';
import { TEST_USER_ADD_BUTTON_CONFIG, TEST_USER_DELETE_BUTTON_CONFIG, TEST_USER_EDIT_BUTTON_CONFIG } from './models/project-test-members';

export const PROJECT_TEST_MEMBERS_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'name',
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'SHARED.FIRST_NAME',
      fieldName: 'firstName',
      type: CrudViewColumnType.Text,
    },
    {
      title: 'SHARED.LAST_NAME',
      fieldName: 'lastName',
      type: CrudViewColumnType.Text,
    },
    {
      title: 'SHARED.EMAIL',
      fieldName: 'email',
      type: CrudViewColumnType.Text,
    },
    {
      title: 'SHARED.ROLES',
      fieldName: 'roles',
      type: CrudViewColumnType.Text,
    }
  ],
  listActions: [
    TEST_USER_ADD_BUTTON_CONFIG,
  ],
  itemActions: [
    TEST_USER_EDIT_BUTTON_CONFIG,
    TEST_USER_DELETE_BUTTON_CONFIG
  ],
};
