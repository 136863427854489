import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ProjectInterface } from '@rappider/api-sdk';
import { UI_DATA_EVENT_CREATE_OR_EDIT_CONFIG } from '@rappider/shared/configs';
import { defaultToolbarTitleHeadingSize, PATH_DEFINITIONS, QUERY_PARAM_DEFINITIONS } from '@rappider/shared/definitions';
import { UpdateUIDataEvent } from 'libs/project/src/lib/states/ui-data-event-state/ui-data-event.actions';
import { Subscription } from 'rxjs';
import { ActionBehavior, ActionButtonConfig, BreadcrumbOption, ButtonSize, CrudFormSelectItem, FormLayout, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { UiDataEvent, UiDataEventWithRelations, UiDataStoreWithRelations } from '@rappider/rappider-sdk';
import { UIDataEventType } from 'libs/shared/src/lib/definitions/ui-data-event/ui-data-event-type.enum';
import { cloneDeep } from 'lodash';
import { StringUtilityService } from '@rappider/services';

@Component({
  selector: 'rappider-data-event-edit',
  templateUrl: './data-event-edit.component.html',
  styleUrls: ['./data-event-edit.component.scss']
})
export class DataEventEditComponent implements OnInit, OnDestroy, OnChanges {
  /* data event edit config */
  UI_DATA_EVENT_EDIT_CONFIG = cloneDeep(UI_DATA_EVENT_CREATE_OR_EDIT_CONFIG);

  @Input() uiDataEventId: string;
  /* layout of the form 'horizontal' or 'vertical' */
  @Input() formLayout: FormLayout;
  /* flag for whether to navigate after data event update */
  @Input() navigateAfterUpdate = true;
  /* flag to display breadcrumb under title */
  @Input() displayBreadcrumb = true;
  @Input() titleBarActionButtons: ActionButtonConfig[];
  @Input() addUiStepFunctionButton: ActionButtonConfig;

  @Output() addWorkflow = new EventEmitter();
  @Output() addUiStepFunctionToDataEvent = new EventEmitter();

  /* main title */
  mainTitle: HeadingComponentConfig = {
    content: 'PROJECT_MODULE.DATA_EVENT_EDIT_COMPONENT.EDIT_DATA_EVENT',
    type: defaultToolbarTitleHeadingSize
  };
  /* title breadcrumb */
  title: BreadcrumbOption[];
  /* ui data event */
  uiDataEvent: UiDataEvent;
  /* ui data events */
  uiDataEvents: UiDataEvent[];
  /* active project */
  activeProject: ProjectInterface;
  /* subscriptions */
  subscriptions: Subscription[];
  /* ui data store */
  uiDataStoreId: string;
  uiDataStore: UiDataStoreWithRelations;
  uiDataStores: UiDataStoreWithRelations[];

  displayToolbar = false;
  displayToolbarBackButton = false;
  defaultTitleBarActionButtonsConfig: ActionButtonConfig[] = [{
    key: 'add-workflow',
    text: 'Add Workflow',
    icon: { name: 'far fa-add' },
    behavior: ActionBehavior.Emit,
    visible: false
  }];

  defaultAddUiStepFunctionButtonConfig: ActionButtonConfig = {
    text: 'Add UI Step Function to Data Event',
    size: ButtonSize.Default,
    icon: {
      name: 'fa-solid fa-plus'
    },
    block: true,
    visible: false
  };

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.setFormLayoutInConfig();
    this.filterCreateSuccessAndFailureEventsItem();
    this.getUIDataEventIdFromUrl();
    this.subscribeToData();
    this.setUIDataEventType();
    this.setButtonConfigs();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.uiDataEventId) {
      this.uiDataEvent = this.findUIDataEventById(this.uiDataEvents);
    }
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToUIDataEvent(),
      this.subscribeToUIDataStore()
    ];
  }

  getUIDataEventIdFromUrl() {
    if (!this.uiDataEventId) {
      this.uiDataEventId = this.activatedRoute.snapshot.params['id'];
    }
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectInterface) => {
      this.activeProject = activeProject;
    });
  }

  /**
   * subscribes to ui data events state and finds editing event by id
   *
   * @return {*}
   * @memberof DataEventEditComponent
   */
  subscribeToUIDataEvent() {
    return this.store.select(state => state.uiDataEvent?.data).subscribe((uiDataEvents: UiDataEvent[]) => {
      this.uiDataEvent = this.findUIDataEventById(uiDataEvents);
      this.uiDataEvents = uiDataEvents;
      this.uiDataStoreId = this.uiDataEvent?.uiDataStoreId;
      if (this.uiDataStoreId) {
        this.setTitle();
      }
    });
  }

  findUIDataEventById(uiDataEvents: UiDataEvent[]) {
    return uiDataEvents?.find(uiDataEvent => uiDataEvent.id === this.uiDataEventId);
  }

  /**
   * subscribes to ui data stores state and finds current ui data store by id
   *
   * @return {*}
   * @memberof DataEventEditComponent
   */
  subscribeToUIDataStore() {
    return this.store.select(state => state.uiDataStore?.data).subscribe((uiDataStores: UiDataStoreWithRelations[]) => {
      if (uiDataStores?.length) {
        this.uiDataStores = uiDataStores;
        if (this.uiDataStores) {
          this.setTitle();
        }
        this.setUIDataStoreSelectOptions(uiDataStores);
      }
    });
  }

  setTitle() {
    if (this.uiDataStores && this.uiDataStoreId) {
      this.uiDataStore = this.uiDataStores.find(uiDataStore => uiDataStore.id === this.uiDataStoreId);
      this.title = [
        {
          label: this.activeProject.name,
          redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject.id}`,
        },
        {
          label: 'PROJECT_MODULE.UI_DATA_STORE_LIST_COMPONENT.UI_DATA_STORE_LIST',
          redirectUrl: PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_LIST
        },
        {
          label: this.uiDataStore?.name,
          redirectUrl: `${PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_DETAIL}/${this.uiDataStoreId}`,
          queryParams: QUERY_PARAM_DEFINITIONS.PROJECT.UI_DATA_STORE_DETAIL.EVENTS_TAB
        },
        {
          label: 'PROJECT_MODULE.DATA_EVENT_EDIT_COMPONENT.EDIT_DATA_EVENT'
        }
      ];
    }
  }

  setButtonConfigs() {
    if (this.addUiStepFunctionButton === undefined) {
      this.addUiStepFunctionButton = this.defaultAddUiStepFunctionButtonConfig;
    }
    if (this.titleBarActionButtons === undefined) {
      this.titleBarActionButtons = this.defaultTitleBarActionButtonsConfig;
    }
  }

  onUpdateUIDataEvent(uiDataEvent: UiDataEventWithRelations) {
    this.store.dispatch(new UpdateUIDataEvent({ uiDataEventId: this.uiDataEventId, uiDataEvent: uiDataEvent, navigateAfterUpdate: this.navigateAfterUpdate }));
  }

  setFormLayoutInConfig() {
    this.UI_DATA_EVENT_EDIT_CONFIG.formLayout = this.formLayout;
  }

  setUIDataEventType() {
    if (!this.uiDataEvent?.type) {
      this.uiDataEvent = {
        ...this.uiDataEvent,
        type: UIDataEventType.Standard
      };
    }
  }

  setUIDataStoreSelectOptions(uiDataStores: UiDataStoreWithRelations[]) {
    const uiDataStoreFormItem = this.UI_DATA_EVENT_EDIT_CONFIG.items.find(item => item.fieldName === 'uiDataStoreId') as CrudFormSelectItem;
    uiDataStoreFormItem.options = uiDataStores.map(uiDataStore => ({
      key: uiDataStore.name,
      value: uiDataStore.id
    }));
    this.UI_DATA_EVENT_EDIT_CONFIG = { ...this.UI_DATA_EVENT_EDIT_CONFIG };
  }

  filterCreateSuccessAndFailureEventsItem() {
    this.UI_DATA_EVENT_EDIT_CONFIG.items = this.UI_DATA_EVENT_EDIT_CONFIG.items.filter(item => item.fieldName !== 'createSuccessAndFailureEvents');
  }

  onTitleBarActionButtonClick() {
    this.addWorkflow.emit();
  }

  onAddUiStepFunctionToDataEvent() {
    this.addUiStepFunctionToDataEvent.emit();
  }

  onDataEventFieldValueChange(dataEvent: any) {
    this.uiDataEvent = {
      ...this.uiDataEvent,
      ...dataEvent
    };

    if (dataEvent.name || dataEvent.name === '') {
      this.uiDataEvent = {
        ...this.uiDataEvent,
        name: StringUtilityService.toTitleCase(dataEvent.name).replace(/\s/g, '') || '',
      };
    }
  }
}
