<section *ngIf="isLoading && !isLoaded">
  <nz-skeleton [nzActive]="true"
               [nzLoading]="isLoading"
               [nzTitle]="false"
               [nzParagraph]="{ rows: 2 }">
  </nz-skeleton>
</section>
<rappider-title-toolbar *ngIf="!isLoading || isLoaded"
                        [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>

<section>
  <rappider-spin [spinning]="isLoading && !submitButtonLoading">
    <rappider-crud-view-edit-form [config]="PROJECT_MODEL_CREATE_CONFIG"
                                  [data]="projectModelDefaultData"
                                  [submitButtonLoading]="submitButtonLoading"
                                  (fieldValueChange)="onProjectModelFieldValueChange($event)"
                                  (formSubmit)="onFormSubmit($event)"></rappider-crud-view-edit-form>
  </rappider-spin>
</section>
