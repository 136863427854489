import { ActionBehavior, ButtonType, CrudTableViewConfig, CrudViewColumnType } from '@rappider/rappider-components/utils';
import { PATH_DEFINITIONS } from '../../definitions/path-definition';
import { POP_CONFIRM_DELETE_ACTION } from '../pop-confirm-button/pop-confirm-button-config';

export const MODULE_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'name',
  columns: [
    {
      title: 'SHARED.ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'SHARED.TITLE',
      fieldName: 'title',
      type: CrudViewColumnType.ActionLink
    },
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.SLUG',
      fieldName: 'slug',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.CREATED_BY',
      fieldName: 'createdBy',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.CREATED_DATE',
      fieldName: 'createdDate',
      type: CrudViewColumnType.Date
    }
  ],
  listActions: [
    {
      name: 'createModule',
      text: 'MODULE_MODULE.ADD_MODULE',
      buttonType: ButtonType.Default,
      icon: { name: 'fas fa-plus' },
      behavior: ActionBehavior.Route,
      redirectUrl: `${PATH_DEFINITIONS.MODULE.MODULE_CREATE_PATH}`
    }
  ],
  itemActions: [
    {
      text: 'SHARED.EDIT',
      name: 'editModule',
      behavior: ActionBehavior.Emit,
      icon: { name: 'far fa-edit' }
    },
    {
      text: 'SHARED.DELETE',
      name: 'DELETE_ITEM',
      behavior: ActionBehavior.Emit,
      icon: { name: 'far fa-trash' },
    }
  ],
  handleDisableFn: (data, item, action) => {
    if (action.name === 'DELETE_ITEM' && item.isDefaultModule === true) {
      return true;
    }
    return false;
  },
  handleTooltipFn: (data, item, action) => {
    if (action.name === 'DELETE_ITEM' && item.isDefaultModule === true) {
      return 'Default Module Cannot Be Deleted';
    }
    return '';
  }
};
