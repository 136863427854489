import { ButtonType, IconType } from '@rappider/rappider-components/utils';

export const PROJECT_LIST_CONFIG_WITH_CARD = {
  firstActionButton: {
    key: 'settings',
    icon: {
      name: 'fa-light far fa-cog',
      type: IconType.FontAwesome
    },
    type: ButtonType.Link
  },
  secondActionButton: {
    key: '',
    icon: {
      name: '',
      type: IconType.FontAwesome,
      color: ''
    },
    popconfirmTitle: '',
    type: ButtonType.Link,
    emitWithoutPopconfirm: false
  },
  thirdActionButton: {
    key: 'delete-project',
    icon: {
      name: 'fa-light far fa-trash',
      type: IconType.FontAwesome
    },
    type: ButtonType.Link,
    popconfirmTitle: 'Are you sure you want to delete this project?',
    emitWithoutPopconfirm: false
  }
};
