/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GenerateJsonSchemaByJsonDataDto } from '../models/generate-json-schema-by-json-data-dto';
import { ValidateDataByJsonSchemaRequestDto } from '../models/validate-data-by-json-schema-request-dto';

@Injectable({
  providedIn: 'root',
})
export class JsonSchemaControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation jsonSchemaControllerGenerateJsonSchemaByJsonData
   */
  static readonly JsonSchemaControllerGenerateJsonSchemaByJsonDataPath = '/json-schemas/generate-json-schema-by-json-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateJsonSchemaByJsonData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateJsonSchemaByJsonData$Response(params?: {
    body?: GenerateJsonSchemaByJsonDataDto
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, JsonSchemaControllerService.JsonSchemaControllerGenerateJsonSchemaByJsonDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateJsonSchemaByJsonData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateJsonSchemaByJsonData(params?: {
    body?: GenerateJsonSchemaByJsonDataDto
  }): Observable<any> {

    return this.generateJsonSchemaByJsonData$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation jsonSchemaControllerValidateDataByJsonSchema
   */
  static readonly JsonSchemaControllerValidateDataByJsonSchemaPath = '/json-schemas/validate-data-by-json-schema';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateDataByJSONSchema()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateDataByJSONSchema$Response(params?: {
    body?: ValidateDataByJsonSchemaRequestDto
  }): Observable<StrictHttpResponse<ValidateDataByJsonSchemaRequestDto>> {

    const rb = new RequestBuilder(this.rootUrl, JsonSchemaControllerService.JsonSchemaControllerValidateDataByJsonSchemaPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ValidateDataByJsonSchemaRequestDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `validateDataByJSONSchema$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateDataByJSONSchema(params?: {
    body?: ValidateDataByJsonSchemaRequestDto
  }): Observable<ValidateDataByJsonSchemaRequestDto> {

    return this.validateDataByJSONSchema$Response(params).pipe(
      map((r: StrictHttpResponse<ValidateDataByJsonSchemaRequestDto>) => r.body as ValidateDataByJsonSchemaRequestDto)
    );
  }

}
