import {
  CrudTableViewConfig,
  CrudViewColumnType,
  ActionBehavior,
  ButtonType,
} from '@rappider/rappider-components/utils';
import { PATH_DEFINITIONS } from '../../definitions/path-definition';

export const PROJECT_MEMBERS_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'name',
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'SHARED.FIRST_NAME',
      fieldName: 'firstName',
      type: CrudViewColumnType.Text,
    },
    {
      title: 'SHARED.LAST_NAME',
      fieldName: 'lastName',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.ROLES',
      fieldName: 'roleNames',
      type: CrudViewColumnType.TagList,
      isListGridDataObject: false
    }
  ],
  listActions: [],
  itemActions: [],
  handleVisibilityFn: (data, item, action) => {
    if (action.name === 'assignManager' && item.authorities.length > 0) {
      return false;
    } else {
      return true;
    }
  }
};

export const PROJECT_PERSON_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'name',
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'SHARED.FIRST_NAME',
      fieldName: 'firstName',
      type: CrudViewColumnType.Text,
      visible: true
    },
    {
      title: 'SHARED.LAST_NAME',
      fieldName: 'lastName',
      type: CrudViewColumnType.Text,
      visible: true
    }
  ],
  listActions: [
    {
      text: 'PROJECT_MODULE.PROJECT_MEMBER_LIST_COMPONENT.BACK_TO_PROJECT_ROLES',
      behavior: ActionBehavior.Route,
      buttonType: ButtonType.Default,
      icon: { name: 'fas fa-angle-left' },
      redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_ROLE_LIST}`
    }
  ],
  itemActions: []
};

export const PROJECT_OWNERS_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'name',
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'SHARED.FIRST_NAME',
      fieldName: 'firstName',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.LAST_NAME',
      fieldName: 'lastName',
      type: CrudViewColumnType.Text
    }
  ],
  listActions: [],
  itemActions: []
};

export const PROJECT_MANAGERS_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'name',
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'SHARED.FIRST_NAME',
      fieldName: 'firstName',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.LAST_NAME',
      fieldName: 'lastName',
      type: CrudViewColumnType.Text
    }
  ],
  listActions: [],
  itemActions: []
};

export enum ROLES {
  OWNERS = 'owner',
  MANAGERS = 'manager',
  MEMBERS = 'member'
};

export enum Authorities {
  ProjectOwner = 'PROJECT_OWNER',
  ProjectManager = 'PROJECT_MANAGER',
  ProjectMember = 'PROJECT_MEMBER'
};
