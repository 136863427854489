import { Injectable } from '@angular/core';
import { MenuConfig, MenuActionBehavior, MenuMode, Menu } from '@rappider/rappider-components/utils';
import { UiDataEventWithRelations, UiDataStoreWithRelations, UiWorkflowStepFunctionSubscribedEventWithRelations, UiWorkflowStepFunctionWithRelations } from '@rappider/rappider-sdk';
import { of } from 'rxjs';
import { DiagramItemType } from '../diagram-item-type';
import { DiagramMenuIcons } from '../diagram-menu-items';
import { WorkflowItemInDiagram } from '../workflow-item-in-diagram.interface';

@Injectable({
  providedIn: 'root'
})
export class DiagramEditorWorkflowMenuService {

  constructor() { }

  /**
   * gets ui data stores of the project for mapping as menu items to
   * populate the workflow menu
   *
   * @param {UiDataStoreWithRelations[]} uiDataStores
   * @return {*}
   * @memberof DiagramEditorWorkflowMenuService
   */
  populateDiagramWorkflowMenu(
    uiDataStores: UiDataStoreWithRelations[],
    uiStepFunctions: UiWorkflowStepFunctionSubscribedEventWithRelations[],
    uiDataEvents: UiDataEventWithRelations[]
  ) {
    return of({
      items: [
        ...<any[]>uiDataStores?.map(uiDataStore =>
          <any>{
            label: uiDataStore.name,
            actionBehavior: MenuActionBehavior.Emit,
            icon: {
              name: DiagramMenuIcons.Workflow
            },
            data: { uiDataStore },
            isExpanded: false,
            /* events of the ui data store */
            children: [
              ...this.transformDataEventsOfDataStoreToMenu(uiDataStore.id, uiDataEvents),
              ...this.transformUIWorkflowStepsFunctionsOfDataStoreToMenu(uiDataStore, uiStepFunctions)
            ]
          })
      ],
      mode: MenuMode.Inline
    });
  }

  /**
   * transforms the data events of the ui datastore to menu
   *
   * @private
   * @param {string} uiDataStoreId
   * @param {UiDataEventWithRelations[]} uiDataEvents
   * @return {*}  {Menu[]}
   * @memberof DiagramEditorWorkflowMenuService
   */
  private transformDataEventsOfDataStoreToMenu(uiDataStoreId: string, uiDataEvents: UiDataEventWithRelations[]): Menu[] {
    const uiDataEventsOfUiDataStore = uiDataEvents?.filter(uiDataEvent => uiDataEvent.uiDataStoreId === uiDataStoreId);

    if (uiDataEventsOfUiDataStore.length) {
      return [
        ...uiDataEventsOfUiDataStore.map(uiDataEvent => <Menu>{
          label: uiDataEvent.name,
          key: uiDataStoreId,
          data: <WorkflowItemInDiagram>{
            item: {
              ...uiDataEvent
            },
            type: DiagramItemType.UIDataEvent
          },
          actionBehavior: MenuActionBehavior.Emit,
          icon: {
            name: DiagramMenuIcons.Event
          }
        })
      ];
    } else {
      return [];
    }
  }

  /**
   * transforms the ui step functions of the ui datastore to menu
   *
   * @param {UiDataStoreWithRelations} uiDataStore
   * @param {UiWorkflowStepFunctionSubscribedEventWithRelations[]} uiStepFunctions
   * @return {*}  {Menu[]}
   * @memberof DiagramEditorWorkflowMenuService
   */
  private transformUIWorkflowStepsFunctionsOfDataStoreToMenu(
    uiDataStore: UiDataStoreWithRelations,
    uiStepFunctions: UiWorkflowStepFunctionSubscribedEventWithRelations[]
  ): Menu[] {
    if (uiStepFunctions?.length) {
      return [...uiStepFunctions.filter(uiWorkflowStepFunction => uiWorkflowStepFunction.uiDataStoreId === uiDataStore.id
      )].map(uiWorkflowStep => <Menu>{
        label: uiWorkflowStep.name,
        key: uiDataStore.id,
        data: <any>{
          item: {
            ...uiWorkflowStep,
            name: uiWorkflowStep.name,
            type: DiagramItemType.UIStepFunction,
            uiDataStoreId: uiWorkflowStep.uiDataStoreId
          } as unknown as UiWorkflowStepFunctionWithRelations,
          type: DiagramItemType.UIStepFunction
        },
        actionBehavior: MenuActionBehavior.Emit,
        icon: {
          name: DiagramMenuIcons.StepFunction
        }
      });
    } else {
      return [];
    }
  }
}
