/* eslint-disable */
import { ProjectExportStatus } from '../../definitions/project-export/project-export-status.enum';
import { Project, ProjectVersion, Person } from '..';

declare var Object: any;
export interface ProjectExportInterface {
  id?: string;
  appName: string;
  appType: string;
  framework: string;
  projectId?: string;
  projectVersionId?: string;
  personId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  project?: Project;
  projectVersion?: ProjectVersion;
  person?: Person;
  status?: ProjectExportStatus;
  processes?: any;
}

export class ProjectExport implements ProjectExportInterface {
  'id'?: string;
  'appName': string;
  'appType': string;
  'framework': string;
  'projectId': string;
  'projectVersionId': string;
  'personId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  'status': ProjectExportStatus;
  project: Project;
  projectVersion: ProjectVersion;
  person: Person;
  constructor(data?: ProjectExportInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ProjectExport`.
   */
  public static getModelName() {
    return 'ProjectExport';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ProjectExport for dynamic purposes.
   **/
  public static factory(data: ProjectExportInterface): ProjectExport {
    return new ProjectExport(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ProjectExport',
      plural: 'ProjectExports',
      path: 'project-exports',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        appName: {
          name: 'appName',
          type: 'string',
        },
        appType: {
          name: 'appType',
          type: 'string',
        },
        framework: {
          name: 'framework',
          type: 'string',
        },
        projectId: {
          name: 'projectId',
          type: 'string',
        },
        projectVersionId: {
          name: 'projectVersionId',
          type: 'string',
        },
        personId: {
          name: 'personId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
        status: {
          name: 'status',
          type: 'string',
        },
      },
      relations: {
        project: {
          name: 'project',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
          keyFrom: 'projectId',
          keyTo: 'id',
        },
        projectVersion: {
          name: 'projectVersion',
          type: 'ProjectVersion',
          model: 'ProjectVersion',
          relationType: 'belongsTo',
          keyFrom: 'projectVersionId',
          keyTo: 'id',
        },
        person: {
          name: 'person',
          type: 'Person',
          model: 'Person',
          relationType: 'belongsTo',
          keyFrom: 'personId',
          keyTo: 'id',
        },
      },
    };
  }
}
