import { Validators } from '@angular/forms';
import {
  CodeMirrorTheme,
  CrudFormCodeMirrorItem,
  CrudFormConfig,
  CrudFormListItem,
  CrudFormRadioItem,
  CrudFormSelectItem,
  CrudViewFormItemType,
  SelectMode
} from '@rappider/rappider-components/utils';
import { EXTERNAL_SCRIPT_TYPE_OPTIONS } from '../../definitions/external-script-type-options';
import { SCRIPT_LOCATION_OPTIONS } from '../../definitions/project-script-location-options';

export const ADD_PROJECT_SCRIPT_FORM_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormSelectItem>{
      title: 'Type',
      fieldName: 'type',
      type: CrudViewFormItemType.Select,
      settings: {
        mode: SelectMode.Single
      },
      options: EXTERNAL_SCRIPT_TYPE_OPTIONS,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    <CrudFormSelectItem>{
      title: 'File Location',
      fieldName: 'fileLocation',
      type: CrudViewFormItemType.Select,
      settings: {
        mode: SelectMode.Single
      },
      options: SCRIPT_LOCATION_OPTIONS,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    <CrudFormRadioItem>{
      title: 'PROJECT_MODULE.ADD_PROJECT_SCRIPT_COMPONENT.SOURCE_TYPE',
      fieldName: 'sourceType',
      type: CrudViewFormItemType.Radio,
      options: [
        {
          key: 'static',
          value: 'static'
        },
        {
          key: 'dynamic',
          value: 'dynamic'
        }
      ]
    },
    <CrudFormListItem>{
      title: 'File List',
      fieldName: 'fileList',
      type: CrudViewFormItemType.List,
      visible: false
    },
    <CrudFormCodeMirrorItem>{
      title: 'Script Code',
      fieldName: 'content',
      type: CrudViewFormItemType.CodeMirror,
      settings: {
        theme: CodeMirrorTheme.Default
      },
      visible: false
    }
  ]
};


