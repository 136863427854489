import { createSelector } from '@ngrx/store';
import { WorkflowStepFunctionWithRelations } from '@rappider/rappider-sdk';

export const getWorkflowStepFunctionsAndTemplates = createSelector(
  state => state['workflowStepFunction']?.data,
  state => state['workflowStepFunction']?.isDataFetched,
  state => state['workflowStepFunction']?.templateWorkflowStepFunctions,
  state => state['workflowStepFunction']?.isTemplateWorkflowStepFunctionsLoaded,
  (
    workflowStepFunctions: WorkflowStepFunctionWithRelations[],
    isDataFetched: boolean,
    templateWorkflowStepFunctions: WorkflowStepFunctionWithRelations[],
    isTemplateWorkflowStepFunctionsLoaded: boolean) => {
    if (isDataFetched && isTemplateWorkflowStepFunctionsLoaded) {
      return {
        workflowStepFunctions: workflowStepFunctions,
        templateWorkflowStepFunctions: templateWorkflowStepFunctions
      };
    }
  }
);
