import { FlexAlignContentOption } from './flex-align-content-option.enum';
import { FlexAlignItemsOption } from './flex-align-items-option.enum';
import { FlexDirectionOption } from './flex-direction-option.enum';
import { FlexJustifyContentOption } from './flex-justify-content-option.enum';
import { FlexWrapOption } from './flex-wrap-option.enum';

export const DefaultFlexOptions = {
    flexAlignContent: FlexAlignContentOption.FlexStart,
    flexAlignItems: FlexAlignItemsOption.FlexStart,
    flexDirection: FlexDirectionOption.Horizantal,
    flexJustifyContent: FlexJustifyContentOption.FlexStart,
    flexWrap: FlexWrapOption.Wrap
};
