import { Component, OnDestroy, OnInit } from '@angular/core';
import { ADMIN_PROJECT_EDIT_FORM_CONFIG } from './config/admin-project-edit-form-config';
import { PAGE_DEFINITIONS, PATH_DEFINITIONS, defaultToolbarTitleHeadingSize } from '@rappider/shared/definitions';
import { BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { Metadata, Project, ProjectWithRelations } from '@rappider/rappider-sdk';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { DataTransformService } from '@rappider/services';
import { UpdateAdminProject } from '../../state/admin-projects/admin-projects.actions';

@Component({
  selector: 'rappider-admin-project-edit',
  templateUrl: './admin-project-edit.component.html',
  styleUrls: ['./admin-project-edit.component.scss']
})
export class AdminProjectEditComponent implements OnInit, OnDestroy {

  ADMIN_PROJECT_EDIT_FORM_CONFIG = ADMIN_PROJECT_EDIT_FORM_CONFIG;

  mainTitle: HeadingComponentConfig = {
    content: 'Project Edit',
    type: defaultToolbarTitleHeadingSize
  };
  title: BreadcrumbOption[] = [];

  projectId: string;
  project: ProjectWithRelations;
  editedProjectData: any;
  subscriptions: Subscription[];
  displayToolbar = false;
  displayToolbarBackButton = false;
  isLoading = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<any>,
    private dataTransformService: DataTransformService
  ) { }

  ngOnInit(): void {
    /* Assign project id */
    this.projectId = this.getProjectId();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToProjectLoading(),
      this.subscribeToProjects()
    ];
  }

  /**
 * Takes the project id paramater from route
 *
 * @returns
 * @memberof ProjectEditComponent
 */
  getProjectId() {
    return this.activatedRoute.snapshot.params['id'];
  }

  subscribeToProjectLoading() {
    return this.store.select(state => state.adminProjects?.isLoading).subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  subscribeToProjects() {
    return this.store.select(state => state.adminProjects.data).subscribe((projects: ProjectWithRelations[]) => {
      if (this.projectId) {
        this.project = projects?.find(project => project.id === this.projectId);
        if (this.project) {
          this.setTitle();
        }
      }
    });
  }

  setTitle() {
    this.title = [
      {
        label: 'Admin Dashboard',
        redirectUrl: PAGE_DEFINITIONS.ADMIN_DASHBOARD.URL
      },
      {
        label: 'Projects',
        redirectUrl: PATH_DEFINITIONS.ADMIN_DASHBOARD.PROJECTS
      },
      {
        label: 'Project Edit'
      },
      {
        label: this.project?.name
      }
    ];
  }

  /**
 * Fire's when save button clicked
 *
 * @param {ProjectInterface} project
 * @memberof ProjectEditComponent
 */
  editProject(project: Partial<Project>) {
    if (this.projectId) {
      const createdMetadataItems = this.dataTransformService.compareArrays(this.project.metadata, project.metadata).createdArray;
      createdMetadataItems.forEach(createdMetadataItem => {
        delete createdMetadataItem.id;
      });
      const updatedMetadataItems = this.dataTransformService.compareArrays(this.project.metadata, project.metadata).updatedArray;
      const deletedMetadataItems = this.dataTransformService.compareArrays(this.project.metadata, project.metadata).deletedArray;
      delete this.editedProjectData?.metadata;

      if (!!this.editedProjectData || createdMetadataItems.length || updatedMetadataItems.length || deletedMetadataItems.length) {
        this.store.dispatch(UpdateAdminProject({
          project: {
            name: project.name,
            key: project.key,
            description: project.description
          },
          projectId: this.projectId,
          metadataCRUD: {
            newMetadataItems: createdMetadataItems,
            updatedMetadataItems: updatedMetadataItems,
            deletedMetadataIds: deletedMetadataItems?.map((metadataItem: Metadata) => metadataItem.id)
          }
        }));
      }
    }
  }

  onFieldValueChange(data: any) {
    this.editedProjectData = {
      ...this.editedProjectData,
      ...data
    };
  }

}
