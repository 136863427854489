import { KeyValue } from '@angular/common';
import { UIDataEventType } from './ui-data-event-type.enum';

export const UIDataEventSelectOptions: KeyValue<string, UIDataEventType>[] = [
  {
    key: 'Standard',
    value: UIDataEventType.Standard
  },
  {
    key: 'Timer',
    value: UIDataEventType.Timer
  }
];
