import { Validators } from '@angular/forms';
import { CrudFormConfig, CrudViewFormItemType, CrudFormSelectItem, CrudFormTextBoxItem } from '@rappider/rappider-components/utils';
import { PROJECT_MODEL_RELATION_TYPE_OPTIONS } from '@rappider/shared/configs';
import { CAMEL_CASE_REGEX } from '@rappider/shared/definitions';

export const PROJECT_MODEL_RELATION_CREATE_CONFIG: CrudFormConfig = {
  items: [
    {
      index: 0,
      title: 'PROJECT_MODULE.PROJECT_MODEL_RELATION_LIST_COMPONENT.RELATION_NAME',
      fieldName: 'name',
      type: CrudViewFormItemType.TextBox,
      showRequiredSign: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          type: Validators.pattern(CAMEL_CASE_REGEX),
          errorKey: 'pattern',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.MUST_START_WITH_LOWERCASE_LETTER'
        }
      ]
    },
    <CrudFormSelectItem>{
      index: 1,
      title: 'PROJECT_MODULE.PROJECT_MODEL_RELATION_LIST_COMPONENT.TYPE',
      fieldName: 'type',
      type: CrudViewFormItemType.Select,
      showRequiredSign: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ],
      options: PROJECT_MODEL_RELATION_TYPE_OPTIONS
    },
    <CrudFormSelectItem>{
      index: 2,
      title: 'Source Model',
      fieldName: 'sourceModelId',
      type: CrudViewFormItemType.Select,
      showRequiredSign: true,
      disabled: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    <CrudFormSelectItem>{
      index: 5,
      title: 'PROJECT_MODULE.PROJECT_MODEL_RELATION_LIST_COMPONENT.TARGET_MODEL',
      fieldName: 'targetModelId',
      type: CrudViewFormItemType.Select,
      showRequiredSign: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    <CrudFormSelectItem>{
      index: 3,
      title: 'Source Model Field',
      fieldName: 'keyFromId',
      type: CrudViewFormItemType.Select,
      showRequiredSign: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    <CrudFormSelectItem>{
      index: 6,
      title: 'Target Model Field',
      fieldName: 'keyToId',
      type: CrudViewFormItemType.Select,
      showRequiredSign: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    }
  ]
};
