/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { NewprojectModelEndpointQueryParam } from '../models/newproject-model-endpoint-query-param';
import { ProjectModelEndpointQueryParam } from '../models/project-model-endpoint-query-param';
import { ProjectModelEndpointQueryParamPartial } from '../models/project-model-endpoint-query-param-partial';
import { ProjectModelEndpointQueryParamWithRelations } from '../models/project-model-endpoint-query-param-with-relations';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class ProjectModelEndpointQueryParamControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation projectModelEndpointQueryParamControllerCount
   */
  static readonly ProjectModelEndpointQueryParamControllerCountPath = '/project-model-endpoint-query-params/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params?: {
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelEndpointQueryParamControllerService.ProjectModelEndpointQueryParamControllerCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params?: {
    where?: any;
  }): Observable<LoopbackCount> {

    return this.count$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation projectModelEndpointQueryParamControllerFindById
   */
  static readonly ProjectModelEndpointQueryParamControllerFindByIdPath = '/project-model-endpoint-query-params/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<ProjectModelEndpointQueryParamWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelEndpointQueryParamControllerService.ProjectModelEndpointQueryParamControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectModelEndpointQueryParamWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
    filter?: any;
  }): Observable<ProjectModelEndpointQueryParamWithRelations> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectModelEndpointQueryParamWithRelations>) => r.body as ProjectModelEndpointQueryParamWithRelations)
    );
  }

  /**
   * Path part for operation projectModelEndpointQueryParamControllerDeleteById
   */
  static readonly ProjectModelEndpointQueryParamControllerDeleteByIdPath = '/project-model-endpoint-query-params/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelEndpointQueryParamControllerService.ProjectModelEndpointQueryParamControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: string;
  }): Observable<any> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation projectModelEndpointQueryParamControllerUpdateById
   */
  static readonly ProjectModelEndpointQueryParamControllerUpdateByIdPath = '/project-model-endpoint-query-params/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    body?: ProjectModelEndpointQueryParamPartial
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelEndpointQueryParamControllerService.ProjectModelEndpointQueryParamControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    body?: ProjectModelEndpointQueryParamPartial
  }): Observable<any> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation projectModelEndpointQueryParamControllerFind
   */
  static readonly ProjectModelEndpointQueryParamControllerFindPath = '/project-model-endpoint-query-params';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<ProjectModelEndpointQueryParamWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelEndpointQueryParamControllerService.ProjectModelEndpointQueryParamControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectModelEndpointQueryParamWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<ProjectModelEndpointQueryParamWithRelations>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectModelEndpointQueryParamWithRelations>>) => r.body as Array<ProjectModelEndpointQueryParamWithRelations>)
    );
  }

  /**
   * Path part for operation projectModelEndpointQueryParamControllerCreate
   */
  static readonly ProjectModelEndpointQueryParamControllerCreatePath = '/project-model-endpoint-query-params';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    body?: NewprojectModelEndpointQueryParam
  }): Observable<StrictHttpResponse<ProjectModelEndpointQueryParam>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelEndpointQueryParamControllerService.ProjectModelEndpointQueryParamControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectModelEndpointQueryParam>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    body?: NewprojectModelEndpointQueryParam
  }): Observable<ProjectModelEndpointQueryParam> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectModelEndpointQueryParam>) => r.body as ProjectModelEndpointQueryParam)
    );
  }

}
