import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'rappider-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RappiderTableComponent {

  @Input() config: any;
  @Input() data: any[];

  defaultPageSize = 25;


  /* True when, select all is checked, ll items are selected */
  allItemsSelected = false;

  /* True when all items are not selected */
  allItemsUnSelected = false;

  fixedColumn = false;

}
