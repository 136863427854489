import { Injectable } from '@angular/core';
import { CSS_NAMED_COLORS } from '../../definitions/colors';

@Injectable({
  providedIn: 'root'
})
export class ColorService {

  adminDashboardRoleColors: Record<string, string> = {
    'PROJECT_OWNER': '#DAA520',
    'FREE_TIER_PROJECT': '#A9A9A9',
    'PROJECT_DEVELOPER': '#0000FF',
    'ENTERPRISE_TIER_PROJECT': '#000080',
    'ADMIN': '#8B0000',
    'PROJECT_MANAGER': '#D2691E',
    'PROJECT_MEMBER': '#808080',
    'OTHER': null
  };

  projectRoleColors: Record<string, string> = {
    'PROJECT_OWNER': '#DAA520',
    'PROJECT_MANAGER': '#D2691E',
    'PROJECT_MEMBER': '#808080',
    'OTHER': null
  };

  constructor() { }

  /* returns a random color with 20% alpha */
  getRandomColor(asHex: boolean = true): string {
    const scale = 4; // colors on 4 scale 0f 0 - 255
    const colorScale = 256 / scale;
    const red = Math.round(this.getRndInteger(0, scale + 1) * colorScale);
    const green = Math.round(this.getRndInteger(0, scale + 1) * colorScale);
    const blue = Math.round(this.getRndInteger(0, scale + 1) * colorScale);
    const alpha = 0.2;
    const rgbaColorAsString = `rgba(${red}, ${green}, ${blue}, ${alpha})`;
    if (!asHex) {
      return this.convertRGBAToHexA(rgbaColorAsString);
    } else {
      return rgbaColorAsString;
    }
  }

  getRandomColorFromNamedColors() {
    const randomColor = CSS_NAMED_COLORS[Math.floor(Math.random() * CSS_NAMED_COLORS.length)];
    return randomColor;
  }

  getScaledColors(): Array<{ color: string; title: string }> {
    const colors: Array<{ color: string; title: string }> = [];
    const scale = 3; // colors on 4 scale 0f 0 - 255
    for (let red = 0; red < scale + 1; red++) {

      for (let green = 0; green < scale + 1; green++) {

        for (let blue = 0; blue < scale + 1; blue++) {

          const colorScale = Math.round(256 / scale);
          const redScale = red * colorScale;
          const greenScale = green * colorScale;
          const blueScale = blue * colorScale;
          const alpha = 0.4;
          colors.push(
            {
              title: `Red: ${redScale}, Green: ${greenScale}, Blue: ${blueScale}, Alpha: ${alpha}`,
              color: `rgba(${redScale}, ${greenScale}, ${blueScale}, ${alpha})`
            }
          );
        }
      }
    }
    console.log(colors);
    return colors;

  }

  getRndInteger(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  convertRGBAToHexA(rgba: string): string {
    const hex = rgba.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
    const r = parseInt(hex[0], 10).toString(16);
    const g = parseInt(hex[1], 10).toString(16);
    const b = parseInt(hex[2], 10).toString(16);
    const a = Math.round(parseFloat(hex[3]) * 255).toString(16);
    return `#${r}${g}${b}${a}`;
  }

  assignRandomColorsToRoles(roles: string[]): Record<string, string> {
    // Create a Set to hold unique roles
    const uniqueRoles = new Set(roles);
    // Define a function to generate random colors
    const getRandomColor = () => {
      // Generate random color components
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);
      // Return the color in hexadecimal format
      return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
    };
    // Create an object to hold the role-color mappings
    const roleColorMappings: Record<string, string> = {};
    // Assign a random color to each unique role
    uniqueRoles.forEach(role => {
      roleColorMappings[role] = getRandomColor();
    });
    return roleColorMappings;
  }

  assignColorsToRolesForAdminDashboard(roles: string[]): Record<string, string> {
    // Create an object to hold the role-color mappings
    const roleColorMappings: Record<string, string> = {};
    // Assign a color from the predefined palette to each role
    roles.forEach(role => {
      roleColorMappings[role] = this.adminDashboardRoleColors[role] || this.adminDashboardRoleColors['OTHER'];
    });
    return roleColorMappings;
  }

  assignColorsToRoles(roles: string[]): Record<string, string> {
    const roleColorMappings: Record<string, string> = {};
    roles.forEach(role => {
      roleColorMappings[role] = this.projectRoleColors[role] || this.projectRoleColors['OTHER'];
    });
    return roleColorMappings;
  }

  getColorForRole(role: string): string {
    return this.projectRoleColors[role] || this.projectRoleColors['OTHER'];
  }

}
