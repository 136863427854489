import {
  ActionBehavior,
  CrudTableViewConfig,
  CrudViewColumnType,
  MenuActionBehavior,
  MenuConfig,
  MenuMode,
  ButtonType,
  IconType,
  HorizontalPosition,
  DropdownMenuComponentConfig,
  CrudFormSelectItem,
  CrudViewFormItemType,
  SelectMode,
  InputSize,
  CrudFormTextBoxItem,
  TextBoxType,
  CrudFormConfig,
  FormLayout,
  CrudFormInputTemplateItem,
  DropdownMenuLabelMode,
  DropdownMenuPlacement,
  Action
} from '@rappider/rappider-components/utils';
import { ProjectSettingWithRelations } from '@rappider/rappider-sdk';
import { ProjectSettingKey } from '../../definitions/project-setting-key.enum';
import { POP_CONFIRM_DELETE_ACTION, projectSettingsPopConfirmTitle } from '../pop-confirm-button/pop-confirm-button-config';
import { Validators } from '@angular/forms';
import { TYPE_OPTIONS } from 'libs/data-transformation/src/lib/shared';
import { ProjectSettingsDropdownItems } from '../../../../../project/src/lib/components/project-settings/utils/project-settings-dropdown-items.enum';
import { nameExistsValidator } from '../../functions/name-exists-validator';
import { UsernameType } from '../../definitions/username-types';
import { CAMEL_CASE_REGEX } from '../../definitions/regex';

export const editSystemSettingsListAction: Action = {
  text: 'Edit System Settings',
  name: 'settings',
  buttonType: ButtonType.Default,
  icon: {
    name: 'down',
    type: IconType.NgZorro
  },
  behavior: ActionBehavior.DropDownMenu,
  horizontalPosition: HorizontalPosition.Right,
  dropdownConfig: {
    label: 'Edit System Settings',
    placement: DropdownMenuPlacement.BottomLeft,
    items: [
      {
        label: 'Styling',
        visible: true,
        items: [
          {
            label: 'Bootstrap',
            visible: true,
            items: [
              {
                label: 'Add Bootstrap',
                actionBehavior: MenuActionBehavior.Emit,
                data: {
                  key: 'bootstrap',
                  value: true,
                  visible: true
                }
              },
              {
                label: 'Remove Bootstrap',
                actionBehavior: MenuActionBehavior.Emit,
                data: {
                  key: 'bootstrap',
                  value: false,
                  visible: false
                }
              }
            ]
          }
        ]
      },
      {
        label: 'Authentication',
        visible: true,
        items: [
          {
            label: 'Set Username Type',
            visible: true,
            items: [
              {
                label: UsernameType.Email,
                actionBehavior: MenuActionBehavior.Emit,
                data: {
                  key: 'usernameType',
                  value: UsernameType.Email,
                  visible: true
                }
              },
              {
                label: UsernameType.PhoneNumber,
                actionBehavior: MenuActionBehavior.Emit,
                data: {
                  key: 'usernameType',
                  value: UsernameType.PhoneNumber,
                  visible: true
                }
              },
              {
                label: UsernameType.Mixed,
                actionBehavior: MenuActionBehavior.Emit,
                data: {
                  key: 'usernameType',
                  value: UsernameType.Mixed,
                  visible: true
                }
              },
              {
                label: UsernameType.Other,
                actionBehavior: MenuActionBehavior.Emit,
                data: {
                  key: 'usernameType',
                  value: UsernameType.Other,
                  visible: true
                }
              }
            ]
          },
          {
            label: 'Include Authentication',
            visible: true,
            items: [
              {
                label: 'Enable Authentication',
                actionBehavior: MenuActionBehavior.Emit,
                data: {
                  key: 'isAuthenticationEnabled',
                  value: true,
                  visible: true
                }
              },
              {
                label: 'Disable Authentication',
                actionBehavior: MenuActionBehavior.Emit,
                data: {
                  key: 'isAuthenticationEnabled',
                  value: false,
                  visible: true
                }
              }
            ]
          }
        ]
      },
      {
        label: 'Payment Provider',
        visible: true,
        items: [
          {
            label: 'Stripe',
            actionBehavior: MenuActionBehavior.Emit,
            data: {
              key: 'paymentProvider',
              value: 'stripe',
              visible: true
            }
          },
          {
            label: 'Iyzico',
            actionBehavior: MenuActionBehavior.Emit,
            data: {
              key: 'paymentProvider',
              value: 'iyzico',
              visible: true
            }
          },
          {
            label: 'Not Provided',
            actionBehavior: MenuActionBehavior.Emit,
            data: {
              key: 'paymentProvider',
              value: 'not-provided',
              visible: true
            }
          }
        ]
      },
      {
        label: 'Authentication Options',
        visible: true,
        items: [
          {
            label: 'Authenticate With Credentials',
            visible: true,
            items: [
              {
                label: 'True',
                visible: true,
                data: {
                  key: 'authenticateWithCredentials',
                  value: true,
                  visible: true
                }
              },
              {
                label: 'False',
                visible: true,
                data: {
                  key: 'authenticateWithCredentials',
                  value: false,
                  visible: true
                }
              }
            ]
          },
          {
            label: 'Authenticate With Descope',
            visible: true,
            items: [
              {
                label: 'True',
                visible: true,
                data: {
                  key: 'authenticateWithDescope',
                  value: true,
                  visible: true
                }
              },
              {
                label: 'False',
                visible: true,
                data: {
                  key: 'authenticateWithDescope',
                  value: false,
                  visible: true
                }
              }
            ]
          }
        ]
      }
    ]
  }
};

export const addProjectSettingsListAction: Action = {
  text: 'PROJECT_MODULE.PROJECT_SETTINGS_COMPONENT.ADD_PROJECT_SETTING',
  behavior: ActionBehavior.Emit,
  buttonType: ButtonType.Default,
  icon: { name: 'fas fa-plus' }
};

export const PROJECT_SETTINGS_FORM_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormTextBoxItem>{
      size: InputSize.Default,
      type: CrudViewFormItemType.TextBox,
      textType: TextBoxType.Text,
      title: 'SHARED.KEY',
      fieldName: 'key',
      placeholder: 'SHARED.KEY',
      showRequiredSign: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'RAPPIDER_COMPONENTS_MODULE.LOGIN_FORM_COMPONENT.REQUIRED_ERROR_TIP'
        },
        {
          type: nameExistsValidator(['payment', 'bootstrap', 'isAuthenticationEnabled']),
          errorKey: 'nameExists',
          errorMessage: 'This key can only be added from the menu'
        },
        {
          type: Validators.pattern(CAMEL_CASE_REGEX),
          errorKey: 'pattern',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.MUST_START_WITH_LOWERCASE_LETTER'
        }
      ]
    },
    <CrudFormSelectItem>{
      title: 'Type',
      fieldName: 'type',
      type: CrudViewFormItemType.Select,
      settings: {
        mode: SelectMode.Single,
      },
      placeholder: 'Type',
      showRequiredSign: true,
      size: InputSize.Default,
      options: TYPE_OPTIONS,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'RAPPIDER_COMPONENTS_MODULE.LOGIN_FORM_COMPONENT.REQUIRED_ERROR_TIP'
        }
      ]
    },
    <CrudFormInputTemplateItem>{
      title: 'SHARED.VALUE',
      fieldName: 'value',
      type: CrudViewFormItemType.InputTemplate,
      showCodemirrorForObjectAndArray: true,
      isInputOptionsVisible: true,
    },
    {
      title: 'PROJECT_MODULE.PROJECT_SETTINGS_COMPONENT.EXPOSE_TO_FRONTEND',
      fieldName: 'exposeToFrontend',
      type: CrudViewFormItemType.CheckBox
    },
    {
      title: 'PROJECT_MODULE.PROJECT_SETTINGS_COMPONENT.EXPOSE_TO_BACKEND',
      fieldName: 'exposeToBackend',
      type: CrudViewFormItemType.CheckBox
    }
  ],
  submitButton: { visible: false },
  itemSettings: {
    inputComponentSize: {
      xs: 24
    }
  },
  formLayout: FormLayout.Horizontal
};

export const PROJECT_SETTINGS_USER_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'key',
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'SHARED.KEY',
      fieldName: 'key',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.VALUE',
      fieldName: 'value',
      type: CrudViewColumnType.Text
    },
    {
      title: 'PROJECT_MODULE.PROJECT_SETTINGS_COMPONENT.EXPOSE_TO_FRONTEND',
      fieldName: 'exposeToFrontend',
      type: CrudViewColumnType.Boolean
    },
    {
      title: 'PROJECT_MODULE.PROJECT_SETTINGS_COMPONENT.EXPOSE_TO_BACKEND',
      fieldName: 'exposeToBackend',
      type: CrudViewColumnType.Boolean
    }
  ],
  listActions: [
    editSystemSettingsListAction
  ],
  itemActions: [],
  handleVisibilityFn: (data, item: ProjectSettingWithRelations, action) => {
    if (action.name === POP_CONFIRM_DELETE_ACTION.name) {
      return item.isDeletable;
    } else if (action.name === 'EDIT_PROJECT_SETTING') {
      return item.isUpdatable;
    }
  }
};

export const systemSettingsItemActions = {
  itemActions: [
    {
      text: 'SHARED.EDIT',
      name: 'EDIT_PROJECT_SETTING',
      icon: { name: 'far fa-edit' },
      behavior: ActionBehavior.Emit,

    },
    {
      text: POP_CONFIRM_DELETE_ACTION.text,
      name: POP_CONFIRM_DELETE_ACTION.name,
      popconfirmTitle: projectSettingsPopConfirmTitle,
      emitWithoutPopconfirm: POP_CONFIRM_DELETE_ACTION.emitWithoutPopconfirm,
      behavior: POP_CONFIRM_DELETE_ACTION.behavior,
      icon: POP_CONFIRM_DELETE_ACTION.icon,
      popConfirmCancelText: POP_CONFIRM_DELETE_ACTION.popConfirmCancelText,
      popConfirmOkText: POP_CONFIRM_DELETE_ACTION.popConfirmOkText,
      popConfirmOkDanger: POP_CONFIRM_DELETE_ACTION.popConfirmOkDanger,
    }
  ]
};

export const PROJECT_SETTINGS_SYSTEM_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'key',
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'SHARED.KEY',
      fieldName: 'key',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.VALUE',
      fieldName: 'value',
      type: CrudViewColumnType.Text
    }
  ],
  listActions: [],
  itemActions: [],
  handleVisibilityFn: (data, item: ProjectSettingWithRelations, action) => {
    if (action.name === POP_CONFIRM_DELETE_ACTION.name) {
      return item.isDeletable;
    } else if (action.name === 'EDIT_PROJECT_SETTING') {
      return item.isUpdatable;
    }
  }
};

export const PROJECT_SETTINGS_MENU_CONFIG: DropdownMenuComponentConfig = {
  label: 'Project Settings',
  items: [
    {
      label: 'Styling',
      key: 'styling',
      actionBehavior: MenuActionBehavior.NoAction,
      items: [
        {
          label: 'Add Bootstrap',
          key: 'bootstrap',
          actionBehavior: MenuActionBehavior.Emit,
          data: {
            key: 'bootstrap',
            value: true
          }
        }
      ]
    },
    {
      label: 'Payment',
      key: 'payment',
      actionBehavior: MenuActionBehavior.NoAction,
      items: [
        {
          label: 'Stripe',
          key: 'payment-stripe',
          actionBehavior: MenuActionBehavior.Emit,
          data: { key: 'payment', value: 'stripe' }
        },
        {
          label: 'Iyzico',
          key: 'payment-iyzico',
          actionBehavior: MenuActionBehavior.Emit,
          data: { key: 'payment', value: 'iyzico' }
        }
      ]
    },
    {
      label: 'SHARED.AUTHENTICATION',
      key: 'authentication',
      actionBehavior: MenuActionBehavior.NoAction,
      items: [
        {
          label: 'PROJECT_MODULE.PROJECT_SETTINGS_COMPONENT.INCLUDE_AUTHENTICATION',
          key: ProjectSettingKey.IsAuthenticationEnabled,
          actionBehavior: MenuActionBehavior.Emit,
          data: { key: ProjectSettingKey.IsAuthenticationEnabled, value: true }
        }
      ]
    }
  ]
};
