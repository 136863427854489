import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { JoyrideService } from 'ngx-joyride';

@Component({
  selector: 'rappider-sort-wrapper',
  templateUrl: './sort-wrapper.component.html',
  styleUrls: ['./sort-wrapper.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => SortWrapperComponent),
      multi: true
    }
  ]
})
export class SortWrapperComponent implements ControlValueAccessor, OnChanges {

  @Input() tree;
  @Input() currentTree;
  @Input() sortStepsFlag: boolean;

  @Output() operationFieldChange = new EventEmitter();
  @Output() showStepsFlag = new EventEmitter<{ flag: boolean; key: string }>();

  sortStepsOptPlaceFlag = false;
  sortStepsSelectOptFlag = false;

  operationField: string;
  title: string;

  _value;

  constructor(
    private readonly joyrideService: JoyrideService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sortStepsFlag) {
      this.showSteps();
    }
  }

  showSteps() {
    if (this.sortStepsFlag) {
      this.sortStepsOptPlaceFlag = true;
    }
  }

  onShowStepsFlag(value: { flag: boolean; key: string }) {
    if (value.key === 'sort-opt-place') {
      this.sortStepsOptPlaceFlag = value.flag;
      this.sortStepsSelectOptFlag = true;
    }
    if (value.key === 'sort-select-opt') {
      this.sortStepsSelectOptFlag = value.flag;
    }
    if (this.sortStepsFlag && !this.sortStepsOptPlaceFlag && !this.sortStepsSelectOptFlag) {
      this.sortStepsFlag = false;
      this.showStepsFlag.emit({ flag: this.sortStepsFlag, key: 'sort' });
    }
  }

  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
    this.onChange(this._value);
    this.onTouched();
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value): void {
    this._value = value;
    this.setOperatingField();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setOperatingField() {
    this.operationField = this.value?.operationField?.map(item => item.name)?.join('.');
    if (this.operationField) {
      this.title = `You are operating in ${this.operationField}`;
    } else {
      this.title = 'You are operating in root';
    }
  }

  onOperationFieldChange() {
    this.value.operations = [];
    this.operationFieldChange.emit();
  }

}
