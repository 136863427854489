import { createAction, props } from '@ngrx/store';
import { DbDiagramNode, NewDbDiagramNode } from '@rappider/rappider-sdk';

/* action types */
export enum ActionTypes {
  UpdateFormPanelVisibility = '[WfDiagram] UpdateFormPanelVisibility'
}

export const UpdateFormPanelVisibility = createAction(
  ActionTypes.UpdateFormPanelVisibility,
  props<{ payload: { visibility: boolean } }>()
);
