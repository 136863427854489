import {
  ButtonType,
  CrudTableViewConfig,
  CrudViewColumnType,
  ActionBehavior
} from '@rappider/rappider-components/utils';
import { PATH_DEFINITIONS } from '../../definitions/path-definition';

export const PROJECT_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'name',
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'Name',
      fieldName: 'name',
      type: CrudViewColumnType.ActionLink
    },
    {
      title: 'Created By',
      fieldName: 'createdBy',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Created Date',
      fieldName: 'createdDate',
      type: CrudViewColumnType.Date
    }
  ],
  listActions: [
    {
      name: 'PROJECT_MODULE.PROJECT_CREATE_COMPONENT.PROJECT_CREATE',
      text: 'PROJECT_MODULE.PROJECT_CREATE_COMPONENT.PROJECT_CREATE',
      buttonType: ButtonType.Default,
      behavior: ActionBehavior.Route,
      icon: { name: 'fas fa-plus' },
      redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_CREATE_PATH}`
    }
  ],
  itemActions: [
    {
      text: 'SHARED.DELETE',
      name: 'DELETE_PROJECT',
      behavior: ActionBehavior.Emit,
      icon: { name: 'far fa-trash' }
    },
  ]
};
