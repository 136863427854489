import { ProjectSettingWithRelations } from '@rappider/rappider-sdk';
import { cloneDeep } from 'lodash';
import { DEFAULT_PROJECT_SETTING_DATA } from './default-project-setting-data';

export const DEFAULT_PROJECT_SETTINGS_MODAL = {
  title: 'PROJECT_MODULE.PROJECT_SETTINGS_COMPONENT.PROJECT_SETTINGS',
  visible: false,
  isSubmitted: false,
  isValid: false,
  data: <ProjectSettingWithRelations>cloneDeep(DEFAULT_PROJECT_SETTING_DATA)
};
