import { createSelector } from '@ngrx/store';
import { PageWithRelations, ProjectWithRelations } from '@rappider/rappider-sdk';
import { orderBy } from 'lodash';

export const getProjectsWithPages = createSelector(
  state => state['adminProjects']?.data,
  state => state['adminPage']?.data,
  (adminProjects: ProjectWithRelations[],
    adminPages: PageWithRelations[]) => adminProjects?.map(adminProject => (
      {
        ...adminProject,
        pagesInReview: orderBy(adminPages?.filter(adminPage => adminPage.projectId === adminProject.id), 'index', 'asc').map(page => page.name).join(', ')
      }
    ))
);
