import { CrudTableViewConfig, ActionBehavior } from '@rappider/rappider-components/utils';

export const JSON_ARRAY_LIST_GRID_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'title',
  columns: [],
  listActions: [],
  itemActions: [{
    icon: { name: 'far fa-trash' },
    behavior: ActionBehavior.Emit,
    name: 'DELETE',
    text: 'SHARED.DELETE'
  },
  {
    icon: { name: 'far fa-edit' },
    behavior: ActionBehavior.Emit,
    name: 'EDIT',
    text: 'SHARED.EDIT'
  }],
  orderable: true,
  searchable: false,
  columnVisibilitySelectable: false
};
