import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import {
  ActionBehavior,
  CrudTableViewConfig,
  CrudViewColumnType,
} from '@rappider/rappider-components/utils';
import { POP_CONFIRM_DELETE_ACTION } from 'libs/shared/src/lib/configs/pop-confirm-button/pop-confirm-button-config';

/**
 * Used for display all data events
 */
export const DATA_EVENTS_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'name',
  searchable: false,
  columnVisibilitySelectable: false,
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false,
    },
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewColumnType.Link,
      routerLink: `${PATH_DEFINITIONS.PROJECTS.DATA_EVENT_DETAIL}/{{id}}`,
    },
  ],
  itemActions: [
    {
      text: 'SHARED.EDIT',
      name: 'Edit',
      behavior: ActionBehavior.Route,
      redirectUrl: `${PATH_DEFINITIONS.PROJECTS.DATA_EVENT_EDIT}/{{id}}`,
      icon: { name: 'far fa-edit' },
    },
    {
      text: POP_CONFIRM_DELETE_ACTION.text,
      name: POP_CONFIRM_DELETE_ACTION.name,
      popconfirmTitle: POP_CONFIRM_DELETE_ACTION.popconfirmTitle,
      emitWithoutPopconfirm: POP_CONFIRM_DELETE_ACTION.emitWithoutPopconfirm,
      behavior: POP_CONFIRM_DELETE_ACTION.behavior,
      icon: POP_CONFIRM_DELETE_ACTION.icon,
      popConfirmCancelText: POP_CONFIRM_DELETE_ACTION.popConfirmCancelText,
      popConfirmOkText: POP_CONFIRM_DELETE_ACTION.popConfirmOkText,
      popConfirmOkDanger: POP_CONFIRM_DELETE_ACTION.popConfirmOkDanger,
    },
  ],
  handleVisibilityFn: (data, item, action) => {
    if (item.isUpdatable !== false && action.name === 'Edit') {
      return true;
    }
    if (item.isDeletable !== false && action.name === 'DELETE_ITEM') {
      return true;
    }
  },
};

export const DATA_EVENTS_EMPTY_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'name',
  searchable: false,
  columnVisibilitySelectable: false,
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    }
  ],
  itemActions: []
};
