/* eslint-disable no-shadow */
import { Action } from '@ngrx/store';
import {
  DataSchemaEnumData, DataSchemaWithRelations, NewDataSchemaEnumData, DataSchemaField,
  DataSchemaFieldBulkUpdateUiSettingsBodyDto,
  EnumData, ProjectModelWithRelations, ProjectModelField, EnumDataUpdateDto, DataSchema, UiDataStoreWithRelations,
  DataSchemaFieldCreateDto,
  DataSchemaFieldBulkUpdateDto
} from '@rappider/rappider-sdk';
import { LastProcessedAction } from '@rappider/shared/interfaces';

export enum ActionTypes {
  GetDataSchemas = '[DataSchema] GetDataSchemas',
  GetDataSchemasSuccess = '[DataSchema] GetDataSchemasSuccess',
  GetDataSchemasFailure = '[DataSchema] GetDataSchemasFailure',
  GetDataSchemaById = '[DataSchema] GetDataSchemaById',
  GetDataSchemaByIdSuccessful = '[DataSchema] GetDataSchemaByIdSuccessful',
  GetDataSchemaByIdFailure = '[DataSchema] GetDataSchemaByIdFailure',
  GetDataSchemasByUIDataStoreId = '[DataSchema] GetDataSchemasByUIDataStoreId',
  GetDataSchemasByUIDataStoreIdSuccessful = '[DataSchema] GetDataSchemasByUIDataStoreIdSuccessful',
  GetDataSchemasByUIDataStoreIdFailure = '[DataSchema] GetDataSchemasByUIDataStoreIdFailure',
  SetDataSchemas = '[DataSchema] SetDataSchemas',
  SetLoadingState = '[DataSchema] SetLoadingState',
  CreateUserGeneratedDataSchema = '[DataSchema] CreateUserGeneratedDataSchema',
  CreateComponentIOTypeDataSchema = '[DataSchema] CreateComponentIOTypeDataSchema',
  CreateDataSchema = '[DataSchema] CreateDataSchema',
  CreateDataSchemaSuccessful = '[DataSchema] CreateDataSchemaSuccessful',
  DeleteDataSchema = '[DataSchema] DeleteDataSchema',
  DeleteDataSchemaSuccessful = '[DataSchema] DeleteDataSchemaSuccessful',
  UpdateDataSchema = '[DataSchema] UpdateDataSchema',
  UpdateDataSchemaSuccessful = '[DataSchema] UpdateDataSchemaSuccessful',
  BulkUpdateDataSchemaFieldUISettings = '[DataSchema] BulkUpdateDataSchemaFieldUISettings',
  BulkUpdateDataSchemaFieldUISettingsSuccessful = '[DataSchema] BulkUpdateDataSchemaFieldUISettingsSuccessful',
  BulkUpdateDataSchemaFieldUISettingsFailure = '[DataSchema] BulkUpdateDataSchemaFieldUISettingsFailure',
  CreateDataSchemaEnumData = '[DataSchema] CreateDataSchemaEnumData',
  CreateDataSchemaEnumDataSuccessful = '[DataSchema] CreateDataSchemaEnumDataSuccessful',
  UpdateDataSchemaEnumData = '[DataSchema] UpdateDataSchemaEnumData',
  UpdateDataSchemaEnumDataSuccessful = '[DataSchema] UpdateDataSchemaEnumDataSuccessful',
  DeleteDataSchemaEnumData = '[DataSchema] DeleteDataSchemaEnumData',
  RemoveDataSchemaFieldByProjectModelField = '[DataSchema] RemoveDataSchemaFieldByProjectModelField',
  ErrorAction = '[DataSchema] ErrorAction',
  ChangeLastProcessedAction = '[DataSchema] ChangeLastProcessedAction',
  ResetLastProcessedAction = '[DataSchema] ResetLastProcessedAction',
  /* data schema field enum data */
  CreateUIDataSelectorEnumData = '[DataSchema] CreateUIDataSelectorEnumData',
  CreateUIDataSelectorEnumDataSuccessful = '[DataSchema] CreateUIDataSelectorEnumDataSuccessful',
  CreateUIDataSelectorEnumDataFailure = '[DataSchema] CreateUIDataSelectorEnumDataFailure',
  UpdateUIDataSelectorEnumData = '[DataSchema] UpdateUIDataSelectorEnumData',
  UpdateUIDataSelectorEnumDataSuccessful = '[DataSchema] UpdateUIDataSelectorEnumDataSuccessful',
  UpdateUIDataSelectorEnumDataFailure = '[DataSchema] UpdateUIDataSelectorEnumDataFailure',
  GetDataSchemaOfCreatedUIDataStore = '[DataSchema] GetDataSchemaOfCreatedUIDataStore',
  CreateAllDataFields = '[DataSchema] CreateAllDataFields',
  CreateAllDataFieldsSuccessful = '[DataSchema] CreateAllDataFieldsSuccessful',
  CreateAllDataFieldsFailure = '[DataSchema] CreateAllDataFieldsFailure',
  UpdateAllDataFields = '[DataSchema] UpdateAllDataFields',
  UpdateAllDataFieldsSuccessful = '[DataSchema] UpdateAllDataFieldsSuccessful',
  UpdateAllDataFieldsFailure = '[DataSchema] UpdateAllDataFieldsFailure',
  DeleteAllDataFields = '[DataSchema] DeleteAllDataFields',
  DeleteAllDataFieldsSuccessful = '[DataSchema] DeleteAllDataFieldsSuccessful',
  DeleteAllDataFieldsFailure = '[DataSchema] DeleteAllDataFieldsFailure'
}


export class GetDataSchemas implements Action {
  readonly type = ActionTypes.GetDataSchemas;
}

export class GetDataSchemasSuccess implements Action {
  readonly type = ActionTypes.GetDataSchemasSuccess;
}

export class GetDataSchemasFailure implements Action {
  readonly type = ActionTypes.GetDataSchemasFailure;
}

export class GetDataSchemaById implements Action {
  readonly type = ActionTypes.GetDataSchemaById;
  constructor(public payload: { id?: string }) { }
}

export class GetDataSchemaByIdSuccessful implements Action {
  readonly type = ActionTypes.GetDataSchemaByIdSuccessful;
  constructor(public payload: { dataSchema: DataSchema }) { }
}

export class GetDataSchemaByIdFailure implements Action {
  readonly type = ActionTypes.GetDataSchemaByIdFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class GetDataSchemasByUIDataStoreId implements Action {
  readonly type = ActionTypes.GetDataSchemasByUIDataStoreId;
  constructor(public payload: { uiDataStoreId: string; loadingModelId: string }) { }
}

export class GetDataSchemasByUIDataStoreIdSuccessful implements Action {
  readonly type = ActionTypes.GetDataSchemasByUIDataStoreIdSuccessful;
  constructor(public payload: { dataSchemas: DataSchemaWithRelations[] }) { }
}

export class GetDataSchemasByUIDataStoreIdFailure implements Action {
  readonly type = ActionTypes.GetDataSchemasByUIDataStoreIdFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class SetDataSchemas implements Action {
  readonly type = ActionTypes.SetDataSchemas;
  constructor(public payload: { dataSchemas: DataSchemaWithRelations[] }) { }
}

export class SetLoadingState implements Action {
  readonly type = ActionTypes.SetLoadingState;
  constructor(public payload: { loading: boolean }) { }
}

export class CreateUserGeneratedDataSchema implements Action {
  readonly type = ActionTypes.CreateUserGeneratedDataSchema;
  constructor(public payload: {
    dataSchema: DataSchemaWithRelations;
    navigateToDataSchemaList: boolean;
  }) { }
}

export class CreateComponentIOTypeDataSchema implements Action {
  readonly type = ActionTypes.CreateComponentIOTypeDataSchema;
  constructor(public payload: { dataSchema: DataSchemaWithRelations; navigateToDataSchemaList: boolean }) { }
}

export class CreateDataSchema implements Action {
  readonly type = ActionTypes.CreateDataSchema;
  constructor(public payload: { dataSchema: DataSchemaWithRelations; navigateToDataSchemaList: boolean }) { }
}

export class CreateDataSchemaSuccessful implements Action {
  readonly type = ActionTypes.CreateDataSchemaSuccessful;
  constructor(public payload: { createdDataSchema: DataSchemaWithRelations; navigateToDataSchemaList: boolean }) { }
}

export class UpdateDataSchema implements Action {
  readonly type = ActionTypes.UpdateDataSchema;
  constructor(public payload: { id: string; dataSchema: DataSchemaWithRelations; navigateToDataSchemaList: boolean }) { }
}

export class UpdateDataSchemaSuccessful implements Action {
  readonly type = ActionTypes.UpdateDataSchemaSuccessful;
  constructor(public payload: { dataSchema: DataSchemaWithRelations }) { }
}

export class GetDataSchemaOfCreatedUIDataStore implements Action {
  readonly type = ActionTypes.GetDataSchemaOfCreatedUIDataStore;
  constructor(public payload: { uiDataStore: UiDataStoreWithRelations; tryCount: number }) { }
}

export class DeleteDataSchema implements Action {
  readonly type = ActionTypes.DeleteDataSchema;
  constructor(public payload: { dataSchemaId: string }) { }
}

export class DeleteDataSchemaSuccessful implements Action {
  readonly type = ActionTypes.DeleteDataSchemaSuccessful;
  constructor(public payload: { dataSchemaId: string }) { }
}

export class BulkUpdateDataSchemaFieldUISettings implements Action {
  readonly type = ActionTypes.BulkUpdateDataSchemaFieldUISettings;
  constructor(public payload: {
    dataSchemaFieldBulkUpdateData: DataSchemaFieldBulkUpdateUiSettingsBodyDto[];
    generateCrudPagesAfterUpdate: boolean;
    projectModel: ProjectModelWithRelations;
  }) { }
}

export class BulkUpdateDataSchemaFieldUISettingsSuccessful implements Action {
  readonly type = ActionTypes.BulkUpdateDataSchemaFieldUISettingsSuccessful;
  constructor(public payload: {
    dataSchemaFields?: DataSchemaField[];
    generateCrudPagesAfterUpdate: boolean;
    projectModel: ProjectModelWithRelations;
  }) { }
}

export class BulkUpdateDataSchemaFieldUISettingsFailure implements Action {
  readonly type = ActionTypes.BulkUpdateDataSchemaFieldUISettingsFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class CreateDataSchemaEnumData implements Action {
  readonly type = ActionTypes.CreateDataSchemaEnumData;
  constructor(public payload: { parentDataSchemaId: string; enumData: NewDataSchemaEnumData; navigateToDataSchemaList: boolean }) { }
}

export class CreateDataSchemaEnumDataSuccessful implements Action {
  readonly type = ActionTypes.CreateDataSchemaEnumDataSuccessful;
  constructor(public payload: { dataSchemaId: string; createdEnumData: DataSchemaEnumData }) { }
}

export class UpdateDataSchemaEnumData implements Action {
  readonly type = ActionTypes.UpdateDataSchemaEnumData;
  constructor(public payload: { enumDataId: string; updatedEnumData: DataSchemaEnumData }) { }
}

export class UpdateDataSchemaEnumDataSuccessful implements Action {
  readonly type = ActionTypes.UpdateDataSchemaEnumDataSuccessful;
  constructor(public payload: { dataSchemaId: string; updatedEnumData: DataSchemaEnumData }) { }
}

export class DeleteDataSchemaEnumData implements Action {
  readonly type = ActionTypes.DeleteDataSchemaEnumData;
  constructor(public payload: { parentDataSchemaId: string; enumDataId: string; navigateToDataSchemaList: boolean }) { }
}

export class ErrorAction implements Action {
  readonly type = ActionTypes.ErrorAction;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class ChangeLastProcessedAction implements Action {
  readonly type = ActionTypes.ChangeLastProcessedAction;
  constructor(public payload: { lastProcessedAction: LastProcessedAction }) { }
}

export class ResetLastProcessedAction implements Action {
  readonly type = ActionTypes.ResetLastProcessedAction;
}

export class CreateUIDataSelectorEnumData implements Action {
  readonly type = ActionTypes.CreateUIDataSelectorEnumData;
  constructor(public payload: { uiDataSelectorEnumData: EnumData }) { }
}

export class CreateUIDataSelectorEnumDataSuccessful implements Action {
  readonly type = ActionTypes.CreateUIDataSelectorEnumDataSuccessful;
  constructor(public payload: { uiDataSelectorEnumData: EnumData }) { }
}

export class CreateUIDataSelectorEnumDataFailure implements Action {
  readonly type = ActionTypes.CreateUIDataSelectorEnumDataFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class UpdateUIDataSelectorEnumData implements Action {
  readonly type = ActionTypes.UpdateUIDataSelectorEnumData;
  constructor(public payload: { enumDataId: string; uiDataSelectorEnumData: any }) { }
}

export class UpdateUIDataSelectorEnumDataSuccessful implements Action {
  readonly type = ActionTypes.UpdateUIDataSelectorEnumDataSuccessful;
  constructor(public payload: { uiDataSelectorEnumData: EnumData }) { }
}

export class UpdateUIDataSelectorEnumDataFailure implements Action {
  readonly type = ActionTypes.UpdateUIDataSelectorEnumDataFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class RemoveDataSchemaFieldByProjectModelField implements Action {
  readonly type = ActionTypes.RemoveDataSchemaFieldByProjectModelField;
  constructor(public payload: { projectModelField: ProjectModelField }) { }
}

export class CreateAllDataFields implements Action {
  readonly type = ActionTypes.CreateAllDataFields;
  constructor(public payload: { dataSchemaFields: DataSchemaFieldCreateDto[]; parentDataSchemaId: string }) { }
}

export class CreateAllDataFieldsSuccessful implements Action {
  readonly type = ActionTypes.CreateAllDataFieldsSuccessful;
  constructor(public payload: { dataSchemaFields: DataSchemaField[]; parentDataSchemaId: string }) { }
}

export class CreateAllDataFieldsFailure implements Action {
  readonly type = ActionTypes.CreateAllDataFieldsFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class UpdateAllDataFields implements Action {
  readonly type = ActionTypes.UpdateAllDataFields;
  constructor(public payload: { updatedDataSchemaFields: DataSchemaFieldBulkUpdateDto[]; parentDataSchemaId: string }) { }
}

export class UpdateAllDataFieldsSuccessful implements Action {
  readonly type = ActionTypes.UpdateAllDataFieldsSuccessful;
  constructor(public payload: { updatedDataSchemaFields: DataSchemaFieldBulkUpdateDto[]; parentDataSchemaId: string }) { }
}

export class UpdateAllDataFieldsFailure implements Action {
  readonly type = ActionTypes.UpdateAllDataFieldsFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class DeleteAllDataFields implements Action {
  readonly type = ActionTypes.DeleteAllDataFields;
  constructor(public payload: { dataSchemaFieldIds: string[]; parentDataSchemaId: string }) { }
}

export class DeleteAllDataFieldsSuccessful implements Action {
  readonly type = ActionTypes.DeleteAllDataFieldsSuccessful;
  constructor(public payload: { dataSchemaFieldIds: string[]; parentDataSchemaId: string }) { }
}

export class DeleteAllDataFieldsFailure implements Action {
  readonly type = ActionTypes.DeleteAllDataFieldsFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export type Actions =
  | GetDataSchemas
  | GetDataSchemasSuccess
  | GetDataSchemasFailure
  | GetDataSchemaById
  | GetDataSchemaByIdSuccessful
  | GetDataSchemaByIdFailure
  | GetDataSchemasByUIDataStoreId
  | GetDataSchemasByUIDataStoreIdSuccessful
  | GetDataSchemasByUIDataStoreIdFailure
  | SetDataSchemas
  | SetLoadingState
  | CreateUserGeneratedDataSchema
  | CreateComponentIOTypeDataSchema
  | CreateDataSchema
  | CreateDataSchemaSuccessful
  | DeleteDataSchema
  | DeleteDataSchemaSuccessful
  | UpdateDataSchema
  | UpdateDataSchemaSuccessful
  | CreateDataSchemaEnumData
  | CreateDataSchemaEnumDataSuccessful
  | UpdateDataSchemaEnumData
  | UpdateDataSchemaEnumDataSuccessful
  | DeleteDataSchemaEnumData
  | ErrorAction
  | ChangeLastProcessedAction
  | ResetLastProcessedAction
  | BulkUpdateDataSchemaFieldUISettings
  | BulkUpdateDataSchemaFieldUISettingsSuccessful
  | BulkUpdateDataSchemaFieldUISettingsFailure
  | CreateUIDataSelectorEnumData
  | CreateUIDataSelectorEnumDataSuccessful
  | CreateUIDataSelectorEnumDataFailure
  | UpdateUIDataSelectorEnumData
  | UpdateUIDataSelectorEnumDataSuccessful
  | UpdateUIDataSelectorEnumDataFailure
  | RemoveDataSchemaFieldByProjectModelField
  | GetDataSchemaOfCreatedUIDataStore
  | CreateAllDataFields
  | CreateAllDataFieldsSuccessful
  | CreateAllDataFieldsFailure
  | UpdateAllDataFields
  | UpdateAllDataFieldsSuccessful
  | UpdateAllDataFieldsFailure
  | DeleteAllDataFields
  | DeleteAllDataFieldsSuccessful
  | DeleteAllDataFieldsFailure
  ;
