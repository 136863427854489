import { ButtonComponentConfig, ActionBehavior, TooltipPlacement, IconType } from '@rappider/rappider-components/utils';

export const titleBarActionButtonsConfig: ButtonComponentConfig[] = [
  {
    key: 'openInNewTab',
    text: 'Open Live Preview in New Tab',
    icon: { name: 'fa-regular fa-arrow-up-right-from-square' },
    tooltipText: 'Open Live Preview in New Tab',
    behavior: ActionBehavior.Emit,
    tooltipPlacement: TooltipPlacement.Top
  },
  {
    key: 'deploymentLog',
    icon: {
      name: 'menu-unfold',
      type: IconType.NgZorro
    },
    tooltipText: 'Deployment Log',
    behavior: ActionBehavior.Emit,
    tooltipPlacement: TooltipPlacement.Top
  }
];
