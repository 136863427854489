import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, catchError, tap, map, withLatestFrom, switchMap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { BrowserStorageManagementService } from '@rappider/services';
import { GetPersonProjectRoles } from 'libs/authentication/src/lib/state/authentication.actions';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { ProjectControllerService, ProjectFolderControllerService, ProjectFolderWithRelations, ProjectWithRelations } from '@rappider/rappider-sdk';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';

import * as AppActions from 'apps/rappider/src/app/state/app.actions';
import * as ActiveProjectActions from './active-project.actions';
import * as ModuleActions from 'libs/module/src/lib/state/module.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import * as UIDataStoreActions from 'libs/project/src/lib/states/ui-data-store/ui-data-store.actions';
import * as UIDataEventActios from 'libs/project/src/lib/states/ui-data-event-state/ui-data-event.actions';
import * as PersonAuthorityActions from 'libs/shared/src/lib/states/person-authority/person-authority.actions';
import * as CommentActions from 'libs/comment/src/lib/state/comment/comment.actions';
import * as PageCommentActions from 'libs/comment/src/lib/state/page-comment/page-comment.actions';
import * as ProjectRequirementActions from 'libs/project/src/lib/states/project-requirements/project-requirements.actions';
import * as LayoutActions from 'libs/pages/src/lib/state/layout-state/layout.actions';
import * as ProjectSettingActions from '../project-settings-state/project-setting.actions';
import * as EnvironmentVariableActions from '../environment-variables-state/environment-variable.actions';
import * as MessageTemplateActions from 'libs/project/src/lib/states/message-template-state/message-template.actions';
import * as WorkflowStepFunctionActions from 'libs/project/src/lib/states/workflow-step-function/workflow-step-function.actions';
import * as AIAssistantActions from 'libs/ai-integration/src/lib/state/ai-assistant.actions';
import * as ProcessInfoActions from 'libs/shared/src/lib/states/process-info/process-info.actions';
import { GetOpenAPIsWithRelations } from '../openapi-state/openapi.actions';
import { GetPages } from '@rappider/pages';
import { GetCategories } from 'libs/shared/src/lib/states/category/category.actions';
import { LoadComponentDefinitions } from 'libs/component-definition/src/lib/state/component-definition.actions';
import { GetProjectThemes } from '../project-theme-state/project-theme.actions';
import { GetProjectScripts } from '../project-script-state/project-script.actions';
import { GetProjectPackages } from '../project-package-state/project-package.actions';
import { UserRoles } from 'libs/shared/src/lib/guards/admin-guard/admin-dashboard-roles.enum';
import { GetLayouts } from 'libs/pages/src/lib/state/layout-state/layout.actions';
import { GetUIDataSelectors } from 'libs/ui-data-selector/src/lib/state/ui-data-selector.actions';
import { GetPageVariables } from 'libs/content-editor/src/lib/state/page-variables-state/page-variables.actions';
import { of } from 'rxjs';

@Injectable()
export class ActiveProjectEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private projectApi: ProjectControllerService,
    private browserStorageManagementService: BrowserStorageManagementService,
    private projectFolderApi: ProjectFolderControllerService
  ) { }


  getActiveProject$ = createEffect(() => this.actions$.pipe(
    ofType<ActiveProjectActions.GetActiveProject>(
      ActiveProjectActions.ActionTypes.GetActiveProject
    ),
    withLatestFrom(
      this.store.select(state => state.auth.user?.roleMapping?.role)
    ),
    mergeMap(([action, role]) => {
      const isAdmin = role?.name === UserRoles.Admin;
      if (isAdmin) {
        const filter = {
          where: {
            id: action.payload.projectId
          },
          include: ['people', 'tenant']
        };
        return this.projectApi.findByAdmin({ filter }).pipe(
          map((projects: ProjectWithRelations[]) => new AuthenticationActions.ChangeActiveProject(
            {
              project: projects?.find(project => project?.id === action.payload?.projectId),
              navigateToProjectDetail: action.payload.navigateToProjectDetail,
              navigateAIAssistantPage: action.payload.navigateAIAssistantPage,
              isAdmin: isAdmin
            }
          )),
          catchError(error => [
            new ActiveProjectActions.ActiveProjectError({
              errorOn: ActiveProjectActions.ActionTypes.GetActiveProject,
              error: error
            })
          ])
        );
      } else {
        const params = {
          id: action.payload.projectId,
          filter: {
            include: ['people', 'tenant']
          }
        };
        return this.projectApi.findById(params).pipe(
          map((project: ProjectWithRelations) => new AuthenticationActions.ChangeActiveProject(
            {
              project: project,
              navigateToProjectDetail: action.payload.navigateToProjectDetail,
              navigateAIAssistantPage: action.payload.navigateAIAssistantPage
            }
          )),
          catchError(error => [
            new ActiveProjectActions.ActiveProjectError({
              errorOn: ActiveProjectActions.ActionTypes.GetActiveProject,
              error: error
            })
          ])
        );
      }

    })
  ));


  changeActiveProjectVerified$ = createEffect(() => this.actions$.pipe(
    ofType<ActiveProjectActions.ChangeActiveProjectVerified>(
      ActiveProjectActions.ActionTypes.ChangeActiveProjectVerified
    ),
    mergeMap(action => {
      const actions: Action[] = [
        new ActiveProjectActions.SetActiveProject({
          project: action.payload.project
        }),
        new ActiveProjectActions.GetRootFolder(),
        new AppActions.GetRightSidebarTabName(),
        new AppActions.GetRightSidebarVisibility(),
        ModuleActions.GetModules(),
        new LayoutActions.GetLayouts(),
        new GetPersonProjectRoles(),
        PersonAuthorityActions.GetPersonAuthorities(),
        new UIDataStoreActions.GetUIDataStores(),
        new UIDataEventActios.GetUIDataEvents(),
        ProjectRequirementActions.GetProjectRequirements(),
        CommentActions.GetComments(),
        PageCommentActions.GetPageComments(),
        new ProjectSettingActions.LoadModule(),
        new EnvironmentVariableActions.LoadModule(),
        GetOpenAPIsWithRelations(),
        GetCategories(),
        new GetPages(),
        new LoadComponentDefinitions(),
        new GetProjectThemes(),
        new GetProjectScripts(),
        new MessageTemplateActions.LoadModule(),
        new GetProjectPackages(),
        WorkflowStepFunctionActions.GetWorkflowStepFunction(),
        AIAssistantActions.GetChatSessionByActiveProject(),
        ProcessInfoActions.GetProcessInfos(),
        new GetLayouts(),
        GetUIDataSelectors(),
        GetPageVariables()
      ];

      if (action.payload.navigateToAIAssistantPage) {
        actions.push(
          new Navigate({ url: PATH_DEFINITIONS.AI.ASSISTANT }),
        );
      } else if (action.payload.navigateToProjectDetail) {
        actions.push(
          new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${action.payload.project.id}` })
        );
      }
      return actions;
    })
  ));


  $setActiveProject = createEffect(() => this.actions$.pipe(
    ofType<ActiveProjectActions.SetActiveProject>(
      ActiveProjectActions.ActionTypes.SetActiveProject
    ),
    tap((action) => {
      const activeProject = action.payload.project;
      if (activeProject) {
        /* set active project id in local storage */
        this.browserStorageManagementService.setLocalStorageItem('activeProjectId', activeProject.id);
      } else {
        /* remove active project id from local storage */
        this.browserStorageManagementService.removeLocalStorageItem('activeProjectId');
      }
    })
  ), { dispatch: false });


  $getRootFolder = createEffect(() => this.actions$.pipe(
    ofType<ActiveProjectActions.GetRootFolder>(
      ActiveProjectActions.ActionTypes.GetRootFolder
    ),
    switchMap((action) => {
      const params = {
        filter: {
          include: [
            {
              relation: 'subFolders'
            },
            {
              relation: 'files'
            }
          ]
        }
      };
      return this.projectFolderApi.getRootFolder(params).pipe(
        map((rootFolder: ProjectFolderWithRelations) =>
          new ActiveProjectActions.SetRootFolder({
            rootFolder: rootFolder
          })
        ),
        catchError(error =>
          of(new ActiveProjectActions.ActiveProjectError({
            errorOn: ActiveProjectActions.ActionTypes.GetRootFolder,
            error: error
          }))
        )
      );
    })
  ));

}

