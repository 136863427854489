import {
  CrudTableViewConfig,
  CrudViewColumnType
} from '@rappider/rappider-components/utils';

export const UI_DATA_STORE_DETAIL_LIST_CREATE_CONFIG: CrudTableViewConfig = {
  columns: [
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewColumnType.Text,
    },
    {
      title: 'isArray',
      fieldName: 'isArray',
      type: CrudViewColumnType.Text,
    },
    {
      title: 'SHARED.INDEX',
      fieldName: 'index',
      type: CrudViewColumnType.Text,
    },
    {
      title: 'SHARED.DEFAULT',
      fieldName: 'default',
      type: CrudViewColumnType.Text,
    },
    {
      title: 'SHARED.DESCRIPTION',
      fieldName: 'description',
      type: CrudViewColumnType.Text,
    },
  ]
};


export const UI_DATA_STORE_DETAIL_LIST_UPDATE_CONFIG: CrudTableViewConfig = {
  columns: [
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewColumnType.Text,
    }
  ]
};
