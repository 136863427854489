import { StepFunctionTypeOptions } from 'libs/shared/src/lib/interfaces/step-function-endpoint-or-custom-options.enum';

export const StepFunctionTypeRadioGroupOptions = [
  {
    key: 'Endpoint',
    value: StepFunctionTypeOptions.Endpoint
  },
  {
    key: 'Custom Code',
    value: StepFunctionTypeOptions.CustomCode
  },
  {
    key: 'Rappider Workflow Service',
    value: StepFunctionTypeOptions.WorkflowServices
  },
  {
    key: 'Server-side Workflow Step Function',
    value: StepFunctionTypeOptions.WorkflowStepFunctions
  },
  {
    key: 'Server-side Workflow Event',
    value: StepFunctionTypeOptions.WorkflowEvents
  }
];
