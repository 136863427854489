<div class="image-timer">
   <div *ngIf="imageTitle"
        class="title">
      <rappider-heading [type]="imageTitle.type"
                        [content]="imageTitle.content"></rappider-heading>
   </div>
   <div *ngIf="additionalContent"
        class="content">
      <rappider-text [content]="additionalContent.content"
                     [textMode]="additionalContent.textMode"
                     [text]="additionalContent.text"
                     [typography]="additionalContent.typography"
                     [colorSettings]="additionalContent.colorSettings"></rappider-text>
   </div>
   <div *ngIf="image"
        class="image">
      <rappider-image [source]="image?.source"
                      [width]="image?.width"
                      [placeholder]="image?.placeholder"
                      [height]="image?.height"
                      [fallback]="image?.fallback"
                      [alternateText]="image?.alternateText"
                      [borderSettings]="image?.borderSettings"
                      [customSizeSettings]="image?.width"
                      [shadowSettings]="image?.shadowSettings"
                      [marginSettings]="image?.marginSettings"
                      [paddingSettings]="image?.paddingSettings"></rappider-image>
   </div>
   <div *ngIf="countdown"
        class="countdown">
      <rappider-countdown [deadline]="countdown.deadline"
                          [title]="countdown.title"
                          [format]="countdown.format"
                          [prefix]="countdown.prefix"
                          (click)="onCountdownFinish(countdown)"></rappider-countdown>

   </div>
</div>
