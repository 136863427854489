import { createAction, props } from '@ngrx/store';

/* action types */
export enum ActionTypes {
  GlobalErrorAction = '[GlobalError] GlobalErrorAction'
}

export const GlobalErrorAction = createAction(
  ActionTypes.GlobalErrorAction,
  props<{ payload: { errorHeader?: any; errorText: any; key: string; timeStamp: number } }>()
);
