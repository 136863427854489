import { createReducer, on } from '@ngrx/store';
import { ProjectRequirementWithRelations } from '@rappider/rappider-sdk';

/* actions */
import * as ProjectRequirementsAction from './project-requirements.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

/* state key */
export const featureKey = 'projectRequirements';

/* state interface */
export interface State {
  data: ProjectRequirementWithRelations[];
  projectRequirementsVisibility: boolean;
  loading: boolean;
}

/* initial values */
export const initialState: State = {
  data: null,
  projectRequirementsVisibility: false,
  loading: false
};


export const reducer = createReducer(
  initialState,

  on(ProjectRequirementsAction.GetProjectRequirements, (state) => ({
    ...state,
    loading: true
  })),

  on(ProjectRequirementsAction.GetProjectRequirementsSuccessful, (state, action) => ({
    ...state,
    data: action.payload.projectRequirements,
    loading: false
  })),

  on(ProjectRequirementsAction.CreateProjectRequirement, (state, action) => ({
    ...state,
    loading: true
  })),

  on(ProjectRequirementsAction.CreateProjectRequirementSuccessful, (state, action) => ({
    ...state,
    data: [
      ...state.data,
      action.payload.projectRequirement
    ],
    loading: false
  })),

  on(ProjectRequirementsAction.ToggleProjectRequirementsVisibility, (state) => ({
    ...state,
    projectRequirementsVisibility: !state.projectRequirementsVisibility
  })),

  on(ProjectRequirementsAction.ResetStateToInitial, () => initialState),

  on(ProjectRequirementsAction.ResetStateToInitialChangeProject, () => initialState)

);
