export interface JsonSchema {
	$schema: string;
	type: string;
	items: Items;
	definitions: any;
};

export interface Items {
	$ref: string;
}
