/* angular */
import { createAction, props } from '@ngrx/store';
import { Module, ModulePartial, NewModule } from '@rappider/rappider-sdk';

/* action types */
export enum ActionTypes {
  GetModules = '[Module] GetModules',
  GetModulesSuccessful = '[Module] GetModulesSuccessful',
  GetModulesFailure = '[Module] GetModulesFailure',
  GetModuleById = '[Module] GetModuleById',
  GetModuleByIdSuccessful = '[Module] GetModuleByIdSuccessful',
  GetModuleByIdFailure = '[Module] GetModuleByIdFailure',
  CreateModule = '[Module] CreateModule',
  CreateModuleSuccessful = '[Module] CreateModuleSuccessful',
  CreateModuleFailure = '[Module] CreateModuleFailure',
  UpdateModule = '[Module] UpdateModule',
  UpdateModuleSuccessful = '[Module] UpdateModuleSuccessful',
  UpdateModuleFailure = '[Module] UpdateModuleFailure',
  DeleteModule = '[Module] DeleteModule',
  ErrorAction = '[Module] ErrorAction',
  ResetStateToInitial = '[Module] ResetStateToInitial',
  ResetStateToInitialChangeProject = '[Module] ResetStateToInitialChangeProject'
}

/* actions */

export const GetModules = createAction(ActionTypes.GetModules);

export const GetModulesSuccessful = createAction(
  ActionTypes.GetModulesSuccessful,
  props<{ payload: { modules: any[] } }>()
);

export const GetModulesFailure = createAction(ActionTypes.GetModulesFailure);

export const CreateModule = createAction(
  ActionTypes.CreateModule,
  props<{ payload: { module: NewModule } }>()
);

export const CreateModuleSuccessful = createAction(
  ActionTypes.CreateModuleSuccessful,
  props<{ payload: { module: Module } }>()
);

export const CreateModuleFailure = createAction(ActionTypes.CreateModuleFailure);

export const UpdateModule = createAction(
  ActionTypes.UpdateModule,
  props<{ payload: { moduleId: string; module: ModulePartial } }>()
);

export const UpdateModuleSuccessful = createAction(
  ActionTypes.UpdateModuleSuccessful,
  props<{ payload: { moduleId: string; module: ModulePartial } }>()
);

export const UpdateModuleFailure = createAction(ActionTypes.UpdateModuleFailure);

export const DeleteModule = createAction(
  ActionTypes.DeleteModule,
  props<{ payload: { moduleId: string } }>()
);

export const GetModuleById = createAction(
  ActionTypes.GetModuleById,
  props<{ payload: { moduleId: string } }>()
);

export const GetModuleByIdSuccessful = createAction(
  ActionTypes.GetModuleByIdSuccessful,
  props<{ payload: { module: Module } }>()
);

export const GetModuleByIdFailure = createAction(
  ActionTypes.GetModuleByIdFailure,
  props<{ payload: { error: any; key: string; timeStamp: number } }>()
);

export const ResetStateToInitial = createAction(ActionTypes.ResetStateToInitial);

export const ResetStateToInitialChangeProject = createAction(ActionTypes.ResetStateToInitialChangeProject);

export const ErrorAction = createAction(
  ActionTypes.ErrorAction,
  props<{ payload: { error: any; key: string; timeStamp: number } }>()
);
