import { ButtonType, ActionBehavior, Action } from '@rappider/rappider-components/utils';
import { POP_CONFIRM_DELETE_ACTION } from 'libs/shared/src/lib/configs/pop-confirm-button/pop-confirm-button-config';

export const TEST_USER_EDIT_BUTTON_CONFIG: Action = {
  text: 'Edit',
  name: 'edit',
  behavior: ActionBehavior.Emit,
  buttonType: ButtonType.Default,
  icon: { name: 'far fa-edit' }
};

export const TEST_USER_ADD_BUTTON_CONFIG: Action = {
  text: 'Add Test User ',
  name: 'addTestUser',
  behavior: ActionBehavior.Emit,
  buttonType: ButtonType.Default,
  icon: { name: 'fas fa-plus' },
};

export const TEST_USER_DELETE_BUTTON_CONFIG: Action = {
      text: POP_CONFIRM_DELETE_ACTION.text,
      name: POP_CONFIRM_DELETE_ACTION.name,
      popconfirmTitle: POP_CONFIRM_DELETE_ACTION.popconfirmTitle,
      emitWithoutPopconfirm: POP_CONFIRM_DELETE_ACTION.emitWithoutPopconfirm,
      behavior: POP_CONFIRM_DELETE_ACTION.behavior,
      icon: POP_CONFIRM_DELETE_ACTION.icon,
      popConfirmCancelText: POP_CONFIRM_DELETE_ACTION.popConfirmCancelText,
      popConfirmOkText: POP_CONFIRM_DELETE_ACTION.popConfirmOkText,
      popConfirmOkDanger: POP_CONFIRM_DELETE_ACTION.popConfirmOkDanger,
};
