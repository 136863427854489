<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>

<!-- ----------------------------------------------------------------------------------------- -->
<!-- TABSET SECTION FOR "DEV" | "QA" | "PROD" -->
<section>
  <nz-tabset nzType="card"
             nzAnimated="true"
             nzTabPosition="top"
             nzSize="default"
             [nzSelectedIndex]="selectedIndex"
             class="card-container">
    <nz-tab *ngFor="let environments of environments"
            [nzTitle]="environments"
            (nzSelect)="setActiveEnvironment(environments)">
      <rappider-list-grid [loading]="loading"
                          [config]="ENVIRONMENT_VARIABLES_CONFIG"
                          [data]="displayedEnvironmentVariables"
                          (columnActionClick)="onColumnActionClick($event)"
                          (listActionClick)="openCreateEnvironmentVariableModal(environments)">
      </rappider-list-grid>
    </nz-tab>
  </nz-tabset>
</section>
<rappider-modal [(visible)]="environmentVariableModal.visible"
                [title]="environmentVariableModal.title | translate"
                [okText]="'SHARED.SAVE' | translate"
                [cancelText]="'SHARED.CANCEL' | translate"
                [fullScreen]="true"
                (okClick)="onEnvironmentVariableModalSubmit()"
                (cancelClick)="closeEnvironmentVariableModal()">
  <rappider-crud-view-edit-form *ngIf="environmentVariableModal.visible"
                                [config]="CREATE_OR_EDIT_ENVIRONMENT_VARIABLE_FORM_CONFIG"
                                [submitted]="environmentVariableModal.isSubmitted"
                                [data]="environmentVariableModal.data"
                                (validityChange)="onEnvironmentModalDataValidityChange($event)"
                                (formValueChange)="onEnvironmentVariableModalDataChange($event)">
  </rappider-crud-view-edit-form>
  <div *ngIf="!isExposeValid"
       class="expose-validation-alert-wrapper">
    <rappider-alert [type]="environmentVariablesFormExposeFieldAlertConfig.type"
                    [title]="environmentVariablesFormExposeFieldAlertConfig.title"
                    [description]="environmentVariablesFormExposeFieldAlertConfig.description"
                    [closeable]="environmentVariablesFormExposeFieldAlertConfig.closeable"
                    [showIcon]="environmentVariablesFormExposeFieldAlertConfig.showIcon"></rappider-alert>
  </div>
  <section class="create-environment-variable-info-area">
    *{{'PROJECT_MODULE.PROJECT_ENVIRONMENT_VARIABLES_COMPONENT.CREATE_ENVIRONMENT_VARIABLE_INFO' | translate}}
  </section>
</rappider-modal>