export interface DataMapping {
    function?: RappiderFunction;
    targetField?: string[];
}

export interface RappiderFunction {
    name?: string;
    parameters?: RappiderFunctionParameters[];
    customFunction?: RappiderCustomFunction;
    isCustom?: boolean;
    code?: string;
}

export interface RappiderFunctionParameters {
    /* whether the type is source field or custom */
    type?: RappiderFunctionParameterType;
    /* source field value */
    value?: string[] | string;
    /* source field value type. ex. string */
    valueType?: string;
    /* source field value format. ex. date-time */
    valueFormat?: string;
}

export enum RappiderFunctionParameterType {
    SourceField = 'field',
    Variable = 'variable'
}

export interface RappiderCustomFunction {
    name?: string;
    code?: string;
}
