import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store, createSelector } from '@ngrx/store';
import {
  BreadcrumbOption, ButtonComponentConfig, ButtonSize, FormLayout,
  HeadingComponentConfig, IconComponentConfig, SelectableOption, SelectMode
} from '@rappider/rappider-components/utils';
import { DataTransformService, StringUtilityService, SubscribeToUiDataStoreService } from '@rappider/services';
import {
  CAMEL_CASE_REGEX,
  defaultToolbarTitleHeadingSize,
  FRONTEND_FRAMEWORK_TYPES,
  PATH_DEFINITIONS,
  QUERY_PARAM_DEFINITIONS,
  UIWorkflowItemIntegrationOperatorTypes,
  UIWorkflowItemTypes,
  UIWorkflowItemTypeValues,
} from '@rappider/shared/definitions';
import { FormService } from 'libs/components/src/lib/services';
import { SelectComponentConfig } from 'libs/components/src/lib/utils/select/select-component-config.interface';
import {
  DataSchemaField,
  ProjectModelEndpointWithRelations,
  ProjectModelWithRelations,
  ProjectWithRelations,
  UiDataEventWithRelations,
  UiDataStoreWithRelations,
  UiWorkflowStepFunctionWithRelations,
  WorkflowEvent,
  WorkflowStepFunctionWithRelations
} from 'libs/rappider-sdk/src/lib/models';
import { Subscription } from 'rxjs';
import { UIStepFunctionCreateEditFormItem } from '../../models/ui-step-function-create-edit-form-item.enum';
import { StepFunctionTypeOptions } from '@rappider/shared/interfaces';
import { cloneDeep } from 'lodash';
import { UpdateUIWorkflowStepFunction } from 'libs/project/src/lib/states/ui-step-functions/ui-step-function.actions';
import { uiStepFunctionEditData } from 'libs/project/src/lib/states/ui-step-functions/selectors/ui-step-function-edit-data.selector';
import { FormItemsInfoMessages } from '../../utils/definitions/form-items-info-messages.config';
import { StepFunctionTypeRadioGroupOptions } from '../../utils/definitions/step-function-type-options.config';
import { WorkflowStepFunctionControllerService } from '@rappider/rappider-sdk';
import { getWorkflowStepFunctionsAndTemplates } from '../utils/get-workflow-step-functions-and-templates-selector';

@Component({
  selector: 'rappider-ui-step-function-edit',
  templateUrl: './ui-step-function-edit.component.html',
  styleUrls: ['./ui-step-function-edit.component.scss']
})
export class UIStepFunctionEditComponent implements OnInit, OnDestroy, OnChanges {
  @Input() uiStepFunctionId: string;
  @Input() isSubmitButtonFloat: boolean;
  @Input() addDataEventButtonVisibility: boolean;

  /* layout of the form 'horizontal' or 'vertical' */
  @Input() formLayout: FormLayout = FormLayout.Horizontal;
  /* flag for whether to navigate after ui step function update */
  @Input() navigateAfterUpdate = true;
  /* flag to display breadcrumb under title */
  @Input() displayBreadcrumb = true;

  @Output() addDataEventToUiStepFunction = new EventEmitter();

  /* form group */
  editUIStepFunctionForm: FormGroup;
  /* project model options */
  projectModelOptions: SelectableOption[];
  /* endpoint options by selected project model */
  endpointOptions: SelectableOption[];
  /* services options by selected project model */
  serviceOptions: SelectableOption[];
  /* services options by selected project model */
  workflowStepFunctionOptions: SelectableOption[];
  /* services options by selected project model */
  serverSideWorkflowEventsOptions: SelectableOption[];
  /* subscribed events select component config */
  subscribedEventsSelectConfig: SelectComponentConfig = {
    options: [],
    settings: {
      mode: SelectMode.Multiple
    }
  };
  /* published events on success select component config */
  publishedEventsOnSuccessSelectConfig: SelectComponentConfig = {
    options: [],
    settings: {
      mode: SelectMode.Single
    }
  };
  /* published events on failure select component config */
  publishedEventsOnFailureSelectConfig: SelectComponentConfig = {
    options: [],
    settings: {
      mode: SelectMode.Multiple
    }
  };

  uIWorkflowItemTypeValues = UIWorkflowItemTypeValues;
  StepFunctionTypeOptions = StepFunctionTypeOptions;
  FormItemsInfoMessages = FormItemsInfoMessages;
  uiWorkflowStepFunctionModeRadioGroupOptions = StepFunctionTypeRadioGroupOptions;
  uiWorkflowStepFunctionModeRadioGroupValue: StepFunctionTypeOptions;
  workflowStepFunctions: WorkflowStepFunctionWithRelations[];
  templateWorkflowStepFunctions: WorkflowStepFunctionWithRelations[];
  workflowEvents: WorkflowEvent[];

  monacoEditorSettings = {
    editorOptions: {
      theme: 'vs-dark',
      language: 'javascript'
    },
    lineNumbers: true,
    autoCloseBrackets: true
  };

  addDataEventButtonConfig: ButtonComponentConfig = {
    text: 'Add Data Event to UI Step Function',
    size: ButtonSize.Default,
    icon: {
      name: 'fa-solid fa-plus'
    },
    block: true
  };

  infoIconConfig: IconComponentConfig = {
    name: 'fa-regular fa-info-circle',
    color: 'var(--primary-color)'
  };

  /* main title */
  mainTitle: HeadingComponentConfig = {
    content: 'PROJECT_MODULE.UI_STEP_FUNCTION_CREATE_OR_EDIT_COMPONENT.UPDATE_UI_STEP_FUNCTION',
    type: defaultToolbarTitleHeadingSize
  };

  /* page title */
  title: BreadcrumbOption[];
  /* active project */
  activeProject: ProjectWithRelations;
  /* subscriptions */
  subscriptions: Subscription[];
  /* ui step function type options */
  eventToServiceOperatorsOptions: KeyValue<string, string>[];
  /* options for subscribed and published events */
  uiDataEventOptions: KeyValue<string, string>[];
  /* ui workflow step function */
  uiStepFunction: Record<string, any>;
  /* project models */
  projectModels: ProjectModelWithRelations[];
  /* project model endpoints */
  projectModelEndpoints: ProjectModelEndpointWithRelations[];
  /* ui data sore */
  uiDataStores: UiDataStoreWithRelations[];
  /* ui step function data to display */
  uiStepFunctionData: any;
  /* subscribed ui data store and data schema field values for form */
  uiDataStoreSubscriptionFormValue: any[];
  /* ui step function types */
  uiStepFunctionTypes: SelectableOption[] = UIWorkflowItemTypes
    .map(i => <SelectableOption>{ key: i.title, value: i.key });
  /* ui step function integration operator types. e.g.: merge-map */
  uiStepFunctionIntegrationOperatorTypes = UIWorkflowItemIntegrationOperatorTypes
    .map(i => <SelectableOption>{ key: i.title, value: i.key });

  /* ui data subscription data */
  uiDataSubscriptionBody: [
    {
      uiDataStoreId: string;
      dataSchemaFieldIds: string;
    }
  ];
  subscribedFieldIds: string[];
  subscribedEventId: string;
  uiStepFunctions: UiWorkflowStepFunctionWithRelations[];
  uiDataStoreSelectOptions: KeyValue<string, string>[];
  projectId: string;

  /* default framework */
  DEFAULT_FRAMEWORK = FRONTEND_FRAMEWORK_TYPES.ANGULAR.value;
  /* form layout enum */
  FormLayout = FormLayout;

  loading = true;
  displayToolbar = false;
  displayToolbarBackButton = false;

  /* form will be disabled if the type is pre-defined-template. This flag is to follow the status */
  formDisabledForPreDefinedTemplate = false;
  isFormBuilt = false;
  uiDataEvents: UiDataEventWithRelations[];
  isEndpointsLoaded = false;
  isNeededEntitiesLoaded = false;
  isFormOptionsUpdated = false;
  initialValuesOfEditUIStepFunctionForm: FormGroup;

  constructor(
    private store: Store<any>,
    private dataTransformService: DataTransformService,
    private activatedRoute: ActivatedRoute,
    private formService: FormService,
    private formBuilder: FormBuilder,
    private subscribeToUiDataStoreService: SubscribeToUiDataStoreService,
    private workflowStepFunctionApi: WorkflowStepFunctionControllerService
  ) { }

  ngOnInit(): void {
    this.getUiStepFunctionIdFromUrl();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onTypeValueChanges(): void {
    this.editUIStepFunctionForm?.get('type').valueChanges.subscribe(valueOfType => {
      if (valueOfType === UIWorkflowItemTypeValues.PreDefinedTemplate && !this.formDisabledForPreDefinedTemplate) {
        this.formDisabledForPreDefinedTemplate = true;
        /* disable everything except name and type */
        this.disableFormFieldsExceptWhitelisted(['name', 'type']);
      } else if (this.formDisabledForPreDefinedTemplate) {
        this.formDisabledForPreDefinedTemplate = false;
        /* enable all fields */
        this.enableFormFieldsExceptBlacklisted([]);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.uiStepFunctionId) {
      this.uiStepFunction = this.uiStepFunctions?.find(uiStepFn => uiStepFn.id === this.uiStepFunctionId);
      this.setUIStepFunctionData();
    }
  }

  buildForm() {
    this.setRadioGroupByUIStepFunction();
    this.editUIStepFunctionForm = this.formBuilder.group({
      name: [this.uiStepFunctionData?.name, [Validators.required, Validators.pattern(CAMEL_CASE_REGEX)]],
      type: [this.uiStepFunctionData?.type, [Validators.required]],
      integrationOperatorType: [this.uiStepFunctionData?.integrationOperatorType, [Validators.required]],
      subscribedEvents: [this.uiStepFunctionData?.subscribedEvents, [Validators.required]],
      publishedEventsOnSuccess: [this.uiStepFunctionData?.publishedEventsOnSuccess],
      publishedEventsOnFailure: [this.uiStepFunctionData?.publishedEventsOnFailure],
      uiDataSubscriptionBody: [this.uiDataStoreSubscriptionFormValue],
      mode: [this.uiStepFunctionData?.mode],
      projectModel: [this.uiStepFunctionData?.projectModel, Validators.required],
      endpointId: [this.uiStepFunctionData?.endpointId, Validators.required],
      code: [this.uiStepFunctionData?.code],
      workflowStepFunctionId: [this.uiStepFunctionData?.workflowStepFunctionId],
      workflowServiceId: [this.uiStepFunctionData?.workflowServiceId],
      workflowEventId: [this.uiStepFunctionData?.workflowEventId],
      uiDataStore: [this.uiStepFunctionData?.uiDataStoreId, Validators.required]
    });
    this.isFormBuilt = true;
    this.loading = false;
    this.subscribeToModeChanges();
    this.onTypeValueChanges();
    this.onSubscribedEventsChange();
    this.onUIDataStoreSubscriptionChange();
    this.isFormBuilt = true;
  }

  getUiStepFunctionIdFromUrl() {
    if (!this.uiStepFunctionId) {
      this.uiStepFunctionId = this.activatedRoute.snapshot.params['id'];
    }
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToProjectModelEndpoints(),
      this.subscribeToUIStepFunctionEditData(),
      this.subscribeToWorkflowStepFunctionsAndTemplates(),
      this.subscribeToUiStepFunctionLoading(),
      this.subscribeToWorkflowEvents()
    ];
  }

  subscribeToModeChanges() {
    return this.editUIStepFunctionForm.get('mode').valueChanges.subscribe(mode => {
      this.updateValidators(mode);
    });
  }

  updateValidators(mode: string) {
    const projectModelControl = this.editUIStepFunctionForm.get('projectModel');
    const endpointIdControl = this.editUIStepFunctionForm.get('endpointId');

    if (projectModelControl && endpointIdControl) {
      projectModelControl.clearValidators();
      endpointIdControl.clearValidators();

      if (mode === StepFunctionTypeOptions.Endpoint) {
        projectModelControl.setValidators([Validators.required]);
        endpointIdControl.setValidators([Validators.required]);
      } else {
        projectModelControl.setValidators([Validators.nullValidator]);
        endpointIdControl.setValidators([Validators.nullValidator]);
      }

      projectModelControl.updateValueAndValidity();
      endpointIdControl.updateValueAndValidity();
    }
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectWithRelations) => {
      if (activeProject) {
        this.activeProject = activeProject;
        this.projectId = activeProject.id;
      } else {
        this.activeProject = null;
      }
    });
  }

  subscribeToUIStepFunctionEditData() {
    return this.store.select(<any>uiStepFunctionEditData).subscribe(editData => {
      this.loading = editData.isLoading;
      if (!editData.isLoading) {
        this.uiDataStores = editData.data.uiDataStores;
        this.uiStepFunctions = editData.data.uiStepFunctions;

        this.uiStepFunction = editData.data.uiStepFunctions.find(uiStepFunction => uiStepFunction.id === this.uiStepFunctionId);
        this.uiDataEvents = editData.data.uiDataEvents;
        this.projectModels = editData.data.projectModels;
        this.isNeededEntitiesLoaded = true;
        if (this.isEndpointsLoaded) {
          this.updateFormOptions();
        }
        this.setTitle();
      }
    });
  }

  subscribeToWorkflowStepFunctionsAndTemplates() {
    return this.store.select(<any>getWorkflowStepFunctionsAndTemplates).subscribe(data => {
      if (data) {
        this.workflowStepFunctions = data.workflowStepFunctions;
        this.templateWorkflowStepFunctions = data.templateWorkflowStepFunctions;
        this.setWorkflowStepFunctionFormItem();
        this.setServiceFormItem();
      }
    });
  }

  subscribeToUiStepFunctionLoading() {
    return this.store.select(state => state.workflowStepFunction?.loading).subscribe(loading => {
      this.loading = loading;
    });
  }

  subscribeToWorkflowEvents() {
    return this.store.select(state => state.workflowEvent.data).subscribe(workflowEvents => {
      this.workflowEvents = workflowEvents;
      if (workflowEvents?.length) {
        this.setServerSideWorkflowEventsFormItem();
      }
    });
  }

  subscribeToProjectModelEndpoints() {
    return this.store.select(createSelector(
      state => <boolean>state['projectModelEndpoint'].isLoading,
      state => <ProjectModelEndpointWithRelations[]>state['projectModelEndpoint'].data,
      (
        isLoading: boolean,
        projectModelEndpoints:
          ProjectModelEndpointWithRelations[]
      ) => {
        if (!isLoading) {
          return projectModelEndpoints;
        }
      }
    )
    ).subscribe((projectModelEndpoints: ProjectModelEndpointWithRelations[]) => {
      this.projectModelEndpoints = projectModelEndpoints;
      this.isEndpointsLoaded = true;
      if (this.isNeededEntitiesLoaded) {
        this.updateFormOptions();
      }
    });
  }

  updateFormOptions() {
    this.projectModels = this.projectModels?.map(projectModel => ({
      ...projectModel,
      endpoints: this.projectModelEndpoints.filter(projectModelEndpoint => projectModelEndpoint.modelId === projectModel.id)
    }));
    /* ui data event */
    this.setUIDataEventSelectOptions(this.uiDataEvents);
    /* ui data store */
    this.setUIDataStoreSelectOptions();
    /* project model */
    this.setProjectModelSelectOptions();
    /* ui step function */
    this.setUIStepFunctionData();
    this.isFormOptionsUpdated = true;
  }

  setUIDataEventSelectOptions(uiDataEvents) {
    /* map ui data events for select box */
    this.uiDataEventOptions = uiDataEvents?.map(uiDataEvent => ({
      key: `${uiDataEvent?.name} (${this.uiDataStores.find(dataStore => dataStore.id === uiDataEvent.uiDataStoreId).name})`,
      value: uiDataEvent?.id
    }));

    this.subscribedEventsSelectConfig.options = this.uiDataEventOptions || [];
    this.publishedEventsOnSuccessSelectConfig.options = this.uiDataEventOptions || [];
    this.publishedEventsOnFailureSelectConfig.options = this.uiDataEventOptions || [];
  }

  setUIStepFunctionData() {
    if (this.uiStepFunction) {
      this.setUIDataStoreSubscriptionData();
      this.setUIStepFunctionSubscribedAndPublishedEventData();
      this.setUIDataStoreSubscriptionTreeValue();

      /* project model */
      this.getProjectModelByEndpointId();
    }
  }

  /**
   * validate and set ui data store subscription data of ui step function
   *
   * @memberof UIStepFunctionEditComponent
   */
  setUIDataStoreSubscriptionData() {
    // Extract field IDs from the UI data stores
    const uiDataStoreFieldIds = this.uiDataStores
      .flatMap(uiDataStore => uiDataStore.schema?.fields?.map(field => field.id))
      .filter(Boolean);

    // Filter subscriptions based on the extracted field IDs
    const filteredSubscriptions = this.uiStepFunction.uiDataStoreSubscriptions?.filter(subscription =>
      subscription.dataSchemaTreeField.nodes.some(node => uiDataStoreFieldIds.includes(node.dataSchemaFieldId))
    );

    // Update the uiStepFunction object with the filtered subscriptions
    this.uiStepFunction = {
      ...this.uiStepFunction,
      uiDataStoreSubscriptions: filteredSubscriptions
    };
  }

  setUIStepFunctionSubscribedAndPublishedEventData() {
    const subscribedEventIds = this.uiStepFunction.subscribedEvents.map(event => event.id);

    const mappedPublishedEventsOnSuccess = this.uiStepFunction.publishedEventsOnSuccess?.map(event => ({
      condition: event.condition,
      publishedEventId: event.id,
      id: event.id
    }));

    const publishedEventIdsOnFailure = this.uiStepFunction.publishedEventsOnFailure?.map(event => event.id);

    this.uiStepFunction = {
      ...this.uiStepFunction,
      subscribedEvents: subscribedEventIds,
      publishedEventsOnSuccess: mappedPublishedEventsOnSuccess,
      publishedEventsOnFailure: publishedEventIdsOnFailure
    };
  }

  /**
   * set subscribed field tree from ui data store subscription values to use in uiDataStoreSubscription component
   *
   * @memberof UIStepFunctionEditComponent
   */
  setUIDataStoreSubscriptionTreeValue() {
    const subscribedFieldTree = this.uiStepFunction.uiDataStoreSubscriptions?.map(subscription => ({
      uiDataStore: subscription.uiDataStore,
      dataSchemaField: subscription.dataSchemaTreeField.nodes.map(node => node.field)
    }));

    this.uiDataStoreSubscriptionFormValue = subscribedFieldTree;
  }

  /**
   * finds project model that endpoint belongs to
   *
   * @param {ProjectModel[]} projectModels
   * @memberof UIStepFunctionEditComponent
   */
  getProjectModelByEndpointId() {
    if (this.uiStepFunction?.endpointId) {
      /* project model that belongs to the editing endpoint */
      const editingProjectModel = this.projectModels
        ?.find(projectModel => projectModel.endpoints
          ?.some(endpoint => endpoint.id === this.uiStepFunction.endpointId));
      this.uiStepFunctionData = {
        ...cloneDeep(this.uiStepFunction),
        projectModel: editingProjectModel?.id
      };
      this.setEndpointsFormItem(editingProjectModel?.id);
      this.uiWorkflowStepFunctionModeRadioGroupValue = StepFunctionTypeOptions.Endpoint;
    } else {
      this.uiStepFunctionData = cloneDeep(this.uiStepFunction);
      this.uiWorkflowStepFunctionModeRadioGroupValue = this.uiStepFunctionData?.mode;
    }
    this.buildForm();
    this.initialValuesOfEditUIStepFunctionForm = cloneDeep(this.editUIStepFunctionForm);
  }

  /**
   * set page title
   *
   * @memberof UIStepFunctionEditComponent
   */
  setTitle() {
    const uiDataStore = this.uiDataStores?.find(item => item?.id === this.uiStepFunction?.uiDataStoreId);

    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`,
      },
      {
        label: 'PROJECT_MODULE.UI_DATA_STORE_LIST_COMPONENT.UI_DATA_STORE_LIST',
        redirectUrl: PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_LIST
      },
      {
        label: uiDataStore?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_DETAIL}/${uiDataStore?.id}`,
        queryParams: QUERY_PARAM_DEFINITIONS.PROJECT.UI_DATA_STORE_DETAIL.UI_STEP_FUNCTIONS_TAB
      },
      {
        label: 'PROJECT_MODULE.UI_STEP_FUNCTION_CREATE_OR_EDIT_COMPONENT.UPDATE_UI_STEP_FUNCTION'
      },
      {
        label: this.uiStepFunction?.name
      }
    ];
  }


  /**
   * sets project model select options
   *
   * @memberof UIStepFunctionEditComponent
   */
  setProjectModelSelectOptions() {
    const projectModelSelectOptions = this.projectModels.map((projectModel: ProjectModelWithRelations) => ({
      key: projectModel.name,
      value: projectModel.id
    }));

    this.projectModelOptions = projectModelSelectOptions || [];
  }

  setUIDataStoreSelectOptions() {
    this.uiDataStoreSelectOptions = this.uiDataStores.map(uiDataStore => ({
      key: uiDataStore.name,
      value: uiDataStore.id
    }));
  }

  setServiceFormItem() {
    this.serviceOptions = this.templateWorkflowStepFunctions?.map(templateWorkflowStepFunction => ({
      key: `${templateWorkflowStepFunction.serviceName}.${templateWorkflowStepFunction.functionName}`,
      value: templateWorkflowStepFunction.id
    }));
  }

  setWorkflowStepFunctionFormItem() {
    this.workflowStepFunctionOptions = this.workflowStepFunctions?.map(workflowStepFunction => ({
      key: workflowStepFunction.name,
      value: workflowStepFunction.id
    }));
  }

  setServerSideWorkflowEventsFormItem() {
    this.serverSideWorkflowEventsOptions = this.workflowEvents?.map(workflowEvent => ({
      key: workflowEvent.name,
      value: workflowEvent.id
    }));
  }

  /**
   * when project model change, reset enpointId value and options
   *
   * @param {string} projectModelId
   * @memberof UIStepFunctionCreateComponent
   */
  onProjectModelValueChange(projectModelId: string) {
    this.endpointOptions = [];
    this.editUIStepFunctionForm.controls[UIStepFunctionCreateEditFormItem.EndpointId].reset();
    if (projectModelId !== null) {
      this.setEndpointsFormItem(projectModelId);
    }
  }


  /**
   * deletes or adds projectModel and endpointId by radio group value
   *
   * @param {StepFunctionEndpointOrCustomOptions} value
   * @memberof UIStepFunctionEditComponent
   */
  stepFunctionModeSelectValueChange(value: StepFunctionTypeOptions) {
    this.uiWorkflowStepFunctionModeRadioGroupValue = value;
    if (value && this.editUIStepFunctionForm.value) {
      if (value === StepFunctionTypeOptions.CustomCode) {
        /* custom code */
        this.editUIStepFunctionForm.get('workflowStepFunctionId')?.setValue(null);
        this.editUIStepFunctionForm.get('workflowServiceId')?.setValue(null);
        this.endpointOptions = [];
        this.editUIStepFunctionForm.get('projectModel')?.setValue(null);
        this.editUIStepFunctionForm.get('endpointId')?.setValue(null);
        this.editUIStepFunctionForm.get('workflowEventId')?.setValue(null);
      } else if (value === StepFunctionTypeOptions.WorkflowServices) {
        /* workflow services */
        this.editUIStepFunctionForm.get('projectModel')?.setValue(null);
        this.editUIStepFunctionForm.get('endpointId')?.setValue(null);
        this.endpointOptions = [];
        this.editUIStepFunctionForm.get('code')?.setValue(null);
        this.editUIStepFunctionForm.get('workflowStepFunctionId')?.setValue(null);
        this.editUIStepFunctionForm.get('workflowEventId')?.setValue(null);
      } else if (value === StepFunctionTypeOptions.Endpoint) {
        /* endpoint */
        this.editUIStepFunctionForm.get('code')?.setValue(null);
        this.editUIStepFunctionForm.get('workflowStepFunctionId')?.setValue(null);
        this.editUIStepFunctionForm.get('workflowServiceId')?.setValue(null);
        this.editUIStepFunctionForm.get('workflowEventId')?.setValue(null);
      } else if (value === StepFunctionTypeOptions.WorkflowStepFunctions) {
        /* workflow step functions */
        this.editUIStepFunctionForm.get('code')?.setValue(null);
        this.endpointOptions = [];
        this.editUIStepFunctionForm.get('workflowServiceId')?.setValue(null);
        this.editUIStepFunctionForm.get('projectModel')?.setValue(null);
        this.editUIStepFunctionForm.get('endpointId')?.setValue(null);
        this.editUIStepFunctionForm.get('workflowEventId')?.setValue(null);
      } else if (value === StepFunctionTypeOptions.WorkflowEvents) {
        /* workflow step event */
        this.editUIStepFunctionForm.get('code')?.setValue(null);
        this.endpointOptions = [];
        this.editUIStepFunctionForm.get('workflowStepFunctionId')?.setValue(null);
        this.editUIStepFunctionForm.get('workflowServiceId')?.setValue(null);
        this.editUIStepFunctionForm.get('projectModel')?.setValue(null);
        this.editUIStepFunctionForm.get('endpointId')?.setValue(null);
      }
    }
  }

  /**
   * sets endpoints form item depending on selected project model
   *
   * @param {any[]} endpoints
   * @memberof UIStepFunctionEditComponent
   */
  setEndpointsFormItem(projectModelId: string) {
    const endpoints = this.projectModelEndpoints?.filter(projectModelEndpoint => projectModelEndpoint.modelId === projectModelId);
    this.endpointOptions = endpoints?.map(endpoint => ({
      key: endpoint.name,
      value: endpoint.id
    })) || [];
  }

  getNameFieldErrorsByErrorKey(errorKey: string) {
    const control = this.editUIStepFunctionForm?.get(UIStepFunctionCreateEditFormItem.Name);
    const isDirty = control.dirty;
    const errors = control.errors;

    return isDirty && errors && control.hasError(errorKey);
  }

  onEditUiWorkflowStepFunction() {
    const uiStepFunction = this.editUIStepFunctionForm.value;
    this.formService.checkFormValidation(this.editUIStepFunctionForm);

    if (this.editUIStepFunctionForm.valid) {
      this.uiWorkflowStepFunctionUpdate(
        uiStepFunction,
        this.compareSubscribedEvents(uiStepFunction),
        this.comparePublishedEventsOnSuccess(uiStepFunction),
        this.comparePublishedEventsOnFailure(uiStepFunction),
        this.compareUIDataSubscriptions(uiStepFunction)
      );
    }
  }

  /**
   * compare changes in subscribed events
   *
   * @param {UiWorkflowStepFunctionWithRelations} uiStepFunction
   * @return {*}
   * @memberof UIStepFunctionEditComponent
   */
  compareSubscribedEvents(uiStepFunction: UiWorkflowStepFunctionWithRelations) {
    const initialUiStepFunction = this.uiStepFunction.subscribedEvents?.map(item => ({ id: item }));
    const finalUiStepFunction = uiStepFunction.subscribedEvents?.map(item => ({ id: item }));

    const addedAndDeletedItemIds = this.compareEvents(initialUiStepFunction, finalUiStepFunction);
    return {
      addedSubscribedEventIds: addedAndDeletedItemIds.addedEventIds || [],
      deletedSubscribedEventIds: addedAndDeletedItemIds.deletedEventIds || [],
    };
  }

  /**
   * compare changes in published events on success
   *
   * @param {UiWorkflowStepFunctionWithRelations} uiStepFunction
   * @return {*}
   * @memberof UIStepFunctionEditComponent
   */
  comparePublishedEventsOnSuccess(uiStepFunction: UiWorkflowStepFunctionWithRelations) {
    const initialUiStepFunction = this.uiStepFunction.publishedEventsOnSuccess;
    const finalUiStepFunction = uiStepFunction.publishedEventsOnSuccess;

    const changes = this.dataTransformService.compareArrays(initialUiStepFunction, finalUiStepFunction);

    return {
      addedPublishedEventsOnSuccessWithCondition: changes.createdArray,
      deletedPublishedEventOnSuccessIds: changes.deletedArray?.map((item: any) => item.publishedEventId),
      updatedPublishedEventsOnSuccessWithCondition: changes.updatedArray
    };
  }

  /**
   * compare changes in published events on failure
   *
   * @param {UiWorkflowStepFunctionWithRelations} uiStepFunction
   * @return {*}
   * @memberof UIStepFunctionEditComponent
   */
  comparePublishedEventsOnFailure(uiStepFunction: UiWorkflowStepFunctionWithRelations) {
    const initialUiStepFunction = this.uiStepFunction.publishedEventsOnFailure?.map(item => ({ id: item }));
    const finalUiStepFunction = uiStepFunction.publishedEventsOnFailure?.map(item => ({ id: item }));

    const addedAndDeletedItemIds = this.compareEvents(initialUiStepFunction, finalUiStepFunction);
    return {
      addedPublishedEventOnFailureIds: addedAndDeletedItemIds.addedEventIds || [],
      deletedPublishedEventOnFailureIds: addedAndDeletedItemIds.deletedEventIds || [],
    };
  }

  /**
   * compares initial and edited ui step function's data subscriptions for created and deleted ui data store subscriptions
   *
   * @param {UiWorkflowStepFunctionWithRelations} uiStepFunction
   * @return {*}
   * @memberof UIStepFunctionEditComponent
   */
  compareUIDataSubscriptions(uiStepFunction: UiWorkflowStepFunctionWithRelations) {
    const previousFields = this.uiStepFunction.uiDataStoreSubscriptions?.map(subscription => {
      const fieldTree = subscription.dataSchemaTreeField.nodes;
      const field = fieldTree.at(-1).field;
      return {
        uiDataStoreId: subscription.uiDataStoreId,
        fieldTree: fieldTree,
        fieldId: field.id,
        subscriptionId: subscription.id
      };
    });

    const currentFields = uiStepFunction.uiDataSubscriptionBody?.map(item => {
      const fieldTree = item.dataSchemaField;
      const field = fieldTree.at(-1);
      return {
        fieldId: field.id,
        fieldTree: fieldTree,
        uiDataStoreId: item.uiDataStore.id
      };
    });

    const comparedUIDataSubscriptions = this.dataTransformService.compareArrays(previousFields, currentFields, 'fieldId');

    return {
      addedUIDataSubscriptions: comparedUIDataSubscriptions.createdArray.map(item => ({
        uiDataStoreId: item.uiDataStoreId,
        dataSchemaFieldIds: (item.fieldTree as DataSchemaField[]).map(field => field.id)
      })),
      deletedUIDataSubscriptionIds: comparedUIDataSubscriptions.deletedArray?.map((item: Record<string, unknown>) => item.subscriptionId)
    };
  }

  uiWorkflowStepFunctionUpdate(
    editedUIStepFunction,
    editedSubscribedEventIds: Object,
    editedPublishedEventsOnSuccessIds: Object,
    editedPublishedEventsOnFailureIds: Object,
    editedUIDataSubscriptions: Object
  ) {
    this.loading = true;

    const uiDataSubscriptionData = editedUIStepFunction.uiDataSubscriptionBody;
    this.uiDataSubscriptionBody = this.subscribeToUiDataStoreService.setUIDataSubscriptionBody(uiDataSubscriptionData);
    const uiWorkflowStepFunctionUpdateBody = {
      id: this.uiStepFunctionId,
      type: editedUIStepFunction?.type,
      name: editedUIStepFunction?.name,
      integrationOperatorType: editedUIStepFunction?.integrationOperatorType,
      endpointId: editedUIStepFunction?.endpointId || null,
      code: editedUIStepFunction?.code,
      projectId: this.projectId,
      workflowStepFunctionId: editedUIStepFunction?.workflowStepFunctionId,
      workflowServiceId: editedUIStepFunction?.workflowServiceId,
      workflowEventId: editedUIStepFunction?.workflowEventId,
      mode: editedUIStepFunction?.mode,
      uiDataStoreId: editedUIStepFunction.uiDataStore,
      uiDataSubscriptionBody: this.uiDataSubscriptionBody,
      ...editedSubscribedEventIds,
      ...editedPublishedEventsOnSuccessIds,
      ...editedPublishedEventsOnFailureIds,
      ...editedUIDataSubscriptions
    };

    this.store.dispatch(new UpdateUIWorkflowStepFunction({
      uiWorkflowStepFunction: uiWorkflowStepFunctionUpdateBody,
      navigateAfterUpdate: this.navigateAfterUpdate,
      uiDataStoreId: editedUIStepFunction.uiDataStore
    }));
  }

  /**
   * gets initial and final step events from workflow step function and compares them
   * returns added and deleted items
   *
   * @param {*} initialUiStepFunction
   * @param {*} finalUiStepFunction
   * @return {*}
   * @memberof UIStepFunctionEditComponent
   */
  compareEvents(initialUiStepFunction: any, finalUiStepFunction: any) {
    const uiStepFunctionChanges = this.dataTransformService.compareArrays(initialUiStepFunction, finalUiStepFunction);

    const addedEventIds = uiStepFunctionChanges.createdArray?.map(item => item.id);

    const deletedEventIds = uiStepFunctionChanges.deletedArray?.map((item: any) => item.id);
    {
      return {
        addedEventIds,
        deletedEventIds
      };
    }
  }

  onUIDataStoreSubscriptionChange() {
    const uiDataStoreSubscriptionBody = this.editUIStepFunctionForm.value.uiDataSubscriptionBody;
    this.subscribedFieldIds = uiDataStoreSubscriptionBody?.map(
      subscription => subscription.dataSchemaField[subscription.dataSchemaField.length - 1].id
    );
  }

  onSubscribedEventsChange() {
    const subscribedEvents = this.editUIStepFunctionForm.value.subscribedEvents;
    if (subscribedEvents?.length) {
      this.subscribedEventId = subscribedEvents[subscribedEvents.length - 1];
    } else {
      this.subscribedEventId = null;
    }
  }

  calculateFormLayout() {
    return this.formLayout === FormLayout.Horizontal ? { label: 6, input: 18 } : { label: null, input: null };
  }

  /** Disable all the fields of the form except the provided filed names in the array */
  disableFormFieldsExceptWhitelisted(excludedFields: string[]) {
    for (const controlName of Object.keys(this.editUIStepFunctionForm.controls)) {
      if (Object.prototype.hasOwnProperty.call(this.editUIStepFunctionForm.controls, controlName)) {
        if (!excludedFields.includes(controlName)) {
          this.editUIStepFunctionForm.get(controlName).setValue(this.initialValuesOfEditUIStepFunctionForm.get(controlName).value);
        }
      }
    }
  }

  enableFormFieldsExceptBlacklisted(disabledFields: string[]) {
    Object.keys(this.editUIStepFunctionForm.controls).forEach(controlName => {
      if (!disabledFields.find(f => f === controlName)) {
        this.editUIStepFunctionForm?.get(controlName).enable();
      }
    });
  }

  onAddDataEventToUiStepFunction() {
    this.addDataEventToUiStepFunction.emit();
  }

  setRadioGroupByUIStepFunction() {
    if (this.uiStepFunctionData.endpointId) {
      this.uiWorkflowStepFunctionModeRadioGroupValue = StepFunctionTypeOptions.Endpoint;
    } else if (this.uiStepFunctionData.workflowStepFunctionId) {
      this.uiWorkflowStepFunctionModeRadioGroupValue = StepFunctionTypeOptions.WorkflowStepFunctions;
    } else if (this.uiStepFunctionData.code) {
      this.uiWorkflowStepFunctionModeRadioGroupValue = StepFunctionTypeOptions.CustomCode;
    } else if (this.uiStepFunctionData.workflowServiceId) {
      this.uiWorkflowStepFunctionModeRadioGroupValue = StepFunctionTypeOptions.WorkflowServices;
    } else if (this.uiStepFunctionData.workflowEventId) {
      this.uiWorkflowStepFunctionModeRadioGroupValue = StepFunctionTypeOptions.WorkflowEvents;
    } else {
      this.uiWorkflowStepFunctionModeRadioGroupValue = null;
    }
  }

  onNameChange(name: string) {
    const stepFunctionName = StringUtilityService.toCamelCase(name).replace(/\s/g, '');
    if (stepFunctionName !== name) {
      this.editUIStepFunctionForm.patchValue({ name: stepFunctionName });
    }
  }

}
