<rappider-title-toolbar *ngIf="title"
                        [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>

<section>
  <rappider-spin [spinning]="!endpoint">
    <div *ngIf="endpoint?.name"
         class="endpoint-property">
      <div class="property-heading col-2">
        <rappider-heading [content]="'SHARED.NAME' | translate"
                          [type]="PropertyHeadingType.H6"></rappider-heading>
      </div>

      <div class="item-area col-10">
        <rappider-paragraph [content]="endpoint?.name"></rappider-paragraph>
      </div>
    </div>

    <div *ngIf="endpoint?.path"
         class="endpoint-property">
      <div class="property-heading col-2">
        <rappider-heading [content]="'SHARED.PATH' | translate"
                          [type]="PropertyHeadingType.H6"></rappider-heading>
      </div>

      <div class="item-area col-10">
        <rappider-paragraph [content]="endpoint?.path"></rappider-paragraph>
      </div>
    </div>

    <div *ngIf="endpoint?.description"
         class="endpoint-property">
      <div class="property-heading col-2">
        <rappider-heading [content]="'SHARED.DESCRIPTION' | translate"
                          [type]="PropertyHeadingType.H6"></rappider-heading>
      </div>

      <div class="item-area col-10">
        <rappider-paragraph [content]="endpoint?.description"></rappider-paragraph>
      </div>
    </div>

    <div *ngIf="endpoint?.method"
         class="endpoint-property">
      <div class="property-heading col-2">
        <rappider-heading [content]="'PROJECT_MODULE.CUSTOM_ENDPOINT_COMPONENT.HTTP_METHOD' | translate"
                          [type]="PropertyHeadingType.H6"></rappider-heading>
      </div>

      <div class="item-area col-10">
        <rappider-paragraph [content]="endpoint?.method"></rappider-paragraph>
      </div>
    </div>

    <div class="endpoint-property">
      <div class="property-heading col-2">
        <rappider-heading [content]="'PROJECT_MODULE.CUSTOM_ENDPOINT_COMPONENT.FILTER_ACCEPTED' | translate"
                          [type]="PropertyHeadingType.H6"></rappider-heading>
      </div>

      <div class="item-area col-10">
        <rappider-paragraph [content]="endpoint?.isFilterAccepted"></rappider-paragraph>
      </div>
    </div>

    <div *ngIf="endpoint?.code"
         class="endpoint-property">
      <div class="property-heading col-2">
        <rappider-heading [content]="'SHARED.CODE' | translate"
                          [type]="PropertyHeadingType.H6"></rappider-heading>
      </div>

      <div class="item-area col-10">
        <rappider-paragraph [content]="endpoint?.code"></rappider-paragraph>
      </div>
    </div>
  </rappider-spin>
</section>

<section>
  <rappider-crud-view-edit-form [config]="ENDPOINT_DETAIL_CONFIG"
                                [data]="endpoint"
                                (fieldValueChange)="onProjectModelEndpointDetailFieldValueChange($event)"
                                (formSubmit)="onProjectModelEndpointUpdate($event)"></rappider-crud-view-edit-form>
</section>
