/* eslint-disable no-shadow */
import { Action } from '@ngrx/store';
import { PersonInterface } from '@rappider/api-sdk';
import { PersonProjectRoleBulkProcesses } from '@rappider/rappider-sdk';

export enum ActionTypes {
  LoadModule = '[ProjectMember] LoadModule',
  GetProjectMembers = '[ProjectMember] GetProjectMembers',
  GetProjectMembersSuccessful = '[ProjectMember] GetProjectMembersSuccessful',
  GetProjectMembersFailure = '[ProjectMember] GetProjectMembersFailure',
  CreateProjectMember = '[ProjectMember] CreateProjectMember',
  UpdateProjectMember = '[ProjectMember] UpdateProjectMember',
  DeleteProjectMember = '[ProjectMember] DeleteProjectMember',
  ProjectMemberError = '[ProjectMember] ProjectMemberError',
  AssignManager = '[ProjectMember] AssignManager',
  AssignManagerSuccessful = '[ProjectMember] AssignManagerSuccessful',
  AssignManagerFailure = '[ProjectMember] AssignManagerFailure',
  UnassignManager = '[ProjectMember] UnassignManager',
  UnassignManagerSuccessful = '[ProjectMember] UnassignManagerSuccessful',
  UnassignManagerFailure = '[ProjectMember] UnassignManagerFailure',
  FindPersonAuthoritiesForAssign = '[ProjectMember] FindPersonAuthoritiesForAssign',
  CreateRoles = '[ProjectMember] CreateRoles',
  CreateRolesSuccessful = '[ProjectMember] CreateRolesSuccessful',
  CreateRolesFailure = '[ProjectMember] CreateRolesFailure',
  DeleteRoles = '[ProjectMember] DeleteRoles',
  DeleteRolesSuccessful = '[ProjectMember] DeleteRolesSuccessful',
  DeleteRolesFailure = '[ProjectMember] DeleteRolesFailure',
  UnassignPersonFromProject = '[ProjectMember] UnassignPersonFromProject',
  UnassignPersonFromProjectSuccessful = '[ProjectMember] UnassignPersonFromProjectSuccessful',
  UnassignPersonFromProjectFailure = '[ProjectMember] UnassignPersonFromProjectFailure'
}

export class LoadModule implements Action {
  readonly type = ActionTypes.LoadModule;
}

export class GetProjectMembers implements Action {
  readonly type = ActionTypes.GetProjectMembers;
}

export class GetProjectMembersSuccessful implements Action {
  readonly type = ActionTypes.GetProjectMembersSuccessful;
  constructor(public payload: { projectMembers: PersonInterface[] }) { }
}

export class GetProjectMembersFailure implements Action {
  readonly type = ActionTypes.GetProjectMembersFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class CreateProjectMember implements Action {
  readonly type = ActionTypes.CreateProjectMember;
  constructor(public payload: { projectMember: PersonInterface }) { }
}

export class UpdateProjectMember implements Action {
  readonly type = ActionTypes.UpdateProjectMember;
  constructor(public payload: { projectMember: PersonInterface; projectMemberId: string }) { }
}

export class DeleteProjectMember implements Action {
  readonly type = ActionTypes.DeleteProjectMember;
  constructor(public payload: { projectMemberId: string }) { }
}

export class ProjectMemberError implements Action {
  readonly type = ActionTypes.ProjectMemberError;
  constructor(public payload: { errorOn: string; error?: any }) { }
}

export class AssignManager implements Action {
  readonly type = ActionTypes.AssignManager;
  constructor(public payload: { personId: string }) { }
}

export class AssignManagerSuccessful implements Action {
  readonly type = ActionTypes.AssignManagerSuccessful;
  constructor(public payload: { personAuthorities: any; personId: string }) { }
}

export class AssignManagerFailure implements Action {
  readonly type = ActionTypes.AssignManagerFailure;
  constructor(public payload: { errorOn: string; error?: any }) { }
}

export class UnassignManager implements Action {
  readonly type = ActionTypes.UnassignManager;
  constructor(public payload: { personId: string }) { }
}

export class UnassignManagerSuccessful implements Action {
  readonly type = ActionTypes.UnassignManagerSuccessful;
  constructor(public payload: { personId: string }) { }
}

export class UnassignManagerFailure implements Action {
  readonly type = ActionTypes.UnassignManagerFailure;
  constructor(public payload: { errorOn: string; error?: any }) { }
}

export class FindPersonAuthoritiesForAssign implements Action {
  readonly type = ActionTypes.FindPersonAuthoritiesForAssign;
  constructor(public payload: { personId: string }) { }
}

export class CreateRoles implements Action {
  readonly type = ActionTypes.CreateRoles;
  constructor(public payload: { body: PersonProjectRoleBulkProcesses }) { }
}

export class CreateRolesSuccessful implements Action {
  readonly type = ActionTypes.CreateRolesSuccessful;
  constructor(public payload: { result: any; roles: any[] }) { }
}

export class CreateRolesFailure implements Action {
  readonly type = ActionTypes.CreateRolesFailure;
  constructor(public payload: { errorOn: string; error?: any }) { }
}

export class DeleteRoles implements Action {
  readonly type = ActionTypes.DeleteRoles;
  constructor(public payload: { body: PersonProjectRoleBulkProcesses }) { }
}

export class DeleteRolesSuccessful implements Action {
  readonly type = ActionTypes.DeleteRolesSuccessful;
  constructor(public payload: { result: any }) { }
}

export class DeleteRolesFailure implements Action {
  readonly type = ActionTypes.DeleteRolesFailure;
  constructor(public payload: { errorOn: string; error?: any }) { }
}

export class UnassignPersonFromProject implements Action {
  readonly type = ActionTypes.UnassignPersonFromProject;
  constructor(public payload: { personId: string }) { }
}

export class UnassignPersonFromProjectSuccessful implements Action {
  readonly type = ActionTypes.UnassignPersonFromProjectSuccessful;
  constructor(public payload: { personId: string }) { }
}

export class UnassignPersonFromProjectFailure implements Action {
  readonly type = ActionTypes.UnassignPersonFromProjectFailure;
  constructor(public payload: { errorOn: string; error?: any }) { }
}

export type Actions =
  LoadModule
  | GetProjectMembers
  | GetProjectMembersSuccessful
  | GetProjectMembersFailure
  | CreateProjectMember
  | UpdateProjectMember
  | DeleteProjectMember
  | ProjectMemberError
  | AssignManager
  | AssignManagerSuccessful
  | AssignManagerFailure
  | UnassignManager
  | UnassignManagerSuccessful
  | UnassignManagerFailure
  | FindPersonAuthoritiesForAssign
  | CreateRoles
  | CreateRolesSuccessful
  | CreateRolesFailure
  | DeleteRoles
  | DeleteRolesSuccessful
  | DeleteRolesFailure
  | UnassignPersonFromProject
  | UnassignPersonFromProjectSuccessful
  | UnassignPersonFromProjectFailure
  ;
