import { Project } from '@rappider/rappider-sdk';
import * as ActiveProjectActions from './active-project.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import * as ProjectActions from 'libs/project/src/lib/states/projects-state/project.actions';

export const activeProjectFeatureKey = 'activeProject';

export interface State {
  data: Project;
  rootFolder: string;
}

export const initialState: State = {
  data: null,
  rootFolder: null
};

export function reducer(
  state: State = initialState,
  action: ActiveProjectActions.Actions | AuthenticationActions.Actions | ProjectActions.Actions
): State {
  switch (action.type) {

    case ActiveProjectActions.ActionTypes.SetActiveProject:
      return {
        ...state,
        data: action.payload.project,
      };

    case ActiveProjectActions.ActionTypes.SetRootFolder:
      return {
        ...state,
        rootFolder: action.payload.rootFolder
      };

    case ActiveProjectActions.ActionTypes.ClearActiveProject:
      return initialState;

    case ActiveProjectActions.ActionTypes.GetActiveProject:
      return initialState;

    case ProjectActions.ActionTypes.SetAsActiveProjectTheme:
      if (state.data?.id === action.payload.activeProjectId) {
        return {
          ...state,
          data: {
            ...state.data,
            activeProjectThemeId: action.payload.projectThemeId
          }
        };
      } else {
        return state;
      }

    case ProjectActions.ActionTypes.UpdateProjectSuccessful: {
      if (action.payload.projectId === state.data?.id) {
        return {
          ...state,
          data: {
            ...state.data,
            ...action.payload.project
          }
        };
      } else {
        return state;
      }
    }

    case AuthenticationActions.ActionTypes.Logout:
      return initialState;

    case ProjectActions.ActionTypes.DeleteProjectSuccessful:
      return initialState;

    default:
      return state;
  }
}
