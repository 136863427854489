import { ProjectPackageWithRelations } from '@rappider/rappider-sdk';
import * as projectPackagesActions from './project-package.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

export const projectPackageFeatureKey = 'projectPackage';

export interface State {
  data: ProjectPackageWithRelations[];
  loading: boolean;
  error: {
    timestamp?: number;
    key?: string;
    error?: any;
  };
}

export const initialState: State = {
  data: [],
  loading: true,
  error: {}
};

export function reducer(
  state: State = initialState,
  action: projectPackagesActions.Actions | AuthenticationActions.Actions
): State {
  switch (action.type) {

    case projectPackagesActions.ActionTypes.SetProjectPackages:
      return {
        ...state,
        data: action.payload.projectPackages
      };

    case projectPackagesActions.ActionTypes.CreateProjectPackage:
      return {
        ...state,
        loading: true
      };

    case projectPackagesActions.ActionTypes.CreateProjectPackageSuccessful:
      return {
        ...state,
        data: [
          ...state.data,
          ...action.payload.projectPackage
        ],
        loading: false
      };

    case projectPackagesActions.ActionTypes.UpdateProjectPackage:
      return {
        ...state,
        loading: true
      };

    case projectPackagesActions.ActionTypes.UpdateProjectPackageSuccessful:
      return {
        ...state,
        data: state.data.map(projectPackage => {
          if (projectPackage.id === action.payload.id) {
            return {
              ...projectPackage,
              ...action.payload.updatedPackage
            };
          }
          return projectPackage;
        }),
        loading: false
      };

    case projectPackagesActions.ActionTypes.SetLoadingState:
      return {
        ...state,
        loading: action.payload.loading
      };

    case projectPackagesActions.ActionTypes.ErrorAction:
      return {
        ...state,
        error: {
          ...state.error,
          timestamp: action.payload.timestamp,
          key: action.payload.key,
          error: action.payload.error
        }
      };

    case AuthenticationActions.ActionTypes.Logout:
      return initialState;

    case AuthenticationActions.ActionTypes.ChangeActiveProjectSuccessful:
      return initialState;

    default:
      return state;
  }
}
