import { Injectable } from '@angular/core';
import { camelCase, kebabCase, lowerCase, snakeCase, startCase, upperCase, upperFirst } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class StringUtilityService {

  static toCamelCase(str) {
    return camelCase(str);
  }

  static toTitleCase(str) {
    return startCase(camelCase(str));
  }

  static toPascalCase(str) {
    return startCase(camelCase(str)).replace(/ /g, '');
  }

  static toConstantCase(str) {
    return upperCase(str).replace(/ /g, '_');
  }

  static toDotCase(str) {
    return lowerCase(str).replace(/ /g, '.');
  }

  static toKebabCase(str) {
    return kebabCase(str);
  }

  static toLowerCase(str) {
    return lowerCase(str).replace(/ /g, '');
  }

  static toPathCase(str) {
    return lowerCase(str).replace(/ /g, '/');
  }

  static toSnakeCase(str) {
    return snakeCase(str);
  }

  static toSentenceCase(str) {
    return upperFirst(lowerCase(str));
  }

  /* John Doe => JD */
  static getInitialCharactersOfString(str) {
    if (str) {
      const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

      let initials = [...str.matchAll(rgx)] || [];

      initials = (
        (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
      ).toUpperCase();
      return initials.toString();
    } else {
      return;
    }
  }

  static camelize(str) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) =>
        index === 0
          ? letter.toLowerCase()
          : letter.toUpperCase()
      )
      .replace(/\s+/g, '');
  }
}
