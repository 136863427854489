import { createAction, createReducer, on } from '@ngrx/store';
import { UiDataSelectorWithRelations } from '@rappider/rappider-sdk';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import * as UIDataSelectorActions from './ui-data-selector.actions';

export const featureKey = 'uiDataSelector';

export interface UIDataSelectorState {
  data: UiDataSelectorWithRelations[];
  isLoaded: boolean;
  isLoading: boolean;
  error: any;
}

export const initialState: UIDataSelectorState = {
  data: [],
  isLoaded: false,
  isLoading: false,
  error: null
};

const ChangeActiveProject = createAction(AuthenticationActions.ActionTypes.ChangeActiveProject);
const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

export const reducer = createReducer(
  initialState,

  on(UIDataSelectorActions.GetUIDataSelectors, (state) => ({
    ...state,
    isLoading: true,
    isLoaded: false
  })),
  on(UIDataSelectorActions.GetUIDataSelectorsSuccessful, (state, action) => ({
    ...state,
    data: action.payload.uiDataSelectors,
    isLoading: false,
    isLoaded: true
  })),
  on(UIDataSelectorActions.GetUIDataSelectorById, (state) => ({
    ...state,
    isLoading: true
  })),
  on(UIDataSelectorActions.GetUIDataSelectorByIdSuccessful, (state, action) => ({
    ...state,
    data: action.payload.replace ? state.data.map((uiDataSelector) =>
      uiDataSelector.id === action.payload.uiDataSelector.id ? action.payload.uiDataSelector : uiDataSelector
    ) : [...state.data, action.payload.uiDataSelector],
    isLoading: false
  })),
  on(UIDataSelectorActions.CreateUIDataSelector, (state) => ({
    ...state,
    isLoading: true
  })),
  on(UIDataSelectorActions.CreateUIDataSelectorSuccessful, (state, action) => ({
    ...state,
    data: [...state.data, action.payload.uiDataSelector],
    isLoading: false
  })),
  on(UIDataSelectorActions.UpdateUIDataSelector, (state) => ({
    ...state,
    isLoading: true
  })),
  on(UIDataSelectorActions.UpdateUIDataSelectorSuccessful, (state, action) => ({
    ...state,
    data: state.data.map((uiDataSelector) =>
      uiDataSelector.id === action.payload.uiDataSelector.id ? action.payload.uiDataSelector : uiDataSelector
    ),
    isLoading: false
  })),
  on(UIDataSelectorActions.DeleteUIDataSelector, (state) => ({
    ...state,
    isLoading: true
  })),
  on(UIDataSelectorActions.DeleteUIDataSelectorSuccessful, (state, action) => ({
    ...state,
    data: state.data.filter((uiDataSelector) => uiDataSelector.id !== action.payload.uiDataSelectorId),
    isLoading: false
  })),
  on(UIDataSelectorActions.Error, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false
  })),

  on(ChangeActiveProject, (state) => initialState),
  on(Logout, (state) => initialState)
);
