<div class="number-input">
  <nz-input-number [(ngModel)]="value"
                   [id]="elementId"
                   class="w-100"
                   [nzStep]="step"
                   [nzMin]="min"
                   [nzMax]="max"
                   [ngClass]="cssClass"
                   [ngStyle]="cssStyle"
                   [nzDisabled]="disabled"
                   nzInputMode="numeric"
                   [ngStyle]="borderStyles"
                   [style.border-top]="borderSettings?.borderTop + ' ' + borderSettings?.borderStyle"
                   [style.border-right]="borderSettings?.borderRight + ' ' + borderSettings?.borderStyle"
                   [style.border-bottom]="borderSettings?.borderBottom + ' ' + borderSettings?.borderStyle"
                   [style.border-left]="borderSettings?.borderLeft + ' ' + borderSettings?.borderStyle"
                   [style.border-radius]="borderSettings?.borderRadius"
                   [style.border-top-left-radius]="borderSettings?.borderTopLeftRadius"
                   [style.border-top-right-radius]="borderSettings?.borderTopRightRadius"
                   [style.border-bottom-right-radius]="borderSettings?.borderBottomRightRadius"
                   [style.border-bottom-left-radius]="borderSettings?.borderBottomLeftRadius"
                   [style.border-color]="borderSettings?.borderColor"
                   [style.border-style]="borderSettings?.borderStyle"
                   [style.padding]="paddingSettings?.all"
                   [style.margin]="marginSettings?.all"
                   [style.box-shadow]="shadowSettings?.boxShadow"
                   [style.color]="colorSettings?.color"
                   [style.background-color]="colorSettings?.backgroundColor"
                   [style.width]="sizeSettings?.width"
                   [style.max-width]="sizeSettings?.maxWidth"
                   [style.min-width]="sizeSettings?.minWidth"
                   [style.height]="sizeSettings?.height"
                   [style.max-height]="sizeSettings?.maxHeight"
                   [style.min-height]="sizeSettings?.minHeight"
                   (nzBlur)="onBlur()"></nz-input-number>
</div>