import { createSelector } from '@ngrx/store';
import { Authority } from '../../../definitions/authority.enum';

export const isActivePersonProjectOwner = createSelector(
  state => state['personAuthority']?.data,
  state => state['auth'].activePerson,
  state => state['activeProject'],
  (personAuthorities: any[], activePerson: any, activeProject: any) => {
    /* return true if active person id matches project owner role's person id and owner  */
    const isOwner = personAuthorities?.some(personAuthority => (
      personAuthority.role?.name === Authority.ProjectOwner &&
      personAuthority.person?.id === activePerson.id &&
      personAuthority.projectId === activeProject.id));
    return isOwner;
  }
);
