/* angular */
import { createReducer, on } from '@ngrx/store';

/* actions */
import * as ModuleActions from './module.actions';

/* state key */
export const featureKey = 'module';

/* state interface */
export interface State {
  data: any[] | null;
  isLoading: boolean;
  isLoaded: boolean;
}

/* initial values */
export const initialState: State = {
  data: null,
  isLoading: false,
  isLoaded: false
};

export const reducer = createReducer(
  initialState,
  on(ModuleActions.GetModules, (state, action) => ({
    ...state,
    isLoading: true,
    isLoaded: false
  })),
  on(ModuleActions.GetModulesSuccessful, (state, action) => ({
    ...state,
    data: action.payload.modules,
    isLoading: false,
    isLoaded: true
  })),
  on(ModuleActions.CreateModule, (state) => ({
    ...state,
    isLoading: true
  })),
  on(ModuleActions.CreateModuleSuccessful, (state, action) => ({
    ...state,
    data: [
      ...state.data,
      action.payload.module
    ],
    isLoading: false
  })),
  on(ModuleActions.CreateModuleFailure, (state) => ({
    ...state,
    isLoading: false
  })),
  on(ModuleActions.UpdateModule, (state) => ({
    ...state,
    isLoading: true
  })),
  on(ModuleActions.UpdateModuleSuccessful, (state, action) => {
    const updatedModule = {
      ...state.data.find(module => module.id === action.payload.moduleId),
      ...action.payload.module
    };
    return {
      ...state,
      data: [
        ...state.data.filter(module => module.id !== action.payload.moduleId),
        updatedModule
      ],
      isLoading: false
    };
  }),
  on(ModuleActions.UpdateModuleFailure, (state) => ({
    ...state,
    isLoading: false
  })),
  on(ModuleActions.GetModuleById, (state) => ({
    ...state,
    isLoading: true
  })),
  on(ModuleActions.GetModuleByIdSuccessful, (state, action) => ({
    ...state,
    isLoading: false,
    data: [
      ...state.data,
      action.payload.module
    ]
  })),
  on(ModuleActions.GetModuleByIdFailure, (state) => ({
    ...state,
    isLoading: false
  })),
  on(ModuleActions.ResetStateToInitial, () => initialState),

  on(ModuleActions.ResetStateToInitialChangeProject, () => initialState)
);
