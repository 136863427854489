/* eslint-disable @typescript-eslint/naming-convention */
import {
  CrudFormConfig,
  CrudViewFormItemType,
  CrudFormTextBoxItem,
  TextBoxType,
  FormLayout,
  CrudFormInputValidatorInfoItem,
  InputSize,
  ButtonType,
  TextBoxSize,
  FeedbackComponentConfig,
  FeedbackButtonActionBehavior
} from '@rappider/rappider-components/utils';
import {
  PASSWORD_REGEX,
  SPECIAL_CHARACTER_REGEX,
  EMAIL_AND_US_PHONE_REGEX
} from '../../definitions/regex';
import { Validators } from '@angular/forms';
import { ValidatePhoneNumber } from 'libs/components/src/lib/utils/validator-utils/phone-number.validator';
import { PATH_DEFINITIONS } from '../../definitions/path-definition';

export const EMAIL_REGISTER_CONFIG = <CrudFormTextBoxItem>{
  title: 'SHARED.EMAIL',
  fieldName: 'email',
  type: CrudViewFormItemType.TextBox,
  required: true,
  index: 3,
  textType: TextBoxType.Email,
  autoComplete: 'new-password',
  size: InputSize.Large,
  placeholder: 'SHARED.EMAIL',
  validators: [
    {
      type: Validators.required,
      errorKey: 'required',
      errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
    },
    {
      type: Validators.email,
      errorKey: 'email',
      errorMessage: 'ERRORS.PATTERN_MESSAGES.VALID_EMAIL_MESSAGE'
    }
  ],
  rowNumber: 2
};

export const PHONE_NUMBER_REGISTER_CONFIG = {
  title: 'SHARED.CELL_PHONE',
  fieldName: 'phoneNumber',
  type: CrudViewFormItemType.PhoneNumber,
  mask: '',
  textMaskGuide: false,
  index: 3,
  selectConfig: {
    grouppedOptions: [],
    settings: {
      searchable: true,
      allowClear: true
    },
    placeholder: 'Choose country code',
    dropdownMatchSelectWidth: false,
    size: InputSize.Large
  },
  textboxConfig: {
    placeholder: '',
    maskGuide: true,
    size: TextBoxSize.Large
  },
  validators: [
    {
      type: ValidatePhoneNumber,
      errorKey: 'validatePhoneNumber',
      errorMessage: 'Please enter phone number.'
    }
  ]
};

export const PHONE_NUMBER_VERIFY_FAILURE_CONFIG: FeedbackComponentConfig = {
  icon: {
    name: 'fas fa-exclamation-triangle text-danger'
  },
  title: 'AUTHENTICATION_MODULE.PHONE_NUMBER_VERIFY_COMPONENT.VERIFY_PHONE_NUMBER_FAILURE_TITLE',
  subTitle: 'AUTHENTICATION_MODULE.PHONE_NUMBER_VERIFY_COMPONENT.VERIFY_PHONE_NUMBER_FAILURE_DESCRIPTION',
  buttons: [
    {
      content: 'AUTHENTICATION_MODULE.PHONE_NUMBER_VERIFY_COMPONENT.CLICK_HERE_RESEND_VERIFY_SMS',
      type: ButtonType.Primary,
      actionBehavior: FeedbackButtonActionBehavior.Emit
    }
  ]
};

export const PHONE_NUMBER_VERIFY_SUCCESS_CONFIG: FeedbackComponentConfig = {
  icon: {
    name: 'far fa-check-circle text-success'
  },
  title: 'AUTHENTICATION_MODULE.PHONE_NUMBER_VERIFY_COMPONENT.VERIFY_PHONE_NUMBER_SUCCESS_TITLE',
  subTitle: 'AUTHENTICATION_MODULE.PHONE_NUMBER_VERIFY_COMPONENT.VERIFY_PHONE_NUMBER_SUCCESS_DESCRIPTION',
  buttons: [
    {
      content: 'SHARED.GO_TO_HOME_PAGE',
      type: ButtonType.Primary,
      actionBehavior: FeedbackButtonActionBehavior.Route,
      redirectUrl: PATH_DEFINITIONS.PROJECTS.PROJECT_LIST_PATH
    }
  ]
};

export const registerWithPhoneNumberConfig: CrudFormConfig = {
  items: [
    <CrudFormTextBoxItem>{
      title: 'SHARED.FIRST_NAME',
      fieldName: 'firstName',
      type: CrudViewFormItemType.TextBox,
      index: 1,
      disabled: false,
      size: InputSize.Large,
      placeholder: 'SHARED.FIRST_NAME',
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ],
      rowNumber: 1
    },
    <CrudFormTextBoxItem>{
      title: 'SHARED.LAST_NAME',
      fieldName: 'lastName',
      index: 2,
      type: CrudViewFormItemType.TextBox,
      size: InputSize.Large,
      placeholder: 'SHARED.LAST_NAME',
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ],
      rowNumber: 1
    },
    EMAIL_REGISTER_CONFIG,
    <CrudFormTextBoxItem>{
      title: 'SHARED.PASSWORD',
      fieldName: 'password',
      type: CrudViewFormItemType.TextBox,
      textType: TextBoxType.Password,
      autoComplete: 'new-password',
      index: 4,
      size: InputSize.Large,
      placeholder: 'SHARED.PASSWORD',
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          type: Validators.pattern(PASSWORD_REGEX),
          errorKey: 'pattern'
        }
      ],
      rowNumber: 3
    },
    <CrudFormInputValidatorInfoItem>{
      relatedFieldName: 'password',
      type: CrudViewFormItemType.InputValidatorInfo,
      index: 5,
      patternInfos: [
        {
          pattern: /[1-9]/,
          message: 'ERRORS.PATTERN_MESSAGES.PASSWORD_PATTERN_MESSAGES.ONE_DIGIT'
        },
        {
          pattern: /[A-Z]/,
          message: 'ERRORS.PATTERN_MESSAGES.PASSWORD_PATTERN_MESSAGES.ONE_UPPERCASE'
        },
        {
          pattern: SPECIAL_CHARACTER_REGEX,
          message: 'ERRORS.PATTERN_MESSAGES.PASSWORD_PATTERN_MESSAGES.SPECIAL_CHARACTER'
        },
        {
          pattern: /.{8,}/,
          message: 'ERRORS.PATTERN_MESSAGES.PASSWORD_PATTERN_MESSAGES.MINIMUM_LENGTH'
        }
      ],
    },
    <CrudFormTextBoxItem>{
      title: 'SHARED.CONFIRM_PASSWORD',
      fieldName: 'confirmPassword',
      type: CrudViewFormItemType.TextBox,
      textType: TextBoxType.Password,
      autoComplete: 'new-password',
      index: 6,
      size: InputSize.Large,
      placeholder: 'SHARED.CONFIRM_PASSWORD',
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          errorKey: 'mustMatch',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.MUST_MATCH_PASSWORD'
        }
      ],
      rowNumber: 3
    },
    <CrudFormTextBoxItem>{
      text: {
        text: 'SHARED.RAPPIDER_TERMS'
      },
      title: null,
      fieldName: 'privacyPolicy',
      type: CrudViewFormItemType.CheckBox,
      showRequiredSign: false,
      validators: [
        {
          type: Validators.requiredTrue,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.RAPPIDER_TERM_CHECKBOX_MESSAGE'
        }
      ],
      rowNumber: 4
    }
  ],
  validators: [
    {
      name: 'MustMatch',
      validatorKey: 'mustMatch',
      params: {
        field1: 'password',
        field2: 'confirmPassword'
      }
    }
  ],
  itemSettings: {
    inputComponentSize: {
      xs: 24
    },
    labelComponentSize: {
      xs: 24
    }
  },
  submitButton: {
    text: 'AUTHENTICATION_MODULE.SIGN_UP',
    size: 'large',
    block: true,
    type: ButtonType.Primary
  },
  formLayout: FormLayout.Vertical
};
export const DISABLED_EMAIL_REGISTER_CONFIG = <CrudFormTextBoxItem>{
  ...EMAIL_REGISTER_CONFIG,
  disabled: true
};

export const DISABLED_PHONE_NUMBER_REGISTER_CONFIG = <CrudFormTextBoxItem>{
  ...PHONE_NUMBER_REGISTER_CONFIG,
  disabled: true
};

export const MIXED_REGISTER_CONFIG = <CrudFormTextBoxItem>{
  title: 'SHARED.USERNAME',
  fieldName: 'mixedUsername',
  type: CrudViewFormItemType.TextBox,
  index: 3,
  size: InputSize.Large,
  placeholder: 'SHARED.USERNAME',
  validators: [
    {
      type: Validators.required,
      errorKey: 'required',
      errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
    },
    {
      type: Validators.pattern(EMAIL_AND_US_PHONE_REGEX),
      errorKey: 'pattern',
      errorMessage: 'ERRORS.PATTERN_MESSAGES.MIXED_USERNAME_ERROR'
    }
  ]
};
