import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PATH_DEFINITIONS, QueryParam } from '../../definitions';
import { BrowserStorageManagementService, RouterStateService } from '../../services';

@Injectable({
  providedIn: 'root'
})
export class ActiveProjectGuard implements CanActivate {

  constructor(
    private store: Store<any>,
    private browserStorageManagementService: BrowserStorageManagementService,
    private routerStateService: RouterStateService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    console.log('ACTIVE PROJECT GUARD');
    return this.store.select(s => s.activeProject).pipe(
      map(activeProject => {
        if (!activeProject) {
          const activeProjectId = this.browserStorageManagementService.getLocalStorageItem('activeProjectId');
          if (!activeProjectId) {
            const activeProjectIdFromUrl = next.queryParams['projectId'];
            if(!activeProjectIdFromUrl){
            this.routerStateService.addQueryParam(QueryParam.RedirectUrl, state.url);
            this.routerStateService.navigate(PATH_DEFINITIONS.PROJECTS.PROJECT_LIST_PATH);
            }
            return !!activeProjectIdFromUrl;
          }
          return !!activeProjectId;
        }
        return !!activeProject;
      })
    );
  }

}
