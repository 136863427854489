import { createAction, props } from '@ngrx/store';
import { NewPermission, Permission, PermissionPartial, PermissionWithRelations } from '@rappider/rappider-sdk';

export enum ActionTypes {
  GetPermissions = '[Permissions] GetPermissions',
  GetPermissionsSuccessful = '[Permissions] GetPermissionsSuccessful',
  GetPermissionsFailure = '[Permissions] GetPermissionsFailure',
  CreatePermission = '[Permissions] CreatePermission',
  CreatePermissionSuccessful = '[Permissions] CreatePermissionSuccessful',
  CreatePermissionFailure = '[Permissions] CreatePermissionFailure',
  UpdatePermission = '[Permissions] UpdatePermission',
  UpdatePermissionSuccessful = '[Permissions] UpdatePermissionSuccessful',
  UpdatePermissionFailure = '[Permissions] UpdatePermissionFailure',
  DeletePermission = '[Permissions] DeletePermission',
  DeletePermissionSuccessful = '[Permissions] DeletePermissionSuccessful',
  DeletePermissionFailure = '[Permissions] DeletePermissionFailure',
  ChangePermissionModalVisibility = '[Permissions] ChangePermissionModalVisibility',
}

/* get */
export const GetPermissions = createAction(ActionTypes.GetPermissions);

export const GetPermissionsSuccessful = createAction(
  ActionTypes.GetPermissionsSuccessful,
  props<{ permissions: PermissionWithRelations[] }>()
);

export const GetPermissionsFailure = createAction(
  ActionTypes.GetPermissionsFailure,
  props<{ error: any; key: string; timestamp: number }>()
);

/* create */
export const CreatePermission = createAction(
  ActionTypes.CreatePermission,
  props<{ permission: NewPermission }>()
);

export const CreatePermissionSuccessful = createAction(
  ActionTypes.CreatePermissionSuccessful,
  props<{ permission: Permission }>()
);

export const CreatePermissionFailure = createAction(
  ActionTypes.CreatePermissionFailure,
  props<{ error: any; key: string; timestamp: number }>()
);

/* update */
export const UpdatePermission = createAction(
  ActionTypes.UpdatePermission,
  props<{ id: string; permission: PermissionPartial }>()
);

export const UpdatePermissionSuccessful = createAction(
  ActionTypes.UpdatePermissionSuccessful,
  props<{ id: string; permission: PermissionPartial }>()
);

export const UpdatePermissionFailure = createAction(
  ActionTypes.UpdatePermissionFailure,
  props<{ error: any; key: string; timestamp: number }>()
);

/* delete */
export const DeletePermission = createAction(
  ActionTypes.DeletePermission,
  props<{ id: string }>()
);

export const DeletePermissionSuccessful = createAction(
  ActionTypes.DeletePermissionSuccessful,
  props<{ id: string }>()
);

export const DeletePermissionFailure = createAction(
  ActionTypes.DeletePermissionFailure,
  props<{ error: any; key: string; timestamp: number }>()
);

/* modal visibility */
export const ChangePermissionModalVisibility = createAction(
  ActionTypes.ChangePermissionModalVisibility,
  props<{ visibility: boolean }>()
);
