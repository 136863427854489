import { createAction, props } from '@ngrx/store';
import { NewWorkflowStepFunctionPublishedEventOnSuccess, WorkflowStepFunctionPublishedEventOnSuccess, WorkflowStepFunctionPublishedEventOnSuccessPartial, WorkflowStepFunctionPublishedEventOnSuccessWithRelations } from '@rappider/rappider-sdk';

/* action types */
export enum ActionTypes {
  CreateWorkflowStepFunctionPublishedEventOnSuccess = '[WorkflowStepFunctionPublishedEventOnSuccess] CreateWorkflowStepFunctionPublishedEventOnSuccess',
  CreateWorkflowStepFunctionPublishedEventOnSuccessSuccessful = '[WorkflowStepFunctionPublishedEventOnSuccess] CreateWorkflowStepFunctionPublishedEventOnSuccessSuccessful',
  CreateWorkflowStepFunctionPublishedEventOnSuccessFailure = '[WorkflowStepFunctionPublishedEventOnSuccess] CreateWorkflowStepFunctionPublishedEventOnSuccessFailure',
  GetWorkflowStepFunctionPublishedEventOnSuccess = '[WorkflowStepFunctionPublishedEventOnSuccess] GetWorkflowStepFunctionPublishedEventOnSuccess',
  GetWorkflowStepFunctionPublishedEventOnSuccessSuccessful = '[WorkflowStepFunctionPublishedEventOnSuccess] GetWorkflowStepFunctionPublishedEventOnSuccessSuccessful',
  GetWorkflowStepFunctionPublishedEventOnSuccessFailure = '[WorkflowStepFunctionPublishedEventOnSuccess] GetWorkflowStepFunctionPublishedEventOnSuccessFailure',
  UpdateWorkflowStepFunctionPublishedEventOnSuccess = '[WorkflowStepFunctionPublishedEventOnSuccess] UpdateWorkflowStepFunctionPublishedEventOnSuccess',
  UpdateWorkflowStepFunctionPublishedEventOnSuccessSuccessful = '[WorkflowStepFunctionPublishedEventOnSuccess] UpdateWorkflowStepFunctionPublishedEventOnSuccessSuccessful',
  UpdateWorkflowStepFunctionPublishedEventOnSuccessFailure = '[WorkflowStepFunctionPublishedEventOnSuccess] UpdateWorkflowStepFunctionPublishedEventOnSuccessFailure',
  DeleteWorkflowStepFunctionPublishedEventOnSuccess = '[WorkflowStepFunctionPublishedEventOnSuccess] DeleteWorkflowStepFunctionPublishedEventOnSuccess',
  DeleteWorkflowStepFunctionPublishedEventOnSuccessSuccessful = '[WorkflowStepFunctionPublishedEventOnSuccess] DeleteWorkflowStepFunctionPublishedEventOnSuccessSuccessful',
  DeleteWorkflowStepFunctionPublishedEventOnSuccessFailure = '[WorkflowStepFunctionPublishedEventOnSuccess] DeleteWorkflowStepFunctionPublishedEventOnSuccessFailure',
  AddWorkflowStepFunctionPublishedEventOnSuccessSuccessful = '[WorkflowStepFunctionPublishedEventOnSuccess] AddWorkflowStepFunctionPublishedEventOnSuccessSuccessful',
}

export const CreateWorkflowStepFunctionPublishedEventOnSuccess = createAction(
  ActionTypes.CreateWorkflowStepFunctionPublishedEventOnSuccess,
  props<{ payload: { workflowStepFunctionPublishedEventOnSuccess: NewWorkflowStepFunctionPublishedEventOnSuccess } }>()
);

export const CreateWorkflowStepFunctionPublishedEventOnSuccessSuccessful = createAction(
  ActionTypes.CreateWorkflowStepFunctionPublishedEventOnSuccessSuccessful,
  props<{ payload: { workflowStepFunctionPublishedEventOnSuccess: WorkflowStepFunctionPublishedEventOnSuccess } }>()
);

export const CreateWorkflowStepFunctionPublishedEventOnSuccessFailure = createAction(
  ActionTypes.CreateWorkflowStepFunctionPublishedEventOnSuccessFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const AddWorkflowStepFunctionPublishedEventOnSuccessSuccessful = createAction(
  ActionTypes.AddWorkflowStepFunctionPublishedEventOnSuccessSuccessful,
  props<{ payload: { workflowStepFunctionPublishedEventOnSuccess: WorkflowStepFunctionPublishedEventOnSuccess[] } }>()
);

export const GetWorkflowStepFunctionPublishedEventOnSuccess = createAction(
  ActionTypes.GetWorkflowStepFunctionPublishedEventOnSuccess,
);

export const GetWorkflowStepFunctionPublishedEventOnSuccessSuccessful = createAction(
  ActionTypes.GetWorkflowStepFunctionPublishedEventOnSuccessSuccessful,
  props<{ payload: { workflowStepFunctionPublishedEventOnSuccesss: WorkflowStepFunctionPublishedEventOnSuccessWithRelations[] } }>()
);

export const GetWorkflowStepFunctionPublishedEventOnSuccessFailure = createAction(
  ActionTypes.GetWorkflowStepFunctionPublishedEventOnSuccessFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const UpdateWorkflowStepFunctionPublishedEventOnSuccess = createAction(
  ActionTypes.UpdateWorkflowStepFunctionPublishedEventOnSuccess,
  props<{ payload: { workflowStepFunctionPublishedEventOnSuccessId: string; workflowStepFunctionPublishedEventOnSuccessBody?: WorkflowStepFunctionPublishedEventOnSuccessPartial } }>()
);

export const UpdateWorkflowStepFunctionPublishedEventOnSuccessSuccessful = createAction(
  ActionTypes.UpdateWorkflowStepFunctionPublishedEventOnSuccessSuccessful,
  props<{ payload: { workflowStepFunctionPublishedEventOnSuccessId: string; workflowStepFunctionPublishedEventOnSuccessBody?: WorkflowStepFunctionPublishedEventOnSuccessWithRelations } }>()
);

export const UpdateWorkflowStepFunctionPublishedEventOnSuccessFailure = createAction(
  ActionTypes.UpdateWorkflowStepFunctionPublishedEventOnSuccessFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const DeleteWorkflowStepFunctionPublishedEventOnSuccess = createAction(
  ActionTypes.DeleteWorkflowStepFunctionPublishedEventOnSuccess,
  props<{ payload: { workflowStepFunctionPublishedEventOnSuccessId: string } }>()
);

export const DeleteWorkflowStepFunctionPublishedEventOnSuccessSuccessful = createAction(
  ActionTypes.DeleteWorkflowStepFunctionPublishedEventOnSuccessSuccessful,
  props<{ payload: { workflowStepFunctionPublishedEventOnSuccessId: string } }>()
);

export const DeleteWorkflowStepFunctionPublishedEventOnSuccessFailure = createAction(
  ActionTypes.DeleteWorkflowStepFunctionPublishedEventOnSuccessFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

