/* eslint-disable no-shadow */
import { createAction, props } from '@ngrx/store';
import { MenuConfig } from '@rappider/rappider-components/utils';
import { UiDataEventDuplicateWorkfloFromIdsDto } from '@rappider/rappider-sdk';
import { DiagramTreeItemConnector } from '../../utils/interfaces/diagram-editor/diagram-tree-item-connector.interface';
import { DiagramTreeItem } from '../../utils/interfaces/diagram-editor/diagram-tree-item.interface';
import { WorkflowItemInDiagram } from '../../utils/workflow-item-in-diagram.interface';

export enum ActionTypes {
  InitState = '[Diagram] InitState',
  SetActiveDiagramItem = '[Diagram] SetActiveDiagramItem',
  BuildDiagramTree = '[Diagram] BuildDiagramTree',
  BuildDiagramTreeSuccessful = '[Diagram] BuildDiagramTreeSuccessful',
  BuildDiagramTreeFailure = '[Diagram] BuildDiagramTreeFailure',
  PopulateWorkflowMenu = '[Diagram] PopulateWorkflowMenu',
  PopulateWorkflowMenuSuccessful = '[Diagram] PopulateWorkflowMenuSuccessful',
  PopulateWorkflowMenuFailure = '[Diagram] PopulateWorkflowMenuFailure',
  DuplicateWorkflow = '[Diagram] DuplicateWorkflow',
  DuplicateWorkflowSuccessful = '[Diagram] DuplicateWorkflowSuccessful',
  DuplicateWorkflowFailure = '[Diagram] DuplicateWorkflowFailure',
  ErrorAction = '[Diagram] ErrorAction',
  ResetStateToInitial = '[Module] ResetStateToInitial',
  ResetStateToInitialChangeProject = '[Module] ResetStateToInitialChangeProject'
}

export const InitState = createAction(
  ActionTypes.InitState
);

export const SetActiveDiagramItem = createAction(
  ActionTypes.SetActiveDiagramItem,
  props<{ payload: { activeItem: WorkflowItemInDiagram } }>()
);

export const BuildDiagramTree = createAction(
  ActionTypes.BuildDiagramTree
);

export const BuildDiagramTreeSuccessful = createAction(
  ActionTypes.BuildDiagramTreeSuccessful,
  props<{ payload: { diagramTree: DiagramTreeItem[]; connectors: DiagramTreeItemConnector[] } }>()
);

export const BuildDiagramTreeFailure = createAction(
  ActionTypes.BuildDiagramTreeFailure,
  props<{ payload: { error: any; key: string; timeStamp: number } }>()
);

export const PopulateWorkflowMenu = createAction(
  ActionTypes.PopulateWorkflowMenu
);

export const PopulateWorkflowMenuSuccessful = createAction(
  ActionTypes.PopulateWorkflowMenuSuccessful,
  props<{ payload: { workflowMenu: MenuConfig } }>()
);

export const PopulateWorkflowMenuFailure = createAction(
  ActionTypes.PopulateWorkflowMenuFailure,
  props<{ payload: { error: any; key: string; timestamp: number } }>()
);

export const DuplicateWorkflow = createAction(
  ActionTypes.DuplicateWorkflow,
  props<{ payload: { duplicateWorkflowWithIds: UiDataEventDuplicateWorkfloFromIdsDto } }>()
);

export const DuplicateWorkflowSuccessful = createAction(
  ActionTypes.DuplicateWorkflowSuccessful,
);

export const DuplicateWorkflowFailure = createAction(
  ActionTypes.DuplicateWorkflowFailure,
  props<{ payload: { error: any; key: string; timestamp: number } }>()
);

export const ResetStateToInitial = createAction(ActionTypes.ResetStateToInitial);

export const ResetStateToInitialChangeProject = createAction(ActionTypes.ResetStateToInitialChangeProject);

export const ErrorAction = createAction(
  ActionTypes.ErrorAction,
  props<{ payload: { error: any; key: string; timeStamp: number } }>()
);

