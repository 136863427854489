/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { NewRole } from '../models/new-role';
import { NewRoleByAdmin } from '../models/new-role-by-admin';
import { Role } from '../models/role';
import { RolePartial } from '../models/role-partial';
import { RolePartialByAdmin } from '../models/role-partial-by-admin';
import { RoleWithRelations } from '../models/role-with-relations';

@Injectable({
  providedIn: 'root',
})
export class RoleControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation roleControllerFindByIdByAdmin
   */
  static readonly RoleControllerFindByIdByAdminPath = '/admin/roles/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByIdByAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByIdByAdmin$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<Role>> {

    const rb = new RequestBuilder(this.rootUrl, RoleControllerService.RoleControllerFindByIdByAdminPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Role>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByIdByAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByIdByAdmin(params: {
    id: string;
    filter?: any;
  }): Observable<Role> {

    return this.findByIdByAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<Role>) => r.body as Role)
    );
  }

  /**
   * Path part for operation roleControllerDeleteByIdByAdmin
   */
  static readonly RoleControllerDeleteByIdByAdminPath = '/admin/roles/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteByIdByAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteByIdByAdmin$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RoleControllerService.RoleControllerDeleteByIdByAdminPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteByIdByAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteByIdByAdmin(params: {
    id: string;
  }): Observable<void> {

    return this.deleteByIdByAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation roleControllerUpdateByIdByAdmin
   */
  static readonly RoleControllerUpdateByIdByAdminPath = '/admin/roles/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateByIdByAdmin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateByIdByAdmin$Response(params: {
    id: string;
    body?: RolePartialByAdmin
  }): Observable<StrictHttpResponse<RoleWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, RoleControllerService.RoleControllerUpdateByIdByAdminPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RoleWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateByIdByAdmin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateByIdByAdmin(params: {
    id: string;
    body?: RolePartialByAdmin
  }): Observable<RoleWithRelations> {

    return this.updateByIdByAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<RoleWithRelations>) => r.body as RoleWithRelations)
    );
  }

  /**
   * Path part for operation roleControllerFindByAdmin
   */
  static readonly RoleControllerFindByAdminPath = '/admin/roles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByAdmin$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<RoleWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, RoleControllerService.RoleControllerFindByAdminPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RoleWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByAdmin(params?: {
    filter?: any;
  }): Observable<Array<RoleWithRelations>> {

    return this.findByAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RoleWithRelations>>) => r.body as Array<RoleWithRelations>)
    );
  }

  /**
   * Path part for operation roleControllerCreateByAdmin
   */
  static readonly RoleControllerCreateByAdminPath = '/admin/roles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createByAdmin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createByAdmin$Response(params?: {
    body?: NewRoleByAdmin
  }): Observable<StrictHttpResponse<RoleWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, RoleControllerService.RoleControllerCreateByAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RoleWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createByAdmin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createByAdmin(params?: {
    body?: NewRoleByAdmin
  }): Observable<RoleWithRelations> {

    return this.createByAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<RoleWithRelations>) => r.body as RoleWithRelations)
    );
  }

  /**
   * Path part for operation roleControllerFindById
   */
  static readonly RoleControllerFindByIdPath = '/roles/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<Role>> {

    const rb = new RequestBuilder(this.rootUrl, RoleControllerService.RoleControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Role>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
    filter?: any;
  }): Observable<Role> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<Role>) => r.body as Role)
    );
  }

  /**
   * Path part for operation roleControllerSoftDeleteById
   */
  static readonly RoleControllerSoftDeleteByIdPath = '/roles/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `softDeleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  softDeleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RoleControllerService.RoleControllerSoftDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `softDeleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  softDeleteById(params: {
    id: string;
  }): Observable<void> {

    return this.softDeleteById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation roleControllerUpdateById
   */
  static readonly RoleControllerUpdateByIdPath = '/roles/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    body?: RolePartial
  }): Observable<StrictHttpResponse<Role>> {

    const rb = new RequestBuilder(this.rootUrl, RoleControllerService.RoleControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Role>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    body?: RolePartial
  }): Observable<Role> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<Role>) => r.body as Role)
    );
  }

  /**
   * Path part for operation roleControllerFind
   */
  static readonly RoleControllerFindPath = '/roles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<RoleWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, RoleControllerService.RoleControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RoleWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<RoleWithRelations>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RoleWithRelations>>) => r.body as Array<RoleWithRelations>)
    );
  }

  /**
   * Path part for operation roleControllerCreate
   */
  static readonly RoleControllerCreatePath = '/roles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    body?: NewRole
  }): Observable<StrictHttpResponse<Role>> {

    const rb = new RequestBuilder(this.rootUrl, RoleControllerService.RoleControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Role>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    body?: NewRole
  }): Observable<Role> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<Role>) => r.body as Role)
    );
  }

}
