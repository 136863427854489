<div class="feedback">
  <nz-result [nzIcon]="iconArea"
             [nzTitle]="titleArea"
             [nzSubTitle]="subTitleArea">
    <div nz-result-extra>
      <ng-container *ngFor="let button of buttons">
        <button nz-button
                [nzType]="button.type"
                (click)="onButtonClick(button)">{{ button.content | translate }}</button>
      </ng-container>
    </div>
    <ng-template #iconArea>
      <rappider-icon class="feedback-icon"
                     [name]="icon.name"
                     [type]="icon.type"
                     [theme]="icon.theme"
                     [color]="icon.color"
                     [size]="icon.size"></rappider-icon>
    </ng-template>
    <ng-template #titleArea>
      <div class="title">
        {{ title | translate }}
      </div>
    </ng-template>
    <ng-template #subTitleArea>
      <div class="sub-title">
        {{ subTitle | translate }}
      </div>
    </ng-template>
  </nz-result>
</div>
