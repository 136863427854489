/* actions */
import * as ProcessInfoActions from './process-info.actions';
import * as UIDataStoreActions from 'libs/project/src/lib/states/ui-data-store/ui-data-store.actions';
import * as ProjectModelEndpointActions from 'libs/project/src/lib/states/project-model-endpoint-state/project-model-endpoint.actions';
import * as DataSchemaActions from 'libs/project/src/lib/states/data-schema/data-schema.actions';
import * as ProjectModelFieldActions from 'libs/project-model-field/src/lib/state/project-model-field.actions';
import * as ModuleActions from 'libs/module/src/lib/state/module.actions';
import * as PersonAuthorityActions from 'libs/shared/src/lib/states/person-authority/person-authority.actions';
import * as ProjectRequirementActions from 'libs/project/src/lib/states/project-requirements/project-requirements.actions';
import * as LayoutActions from 'libs/pages/src/lib/state/layout-state/layout.actions';
import * as ProjectSettingActions from 'libs/project/src/lib/states/project-settings-state/project-setting.actions';
import * as EnvironmentVariableActions from 'libs/project/src/lib/states/environment-variables-state/environment-variable.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import * as PageActions from 'libs/pages/src/lib/state/page-state/page.actions';
import * as ProjectThemeActions from 'libs/project/src/lib/states/project-theme-state/project-theme.actions';
import * as ProjectScriptActions from 'libs/project/src/lib/states/project-script-state/project-script.actions';
import * as UIDataEventActions from 'libs/project/src/lib/states/ui-data-event-state/ui-data-event.actions';
import * as ProjectModelActions from 'libs/project/src/lib/states/project-model-state/project-model.actions';
/* actions */

import { ProcessInfoConclusion, ProcessInfoIdentifier, ProcessInfoStatus } from '../../definitions/process-info';
import { ProcessInfoWithRelations } from '@rappider/rappider-sdk';
import { Action } from '@ngrx/store';


export function getActionsToDispatchAccordingToProcessInformation(processInformations: ProcessInfoWithRelations[]): Action[] {
  const actionsToDispatch: Action[] = [];
  processInformations.forEach(processInfo => {
    if (processInfo.conclusion === ProcessInfoConclusion.Successful && processInfo.status === ProcessInfoStatus.Completed) {
      switch (processInfo.identifier) {
        case ProcessInfoIdentifier.DataSchemaFieldsCreate:
          actionsToDispatch.push(
            new DataSchemaActions.GetDataSchemaById({ id: (processInfo.data as any).dataSchemaId })
          );
          break;
        case ProcessInfoIdentifier.ProjectCreate:
          actionsToDispatch.push(
            ModuleActions.GetModules(),
            PersonAuthorityActions.GetPersonAuthorities(),
            ProjectRequirementActions.GetProjectRequirements(),
            new LayoutActions.GetLayouts(),
            new AuthenticationActions.GetPersonProjectRoles(),
            new ProjectSettingActions.LoadModule(),
            new EnvironmentVariableActions.LoadModule(),
            new PageActions.GetPages(),
            new ProjectThemeActions.GetProjectThemes(),
            new ProjectScriptActions.GetProjectScripts(),
            new UIDataStoreActions.GetUIDataStores(),
            new UIDataEventActions.GetUIDataEvents(),
            new ProjectModelActions.GetProjectModels()
          );
          break;
        case ProcessInfoIdentifier.ProjectModelEndpointsCreate:
          actionsToDispatch.push(
            ProjectModelEndpointActions.GetProjectModelEndpointByModelId({ payload: { projectModelId: (processInfo.data as any).projectModelId } })
          );
          break;
        case ProcessInfoIdentifier.ProjectModelFieldsCreate:
          actionsToDispatch.push(
            ProjectModelFieldActions.GetProjectModelFieldsByModelId({ payload: { projectModelId: (processInfo.data as any).projectModelId } })
          );
          break;
        case ProcessInfoIdentifier.UIDataStoreCreate:
          actionsToDispatch.push(
            new UIDataStoreActions.GetUIDataStore({ uiDataStoreId: (processInfo.data as any).uiDataStoreId, navigateAfter: false })
          );
          break;
        default:
          break;
      }
    }
    actionsToDispatch.push(ProcessInfoActions.AddProcessInfo({ payload: { processInfo: processInfo } }));
  });

  return actionsToDispatch;
}
