import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { NotificationService, PaginationService, RouterStateService } from '@rappider/services';
import * as ProjectVersionActions from './project-version.actions';
import { ApiResult } from '@rappider/models';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { NewProjectVersion, ProjectVersion, ProjectVersionControllerService } from '@rappider/rappider-sdk';


@Injectable()
export class ProjectVersionEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private notificationService: NotificationService,
    private projectVersionApi: ProjectVersionControllerService,
    private paginationService: PaginationService,
    private routerStateService: RouterStateService
  ) { }


  loadModule$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectVersionActions.LoadModule>(
      ProjectVersionActions.ActionTypes.LoadModule,
    ),
    concatMap(() => [
      new ProjectVersionActions.GetTotalCount(),
      new ProjectVersionActions.GetProjectVersions()
    ])
  ));


  enableProjectVersionsLoading$ = createEffect(() => this.actions$.pipe(
    ofType<
      ProjectVersionActions.GetProjectVersions | ProjectVersionActions.CreateProjectVersion
    >(
      ProjectVersionActions.ActionTypes.GetProjectVersions, ProjectVersionActions.ActionTypes.CreateProjectVersion
    ),
    map(() => new ProjectVersionActions.EnableProjectVersionsLoading())
  ));


  disableProjectVersionsLoading$ = createEffect(() => this.actions$.pipe(
    ofType<
      ProjectVersionActions.SetProjectVersions | ProjectVersionActions.ProjectVersionError
    >(
      ProjectVersionActions.ActionTypes.SetProjectVersions, ProjectVersionActions.ActionTypes.ProjectVersionError
    ),
    map(() => new ProjectVersionActions.DisableProjectVersionsLoading())
  ));


  getTotalCount$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectVersionActions.GetTotalCount>(
      ProjectVersionActions.ActionTypes.GetTotalCount
    ),
    withLatestFrom(
      this.store.select(state => state.projectVersion.pagination)
    ),
    mergeMap(([action, pagination]) => this.projectVersionApi.count().pipe(
      map((res: { count: number }) => new ProjectVersionActions.SetPagination({
        pagination: {
          totalCount: res.count,
          totalPageNumber: this.paginationService.getTotalPageNumber(res.count, pagination.pageSize)
        }
      })),
      catchError(error => [
        new ProjectVersionActions.ProjectVersionError({ errorOn: 'GetTotalCount', error: error }),
      ])
    ))
  ));

  /**
   * Create Project Version and update state fields
   *
   * @memberof ProjectVersionEffects
   */

  getProjectVersions$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectVersionActions.GetProjectVersions>(
      ProjectVersionActions.ActionTypes.GetProjectVersions
    ),
    mergeMap((action) => this.projectVersionApi.find().pipe(
      mergeMap((projectVersions: ProjectVersion[]) => {
        if (projectVersions) {
          return [
            new ProjectVersionActions.GetTotalCount(),
            new ProjectVersionActions.SetProjectVersions({ projectVersions: projectVersions })
          ];
        } else {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_VERSION_COULDNOT_LAODED'
          );
          return [new ProjectVersionActions.GetProjectVersionsFailure({ error: 'Project version could not loaded', key: 'GetProjectVersionsFailure', timestamp: Date.now() })];
        }
      }),
      catchError(error => [
        new ProjectVersionActions.ProjectVersionError({ errorOn: 'GetProjectVersions', error: error }),
      ])
    ))
  ));


  setPagination$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectVersionActions.SetPagination>(
      ProjectVersionActions.ActionTypes.SetPagination
    ),
    mergeMap(action => {
      if (action.payload.getDataAfter) {
        return of(new ProjectVersionActions.GetProjectVersions());
      } else {
        return [new ProjectVersionActions.SetPaginationFailure({ error: 'There is no getDataAfter in payload', key: 'SetPaginationFailure', timestamp: Date.now() })];
      }
    })
  ));

  /**
   * Create Project Version and update state fields
   *
   * @memberof ProjectVersionEffects
   */

  createProjectVersion$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectVersionActions.CreateProjectVersion>(
      ProjectVersionActions.ActionTypes.CreateProjectVersion
    ),
    withLatestFrom(
      this.store.select(state => state.auth.activePerson),
      this.store.select(state => state.activeProject.data?.id)
    ),
    mergeMap(([action, person, activeProjectId]) => {
      const projectVersionData: NewProjectVersion = {
        ...action.payload.projectVersion,
        description: action.payload.projectVersion.description || undefined
      };
      return this.projectVersionApi.create({ body: projectVersionData }).pipe(
        mergeMap((projectVersion: ProjectVersion) => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFUL',
            'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_VERSION_CREATED'
          );
          this.routerStateService.navigate(PATH_DEFINITIONS.PROJECTS.PROJECT_VERSION_LIST);
          return of(new ProjectVersionActions.SetPagination({ pagination: { currentPageNumber: 1 }, getDataAfter: true }));
        }),
        catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_VERSION_COULDNOT_CREATED'
          );
          return [
            new ProjectVersionActions.CreateProjectVersionFailure({
              key: 'CreateProjectVersionFailure',
              timestamp: Date.now(),
              error,
            })
          ];
        })
      );
    })
  ));

}
