import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {
  CodeMirrorSettings,
  CrudViewFormItemType,
  GrouppedOption,
  SelectSettings
} from '@rappider/rappider-components/utils';
import { ControlStatus, DataSchemaElementRow, DataSchemaElementRowOutput, INITIAL_ITEM } from './models/data-schema-element-row';
import { DATA_SCHEMA_CREATE_OR_EDIT_CONFIG } from '../../../configs/data-schema-create-or-edit-config';
import { DataSchemaApi, DataSchemaField, DataSchemaInterface } from '@rappider/api-sdk';
import { cloneDeep, orderBy } from 'lodash';
import { DataSchemaTypes } from '../../models/data-schema-type.enum';
import { DataSchemaService, DataTransformService, NotificationService } from '@rappider/services';
import { CODEMIRROR_JSON_SETTINGS, DATA_FIELD_NAME_REGEX, DEFAULT_MODAL_SIZE, PrimitiveDataSchemas } from '@rappider/shared/definitions';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DataSchemaFieldRowPages } from '@rappider/shared/interfaces';
import { Store } from '@ngrx/store';
import { DataSchemaFieldRowType } from './models/field-type.enum';
import { DataSchemaCategory } from '../../models/data-schema-category.enum';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { POP_CONFIRM_DELETE_ACTION } from 'libs/shared/src/lib/configs/pop-confirm-button/pop-confirm-button-config';
import { DataSchemaControllerService, DataSchemaWithRelations } from '@rappider/rappider-sdk';
import { getDataSchemasWithDetailsSelector } from 'libs/project/src/lib/states/selectors/get-data-schemas-with-details.selector';

@Component({
  selector: 'rappider-data-schema-element-row',
  templateUrl: './data-schema-element-row.component.html',
  styleUrls: ['./data-schema-element-row.component.scss']
})
export class DataSchemaElementRowComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChildren('name') names: QueryList<ElementRef>;

  @ViewChild('initialValueModalTemplate', { static: true }) initialValueModalTemplate: TemplateRef<{}>;

  /* data */
  @Input() data: DataSchemaElementRow[];
  /* button loading state */
  @Input() buttonLoading = false;
  /* data schema id */
  @Input() dataSchemaId: string;
  /* active project id */
  @Input() activeProjectId: string;
  /* add button text */
  @Input() addButtonText = 'PROJECT_MODULE.DATA_SCHEMA_MODULE.DATA_SCHEMA_ELEMENT_ROW_COMPONENT.ADD_DATA_SCHEMA_FIELD';
  /* add button visiblity state */
  @Input() isAddButtonVisible = true;
  /* delete button visiblity state */
  @Input() isDeleteButtonVisible = true;
  /* data schema field default fieldname flag */
  @Input() hasDefault = false;
  /* is input definition */
  @Input() type = DataSchemaFieldRowType.Default;
  /* data schema field row page type - will be used in filter */
  @Input() dataSchemaPageType: DataSchemaFieldRowPages;
  @Input() cancelButtonText = 'SHARED.CANCEL';

  @Output() saveButtonClick = new EventEmitter<DataSchemaElementRowOutput>();
  @Output() cancelButtonClick = new EventEmitter();

  typeOptions: GrouppedOption[];
  typeOptionsForComponentSelector: GrouppedOption[];

  hideDragable = false;
  deleteButtonField = false;
  hideSaveAndCancelButtons = false;

  selectSettings: SelectSettings = {
    searchable: true
  };
  isArrayOptions = [
    {
      key: true,
      value: true
    },
    {
      key: false,
      value: false
    }
  ];
  dividerText = 'PROJECT_MODULE.DATA_SCHEMA_CREATE_COMPONENT.ADD_DATA_SCHEMA';

  DATA_SCHEMA_CREATE_OR_EDIT_CONFIG = DATA_SCHEMA_CREATE_OR_EDIT_CONFIG;
  formData: any;
  /* selectbox open state */
  isSelectboxOpen = false;
  /* invalid datum indices */
  invalidTypeIndices: number[];
  /* invalid name indices */
  invalidNameIndices: number[];
  /* form state */
  formState = {
    isValid: false,
    isSubmit: false
  };
  /* initial data which comes from input */
  initialData: DataSchemaElementRow[] = [{ description: null, isArray: false, name: null, typeId: null }];

  DATA_FIELD_NAME_REGEX = DATA_FIELD_NAME_REGEX;
  hasSameNameIndices: { matchedIndices?: any }[] = [];
  defaultUIDataSelectors = [];

  PrimitiveDataSchemas = PrimitiveDataSchemas;
  /* settings for codemirror component */
  codeMirrorSettings: CodeMirrorSettings = CODEMIRROR_JSON_SETTINGS;

  initialValueModal: NzModalRef;
  isDefaultCodemirrorVisible = false;

  localCodemirrorValue: any;

  dataSchemas: DataSchemaWithRelations[];
  DataSchemaFieldRowType = DataSchemaFieldRowType;
  DataSchemaFieldRowPages = DataSchemaFieldRowPages;

  modalSize = DEFAULT_MODAL_SIZE;

  popConfirmDeleteConfig = POP_CONFIRM_DELETE_ACTION;

  CrudViewFormItemType = CrudViewFormItemType;

  isLoading: boolean;
  subscriptions: Subscription[];

  isPayloadExist = true;

  constructor(
    private store: Store<any>,
    private dataSchemaApi: DataSchemaApi,
    private dataTransformService: DataTransformService,
    private dataSchemaService: DataSchemaService,
    private notificationService: NotificationService,
    private nzModalService: NzModalService,
    private translateService: TranslateService,
    private dataSchemaControllerService: DataSchemaControllerService
  ) { }

  ngOnInit(): void {
    this.validateData();
    this.subscribeToData();
    this.setInitialData();
    this.setDefaultUIDataSelector();

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.getTypes(),
      this.subscribeToDataSchemaLoading()
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      this.validateData();
    }
    if (changes.isInputDefinition) {
      this.setDefaultUIDataSelector();
    }
  }

  validateData() {
    if (!this.data?.length) {
      this.data = [{ ...cloneDeep(INITIAL_ITEM), index: 0 }];
      if (this.hasDefault) {
        this.data[0].default = null;
      }

      if (!this.data[0].typeId) {
        this.isPayloadExist = false;
      }

      if (this.typeOptions) {
        this.data[0].typeId = this.typeOptions?.find(typeOption => typeOption?.label === 'string')?.value;
      }

    } else {
      this.data = orderBy(cloneDeep(this.data), 'index', 'asc');

      if (this.data[0].typeId) {
        this.isPayloadExist = true;
      }

      this.hideDragable = this.data.some((field: DataSchemaElementRow) => field.isUpdatable === false);
      this.deleteButtonField = this.data.every((field: DataSchemaElementRow) => field.isDeletable === false);
      this.hideSaveAndCancelButtons = this.data.every((field: DataSchemaElementRow) => field.isUpdatable === false);

    }

    this.setInitialData();
  }

  setInitialData() {
    this.initialData = cloneDeep(this.data);
  }

  submit() {
    const lastIndex = this.data?.reduce((acc, curr) => {
      if (curr.index > acc) {
        return curr.index;
      } else {
        return acc;
      }
    }, 0);
    const newRowData = { ...cloneDeep(INITIAL_ITEM), index: lastIndex };
    // set default typeId as string
    const defaultDataSchema = this.dataSchemaService.getDefaultDataSchema(this.dataSchemas);
    if (defaultDataSchema) {
      newRowData.typeId = defaultDataSchema.id;
    }

    if (this.hasDefault) {
      newRowData.default = null;
    }

    if (this.type === DataSchemaFieldRowType.InputDefinition) {
      newRowData.fieldName = null;
      newRowData.uiDataSelector = CrudViewFormItemType.TextBox;
      newRowData.category = null;
      newRowData.title = null;
    } else if (this.type === DataSchemaFieldRowType.OutputDefinition) {
      newRowData.fieldName = null;
    } else {
      newRowData.name = null;
    }
    this.data = [
      ...this.data,
      newRowData
    ];
  }

  onSave() {
    this.validateForm();
    if (this.formState.isValid && this.formState.isSubmit) {
      let dataDifferences;
      if (this.initialData?.length === 1 && !this.initialData[0].id) {
        dataDifferences = {
          createdArray: this.data
        };
      } else {
        dataDifferences = this.dataTransformService.compareArrays(this.initialData, this.data);
      }

      const dataToEmit = <DataSchemaElementRowOutput>{
        created: dataDifferences.createdArray,
        updated: dataDifferences.updatedArray,
        deleted: dataDifferences.deletedArray
      };
      this.saveButtonClick.emit(dataToEmit);
    }
  }

  onCancel() {
    this.data = cloneDeep(this.initialData);
    this.cancelButtonClick.emit();
  }

  createType(dataSchema: any, index: number) {
    if (dataSchema?.name) {
      const dataSchemaData = {
        ...dataSchema,
        projectId: this.activeProjectId,
        isPrimitive: false,
        type: DataSchemaTypes.Model
      };
      this.dataSchemaApi.create(dataSchemaData).subscribe((createdDataSchema: DataSchemaInterface) => {
        this.typeOptions.push({
          label: createdDataSchema.name,
          value: createdDataSchema.id,
          groupLabel: !dataSchema.isPrimitive ? 'Custom' : 'Primitive'
        });
        this.typeOptions = orderBy(this.typeOptions, 'groupLabel', 'desc');
        this.data[index].typeId = dataSchema.id;
        this.data[index].default = null;
        this.isSelectboxOpen = false;
      });
    }
  }

  getTypes() {
    return this.store.select(<any>getDataSchemasWithDetailsSelector).subscribe((dataSchemas: DataSchemaWithRelations[]) => {
      this.dataSchemas = this.dataSchemaService
        .getDataSchemasByDataSchemaFieldRowPages(this.activeProjectId, this.dataSchemaPageType, dataSchemas);
      this.setTypeOptions();
    });
  }

  subscribeToDataSchemaLoading() {
    return this.store.select(state => state.dataSchema?.loading).subscribe((loading: boolean) => {
      this.isLoading = loading;
    });
  }

  setTypeOptions() {
    const filteredDataSchemas = this.dataSchemas.filter(dataSchema => dataSchema.category === DataSchemaCategory.ComponentConfig);
    this.typeOptionsForComponentSelector = filteredDataSchemas.map(
      (dataSchema: DataSchemaWithRelations) => ({
        label: dataSchema.name,
        value: dataSchema.id,
        groupLabel: null
      })
    );

    this.typeOptions = this.dataSchemas.map(
      (dataSchema: DataSchemaWithRelations) => ({
        label: dataSchema.name,
        value: dataSchema.id,
        groupLabel: !dataSchema.isPrimitive ? 'Custom' : 'Primitive'
      })
    );
    this.typeOptions = orderBy(this.typeOptions, 'groupLabel', 'desc');

    if (!this.isPayloadExist) {
      this.data[0].typeId = this.typeOptions?.find(typeOption => typeOption?.label === 'string')?.value;
    }
  }

  onModalSaveOrCancel() {
    this.validateData();
    this.setInitialData();
    this.setDefaultUIDataSelector();
  }

  onSearchTextChange(searchText: string) {
    this.formData = {
      name: searchText
    };
  }

  deleteItem(index: number) {
    this.data = this.data.filter((item, itemIndex) => itemIndex !== index);
  }

  validateForm() {
    /* filters data for invalid type */
    this.invalidTypeIndices = this.data.map((item, index) => {
      if (!item.typeId) {
        return index;
      }
    }).filter(item => item !== undefined);

    /* filters data for invalid name  */
    this.invalidNameIndices = this.data.map((item, index) => {
      /* checks isInputDefinition because if dataSchemaField is input definition it  has a variable called fieldName instead of name  */
      if (!(
        [DataSchemaFieldRowType.OutputDefinition, DataSchemaFieldRowType.InputDefinition].includes(this.type)
      )
        ? !item.name
        : !item.fieldName) {
        return index;
      }
    }).filter(item => item !== undefined);

    const hasInvalidName = this.names.toArray().some((item: any) => item.control.status === ControlStatus.Invalid);
    /* checks if data has duplicate name */
    const hasDuplicatedName = this.data.some((firstItem, index) => this.data.some(
      /* checks isInputDefinition because if dataSchemaField is input definition it  has a variable called fieldName instead of name  */
      (item, secondIndex) => (
        !(this.type === DataSchemaFieldRowType.InputDefinition || this.type === DataSchemaFieldRowType.OutputDefinition)
          ? item.name === firstItem.name
          : item.fieldName === firstItem.fieldName
      ) && index !== secondIndex)
    );

    this.formState.isValid = !this.invalidTypeIndices?.length && !this.invalidNameIndices?.length && !hasInvalidName && !hasDuplicatedName;

    this.formState.isSubmit = true;
  }

  showError(index: number, field: string) {
    if (field === 'type') {
      return this.invalidTypeIndices?.some(item => item === index) && this.formState.isSubmit;
    } else if (field === 'name') {
      return this.invalidNameIndices?.some(item => item === index) && this.formState.isSubmit;
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.data, event.previousIndex, event.currentIndex);
    this.data = this.data.map((item, index) => ({
      ...item,
      index: index
    }));
  }

  isNameDuplicated(row: DataSchemaElementRow, rowIndex: number, fieldName = 'name') {
    return this.data.some((item, index) => item[fieldName] === row[fieldName] && rowIndex !== index);
  }

  setDefaultUIDataSelector() {
    if (this.type === DataSchemaFieldRowType.InputDefinition || DataSchemaFieldRowType.Default) {
      this.defaultUIDataSelectors = Object.entries(CrudViewFormItemType)
        .map(([key, value]) => ({
          key: key,
          value: value
        }));
      const component = {
        key: 'Component',
        value: 'component'
      };
      this.defaultUIDataSelectors.push(component);
      this.defaultUIDataSelectors = orderBy(this.defaultUIDataSelectors, 'key', 'asc');
    }
  }

  openDefaultModal(index: number) {
    this.localCodemirrorValue = JSON.stringify(this.data[index].default);
    setTimeout(() => this.isDefaultCodemirrorVisible = true, 500);

    this.initialValueModal = this.nzModalService.create({
      nzTitle: this.translateService.instant('PROJECT_MODULE.DATA_SCHEMA_MODULE.DATA_SCHEMA_ELEMENT_ROW_COMPONENT.EDIT_INITIAL_VALUE'),
      nzWidth: this.modalSize,
      nzContent: this.initialValueModalTemplate,
      nzFooter: [
        {
          label: this.translateService.instant('SHARED.CANCEL'),
          type: 'default',
          onClick: () => this.closeDefaultModal()
        },
        {
          label: this.translateService.instant('SHARED.SAVE'),
          type: 'primary',
          onClick: () => this.changeDefaultValue(index)
        }
      ]
    });
  }

  closeDefaultModal() {
    this.isDefaultCodemirrorVisible = false;
    this.initialValueModal.destroy();
  }

  changeDefaultValue(index: number) {
    try {
      JSON.parse(this.localCodemirrorValue);

      const body = this.data[index] as any;
      const dataSchemaId = body.typeId;
      const options = { isArray: body.isArray };

      const params = {
        dataSchemaId: dataSchemaId,
        options: options,
        body: JSON.parse(this.localCodemirrorValue)
      };

      this.dataSchemaControllerService.validateDataByDataSchemaId(params).subscribe(() => {
        this.data[index].default = JSON.parse(this.localCodemirrorValue);

        /* to trigger setter */
        this.closeDefaultModal();
      }, error => {
        this.notificationService.createNotification(
          'error',
          'SHARED.ERROR',
          error?.error?.error?.message[0]?.message
        );
      });

    } catch (error) {
      this.notificationService.createNotification('error', 'SHARED.ERROR', 'CRUD_VIEW_MODULE.JSON_ARRAY_COMPONENT.WRONG_JSON_FORMAT');
    }
  }

  /**
   * checks if data scheme field type object
   *
   * @param {number} index
   * @memberof DataSchemaElementRowComponent
   */
  isCodemirrorVisible(item: DataSchemaElementRow) {
    const dataSchemaFieldType = this.getDataSchemaTypeByTypeId(item.typeId);
    return dataSchemaFieldType?.isPrimitive === false
      || item.isArray
      || [PrimitiveDataSchemas.Any, PrimitiveDataSchemas.Object].includes(<PrimitiveDataSchemas>dataSchemaFieldType?.name);
  }

  compareFieldTypeAndComponentType(item: DataSchemaElementRow, type: PrimitiveDataSchemas) {
    const dataSchemaFieldType = this.getDataSchemaTypeByTypeId(item.typeId);
    const isFormatSupported = [PrimitiveDataSchemas.Boolean, PrimitiveDataSchemas.Number, PrimitiveDataSchemas.String]
      .includes(<PrimitiveDataSchemas>dataSchemaFieldType?.name);
    if (isFormatSupported && dataSchemaFieldType) {
      return type === dataSchemaFieldType.name;
    } else {
      return type === PrimitiveDataSchemas.String;
    }
  }

  getDataSchemaTypeByTypeId(typeId: string) {
    return this.dataSchemas.find(dataSchema => dataSchema.id === typeId);
  }

  onTypeChange(index: number) {
    this.data[index].default = null;
  }

  onDataSchemaCreated(dataSchema: DataSchemaInterface, index: number) {
    this.data[index].typeId = dataSchema.id;
    this.data = [...this.data];
  }

  clearDefault(dataSchemaField: DataSchemaField) {
    if (dataSchemaField.isUpdatable) {
      dataSchemaField.default = null;
    }
  }

}
