/* eslint-disable no-shadow */
import { Action } from '@ngrx/store';
import { DataSchemaField, ProjectModel } from '@rappider/rappider-sdk';
import { NewUiDataStore, UiDataStore, UiDataStorePartial, UiDataStoreWithRelations } from '@rappider/rappider-sdk';

export enum ActionTypes {
  LoadModule = '[UIDataStore] LoadModule',
  GetUIDataStores = '[UIDataStore] GetUIDataStores',
  GetUIDataStoresFailure = '[UIDataStore] GetUIDataStoresFailure',
  GetUIDataStoresAccordingToId = '[UIDataStore] GetUIDataStoresAccordingToId',
  CreateUIDataStoresAccordingToIdSuccessful = '[UIDataStore] CreateUIDataStoresAccordingToIdSuccessful',
  GetUIDataStoresAccordingToIdFailure = '[UIDataStore] GetUIDataStoresAccordingToIdFailure',
  SetUIDataStores = '[UIDataStore] SetUIDataStores',
  SetLoadingState = '[UIDataStore] SetLoadingState',
  CreateUIDataStore = '[UIDataStore] CreateUIDataStore',
  DeleteUIDataStore = '[UIDataStore] DeleteUIDataStore',
  UpdateUIDataStore = '[UIDataStore] UpdateUIDataStore',
  CreateUIDataStoreSuccessful = '[UIDataStore] CreateUIDataStoreSuccessful',
  UpdateUIDataStoreSuccessful = '[UIDataStore] UpdateUIDataStoreSuccessful',
  GetAutoGeneratedDataStoreSuccessful = '[UIDataStore] GetAutoGeneratedDataStoreSuccessful',
  GetUIDataStore = '[UIDataStore] GetUIDataStore',
  GetUIDataStoreSuccessful = '[UIDataStore] GetUIDataStoreSuccessful',
  UpdateDataSchemaFieldsOfUIDataStore = '[UIDataStore] UpdateDataSchemaFieldOfUIDataStore',
  CreateDataSchemaFieldsOfUIDataStore = '[UIDataStore] CreateDataSchemaFieldOfUIDataStore',
  DeleteDataSchemaFieldsOfUIDataStore = '[UIDataStore] DeleteDataSchemaFieldsOfUIDataStore',
  GetUIDataStoreByProjectModelId = '[UIDataStore] GetUIDataStoreByProjectModelId',
  GetUIDataStoreFieldsLoadingByModelId = '[UIDataStore] GetUIDataStoreFieldsLoadingByModelId',
  ErrorAction = '[UIDataStore] ErrorAction'
}

export class LoadModule implements Action {
  readonly type = ActionTypes.LoadModule;
}

export class GetUIDataStores implements Action {
  readonly type = ActionTypes.GetUIDataStores;
}

export class GetUIDataStoresFailure implements Action {
  readonly type = ActionTypes.GetUIDataStoresFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class GetUIDataStoresAccordingToId implements Action {
  readonly type = ActionTypes.GetUIDataStoresAccordingToId;
  constructor(public payload: { id: string }) { }
}

export class CreateUIDataStoresAccordingToIdSuccessful implements Action {
  readonly type = ActionTypes.CreateUIDataStoresAccordingToIdSuccessful;
  constructor(public payload: { uiDataStore: UiDataStoreWithRelations }) { }
}

export class GetUIDataStoresAccordingToIdFailure implements Action {
  readonly type = ActionTypes.GetUIDataStoresAccordingToIdFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class SetUIDataStores implements Action {
  readonly type = ActionTypes.SetUIDataStores;
  constructor(public payload: { uiDataStores: UiDataStoreWithRelations[] }) { }
}

export class UpdateDataSchemaFieldsOfUIDataStore implements Action {
  readonly type = ActionTypes.UpdateDataSchemaFieldsOfUIDataStore;
  constructor(public payload: { updatedDataSchemaFields: DataSchemaField[]; parentDataSchemaId: string }) { }
}

export class CreateDataSchemaFieldsOfUIDataStore implements Action {
  readonly type = ActionTypes.CreateDataSchemaFieldsOfUIDataStore;
  constructor(public payload: { createdDataSchemaFields: DataSchemaField[]; parentDataSchemaId: string }) { }
}

export class DeleteDataSchemaFieldsOfUIDataStore implements Action {
  readonly type = ActionTypes.DeleteDataSchemaFieldsOfUIDataStore;
  constructor(public payload: { deletedDataSchemaFieldIds: string[]; parentDataSchemaId: string }) { }
}

export class SetLoadingState implements Action {
  readonly type = ActionTypes.SetLoadingState;
  constructor(public payload: { loading: boolean }) { }
}

export class CreateUIDataStore implements Action {
  readonly type = ActionTypes.CreateUIDataStore;
  constructor(public payload: { uiDataStore: NewUiDataStore }) { }
}

export class CreateUIDataStoreSuccessful implements Action {
  readonly type = ActionTypes.CreateUIDataStoreSuccessful;
  constructor(public payload: { uiDataStore: UiDataStoreWithRelations }) { }
}

export class GetUIDataStoreSuccessful implements Action {
  readonly type = ActionTypes.GetUIDataStoreSuccessful;
  constructor(public payload: { uiDataStore: UiDataStoreWithRelations }) { }
}

export class GetUIDataStore implements Action {
  readonly type = ActionTypes.GetUIDataStore;
  constructor(public payload: { uiDataStoreId: string; navigateAfter?: boolean }) { }
}


export class UpdateUIDataStoreSuccessful implements Action {
  readonly type = ActionTypes.UpdateUIDataStoreSuccessful;
  constructor(public payload: { id: string; uiDataStore: UiDataStorePartial }) { }
}

export class UpdateUIDataStore implements Action {
  readonly type = ActionTypes.UpdateUIDataStore;
  constructor(public payload: { id: string; uiDataStore: UiDataStorePartial }) { }
}

export class DeleteUIDataStore implements Action {
  readonly type = ActionTypes.DeleteUIDataStore;
  constructor(public payload: { uiDataStoreId: string }) { }
}

export class GetAutoGeneratedDataStoreSuccessful implements Action {
  readonly type = ActionTypes.GetAutoGeneratedDataStoreSuccessful;
  constructor(public payload: { uiDataStore: UiDataStoreWithRelations }) { }
}

export class GetUIDataStoreByProjectModelId implements Action {
  readonly type = ActionTypes.GetUIDataStoreByProjectModelId;
  constructor(public payload: { projectModelId: string; tryCount: number }) { }
}

export class GetUIDataStoreFieldsLoadingByModelId implements Action {
  readonly type = ActionTypes.GetUIDataStoreFieldsLoadingByModelId;
  constructor(public payload: { projectModelId: string }) { }
}

export class ErrorAction implements Action {
  readonly type = ActionTypes.ErrorAction;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export type Actions =
  LoadModule
  | GetUIDataStores
  | GetUIDataStoresFailure
  | GetUIDataStoresAccordingToId
  | CreateUIDataStoresAccordingToIdSuccessful
  | GetUIDataStoresAccordingToIdFailure
  | SetUIDataStores
  | SetLoadingState
  | CreateUIDataStore
  | DeleteUIDataStore
  | UpdateUIDataStore
  | CreateUIDataStoreSuccessful
  | UpdateUIDataStoreSuccessful
  | GetAutoGeneratedDataStoreSuccessful
  | GetUIDataStoreSuccessful
  | GetUIDataStore
  | ErrorAction
  | CreateDataSchemaFieldsOfUIDataStore
  | UpdateDataSchemaFieldsOfUIDataStore
  | DeleteDataSchemaFieldsOfUIDataStore
  | GetUIDataStoreByProjectModelId
  | GetUIDataStoreFieldsLoadingByModelId
  ;
