import { Injectable } from '@angular/core';
import { DataSchemaTypes } from 'libs/project/src/lib/modules/data-schema/models/data-schema-type.enum';
import { DataSchemaCategory } from 'libs/project/src/lib/modules/data-schema/models/data-schema-category.enum';
import { DEFAULT_DATA_SCHEMA_FIELD_TYPE } from '../../definitions/default-data-schema-field-type';
import { DataSchemaFieldRowPages } from '@rappider/shared/interfaces';
import { DataSchemaInterface } from '../../sdk/models/DataSchema';
import {
  dataSchemasToDisplayInDataEvent,
  dataSchemasToDisplayInDataStore,
  dataSchemasToDisplayInInputOutputDefinition,
  dataSchemasToDisplayInModelField,
  dataSchemasToDisplayInUserGeneratedDataSchemaList
} from '../../definitions/basic-data-schema-categories';
import { DataSchema, DataSchemaWithRelations } from '@rappider/rappider-sdk';

@Injectable({
  providedIn: 'root'
})
export class DataSchemaService {


  getDataSchemasByDataSchemaFieldRowPages(
    activeProjectId: string,
    dataSchemaFieldRowPages: DataSchemaFieldRowPages,
    dataSchemas: DataSchemaWithRelations[]
  ) {
    if (dataSchemaFieldRowPages === DataSchemaFieldRowPages.InputDefinition
      || dataSchemaFieldRowPages === DataSchemaFieldRowPages.OutputDefinition) {
      return dataSchemas.filter((dataSchema: DataSchemaWithRelations) => (
        dataSchema.isPrimitive === true
        || dataSchemasToDisplayInInputOutputDefinition.includes(<DataSchemaCategory>dataSchema.category)
        || (
          dataSchema.projectId === activeProjectId &&
          dataSchema.type === DataSchemaTypes.Model
        )
      ));
    } else if (dataSchemaFieldRowPages === DataSchemaFieldRowPages.DataStore) {
      return dataSchemas.filter((dataSchema: DataSchemaWithRelations) => (
        dataSchema.isPrimitive === true
        || dataSchema.projectId === activeProjectId
        && dataSchemasToDisplayInDataStore.includes(<DataSchemaCategory>dataSchema.category)
      ));
    } else if (dataSchemaFieldRowPages === DataSchemaFieldRowPages.DataEvent) {
      return dataSchemas.filter((dataSchema: DataSchemaWithRelations) => (
        dataSchema.isPrimitive === true
        || dataSchema.projectId === activeProjectId
        && dataSchemasToDisplayInDataEvent.includes(<DataSchemaCategory>dataSchema.category)
      ));
    } else if (dataSchemaFieldRowPages === DataSchemaFieldRowPages.ModelField) {
      return dataSchemas.filter((dataSchema: DataSchemaWithRelations) => (
        dataSchema.isPrimitive === true
        || dataSchema.projectId === activeProjectId
        && dataSchemasToDisplayInModelField.includes(<DataSchemaCategory>dataSchema.category)
      ));
    } else if (dataSchemaFieldRowPages === DataSchemaFieldRowPages.UserGenerated) {
      return dataSchemas.filter((dataSchema: DataSchemaWithRelations) => (
        !dataSchema.isPrimitive
        && dataSchema.projectId === activeProjectId
        && dataSchemasToDisplayInUserGeneratedDataSchemaList.includes(<DataSchemaCategory>dataSchema.category)
      ));
    }
  }

  getDefaultDataSchema(dataSchemas: DataSchema[]) {
    return dataSchemas?.find(dataSchema => dataSchema.isPrimitive && dataSchema.name === DEFAULT_DATA_SCHEMA_FIELD_TYPE);
  }

}
