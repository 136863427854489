/* eslint-disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UIDataStore } from '../../models/UIDataStore';
import { SocketConnection } from '../../sockets/socket.connections';
import { Project } from '../../models/Project';
import { UIDataEvent } from '../../models/UIDataEvent';
import { DataSchema } from '../../models/DataSchema';

/**
 * Api services for the `UIDataStore` model.
 */
@Injectable()
export class UIDataStoreApi extends BaseLoopBackApi {
  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SocketConnection) protected connection: SocketConnection,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http, connection, models, auth, errorHandler);
  }

  /**
   * Fetches belongsTo relation project.
   *
   * @param {any} id UIDataStore id
   *
   * @param {boolean} refresh
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIDataStore` object.)
   * </em>
   */
  public getProject(
    id: any,
    refresh: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string = LoopBackConfig.getPath() + '/ui-data-stores/:id/project';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null)
      _urlParams.refresh = refresh;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Find a related item by id for events.
   *
   * @param {any} id UIDataStore id
   *
   * @param {any} fk Foreign key for events
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIDataStore` object.)
   * </em>
   */
  public findByIdEvents(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() + '/ui-data-stores/:id/events/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Delete a related item by id for events.
   *
   * @param {any} id UIDataStore id
   *
   * @param {any} fk Foreign key for events
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdEvents(
    id: any,
    fk: any,
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string =
      LoopBackConfig.getPath() + '/ui-data-stores/:id/events/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Update a related item by id for events.
   *
   * @param {any} id UIDataStore id
   *
   * @param {any} fk Foreign key for events
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIDataStore` object.)
   * </em>
   */
  public updateByIdEvents(
    id: any,
    fk: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PUT';
    let _url: string =
      LoopBackConfig.getPath() + '/ui-data-stores/:id/events/:fk';
    let _routeParams: any = {
      id: id,
      fk: fk,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Fetches belongsTo relation schema.
   *
   * @param {any} id UIDataStore id
   *
   * @param {boolean} refresh
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIDataStore` object.)
   * </em>
   */
  public getSchema(
    id: any,
    refresh: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string = LoopBackConfig.getPath() + '/ui-data-stores/:id/schema';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null)
      _urlParams.refresh = refresh;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Queries events of UIDataStore.
   *
   * @param {any} id UIDataStore id
   *
   * @param {object} filter
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIDataStore` object.)
   * </em>
   */
  public getEvents(
    id: any,
    filter: LoopBackFilter = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string = LoopBackConfig.getPath() + '/ui-data-stores/:id/events';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null)
      _urlParams.filter = filter;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in events of this model.
   *
   * @param {any} id UIDataStore id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIDataStore` object.)
   * </em>
   */
  public createEvents(
    id: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string = LoopBackConfig.getPath() + '/ui-data-stores/:id/events';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Deletes all events of this model.
   *
   * @param {any} id UIDataStore id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteEvents(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = 'DELETE';
    let _url: string = LoopBackConfig.getPath() + '/ui-data-stores/:id/events';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Counts events of UIDataStore.
   *
   * @param {any} id UIDataStore id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` -
   */
  public countEvents(
    id: any,
    where: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'GET';
    let _url: string =
      LoopBackConfig.getPath() + '/ui-data-stores/:id/events/count';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null)
      _urlParams.where = where;
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Patch an existing model instance or insert a new one into the data source.
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - Model instance data
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIDataStore` object.)
   * </em>
   */
  public patchOrCreate(
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PATCH';
    let _url: string = LoopBackConfig.getPath() + '/ui-data-stores';
    let _routeParams: any = {};
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   * @param {any} id UIDataStore id
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - An object of model property name/value pairs
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIDataStore` object.)
   * </em>
   */
  public patchAttributes(
    id: any,
    data: any = {},
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'PATCH';
    let _url: string = LoopBackConfig.getPath() + '/ui-data-stores/:id';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * Creates a new instance in events of this model.
   *
   * @param {any} id UIDataStore id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIDataStore` object.)
   * </em>
   */
  public createManyEvents(
    id: any,
    data: any[] = [],
    customHeaders?: Function
  ): Observable<any> {
    let _method: string = 'POST';
    let _url: string = LoopBackConfig.getPath() + '/ui-data-stores/:id/events';
    let _routeParams: any = {
      id: id,
    };
    let _postBody: any = {
      data: data,
    };
    let _urlParams: any = {};
    let result = this.request(
      _method,
      _url,
      _routeParams,
      _urlParams,
      _postBody,
      null,
      customHeaders
    );
    return result;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `UIDataStore`.
   */
  public getModelName() {
    return 'UIDataStore';
  }
}
