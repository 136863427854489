import { DataSchemaCategory } from 'libs/project/src/lib/modules/data-schema/models/data-schema-category.enum';

export const dataSchemasToDisplayInDataStore = [
    DataSchemaCategory.UserGenerated,
    DataSchemaCategory.ProjectModel
];

export const dataSchemasToDisplayInDataEvent = [
    DataSchemaCategory.UserGenerated,
    DataSchemaCategory.ProjectModel
];

export const dataSchemasToDisplayInModelField = [
    DataSchemaCategory.UserGenerated,
    DataSchemaCategory.ProjectModel
];

export const dataSchemasToDisplayInInputOutputDefinition = [
    DataSchemaCategory.ComponentIOType,
    DataSchemaCategory.ComponentConfig
];

export const dataSchemasToDisplayInUserGeneratedDataSchemaList = [
    DataSchemaCategory.UserGenerated,
    DataSchemaCategory.ProjectModel
];
