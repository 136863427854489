/* eslint-disable */

declare var Object: any;
export interface UserInvitationInterface {
  id?: string;
  registeredUserId?: string;
  invitedUsername: string;
  name: string;
  invitedUsernameType: string;
  invitedProjectId: string;
  registeredDate?: Date;
  status: string;
  statusUpdateDate?: Date;
  statusDescription?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
}

export class UserInvitation implements UserInvitationInterface {
  'id'?: string;
  'registeredUserId': string;
  'invitedUsername': string;
  'name': string;
  'invitedUsernameType': string;
  'invitedProjectId': string;
  'registeredDate': Date;
  'status': string;
  'statusUpdateDate': Date;
  'statusDescription': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  constructor(data?: UserInvitationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UserInvitation`.
   */
  public static getModelName() {
    return 'UserInvitation';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of UserInvitation for dynamic purposes.
   **/
  public static factory(data: UserInvitationInterface): UserInvitation {
    return new UserInvitation(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'UserInvitation',
      plural: 'UserInvitations',
      path: 'user-invitations',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        registeredUserId: {
          name: 'registeredUserId',
          type: 'string',
        },
        invitedUsername: {
          name: 'invitedUsername',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        invitedUsernameType: {
          name: 'invitedUsernameType',
          type: 'string',
        },
        invitedProjectId: {
          name: 'invitedProjectId',
          type: 'string',
        },
        registeredDate: {
          name: 'registeredDate',
          type: 'Date',
        },
        status: {
          name: 'status',
          type: 'string',
        },
        statusUpdateDate: {
          name: 'statusUpdateDate',
          type: 'Date',
        },
        statusDescription: {
          name: 'statusDescription',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
      },
      relations: {},
    };
  }
}
