import { Validators } from '@angular/forms';
import { CardDataMapConfig } from '@rappider/rappider-components';
import { ActionBehavior, ButtonType, CrudFormConfig, CrudFormMonacoCodeEditorItem, CrudFormSelectItem, CrudTableViewConfig, CrudViewColumnType, CrudViewFormItemType, FormLayout, HeadingType } from '@rappider/rappider-components/utils';
import { CustomFunctionDefinitionWithRelations } from '@rappider/rappider-sdk';
import { PROGRAMMING_LANGUAGES } from './programming-languages';
import * as moment from 'moment';
import { PACKAGE_CONFIG } from './package.config';
import { POP_CONFIRM_DELETE_ACTION } from 'libs/shared/src/lib/configs/pop-confirm-button/pop-confirm-button-config';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';

export const MODEL_NAME_SINGULAR = 'Workflow Function';
export const LIST_ITEMS_TITLE = 'Workflow Functions';
export const CREATE_ITEM_TITLE = 'Create Workflow Function';
export const EDIT_ITEM_TITLE = 'Edit Workflow Function';

export const CUSTOM_FUNCTION_LIST_CONFIG: CrudTableViewConfig = {
  searchable: true,
  multipleSearchFields: ['functionName', 'serviceName'],
  sortable: true,
  columns:
    [
      {
        title: 'Service Name',
        type: CrudViewColumnType.Text,
        fieldName: 'serviceName',
        visible: true
      },
      {
        title: 'Function Name',
        type: CrudViewColumnType.Text,
        fieldName: 'functionName',
        visible: true
      },
      {
        title: 'Programming Language',
        type: CrudViewColumnType.Text,
        fieldName: 'programmingLanguage',
        visible: true
      },
      {
        title: 'Created By',
        type: CrudViewColumnType.Text,
        fieldName: 'createdBy',
        visible: true
      }
    ],
  orderable: false,
  listActions: [],
  itemActions: [
    {
      text: 'Edit',
      name: 'edit-item',
      behavior: ActionBehavior.Route,
      redirectUrl: `${PATH_DEFINITIONS.WORKFLOW_FUNCTIONS.WORKFLOW_FUNCTION_EDIT}/{{id}}`,
      icon: { name: 'far fa-edit' },
    },
    {
      text: POP_CONFIRM_DELETE_ACTION.text,
      name: 'delete-item',
      popconfirmTitle: POP_CONFIRM_DELETE_ACTION.popconfirmTitle,
      emitWithoutPopconfirm: POP_CONFIRM_DELETE_ACTION.emitWithoutPopconfirm,
      behavior: POP_CONFIRM_DELETE_ACTION.behavior,
      icon: POP_CONFIRM_DELETE_ACTION.icon,
      popConfirmCancelText: POP_CONFIRM_DELETE_ACTION.popConfirmCancelText,
      popConfirmOkText: POP_CONFIRM_DELETE_ACTION.popConfirmOkText,
      popConfirmOkDanger: POP_CONFIRM_DELETE_ACTION.popConfirmOkDanger,
    }
  ]
};

export const CUSTOM_FUNCTION_CREATE_OR_UPDATE_FORM_CONFIG: CrudFormConfig = {
  formLayout: FormLayout.Horizontal,
  items: [
    {
      title: 'Service Name',
      type: CrudViewFormItemType.TextBox,
      fieldName: 'serviceName',
      showRequiredSign: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'This field is required'
        }
      ],
    },
    {
      title: 'Function Name',
      type: CrudViewFormItemType.TextBox,
      fieldName: 'functionName',
      showRequiredSign: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'This field is required'
        }
      ],
    },
    <CrudFormSelectItem>
    {
      title: 'Programming Language',
      fieldName: 'programmingLanguage',
      type: CrudViewFormItemType.Select,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ],
      options: PROGRAMMING_LANGUAGES
    },
    PACKAGE_CONFIG,
    <CrudFormMonacoCodeEditorItem>{
      title: 'Code',
      type: CrudViewFormItemType.MonacoCodeEditor,
      fieldName: 'code',
      showRequiredSign: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'This field is required'
        }
      ],
      editorOptions:
      {
        theme: 'vs',
        language: 'javascript',
        lineNumbers: { renderType: 0 },
        minimap: {
          enabled: false,
        },
      },
      sizeSettings: {
        minHeight: '200px'
      },
    },
    <CrudFormMonacoCodeEditorItem>{
      title: 'Request JSON Sample',
      type: CrudViewFormItemType.MonacoCodeEditor,
      fieldName: 'requestJSONSchema',
      showRequiredSign: false,
      editorOptions:
      {
        theme: 'vs',
        language: 'json',
        lineNumbers: { renderType: 0 },
        minimap: {
          enabled: false,
        },
      },
      sizeSettings: {
        minHeight: '200px'
      },
    },
    <CrudFormMonacoCodeEditorItem>{
      title: 'Response JSON Sample',
      type: CrudViewFormItemType.MonacoCodeEditor,
      fieldName: 'responseJSONSchema',
      showRequiredSign: false,
      editorOptions:
      {
        theme: 'vs',
        language: 'json',
        lineNumbers: { renderType: 0 },
        minimap: {
          enabled: false,
        },
      },
      sizeSettings: {
        minHeight: '200px'
      },
    },
  ],
  itemSettings: {
  },
  submitButton: { type: ButtonType.Primary }
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const LABEL_CARDS_LIST_CONFIG: CardDataMapConfig = {
  titleFieldName: 'functionName',
  cardItemClickBehavior: ActionBehavior.Route,
  getSubTitlesFunction: (customFunction: CustomFunctionDefinitionWithRelations) => {
    const labelSubtitles = [];
    if (customFunction.serviceName) {
      labelSubtitles.push({ content: customFunction.serviceName, type: HeadingType.H5 });
    }
    if (customFunction.createdDate) {
      labelSubtitles.push(
        // eslint-disable-next-line max-len
        { content: `Created on ${moment(customFunction.createdDate).format('MMM D, YY')}`, type: HeadingType.H6 });
    }
    if (customFunction.createdBy) {
      labelSubtitles.push({ content: customFunction.createdBy, type: HeadingType.H6 });
    }
    return labelSubtitles;
  }
};
