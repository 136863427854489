import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { NotificationService } from '@rappider/services';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { NewUiDataUpdateFunction, UiDataUpdateFunction, UiDataUpdateFunctionControllerService, UiDataUpdateFunctionWithRelations } from '@rappider/rappider-sdk';
import { PATH_DEFINITIONS } from 'libs/shared/src/lib/definitions/path-definition';

import * as UIDataUpdateFunctionActions from './ui-data-update-function.actions';
import * as UIDataStoreActions from '../ui-data-store/ui-data-store.actions';
import * as RouterActions from '@rappider/shared';
import { orderBy } from 'lodash';
@Injectable()
export class UIDataUpdateFunctionEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private notificationService: NotificationService,
    private UIDataUpdateFunctionApi: UiDataUpdateFunctionControllerService,
  ) { }


  getUIDataUpdateFunctions$ = createEffect(() => this.actions$.pipe(
    ofType<UIDataStoreActions.SetUIDataStores>(
      UIDataStoreActions.ActionTypes.SetUIDataStores
    ),
    mergeMap((action) => {
      if (action.payload.uiDataStores?.length) {
        const params = {
          filter: {
            where: {
              or: action.payload.uiDataStores.map(uiDataStore => ({ uiDataStoreId: uiDataStore.id }))
            }
          },
        };
        return this.UIDataUpdateFunctionApi.find(params).pipe(
          mergeMap((uiDataUpdateFunctions: UiDataUpdateFunctionWithRelations[]) =>
            [(new UIDataUpdateFunctionActions.GetUIDataUpdateFunctionsSuccessful(
              { uiDataUpdateFunctions: uiDataUpdateFunctions }))]
          )
        );
      } else {
        return [new UIDataUpdateFunctionActions.GetUIDataUpdateFunctionsFailure({ error: 'NoUIDataStore', key: 'GetUIDataUpdateFunctions', timestamp: Date.now() })];
      }
    }), catchError(error => {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'SHARED.COULDNT_LOAD'
      );
      return [new UIDataUpdateFunctionActions.GetUIDataUpdateFunctionsFailure({ error: { error }, key: 'GetUIDataUpdateFunctions', timestamp: Date.now() })];
    })
  ));


  createUIDataUpdateFunction$ = createEffect(() => this.actions$.pipe(
    ofType<UIDataUpdateFunctionActions.CreateUIDataUpdateFunction>(
      UIDataUpdateFunctionActions.ActionTypes.CreateUIDataUpdateFunction
    ),
    withLatestFrom(
      this.store.select(state => state.uiDataEvent?.data)
    ),
    mergeMap(([action, uiDataEvents]) => this.UIDataUpdateFunctionApi.create({
      body: <NewUiDataUpdateFunction>{
        ...action.payload.uiDataUpdateFunction,
        uiDataStoreId: action.payload.activeUIDataStoreId
      }
    }).pipe(
      mergeMap((uiDataUpdateFunction: UiDataUpdateFunction) => {
        const uiDataUpdateFunctionPayload = action.payload.uiDataUpdateFunction;
        const uiDataUpdateFunctionName = uiDataEvents.find(uiDataEvent => uiDataEvent.id === uiDataUpdateFunctionPayload.uiDataEventId);
        this.notificationService.createNotification(
          'success',
          uiDataUpdateFunctionName.name,
          'SHARED.SUCCESSFULLY_CREATED'
        );

        const publishedActions: Action[] = [
          new UIDataUpdateFunctionActions.CreateUIDataUpdateFunctionSuccessful({ uiDataUpdateFunction })
        ];
        publishedActions.push(new RouterActions.AddQueryParam({ key: 'tab', value: 'data-event-update-function' }));
        publishedActions.push(new RouterActions.Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_DETAIL}/${uiDataUpdateFunction.uiDataStoreId}` }));

        return publishedActions;
      }), catchError(error => {
        this.notificationService.createNotification(
          'error',
          'UI_DATA_EVENT_UPDATE_FUNCTION',
          'SHARED.COULDNT_CREATED'
        );
        return [new UIDataUpdateFunctionActions.CreateUIDataUpdateFunctionFailure({ error: { error }, key: 'CreateUIDataUpdateFunction', timestamp: Date.now() })];
      })
    ))
  ));


  updateUIDataUpdateFunction$ = createEffect(() => this.actions$.pipe(
    ofType<UIDataUpdateFunctionActions.UpdateUIDataUpdateFunction>(
      UIDataUpdateFunctionActions.ActionTypes.UpdateUIDataUpdateFunction
    ),
    withLatestFrom(
      this.store.select(state => state.uiDataUpdateFunction?.data), this.store.select(state => state.uiDataEvent?.data)
    ),
    mergeMap(([action, uiDataUpdateFunctions, uiDataEvents]) => {
      const uiDataStoreId = uiDataUpdateFunctions.find(uiDataUpdateFunction => uiDataUpdateFunction.id === action.payload.id).uiDataStoreId;
      const uiDataUpdateFunction = action.payload.uiDataUpdateFunction;
      const uiDataUpdateFunctionName = uiDataEvents.find(event => event?.uiDataUpdateFunction?.id === action.payload.id)?.name;

      const updateBody = {
        id: action.payload.id,
        body: uiDataUpdateFunction
      };
      return this.UIDataUpdateFunctionApi.updateById(updateBody).pipe(
        mergeMap(() => {
          this.notificationService.createNotification(
            'success',
            uiDataUpdateFunctionName,
            'SHARED.SUCCESSFULLY_UPDATED'
          );

          const publishedActions: Action[] = [
            new UIDataUpdateFunctionActions.UpdateUIDataUpdateFunctionSuccessful({
              id: action.payload.id,
              uiDataUpdateFunction: uiDataUpdateFunction
            }),
            new RouterActions.AddQueryParam({ key: 'tab', value: 'data-event-update-function' }),
            new RouterActions.Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_DETAIL}/${uiDataStoreId}` })
          ];
          return publishedActions;
        }), catchError(error => {
          this.notificationService.createNotification(
            'error',
            uiDataUpdateFunctionName,
            'SHARED.COULDNT_UPDATED'
          );
          return [new UIDataUpdateFunctionActions.UpdateUIDataUpdateFunctionFailure({ error: { error }, key: 'UpdateUIDataUpdateFunction', timestamp: Date.now() })];
        })
      );
    })
  ));

  /**
   * gets updated ui data update function with details after a successful update
   *
   * @memberof UIDataUpdateFunctionEffects
   */


  deleteUIDataUpdateFunction$ = createEffect(() => this.actions$.pipe(
    ofType<UIDataUpdateFunctionActions.DeleteUIDataUpdateFunction>(
      UIDataUpdateFunctionActions.ActionTypes.DeleteUIDataUpdateFunction
    ),
    mergeMap((action) => this.UIDataUpdateFunctionApi.deleteById({ id: action.payload.id }).pipe(
      map(() => {
        this.notificationService.createNotification(
          'success',
          action.payload.dataEventName,
          'SHARED.SUCCESSFULLY_DELETED'
        );

        return new UIDataUpdateFunctionActions.DeleteUIDataUpdateFunctionSuccessful({ id: action.payload.id });
      }), catchError(error => {
        this.notificationService.createNotification(
          'error',
          action.payload.dataEventName,
          'SHARED.COULDNT_DELETED'
        );
        return [new UIDataUpdateFunctionActions.DeleteUIDataUpdateFunctionFailure({ error: { error }, key: 'UpdateUIDataUpdateFunction', timestamp: Date.now() })];
      })
    ))
  ));


  bulkCreateUIDataUpdateFunction$ = createEffect(() => this.actions$.pipe(
    ofType<UIDataUpdateFunctionActions.BulkCreateUIDataUpdateFunction>(
      UIDataUpdateFunctionActions.ActionTypes.BulkCreateUIDataUpdateFunction
    ),
    mergeMap((action) => {
      const params = {
        body: action.payload.uiDataUpdateFunctions
      };
      return this.UIDataUpdateFunctionApi.bulkCreate(params).pipe(
        mergeMap((uiDataUpdateFunctions: UiDataUpdateFunction[]) => {
          const uiDataUpdateFunctionPayload = action.payload.uiDataUpdateFunctions;
          const uiDataStoreIds = uiDataUpdateFunctionPayload.map(item => item.uiDataStoreId);
          const urlDataStoreId = uiDataStoreIds.find(id => id);
          this.notificationService.createNotification(
            'success',
            'All Selected Data Events Are Created',
            'SHARED.SUCCESSFULLY_CREATED'
          );

          const publishedActions: Action[] = [
            new UIDataUpdateFunctionActions.BulkCreateUIDataUpdateFunctionSuccessful({ uiDataUpdateFunctions })
          ];
          publishedActions.push(new RouterActions.AddQueryParam({ key: 'tab', value: 'data-event-update-function' }));
          publishedActions.push(new RouterActions.Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_DETAIL}/${urlDataStoreId}` }));

          return publishedActions;
        }), catchError(error => {
          this.notificationService.createNotification(
            'error',
            'UI_DATA_EVENT_UPDATE_FUNCTION',
            'SHARED.COULDNT_CREATED'
          );
          return [new UIDataUpdateFunctionActions.BulkCreateUIDataUpdateFunctionFailure({ error: error, key: 'CreateUIDataUpdateFunction', timestamp: Date.now() })];
        }));
    })
  ));


  getUIDataUpdateFunctionsByUIDataStoreId$ = createEffect(() => this.actions$.pipe(
    ofType<UIDataUpdateFunctionActions.GetUIDataUpdateFunctionsAccordingToUIDataStoreId>(
      UIDataUpdateFunctionActions.ActionTypes.GetUIDataUpdateFunctionsAccordingToUIDataStoreId
    ),
    mergeMap((action) => {
      const params = {
        filter: {
          where: {
            uiDataStoreId: action.payload.uiDataStoreId
          },
        },
      };
      return this.UIDataUpdateFunctionApi.find(params).pipe(
        map((uiDataUpdateFunctions: UiDataUpdateFunctionWithRelations[]) =>
          new UIDataUpdateFunctionActions.GetUIDataUpdateFunctionsAccordingToUIDataStoreIdSuccessful({ uiDataUpdateFunctions: uiDataUpdateFunctions })
        )
      );
    }), catchError(error => {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'SHARED.COULDNT_LOAD'
      );
      return [new UIDataUpdateFunctionActions.GetUIDataUpdateFunctionsAccordingToUIDataStoreIdFailure({ error: { error }, key: 'GetUIDataUpdateFunctionsByUIDataStoreIdFailure', timestamp: Date.now() })];
    })
  ));
}

