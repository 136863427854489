import { KeyValue } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  ProjectInterface,
  UIDataEventInterface, UIDataStoreApi,
  UIDataStoreInterface,
  UIDataUpdateFunctionApi,
} from '@rappider/api-sdk';
import { PATH_DEFINITIONS, QUERY_PARAM_DEFINITIONS, defaultToolbarTitleHeadingSize } from '@rappider/shared/definitions';
import { AlertConfig, AlertTypes, BreadcrumbOption, CheckboxListComponentConfig, CrudFormCheckboxListItem, HeadingComponentConfig, HeadingType } from '@rappider/rappider-components/utils';
import { GetUIDataEvents } from 'libs/project/src/lib/states/ui-data-event-state/ui-data-event.actions';
import { Subscription } from 'rxjs';
import { UI_DATA_UPDATE_FUNCTION_CREATE_FORM_CONFIG } from './config/ui-data-update-function-create-form-config';
import { BulkCreateUIDataUpdateFunction, CreateUIDataUpdateFunction } from 'libs/project/src/lib/states/ui-data-update-functions/ui-data-update-function.actions';
import { NewUiDataUpdateFunction } from '@rappider/rappider-sdk';

@Component({
  selector: 'rappider-ui-data-update-function-create',
  templateUrl: './ui-data-update-function-create.component.html',
  styleUrls: ['./ui-data-update-function-create.component.scss']
})
export class UiDataUpdateFunctionCreateComponent implements OnInit, OnDestroy {

  dataStoreId: string;
  dataEventOptions: KeyValue<string, string>[];
  subscriptions: Subscription[];
  activeProject: {
    id: string;
    name: string;
  };
  mainTitle: HeadingComponentConfig;
  title: BreadcrumbOption[];
  UI_DATA_UPDATE_FUNCTION_CREATE_FORM_CONFIG = UI_DATA_UPDATE_FUNCTION_CREATE_FORM_CONFIG;
  dataStore: UIDataStoreInterface;
  displayToolbar = false;
  displayToolbarBackButton = false;

  alertConfig: AlertConfig = {
    type: AlertTypes.Warning,
    title: {
      type: HeadingType.H5,
      content: 'There is no Data Event To Add'
    },
    showIcon: true,
    closeable: false,
  };

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private dataStoreApi: UIDataStoreApi,
    private uiDataUpdateFunctionApi: UIDataUpdateFunctionApi,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getDataStoreIdFromUrl();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  getDataStoreIdFromUrl() {
    this.dataStoreId = this.activatedRoute.snapshot.params['dataStoreId'];
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToDataEvents()
    ];
  }

  subscribeToDataEvents() {
    return this.store.select(state => state.uiDataEvent?.data).subscribe((dataEvents: UIDataEventInterface[]) => {
      this.dataEventOptions = dataEvents
        ?.filter(dataEvent => (!dataEvent.uiDataUpdateFunction && dataEvent.uiDataStoreId === this.dataStoreId))
        ?.map((dataEvent: UIDataEventInterface) => ({
          key: dataEvent.name,
          value: dataEvent.id
        })) || [];

      this.setDataEventOptionsForForm();
    });
  }

  setDataEventOptionsForForm() {
    const createFormConfig = this.UI_DATA_UPDATE_FUNCTION_CREATE_FORM_CONFIG.items.find(item => item.fieldName === 'uiDataEventId');
    createFormConfig.options = this.dataEventOptions.map(dataEventOption => ({
      key: JSON.parse(`{"text": "${dataEventOption.key}"}`),
      value: dataEventOption.value
    }));
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectInterface) => {
      if (activeProject) {
        this.store.dispatch(new GetUIDataEvents());
        this.activeProject = {
          name: activeProject.name,
          id: activeProject.id
        };
        this.getDataStoreById();
      }
    });
  }

  onCreateUIDataUpdateFunction(formValue: {
    uiDataEventId: string[];
  }) {
    const uiDataUpdateFunctions = formValue.uiDataEventId.map(item => ({
      uiDataEventId: item,
      uiDataStoreId: this.dataStoreId
    }));
    this.store.dispatch(new BulkCreateUIDataUpdateFunction({
      uiDataUpdateFunctions: uiDataUpdateFunctions as Array<NewUiDataUpdateFunction>
    }));
  }

  getDataStoreById() {
    this.dataStoreApi.findById(this.dataStoreId).subscribe((dataStore: UIDataStoreInterface) => {
      if (dataStore) {
        this.dataStore = dataStore;
        this.setTitle();
      }
    });
  }

  setTitle() {
    this.mainTitle = {
      content: 'PROJECT_MODULE.UI_DATA_EVENT_UPDATE_FUNCTION_CREATE_COMPONENT.CREATE_UI_DATA_EVENT_UPDATE_FUNCTION',
      type: defaultToolbarTitleHeadingSize
    };
    this.title = [
      {
        label: this.activeProject.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`,
      },
      {
        label: 'PROJECT_MODULE.UI_DATA_STORE_LIST_COMPONENT.UI_DATA_STORE_LIST',
        redirectUrl: PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_LIST
      },
      {
        label: this.dataStore.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_DETAIL}/${this.dataStore.id}`,
        queryParams: QUERY_PARAM_DEFINITIONS.PROJECT.UI_DATA_STORE_DETAIL.DATA_EVENT_UPDATE_FUNCTION_TAB
      },
      {
        label: 'PROJECT_MODULE.UI_DATA_EVENT_UPDATE_FUNCTION_CREATE_COMPONENT.CREATE_UI_DATA_EVENT_UPDATE_FUNCTION'
      }
    ];
  }
}
