import { PATH_DEFINITIONS, QUERY_PARAM_DEFINITIONS } from '@rappider/shared/definitions';
import {
  ButtonType,
  ActionBehavior,
  CrudTableViewConfig,
  CrudViewColumnType,
  HorizontalPosition
} from '@rappider/rappider-components/utils';
import { POP_CONFIRM_DELETE_ACTION } from 'libs/shared/src/lib/configs/pop-confirm-button/pop-confirm-button-config';

export const UI_DATA_STORE_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'name',
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewColumnType.Link,
      routerLink: `${PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_DETAIL}/{{id}}`,
      queryParams: QUERY_PARAM_DEFINITIONS.PROJECT.UI_DATA_STORE_DETAIL.FIELDS_TAB
    },
    {
      title: 'SHARED.CREATED_DATE',
      fieldName: 'createdDate',
      type: CrudViewColumnType.Date
    }
  ],
  listActions: [
    {
      text: 'PROJECT_MODULE.UI_DATA_STORE_CREATE_COMPONENT.UI_DATA_STORE_ADD',
      buttonType: ButtonType.Default,
      icon: { name: 'fas fa-plus' },
      behavior: ActionBehavior.Route,
      redirectUrl: PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_CREATE
    },
    {
      text: 'SHARED.REFRESH',
      name: 'SHARED.REFRESH',
      icon: { name: 'fas fa-sync' },
      behavior: ActionBehavior.Emit,
      horizontalPosition: HorizontalPosition.Right,
      disabled: false
    }
  ],
  itemActions: [
    {
      text: 'SHARED.EDIT',
      name: 'SHARED.EDIT',
      behavior: ActionBehavior.Route,
      icon: { name: 'far fa-edit' },
      redirectUrl: `${PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_EDIT}/{{id}}`
    },
    {
      text: POP_CONFIRM_DELETE_ACTION.text,
      name: POP_CONFIRM_DELETE_ACTION.name,
      popconfirmTitle: POP_CONFIRM_DELETE_ACTION.popconfirmTitle,
      emitWithoutPopconfirm: POP_CONFIRM_DELETE_ACTION.emitWithoutPopconfirm,
      behavior: POP_CONFIRM_DELETE_ACTION.behavior,
      icon: POP_CONFIRM_DELETE_ACTION.icon,
      popConfirmCancelText: POP_CONFIRM_DELETE_ACTION.popConfirmCancelText,
      popConfirmOkText: POP_CONFIRM_DELETE_ACTION.popConfirmOkText,
      popConfirmOkDanger: POP_CONFIRM_DELETE_ACTION.popConfirmOkDanger,
    }
  ],
  handleVisibilityFn: (data, item, action) => {
    if (action.name === POP_CONFIRM_DELETE_ACTION.name) {
      return item.isDeletable !== false;
    } else if (action.name === 'SHARED.EDIT') {
      return item.isUpdatable !== false;
    }
  }
};
