import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ButtonComponentConfig, ButtonType } from '../../utils/button';

@Component({
  selector: 'rappider-back-top',
  templateUrl: './back-top.component.html',
  styleUrls: ['./back-top.component.scss']
})
export class RappiderBackTopComponent implements OnInit, OnChanges {

  /**
   * custom button config
   *
   * @type {string}
   * @memberof RappiderBackTopComponent
   */
  @Input() customButton: ButtonComponentConfig;

  /**
   * custom template flag, if true we can customize using inputs
   *
   * @type {boolean}
   * @memberof RappiderBackTopComponent
   */
  @Input() customTemplate: boolean;

  /**
   * the nz-back-top button will not show until the scroll height reaches this value
   *
   * @type {number}
   * @memberof RappiderBackTopComponent
   */
  @Input() visibilityHeight: number;

  /**
   * Time to return to top (ms)
   *
   * @type {number}
   * @memberof RappiderBackTopComponent
   */
  @Input() duration: number;

  @Input() target: string | Element;

  ngOnInit(): void {
    this.initDefaults();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initDefaults();
  }

  initDefaults() {
    if (!this.customButton) {
      this.customButton = {
        text: 'UP',
        type: ButtonType.Primary
      }
    }
    if (!this.customTemplate) {
      this.customTemplate = false;
    }
    if (!this.visibilityHeight) {
      this.visibilityHeight = 400;
    }
    if (!this.target) {
      this.target = 'window';
    }
    if (!this.duration) {
      this.duration = 450;
    }
  }

}
