import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, createSelector } from '@ngrx/store';
import { defaultToolbarTitleHeadingSize, PATH_DEFINITIONS, QUERY_PARAM_DEFINITIONS } from '@rappider/shared/definitions';
import { BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { Subscription } from 'rxjs';
import { ProjectWithRelations, UiDataEventWithRelations, DataSchemaWithRelations, UiDataStoreWithRelations } from '@rappider/rappider-sdk';
import { getDataSchemasWithDetailsSelector } from 'libs/project/src/lib/states/selectors/get-data-schemas-with-details.selector';

@Component({
  selector: 'rappider-data-event-detail',
  templateUrl: './data-event-detail.component.html',
  styleUrls: ['./data-event-detail.component.scss']
})
export class DataEventDetailComponent implements OnInit, OnDestroy {
  /* ui data event id */
  @Input() dataEventId: string;
  /* flag for whether to navigate after data event creation */
  @Input() navigateAfterCreate = true;
  /* flag to display breadcrumb under title */
  @Input() displayBreadcrumb = true;

  /* main title */
  mainTitle: HeadingComponentConfig = {
    content: 'PROJECT_MODULE.DATA_EVENT_DETAIL_COMPONENT.DATA_EVENT_DETAIL',
    type: defaultToolbarTitleHeadingSize
  };
  /* title */
  title: BreadcrumbOption[];
  /* active project */
  activeProject: ProjectWithRelations;
  /* ui data event */
  dataEvent: UiDataEventWithRelations;
  /* subscriptions */
  subscriptions: Subscription[];
  /* loading state */
  isLoading = false;

  displayToolbar = false;
  displayToolbarBackButton = false;

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getDataEventIdFromUrl();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToUiDataEventsAndUiDataStores(),
      this.subscribeToUIDataEventsLoading()
    ];
  }

  getDataEventIdFromUrl() {
    if (!this.dataEventId) {
      this.dataEventId = this.activatedRoute.snapshot.params['id'];
    }
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectWithRelations) => {
      this.activeProject = activeProject;
    });
  }

  subscribeToUiDataEventsAndUiDataStores() {
    return this.store.select(createSelector(
      state => <UiDataStoreWithRelations[]>state['uiDataStore']?.data,
      state => <UiDataEventWithRelations[]>state['uiDataEvent']?.data,
      <any>getDataSchemasWithDetailsSelector,
      (
        uiDataStores: UiDataStoreWithRelations[],
        uiDataEvents: UiDataEventWithRelations[],
        dataSchemas: DataSchemaWithRelations[]
      ) => {
        const activeUiDataEvent = uiDataEvents?.find(uiDataEvent => uiDataEvent.id === this.dataEventId);
        if (activeUiDataEvent) {
          const payload = dataSchemas?.find(dataSchema => dataSchema.id === activeUiDataEvent.dataSchemaId);
          return <UiDataEventWithRelations>{
            ...activeUiDataEvent,
            payload: payload,
            uiDataStore: uiDataStores?.find(uiDataStore => uiDataStore.id === activeUiDataEvent?.uiDataStoreId)
          };
        }
      })).subscribe(uiDataEvent => {
        this.dataEvent = uiDataEvent;
        this.setTitle();
      });
  }

  subscribeToUIDataEventsLoading() {
    return this.store.select(state => state.uiDataEvent?.loading).subscribe(loading => {
      this.isLoading = loading;
    });
  }

  setTitle() {
    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`,
      },
      {
        label: 'PROJECT_MODULE.UI_DATA_STORE_LIST_COMPONENT.UI_DATA_STORE_LIST',
        redirectUrl: PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_LIST
      },
      {
        label: this.dataEvent?.uiDataStore?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_DETAIL}/${this.dataEvent?.uiDataStoreId}`,
        queryParams: QUERY_PARAM_DEFINITIONS.PROJECT.UI_DATA_STORE_DETAIL.EVENTS_TAB
      },
      {
        label: 'PROJECT_MODULE.DATA_EVENT_DETAIL_COMPONENT.DATA_EVENT_DETAIL'
      },
      {
        label: this.dataEvent?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.DATA_EVENT_DETAIL}/${this.dataEvent?.id}`
      }
    ];
  }
}
