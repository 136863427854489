import { PropertyType } from '@rappider/rappider-components/utils';

export const PACKAGE_COLUMNS = [
  {
    fieldName: 'packageName',
    placeholder: 'Package Name',
    unique: true,
    typeAndFormat: {
      type: PropertyType.String
    },
    config: {
      placeholder: 'Package Name',
      disabled: true
    }
  },
  {
    fieldName: 'versionNumber',
    placeholder: 'Version Number',
    typeAndFormat: {
      type: PropertyType.String
    },
    config: {
      placeholder: 'Version Number',
      disabled: true
    }
  }
];
