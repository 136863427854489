import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, delayWhen, mergeMap, withLatestFrom } from 'rxjs/operators';
import * as AIAssistantActions from './ai-assistant.actions';
import { ChatMessage, ChatSessionControllerService, KeyValuePair } from '@rappider/rappider-sdk';
import { Action, Store } from '@ngrx/store';
import { initializeChatByProject } from '../utils/initialize-chat.function';
import { GetPageById, GetPages } from '@rappider/pages';
import { GetProjectModelWithModelId } from 'libs/project/src/lib/states/project-model-state/project-model.actions';
import { GetProjectThemes } from 'libs/project/src/lib/states/project-theme-state/project-theme.actions';
import { LoadPageWithComponents } from 'libs/content-editor/src/lib/state/content-editor.actions';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Navigate } from '@rappider/shared';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Router } from '@angular/router';
import { v4 } from 'uuid';
import { of } from 'rxjs';

@Injectable()
export class AIAssistantEffects {
  activePollingKey?: string = undefined;

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private chatSessionApi: ChatSessionControllerService,
    private nzMessageService: NzMessageService,
    private router: Router
  ) { }

  getChatSessionByActiveProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AIAssistantActions.GetChatSessionByActiveProject),
      withLatestFrom(
        this.store.select(state => state.activeProject.data)
      ),
      mergeMap(([_, activeProject]) => {
        const params = {
          filter: {
            where: {
              projectId: activeProject.id
            }
          }
        };
        const pollingKey = v4();
        this.activePollingKey = pollingKey;
        return this.chatSessionApi.find(params).pipe(
          mergeMap((chatSessions) => {
            if (chatSessions?.length) {
              const chatSession = chatSessions[0];
              return [
                AIAssistantActions.GetChatSessionByActiveProjectSuccessful({
                  payload: {
                    chatSession: {
                      ...chatSession,
                      messages: [
                        ...initializeChatByProject(activeProject),
                        ...chatSession.messages || []
                      ]
                    }
                  }
                }),
                AIAssistantActions.GetChatMessagesByTimestamp({ pollingKey: pollingKey })
              ];
            } else {
              return [
                AIAssistantActions.Error({ payload: { error: { error: 'There is no chat session found.', key: 'GetChatSessionByActiveProject' } } })
              ];
            }
          }),
          catchError((error) =>
            [
              AIAssistantActions.Error({ payload: { error: { error, key: 'GetChatSessionByActiveProject' } } })
            ]
          )
        );
      })
    )
  );

  getChatMessageByTimestampRecursively$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AIAssistantActions.GetChatMessagesByTimestamp
      ),
      withLatestFrom(
        this.store.select(state => state.activeProject.data),
        this.store.select(state => state.aiAssistant.messages),
        this.store.select(state => <boolean>state.aiAssistant.isAssistantTyping)
      ),
      delayWhen(([, , , isAssistantTyping]) => {
        // Delay 60 seconds if assistant is not typing as we dont expect a message, otherwise 5 seconds
        const delayDuration: number = isAssistantTyping ? 5000 : 60000;
        return of(null).pipe(delay(delayDuration));
      }),
      mergeMap(([action, activeProject, messages, isAssistantTyping]) => {
        const lastMessageTimestamp = messages[messages.length - 1].timestamp;
        if (!activeProject?.id && lastMessageTimestamp) {
          return [];
        }
        const params = {
          filter: {
            where: {
              projectId: activeProject.id
            }
          },
          timestamp: lastMessageTimestamp
        };

        return this.chatSessionApi.find(params).pipe(
          mergeMap((chatSessions) => {
            if (chatSessions?.length) {
              const chatSession = chatSessions[0];
              const createdPageIds = chatSession.messages?.map((message: ChatMessage) =>
                message.metadata?.find((metadata: KeyValuePair) => metadata.key === 'page-created')?.value
              )
                .filter(e => e);
              const createdModelIds = chatSession.messages?.map((message: ChatMessage) =>
                message.metadata?.find((metadata: KeyValuePair) => metadata.key === 'model')?.value
              )
                .filter(e => e);
              const createdThemeIds = chatSession.messages?.map((message: ChatMessage) =>
                message.metadata?.find((metadata: KeyValuePair) => metadata.key === 'theme')?.value
              )
                .filter(e => e);
              const successActionsToDispatch: Action[] = [
                AIAssistantActions.GetChatMessagesByTimestampSuccessful({ payload: { chatSession: chatSession } })
              ];

              /* TODO: must use findById like ProjectModel Action */
              if (createdPageIds?.length) {
                const isContentEditor = createdPageIds.some(id => this.router.url.includes(id));
                if (isContentEditor) {
                  successActionsToDispatch.push(new LoadPageWithComponents({ pageId: createdPageIds[0] }));
                } else {
                  successActionsToDispatch.push(...createdPageIds.map(id => new GetPageById({ pageId: id })));
                }
              }
              if (createdModelIds?.length) {
                successActionsToDispatch.push(...createdModelIds.map(id => new GetProjectModelWithModelId({ id: id, isCrudPagesGenerated: false, tryCount: 0 })));
                /*
                 * navigate with notification to data model diagram page when a new data model created
                 */
                if (this.router.url.includes(PATH_DEFINITIONS.AI.ASSISTANT)) {
                  this.nzMessageService.create('info', 'We\'ve created the Data Model. You\'ll now be directed to the diagram page where you can view the models we\'ve generated.', { nzDuration: 5000 });
                  setTimeout(() => {
                    this.store.dispatch(new Navigate({ url: PATH_DEFINITIONS.DATABASE_DIAGRAM_EDITOR.DATABASE_DIAGRAM_EDITOR_PATH }));
                  }, 5000);
                }
              }
              /* TODO: must use findById like ProjectModel Action */
              if (createdThemeIds?.length) {
                successActionsToDispatch.push(new GetProjectThemes());
              }
              if (this.activePollingKey === action.pollingKey) {
                successActionsToDispatch.push(AIAssistantActions.GetChatMessagesByTimestamp({ pollingKey: action.pollingKey }));
                return successActionsToDispatch;
              } else {
                return [];
              }
            } else {
              return [
                AIAssistantActions.Error({ payload: { error: { error: 'There is no chat session found.', key: 'GetChatMessagesByTimestamp' } } })
              ];
            }
          }),
          catchError((error) =>
            [
              AIAssistantActions.Error({ payload: { error: { error, key: 'GetChatMessagesByTimestamp' } } })
            ]
          )
        );
      })
    )
  );

  createChatMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AIAssistantActions.CreateChatMessage
      ),
      withLatestFrom(
        this.store.select(state => state.aiAssistant.chatSessionId),
        this.store.select(state => state.contentEditor?.page?.id)
      ),
      mergeMap(([action, chatSessionId, activePageId]) => {
        const params = {
          body: {
            id: chatSessionId,
            message: action.payload.message.content,
            featureKey: action?.payload?.message?.content?.startsWith('ui-page-generator') ? 'ui-page-generator' : null
          }
        } as any;
        if (this.router.url.includes(PATH_DEFINITIONS.CONTENT_EDITOR.CONTENT_EDITOR_PATH) && activePageId) {
          params.body = {
            ...params.body,
            activePageId
          };
        }
        if (!chatSessionId) {
          return [
            AIAssistantActions.Error({ payload: { error: { error: 'There is no chat session id.', key: 'CreateChatMessage' } } })
          ];
        }
        return this.chatSessionApi.createChat(params).pipe(
          mergeMap((_) => ([AIAssistantActions.CreateChatMessageSuccessful({ payload: { message: action.payload.message } })])),
          catchError((error) =>
            [
              AIAssistantActions.Error({ payload: { error: { error, key: 'CreateChatMessage' } } }),
              AIAssistantActions.CreateChatMessageFailure({ payload: { messageKey: (action.payload.message as any).keyForUI } }),
            ]
          )
        );
      })
    )
  );

}
