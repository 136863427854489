<section>
  <section class="subscribed-data-area"
           *ngFor="let data of value; let i = index">
    {{value[i]?.uiDataStore?.name}}.{{getSubscribedTreeAsString(data?.dataSchemaField)}}
    <rappider-button [icon]="deleteIcon"
                     [disabled]="disabled"
                     (click)="onDeleteSubscription(i)"></rappider-button>
  </section>


  <rappider-button [text]="addNewSubscriptionButton.text"
                   [type]="addNewSubscriptionButton.type"
                   [icon]="addNewSubscriptionButton.icon"
                   [disabled]="disabled"
                   (click)="onAddNewSubscriptionButtonClick()"></rappider-button>
</section>

<rappider-modal [(visible)]="isModalVisible"
                [fullScreen]="false"
                [width]="'40%'"
                [title]="'PROJECT_MODULE.SUBSCRIBE_TO_UI_DATA_STORE_COMPONENT.UI_DATA_STORE_SUBSCRIPTIONS' | translate"
                [okDisabled]="!subscribedFieldData?.dataSchemaField"
                (cancelClick)="onCancelSubscriptionModal()"
                (okClick)="onSaveSubscriptionModal()">
  <div class="subscribe-to-ui-data-store-component">
    <rappider-select [(ngModel)]="selectedUiDataStoreId"
                     [options]="uiDataStoreSelectOptions"
                     [placeholder]="'PROJECT_MODULE.SUBSCRIBE_TO_UI_DATA_STORE_COMPONENT.UI_DATA_STORES' | translate"
                     (valueChange)="onSelectUiDataStore($event)"></rappider-select>
    <rappider-spin [spinning]="isDataStoreFieldsLoading">
      <rappider-data-schema-select *ngIf="selectedUiDataStore"
                                   [dataSchema]="dataSchema"
                                   [isNoFieldAlertShown]="true"
                                   [(ngModel)]="newSubscription"
                                   (ngModelChange)="onDataSchemaFieldSelect($event)"></rappider-data-schema-select>
      <small *ngIf="showDuplicatedFieldError"
             class="error"> You've already subscribed to this field</small>
      <ng-container *ngIf="showDuplicatedFieldError && showRequiredError">
        <br>
      </ng-container>
      <small *ngIf="showRequiredError"
             class="error"> Please fulfill the required areas.</small>
    </rappider-spin>
  </div>
</rappider-modal>
