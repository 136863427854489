import { Component, EventEmitter, forwardRef, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzTreeNode, NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { NzTreeSelectComponent } from 'ng-zorro-antd/tree-select';

@Component({
  selector: 'rappider-schema-tree-select',
  templateUrl: './schema-tree-select.component.html',
  styleUrls: ['./schema-tree-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => SchemaTreeSelectComponent),
      multi: true
    }
  ]
})

export class SchemaTreeSelectComponent implements ControlValueAccessor {


  @ViewChild('nzTreeSelect') nzTreeSelect: NzTreeSelectComponent;

  @Input() tree: NzTreeNode[];
  @Input() multipleSelect = true;
  @Input() placeholder: string;

  @Output() propertySelect = new EventEmitter<string>();
  @Output() selectedPropertyType = new EventEmitter<string>();

  _value: string[] | string;

  get value() {
    return this._value;
  }

  set value(value: string[] | string) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
  }

  getSelectedNodes(value: any) {
    const selectedNodes = value?.map(item => this.nzTreeSelect.getTreeNodeByKey(item));
    this.nzTreeSelect.selectedNodes = selectedNodes;
  }

  getSelectedPropertyOriginDataByKey(key: any) {
    if (this.multipleSelect) {
      /* returns origin data of selected nodes - for multiple selection */
      return this.nzTreeSelect.getSelectedNodeList().map(item => item.origin);
    } else {
      /* returns origin data of the selected node - for singular selection */
      return this.nzTreeSelect.getTreeNodeByKey(key)?.origin;
    }
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: string[] | string) {
    this._value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  getSelectedTreeNodeAsTree(key: string) {
    return this.nzTreeSelect.getTreeNodeByKey(key);
  }

  display = (node) => {
    const selectedTreeTitle = node.key;
    return selectedTreeTitle;
  };
}
