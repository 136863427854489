/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Comment } from '../models/comment';
import { CommentPartial } from '../models/comment-partial';
import { LastProcessInformation } from '../models/last-process-information';
import { NewCommentInUiDataUpdateFunction } from '../models/new-comment-in-ui-data-update-function';
import { NewUiDataUpdateFunction } from '../models/new-ui-data-update-function';
import { UiDataUpdateFunction } from '../models/ui-data-update-function';
import { UiDataUpdateFunctionPartial } from '../models/ui-data-update-function-partial';
import { UiDataUpdateFunctionWithRelations } from '../models/ui-data-update-function-with-relations';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class UiDataUpdateFunctionControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation uiDataUpdateFunctionControllerBulkCreate
   */
  static readonly UiDataUpdateFunctionControllerBulkCreatePath = '/ui-data-update-functions/bulk-create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkCreate$Response(params?: {
    body?: Array<NewUiDataUpdateFunction>
  }): Observable<StrictHttpResponse<Array<UiDataUpdateFunction>>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataUpdateFunctionControllerService.UiDataUpdateFunctionControllerBulkCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UiDataUpdateFunction>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkCreate(params?: {
    body?: Array<NewUiDataUpdateFunction>
  }): Observable<Array<UiDataUpdateFunction>> {

    return this.bulkCreate$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UiDataUpdateFunction>>) => r.body as Array<UiDataUpdateFunction>)
    );
  }

  /**
   * Path part for operation uiDataUpdateFunctionControllerCount
   */
  static readonly UiDataUpdateFunctionControllerCountPath = '/ui-data-update-functions/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params?: {
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataUpdateFunctionControllerService.UiDataUpdateFunctionControllerCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params?: {
    where?: any;
  }): Observable<LoopbackCount> {

    return this.count$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation uiDataUpdateFunctionControllerFindLatestChangeTimestamps
   */
  static readonly UiDataUpdateFunctionControllerFindLatestChangeTimestampsPath = '/ui-data-update-functions/latest-change-timestamps';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findLatestChangeTimestamps()` instead.
   *
   * This method doesn't expect any request body.
   */
  findLatestChangeTimestamps$Response(params?: {
  }): Observable<StrictHttpResponse<LastProcessInformation>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataUpdateFunctionControllerService.UiDataUpdateFunctionControllerFindLatestChangeTimestampsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LastProcessInformation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findLatestChangeTimestamps$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findLatestChangeTimestamps(params?: {
  }): Observable<LastProcessInformation> {

    return this.findLatestChangeTimestamps$Response(params).pipe(
      map((r: StrictHttpResponse<LastProcessInformation>) => r.body as LastProcessInformation)
    );
  }

  /**
   * Path part for operation uiDataUpdateFunctionControllerFindComments
   */
  static readonly UiDataUpdateFunctionControllerFindCommentsPath = '/ui-data-update-functions/{id}/comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findComments()` instead.
   *
   * This method doesn't expect any request body.
   */
  findComments$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<Array<Comment>>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataUpdateFunctionControllerService.UiDataUpdateFunctionControllerFindCommentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Comment>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findComments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findComments(params: {
    id: string;
    filter?: any;
  }): Observable<Array<Comment>> {

    return this.findComments$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Comment>>) => r.body as Array<Comment>)
    );
  }

  /**
   * Path part for operation uiDataUpdateFunctionControllerCreateComment
   */
  static readonly UiDataUpdateFunctionControllerCreateCommentPath = '/ui-data-update-functions/{id}/comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createComment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createComment$Response(params: {
    id: string;
    body?: NewCommentInUiDataUpdateFunction
  }): Observable<StrictHttpResponse<Comment>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataUpdateFunctionControllerService.UiDataUpdateFunctionControllerCreateCommentPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Comment>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createComment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createComment(params: {
    id: string;
    body?: NewCommentInUiDataUpdateFunction
  }): Observable<Comment> {

    return this.createComment$Response(params).pipe(
      map((r: StrictHttpResponse<Comment>) => r.body as Comment)
    );
  }

  /**
   * Path part for operation uiDataUpdateFunctionControllerDeleteComment
   */
  static readonly UiDataUpdateFunctionControllerDeleteCommentPath = '/ui-data-update-functions/{id}/comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteComment()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteComment$Response(params: {
    id: string;
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataUpdateFunctionControllerService.UiDataUpdateFunctionControllerDeleteCommentPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteComment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteComment(params: {
    id: string;
    where?: any;
  }): Observable<LoopbackCount> {

    return this.deleteComment$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation uiDataUpdateFunctionControllerUpdateComment
   */
  static readonly UiDataUpdateFunctionControllerUpdateCommentPath = '/ui-data-update-functions/{id}/comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateComment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateComment$Response(params: {
    id: string;
    where?: any;
    body?: CommentPartial
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataUpdateFunctionControllerService.UiDataUpdateFunctionControllerUpdateCommentPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('where', params.where, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateComment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateComment(params: {
    id: string;
    where?: any;
    body?: CommentPartial
  }): Observable<LoopbackCount> {

    return this.updateComment$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation uiDataUpdateFunctionControllerFindById
   */
  static readonly UiDataUpdateFunctionControllerFindByIdPath = '/ui-data-update-functions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<UiDataUpdateFunction>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataUpdateFunctionControllerService.UiDataUpdateFunctionControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UiDataUpdateFunction>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
    filter?: any;
  }): Observable<UiDataUpdateFunction> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<UiDataUpdateFunction>) => r.body as UiDataUpdateFunction)
    );
  }

  /**
   * Path part for operation uiDataUpdateFunctionControllerDeleteById
   */
  static readonly UiDataUpdateFunctionControllerDeleteByIdPath = '/ui-data-update-functions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataUpdateFunctionControllerService.UiDataUpdateFunctionControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: string;
  }): Observable<any> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation uiDataUpdateFunctionControllerUpdateById
   */
  static readonly UiDataUpdateFunctionControllerUpdateByIdPath = '/ui-data-update-functions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    body?: UiDataUpdateFunctionPartial
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataUpdateFunctionControllerService.UiDataUpdateFunctionControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    body?: UiDataUpdateFunctionPartial
  }): Observable<any> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation uiDataUpdateFunctionControllerFind
   */
  static readonly UiDataUpdateFunctionControllerFindPath = '/ui-data-update-functions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<UiDataUpdateFunctionWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataUpdateFunctionControllerService.UiDataUpdateFunctionControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UiDataUpdateFunctionWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<UiDataUpdateFunctionWithRelations>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UiDataUpdateFunctionWithRelations>>) => r.body as Array<UiDataUpdateFunctionWithRelations>)
    );
  }

  /**
   * Path part for operation uiDataUpdateFunctionControllerCreate
   */
  static readonly UiDataUpdateFunctionControllerCreatePath = '/ui-data-update-functions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    body?: NewUiDataUpdateFunction
  }): Observable<StrictHttpResponse<UiDataUpdateFunction>> {

    const rb = new RequestBuilder(this.rootUrl, UiDataUpdateFunctionControllerService.UiDataUpdateFunctionControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UiDataUpdateFunction>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    body?: NewUiDataUpdateFunction
  }): Observable<UiDataUpdateFunction> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<UiDataUpdateFunction>) => r.body as UiDataUpdateFunction)
    );
  }

}
