import { createAction, createReducer, on } from '@ngrx/store';
import { WorkflowEventWithRelations } from '@rappider/rappider-sdk';

import * as WorkflowEventActions from './workflow-event.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

/* state key */
export const featureKey = 'workflowEvent';

/* state interface */
export interface WorkflowEventState {
  data: WorkflowEventWithRelations[];
  isLoading: boolean;
  isDataFetched: boolean;
  error: any;
}

/* initial values */
export const initialState: WorkflowEventState = {
  data: [],
  isLoading: false,
  error: null,
  isDataFetched: false
};

const ChangeActiveProjectSuccessful = createAction(AuthenticationActions.ActionTypes.ChangeActiveProjectSuccessful);
const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

export const reducer = createReducer(
  initialState,
  on(WorkflowEventActions.CreateWorkflowEvent, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(WorkflowEventActions.CreateWorkflowEventSuccessful, (state, action) => ({
    ...state,
    data: [
      ...state.data,
      action.payload.workflowEvent
    ],
    isLoading: false,
  })),
  on(WorkflowEventActions.CreateWorkflowEventFailure, (state, action) => ({
    ...state,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    },
    isLoading: false
  })),
  on(WorkflowEventActions.GetWorkflowEvent, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(WorkflowEventActions.GetWorkflowEventSuccessful, (state, action) => ({
    ...state,
    data: action.payload.workflowEvents,
    isDataFetched: true,
    isLoading: false
  })),
  on(WorkflowEventActions.GetWorkflowEventFailure, (state, action) => ({
    ...state,
    data: [],
    isDataFetched: false,
    isLoading: false
  })),
  on(WorkflowEventActions.UpdateWorkflowEvent, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(WorkflowEventActions.UpdateWorkflowEventSuccessful, (state, action) => ({
    ...state,
    data: state.data.map(workflowEvent => {
      if (workflowEvent.id === action.payload.workflowEventId) {
        return {
          ...workflowEvent,
          ...action.payload.workflowEventBody
        };
      }
      return workflowEvent;
    }),
    isLoading: false
  })),
  on(WorkflowEventActions.UpdateWorkflowEventFailure, (state, action) => ({
    ...state,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    },
    isLoading: false
  })),
  on(WorkflowEventActions.DeleteWorkflowEvent, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(WorkflowEventActions.DeleteWorkflowEventSuccessful, (state, action) => ({
    ...state,
    data: state.data?.filter(workflowEvent => workflowEvent.id !== action.payload.workflowEventId),
    isLoading: false
  })),
  on(WorkflowEventActions.DeleteWorkflowEventFailure, (state, action) => ({
    ...state,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    },
    isLoading: false
  })),
  on(ChangeActiveProjectSuccessful, () => (initialState)),
  on(Logout, () => (initialState))
);
