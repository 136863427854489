<rappider-date-picker class="date-picker"
                      [id]="elementId+'_date'"
                      [(ngModel)]="date"
                      [minSelectableDate]="minSelectableDate"
                      [maxSelectableDate]="maxSelectableDate"
                      [dateFormat]="datePicker?.dateFormat"
                      [borderSettings]="datePicker?.borderSettings"
                      [customSizeSettings]="datePicker?.customSizeSettings"
                      [shadowSettings]="datePicker?.shadowSettings"
                      [marginSettings]="datePicker?.marginSettings"
                      [paddingSettings]="datePicker?.paddingSettings"
                      (ngModelChange)="onValueChange()"></rappider-date-picker>
<rappider-time-picker [(ngModel)]="time"
                      [id]="elementId+'_time'"
                      [borderSettings]="timePicker?.borderSettings"
                      [customSizeSettings]="timePicker?.customSizeSettings"
                      [shadowSettings]="timePicker?.shadowSettings"
                      [marginSettings]="timePicker?.marginSettings"
                      [paddingSettings]="timePicker?.paddingSettings"
                      (ngModelChange)="onValueChange()"></rappider-time-picker>
