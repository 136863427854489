import { EnvironmentVariableModalData } from './environment-variable-modal-data.interface';

export const DEFAULT_ENVIRONMENT_VARIABLE_MODAL_DATA: EnvironmentVariableModalData = {
  title: 'Environment Variable',
  visible: false,
  isSubmitted: false,
  isValid: false,
  data: {
    key: null,
    type: null,
    value: null,
    exposeToFrontend: null,
    exposeToBackend: null,
    isSensitive: null
  },
  environment: null,
  editingEnvironmentVariableId: null
};
