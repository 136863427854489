import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { WorkflowStepFunctionSubscribedEvent, WorkflowStepFunctionSubscribedEventControllerService, WorkflowStepFunctionSubscribedEventWithRelations } from '@rappider/rappider-sdk';
import { NotificationService } from '@rappider/services';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import * as WorkflowStepFunctionSubscribedEventActions from './workflow-step-function-subscribed-event.actions';
import * as WorkflowStepFunctionActions from '../workflow-step-function/workflow-step-function.actions';

@Injectable()
export class WorkflowStepFunctionSubscribedEventEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private notificationService: NotificationService,
    private workflowStepFunctionSubscribedEventApi: WorkflowStepFunctionSubscribedEventControllerService,
  ) { }

  createWorkflowStepFunctionSubscribedEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowStepFunctionSubscribedEventActions.CreateWorkflowStepFunctionSubscribedEvent
      ),
      mergeMap((action) => {
        const params = {
          body: action.payload.workflowStepFunctionSubscribedEvent
        };

        return this.workflowStepFunctionSubscribedEventApi.create(params).pipe(
          map((workflowStepFunctionSubscribedEvent: WorkflowStepFunctionSubscribedEvent) => {
            this.notificationService.createNotification(
              'success',
              'success',
              'SHARED.SUCCESSFULLY_CREATED'
            );
            return WorkflowStepFunctionSubscribedEventActions.CreateWorkflowStepFunctionSubscribedEventSuccessful({ payload: { workflowStepFunctionSubscribedEvent } });
          }), catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'SHARED.COULDNT_CREATED'
            );
            return [
              WorkflowStepFunctionSubscribedEventActions.CreateWorkflowStepFunctionSubscribedEventFailure({ payload: { error, key: 'CreateWorkflowStepFunctionSubscribedEventFailure', timestamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

  getWorkflowStepFunctionSubscribedEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowStepFunctionActions.GetWorkflowStepFunctionSuccessful
      ),
      withLatestFrom(
        this.store.select(state => state.workflowStepFunction?.data)
      ),
      mergeMap(([action, workflowStepFunction]) => {
        const workflowStepFunctionIds = workflowStepFunction.map(workflowStepFunctionItem => workflowStepFunctionItem.id);
        const params = {
          filter: {
            where: {
              workflowStepFunctionId: { inq: workflowStepFunctionIds }
            }
          }
        };
        return this.workflowStepFunctionSubscribedEventApi.find(params).pipe(
          map((workflowStepFunctionSubscribedEvents: WorkflowStepFunctionSubscribedEventWithRelations[]) =>
            WorkflowStepFunctionSubscribedEventActions.GetWorkflowStepFunctionSubscribedEventSuccessful({ payload: { workflowStepFunctionSubscribedEvents } })
          ),
          catchError((error) => [
            WorkflowStepFunctionSubscribedEventActions.GetWorkflowStepFunctionSubscribedEventFailure({ payload: { error, key: 'GetWorkflowStepFunctionSubscribedEventFailure', timestamp: Date.now() } })
          ])
        );
      })
    )
  );

  updateWorkflowStepFunctionSubscribedEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowStepFunctionSubscribedEventActions.UpdateWorkflowStepFunctionSubscribedEvent
      ),
      mergeMap((action) => {
        const params = {
          id: action.payload.workflowStepFunctionSubscribedEventId,
          body: action.payload.workflowStepFunctionSubscribedEventBody
        };

        return this.workflowStepFunctionSubscribedEventApi.updateById(params).pipe(
          map(() => {
            this.notificationService.createNotification(
              'success',
              'success',
              'SHARED.SUCCESSFULLY_UPDATED'
            );
            const workflowStepFunctionSubscribedEventBody = {
              ...action.payload.workflowStepFunctionSubscribedEventBody
            } as WorkflowStepFunctionSubscribedEventWithRelations;
            return WorkflowStepFunctionSubscribedEventActions.UpdateWorkflowStepFunctionSubscribedEventSuccessful({ payload: { workflowStepFunctionSubscribedEventId: action.payload.workflowStepFunctionSubscribedEventId, workflowStepFunctionSubscribedEventBody } });
          }), catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'SHARED.COULDNT_UPDATED'
            );
            return [
              WorkflowStepFunctionSubscribedEventActions.UpdateWorkflowStepFunctionSubscribedEventFailure({ payload: { error, key: 'UpdateWorkflowStepFunctionSubscribedEventFailure', timestamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

  deleteWorkflowStepFunctionSubscribedEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowStepFunctionSubscribedEventActions.DeleteWorkflowStepFunctionSubscribedEvent
      ),
      mergeMap((action) => {
        const params = {
          id: action.payload.workflowStepFunctionSubscribedEventId
        };
        return this.workflowStepFunctionSubscribedEventApi.deleteById(params).pipe(
          map(() => {
            this.notificationService.createNotification(
              'success',
              'SHARED.SUCCESS',
              'SHARED.SUCCESSFULLY_DELETED'
            );
            return WorkflowStepFunctionSubscribedEventActions.DeleteWorkflowStepFunctionSubscribedEventSuccessful({ payload: { workflowStepFunctionSubscribedEventId: action.payload.workflowStepFunctionSubscribedEventId } });
          }, catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'SHARED.COULDNT_DELETED'
            );
            return [
              WorkflowStepFunctionSubscribedEventActions.DeleteWorkflowStepFunctionSubscribedEventFailure({ payload: { error, key: 'DeleteWorkflowStepFunctionSubscribedEventFailure', timestamp: Date.now() } })
            ];
          }))
        );
      })
    )
  );
}
