import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JsonValidationService {
  validateStringifiedJson(data: string | any, isNullable?: boolean) {
    if (isNullable && !data) {
      return {
        dataAsJson: null,
        isJsonValid: true
      };
    } else {
      try {
        const dataAsJson = JSON.parse(data);
        return {
          isJsonValid: true,
          dataAsJson: dataAsJson
        };
      } catch (error) {
        return {
          isJsonValid: false,
        };
      }
    }
  }
}
