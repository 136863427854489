import { Router } from '@angular/router';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { PersonInterface } from '@rappider/api-sdk';
import { AppRightSidebarTab } from '@rappider/shared/definitions';

@Component({
  selector: 'rappider-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss']
})
export class RightSidebarComponent implements OnInit, OnChanges {

  @Input() visibility = false;
  @Input() activeTabName: AppRightSidebarTab;

  AppRightSidebarTab = AppRightSidebarTab;

  isToggleButtonVisible = true;
  isContentExist: boolean;
  subscriptions: Subscription[] = [];
  activePerson: PersonInterface;

  currentUrl: string;
  params = {};

  constructor(
    private store: Store<any>,
    private router: Router) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visibility.currentValue === true) {
      this.currentUrl = this.router.url;
    }
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActivePerson(),
    ];
  }

  subscribeToActivePerson() {
    return this.store.select(state => state.auth?.activePerson).subscribe(activePerson => {
      this.activePerson = activePerson;
    });
  }

  getGuidanceExpansionCSSClass(): string {
    return this.visibility ? 'toggler-open' : 'toggler-close';
  }

  getPathLength(isContentExist: boolean) {
    // isContentExist comes from guidance component. it is a boolean variable that guidance content is exist or not
    this.isContentExist = isContentExist;
  }
}
