import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataTransformService, NotificationService } from '@rappider/services';
import { PROJECT_EDIT_FORM_CONFIG } from '@rappider/shared/configs';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { UpdateProject } from '../../states/projects-state/project.actions';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { Metadata, Project, ProjectControllerService } from '@rappider/rappider-sdk';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rappider-project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: ['./project-edit.component.scss']
})
export class ProjectEditComponent implements OnInit, OnDestroy {
  PROJECT_EDIT_FORM_CONFIG = PROJECT_EDIT_FORM_CONFIG;
  mainTitle: HeadingComponentConfig = {
    content: 'PROJECT_MODULE.PROJECT_EDIT_COMPONENT.PROJECT_EDIT',
    type: defaultToolbarTitleHeadingSize
  };
  title: string | string[] | BreadcrumbOption[];

  projectId: string;
  project: Project;
  editedProjectData: any;
  subscriptions: Subscription[];
  displayToolbar = false;
  displayToolbarBackButton = false;
  isLoading = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<any>,
    private dataTransformService: DataTransformService
  ) { }

  /**
   *
   *
   * @memberof ProjectEditComponent
   */
  ngOnInit(): void {
    /* Assign project id */
    this.projectId = this.getProjectId();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToProjectLoading(),
      this.subscribeToProjects()
    ];
  }

  /**
   * Takes the project id paramater from route
   *
   * @returns
   * @memberof ProjectEditComponent
   */
  getProjectId() {
    return this.activatedRoute.snapshot.params['projectId'];
  }

  subscribeToProjectLoading() {
    return this.store.select(state => state.project?.loading).subscribe(loading => {
      this.isLoading = loading;
    });
  }

  subscribeToProjects() {
    return this.store.select(state => state.project.data).subscribe(projects => {
      if (this.projectId) {
        this.project = projects?.find(project => project.id === this.projectId);
        if (this.project) {
          this.title = [
            {
              label: this.project.name ?? '',
              redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.project?.id}`
            },
            {
              label: 'PROJECT_MODULE.PROJECT_EDIT_COMPONENT.PROJECT_EDIT'
            }
          ];
        }
      }
    });
  }

  /**
   * Fire's when save button clicked
   *
   * @param {ProjectInterface} project
   * @memberof ProjectEditComponent
   */
  editProject(project: Partial<Project>) {
    if (this.projectId) {
      const createdMetadataItems = this.dataTransformService.compareArrays(this.project.metadata, project.metadata).createdArray;
      createdMetadataItems.forEach(createdMetadataItem => {
        delete createdMetadataItem.id;
      });
      const updatedMetadataItems = this.dataTransformService.compareArrays(this.project.metadata, project.metadata).updatedArray;
      const deletedMetadataItems = this.dataTransformService.compareArrays(this.project.metadata, project.metadata).deletedArray;
      delete this.editedProjectData?.metadata;

      if (!!this.editedProjectData || createdMetadataItems.length || updatedMetadataItems.length || deletedMetadataItems.length) {
        this.store.dispatch(new UpdateProject({
          project: {
            name: project.name,
            key: project.key,
            description: project.description
          },
          projectId: this.projectId,
          metadataCRUD: {
            newMetadataItems: createdMetadataItems,
            updatedMetadataItems: updatedMetadataItems,
            deletedMetadataIds: deletedMetadataItems?.map((metadataItem: Metadata) => metadataItem.id)
          }
        }));
      }
    }
  }

  onFieldValueChange(data: any) {
    this.editedProjectData = {
      ...this.editedProjectData,
      ...data
    };
  }

}
