/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ApiResultDto } from '../models/api-result-dto';
import { BulkUpdateResultDto } from '../models/bulk-update-result-dto';
import { ComponentInputDefinition } from '../models/component-input-definition';
import { ComponentInputDefinitionBulkCreate } from '../models/component-input-definition-bulk-create';
import { ComponentInputDefinitionPartial } from '../models/component-input-definition-partial';
import { ComponentInputDefinitionWithRelations } from '../models/component-input-definition-with-relations';
import { NewComponentInputDefinition } from '../models/new-component-input-definition';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class ComponentInputDefinitionControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation componentInputDefinitionControllerBulkCreate
   */
  static readonly ComponentInputDefinitionControllerBulkCreatePath = '/component-input-definitions/bulk-create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkCreate$Response(params?: {
    body?: ComponentInputDefinitionBulkCreate
  }): Observable<StrictHttpResponse<Array<ComponentInputDefinition>>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentInputDefinitionControllerService.ComponentInputDefinitionControllerBulkCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ComponentInputDefinition>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkCreate(params?: {
    body?: ComponentInputDefinitionBulkCreate
  }): Observable<Array<ComponentInputDefinition>> {

    return this.bulkCreate$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ComponentInputDefinition>>) => r.body as Array<ComponentInputDefinition>)
    );
  }

  /**
   * Path part for operation componentInputDefinitionControllerBulkDelete
   */
  static readonly ComponentInputDefinitionControllerBulkDeletePath = '/component-input-definitions/bulk-delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkDelete$Response(params?: {
    body?: Array<any>
  }): Observable<StrictHttpResponse<ApiResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentInputDefinitionControllerService.ComponentInputDefinitionControllerBulkDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResultDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkDelete(params?: {
    body?: Array<any>
  }): Observable<ApiResultDto> {

    return this.bulkDelete$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResultDto>) => r.body as ApiResultDto)
    );
  }

  /**
   * Path part for operation componentInputDefinitionControllerCustomBulkUpdate
   */
  static readonly ComponentInputDefinitionControllerCustomBulkUpdatePath = '/component-input-definitions/bulk-update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customBulkUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customBulkUpdate$Response(params?: {
    body?: Array<any>
  }): Observable<StrictHttpResponse<BulkUpdateResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentInputDefinitionControllerService.ComponentInputDefinitionControllerCustomBulkUpdatePath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulkUpdateResultDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customBulkUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customBulkUpdate(params?: {
    body?: Array<any>
  }): Observable<BulkUpdateResultDto> {

    return this.customBulkUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<BulkUpdateResultDto>) => r.body as BulkUpdateResultDto)
    );
  }

  /**
   * Path part for operation componentInputDefinitionControllerCount
   */
  static readonly ComponentInputDefinitionControllerCountPath = '/component-input-definitions/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params?: {
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentInputDefinitionControllerService.ComponentInputDefinitionControllerCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params?: {
    where?: any;
  }): Observable<LoopbackCount> {

    return this.count$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation componentInputDefinitionControllerFindById
   */
  static readonly ComponentInputDefinitionControllerFindByIdPath = '/component-input-definitions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<ComponentInputDefinition>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentInputDefinitionControllerService.ComponentInputDefinitionControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ComponentInputDefinition>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
    filter?: any;
  }): Observable<ComponentInputDefinition> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<ComponentInputDefinition>) => r.body as ComponentInputDefinition)
    );
  }

  /**
   * Path part for operation componentInputDefinitionControllerDeleteById
   */
  static readonly ComponentInputDefinitionControllerDeleteByIdPath = '/component-input-definitions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentInputDefinitionControllerService.ComponentInputDefinitionControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: string;
  }): Observable<void> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation componentInputDefinitionControllerUpdateById
   */
  static readonly ComponentInputDefinitionControllerUpdateByIdPath = '/component-input-definitions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    body?: ComponentInputDefinitionPartial
  }): Observable<StrictHttpResponse<ComponentInputDefinition>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentInputDefinitionControllerService.ComponentInputDefinitionControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ComponentInputDefinition>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    body?: ComponentInputDefinitionPartial
  }): Observable<ComponentInputDefinition> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<ComponentInputDefinition>) => r.body as ComponentInputDefinition)
    );
  }

  /**
   * Path part for operation componentInputDefinitionControllerFind
   */
  static readonly ComponentInputDefinitionControllerFindPath = '/component-input-definitions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<ComponentInputDefinitionWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentInputDefinitionControllerService.ComponentInputDefinitionControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ComponentInputDefinitionWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<ComponentInputDefinitionWithRelations>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ComponentInputDefinitionWithRelations>>) => r.body as Array<ComponentInputDefinitionWithRelations>)
    );
  }

  /**
   * Path part for operation componentInputDefinitionControllerCreate
   */
  static readonly ComponentInputDefinitionControllerCreatePath = '/component-input-definitions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    body?: NewComponentInputDefinition
  }): Observable<StrictHttpResponse<ComponentInputDefinition>> {

    const rb = new RequestBuilder(this.rootUrl, ComponentInputDefinitionControllerService.ComponentInputDefinitionControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ComponentInputDefinition>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    body?: NewComponentInputDefinition
  }): Observable<ComponentInputDefinition> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<ComponentInputDefinition>) => r.body as ComponentInputDefinition)
    );
  }

}
