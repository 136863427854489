import { Component, OnDestroy, OnInit } from '@angular/core';
import { CardDataMapConfig, ListMode, ViewMode } from '@rappider/rappider-components';
import { BreadcrumbOption, CrudFormConfig, HeadingComponentConfig, HeadingType } from '@rappider/rappider-components/utils';
import { CUSTOM_FUNCTION_LIST_CONFIG, LABEL_CARDS_LIST_CONFIG, CUSTOM_FUNCTION_CREATE_OR_UPDATE_FORM_CONFIG, CREATE_ITEM_TITLE, EDIT_ITEM_TITLE, LIST_ITEMS_TITLE, MODEL_NAME_SINGULAR } from '../../utils/custom-function.configs';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'libs/components/src/lib/services';
import { CustomFunctionDefinitionPartial, CustomFunctionDefinitionWithRelations, NewCustomFunctionDefinition, ProjectWithRelations } from '@rappider/rappider-sdk';
import { Subscription } from 'rxjs';
import { CreateCustomFunction, DeleteCustomFunction, UpdateCustomFunction } from '../../state/custom-function.actions';
import { JsonValidationService } from '@rappider/services';
import { cloneDeep } from 'lodash';
import { PROGRAMMING_LANGUAGES } from '../../utils/programming-languages';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { environment } from '@environment';
import { POP_CONFIRM_DELETE_ACTION } from 'libs/shared/src/lib/configs/pop-confirm-button/pop-confirm-button-config';

@Component({
  selector: 'rappider-custom-function-crud',
  templateUrl: './custom-function-crud.component.html',
  styleUrls: ['./custom-function-crud.component.scss']
})
export class CustomFunctionCrudComponent implements OnInit, OnDestroy {
  viewMode = ViewMode.List;
  ListMode = ListMode;
  ViewMode = ViewMode;

  /* Crud configs */
  listGridConfig = CUSTOM_FUNCTION_LIST_CONFIG;
  cardListConfig: CardDataMapConfig = LABEL_CARDS_LIST_CONFIG;
  createItemFormConfig: CrudFormConfig = cloneDeep(CUSTOM_FUNCTION_CREATE_OR_UPDATE_FORM_CONFIG);
  updateItemFormConfig: CrudFormConfig = cloneDeep(CUSTOM_FUNCTION_CREATE_OR_UPDATE_FORM_CONFIG);
  modelNameSingular = '';
  createItemTitle = '';
  programmingLanguages = PROGRAMMING_LANGUAGES;

  /* data */
  customFunctions: CustomFunctionDefinitionWithRelations[] = [];

  /* Active item id in the path for editing or displaying the details of an item */
  activeItemId?: string;
  activeItem?: CustomFunctionDefinitionWithRelations;
  activeProject: ProjectWithRelations;

  /* data loading flag */
  isCustomFunctionsLoading = true;

  /* subscriptions for the data */
  subscriptions: Subscription[] = [];

  /* calculated card subtitles */
  subtitles: { [key: string]: HeadingComponentConfig[] } = {};

  /* config to set title according to crud pages */
  mainTitleConfig: HeadingComponentConfig | undefined;
  titleConfig: BreadcrumbOption[];

  constructor(
    private store: Store<any>,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private jsonValidationService: JsonValidationService
  ) { }

  ngOnInit(): void {
    this.getPathParameters();
    this.subscribeToData();
    this.setTranslatedTitles();
    this.setCrudViewComponentItemsButtonsVisibility();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /* get path parameters for view mode and editing item id */
  getPathParameters() {
    /* active item id is read from the path in order to show data to edit, e.g. /projects/edit/{id} */
    this.activeItemId = this.route.snapshot?.params?.itemId;
    this.viewMode = this.route.snapshot?.params?.viewMode || ViewMode.List;
  }

  setTranslatedTitles() {
    this.createItemTitle = this.translateService.instant(CREATE_ITEM_TITLE);
    this.modelNameSingular = this.translateService.instant(MODEL_NAME_SINGULAR);
  }

  /* set main title for different crud views */
  setMainTitle() {
    switch (this.viewMode) {
      case ViewMode.Create:
        this.mainTitleConfig = { content: this.translateService.instant(CREATE_ITEM_TITLE), type: HeadingType.H3 };
        this.titleConfig = [
          {
            label: this.activeProject?.name,
            redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
          },
          {
            label: LIST_ITEMS_TITLE,
            redirectUrl: PATH_DEFINITIONS.WORKFLOW_FUNCTIONS.WORKFLOW_FUNCTIONS_LIST
          },
          {
            label: CREATE_ITEM_TITLE
          }
        ];
        break;
      case ViewMode.Edit:
        this.mainTitleConfig = { content: this.translateService.instant(EDIT_ITEM_TITLE), type: HeadingType.H3 };
        this.titleConfig = [
          {
            label: this.activeProject?.name,
            redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
          },
          {
            label: LIST_ITEMS_TITLE,
            redirectUrl: PATH_DEFINITIONS.WORKFLOW_FUNCTIONS.WORKFLOW_FUNCTIONS_LIST
          },
          {
            label: EDIT_ITEM_TITLE
          }
        ];
        break;
      default:
        this.mainTitleConfig = { content: this.translateService.instant(LIST_ITEMS_TITLE), type: HeadingType.H3 };
        this.titleConfig = [
          {
            label: this.activeProject?.name,
            redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
          },
          {
            label: LIST_ITEMS_TITLE
          }
        ];
        break;
    }
  }

  // #region SUBSCRIPTIONS
  subscribeToData() {
    this.subscriptions = [
      this.subscribeToCustomFunctionsLoading(),
      this.subscribeToCustomFunctions(),
      this.subscribeToActiveProject()
    ];
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectWithRelations) => {
      if (activeProject) {
        this.activeProject = activeProject;
        /* set main title after setting the active Project */
        this.setMainTitle();
      }
    });
  }

  subscribeToCustomFunctionsLoading() {
    return this.store.select(state => state.customFunction?.isLoading).subscribe((isLoading: boolean) => {
      this.isCustomFunctionsLoading = isLoading;
    });
  }

  subscribeToCustomFunctions() {
    return this.store.select(state => state.customFunction?.data).subscribe((customFunctions: CustomFunctionDefinitionWithRelations[]) => {
      this.customFunctions = customFunctions;

      this.customFunctions = this.customFunctions.map((customFunction) => {
        const languageKey = this.programmingLanguages.find(language => language.value === customFunction.programmingLanguage);
        customFunction = {
          ...customFunction,
          programmingLanguage: languageKey.key
        };
        return customFunction;
      });

      this.customFunctions = [...this.customFunctions];

      if (this.activeItemId) {
        this.activeItem = customFunctions.find(item => item.id === this.activeItemId);
        this.activeItem = {
          ...this.activeItem as CustomFunctionDefinitionWithRelations,
          code: this.activeItem?.code as string,
          requestJSONSchema: JSON.stringify(this.activeItem?.requestJSONSchema),
          responseJSONSchema: JSON.stringify(this.activeItem?.requestJSONSchema)
        };
      }
    });
  }
  // #endregion SUBSCRIPTIONS

  // #region CRUD OPERATIONS
  onCreateFormSubmit(newCustomFunction: any) {
    const requestJSONSampleData = this.jsonValidationService.validateStringifiedJson(newCustomFunction.requestJSONSample, true);
    const responseJSONSampleData = this.jsonValidationService.validateStringifiedJson(newCustomFunction.responseJSONSample, true);

    if (!requestJSONSampleData.isJsonValid || !responseJSONSampleData.isJsonValid) {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'Wrong JSON format'
      );
    } else {
      const createBody: NewCustomFunctionDefinition = {
        ...newCustomFunction,
        requestJSONSample: requestJSONSampleData.dataAsJson || undefined,
        responseJSONSample: responseJSONSampleData.dataAsJson || undefined
      };
      this.store.dispatch(CreateCustomFunction({ customFunction: createBody, navigateAfterSubmit: true }));
    }
  }

  onUpdateFormSubmit(updatedCustomFunction: any) {
    if (
      updatedCustomFunction && this.activeItem?.id &&
      (this.viewMode === ViewMode.Edit || this.viewMode === ViewMode.Update)
    ) {
      const requestJSONSchemaData = this.jsonValidationService.validateStringifiedJson(updatedCustomFunction.requestJSONSchema, true);
      const responseJSONSchemaData = this.jsonValidationService.validateStringifiedJson(updatedCustomFunction.responseJSONSchema, true);

      if (!requestJSONSchemaData.isJsonValid || !responseJSONSchemaData.isJsonValid) {
        this.notificationService.createNotification(
          'error',
          'SHARED.ERROR',
          'Wrong JSON format'
        );
      } else {
        const updateBody: CustomFunctionDefinitionPartial = {
          ...updatedCustomFunction,
          requestJSONSchema: requestJSONSchemaData.dataAsJson || undefined,
          responseJSONSchema: responseJSONSchemaData.dataAsJson || undefined
        };
        this.store.dispatch(UpdateCustomFunction({ id: this.activeItemId as string, customFunction: updateBody, navigateAfterSubmit: true }));
      }
    }
  }

  onItemDeleted(deletedItemAction: any) {
    const deletingItemId = deletedItemAction.data.id;
    this.store.dispatch(DeleteCustomFunction({ id: deletingItemId }));
  }
  // #endregion CRUD OPERATIONS

  setCrudViewComponentItemsButtonsVisibility() {
    this.listGridConfig = {
      ...this.listGridConfig,
      handleVisibilityFn: (data, item, action) =>
        (item.isDeletable && action.name === 'delete-item')
        || (item.isUpdatable && action.name === 'edit-item') || (item.isDeletable && action.name === POP_CONFIRM_DELETE_ACTION.name)

    };
  }
}
