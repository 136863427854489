import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { PageInterface, ProjectInterface } from '@rappider/api-sdk';
import { ActionResponse, BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { PAGE_LAYOUT_LIST_CONFIG } from '@rappider/shared/configs';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Subscription } from 'rxjs';
import { ChangeDefaultLayout, DeleteLayout } from '../../state/layout-state/layout.actions';

@Component({
  selector: 'rappider-page-layout-list',
  templateUrl: './page-layout-list.component.html',
  styleUrls: ['./page-layout-list.component.scss']
})
export class PageLayoutListComponent implements OnInit, OnDestroy {
  /* main title */
  mainTitle: HeadingComponentConfig;
  /* Page title */
  title: string | string[] | BreadcrumbOption[];
  /* layouts */
  layouts: PageInterface[];
  /* List grid config */
  PAGE_LAYOUT_LIST_CONFIG = PAGE_LAYOUT_LIST_CONFIG;
  /* Subscriptions */
  subscriptions: Subscription[] = [];
  /* active project */
  activeProject: ProjectInterface;
  /* layout loading */
  loading: boolean;

  displayToolbar = false;
  displayToolbarBackButton = false;

  constructor(
    private store: Store<any>,
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToLayouts(),
      this.subscribeToLayoutsLoadingState()
    ];
  }

  /**
   * subscribes to active project
   *
   * @return {*}
   * @memberof PageLayoutListComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe(activeProject => {
      if (activeProject) {
        this.activeProject = activeProject;
        /* sets the title */
        this.mainTitle = {
          content: 'PAGES_MODULE.PAGE_LAYOUT_LIST_COMPONENT.PAGE_LAYOUT_LIST',
          type: defaultToolbarTitleHeadingSize
        };
        this.title = [
          {
            label: activeProject.name,
            redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${activeProject?.id}`
          },
          {
            label: PAGE_DEFINITIONS.PAGES.CHILDREN.PAGE_LAYOUTS_LIST.PAGE_TITLE,
          }
        ];
      }
    });
  }

  subscribeToLayouts() {
    return this.store.select(state => state.layout.data).subscribe((layouts) => {
      this.layouts = layouts;
    });
  }

  subscribeToLayoutsLoadingState() {
    return this.store.select(state => state.layout.loading).subscribe((loading: boolean) => {
      this.loading = loading;
    });
  }

  /**
   * on column action click - triggered when clicked delete
   *
   * @param {ActionResponse} actionResponse
   * @memberof PageLayoutListComponent
   */
  onColumnActionClick(actionResponse: ActionResponse) {
    /* deletes the selected page by page id */
    if (actionResponse.action.name === 'DELETE_ITEM') {
      this.store.dispatch(new DeleteLayout({ layoutId: actionResponse.data.id }));
    }
    if (actionResponse.action.name === 'SET_AS_DEFAULT') {
      /* Changes default layout by page id */
      this.store.dispatch(new ChangeDefaultLayout({ layoutId: actionResponse.data.id }));
    }
  }
}
