import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RappiderDocumentUploadComponent } from './document-upload.component';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { RappiderButtonModule } from '../button/button.module';
import { RappiderPreviewerModule } from '../previewer/previewer.module';

@NgModule({
  declarations: [
    RappiderDocumentUploadComponent
  ],
  imports: [
    CommonModule,
    NzUploadModule,
    RappiderButtonModule,
    RappiderPreviewerModule
  ],
  exports: [
    RappiderDocumentUploadComponent
  ]
})
export class RappiderDocumentUploadModule { }
