import { Validators } from '@angular/forms';
import { ButtonType, CrudFormConfig, CrudFormSelectItem, CrudViewFormItemType } from '@rappider/rappider-components/utils';
import { TITLE_CASE_DATA_FIELD_NAME_REGEX } from '../../definitions/regex';
import { UIDataEventSelectOptions } from '../../definitions/ui-data-event/ui-data-event-type-select-options';
import { MONACO_MARKDOWN_CONFIG } from '../monaco-markdown-config';

export const UI_DATA_EVENT_CREATE_OR_EDIT_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormSelectItem>
    {
      title: 'SHARED.UI_DATA_STORE',
      fieldName: 'uiDataStoreId',
      type: CrudViewFormItemType.Select,
      showRequiredSign: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewFormItemType.TextBox,
      showRequiredSign: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          type: Validators.pattern(TITLE_CASE_DATA_FIELD_NAME_REGEX),
          errorKey: 'pattern',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.MUST_BE_TITLE_CASE_MESSAGE'
        }
      ]
    },
    <CrudFormSelectItem>
    {
      title: 'SHARED.TYPE',
      fieldName: 'type',
      type: CrudViewFormItemType.Select,
      options: UIDataEventSelectOptions,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    MONACO_MARKDOWN_CONFIG,
    {
      title: 'Create Success And Failure Events',
      fieldName: 'createSuccessAndFailureEvents',
      type: CrudViewFormItemType.Switch
    }
  ],
  submitButton: {
    type: ButtonType.Primary,
    text: 'Submit'
  },
  itemSettings: {
    inputComponentSize: {
      xs: 24
    }
  }
};
