<rappider-transformation-info-bar *ngIf="value"
                                  [(ngModel)]="value.operationField"
                                  [sortStepsOptPlaceFlag]="sortStepsOptPlaceFlag"
                                  [tree]="tree"
                                  (showStepsFlag)="onShowStepsFlag($event)"
                                  (operationFieldChange)="onOperationFieldChange()"></rappider-transformation-info-bar>
<rappider-sort-row-element *ngIf="value && tree"
                           [tree]="currentTree"
                           [sortStepsSelectOptFlag]="sortStepsSelectOptFlag"
                           (showStepsFlag)="onShowStepsFlag($event)"
                           [(ngModel)]="value.operations"></rappider-sort-row-element>