import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIDataStoreFieldListComponent } from './components/ui-data-store-field-list/ui-data-store-field-list.component';
import { RouterModule, Routes } from '@angular/router';
import { PAGE_DEFINITIONS } from '@rappider/shared/definitions';
import { DataSchemaModule } from '../data-schema/data-schema.module';
import { RappiderSpinModule } from '@rappider/rappider-components';

const routes: Routes = [
  {
    path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_DATA_STORE_FIELDS.URL}/:dataStoreId`,
    component: UIDataStoreFieldListComponent
  }
];

@NgModule({
  declarations: [
    UIDataStoreFieldListComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    DataSchemaModule,
    RappiderSpinModule
  ],
  exports: [
    UIDataStoreFieldListComponent
  ]
})
export class UIDataStoreFieldModule { }
