import { DEFAULT_PAGINATION } from '@rappider/shared/definitions';
import { Pagination } from '@rappider/shared/interfaces';
import * as ProjectVersionActions from './project-version.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

export const projectVersionFeatureKey = 'projectVersion';

export interface State {
  data: any[];
  pagination: Pagination;
  loading: boolean;
}

export const initialState: State = {
  data: null,
  pagination: {
    currentPageNumber: DEFAULT_PAGINATION.ACTIVE_PAGE_NUMBER,
    pageSize: DEFAULT_PAGINATION.PAGE_SIZE,
    totalCount: null,
    totalPageNumber: null
  },
  loading: false
};

export function reducer(
  state: State = initialState,
  action: ProjectVersionActions.Actions | AuthenticationActions.Actions
): State {
  switch (action.type) {

    case ProjectVersionActions.ActionTypes.EnableProjectVersionsLoading:
      return {
        ...state,
        loading: true
      };

    case ProjectVersionActions.ActionTypes.DisableProjectVersionsLoading:
      return {
        ...state,
        loading: false
      };

    case ProjectVersionActions.ActionTypes.SetProjectVersions:
      return {
        ...state,
        data: action.payload.projectVersions
      };

    case ProjectVersionActions.ActionTypes.SetPagination:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload.pagination
        }
      };

    case AuthenticationActions.ActionTypes.Logout:
      return initialState;

    case AuthenticationActions.ActionTypes.ChangeActiveProjectSuccessful:
      return initialState;

    default:
      return state;
  }
}
