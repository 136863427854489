import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NotificationService } from 'libs/components/src/lib/services';
import { Subscription } from 'rxjs';
import { GlobalError } from '../../utils/error.interface';

@Component({
  selector: 'rappider-global-error',
  templateUrl: './global-error.component.html',
  styleUrls: ['./global-error.component.scss'],
})
export class GlobalErrorComponent implements OnInit, OnDestroy {

  error: GlobalError;

  subscriptions: Subscription[] = [];

  constructor(
    private store: Store<any>,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToGlobalError()
    ];
  }

  subscribeToGlobalError() {
    return this.store.select(state => state.error.error).subscribe(error => {
      this.error = error;
      if (this.error) {
        this.createNotication();
      }
    });
  }

  createNotication() {
    this.notificationService.createNotification(
      'error',
      this.error.errorHeader || 'Error',
      this.error.errorText
    );
  }

}
