import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { NotificationService } from '@rappider/services';
import * as ProjectThemeActions from './project-theme.actions';
import { ProjectThemeControllerService, ProjectThemeWithRelations } from '@rappider/rappider-sdk';
import { Navigate } from '@rappider/shared';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';

@Injectable()
export class ProjectThemeEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private notificationService: NotificationService,
    private projectThemeApi: ProjectThemeControllerService
  ) { }

  /**
   * Get Project Themes
   *
   * @memberof ProjectThemeEffects
   */

  getProjectThemes$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectThemeActions.GetProjectThemes>(
      ProjectThemeActions.ActionTypes.GetProjectThemes
    ),
    withLatestFrom(
      this.store.select(state => state.activeProject.data?.id)
    ),
    mergeMap(([action, activeProjectId]) => {
      const params = {
        filter: {
          where: { projectId: activeProjectId },
          order: ['createdDate DESC']
        }
      };
      /* get project themes if active project exist */
      if (activeProjectId) {
        return this.projectThemeApi.find(params).pipe(
          mergeMap((projectThemes: ProjectThemeWithRelations[]) => [
            new ProjectThemeActions.GetProjectThemesSuccessful({ projectThemes: projectThemes })
          ]),
          catchError(error => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_ROLE_COULDNOT_CREATED'
            );
            return [
              new ProjectThemeActions.ProjectThemeError({ errorOn: 'GetProjectThemes', error: error }),
            ];
          })
        );
      } else {
        return [new ProjectThemeActions.GetProjectThemesFailure({ error: 'Active project could not found', key: 'GetProjectThemesFailure', timestamp: Date.now() })];
      }
    })
  ));

  /**
   * Create Project Theme and update state fields
   *
   * @memberof ProjectThemeEffects
   */

  createProjectTheme$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectThemeActions.CreateProjectTheme>(
      ProjectThemeActions.ActionTypes.CreateProjectTheme
    ),
    withLatestFrom(
      this.store.select(state => state.activeProject.data?.id)
    ),
    mergeMap(([action, activeProjectId]) => {
      const params = {
        body: {
          ...action.payload.projectTheme,
          projectId: activeProjectId
        }
      };

      return this.projectThemeApi.create(params).pipe(
        mergeMap(projectTheme => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFUL',
            'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_THEME_CREATED'
          );
          return [
            new ProjectThemeActions.CreateProjectThemeSuccessful({ projectTheme: projectTheme }),
            new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_THEME_LIST_PATH}` })
          ];
        }),
        catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_THEME_COULDNOT_CREATED'
          );
          return [
            new ProjectThemeActions.ProjectThemeError({ errorOn: 'CreateProjectTheme', error: error }),
          ];
        })
      );
    })
  ));

  /**
   * Update Project Theme and update state fields
   *
   * @memberof ProjectThemeEffects
   */

  updateProjectTheme$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectThemeActions.UpdateProjectTheme>(
      ProjectThemeActions.ActionTypes.UpdateProjectTheme
    ),
    mergeMap(action => {
      const actions = [];
      if (action.payload.navigateToProjectThemeList === true) {
        actions.push(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_THEME_LIST_PATH}` }));
      }
      const params = {
        id: action.payload.projectThemeId,
        body: action.payload.projectTheme
      };
      return this.projectThemeApi.updateById(params).pipe(
        mergeMap(projectTheme => {
          this.notificationService.createNotification(
            'success',
            projectTheme.name,
            'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_THEME_UPDATED'
          );
          actions.push(new ProjectThemeActions.UpdateProjectThemeSuccessful({ updatedProjectTheme: projectTheme }));
          return actions;
        }),
        catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_THEME_COULDNOT_UPDATED'
          );
          return [
            new ProjectThemeActions.ProjectThemeError({ errorOn: 'UpdateProjectTheme', error: error }),
          ];
        })
      );
    })
  ));

  /**
   * Delete Project Theme and update state fields
   *
   * @memberof ProjectThemeEffects
   */

  deleteProjectTheme$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectThemeActions.DeleteProjectTheme>(
      ProjectThemeActions.ActionTypes.DeleteProjectTheme
    ),
    mergeMap(action => {
      const params = {
        id: action.payload.projectThemeId
      };
      return this.projectThemeApi.deleteById(params).pipe(
        mergeMap(projectTheme => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFUL',
            'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_THEME_DELETED'
          );
          return [
            new ProjectThemeActions.DeleteProjectThemeSuccessful({ deletedProjectThemeId: params.id })
          ];

        }),
        catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_THEME_COULDNOT_DELETED'
          );
          return [
            new ProjectThemeActions.ProjectThemeError({ errorOn: 'DeleteProjectTheme', error: error }),
          ];
        })
      );
    })
  ));

}
