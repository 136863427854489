import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DataMappingModule } from './modules/data-mapping/data-mapping.module';
import { DataTransformationComponent } from './components/data-transformation/data-transformation.component';
import { FormsModule } from '@angular/forms';
import {
  RappiderButtonModule,
  RappiderTextboxModule,
  RappiderNumberInputModule,
  RappiderCheckboxModule,
  RappiderSelectModule,
  RappiderDatePickerModule,
  RappiderListGridModule,
} from '@rappider/rappider-components';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { TranslateModule } from '@ngx-translate/core';
import { SharedComponentsModule } from './modules/shared-components/shared-components.module';
import { FilterFindModule } from './modules/filter-find/filter-find.module';
import { RappiderModalModule } from '@rappider/rappider-components';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { SortModule } from './modules/sort/sort.module';
import { JoyrideModule } from 'ngx-joyride';
import { NzIconModule } from 'ng-zorro-antd/icon';


@NgModule({
  imports: [
    CommonModule,
    DataMappingModule,
    NzTreeModule,
    FormsModule,
    RappiderButtonModule,
    RappiderTextboxModule,
    RappiderNumberInputModule,
    RappiderCheckboxModule,
    RappiderSelectModule,
    TranslateModule,
    SharedComponentsModule,
    FilterFindModule,
    RappiderDatePickerModule,
    RappiderListGridModule,
    RappiderModalModule,
    NzDropDownModule,
    SortModule,
    JoyrideModule.forChild(),
    NzIconModule
  ],
  declarations: [
    DataTransformationComponent
  ],
  exports: [
    DataTransformationComponent
  ]
})
export class DataTransformationModule { }
