import { Injectable } from '@angular/core';
import {
  ComponentInputDefinition,
  DataSchemaWithRelations
} from '@rappider/rappider-sdk';
import {
  CrudFormItem,
  CrudViewFormItemType,
  CrudFormSelectItem,
  CrudFormObjectItem,
  CrudFormConfig
} from '@rappider/rappider-components/utils';
import { DEFAULT_UI_DATA_SELECTOR } from '../../definitions/edit-form-settings';
import { StringTransformService } from '../string-transform-service/string-transform.service';
import { KeyValue } from '@angular/common';
import { ComponentInputDefinitionInterface, DataSchemaInterface } from '../../sdk/models';

@Injectable({
  providedIn: 'root'
})
export class EditFormService {

  constructor(
    private stringTransformService: StringTransformService
  ) { }

  setCrudFormItemOptions(crudItemForm: CrudFormItem, options: KeyValue<string, any>[]): void {
    if (options?.length) {
      /* set options as array by mapping as key value */
      (<CrudFormSelectItem>crudItemForm).options = options;
    }
  }

  createCrudFormItem(title: string, fieldName: string, componentType: CrudViewFormItemType, category?: string): CrudFormItem {
    const crudFormItem: CrudFormItem = {
      title: title,
      fieldName: fieldName,
      type: componentType
    };
    return crudFormItem;
  }

  createCrudFormItemByComponentInputDefinition(componentInputDefinition: ComponentInputDefinition): CrudFormItem {
    /* set ui data selector */
    const uiDataSelector: CrudViewFormItemType = (
      componentInputDefinition.uiDataSelector ||
      componentInputDefinition.type?.defaultUIDataSelector ||
      DEFAULT_UI_DATA_SELECTOR
    ) as CrudViewFormItemType;

    /* create crud form item */
    const crudFormItem: CrudFormItem = this.createCrudFormItem(
      componentInputDefinition.title,
      componentInputDefinition.fieldName,
      uiDataSelector,
      componentInputDefinition.category
    );

    /* set nested items */
    if (!componentInputDefinition.isArray) {
      (<CrudFormObjectItem>crudFormItem).items = this.createCrudFormItemsByDataSchema(componentInputDefinition.type);
    }

    const options: KeyValue<string, any>[] = (<any>componentInputDefinition?.type)?.enumData?.data;

    /* set enum data ( e.g.: Gender field => [ male , female ] options ) */
    if (options?.length) {
      /* set form item options to be selected */
      this.setCrudFormItemOptions(crudFormItem, options);
    }
    return crudFormItem;
  }

  createCrudFormItemsByDataSchema(dataSchema: DataSchemaInterface): CrudFormItem[] {
    return dataSchema?.fields?.map(dataSchemaField => {

      /* get default data definition of the specified data schema */
      const options = (<DataSchemaWithRelations><unknown>dataSchemaField.type)?.enumData?.data as KeyValue<string, any>[];

      /* type of the crud form item */
      const formItemType = <CrudViewFormItemType>(
        dataSchemaField.uiDataSelector ||
        dataSchemaField.type?.defaultUIDataSelector ||
        DEFAULT_UI_DATA_SELECTOR
      );

      /* create crud form item */
      const crudFormItem: CrudFormItem = this.createCrudFormItem(
        this.stringTransformService.getSeperatedTextFromCapitalizedCombinedText(
          this.stringTransformService.getCapitalizedText(dataSchemaField.name)
        ),
        dataSchemaField.name,
        formItemType
      );

      /* set options by enum data ( e.g.: Gender field => [ male , female ] options ) */
      if (options) {
        /* set form item options to be selected */
        this.setCrudFormItemOptions(crudFormItem, options);
      }
      if (dataSchemaField.type?.fields?.length && !dataSchemaField.isArray) {
        /* set items as recursively */
        (<CrudFormObjectItem>crudFormItem).items = this.createCrudFormItemsByDataSchema(dataSchemaField.type);
      }

      /* set type as default ui data selector if still not set */
      if (!crudFormItem.type) {
        crudFormItem.type = DEFAULT_UI_DATA_SELECTOR;
      }

      crudFormItem.metadata = {
        key: dataSchemaField.id
      };
      return crudFormItem;
    });

  }

  createCrudFormItemsByComponentInputDefinitions(componentInputDefinitions: ComponentInputDefinition[]) {
    const crudFormItems: CrudFormItem[] = componentInputDefinitions?.map(componentInputDefinition => {
      const crudFormItem: CrudFormItem = this.createCrudFormItemByComponentInputDefinition(componentInputDefinition);
      crudFormItem.index = componentInputDefinition.index;
      crudFormItem.metadata = {
        key: componentInputDefinition.id
      };
      return crudFormItem;
    });
    return crudFormItems;
  }

  addNewItemToConfig(formItem: CrudFormItem, formConfig: CrudFormConfig): CrudFormConfig {
    let config: CrudFormConfig;

    const isFieldAlreadyExist = formConfig.items.some(item => item.fieldName === formItem.fieldName);
    if (!isFieldAlreadyExist) {
      config = {
        ...formConfig,
        items: [
          ...formConfig.items,
          formItem
        ]
      };
    }
    return config;
  }

  deleteItemFromConfigByFieldName(fieldName: string, formConfig: CrudFormConfig): CrudFormConfig {
    const config = {
      ...formConfig,
      items: formConfig.items.filter(item => item.fieldName !== fieldName)
    };
    return config;
  }

  /**
   * enables or disables form items by given field names and returns crud form items
   *
   * @param {string[]} fieldNames
   * @param {CrudFormConfig} formConfig
   * @param {boolean} isDisabled
   * @return {*}  {CrudFormItem[]}
   * @memberof EditFormService
   */
  enableOrDisableFormItemsByFieldNames(fieldNames: string[], formConfig: CrudFormConfig, isDisabled: boolean): CrudFormItem[] {
    return formConfig.items?.map(item => {
      if (fieldNames.includes(item.fieldName)) {
        return {
          ...item,
          disabled: isDisabled
        };
      } else {
        return item;
      }
    });
  }
}
