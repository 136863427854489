import { ButtonComponentConfig, ButtonSize } from '@rappider/rappider-components/utils';
import { IconType } from '../../../definitions/icon-type.enum';

export const addEndpointButtonConfig: ButtonComponentConfig = {
  text: 'Add New Endpoint',
  icon: {
    name: 'plus',
    type: IconType.NgZorro
  },
  size: ButtonSize.ExtraSmall
};
