import { Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { GrouppedOption, SelectSettings } from '@rappider/rappider-components/utils';
import { DataSchemaFieldRowPages, LastProcessedAction } from '@rappider/shared/interfaces';
import { ButtonComponentConfig } from 'libs/components/src/lib/utils';
import {
  UpdateDataSchema
} from 'libs/project/src/lib/states/data-schema/data-schema.actions';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { DATA_SCHEMA_CREATE_OR_EDIT_CONFIG } from '../../../configs/data-schema-create-or-edit-config';
import { DataSchemaElementRow, DataSchemaElementRowOutput } from '../data-schema-element-row/models/data-schema-element-row';
import { DataSchemaFieldRowType } from '../data-schema-element-row/models/field-type.enum';
import { CREATE_DATA_SCHEMA_BUTTON, DATA_SCHEMA_ENUM_DATA_CONFIG, EDIT_DATA_SCHEMA_BUTTON, EDIT_ICON } from './model/data-schema-selectbox';
import * as DataSchemaActions from '../../../../states/data-schema/data-schema.actions';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DataSchemaTypes } from '../../models/data-schema-type.enum';
import { DataSchemaField, DataSchemaFieldBulkUpdateDto, DataSchemaFieldCreateDto, DataSchemaFieldUpdateDto, DataSchemaWithRelations } from '@rappider/rappider-sdk';
import { getDataSchemasWithDetailsSelector } from 'libs/project/src/lib/states/selectors/get-data-schemas-with-details.selector';

@Component({
  selector: 'rappider-data-schema-selectbox',
  templateUrl: './data-schema-selectbox.component.html',
  styleUrls: ['./data-schema-selectbox.component.scss']
})
export class DataSchemaSelectboxComponent implements OnInit, OnChanges {

  @Input() dataSchemaElementRow: DataSchemaElementRow;
  @Input() typeOptions: GrouppedOption[];
  @Input() selectSettings: SelectSettings;
  @Input() dataSchemaFieldPageType: DataSchemaFieldRowPages;
  @Input() activeProjectId: string;
  @Input() disabled: boolean;

  @Output() searchText = new EventEmitter<string>();
  @Output() valueChange = new EventEmitter<string>();
  @Output() dataSchemaCreated = new EventEmitter<DataSchemaWithRelations>();
  @Output() modalSaveOrCancel = new EventEmitter<boolean>();

  @ViewChild('createDataSchemaTemplate', { static: true }) createDataSchemaTemplate: TemplateRef<{}>;
  @ViewChild('dataSchemaFieldsTemplate', { static: true }) dataSchemaFieldsTemplate: TemplateRef<{}>;
  @ViewChild('editDataSchemaTemplate', { static: true }) editDataSchemaTemplate: TemplateRef<{}>;
  @ViewChild('editDataSchemaModalTitleTemplate', { static: true }) editDataSchemaModalTitleTemplate: TemplateRef<{}>;
  @ViewChild('dataSchemaEnumDataTemplate', { static: true }) dataSchemaEnumDataTemplate: TemplateRef<{}>;

  dataSchemaFieldRowType = DataSchemaFieldRowType.Default;
  DataSchemaFieldRowPages = DataSchemaFieldRowPages;

  dataSchemaFormConfig = DATA_SCHEMA_CREATE_OR_EDIT_CONFIG;
  dataSchemaEnumDataConfig = DATA_SCHEMA_ENUM_DATA_CONFIG;
  editIcon = EDIT_ICON;

  buttons: ButtonComponentConfig[];

  parentDataSchema: DataSchemaWithRelations;
  dataSchemas: DataSchemaWithRelations[];

  createDataSchemaModal: NzModalRef;
  dataSchemaFieldModal: NzModalRef;
  editDataSchemaModal: NzModalRef;
  dataSchemaEnumDataModal: NzModalRef;

  lastProcessActionSubscription: Subscription;
  dataSchemaSubscription: Subscription;

  constructor(
    private nzModalService: NzModalService,
    private store: Store<any>,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.setButtonConfig();
    this.subscribeToDataSchema();
  }

  ngOnChanges(): void {
    this.setButtonConfig();
  }

  subscribeToDataSchema() {
    this.dataSchemaSubscription = this.store.select(<any>getDataSchemasWithDetailsSelector).subscribe((dataSchemas: DataSchemaWithRelations[]) => {
      this.dataSchemas = dataSchemas;
    });
  }

  subscribeToLastProcessedAction() {
    this.lastProcessActionSubscription = this.store.select(state => state.dataSchema.lastProcessedAction)
      .subscribe((lastProcessedAction: LastProcessedAction) => {
        if (lastProcessedAction?.action === DataSchemaActions.ActionTypes.CreateDataSchemaSuccessful
          && lastProcessedAction?.success) {
          this.parentDataSchema = lastProcessedAction.data;
          this.onDataSchemaCreatedSuccessful();
        }
      });
  }

  setButtonConfig() {
    this.buttons = [CREATE_DATA_SCHEMA_BUTTON];
    if (!this.buttons.some(element => element.key === 'edit') && this.dataSchemaElementRow.typeId) {
      this.buttons = [
        ...this.buttons,
        EDIT_DATA_SCHEMA_BUTTON
      ];
    }
  }

  onInputDefinitionSelectboxButtonClick(button: ButtonComponentConfig) {
    if (button.key === CREATE_DATA_SCHEMA_BUTTON.key) {
      this.showCreateDataSchemaModal();
    }
    if (button.key === EDIT_DATA_SCHEMA_BUTTON.key) {
      this.openModalByDataSchemaType();
    }
  }

  openModalByDataSchemaType() {
    this.findParentDataSchema();
    if (this.parentDataSchema.type === DataSchemaTypes.Model) {
      this.showDataSchemaFieldsModal();
    }
    if (this.parentDataSchema.type === DataSchemaTypes.Enum) {
      this.showDataSchemaEnumDataModal();
    }
  }

  findParentDataSchema() {
    this.parentDataSchema = this.dataSchemas?.find(dataSchema => dataSchema.id === this.dataSchemaElementRow.typeId);
  }

  onSearchTextChange(value: string) {
    this.searchText.emit(value);
  }

  onValueChange(dataSchemaId: string) {
    this.valueChange.emit(dataSchemaId);
    this.findParentDataSchema();
    this.setButtonConfig();
  }

  // #region Create Data Schema

  showCreateDataSchemaModal() {
    this.createDataSchemaModal = this.nzModalService.create({
      nzTitle: this.translateService.instant('PROJECT_MODULE.DATA_SCHEMA_MODULE.DATA_SCHEMA_SELECTBOX.CREATE_DATA_SCHEMA'),
      nzWidth: '90%',
      nzContent: this.createDataSchemaTemplate,
      nzFooter: null,
      nzOnCancel: () => { /* Do nothing */ }
    });
  }

  onCreateDataSchemaFormSubmit(dataSchema: DataSchemaWithRelations) {
    this.lastProcessActionSubscription?.unsubscribe();
    // dispatch create data schema action by page type
    if (this.dataSchemaFieldPageType === DataSchemaFieldRowPages.InputDefinition
      || this.dataSchemaFieldPageType === DataSchemaFieldRowPages.OutputDefinition) {
      this.store.dispatch(new DataSchemaActions.CreateComponentIOTypeDataSchema({
        dataSchema: dataSchema,
        navigateToDataSchemaList: false
      }));
    } else if (this.dataSchemaFieldPageType === DataSchemaFieldRowPages.DataEvent
      || this.dataSchemaFieldPageType === DataSchemaFieldRowPages.DataStore
      || this.dataSchemaFieldPageType === DataSchemaFieldRowPages.ModelField) {
      this.store.dispatch(new DataSchemaActions.CreateUserGeneratedDataSchema({
        dataSchema: dataSchema,
        navigateToDataSchemaList: false
      }));
    }
    // subscribe to last processed action and get created data schema if data schema created successful
    this.subscribeToLastProcessedAction();
  }

  onDataSchemaCreatedSuccessful() {
    // unsubscribe to last process action
    this.lastProcessActionSubscription.unsubscribe();
    // reset last processed action
    this.store.dispatch(new DataSchemaActions.ResetLastProcessedAction());
    // emit created data schema
    this.dataSchemaCreated.emit(this.parentDataSchema);
    // close create data schema modal
    this.onModalSave(this.createDataSchemaModal);
    this.openModalByDataSchemaType();
  }

  // #endregion Create Data Schema

  // #region Data Schema Fields

  showDataSchemaFieldsModal() {
    this.findParentDataSchema();
    this.dataSchemaFieldModal = this.nzModalService.create({
      nzTitle: this.editDataSchemaModalTitleTemplate,
      nzWidth: '90%',
      nzContent: this.dataSchemaFieldsTemplate,
      nzFooter: null,
      nzOnCancel: () => { }
    });
  }

  /* this function gets data schema field differences and dispatches actions */
  onDataSchemaFieldModalSaved(dataSchemaFieldDifferences: DataSchemaElementRowOutput) {
    // dispatch the bulk update action if the field or fields of the data schema have been updated
    if (dataSchemaFieldDifferences?.updated?.length) {
      const updatedDataSchemaFields = dataSchemaFieldDifferences.updated.map(updatedField => {
        const { id, ...data } = updatedField;
        return {
          id,
          data: data as DataSchemaFieldUpdateDto
        };
      }) as DataSchemaFieldBulkUpdateDto[];
      this.store.dispatch(
        new DataSchemaActions.UpdateAllDataFields({ updatedDataSchemaFields: updatedDataSchemaFields, parentDataSchemaId: this.parentDataSchema.id })
      );
    }
    // dispatch the bulk create action if the field or fields of the data schema have been created
    if (dataSchemaFieldDifferences?.created?.length) {
      const createRequestBody = dataSchemaFieldDifferences.created.map(createdField => ({
        ...createdField,
        parentDataSchemaId: this.parentDataSchema.id
      })) as DataSchemaFieldCreateDto[];

      const filteredPayload = createRequestBody.map(obj => {
        // Check all properties except 'typeId', 'parentDataSchemaId', and 'name'
        const { typeId, parentDataSchemaId, name, ...optionalFields } = obj;

        // Create a new object to store the non-null fields
        const filteredObj = { typeId, parentDataSchemaId, name };

        // Check the optional fields and add the non-null ones
        for (const [key, value] of Object.entries(optionalFields)) {
          if (value !== null) {
            filteredObj[key] = value;
          }
        }
        return filteredObj;
      });

      // Dispatch Create Action
      this.store.dispatch(
        new DataSchemaActions.CreateAllDataFields({ dataSchemaFields: filteredPayload, parentDataSchemaId: this.parentDataSchema.id })
      );
    }
    // dispatch the bulk delete action if the field or fields of the data schema have been deleted
    if (dataSchemaFieldDifferences?.deleted?.length) {
      const deleteRequestBody = dataSchemaFieldDifferences.deleted.map((item: DataSchemaField) => item.id);
      this.store.dispatch(
        new DataSchemaActions.DeleteAllDataFields({ dataSchemaFieldIds: deleteRequestBody, parentDataSchemaId: this.parentDataSchema.id }
        ));
    }
    this.modalSaveOrCancel.emit(true);
    // trigger ok
    this.onModalSave(this.dataSchemaFieldModal);
  }

  onDataSchemaFieldModalCancel() {
    this.modalSaveOrCancel.emit(true);
    this.dataSchemaFieldModal.triggerCancel();
  }

  // #endregion Data Schema Fields

  // #region Edit Data Schema

  showEditDataSchemaModal() {
    this.editDataSchemaModal = this.nzModalService.create({
      nzTitle: `${this.translateService.instant('SHARED.EDIT')} ${this.parentDataSchema.name}`,
      nzWidth: '90%',
      nzContent: this.editDataSchemaTemplate,
      nzOkText: null,
      nzOnCancel: () => { }
    });
  }

  onEditDataSchemaFormSubmit(dataSchema: DataSchemaWithRelations) {
    // dispatch update data schema action
    this.store.dispatch(new UpdateDataSchema({ id: this.parentDataSchema.id, dataSchema: dataSchema, navigateToDataSchemaList: false }));
    // close edit data schema modal
    this.onModalSave(this.editDataSchemaModal);
  }

  // #endregion Edit Data Schema

  // #region Enum Definitions

  showDataSchemaEnumDataModal() {
    this.dataSchemaEnumDataModal = this.nzModalService.create({
      nzTitle: this.editDataSchemaModalTitleTemplate,
      nzWidth: '90%',
      nzContent: this.dataSchemaEnumDataTemplate,
      nzFooter: null,
      nzOnCancel: () => { }
    });
  }

  onDataSchemaEnumDataSave(formData) {
    const enumData = {
      data: formData.data,
      dataSchemaId: this.parentDataSchema.id
    };
    // dispatch create action
    this.store.dispatch(new DataSchemaActions.CreateDataSchemaEnumData({
      enumData: enumData,
      parentDataSchemaId: enumData.dataSchemaId,
      navigateToDataSchemaList: false
    }));
    this.onModalSave(this.dataSchemaEnumDataModal);
  }

  // #endregion Enum Definitions

  onModalSave(modal: NzModalRef) {
    modal?.triggerOk();
  }

}
