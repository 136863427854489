import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  CAMEL_CASE_REGEX,
  defaultToolbarTitleHeadingSize,
  FRONTEND_FRAMEWORK_TYPES,
  PATH_DEFINITIONS,
  QUERY_PARAM_DEFINITIONS,
  UIWorkflowItemIntegrationOperatorTypes,
  UIWorkflowItemTypes,
  UIWorkflowItemTypeValues,
} from '@rappider/shared/definitions';
import { KeyValue } from '@angular/common';
import {
  ProjectModelEndpointWithRelations,
  ProjectModelWithRelations,
  ProjectWithRelations,
  UiDataEvent,
  UiDataStoreWithRelations,
  WorkflowStepFunctionWithRelations
} from '@rappider/rappider-sdk';
import { NotificationService, StringUtilityService, SubscribeToUiDataStoreService } from '@rappider/services';
import {
  BreadcrumbOption, FormLayout,
  HeadingComponentConfig, IconComponentConfig, SelectableOption, SelectMode
} from '@rappider/rappider-components/utils';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectComponentConfig } from 'libs/components/src/lib/utils/select/select-component-config.interface';
import { FormService } from 'libs/components/src/lib/services';
import { UIStepFunctionCreateEditFormItem } from '../../models/ui-step-function-create-edit-form-item.enum';
import { StepFunctionTypeOptions } from '@rappider/shared/interfaces';
import { CreateUIWorkflowStepFunction } from 'libs/project/src/lib/states/ui-step-functions/ui-step-function.actions';
import { CreatedWorkflowItem } from 'libs/diagram-editor/src/lib/utils/interfaces/diagram-settings/created-workflow-item.interface';
import { DiagramItemType } from 'libs/diagram-editor/src/lib/utils/diagram-item-type';
import { FormItemsInfoMessages } from '../../utils/definitions/form-items-info-messages.config';
import { StepFunctionTypeRadioGroupOptions } from '../../utils/definitions/step-function-type-options.config';
import { WorkflowEvent } from '@rappider/api-sdk';
import { getWorkflowStepFunctionsAndTemplates } from '../utils/get-workflow-step-functions-and-templates-selector';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'rappider-ui-step-function-create',
  templateUrl: './ui-step-function-create.component.html',
  styleUrls: ['./ui-step-function-create.component.scss']
})
export class UIStepFunctionCreateComponent implements OnInit, OnDestroy {
  @Input() uiDataStoreId: string;
  /* subscribed ui data event id */
  @Input() subscribedEventId: string;
  /* flag for whether to navigate after ui step function creation */
  @Input() navigateAfterCreate = true;
  /* layout of the form 'horizontal' or 'vertical' */
  @Input() formLayout: FormLayout = FormLayout.Horizontal;
  /* ui data store */
  @Input() uiDataStore: UiDataStoreWithRelations;
  @Input() isSubmitButtonFloat: boolean;

  /* flag to display breadcrumb under title */
  @Input() displayBreadcrumb = true;
  /* flag to show ui data store selectbox */
  @Input() showUIDataStoreSelection = false;
  @Input() loading = false;

  @Output() lastCreatedStepFunctionData = new EventEmitter<CreatedWorkflowItem>();

  /* form group */
  createUIStepFunctionForm: FormGroup;
  /* project model options */
  projectModelOptions: SelectableOption[];
  /* endpoint options by selected project model */
  endpointOptions: SelectableOption[];
  /* services options by selected project model */
  serviceOptions: SelectableOption[];
  /* services options by selected project model */
  workflowStepFunctionOptions: SelectableOption[];
  /* services options by selected project model */
  serverSideWorkflowEventsOptions: SelectableOption[];
  /* ui step function type options */
  uiStepFunctionTypeOptions: SelectableOption[];
  /* subscribed events select component config */
  subscribedEventsSelectConfig: SelectComponentConfig = {
    options: [],
    settings: {
      mode: SelectMode.Multiple
    }
  };
  /* published events on success select component config */
  publishedEventsOnSuccessSelectConfig: SelectComponentConfig = {
    options: [],
    settings: {
      mode: SelectMode.Single
    }
  };
  /* published events on failure select component config */
  publishedEventsOnFailureSelectConfig: SelectComponentConfig = {
    options: [],
    settings: {
      mode: SelectMode.Multiple
    }
  };

  uIWorkflowItemTypeValues = UIWorkflowItemTypeValues;
  uiWorkflowStepFunctionModeRadioGroupValue: StepFunctionTypeOptions;
  StepFunctionTypeOptions = StepFunctionTypeOptions;
  uiWorkflowStepFunctionModeRadioGroupOptions = StepFunctionTypeRadioGroupOptions;
  FormItemsInfoMessages = FormItemsInfoMessages;
  workflowStepFunctions: WorkflowStepFunctionWithRelations[];
  templateWorkflowStepFunctions: WorkflowStepFunctionWithRelations[];
  workflowEvents: WorkflowEvent[];
  projectId: string;

  monacoEditorSettings = {
    editorOptions: {
      theme: 'vs-dark',
      language: 'javascript'
    },
    lineNumbers: true,
    autoCloseBrackets: true,
  };

  /* main title */
  mainTitle: HeadingComponentConfig = {
    content: 'PROJECT_MODULE.UI_STEP_FUNCTION_CREATE_OR_EDIT_COMPONENT.CREATE_UI_STEP_FUNCTION',
    type: defaultToolbarTitleHeadingSize
  };

  infoIconConfig: IconComponentConfig = {
    name: 'fa-regular fa-info-circle',
    color: 'var(--primary-color)'
  };

  /* page title */
  title: BreadcrumbOption[];
  /* active project */
  activeProject: ProjectWithRelations;
  /* subscriptions */
  subscriptions: Subscription[];
  /* ui step function types */
  uiStepFunctionTypes: SelectableOption[] = UIWorkflowItemTypes
    .map(i => <SelectableOption>{ key: i.title, value: i.key });
  /* ui step function integration operator types. e.g.: merge-map */
  uiStepFunctionIntegrationOperatorTypes = UIWorkflowItemIntegrationOperatorTypes
    .map(i => <SelectableOption>{ key: i.title, value: i.key });
  /* options for subscribed and published events */
  uiDataEventOptions: KeyValue<string, string>[];
  /* project model */
  projectModels: ProjectModelWithRelations[];
  /* project model endpoints */
  projectModelEndpoints: ProjectModelEndpointWithRelations[];
  /* default framework */
  DEFAULT_FRAMEWORK = FRONTEND_FRAMEWORK_TYPES.ANGULAR.value;
  /* ui data stores */
  uiDataStores: UiDataStoreWithRelations[];
  /* ui data events */
  uiDataEvents: UiDataEvent[];
  /* ui data subscription data */
  uiDataSubscriptionBody: [
    {
      uiDataStoreId: string;
      dataSchemaFieldIds: string;
    },
  ];
  uiDataStoreSelectOptions: KeyValue<string, string>[];
  subscribedFieldIds: string[];
  uiDataEventsForSelect: KeyValue<string, string>[];
  /* form layout enum */
  FormLayout = FormLayout;

  displayToolbar = false;
  displayToolbarBackButton = false;
  initialValuesOfCreateUIStepFunctionForm: FormGroup;

  /* form will be disabled if the type is pre-defined-template. This flag is to follow the status */
  formDisabledForPreDefinedTemplate = false;

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private formService: FormService,
    private subscribeToUIDataStoreService: SubscribeToUiDataStoreService
  ) { }

  ngOnInit(): void {
    this.getUIDataStoreIdFromUrl();
    this.subscribeToData();
    this.buildForm();
    this.setSubscribedEvents();
    this.setUIDataStoreFormControl();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onTypeValueChanges(): void {
    this.createUIStepFunctionForm.get('type').valueChanges.subscribe(valueOfType => {
      if (valueOfType === UIWorkflowItemTypeValues.PreDefinedTemplate && !this.formDisabledForPreDefinedTemplate) {
        this.formDisabledForPreDefinedTemplate = true;
        /* disable everything except name and type */
        this.disableFormFieldsExceptWhitelisted(['name', 'type']);
      } else if (this.formDisabledForPreDefinedTemplate) {
        this.formDisabledForPreDefinedTemplate = false;
        /* enable all fields */
        this.enableFormFieldsExceptBlacklisted([]);
      }
    });
  }

  buildForm() {
    this.createUIStepFunctionForm = this.formBuilder.group({
      name: [null, [Validators.required, Validators.pattern(CAMEL_CASE_REGEX)]],
      type: ['configuration-driven', Validators.required],
      integrationOperatorType: ['merge-map', Validators.required],
      subscribedEvents: [null, Validators.required],
      publishedEventsOnSuccess: [null],
      publishedEventsOnFailure: [null],
      uiDataSubscriptionBody: [null],
      mode: [null],
      projectModel: [null, Validators.required],
      endpointId: [null, Validators.required],
      code: [null],
      workflowServiceId: [null],
      workflowStepFunctionId: [null],
      workflowEventId: [null],
      uiDataStore: [null, Validators.required]
    });
    this.initialValuesOfCreateUIStepFunctionForm = cloneDeep(this.createUIStepFunctionForm);
    this.subscribeToModeChanges();
    this.onTypeValueChanges();
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToUIDataStore(),
      this.subscribeToUIDataEvents(),
      this.subscribeToProjectModels(),
      this.subscribeToProjectModelEndpoints(),
      this.subscribeToUIStepFunctionsLoading(),
      this.subscribeToWorkflowStepFunctionsAndTemplates(),
      this.subscribeToWorkflowEvents()
    ];
  }

  getUIDataStoreIdFromUrl() {
    if (!this.uiDataStoreId) {
      this.uiDataStoreId = this.activatedRoute.snapshot.params['dataStoreId'];
    }
  }

  subscribeToModeChanges() {
    return this.createUIStepFunctionForm.get('mode').valueChanges.subscribe(mode => {
      this.updateValidators(mode);
    });
  }

  updateValidators(mode: string) {
    const projectModelControl = this.createUIStepFunctionForm.get('projectModel');
    const endpointIdControl = this.createUIStepFunctionForm.get('endpointId');

    if (projectModelControl && endpointIdControl) {
      projectModelControl.clearValidators();
      endpointIdControl.clearValidators();

      if (mode === StepFunctionTypeOptions.Endpoint) {
        projectModelControl.setValidators([Validators.required]);
        endpointIdControl.setValidators([Validators.required]);
      } else {
        projectModelControl.setValidators([Validators.nullValidator]);
        endpointIdControl.setValidators([Validators.nullValidator]);
      }

      projectModelControl.updateValueAndValidity();
      endpointIdControl.updateValueAndValidity();
    }
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectWithRelations) => {
      if (activeProject) {
        this.activeProject = activeProject;
        this.projectId = activeProject.id;
      } else {
        this.activeProject = null;
      }
    });
  }

  subscribeToUIStepFunctionsLoading() {
    return this.store.select(state => state.uiWorkflowStepFunction.loading).subscribe((loading: boolean) => {
      this.loading = loading;
    });
  }

  subscribeToUIDataStore() {
    return this.store.select(state => state.uiDataStore.data).subscribe((uiDataStores: UiDataStoreWithRelations[]) => {
      if (uiDataStores?.length) {
        this.uiDataStore = uiDataStores.find(dataStore => dataStore.id === this.uiDataStoreId);
        this.uiDataStores = uiDataStores;
        this.setTitle();
        this.setSelectOptions();

        if (this.showUIDataStoreSelection) {
          this.setUIDataStoreSelectOptions();
        }
      } else {
        this.uiDataStore = null;
      }
    });
  }

  subscribeToWorkflowStepFunctionsAndTemplates() {
    return this.store.select(<any>getWorkflowStepFunctionsAndTemplates).subscribe(data => {
      if (data) {
        this.workflowStepFunctions = data.workflowStepFunctions;
        this.templateWorkflowStepFunctions = data.templateWorkflowStepFunctions;
        this.setWorkflowStepFunctionFormItem();
        this.setServiceFormItem();
      }
    });
  }

  subscribeToWorkflowEvents() {
    return this.store.select(state => state.workflowEvent.data).subscribe(workflowEvents => {
      this.workflowEvents = workflowEvents;
      if (workflowEvents?.length) {
        this.setServerSideWorkflowEventsFormItem();
      }
    });
  }

  setUIDataStoreFormControl() {
    if (!this.showUIDataStoreSelection) {
      this.createUIStepFunctionForm?.removeControl('uiDataStore');
    }
  }

  setUIDataStoreSelectOptions() {
    this.uiDataStoreSelectOptions = this.uiDataStores?.map(uiDataStore => ({
      key: uiDataStore.name,
      value: uiDataStore.id
    }));

    if (this.uiDataStore) {
      /* added timeout for form building */
      setTimeout(() => {
        this.createUIStepFunctionForm?.patchValue({ uiDataStore: this.uiDataStore?.id });
      }, 50);
    }
  }

  /**
   * updates subscribed event value if subscribed event id input is provided
   *
   * @memberof UIStepFunctionCreateComponent
   */
  setSubscribedEvents() {
    if (this.subscribedEventId) {
      this.createUIStepFunctionForm.patchValue({ subscribedEvents: [this.subscribedEventId] });
    }
  }

  /**
   * sets page title
   *
   * @param {UIDataStoreInterface} uiDataStore
   * @memberof UIStepFunctionCreateComponent
   */
  setTitle() {
    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`,
      },
      {
        label: 'PROJECT_MODULE.UI_DATA_STORE_LIST_COMPONENT.UI_DATA_STORE_LIST',
        redirectUrl: PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_LIST
      },
      {
        label: this.uiDataStore?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_DETAIL}/${this.uiDataStore?.id}`,
        queryParams: QUERY_PARAM_DEFINITIONS.PROJECT.UI_DATA_STORE_DETAIL.UI_STEP_FUNCTIONS_TAB
      },
      {
        label: 'PROJECT_MODULE.UI_STEP_FUNCTION_CREATE_OR_EDIT_COMPONENT.CREATE_UI_STEP_FUNCTION'
      }
    ];
  }

  /**
   * subscribe to ui data events for setting the subscribed and published events
   *
   * @return {*}
   * @memberof UIStepFunctionCreateComponent
   */
  subscribeToUIDataEvents() {
    return this.store.select(state => state.uiDataEvent?.data).subscribe((uiDataEvents: UiDataEvent[]) => {
      if (uiDataEvents?.length) {
        /* map ui data events for select box */
        this.uiDataEvents = uiDataEvents;
        this.setSelectOptions();
      }
    });
  }

  setSelectOptions() {
    if (this.uiDataStores && this.uiDataEvents) {
      const uiDataEventOptions = this.uiDataEvents.map(dataEvent => ({
        key: `${dataEvent.name} (${this.uiDataStores.find(dataStore => dataStore.id === dataEvent.uiDataStoreId).name})`,
        value: dataEvent.id
      }));
      this.subscribedEventsSelectConfig.options = uiDataEventOptions || [];
      this.uiDataEventsForSelect = uiDataEventOptions || [];
      this.publishedEventsOnFailureSelectConfig.options = uiDataEventOptions || [];
    }
  }

  subscribeToProjectModels() {
    return this.store.select(state => state.projectModel?.data).subscribe((projectModels: ProjectModelWithRelations[]) => {
      this.projectModels = projectModels;
      /* set project model options */
      const projectModelSelectOptions = projectModels?.map((projectModel: ProjectModelWithRelations) => ({
        key: projectModel.name,
        value: projectModel.id
      }));
      this.projectModelOptions = projectModelSelectOptions || [];
    });
  }

  subscribeToProjectModelEndpoints() {
    return this.store.select(state => state.projectModelEndpoint?.data).subscribe((projectModelEndpoints: ProjectModelEndpointWithRelations[]) => {
      this.projectModelEndpoints = projectModelEndpoints;
    });
  }

  /**
   * when project model change, reset enpointId value and options
   *
   * @param {string} projectModelId
   * @memberof UIStepFunctionCreateComponent
   */
  onProjectModelValueChange(projectModelId: string) {
    this.endpointOptions = [];
    this.createUIStepFunctionForm.controls[UIStepFunctionCreateEditFormItem.EndpointId].reset();
    if (projectModelId !== null) {
      this.setEndpointsFormItem(projectModelId);
    }
  }

  /**
   * sets endpoints select box form item in config
   *
   * @param {any[]} endpoints
   * @memberof UIStepFunctionCreateComponent
   */
  setEndpointsFormItem(projectModelId: string) {
    const endpoints = this.projectModelEndpoints?.filter(projectModelEndpoint => projectModelEndpoint.modelId === projectModelId);

    this.endpointOptions = endpoints?.map(endpoint => ({
      key: endpoint.name,
      value: endpoint.id
    }));

  }

  /**
   * sets endpoints select box form item in config
   *
   * @param {any[]} service
   * @memberof UIStepFunctionCreateComponent
   */
  setServiceFormItem() {
    this.serviceOptions = this.templateWorkflowStepFunctions?.map(templateWorkflowStepFunction => ({
      key: `${templateWorkflowStepFunction.serviceName}.${templateWorkflowStepFunction.functionName}`,
      value: templateWorkflowStepFunction.id
    }));
  }

  setWorkflowStepFunctionFormItem() {
    this.workflowStepFunctionOptions = this.workflowStepFunctions?.map(workflowStepFunction => ({
      key: workflowStepFunction.name,
      value: workflowStepFunction.id
    }));
  }

  setServerSideWorkflowEventsFormItem() {
    this.serverSideWorkflowEventsOptions = this.workflowEvents?.map(workflowEvent => ({
      key: workflowEvent.name,
      value: workflowEvent.id
    }));
  }

  getNameFieldErrorsByErrorKey(errorKey: string) {
    const control = this.createUIStepFunctionForm.get(UIStepFunctionCreateEditFormItem.Name);
    const isDirty = control.dirty;
    const errors = control.errors;
    return isDirty && errors && control.hasError(errorKey);
  }

  /**
   * deletes or adds projectModel and endpointId by radio group value
   *
   * @param {string} value
   * @memberof UIStepFunctionCreateComponent
   */
  stepFunctionModeSelectValueChange(value: StepFunctionTypeOptions) {
    this.createUIStepFunctionForm.get('projectModel')?.reset();
    this.createUIStepFunctionForm.get('endpointId')?.reset();
    this.createUIStepFunctionForm.get('code')?.reset();
    this.createUIStepFunctionForm.get('workflowServiceId')?.reset();
    this.createUIStepFunctionForm.get('workflowStepFunctionId')?.reset();
    this.createUIStepFunctionForm.get('workflowEventId')?.reset();
    this.uiWorkflowStepFunctionModeRadioGroupValue = value;
    if (value === StepFunctionTypeOptions.CustomCode) {
      this.createUIStepFunctionForm.removeControl(UIStepFunctionCreateEditFormItem.ProjectModel);
      this.createUIStepFunctionForm.removeControl(UIStepFunctionCreateEditFormItem.EndpointId);
      this.endpointOptions = [];
    } else {
      this.createUIStepFunctionForm.addControl(UIStepFunctionCreateEditFormItem.ProjectModel, new FormControl());
      this.createUIStepFunctionForm.addControl(UIStepFunctionCreateEditFormItem.EndpointId, new FormControl());
    }
  }

  onCreateUIStepFunction() {
    this.formService.checkFormValidation(this.createUIStepFunctionForm);
    if (this.createUIStepFunctionForm.valid) {
      const createUIStepFunctionFormValue = this.createUIStepFunctionForm.value;
      this.uiDataSubscriptionBody = this.subscribeToUIDataStoreService.
        setUIDataSubscriptionBody(createUIStepFunctionFormValue.uiDataSubscriptionBody);
      const uiWorkflowStepFunctionCreateBody = {
        name: createUIStepFunctionFormValue.name,
        type: createUIStepFunctionFormValue.type,
        integrationOperatorType: createUIStepFunctionFormValue.integrationOperatorType,
        subscribedEventIds: createUIStepFunctionFormValue.subscribedEvents,
        publishedEventsOnSuccessWithCondition: createUIStepFunctionFormValue.publishedEventsOnSuccess || [],
        publishedEventOnFailureIds: createUIStepFunctionFormValue.publishedEventsOnFailure || [],
        uiDataSubscriptionBody: this.uiDataSubscriptionBody || null,
        mode: createUIStepFunctionFormValue.mode,
        endpointId: createUIStepFunctionFormValue.endpointId,
        code: createUIStepFunctionFormValue.code,
        workflowStepFunctionId: createUIStepFunctionFormValue.workflowStepFunctionId,
        workflowEventId: createUIStepFunctionFormValue.workflowEventId,
        workflowServiceId: createUIStepFunctionFormValue.workflowServiceId,
        uiDataStoreId: createUIStepFunctionFormValue.uiDataStore ? createUIStepFunctionFormValue.uiDataStore : this.uiDataStoreId
      };

      if (uiWorkflowStepFunctionCreateBody.uiDataStoreId) {
        this.store.dispatch(new CreateUIWorkflowStepFunction({
          uiWorkflowStepFunction: uiWorkflowStepFunctionCreateBody,
          navigateAfterCreate: this.navigateAfterCreate
        }));
        this.lastCreatedStepFunctionData.emit(
          {
            uiDataStoreId: createUIStepFunctionFormValue.uiDataStore,
            name: uiWorkflowStepFunctionCreateBody.name,
            type: DiagramItemType.UIStepFunction
          });
      } else {
        this.notificationService.createNotification(
          'error',
          uiWorkflowStepFunctionCreateBody.name,
          'SHARED.COULDNT_CREATED'
        );
      }
    }
  }

  /**
   * navigates to ui data store detail page
   *
   * @memberof UIStepFunctionCreateComponent
   */
  navigateToUIDataStoreDetailPage() {
    // TODO: needs navigate action
    this.router.navigate(
      [`${PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_DETAIL}/${this.uiDataStoreId}`],
      { queryParams: QUERY_PARAM_DEFINITIONS.PROJECT.UI_DATA_STORE_DETAIL.UI_STEP_FUNCTIONS_TAB }
    );
  }

  onSubscriptionChange() {
    const uiDataStoreSubscriptionBody = this.createUIStepFunctionForm.controls['uiDataSubscriptionBody'].value;
    this.subscribedFieldIds = uiDataStoreSubscriptionBody?.map(
      subscription => subscription.dataSchemaField[subscription.dataSchemaField.length - 1].id
    );
  }

  onSubscribedEventsChange() {
    const subscribedEvents = this.createUIStepFunctionForm.value.subscribedEvents;
    this.subscribedEventId = subscribedEvents[subscribedEvents.length - 1];
  }

  calculateFormLayout() {
    return this.formLayout === FormLayout.Horizontal ? { label: 6, input: 18 } : { label: null, input: null };
  }

  /** Disable all the fields of the form except the provided filed names in the array */
  disableFormFieldsExceptWhitelisted(excludedFields: string[]) {
    for (const controlName of Object.keys(this.createUIStepFunctionForm.controls)) {
      if (Object.prototype.hasOwnProperty.call(this.createUIStepFunctionForm.controls, controlName)) {
        if (!excludedFields.includes(controlName)) {
          this.createUIStepFunctionForm.get(controlName).setValue(this.initialValuesOfCreateUIStepFunctionForm.get(controlName).value);
        }
      }
    }
  }

  enableFormFieldsExceptBlacklisted(disabledFields: string[]) {
    Object.keys(this.createUIStepFunctionForm.controls).forEach(controlName => {
      if (!disabledFields.find(f => f === controlName)) {
        this.createUIStepFunctionForm.get(controlName).enable();
      }
    });
  }

  onNameChange(name: string) {
    const stepFunctionName = StringUtilityService.camelize(name);
    if (stepFunctionName !== name) {
      this.createUIStepFunctionForm.patchValue({ name: stepFunctionName });
    }
  }

}
