import { createSelector } from '@ngrx/store';
import { ProjectWithRelations } from '@rappider/rappider-sdk';

export const getProjectListWithLoading = createSelector(
  state => state['project']?.data,
  state => state['project']?.isLoaded,
  state => state['project']?.isPersonProjectsLoaded,
  (
    projects: ProjectWithRelations[],
    projectsIsLoaded: boolean,
    personProjectsIsLoaded: boolean
  ) => ({
    projects: projects,
    isLoaded: projectsIsLoaded && personProjectsIsLoaded
  })
);
