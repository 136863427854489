import { createSelector } from '@ngrx/store';
import { Authority } from '../../../definitions/authority.enum';

export const isActivePersonManager = createSelector(
  state => state['personAuthority']?.data,
  state => state['auth'].activePerson,
  state => state['activeProject'],
  (personAuthorities: any[], activePerson: any, activeProject: any) => {
    const isProjectManager = personAuthorities?.some(personAuthority => (
      personAuthority.role?.name === Authority.ProjectManager &&
      personAuthority.person?.id === activePerson.id &&
      personAuthority.projectId === activeProject.id));
    return isProjectManager;
  }
);
