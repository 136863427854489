<section *ngIf="isLoading">
  <nz-skeleton [nzActive]="true"
               [nzLoading]="isLoading"
               [nzTitle]="false"
               [nzParagraph]="{ rows: 2 }">
  </nz-skeleton>
</section>
<rappider-title-toolbar *ngIf="!isLoading"
                        [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>

<section>
  <rappider-list-grid [config]="PROJECT_MODEL_LIST_CONFIG"
                      [data]="projectModels"
                      [loading]="isLoading"
                      (listActionDropdownItemClick)="onListActionDropdownItemClick($event)"
                      (columnActionClick)="onClickDelete($event)"></rappider-list-grid>
</section>

<ng-template #deleteConfirmationTemplate>
  <div *ngIf="deletedProjectModel.fields">
    <strong> {{'PROJECT_MODULE.PROJECT_MODEL_LIST_COMPONENT.FIELDS' | translate}}:</strong>
    <ul>
      <li *ngFor="let dataField of deletedProjectModel.fields">
        {{dataField.name}}
      </li>
    </ul>
  </div>

  <div *ngIf="deletedProjectModel.relations">
    <strong> {{'PROJECT_MODULE.PROJECT_MODEL_LIST_COMPONENT.RELATIONS' | translate}}:</strong>
    <ul>
      <li *ngFor="let relation of deletedProjectModel.relations">
        {{relation.name}}
      </li>
    </ul>
  </div>
</ng-template>

<nz-modal [(nzVisible)]="isImportProjectModelModalVisible"
          nzWidth="70%"
          [nzTitle]="importProjectModelModalData?.modalTitle"
          [nzOkText]="'SHARED.SAVE' | translate"
          [nzOkLoading]="isLoading"
          (nzOnCancel)="isImportProjectModelModalVisible = false"
          (nzOnOk)="onSaveImportProjectModelData(importProjectModelModalData)">
  <ng-container *nzModalContent>
    <div *ngIf="!(importProjectModelModalData.importType === ImportProjectModelOption.ImportFromPostgreSQL ||
    importProjectModelModalData.importType === ImportProjectModelOption.ImportFromOpenApiSpecs)"
         class="model-name-area">
      <div>
        {{'PROJECT_MODULE.PROJECT_MODEL_CREATE_COMPONENT.PROJECT_MODEL_NAME' | translate}}
      </div>

      <rappider-textbox [(ngModel)]="importProjectModelModalData.projectModelName"
                        [placeholder]="'SHARED.TITLE_CASE_EXAMPLE' | translate"></rappider-textbox>
      <div class="project-model-name-validation-error"
           *ngIf="validateModelNameForJsonData(importProjectModelModalData.projectModelName)">
        *{{'ERRORS.PATTERN_MESSAGES.PROJECT_MODEL_NAME_ERROR' | translate}}
      </div>
    </div>

    <rappider-code-editor [(ngModel)]="importProjectModelModalData.data"
                          [title]="importProjectModelModalData?.codeEditorTitle"
                          [settings]="CODEMIRROR_JSON_SETTINGS"></rappider-code-editor>
  </ng-container>
</nz-modal>
