import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* 3rd Part Modules */
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';

/* Guards */
import { AuthGuard } from '@rappider/authentication/guards';
import { ActiveProjectGuard, PersonGuard, ProjectRoleGuardGuard } from '@rappider/shared/guards';
import { VerifyPersonGuard } from '@rappider/shared/guards';

/* State */
import { StoreModule } from '@ngrx/store';
import * as fromProject from './states/active-project-state/active-project.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ActiveProjectEffects } from './states/active-project-state/active-project.effects';

/* Internal Modules */
import { SharedModule } from '@rappider/shared';
import { UIDataStoreFieldModule } from './modules/ui-data-store-field/ui-data-store-field.module';
import { UiDataStoreModule } from './modules/ui-data-store/ui-data-store.module';
import { DataSchemaModule } from './modules/data-schema/data-schema.module';
import { ProjectModelRelationModule } from './modules/project-model-relation/project-model-relation.module';
import { CustomEndpointModule } from './modules/custom-endpoint/custom-endpoint.module';
import { WorkflowEventModule } from '@rappider/workflow-event';
import {
  RappiderEditFormModule,
  RappiderTitleModule,
  RappiderListGridModule,
  RappiderButtonModule,
  RappiderMenuModule,
  RappiderUploadFileModule,
  RappiderSelectModule,
  RappiderInputLabelModule,
  RappiderCodeEditorModule,
  RappiderDividerModule,
  RappiderRadioModule,
  RappiderTextboxModule,
  RappiderLabelModule,
  RappiderTitleToolbarModule,
  RappiderLottiePlayerModule,
  RappiderPageWrapperModule,
  RappiderCodemirrorInputFormModule,
  RappiderCardCcsModule,
  RappiderTextModule,
  RappiderDropdownMenuModule,
  RappiderCrudDetailModule,
  RappiderIconModule,
  RappiderModalModule,
  RappiderAlertModule,
  RappiderInputTemplateModule,
  RappiderHeadingModule,
  RappiderParagraphModule,
  RappiderCardFourModule,
  RappiderReviewResultsModule,
  RappiderCardTenModule,
  RappiderProgressOneModule,
  RappiderSpinModule,
  RappiderCardOneModule,
  RappiderProgressModule,
  RappiderTimelineModule,
  RappiderCardsModule,
  RappiderInputGroupModule,
  RappiderImageModule
} from '@rappider/rappider-components';

/* Components */
import { ProjectCreateComponent } from './components/project-create/project-create.component';
import { ProjectCreatePreviewComponent } from './components/project-create-preview/project-create-preview.component';
import { ProjectDetailComponent } from './components/project-detail/project-detail.component';
import { ProjectEditComponent } from './components/project-edit/project-edit.component';
import { ProjectListComponent } from './components/project-list/project-list.component';
import { ProjectSettingsComponent } from './components/project-settings/project-settings.component';
import { ProjectEnvironmentVariablesComponent } from './components/project-environment-variables/project-environment-variables.component';
import { ProjectMembersComponent } from './components/project-members/project-members.component';
import { MessageTemplateComponent } from './components/message-template/message-template.component';
import { MessageTemplateListComponent } from './components/message-template-list/message-template-list.component';
import { EditMessageTemplateComponent } from './components/edit-message-template/edit-message-template.component';
import { ProjectRolesComponent } from './components/project-roles/project-roles.component';
import { ProjectMemberListComponent } from './components/project-member-list/project-member-list.component';
import { ProjectExportComponent } from './components/project-export/project-export.component';
import { ProjectVersionListComponent } from './components/project-version-list/project-version-list.component';
import { AddProjectScriptComponent } from './components/add-project-script/add-project-script.component';
import { FileListComponent } from './components/add-project-script/file-list/file-list.component';
import { ProjectScriptListComponent } from './components/project-script-list/project-script-list.component';
import { ProjectVersionCreateComponent } from './components/project-version-create/project-version-create.component';
import { UserInvitationsComponent } from './components/user-invitations/user-invitations.component';
import {
  ProjectModelDataFieldCreateComponent
} from './components/project-model-data-field-create/project-model-data-field-create.component';
import { DataFieldListComponent } from './components/data-field-list/data-field-list.component';
import { DataFieldEditComponent } from './components/data-field-edit/data-field-edit.component';
import { ProjectModelListComponent } from './components/project-model-list/project-model-list.component';
import { ProjectModelCreateComponent } from './components/project-model-create/project-model-create.component';
import { ProjectModelEditComponent } from './components/project-model-edit/project-model-edit.component';
import { Authority, FullPaths, PAGE_DEFINITIONS } from '@rappider/shared/definitions';
import { DataSchemaCreateComponent } from './modules/data-schema/components/data-schema-create/data-schema-create.component';
import { ProjectVersionDetailComponent } from './components/project-version-detail/project-version-detail.component';
import { ProjectScriptEditComponent } from './components/project-script-edit/project-script-edit.component';
import { ProjectPackageCreateComponent } from './components/project-package-create/project-package-create.component';
import { ProjectPackageCreateModalComponent } from './components/project-package-create-modal/project-package-create-modal.component';
import { ProjectPackageEditComponent } from './components/project-package-edit/project-package-edit.component';
import { ProjectPackageListComponent } from './components/project-package-list/project-package-list.component';
import { UIDataUpdateFunctionModule } from './modules/ui-data-update-function/ui-data-update-function.module';
import { DataEventEditComponent } from './modules/data-events/components/data-event-edit/data-event-edit.component';
import { MemberRolesComponent } from './components/project-members/member-roles/member-roles.component';
import { CustomFunctionModule } from './modules/custom-function/custom-function.module';
import {
  ProjectCssStyleCustomizationComponent
} from './components/project-css-style-customization/project-css-style-customization.component';
import { FormatDatePipe } from 'libs/shared/src/lib/pipes/format-date.pipe';
import { ProjectExportDetailsComponent } from './components/project-export-details/project-export-details.component';
import { RappiderOpenapiModule } from './modules/openapi/openapi.module';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { RappiderProjectRequirementsModule } from './modules/project-requirements/project-requirements.module';
import { RappiderProjectDocumentModule } from './modules/project-document/project-document.module';
import { ProjectTestMembersComponent } from './components/project-test-members/project-test-members.component';
import { TestUsersModalsComponent } from './components/project-test-members/test-users-modals/test-users-modals.component';

import { ProjectThemeCreateComponent } from 'libs/theme/src/lib/components/project-theme-create/project-theme-create.component';
import { ProjectThemeEditComponent } from 'libs/theme/src/lib/components/project-theme-edit/project-theme-edit.component';
import { ProjectThemeListComponent } from 'libs/theme/src/lib/components/project-theme-list/project-theme-list.component';
import { ProjectThemeComponent } from 'libs/theme/src/lib/components/project-theme/project-theme.component';
import { LivePreviewComponent } from './components/live-preview/live-preview.component';
import { SafeUrlPipe } from 'libs/shared/src/lib/pipes/safe-url/safe-url.pipe';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: FullPaths.ProjectList,
        pathMatch: 'full'
      },
      {
        path: 'create',
        component: ProjectCreateComponent,
        canActivate: [AuthGuard, VerifyPersonGuard],
        data: { displayLivePreviewNotification: false }
      },
      {
        path: 'preview/:id',
        component: ProjectCreatePreviewComponent,
        canActivate: [AuthGuard, VerifyPersonGuard],
        data: { displayLivePreviewNotification: false }
      },
      {
        path: 'live-preview/:projectId',
        component: LivePreviewComponent,
        canActivate: [AuthGuard, VerifyPersonGuard],
        data: { displayLivePreviewNotification: false, title: 'Project Preview' }
      },
      {
        path: 'detail/:projectId',
        component: ProjectDetailComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'edit/:projectId',
        component: ProjectEditComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
      },
      {
        path: 'projects/list',
        component: ProjectListComponent,
        canActivate: [AuthGuard, VerifyPersonGuard, PersonGuard],
        data: { displayLivePreviewNotification: false }
      },
      {
        path: 'list',
        component: ProjectListComponent,
        canActivate: [AuthGuard, VerifyPersonGuard, PersonGuard],
        data: { displayLivePreviewNotification: false }
      },
      {
        path: 'project-settings',
        component: ProjectSettingsComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Project Settings' }
      },
      {
        path: 'environment-variables',
        component: ProjectEnvironmentVariablesComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Environment Variables' }
      },
      {
        path: 'members',
        component: ProjectMembersComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Project Members' }
      },
      {
        path: 'test-members',
        component: ProjectTestMembersComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Test Accounts' }
      },
      {
        path: 'message-template',
        component: MessageTemplateComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Message Templates' }
      },
      {
        path: 'edit-message-template/:templateId',
        component: EditMessageTemplateComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
      },
      {
        path: 'message-template-list',
        component: MessageTemplateListComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
      },
      {
        path: 'project-roles',
        component: ProjectRolesComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Roles' }
      },
      {
        path: 'members/:roleId',
        component: ProjectMemberListComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
      },
      {
        path: 'export/:projectVersionId',
        component: ProjectExportComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
      },
      {
        path: 'versions',
        component: ProjectVersionListComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Versions' }
      },
      {
        path: 'create-theme',
        component: ProjectThemeCreateComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
      },
      {
        path: 'edit-theme/:id',
        component: ProjectThemeEditComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Edit Theme' }
      },
      {
        path: 'theme-list',
        component: ProjectThemeListComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'User Interface | Theme' }
      },
      {
        path: 'theme-settings/:id',
        component: ProjectThemeComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
      },
      {
        path: 'add-project-script',
        component: AddProjectScriptComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Add Script' }
      },
      {
        path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.EDIT_PROJECT_SCRIPT.URL}/:id`,
        component: ProjectScriptEditComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
      },
      {
        path: 'script-list',
        component: ProjectScriptListComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
      },
      {
        path: 'version-create',
        component: ProjectVersionCreateComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
      },
      {
        path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_VERSION_DETAIL.URL}/:projectVersionId`,
        component: ProjectVersionDetailComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
      },
      {
        path: 'user-invitations',
        component: UserInvitationsComponent,
        data: [
          Authority.ProjectOwner,
          Authority.ProjectManager
        ],
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard, ProjectRoleGuardGuard]
      },
      {
        path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_FIELD_CREATE.URL}/:projectModelId`,
        component: ProjectModelDataFieldCreateComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
      },
      {
        path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_FIELD_LIST.URL}/:projectModelId`,
        component: DataFieldListComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
      },
      {
        path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_FIELD_EDIT.URL}/:id`,
        component: DataFieldEditComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
      },
      {
        path: 'model-list',
        component: ProjectModelListComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Data Models' }
      },
      {
        path: 'model-create',
        component: ProjectModelCreateComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
      },
      {
        path: 'model-edit/:id',
        component: ProjectModelEditComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
      },
      {
        path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_SCHEMA_CREATE.URL}`,
        component: DataSchemaCreateComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
      },
      {
        path: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_PACKAGE_LIST.URL,
        component: ProjectPackageListComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Project Packages' }
      },
      {
        path: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_PACKAGE_CREATE.URL,
        component: ProjectPackageCreateComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
      },
      {
        path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_PACKAGE_EDIT.URL}/:id`,
        component: ProjectPackageEditComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
      },
      {
        path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_EVENT_EDIT.URL}/:id`,
        component: DataEventEditComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
      },
      /* project css style customization */
      {
        path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.CSS_STYLE_CUSTOMIZATION.URL}`,
        component: ProjectCssStyleCustomizationComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature(fromProject.activeProjectFeatureKey, fromProject.reducer, {
      initialState: fromProject.initialState
    }),
    EffectsModule.forFeature([ActiveProjectEffects]),
    NzSkeletonModule,
    RappiderModalModule,
    NzFormModule,
    NzTabsModule,
    NzCardModule,
    NzCheckboxModule,
    NzDividerModule,
    NzButtonModule,
    NzDrawerModule,
    NzDropDownModule,
    NzIconModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    UIDataStoreFieldModule,
    UiDataStoreModule,
    DataSchemaModule,
    UIDataStoreFieldModule,
    UIDataUpdateFunctionModule,
    ProjectModelRelationModule,
    CustomFunctionModule,
    CustomEndpointModule,
    WorkflowEventModule,
    RappiderEditFormModule,
    RappiderTitleModule,
    RappiderListGridModule,
    RappiderButtonModule,
    RappiderMenuModule,
    RappiderUploadFileModule,
    RappiderSelectModule,
    RappiderInputLabelModule,
    RappiderCodeEditorModule,
    RappiderDividerModule,
    RappiderRadioModule,
    RappiderTextboxModule,
    RappiderLabelModule,
    RappiderTitleToolbarModule,
    RappiderLottiePlayerModule,
    RappiderPageWrapperModule,
    RappiderCodemirrorInputFormModule,
    RappiderCardCcsModule,
    RappiderTextModule,
    RappiderDropdownMenuModule,
    NzPopconfirmModule,
    NzSelectModule,
    RappiderIconModule,
    RappiderCrudDetailModule,
    RappiderAlertModule,
    RappiderInputTemplateModule,
    RappiderHeadingModule,
    RappiderParagraphModule,
    RappiderCardFourModule,
    RappiderReviewResultsModule,
    RappiderCardTenModule,
    RappiderProgressOneModule,
    NzToolTipModule,
    RappiderSpinModule,
    NzCollapseModule,
    RappiderCardOneModule,
    RappiderOpenapiModule,
    NzModalModule,
    RappiderProjectRequirementsModule,
    RappiderProjectDocumentModule,
    RappiderProgressModule,
    RappiderTimelineModule,
    RappiderCardsModule,
    RappiderInputGroupModule,
    RappiderImageModule
  ],
  providers: [
    FormatDatePipe,
    SafeUrlPipe
  ],
  declarations: [
    ProjectCreateComponent,
    ProjectCreatePreviewComponent,
    ProjectDetailComponent,
    ProjectEditComponent,
    ProjectListComponent,
    ProjectSettingsComponent,
    ProjectEnvironmentVariablesComponent,
    ProjectMembersComponent,
    MessageTemplateComponent,
    MessageTemplateListComponent,
    EditMessageTemplateComponent,
    ProjectRolesComponent,
    ProjectMemberListComponent,
    ProjectExportComponent,
    ProjectVersionListComponent,
    AddProjectScriptComponent,
    FileListComponent,
    ProjectScriptListComponent,
    ProjectVersionCreateComponent,
    UserInvitationsComponent,
    ProjectModelDataFieldCreateComponent,
    DataFieldListComponent,
    DataFieldEditComponent,
    ProjectModelListComponent,
    ProjectModelCreateComponent,
    ProjectModelEditComponent,
    ProjectVersionDetailComponent,
    ProjectVersionDetailComponent,
    ProjectPackageCreateComponent,
    ProjectPackageCreateModalComponent,
    ProjectPackageEditComponent,
    ProjectPackageListComponent,
    MemberRolesComponent,
    ProjectScriptEditComponent,
    ProjectCssStyleCustomizationComponent,
    ProjectExportDetailsComponent,
    ProjectTestMembersComponent,
    TestUsersModalsComponent,
    LivePreviewComponent
  ],
  exports: [
    ProjectCssStyleCustomizationComponent,
  ]
})
export class ProjectModule { }
