import { createSelector } from '@ngrx/store';

export const isContentTreeChangesSaved = createSelector(
    state => state['contentEditor'].page?.contentTree,
    state => state['contentEditor'].lastSavedContentTree,
    (contentTree, lastSavedContentTree) => {
        if (contentTree && lastSavedContentTree) {
            try {
                return JSON.stringify(contentTree) === JSON.stringify(lastSavedContentTree);
            } catch (e) {
                return null;
            }
        } else {
            return null;
        }
    }
);
