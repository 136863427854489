/* angular imports */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

/* internal modules */
import {
  RappiderListGridModule,
  RappiderEditFormModule,
  RappiderTitleBarModule,
  RappiderCodeEditorModule,
  RappiderRadioModule,
  RappiderSelectModule,
  RappiderAlertModule,
  RappiderButtonModule,
  RappiderTitleToolbarModule,
  RappiderSpinModule,
  RappiderCheckboxListModule,
  RappiderInputGroupModule
} from '@rappider/rappider-components';

/* Definitions */
import { PAGE_DEFINITIONS } from '@rappider/shared/definitions';

/* external modules */
import { RappiderModalModule } from '@rappider/rappider-components';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { TranslateModule } from '@ngx-translate/core';

/* components */
import { UiDataUpdateFunctionListComponent } from './components/ui-data-update-function-list/ui-data-update-function-list.component';
import { DataSchemaSelectComponent } from './components/data-schema-select/data-schema-select.component';
import { DataMappingCreateComponent } from './components/data-mapping-create/data-mapping-create.component';
import { DataMappingsComponent } from './components/data-mappings/data-mappings.component';
import { UiDataUpdateFunctionCreateComponent } from './components/ui-data-update-function-create/ui-data-update-function-create.component';
import { CreateDataMappingPageComponent } from './components/create-data-mapping-page/create-data-mapping-page.component';
import { EditCodeComponent } from './components/edit-code/edit-code.component';
import { CustomFunctionModule } from '../custom-function/custom-function.module';
import { UiDataUpdateFunctionCreateFormComponent } from './components/ui-data-update-function-create-form/ui-data-update-function-create-form.component';
import { NzFormModule } from 'ng-zorro-antd/form';
import { RappiderCheckboxListComponent } from 'libs/components/src/lib/components/checkbox-list/checkbox-list.component';
import { UiDataUpdateFunctionPageComponent } from './components/ui-data-update-function-page/ui-data-update-function-page.component';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { UiDataUpdateFunctionsComponent } from './components/ui-data-update-functions/ui-data-update-functions.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzEmptyModule } from 'ng-zorro-antd/empty';


const routes: Routes = [
  {
    component: UiDataUpdateFunctionCreateComponent,
    path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_DATA_UPDATE_FUNCTION_CREATE.URL}/:dataStoreId`
  },
  {
    component: CreateDataMappingPageComponent,
    path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.CREATE_DATA_MAPPING_PAGE.URL}/:id`
  },
  {
    component: EditCodeComponent,
    path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.EDIT_CODE.URL}/:id`
  },
  {
    component: UiDataUpdateFunctionsComponent,
    path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_DATA_UPDATE_FUNCTION.URL}`
  },
];
@NgModule({
  declarations: [
    UiDataUpdateFunctionCreateFormComponent,
    UiDataUpdateFunctionListComponent,
    DataSchemaSelectComponent,
    DataMappingCreateComponent,
    DataMappingsComponent,
    UiDataUpdateFunctionCreateComponent,
    CreateDataMappingPageComponent,
    EditCodeComponent,
    UiDataUpdateFunctionPageComponent,
    UiDataUpdateFunctionsComponent,
  ],
  imports: [
    CommonModule,
    RappiderModalModule,
    NzTreeModule,
    NzTreeSelectModule,
    RouterModule.forChild(routes),
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    DragDropModule,
    CustomFunctionModule,
    RappiderButtonModule,
    RappiderListGridModule,
    RappiderEditFormModule,
    RappiderTitleBarModule,
    RappiderCodeEditorModule,
    RappiderRadioModule,
    RappiderSelectModule,
    RappiderAlertModule,
    RappiderButtonModule,
    NzFormModule,
    RappiderTitleToolbarModule,
    RappiderSpinModule,
    RappiderCheckboxListModule,
    NzCollapseModule,
    RappiderInputGroupModule,
    NzToolTipModule,
    NzEmptyModule
  ],
  exports: [
    UiDataUpdateFunctionListComponent,
    UiDataUpdateFunctionCreateFormComponent,
    DataSchemaSelectComponent,
    UiDataUpdateFunctionsComponent
  ]
})
export class UIDataUpdateFunctionModule { }
