export const FormItemsInfoMessages = {
  integrationOperatorTypeInfo: {
    title: 'Integration Operator Type',
    content: ''
  },
  subscribedEventsInfo: {
    title: 'Subscribed Events',
    content: ''
  },
  publishedEventsOnSuccessInfo: {
    title: 'Published Events On Success',
    content: ''
  },
  publishedEventsOnFailureInfo: {
    title: 'Published Events On Failure',
    content: ''
  },
  uiDataStoreSubscriptionsInfo: {
    title: 'UI Data Store Subscriptions',
    content: ''
  }
};
