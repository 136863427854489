import { ActionBehavior, CrudTableViewConfig, CrudViewColumnType, ListGridOrderType } from '@rappider/rappider-components/utils';

export const FILE_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'title',
  columns: [
    {
      title: 'SHARED.TITLE',
      fieldName: 'title',
      type: CrudViewColumnType.Text
    }
  ],
  listActions: [],
  itemActions: [
    {
      text: 'SHARED.ADD',
      name: 'addFile',
      behavior: ActionBehavior.Emit
    }
  ],
  order: {
    fieldName: 'title',
    type: ListGridOrderType.Ascending
  }
};
