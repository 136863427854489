<rappider-modal [(visible)]="rolesModalConfig.visible"
                [title]="setModalTitle()"
                [width]="rolesModalConfig.width"
                (cancelClick)="onRolesModalClose()"
                (okClick)="onRolesModalSubmit()">
  <ng-container *ngIf="rolesModalConfig.visible">
    <rappider-select [(ngModel)]="selectedRoleIds"
                     [settings]="selectSettings"
                     [placeholder]="selectPlaceholder"
                     [options]="roleSelectOptions"></rappider-select>
  </ng-container>
</rappider-modal>