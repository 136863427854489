import { CrudFormInlineRowFormItem, CrudViewFormItemType } from '@rappider/rappider-components/utils';
import { ROW_FORM_KEY_VALUE_COLUMNS } from '../row-form-key-value-config';

export const METADATA_CREATE_FORM_CONFIG: CrudFormInlineRowFormItem = {
  title: 'SHARED.METADATA',
  fieldName: 'metadata',
  type: CrudViewFormItemType.InlineRowForm,
  columns: ROW_FORM_KEY_VALUE_COLUMNS,
  orderable: true,
  orderNumbersVisibility: true
};
