export const RAPPIDER_THEME_SETTINGS = {
    menuWidthWithPageMenu: '279px',
    menuWidthWithoutPageMenu: '50px',
    menuWidthWhenWorkspaceMenuExpanded: '279px',
    menuWidthWhenNoMenu: '0px',
    workspaceMenuPadding: 16
};

export enum ThemeCategoryKeys {
    Default = 'default',
    ExampleTemplate = 'example-template',
    ExampleTemplateTwo = 'example-template-two'
};
