/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ProjectFile } from '../models/project-file';
import { ProjectFileUpdateDto } from '../models/project-file-update-dto';
import { ProjectFileWithRelations } from '../models/project-file-with-relations';

@Injectable({
  providedIn: 'root',
})
export class ProjectFileControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation projectFileControllerGetFileContent
   */
  static readonly ProjectFileControllerGetFileContentPath = '/project-files/get-file-content/{projectFileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFileContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFileContent$Response(params: {
    projectFileId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectFileControllerService.ProjectFileControllerGetFileContentPath, 'get');
    if (params) {
      rb.path('projectFileId', params.projectFileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFileContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFileContent(params: {
    projectFileId: string;
  }): Observable<void> {

    return this.getFileContent$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation projectFileControllerGetFolderFiles
   */
  static readonly ProjectFileControllerGetFolderFilesPath = '/project-files/get-folder-files/{projectFolderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFolderFiles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFolderFiles$Response(params: {
    projectFolderId: string;
    filter?: any;
  }): Observable<StrictHttpResponse<Array<ProjectFileWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectFileControllerService.ProjectFileControllerGetFolderFilesPath, 'get');
    if (params) {
      rb.path('projectFolderId', params.projectFolderId, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectFileWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFolderFiles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFolderFiles(params: {
    projectFolderId: string;
    filter?: any;
  }): Observable<Array<ProjectFileWithRelations>> {

    return this.getFolderFiles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectFileWithRelations>>) => r.body as Array<ProjectFileWithRelations>)
    );
  }

  /**
   * Path part for operation projectFileControllerUploadFiles
   */
  static readonly ProjectFileControllerUploadFilesPath = '/project-files/upload-files/{parentFolderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadFiles()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFiles$Response(params: {
    parentFolderId: string;

    /**
     * multipart/form-data value.
     */
    body: {  }
  }): Observable<StrictHttpResponse<Array<ProjectFile>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectFileControllerService.ProjectFileControllerUploadFilesPath, 'post');
    if (params) {
      rb.path('parentFolderId', params.parentFolderId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectFile>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadFiles$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFiles(params: {
    parentFolderId: string;

    /**
     * multipart/form-data value.
     */
    body: {  }
  }): Observable<Array<ProjectFile>> {

    return this.uploadFiles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectFile>>) => r.body as Array<ProjectFile>)
    );
  }

  /**
   * Path part for operation projectFileControllerDeleteById
   */
  static readonly ProjectFileControllerDeleteByIdPath = '/project-files/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectFileControllerService.ProjectFileControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: string;
  }): Observable<void> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation projectFileControllerUpdateById
   */
  static readonly ProjectFileControllerUpdateByIdPath = '/project-files/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    body?: ProjectFileUpdateDto
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectFileControllerService.ProjectFileControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    body?: ProjectFileUpdateDto
  }): Observable<any> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation projectFileControllerFind
   */
  static readonly ProjectFileControllerFindPath = '/project-files';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<ProjectFileWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectFileControllerService.ProjectFileControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectFileWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<ProjectFileWithRelations>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectFileWithRelations>>) => r.body as Array<ProjectFileWithRelations>)
    );
  }

}
