import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Project } from '@rappider/api-sdk';
import { BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { OpenApiWithRelations, OpenApiEndpointWithRelations } from '@rappider/rappider-sdk';
import { OPENAPI_ENDPOINT_LIST_CONFIG } from '@rappider/shared/configs';
import { PAGE_DEFINITIONS, defaultToolbarTitleHeadingSize, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { GetOpenAPIsWithRelations } from 'libs/project/src/lib/states/openapi-state/openapi.actions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rappider-openapi-endpoint-list',
  templateUrl: './openapi-endpoint-list.component.html',
  styleUrls: ['./openapi-endpoint-list.component.scss']
})
export class OpenapiEndpointListComponent implements OnInit, OnDestroy {

  OPENAPI_ENDPOINT_LIST_CONFIG = OPENAPI_ENDPOINT_LIST_CONFIG;

  /* active project */
  activeProject: Project;
  /* subscriptions */
  subscriptions: Subscription[];
  /* external api (openApi) id */
  openApiId: string;
  /* external api (openApi) data */
  activeOpenApi: OpenApiWithRelations;
  /* endpoints of external api (openApi) data */
  openApiEndpointsData: OpenApiEndpointWithRelations[];
  /* loading state */
  loading: boolean;

  /* title toolbar configuration */
  mainTitle: HeadingComponentConfig;
  title: BreadcrumbOption[];
  displayToolbar = false;
  displayToolbarBackButton = false;

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getOpenApiIdFromUrl();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToOpenApiLoading(),
      this.subscribeToOpenApiData()
    ];
  }

  getOpenApiData() {
    this.store.dispatch(GetOpenAPIsWithRelations());
  }

  getOpenApiIdFromUrl() {
    this.openApiId = this.activatedRoute.snapshot.params['id'];
  }

  setTitle() {
    this.mainTitle = {
      content: PAGE_DEFINITIONS.PROJECTS.CHILDREN.OPENAPI_ENDPOINT_LIST.NAME,
      type: defaultToolbarTitleHeadingSize
    };

    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
      },
      {
        label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.OPENAPI_LIST.NAME,
        redirectUrl: PATH_DEFINITIONS.PROJECTS.OPENAPI_LIST
      },
      {
        label: this.activeOpenApi?.name
      }
    ];
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((project: Project) => {
      if (project) {
        this.activeProject = project;
        this.getOpenApiData();
      } else {
        this.activeProject = null;
      }
    });
  }

  subscribeToOpenApiLoading() {
    return this.store.select(state => state.openAPI?.loading).subscribe((loading: boolean) => {
      this.loading = loading;
    });
  }

  subscribeToOpenApiData() {
    return this.store.select(state => state.openAPI?.data).subscribe(openAPIs => {
      if (openAPIs) {
        this.activeOpenApi = openAPIs.find(openApi => openApi.id === this.openApiId);
        this.openApiEndpointsData = this.activeOpenApi.endpoints.map(endpoint => ({
          ...endpoint,
          method: `[${endpoint.method.toUpperCase()}]`
        }));
        this.setTitle();
      } else {
        this.activeOpenApi = null;
      }
    });
  }

}
