<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton"
                        [displayBreadcrumb]="displayBreadcrumb"></rappider-title-toolbar>

<rappider-crud-view-edit-form *ngIf="activeProject?.id"
                              [config]="UI_DATA_EVENT_CREATE_CONFIG"
                              [data]="defaultUIDataEventData"
                              [submitButtonLoading]="isLoading"
                              (fieldValueChange)="onDataEventFieldValueChange($event)"
                              (formSubmit)="onCreateUIDataEvent($event)"></rappider-crud-view-edit-form>