import {
  ActionBehavior,
  CrudTableViewConfig,
  CrudViewColumnType,
  ButtonType
} from '@rappider/rappider-components/utils';

export const CUSTOM_ENDPOINT_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'name',
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewColumnType.ActionLink
    },
    {
      title: 'SHARED.DESCRIPTION',
      fieldName: 'description',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.TYPE',
      fieldName: 'type',
      type: CrudViewColumnType.Text
    },
    {
      title: 'PROJECT_MODULE.CUSTOM_ENDPOINT_COMPONENT.HTTP_METHOD',
      fieldName: 'method',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.PATH',
      fieldName: 'path',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.CODE',
      fieldName: 'code',
      type: CrudViewColumnType.Boolean
    },
    {
      title: 'PROJECT_MODULE.CUSTOM_ENDPOINT_COMPONENT.MOCKED',
      fieldName: 'isMocked',
      type: CrudViewColumnType.Boolean
    }
  ],
  listActions: [
    {
      text: 'PROJECT_MODULE.CUSTOM_ENDPOINT_CREATE_OR_EDIT_COMPONENT.CUSTOM_ENDPOINT_CREATE',
      name: 'createCustomEndpoint',
      buttonType: ButtonType.Default,
      behavior: ActionBehavior.Emit,
      icon: { name: 'fas fa-plus' }
    }
  ],
  itemActions: [
    {
      text: 'SHARED.DELETE',
      name: 'deleteCustomEndpoint',
      behavior: ActionBehavior.Emit,
      icon: { name: 'far fa-trash' }
    },
  ]
};
