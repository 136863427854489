import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MetadataService {


  getValueByKey(metadata: KeyValue<string, any>[], key: string) {
    return metadata?.find(keyValue => keyValue.key === key)?.value;
  }

}
