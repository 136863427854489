import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ProjectInterface, ProjectPackageApi, ProjectPackageInterface } from '@rappider/api-sdk';
import { BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { PROJECT_PACKAGE_CREATE_OR_EDIT_CONFIG } from '@rappider/shared/configs';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Subscription } from 'rxjs';
import { UpdateProjectPackage } from '../../states/project-package-state/project-package.actions';
import { NpmPackageSearchService } from '../../utils/services/npm-package-search.service.ts/npm-package-search.service';
import { ProjectPackageWithRelations } from '@rappider/rappider-sdk';

@Component({
  selector: 'rappider-project-package-edit',
  templateUrl: './project-package-edit.component.html',
  styleUrls: ['./project-package-edit.component.scss']
})
export class ProjectPackageEditComponent implements OnInit, OnDestroy {
  /* edit config */
  PROJECT_PACKAGE_EDIT_CONFIG = PROJECT_PACKAGE_CREATE_OR_EDIT_CONFIG;
  /* main title */
  mainTitle: HeadingComponentConfig;
  /* title */
  title: string | string[] | BreadcrumbOption[];
  /* subscriptions */
  subscriptions: Subscription[];
  /* project name */
  activeProjectName: string;
  /* project id */
  activeProjectId: string;
  /* project package id */
  projectPackageId: string;
  /* project package */
  projectPackage: ProjectPackageWithRelations;

  displayToolbar = false;
  displayToolbarBackButton = false;
  loading: boolean;
  npmPackageLoading = true;

  updatedPackageVersions = [
    {
      key: 0,
      value: ''
    }
  ];

  updatedPackage: {
    fieldName: string;
    options: any[];
  };

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private projectpackageApi: ProjectPackageApi,
    private npmPackageSearchService: NpmPackageSearchService,
  ) { }

  ngOnInit(): void {
    this.getProjectPackageIdFromUrl();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToLoading(),
      this.subscribeToProjectPackages()
    ];
  }

  getProjectPackageIdFromUrl() {
    this.projectPackageId = this.activatedRoute.snapshot.params['id'];
    this.subscribeToData();
  }

  subscribeToLoading() {
    return this.store.select(state => state.projectPackage.loading).subscribe((loading: boolean) => {
      this.loading = loading;
    });
  }

  subscribeToProjectPackages() {
    return this.store.select(state => state.projectPackage.data).subscribe((projectPackages: ProjectPackageWithRelations[]) => {
      this.projectPackage = projectPackages.find(projectPackage => projectPackage.id === this.projectPackageId);
      if (this.projectPackage) {
        this.getNpmPackages(this.projectPackage);
      }
    });
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectInterface) => {
      if (activeProject) {
        this.activeProjectName = activeProject.name;
        this.activeProjectId = activeProject.id;
      }
    });
  }

  async getNpmPackages(projectPackage: ProjectPackageWithRelations) {
    this.npmPackageLoading = true;

    const npmPackageVersions = await this.npmPackageSearchService.getNpmPackageVersions(projectPackage.name);
    const sortedNpmPackageVersions = {
      ...npmPackageVersions,
      versions: this.npmPackageSearchService.sortPackageVersions(npmPackageVersions)
    };
    const values = Object.values(sortedNpmPackageVersions.versions);

    values.forEach((item, index) => {
      this.updatedPackageVersions[index] = { key: item['version'], value: item['version'] };
    });

    this.updatedPackage = {
      fieldName: 'version',
      options: this.updatedPackageVersions
    };

    this.PROJECT_PACKAGE_EDIT_CONFIG.items.forEach(item => {
      if (item.fieldName === 'version') {
        item['options'] = this.updatedPackageVersions;
      }
    });

    this.PROJECT_PACKAGE_EDIT_CONFIG = {
      ...this.PROJECT_PACKAGE_EDIT_CONFIG
    };

    this.npmPackageLoading = false;

    this.setTitle();
  }

  setTitle() {
    this.mainTitle = {
      content: 'PROJECT_MODULE.PROJECT_PACKAGE_EDIT_COMPONENT.PROJECT_PACKAGE_EDIT',
      type: defaultToolbarTitleHeadingSize
    };
    this.title = [
      {
        label: this.activeProjectName,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProjectId}`
      },
      {
        label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_PACKAGE_LIST.PAGE_TITLE,
        redirectUrl: PATH_DEFINITIONS.PROJECTS.PROJECT_PACKAGE_LIST
      },
      {
        label: this.projectPackage?.name
      },
      {
        label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_PACKAGE_EDIT.PAGE_TITLE
      }
    ];
  }

  onProjectPackageUpdate(projectPackage) {
    this.store.dispatch(new UpdateProjectPackage({ id: this.projectPackageId, body: projectPackage }));
  }
}
