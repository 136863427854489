/* angular */
import { createAction, props } from '@ngrx/store';

/* action types */
export enum ActionTypes {
  GetPersonAuthorities = '[PersonAuthority] GetPersonAuthorities',
  GetPersonAuthoritiesSuccessful = '[PersonAuthority] GetPersonAuthoritiesSuccessful',
  GetPersonAuthoritiesFailure = '[PersonAuthority] GetPersonAuthoritiesFailure',
  ResetStateToInitial = '[PersonAuthority] ResetStateToInitial',
  ResetStateToInitialChangeProject = '[PersonAuthority] ResetStateToInitialChangeProject'
}

/* actions */
export const GetPersonAuthorities = createAction(ActionTypes.GetPersonAuthorities);

export const GetPersonAuthoritiesSuccessful = createAction(
  ActionTypes.GetPersonAuthoritiesSuccessful,
  props<{ personAuthorities: any }>()
);

export const GetPersonAuthoritiesFailure = createAction(
  ActionTypes.GetPersonAuthoritiesFailure,
  props<{ error: { error: any; key: string; time: any } }>()
);

export const ResetStateToInitial = createAction(ActionTypes.ResetStateToInitial);

export const ResetStateToInitialChangeProject = createAction(ActionTypes.ResetStateToInitialChangeProject);
