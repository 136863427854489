import {
  ActionBehavior,
  CrudTableViewConfig,
  CrudViewColumnType,
  ButtonType
} from '@rappider/rappider-components/utils';
import { CustomEndpointListFieldName } from './custom-endpoint-list-field-name.enum';

export const MODEL_ENDPOINT_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'name',
  searchable: true,
  filterable: false,
  columnVisibilitySelectable: false,
  columns: [
    {
      title: 'PROJECT_MODULE.CUSTOM_ENDPOINT_COMPONENT.HTTP_METHOD',
      fieldName: 'method',
      type: CrudViewColumnType.Tag,
    },
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.PATH',
      fieldName: 'path',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.DESCRIPTION',
      fieldName: 'description',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Authentication Required',
      fieldName: 'isAuthenticationRequired',
      type: CrudViewColumnType.Boolean
    },
    {
      title: 'PROJECT_MODULE.CUSTOM_ENDPOINT_COMPONENT.MOCKED',
      fieldName: 'isMocked',
      type: CrudViewColumnType.Boolean
    }
  ],
  listActions: [
    {
      text: 'PROJECT_MODULE.CUSTOM_ENDPOINT_CREATE_OR_EDIT_COMPONENT.CUSTOM_ENDPOINT_CREATE',
      name: 'createCustomEndpoint',
      buttonType: ButtonType.Default,
      behavior: ActionBehavior.Emit,
      icon: { name: 'fas fa-plus' },
    }
  ],
  itemActions: [
    {
      text: 'SHARED.EDIT',
      behavior: ActionBehavior.Emit,
      name: CustomEndpointListFieldName.EditCustomEndpoint,
      icon: { name: 'far fa-edit' },
    },
    {
      text: 'SHARED.DELETE',
      name: CustomEndpointListFieldName.DeleteCustomEndpoint,
      behavior: ActionBehavior.Emit,
      icon: { name: 'far fa-trash' },
      popconfirmTitle: 'Are you sure delete endpoint?',
      emitWithoutPopconfirm: false
    }
  ]
};
