import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { RappiderEditFormComponent } from '@rappider/rappider-components';
import { CODEMIRROR_JSON_SETTINGS, CrudViewFormItemType } from '@rappider/rappider-components/utils';
import { DataSchemaFieldBulkUpdateUiSettingsBodyDto, DataSchemaFieldWithRelations, DataSchemaWithRelations, EnumData, EnumDataWithRelations, PageWithRelations, ProjectModelWithRelations } from '@rappider/rappider-sdk';
import { JsonValidationService, NotificationService } from '@rappider/services';
import {
  BulkUpdateDataSchemaFieldUISettings, CreateUIDataSelectorEnumData,
  UpdateUIDataSelectorEnumData
} from 'libs/project/src/lib/states/data-schema/data-schema.actions';
import { CreateCrudPagesForProjectModel } from 'libs/project/src/lib/states/project-model-state/project-model.actions';
import { cloneDeep, isEmpty, orderBy } from 'lodash';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ActionButtonConfig } from './utils/action-buttons.config';
import { DataSchemaDisplayComponentMode } from './utils/data-schema-field-edit-component-mode.enum';
import { DATA_SCHEMA_FIELD_ENUM_DATA_CONFIG } from './utils/data-schema-field-enum-data.config';
import { settingsSecondStepModalAlertConfig } from './utils/settings-second-step-modal-alert.config';
import { Subscription } from 'rxjs';
import { setUIDataSelectorConfig } from './utils/set-ui-data-selector-config.function';
import { settingsFirstStepModalAlertConfig } from './utils/settings-first-step-modal-alert.config';

@Component({
  selector: 'rappider-data-schema-display',
  templateUrl: './data-schema-display.component.html',
  styleUrls: ['./data-schema-display.component.scss'],
})
export class DataSchemaDisplayComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('enumDataForm') enumDataForm: RappiderEditFormComponent;
  @ViewChild('editSettingsModalTemplate', { static: false }) editSettingsModalTemplate: TemplateRef<any>;
  @ViewChild('editOptionsModalTemplate', { static: false }) editOptionsModalTemplate: TemplateRef<any>;

  @Input() dataSchemas: DataSchemaWithRelations[];
  @Input() activeDataSchema: DataSchemaWithRelations;
  @Input() projectModel: ProjectModelWithRelations;
  @Input() isLoading: boolean;
  @Input() layouts: PageWithRelations[];
  @Input() mode = DataSchemaDisplayComponentMode.Edit;
  @Input() replaceExistingCrudPages: boolean;

  @Output() currentStepChange = new EventEmitter<number>();

  isGuidanceExpanded = false;
  isFieldsSaved: boolean;
  uiDataSelectorOptions: KeyValue<string, string>[];
  initialDataSchema: DataSchemaWithRelations;
  layoutSelectOptions: KeyValue<string, string>[] = [];
  selectedLayoutId: string;
  layoutPageData: string[];

  DATA_SCHEMA_FIELD_ENUM_DATA_CONFIG = DATA_SCHEMA_FIELD_ENUM_DATA_CONFIG;
  CodemirrorSettings = CODEMIRROR_JSON_SETTINGS;
  settingsSecondStepModalAlertConfig = settingsSecondStepModalAlertConfig;
  settingsFirstStepModalAlertConfig = settingsFirstStepModalAlertConfig;
  ActionButtonConfig = ActionButtonConfig;
  DataSchemaDisplayComponentMode = DataSchemaDisplayComponentMode;

  /* current step of data schema field edit and crud pages generation */
  currentStep = 0;

  subscription: Subscription;

  constructor(
    private store: Store<any>,
    private modalService: NzModalService,
    private jsonValidationService: JsonValidationService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    // this.store.dispatch(new UpdateGuidanceKey({ guidanceKey: 'dataSchemaFieldEdit' }));
    this.setDefaultUIDataSelector();
    this.subscription = this.subscribeToFieldsSaved();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSchemas) {
      this.setDataSchemaFieldData();
    }
    if (changes.isLoading) {
      this.isLoading = changes.isLoading.currentValue;
    }
    if (changes.layouts) {
      this.setLayoutSelectOptions();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  subscribeToFieldsSaved() {
    return this.store.select(state => state.dataSchema?.isFieldsSaved).subscribe((isFieldsSaved: boolean) => {
      this.isFieldsSaved = isFieldsSaved;
      if (this.isFieldsSaved) {
        this.currentStep += 1;
        this.currentStepChange.emit(this.currentStep);
        this.isGuidanceExpanded = false;
      }
    });
  }

  setDataSchemaFieldData() {
    if (this.activeDataSchema?.fields?.length) {
      this.initialDataSchema = cloneDeep(this.activeDataSchema);
      this.activeDataSchema = cloneDeep(this.activeDataSchema);

      const fields = [
        ...<any[]>this.activeDataSchema?.fields?.map(dataSchemaField => ({
          ...dataSchemaField,
          uiDataSelectorSettings: dataSchemaField.uiDataSelectorSettings ?? {},
          uiDataSelectorEnumData: dataSchemaField.uiDataSelectorEnumData ?? <EnumDataWithRelations>{ options: null },
          type: this.dataSchemas?.find(dataSchema => dataSchema.id === dataSchemaField.typeId),
        }))
      ];

      this.activeDataSchema.fields = [
        ...fields.map(dataSchemaField => (setUIDataSelectorConfig(dataSchemaField)))
      ];
    }
  }

  /**
   *
   *
   * @param {DataSchemaFieldWithRelations} dataSchemaField
   * @return {*}
   * @memberof DataSchemaDisplayComponent
   */
  setDataSchemaFieldType(dataSchemaField: DataSchemaFieldWithRelations) {
    return dataSchemaField.isArray ? `${dataSchemaField?.type?.name}[]` : dataSchemaField?.type?.name;
  }

  /**
   * set ui data selector options for selectbox
   *
   * @memberof DataSchemaDisplayComponent
   */
  setDefaultUIDataSelector() {
    this.uiDataSelectorOptions = Object.entries(CrudViewFormItemType)
      .map(([key, value]) => ({
        key: key,
        value: value
      }));
    const component = {
      key: 'Component',
      value: 'component'
    };
    this.uiDataSelectorOptions.push(component);
    this.uiDataSelectorOptions = orderBy(this.uiDataSelectorOptions, 'key', 'asc');
  }

  /**
   * set modal settings by component's edit mode.
   *
   * @param {*} modalMode
   * @param {DataSchemaFieldWithRelations} dataSchemaField
   * @memberof DataSchemaDisplayComponent
   */
  setEditDataSchemaFieldModal(modalMode: any, dataSchemaField: DataSchemaFieldWithRelations) {
    if (modalMode === 'editSettings') {
      this.modalService.create(
        {
          nzMask: false,
          nzTitle: `${this.activeDataSchema.name} ${dataSchemaField.name} Field Settings`,
          nzOkText: 'Save',
          nzWidth: '80%',
          nzContent: this.editSettingsModalTemplate,
          nzComponentParams: {
            dataSchemaField: dataSchemaField
          },
          nzOnOk: () => {
            this.onSaveUIDataSelectorSettings(dataSchemaField);
          }
        }
      );
    } else if (modalMode === 'editOptions') {
      this.modalService.create(
        {
          nzMask: false,
          nzTitle: `${this.activeDataSchema.name} ${dataSchemaField.name} Field Options`,
          nzOkText: 'Save',
          nzWidth: '80%',
          nzContent: this.editOptionsModalTemplate,
          nzComponentParams: {
            dataSchemaField: dataSchemaField
          },
          nzOnOk: () => {
            this.enumDataForm.submit();
          }
        }
      );
    }
  }

  setLayoutSelectOptions() {
    this.layoutSelectOptions = this.layouts?.map(layout => ({
      key: layout.name,
      value: layout.id
    }));
  }

  onSaveUIDataSelectorSettings(dataSchemaField: DataSchemaFieldWithRelations) {
    const editingDataSchemaField = this.activeDataSchema.fields.find(field => field.id === dataSchemaField.id);

    editingDataSchemaField.uiDataSelectorSettings = !isEmpty(dataSchemaField.uiDataSelectorSettings) ?
      this.validateUIDataSelectorSettings(dataSchemaField.uiDataSelectorSettings) : {};

    const dataSchemaFieldsData: DataSchemaFieldBulkUpdateUiSettingsBodyDto[] = [
      {
        id: editingDataSchemaField.id,
        data: {
          uiDataSelectorSettings: editingDataSchemaField.uiDataSelectorSettings
        }
      }
    ];

    this.store.dispatch(new BulkUpdateDataSchemaFieldUISettings({
      dataSchemaFieldBulkUpdateData: dataSchemaFieldsData,
      projectModel: this.projectModel,
      generateCrudPagesAfterUpdate: false
    }));
  }

  /**
   * Creates or updates ui data selector enum data (options data) depending on there is enum data
   * in initial data schema field
   *
   * @param {EnumDataCreateDto} enumData
   * @param {DataSchemaFieldWithRelations} dataSchemaField
   * @memberof DataSchemaDisplayComponent
   */
  onEnumDataFormSubmit(enumData: EnumData, dataSchemaField: DataSchemaFieldWithRelations) {
    const initialDataSchemaField = this.initialDataSchema.fields.find(field => field.id === dataSchemaField.id);
    enumData = {
      ...enumData,
      dataSchemaFieldId: dataSchemaField.id
    };

    if (!initialDataSchemaField.uiDataSelectorEnumData) {
      this.store.dispatch(new CreateUIDataSelectorEnumData({ uiDataSelectorEnumData: enumData }));
    } else {
      delete enumData.dataSchemaFieldId;
      this.store.dispatch(new UpdateUIDataSelectorEnumData({ enumDataId: dataSchemaField.uiDataSelectorEnumData?.id, uiDataSelectorEnumData: enumData }));
    }
  }

  /**
   *  validates ui data selector settings data and returns parsed json if the data is valid
   *
   * @param {*} uiDataSelectorSettings
   * @return {*}
   * @memberof DataSchemaDisplayComponent
   */
  validateUIDataSelectorSettings(uiDataSelectorSettings) {
    const uiDataSelectorSettingsData = this.jsonValidationService.validateStringifiedJson(uiDataSelectorSettings, true);
    if (uiDataSelectorSettingsData.isJsonValid) {
      return uiDataSelectorSettingsData.dataAsJson;
    } else {
      if (!isEmpty(uiDataSelectorSettings)) {
        this.notificationService.createNotification(
          'error',
          'SHARED.ERROR',
          'ERRORS.WRONG_JSON_FORMAT'
        );
      }
      return null;
    }
  }

  /**
   * set data schema field dat for editing by mapping it to update body and
   * removing empty fields
   *
   * @return {*}
   * @memberof DataSchemaDisplayComponent
   */
  setDataSchemaFieldUpdateData() {
    let dataSchemaFieldsData: DataSchemaFieldBulkUpdateUiSettingsBodyDto[] = this.activeDataSchema.fields.map(dataSchemaField => ({
      id: dataSchemaField.id,
      data: {
        uiDataSelector: dataSchemaField.uiDataSelector,
        uiDataSelectorSettings: dataSchemaField.uiDataSelectorSettings
      }
    }));

    dataSchemaFieldsData.map((dataSchemaFieldData) => {
      if (isEmpty(dataSchemaFieldData.data.uiDataSelector)) {
        delete dataSchemaFieldData.data.uiDataSelector;
      }
      if (isEmpty(dataSchemaFieldData.data.uiDataSelectorSettings)) {
        delete dataSchemaFieldData.data.uiDataSelectorSettings;
      }
    });

    return dataSchemaFieldsData = dataSchemaFieldsData.filter(dataSchemaFieldData => !isEmpty(dataSchemaFieldData.data));
  }

  /**
   * dispatch bulk update data schema fields. Generates crud pages after update depending on
   * component's edit mode
   *
   * @memberof DataSchemaDisplayComponent
   */
  onSaveDataSchemaFieldDetails() {
    const dataSchemaFieldsData = this.setDataSchemaFieldUpdateData();

    this.store.dispatch(new BulkUpdateDataSchemaFieldUISettings({
      dataSchemaFieldBulkUpdateData: dataSchemaFieldsData,
      projectModel: this.projectModel,
      generateCrudPagesAfterUpdate: false
    }));
  }

  /**
   * dispatch generate crud pages action
   *
   * @memberof DataSchemaDisplayComponent
   */
  generateCRUDPages() {
    this.store.dispatch(new CreateCrudPagesForProjectModel({ projectModel: this.projectModel, layoutId: this.selectedLayoutId, replaceExistingCrudPages: this.replaceExistingCrudPages }));
  }

  onClickHelp() {
    this.isGuidanceExpanded = !this.isGuidanceExpanded;
  }

  onClickSave() {
    this.onSaveDataSchemaFieldDetails();
  }

  onClickSkip() {
    this.currentStep += 1;
    this.isGuidanceExpanded = false;
    this.currentStepChange.emit(this.currentStep);
  }

  onClickBack() {
    this.currentStep -= 1;
    this.currentStepChange.emit(this.currentStep);
  }
}
