/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { isEqual, isObject } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DataTransformService {

  checkIfObjectsEqual;

  flattenObject(source: object, prefix: string = null) {
    /* more info => https://stackoverflow.com/questions/62272415/how-to-convert-json-object-keys-as-string-format */
    return Object.keys(source).reduce((acc, key) => {
      const pre = prefix && prefix.length ? prefix + '.' : '';
      const value = source[key];
      /* if value variable has a value and is an only object ( not an array ) */
      if (value && !Array.isArray(value) && isObject(value)) {
        Object.assign(acc, this.flattenObject(source[key], pre + key));
      } else {
        acc[pre + key] = value;
      }
      return acc;
    }, {});
  }

  /**
   *
   *
   * @param {object} source
   * Only object type will be given to source.
   * @param {(object | Array<any>)} value
   * Only object or array type will be given to value.
   * @returns {string}
   * Returns value's path in source as a string.
   * @memberof DataTransformService
   */
  findObjectPath(source: object, value: object | Array<any>): string {
    for (const key in source) {
      if (source[key] === value) {
        return key;
      } else if (typeof source[key] === 'object') {
        const path = this.findObjectPath(source[key], value);
        if (path) {
          return key + '.' + path;
        }
      }
    }
  }


  /**
   * converts data to key-value paired object array
   *
   * @param {object} data
   * @returns
   * @memberof DataTransformService
   */
  convertObjectDataToKeyValueData(data: object) {
    return Object.entries(data).map(([key, value]) => ({
      key: key,
      value: value
    }));
  }

  /**
   * takes two arrayobject as argument and finds the deleted,created,updated objects by fieldName
   *
   * @param {any[]} arrayInitial
   * @param {any[]} arrayFinal
   * @param {string} [fieldName]
   * @returns
   * @memberof DataTransformService
   */
  compareArrays(arrayInitial: object[], arrayFinal: object[], fieldName: string = 'id', returnWithFinalItem = false) {
    /* create local deleted Array  */
    const deletedArray = arrayInitial?.filter(
      prevItem => !arrayFinal?.some(nextItem => nextItem[fieldName] === prevItem[fieldName])
    );
    /* intializing local updated array */
    const updatedArray = [];
    /* initializing local created array */
    const createdArray = [];
    /* for each object in final array */
    arrayFinal?.forEach((item: any) => {
      /* finding comparising object */
      const comparisingObject: any = arrayInitial?.find(element => fieldName && element[fieldName] === item[fieldName]);
      /* initializing changed fields */
      let changedFields: any = {};
      /* if there is no comparising object, it means that it's created so push to created array */
      if (!comparisingObject) {
        createdArray.push(item);
      } else {
        /* for each final array's object key  */
        Object.keys(item).forEach(key => {
          /* if value isn't equal to comparising object's value */
          if (!isEqual(item[key], comparisingObject[key])) {
            if (!changedFields[fieldName]) {
              changedFields = {
                ...changedFields,
                [fieldName]: item[fieldName],
                [key]: item[key]
              };
            } else {
              changedFields = {
                ...changedFields,
                [key]: item[key]
              };
            }
          }
        });
      }
      /* if there is something changed, push to changedArray */
      if (Object.keys(changedFields).length !== 0) {
        if (returnWithFinalItem) {
          updatedArray.push({
            changedFields,
            item
          });
        } else {
          updatedArray.push(changedFields);
        }
      }
    });

    return {
      createdArray: createdArray,
      updatedArray: updatedArray,
      deletedArray: deletedArray
    };
  }

}
