import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DataSchemaInterface } from '@rappider/api-sdk';
import { DataSchemaFieldRowPages } from '@rappider/shared/interfaces';
import { DATA_SCHEMA_CREATE_OR_EDIT_CONFIG, DATA_SCHEMA_TYPE_SELECT_CONFIG } from '../../../configs/data-schema-create-or-edit-config';

@Component({
  selector: 'rappider-data-schema-create-edit-form',
  templateUrl: './data-schema-create-edit-form.component.html',
  styleUrls: ['./data-schema-create-edit-form.component.scss']
})
export class DataSchemaCreateEditFormComponent implements OnInit, OnDestroy {

  @Input() data: DataSchemaInterface;
  @Input() dataSchemaFieldPageType: DataSchemaFieldRowPages;
  @Input() typeSelector = true;
  @Input() submitButtonLoading: boolean;

  dataSchemaFormConfig = DATA_SCHEMA_CREATE_OR_EDIT_CONFIG;

  @Output() formSubmit = new EventEmitter<DataSchemaInterface>();

  ngOnInit(): void {
    this.setDataSchemaTypeSelect();
  }

  ngOnDestroy(): void {
    this.removeDataSchemaTypeSelect();
  }

  /**
   *
   * sets data schema type select (model or enum)
   *
   * @memberof DataSchemaCreateEditFormComponent
   */
  setDataSchemaTypeSelect() {
    if (this.dataSchemaFieldPageType === DataSchemaFieldRowPages.InputDefinition && this.typeSelector) {
      this.dataSchemaFormConfig.items.push(DATA_SCHEMA_TYPE_SELECT_CONFIG);
    }
  }

  /**
   *
   * remove data schema type select config (model or enum)
   * when create data schema modal saved or canceled
   *
   * @memberof DataSchemaCreateEditFormComponent
   */
  removeDataSchemaTypeSelect() {
    this.dataSchemaFormConfig.items = this.dataSchemaFormConfig.items.filter(item => item.fieldName !== 'type');
  }

  onDataSchemaFormSubmit(dataSchema: DataSchemaInterface) {
    this.formSubmit.emit(dataSchema);
  }

}
