import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RappiderMediaPlayerComponent } from './media-player.component';

@NgModule({
  declarations: [
    RappiderMediaPlayerComponent
  ],
  imports: [
    CommonModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [
    RappiderMediaPlayerComponent
  ],
})
export class RappiderMediaPlayerModule { }
