import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { VersionSummary } from './config/version-summary.interface';

@Component({
  selector: 'rappider-app-build-page',
  templateUrl: './app-build-page.component.html',
  styleUrls: ['./app-build-page.component.scss']
})
export class AppBuildPageComponent implements OnInit {
  versionSummary: VersionSummary | undefined;
  isBuildInfoExist = false;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.loadVersionSummary();
  }

  loadVersionSummary(): void {
    this.http.get<VersionSummary>('assets/build-info/version-summary.json')
      .subscribe({
        next: (data: VersionSummary) => {
          this.versionSummary = data;
          this.isBuildInfoExist = true;
        },
        error: (error) => {
          this.isBuildInfoExist = false;
        },
        complete: () => {
          console.log('JSON loading complete');
        }
      });
  }
}
