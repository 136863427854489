import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { StateService } from '@rappider/services';
import { PAGE_LAYOUT_EDIT_FORM_CONFIG } from '@rappider/shared/configs';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { Subscription } from 'rxjs';
import { UpdateLayout } from '../../state/layout-state/layout.actions';
import { Module, PagePartial, PageWithRelations, Project } from '@rappider/rappider-sdk';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'rappider-page-layout-edit',
  templateUrl: './page-layout-edit.component.html',
  styleUrls: ['./page-layout-edit.component.scss']
})
export class PageLayoutEditComponent implements OnInit, OnDestroy {
  /* main title */
  mainTitle: HeadingComponentConfig;
  /* component title */
  title: string | string[] | BreadcrumbOption[];
  /* subscriptions */
  subscriptions: Subscription[] = [];
  /* component config */
  PAGE_LAYOUT_EDIT_FORM_CONFIG = PAGE_LAYOUT_EDIT_FORM_CONFIG;
  /* layout page */
  layout: PageWithRelations;
  /* all layouts */
  layouts: PageWithRelations[];
  /* layout id */
  layoutId: string;
  /* edited layout data */
  editedLayoutData: PagePartial;
  /* module options */
  moduleOptions: { key: string; value: string }[];
  displayToolbar = false;
  displayToolbarBackButton = false;
  activeProject: Project;
  loading: boolean;

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private stateService: StateService
    // eslint-disable-next-line no-empty-function
  ) { }

  ngOnInit(): void {
    this.layoutId = this.getLayoutIdFromUrl();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToLayoutLastProcessedAction(),
      this.subscribeToModules(),
      this.subscribeToLayouts(),
      this.subscribeToLoading()
    ];
  }

  /**
   * subscirbe to active project
   *
   * @return {*}
   * @memberof PageLayoutEditComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe(activeProject => {
      if (activeProject) {
        this.activeProject = activeProject;
      }
    });
  }

  subscribeToModules() {
    return this.store.select(state => state.module?.data).subscribe((modules: Module[]) => {
      this.moduleOptions = modules?.map(module => ({
        key: module.name,
        value: module.id
      })) ?? [];
    });
  }

  subscribeToLayouts() {
    return this.store.select(state => state.layout?.data).subscribe((layouts: PageWithRelations[]) => {
      this.layouts = layouts;
      if (this.layoutId) {
        this.getLayoutById(this.layoutId);
      }
    });
  }

  subscribeToLoading() {
    return this.store.select(state => state.layout.loading).subscribe(loading => {
      this.loading = loading;
    });
  }

  getLayoutIdFromUrl() {
    return this.activatedRoute.snapshot.paramMap.get('id');
  }

  /**
   * get layouts by layout id
   *
   * @param {string} pageId
   * @memberof PageLayoutEditComponent
   */
  getLayoutById(pageId: string) {
    /* subscribe to layouts's */
    this.layout = cloneDeep(this.layouts?.find(layout => layout.id === pageId));
    this.getTitle();
  }

  getTitle() {
    this.mainTitle = {
      content: 'PAGES_MODULE.PAGE_LAYOUT_EDIT_COMPONENT.PAGE_LAYOUT_EDIT',
      type: defaultToolbarTitleHeadingSize
    };
    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
      },
      {
        label: PAGE_DEFINITIONS.PAGES.CHILDREN.PAGE_LAYOUTS_LIST.PAGE_TITLE,
        redirectUrl: PATH_DEFINITIONS.PAGES.PAGE_LAYOUT_LIST_PATH
      },
      {
        label: this.layout?.title,
      },
      {
        label: PAGE_DEFINITIONS.PAGES.CHILDREN.PAGE_LAYOUTS_EDIT.PAGE_TITLE
      }
    ];
  }

  /**
   * on form submit function for page layout edit - triggered when clicked save
   *
   * @param {PageInterface} layoutData
   * @memberof PageLayoutEditComponent
   */
  editLayout(layout: PagePartial) {
    if (this.editedLayoutData) {
      this.store.dispatch(new UpdateLayout({
        layout: this.editedLayoutData,
        layoutId: this.layoutId,
      }));
    }
  }

  onFieldValueChange(data: PagePartial) {
    this.editedLayoutData = {
      ...this.editedLayoutData,
      ...data
    };
  }

  subscribeToLayoutLastProcessedAction() {
    return this.store.select(state => state.layout.lastProcessedAction).subscribe((lastProcessedAction) => {
      const isLastProcessedActionValid = this.stateService.getBufferedTimestamp(lastProcessedAction.timestamp);
      if (lastProcessedAction.success && (lastProcessedAction.data.id === this.layoutId) && isLastProcessedActionValid) {
        this.store.dispatch(new Navigate({ url: PATH_DEFINITIONS.PAGES.PAGE_LAYOUT_LIST_PATH }));
      }
    });
  }
}
