export enum RadioGroupDirection {
  Horizantal = 'horizantal',
  Vertical = 'vertical'
}

export const radioGroupDirectionOptions = [
  {
    key: 'Horizantal',
    value: 'horizontal'
  },
  {
    key: 'Vertical',
    value: 'vertical'
  }
];
