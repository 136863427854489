export const booleanValueOptions = [
  {
    key: 'True',
    value: true
  },
  {
    key: 'False',
    value: false
  }
];
