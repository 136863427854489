<div class="crud-modal-wrapper">
  <div *ngIf="selectedCustomFunctionName"
       class="selected-function-name">{{selectedCustomFunctionName}}</div>
  <div>
    <rappider-modal [(visible)]="customFunctionModalVisibility"
                    [title]="modalConfig?.title"
                    [okText]="modalConfig?.okText"
                    [cancelText]="modalConfig?.cancelText"
                    [width]="modalConfig?.width"
                    [okDisabled]="modalConfig?.okDisabled"
                    [cancelDisabled]="modalConfig?.cancelDisabled"
                    [footer]="isGoBackModalFooter ? createEditFooter : ''"
                    [okLoading]="modalConfig?.okLoading"
                    [okDanger]="modalConfig?.okDanger"
                    [mask]="modalConfig?.mask"
                    [maskClosable]="modalConfig?.maskClosable"
                    [className]="modalConfig?.className"
                    [bodyStyle]="modalConfig?.bodyStyle"
                    [closeIconVisibility]="modalConfig?.closeIconVisibility"
                    [fullScreen]="modalConfig?.fullScreen"
                    (okClick)="onSelectCustomFunction()"
                    (cancelClick)="onCloseModalAndClearSelectedCustomFunction()">
      <div *ngIf="crudVisibility">
        <rappider-crud-view [modelNameSingular]="modelNameSingular"
                            [mainTitleConfig]="mainTitleConfig!"
                            [listMode]="ListMode"
                            [showCreateButton]="showCreateButton"
                            [showCreateButtonEmit]="mode === WorkflowFunctionTypeOptions.WorkflowFunctions ? showCreateButtonEmit : null"
                            [showEditButtonEmit]="showEditButtonEmit"
                            [createButtonTitle]="createItemTitle"
                            [displayToolbar]="displayToolbar"
                            [listGridData]="displayedFunctions"
                            [listGridConfig]="listGridConfig"
                            [isListGridActionButtonsEmit]="isListGridActionButtonsEmit"
                            [cardListConfig]="cardListConfig"
                            [createFormConfig]="createItemFormConfig!"
                            [updateFormConfig]="updateItemFormConfig!"
                            [updateFormData]="activeCustomFunction"
                            [isListGridDataLoading]="isCustomFunctionsLoading"
                            [updateFormSubmitButtonLoading]="isCustomFunctionsLoading"
                            [createFormSubmitButtonLoading]="isCustomFunctionsLoading"
                            (cardListItemSelected)="onListCustomFunctionSelected($event)"
                            (gridListItemSelected)="onListCustomFunctionSelected($event)"
                            (titleBarActionButtonClicked)="onTitleBarActionButtonClicked($event)"
                            (cardListItemActionClicked)="onListCustomFunctionActionClicked($event)"
                            (gridListItemActionClicked)="onListCustomFunctionActionClicked($event)"
                            (itemDeleted)="onCustomFunctionDeleted($event)">
        </rappider-crud-view>
      </div>
      <div *ngIf="createVisibility">
        <rappider-custom-function-create [mainTitleConfig]="mainTitleConfig!"
                                         [displayToolbar]="displayToolbar"
                                         (createFormSubmit)="onCreateCustomFunctionFormSubmit($event)"></rappider-custom-function-create>
      </div>
      <div *ngIf="editVisibility">
        <rappider-custom-function-edit [mainTitleConfig]="mainTitleConfig!"
                                       [activeCustomFunctionId]="activeCustomFunctionId"
                                       [displayToolbar]="displayToolbar"
                                       [updateFormData]="activeCustomFunction"
                                       (updateFormSubmit)="onUpdateCustomFunctionFormSubmit($event)"></rappider-custom-function-edit>
      </div>
    </rappider-modal>
    <ng-template #createEditFooter>
      <div nz-modal-footer>
        <rappider-button [text]="footerButtonConfig.text"
                         [type]="footerButtonConfig.type"
                         (click)="onGoBackToCrudListOfCustomFunctions()"></rappider-button>
      </div>
    </ng-template>
    <rappider-button [text]="buttonConfig?.text"
                     [key]="buttonConfig?.key"
                     [type]="buttonConfig?.type"
                     [shape]="buttonConfig?.shape"
                     [size]="buttonConfig?.size"
                     [transparent]="buttonConfig?.transparent"
                     [loading]="buttonConfig?.loading"
                     [block]="buttonConfig?.block"
                     [disabled]="buttonConfig?.disabled"
                     [icon]="buttonConfig?.icon"
                     [colorType]="buttonConfig?.colorType"
                     [popconfirmTitle]="buttonConfig?.popconfirmTitle"
                     [emitWithoutPopconfirm]="buttonConfig?.emitWithoutPopconfirm"
                     [iconPlacement]="buttonConfig?.iconPlacement"
                     [formButtonType]="buttonConfig?.formButtonType"
                     [borderSettings]="buttonConfig?.borderSettings"
                     [marginSettings]="buttonConfig?.marginSettings"
                     [paddingSettings]="buttonConfig?.paddingSettings"
                     [shadowSettings]="buttonConfig?.shadowSettings"
                     [customColorSettings]="buttonConfig?.customColorSettings"
                     [customSizeSettings]="buttonConfig?.customSizeSettings"
                     [tooltipText]="buttonConfig?.tooltipText"
                     (click)="onOpenCustomFunctionCrudListModal()"></rappider-button>
  </div>
</div>
