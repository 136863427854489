import { createSelector } from '@ngrx/store';
import { ProjectModelField, ProjectModelWithRelations } from '@rappider/rappider-sdk';
import { orderBy } from 'lodash';

export const getProjectModelsWithFields = createSelector(
  state => state['projectModel']?.data,
  state => state['projectModelField']?.data,
  state => state['projectModelField']?.isLoaded,
  state => state['projectModel']?.isLoaded,
  (
    projectModels: ProjectModelWithRelations[],
    projectModelFields: ProjectModelField[],
    isProjectModelsLoaded: boolean,
    isProjectModelFieldsLoaded: boolean) => {
    if (isProjectModelFieldsLoaded && isProjectModelsLoaded) {
      return projectModels.map(projectModel => (
        {
          ...projectModel,
          fields: orderBy(projectModelFields.filter(projectModelField => projectModelField.projectModelId === projectModel.id), 'index', 'asc')
        }
      ));
    }
  }
);
