import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { RappiderTagComponent } from './tag.component';
import { RappiderIconModule } from '../icon/icon.module';
import { RappiderTextModule } from '../text/text.module';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    RappiderTagComponent
  ],
  exports: [
    RappiderTagComponent
  ],
  imports: [
    CommonModule,
    NzTagModule,
    RappiderIconModule,
    RappiderTextModule,
    NzToolTipModule,
    TranslateModule,
  ]
})
export class RappiderTagModule { }
