import { createAction, createReducer, on } from '@ngrx/store';
import { ProjectModelEndpointWithRelations } from '@rappider/rappider-sdk';

import * as ProjectModelEndpointActions from './project-model-endpoint.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import { orderBy } from 'lodash';

/* state key */
export const featureKey = 'projectModelEndpoint';

/* state interface */
export interface ProjectModelEndpointState {
  data: ProjectModelEndpointWithRelations[];
  isLoading: boolean;
  error: any;
  openAPIDefinition: Record<string, unknown>;
}

/* initial values */
export const initialState: ProjectModelEndpointState = {
  data: [],
  isLoading: false,
  error: null,
  openAPIDefinition: undefined
};

const ChangeActiveProjectSuccessful = createAction(AuthenticationActions.ActionTypes.ChangeActiveProjectSuccessful);
const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

export const reducer = createReducer(
  initialState,
  on(ProjectModelEndpointActions.CreateProjectModelEndpoint, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(ProjectModelEndpointActions.CreateProjectModelEndpointSuccessful, (state, action) => ({
    ...state,
    data: orderBy([
      ...state.data,
      action.payload.endpoint
    ], 'createdDate', 'desc'),
    isLoading: false,
  })),
  on(ProjectModelEndpointActions.CreateProjectModelEndpointFailure, (state, action) => ({
    ...state,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    },
    isLoading: false
  })),
  on(ProjectModelEndpointActions.GetProjectModelEndpoints, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(ProjectModelEndpointActions.GetProjectModelEndpointsSuccessful, (state, action) => ({
    ...state,
    data: orderBy(action.payload.endpoints, 'createdDate', 'desc'),
    isLoading: false
  })),
  on(ProjectModelEndpointActions.GetProjectModelEndpointByModelIdSuccessful, (state, action) => ({
    ...state,
    data: [
      ...state.data,
      ...action.payload.endpoints
    ]
  })),
  on(ProjectModelEndpointActions.UpdateProjectModelEndpoint, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(ProjectModelEndpointActions.UpdateProjectModelEndpointFailure, (state, action) => ({
    ...state,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    },
    isLoading: false
  })),
  on(ProjectModelEndpointActions.GetProjectModelEndpointById, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(ProjectModelEndpointActions.GetProjectModelEndpointByIdSuccessful, (state, action) => ({
    ...state,
    data: orderBy([
      ...state.data.filter(endpoint => endpoint.id !== action.payload.updatedEndpoint.id),
      action.payload.updatedEndpoint
    ], 'createdDate', 'desc'),
    isLoading: false
  })),
  on(ProjectModelEndpointActions.GetProjectModelEndpointByIdFailure, (state, action) => ({
    ...state,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    },
    isLoading: false
  })),
  on(ProjectModelEndpointActions.DeleteProjectModelEndpoint, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(ProjectModelEndpointActions.DeleteProjectModelEndpointSuccessful, (state, action) => ({
    ...state,
    data: state.data?.filter(endpoint => endpoint.id !== action.payload.endpointId),
    isLoading: false
  })),
  on(ProjectModelEndpointActions.DeleteProjectModelEndpointFailure, (state, action) => ({
    ...state,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    },
    isLoading: false
  })),
  on(ProjectModelEndpointActions.GetOpenAPIDefinitionSuccessful, (state, action) => ({
    ...state,
    openAPIDefinition: action.payload.openAPIDefinition
  })),
  on(ChangeActiveProjectSuccessful, () => (initialState)),
  on(Logout, () => (initialState))
);
