import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RappiderChatComponent } from './chat.component';
import { RappiderInputGroupModule } from '../input-group/input-group.module';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { RappiderAvatarModule } from '../avatar/avatar.module';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { PipesModule } from '../../utils/shared';
import { RappiderButtonModule } from '../button/button.module';
import { RappiderButtonGroupModule } from '../button-group/button-group.module';

@NgModule({
  declarations: [
    RappiderChatComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RappiderInputGroupModule,
    NzButtonModule,
    RappiderAvatarModule,
    NzSkeletonModule,
    PipesModule,
    RappiderButtonModule,
    RappiderButtonGroupModule
  ],
  exports: [
    RappiderChatComponent
  ]
})
export class RappiderChatModule { }
