import { ActionBehavior, CrudTableViewConfig, CrudViewColumnType } from '@rappider/rappider-components/utils';
import { PageListButtonsType } from './project-pages-list-page-buttons-config';

export const PROJECT_PAGES_CONFIG: CrudTableViewConfig = {
  columns: [
    {
      title: 'Id',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'Title',
      fieldName: 'title',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Created Date',
      fieldName: 'createdDate',
      type: CrudViewColumnType.Date
    },
    {
      title: 'Created By',
      fieldName: 'createdBy',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Status',
      fieldName: 'developmentStatus',
      type: CrudViewColumnType.Tag
    }
  ],
  itemActions: [
    {
      icon: {
        name: 'fa-arrow-up-right-from-square fa-solid'
      },
      tooltipText: 'Open in new tab',
      name: PageListButtonsType.OpenPage,
      behavior: ActionBehavior.Emit
    },
    {
      text: 'Change Page Status',
      name: PageListButtonsType.EditStatus,
      behavior: ActionBehavior.Emit
    }
  ],
  defaultSearchField: 'title',
  pageSize: 15,
  showPagination: true,
  columnVisibilitySelectable: false,
  handleVisibilityFn: (data, item, action) => {
    if (item.developmentStatus) {
      return true;
    } else {
      return false;
    }
  }
};
