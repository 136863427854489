import { environment } from '../../../../../apps/rappider/src/environments/environment';

export function logToConsole(logs: any[], title?: string) {
  /* Do not console log in prod */
  if (!environment.production && environment.logActionsToConsole) {
    if (logs && logs.length) {
      console.groupCollapsed(title || null);
      logs.forEach(log => console.log(log));
      console.groupEnd();
    }
  }
}
