import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ProjectInterface } from '@rappider/api-sdk';
import { BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { NewUiDataUpdateFunction, UiDataUpdateFunctionPartial } from '@rappider/rappider-sdk';
import { uiDataUpdateFunctionListDataSelector } from 'libs/project/src/lib/states/selectors/ui-data-update-function-list-data.selector';
import { PATH_DEFINITIONS, QUERY_PARAM_DEFINITIONS, defaultToolbarTitleHeadingSize } from '@rappider/shared/definitions';
import { UpdateUIDataUpdateFunction } from 'libs/project/src/lib/states/ui-data-update-functions/ui-data-update-function.actions';
import { Subscription } from 'rxjs';
import { EDIT_CODE_FORM_CONFIG } from './config/edit-code-config';

@Component({
  selector: 'rappider-edit-code',
  templateUrl: './edit-code.component.html',
  styleUrls: ['./edit-code.component.scss']
})
export class EditCodeComponent implements OnInit, OnDestroy {
  EDIT_CODE_FORM_CONFIG = EDIT_CODE_FORM_CONFIG;

  @Input() uiDataStoreId: string;
  @Input() displayBreadcrumb = true;

  uiDataUpdateFunctionId: string;
  uiDataUpdateFunction: NewUiDataUpdateFunction;
  /* main title */
  mainTitle: HeadingComponentConfig = {
    content: 'Edit Code',
    type: defaultToolbarTitleHeadingSize
  };
  /* page title */
  title: BreadcrumbOption[];
  /* subscriptions */
  subscriptions: Subscription[];
  /* active project name */
  activeProject: ProjectInterface;
  displayToolbar = false;
  displayToolbarBackButton = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<any>,
  ) { }

  ngOnInit(): void {
    this.getUIDataUpdateFunctionIdFromUrl();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToUIDataUpdateFunctions()
    ];
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectInterface) => {
      this.activeProject = activeProject;
    });
  }

  subscribeToUIDataUpdateFunctions() {
    return this.store.select(<any>uiDataUpdateFunctionListDataSelector).subscribe((uiDataUpdateFunctions: NewUiDataUpdateFunction[]) => {
      this.uiDataUpdateFunction = uiDataUpdateFunctions.find(uiDataUpdateFunction => uiDataUpdateFunction?.id === this.uiDataUpdateFunctionId);
      this.setTitle();
    });
  }

  setTitle() {
    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`,
      },
      {
        label: 'PROJECT_MODULE.UI_DATA_STORE_LIST_COMPONENT.UI_DATA_STORE_LIST',
        redirectUrl: PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_LIST
      },
      {
        label: this.uiDataUpdateFunction?.uiDataStoreName,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_DETAIL}/${this.uiDataUpdateFunction?.uiDataStoreId}`,
        queryParams: QUERY_PARAM_DEFINITIONS.PROJECT.UI_DATA_STORE_DETAIL.DATA_EVENT_UPDATE_FUNCTION_TAB
      },
      {
        label: this.uiDataUpdateFunction?.dataEventName
      },
      {
        label: 'PROJECT_MODULE.DATA_MAPPINGS_COMPONENT.EDIT_CODE'
      }
    ];
  }

  getUIDataUpdateFunctionIdFromUrl() {
    this.uiDataUpdateFunctionId = this.activatedRoute.snapshot.params['id'];
  }

  onEditCode(uiDataUpdateFunction: UiDataUpdateFunctionPartial) {
    this.store.dispatch(new UpdateUIDataUpdateFunction({
      id: this.uiDataUpdateFunctionId,
      uiDataUpdateFunction: uiDataUpdateFunction,
    }));
  }
}
