<section *ngIf="isLoading">
  <nz-skeleton [nzActive]="true"
               [nzLoading]="isLoading"
               [nzTitle]="false"
               [nzParagraph]="{ rows: 2 }">
  </nz-skeleton>
</section>
<rappider-title-toolbar *ngIf="!isLoading"
                        [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>

<section>
  <rappider-spin [spinning]="!uiDataStore && isLoading">
    <rappider-crud-view-edit-form [config]="UI_DATA_STORE_EDIT_CONFIG"
                                  [data]="uiDataStore"
                                  [submitButtonLoading]="isLoading"
                                  (formSubmit)="onUIDataStoreUpdate($event)"></rappider-crud-view-edit-form>
  </rappider-spin>
</section>