<div class="search">
  <rappider-auto-complete *ngIf="primarySearchBox"
                          [backfill]="primarySearchBox.backfill"
                          [dataSource]="primarySearchBox.dataSource"
                          [defaultActiveFirstOption]="primarySearchBox.defaultActiveFirstOption"
                          [overlayClassName]="primarySearchBox.overlayClassName"
                          [overlayStyle]="primarySearchBox.overlayStyle"
                          [placeholder]="primarySearchBox.placeholder"
                          [borderSettings]="primarySearchBox.borderSettings"
                          [sizeSettings]="primarySearchBox.sizeSettings"
                          [colorSettings]="primarySearchBox.colorSettings"
                          [boxShadowSettings]="primarySearchBox.boxShadowSettings"
                          [marginSettings]="primarySearchBox.marginSettings"
                          [paddingSettings]="primarySearchBox.paddingSettings"
                          [typographySettings]="primarySearchBox.typographySettings"
                          (valueChange)="onPrimarySearchTextChange($event)"></rappider-auto-complete>

  <rappider-auto-complete *ngIf="secondarySearchBox"
                          [backfill]="secondarySearchBox.backfill"
                          [dataSource]="secondarySearchBox.dataSource"
                          [defaultActiveFirstOption]="secondarySearchBox.defaultActiveFirstOption"
                          [overlayClassName]="secondarySearchBox.overlayClassName"
                          [overlayStyle]="secondarySearchBox.overlayStyle"
                          [placeholder]="secondarySearchBox.placeholder"
                          [borderSettings]="secondarySearchBox.borderSettings"
                          [sizeSettings]="secondarySearchBox.sizeSettings"
                          [colorSettings]="secondarySearchBox.colorSettings"
                          [boxShadowSettings]="secondarySearchBox.boxShadowSettings"
                          [marginSettings]="secondarySearchBox.marginSettings"
                          [paddingSettings]="secondarySearchBox.paddingSettings"
                          [typographySettings]="secondarySearchBox.typographySettings"
                          (valueChange)="onSecondarySearchTextChange($event)"></rappider-auto-complete>

  <div *ngIf="searchButton"
       [ngClass]="searchButton.block ? 'block-search-button' : 'search-button' ">
    <rappider-button [key]="searchButton.key"
                     [text]="searchButton.text"
                     [type]="searchButton.type"
                     [shape]="searchButton.shape"
                     [size]="searchButton.size"
                     [transparent]="searchButton.transparent"
                     [loading]="searchButton.loading"
                     [block]="searchButton.block"
                     [disabled]="searchButton.disabled"
                     [icon]="searchButton.icon"
                     [colorType]="searchButton.colorType"
                     [formButtonType]="searchButton?.formButtonType"
                     [borderSettings]="searchButton?.borderSettings"
                     [customSizeSettings]="searchButton?.customSizeSettings"
                     [customColorSettings]="searchButton?.customColorSettings"
                     [shadowSettings]="searchButton?.shadowSettings"
                     [paddingSettings]="searchButton?.paddingSettings"
                     [marginSettings]="searchButton?.marginSettings"
                     (click)="onSearchButtonClick()"></rappider-button>
  </div>
</div>
