import { CrudFormInlineRowFormItem, CrudViewFormItemType } from '@rappider/rappider-components/utils';
import { PACKAGE_COLUMNS } from './package-columns.config';

export const PACKAGE_CONFIG: CrudFormInlineRowFormItem = {
  title: 'Packages',
  fieldName: 'package',
  type: CrudViewFormItemType.InlineRowForm,
  columns: PACKAGE_COLUMNS,
  orderable: true,
  orderNumbersVisibility: true
};
