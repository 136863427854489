/* actions */
import { createReducer, on } from '@ngrx/store';
import { OpenApiWithRelations } from '@rappider/rappider-sdk';
import * as OpenAPIActions from './openapi.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

/* state key */
export const featureKey = 'openAPI';

/* state interface */
export interface State {
  data: OpenApiWithRelations[] | null;
  importFromOpenApiModalVisibility: boolean;
  loading: boolean;
  error: any;
}

/* initial values */
export const initialState: State = {
  data: null,
  importFromOpenApiModalVisibility: false,
  loading: false,
  error: null
};

export const reducer = createReducer(
  initialState,

  on(OpenAPIActions.ToggleImportOpenApiSpecModalVisibility, (state) => ({
    ...state,
    importFromOpenApiModalVisibility: !state.importFromOpenApiModalVisibility,
  })),

  on(OpenAPIActions.GetOpenAPIsWithRelations, (state) => ({
    ...state,
    loading: true
  })),

  on(OpenAPIActions.GetOpenAPIsWithRelationsSuccessful, (state, action) => ({
    ...state,
    data: action.payload.openApiData,
    loading: false
  })),

  on(OpenAPIActions.CreateOpenAPI, (state, action) => ({
    ...state,
    loading: true
  })),

  on(OpenAPIActions.CreateOpenAPISuccessful, (state, action) => ({
    ...state,
    loading: false,
    data: [...state.data, action.payload.openApi],
    importFromOpenApiModalVisibility: false
  })),

  on(OpenAPIActions.DeleteOpenAPI, (state, action) => ({
    ...state,
    loading: true
  })),

  on(OpenAPIActions.DeleteOpenAPISuccessful, (state, action) => ({
    ...state,
    loading: false,
    data: action.payload.openApiData,
  })),

  on(OpenAPIActions.ErrorAction, (state, action) => ({
    ...state,
    loading: false,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timeStamp: action.payload.timeStamp
    }
  })),
  on(OpenAPIActions.ResetStateToInitial, () => initialState),

  on(OpenAPIActions.ResetStateToInitialChangeProject, () => initialState)
);
