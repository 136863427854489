import { MonacoEditorLanguage } from './monaco-editor-language';
import { MonacoEditorTheme } from './monaco-editor-theme';

export const MONACO_EDITOR_JSON_CONFIG = {
  theme: MonacoEditorTheme.Dark,
  language: MonacoEditorLanguage.Json
};

export const MONACO_EDITOR_JAVASCRIPT_CONFIG = {
  theme: MonacoEditorTheme.Dark,
  language: MonacoEditorLanguage.Javascript
};

export const MONACO_EDITOR_TYPECRIPT_CONFIG = {
  theme: MonacoEditorTheme.Dark,
  language: MonacoEditorLanguage.Typescript
};

export const MONACO_EDITOR_CSS_CONFIG = {
  theme: MonacoEditorTheme.Dark,
  language: MonacoEditorLanguage.Css
};

export const MONACO_EDITOR_PYTHON_CONFIG = {
  theme: MonacoEditorTheme.Dark,
  language: MonacoEditorLanguage.Python
};
