import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { ProjectDocumentWithRelations, ProjectWithRelations } from '@rappider/rappider-sdk';
import { PROJECT_DOCUMENT_LIST_CONFIG } from '@rappider/shared/configs';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { parseJSON } from 'date-fns';
import { DeleteProjectDocument, GetProjectDocumentUpload, GetProjectDocuments, ProjectDocumentModalVisibility } from 'libs/project/src/lib/states/project-document-state/project-document.actions';
import { Subscription } from 'rxjs';
import { DocumentListService } from 'libs/shared/src/lib/services/document-list-service/document-list-service';

@Component({
  selector: 'rappider-project-document-list',
  templateUrl: './project-document-list.component.html',
  styleUrls: ['./project-document-list.component.scss']
})
export class ProjectDocumentListComponent implements OnInit, OnDestroy {
  /* list config */
  PROJECT_DOCUMENT_LIST_CONFIG = PROJECT_DOCUMENT_LIST_CONFIG;

  /* main title */
  mainTitle: HeadingComponentConfig;
  /* page title */
  title: BreadcrumbOption[];
  /* subscriptions */
  subscriptions: Subscription[];
  /* active project */
  activeProject: ProjectWithRelations;
  /* project documents */
  projectDocuments: ProjectDocumentWithRelations[];
  /* project documents loading */
  loading: boolean;

  displayToolbar = false;
  displayToolbarBackButton = false;
  source;
  visibility = false;
  modalConfig = {
    width: '100%',
    title: 'Preview',
    okText: 'Select'
  };

  constructor(
    private store: Store<any>,
    private documentListService: DocumentListService
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToProjectDocuments(),
      this.subscribeToProjectDocumentModalVisibility(),
      this.subscribeToProjectDocumentsLoading(),
      this.subscribeToSelectedProjectDocument()
    ];
  }

  setTitle() {
    this.mainTitle = {
      content: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_DOCUMENT_LIST.PAGE_TITLE,
      type: defaultToolbarTitleHeadingSize
    };

    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
      }
    ];
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((project: ProjectWithRelations) => {
      if (project) {
        this.activeProject = project;
        this.setTitle();
        this.store.dispatch(GetProjectDocuments());
      } else {
        this.activeProject = null;
      }
    });
  }

  subscribeToProjectDocuments() {
    return this.store.select(state => state.projectDocument?.data).subscribe((projectDocuments: ProjectDocumentWithRelations[]) => {
      this.projectDocuments = projectDocuments ?? [];
    });
  }

  subscribeToProjectDocumentModalVisibility() {
    return this.store.select(state => state.projectDocument?.modalVisibility).subscribe((modalVisibility: boolean) => {
      this.visibility = modalVisibility;
    });
  }

  subscribeToProjectDocumentsLoading() {
    return this.store.select(state => state.projectDocument.loading).subscribe((loading: boolean) => {
      this.loading = loading;
    });
  }

  subscribeToSelectedProjectDocument() {
    return this.store.select(state => state.projectDocument?.source).subscribe((source: any) => {
      if (source) {
        this.documentListService.blobToBase64(source).then(base64 => {
          this.source = base64;
        });
      }
    });
  }

  onColumnActionClick(columnAction) {
    const projectDocument = columnAction.data;
    if (columnAction.action?.name === 'DELETE_ITEM') {
      this.store.dispatch(DeleteProjectDocument({ payload: { projectDocumentId: projectDocument.id } }));
    } else if (columnAction.action?.name === 'preview') {
      this.store.dispatch(GetProjectDocumentUpload({ payload: { id: columnAction.data.id } }));
    }
  }

  visibleChange(visibility) {
    this.store.dispatch(ProjectDocumentModalVisibility({ payload: { modalVisibility: visibility } }));
  }
}
