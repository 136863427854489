import { Validators } from '@angular/forms';
import { CrudFormConfig, CrudFormSelectItem, CrudViewFormItemType, FormLayout } from '@rappider/rappider-components/utils';
import { ProjectStructureType } from 'libs/shared/src/lib/definitions/project-structure-type.enum';
import { ProjectVisibility } from 'libs/shared/src/lib/definitions/project-visibility.enum';

export const ADMIN_PROJECTS_FORM_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormSelectItem>{
      title: 'Project Visibility',
      fieldName: 'visibility',
      type: CrudViewFormItemType.Select,
      options: [
        { key: 'Private', value: ProjectVisibility.Private },
        { key: 'Public', value: ProjectVisibility.Public }
      ],
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'Please select a project visibility'
        }
      ]
    },
    <CrudFormSelectItem>{
      title: 'Project Structure Type',
      fieldName: 'structureType',
      type: CrudViewFormItemType.Select,
      options: [
        { key: 'Application', value: ProjectStructureType.Application },
        { key: 'Template', value: ProjectStructureType.Template},
        { key: 'Module', value: ProjectStructureType.Module }
      ],
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'Please select a project structure type'
        }
      ]
    },
  ],
  submitButton: { visible: false },
  formLayout: FormLayout.Horizontal
};

