import { NgZorroIconTheme } from '../../definitions/ng-zorro-icon-theme.enum';
import { NgZorroIcon } from '@rappider/rappider-components/utils';

export const NG_ZORRO_ICONS: NgZorroIcon[] = [
  {
    name: 'bug',
    theme: NgZorroIconTheme.Fill
  },
  {
    name: 'step-backward',
    theme: NgZorroIconTheme.Fill
  },
  {
    name: 'caret-right',
    theme: NgZorroIconTheme.Fill
  },
  {
    name: 'down-square',
    theme: NgZorroIconTheme.Fill
  },
  {
    name: 'camera',
    theme: NgZorroIconTheme.Fill
  },
  {
    name: 'audio',
    theme: NgZorroIconTheme.Fill
  },
  {
    name: 'bank',
    theme: NgZorroIconTheme.Fill
  },
  {
    name: 'fund',
    theme: NgZorroIconTheme.Fill
  }
];
