import { UiWorkflowStepFunctionWithRelations } from '@rappider/rappider-sdk';
import { PostDataTransformationData, PreDataTransformationData } from '@rappider/shared/interfaces';
import * as UIWorkflowStepFunctionActions from './ui-step-function.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import * as AppActions from 'apps/rappider/src/app/state/app.actions';
import { orderBy } from 'lodash';

export const uiWorkflowStepFunctionFeatureKey = 'uiWorkflowStepFunction';

export interface State {
  data: UiWorkflowStepFunctionWithRelations[];
  preDataTransformationData: PreDataTransformationData;
  postDataTransformationData: PostDataTransformationData;
  loading: boolean;
  dataTransformationsLoading: boolean;
  preDataTransformationModalVisibility: boolean;
  postDataTransformationModalVisibility: boolean;
  isLoaded: boolean;
  templateUIStepFunctions: UiWorkflowStepFunctionWithRelations[];
  isTemplateUIStepFunctionsLoading: boolean;
  error: {
    timestamp?: number;
    key?: string;
    error?: unknown;
  };
}

export const initialState: State = {
  data: [],
  preDataTransformationData: null,
  postDataTransformationData: null,
  loading: true,
  isTemplateUIStepFunctionsLoading: false,
  dataTransformationsLoading: false,
  preDataTransformationModalVisibility: false,
  postDataTransformationModalVisibility: false,
  isLoaded: false,
  templateUIStepFunctions: [],
  error: {}
};

export function reducer(
  state: State = initialState,
  action: UIWorkflowStepFunctionActions.Actions | AuthenticationActions.Actions | AppActions.Actions
): State {
  switch (action.type) {

    case UIWorkflowStepFunctionActions.ActionTypes.GetUIWorkflowStepFunctions:
      return {
        ...state,
        loading: true
      };

    case UIWorkflowStepFunctionActions.ActionTypes.GetUIWorkflowStepFunctionsSuccessful:
      return {
        ...state,
        data: orderBy(action.payload.uiWorkflowStepFunctions, 'createdDate', 'asc') || [],
        loading: false,
        isLoaded: true
      };

    case UIWorkflowStepFunctionActions.ActionTypes.CreateUIWorkflowStepFunctionSuccessful:
      return {
        ...state,
        data: orderBy([...state.data, action.payload.uiWorkflowStepFunction], 'createdDate', 'asc'),
        loading: false
      };

    case UIWorkflowStepFunctionActions.ActionTypes.BulkCreateUIWorkflowStepFunctionSuccessful:
      return {
        ...state,
        data: [...state.data, ...action.payload.uiWorkflowStepFunctions],
        loading: false
      };

    case UIWorkflowStepFunctionActions.ActionTypes.GetUpdatedUIWorkflowStepFunctionWithDetailsSuccessful: {
      const updatedUIStepFunction = action.payload.uiWorkflowStepFunction;
      return {
        ...state,
        data: orderBy([
          ...state.data.filter(stepFunction => stepFunction.id !== updatedUIStepFunction.id),
          updatedUIStepFunction
        ], 'createdDate', 'asc'),
        loading: false
      };
    }

    case UIWorkflowStepFunctionActions.ActionTypes.CreateUIWorkflowStepFunction:
      return {
        ...state,
        loading: true
      };

    case UIWorkflowStepFunctionActions.ActionTypes.UpdateUIWorkflowStepFunction:
      return {
        ...state,
        loading: true
      };

    case UIWorkflowStepFunctionActions.ActionTypes.DeleteUIWorkflowStepFunction:
      return {
        ...state,
        loading: true
      };

    case UIWorkflowStepFunctionActions.ActionTypes.DeleteUIWorkflowStepFunctionSuccessful:
      return {
        ...state,
        data: state.data.filter(stepFunction => stepFunction.id !== action.payload.id),
        loading: false
      };

    case UIWorkflowStepFunctionActions.ActionTypes.GetPreDataTransformationData:
      return {
        ...state,
        dataTransformationsLoading: true
      };

    case UIWorkflowStepFunctionActions.ActionTypes.GetPreDataTransformationDataSuccessful:
      return {
        ...state,
        preDataTransformationData: {
          preDataTransformationId: action.payload.preDataTransformationId,
          preSourceJsonSchema: action.payload.sourceJsonSchema,
          preTargetJsonSchema: action.payload.targetJsonSchema
        },
        loading: false,
        dataTransformationsLoading: false
      };

    case UIWorkflowStepFunctionActions.ActionTypes.GetPreDataTransformationDataFailure:
      return {
        ...state,
        error: {
          timestamp: action.payload.timestamp,
          key: action.payload.key,
          error: action.payload.error
        },
        dataTransformationsLoading: false
      };

    case UIWorkflowStepFunctionActions.ActionTypes.GetPreDTSourceAndTargetSchemaData: {
      const updatedUIStepFunction = {
        ...state.data.find(uiStepFunction => uiStepFunction.id === action.payload.uiStepFunction.id),
        ...action.payload.uiStepFunction
      };

      return {
        ...state,
        data: orderBy([
          ...state.data.filter(uiStepFunction => uiStepFunction.id !== action.payload.uiStepFunction.id),
          updatedUIStepFunction
        ], 'createdDate', 'asc')
      };
    }

    case UIWorkflowStepFunctionActions.ActionTypes.GetPreDTSourceAndTargetSchemaDataFailure:
      return {
        ...state,
        error: {
          timestamp: action.payload.timestamp,
          key: action.payload.key,
          error: action.payload.error
        },
        dataTransformationsLoading: false
      };

    case UIWorkflowStepFunctionActions.ActionTypes.GetPostDataTransformationData:
      return {
        ...state,
        dataTransformationsLoading: true
      };

    case UIWorkflowStepFunctionActions.ActionTypes.GetPostDataTransformationDataSuccessful:
      return {
        ...state,
        postDataTransformationData: {
          postDataTransformationId: action.payload.postDataTransformationId,
          postSourceJsonSchema: action.payload.sourceJsonSchema,
          postTargetJsonSchema: action.payload.targetJsonSchema
        },
        loading: false,
        dataTransformationsLoading: false
      };

    case UIWorkflowStepFunctionActions.ActionTypes.GetPostDataTransformationDataFailure:
      return {
        ...state,
        error: {
          timestamp: action.payload.timestamp,
          key: action.payload.key,
          error: action.payload.error
        },
        dataTransformationsLoading: false
      };

    case UIWorkflowStepFunctionActions.ActionTypes.GetPostDTSourceAndTargetSchemaDataFailure:
      return {
        ...state,
        error: {
          timestamp: action.payload.timestamp,
          key: action.payload.key,
          error: action.payload.error
        },
        dataTransformationsLoading: false
      };

    case UIWorkflowStepFunctionActions.ActionTypes.GetPostDTSourceAndTargetSchemaData: {
      const updatedUIStepFunction = {
        ...state.data.find(uiStepFunction => uiStepFunction.id === action.payload.uiStepFunction.id),
        postDataTransformationId: action.payload.uiStepFunction.postDataTransformationId
      };

      return {
        ...state,
        data: orderBy([
          ...state.data.filter(uiStepFunction => uiStepFunction.id !== action.payload.uiStepFunction.id),
          updatedUIStepFunction
        ], 'createdDate', 'asc')
      };
    }

    case UIWorkflowStepFunctionActions.ActionTypes.ClearPreDataTransformationData:
      return {
        ...state,
        preDataTransformationData: null,
        loading: false
      };

    case UIWorkflowStepFunctionActions.ActionTypes.ClearPostDataTransformationData:
      return {
        ...state,
        postDataTransformationData: null,
        loading: false
      };

    case UIWorkflowStepFunctionActions.ActionTypes.TogglePreDataTransformationModalVisibility:
      return {
        ...state,
        preDataTransformationModalVisibility: !state.preDataTransformationModalVisibility
      };

    case UIWorkflowStepFunctionActions.ActionTypes.TogglePostDataTransformationModalVisibility:
      return {
        ...state,
        postDataTransformationModalVisibility: !state.postDataTransformationModalVisibility
      };

    case UIWorkflowStepFunctionActions.ActionTypes.ErrorAction:
      return {
        ...state,
        error: {
          ...state.error,
          timestamp: action.payload.timestamp,
          key: action.payload.key,
          error: action.payload.error
        },
        loading: false,
        dataTransformationsLoading: false
      };

    case AppActions.ActionTypes.LoadApp:
      return {
        ...state,
        isTemplateUIStepFunctionsLoading: true
      };

    case UIWorkflowStepFunctionActions.ActionTypes.GetTemplateUIStepFunctionsSuccessful:
      return {
        ...state,
        templateUIStepFunctions: [...action.payload.uiWorkflowStepFunctions],
        isTemplateUIStepFunctionsLoading: false
      };

    case UIWorkflowStepFunctionActions.ActionTypes.GetTemplateUIStepFunctionsFailure:
      return {
        ...state,
        error: action.payload.error,
        isTemplateUIStepFunctionsLoading: false
      };

    case AuthenticationActions.ActionTypes.Logout:
      return initialState;

    case AuthenticationActions.ActionTypes.ChangeActiveProjectSuccessful: {
      const templateUIStepFunctions = state.templateUIStepFunctions;
      return {
        ...initialState, templateUIStepFunctions: templateUIStepFunctions
      };
    };

    default:
      return state;
  }
}
