import { Component, OnInit } from '@angular/core';
import { BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { PAGE_DEFINITIONS, PATH_DEFINITIONS, defaultToolbarTitleHeadingSize } from '@rappider/shared/definitions';
import { PROJECT_DETAIL_CONFIG } from './config/project-detail-config';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { ProjectWithRelations } from '@rappider/rappider-sdk';
import { GetPaginationBySearchCount } from '../../state/admin-projects/admin-projects.actions';

@Component({
  selector: 'rappider-admin-project-detail',
  templateUrl: './admin-project-detail.component.html',
  styleUrls: ['./admin-project-detail.component.scss']
})
export class AdminProjectDetailComponent implements OnInit {

  /* config */
  projectDetailConfig = PROJECT_DETAIL_CONFIG;

  /* main title for title toolbar */
  mainTitle: HeadingComponentConfig = {
    content: 'Admin Dashboard',
    type: defaultToolbarTitleHeadingSize
  };

  title: BreadcrumbOption[] = [];

  projectId: string;
  selectedProject: ProjectWithRelations;
  isLoading: boolean;

  subscriptions: Subscription[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.getProjectId();
    this.subscribeToData();
  }

  getProjectId() {
    this.projectId = this.activatedRoute.snapshot.params['id'];
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeAdminProjects(),
      this.subscribeToLoading()
    ];
  }

  subscribeAdminProjects() {
    return this.store.select(state => state.adminProjects?.data).subscribe((projects: ProjectWithRelations[]) => {
      if (projects && this.projectId) {
        this.selectedProject = projects.find(project => project.id === this.projectId);
        this.setTitle();
      } else {
        this.store.dispatch(GetPaginationBySearchCount({ pageIndex: 1, pageSize: 10, searchText: '' }));
      }
    });
  }

  subscribeToLoading() {
    return this.store.select(state => state.adminProjects?.isLoading).subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
  }

  setTitle() {
    this.title = [
      {
        label: 'Admin Dashboard',
        redirectUrl: PAGE_DEFINITIONS.ADMIN_DASHBOARD.URL
      },
      {
        label: 'Projects',
        redirectUrl: PATH_DEFINITIONS.ADMIN_DASHBOARD.PROJECTS
      },
      {
        label: 'Project Detail'
      },
      {
        label: this.selectedProject?.name
      }
    ];
  }
}
