<div class="item">
  <div class="title-area">
    <div class="title">
      {{ 'PROJECT_MODULE.DATA_MAPPING_CREATE_COMPONENT.FUNCTION' | translate }}
    </div>
    <div class="add-button">
      <rappider-button [text]="functionButtonText"
                       [icon]="functionButton.icon"
                       (click)="handleFunctionState()"></rappider-button>
    </div>
  </div>

  <div>
    <ng-container *ngIf="!this.value.isCustom; else customTmpl">
      <nz-select [(ngModel)]="value.name"
                 [nzShowSearch]="true"
                 nzAllowClear
                 [nzPlaceHolder]="'SHARED.FUNCTION' | translate"
                 (ngModelChange)="onFunctionSelect()">
        <nz-option *ngFor="let option of functions"
                   [nzLabel]="option.title | translate"
                   [nzValue]="option.key"></nz-option>
      </nz-select>
    </ng-container>

    <ng-template #customTmpl>
      <rappider-map-custom-function [(ngModel)]="value.code"
                                    [selectedSourceFields]="sourceFields">
      </rappider-map-custom-function>
    </ng-template>
  </div>
</div>
