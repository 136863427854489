export const renderedPageAnimationOptions = [
    {
        path: 'assets/animations/empty-1.json',
        loop: true
    },
    {
        path: 'assets/animations/empty-2.json',
        loop: true
    },
    {
        path: 'assets/animations/empty-3.json',
        loop: true
    },
    {
        path: 'assets/animations/empty-4.json',
        loop: true
    },
    {
        path: 'assets/animations/empty-5.json',
        loop: true
    }
];
