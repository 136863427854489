/* eslint-disable */

declare var Object: any;
export interface SettingsInterface {
  id?: any;
  key: string;
  value: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
}

export class Settings implements SettingsInterface {
  'id'?: any;
  'key': string;
  'value': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  constructor(data?: SettingsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Settings`.
   */
  public static getModelName() {
    return 'Settings';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Settings for dynamic purposes.
   **/
  public static factory(data: SettingsInterface): Settings {
    return new Settings(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Settings',
      plural: 'Settings',
      path: 'settings',
      idName: 'id',
      properties: {
        key: {
          name: 'key',
          type: 'string',
        },
        value: {
          name: 'value',
          type: 'string',
        },
        id: {
          name: 'id',
          type: 'any',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
      },
      relations: {},
    };
  }
}
