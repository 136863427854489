<form nz-form
      [formGroup]="updateCustomFunctionForm"
      [nzLayout]="formLayout"
      (ngSubmit)="onUpdateCustomFunction()">

  <!-- <nz-form-item>
    <nz-form-label  >
      {{ 'Service Name' | translate}}
    </nz-form-label>
    <nz-form-control [nzErrorTip]="nameFieldErrorTemplates"
                      >
      <rappider-textbox formControlName="serviceName"></rappider-textbox>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label  >
      {{ 'Function Name' | translate}}
    </nz-form-label>
    <nz-form-control [nzErrorTip]="requiredErrorTemplate"
                      >
      <rappider-textbox formControlName="functionName"></rappider-textbox>
    </nz-form-control>
  </nz-form-item> -->

  <nz-form-item *ngIf="updateCustomFunctionForm.get('programmingLanguage')">
    <nz-form-label>
      {{ 'Programming Language' | translate}}
    </nz-form-label>
    <nz-form-control>
      <rappider-select [options]="programmingLanguageOptions"
                       formControlName="programmingLanguage"
                       (valueChange)="onProgrammingLanguageChange($event)"></rappider-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="updateCustomFunctionForm.get('packages')">
    <nz-form-label>
      {{ 'Packages' | translate}}
    </nz-form-label>
    <nz-form-control>
      <rappider-inline-row-form [columns]="packageColumns"
                                formControlName="packages"></rappider-inline-row-form>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="updateCustomFunctionForm.get('packages')">
    <nz-form-label [nzNoColon]="true">
    </nz-form-label>
    <nz-form-control>
      <rappider-button [text]="'Add Packages'"
                       [size]="'small'"
                       (confirm)="openModal()"></rappider-button>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="updateCustomFunctionForm.get('environmentVariables')">
    <nz-form-label>
      {{ 'Environment Variables' | translate }}
    </nz-form-label>
    <nz-form-control>
      <rappider-custom-function-tab-list-wrapper [displayedEnvironmentVariables]="displayedEnvironmentVariables"
                                                 (activeEnvironmentVariableTab)="onHandleActiveEnvironmentVariableTabList($event)"
                                                 (createFormSubmit)="onCreateFormSubmit($event)"
                                                 (editFormSubmit)="onEditFormSubmit($event)"
                                                 (deleteFormItem)="onDeleteFormItem($event)"></rappider-custom-function-tab-list-wrapper>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="updateCustomFunctionForm.get('code')">
    <nz-form-label>
      {{ 'Code and Payload Configurations' | translate }}
    </nz-form-label>
    <nz-form-control>
      <nz-tabset nzType="card"
                 nzAnimated="true"
                 nzTabPosition="top"
                 nzSize="default"
                 [nzSelectedIndex]="'0'"
                 class="card-container">
        <nz-tab [nzTitle]="'Code'"
                (nzSelect)="changeActiveCodeEditorTab(customFunctionCreateEditFormItem.Code)">

          <nz-form-item>
            <nz-form-control [nzErrorTip]="requiredErrorTemplate">
              <div class="code-editor-wrapper">
                <rappider-monaco-code-editor *ngIf="codeEditorVisible"
                                             [options]="editorConfig"
                                             [borderSettings]="{border: 'none'}"
                                             formControlName="code"></rappider-monaco-code-editor>
              </div>
            </nz-form-control>
          </nz-form-item>

        </nz-tab>
        <nz-tab [nzTitle]="'Request JSON Sample'"
                (nzSelect)="changeActiveCodeEditorTab(customFunctionCreateEditFormItem.RequestJSONSchema)">

          <nz-form-item>
            <nz-form-control [nzErrorTip]="updateCustomFunctionForm.get('requestJSONSample')?.errors?.error">
              <div class="code-editor-wrapper">
                <rappider-monaco-code-editor *ngIf="requestJSONSchemaVisible"
                                             [options]="MONACO_EDITOR_JSON_CONFIG"
                                             [autoFormat]="true"
                                             [borderSettings]="{border: 'none'}"
                                             formControlName="requestJSONSample"></rappider-monaco-code-editor>
                {{MONACO_EDITOR_JAVASCRIPT_CONFIG | json}}
              </div>
            </nz-form-control>
          </nz-form-item>

        </nz-tab>
        <nz-tab [nzTitle]="'Response JSON Sample'"
                (nzSelect)="changeActiveCodeEditorTab(customFunctionCreateEditFormItem.ResponseJSONSchema)">
          <nz-form-item>
            <nz-form-control [nzErrorTip]="updateCustomFunctionForm.get('responseJSONSample')?.errors?.error">
              <div class="code-editor-wrapper">
                <rappider-monaco-code-editor *ngIf="responseJSONSchemaVisible"
                                             [options]="MONACO_EDITOR_JSON_CONFIG"
                                             [autoFormat]="true"
                                             [borderSettings]="{border: 'none'}"
                                             formControlName="responseJSONSample"></rappider-monaco-code-editor>
              </div>
            </nz-form-control>
          </nz-form-item>
        </nz-tab>

        <!-- Mock Response Area -->
        <nz-tab *ngIf="visibleFields.includes('mockResponse')"
                [nzTitle]="'Mock Response'"
                (nzSelect)="changeActiveCodeEditorTab(customFunctionCreateEditFormItem.MockResponse)">
          <nz-form-item>
            <nz-form-control [nzErrorTip]="updateCustomFunctionForm.get('mockResponse')?.errors?.error">
              <div class="code-editor-wrapper">
                <rappider-monaco-code-editor *ngIf="mockResponseVisible"
                                             [options]="MONACO_EDITOR_JSON_CONFIG"
                                             [autoFormat]="true"
                                             [borderSettings]="{border: 'none'}"
                                             formControlName="mockResponse"></rappider-monaco-code-editor>
              </div>
            </nz-form-control>
          </nz-form-item>
        </nz-tab>
      </nz-tabset>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="visibleFields.includes('submit-button')">
    <nz-form-label [nzNoColon]="true">
    </nz-form-label>
    <nz-form-control>
      <button nz-button
              [disabled]="!enableSubmitButton"
              [nzLoading]="submitButtonLoading"
              type="submit"
              nzType="primary">
        {{ 'Save' | translate}}
      </button>
    </nz-form-control>
  </nz-form-item>

  <ng-template #nameFieldErrorTemplates>
    <small *ngIf="getNameFieldErrorsByErrorKey('required')">
      - {{ 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE' | translate}}
      <br />
    </small>
    <small *ngIf="getNameFieldErrorsByErrorKey('pattern')">
      - {{'ERRORS.PATTERN_MESSAGES.MUST_START_WITH_CAPITAL_LETTER' | translate }}
    </small>
  </ng-template>
  <ng-template #requiredErrorTemplate>
    <small>
      - {{ 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE' | translate}}
    </small>
  </ng-template>
</form>

<rappider-modal [(visible)]="isModalOpen"
                [title]="'Add Package'"
                width="40%"
                (okClick)="(selectedVersion) ? onModalOk() : selectVersionAlert()"
                (cancelClick)="onModalCancel()">
  <rappider-spin>
    <nz-select class="search-area"
               nzPlaceHolder="Search Packages"
               nzAllowClear
               nzShowSearch
               nzServerSearch
               [(ngModel)]="selectedPackage"
               (nzOnSearch)="onSearchValueChange($event)"
               (ngModelChange)="onSelectPackage($event)">
      <ng-container *ngFor="let package of npmPackages">
        <nz-option *ngIf="!isLoading"
                   [nzValue]="package.value"
                   [nzLabel]="package.key"></nz-option>
      </ng-container>
      <nz-option *ngIf="isLoading"
                 nzDisabled
                 nzCustomContent>
        <rappider-icon class="loading-icon"
                       [name]="loadingIcon.name"
                       [type]="loadingIcon.type"></rappider-icon>
        {{"SHARED.LOADING" | translate}}
      </nz-option>
    </nz-select>

    <div class="package-details-area">
      <rappider-label *ngIf="packageDetails"
                      content="Package Details"></rappider-label>

      <!-- <rappider-crud-detail *ngIf="packageDetails; else noPackageTmpl"
                              [data]="packageDetails"></rappider-crud-detail> -->

      <div *ngIf="packageDetails; else noPackageTmpl">
        <section>
          <div *ngFor="let item of packageDetails"
               class="item-area">
            <div class="property-title col-2">
              <rappider-label [content]="item.key | translate"></rappider-label>
            </div>

            <div class="property-value col-10">
              <div *ngFor="let value of item.value">
                <rappider-text *ngIf="item.key !== 'SHARED.VERSION'"
                               [text]="value"></rappider-text>
                <rappider-select *ngIf="item.key === 'SHARED.VERSION'"
                                 [grouppedOptions]="value"
                                 [placeholder]="selectedVersion"
                                 (valueChange)="onVersionChange($event)"></rappider-select>
              </div>
            </div>
          </div>
        </section>
      </div>

      <ng-template #noPackageTmpl>
        <rappider-alert [type]="projectPackageInfoAlertConfig.type"
                        [title]="projectPackageInfoAlertConfig.title"
                        [description]="projectPackageInfoAlertConfig.description"
                        [closeable]="projectPackageInfoAlertConfig.closeable"
                        [showIcon]="projectPackageInfoAlertConfig.showIcon"></rappider-alert>
      </ng-template>
    </div>
  </rappider-spin>
</rappider-modal>