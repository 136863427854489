<div *ngIf="isGuidanceContentAvailable()"
     class="guidance-wrapper">
  <section *ngIf="isGuidanceExpanded"
           class="markdown-section"
           [ngClass]="getGuidanceExpansionCSSClass()">
    <section *ngFor="let content of guidanceContents"
             class="markdown-sub-section">
      <div>
        <rappider-html-viewer [html]="content"></rappider-html-viewer>
      </div>
    </section>
  </section>
</div>