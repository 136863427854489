import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Module, ModulePartial, ProjectSetting } from '@rappider/rappider-sdk';
import { MODULE_CREATE_OR_EDIT_CONFIG } from '@rappider/shared/configs';
import { Subscription } from 'rxjs';
import { UpdateModule } from '../../state/module.actions';
import { CrudFormTextBoxItem } from '@rappider/rappider-components/utils';

@Component({
  selector: 'rappider-module-edit',
  templateUrl: './module-edit.component.html',
  styleUrls: ['./module-edit.component.scss']
})
export class ModuleEditComponent implements OnInit, OnDestroy {
  MODULE_EDIT_CONFIG = MODULE_CREATE_OR_EDIT_CONFIG;

  moduleId: string;
  editingModule: Module;
  defaultModuleId: string;
  subscriptions: Subscription[];
  submitButtonLoading = false;

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getModuleIdFromUrl();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToModules(),
      this.subscribeToDefaultModuleId(),
      this.subscribeToModuleLoading(),
    ];
  }

  getModuleIdFromUrl() {
    this.moduleId = this.activatedRoute.snapshot.params['id'];
  }

  subscribeToModuleLoading() {
    return this.store.select(state => state.module?.isLoading).subscribe((loading: boolean) => {
      this.submitButtonLoading = loading;
    });
  }

  /**
   *  subscribe to modules to filter the editing module
   *
   * @return {*}
   * @memberof ModuleEditComponent
   */
  subscribeToModules() {
    return this.store.select(state => state.module?.data).subscribe((modules: Module[]) => {
      if (modules) {
        this.editingModule = modules.find(module => module.id === this.moduleId);
      }
    });
  }

  subscribeToDefaultModuleId() {
    return this.store.select(state => state.projectSetting?.data).subscribe((projectSettings: ProjectSetting[]) => {
      if (projectSettings.length) {
        this.defaultModuleId = projectSettings.find(setting => setting.key === 'defaultModuleId').value;
        this.setSlugDisabled();
      }
    });
  }

  setSlugDisabled() {
    const slugField = this.MODULE_EDIT_CONFIG.items.find(item => item.fieldName === 'slug') as CrudFormTextBoxItem;
    if (slugField) {
      slugField.disabled = this.defaultModuleId && this.defaultModuleId === this.editingModule.id;
    }
  }

  onEditModuleFormSubmit(module: ModulePartial) {
    this.store.dispatch(UpdateModule({ payload: { module: module, moduleId: this.moduleId } }));
  }

}
