import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PageListService {

  constructor() { }

  convertTreeToList(root: any): any[] {
    const stack: any[] = [];
    const array: any[] = [];
    const hashMap = {};
    stack.push({ ...root, level: 0, expand: true });

    while (stack.length !== 0) {
      const node = stack.pop();
      this.visitNode(node, hashMap, array);
      if (node.children) {
        stack.push(
          ...node.children.reverse().map(item => ({
              ...item,
              level: node.level + 1,
              expand: false,
              parent: node
            }))
        );
      }
    }

    return array;
  }

  visitNode(node: any, hashMap: { [key: string]: boolean }, array: any[]): void {
    if (!hashMap[node.key]) {
      hashMap[node.key] = true;
      array.push(node);
    }
  }
}
