import { ActionBehavior, ButtonType, CrudTableViewConfig, CrudViewColumnType } from '@rappider/rappider-components/utils';

export const PROJECT_COMMENTS_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'content',
  pageSize: 10,
  showPagination: true,
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'Content',
      fieldName: 'content',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Category',
      fieldName: 'category',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Created By',
      fieldName: 'createdBy',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Status',
      fieldName: 'status',
      type: CrudViewColumnType.Tag
    }
  ],
  itemActions: [
    // currently delete and edit are not possible
    // {
    //   text: 'Edit',
    //   name: 'EDIT',
    //   behavior: ActionBehavior.Emit,
    //   icon: { name: 'far fa-edit' },
    // },
    // {
    //   text: 'Delete',
    //   icon: { name: 'far fa-trash' },
    //   name: 'DELETE',
    //   behavior: ActionBehavior.Emit
    // }
  ],
};
