import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { BreadcrumbOption, ButtonComponentConfig, ButtonSize, HeadingComponentConfig, IconType, InputGroupComponentConfig } from '@rappider/rappider-components/utils';
import { UI_DATA_UPDATE_FUNCTIONS_LIST_CONFIG } from '../ui-data-update-function-list/config/ui-data-update-functions-list-config';
import { GetUIDataStores } from 'libs/project/src/lib/states/ui-data-store/ui-data-store.actions';
import { uiDataUpdateFunctionListDataSelector } from 'libs/project/src/lib/states/selectors/ui-data-update-functions-list-data.selector';
import { toLower } from 'lodash';
import { CustomUIDataStore } from '../ui-data-update-function-page/utils/custom-ui-data-store.interface';
import { CustomUIDataUpdateFunction } from '../ui-data-update-function-page/utils/custom-ui-data-update-function.interface';
import { DeleteUIDataUpdateFunction } from 'libs/project/src/lib/states/ui-data-update-functions/ui-data-update-function.actions';
import { ProjectWithRelations } from '@rappider/rappider-sdk';

@Component({
  selector: 'rappider-ui-data-update-functions',
  templateUrl: './ui-data-update-functions.component.html',
  styleUrls: ['./ui-data-update-functions.component.scss']
})
export class UiDataUpdateFunctionsComponent implements OnInit, OnDestroy {
  /* list config */
  UI_DATA_UPDATE_FUNCTIONS_LIST_CONFIG = UI_DATA_UPDATE_FUNCTIONS_LIST_CONFIG;
  /* main title of the page */
  mainTitle: HeadingComponentConfig;
  /* breadcrumb title of the page */
  title: BreadcrumbOption[];
  /* subscriptions */
  subscriptions: Subscription[];
  /* activeProjectId */
  activeProjectId: string;
  /* ui data update function list data with ui data store and update functions */
  uiDataUpdateFunctionListData: CustomUIDataStore[];
  /* ui data update functions loading state */
  loading: boolean;
  collapseToggle = true;
  searchText: string;
  searchResults: CustomUIDataStore[];

  expandButtonConfig: ButtonComponentConfig = {
    text: 'Expand All',
    key: 'expandAll',
    icon: {
      name: 'fas fa-plus'
    }
  };

  collapseButtonConfig: ButtonComponentConfig = {
    text: 'Collapse All',
    key: 'collapseAll',
    icon: {
      name: 'fas fa-minus'
    }
  };
  inputGroupConfig: InputGroupComponentConfig = {
    textbox: {
      placeholder: 'Search In UI Data Update Functions'
    },
    suffixIcon: {
      name: 'fas fa-search',
      type: IconType.FontAwesome
    }
  };

  createButtonConfig: ButtonComponentConfig = {
    size: ButtonSize.ExtraSmall,
    icon: {
      name: 'fa fa-plus',
    }
  };

  displayToolbar = false;
  displayToolbarBackButton = false;

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToUIDataUpdateFunctionListData(),
      this.subscribeToUIDataUpdateFunctionsLoadingState()
    ];
  }

  getUIDataStores() {
    this.store.dispatch(new GetUIDataStores());
  }

  subscribeToUIDataUpdateFunctionListData() {
    return this.store.select(<any>uiDataUpdateFunctionListDataSelector).subscribe(uiDataUpdateFunctionListData => {
      this.uiDataUpdateFunctionListData = uiDataUpdateFunctionListData;
    });
  }

  subscribeToUIDataUpdateFunctionsLoadingState() {
    return this.store.select(state => state.uiDataUpdateFunction.loading).subscribe((loading: boolean) => {
      this.loading = loading;
    });
  }

  navigateToCreateUIDataUpdateFunctionPage(uiDataStoreId: string) {
    this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.UI_DATA_UPDATE_FUNCTION_CREATE}/${uiDataStoreId}` }));
  }

  deleteUIDataUpdateFunction(uiDataUpdateFunction: CustomUIDataUpdateFunction) {
    const uiDataUpdateFunctionId = uiDataUpdateFunction.id;
    if (uiDataUpdateFunctionId) {
      this.store.dispatch(new DeleteUIDataUpdateFunction({ dataEventName: uiDataUpdateFunction?.dataEventName, id: uiDataUpdateFunction.id }));
    }
  }

  toggleCollapsePanelVisibility(key: string) {
    if (key === 'expandAll') {
      this.collapseToggle = true;
    } else if (key === 'collapseAll') {
      this.collapseToggle = false;
    }
  }

  /**
    * subscribe to active project to set the title
    *
    * @returns
    * @memberof UiDataUpdateFunctionsComponent
    */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectWithRelations) => {
      if (activeProject) {
        this.getUIDataStores();

        this.mainTitle = {
          content: 'PROJECT_MODULE.UI_DATA_EVENT_UPDATE_FUNCTION_COMPONENT.UI_DATA_EVENT_UPDATE_FUNCTION',
          type: defaultToolbarTitleHeadingSize
        };
        this.title = [
          {
            label: activeProject.name,
            redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${activeProject?.id}`
          },
          {
            label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_DATA_UPDATE_FUNCTION.PAGE_TITLE
          }
        ];
        this.activeProjectId = activeProject.id;
      }
    });
  }

  onSearchTextChange() {
    this.searchResults = this.uiDataUpdateFunctionListData.map(data => ({
      ...data,
      uiDataUpdateFunctions: data.uiDataUpdateFunctions?.filter(uiDataUpdateFunction => toLower(uiDataUpdateFunction.dataEventName).includes(toLower(this.searchText)))
    }));
    this.searchResults.sort(this.sortByEmptyListData);
  }

  sortByEmptyListData(emptyData, data) {
    if (emptyData.uiDataUpdateFunctions.length === 0 && data.uiDataUpdateFunctions.length > 0) {
      return 1;
    } else if (emptyData.uiDataUpdateFunctions.length > 0 && data.uiDataUpdateFunctions.length === 0) {
      return -1;
    } else {
      return 0;
    }
  }
}
