import { CrudFormConfig, CrudViewFormItemType } from '@rappider/rappider-components/utils';
import { Validators } from '@angular/forms';
import { METADATA_EDIT_FORM_CONFIG } from '../metadata/metadata-edit-form-config';

export const TENANT_EDIT_FORM_CONFIG: CrudFormConfig = {
  items: [
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewFormItemType.TextBox,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    METADATA_EDIT_FORM_CONFIG
  ]
};
