/**
 * used for to distinguish comment's related model for shared actions
 *
 * @export
 * @enum {number}
 */
export enum CommentCategory {
  Page = 'page',
  ProjectModel = 'project-model',
  AllComments = 'all-comments',
  // ClientSideWorkflow
  UIDataEvent = 'ui-data-event',
  UIWorkflowStepFunction = 'ui-workflow-step-function',
  // ServerSideWorkflow
  BEWorkflowEvent = 'be-workflow-event',
  BEWorkflowStepFunction = 'be-workflow-step-function'
}
