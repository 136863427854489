/* eslint-disable */
import { Project, Page, Person } from '../index';

declare var Object: any;
export interface ProjectRoleInterface {
  id?: string;
  title?: string;
  projectId?: string;
  project?: Project;
  allowedPages?: Page[];
  restrictedPages?: Page[];
  people?: Person[];
}

export class ProjectRole implements ProjectRoleInterface {
  'id'?: string;
  'title': string;
  'projectId': string;
  project: Project;
  allowedPages: Page[];
  restrictedPages: Page[];
  people: Person[];
  constructor(data?: ProjectRoleInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ProjectRole`.
   */
  public static getModelName() {
    return 'ProjectRole';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ProjectRole for dynamic purposes.
   **/
  public static factory(data: ProjectRoleInterface): ProjectRole {
    return new ProjectRole(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ProjectRole',
      plural: 'ProjectRoles',
      path: 'project-roles',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        title: {
          name: 'title',
          type: 'string',
        },
        projectId: {
          name: 'projectId',
          type: 'string',
        },
      },
      relations: {
        project: {
          name: 'project',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
          keyFrom: 'projectId',
          keyTo: 'id',
        },
        allowedPages: {
          name: 'allowedPages',
          type: 'Page[]',
          model: 'Page',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'id',
        },
        restrictedPages: {
          name: 'restrictedPages',
          type: 'Page[]',
          model: 'Page',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'id',
        },
        people: {
          name: 'people',
          type: 'Person[]',
          model: 'Person',
          relationType: 'hasMany',
          modelThrough: 'PersonProjectRole',
          keyThrough: 'personId',
          keyFrom: 'id',
          keyTo: 'projectRoleId',
        },
      },
    };
  }
}
