export const DEFAULT_THEME = {
    /* fonts */
    '--font-family-primary': 'Poppins',
    '--font-family-secondary': 'Helvetica',

    /* background colors */
    '--body-background-color': '#f9fbfd',
    '--component-header-background-color': '#f7f7f7',
    '--component-inner-background-color': '#ffffff',
    '--alternative-background-color': '#f7f7f7',
    '--input-background-color': '#ffffff',
    '--navbar-background-color': '#f7f7f7',
    '--footer-background-color': '#f7f7f7',
    '--section-background-color': '#ffffff',
    '--table-background-color': '#fff',
    '--table-header-background-color': '#ffff',

    /* border */
    '--primary-border-color': '#edf2f0',
    '--secondary-border-color': '#dee2e6d5',
    '--border-radius-x': '2px',
    '--footer-border-color': '#edf2f0',

    /* text color */
    '--text-color': '#343a40',
    '--primary-text-color': '#16181b',
    '--secondary-text-color': '#6a7482',
    '--alternative-text-color': '#ffffff',
    '--primary-button-text-color': '#ffffff',
    '--secondary-button-text-color': '#343a40',
    '--disabled-color': 'lightgray',
    '--disabled-text-color': '#6c757d',

    /* font sizes */
    '--title-font-size': '16px',
    '--main-font-size': '14px',
    '--subtext-font-size': '13px',

    /* headings */
    '--h1-color': '#16181b',
    '--h2-color': '#16181b',
    '--h3-color': '#16181b',
    '--h4-color': '#16181b',
    '--h5-color': '#16181b',
    '--h6-color': '#16181b',

    '--h1-font-size': '30px',
    '--h2-font-size': '24px',
    '--h3-font-size': '22px',
    '--h4-font-size': '19px',
    '--h5-font-size': '16px',
    '--h6-font-size': '14px',

    /* link */
    '--link-color': '#1890ff',
    '--link-hover-color': '#3aa0ff',

    /* hover */
    '--default-hover-transform': 'scale(1.2)',
    '--default-hover-transition': 'transform 0.3s',
    '--default-hover-color': '#e6e6e6',
    '--primary-hover-color': '#3aa0ff',
    '--table-hover-color': '#fcfcfc',

    /* colors */
    '--primary-color': '#1890ff',
    '--default-color': '#ffffff',
    '--danger-color': '#dc3545',
    '--success-color': '#33cc33',
    '--progress-color': 'purple',
    '--info-color': '#6495ed',
    '--warning-color': '#ffcc00',
    '--lighter-gray': 'lightgray',
    '--lightest-gray': '#f4f4f4',
    '--tag-color': '#f5f5f5',
    '--focus-color': '#188fff5e',
    '--placeholder-color': '#bfbfbf',

    /* Sizes */
    '--margin-x': '5px',
    '--padding-x': '5px',
    '--height-x': '50px',
    '--width-x': '50px',
    '--footer-height': '200px',
    '--card-padding': '24px',

    /* Page Container Margin and Padding */
    '--page-container-margin-top': '10px',
    '--page-container-margin-right': '0px',
    '--page-container-margin-bottom': '10px',
    '--page-container-margin-left': '0px',

    '--page-container-padding-top': '10px',
    '--page-container-padding-right': '10px',
    '--page-container-padding-bottom': '10px',
    '--page-container-padding-left': '10px',

    /* Component Container Margin and Padding */
    '--component-container-margin-top': '10px',
    '--component-container-margin-right': '0px',
    '--component-container-margin-bottom': '10px',
    '--component-container-margin-left': '0px',

    '--component-container-padding-top': '0px',
    '--component-container-padding-right': '0px',
    '--component-container-padding-bottom': '0px',
    '--component-container-padding-left': '0px'
};
