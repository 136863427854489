<div class="date-picker-container">
  <div *ngIf="!timeRangeMode"
       class="date-picker">
    <nz-date-picker [(ngModel)]="value"
                    [id]="elementId"
                    [nzShowTime]="showTime"
                    [nzDisabledDate]="disabledDate"
                    [ngClass]="cssClass"
                    [ngStyle]="cssStyle"
                    [nzPlaceHolder]="placeholder || 'Select Date' | translate"
                    [nzMode]="dateMode"
                    [nzFormat]="format"
                    [nzBorderless]="boxBorder"
                    [nzInline]="inline"
                    [nzRenderExtraFooter]="dateMode === DateMode.Date ? extraFooterTemplate : null"></nz-date-picker>
  </div>

  <div *ngIf="timeRangeMode"
       class="two-date-picker">
    <nz-range-picker [(ngModel)]="value"
                     [nzShowTime]="showTime"
                     [nzDisabledTime]="disabledDate"></nz-range-picker>
  </div>

  <ng-template #extraFooterTemplate>
    <span>
      Date Format: <strong>{{format}}</strong></span>
  </ng-template>
</div>