<div class="search-overlay"
     (click)="onOutsideOfSearchAreaClick()">
  <div class="search-wrapper">
    <div class="search-input-wrapper"
         [ngClass]="{'input-area-with-search-results':searchText}"
         (click)="onSearchAreaClick($event)">
      <div class="search-input">
        <i class="fa-regular fa-magnifying-glass"></i>
        <input id="search-input"
               [(ngModel)]="searchText"
               placeholder="Search in Rapider AI"
               autocomplete="off"
               (ngModelChange)="onSearchTextChange()">
      </div>
    </div>
    <div *ngIf="searchText"
         class="search-results">
      <ng-container *ngIf="loading">
        <div class="search-result d-flex align-items-center">
          <div class="main-title">
            <i class="fa-light fa-spinner-third fa-spin"
               style="color: #ffffff;"></i>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="loading === false && searchResults?.length">
        <div *ngFor="let searchResult of searchResults"
             [id]="searchResult.url"
             class="search-result"
             [ngClass]="{'active-search-result': selectedResult.url === searchResult.url}"
             (click)="onSearchResultClick(searchResult.url)">
          <div class="main-title">
            {{searchResult.label}}
          </div>
          <div class="from">
            <nz-tag class="m-0"
                    nzColor="default">
              {{searchResult.group}}
            </nz-tag>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="loading === false && !searchResults?.length">
        <div class="search-result no-result">
          <div class="main-title">
            <nz-empty nzNotFoundContent="No Result"></nz-empty>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
