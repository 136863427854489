import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageInterface, PageApi, Page } from '@rappider/api-sdk';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTransformService, NotificationService, StateService } from '@rappider/services';
import { PATH_DEFINITIONS, PAGE_DEFINITIONS, defaultToolbarTitleHeadingSize, PAGE_PATH_REGEX } from '@rappider/shared/definitions';
import { pageConfigFieldNames, PAGE_EDIT_FORM_CONFIG } from '@rappider/shared/configs';
import { Store } from '@ngrx/store';
import { UpdatePage } from '../../state/page-state/page.actions';
import { Subscription } from 'rxjs';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { BreadcrumbOption, CrudFormSelectItem, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { Category, Module, PageWithRelations, Project } from '@rappider/rappider-sdk';
import { nameExistsValidator } from 'libs/shared/src/lib/functions/name-exists-validator';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'rappider-page-edit',
  templateUrl: './page-edit.component.html',
  styleUrls: ['./page-edit.component.scss']
})
export class PageEditComponent implements OnInit, OnDestroy {

  pageId: string;
  page: PageWithRelations;
  PAGE_EDIT_FORM_CONFIG = PAGE_EDIT_FORM_CONFIG;
  MAIN_PAGE_EDIT_FORM_CONFIG = cloneDeep(this.PAGE_EDIT_FORM_CONFIG.items);
  mainTitle: HeadingComponentConfig;
  title: string | string[] | BreadcrumbOption[];
  subscriptions: Subscription[] = [];
  editedPageData: PageInterface;
  moduleOptions: { key: string; value: string }[];
  displayToolbar = false;
  displayToolbarBackButton = false;
  submitButtonLoading = false;
  selectedModuleId: string;
  currentModuleId: string;
  currentPageSlug: string;
  pages: PageWithRelations[];
  isPageEditFormSubmitted: boolean;
  pageSlugs: string[];
  activeProject: Project;
  previousLayouts: PageInterface[];
  /* categories */
  categories: Category[];

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private pageApi: PageApi,
    private notificationService: NotificationService,
    private router: Router,
    private dataTransformService: DataTransformService,
    private stateService: StateService
    // eslint-disable-next-line no-empty-function
  ) { }

  ngOnInit(): void {
    /* Assign page id */
    this.pageId = this.getPageId();
    /* subscribe To Data */
    this.subscribeToData();
  }

  /**
   * onDestroy
   *
   * @memberof PageEditComponent
   */
  ngOnDestroy(): void {
    /* Unsubscribes all subscriptions */
    this.resetPageEditFormConfigValidators();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   *  Subscribing all Data
   *
   * @memberof PageEditComponent
   */
  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToModules(),
      this.subscribeToPageLastProcessedAction(),
      this.subscribeToLayouts(),
      this.subscribeToSubmitButtonLoading(),
      this.subscribeToPages(),
      this.subscribeToCategories()
    ];
  }

  /**
   * Subscribes to active project from state
   *
   * @returns
   * @memberof PageEditComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe(activeProject => {
      if (activeProject) {
        this.activeProject = activeProject;
        /* title assign */
        this.mainTitle = {
          content: PAGE_DEFINITIONS.PAGES.CHILDREN.PAGES_EDIT.PAGE_TITLE,
          type: defaultToolbarTitleHeadingSize
        };
      }
    });
  }

  subscribeToCategories() {
    return this.store.select(state => state.category?.data).subscribe((categories: Category[]) => {
      this.categories = categories?.filter(category => category.projectId === this.activeProject?.id) ?? [];
      this.setCategoryOptions();
    });
  }

  subscribeToLayouts() {
    return this.store.select(state => state.layout?.data).subscribe((layouts: PageInterface[]) => {
      if (layouts?.length && JSON.stringify(this.previousLayouts) !== JSON.stringify(layouts)) {
        this.setLayoutOptions(layouts);
        this.previousLayouts = layouts;
      }
    });
  }

  setCategoryOptions() {
    const categoryOptions = <CrudFormSelectItem>this.PAGE_EDIT_FORM_CONFIG.items.find(item => item.fieldName === 'categoryIds');

    categoryOptions.options = this.categories?.map(category => ({
      key: category?.title,
      value: category?.id
    })) || [];

    this.PAGE_EDIT_FORM_CONFIG = { ...this.PAGE_EDIT_FORM_CONFIG };
  }

  setLayoutOptions(layouts) {
    const layoutsFormItem = this.PAGE_EDIT_FORM_CONFIG.items.find(
      item => item.fieldName === pageConfigFieldNames.layoutId
    ) as CrudFormSelectItem;
    if (layoutsFormItem) {
      layoutsFormItem.options = layouts?.map((layout: PageInterface) => ({
        key: layout.title,
        value: layout.id
      })) ?? [];
      // trigger change detection
      this.PAGE_EDIT_FORM_CONFIG = { ...this.PAGE_EDIT_FORM_CONFIG };
    }
  }

  /**
   * Takes the page id paramater from route
   *
   * @returns
   * @memberof PageEditComponent
   */
  getPageId() {
    return this.activatedRoute.snapshot.paramMap.get('id');
  }

  /**
   * get page's by id
   *
   * @param {string} pageId
   * @memberof PageEditComponent
   */

  subscribeToModules() {
    return this.store.select(state => state.module?.data).subscribe((modules: Module[]) => {
      if (modules) {
        this.moduleOptions = modules.map(module => ({
          key: module.title,
          value: module.id
        }));
        this.setModuleOptions();
      } else {
        this.moduleOptions = [];
      }
    });
  }

  subscribeToSubmitButtonLoading() {
    return this.store.select(state => state.page.submitButtonLoading).subscribe((loading: boolean) => {
      this.submitButtonLoading = loading;
    });
  }

  subscribeToPages() {
    return this.store.select(state => state.page?.data).subscribe((pages: PageWithRelations[]) => {
      this.pages = cloneDeep(pages);
      if (pages?.length) {
        this.page = this.pages.find(page => page.id === this.pageId);
        if (this.page) {
          this.page = {
            ...this.page,
            categoryIds: this.page?.categories?.map(category => category.id) || []
          };
          delete this.page.categories;
          this.currentModuleId = this.page?.moduleId;
          this.currentPageSlug = this.page?.slug;
          this.title = [
            {
              label: this.activeProject.name,
              redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
            },
            {
              label: PAGE_DEFINITIONS.PAGES.CHILDREN.PAGES_LIST.PAGE_TITLE,
              redirectUrl: PATH_DEFINITIONS.PAGES.PAGE_LIST_PATH
            },
            {
              label: PAGE_DEFINITIONS.PAGES.CHILDREN.PAGES_EDIT.PAGE_TITLE
            },
            {
              label: this.page?.title
            }
          ];
          this.changeModule();
        }
      } else {
        this.page = undefined;
        this.currentModuleId = undefined;
        this.currentPageSlug = undefined;
      }
    });
  }

  changeModule() {
    if (this.selectedModuleId && this.selectedModuleId !== this.currentModuleId) {
      const allPagesByModule = this.pages?.filter(page => page.moduleId === this.selectedModuleId);
      this.pageSlugs = allPagesByModule?.map(page => page?.slug);
    } else if (this.selectedModuleId && this.selectedModuleId === this.currentModuleId) {
      const allPagesByModule = this.pages?.filter(page => page.moduleId === this.selectedModuleId);
      const pagesSlugsWithoutCurrentSlug = allPagesByModule.filter(currentSlug => currentSlug.slug !== this.currentPageSlug);
      this.pageSlugs = pagesSlugsWithoutCurrentSlug?.map(page => page?.slug);
    } else {
      const allPagesByModule = this.pages?.filter(page => page.moduleId === this.currentModuleId);
      const pagesSlugsWithoutCurrentSlug = allPagesByModule?.filter(currentSlug => currentSlug.slug !== this.currentPageSlug);
      this.pageSlugs = pagesSlugsWithoutCurrentSlug?.map(page => page?.slug);
    }
    this.updatePageEditFormConfigValidators();
  }

  setModuleOptions() {
    const moduleFormItem = <CrudFormSelectItem>(
      this.PAGE_EDIT_FORM_CONFIG.items.find(
        item => item.fieldName === pageConfigFieldNames.moduleId
      )
    );
    moduleFormItem.options = this.moduleOptions;
  }

  /**
   * Fire's when save button clicked
   *
   * @param {PageInterface} page
   * @memberof PageEditComponent
   */
  editPage(page: PageInterface) {
    if (this.editedPageData) {
      this.dispatchUpdateAction(
        this.setPath(),
        this.pageId,
      );
    }
  }

  dispatchUpdateAction(editedPageData, pageId: string) {
    this.store.dispatch(new UpdatePage({
      page: editedPageData,
      pageId: pageId
    }));
  }

  setPath() {
    if (this.editedPageData?.slug === null) {
      this.editedPageData.slug = this.matchPathToFormat(this.editedPageData.title);
      return this.editedPageData;
    } else {
      return this.editedPageData;
    }
  }

  matchPathToFormat(path: string) {
    return path?.toLowerCase().replace(/[^\w]+/g, '-');
  }

  onFieldValueChange(data: PageInterface) {
    if (data?.moduleId) {
      this.selectedModuleId = data?.moduleId;
      this.resetPageEditFormConfigValidators();
      this.changeModule();
      this.isPageEditFormSubmitted = false;
    }

    this.editedPageData = {
      ...this.editedPageData,
      ...data
    };
  }

  subscribeToPageLastProcessedAction() {
    return this.store.select(state => state.page.lastProcessedAction).subscribe((lastProcessedAction) => {
      const isLastProcessedActionValid = this.stateService.getBufferedTimestamp(lastProcessedAction.timestamp);
      if (lastProcessedAction.success && (lastProcessedAction.data.id === this.pageId) && isLastProcessedActionValid) {
        this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PAGES.PAGE_DETAIL_PATH}/${this.pageId}` }));
      }
    });
  }

  updatePageEditFormConfigValidators() {
    const pageEditFormConfig = this.PAGE_EDIT_FORM_CONFIG.items.find(item => item.fieldName === 'slug' && item.validators.length !== 2);
    pageEditFormConfig?.validators?.push({
      type: nameExistsValidator(this.pageSlugs),
      errorKey: 'nameExists',
      errorMessage: 'Name Already Exists Page Path'
    });
  }

  resetPageEditFormConfigValidators() {
    this.PAGE_EDIT_FORM_CONFIG.items = this.PAGE_EDIT_FORM_CONFIG.items.map(item => {
      if (item.validators && item.validators.length > 1) {
        item.validators = item.validators.filter(validator => validator.errorKey !== 'nameExists');
      }
      return item;
    });
    this.page.slug = '';
  }

  submitButtonClick(isPageSlugValid) {
    if (this.pageSlugs.includes(isPageSlugValid.slug)) {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'Name Already Exists Page Path'
      );
    }

  }
}
