import { Validators } from '@angular/forms';
import { ButtonType, CrudFormConfig, CrudFormMonacoCodeEditorItem, CrudFormTextBoxItem, CrudViewFormItemType, FormLayout } from '@rappider/rappider-components/utils';
import { PASCAL_CASE_REGEX } from '../../../definitions/regex';

export const WORKFLOW_EVENT_CREATE_OR_EDIT_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormTextBoxItem>{
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewFormItemType.TextBox,
      autoFocus: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          type: Validators.pattern(PASCAL_CASE_REGEX),
          errorKey: 'pattern',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.MUST_BE_PASCAL_CASE'
        }
      ],
      showRequiredSign: true
    },
    <CrudFormMonacoCodeEditorItem>{
      title: 'Workflow Event Input Sample',
      fieldName: 'inputDataSample',
      type: CrudViewFormItemType.MonacoCodeEditor,
      editorOptions: {
        theme: 'vs',
        language: 'json',
        lineNumbers: { renderType: 0 },
        minimap: {
          enabled: false,
        },
      },
      sizeSettings: {
        minHeight: '200px'
      }
    }
  ],
  formLayout: FormLayout.Vertical,
  submitButton: {
    type: ButtonType.Primary
  }
};
