import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[rappiderUnwrapTag]',
})
export class UnwrapTagDirective implements AfterViewInit {

  constructor(private el: ElementRef) { }
  ngAfterViewInit() {
    // get the element's parent node
    const parent = this.el.nativeElement.parentNode;

    // move all children out of the element
    while (this.el.nativeElement.firstChild) {
      parent.insertBefore(this.el.nativeElement.firstChild, this.el.nativeElement);
    }
    // remove the empty element
    parent.removeChild(this.el.nativeElement);
  }

}
