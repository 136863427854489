import { createReducer, on } from '@ngrx/store';
import { PermissionWithRelations } from '@rappider/rappider-sdk';

import * as PermissionActions from './permission.actions';
import { orderBy } from 'lodash';

export const featureKey = 'permissions';

export interface State {
  data: PermissionWithRelations[];
  loading: boolean;
  modalVisibility: boolean;
}

export const initialState: State = {
  data: null,
  loading: false,
  modalVisibility: false
};

export const reducer = createReducer(
  initialState,

  /* get */
  on(PermissionActions.GetPermissions, (state) => ({
    ...state,
    loading: true
  })),

  on(PermissionActions.GetPermissionsSuccessful, (state, action) => ({
    ...state,
    data: orderBy(action.permissions, 'createdDate', 'desc'),
    loading: false
  })),

  on(PermissionActions.GetPermissionsFailure, (state) => ({
    ...state,
    loading: false
  })),

  /* create */
  on(PermissionActions.CreatePermission, (state) => ({
    ...state,
    loading: true
  })),

  on(PermissionActions.CreatePermissionSuccessful, (state, action) => ({
    ...state,
    data: orderBy([
      ...state.data,
      action.permission
    ], 'createdDate', 'desc'),
    loading: false,
    modalVisibility: false
  })),

  on(PermissionActions.CreatePermissionFailure, (state) => ({
    ...state,
    loading: false,
    modalVisibility: false
  })),

  /* update */
  on(PermissionActions.UpdatePermission, (state) => ({
    ...state,
    loading: true
  })),

  on(PermissionActions.UpdatePermissionSuccessful, (state, action) => {
    const updatedPermission = {
      ...state.data.find(permission => permission.id === action.id),
      ...action.permission
    };

    return {
      ...state,
      data: orderBy([
        ...state.data.filter(permission => permission.id !== action.id),
        updatedPermission
      ], 'createdDate', 'desc'),
      loading: false,
      modalVisibility: false
    };
  }),

  on(PermissionActions.UpdatePermissionFailure, (state) => ({
    ...state,
    loading: false,
    modalVisibility: false
  })),

  /* delete */
  on(PermissionActions.DeletePermission, (state) => ({
    ...state,
    loading: true
  })),

  on(PermissionActions.DeletePermissionSuccessful, (state, action) => ({
    ...state,
    data: [
      ...state.data.filter(permission => permission.id !== action.id)
    ],
    loading: false
  })),

  on(PermissionActions.DeletePermissionFailure, (state) => ({
    ...state,
    loading: false
  })),

  /* modal visibility */
  on(PermissionActions.ChangePermissionModalVisibility, (state, action) => ({
    ...state,
    modalVisibility: action.visibility
  })),

);
