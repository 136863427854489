import { DropdownMenuComponentConfig, MenuActionBehavior } from '@rappider/rappider-components/utils';
import { IconType } from '../../definitions/icon-type.enum';

export const PageListDropdownMenuConfig: DropdownMenuComponentConfig = {
  icon: {
    name: 'ellipsis',
    type: IconType.NgZorro
  },
  items: [
    {
      label: 'PAGES_MODULE.PAGE_LIST_COMPONENT.SET_HOME_PAGE',
      key: 'setAsHomepage',
      icon: {
        name: 'home',
        type: IconType.NgZorro
      },
      actionBehavior: MenuActionBehavior.Emit
    },
    {
      label: 'PAGES_MODULE.PAGE_LIST_COMPONENT.DUPLICATE_PAGE',
      key: 'duplicate',
      icon: {
        name: 'fa-regular fa-copy',
        type: IconType.FontAwesome
      }
    },
    {
      label: 'SHARED.EDIT',
      key: 'edit',
      icon: {
        name: 'edit',
        type: IconType.NgZorro
      },
      actionBehavior: MenuActionBehavior.Emit
    },
    {
      label: 'SHARED.DELETE',
      key: 'delete',
      icon: {
        name: 'delete',
        type: IconType.NgZorro
      },
      popconfirmTitle: 'Are you sure you want to delete these records ?',
      popConfirmOkDanger: true,
      actionBehavior: MenuActionBehavior.Emit
    }
  ]
};
