<h1 *ngIf="type === HeadingType.H1 || type === null || type === undefined"
    [innerHTML]="content | translate"
    [style.font-size]="typography?.fontSize"
    [style.font-weight]="typography?.fontWeight"
    [style.font-style]="typography?.fontStyle"
    [style.text-align]="typography?.textAlign"
    [style.text-shadow]="typography?.textShadow"
    [style.font-family]="typography?.fontFamily"
    [style.font-stretch]="typography?.fontStretch"
    [style.text-indent]="typography?.textIndent"
    [style.letter-spacing]="typography?.letterSpacing"
    [style.line-height]="typography?.lineHeight"
    [style.word-spacing]="typography?.wordSpacing"
    [style.text-decoration]="typography?.textDecoration"
    [style.text-transform]="typography?.textTransform"
    [style.user-select]="typography?.userSelect"
    [style.background-color]="colorSettings?.backgroundColor"
    [style.color]="colorSettings?.color"
    [class]="className">
</h1>

<h2 *ngIf="type === HeadingType.H2"
    [innerHTML]="content | translate"
    [style.font-size]="typography?.fontSize"
    [style.font-weight]="typography?.fontWeight"
    [style.font-style]="typography?.fontStyle"
    [style.text-align]="typography?.textAlign"
    [style.text-shadow]="typography?.textShadow"
    [style.font-family]="typography?.fontFamily"
    [style.font-stretch]="typography?.fontStretch"
    [style.text-indent]="typography?.textIndent"
    [style.letter-spacing]="typography?.letterSpacing"
    [style.line-height]="typography?.lineHeight"
    [style.word-spacing]="typography?.wordSpacing"
    [style.text-decoration]="typography?.textDecoration"
    [style.text-transform]="typography?.textTransform"
    [style.user-select]="typography?.userSelect"
    [style.background-color]="colorSettings?.backgroundColor"
    [style.color]="colorSettings?.color"
    [class]="className">
</h2>

<h3 *ngIf="type === HeadingType.H3"
    [innerHTML]="content | translate"
    [style.font-size]="typography?.fontSize"
    [style.font-weight]="typography?.fontWeight"
    [style.font-style]="typography?.fontStyle"
    [style.text-align]="typography?.textAlign"
    [style.text-shadow]="typography?.textShadow"
    [style.font-family]="typography?.fontFamily"
    [style.font-stretch]="typography?.fontStretch"
    [style.text-indent]="typography?.textIndent"
    [style.letter-spacing]="typography?.letterSpacing"
    [style.line-height]="typography?.lineHeight"
    [style.word-spacing]="typography?.wordSpacing"
    [style.text-decoration]="typography?.textDecoration"
    [style.text-transform]="typography?.textTransform"
    [style.user-select]="typography?.userSelect"
    [style.background-color]="colorSettings?.backgroundColor"
    [style.color]="colorSettings?.color"
    [class]="className">
</h3>

<h4 *ngIf="type === HeadingType.H4"
    [innerHTML]="content | translate"
    [style.font-size]="typography?.fontSize"
    [style.font-weight]="typography?.fontWeight"
    [style.font-style]="typography?.fontStyle"
    [style.text-align]="typography?.textAlign"
    [style.text-shadow]="typography?.textShadow"
    [style.font-family]="typography?.fontFamily"
    [style.font-stretch]="typography?.fontStretch"
    [style.text-indent]="typography?.textIndent"
    [style.letter-spacing]="typography?.letterSpacing"
    [style.line-height]="typography?.lineHeight"
    [style.word-spacing]="typography?.wordSpacing"
    [style.text-decoration]="typography?.textDecoration"
    [style.text-transform]="typography?.textTransform"
    [style.user-select]="typography?.userSelect"
    [style.background-color]="colorSettings?.backgroundColor"
    [style.color]="colorSettings?.color"
    [class]="className">
</h4>

<h5 *ngIf="type === HeadingType.H5"
    [innerHTML]="content | translate"
    [style.font-size]="typography?.fontSize"
    [style.font-weight]="typography?.fontWeight"
    [style.font-style]="typography?.fontStyle"
    [style.text-align]="typography?.textAlign"
    [style.text-shadow]="typography?.textShadow"
    [style.font-family]="typography?.fontFamily"
    [style.font-stretch]="typography?.fontStretch"
    [style.text-indent]="typography?.textIndent"
    [style.letter-spacing]="typography?.letterSpacing"
    [style.line-height]="typography?.lineHeight"
    [style.word-spacing]="typography?.wordSpacing"
    [style.text-decoration]="typography?.textDecoration"
    [style.text-transform]="typography?.textTransform"
    [style.user-select]="typography?.userSelect"
    [style.background-color]="colorSettings?.backgroundColor"
    [style.color]="colorSettings?.color"
    [class]="className">
</h5>

<h6 *ngIf="type === HeadingType.H6"
    [innerHTML]="content | translate"
    [style.font-size]="typography?.fontSize"
    [style.font-weight]="typography?.fontWeight"
    [style.font-style]="typography?.fontStyle"
    [style.text-align]="typography?.textAlign"
    [style.text-shadow]="typography?.textShadow"
    [style.font-family]="typography?.fontFamily"
    [style.font-stretch]="typography?.fontStretch"
    [style.text-indent]="typography?.textIndent"
    [style.letter-spacing]="typography?.letterSpacing"
    [style.line-height]="typography?.lineHeight"
    [style.word-spacing]="typography?.wordSpacing"
    [style.text-decoration]="typography?.textDecoration"
    [style.text-transform]="typography?.textTransform"
    [style.user-select]="typography?.userSelect"
    [style.background-color]="colorSettings?.backgroundColor"
    [style.color]="colorSettings?.color"
    [class]="className">
</h6>
