import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import * as ProcessInfoActions from './process-info.actions';
import { ProcessInfoControllerService, ProcessInfoWithRelations } from '@rappider/rappider-sdk';
import { environment } from 'apps/rappider/src/environments/environment';
import { clone } from 'lodash';
import { getActionsToDispatchAccordingToProcessInformation } from './process-info-helper-functions';
import { v4 } from 'uuid';
import { ProcessInfoConclusion } from '../../definitions/process-info';

@Injectable()
export class ProcessInfoEffects {
  activePollingKey = null;

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private processInfoApi: ProcessInfoControllerService
  ) { }

  getProcessInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessInfoActions.GetProcessInfos),
      withLatestFrom(
        this.store.select(state => state.activeProject.data?.id)
      ),
      mergeMap(([action, activeProjectId]) => {
        if (!activeProjectId) {
          return [];
        }
        const params = {
          filter: {
            where: {
              projectId: activeProjectId
            }
          }
        };
        const pollingKey = v4();
        this.activePollingKey = pollingKey;
        return this.processInfoApi.find(params).pipe(
          mergeMap(processInfos => [
            ProcessInfoActions.GetProcessInfosSuccessful({ payload: { processInfos: processInfos } }),
            /* start polling */
            ProcessInfoActions.PollProcessInfo({ pollingKey: pollingKey })
          ]),
          catchError((error) => [
            ProcessInfoActions.Error({ payload: { error } })
          ])
        );
      })
    ));

  pollProcessInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessInfoActions.PollProcessInfo),
      withLatestFrom(
        this.store.select(state => state.activeProject.data?.id),
        this.store.select(state => state.processInfo?.lastPollingDate),
        this.store.select(state => state.processInfo?.data)
      ),
      mergeMap(([action, activeProjectId, lastPollingDate, processInfosInState]) => {
        /* avoid multiple http polling */
        if (!activeProjectId) {
          return [];
        }
        const params = {
          filter: {
            where: {
              and: [
                { projectId: activeProjectId },
                {
                  or: [
                    { updatedDate: { gte: lastPollingDate } },
                    { createdDate: { gte: lastPollingDate } }
                  ]
                }
              ]
            }
          }
        };
        let pollingDelay = 60000;
        return this.processInfoApi.find(params).pipe(
          mergeMap(processInfos => {
            this.store.dispatch(ProcessInfoActions.UpdateLastPollingDateToNow());
            const inProgressProcessInfoExists = [...(processInfosInState || []), ...(processInfos || [])]
              .some(processInfo => processInfo.conclusion === ProcessInfoConclusion.InProgress);
            if (!inProgressProcessInfoExists) {
              /* polling needs to be kill when all processes are done, but this action requires re-dispatch when any backend process appear.
               * Temp Solution: Instead of that set delay up to 10 min when there is no in-progress process.
               */
              pollingDelay = 600000;
            }
            /* only one polling can run at the same time */
            if (action.pollingKey === this.activePollingKey) {
              setTimeout(() => this.store.dispatch(ProcessInfoActions.PollProcessInfo({ pollingKey: action.pollingKey })), pollingDelay);
              return getActionsToDispatchAccordingToProcessInformation(processInfos) || [];
            } else {
              return [];
            }
          }),
          catchError((error) => [
            ProcessInfoActions.Error({ payload: { error } })
          ])
        );
      })
    )
  );

}
