/* angular */
import { createReducer, on } from '@ngrx/store';

/* actions */
import * as PersonAuthorityActions from './person-authority.actions';

/* state key */
export const featureKey = 'personAuthority';

/* state interface */
export interface State {
  data: any[] | null;
  error: any;
  isLoading: boolean;
}

/* initial values */
export const initialState: State = {
  data: null,
  error: null,
  isLoading: false
};

export const reducer = createReducer(
  initialState,
  on(PersonAuthorityActions.GetPersonAuthorities, (state) => ({
    ...state,
    isLoading: true
  })),
  on(PersonAuthorityActions.GetPersonAuthoritiesSuccessful, (state, action) => ({
    ...state,
    data: action.personAuthorities,
    isLoading: false
  })),
  on(PersonAuthorityActions.GetPersonAuthoritiesFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),

  on(PersonAuthorityActions.ResetStateToInitial, () => initialState),

  on(PersonAuthorityActions.ResetStateToInitialChangeProject, () => initialState)
);
