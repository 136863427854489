import { AlertConfig, AlertTypes, HeadingType } from '@rappider/rappider-components/utils';

export const deployErrorAlertConfig: AlertConfig = {
  type: AlertTypes.Error,
  title: {
    content: 'Error',
    type: HeadingType.H4
  },
  description: {
    text: 'There was an error while deploying your project, please try again.'
  },
  showIcon: true,
  closeable: false
};
