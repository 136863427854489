import {
  CrudViewFormItemType,
  CrudFormConfig,
  CrudFormTextBoxItem,
  FormLayout,
  CrudFormButtonItem,
  ActionBehavior,
  ActionView,
  CrudFormSelectItem,
  SelectMode
} from '@rappider/rappider-components/utils';
import { ROW_FORM_KEY_VALUE_COLUMNS } from '../row-form-key-value-config';
import { Validators } from '@angular/forms';

export const CREATE_FILE_FILE_INFORMATIONS_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormTextBoxItem>{
      title: 'File Name',
      fieldName: 'fileName',
      placeholder: 'File Name',
      type: CrudViewFormItemType.TextBox,
      showRequiredSign: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'This field is required.'
        }
      ]
    },
    <CrudFormTextBoxItem>{
      title: 'Title',
      fieldName: 'title',
      placeholder: 'Title',
      type: CrudViewFormItemType.TextBox
    },
    {
      title: 'Size',
      fieldName: 'size',
      type: CrudViewFormItemType.Label
    },
    <CrudFormButtonItem>{
      text: 'Remove this file',
      view: ActionView.Icon,
      name: 'REMOVE_FILE',
      icon: { name: 'far fa-trash' },
      type: CrudViewFormItemType.Button,
      behavior: ActionBehavior.Emit,
      showColonSign: false,
      cssStyle: {
        color: 'red',
        'font-size': '13px'
      }
    }
  ],
  itemSettings: {
    inputComponentSize: {
      xs: 24
    },
    labelComponentSize: {
      xs: 24
    }
  },
  submitButton: {
    visible: false
  },
  formLayout: FormLayout.Vertical
};

export const CREATE_FILE_ROLE_ASSIGNMENTS_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormSelectItem>{
      title: 'Allowed Roles',
      fieldName: 'allowedProjectRoles',
      placeholder: 'Allowed Roles',
      type: CrudViewFormItemType.Select,
      settings: {
        mode: SelectMode.Multiple
      },
      options: [
        {
          key: 'Moderator',
          value: 1
        },
        {
          key: 'Manager',
          value: 2
        }
      ]
    },
    <CrudFormSelectItem>{
      title: 'Restricted Roles',
      fieldName: 'restrictedProjectRoles',
      placeholder: 'Restricted roles',
      type: CrudViewFormItemType.Select,
      settings: {
        mode: SelectMode.Multiple
      },
      options: [
        {
          key: 'Moderator',
          value: 1
        },
        {
          key: 'Manager',
          value: 2
        }
      ]
    }
  ],
  itemSettings: {
    inputComponentSize: {
      xs: 24
    },
    labelComponentSize: {
      xs: 24
    }
  },
  submitButton: {
    visible: false
  },
  formLayout: FormLayout.Vertical
};

export const CREATE_FILE_METADATA_CONFIG = {
  columns: ROW_FORM_KEY_VALUE_COLUMNS,
  orderable: true,
  orderNumbersVisibility: true
};
