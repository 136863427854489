import { KeyValue } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  DataMappingApi,
  DataSchemaApi,
  DataSchemaInterface,
  ProjectInterface,
  RappiderFunctionApi,
  RappiderFunctionInterface,
} from '@rappider/api-sdk';
import { TitleBarComponentConfig } from '@rappider/rappider-components/utils';
import { ApiResult } from '@rappider/models';
import { DataMappingService, NotificationService } from '@rappider/services';
import { DataMapping } from '../../models/data-mapping';
import { UIDataUpdateFunctionGeneratedBy } from '../../models/ui-data-update-function';
import { ASSIGN_FUNCTION } from './config/assign-function';
import { uiDataUpdateFunctionGetWithDetailsFilter } from './config/data-mapping-filter';
import { Subscription } from 'rxjs';
import { defaultToolbarTitleHeadingSize, PATH_DEFINITIONS, QUERY_PARAM_DEFINITIONS } from '@rappider/shared/definitions';
import { UiDataEventWithRelations, UiDataUpdateFunctionControllerService, UiDataUpdateFunctionWithRelations } from '@rappider/rappider-sdk';

@Component({
  selector: 'rappider-create-data-mapping-page',
  templateUrl: './create-data-mapping-page.component.html',
  styleUrls: ['./create-data-mapping-page.component.scss']
})
export class CreateDataMappingPageComponent implements OnInit, OnDestroy {

  uiDataUpdateFunctionId: string;
  targetDataSchema: DataSchemaInterface;
  sourceDataSchema: DataSchemaInterface;
  functions: KeyValue<string, string>[];
  dataMappings: DataMapping[];
  projectId: string;
  uiDataStoreId: string;
  title: TitleBarComponentConfig;
  subscriptions: Subscription[];
  activeProject: ProjectInterface;
  uiDataEvent: UiDataEventWithRelations;

  displayToolbar = false;
  displayToolbarBackButton = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private uiDataUpdateFunctionApi: UiDataUpdateFunctionControllerService,
    private dataSchemaApi: DataSchemaApi,
    private rappiderFunctionApi: RappiderFunctionApi,
    private dataMappingApi: DataMappingApi,
    private notificationService: NotificationService,
    private dataMappingService: DataMappingService,
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.getUIDataUpdateFunctionIdFromUrl();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject()
    ];
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectInterface) => {
      this.activeProject = activeProject;
      if (activeProject) {
        this.setTitle();
      }
    });
  }

  setTitle() {
    this.title = {
      mainTitle: {
        content: `${this.uiDataEvent?.name} Data Mappings`,
        type: defaultToolbarTitleHeadingSize
      },
      options: [
        {
          label: this.activeProject?.name,
          redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject.id}`,
        },
        {
          label: 'PROJECT_MODULE.UI_DATA_STORE_LIST_COMPONENT.UI_DATA_STORE_LIST',
          redirectUrl: PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_LIST
        },
        {
          label: 'PROJECT_MODULE.UI_DATA_EVENT_UPDATE_FUNCTION_LIST_COMPONENT.UI_DATA_EVENT_UPDATE_FUNCTION',
          redirectUrl: `${PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_DETAIL}/${this.uiDataEvent.uiDataStoreId}`,
          queryParams: QUERY_PARAM_DEFINITIONS.PROJECT.UI_DATA_STORE_DETAIL.DATA_EVENT_UPDATE_FUNCTION_TAB
        },
        {
          label: 'PROJECT_MODULE.DATA_MAPPINGS_COMPONENT.ADD_DATA_MAPPING'
        }
      ]
    };
  }

  getUIDataUpdateFunctionIdFromUrl() {
    this.uiDataUpdateFunctionId = this.activatedRoute.snapshot.params['id'];
    if (this.uiDataUpdateFunctionId) {
      this.getDataMappingsByUIDataUpdateFunctionId();
    }
  }

  getDataMappingsByUIDataUpdateFunctionId() {
    const params = {
      id: this.uiDataUpdateFunctionId,
      filter: uiDataUpdateFunctionGetWithDetailsFilter
    };

    this.uiDataUpdateFunctionApi.findById(params).subscribe(
      (uiDataUpdateFunction: UiDataUpdateFunctionWithRelations) => {
        this.uiDataStoreId = uiDataUpdateFunction.uiDataStoreId;
        this.uiDataEvent = uiDataUpdateFunction.event;

        this.subscribeToData();

        const sourceDataSchemaId = uiDataUpdateFunction.event.payload.id;
        const targetDataSchemaId = uiDataUpdateFunction.event.uiDataStore.dataSchemaId;
        this.dataMappings = this.dataMappingService.getDataMappingsFromUIDataUpdateFunction(uiDataUpdateFunction);
        if (!uiDataUpdateFunction.generatedBy) {
          const params = {
            id: uiDataUpdateFunction.id,
            body: {
              ...uiDataUpdateFunction,
              generatedBy: UIDataUpdateFunctionGeneratedBy.Rappider
            }
          };
          this.uiDataUpdateFunctionApi.updateById(params).subscribe();
        }
        this.projectId = uiDataUpdateFunction.event.uiDataStore.projectId;
        /* gets source data schema and its fields (data event payload and its fields) */
        this.dataSchemaApi.getWithDetailsById(sourceDataSchemaId).subscribe((response: ApiResult) => {
          if (response.success) {
            this.sourceDataSchema = response.data;
          }
        });
        /* gets target data schema and its fields (data ui store's schema and its fields) */
        this.dataSchemaApi.getWithDetailsById(targetDataSchemaId).subscribe((response: ApiResult) => {
          if (response.success) {
            this.targetDataSchema = response.data;
          }
        });

        /* gets rappider functions */
        this.rappiderFunctionApi.find().subscribe((rappiderFunctions: RappiderFunctionInterface[]) => {
          if (rappiderFunctions?.length) {
            this.functions = rappiderFunctions.map(rappiderFunction => ({
              key: rappiderFunction.name,
              value: rappiderFunction.id
            }));
          }
        });
      });
  }

  addDataMapping(createdDataMapping: DataMapping) {
    const dataMappingRequestBody = {
      uiDataUpdateFunctionId: this.uiDataUpdateFunctionId,
      projectId: this.projectId,
      dataSchemaFieldIdsForSourceFieldsNodes: createdDataMapping.sourceFields.map(sourceFieldNodes => sourceFieldNodes.map(sourceField => sourceField.id)),
      dataSchemaFieldIdsForTargetFieldNodes: createdDataMapping.targetField.map(targetFieldNode => targetFieldNode.id),
      /* component uses 'value' for assign function. If value is equal 'value' then remove it.  */
      functionId: createdDataMapping.functionId === ASSIGN_FUNCTION.id ? '' : createdDataMapping.functionId,
      customFunction: createdDataMapping.customFunction
    };
    this.dataMappingApi.create(dataMappingRequestBody).subscribe(() => {
      this.notificationService.createNotification(
        'success',
        'PROJECT_MODULE.UI_DATA_EVENT_UPDATE_FUNCTION_CREATE_COMPONENT.DATA_MAPPING',
        'SHARED.SUCCESSFULLY_CREATED'
      );
    }, () => {
      this.notificationService.createNotification(
        'error',
        'PROJECT_MODULE.UI_DATA_EVENT_UPDATE_FUNCTION_CREATE_COMPONENT.DATA_MAPPING',
        'SHARED.COULDNT_CREATED'
      );
    });
  }

  deleteDataMapping(id: string) {
    if (id) {
      this.dataMappingApi.deleteById(id).subscribe(() => {
        this.notificationService.createNotification(
          'success',
          'PROJECT_MODULE.UI_DATA_EVENT_UPDATE_FUNCTION_CREATE_COMPONENT.DATA_MAPPING',
          'SHARED.SUCCESSFULLY_DELETED'
        );
      }, () => {
        this.notificationService.createNotification(
          'error',
          'PROJECT_MODULE.UI_DATA_EVENT_UPDATE_FUNCTION_CREATE_COMPONENT.DATA_MAPPING',
          'SHARED.COULDNT_DELETED'
        );
      });
    }
  }

}
