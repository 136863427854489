<nz-tabset nzType="card"
            nzAnimated="true"
            nzTabPosition="top"
            nzSize="default"
            [nzSelectedIndex]="'0'"
            class="card-container">
    <nz-tab *ngFor="let environments of environments"
                    [nzTitle]="environments"
                    (nzSelect)="setActiveEnvironment(environments)">
        <rappider-list-grid [loading]="loading"
                            [config]="environmentVariablesConfig"
                            [data]="displayedEnvironmentVariables"
                            (columnActionClick)="onColumnActionClick($event)"
                            (listActionClick)="openCreateEnvironmentVariableModal(environments)"></rappider-list-grid>
    </nz-tab>
</nz-tabset>
<rappider-modal [(visible)]="environmentVariableModal.visible"
                [title]="environmentVariableModal.title | translate"
                [okText]="'SHARED.SAVE' | translate"
                [cancelText]="'SHARED.CANCEL' | translate"
                width="40%"
                (okClick)="onEnvironmentVariableModalSubmit()"
                (cancelClick)="closeEnvironmentVariableModal()">
    <rappider-crud-view-edit-form *ngIf="environmentVariableModal.visible"
                                    [config]="createOrEditEnvironmentVariableFormConfig"
                                    [submitted]="environmentVariableModal.isSubmitted"
                                    [data]="environmentVariableModal.data"
                                    (validityChange)="onEnvironmentModalDataValidityChange($event)"
                                    (formValueChange)="onEnvironmentVariableModalDataChange($event)"></rappider-crud-view-edit-form>
<section class="create-environment-variable-info-area">
*{{'PROJECT_MODULE.PROJECT_ENVIRONMENT_VARIABLES_COMPONENT.CREATE_ENVIRONMENT_VARIABLE_INFO' | translate}}
</section>
</rappider-modal>
