import { Injectable } from '@angular/core';
import { COMPONENTS_COLLECTION } from 'libs/components/src/lib/components-collection.definition';


@Injectable({
  providedIn: 'root'
})
/**
 * Maps & returns components
 *
 * @export
 * @class DynamicComponentService
 */
export class DynamicComponentService {


  /**
   * Returns angular component by component name
   *
   * @param {string} componentName
   * @returns
   * @memberof DynamicComponentService
   */
  getComponent(componentName: string) {
    return COMPONENTS_COLLECTION[componentName] || null;
  }

}
