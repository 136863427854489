/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { withLatestFrom, mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { NotificationService, PaginationService } from '@rappider/services';
import { Observable, concat, of } from 'rxjs';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { PageType } from '@rappider/shared/interfaces';
import { NewPage, Page, PageControllerService, PagePartial, PageWithRelations, ProjectSettingControllerService } from '@rappider/rappider-sdk';

import * as PageActions from './page.actions';
import * as ProjectSettingActions from 'libs/project/src/lib/states/project-settings-state/project-setting.actions';
import * as ActiveProjectActions from 'libs/project/src/lib/states/active-project-state/active-project.actions';
import * as ContentEditorActions from 'libs/content-editor/src/lib/state/content-editor.actions';
import { GetModuleByIdSuccessful } from '@rappider/module';
import { getProjectDefaultModuleSelector } from '@rappider/shared';

@Injectable()
export class PageEffects {

  constructor(
    private actions$: Actions,
    private pageApi: PageControllerService,
    private store: Store<any>,
    private notificationService: NotificationService,
    private paginationService: PaginationService,
    private projectSettingApi: ProjectSettingControllerService
  ) { }


  enablePageLoading$ = createEffect(() => this.actions$.pipe(
    ofType<PageActions.GetPages>(
      PageActions.ActionTypes.GetPages,
    ),
    map(() => new PageActions.EnablePageLoading())
  ));


  disablePageLoading$ = createEffect(() => this.actions$.pipe(
    ofType<PageActions.SetPages>(
      PageActions.ActionTypes.SetPages,
    ),
    map(() => new PageActions.DisablePageLoading())
  ));


  getTotalCount$ = createEffect(() => this.actions$.pipe(
    ofType<PageActions.GetTotalCount>(
      PageActions.ActionTypes.GetTotalCount
    ),
    withLatestFrom(
      this.store.select(state => state.modules),
      this.store.select(state => state.page.pagination)
    ),
    mergeMap(([action, modules, pagination]) => {
      /* if active project is not set */
      if (!modules?.length) {
        return [
          new PageActions.GetTotalCountFailure()
        ];
      }
      /* get modules' ids */
      const moduleIds = modules.map(module => module.id);
      /* set where filter */
      const where = {
        moduleId: { inq: moduleIds }
      };
      return this.pageApi.count({ where }).pipe(
        map((res: { count: number }) => new PageActions.SetPagination({
          pagination: {
            totalCount: res.count,
            totalPageNumber: this.paginationService.getTotalPageNumber(res.count, pagination.pageSize)
          }
        })));
    }), catchError(error => [
      new PageActions.PageError({ errorOn: 'GetTotalCount', error: error }),
    ])
  ));

  /**
   * Get Pages
   *
   * @memberof PageEffects
   */

  getPages$ = createEffect(() => this.actions$.pipe(
    ofType<PageActions.GetPages>(
      PageActions.ActionTypes.GetPages
    ),
    mergeMap((action) => {
      const filter = {
        fields: {
          contentTree: false
        },
        where: {
          type: PageType.Page,
        },
        include: [
          {
            relation: 'categories',
            scope: {
              fields: {
                id: true,
                title: true
              }
            }
          }
        ],
        order: ['createdDate DESC'],
      };

      return this.pageApi.find({ filter }).pipe(
        mergeMap((pages: Page[]) => [
          new PageActions.SetPages({ pages: pages })
        ])
      );
    }),
    catchError(error => [
      new PageActions.PageError({ error: error, errorOn: 'GetPagesFailure' })
    ])
  ));

  /**
* Get Page by Page Id
*
* @memberof PageEffects
*/

  getPageById$ = createEffect(() => this.actions$.pipe(
    ofType<PageActions.GetPageById>(
      PageActions.ActionTypes.GetPageById
    ),
    mergeMap((action) => {
      const filter = {
        fields: {
          contentTree: false
        },
        where: {
          type: PageType.Page,
        },
        include: [
          {
            relation: 'categories',
            scope: {
              fields: {
                id: true,
                title: true
              }
            }
          }
        ],
      };

      return this.pageApi.findById({ id: action.payload.pageId, filter: filter }).pipe(
        mergeMap((page: Page) => [
          new PageActions.GetPageByIdSuccessful({ page: page })
        ])
      );
    }),
    catchError(error => [
      new PageActions.PageError({ error: error, errorOn: 'GetPagesFailure' })
    ])
  ));

  /**
  * Get Pages By Module Id
  *
  * @memberof PageEffects
  */

  getPagesByModuleId$ = createEffect(() => this.actions$.pipe(
    ofType(
      GetModuleByIdSuccessful
    ),
    mergeMap((action) => {
      const filter = {
        fields: {
          contentTree: false
        },
        where: {
          type: PageType.Page,
          moduleId: action.payload.module.id
        },
        order: ['createdDate DESC'],
      };

      return this.pageApi.find({ filter }).pipe(
        mergeMap((pages: Page[]) => [
          new PageActions.GetPagesByModuleIdSuccessful({ pages: pages })
        ])
      );
    }),
    catchError(error => [
      new PageActions.PageError({ error: error, errorOn: 'GetPagesByModuleIdFailure' })
    ])
  ));


  setPagination$ = createEffect(() => this.actions$.pipe(
    ofType<PageActions.SetPagination>(
      PageActions.ActionTypes.SetPagination
    ),
    mergeMap(action => {
      if (action.payload.getDataAfter) {
        return of(new PageActions.GetPages());
      } else {
        return [new PageActions.PageError({ error: 'There is no getDataAfter in payload', errorOn: 'GetPagesFailure' })];
      }
    })
  ));

  /**
   * Create Page
   *
   * @memberof PageEffects
   */

  createPage$ = createEffect(() => this.actions$.pipe(
    ofType<PageActions.CreatePage>(
      PageActions.ActionTypes.CreatePage
    ),
    withLatestFrom(
      this.store.select(state => state.auth.activePerson),
    ),
    concatMap(([action, person]) => {
      const body: NewPage = {
        ...action.payload.page,
        type: PageType.Page,
        metadata: action.payload.page.metadata || []
      };

      /* filtering null keywords */
      body.keywords = body.keywords?.filter(item => item) || [];
      return this.pageApi.create({ body }).pipe(
        mergeMap(page => {
          if (page) {
            const result: any[] = [
              of(new PageActions.SetPagination({ pagination: { currentPageNumber: 1 }, getDataAfter: true }))
            ];

            this.notificationService.createNotification(
              'success',
              action.payload.page.title,
              'PAGES_MODULE.NOTIFICATIONS.PAGE_CREATED'
            );
            result.push(of(new Navigate({ url: PATH_DEFINITIONS.PAGES.PAGE_LIST_PATH })));
            return <Observable<any>>concat(...result);
          } else {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'PAGES_MODULE.NOTIFICATIONS.PAGE_COULDNOT_CREATED'
            );
            return [new PageActions.PageError({ error: 'Page could not created', errorOn: 'CreatePageFailure' })];
          }
        }), catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            error.error?.error?.message ? error.error.error.message : error.statusText
          );
          return [new PageActions.PageError({ error, errorOn: 'CreatePageFailure' })];
        })
      );
    })
  ));

  /**
   * Update page
   *
   * @memberof PageEffects
   */

  updatePage$ = createEffect(() => this.actions$.pipe(
    ofType<PageActions.UpdatePage>(
      PageActions.ActionTypes.UpdatePage
    ),
    withLatestFrom(
      this.store.select(state => state.category.data)
    ),
    mergeMap(([action, categories]) => {
      /* get current date */
      const currentDate = new Date();
      /* create page date to update */
      const pageData: PagePartial = action.payload.page;

      return this.pageApi.updateById({ id: action.payload.pageId, body: pageData }).pipe(
        concatMap((page: Page) => {
          if (page) {
            const updatedPage = {
              ...action.payload.page
            } as PageWithRelations;
            if (action.payload.page.categoryIds) {
              updatedPage.categories = categories?.filter(category => action.payload.page?.categoryIds?.includes(category.id));
            }
            delete updatedPage.categoryIds;
            const result: any[] = [
              new PageActions.UpdatePageSuccessful({ page: updatedPage, pageId: action.payload.pageId }),
              new ContentEditorActions.SyncPageDataOptimistic({ pageData: updatedPage }),
              new PageActions.ChangeLastProcessedAction({
                lastProcessedAction: {
                  success: true,
                  action: 'UpdatePage',
                  message: 'Page Successfully Updated',
                  timestamp: currentDate.getTime(),
                  data: page
                }
              })
            ];
            this.notificationService.createNotification(
              'success',
              'SHARED.SUCCESSFUL',
              'PAGES_MODULE.NOTIFICATIONS.PAGE_UPDATED'
            );
            if (action.payload.navigateToPageList !== false) {
              result.push(new Navigate({ url: PATH_DEFINITIONS.PAGES.PAGE_LIST_PATH }));
            }
            return result;
          } else {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'PAGES_MODULE.NOTIFICATIONS.PAGE_COULDNOT_UPDATED'
            );
            return [
              new PageActions.ChangeLastProcessedAction({
                lastProcessedAction: {
                  success: false,
                  action: 'UpdatePage',
                  message: 'Page Couldn\'t Updated',
                  timestamp: currentDate.getTime(),
                  data: { ...action.payload.page, id: action.payload.pageId }
                }
              })
            ];
          }
        }), catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            error.error?.error?.message ? error.error.error.message : error.statusText
          );
          return [new PageActions.PageError({ error, errorOn: 'UpdatePageFailure' })];
        })
      );
    })
  ));


  updateBulkPages$ = createEffect(() => this.actions$.pipe(
    ofType<PageActions.BulkUpdatePages>(
      PageActions.ActionTypes.BulkUpdatePages
    ),
    mergeMap((action) => {
      const bulkUpdatePages = action.payload;
      const formattedPageNames = bulkUpdatePages.pageNames.map(name => `${name} Page`).join('<br>');
      const moduleName = bulkUpdatePages.moduleName;

      return this.pageApi.bulkUpdate({ body: bulkUpdatePages.body }).pipe(
        mergeMap(() => {
          this.notificationService.createNotification(
            'success',
            `Pages were successfully transferred to ${moduleName} Module`,
            formattedPageNames
          );
          return [new PageActions.BulkUpdatePagesSuccessful({ body: bulkUpdatePages.body })];
        }
        ), catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            error.error?.error?.message ? error.error.error.message : error.statusText
          );
          return [new PageActions.PageError({ error, errorOn: 'BulkUpdatePagesFailure' })];
        })
      );
    })
  ));

  deletePage$ = createEffect(() => this.actions$.pipe(
    ofType<PageActions.DeletePage>(PageActions.ActionTypes.DeletePage),
    mergeMap((action) => {
      const pageId = { id: action.payload.pageId };

      return this.pageApi.deleteById(pageId).pipe(
        map(() => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFUL',
            'PAGES_MODULE.NOTIFICATIONS.PAGE_DELETED'
          );
          return new PageActions.DeletePageSuccessful({ pageId: pageId.id });
        }),
        catchError(error => {
          const errorMessage = JSON.parse(error.error);
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            errorMessage?.error?.message || error.statusText
          );
          return [new PageActions.PageError({ error, errorOn: 'DeletePageFailure' })];
        })
      );
    })
  ));


  deleteBulkPages$ = createEffect(() => this.actions$.pipe(
    ofType<PageActions.BulkDeletePages>(PageActions.ActionTypes.BulkDeletePages),
    mergeMap((action) => {
      const pageIds = action.payload.pageIds;
      const pageNames = action.payload.pageNames;
      const formattedPageNames = pageNames.map(name => `${name} Page`).join('<br>');

      return this.pageApi.bulkDelete({ pageIds: pageIds }).pipe(
        map(() => {
          this.notificationService.createNotification(
            'success',
            'PAGES_MODULE.NOTIFICATIONS.PAGES_DELETED',
            formattedPageNames
          );
          return new PageActions.BulkDeletePagesSuccessful({ pageIds: pageIds });
        }),
        catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            error.error?.error?.message ? error.error.error.message : error.statusText
          );
          return [new PageActions.PageError({ error, errorOn: 'BulkDeletePagesFailure' })];
        })
      );
    })
  ));


  setHomePage$ = createEffect(() => this.actions$.pipe(
    ofType<PageActions.SetHomePage>(PageActions.ActionTypes.SetHomePage),
    mergeMap((action) => this.pageApi.setPageAsHomePage({ pageId: action.payload.pageId }).pipe(
      mergeMap(() => {
        this.notificationService.createNotification(
          'success',
          'SHARED.SUCCESSFUL',
          'PAGES_MODULE.NOTIFICATIONS.HOME_PAGE_UPDATED'
        );
        // get project settings for homePageId
        return [
          new PageActions.GetPages(),
          new ProjectSettingActions.GetProjectSettings()
        ];
      })
    )),
    catchError(error => {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'PAGES_MODULE.NOTIFICATIONS.HOME_PAGE_COULDNOT_UPDATED'
      );
      return [
        new PageActions.PageError({ errorOn: 'SetHomePage', error: error }),
      ];
    })
  ));


  duplicatePage$ = createEffect(() => this.actions$.pipe(
    ofType<PageActions.DuplicatePage>(PageActions.ActionTypes.DuplicatePage),
    mergeMap((action) => {

      /* set the parameters for copy page api */
      const params = {
        body: {
          sourcePageId: action.payload.pageId,
          targetModuleId: action.payload.moduleId,
          options: {
            overridePageFields: {
              title: action.payload.title || '',
              description: action.payload.description || ''
            }
          }
        }
      };

      /* create the duplicated page with the api */
      return this.pageApi.copyPage(params).pipe(
        map(() => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFUL',
            'PAGES_MODULE.NOTIFICATIONS.PAGE_DUPLICATED'
          );
          return new PageActions.GetPages();
        })
      );

    }),
    catchError(error => {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'PAGES_MODULE.NOTIFICATIONS.PAGE_COULDNOT_DUPLICATED'
      );
      return [
        new PageActions.PageError({ errorOn: 'DuplicatePage', error: error })
      ];
    })
  ));


  initPageTemplates$ = createEffect(() => this.actions$.pipe(
    ofType<ActiveProjectActions.SetActiveProject>(
      ActiveProjectActions.ActionTypes.SetActiveProject
    ),
    map(() => new PageActions.GetTemplatePagesPagination({ pageIndex: 1, pageSize: 6, searchText: '' }))
  ));


  getPageTemplatesCount$ = createEffect(() => this.actions$.pipe(
    ofType<PageActions.GetTemplatePagesPagination>(
      PageActions.ActionTypes.GetTemplatePagesPagination
    ),
    mergeMap((action) => {
      const params = {
        filter: {
          fields: {
            id: true,
            tags: true
          }
        }
      } as any;
      return this.pageApi.findTemplates(this.handleGetTemplatePagesFilter(params.filter, action.payload.searchText, action.payload.tags, true)).pipe(
        map(templatePages => {
          const templatePagesTags = templatePages.reduce((acc, page) => {
            if (page.tags) {
              acc.push(...page.tags);
            }
            return acc;
          }, []);
          return new PageActions.GetTemplatePagesPaginationSuccessful({ pageIndex: action.payload.pageIndex, pageSize: action.payload.pageSize, searchText: action.payload.searchText, count: templatePages.length, templatePagesTags, tags: action.payload.tags, isDynamicPagination: true });
        }),
        catchError(error => [
          new PageActions.PageError({ error, errorOn: 'GetTemplatePagesFailure' }),
        ])
      );
    })
  ));


  getPageTemplates$ = createEffect(() => this.actions$.pipe(
    ofType<PageActions.GetTemplatePagesPaginationSuccessful | PageActions.GetTemplatePages>(
      PageActions.ActionTypes.GetTemplatePagesPaginationSuccessful,
      PageActions.ActionTypes.GetTemplatePages
    ),
    mergeMap((action) => {
      const params = {
        filter: {
          fields: {
            id: true,
            name: true,
            title: true,
            decsription: true,
            tags: true,
            thumbnailImageUrl: true,
            createdDate: true,
            createdBy: true,
            contentTree: false
          },
          order: ['createdDate ASC']
        }
      } as any;
      if (action.payload.isDynamicPagination) {
        params.filter = {
          ...params.filter,
          skip: this.paginationService.
            getSkippedSizeByPagination(action.payload.pageIndex, action.payload.pageSize),
          limit: action.payload.pageSize
        };
      }
      return this.pageApi.findTemplates(this.handleGetTemplatePagesFilter(params.filter, action.payload.searchText, action.payload.tags, action.payload.isDynamicPagination)).pipe(
        map(templatePages => new PageActions.GetTemplatePagesSuccessful({ templatePages })
        ),
        catchError(error => [
          new PageActions.PageError({ error, errorOn: 'GetTemplatePagesFailure' }),
        ])
      );
    })
  ));

  changeDefaultModule$ = createEffect(() => this.actions$.pipe(
    ofType<PageActions.ChangeDefaultModule>(
      PageActions.ActionTypes.ChangeDefaultModule
    ),
    withLatestFrom(
      this.store.select(<any>getProjectDefaultModuleSelector)
    ),
    mergeMap(([action, projectSettingDefaultModule]) => {
      const projectSettingData = {
        key: 'defaultModuleId',
        value: action.payload.moduleId
      };
      return this.projectSettingApi.updateById({ id: projectSettingDefaultModule.id, body: projectSettingData }).pipe(
        mergeMap(projectSetting => {
          if (projectSetting) {
            this.notificationService.createNotification(
              'success',
              'SHARED.SUCCESSFUL',
              'MODULE_MODULE.DEFAULT_MODULE_CHANGED'
            );
            return [
              new ProjectSettingActions.UpdateProjectSettingSuccessful({ projectSetting: projectSetting, projectSettingId: projectSetting.id }),
            ];
          } else {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'MODULE_MODULE.DEFAULT_MODULE_COULDNOT_CHANGED'
            );
            return [
              new ProjectSettingActions.UpdateProjectSettingFailure({ error: 'Default Module could not be changed', key: 'defaultModuleId', timestamp: new Date().getTime() })
            ];
          }
        }
        )
      );
    }
    ), catchError(error => [
      new ProjectSettingActions.ProjectSettingError({ errorOn: 'ChangeDefaultModule', error: error }),
    ])
  ));

  updatePageWithLifecycleActions$ = createEffect(() => this.actions$.pipe(
    ofType<PageActions.UpdatePageWithLifecycleActions>(
      PageActions.ActionTypes.UpdatePageWithLifecycleActions
    ),
    mergeMap((action) => this.pageApi.updateById({ id: action.payload.pageId, body: action.payload.page }).pipe(
      map((page) => new PageActions.UpdatePageWithLifecycleActionsSuccessful({ pageId: action.payload.pageId, page }))
    )), catchError(error => [new PageActions.PageError({ error, errorOn: 'UpdatePageWithLifecycleActions' })])
  ));

  private handleGetTemplatePagesFilter(filter, searchText, tags, isDynamicPagination) {
    const params = {
      filter: filter
    } as any;

    if (searchText && searchText !== '' && isDynamicPagination) {
      params.filter.where = {
        ...params.filter.where,
        name: { regexp: `/${searchText}/i` }
      };
    }
    if (tags?.length && isDynamicPagination) {
      params.filter.where = {
        ...params.filter.where,
        tags: { inq: tags }
      };
    }
    return params;
  }
}
