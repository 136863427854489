<rappider-spin [spinning]="npmPackageLoading">
  <rappider-title-toolbar [mainTitle]="mainTitle"
                          [options]="title"
                          [displayToolbar]="displayToolbar"
                          [displayToolbarBackButton]="displayToolbarBackButton">
  </rappider-title-toolbar>

  <section>

    <rappider-crud-view-edit-form [config]="PROJECT_PACKAGE_EDIT_CONFIG"
                                  [data]="projectPackage"
                                  [submitButtonLoading]="loading"
                                  (formSubmit)="onProjectPackageUpdate($event)"></rappider-crud-view-edit-form>
  </section>
</rappider-spin>