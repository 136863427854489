<img nz-image
     [nzSrc]="source"
     [alt]="alternateText"
     [nzPlaceholder]="placeholder"
     [nzFallback]="fallback"
     [ngStyle]="borderStyles"
     [nzDisablePreview]="disablePreview"
     [style.border-top]="borderSettings?.borderTop + ' ' + borderSettings?.borderStyle"
     [style.border-right]="borderSettings?.borderRight + ' ' + borderSettings?.borderStyle"
     [style.border-bottom]="borderSettings?.borderBottom + ' ' + borderSettings?.borderStyle"
     [style.border-left]="borderSettings?.borderLeft + ' ' + borderSettings?.borderStyle"
     [style.border-radius]="borderSettings?.borderRadius"
     [style.border-top-left-radius]="borderSettings?.borderTopLeftRadius"
     [style.border-top-right-radius]="borderSettings?.borderTopRightRadius"
     [style.border-bottom-left-radius]="borderSettings?.borderBottomLeftRadius"
     [style.border-bottom-right-radius]="borderSettings?.borderBottomRightRadius"
     [style.border-color]="borderSettings?.borderColor"
     [style.border-style]="borderSettings?.borderStyle"
     [style.width]="customSizeSettings?.width"
     [style.height]="customSizeSettings?.height"
     [style.min-width]="customSizeSettings?.minWidth"
     [style.min-height]="customSizeSettings?.minHeight"
     [style.max-width]="customSizeSettings?.maxWidth || '100%'"
     [style.max-height]="customSizeSettings?.maxHeight"
     [style.boxShadow]="shadowSettings?.boxShadow"
     [style.padding]="paddingSettings?.all"
     [style.margin]="marginSettings?.all">