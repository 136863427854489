import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OpenApiControllerService, OpenApiEndpointControllerService } from '@rappider/rappider-sdk';
import { NotificationService } from '@rappider/services';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';


import * as OpenAPIActions from './openapi.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

@Injectable()
export class OpenAPIEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private notificationService: NotificationService,
    private openApiApi: OpenApiControllerService,
    private openApiEndpointApi: OpenApiEndpointControllerService
  ) { }

  getOpenApisWithRelations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OpenAPIActions.GetOpenAPIsWithRelations
      ),
      withLatestFrom(
        this.store.select(state => state.activeProject.data?.id)
      ),
      mergeMap(([action, activeProjectId]) => {
        if (activeProjectId) {
          const filter = {
            where: {
              projectId: activeProjectId
            },
            include: ['endpoints']
          };

          return this.openApiApi.find({ filter }).pipe(
            map((openApiData) => OpenAPIActions.GetOpenAPIsWithRelationsSuccessful({ payload: { openApiData } })), catchError(error => {
              this.notificationService.createNotification(
                'error',
                'SHARED.ERROR',
                'SHARED.COULDNT_LOAD'
              );
              return [
                OpenAPIActions.ErrorAction({ payload: { error, key: 'GetOpenAPIs', timeStamp: Date.now() } })
              ];
            })
          );
        } else {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'SHARED.COULDNT_LOAD'
          );
        }
      })
    )
  );

  createOpenApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OpenAPIActions.CreateOpenAPI
      ),
      mergeMap((action) => {
        const params = {
          body: action.payload.createOpenApiData
        };

        return this.openApiApi.create(params).pipe(
          map((openApi) => {
            this.notificationService.createNotification(
              'success',
              'SHARED.SUCCESS',
              'SHARED.SUCCESSFULLY_CREATED'
            );
            return OpenAPIActions.CreateOpenAPISuccessful({ payload: { openApi } });
          }), catchError(error => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'SHARED.COULDNT_CREATED'
            );
            return [
              OpenAPIActions.ErrorAction({ payload: { error, key: 'CreateOpenAPIs', timeStamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

  createOpenApiSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OpenAPIActions.CreateOpenAPISuccessful
      ),
      map(() => OpenAPIActions.GetOpenAPIsWithRelations())
    )
  );

  deleteOpenApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OpenAPIActions.DeleteOpenAPI
      ),
      withLatestFrom(
        this.store.select(state => state.openAPI?.data)
      ),
      mergeMap(([action, openAPIs]) => {
        const params = { id: action.payload.openApiId };

        return this.openApiApi.deleteById(params).pipe(
          map(() => {
            const filteredOpenApiData = openAPIs.filter(openApi => openApi.id !== action.payload.openApiId);
            console.log(filteredOpenApiData);

            this.notificationService.createNotification(
              'success',
              'SHARED.SUCCESS',
              'SHARED.SUCCESSFULLY_DELETED'
            );
            return OpenAPIActions.DeleteOpenAPISuccessful({ payload: { openApiData: filteredOpenApiData } });
          }), catchError(error => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'SHARED.COULDNT_DELETED'
            );
            return [
              OpenAPIActions.ErrorAction({ payload: { error, key: 'DeleteOpenAPIs', timeStamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

  /* TODO: we can use old actions inside new reducers. This effects needs to be removed. */
  /* Old actions can not listen on new reducers. So we use this effect temp. */
  resetStateToInitial$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthenticationActions.Logout>(AuthenticationActions.ActionTypes.Logout),
      mergeMap(() => [
        OpenAPIActions.ResetStateToInitial()
      ])
    )
  );

  resetStateToInitialChangeProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthenticationActions.ChangeActiveProjectSuccessful>(AuthenticationActions.ActionTypes.ChangeActiveProjectSuccessful),
      mergeMap(action => [
        OpenAPIActions.ResetStateToInitial()
      ])
    )
  );
}
