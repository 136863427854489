import { Validators } from '@angular/forms';
import { ButtonType, CrudFormConfig, CrudFormInputTemplateItem, CrudFormSelectItem, CrudFormTextAreaItem, CrudViewFormItemType, FormLayout } from '@rappider/rappider-components/utils';
import { DATA_FIELD_DEFAULT_FNS } from '../../definitions/model-data-field/model-data-field-default-fns';
import { DATA_FIELD_TYPES } from '../../definitions/model-data-field/model-data-field-types';
import { DATA_FIELD_NAME_REGEX } from '../../definitions/regex';
import { MONACO_MARKDOWN_CONFIG } from '../monaco-markdown-config';

export const PROJECT_MODEL_DATA_FIELD_CREATE_OR_EDIT_CONFIG: CrudFormConfig = {
  items: [
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewFormItemType.TextBox,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          type: Validators.pattern(DATA_FIELD_NAME_REGEX),
          errorKey: 'pattern',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.MUST_START_WITH_LOWERCASE_LETTER'
        }
      ],
      showRequiredSign: true
    },
    <CrudFormSelectItem>{
      title: 'SHARED.TYPE',
      fieldName: 'type',
      type: CrudViewFormItemType.Select,
      settings: {
        searchable: true
      },
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ],
      options: DATA_FIELD_TYPES,
      showRequiredSign: true
    },
    {
      title: 'SHARED.IS_ARRAY',
      fieldName: 'isArray',
      type: CrudViewFormItemType.CheckBox,
    },
    <CrudFormInputTemplateItem>{
      title: 'SHARED.DEFAULT',
      fieldName: 'default',
      type: CrudViewFormItemType.InputTemplate,
      showCodemirrorForObjectAndArray: true
    },
    <CrudFormSelectItem>{
      title: 'PROJECT_MODULE.PROJECT_MODEL_DATA_FIELD_CREATE_COMPONENT.DEFAULT_FN',
      fieldName: 'defaultFn',
      type: CrudViewFormItemType.Select,
      options: DATA_FIELD_DEFAULT_FNS
    },
    {
      title: 'SHARED.REQUIRED',
      fieldName: 'isRequired',
      type: CrudViewFormItemType.CheckBox
    },
    {
      title: 'SHARED.HIDDEN',
      fieldName: 'isHidden',
      type: CrudViewFormItemType.CheckBox
    },
    {
      title: 'PROJECT_MODULE.PROJECT_MODEL_DATA_FIELD_CREATE_COMPONENT.UNIQUE',
      fieldName: 'isUnique',
      type: CrudViewFormItemType.CheckBox
    },
    {
      title: 'PROJECT_MODULE.PROJECT_MODEL_LIST_COMPONENT.IS_NULLABLE',
      fieldName: 'isNullable',
      type: CrudViewFormItemType.CheckBox,
      default: false
    },
    MONACO_MARKDOWN_CONFIG
  ],
  submitButton: {
    type: ButtonType.Primary
  },
  formLayout: FormLayout.Vertical
};
