<section *ngIf="!title">
  <nz-skeleton [nzActive]="true"
               [nzLoading]="!title"
               [nzTitle]="false"
               [nzParagraph]="{ rows: 2 }">
  </nz-skeleton>
</section>
<rappider-title-toolbar *ngIf="title"
                        [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>

<div class="detail-page">
  <section>

    <!-- Description -->
    <section *ngIf="uiDataStore?.description"
             class="section">
      {{ uiDataStore?.description }}
    </section>
    <!-- END OF Description -->

    <!-- Data Store Tabs -->
    <nz-tabset nzLinkRouter
               nzType="card"
               nzAnimated="true"
               nzTabPosition="top"
               nzSize="default"
               class="card-container">
      <nz-tab>
        <a *nzTabLink
           class="tab-link"
           nz-tab-link
           [routerLink]="[]"
           [queryParams]="QUERY_PARAM_DEFINITIONS.PROJECT.UI_DATA_STORE_DETAIL.FIELDS_TAB"
           queryParamsHandling="merge">
          {{ 'PROJECT_MODULE.UI_DATA_STORE_FIELD_LIST_COMPONENT.FIELDS'| translate }}
        </a>
        <rappider-ui-data-store-field-list [uiDataStoreFields]="uiDataStore?.schema?.fields"
                                           [uiDataStore]="uiDataStore"
                                           [activeProjectId]="activeProject?.id"
                                           [loading]="!dataSchemaLoading || isFieldsLoading"
                                           [parentDataSchemaId]="uiDataStore?.schema?.id">
        </rappider-ui-data-store-field-list>

        <nz-collapse *ngIf="notCreatedFieldsVisible">
          <nz-collapse-panel [nzHeader]="'These fields could not be created.'"
                             [nzExtra]="closeNotCreatedFields">
            <rappider-list-grid [config]="UI_DATA_STORE_DETAIL_LIST_CREATE_CONFIG"
                                [data]="notCreatedFields"></rappider-list-grid>
            <ng-template #closeNotCreatedFields>
              <rappider-button [icon]="closeButton.icon"
                               [size]="closeButton.size"
                               (click)="closeNotCreatedfields()"></rappider-button>
            </ng-template>
          </nz-collapse-panel>
        </nz-collapse>

        <nz-collapse *ngIf="notUpdatedFieldsVisible">
          <nz-collapse-panel [nzHeader]="'These fields could not be updated.'"
                             [nzExtra]="closeNotUpdatedFields">
            <rappider-list-grid [config]="UI_DATA_STORE_DETAIL_LIST_UPDATE_CONFIG"
                                [data]="notUpdatedFields"></rappider-list-grid>
            <ng-template #closeNotUpdatedFields>
              <rappider-button [icon]="closeButton.icon"
                               [size]="closeButton.size"
                               (click)="closeNotUpdatedfields()"></rappider-button>
            </ng-template>
          </nz-collapse-panel>
        </nz-collapse>

      </nz-tab>

      <nz-tab>
        <a *nzTabLink
           class="tab-link"
           nz-tab-link
           [routerLink]="[]"
           [queryParams]="QUERY_PARAM_DEFINITIONS.PROJECT.UI_DATA_STORE_DETAIL.EVENTS_TAB"
           queryParamsHandling="merge">
          {{ 'PROJECT_MODULE.DATA_EVENT_CREATE_COMPONENT.EVENTS' | translate }}
        </a>
        <rappider-data-event-list [uiDataEvents]="uiDataEvents"
                                  [config]="DATA_EVENT_LIST_CONFIG"
                                  [loadingState]="uiDataEventsLoading"
                                  (deleteEvent)="onUIDataEventDelete($event)"
                                  (editEvent)="onUIDataEventEdit($event)"
                                  (createEvent)="navigateCreateDataEventPage(uiDataStoreId)">
        </rappider-data-event-list>
      </nz-tab>

      <nz-tab>
        <a *nzTabLink
           class="tab-link"
           nz-tab-link
           [routerLink]="[]"
           [queryParams]="QUERY_PARAM_DEFINITIONS.PROJECT.UI_DATA_STORE_DETAIL.DATA_EVENT_UPDATE_FUNCTION_TAB"
           queryParamsHandling="merge">
          {{ 'PROJECT_MODULE.UI_DATA_EVENT_UPDATE_FUNCTION_LIST_COMPONENT.UI_DATA_EVENT_UPDATE_FUNCTION' | translate }}
        </a>
        <rappider-spin [spinning]="uiDataStoresLoading">
          <rappider-ui-data-update-function-list [uiDataStoreId]="uiDataStoreId"
                                                 [listConfig]="UI_DATA_UPDATE_FUNCTION_LIST_CONFIG">
          </rappider-ui-data-update-function-list>
        </rappider-spin>
      </nz-tab>

      <nz-tab>
        <a *nzTabLink
           class="tab-link"
           nz-tab-link
           [routerLink]="[]"
           [queryParams]="QUERY_PARAM_DEFINITIONS.PROJECT.UI_DATA_STORE_DETAIL.UI_STEP_FUNCTIONS_TAB"
           queryParamsHandling="merge">
          {{ 'PROJECT_MODULE.UI_STEP_FUNCTION_LIST_COMPONENT.UI_STEP_FUNCTIONS' | translate }}
        </a>
        <rappider-spin [spinning]="uiStepFunctionsLoading || uiDataStoresLoading">
          <rappider-ui-step-function-list [uiDataStore]="uiDataStore"
                                          [listConfig]="UI_STEP_FUNCTION_LIST_CONFIG"></rappider-ui-step-function-list>
        </rappider-spin>
      </nz-tab>
    </nz-tabset>
    <!-- END OF Data Store Tabs -->

  </section>
</div>