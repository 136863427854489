import { Validators } from '@angular/forms';
import { CrudFormConfig, CrudViewFormItemType } from '@rappider/rappider-components/utils';
import { MONACO_MARKDOWN_CONFIG } from '@rappider/shared/configs';

export const PROJECT_THEME_EDIT_FORM_CONFIG: CrudFormConfig = {
  items: [
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewFormItemType.TextBox,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    MONACO_MARKDOWN_CONFIG
  ]
};


