
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CrudFormConfig, CrudFormItem, CrudViewFormItemType } from '../../utils/edit-form';
import { orderBy, groupBy } from 'lodash';
import { CrudFormSection } from '../../utils/edit-form/crud-form-section.interface';

@Component({
  selector: 'rappider-crud-fields-display',
  templateUrl: './crud-fields-display.component.html',
  styleUrls: ['./crud-fields-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RappiderCrudFieldsDisplayComponent implements OnInit {
  @Input() data: any | undefined;
  @Input() config: CrudFormConfig | undefined;
  /* Displayer is on edit mode, when the editMode is true, elements will show inputs */
  @Input() editMode?: boolean = false;

  @Output() formSubmit = new EventEmitter<any>();
  @Output() formCancel = new EventEmitter<boolean>();

  /* Form items grouped by sections */
  groupedItems;
  /* names of the sections */
  itemSections: CrudFormSection[];

  /* columns to display as default */
  defaultColumns = 2;

  CrudViewFormItemType = CrudViewFormItemType;

  itemsDoNotShowAsLabel = [
    CrudViewFormItemType.Table,
    CrudViewFormItemType.CheckBox,
    CrudViewFormItemType.MarkDown,
  ];

  ngOnInit(): void {
    this.prepareConfig();
  }


  prepareConfig() {
    if (this.config?.items) {
      this.config.items = orderBy(this.config?.items, 'index', 'asc');
      if (this.config.items.some(item => item.section)) {
        this.config?.items.map(item => <CrudFormItem>{ ...item, section: item.section || 'Other' });
        this.groupedItems = groupBy(this.config?.items, 'section');
        if (this.config?.sections?.length) {
          this.itemSections = this.config.sections;
        } else {
          this.itemSections = Object.keys(this.groupedItems).filter(i => i).map(sectionName =>
            <CrudFormSection>{ name: sectionName, columns: this.defaultColumns });
        }
      } else {
        if (this.config?.sections?.length) {
          this.itemSections = this.config.sections;
        } else {
          this.itemSections = [<CrudFormSection>{ name: '', columns: this.defaultColumns }];
        }
        this.groupedItems = groupBy(this.config?.items, 'section');
      }
    }
  }

  getItemSize(itemSection: CrudFormSection) {
    return itemSection?.columns ? `calc(${100 / itemSection?.columns}% - 10px)` : `calc(${100 / this.defaultColumns}% - 10px)`;
  }

  onFieldValueChange(event: any) {
    console.log('Field value changed:', event);
  }

  onFormSubmit(event: any) {
    console.log('Form submitted', event);
    this.formSubmit.emit(event);
  }

  onFormCancel() {
    console.log('Form cancelled');
    this.formCancel.emit(true);
  }
}
