<div class="card-six-container">
  <nz-card [nzCover]="coverTemplate">

    <div *ngIf="title"
         class="card-six-title">
      <rappider-heading [content]="title.content"
                        [type]="title.type"></rappider-heading>
    </div>

    <div *ngIf="description"
         class="description-area">
      <rappider-text [content]="description.content"
                     [textMode]="description.textMode"
                     [text]="description.text"
                     [typography]="description.typography"
                     [colorSettings]="description.colorSettings"></rappider-text>
    </div>

    <div class="date-container">
      <div *ngIf="button"
           class="card-six-button">
        <rappider-button [type]="button.type"
                         [shape]="button.shape"
                         [text]="button.text"
                         [size]="button.size"
                         [transparent]="button.transparent"
                         [loading]="button.loading"
                         [block]="button.block"
                         [disabled]="button.disabled"
                         [colorType]="button.colorType"
                         [formButtonType]="button?.formButtonType"
                         [borderSettings]="button?.borderSettings"
                         [customSizeSettings]="button?.customSizeSettings"
                         [customColorSettings]="button?.customColorSettings"
                         [shadowSettings]="button?.shadowSettings"
                         [paddingSettings]="button?.paddingSettings"
                         [marginSettings]="button?.marginSettings"
                         [icon]="button.icon"></rappider-button>
      </div>
    </div>

    <div *ngIf="avatar"
         class="avatar-container">
      <rappider-avatar [text]="avatar.text"
                       [iconName]="avatar.iconName"
                       [shape]="avatar.shape"
                       [imageUrl]="avatar.imageUrl"
                       [altText]="avatar.altText"
                       [cssStyle]="avatar.cssStyle"
                       [size]="avatar.size"
                       [borderSettings]="avatar.borderSettings"
                       [sizeSettings]="avatar.sizeSettings"
                       [colorSettings]="avatar.colorSettings"
                       [boxShadowSettings]="avatar.boxShadowSettings"
                       [paddingSettings]="avatar.paddingSettings"
                       [marginSettings]="avatar.marginSettings"
                       [description]="avatar.description"></rappider-avatar>
      <div *ngIf="authorName"
           class="author-name">
        <rappider-text [content]="authorName.content"
                       [textMode]="authorName.textMode"
                       [text]="authorName.text"
                       [typography]="authorName.typography"
                       [colorSettings]="authorName.colorSettings"></rappider-text>
      </div>
    </div>

    <ng-template #coverTemplate>
      <rappider-image *ngIf="image"
                      [source]="image?.source"
                      [alternateText]="image?.alternateText"
                      [width]="image?.width"
                      [placeholder]="image?.placeholder"
                      [height]="image?.height"
                      [fallback]="image?.fallback"
                      [borderSettings]="image?.borderSettings"
                      [customSizeSettings]="image?.width"
                      [shadowSettings]="image?.shadowSettings"
                      [marginSettings]="image?.marginSettings"
                      [paddingSettings]="image?.paddingSettings"></rappider-image>
    </ng-template>

  </nz-card>
</div>
