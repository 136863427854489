import { createSelector } from '@ngrx/store';
import { UiDataStoreWithRelations, UiWorkflowStepFunctionWithRelations } from '@rappider/rappider-sdk';
export const uiStepFunctionListDataSelector = createSelector(
  state => state['uiDataStore']?.data,
  state => state['uiWorkflowStepFunction']?.data,
  (uiDataStores: UiDataStoreWithRelations[], uiStepFunctions: UiWorkflowStepFunctionWithRelations[]) => {
    const uiStepFunctionListData = uiDataStores?.map(uiDataStore => ({
      uiDataStore: uiDataStore,
      uiStepFunctions: uiStepFunctions?.filter(uiStepFunction => uiDataStore.id === uiStepFunction.uiDataStoreId).map(uiStepFunction => ({
        ...uiStepFunction,
        subscribedEvents: uiStepFunction.subscribedEvents?.map(subscribedEvent => subscribedEvent.name).join(', '),
        publishedEventsOnSuccess: uiStepFunction.publishedEventsOnSuccess?.map(event => event.name).join(', '),
        publishedEventsOnFailure: uiStepFunction.publishedEventsOnFailure?.map(event => event.name).join(', ')

      }))
    }));
    return {
      uiStepFunctionListData: uiStepFunctionListData,
      uiStepFunctions: uiStepFunctions
    };
  }
);
