import {
  ActionBehavior,
  CrudTableViewConfig,
  CrudViewColumnType,
  ButtonType
} from '@rappider/rappider-components/utils';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { POP_CONFIRM_DELETE_ACTION } from '../../pop-confirm-button/pop-confirm-button-config';

export const WORKFLOW_STEP_FUNCTION_LIST_CONFIG: CrudTableViewConfig = {
  columns: [
    {
      title: 'SHARED.ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewColumnType.Link,
      routerLink: `${PATH_DEFINITIONS.WORKFLOW_STEP_FUNCTION.WORKFLOW_STEP_FUNCTION_DETAIL}/{{id}}`
    },
    {
      title: 'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_LIST_COMPONENT.SUBSCRIBED_EVENTS',
      fieldName: 'subscribedEvents',
      type: CrudViewColumnType.Text
    },
    {
      title: 'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_LIST_COMPONENT.PUBLISHED_EVENTS_ON_SUCCESS',
      fieldName: 'publishedEventsOnSuccess',
      type: CrudViewColumnType.Text
    },
    {
      title: 'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_LIST_COMPONENT.PUBLISHED_EVENTS_ON_FAILURE',
      fieldName: 'publishedEventsOnFailure',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Custom Function',
      fieldName: 'customFunction.functionName',
      type: CrudViewColumnType.Text
    },
  ],
  itemActions: [
    {
      text: 'SHARED.EDIT',
      name: 'editStepFunction',
      behavior: ActionBehavior.Route,
      redirectUrl: `${PATH_DEFINITIONS.WORKFLOW_STEP_FUNCTION.WORKFLOW_STEP_FUNCTION_EDIT}/{{id}}`,
      icon: { name: 'far fa-edit' }
    },
    {
      text: 'Pre Data Transformation',
      name: 'preDataTransformation',
      behavior: ActionBehavior.Emit,
      icon: { name: 'fal fa-database' }
    },
    {
      text: 'Post Data Transformation',
      name: 'postDataTransformation',
      behavior: ActionBehavior.Emit,
      icon: { name: 'fad fa-database' }
    },
    {
      text: POP_CONFIRM_DELETE_ACTION.text,
      name: POP_CONFIRM_DELETE_ACTION.name,
      popconfirmTitle: POP_CONFIRM_DELETE_ACTION.popconfirmTitle,
      emitWithoutPopconfirm: POP_CONFIRM_DELETE_ACTION.emitWithoutPopconfirm,
      behavior: POP_CONFIRM_DELETE_ACTION.behavior,
      icon: POP_CONFIRM_DELETE_ACTION.icon,
      popConfirmCancelText: POP_CONFIRM_DELETE_ACTION.popConfirmCancelText,
      popConfirmOkText: POP_CONFIRM_DELETE_ACTION.popConfirmOkText,
      popConfirmOkDanger: POP_CONFIRM_DELETE_ACTION.popConfirmOkDanger,
    }
  ],
  listActions: [
    {
      name: 'createWorkflowStepFunction',
      text: 'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_CREATE_OR_EDIT_COMPONENT.WORKFLOW_STEP_FUNCTION_CREATE',
      buttonType: ButtonType.Default,
      icon: { name: 'fas fa-plus' },
      behavior: ActionBehavior.Route,
      redirectUrl: `${PATH_DEFINITIONS.WORKFLOW_STEP_FUNCTION.WORKFLOW_STEP_FUNCTION_CREATE}`
    }
  ],
  handleVisibilityFn: (data, item, action) => {
    /* hide workflow step functions that belongs to template project */
    if (item.projectId === '00000000-0000-0000-0000-000000000002'
      && (
        action.name === 'editStepFunction'
        || action.name === 'preDataTransformation'
        || action.name === 'postDataTransformation'
        || action.name === POP_CONFIRM_DELETE_ACTION.name
      )
    ) {
      return false;
    } else {
      return true;
    }
  },
};
