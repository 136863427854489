import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RappiderBackTopComponent } from './back-top.component';
import { NzBackTopModule } from 'ng-zorro-antd/back-top';
import { RappiderButtonModule } from '../button/button.module';


@NgModule({
  declarations: [
    RappiderBackTopComponent
  ],
  imports: [
    CommonModule,
    NzBackTopModule,
    RappiderButtonModule
  ],
  exports: [
    RappiderBackTopComponent
  ]
})
export class RappiderBackTopModule { }
