import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RappiderDatePickerComponent } from './date-picker.component';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { RappiderTextboxModule } from '../textbox/textbox.module';
import { TranslateModule } from '@ngx-translate/core';
import { NZ_DATE_LOCALE, NzI18nService } from 'ng-zorro-antd/i18n';
import { enUS } from 'date-fns/locale';

@NgModule({
  declarations: [
    RappiderDatePickerComponent
  ],
  providers: [
    { provide: NZ_DATE_LOCALE, useValue: enUS } // Configures the date format to Day/Month/Year
  ],
  exports: [
    RappiderDatePickerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NzDatePickerModule,
    RappiderTextboxModule,
    TranslateModule
  ]
})
export class RappiderDatePickerModule {
  constructor(private i18n: NzI18nService) { }
  switchLanguage() {
    this.i18n.setDateLocale(enUS);
  }
}
