import { createAction, props } from '@ngrx/store';
import { Comment, CommentPartial, CommentWithRelations, NewCommentReply } from '@rappider/rappider-sdk';

/* action types */
export enum ActionTypes {
  GetComments = '[Comment] GetComments',
  GetCommentsSuccessful = '[Comment] GetCommentsSuccessful',
  GetCommentsFailure = '[Comment] GetCommentsFailure',
  SetComment = '[Comment] SetComment',
  SetComments = '[Comment] SetComments',
  UpdateComment = '[Comment] UpdateComment',
  UpdateCommentSuccessful = '[Comment] UpdateCommentSuccessful',
  UpdateCommentFailure = '[Comment] UpdateCommentFailure',
  DeleteComment = '[Comment] DeleteComment',
  DeleteCommentSuccessful = '[Comment] DeleteCommentSuccessful',
  DeleteCommentFailure = '[Comment] DeleteCommentFailure',
  ReplyComment = '[Comment] ReplyComment',
  ReplyCommentSuccessful = '[Comment] ReplyCommentSuccessful',
  ReplyCommentFailure = '[Comment] ReplyCommentFailure',
  UpdateReply = '[Comment] UpdateReply',
  UpdateReplySuccessful = '[Comment] UpdateReplySuccessful',
  UpdateReplyFailure = '[Comment] UpdateReplyFailure',
  DeleteReply = '[Comment] DeleteReply',
  DeleteReplySuccessful = '[Comment] DeleteReplySuccessful',
  DeleteReplyFailure = '[Comment] DeleteReplyFailure'
}

/* get */
export const GetComments = createAction(ActionTypes.GetComments);

export const GetCommentsSuccessful = createAction(
  ActionTypes.GetCommentsSuccessful,
  props<{ comments: CommentWithRelations[] }>()
);

export const GetCommentsFailure = createAction(
  ActionTypes.GetCommentsFailure,
  props<{ error: any; key: string; date: number }>()
);

/* set */
export const SetComment = createAction(
  ActionTypes.SetComment,
  props<{ comment: CommentWithRelations }>()
);

export const SetComments = createAction(
  ActionTypes.SetComments,
  props<{ comments: CommentWithRelations[] }>()
);

/* update */
export const UpdateComment = createAction(
  ActionTypes.UpdateComment,
  props<{ id: string; comment: CommentPartial }>()
);

export const UpdateCommentSuccessful = createAction(
  ActionTypes.UpdateCommentSuccessful,
  props<{ id: string; comment: Comment }>()
);

export const UpdateCommentFailure = createAction(
  ActionTypes.UpdateCommentFailure,
  props<{ error: any; key: string; date: number }>()
);

/* delete */
export const DeleteComment = createAction(
  ActionTypes.DeleteComment,
  props<{ id: string }>()
);

export const DeleteCommentSuccessful = createAction(
  ActionTypes.DeleteCommentSuccessful,
  props<{ id: string }>()
);

export const DeleteCommentFailure = createAction(
  ActionTypes.DeleteCommentFailure,
  props<{ error: any; key: string; date: number }>()
);

export const ReplyComment = createAction(
  ActionTypes.ReplyComment,
  props<{ parentCommentId: string; reply: NewCommentReply }>()
);

export const ReplyCommentSuccessful = createAction(
  ActionTypes.ReplyCommentSuccessful,
  props<{ comment: Comment }>()
);

export const ReplyCommentFailure = createAction(
  ActionTypes.ReplyCommentFailure,
  props<{ error: any; key: string; date: number }>()
);

export const DeleteReply = createAction(
  ActionTypes.DeleteReply,
  props<{ parentCommentId: string; replyId: string }>()
);

export const DeleteReplySuccessful = createAction(
  ActionTypes.DeleteReplySuccessful,
  props<{ parentCommentId: string; replyId: string }>()
);

export const DeleteReplyFailure = createAction(
  ActionTypes.DeleteReplyFailure,
  props<{ error: any; key: string; date: number }>()
);

export const UpdateReply = createAction(
  ActionTypes.UpdateReply,
  props<{ parentCommentId: string; replyId: string; reply: CommentPartial }>()
);

export const UpdateReplySuccessful = createAction(
  ActionTypes.UpdateReplySuccessful,
  props<{ parentCommentId: string; replyId: string; reply: CommentPartial }>()
);

export const UpdateReplyFailure = createAction(
  ActionTypes.UpdateReplyFailure,
  props<{ error: any; key: string; date: number }>()
);

