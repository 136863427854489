import { createAction, props } from '@ngrx/store';
import { NewWorkflowStepFunctionSubscribedEvent, WorkflowStepFunctionSubscribedEvent, WorkflowStepFunctionSubscribedEventPartial, WorkflowStepFunctionSubscribedEventWithRelations } from '@rappider/rappider-sdk';

/* action types */
export enum ActionTypes {
  CreateWorkflowStepFunctionSubscribedEvent = '[WorkflowStepFunctionSubscribedEvent] CreateWorkflowStepFunctionSubscribedEvent',
  CreateWorkflowStepFunctionSubscribedEventSuccessful = '[WorkflowStepFunctionSubscribedEvent] CreateWorkflowStepFunctionSubscribedEventSuccessful',
  CreateWorkflowStepFunctionSubscribedEventFailure = '[WorkflowStepFunctionSubscribedEvent] CreateWorkflowStepFunctionSubscribedEventFailure',
  GetWorkflowStepFunctionSubscribedEvent = '[WorkflowStepFunctionSubscribedEvent] GetWorkflowStepFunctionSubscribedEvent',
  GetWorkflowStepFunctionSubscribedEventSuccessful = '[WorkflowStepFunctionSubscribedEvent] GetWorkflowStepFunctionSubscribedEventSuccessful',
  GetWorkflowStepFunctionSubscribedEventFailure = '[WorkflowStepFunctionSubscribedEvent] GetWorkflowStepFunctionSubscribedEventFailure',
  UpdateWorkflowStepFunctionSubscribedEvent = '[WorkflowStepFunctionSubscribedEvent] UpdateWorkflowStepFunctionSubscribedEvent',
  UpdateWorkflowStepFunctionSubscribedEventSuccessful = '[WorkflowStepFunctionSubscribedEvent] UpdateWorkflowStepFunctionSubscribedEventSuccessful',
  UpdateWorkflowStepFunctionSubscribedEventFailure = '[WorkflowStepFunctionSubscribedEvent] UpdateWorkflowStepFunctionSubscribedEventFailure',
  DeleteWorkflowStepFunctionSubscribedEvent = '[WorkflowStepFunctionSubscribedEvent] DeleteWorkflowStepFunctionSubscribedEvent',
  DeleteWorkflowStepFunctionSubscribedEventSuccessful = '[WorkflowStepFunctionSubscribedEvent] DeleteWorkflowStepFunctionSubscribedEventSuccessful',
  DeleteWorkflowStepFunctionSubscribedEventFailure = '[WorkflowStepFunctionSubscribedEvent] DeleteWorkflowStepFunctionSubscribedEventFailure',
  AddWorkflowStepFunctionSubscribedEventSuccessful = '[WorkflowStepFunctionSubscribedEvent] AddWorkflowStepFunctionSubscribedEventSuccessful',
}

export const CreateWorkflowStepFunctionSubscribedEvent = createAction(
  ActionTypes.CreateWorkflowStepFunctionSubscribedEvent,
  props<{ payload: { workflowStepFunctionSubscribedEvent: NewWorkflowStepFunctionSubscribedEvent } }>()
);

export const CreateWorkflowStepFunctionSubscribedEventSuccessful = createAction(
  ActionTypes.CreateWorkflowStepFunctionSubscribedEventSuccessful,
  props<{ payload: { workflowStepFunctionSubscribedEvent: WorkflowStepFunctionSubscribedEvent } }>()
);

export const CreateWorkflowStepFunctionSubscribedEventFailure = createAction(
  ActionTypes.CreateWorkflowStepFunctionSubscribedEventFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const AddWorkflowStepFunctionSubscribedEventSuccessful = createAction(
  ActionTypes.AddWorkflowStepFunctionSubscribedEventSuccessful,
  props<{ payload: { workflowStepFunctionSubscribedEvent: WorkflowStepFunctionSubscribedEvent[] } }>()
);

export const GetWorkflowStepFunctionSubscribedEvent = createAction(
  ActionTypes.GetWorkflowStepFunctionSubscribedEvent,
);

export const GetWorkflowStepFunctionSubscribedEventSuccessful = createAction(
  ActionTypes.GetWorkflowStepFunctionSubscribedEventSuccessful,
  props<{ payload: { workflowStepFunctionSubscribedEvents: WorkflowStepFunctionSubscribedEventWithRelations[] } }>()
);

export const GetWorkflowStepFunctionSubscribedEventFailure = createAction(
  ActionTypes.GetWorkflowStepFunctionSubscribedEventFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const UpdateWorkflowStepFunctionSubscribedEvent = createAction(
  ActionTypes.UpdateWorkflowStepFunctionSubscribedEvent,
  props<{ payload: { workflowStepFunctionSubscribedEventId: string; workflowStepFunctionSubscribedEventBody?: WorkflowStepFunctionSubscribedEventPartial } }>()
);

export const UpdateWorkflowStepFunctionSubscribedEventSuccessful = createAction(
  ActionTypes.UpdateWorkflowStepFunctionSubscribedEventSuccessful,
  props<{ payload: { workflowStepFunctionSubscribedEventId: string; workflowStepFunctionSubscribedEventBody?: WorkflowStepFunctionSubscribedEventWithRelations } }>()
);

export const UpdateWorkflowStepFunctionSubscribedEventFailure = createAction(
  ActionTypes.UpdateWorkflowStepFunctionSubscribedEventFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const DeleteWorkflowStepFunctionSubscribedEvent = createAction(
  ActionTypes.DeleteWorkflowStepFunctionSubscribedEvent,
  props<{ payload: { workflowStepFunctionSubscribedEventId: string } }>()
);

export const DeleteWorkflowStepFunctionSubscribedEventSuccessful = createAction(
  ActionTypes.DeleteWorkflowStepFunctionSubscribedEventSuccessful,
  props<{ payload: { workflowStepFunctionSubscribedEventId: string } }>()
);

export const DeleteWorkflowStepFunctionSubscribedEventFailure = createAction(
  ActionTypes.DeleteWorkflowStepFunctionSubscribedEventFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

