/* eslint-disable @typescript-eslint/naming-convention */

import { ProjectModelDataFieldType } from './model-data-field-type.enum';

export const DATA_FIELD_TYPES = [
  {
    key: 'any',
    value: ProjectModelDataFieldType.Any
  },
  {
    key: 'boolean',
    value: ProjectModelDataFieldType.Boolean
  },
  // {
  //   key: 'buffer',
  //   value: ProjectModelDataFieldType.Buffer
  // },
  {
    key: 'date',
    value: ProjectModelDataFieldType.Date
  },
  // {
  //   key: 'GeoPoint',
  //   value: ProjectModelDataFieldType.GeoPoint
  // },
  // {
  //   key: 'DateString',
  //   value: ProjectModelDataFieldType.DateString
  // },
  {
    key: 'number',
    value: ProjectModelDataFieldType.Number
  },
  {
    key: 'Object',
    value: ProjectModelDataFieldType.Object
  },
  {
    key: 'string',
    value: ProjectModelDataFieldType.String
  }
];
