import { PATH_DEFINITIONS } from './path-definition';

export const rappiderFeatureList = [
  {
    label: 'Project Settings & Variables',
    group: 'Rappider Features',
    url: PATH_DEFINITIONS.PROJECTS.PROJECT_SETTINGS_PATH
  },
  {
    label: 'Environment Variables',
    group: 'Rappider Features',
    url: PATH_DEFINITIONS.PROJECTS.PROJECT_ENVIRONMENT_VARIABLES
  },
  {
    label: 'Project Documents',
    group: 'Rappider Features',
    url: PATH_DEFINITIONS.PROJECTS.PROJECT_DOCUMENT_LIST
  },
  {
    label: 'Project Management',
    group: 'Rappider Features',
    // TODO: Fix Path
    url: '/project-management'
  },
  {
    label: 'Data Modelling Diagram',
    group: 'Rappider Features',
    url: PATH_DEFINITIONS.DATABASE_DIAGRAM_EDITOR.DATABASE_DIAGRAM_EDITOR_PATH
  },
  {
    label: 'Data Models',
    group: 'Rappider Features',
    url: PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_LIST
  },
  {
    label: 'OpenAPIs',
    group: 'Rappider Features',
    url: PATH_DEFINITIONS.PROJECTS.OPENAPI_LIST
  },
  {
    label: 'Endpoints',
    group: 'Rappider Features',
    url: PATH_DEFINITIONS.PROJECTS.CUSTOM_ENDPOINT_LIST
  },
  {
    label: 'Pages',
    group: 'Rappider Features',
    url: PATH_DEFINITIONS.PAGES.PAGE_LIST_PATH
  },
  {
    label: 'Modules',
    group: 'Rappider Features',
    url: PATH_DEFINITIONS.MODULE.MODULE_LIST_PATH
  },
  {
    label: 'Layouts',
    group: 'Rappider Features',
    url: PATH_DEFINITIONS.PAGES.PAGE_LAYOUT_LIST_PATH
  },
  {
    label: 'Theme',
    group: 'Rappider Features',
    url: PATH_DEFINITIONS.PROJECTS.PROJECT_THEME_LIST_PATH
  },
  {
    label: 'Styles',
    group: 'Rappider Features',
    // TODO: Fix Path
    url: '/projects/css-style-customization'
  },
  {
    label: 'UI Process Management',
    group: 'Rappider Features',
    // TODO: Fix Path
    url: '/ui-workflow-diagram'
  },
  {
    label: 'Client (UI) Data Stores',
    group: 'Rappider Features',
    url: PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_LIST
  },
  {
    label: 'Events',
    group: 'Rappider Features',
    url: PATH_DEFINITIONS.PROJECTS.UI_DATA_EVENTS
  },
  {
    label: 'Client (UI) Data Update Functions',
    group: 'Rappider Features',
    url: '/projects/ui-data-update-function'
  },
  {
    label: 'Client (UI) Step Functions',
    group: 'Rappider Features',
    // TODO: Fix Path
    url: '/projects/ui-step-functions'
  },
  {
    label: 'Server Step Functions',
    group: 'Rappider Features',
    url: PATH_DEFINITIONS.WORKFLOW_STEP_FUNCTION.WORKFLOW_STEP_FUNCTION_LIST
  },
  {
    label: 'Workflow Functions',
    group: 'Rappider Features',
    // TODO: Fix Path
    url: '/custom-function/workflow-function'
  },
  {
    label: 'Server-Side Process Management',
    group: 'Rappider Features',
    // TODO: Fix Path
    url: 'workflow-diagram'
  },
  {
    label: 'Message Templates',
    group: 'Rappider Features',
    url: PATH_DEFINITIONS.PROJECTS.PROJECT_MESSAGE_TEMPLATE_LIST
  },
  {
    label: 'Project Packages',
    group: 'Rappider Features',
    url: PATH_DEFINITIONS.PROJECTS.PROJECT_PACKAGE_LIST
  },
  {
    label: 'Embedded Scripts',
    group: 'Rappider Features',
    url: PATH_DEFINITIONS.PROJECTS.PROJECT_SCRIPT_LIST
  },
  {
    label: 'Versions',
    group: 'Rappider Features',
    url: PATH_DEFINITIONS.PROJECTS.PROJECT_VERSION_LIST
  },
  {
    label: 'Users & Members',
    group: 'Rappider Features',
    url: PATH_DEFINITIONS.PROJECTS.PROJECT_MEMBER_LIST
  },
  {
    label: 'Roles',
    group: 'Rappider Features',
    url: PATH_DEFINITIONS.PROJECTS.PROJECT_ROLE_LIST
  },
  {
    label: 'Invite Users',
    group: 'Rappider Features',
    url: PATH_DEFINITIONS.PROJECTS.PROJECT_USER_INVITATIONS
  },
  {
    label: 'Assets',
    group: 'Rappider Features',
    url: PATH_DEFINITIONS.FILE_MANAGEMENT.EXPLORER_PATH
  }
];
