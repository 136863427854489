<!-- ----------------------------------------------------------------------------------------- -->
<!-- TITLE AREA -->
<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>
<!-- / TITLE AREA -->
<!-- ----------------------------------------------------------------------------------------- -->

<!-- ----------------------------------------------------------------------------------------- -->
<!-- PROJECT ROLE LIST -->
<section>
  <rappider-list-grid [config]="projectRolesConfig"
                      [data]="roles"
                      [loading]="loading"
                      (columnActionClick)="editOrDeleteButtonClick($event)"
                      (listActionClick)="onCreateNewRoleButtonClick($event)"></rappider-list-grid>
</section>
<!-- / PROJECT ROLE LIST -->
<!-- ----------------------------------------------------------------------------------------- -->

<!-- ----------------------------------------------------------------------------------------- -->
<!-- CREATE NEW ROLE MODAL -->
<rappider-modal [(visible)]="createNewRoleModalVisible"
                [title]="'Create New Role' | translate"
                [okText]="'SHARED.SAVE' | translate"
                [cancelText]="'SHARED.CANCEL' | translate"
                width="40%"
                (okClick)="createNewRoleSubmit()"
                (cancelClick)="onCreateNewCancelButtonClick()">
  <rappider-crud-view-edit-form #createNewRoleForm
                                [config]="createOrEditProjectRolesConfig"
                                (formSubmit)="onSaveNewRoleButtonClick($event)">
  </rappider-crud-view-edit-form>
</rappider-modal>
<!-- / CREATE NEW ROLE MODAL -->
<!-- ----------------------------------------------------------------------------------------- -->

<!-- ----------------------------------------------------------------------------------------- -->
<!-- EDIT ROLE MODAL -->
<rappider-modal [(visible)]="editRoleModalVisible"
                [title]="'Edit Role' | translate"
                [okText]="'SHARED.SAVE' | translate"
                [cancelText]="'SHARED.CANCEL' | translate"
                width="40%"
                (okClick)="onEditRoleSubmit()"
                (cancelClick)="onEditRoleCancelButtonClick()">
  <rappider-crud-view-edit-form #editRoleForm
                                [config]="createOrEditProjectRolesConfig"
                                [data]="editedRole"
                                (formSubmit)="onEditRoleSaveButtonClick($event)">
  </rappider-crud-view-edit-form>
</rappider-modal>
<!-- / EDIT ROLE MODAL -->
<!-- ----------------------------------------------------------------------------------------- -->