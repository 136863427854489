import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { RappiderTableComponent } from './table.component';


@NgModule({
  declarations: [
    RappiderTableComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    NzTableModule,
    NzToolTipModule
  ],
  exports: [
    RappiderTableComponent
  ]
})
export class RappiderTableModule { }
