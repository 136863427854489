import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormLayout, SelectComponentConfig } from '@rappider/rappider-components/utils';
import { NewProjectModelField, ProjectModelFieldWithRelations } from '@rappider/rappider-sdk';
import { FormService } from '@rappider/services';
import { CAMEL_CASE_REGEX, DATA_FIELD_DEFAULT_FNS, DATA_FIELD_TYPES, ProjectModelDataFieldType } from '@rappider/shared/definitions';
import { lowerCase } from 'lodash';

@Component({
  selector: 'rappider-new-project-model-field-form',
  templateUrl: './new-project-model-field-form.component.html',
  styleUrls: ['./new-project-model-field-form.component.scss']
})
export class NewProjectModelFieldFormComponent implements OnInit {

  @Input() loading!: boolean;
  /* layout of the form 'horizontal' or 'vertical' */
  @Input() formLayout: FormLayout = FormLayout.Vertical;
  @Input() isSubmitButtonFloat = true;
  @Input() submitButtonLoading: boolean;

  @Output() newProjectModelFieldClick = new EventEmitter<NewProjectModelField>();

  newProjectModelField: ProjectModelFieldWithRelations;
  /* form group */
  newProjectModelFieldForm: FormGroup;

  monacoEditorConfig = {
    options: {
      theme: 'vs-dark',
      language: 'markdown',
    }
  };
  autoTips: Record<string, Record<string, string>> = {
    default: {
      required: '- This field is required.',
      pattern: '- This field must start with a lowercase letter.'
    }
  };

  typeSelectConfig: SelectComponentConfig = {
    options: DATA_FIELD_TYPES,
    settings: {
      searchable: true
    }
  };

  defaultFnSelectConfig: SelectComponentConfig = {
    options: DATA_FIELD_DEFAULT_FNS
  };

  constructor(
    private formBuilder: FormBuilder,
    private formService: FormService
  ) { }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.newProjectModelFieldForm = this.formBuilder.group({
      name: [this.newProjectModelField?.name, [Validators.required, Validators.pattern(CAMEL_CASE_REGEX)]],
      type: [lowerCase(ProjectModelDataFieldType.String), Validators.required],
      default: [this.newProjectModelField?.default],
      defaultFn: [this.newProjectModelField?.defaultFn],
      isArray: [this.newProjectModelField?.isArray],
      isRequired: [this.newProjectModelField?.isRequired],
      isHidden: [this.newProjectModelField?.isHidden],
      isUnique: [this.newProjectModelField?.isUnique],
      description: [this.newProjectModelField?.description]
    });
    setTimeout(() => {
      document.getElementById('name').focus();
    }, 10);
  }


  onCreateProjectModelFieldFormSubmit() {
    this.formService.checkFormValidation(this.newProjectModelFieldForm);
    if (this.newProjectModelFieldForm.valid) {
      this.newProjectModelFieldClick.emit(this.newProjectModelFieldForm?.value);
    }
  }

}
