import { WorkflowStepFunction } from '../index';

declare let Object: any;

export interface DataTransformationInterface {
    'id'?: string;
    'workflowStepFunctionId'?: string;
    workflowStepFunction: WorkflowStepFunction;
}

export class DataTransformation implements DataTransformationInterface {
    'id'?: string;
    'workflowStepFunctionId'?: string;
    workflowStepFunction: WorkflowStepFunction;
    constructor(data?: DataTransformationInterface) {
        Object.assign(this, data);
    }
    /**
     * The name of the model represented by this $resource,
     * i.e. `DataTransformation`.
     */
    public static getModelName() {
        return 'DataTransformation';
    }
    /**
    * @method factory
    * @author Jonathan Casarrubias
    * @license MIT
    * This method creates an instance of DataTransformation for dynamic purposes.
    **/
    public static factory(data: DataTransformationInterface): DataTransformation {
        return new DataTransformation(data);
    }
    /**
    * @method getModelDefinition
    * @author Julien Ledun
    * @license MIT
    * This method returns an object that represents some of the model
    * definitions.
    **/
    public static getModelDefinition() {
        return {
            name: 'DataTransformation',
            plural: 'DataTransformations',
            path: 'data-transformations',
            idName: 'id',
            properties: {
                'id': {
                    name: 'id',
                    type: 'string'
                },
                'workflowStepFunctionId': {
                    name: 'workflowStepFunctionId',
                    type: 'string'
                }
            },
            relations: {
                WorkflowStepFunction: {
                    name: 'workflowStepFunction',
                    type: 'WorkflowStepFunction',
                    model: 'WorkflowStepFunction',
                    relationType: 'belongsTo',
                    keyFrom: 'workflowStepFunctionId',
                    keyTo: 'id'
                }
            }
        };
    }
}
