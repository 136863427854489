/* eslint-disable */

declare var Object: any;
export interface DataFieldSchemaInterface {
  id?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
}

export class DataFieldSchema implements DataFieldSchemaInterface {
  'id': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  constructor(data?: DataFieldSchemaInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `DataFieldSchema`.
   */
  public static getModelName() {
    return 'DataFieldSchema';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of DataFieldSchema for dynamic purposes.
   **/
  public static factory(data: DataFieldSchemaInterface): DataFieldSchema {
    return new DataFieldSchema(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'DataFieldSchema',
      plural: 'DataFieldSchemas',
      path: 'data-field-schemas',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
      },
      relations: {},
    };
  }
}
