import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActionResponse, BreadcrumbOption, HeadingComponentConfig, HeadingType } from '@rappider/rappider-components/utils';
import { NotificationService } from '@rappider/services';
import { PAGE_DEFINITIONS } from 'libs/shared/src/lib/definitions/page-definition';
import { Subscription } from 'rxjs';
import { UI_DATA_STORE_LIST_CONFIG } from './config/ui-data-store-list-config';
import { DeleteUIDataStore, GetUIDataStores } from '../../../../states/ui-data-store/ui-data-store.actions';
import { defaultToolbarTitleHeadingSize, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { ProjectWithRelations, UiDataStoreWithRelations } from '@rappider/rappider-sdk';
import { GetUIWorkflowStepFunctions } from 'libs/project/src/lib/states/ui-step-functions/ui-step-function.actions';
import { GetUIDataEvents } from 'libs/project/src/lib/states/ui-data-event-state/ui-data-event.actions';
import { GetDataSchemas } from 'libs/project/src/lib/states/data-schema/data-schema.actions';

@Component({
  selector: 'rappider-ui-data-store-list',
  templateUrl: './ui-data-store-list.component.html',
  styleUrls: ['./ui-data-store-list.component.scss']
})
export class UiDataStoreListComponent implements OnInit, OnDestroy {

  /* list grid config */
  UI_DATA_STORE_LIST_CONFIG = UI_DATA_STORE_LIST_CONFIG;
  /* main title */
  mainTitle: HeadingComponentConfig = {
    content: 'PROJECT_MODULE.UI_DATA_STORE_LIST_COMPONENT.UI_DATA_STORE_LIST',
    type: defaultToolbarTitleHeadingSize
  };
  /* page title */
  title: BreadcrumbOption[];
  /* subscriptions */
  subscriptions: Subscription[];
  /* active project id */
  projectId: string;
  /* project models */
  uiDataStores: UiDataStoreWithRelations[];
  displayToolbar = false;
  displayToolbarBackButton = false;
  isLoading: boolean;

  constructor(
    private store: Store<any>,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToUIDataStores(),
      this.subscribeToLoading(),
      this.subscribeToFieldsLoadingByModelIds()
    ];
  }

  /**
   * subscribe to active project to set the title
   *
   * @returns
   * @memberof UiDataStoreListComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectWithRelations) => {
      this.projectId = activeProject?.id;
      this.setTitle(activeProject);
    });
  }

  subscribeToUIDataStores() {
    return this.store.select(state => state.uiDataStore.data).subscribe((uiDataStores: UiDataStoreWithRelations[]) => {
      this.uiDataStores = uiDataStores;
    });
  }

  subscribeToLoading() {
    return this.store.select(state => state.uiDataStore.loading).subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
  }

  subscribeToFieldsLoadingByModelIds() {
    return this.store.select(state => state.uiDataStore.fieldsLoadingByModelIds).subscribe((fieldsLoadingByModelIds: string[]) => {
      UI_DATA_STORE_LIST_CONFIG.listActions.find(listAction => listAction.name === 'SHARED.REFRESH').disabled = fieldsLoadingByModelIds.length > 0;
    });
  }

  setTitle(activeProject: ProjectWithRelations) {
    this.title = [
      {
        label: activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${activeProject?.id}`
      },
      {
        label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_DATA_STORE_LIST.PAGE_TITLE
      }
    ];
  }

  onUIDataStoreDelete(actionResponse: ActionResponse) {
    const uiDataStore = <UiDataStoreWithRelations>actionResponse.data;

    if (uiDataStore.schema?.fields?.length) {
      this.notificationService.createNotification(
        'error',
        'PROJECT_MODULE.UI_DATA_STORE_LIST_COMPONENT.COULDNT_DELETE_STORE_LIST_WITH_DATA_FIELD',
        'PROJECT_MODULE.UI_DATA_STORE_LIST_COMPONENT.COULDNT_DELETE_STORE_LIST_WITH_DATA_FIELD'
      );
    } else {
      this.store.dispatch(new DeleteUIDataStore({ uiDataStoreId: uiDataStore.id }));
    }
  }

  onUIDataStoreRefresh(actionResponse: ActionResponse) {
    if (actionResponse.action.name === 'SHARED.REFRESH') {
      this.getUIDataStores();
    }
  }

  /* state implementation */
  getUIDataStores() {
    this.store.dispatch(new GetUIDataStores());
    this.store.dispatch(new GetUIWorkflowStepFunctions());
    this.store.dispatch(new GetUIDataEvents());
    this.store.dispatch(new GetDataSchemas());
  }
}
