/* eslint-disable no-shadow */
import { Action } from '@ngrx/store';
import { ProjectExport } from '@rappider/rappider-sdk';
import { Pagination } from '@rappider/shared/interfaces';

export enum ActionTypes {
  LoadModule = '[ProjectExport] LoadModule',
  SetPagination = '[ProjectExport] SetPagination',
  SetPaginationFailure = '[ProjectExport] SetPaginationFailure',
  GetTotalCount = '[ProjectExport] GetTotalCount',
  GetProjectExports = '[ProjectExport] GetProjectExports',
  SetProjectExports = '[ProjectExport] SetProjectExports',
  ExportProject = '[ProjectExport] ExportProject',
  ProjectExportError = '[ProjectExport] ProjectExportError',
  DeleteProjectVersionId = '[ProjectExport] DeleteProjectVersionId',
}

export class LoadModule implements Action {
  readonly type = ActionTypes.LoadModule;
}

export class GetProjectExports implements Action {
  readonly type = ActionTypes.GetProjectExports;
}

/**
 * sets pagination and gets data after the action if specified
 *
 * @export
 * @class SetPagination
 * @implements {Action}
 */
export class SetPagination implements Action {
  readonly type = ActionTypes.SetPagination;
  constructor(public payload: { pagination: Pagination; getDataAfter?: boolean }) { }
}

export class SetPaginationFailure implements Action {
  readonly type = ActionTypes.SetPaginationFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class GetTotalCount implements Action {
  readonly type = ActionTypes.GetTotalCount;
}

export class SetProjectExports implements Action {
  readonly type = ActionTypes.SetProjectExports;
  constructor(public payload: { projectExports: any[] }) { }
}

export class ExportProject implements Action {
  readonly type = ActionTypes.ExportProject;
  constructor(public payload: { projectVersionId: string }) { }
}

export class DeleteProjectVersionId implements Action {
  readonly type = ActionTypes.DeleteProjectVersionId;
  constructor(public payload: { projectVersionId: string }) { }
}

export class ProjectExportError implements Action {
  readonly type = ActionTypes.ProjectExportError;
  constructor(public payload: { errorOn: string; error?: any }) { }
}

export type Actions =
  LoadModule
  | SetPagination
  | SetPaginationFailure
  | GetTotalCount
  | GetProjectExports
  | SetProjectExports
  | ExportProject
  | ProjectExportError
  | DeleteProjectVersionId
  ;
