<rappider-spin [spinning]="loading">
  <rappider-data-schema-element-row *ngIf="activeProjectId"
                                    [data]="uiDataStoreFields"
                                    addButtonText="PROJECT_MODULE.UI_DATA_STORE_FIELD_CREATE_COMPONENT.UI_DATA_STORE_FIELD_ADD"
                                    hasDefault="true"
                                    [activeProjectId]="activeProjectId"
                                    [dataSchemaPageType]="DataSchemaPageType"
                                    [isAddButtonVisible]="!uiDataStore?.isReadOnly"
                                    [isDeleteButtonVisible]="!uiDataStore?.isReadOnly"
                                    (saveButtonClick)="onDataSchemaSave($event)"
                                    (cancelButtonClick)="onDataSchemaCancel()">
  </rappider-data-schema-element-row>
</rappider-spin>
