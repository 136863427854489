import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TokenService } from '@rappider/authentication/services';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QueryParam } from '@rappider/shared/definitions';
import { RouterStateService } from '@rappider/services';


@Injectable({
  providedIn: 'root'
})
export class NonAuthGuard implements CanActivate {

  constructor(
    private tokenService: TokenService,
    private routerStateService: RouterStateService,
    private store: Store<any>
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    /* check if user is already in state */
    console.log('NON AUTH GUARD');
    return this.store.select(s => s.auth.user).pipe(
      map(user => {
        /* if there is no user data in the state */
        if (!user?.id) {
          /* return if there is no access token data in the storage */
          const authenticationToken = this.tokenService.getAuthenticationToken();
          if (authenticationToken) {
            this.navigateToHomePage();
          }
          return !authenticationToken;
        } else {
          /* the user is logged in */
          this.navigateToHomePage();
          return false;
        }
      })
    );
  }

  navigateToHomePage() {
    /* navigate to home page */
    this.routerStateService.navigate(PATH_DEFINITIONS.HOME_PAGE.HOME_PAGE_PATH);
  }

}
