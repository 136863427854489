/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */

/* angular imports */
import { createAction, props } from '@ngrx/store';
import { Category, CategoryPartial, NewCategory } from '@rappider/rappider-sdk';

/* action types */
export enum ActionTypes {
  GetCategories = '[Category] GetCategories',
  GetCategoriesSuccessful = '[Category] GetCategoriesSuccessful',
  GetCategoriesFailure = '[Category] GetCategoriesFailure',
  CreateCategory = '[Category] CreateCategory',
  CreateCategorySuccessful = '[Category] CreateCategorySuccessful',
  CreateCategoryFailure = '[Category] CreateCategoryFailure',
  UpdateCategory = '[Category] UpdateCategory',
  UpdateCategorySuccessful = '[Category] UpdateCategorySuccessful',
  UpdateCategoryFailure = '[Category] UpdateCategoryFailure',
  DeleteCategory = '[Category] DeleteCategory',
  DeleteCategorySuccessful = '[Category] DeleteCategorySuccessful',
  DeleteCategoryFailure = '[Category] DeleteCategoryFailure',
  ErrorAction = '[Category] ErrorAction',
  ResetStateToInitial = '[Category] ResetStateToInitial',
  ResetStateToInitialChangeProject = '[Category] ResetStateToInitialChangeProject'
}

/* actions */

export const GetCategories = createAction(ActionTypes.GetCategories);

export const GetCategoriesSuccessful = createAction(
  ActionTypes.GetCategoriesSuccessful,
  props<{ payload: { categories: Category[] } }>()
);

export const GetCategoriesFailure = createAction(
  ActionTypes.GetCategoriesFailure);

export const CreateCategory = createAction(
  ActionTypes.CreateCategory,
  props<{ payload: { category: NewCategory; navigateToListAfterCreate?: boolean } }>()
);

export const CreateCategorySuccessful = createAction(
  ActionTypes.CreateCategorySuccessful,
  props<{ payload: { category: Category } }>()
);

export const CreateCategoryFailure = createAction(ActionTypes.CreateCategoryFailure);

export const UpdateCategory = createAction(
  ActionTypes.UpdateCategory,
  props<{ payload: { categoryId: string; category: CategoryPartial; navigateToListAfterUpdate?: boolean } }>()
);

export const UpdateCategorySuccessful = createAction(
  ActionTypes.UpdateCategorySuccessful,
  props<{ payload: { categoryId: string; category: Category } }>()
);

export const UpdateCategoryFailure = createAction(ActionTypes.UpdateCategoryFailure);

export const DeleteCategory = createAction(
  ActionTypes.DeleteCategory,
  props<{ payload: { categoryId: string } }>()
);

export const DeleteCategorySuccessful = createAction(
  ActionTypes.DeleteCategorySuccessful,
  props<{ payload: { categoryId: string } }>()
);

export const DeleteCategoryFailure = createAction(ActionTypes.DeleteCategoryFailure);

export const ResetStateToInitial = createAction(ActionTypes.ResetStateToInitial);

export const ResetStateToInitialChangeProject = createAction(ActionTypes.ResetStateToInitialChangeProject);

export const ErrorAction = createAction(
  ActionTypes.ErrorAction,
  props<{ payload: { error: any; key: string; timeStamp: number } }>()
);
