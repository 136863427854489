<!-- FONT AWESOME ICON -->
<span *ngIf="name && type === IconType.FontAwesome">
  <i class='{{style}} {{name}} {{animation}}'
     [style.color]="color"
     [style.--fa-primary-color]="color"
     [style.--fa-secondary-color]="secondColor"
     [style.font-size]="size"></i>
</span>

<!-- NG ZORRO ICON -->
<span *ngIf="name && type === IconType.NgZorro"
      nz-icon
      [style.font-size]="size"
      [style.color]="color"
      [nzType]="name"
      [nzTheme]="theme"
      [nzTwotoneColor]="secondColor">
</span>

<!-- MATERIAL ICON -->
<mat-icon *ngIf="name && type === IconType.Material">{{ name }}</mat-icon>

<span *ngIf="name && type === IconType.ImportIcon"
      class="import-icon-area">
  <img [src]="name"
       [style.width]="size"
       [style.height]="size" />
</span>

<!-- NO ICON INFO -->
<ng-container *ngIf="!name">
  <!-- for font awesome placeholder -->
  <!-- // TODO: comment until content editor object bug fixed -->
  <!-- <i class="fas icon-does-not-exist"></i> -->
</ng-container>