import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionResponse, CrudTableViewConfig } from '@rappider/rappider-components/utils';
import { UI_STEP_FUNCTION_LIST_CONFIG } from '@rappider/shared/configs';
import { UiWorkflowStepFunction } from 'libs/rappider-sdk/src/lib/models';

@Component({
  selector: 'rappider-ui-step-function-list-detail',
  templateUrl: './ui-step-function-list-detail.component.html',
  styleUrls: ['./ui-step-function-list-detail.component.scss']
})
export class UiStepFunctionListDetailComponent {

  @Input() config: CrudTableViewConfig = UI_STEP_FUNCTION_LIST_CONFIG;
  @Input() uiStepFunctionData: UiWorkflowStepFunction[];
  @Input() isLoading: boolean;

  @Output() uiStepFunctionCreate = new EventEmitter();
  @Output() uiStepFunctionDelete = new EventEmitter<UiWorkflowStepFunction>();
  @Output() uiStepFunctionPreData = new EventEmitter<UiWorkflowStepFunction>();
  @Output() uiStepFunctionPostData = new EventEmitter<UiWorkflowStepFunction>();

  navigateToUIStepFunctionCreate() {
    this.uiStepFunctionCreate.emit();
  }
  onUiStepFunctionListColumnActionClick(event: ActionResponse) {
    const uiStepFunctionData: UiWorkflowStepFunction = event.data;
    if (event.action.name === 'DELETE_ITEM') {
      this.uiStepFunctionDelete.emit(uiStepFunctionData);
    } else if (event.action.name === 'preDataTransformation') {
      this.uiStepFunctionPreData.emit(uiStepFunctionData);
    } else if (event.action.name === 'postDataTransformation') {
      this.uiStepFunctionPostData.emit(uiStepFunctionData);
    }
  }
}
