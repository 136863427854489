/* eslint-disable */
import {
  Category,
  UIDataStore,
  DataMapping,
  ComponentInputDefinition,
} from '../index';

declare var Object: any;
export interface ComponentDataSubscriptionInterface {
  id?: string;
  componentId?: string;
  uiDataStoreId?: string;
  dataMappingId?: string;
  componentInputDefinitionId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  component?: Category;
  uiDataStore?: UIDataStore;
  dataMapping?: DataMapping;
  inputDefinition?: ComponentInputDefinition;
}

export class ComponentDataSubscription
  implements ComponentDataSubscriptionInterface {
  'id'?: string;
  'componentId': string;
  'uiDataStoreId': string;
  'dataMappingId': string;
  'componentInputDefinitionId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  component: Category;
  uiDataStore: UIDataStore;
  dataMapping: DataMapping;
  inputDefinition: ComponentInputDefinition;
  constructor(data?: ComponentDataSubscriptionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ComponentDataSubscription`.
   */
  public static getModelName() {
    return 'ComponentDataSubscription';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ComponentDataSubscription for dynamic purposes.
   **/
  public static factory(
    data: ComponentDataSubscriptionInterface
  ): ComponentDataSubscription {
    return new ComponentDataSubscription(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ComponentDataSubscription',
      plural: 'ComponentDataSubscriptions',
      path: 'component-data-subscriptions',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        componentId: {
          name: 'componentId',
          type: 'string',
        },
        uiDataStoreId: {
          name: 'uiDataStoreId',
          type: 'string',
        },
        dataMappingId: {
          name: 'dataMappingId',
          type: 'string',
        },
        componentInputDefinitionId: {
          name: 'componentInputDefinitionId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
      },
      relations: {
        component: {
          name: 'component',
          type: 'Category',
          model: 'Category',
          relationType: 'belongsTo',
          keyFrom: 'componentId',
          keyTo: 'id',
        },
        uiDataStore: {
          name: 'uiDataStore',
          type: 'UIDataStore',
          model: 'UIDataStore',
          relationType: 'belongsTo',
          keyFrom: 'uiDataStoreId',
          keyTo: 'id',
        },
        dataMapping: {
          name: 'dataMapping',
          type: 'DataMapping',
          model: 'DataMapping',
          relationType: 'belongsTo',
          keyFrom: 'dataMappingId',
          keyTo: 'id',
        },
        inputDefinition: {
          name: 'inputDefinition',
          type: 'ComponentInputDefinition',
          model: 'ComponentInputDefinition',
          relationType: 'belongsTo',
          keyFrom: 'componentInputDefinitionId',
          keyTo: 'id',
        },
      },
    };
  }
}
