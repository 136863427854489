<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>
<section>
  <div class="actionArea">
    <rappider-input-group [(ngModel)]="searchText"
                          [textbox]="inputGroupConfig.textbox"
                          [suffixIcon]="inputGroupConfig.suffixIcon"
                          (ngModelChange)="onSearchTextChange()"></rappider-input-group>

    <div class="buttons">
      <rappider-button [text]="expandButtonConfig.text"
                       [key]="expandButtonConfig.key"
                       [icon]="expandButtonConfig.icon"
                       (confirm)="toggleCollapsePanelVisibility(expandButtonConfig.key)">

      </rappider-button>
      &nbsp;&nbsp;
      <rappider-button [text]="collapseButtonConfig.text"
                       [key]="collapseButtonConfig.key"
                       [icon]="collapseButtonConfig.icon"
                       (confirm)="toggleCollapsePanelVisibility(collapseButtonConfig.key)">
      </rappider-button>
    </div>
  </div>
</section>

<nz-collapse *ngIf="uiDataUpdateFunctionListData">
  <nz-collapse-panel *ngFor=" let data of searchText? searchResults : uiDataUpdateFunctionListData"
                     [nzHeader]="data.name"
                     [nzActive]="data.active"
                     [nzActive]="collapseToggle"
                     [nzExtra]="data?.isReadOnly ? null : createButtonTemplate">
    <ng-container *ngIf="data.uiDataUpdateFunctions.length; else noDataTemplate">
      <rappider-ui-data-update-function-page [config]="UI_DATA_UPDATE_FUNCTIONS_LIST_CONFIG"
                                             [loading]="loading"
                                             [uiDataUpdateFunctions]="data.uiDataUpdateFunctions"
                                             (uiDataUpdateFunctionDelete)="deleteUIDataUpdateFunction($event)">
      </rappider-ui-data-update-function-page>
    </ng-container>

    <ng-template #noDataTemplate>
      <nz-empty nzNotFoundImage="simple"></nz-empty>
    </ng-template>

    <ng-template #createButtonTemplate>
      <rappider-button [icon]="createButtonConfig.icon"
                       [size]="createButtonConfig.size"
                       nz-tooltip
                       nzTooltipTitle="Add UI Data Update Function"
                       (confirm)="navigateToCreateUIDataUpdateFunctionPage(data.id)"></rappider-button>
    </ng-template>
  </nz-collapse-panel>
</nz-collapse>
