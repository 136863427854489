import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ProjectExportInterface, ProjectInterface, ProjectVersionApi, ProjectVersionInterface } from '@rappider/api-sdk';
import { ActionResponse, BreadcrumbOption, ButtonComponentConfig, ButtonType, HeadingComponentConfig, ProgressStatus } from '@rappider/rappider-components/utils';
import { ProjectExport, ProjectExportWithRelations, ProjectWithRelations } from '@rappider/rappider-sdk';
import { RouterStateService, StringUtilityService } from '@rappider/services';
import { LAST_PROJECT_EXPORT_LIST_CONFIG, PROJECT_EXPORT_LIST_CONFIG } from '@rappider/shared/configs';
import { defaultToolbarTitleHeadingSize, IconType, PATH_DEFINITIONS, ProjectExportStatus } from '@rappider/shared/definitions';
import { Subscription } from 'rxjs';
import { LoadModule, DeleteProjectVersionId } from '../../states/project-export-state/project-export.actions';
import { GetProjectVersions } from '../../states/project-versions-state/project-version.actions';

@Component({
  selector: 'rappider-project-version-detail',
  templateUrl: './project-version-detail.component.html',
  styleUrls: ['./project-version-detail.component.scss']
})
export class ProjectVersionDetailComponent implements OnInit, OnDestroy {

  /* exported version list grid configs */
  PROJECT_EXPORT_LIST_CONFIG = PROJECT_EXPORT_LIST_CONFIG;
  LAST_PROJECT_EXPORT_LIST_CONFIG = LAST_PROJECT_EXPORT_LIST_CONFIG;
  /* loading status */
  loading: boolean;
  /* main title */
  mainTitle: HeadingComponentConfig;
  /* component title */
  title: string | string[] | BreadcrumbOption[];
  /* active project data */
  activeProject: ProjectWithRelations;
  /* project version informations */
  projectVersionId: string; /* from url */
  projectVersion: ProjectVersionInterface; /* getting project version by id */
  projectVersions: ProjectVersionInterface[];
  /* latest project export */
  latestProjectExport: ProjectExportWithRelations | ProjectWithRelations;
  /* project export list */
  projectExports: ProjectExportWithRelations[];
  /* Subscriptions */
  subscriptions: Subscription[];
  /* button icon */
  projectExportPageButton: ButtonComponentConfig = {
    text: 'PROJECT_MODULE.PROJECT_VERSION_DETAIL_COMPONENT.EXPORT_PROJECT',
    type: ButtonType.Default,
    icon: {
      name: 'far fa-file-export'
    }
  };
  isExportDetailsCollapsed = false;
  exportDetailsTogglerButton = {
    text: 'PROJECT_MODULE.PROJECT_VERSION_DETAIL_COMPONENT.HIDE_EXPORT_DETAILS',
    type: ButtonType.Default,
    icon: {
      name: 'up',
      type: IconType.NgZorro
    }
  };
  displayToolbar = false;
  displayToolbarBackButton = false;
  exportStatusFlag = false;
  progressStatus: ProgressStatus;
  statusPercent: number;
  showText = false;
  progressInnerText: string;
  isExportContinue = false;

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private projectVersionApi: ProjectVersionApi,
    private routerStateService: RouterStateService
  ) { }

  ngOnInit() {
    this.getProjectVersionIdFromURL();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    if (this.latestProjectExport?.status !== ProjectExportStatus.Exporting) {
      this.store.dispatch(
        new DeleteProjectVersionId({ projectVersionId: this.projectVersionId })
      );
    }
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /* getting project version id from url params */
  getProjectVersionIdFromURL() {
    this.projectVersionId = this.activatedRoute.snapshot.params.projectVersionId;
  }

  /**
   * Subscribe to all data
   *
   * @memberof ProjectVersionListComponent
   */
  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToProjectExportsData(),
      this.subscribeToProjectExportsLoading(),
      this.subscribeToProjectVersions(),
      this.subscribeToProjectExportStatus()
    ];
  }

  /**
   * get project exports data field
   *
   * @param {string} projectId
   * @memberof ProjectVersionListComponent
   */
  subscribeToProjectExportsData() {
    return this.store.select(state => state.projectExport.data).subscribe((data: ProjectExportWithRelations[]) => {
      if (data) {
        this.projectExports = data
          .filter((projectExport: ProjectExportWithRelations) => projectExport.projectVersionId === this.projectVersionId)
          .map((exportData: ProjectExportWithRelations, index: number) => ({
            ...exportData,
            versionNumber: exportData.projectVersion.versionNumber,
            isLatestExport: index === 0, /* TODO: fix this field when pagination added to this component */
            appType: StringUtilityService.toPascalCase(this.activeProject.appType),
            frontendFramework: StringUtilityService.toPascalCase(this.activeProject.frontendFramework),
            backendFramework: StringUtilityService.toPascalCase(this.activeProject.backendFramework)
          }));
        /* get latest project exports */
        this.latestProjectExport = this.projectExports[0]; /* TODO: fix this variable when pagination added to this component */
      } else {
        this.projectExports = [];
        this.latestProjectExport = null;
      }
    });
  }

  /**
   * get project exports loading field
   *
   * @param {string} projectId
   * @memberof ProjectVersionListComponent
   */
  subscribeToProjectExportsLoading() {
    return this.store.select(state => state.projectExport.loading).subscribe(loading => {
      this.loading = loading;
    });
  }

  /**
   * get active project from state and get project versions by active project id
   *
   * @return {*}
   * @memberof ProjectVersionListComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe(activeProject => {
      this.activeProject = activeProject;
      if (activeProject) {
        this.getProjectVersionById(this.projectVersionId);
        this.getProjectExports();
        this.getProjectVersions();
      }
    });
  }

  /**
 * get project versions data from state
 *
 * @return {*}
 * @memberof ProjectVersionListComponent
 */
  subscribeToProjectVersions() {
    return this.store.select(state => state.projectVersion.data).subscribe((projectVersions: ProjectVersionInterface[]) => {
      if (projectVersions) {
        this.projectVersions = projectVersions;
        this.getProjectVersion();
      }
    });
  }

  subscribeToProjectExportStatus() {
    return this.store.select(state => state.projectExport.isExportContinue).subscribe(isContinue => {
      if (isContinue === this.projectVersionId) {
        this.isExportContinue = true;
      } else {
        this.isExportContinue = false;
      }
    });
  }

  getProjectVersion() {
    if (this.projectVersions && this.projectVersionId) {
      this.projectVersion = this.projectVersions.find(projectVersion => projectVersion.id === this.projectVersionId);
      this.compareTheVersions();
    }
  }

  compareTheVersions() {
    if (this.projectVersions && this.projectVersion) {
      const lastProjectVersion = this.getLastProjectVersion();
      if (lastProjectVersion.createdDate === this.projectVersion.createdDate) {
        this.exportStatusFlag = false;
      } else {
        this.exportStatusFlag = true;
      }
    } else {
      // sometimes version is undefined, so we should check this condition
      this.exportStatusFlag = true;
    }
  }

  getLastProjectVersion() {
    const sortedVersions = this.projectVersions.slice().sort((a, b) => (a.createdDate < b.createdDate ? -1 : 1));
    return sortedVersions[sortedVersions.length - 1];
  }

  getProjectVersions() {
    this.store.dispatch(new GetProjectVersions());
  }

  /**
   * Get Project Exports
   *
   * @memberof ProjectVersionListComponent
   */
  getProjectExports() {
    this.store.dispatch(new LoadModule());
  }

  getProjectVersionById(projectVersionId: string) {
    this.projectVersionApi.findById(projectVersionId).subscribe((projectVersion: ProjectVersionInterface) => {
      this.mainTitle = {
        content: 'PROJECT_MODULE.PROJECT_VERSIONS_COMPONENT.PROJECT_VERSIONS',
        type: defaultToolbarTitleHeadingSize
      };
      this.title = [
        {
          label: this.activeProject.name,
          redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
        },
        {
          label: 'PROJECT_MODULE.PROJECT_VERSIONS_COMPONENT.PROJECT_VERSIONS',
          redirectUrl: PATH_DEFINITIONS.PROJECTS.PROJECT_VERSION_LIST
        },
        {
          label: projectVersion.versionNumber
        }
      ];
    });
  }

  onNavigateToProjectExportPage() {
    const exportPageURL = `${PATH_DEFINITIONS.PROJECTS.PROJECT_EXPORT}/${this.projectVersionId}`;
    this.routerStateService.navigate(exportPageURL);
  }

  onProjectExportDetailsTogglerClick() {
    this.isExportDetailsCollapsed = !this.isExportDetailsCollapsed;
    if (this.isExportDetailsCollapsed) {
      this.exportDetailsTogglerButton.text = 'PROJECT_MODULE.PROJECT_VERSION_DETAIL_COMPONENT.SHOW_EXPORT_DETAILS';
      this.exportDetailsTogglerButton.icon.name = 'down';
    } else {
      this.exportDetailsTogglerButton.text = 'PROJECT_MODULE.PROJECT_VERSION_DETAIL_COMPONENT.HIDE_EXPORT_DETAILS';
      this.exportDetailsTogglerButton.icon.name = 'up';
    }
  }

  handleExportDetailsHeight() {
    return this.isExportDetailsCollapsed ? '0px' : '1000px';
  }

  checkStatus(status: string) {
    this.statusPercent = Math.round((this.latestProjectExport.completedStepCount / this.latestProjectExport.totalStepCount) * 100);
    switch (this.latestProjectExport.status) {
      case ProjectExportStatus.Successful:
        this.progressStatus = ProgressStatus.Success;
        this.latestProjectExport = {
          ...this.latestProjectExport,
          statusCheck: 'fal fa-check',
        };
        break;
      case ProjectExportStatus.PartiallySuccessful:
        this.progressStatus = ProgressStatus.Success;
        this.latestProjectExport = {
          ...this.latestProjectExport,
          statusCheck: 'fal fa-check',
        };
        break;
      case ProjectExportStatus.Exporting:
        this.progressStatus = ProgressStatus.Active;
        this.latestProjectExport = {
          ...this.latestProjectExport,
          statusCheck: 'fas fa-cog fa-spin',
        };
        break;
      case ProjectExportStatus.Failure:
        this.progressStatus = ProgressStatus.Exception;
        this.latestProjectExport = {
          ...this.latestProjectExport,
          statusCheck: this.statusPercent < 100 ? 'fal fa-times' : 'fal fa-check',
        };
        break;
      default:
        break;
    }
    if (this.latestProjectExport.status !== ProjectExportStatus.Exporting || this.statusPercent === 100) {
      this.showText = true;
      this.progressInnerText = this.latestProjectExport.status === 'successful'
        ? 'Successfully Completed' : 'Partially Completed';
    } else {
      this.showText = false;
      this.progressInnerText = '';
    }
  }

}
