import { PropertyType } from '@rappider/rappider-components/utils';

export const ENVIRONMENT_VARIABLE_DATA_TYPES = [
  {
    key: 'String',
    value: PropertyType.String
  },
  {
    key: 'Number',
    value: PropertyType.Number
  },
  {
    key: 'Boolean',
    value: PropertyType.Boolean
  },
  {
    key: 'Date',
    value: PropertyType.Date
  },
  {
    key: 'Array',
    value: PropertyType.Array
  },
  {
    key: 'Object',
    value: PropertyType.Object
  }
];
