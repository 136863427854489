/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ApiResultDto } from '../models/api-result-dto';
import { CalculateJsonSchemaRequestDto } from '../models/calculate-json-schema-request-dto';
import { CalculateJsonSchemasByIdsResponseDto } from '../models/calculate-json-schemas-by-ids-response-dto';
import { DataSchema } from '../models/data-schema';
import { DataSchemaPartial } from '../models/data-schema-partial';
import { DataSchemaWithRelations } from '../models/data-schema-with-relations';
import { LastProcessInformation } from '../models/last-process-information';
import { NewDataSchema } from '../models/new-data-schema';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class DataSchemaControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation dataSchemaControllerCalculateJsonSchema
   */
  static readonly DataSchemaControllerCalculateJsonSchemaPath = '/data-schemas/calculate-json-schema/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calculateJSONSchema()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calculateJSONSchema$Response(params: {
    id: string;
    body?: CalculateJsonSchemaRequestDto
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaControllerService.DataSchemaControllerCalculateJsonSchemaPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calculateJSONSchema$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calculateJSONSchema(params: {
    id: string;
    body?: CalculateJsonSchemaRequestDto
  }): Observable<any> {

    return this.calculateJSONSchema$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation dataSchemaControllerCount
   */
  static readonly DataSchemaControllerCountPath = '/data-schemas/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params?: {
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaControllerService.DataSchemaControllerCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params?: {
    where?: any;
  }): Observable<LoopbackCount> {

    return this.count$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation dataSchemaControllerGetExampleDataByDataSchemaId
   */
  static readonly DataSchemaControllerGetExampleDataByDataSchemaIdPath = '/data-schemas/get-example-data-by-data-schema-id/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExampleDataByDataSchemaId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExampleDataByDataSchemaId$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaControllerService.DataSchemaControllerGetExampleDataByDataSchemaIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getExampleDataByDataSchemaId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExampleDataByDataSchemaId(params: {
    id: string;
  }): Observable<any> {

    return this.getExampleDataByDataSchemaId$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation dataSchemaControllerGetWithDetailsById
   */
  static readonly DataSchemaControllerGetWithDetailsByIdPath = '/data-schemas/get-with-details-by-id/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWithDetailsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWithDetailsById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<ApiResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaControllerService.DataSchemaControllerGetWithDetailsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResultDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWithDetailsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWithDetailsById(params: {
    id: string;
  }): Observable<ApiResultDto> {

    return this.getWithDetailsById$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResultDto>) => r.body as ApiResultDto)
    );
  }

  /**
   * Path part for operation dataSchemaControllerGetWithDetailsByIds
   */
  static readonly DataSchemaControllerGetWithDetailsByIdsPath = '/data-schemas/get-with-details-by-ids';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWithDetailsByIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getWithDetailsByIds$Response(params?: {
    body?: Array<string>
  }): Observable<StrictHttpResponse<Array<DataSchema>>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaControllerService.DataSchemaControllerGetWithDetailsByIdsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DataSchema>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWithDetailsByIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getWithDetailsByIds(params?: {
    body?: Array<string>
  }): Observable<Array<DataSchema>> {

    return this.getWithDetailsByIds$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DataSchema>>) => r.body as Array<DataSchema>)
    );
  }

  /**
   * Path part for operation dataSchemaControllerGetJsonSchemasByIds
   */
  static readonly DataSchemaControllerGetJsonSchemasByIdsPath = '/data-schemas/json-schemas-by-ids';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getJSONSchemasByIds()` instead.
   *
   * This method doesn't expect any request body.
   */
  getJSONSchemasByIds$Response(params?: {
    dataSchemaId?: Array<string>;
  }): Observable<StrictHttpResponse<Array<CalculateJsonSchemasByIdsResponseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaControllerService.DataSchemaControllerGetJsonSchemasByIdsPath, 'get');
    if (params) {
      rb.query('dataSchemaId', params.dataSchemaId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CalculateJsonSchemasByIdsResponseDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getJSONSchemasByIds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getJSONSchemasByIds(params?: {
    dataSchemaId?: Array<string>;
  }): Observable<Array<CalculateJsonSchemasByIdsResponseDto>> {

    return this.getJSONSchemasByIds$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CalculateJsonSchemasByIdsResponseDto>>) => r.body as Array<CalculateJsonSchemasByIdsResponseDto>)
    );
  }

  /**
   * Path part for operation dataSchemaControllerFindLatestChangeTimestamps
   */
  static readonly DataSchemaControllerFindLatestChangeTimestampsPath = '/data-schemas/latest-change-timestamps';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findLatestChangeTimestamps()` instead.
   *
   * This method doesn't expect any request body.
   */
  findLatestChangeTimestamps$Response(params?: {
  }): Observable<StrictHttpResponse<LastProcessInformation>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaControllerService.DataSchemaControllerFindLatestChangeTimestampsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LastProcessInformation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findLatestChangeTimestamps$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findLatestChangeTimestamps(params?: {
  }): Observable<LastProcessInformation> {

    return this.findLatestChangeTimestamps$Response(params).pipe(
      map((r: StrictHttpResponse<LastProcessInformation>) => r.body as LastProcessInformation)
    );
  }

  /**
   * Path part for operation dataSchemaControllerValidateDataByDataSchemaId
   */
  static readonly DataSchemaControllerValidateDataByDataSchemaIdPath = '/data-schemas/validate-data-by-data-schema-id/{dataSchemaId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateDataByDataSchemaId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateDataByDataSchemaId$Response(params: {
    dataSchemaId: string;
    options?: any;
    body?: any
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaControllerService.DataSchemaControllerValidateDataByDataSchemaIdPath, 'post');
    if (params) {
      rb.path('dataSchemaId', params.dataSchemaId, {});
      rb.query('options', params.options, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `validateDataByDataSchemaId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateDataByDataSchemaId(params: {
    dataSchemaId: string;
    options?: any;
    body?: any
  }): Observable<any> {

    return this.validateDataByDataSchemaId$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation dataSchemaControllerFindById
   */
  static readonly DataSchemaControllerFindByIdPath = '/data-schemas/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<DataSchema>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaControllerService.DataSchemaControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DataSchema>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
    filter?: any;
  }): Observable<DataSchema> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<DataSchema>) => r.body as DataSchema)
    );
  }

  /**
   * Path part for operation dataSchemaControllerDeleteById
   */
  static readonly DataSchemaControllerDeleteByIdPath = '/data-schemas/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaControllerService.DataSchemaControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: string;
  }): Observable<any> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation dataSchemaControllerUpdateById
   */
  static readonly DataSchemaControllerUpdateByIdPath = '/data-schemas/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    body?: DataSchemaPartial
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaControllerService.DataSchemaControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    body?: DataSchemaPartial
  }): Observable<any> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation dataSchemaControllerFind
   */
  static readonly DataSchemaControllerFindPath = '/data-schemas';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<DataSchemaWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaControllerService.DataSchemaControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DataSchemaWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<DataSchemaWithRelations>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DataSchemaWithRelations>>) => r.body as Array<DataSchemaWithRelations>)
    );
  }

  /**
   * Path part for operation dataSchemaControllerCreate
   */
  static readonly DataSchemaControllerCreatePath = '/data-schemas';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    body?: NewDataSchema
  }): Observable<StrictHttpResponse<DataSchema>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaControllerService.DataSchemaControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DataSchema>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    body?: NewDataSchema
  }): Observable<DataSchema> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<DataSchema>) => r.body as DataSchema)
    );
  }

}
