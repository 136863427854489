/* eslint-disable */

declare var Object: any;
export interface MessageTemplateDataFieldInterface {
  id?: string;
  fieldName: string;
  title: string;
}

export class MessageTemplateDataField
  implements MessageTemplateDataFieldInterface {
  'id'?: string;
  'fieldName': string;
  'title': string;
  constructor(data?: MessageTemplateDataFieldInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MessageTemplateDataField`.
   */
  public static getModelName() {
    return 'MessageTemplateDataField';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of MessageTemplateDataField for dynamic purposes.
   **/
  public static factory(
    data: MessageTemplateDataFieldInterface
  ): MessageTemplateDataField {
    return new MessageTemplateDataField(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'MessageTemplateDataField',
      plural: 'MessageTemplateDataFields',
      path: 'message-template-data-fields',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        fieldName: {
          name: 'fieldName',
          type: 'string',
        },
        title: {
          name: 'title',
          type: 'string',
        },
      },
      relations: {},
    };
  }
}
