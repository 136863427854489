import { CheckboxListDirection } from '@rappider/rappider-components/utils';

export const CheckboxListConfig = {
  direction: CheckboxListDirection.Column,
  options: [
    {
      value: 'create',
      key: {
        text: 'Create'
      },
      isSelected: true
    },
    {
      value: 'update',
      key: {
        text: 'Update'
      },
      isSelected: true
    },
    {
      value: 'delete',
      key: {
        text: 'Delete'
      },
      isSelected: false
    }
  ]
};
