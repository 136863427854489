import { Action, createAction, props } from '@ngrx/store';
import { GitActionWorflowHistoryItemResponseDto } from 'libs/rappider-sdk/src/lib/models/git-action-worflow-history-item-response-dto';
import { GitActionWorflowRunDetailResponseDto } from 'libs/rappider-sdk/src/lib/models/git-action-worflow-run-detail-response-dto';
import { LivePreviewInformation } from 'libs/shared/src/lib/interfaces/live-preview-information';

export enum ActionTypes {
  DeployProject = '[DeployManagement] DeployProject',
  DeployProjectSuccessful = '[DeployManagement] DeployProjectSuccessful',
  DeployProjectFailure = '[DeployManagement] DeployProjectFailure',
  GetLivePreviewInformation = '[DeployManagement] GetLivePreviewInformation',
  GetLivePreviewInformationSuccessful = '[DeployManagement] GetLivePreviewInformationSuccessful',
  GetLivePreviewInformationFailure = '[DeployManagement] GetLivePreviewInformationFailure',
  SetLivePreviewInformation = '[DeployManagement] SetLivePreviewInformation',
  StopLivePreviewInformationPolling = '[DeployManagement] StopLivePreviewInformationPolling',
  GetGitActionWorkflowRunDetails = '[DeployManagement] GetGitActionWorkflowRunDetails',
  GetGitActionWorkflowRunDetailsSuccessful = '[DeployManagement] GetGitActionWorkflowRunDetailsSuccessful',
  GetGitActionWorkflowRunDetailsFailure = '[DeployManagement] GetGitActionWorkflowRunDetailsFailure'
}

export const DeployProject = createAction(ActionTypes.DeployProject);

export const DeployProjectSuccessful = createAction(
  ActionTypes.DeployProjectSuccessful,
  props<{ backendURL: string; frontendURL: string }>()
);

export const DeployProjectFailure = createAction(
  ActionTypes.DeployProjectFailure,
  props<{ error: any; key: string; timeStamp: number }>()
);

export const GetLivePreviewInformation = createAction(
  ActionTypes.GetLivePreviewInformation,
  props<{ continuePollingAfterSuccessAndFailure: boolean }>()
);

export const GetLivePreviewInformationSuccessful = createAction(
  ActionTypes.GetLivePreviewInformationSuccessful,
  props<{ data: LivePreviewInformation }>()
);

export const GetLivePreviewInformationFailure = createAction(
  ActionTypes.GetLivePreviewInformationFailure,
  props<{ error: any; key: string; timeStamp: number }>()
);

export const SetLivePreviewInformation = createAction(
  ActionTypes.SetLivePreviewInformation,
  props<{ data: LivePreviewInformation }>()
);

export const GetGitActionWorkflowRunDetails = createAction(
  ActionTypes.GetGitActionWorkflowRunDetails,
  props<{ payload: { id: string } }>()
);

export const GetGitActionWorkflowRunDetailsSuccessful = createAction(
  ActionTypes.GetGitActionWorkflowRunDetailsSuccessful,
  props<{ runDetails: GitActionWorflowRunDetailResponseDto }>()
);

export const GetGitActionWorkflowRunDetailsFailure = createAction(
  ActionTypes.GetGitActionWorkflowRunDetailsFailure,
  props<{ payload: { error: any; key: string; timeStamp: number } }>()
);

export const StopLivePreviewInformationPolling = createAction(ActionTypes.StopLivePreviewInformationPolling);
