import { createAction, createReducer, on } from '@ngrx/store';
import { WorkflowStepFunctionSubscribedEventWithRelations } from '@rappider/rappider-sdk';

import * as WorkflowStepFunctionSubscribedEventActions from './workflow-step-function-subscribed-event.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

/* state key */
export const featureKey = 'workflowStepFunctionSubscribedEvent';

/* state interface */
export interface WorkflowStepFunctionSubscribedEventState {
  data: WorkflowStepFunctionSubscribedEventWithRelations[];
  isLoading: boolean;
  error: any;
  isDataFetched: boolean;
}

/* initial values */
export const initialState: WorkflowStepFunctionSubscribedEventState = {
  data: [],
  isLoading: false,
  error: null,
  isDataFetched: false
};

const ChangeActiveProjectSuccessful = createAction(AuthenticationActions.ActionTypes.ChangeActiveProjectSuccessful);
const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

export const reducer = createReducer(
  initialState,
  on(WorkflowStepFunctionSubscribedEventActions.CreateWorkflowStepFunctionSubscribedEvent, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(WorkflowStepFunctionSubscribedEventActions.CreateWorkflowStepFunctionSubscribedEventSuccessful, (state, action) => ({
    ...state,
    data: [
      ...state.data,
      action.payload.workflowStepFunctionSubscribedEvent
    ],
    isLoading: false,
  })),
  on(WorkflowStepFunctionSubscribedEventActions.AddWorkflowStepFunctionSubscribedEventSuccessful, (state, action) => ({
    ...state,
    data: [
      ...state.data,
      ...action.payload.workflowStepFunctionSubscribedEvent
    ],
    isLoading: false,
  })),
  on(WorkflowStepFunctionSubscribedEventActions.CreateWorkflowStepFunctionSubscribedEventFailure, (state, action) => ({
    ...state,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    },
    isLoading: false
  })),
  on(WorkflowStepFunctionSubscribedEventActions.GetWorkflowStepFunctionSubscribedEvent, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(WorkflowStepFunctionSubscribedEventActions.GetWorkflowStepFunctionSubscribedEventSuccessful, (state, action) => ({
    ...state,
    data: action.payload.workflowStepFunctionSubscribedEvents,
    isLoading: false,
    isDataFetched: true
  })),
  on(WorkflowStepFunctionSubscribedEventActions.UpdateWorkflowStepFunctionSubscribedEvent, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(WorkflowStepFunctionSubscribedEventActions.UpdateWorkflowStepFunctionSubscribedEventSuccessful, (state, action) => ({
    ...state,
    data: [
      ...state.data.filter(workflowStepFunctionSubscribedEvent => workflowStepFunctionSubscribedEvent.id !== action.payload.workflowStepFunctionSubscribedEventBody.id),
      action.payload.workflowStepFunctionSubscribedEventBody
    ],
    isLoading: false
  })),
  on(WorkflowStepFunctionSubscribedEventActions.UpdateWorkflowStepFunctionSubscribedEventFailure, (state, action) => ({
    ...state,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    },
    isLoading: false
  })),
  on(WorkflowStepFunctionSubscribedEventActions.DeleteWorkflowStepFunctionSubscribedEvent, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(WorkflowStepFunctionSubscribedEventActions.DeleteWorkflowStepFunctionSubscribedEventSuccessful, (state, action) => ({
    ...state,
    data: state.data?.filter(workflowStepFunctionSubscribedEvent => workflowStepFunctionSubscribedEvent.id !== action.payload.workflowStepFunctionSubscribedEventId),
    isLoading: false
  })),
  on(WorkflowStepFunctionSubscribedEventActions.DeleteWorkflowStepFunctionSubscribedEventFailure, (state, action) => ({
    ...state,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    },
    isLoading: false
  })),
  on(ChangeActiveProjectSuccessful, () => (initialState)),
  on(Logout, () => (initialState))
);
