import { createSelector } from '@ngrx/store';
import { Page } from '@rappider/rappider-sdk';

export const getTemplatePagesWithTags = createSelector(
  state => state['page']?.templatePages,
  state => state['page']?.isTemplatePagesLoaded,
  state => state['page']?.templatePagesTags,
  state => state['page']?.isTemplatePagesTagsLoaded,
  (templatePages: Page[],
    isTemplatePagesLoaded: boolean,
    templatePagesTags: string[],
    isTemplatePagesTagsLoaded: boolean) => {
    if (isTemplatePagesLoaded && isTemplatePagesTagsLoaded) {
      const templatePagesWithTags = {
        templatePages: templatePages,
        templatePagesTags: templatePagesTags
      };
      return templatePagesWithTags;
    }
  }
);
