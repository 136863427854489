import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { UI_DATA_EVENT_CREATE_OR_EDIT_CONFIG } from '@rappider/shared/configs';
import { defaultToolbarTitleHeadingSize, PATH_DEFINITIONS, QUERY_PARAM_DEFINITIONS } from '@rappider/shared/definitions';
import { BreadcrumbOption, CrudFormSelectItem, FormLayout, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { CreateUIDataEvent } from 'libs/project/src/lib/states/ui-data-event-state/ui-data-event.actions';
import { Subscription } from 'rxjs';
import { ProjectWithRelations, UiDataStore, UiDataStoreWithRelations } from '@rappider/rappider-sdk';
import { UIDataEventType } from 'libs/shared/src/lib/definitions/ui-data-event/ui-data-event-type.enum';
import { CreatedWorkflowItem } from 'libs/diagram-editor/src/lib/utils/interfaces/diagram-settings/created-workflow-item.interface';
import { DiagramItemType } from 'libs/diagram-editor/src/lib/utils/diagram-item-type';
import { cloneDeep } from 'lodash';
import { StringUtilityService } from '@rappider/services';
import { getActiveProjectDataSelector } from '@rappider/shared';

@Component({
  selector: 'rappider-data-event-create',
  templateUrl: './data-event-create.component.html',
  styleUrls: ['./data-event-create.component.scss']
})
export class DataEventCreateComponent implements OnInit, OnDestroy {
  /* ui data store id */
  @Input() uiDataStoreId: string;
  /* flag for whether to navigate after data event creation */
  @Input() navigateAfterCreate = true;
  /* layout of the form 'horizontal' or 'vertical' */
  @Input() formLayout: FormLayout;
  /* flag to display breadcrumb under title */
  @Input() displayBreadcrumb = true;
  /* flag to show ui data store selectbox */
  @Input() showUIDataStoreSelection = false;
  /* ui data stores */
  @Input() uiDataStores: UiDataStoreWithRelations[];

  @Output() lastCreatedUIDataEvent = new EventEmitter<CreatedWorkflowItem>();

  UI_DATA_EVENT_CREATE_CONFIG = cloneDeep(UI_DATA_EVENT_CREATE_OR_EDIT_CONFIG);
  /* main title */
  mainTitle: HeadingComponentConfig = {
    content: 'PROJECT_MODULE.DATA_EVENT_CREATE_COMPONENT.ADD_DATA_EVENT',
    type: defaultToolbarTitleHeadingSize
  };
  /* title breadcrumb */
  title: BreadcrumbOption[];
  subscriptions: Subscription[];
  activeProject: ProjectWithRelations;
  activeUiDataStore: UiDataStore;
  isLoading = false;

  defaultUIDataEventData = {
    name: null,
    type: UIDataEventType.Standard,
    uiDataStoreId: null,
    createSuccessAndFailureEvents: false
  };
  displayToolbar = false;
  displayToolbarBackButton = false;

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.setFormLayoutInConfig();
    this.getUIDataStoreIdFromUrl();
    this.subscribeToData();
    this.setUIDataStoreSelectField();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToUiDataStores(),
      this.subscribeToUiDataEventsLoading()
    ];
  }

  getUIDataStoreIdFromUrl() {
    if (!this.uiDataStoreId) {
      this.uiDataStoreId = this.activatedRoute.snapshot.params['dataStoreId'];
    }
  }

  subscribeToActiveProject() {
    return this.store.select(getActiveProjectDataSelector).subscribe((activeProject: ProjectWithRelations) => {
      this.activeProject = activeProject;
    });
  }

  subscribeToUiDataStores() {
    return this.store.select(state => state.uiDataStore?.data).subscribe((uiDataStores: UiDataStoreWithRelations[]) => {
      this.uiDataStores = uiDataStores ?? [];
      this.activeUiDataStore = uiDataStores?.find(uiDataStore => uiDataStore.id === this.uiDataStoreId);
      this.setTitle();

      if (this.showUIDataStoreSelection) {
        this.setUIDataStoreSelectOptions(uiDataStores);
      }
    });
  }

  subscribeToUiDataEventsLoading() {
    return this.store.select(state => state.uiDataEvent?.loading).subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
  }

  setTitle() {
    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`,
      },
      {
        label: 'PROJECT_MODULE.UI_DATA_STORE_LIST_COMPONENT.UI_DATA_STORE_LIST',
        redirectUrl: PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_LIST
      },
      {
        label: this.activeUiDataStore?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_DETAIL}/${this.uiDataStoreId}`,
        queryParams: QUERY_PARAM_DEFINITIONS.PROJECT.UI_DATA_STORE_DETAIL.EVENTS_TAB
      },
      {
        label: 'PROJECT_MODULE.DATA_EVENT_CREATE_COMPONENT.ADD_DATA_EVENT'
      }
    ];
  }

  onCreateUIDataEvent(uiDataEventCreateData: any) {
    this.store.dispatch(new CreateUIDataEvent({
      uiDataStoreId: this.activeUiDataStore?.id || uiDataEventCreateData.uiDataStoreId,
      uiDataEvent: uiDataEventCreateData,
      navigateAfterCreate: this.navigateAfterCreate,
      createSuccessAndFailureEvents: uiDataEventCreateData.createSuccessAndFailureEvents
    }));
    this.lastCreatedUIDataEvent.emit(
      {
        uiDataStoreId: this.activeUiDataStore?.id || uiDataEventCreateData.uiDataStoreId,
        name: uiDataEventCreateData.name,
        type: DiagramItemType.UIDataEvent
      });
  }

  setFormLayoutInConfig() {
    this.UI_DATA_EVENT_CREATE_CONFIG.formLayout = this.formLayout;
  }

  /**
   * removes ui data store select box from config if showUIDataStoreSelection is false
   *
   * @memberof DataEventCreateComponent
   */
  setUIDataStoreSelectField() {
    if (!this.showUIDataStoreSelection) {
      this.UI_DATA_EVENT_CREATE_CONFIG.items = this.UI_DATA_EVENT_CREATE_CONFIG.items.filter(item => item.fieldName !== 'uiDataStoreId');
      this.UI_DATA_EVENT_CREATE_CONFIG = { ...this.UI_DATA_EVENT_CREATE_CONFIG };
    }
  }

  setUIDataStoreSelectOptions(uiDataStores: UiDataStoreWithRelations[]) {
    const uiDataStoreFormItem = this.UI_DATA_EVENT_CREATE_CONFIG.items.find(item => item.fieldName === 'uiDataStoreId') as CrudFormSelectItem;
    uiDataStoreFormItem.options = uiDataStores?.map(uiDataStore => ({
      key: uiDataStore.name,
      value: uiDataStore.id
    })) ?? [];

    this.defaultUIDataEventData = {
      ...this.defaultUIDataEventData,
      uiDataStoreId: this.activeUiDataStore?.id
    };
  }

  onDataEventFieldValueChange(dataEvent: any) {
    this.defaultUIDataEventData = {
      ...this.defaultUIDataEventData,
      ...dataEvent
    };

    if (dataEvent.name || dataEvent.name === '') {
      this.defaultUIDataEventData = {
        ...this.defaultUIDataEventData,
        name: StringUtilityService.toTitleCase(dataEvent.name).replace(/\s/g, '') ?? '',
      };
    }
  }
}
