<div class="example-template-two">
  <div class="row justify-content-between">
    <div class="col-5">
      <rappider-card-one [image]="cardOne.image"
                         [titles]="cardOne.titles"
                         [currency]="cardOne.currency"
                         [price]="cardOne.price"
                         [finalPrice]="cardOne.finalPrice"
                         [descriptions]="cardOne.descriptions"></rappider-card-one>
    </div>
    <div class="content-right col-6">
      <div *ngFor="let title of titles">
        <rappider-heading [content]="title.content"
                          [type]="title.type"></rappider-heading>
      </div>
      <rappider-paragraph [content]="paragraph?.content"
                          [colorSettings]="paragraph?.colorSettings"></rappider-paragraph>
      <div class="buttons">
        <div *ngFor="let button of buttons"
             class="w-100">
          <rappider-button [type]="button.type"
                           [text]="button.text"
                           [block]="button.block"></rappider-button>
        </div>
      </div>
    </div>
  </div>
</div>
