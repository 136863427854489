import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { ProjectWithRelations } from '@rappider/rappider-sdk';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'rappider-project-create-preview',
  templateUrl: './project-create-preview.component.html',
  styleUrls: ['./project-create-preview.component.scss']
})
export class ProjectCreatePreviewComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  selectedTemplateNameConfig = {
    typography: {
      fontSize: 'var(--h3-font-size)',
      fontWeight: 'bold'
    },
    colorSettings: {
      color: 'var(--text-color)',
      backgroundColor: 'var(--component-inner-background-color)'
    }
  };
  selectedTemplateDescriptionConfig = {
    typography: {
      fontSize: 'var(--h4-font-size)'
    },
    colorSettings: {
      color: 'var(--text-color)'
    }
  };
  thumbnailTitleConfig = {
    typography: {
      fontSize: 'var(--h5-font-size)',
      fontWeight: 'bold'
    },
    colorSettings: {
      color: 'var(--text-color)'
    }
  };
  thumbnailDescriptionConfig = {
    typography: {
      fontSize: 'var(--h6-font-size)'
    },
    colorSettings: {
      color: 'var(--text-color)'
    }
  };
  publicProjectId: string;
  selectedTemplate: ProjectWithRelations;

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {
    this.publicProjectId = this.activatedRoute.snapshot.params['id'];
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToPublicProjects(),
    ];
  }

  subscribeToPublicProjects() {
    return this.store.select(state => state.project?.publicProjects).subscribe((publicProjects) => {
      if (publicProjects?.length) {
        this.selectedTemplate = publicProjects.find(publicProject => publicProject.id === this.publicProjectId);
      }
    });
  }

  backButtonClick() {
    this.router.navigate([PATH_DEFINITIONS.PROJECTS.PROJECT_CREATE_PATH]);
  }

  buildWithTemplateClick() {
    this.router.navigate([PATH_DEFINITIONS.PROJECTS.PROJECT_CREATE_PATH], { queryParams: { selectedTemplateId: this.publicProjectId } });
  }

}
