import { createAction, props } from '@ngrx/store';
import { NewOpenApi, OpenApiCreateDto, OpenApiEndpointWithRelations, OpenApiWithRelations } from '@rappider/rappider-sdk';

/* action types */
export enum ActionTypes {
  /* External API actions */
  GetOpenAPIsWithRelations = '[OpenAPI] GetOpenAPIsWithRelations',
  GetOpenAPIsWithRelationsSuccessful = '[OpenAPI] GetOpenAPIsWithRelationsSuccessful',
  CreateOpenAPI = '[OpenAPI] CreateOpenAPI',
  CreateOpenAPISuccessful = '[OpenAPI] CreateOpenAPISuccessful',
  DeleteOpenAPI = '[OpenAPI] DeleteOpenAPI',
  DeleteOpenAPISuccessful = '[OpenAPI] DeleteOpenAPISuccessful',
  DeleteOpenAPIEndpoint = '[OpenAPI] DeleteOpenAPIEndpoint',
  DeleteOpenAPIEndpointSuccessful = '[OpenAPI] DeleteOpenAPIEndpointSuccessful',
  ToggleImportOpenApiSpecModalVisibility = '[OpenAPI] ToggleImportOpenApiSpecModalVisibility',
  ErrorAction = '[OpenAPI] ErrorAction',
  ResetStateToInitial = '[OpenAPI] ResetStateToInitial',
  ResetStateToInitialChangeProject = '[OpenAPI] ResetStateToInitialChangeProject'
}

/* actions */
export const GetOpenAPIsWithRelations = createAction(ActionTypes.GetOpenAPIsWithRelations);

export const GetOpenAPIsWithRelationsSuccessful = createAction(
  ActionTypes.GetOpenAPIsWithRelationsSuccessful,
  props<{ payload: { openApiData: OpenApiWithRelations[] } }>()
);

export const CreateOpenAPI = createAction(
  ActionTypes.CreateOpenAPI,
  props<{ payload: { createOpenApiData: NewOpenApi } }>()
);

export const CreateOpenAPISuccessful = createAction(
  ActionTypes.CreateOpenAPISuccessful,
  props<{ payload: { openApi: OpenApiWithRelations } }>()
);

export const DeleteOpenAPI = createAction(
  ActionTypes.DeleteOpenAPI,
  props<{ payload: { openApiId: string } }>()
);

export const DeleteOpenAPISuccessful = createAction(
  ActionTypes.DeleteOpenAPISuccessful,
  props<{ payload: { openApiData: OpenApiWithRelations[] } }>()
);

export const DeleteOpenAPIEndpoint = createAction(
  ActionTypes.DeleteOpenAPIEndpoint,
  props<{ payload: { openApiEndpointId: string } }>()
);

export const DeleteOpenAPIEndpointSuccessful = createAction(
  ActionTypes.DeleteOpenAPIEndpointSuccessful,
  props<{ payload: { openApiData: OpenApiWithRelations[] } }>()
);

export const ToggleImportOpenApiSpecModalVisibility = createAction(ActionTypes.ToggleImportOpenApiSpecModalVisibility);

export const ErrorAction = createAction(
  ActionTypes.ErrorAction,
  props<{ payload: { error: any; key: string; timeStamp: number } }>()
);

export const ResetStateToInitial = createAction(ActionTypes.ResetStateToInitial);

export const ResetStateToInitialChangeProject = createAction(ActionTypes.ResetStateToInitialChangeProject);

