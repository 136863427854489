import * as RouterActions from './router.actions';

export const routerFeatureKey = 'router';

export interface State {
  queryParams: { [key: string]: string };
}

export const initialState: State = {
  queryParams: {}
};

export function reducer(
  state: State = initialState,
  action: RouterActions.Actions
): State {
  switch (action.type) {

    case RouterActions.ActionTypes.AddQueryParam: {
      /* clear query params from url */
      const url = action.payload.value?.split('?')[0];
      /* set query param */
      const queryParam = {
        [action.payload.key]: url
      };

      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          ...queryParam
        }
      };
    }

    case RouterActions.ActionTypes.RemoveQueryParam: {
      /* get query params */
      const queryParams = {
        ...state.queryParams
      };
      /* remove query param */
      delete queryParams[action.payload.key];

      return {
        ...state,
        queryParams: queryParams
      };
    }

    case RouterActions.ActionTypes.ClearQueryParams: {
      return {
        ...state,
        queryParams: initialState.queryParams
      };
    }

    default:
      return state;
  }
}
