/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PageWithRelations } from '../models/page-with-relations';

@Injectable({
  providedIn: 'root',
})
export class TemplatePageControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation templatePageControllerAutoCreatePageFromAiSuggestion
   */
  static readonly TemplatePageControllerAutoCreatePageFromAiSuggestionPath = '/template-pages/auto-create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `autoCreatePageFromAISuggestion()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  autoCreatePageFromAISuggestion$Response(params?: {
    body?: any
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatePageControllerService.TemplatePageControllerAutoCreatePageFromAiSuggestionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `autoCreatePageFromAISuggestion$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  autoCreatePageFromAISuggestion(params?: {
    body?: any
  }): Observable<any> {

    return this.autoCreatePageFromAISuggestion$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation templatePageControllerSuggestSiteMap
   */
  static readonly TemplatePageControllerSuggestSiteMapPath = '/template-pages/suggest-site-map';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `suggestSiteMap()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  suggestSiteMap$Response(params?: {
    body?: any
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatePageControllerService.TemplatePageControllerSuggestSiteMapPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `suggestSiteMap$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  suggestSiteMap(params?: {
    body?: any
  }): Observable<any> {

    return this.suggestSiteMap$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation templatePageControllerGetSuggestedTemplate
   */
  static readonly TemplatePageControllerGetSuggestedTemplatePath = '/template-pages/suggest-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSuggestedTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getSuggestedTemplate$Response(params?: {
    body?: any
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatePageControllerService.TemplatePageControllerGetSuggestedTemplatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSuggestedTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getSuggestedTemplate(params?: {
    body?: any
  }): Observable<any> {

    return this.getSuggestedTemplate$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation templatePageControllerFind
   */
  static readonly TemplatePageControllerFindPath = '/template-pages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
  }): Observable<StrictHttpResponse<Array<PageWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, TemplatePageControllerService.TemplatePageControllerFindPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PageWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
  }): Observable<Array<PageWithRelations>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PageWithRelations>>) => r.body as Array<PageWithRelations>)
    );
  }

}
