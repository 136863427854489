<div class="label">
  <label [style.font-size]="typography?.fontSize"
         [style.font-weight]="typography?.fontWeight"
         [style.font-style]="typography?.fontStyle"
         [style.text-align]="typography?.textAlign"
         [style.text-shadow]="typography?.textShadow"
         [style.font-family]="typography?.fontFamily"
         [style.font-stretch]="typography?.fontStretch"
         [style.text-indent]="typography?.textIndent"
         [style.letter-spacing]="typography?.letterSpacing"
         [style.line-height]="typography?.lineHeight"
         [style.word-spacing]="typography?.wordSpacing"
         [style.text-decoration]="typography?.textDecoration"
         [style.text-transform]="typography?.textTransform"
         [style.user-select]="typography?.userSelect"
         [style.background-color]="colorSettings?.backgroundColor"
         [style.color]="colorSettings?.color">{{ content }}</label>
</div>
