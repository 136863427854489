import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { PersonWithRelations } from '@rappider/rappider-sdk';
import { RouterStateService } from '@rappider/services';
import { QueryParam, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PersonGuard implements CanActivate {

  isNotificationSent = false;

  constructor(
    private routerStateService: RouterStateService,
    private store: Store<any>
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    /* check if person has company name */
    console.log('PERSON GUARD');
    return this.store.select(s => s.auth?.activePerson).pipe(
      map((activePerson: PersonWithRelations) => {
        if (activePerson) {
          if (!activePerson?.companyName) {
            this.routerStateService.addQueryParam(QueryParam.RedirectUrl, state.url);
            this.routerStateService.navigate(PATH_DEFINITIONS.PROFILE.PROFILE_PATH);
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      })
    );
  }

}
