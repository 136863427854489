import { Injectable } from '@angular/core';
import { Actions, createEffect, act, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { TokenService } from '@rappider/authentication/services';
import { LoginType, PATH_DEFINITIONS, elementsCategoryId, rappiderFeatureList, rightSidebarTabStorageKey, rightSidebarTabStorageValue, rightSidebarVisibilityStorageKey } from '@rappider/shared/definitions';

/* actions */
import * as AppActions from './app.actions';
import { LoginByAuthenticationToken, LoginByAuthenticationTokenFailure } from 'libs/authentication/src/lib/state/authentication.actions';
import { Store } from '@ngrx/store';
import { dataSchemasToDisplayInUserGeneratedDataSchemaList } from 'libs/shared/src/lib/definitions/basic-data-schema-categories';
import { getComponentDefinitionsWithDetailsSelector } from '@rappider/shared';

@Injectable()
export class AppEffects {

  constructor(
    private actions$: Actions,
    private tokenService: TokenService,
    private store: Store<any>
  ) { }


  loadApp$ = createEffect(() => this.actions$.pipe(
    ofType<AppActions.LoadApp>(AppActions.ActionTypes.LoadApp),
    map(() => new AppActions.LoadAppSuccessful())
  ));


  getRightSidebarTabName$ = createEffect(() => this.actions$.pipe(
    ofType<AppActions.GetRightSidebarTabName>(AppActions.ActionTypes.GetRightSidebarTabName),
    map(() => {
      const rightSidebarTabName = localStorage.getItem(rightSidebarTabStorageKey);
      if (!rightSidebarTabName) {
        return new AppActions.SetRightSidebarTabName({ rightSidebarTabName: rightSidebarTabStorageValue });
      } else {
        return new AppActions.SetRightSidebarTabName({ rightSidebarTabName });
      }
    })
  ));


  setRightSidebarTabName$ = createEffect(() => this.actions$.pipe(
    ofType<AppActions.SetRightSidebarTabName>(AppActions.ActionTypes.SetRightSidebarTabName),
    map((action) => {
      localStorage.setItem(rightSidebarTabStorageKey, action.payload.rightSidebarTabName);
      return { type: 'NO_ACTION' };
    })
  ));


  getRightSidebarVisibility$ = createEffect(() => this.actions$.pipe(
    ofType<AppActions.GetRightSidebarVisibility>(AppActions.ActionTypes.GetRightSidebarVisibility),
    map(() => {
      const rightSidebarVisibility = localStorage.getItem(rightSidebarVisibilityStorageKey);
      if (rightSidebarVisibility == null) {
        return new AppActions.SetRightSidebarVisibility({ rightSidebarVisibility: true });
      } else {
        return new AppActions.SetRightSidebarVisibility({ rightSidebarVisibility: rightSidebarVisibility === 'true' });
      }
    })
  ));


  setRightSidebarVisibility$ = createEffect(() => this.actions$.pipe(
    ofType<AppActions.SetRightSidebarVisibility>(AppActions.ActionTypes.SetRightSidebarVisibility),
    map((action) => {
      localStorage.setItem(rightSidebarVisibilityStorageKey, action.payload.rightSidebarVisibility.toString());
      return { type: 'NO_ACTION' };
    })
  ));


  toggleRightSidebarVisibility$ = createEffect(() => this.actions$.pipe(
    ofType<AppActions.ToggleRightSidebarVisibility>(AppActions.ActionTypes.ToggleRightSidebarVisibility),
    map(() => {
      const currentVisibility = localStorage.getItem(rightSidebarVisibilityStorageKey) === 'true';
      const newVisibility = !currentVisibility;
      localStorage.setItem(rightSidebarVisibilityStorageKey, newVisibility.toString());
      return new AppActions.SetRightSidebarVisibility({ rightSidebarVisibility: newVisibility });
    })
  ));

  /**
   * auto login by access token
   *
   * @memberof AppEffects
   */

  autoLogin$ = createEffect(() => this.actions$.pipe(
    ofType<AppActions.LoadApp>(AppActions.ActionTypes.LoadApp),
    mergeMap(() => {
      /* auto login */
      const authenticationToken = this.tokenService.getAuthenticationToken();
      if (authenticationToken) {
        return of(new LoginByAuthenticationToken({ authenticationToken: authenticationToken, loginType: LoginType.AuthenticationToken }));
      } else {
        return [new LoginByAuthenticationTokenFailure({ error: 'Authentication token could not found', key: 'LoginByAuthenticationTokenFailure', timestamp: Date.now() })];
      }
    })
  ));


  appSearch$ = createEffect(() => this.actions$.pipe(
    ofType<AppActions.AppSearch>(AppActions.ActionTypes.AppSearch),
    withLatestFrom(
      this.store.select(<any>getComponentDefinitionsWithDetailsSelector),
      this.store.select(state => state.uiDataStore.data),
      this.store.select(state => state.uiDataEvent.data),
      this.store.select(state => state.uiWorkflowStepFunction.data),
      this.store.select(state => state.dataSchema.data),
      this.store.select(state => state.page.data),
      this.store.select(state => state.layout.data),
      this.store.select(state => state.projectModel.data),
      this.store.select(state => state.module.data),
      this.store.select(state => state.customFunction.data),
      this.store.select(state => state.workflowEvent.data),
      this.store.select(state => state.workflowStepFunction.data),
    ),
    mergeMap(([
      action,
      componentDefinitions,
      uiDataStores,
      uiDataEvents,
      uiWorkflowStepFunctions,
      dataSchemas,
      pages,
      layouts,
      projectModels,
      modules,
      customFunctions,
      workflowEvents,
      workflowStepFunctions
    ]) => {
      const searchResults = [
        ...(rappiderFeatureList?.filter(featureList => featureList.label.toLowerCase().includes(action.payload.searchText.toLowerCase())) || []),
        ...(projectModels?.filter(projectModel => projectModel.name.toLowerCase().includes(action.payload.searchText.toLowerCase()))?.map(projectModel => ({
          label: projectModel.name,
          group: 'Project Model',
          url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_EDIT}/${projectModel.id}`
        })) || []),
        ...(uiDataStores?.filter(uiDataStore => uiDataStore.name.toLowerCase().includes(action.payload.searchText.toLowerCase()))?.map(uiDataStore => ({
          label: uiDataStore.name,
          group: 'UI Data Store',
          url: `${PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_DETAIL}/${uiDataStore.id}`
        })) || []),
        ...(pages?.filter(page => page.title.toLowerCase().includes(action.payload.searchText.toLowerCase()))?.map(page => ({
          label: page.title,
          group: 'Project Page',
          url: `${PATH_DEFINITIONS.CONTENT_EDITOR.CONTENT_EDITOR_PATH}/${page.id}`
        })) || []),
        ...(layouts?.filter(layout => layout.title.toLowerCase().includes(action.payload.searchText.toLowerCase()))?.map(layout => ({
          label: layout.title,
          group: 'Project Layout',
          url: `${PATH_DEFINITIONS.CONTENT_EDITOR.CONTENT_EDITOR_PATH}/${layout.id}`
        })) || []),
        ...(modules?.filter(module => module.name.toLowerCase().includes(action.payload.searchText.toLowerCase()))?.map(projectModule => ({
          label: projectModule.name,
          group: 'Project Modules',
          url: `${PATH_DEFINITIONS.MODULE.MODULE_DETAIL_PATH}/${projectModule.id}`
        })) || []),
        ...(uiDataEvents?.filter(uiDataEvent => uiDataEvent.name.toLowerCase().includes(action.payload.searchText.toLowerCase()))?.map(uiDataEvent => ({
          label: uiDataEvent.name,
          group: 'UI Data Event',
          url: `${PATH_DEFINITIONS.PROJECTS.DATA_EVENT_DETAIL}/${uiDataEvent.id}`
        })) || []),
        ...(uiWorkflowStepFunctions?.filter(uiWorkflowStepFunction => uiWorkflowStepFunction.name.toLowerCase().includes(action.payload.searchText.toLowerCase()))?.map(uiWorkflowStepFunction => ({
          label: uiWorkflowStepFunction.name,
          group: 'UI Workflow Step Function',
          url: `${PATH_DEFINITIONS.PROJECTS.UI_STEP_FUNCTION_DETAIL}/${uiWorkflowStepFunction.id}`
        })) || []),
        ...(customFunctions?.filter(customFunction => customFunction.serviceName.toLowerCase().includes(action.payload.searchText.toLowerCase()) || customFunction.functionName.toLowerCase().includes(action.payload.searchText.toLowerCase()))?.map(customFunction => ({
          label: `${customFunction.serviceName}.${customFunction.functionName}`,
          group: 'Custom Function',
          url: `${PATH_DEFINITIONS.WORKFLOW_FUNCTIONS.WORKFLOW_FUNCTION_EDIT}/${customFunction.id}`
        })) || []),
        // TODO: ADD PATH
        // ...(workflowEvents?.filter(workflowEvent => workflowEvent.name.toLowerCase().includes(action.payload.searchText.toLowerCase()))?.map(workflowEvent => ({
        //   label: workflowEvent.name,
        //   group: 'Workflow Event',
        //   url: ''
        // })) || []),
        ...(workflowStepFunctions?.filter(workflowStepFunction => workflowStepFunction.name.toLowerCase().includes(action.payload.searchText.toLowerCase()))?.map(workflowStepFunction => ({
          label: workflowStepFunction.name,
          group: 'Workflow Step Function',
          url: `${PATH_DEFINITIONS.WORKFLOW_STEP_FUNCTION.WORKFLOW_STEP_FUNCTION_DETAIL}/${workflowStepFunction.id}`
        })) || []),
        ...(dataSchemas?.filter(dataSchema =>
          dataSchemasToDisplayInUserGeneratedDataSchemaList.includes(dataSchema.category)
          && dataSchema.name.toLowerCase().includes(action.payload.searchText.toLowerCase())
        )?.map(dataSchema => ({
          label: dataSchema.name,
          group: 'Data Schema',
          url: `${PATH_DEFINITIONS.PROJECTS.DATA_SCHEMA_EDIT}/${dataSchema.id}`
        })) || []),
        ...(componentDefinitions?.filter(componentDefinition =>
          (
            componentDefinition.mainCategory.id === elementsCategoryId
            || componentDefinition.mainCategory.parentCategoryId === elementsCategoryId
          )
          && componentDefinition.title.toLowerCase().includes(action.payload.searchText.toLowerCase())
        )?.map(componentDefinition => ({
          label: componentDefinition.title,
          group: 'Element',
          url: `${PATH_DEFINITIONS.COMPONENT_BROWSER.COMPONENT_DEFINITION_DETAIL_PATH}/${componentDefinition.id}`
        })) || []),
      ];
      return [new AppActions.AppSearchSuccessful({ searchResults: searchResults })];
    })
  ));

}
