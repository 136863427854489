import { AbstractControl, ValidatorFn } from '@angular/forms';

export function nameExistsValidator(valueList): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (valueList?.includes(value)) {
      return { 'nameExists': true };
    }
    return null;
  };
}
