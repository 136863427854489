/* eslint-disable @typescript-eslint/no-use-before-define */
import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ProjectFile, ProjectFileControllerService } from '@rappider/rappider-sdk';
import { ExternalScriptType, EXTERNAL_SCRIPT_TYPE_OPTIONS, PROJECT_FILE_EXTENSIONS } from '@rappider/shared/definitions';
import { ADD_PROJECT_SCRIPT_FORM_CONFIG } from '../../../../../../shared/src/lib/configs/project/add-project-script-config';
import { FILE_LIST_CONFIG } from '../definitions/file-list-config';

@Component({
  selector: 'rappider-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileListComponent),
      multi: true
    }
  ]
})
export class FileListComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input() projectId: string;
  @Input() externalScriptType: ExternalScriptType;

  @Output() fileSelect = new EventEmitter<ProjectFile>();

  fileList: ProjectFile[];
  fileListItem = {
    visible: false,
    loading: false
  };

  selectedFile: ProjectFile;
  ADD_PROJECT_SCRIPT_FORM_CONFIG = ADD_PROJECT_SCRIPT_FORM_CONFIG;
  FILE_LIST_CONFIG = FILE_LIST_CONFIG;

  get value() {
    return this.selectedFile;
  }

  set value(value: ProjectFile) {
    this.selectedFile = value;
    this.onChange(value);
    this.onTouched();
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: ProjectFile) {
    this.selectedFile = value;
    this.setFileListSelectedState(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  constructor(
    private projectFileApi: ProjectFileControllerService
  ) { }


  ngOnInit(): void {
    this.getProjectFileByExternalScriptType(this.externalScriptType);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.externalScriptType) {
      this.getProjectFileByExternalScriptType(changes.externalScriptType.currentValue);
    }
  }

  getProjectFileByExternalScriptType(externalScriptType?: ExternalScriptType) {
    this.fileListItem.loading = true;
    const fileExtensionsByExternalScriptType = externalScriptType === ExternalScriptType.Style
      ? PROJECT_FILE_EXTENSIONS.styleExtensions
      : PROJECT_FILE_EXTENSIONS.scriptExtensions;
    if (this.projectId) {
      const params = fileExtensionsByExternalScriptType
        ? {
          filter: {
            where: {
              and: [
                { projectId: this.projectId },
                {
                  or: [
                    ...fileExtensionsByExternalScriptType.map(extension => ({ extension: extension }))
                  ]
                }
              ]
            }
          }
        }
        : {
          filter: {
            where: {
              projectId: this.projectId
            }
          }
        };
      return this.projectFileApi.find(params).subscribe((files: ProjectFile[]) => {
        this.fileListItem.visible = true;
        this.fileList = files.map(item => ({
          ...item,
          selected: item?.id === this.selectedFile?.id ? true : false
        }));
        this.fileListItem.loading = false;
      });
    }
  }

  setFileListSelectedState(value: any) {
    this.fileList = this.fileList?.map(file => ({
      ...file,
      selected: value?.id === file.id ? true : false
    }));
  }

  onAddFile(itemAction) {
    if (itemAction.action.name === 'addFile') {
      const selectedFile = itemAction.data;
      this.value = selectedFile;
      this.fileSelect.emit(selectedFile);
    }
  }

}
