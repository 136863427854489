import { createAction, props } from '@ngrx/store';
import { Metadata, Project, ProjectPartial, ProjectWithRelations } from '@rappider/rappider-sdk';
import { StateError } from '@rappider/shared/interfaces';
import { ProjectStructureType } from 'libs/shared/src/lib/definitions/project-structure-type.enum';
import { ProjectVisibility } from 'libs/shared/src/lib/definitions/project-visibility.enum';

export enum ActionTypes {
  GetAdminProjects = '[AdminProjects] GetAdminProjects',
  GetAdminProjectsSuccessful = '[AdminProjects] GetAdminProjectsSuccessful',
  GetAdminProjectsFailure = '[AdminProjects] GetAdminProjectsFailure',
  DeleteAdminProject = '[AdminProjects] DeleteAdminProject',
  DeleteAdminProjectSuccessful = '[AdminProjects] DeleteAdminProjectSuccessful',
  DeleteAdminProjectFailure = '[AdminProjects] DeleteAdminProjectFailure',
  UpdateAdminProject = '[AdminProjects] UpdateAdminProject',
  UpdateAdminProjectSuccessful = '[AdminProjects] UpdateAdminProjectSuccessful',
  UpdateAdminProjectFailure = '[AdminProjects] UpdateAdminProjectFailure',
  GetPaginationBySearchCount = '[AdminProjects] GetPaginationBySearchCount',
  GetPaginationBySearchCountSuccessful = '[AdminProjects] GetPaginationBySearchCountSuccessful',
  GetPaginationBySearchCountFailure = '[AdminProjects] GetPaginationBySearchCountFailure',
  UpdateProjectByAdmin = '[AdminProjects] UpdateProjectByAdmin',
  UpdateProjectByAdminSuccessful = '[AdminProjects] UpdateProjectByAdminSuccessful',
  UpdateProjectByAdminFailure = '[AdminProjects] UpdateProjectByAdminFailure'
}

/* get */
export const GetAdminProjects = createAction(
  ActionTypes.GetAdminProjects,
  props<{ pageIndex: number; pageSize: number; searchText?: string }>()
);

export const GetAdminProjectsSuccessful = createAction(
  ActionTypes.GetAdminProjectsSuccessful,
  props<{ projects: ProjectWithRelations[] }>()
);

export const GetAdminProjectsFailure = createAction(
  ActionTypes.GetAdminProjectsFailure,
  props<{ error: any; key: string }>()
);

/* delete */
export const DeleteAdminProject = createAction(
  ActionTypes.DeleteAdminProject,
  props<{ projectId: string }>()
);

export const DeleteAdminProjectSuccessful = createAction(
  ActionTypes.DeleteAdminProjectSuccessful,
  props<{ projectId: string }>()
);

export const DeleteAdminProjectFailure = createAction(
  ActionTypes.DeleteAdminProjectFailure,
  props<{ error: any; key: string }>()
);

/* update - edit */
export const UpdateAdminProject = createAction(
  ActionTypes.UpdateAdminProject,
  props<{
    projectId: string;
    project: Partial<Project>;
    metadataCRUD?: {
      newMetadataItems?: Omit<Metadata, 'id'>[];
      updatedMetadataItems?: Partial<Metadata>[];
      deletedMetadataIds?: string[];
    };
    redirectToProjectDetail?: boolean;
  }>()
);

export const UpdateAdminProjectSuccessful = createAction(
  ActionTypes.UpdateAdminProjectSuccessful,
  props<{ project: Partial<ProjectWithRelations>; projectId: string }>()
);

export const UpdateAdminProjectFailure = createAction(
  ActionTypes.UpdateAdminProjectFailure,
  props<{ error: any; key: string }>()
);

export const GetPaginationBySearchCount = createAction(
  ActionTypes.GetPaginationBySearchCount,
  props<{ pageIndex?: number; pageSize?: number; searchText?: string }>()
);

export const GetPaginationBySearchCountSuccessful = createAction(
  ActionTypes.GetPaginationBySearchCountSuccessful,
  props<{ pageIndex: number; pageSize: number; searchText?: string; count?: number }>()
);

export const GetPaginationBySearchCountFailure = createAction(
  ActionTypes.GetPaginationBySearchCountFailure,
  props<{ error: any; key: string }>()
);

export const UpdateProjectByAdmin = createAction(
  ActionTypes.UpdateProjectByAdmin,
  props<{ publicProject: ProjectPartial }>()
);

export const UpdateProjectByAdminSuccessful = createAction(
  ActionTypes.UpdateProjectByAdminSuccessful,
  props<{ publicProject: ProjectPartial }>()
);

export const UpdateProjectByAdminFailure = createAction(
  ActionTypes.UpdateProjectByAdminFailure,
  props<StateError>()
);
