import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ComponentDefinitionActions from './component-definition.actions';
import { mergeMap, map, catchError, withLatestFrom, shareReplay } from 'rxjs/operators';
import { ComponentDefinitionApi, ComponentInputDefinitionApi } from '@rappider/api-sdk';
import { COMPONENT_DEFINITION_GET_FROM_SERVER_LIMIT, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Action, Store } from '@ngrx/store';
import { NotificationService, PersonService } from '@rappider/services';
import { cloneDeep } from 'lodash';
import {
  BulkUpdateResultDto,
  ComponentDefinitionControllerService,
  ComponentDefinitionWithRelations,
  ComponentOutputDefinition,
  ComponentOutputDefinitionControllerService,
  ComponentOutputDefinitionWithRelations,
  ComponentSampleControllerService,
} from '@rappider/rappider-sdk';
import { Navigate, NavigateFailure } from 'libs/shared/src/lib/states/router/router.actions';
import { getComponentDefinitionsWithDetailsSelector } from '@rappider/shared';

@Injectable()
export class ComponentDefinitionEffects {

  constructor(
    private actions$: Actions,
    private componentDefinitionApi: ComponentDefinitionApi,
    private store: Store<any>,
    private notificationService: NotificationService,
    private componentInputDefinitionApi: ComponentInputDefinitionApi,
    private componentOutputDefinitionApi: ComponentOutputDefinitionControllerService,
    private componentDefinitionControllerService: ComponentDefinitionControllerService,
    private componentSampleApi: ComponentSampleControllerService,
  ) { }


  loadModule$ = createEffect(() => this.actions$.pipe(
    ofType<ComponentDefinitionActions.LoadModule>(ComponentDefinitionActions.ActionTypes.LoadModule),
    map(() => new ComponentDefinitionActions.LoadComponentDefinitions())
  ));


  loadComponentDefinitions$ = createEffect(() => this.actions$.pipe(
    ofType<ComponentDefinitionActions.LoadComponentDefinitions>(ComponentDefinitionActions.ActionTypes.LoadComponentDefinitions),
    withLatestFrom(
      this.store.select(state => state.category.data),
    ),
    mergeMap(([action, categoryInState]) => {
      const params = {
        filter: {
          include: ['inputDefinitions', 'outputDefinitions', 'samples']
        }
      };
      return this.componentDefinitionControllerService.find(params).pipe(
        shareReplay(COMPONENT_DEFINITION_GET_FROM_SERVER_LIMIT),
        map((componentDefinitions: ComponentDefinitionWithRelations[]) => {
          if (categoryInState?.length) {
            const componentDefinitionsWithMainCategory = componentDefinitions.map(componentDefinition => ({
              ...componentDefinition,
              mainCategory: categoryInState.find(category => category.id === componentDefinition.categoryId)
            }));
            return new ComponentDefinitionActions.LoadComponentDefinitionsSuccess({ componentDefinitions: componentDefinitionsWithMainCategory });
          } else {
            return new ComponentDefinitionActions.LoadComponentDefinitionsAfterGetCategories({ navigateToLoadComponentDefinitions: true });
          }
        }),
        catchError((error) => [new ComponentDefinitionActions.LoadComponentDefinitionsFailure({ error, key: 'LoadComponentDefinitionsFailure', timestamp: Date.now() })]
        )
      );
    }
    )
  ));


  deleteOutputDefinition$ = createEffect(() => this.actions$.pipe(
    ofType<ComponentDefinitionActions.DeleteOutputDefinition>(ComponentDefinitionActions.ActionTypes.DeleteOutputDefinition),
    mergeMap(action => {
      const outputDefinitionId = { id: action.payload.outputDefinitionId };

      return this.componentOutputDefinitionApi.deleteById(outputDefinitionId).pipe(
        map(() => {
          const componentDefinitionId = action.payload.componentDefinitionId;
          const deletedOutputDefinitionId = [action.payload.outputDefinitionId];
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESS',
            'COMPONENT_BROWSER_MODULE.COMPONENT_DEFINITION_DETAIL_PAGE_COMPONENT.OUTPUT_DEFINITION_DELETED'
          );
          return new ComponentDefinitionActions.DeleteComponentOutputDefinitionsSuccess({
            componentDefinitionId,
            deletedComponentOutputDefinitionIds: deletedOutputDefinitionId
          });
        }), catchError((error) => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'COMPONENT_BROWSER_MODULE.COMPONENT_DEFINITION_DETAIL_PAGE_COMPONENT.OUTPUT_DEFINITION_COULDNT_DELETED'
          );
          return [new ComponentDefinitionActions.DeleteComponentOutputDefinitionsFailure({ error, key: 'DeleteComponentOutputDefinitionsFailure', timestamp: Date.now() })];
        })
      );
    })
  ));


  deleteOutputDefinitions$ = createEffect(() => this.actions$.pipe(
    ofType<ComponentDefinitionActions.DeleteOutputDefinitions>(ComponentDefinitionActions.ActionTypes.DeleteOutputDefinitions),
    mergeMap(action => {
      const componentDefinitionId = action.payload.componentDefinitionId;
      const deletedOutputDefinitionIds = action.payload.deletedOutputDefinitionIds;

      return this.componentOutputDefinitionApi.bulkDelete({ body: deletedOutputDefinitionIds }).pipe(
        map(() => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESS',
            'COMPONENT_BROWSER_MODULE.COMPONENT_DEFINITION_DETAIL_PAGE_COMPONENT.OUTPUT_DEFINITION_DELETED'
          );
          return new ComponentDefinitionActions.DeleteComponentOutputDefinitionsSuccess(
            { componentDefinitionId: componentDefinitionId, deletedComponentOutputDefinitionIds: deletedOutputDefinitionIds }
          );
        }), catchError((error) => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'COMPONENT_BROWSER_MODULE.COMPONENT_DEFINITION_DETAIL_PAGE_COMPONENT.OUTPUT_DEFINITION_COULDNT_DELETED'
          );
          return [new ComponentDefinitionActions.DeleteComponentOutputDefinitionsFailure({ error, key: 'DeleteComponentOutputDefinitionsFailure', timestamp: Date.now() })];
        })
      );
    })
  ));


  deleteInputDefinitions$ = createEffect(() => this.actions$.pipe(
    ofType<ComponentDefinitionActions.DeleteInputDefinitions>(ComponentDefinitionActions.ActionTypes.DeleteInputDefinitions),
    withLatestFrom(
      this.store.select(state => state.componentDefinition.data)
    ),
    mergeMap(([action, componentDefinitions]) => {
      const componentDefinitionId = action.payload.componentDefinitionId;
      const deletedInputDefinitionIds = action.payload.inputDefinitionIds;
      return this.componentInputDefinitionApi.bulkDelete(deletedInputDefinitionIds).pipe(
        map(() => {
          const componentDefinitionsData = cloneDeep(componentDefinitions);
          const updatedComponentDefinition = componentDefinitionsData.find(
            componentDefinition => componentDefinition.id === componentDefinitionId
          );
          updatedComponentDefinition.inputDefinitions = updatedComponentDefinition.inputDefinitions.filter(
            inputDefinition => !deletedInputDefinitionIds.some(inputDefinitionId => inputDefinitionId === inputDefinition.id)
          );

          this.notificationService.createNotification('success', 'SHARED.SUCCESS', 'COMPONENT_BROWSER_MODULE.COMPONENT_DEFINITION_DETAIL_PAGE_COMPONENT.INPUT_DEFINITION_DELETED');
          return new ComponentDefinitionActions.LoadComponentDefinitionsSuccess({ componentDefinitions: componentDefinitionsData });
        }), catchError((error) => {
          this.notificationService.createNotification('error', 'SHARED.ERROR', 'COMPONENT_BROWSER_MODULE.COMPONENT_DEFINITION_DETAIL_PAGE_COMPONENT.INPUT_DEFINITION_COULDNT_DELETED');
          return [new ComponentDefinitionActions.LoadComponentDefinitionsFailure({ error, key: 'LoadComponentDefinitionsFailure', timestamp: Date.now() })];
        })
      );
    })
  ));


  deleteSampleInput$ = createEffect(() => this.actions$.pipe(
    ofType<ComponentDefinitionActions.DeleteSampleInput>(ComponentDefinitionActions.ActionTypes.DeleteSampleInput),
    mergeMap(action => {
      const params = {
        id: action.payload.sampleInputId
      };
      return this.componentSampleApi.deleteById(params).pipe(
        map(() => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESS',
            'COMPONENT_BROWSER_MODULE.COMPONENT_DEFINITION_DETAIL_PAGE_COMPONENT.SAMPLE_INPUT_DELETED');
          return new ComponentDefinitionActions.DeleteSampleInputSuccessful({ sampleInputId: action.payload.sampleInputId });
        }), catchError(() => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'COMPONENT_BROWSER_MODULE.COMPONENT_DEFINITION_DETAIL_PAGE_COMPONENT.SAMPLE_INPUT_COULDNT_DELETED'
          );
          return [new ComponentDefinitionActions.DeleteSampleInputFailure()];
        })
      );
    })
  ));


  createComponentDefinition$ = createEffect(() => this.actions$.pipe(
    ofType<ComponentDefinitionActions.CreateComponentDefinition>(ComponentDefinitionActions.ActionTypes.CreateComponentDefinition),
    mergeMap((action) => {
      /* component definition create post body */
      const requestBody = {
        body: {
          ...action.payload.componentDefinition,
          subCategoryIds: action.payload.componentDefinition.subCategoryIds || [],
          previewImgUrls: action.payload.componentDefinition.previewImgUrls || [],
          tags: action.payload.componentDefinition.tags || [],
          isSystemGenerated: false
        }
      };

      /* create component definition api call */
      return this.componentDefinitionControllerService.create(requestBody).pipe(
        map(newComponentDefinition => {
          if (newComponentDefinition) {
            this.notificationService.createNotification(
              'success',
              'SHARED.SUCCESS',
              'COMPONENT_BROWSER_MODULE.COMPONENT_DEFINITION_CREATED'
            );
            return new ComponentDefinitionActions.CreateComponentDefinitionSuccessful({
              createdComponentDefinition: newComponentDefinition,
              navigateToDetailPage: action.payload.navigateToDetailPage
            });
          }
        }), catchError(() => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'COMPONENT_BROWSER_MODULE.COMPONENT_DEFINITION_COULDNT_CREATED');
          return [new ComponentDefinitionActions.CreateComponentDefinitionFailure()];
        }));
    })
  ));


  createComponentDefinitionSuccessful$ = createEffect(() => this.actions$.pipe(
    ofType<ComponentDefinitionActions.CreateComponentDefinitionSuccessful>
      (ComponentDefinitionActions.ActionTypes.CreateComponentDefinitionSuccessful),
    mergeMap(action => {
      const createdComponentDefinition = action.payload.createdComponentDefinition;
      if (action.payload.navigateToDetailPage) {
        return [
          new Navigate({ url: `${PATH_DEFINITIONS.COMPONENT_BROWSER.COMPONENT_DEFINITION_DETAIL_PATH}/${createdComponentDefinition.id}` }),
        ];
      }
    })
  ));


  deleteComponentDefinition$ = createEffect(() => this.actions$.pipe(
    ofType<ComponentDefinitionActions.DeleteComponentDefinition>
      (ComponentDefinitionActions.ActionTypes.DeleteComponentDefinition),
    withLatestFrom(
      this.store.select(<any>getComponentDefinitionsWithDetailsSelector)
    ),
    mergeMap(([action, componentDefinitions]) => {
      const postBody = {
        id: action.payload.componentDefinitionId
      };
      const deletedComponentDefinition = componentDefinitions
        .find(componentDefinition => componentDefinition.id === action.payload.componentDefinitionId);

      return this.componentDefinitionControllerService.deleteById(postBody).pipe(
        mergeMap(() => {
          const actions: Action[] = [
            new ComponentDefinitionActions.DeleteComponentDefinitionSuccessful({ componentDefinitionId: postBody.id })
          ];
          if (action.payload.navigateToComponentBrowser) {
            actions.push(
              new Navigate({ url: `${PATH_DEFINITIONS.COMPONENT_BROWSER.COMPONENT_BROWSER_PATH}` })
            );
          }
          this.notificationService.createNotification(
            'success',
            deletedComponentDefinition.title,
            'COMPONENT_BROWSER_MODULE.EDIT_COMPONENT_DEFINITION.COMPONENT_DEFINITION_DELETED'
          );
          return actions;
        }), catchError(() => {
          this.notificationService.createNotification(
            'error',
            deletedComponentDefinition.title,
            'COMPONENT_BROWSER_MODULE.EDIT_COMPONENT_DEFINITION.COMPONENT_DEFINITION_COULDNT_DELETED'
          );
          return [
            new ComponentDefinitionActions.DeleteComponentDefinitionFailure()
          ];
        })
      );
    })
  ));


  createComponentInputDefinitions$ = createEffect(() => this.actions$.pipe(
    ofType<ComponentDefinitionActions.CreateComponentInputDefinitions>(
      ComponentDefinitionActions.ActionTypes.CreateComponentInputDefinitions
    ),
    withLatestFrom(
      this.store.select(state => state.componentDefinition.data),
      this.store.select(state => state?.auth?.activePerson)
    ),
    mergeMap(([action, componentDefinitions, person]) => {
      const reqBody = {
        ...action.payload,
        inputDefinitions: action.payload.inputDefinitions
      };
      const componentDefinitionId = action.payload.componentDefinitionId;
      return this.componentInputDefinitionApi.bulkCreate(reqBody).pipe(
        mergeMap(newComponentInputDefinitions => {
          const cloneComponentDefinitions = cloneDeep(componentDefinitions);
          const componentDefinitionToUpdate = cloneComponentDefinitions.find(
            componentDefinition => componentDefinition.id === componentDefinitionId
          );

          componentDefinitionToUpdate.inputDefinitions = componentDefinitionToUpdate.inputDefinitions?.concat(newComponentInputDefinitions.created)
            || newComponentInputDefinitions.created;

          if (cloneComponentDefinitions?.length) {
            this.notificationService.createNotification(
              'success',
              'SHARED.SUCCESS',
              'COMPONENT_BROWSER_MODULE.COMPONENT_DEFINITION_DETAIL_PAGE_COMPONENT.INPUT_DEFINITION_CREATED'
            );
            return [
              new ComponentDefinitionActions.CreateComponentInputDefinitionSuccess(
                { createdComponentInputDefinitions: action.payload.inputDefinitions, componentDefinitionId: componentDefinitionId }
              ),
              new ComponentDefinitionActions.LoadComponentDefinitionsSuccess({ componentDefinitions: cloneComponentDefinitions })
            ];
          }
        }), catchError(() => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'COMPONENT_BROWSER_MODULE.COMPONENT_DEFINITION_DETAIL_PAGE_COMPONENT.INPUT_DEFINITION_COULDNT_CREATED'
          );

          return [
            new ComponentDefinitionActions.CreateComponentInputDefinitionFailure()
          ];
        }));
    })
  ));


  createComponentOutputDefinition$ = createEffect(() => this.actions$.pipe(
    ofType<ComponentDefinitionActions.CreateComponentOutputDefinitions>(
      ComponentDefinitionActions.ActionTypes.CreateComponentOutputDefinitions
    ),
    withLatestFrom(
      this.store.select(state => state?.auth?.activePerson)
    ),
    mergeMap(([action, person]) => {
      const createdComponentOutputDefinitions = action.payload.outputDefinitions;
      const componentDefinitionId = action.payload.componentDefinitionId;
      const request = {
        body: createdComponentOutputDefinitions
      };
      return this.componentOutputDefinitionApi.bulkCreate(request).pipe(
        map((outputDefinitions: ComponentOutputDefinitionWithRelations[]) => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESS',
            'COMPONENT_BROWSER_MODULE.COMPONENT_DEFINITION_DETAIL_PAGE_COMPONENT.OUTPUT_DEFINITION_CREATED'
          );
          return new ComponentDefinitionActions.CreateComponentOutputDefinitionsSuccess(
            { componentOutputDefinitions: outputDefinitions, componentDefinitionId: componentDefinitionId }
          );
        }), catchError((error) => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'COMPONENT_BROWSER_MODULE.COMPONENT_DEFINITION_DETAIL_PAGE_COMPONENT.OUTPUT_DEFINITION_COULDNT_CREATED'
          );
          return [new ComponentDefinitionActions.CreateComponentOutputDefinitionsFailure({ error, key: 'CreateComponentOutputDefinitionsFailure', timestamp: Date.now() })];
        }));
    })
  ));


  createSampleInput$ = createEffect(() => this.actions$.pipe(
    ofType<ComponentDefinitionActions.CreateSampleInput>(ComponentDefinitionActions.ActionTypes.CreateSampleInput),
    mergeMap((action) => {
      const sampleInput = {
        ...action.payload.sampleInput,
        componentDefinitionId: action.payload.componentDefinitionId
      };

      return this.componentSampleApi.create({ body: sampleInput }).pipe(
        map(createdComponentSample => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESS',
            'COMPONENT_BROWSER_MODULE.COMPONENT_DEFINITION_DETAIL_PAGE_COMPONENT.SAMPLE_INPUT_CREATED'
          );

          return new ComponentDefinitionActions.CreateSampleInputSuccessful({ sampleInput: createdComponentSample, componentDefinitionId: action.payload.componentDefinitionId });
        }), catchError(() => {
          this.notificationService.createNotification('error', 'SHARED.ERROR', 'COMPONENT_BROWSER_MODULE.COMPONENT_DEFINITION_DETAIL_PAGE_COMPONENT.SAMPLE_INPUT_COULDNT_CREATED');
          return [new ComponentDefinitionActions.CreateSampleInputFailure()];
        }));
    })
  ));


  updateInputDefinitions$ = createEffect(() => this.actions$.pipe(
    ofType<ComponentDefinitionActions.UpdateInputDefinitions>(ComponentDefinitionActions.ActionTypes.UpdateInputDefinitions),
    withLatestFrom(
      this.store.select(state => state?.auth?.activePerson),
      this.store.select(state => state.componentDefinition.data)
    ),
    mergeMap(([action, person, componentDefinitions]) => {

      const updatedInputDefinitionsBody = action.payload.inputDefinitions;

      return this.componentInputDefinitionApi.bulkUpdate(updatedInputDefinitionsBody).pipe(
        map((updatedInputDefinitions) => {
          const componentDefinitionsData = cloneDeep(componentDefinitions);
          const componentDefinitionId = action.payload.componentDefinitionId;
          const updatedComponentDefinition = componentDefinitionsData.find(
            componentDefinition => componentDefinition.id === componentDefinitionId
          );

          updatedComponentDefinition.inputDefinitions = updatedComponentDefinition.inputDefinitions?.filter(
            inputDefinition => !updatedInputDefinitions.updated.some(
              updatedInputDefinition => updatedInputDefinition.id === inputDefinition.id
            )
          );

          updatedComponentDefinition.inputDefinitions = updatedComponentDefinition.inputDefinitions?.concat(updatedInputDefinitions.updated)
            || updatedInputDefinitions.updated;

          this.notificationService.createNotification('success', 'SHARED.SUCCESS', 'COMPONENT_BROWSER_MODULE.COMPONENT_DEFINITION_DETAIL_PAGE_COMPONENT.INPUT_DEFINITION_UPDATED');
          return new ComponentDefinitionActions.LoadComponentDefinitionsSuccess({ componentDefinitions: componentDefinitionsData });
        }), catchError((error) => {
          this.notificationService.createNotification(
            'error', 'SHARED.ERROR', 'COMPONENT_BROWSER_MODULE.COMPONENT_DEFINITION_DETAIL_PAGE_COMPONENT.INPUT_DEFINITION_COULDNT_UPDATED');
          return [new ComponentDefinitionActions.LoadComponentDefinitionsFailure({ error, key: 'LoadComponentDefinitionsFailure', timestamp: Date.now() })];
        }));
    })
  ));


  updateOutputDefinition$ = createEffect(() => this.actions$.pipe(
    ofType<ComponentDefinitionActions.UpdateOutputDefinitions>(ComponentDefinitionActions.ActionTypes.UpdateOutputDefinitions),
    withLatestFrom(
      this.store.select(state => state?.auth?.activePerson)
    ),
    mergeMap(([action, person]) => {

      const updatedComponentOutputDefinitions = action.payload.updatedComponentOutputDefinitions;

      const updatedComponentOutputDefinitionId = action.payload.componentDefinitionId;

      const reqBody = {
        body: <ComponentOutputDefinition[]>updatedComponentOutputDefinitions
      };

      return this.componentOutputDefinitionApi.customBulkUpdate(reqBody).pipe(
        map((updatedOutputDefinitionsResponse: BulkUpdateResultDto) => {
          this.notificationService.createNotification('success', 'SHARED.SUCCESS', 'COMPONENT_BROWSER_MODULE.COMPONENT_DEFINITION_DETAIL_PAGE_COMPONENT.OUTPUT_DEFINITION_UPDATED');
          return new ComponentDefinitionActions.UpdateComponentOutputDefinitionsSuccess(
            {
              componentDefinitionId: updatedComponentOutputDefinitionId,
              componentOutputDefinitions: updatedOutputDefinitionsResponse.updated
            }
          );
        }), catchError((error) => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'COMPONENT_BROWSER_MODULE.COMPONENT_DEFINITION_DETAIL_PAGE_COMPONENT.OUTPUT_DEFINITION_COULDNT_UPDATED'
          );
          return [new ComponentDefinitionActions.UpdateComponentOutputDefinitionsFailure({ error, key: 'UpdateComponentOutputDefinitionsFailure', timestamp: Date.now() })];
        }));
    })
  ));


  updateComponentDefinition$ = createEffect(() => this.actions$.pipe(
    ofType<ComponentDefinitionActions.UpdateComponentDefinition>(ComponentDefinitionActions.ActionTypes.UpdateComponentDefinition),
    mergeMap((action) => {
      const params = {
        id: action.payload.componentDefinitionId,
        body: action.payload.updatedComponentDefinition
      };
      return this.componentDefinitionControllerService.updateById(params).pipe(
        map(componentDefinition => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESS',
            'COMPONENT_BROWSER_MODULE.COMPONENT_DEFINITION_UPDATED'
          );
          return new ComponentDefinitionActions.UpdateComponentDefinitionSuccessful(
            {
              componentDefinitionId: componentDefinition.id,
              updatedComponentDefinition: componentDefinition,
              navigateToDetailPage: action.payload.navigateToDetailPage
            }
          );
        }), catchError((error) => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'COMPONENT_BROWSER_MODULE.COMPONENT_DEFINITION_COULDNT_UPDATED'
          );
          return [new ComponentDefinitionActions.UpdateComponentDefinitionFailure({ error, key: 'UpdateComponentDefinition', timestamp: Date.now() })];
        })
      );
    })
  ));


  updateComponentDefinitionSuccessful$ = createEffect(() => this.actions$.pipe(
    ofType<ComponentDefinitionActions.UpdateComponentDefinitionSuccessful>
      (ComponentDefinitionActions.ActionTypes.UpdateComponentDefinitionSuccessful),
    mergeMap((action) => {
      const componentDefinitionId = action.payload.componentDefinitionId;
      if (action.payload.navigateToDetailPage) {
        return [new Navigate({ url: `${PATH_DEFINITIONS.COMPONENT_BROWSER.COMPONENT_DEFINITION_DETAIL_PATH}/${componentDefinitionId}` })];
      } else {
        return [new NavigateFailure({ error: 'The command required to navigate could not be found in payload', key: 'NavigateFailure', timestamp: Date.now() })];
      }
    })
  ));


  deprecateComponentDefinition$ = createEffect(() => this.actions$.pipe(
    ofType<ComponentDefinitionActions.DeprecateComponentDefinition>
      (ComponentDefinitionActions.ActionTypes.DeprecateComponentDefinition),
    mergeMap((action) => {
      const params = {
        id: action.payload.componentDefinitionId
      };
      return this.componentDefinitionControllerService.deprecateById(params).pipe(
        map(() => {
          this.notificationService.createNotification(
            'success',
            'Success',
            'Component Definition successfully deprecated'
          );
          return new ComponentDefinitionActions.DeprecateComponentDefinitionSuccessful({
            componentDefinitionId: action.payload.componentDefinitionId
          });
        }), catchError(error => {
          this.notificationService.createNotification(
            'error',
            'Error',
            'Component Definition Could Not Deprecated'
          );
          return [new ComponentDefinitionActions.ComponentDefinitionError({ error: error, errorOn: 'DeprecateComponentDefinition' })];
        })
      );
    })
  ));


  deprecateComponentDefinitionSuccessful$ = createEffect(() => this.actions$.pipe(
    ofType<ComponentDefinitionActions.DeprecateComponentDefinitionSuccessful>
      (ComponentDefinitionActions.ActionTypes.DeprecateComponentDefinitionSuccessful),
    map((actions) => new Navigate({ url: `${PATH_DEFINITIONS.COMPONENT_BROWSER.COMPONENT_BROWSER_PATH}` }))
  ));

}
