import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { UserRoles } from './admin-dashboard-roles.enum';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IsAdminGuard implements CanActivate {

  constructor(private store: Store<any>) { }

  canActivate() {
    console.log('ADMIN GUARD');
    return this.store.select(state => state?.auth?.user?.roleMapping?.role).pipe(
      filter(role => !!role),
      take(1),
      switchMap(role => of(role?.name === UserRoles.Admin))
    );
  }
}
