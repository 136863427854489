<rappider-ui-step-function-list-detail [uiStepFunctionData]="uiWorkflowStepFunctionListData"
                                       [isLoading]="isDataTransformationsLoading || isUIStepFunctionsLoading"
                                       [config]="listConfig"
                                       (uiStepFunctionCreate)="navigateToUIStepFunctionCreate()"
                                       (uiStepFunctionDelete)="onUiStepFunctionDeleteClick($event)"
                                       (uiStepFunctionPreData)="onPreDataTransformationClick($event)"
                                       (uiStepFunctionPostData)="onPostDataTransformationClick($event)"></rappider-ui-step-function-list-detail>

<rappider-modal *ngIf="isPreDataTransformationModalVisible"
                [(visible)]="isPreDataTransformationModalVisible"
                [title]="preDataTransformationTitleTemplate"
                [fullScreen]="true"
                (cancelClick)="handlePreDataTransformationModalVisibility()"
                (okClick)="handlePreDataTransformationModalVisibility()">
  <rappider-spin [spinning]="isDataTransformationsLoading && !preDataTransformationId">
    <rappider-data-transformation *ngIf="preSourceJsonSchema && preDataTransformationId && isPreDataTransformationModalVisible"
                                  [dataTransformationId]="preDataTransformationId"
                                  [schema]="preSourceJsonSchema"
                                  [dataTransformationFlag]="dataTransformationFlag"
                                  (showStepsFlag)="onShowStepsFlag($event)"
                                  [targetSchema]="preTargetJsonSchema"></rappider-data-transformation>
  </rappider-spin>
</rappider-modal>
<ng-template #preDataTransformationTitleTemplate>
  <div class="dt-modal-title">
    <span>Pre Data Transformation</span>
    <span class="info-icon"
          nz-icon
          title="Show Help"
          nzType="info-circle"
          (click)="setStepsFlag('data-transformation')"></span>
  </div>
</ng-template>

<rappider-modal *ngIf="isPostDataTransformationModalVisible"
                [(visible)]="isPostDataTransformationModalVisible"
                [title]="postDataTransformationTitleTemplate"
                [fullScreen]="true"
                (cancelClick)="handlePostDataTransformationModalVisibility()"
                (okClick)="handlePostDataTransformationModalVisibility()">
  <rappider-spin [spinning]="isDataTransformationsLoading && !postDataTransformationId">
    <rappider-data-transformation *ngIf="postSourceJsonSchema && postDataTransformationId && isPostDataTransformationModalVisible"
                                  [dataTransformationId]="postDataTransformationId"
                                  [schema]="postSourceJsonSchema"
                                  [dataTransformationFlag]="dataTransformationFlag"
                                  (showStepsFlag)="onShowStepsFlag($event)"
                                  [targetSchema]="postTargetJsonSchema"></rappider-data-transformation>
  </rappider-spin>
</rappider-modal>
<ng-template #postDataTransformationTitleTemplate>
  <div class="dt-modal-title">
    <span>Post Data Transformation</span>
    <span class="info-icon"
          nz-icon
          title="Show Help"
          nzType="info-circle"
          (click)="setStepsFlag('data-transformation')"></span>
  </div>
</ng-template>
