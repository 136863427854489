<ng-container *ngIf="tree">
  <div *ngFor="let value of localValue; let i = index"
       class="row-elements">
    <rappider-schema-tree-select #treeSelectComponent
                                 class="tree-select"
                                 joyrideStep="sortThirdStep"
                                 [title]="'DTSortAddItemSecondStepTitle' | translate"
                                 [text]="'DTSortAddItemSecondStepContent' | translate"
                                 [tree]="tree"
                                 [multipleSelect]="false"
                                 [(ngModel)]="value.sourceField"
                                 (ngModelChange)="onSourceFieldChange($event,i)"></rappider-schema-tree-select>
    <rappider-select class="direction-select"
                     joyrideStep="sortFourthStep"
                     [title]="'DTSortAddItemThirdStepTitle' | translate"
                     [text]="'DTSortAddItemThirdStepContent' | translate"
                     [options]="directionOptions"
                     [(ngModel)]="value.direction"
                     (ngModelChange)="onDirectionChange($event,i)"></rappider-select>
    <rappider-icon class="delete"
                   type="FONT_AWESOME"
                   name="far fa-trash"
                   joyrideStep="sortFifthStep"
                   [title]="'DTSortAddItemFourthStepTitle' | translate"
                   [text]="'DTSortAddItemFourthStepContent' | translate"
                   (click)="removeField(i)"></rappider-icon>
  </div>
  <button nz-button
          nzType="default"
          nzSize="small"
          joyrideStep="sortSecondStep"
          [title]="'DTSortAddItemFirstStepTitle' | translate"
          [text]="'DTSortAddItemFirstStepContent'| translate"
          (click)="addField()"><span nz-icon
          nzType="plus"
          nzTheme="outline"></span>
    {{addFieldButton.text | translate}}
  </button>
</ng-container>