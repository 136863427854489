import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StateErrorService {
  static handleFailure(state, action) {
    const { error, key, timestamp } = action.payload;
    return {
      ...state,
      error: { error, key, timestamp },
      isLoading: false
    };
  }
}
