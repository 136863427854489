import { MenuActionBehavior, MenuMode, MenuConfig } from '@rappider/rappider-components/utils';
import { PATH_DEFINITIONS } from '../../definitions/path-definition';

export const SIDEBAR_MENU: MenuConfig = {
  items: [
    {
      label: 'SHARED.PROFILE',
      key: 'profile',
      actionBehavior: MenuActionBehavior.Router,
      redirectUrl: `${PATH_DEFINITIONS.PROFILE.PROFILE_PATH}`
    },
    {
      label: 'SHARED.CHANGE_PASSWORD',
      key: 'changePassword',
      actionBehavior: MenuActionBehavior.Router,
      redirectUrl: `${PATH_DEFINITIONS.PROFILE.PROFILE_CHANGE_PASSWORD_PATH}`
    },
    {
      label: 'SHARED.ACCOUNTS',
      key: 'accounts',
      actionBehavior: MenuActionBehavior.Router,
      redirectUrl: `${PATH_DEFINITIONS.PROFILE.PROFILE_PERSON_LIST_PATH}`
    },
    {
      label: 'SHARED.ORGANIZATION',
      key: 'organization',
      actionBehavior: MenuActionBehavior.Router
    }
  ],
  mode: MenuMode.Inline
};
