import { createSelector } from '@ngrx/store';
import { CustomFunctionDefinition } from '@rappider/rappider-sdk';

export const getWorkflowFunctionsAndTemplates = createSelector(
  state => state['customFunction'].data,
  state => state['customFunction'].isWorkflowFunctionsLoaded,
  state => state['customFunction'].templateWorkflowFunctions,
  state => state['customFunction'].isTemplateWorkflowFunctionsLoaded,
  (
    workflowFunctions: CustomFunctionDefinition[],
    isWorkflowFunctionsLoaded: boolean,
    templateWorkflowFunctions: CustomFunctionDefinition[],
    isTemplateWorkflowFunctionsLoaded: boolean) => {
    if (isWorkflowFunctionsLoaded && isTemplateWorkflowFunctionsLoaded) {
      return {
        workflowFunctions: workflowFunctions,
        templateWorkflowFunctions: templateWorkflowFunctions
      };
    }
  }
);
