import { PropertyType } from '@rappider/rappider-components/utils';

export function assignTypeAccordingToValue(value) {
    if (typeof value === PropertyType.String) {
        return PropertyType.String;
    } else if (typeof value === PropertyType.Number) {
        return PropertyType.Number;
    } else if (typeof value === PropertyType.Boolean) {
        return PropertyType.Boolean;
    } else if (Array.isArray(value)) {
        return PropertyType.Array;
    } else if (typeof value === PropertyType.Object && value !== null) {
        return PropertyType.Object;
    } else {
        return null;
    }
}
