<div class="example-template">
  <div class="heading-area"
       nz-tooltip
       [nzTooltipTrigger]="showTooltip ? 'hover' : null"
       nzTooltipTitle="Component Header Background Color">
    <rappider-heading [content]="heading.content"
                      [type]="heading.type"></rappider-heading>
    <rappider-paragraph [content]="paragraph?.content"
                        [colorSettings]="paragraph?.colorSettings"></rappider-paragraph>
  </div>

  <div class="body-area"
       nz-tooltip
       [nzTooltipTrigger]="showTooltip ? 'hover' : null"
       nzTooltipTitle="Body Background Color">
    <rappider-steps [(current)]="current"
                    [steps]="stepsComponentConfig.steps">
    </rappider-steps>

    <div class="step-components">
      <div *ngIf="current === 0"
           class="step-one">
        <div *ngFor="let cardFourConfig of cardFourComponentConfigs"
             class="card-four">
          <rappider-card-four [avatar]="cardFourConfig.avatar"
                              [title]="cardFourConfig.title"
                              nz-tooltip
                              [nzTooltipTrigger]="showTooltip ? 'hover' : null"
                              nzTooltipTitle="Primary Color"></rappider-card-four>
        </div>
      </div>
      <div *ngIf="current === 1"
           class="step-two">
        <section class="card-content">
          <div class="w-100">
            <rappider-image [source]="imageComponentConfig.source"
                            [customSizeSettings]="imageComponentConfig.customSizeSettings"></rappider-image>
          </div>
          <div class="w-100">
            <rappider-heading [content]="heading.content"
                              [type]="heading.type"></rappider-heading>
            <rappider-rate-display [rate]="4"></rappider-rate-display>
            <rappider-paragraph [content]="paragraph?.content"
                                [colorSettings]="paragraph?.colorSettings"></rappider-paragraph>
          </div>
        </section>
      </div>
      <div *ngIf="current === 2"
           class="step-three">
        <rappider-list-grid [data]="listGridComponentConfig.data"
                            [config]="listGridComponentConfig.config"></rappider-list-grid>
      </div>
    </div>

    <div class="steps-buttons">
      <rappider-button text="Previus"
                       [disabled]="current === 0 ? true : false"
                       (click)="decreaseStep()"></rappider-button>
      <rappider-button text="Next"
                       [disabled]="current === 2 ? true : false"
                       (click)="increaseStep()"></rappider-button>
    </div>
  </div>

  <div class="row footer-area">
    <div class="col-8 alternative-bg"
         nz-tooltip
         [nzTooltipTrigger]="showTooltip ? 'hover' : null"
         nzTooltipTitle="Alternative Background Color">
      <rappider-review-results [title]="reviewResultComponentConfig.title"
                               [progresses]="reviewResultComponentConfig.progresses"></rappider-review-results>
      <rappider-button text="Button"
                       type="primary"></rappider-button>
    </div>

    <div class="col-4 percent"
         nz-tooltip
         [nzTooltipTrigger]="showTooltip ? 'hover' : null"
         nzTooltipTitle="Default Color">
      <rappider-progress [percent]="progressComponentConfig.percent"
                         [successPercent]="progressComponentConfig.successPercent"
                         [showInfo]="progressComponentConfig.showInfo"
                         [status]="progressComponentConfig.status"
                         [strokeWidth]="progressComponentConfig.strokeWidth"
                         [width]="progressComponentConfig.width"
                         [type]="progressComponentConfig.type"></rappider-progress>
    </div>
  </div>
</div>
