import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CODEMIRROR_JSON_SETTINGS } from '@rappider/shared/definitions';

@Component({
  selector: 'rappider-map-custom-function',
  templateUrl: './map-custom-function.component.html',
  styleUrls: ['./map-custom-function.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => MapCustomFunctionComponent),
      multi: true
    }
  ]
})
export class MapCustomFunctionComponent implements ControlValueAccessor {

  @Input() selectedSourceFields;

  _value: string;
  codemirrorJsonSettings = CODEMIRROR_JSON_SETTINGS;

  set value(value: string) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: string) {
    this._value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  /**
   * selected source fields comes as variableName1.variableName2, this fn returns the last string index variableName2
   *
   * @memberof MapCustomFunctionComponent
   */
  getSourceFieldName() {
    const lastStringOfVariable = this.selectedSourceFields?.map(selectedSourceField => {
      const selectedSourceFieldVariableArray = selectedSourceField.split('.');
      const lastIndex = selectedSourceFieldVariableArray.length - 1;
      return selectedSourceFieldVariableArray.at(lastIndex);
    }).join(', ');
    return lastStringOfVariable;
  }

}
