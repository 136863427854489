import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { PROJECT_MODEL_DATA_FIELD_CREATE_OR_EDIT_CONFIG } from '@rappider/shared/configs';
import { cloneDeep, toLower } from 'lodash';
import { Subscription } from 'rxjs';
import { Project, ProjectModel, ProjectModelField } from '@rappider/rappider-sdk';
import { GetProjectModels, UpdateProjectModelField } from '../../states/project-model-state/project-model.actions';
import { BreadcrumbOption, CrudFormInputTemplateItem, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { PropertyType } from 'libs/components/src/lib/utils/input-template';
import { PATH_DEFINITIONS, defaultToolbarTitleHeadingSize } from '@rappider/shared/definitions';
import { getProjectAndProjectModelsLoading } from '../data-field-list/utils/loading-selector';
import { getProjectModelsWithFields } from 'libs/shared/src/lib/state-selectors/get-models-with-fields-selector';

@Component({
  selector: 'rappider-data-field-edit',
  templateUrl: './data-field-edit.component.html',
  styleUrls: ['./data-field-edit.component.scss']
})
export class DataFieldEditComponent implements OnInit, OnDestroy {
  /* form config */
  PROJECT_MODEL_DATA_FIELD_EDIT_CONFIG = cloneDeep(PROJECT_MODEL_DATA_FIELD_CREATE_OR_EDIT_CONFIG);
  /* subscriptions */
  subscriptions: Subscription[];
  /* main title */
  mainTitle: HeadingComponentConfig;
  /* page title */
  title: string[] | BreadcrumbOption[];
  /* project model field id */
  fieldId: string;
  /* project model field */
  editingField: ProjectModelField;
  /* active project name */
  activeProjectName: string;
  /* default type and format data for data field edit */
  defaultTypeAndFormat = {
    type: PropertyType.String,
    format: null
  };
  relationedTypeId: string;

  displayToolbar = false;
  displayToolbarBackButton = false;
  isLoading: boolean;
  isLoaded: boolean;
  isFormLoading = false;

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.setDataFieldInputAreaByType(this.defaultTypeAndFormat);
    this.getDataFieldIdFromUrl();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToLoaded(),
      this.subscribeToLoading(),
      this.getProjectModelsByProjectModelFieldId()
    ];
  }

  /**
   * subscribe to active project to set the title
   *
   * @returns
   * @memberof ProjectModelListComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject) => {
      if (activeProject) {
        this.activeProjectName = activeProject.name;
        this.store.dispatch(new GetProjectModels());
      }
    });
  }

  subscribeToLoading() {
    return this.store.select(<any>getProjectAndProjectModelsLoading).subscribe(loading => {
      this.isLoading = loading;
    });
  }

  subscribeToLoaded() {
    return this.store.select(state => state.projectModel.isLoaded).subscribe(isLoaded => {
      this.isLoaded = isLoaded;
    });
  }

  getDataFieldIdFromUrl() {
    this.fieldId = this.activatedRoute.snapshot.params['id'];
    this.getProjectModelsByProjectModelFieldId();
  }

  onEditFieldSubmit(projectModelField: ProjectModelField) {
    this.isFormLoading = true;
    this.store.dispatch(new UpdateProjectModelField({ projectModelFieldId: this.fieldId, projectModelField: projectModelField, relationedTypeId: this.relationedTypeId }));
  }

  getProjectModelsByProjectModelFieldId() {
    return this.store.select(<any>getProjectModelsWithFields).subscribe(projectModels => {
      const existingProjectModel = projectModels?.find(projectModel => projectModel.fields.some(field => field.id === this.fieldId));
      if (existingProjectModel) {
        this.relationedTypeId = existingProjectModel.relationedTypeId;
        this.editingField = existingProjectModel.fields.find(field => field.id === this.fieldId);
        this.onDataFieldValueChange(this.editingField);
        this.setTitle(existingProjectModel);
      } else {
        this.relationedTypeId = null;
        this.editingField = null;
        this.onDataFieldValueChange(null);
        this.setTitle(null);
      }
    });
  }

  setTitle(projectModel: ProjectModel) {
    this.mainTitle = {
      content: 'PROJECT_MODULE.PROJECT_MODEL_DATA_FIELD_EDIT_COMPONENT.PROJECT_MODEL_DATA_FIELD_EDIT',
      type: defaultToolbarTitleHeadingSize
    };
    this.title = [
      {
        label: this.activeProjectName,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${projectModel?.id}`
      },
      {
        label: 'PROJECT_MODULE.PROJECT_MODEL_DATA_FIELD_LIST_COMPONENT.DATA_MODELS',
        redirectUrl: PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_LIST
      },
      {
        label: projectModel?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_DATA_FIELD_LIST}/${projectModel?.id}`, queryParams: { tab: 'fields' }
      },
      {
        label: 'PROJECT_MODULE.PROJECT_MODEL_DATA_FIELD_EDIT_COMPONENT.PROJECT_MODEL_DATA_FIELD_EDIT',
      },
      {
        label: this.editingField?.name
      }
    ];
  }

  onDataFieldValueChange(data) {
    if (data?.type) {
      this.setDataFieldInputAreaByType(data.type);
    }
  }

  setDataFieldInputAreaByType(type) {
    const defaultFormItem = <CrudFormInputTemplateItem>
      this.PROJECT_MODEL_DATA_FIELD_EDIT_CONFIG.items.find(item => item.fieldName === 'default');

    defaultFormItem.typeAndFormat = {
      type: toLower(type),
      format: null
    };
    this.PROJECT_MODEL_DATA_FIELD_EDIT_CONFIG = { ...this.PROJECT_MODEL_DATA_FIELD_EDIT_CONFIG };
  }
}
