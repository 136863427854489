<!-- ----------------------------------------------------------------------------------------- -->
<!-- TITLE -->
<rappider-title-toolbar *ngIf="title"
                        [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton"></rappider-title-toolbar>
<!-- / TITLE -->
<!-- ----------------------------------------------------------------------------------------- -->

<!-- ----------------------------------------------------------------------------------------- -->
<!-- LOADING SKELETON -->
<section *ngIf="loading || !projectExports">
  <nz-skeleton [nzActive]="true"></nz-skeleton>
</section>
<!-- / LOADING SKELETON -->
<!-- ----------------------------------------------------------------------------------------- -->

<!-- ----------------------------------------------------------------------------------------- -->
<!-- TOOLBAR -->
<div *ngIf="!loading && projectVersion && !exportStatusFlag"
     class="toolbar">
  <rappider-button [text]="projectExportPageButton.text"
                   [icon]="projectExportPageButton.icon"
                   [type]="projectExportPageButton.type"
                   (click)="onNavigateToProjectExportPage()"></rappider-button>
</div>
<!-- / TOOLBAR -->
<!-- ----------------------------------------------------------------------------------------- -->
<!-- ----------------------------------------------------------------------------------------- -->
<!-- LATEST PROJECT EXPORT -->
<section *ngIf="!loading && latestProjectExport">
  <div class="last-exported-version-list">
    <rappider-list-grid [config]="LAST_PROJECT_EXPORT_LIST_CONFIG"
                        [data]="[latestProjectExport]"></rappider-list-grid>
  </div>
  <div class="export-details-wrapper">
    <section>
      <div class="process-progress">
        <rappider-progress *ngIf="isExportContinue"
                           [percent]="statusPercent"
                           [showInfo]="true"
                           [strokeWidth]="30"
                           [showText]="showText"
                           [innerText]="progressInnerText || ''"
                           [status]="progressStatus"></rappider-progress>
      </div>
      <div>
        <rappider-project-export-details [processes]="latestProjectExport.processes"
                                         (status)="checkStatus($event)"></rappider-project-export-details>
      </div>
    </section>
  </div>
</section>
<!-- ----------------------------------------------------------------------------------------- -->
<!-- / LATEST PROJECT EXPORT -->

<!-- ----------------------------------------------------------------------------------------- -->
<!-- PROJECT EXPORTS -->
<section *ngIf="!loading && projectExports">
  <rappider-list-grid [config]="PROJECT_EXPORT_LIST_CONFIG"
                      [data]="projectExports"></rappider-list-grid>
</section>
<!-- ----------------------------------------------------------------------------------------- -->
<!-- / PROJECT EXPORTS -->