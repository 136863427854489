import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { FieldDefinitionApi, FieldTypeApi } from '../../sdk/services';
import { map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InputDefinitionService {

  constructor(
    private fieldDefinitionApi: FieldDefinitionApi,
    private fieldTypeApi: FieldTypeApi
  ) { }

  changeAddInputDefinitionFormData(data: any): any {
    /* taking data */
    let formData = cloneDeep(data);
    /* If existing fieldDefinition selected */
    if (formData.fieldDefinition.fieldDefinitionSelect === true) {
      /* deletes fieldDefinition assigning fieldDefinitionId instead.  */
      delete formData.fieldDefinition;
      formData = {
        ...formData,
        fieldDefinitionId: data.fieldDefinition.selectFieldDefinition
      };
      return of(formData);
    } else {
      /* If Adding New Field Definition  */
      /* Taking new field Definition and assign it to fieldDefinition */
      const fieldDefinition = formData.fieldDefinition.newFieldDefinition;
      /* assigning newFieldDefinition to formData's fieldDefinition*/
      formData = {
        ...formData,
        fieldDefinition: fieldDefinition
      };
      if (fieldDefinition.fieldType.fieldTypeSelect === true) {
        const fieldTypeId = fieldDefinition.fieldType.selectFieldType;
        /* deleting fieldType object */
        delete formData.fieldDefinition.fieldType;
        /* Field Definition To Create */
        const fieldDefinitionToCreate = {
          ...formData.fieldDefinition,
          fieldTypeId: fieldTypeId
        };
        /* creating field definition */
        return this.fieldDefinitionApi.create(fieldDefinitionToCreate).pipe(
          map(res => {
            /* deleting Form Data's field definition field */
            delete formData.fieldDefinition;
            /* Final Form Data{title,name,fieldDefinitionId} */
            formData = {
              ...formData,
              fieldDefinitionId: res.id
            };
            return formData;
          }));
      } else {
        /* If adding new field type */
        /* taking field type */
        const fieldType = {
          ...formData.fieldDefinition.fieldType.newFieldType
        };
        /* creating field type */
        return this.fieldTypeApi.create(fieldType).pipe(
          mergeMap(res => {
            /* field definition to create */
            const newFieldDefinition = {
              ...formData.fieldDefinition,
              fieldTypeId: res.id
            };
            /* creating field definition */
            return this.fieldDefinitionApi.create(newFieldDefinition).pipe(map(createdFieldDefinition => {
              /* deleting formData's field definition */
              delete formData.fieldDefinition;
              /* final formData */
              formData = {
                ...formData,
                fieldDefinitionId: createdFieldDefinition.id
              };
              return formData;
            }));
          }));
      }
    }
  }

  // getPerson() {
  //   return this.store.select(state => state.auth?.activePerson).pipe(map(activePerson => {
  //     const person = cloneDeep(activePerson);
  //     const createdByInformation = {
  //       createdBy: person.firstName + ' ' + person.lastName,
  //       createdById: person.id
  //     }
  //     return createdByInformation;
  //   }));
  // }
}
