import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ButtonComponentConfig, ButtonType, CODEMIRROR_CSS_SETTINGS, TitleToolbarComponentConfig } from '@rappider/rappider-components/utils';
import { ProjectWithRelations } from '@rappider/rappider-sdk';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Subscription } from 'rxjs';
import { UpdateProject } from '../../states/projects-state/project.actions';
import { MONACO_EDITOR_CSS_CONFIG } from 'libs/shared/src/lib/configs/monaco-editor-language-and-mode-config';

@Component({
  selector: 'rappider-project-css-style-customization',
  templateUrl: './project-css-style-customization.component.html',
  styleUrls: ['./project-css-style-customization.component.scss']
})
export class ProjectCssStyleCustomizationComponent implements OnInit, OnDestroy {

  /* for using this. component in content editor */
  @Input() titlebarVisibility = true;

  /* title toolbar config */
  titleToolbarConfig: TitleToolbarComponentConfig = {
    mainTitle: {
      content: 'PROJECT_MODULE.PROJECT_CSS_STYLE_CUSTOMIZATION_COMPONENT.EDIT_CSS_STYLE_CUSTOMIZATION',
      type: defaultToolbarTitleHeadingSize
    }
  };
  /* active project */
  activeProject: ProjectWithRelations;
  /* subscriptions */
  subscriptions: Subscription[];

  /* Monaco Css Settings */
  CSS_CODEMIRROR_SETTINGS = MONACO_EDITOR_CSS_CONFIG;

  cssStyle: string;
  buttonConfig: ButtonComponentConfig = {
    text: 'Save',
    type: ButtonType.Primary,
    loading: false
  };

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToLoading()
    ];
  }

  subscribeToLoading() {
    return this.store.select(state => state.project.loading).subscribe(loading => {
      this.buttonConfig = {
        ...this.buttonConfig,
        loading: loading
      };
    });
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectWithRelations) => {
      if (activeProject) {
        /* set active project */
        this.activeProject = activeProject;
        /* set title toolbar options */
        this.titleToolbarConfig.options = [
          {
            label: activeProject.name,
            redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${activeProject?.id}`
          },
          {
            label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.CSS_STYLE_CUSTOMIZATION.PAGE_TITLE
          }
        ];
      } else {
        /* set title */
        this.titleToolbarConfig.options = [];
      }
    });
  }

  onEditProjectCssStyle(cssStyle: string) {
    this.store.dispatch(new UpdateProject({
      project: {
        cssStyle: cssStyle
      },
      projectId: this.activeProject.id,
      redirectToProjectDetail: false,
      notificationMessageOnSuccess: 'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_CSS_STYLE_UPDATED',
      notificationMessageOnFailure: 'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_CSS_STYLE_COULDNOT_UPDATED',
    }));
  }

}
