import { FormGroup } from '@angular/forms';
import { MonacoEditorLanguage } from 'libs/shared/src/lib/configs/monaco-editor-language';
import { MONACO_EDITOR_JAVASCRIPT_CONFIG, MONACO_EDITOR_JSON_CONFIG, MONACO_EDITOR_PYTHON_CONFIG } from 'libs/shared/src/lib/configs/monaco-editor-language-and-mode-config';

export const MONACO_LANGUAGE_CONFIGS = [
  { key: MonacoEditorLanguage.NodeJS, config: MONACO_EDITOR_JAVASCRIPT_CONFIG },
  { key: MonacoEditorLanguage.Json, config: MONACO_EDITOR_JSON_CONFIG },
  { key: MonacoEditorLanguage.Python, config: MONACO_EDITOR_PYTHON_CONFIG }
];

export function updateCodeExampleOnLanguage(language: string) {
  const selectedConfig = MONACO_LANGUAGE_CONFIGS.find(config => language.startsWith(config.key));
  return selectedConfig?.config || MONACO_EDITOR_JAVASCRIPT_CONFIG;
}
