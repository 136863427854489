import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TenantInterface } from '../../sdk/models';
import { TenantApi } from '../../sdk/services';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  constructor(
    private tenantApi: TenantApi
  ) { }

  getTenantById(tenantId: string): Observable<TenantInterface> {
    return this.tenantApi.findById(tenantId);
  }

}
