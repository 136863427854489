import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectRequirementsComponent } from './project-requirements.component';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { RappiderButtonModule, RappiderTextboxModule } from '@rappider/rappider-components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    ProjectRequirementsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzDrawerModule,
    NzCollapseModule,
    RappiderButtonModule,
    RappiderTextboxModule,
    TranslateModule
  ],
  exports: [
    ProjectRequirementsComponent
  ]
})
export class RappiderProjectRequirementsModule { }
