import { CrudViewFormItemType, CrudFormConfig, CrudFormTextBoxItem, InputSize } from '@rappider/rappider-components/utils';
import { Validators } from '@angular/forms';

export const EDIT_OUTPUT_DEFINITION_CONFIG: CrudFormConfig = {
  items: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewFormItemType.Label,
      index: 1
    },
    <CrudFormTextBoxItem>{
      title: 'Title',
      fieldName: 'title',
      type: CrudViewFormItemType.TextBox,
      size: InputSize.Large,
      index: 1,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.TITLE_REQUIRED'
        }
      ],
    },
    <CrudFormTextBoxItem>{
      title: 'Field Name',
      fieldName: 'fieldName',
      type: CrudViewFormItemType.TextBox,
      size: InputSize.Large,
      index: 1,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.TITLE_REQUIRED'
        }
      ],
    },
    {
      title: 'Description',
      fieldName: 'description',
      type: CrudViewFormItemType.TextArea,
      index: 3
    },
    {
      title: 'SHARED.INDEX',
      fieldName: 'index',
      type: CrudViewFormItemType.Number,
      index: 2,
      size: InputSize.Large
    }
  ],
  submitButton: {
    visible: false
  }
};
