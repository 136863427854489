import { ButtonType } from '@rappider/rappider-components/utils';

export const ActionButtonConfig = {
  SaveButton: {
    text: 'Save',
    type: ButtonType.Primary
  },
  SkipButton: {
    text: 'Skip'
  },
  BackButton: {
    text: 'Back'
  },
  GenerateCrudPagesButton: {
    text: 'Generate CRUD Pages',
    type: ButtonType.Primary,
    emitWithoutPopconfirm: false
  },
  SaveAndContinueButton: {
    text: 'Save and Continue',
    type: ButtonType.Primary
  },
  HelpButton: {
    icon: {
      name: 'fa-regular fa-circle-question'
    }
  },
  EditSettingsButton: {
    text: 'Edit Settings'
  },
  EditOptionsButton: {
    text: 'Edit Options'
  }
};
