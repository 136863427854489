/* eslint-disable */
import { ProjectFolder, Metadata } from '../index';

declare var Object: any;
export interface ProjectFileInterface {
  id?: string;
  name: string;
  title?: string;
  description?: string;
  tags?: Array<any>;
  isPublic: boolean;
  isDeleted?: boolean;
  type: string;
  path: string;
  extension?: string;
  size?: number;
  projectId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  parentFolderId?: string;
  parentFolder?: ProjectFolder;
  metadata?: Metadata[];
}

export class ProjectFile implements ProjectFileInterface {
  'id'?: string;
  'name': string;
  'title'?: string;
  'description': string;
  'tags': Array<any>;
  'isPublic': boolean;
  'isDeleted': boolean;
  'type': string;
  'path': string;
  'extension': string;
  'size': number;
  'projectId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  'parentFolderId': string;
  parentFolder: ProjectFolder;
  metadata: Metadata[];
  constructor(data?: ProjectFileInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ProjectFile`.
   */
  public static getModelName() {
    return 'ProjectFile';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ProjectFile for dynamic purposes.
   **/
  public static factory(data: ProjectFileInterface): ProjectFile {
    return new ProjectFile(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ProjectFile',
      plural: 'ProjectFiles',
      path: 'project-files',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        title: {
          name: 'title',
          type: 'string',
        },
        description: {
          name: 'description',
          type: 'string',
        },
        tags: {
          name: 'tags',
          type: 'Array&lt;any&gt;',
        },
        isPublic: {
          name: 'isPublic',
          type: 'boolean',
        },
        isDeleted: {
          name: 'isDeleted',
          type: 'boolean',
        },
        type: {
          name: 'type',
          type: 'string',
        },
        path: {
          name: 'path',
          type: 'string',
        },
        extension: {
          name: 'extension',
          type: 'string',
        },
        size: {
          name: 'size',
          type: 'number',
        },
        projectId: {
          name: 'projectId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
        parentFolderId: {
          name: 'parentFolderId',
          type: 'string',
        },
      },
      relations: {
        parentFolder: {
          name: 'parentFolder',
          type: 'ProjectFolder',
          model: 'ProjectFolder',
          relationType: 'belongsTo',
          keyFrom: 'parentFolderId',
          keyTo: 'id',
        },
        metadata: {
          name: 'metadata',
          type: 'Metadata[]',
          model: 'Metadata',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'projectFileId',
        },
      },
    };
  }
}
