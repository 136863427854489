/* eslint-disable */

declare var Object: any;
export interface MessageLogsInterface {
  id?: string;
  type: string;
  provider: string;
  from: string;
  to: string;
  body: any;
  clientBody: any;
  clientResult: any;
  status: string;
  personId: string;
  createdDate?: Date;
}

export class MessageLogs implements MessageLogsInterface {
  'id'?: string;
  'type': string;
  'provider': string;
  'from': string;
  'to': string;
  'body': any;
  'clientBody': any;
  'clientResult': any;
  'status': string;
  'personId': string;
  'createdDate': Date;
  constructor(data?: MessageLogsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MessageLogs`.
   */
  public static getModelName() {
    return 'MessageLogs';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of MessageLogs for dynamic purposes.
   **/
  public static factory(data: MessageLogsInterface): MessageLogs {
    return new MessageLogs(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'MessageLogs',
      plural: 'MessageLogs',
      path: 'message-logs',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        type: {
          name: 'type',
          type: 'string',
        },
        provider: {
          name: 'provider',
          type: 'string',
        },
        from: {
          name: 'from',
          type: 'string',
        },
        to: {
          name: 'to',
          type: 'string',
        },
        body: {
          name: 'body',
          type: 'any',
        },
        clientBody: {
          name: 'clientBody',
          type: 'any',
        },
        clientResult: {
          name: 'clientResult',
          type: 'any',
        },
        status: {
          name: 'status',
          type: 'string',
        },
        personId: {
          name: 'personId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
      },
      relations: {},
    };
  }
}
