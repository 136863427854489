<div class="pdf-viewer">
    <ngx-extended-pdf-viewer [(src)]="source"
                             [(page)]="page"
                             [(pageLabel)]="pageLabel"
                             [imageResourcesPath]="imageResourcesPath"
                             [localeFolderPath]="localeFolderPath"
                             [backgroundColor]="backgroundColor"
                             [enablePrint]="enablePrint"
                             [filenameForDownload]="filenameForDownload"
                             [handTool]="handTool"
                             [height]="height"
                             [useBrowserLocale]="useBrowserLocale"
                             [language]="language"
                             [showSidebarButton]="showSidebarButton"
                             [mobileFriendlyZoom]="mobileFriendlyZoom"
                             [pageViewMode]="pageViewMode"
                             [showBookmarkButton]="showBookmarkButton"
                             [showDownloadButton]="showDownloadButton"
                             [showFindButton]="showFindButton"
                             [showHandToolButton]="showHandToolButton"
                             [showOpenFileButton]="showOpenFileButton"
                             [showPagingButtons]="showPagingButtons"
                             [showPresentationModeButton]="showPresentationModeButton"
                             [showPrintButton]="showPrintButton"
                             [showPropertiesButton]="showPropertiesButton"
                             [showRotateButton]="showRotateButton"
                             [showScrollingButton]="showScrollingButton"
                             [showToolbar]="showToolbar"
                             [showSecondaryToolbarButton]="showSecondaryToolbarButton"
                             [showSpreadButton]="showSpreadButton"
                             [showZoomButtons]="showZoomButtons"
                             [logLevel]="logLevel"
                             (progress)="onProgress($event)"
                             (pdfLoadingStarts)="onPdfLoadingStarts()"
                             (pdfLoaded)="onPdfLoaded($event)"
                             (pdfLoadingFailed)="onPdfLoadingFailed($event)"
                             (pdfDownloaded)="onPdfDownloaded()"></ngx-extended-pdf-viewer>
</div>
