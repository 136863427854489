import { createSelector } from '@ngrx/store';

export const getNotificationsWithProjectSelector = createSelector(
  state => state['notification']?.data,
  state => state['project']?.data,
  (
    notifications: any[],
    projects: any[],
  ) => notifications.map(notification => ({
    ...notification,
    project: projects?.find(project=>project.id === notification.data?.projectId),
  }))
);
