import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SortRowElementComponent } from './sort-row-element/sort-row-element.component';
import { RappiderButtonModule, RappiderIconModule, RappiderSelectModule } from '@rappider/rappider-components';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { SortWrapperComponent } from './sort-wrapper/sort-wrapper.component';
import { FormsModule } from '@angular/forms';
import { JoyrideModule } from 'ngx-joyride';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    SortRowElementComponent,
    SortWrapperComponent
  ],
  imports: [
    CommonModule,
    RappiderButtonModule,
    FormsModule,
    RappiderIconModule,
    SharedComponentsModule,
    RappiderSelectModule,
    JoyrideModule.forChild(),
    NzButtonModule,
    TranslateModule,
    NzIconModule
  ],
  exports: [
    SortWrapperComponent
  ]
})
export class SortModule { }
