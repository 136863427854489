import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ButtonComponentConfig, ButtonSize, ButtonType, HeadingType, IconType } from '@rappider/rappider-components/utils';
import { NewProjectModelField, ProjectModelField, ProjectModelFieldPartial, ProjectModelFieldWithRelations, ProjectModelWithRelations } from '@rappider/rappider-sdk';
import { ProjectModelFieldUpdateData } from '../../utils/project-model-field-update-data';
import { ProjectModelFieldComponentMode } from '../../utils/project-model-field-component-mode.enum';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rappider-new-project-model-field',
  templateUrl: './new-project-model-field.component.html',
  styleUrls: ['./new-project-model-field.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ transform: 'translateX(100%)', opacity: 0 }),
            animate('150ms', style({ transform: 'translateX(0)', opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ transform: 'translateX(0)', opacity: 1 }),
            animate('150ms', style({ transform: 'translateX(100%)', opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class NewProjectModelFieldComponent implements OnInit, OnChanges {
  @Input() projectModel!: ProjectModelWithRelations;
  @Input() fieldCreatingModelIds!: string[];
  @Input() submitButtonLoading: boolean;

  @Output() projectModelUpdate = new EventEmitter<ProjectModelWithRelations>();
  @Output() projectModelFieldDelete = new EventEmitter<ProjectModelField>();
  @Output() projectModelFieldCreate = new EventEmitter<NewProjectModelField>();
  @Output() projectModelFieldUpdate = new EventEmitter<ProjectModelFieldUpdateData>();
  @Output() projectModelFieldsUpdate = new EventEmitter<ProjectModelFieldPartial[]>();

  activeProjectModelField!: ProjectModelFieldWithRelations;

  loading = false;

  newButtonType = ButtonType.Primary;

  mode: ProjectModelFieldComponentMode = ProjectModelFieldComponentMode.Read;

  deleteButtonConfig: ButtonComponentConfig = {
    icon: {
      type: IconType.FontAwesome,
      name: 'fa-sharp fa-solid fa-trash'
    },
    size: ButtonSize.Small,
    popconfirmTitle: 'Are you sure delete this item?',
    popconfirmOkText: 'Delete',
    popconfirmOkDanger: true,
    emitWithoutPopconfirm: false
  };

  editButtonConfig: ButtonComponentConfig = {
    size: ButtonSize.Small,
    icon: {
      type: IconType.FontAwesome,
      name: 'fa-sharp fa-solid fa-pen-to-square',
      color: 'var(--text-color)'
    }
  };

  backButtonConfig: ButtonComponentConfig = {
    size: ButtonSize.Small,
    icon: {
      type: IconType.FontAwesome,
      name: 'fa-sharp fa-solid fa-arrow-left'
    }
  };

  ComponentMode = ProjectModelFieldComponentMode;
  projectModelHeadingType = HeadingType.H4;
  projectModelFieldsLoading = false;
  subscriptions!: Subscription[];

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.handleLoading();
    this.subscribeToData();
  }

  ngOnChanges(): void {
    this.handleLoading();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToProjectModelFieldsLoading()
    ];
  }

  subscribeToProjectModelFieldsLoading() {
    return this.store.select(state => state.projectModelField?.isLoading).subscribe(loading => this.projectModelFieldsLoading = loading);
  }

  handleLoading() {
    if (this.fieldCreatingModelIds?.includes(this.projectModel?.id)) {
      this.loading = true;
    } else {
      this.loading = false;
    }
  }

  /**
   * trigger project model field create mode
   *
   * @memberof NewProjectModelFieldComponent
   */
  onNewFieldClick() {
    this.mode = ProjectModelFieldComponentMode.Create;
  }

  /**
   * trigger project model field edit mode and set active project model field
   *
   * @memberof NewProjectModelFieldComponent
   */
  onEditFieldClick(projectModelField: ProjectModelField) {
    this.activeProjectModelField = projectModelField;
    this.mode = ProjectModelFieldComponentMode.Update;
  }

  /**
   * set components' mode to read
   *
   * @memberof NewProjectModelFieldComponent
   */
  public setModeToRead() {
    this.mode = ProjectModelFieldComponentMode.Read;
  }

  /* edit formunun submit'ine basilinca emit edilecek */
  onUpdateProjectModelField(projectModelFieldUpdateData: ProjectModelFieldUpdateData) {
    this.projectModelFieldUpdate.emit(projectModelFieldUpdateData);
  }

  /**
   * emit deleting project model field
   *
   * @param {ProjectModelField} projectModelField
   * @memberof NewProjectModelFieldComponent
   */
  onDeleteProjectModelField(projectModelField: ProjectModelField) {
    this.projectModelFieldDelete.emit(projectModelField);
  }

  onNewProjectModelFieldClick(projectModelField: NewProjectModelField) {
    // this.mode = 'read';
    this.projectModelFieldCreate.emit(projectModelField);
  }

  onClickProjectModelUpdate() {
    this.projectModelUpdate.emit(this.projectModel);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.projectModel?.fields, event.previousIndex, event.currentIndex);
    let index = event.previousIndex;
    const updatedFields = this.projectModel.fields.slice(event.previousIndex, event.currentIndex).map((item) => {
      const updatedField = { id: item.id, index };
      index++;
      return updatedField;
    });
    this.projectModelFieldsUpdate.emit(updatedFields);
  }

}
