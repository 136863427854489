import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as AdminProjectsActions from './admin-projects.actions';
import { catchError, concatMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Project, ProjectControllerService, ProjectUpdateDtoPartial, ProjectWithRelations } from '@rappider/rappider-sdk';
import { NotificationService } from 'libs/components/src/lib/services';
import { Store } from '@ngrx/store';

import * as PersonActions from '../person-state/person.actions';
import { PaginationService } from '@rappider/services';

@Injectable()
export class AdminProjectsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private projectApi: ProjectControllerService,
    private notificationService: NotificationService,
    private paginationService: PaginationService
  ) { }


  initAdminProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonActions.InitAdminDashboard),
      map(() => AdminProjectsActions.GetPaginationBySearchCount({ pageIndex: 1, pageSize: 10, searchText: '' }))
    )
  );

  getInitPaginationCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AdminProjectsActions.GetPaginationBySearchCount
      ),
      switchMap((action) => {
        const filter = {
          limit: 999999,
          fields: {
            id: true,
            name: true
          },
          include: ['tenant']
        } as any;
        if (action.searchText && action.searchText !== '') {
          filter.where = {
            or: [
              { name: { regexp: `/${action.searchText}/i` } },
              { id: { regexp: `/${action.searchText}/i` } }
            ]
          };
        }
        return this.projectApi.findByAdmin({ filter }).pipe(
          map((projects: ProjectWithRelations[]) => AdminProjectsActions.GetPaginationBySearchCountSuccessful({ pageIndex: action.pageIndex, pageSize: action.pageSize, searchText: action.searchText, count: projects.length })
          ),
          catchError((error) =>
            [
              AdminProjectsActions.GetPaginationBySearchCountFailure({ error: error, key: 'GetPaginationBySearchCountFailure' })
            ]
          )
        );
      }
      )
    )
  );

  getAdminProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AdminProjectsActions.GetAdminProjects,
        AdminProjectsActions.GetPaginationBySearchCountSuccessful
      ),
      switchMap((action) => {
        const filter = {
          skip: this.paginationService.
            getSkippedSizeByPagination(action.pageIndex, action.pageSize),
          limit: action.pageSize,
          include: ['tenant'],
          order: ['createdDate DESC']
        } as any;

        if (action.searchText && action.searchText !== '') {
          filter.where = {
            or: [
              { name: { regexp: `/${action.searchText}/i` } },
              { id: { regexp: `/${action.searchText}/i` } }
            ]
          };
        }
        return this.projectApi.findByAdmin({ filter: filter }).pipe(
          map((projects: ProjectWithRelations[]) => AdminProjectsActions.GetAdminProjectsSuccessful({ projects })),
          catchError((error) =>
            [
              AdminProjectsActions.GetAdminProjectsFailure({ error: error, key: 'GetAdminProjectsFailure' })
            ]
          )
        );
      }
      )
    )
  );

  /* delete project by id */
  deleteAdminProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AdminProjectsActions.DeleteAdminProject
      ),
      switchMap((action) => this.projectApi.deleteById({ projectId: action.projectId }).pipe(
        map(() => {
          this.notificationService.createNotification(
            'success',
            'Success',
            'Project Deleted Successfully'
          );
          return AdminProjectsActions.DeleteAdminProjectSuccessful({ projectId: action.projectId });
        }),
        catchError((error) => {
          this.notificationService.createNotification(
            'error',
            'Error',
            'Project Could not Deleted'
          );
          return [
            AdminProjectsActions.DeleteAdminProjectFailure({ error: error, key: 'DeleteAdminProject' })
          ];
        }
        )
      )
      )
    )
  );

  /* update project */
  updateAdminProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AdminProjectsActions.UpdateAdminProject
      ),
      withLatestFrom(
        this.store.select(state => state.auth.activePerson),
      ),
      concatMap(([action, person]) => {
        const body: ProjectUpdateDtoPartial = {
          ...action.project,
          metadataCRUD: {
            newMetadataItems: action.metadataCRUD?.newMetadataItems ?? [],
            updatedMetadataItems: action.metadataCRUD?.updatedMetadataItems ?? [],
            deletedMetadataIds: action.metadataCRUD?.deletedMetadataIds ?? []
          }
        };
        return this.projectApi.updateById({ id: action.projectId, body: body }).pipe(
          map((response: Project) => {
            this.notificationService.createNotification(
              'success',
              'Success',
              'Project Updated Successfully'
            );
            return AdminProjectsActions.UpdateAdminProjectSuccessful({ project: response, projectId: response.id });
          }),
          catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'Error',
              'Project Could not Updated'
            );
            return [
              AdminProjectsActions.UpdateAdminProjectFailure({ error: error, key: 'UpdateAdminProject' })
            ];
          }
          )
        );
      }
      )
    )
  );

  updateProjectByAdmin = createEffect(() => this.actions$.pipe(
    ofType(AdminProjectsActions.UpdateProjectByAdmin),
    mergeMap((action) => {
      const params = {
        id: action.publicProject.id,
        body: action.publicProject.body
      };
      return this.projectApi.updateByIdByAdmin(params).pipe(
        map(() => {
          this.notificationService.createNotification(
            'success',
            'Success',
            'Project Updated Successfully'
          );
          return AdminProjectsActions.UpdateProjectByAdminSuccessful({ publicProject: action.publicProject });
        }),
        catchError(error => {
          this.notificationService.createNotification(
            'error',
            'Error',
            'Project Could not Updated'
          );
          return [
            AdminProjectsActions.UpdateProjectByAdminFailure({ payload: { error, key: 'UpdateProjectByAdminFailure', timestamp: Date.now() } })
          ];
        })
      );
    })
  ));

}
