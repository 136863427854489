import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IconType, TextMode, TitleToolbarComponentConfig } from '@rappider/rappider-components/utils';
import { Project, ProjectTheme } from '@rappider/rappider-sdk';
import { Navigate } from '@rappider/shared';
import { defaultToolbarTitleHeadingSize, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Subscription } from 'rxjs';
import { DeleteProjectTheme } from '../../../../../project/src/lib/states/project-theme-state/project-theme.actions';
import { SetAsActiveProjectTheme } from '../../../../../project/src/lib/states/projects-state/project.actions';
import { ProjectThemeActionKeys } from './project-theme-configs';
import { orderBy } from 'lodash';

@Component({
  selector: 'rappider-project-theme-list',
  templateUrl: './project-theme-list.component.html',
  styleUrls: ['./project-theme-list.component.scss']
})
export class ProjectThemeListComponent implements OnInit, OnDestroy {

  themes: ProjectTheme[];
  activeProject: Project;
  loading = false;

  subscriptions: Subscription[];
  titleToolbarConfig: TitleToolbarComponentConfig = {
    mainTitle: {
      content: 'Project Themes',
      type: defaultToolbarTitleHeadingSize
    },
    options: []
  };
  tagConfig = {
    text: {
      textMode: TextMode.Text,
      text: 'Active'
    },
    color: 'green'
  };

  activeThemeIcon = {
    name: 'fa-solid fa-circle-check',
    type: IconType.FontAwesome
  };

  ProjectThemeActionKeys = ProjectThemeActionKeys;

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach((subscription) => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToProjectThemes(),
      this.subscribeToThemesLoading()
    ];
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: Project) => {
      this.activeProject = activeProject;
      if (activeProject) {
        this.setTitleToolbarConfig();
        if (this.themes?.length > 0) {
          this.orderThemes();
        }
      }
    });
  }

  subscribeToProjectThemes() {
    return this.store.select(state => state.projectTheme?.data).subscribe((projectThemes: ProjectTheme[]) => {
      this.themes = projectThemes;
      if (this.themes?.length) {
        setTimeout(() => {
          this.applyThemesToThumbnails();
        }, 10);
      }
    });
  }

  subscribeToThemesLoading() {
    return this.store.select(state => state.projectTheme?.loading).subscribe(loading => {
      this.loading = loading;
    });
  }

  setTitleToolbarConfig() {
    this.titleToolbarConfig.options = [
      {
        label: this.activeProject.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject.id}`
      },
      {
        label: 'Project Themes'
      }
    ];
  }

  applyThemesToThumbnails() {
    this.themes.forEach((theme: ProjectTheme) => {
      const element = document.getElementById(theme.id);
      if (element) {
        Object.entries(theme.theme).forEach(([key, value]) => {
          if (key && value) {
            element.style.setProperty(key, <any>value);
          }
        });
      }
    });
    this.orderThemes();
  }

  orderThemes() {
    const activeTheme = this.themes.find(theme => theme.id === this.activeProject.activeProjectThemeId);
    const filteredThemes = orderBy(this.themes.filter(theme =>
      theme.id !== this.activeProject.activeProjectThemeId), 'createdDate', 'desc');
    this.themes = [activeTheme, ...filteredThemes];
  }

  navigateToCreateThemePage() {
    this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_THEME_CREATE}` }));
  }

  navigateToProjectTheme(themeId: string) {
    this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_THEME}/${themeId}` }));
  }

  onProjectThemeCardActionClick(key: ProjectThemeActionKeys, themeId: string) {
    switch (key) {
      case ProjectThemeActionKeys.Edit:
        this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_THEME_EDIT}/${themeId}` }));
        break;
      case ProjectThemeActionKeys.Delete:
        this.store.dispatch(new DeleteProjectTheme({ projectThemeId: themeId }));
        break;
      case ProjectThemeActionKeys.SetAsActive:
        this.store.dispatch(new SetAsActiveProjectTheme({ projectThemeId: themeId, activeProjectId: this.activeProject.id }));
        break;
      default:
        break;
    }

  }

}
