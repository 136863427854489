import { FeedbackButtonActionBehavior } from '@rappider/rappider-components/utils';
import { PATH_DEFINITIONS } from '../../definitions/path-definition';

export const ERRORS = [
  {
    key: 'PROJECT-NOT-FOUND',
    title: 'ERRORS.PROJECT_NOT_FOUND',
    icon: 'fas fa-exclamation-triangle text-danger',
    description: 'ERRORS.PROJECT_NOT_FOUND'
  },
  {
    key: 'PROVIDER-NOT-FOUND',
    title: 'ERRORS.PROVIDER_NOT_FOUND',
    icon: 'fas fa-exclamation-triangle text-danger',
    description: 'ERRORS.PROVIDER_NOT_FOUND'
  },
  {
    key: 'INVALID-REQUEST',
    title: 'ERRORS.INVALID_REQUEST',
    icon: 'fas fa-exclamation-triangle text-danger',
    description: 'ERRORS.INVALID_REQUEST'
  },
  {
    key: 'LOGIN-COULD-NOT-PERFORMED',
    title: 'ERRORS.LOGIN_COULD_NOT_PERFORMED',
    icon: 'fas fa-exclamation-triangle text-danger',
    description: 'ERRORS.LOGIN_COULD_NOT_PERFORMED'
  },
  {
    key: 'PROVIDER-ERROR',
    title: 'ERRORS.PROVIDER_ERROR',
    icon: 'fas fa-exclamation-triangle text-danger',
    description: 'ERRORS.PROVIDER_ERROR'
  },
  {
    key: 'NOT-FOUND',
    title: 'ERRORS.NOT_FOUND',
    icon: 'fas fa-exclamation-triangle text-danger',
    description: 'ERRORS.NOT_FOUND',
    buttons: [
      {
        content: 'SHARED.GO_TO_HOME_PAGE',
        actionBehavior: FeedbackButtonActionBehavior.Route,
        redirectUrl: PATH_DEFINITIONS.HOME_PAGE
      }
    ]
  },
  {
    key: '404',
    title: 'ERRORS.404_ERROR_TITLE',
    icon: 'fas fa-exclamation-triangle text-danger',
    description: 'ERRORS.404_ERROR_DESCRIPTION'
  },
  {
    key: '401',
    title: 'ERRORS.401_ERROR_TITLE',
    icon: 'fas fa-exclamation-triangle text-danger',
    description: 'ERRORS.401_ERROR_DESCRIPTION'
  },
  {
    key: 'CAN_NOT_SENT',
    description: 'ERRORS.CAN_NOT_SENT'
  },
  {
    key: 'authentication-token-can-not-be-generated',
    title: 'ERRORS.AUTHENTICATION_TOKEN_CAN_NOT_BE_GENERATED_TITLE',
    description: 'ERRORS.AUTHENTICATION_TOKEN_CAN_NOT_BE_GENERATED_DESCRIPTION',
    icon: 'fas fa-exclamation-triangle text-danger',
  },
  {
    key: 'third-party-auth-username-could-not-be-set',
    title: 'ERRORS.THIRD_PARTY_AUTH_USERNAME_COULD_NOT_BE_SET_TITLE',
    description: 'ERRORS.THIRD_PARTY_AUTH_USERNAME_COULD_NOT_BE_SET_DESCRIPTION',
    icon: 'fas fa-exclamation-triangle text-danger',
  },
];
