import { createAction, createReducer, on } from '@ngrx/store';
import * as WorkflowEventActions from '../../../../project/src/lib/states/workflow-event/workflow-event.actions';
import * as WorkflowStepFunctionActions from '../../../../project/src/lib/states/workflow-step-function/workflow-step-function.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import * as WfDiagramActions from './wf-diagram.actions';

/* state key */
export const featureKey = 'wfDiagramState';

/* state interface */
export interface WFDiagramState {
  creatingPlaceholderDataId?: string;
  lastCreatedPlaceholderDataId?: string;
  formPanelVisibility?: boolean;
  isLoaded?: boolean;
}

/* initial values */
export const initialState: WFDiagramState = {
  creatingPlaceholderDataId: undefined,
  lastCreatedPlaceholderDataId: undefined,
  formPanelVisibility: null,
  isLoaded: false
};

const ChangeActiveProjectSuccessful = createAction(AuthenticationActions.ActionTypes.ChangeActiveProjectSuccessful);
const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

export const reducer = createReducer(
  initialState,
  on(WorkflowEventActions.CreateWorkflowEvent, (state, action) => ({
    ...state,
    creatingPlaceholderDataId: action.payload.placeholderData?.id
  })),
  on(WorkflowEventActions.CreateWorkflowEventSuccessful, (state, action) => ({
    ...state,
    placeholderDataId: undefined,
    lastCreatedPlaceholderDataId: action.payload.createdPlaceholderId,
    formPanelVisibility: false
  })),
  on(WorkflowEventActions.UpdateWorkflowEventSuccessful, (state) => ({
    ...state,
    formPanelVisibility: false
  })),
  on(WorkflowStepFunctionActions.CreateWorkflowStepFunction, (state, action) => ({
    ...state,
    creatingPlaceholderDataId: action.payload.placeholderDataId
  })),
  on(WorkflowStepFunctionActions.CreateWorkflowStepFunctionSuccessful, (state, action) => ({
    ...state,
    creatingPlaceholderDataId: undefined,
    lastCreatedPlaceholderDataId: action.payload.placeholderDataId,
    formPanelVisibility: false
  })),
  on(WorkflowStepFunctionActions.UpdateWorkflowStepFunctionSuccessful, (state) => ({
    ...state,
    formPanelVisibility: false
  })),
  on(WfDiagramActions.UpdateFormPanelVisibility, (state, action) => ({
    ...state,
    formPanelVisibility: action.payload.visibility
  })),
  on(WorkflowEventActions.GetWorkflowEventSuccessful, (state) => ({
    ...state,
    isLoaded: true
  })),
  on(ChangeActiveProjectSuccessful, () => (initialState)),
  on(Logout, () => (initialState))
);
