import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { RoleMappingControllerService } from '@rappider/rappider-sdk';

import * as PersonAuthorityActions from './person-authority.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

@Injectable()
export class PersonAuthorityEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private roleMappingApi: RoleMappingControllerService,
  ) { }

  getPersonAuthorities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PersonAuthorityActions.GetPersonAuthorities
      ),
      withLatestFrom(
        this.store.select(state => state.activeProject.data?.id),
        this.store.select(state => state.activeProject.data?.people),
        this.store.select(state => state.auth?.activePerson),
      ),
      mergeMap(([action, activeProjectId, people, activePerson]) => {
        const filter = {
          where: { projectId: activeProjectId },
          include: ['role']
        };

        return this.roleMappingApi.find({ filter }).pipe(
          map((roleMapping) => {
            const personAuthorities = roleMapping.map(item => ({
              ...item,
              person: people?.find(person => person.id === item.principalId) || activePerson
            }));
            return PersonAuthorityActions.GetPersonAuthoritiesSuccessful({ personAuthorities });
          }), catchError(error => [
            PersonAuthorityActions.GetPersonAuthoritiesFailure({ error: { error, key: 'GetPersonAuthorities', time: Date.now() } })
          ])
        );
      })
    )
  );

  /* Old actions can not listen on new reducers. So we use this effect temp. */
  resetStateToInitial$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthenticationActions.Logout>(AuthenticationActions.ActionTypes.Logout),
      mergeMap(action => [
        PersonAuthorityActions.ResetStateToInitial()
      ])
    )
  );

  resetStateToInitialChangeProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthenticationActions.ChangeActiveProjectSuccessful>(AuthenticationActions.ActionTypes.ChangeActiveProjectSuccessful),
      mergeMap(action => [
        PersonAuthorityActions.ResetStateToInitial()
      ])
    )
  );
}
