import { CrudTableViewConfig, CrudViewColumnType, ButtonType, ActionBehavior } from '@rappider/rappider-components/utils';
import { PATH_DEFINITIONS } from '../../../definitions/path-definition';
import { POP_CONFIRM_DELETE_ACTION } from '../../pop-confirm-button/pop-confirm-button-config';

export const UI_STEP_FUNCTION_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'name',
  columnVisibilitySelectable: false,
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewColumnType.Link,
      routerLink: `${PATH_DEFINITIONS.PROJECTS.UI_STEP_FUNCTION_DETAIL}/{{id}}`
    },
    {
      title: 'PROJECT_MODULE.UI_STEP_FUNCTION_COMPONENT.SUBSCRIBED_EVENTS',
      fieldName: 'subscribedEvents',
      type: CrudViewColumnType.Text
    },
    {
      title: 'PROJECT_MODULE.UI_STEP_FUNCTION_COMPONENT.PUBLISHED_EVENTS_ON_SUCCESS',
      fieldName: 'publishedEventsOnSuccess',
      type: CrudViewColumnType.Text
    },
    {
      title: 'PROJECT_MODULE.UI_STEP_FUNCTION_COMPONENT.PUBLISHED_EVENTS_ON_FAILURE',
      fieldName: 'publishedEventsOnFailure',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.TYPE',
      fieldName: 'integrationOperatorType',
      type: CrudViewColumnType.Text
    }
  ],
  listActions: [
    {
      text: 'PROJECT_MODULE.UI_STEP_FUNCTION_CREATE_OR_EDIT_COMPONENT.CREATE_UI_STEP_FUNCTION',
      name: 'createUIStepFunction',
      buttonType: ButtonType.Default,
      behavior: ActionBehavior.Emit,
      icon: { name: 'fas fa-plus' }
    }
  ],
  itemActions: [
    {
      text: 'SHARED.EDIT',
      name: 'edit',
      behavior: ActionBehavior.Route,
      redirectUrl: `${PATH_DEFINITIONS.PROJECTS.UI_STEP_FUNCTION_UPDATE}/{{id}}`,
      icon: { name: 'far fa-edit' }
    },
    {
      text: POP_CONFIRM_DELETE_ACTION.text,
      name: POP_CONFIRM_DELETE_ACTION.name,
      popconfirmTitle: POP_CONFIRM_DELETE_ACTION.popconfirmTitle,
      emitWithoutPopconfirm: POP_CONFIRM_DELETE_ACTION.emitWithoutPopconfirm,
      behavior: POP_CONFIRM_DELETE_ACTION.behavior,
      icon: POP_CONFIRM_DELETE_ACTION.icon,
      popConfirmCancelText: POP_CONFIRM_DELETE_ACTION.popConfirmCancelText,
      popConfirmOkText: POP_CONFIRM_DELETE_ACTION.popConfirmOkText,
      popConfirmOkDanger: POP_CONFIRM_DELETE_ACTION.popConfirmOkDanger,
    },
    {
      text: 'Pre Data Transformation',
      name: 'preDataTransformation',
      behavior: ActionBehavior.Emit,
      icon: { name: 'fal fa-database' }
    },
    {
      text: 'Post Data Transformation',
      name: 'postDataTransformation',
      behavior: ActionBehavior.Emit,
      icon: { name: 'fad fa-database' }
    }
  ],
  handleVisibilityFn: (data, item, action) => {
    if (action.name === 'postDataTransformation') {
      return !!item.endpointId || !!item.workflowStepFunctionId || !!item.workflowEventId;
    } else {
      return true;
    }
  }
};
