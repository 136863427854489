import { CrudViewFormItemType, CrudFormConfig, CrudFormTextBoxItem, CrudFormConfigInputChangeReaction, InputSize } from '@rappider/rappider-components/utils';
import { Validators } from '@angular/forms';

export const EDIT_INPUT_DEFINITION_CONFIG: CrudFormConfig = {
  items: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewFormItemType.Label,
      index: 1
    },
    <CrudFormTextBoxItem>{
      title: 'Category',
      fieldName: 'category',
      type: CrudViewFormItemType.TextBox,
      size: InputSize.Large,
      index: 1,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.TITLE_REQUIRED'
        }
      ]
    },
    {
      title: 'Description',
      fieldName: 'description',
      type: CrudViewFormItemType.TextArea,
      index: 3
    },
    {
      title: 'SHARED.INDEX',
      fieldName: 'index',
      type: CrudViewFormItemType.Number,
      index: 4,
      size: InputSize.Large
    }
  ],
  submitButton: {
    visible: false
  },
  inputChangeReaction: CrudFormConfigInputChangeReaction.None
};
