/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddConditionToContainerRequestDto } from '../models/add-condition-to-container-request-dto';
import { AddNewPageContainerComponentDto } from '../models/add-new-page-container-component-dto';
import { AddNewPageContainerDto } from '../models/add-new-page-container-dto';
import { ContainerConditionDto } from '../models/container-condition-dto';
import { CopyPageContentRequestDto } from '../models/copy-page-content-request-dto';
import { CopyPageDto } from '../models/copy-page-dto';
import { DeletePageContainerComponentDto } from '../models/delete-page-container-component-dto';
import { DeletePageContainerDto } from '../models/delete-page-container-dto';
import { FuzzyCreateComponentAndAssignIntoPageDto } from '../models/fuzzy-create-component-and-assign-into-page-dto';
import { GetPageTemplatesResponseDtoWithRelations } from '../models/get-page-templates-response-dto-with-relations';
import { LastProcessInformation } from '../models/last-process-information';
import { MoveContentTreeItemDto } from '../models/move-content-tree-item-dto';
import { NewPage } from '../models/new-page';
import { Page } from '../models/page';
import { PageBulkUpdateDto } from '../models/page-bulk-update-dto';
import { PagePartial } from '../models/page-partial';
import { PageWithRelations } from '../models/page-with-relations';
import { ProjectSetting } from '../models/project-setting';
import { RemoveContainerConditionRequestDto } from '../models/remove-container-condition-request-dto';
import { UpdateContainerConditionRequestDto } from '../models/update-container-condition-request-dto';
import { UpdateContainerPropertiesDto } from '../models/update-container-properties-dto';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class PageControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation pageControllerFindByAdmin
   */
  static readonly PageControllerFindByAdminPath = '/admin/pages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByAdmin$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<PageWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerFindByAdminPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PageWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByAdmin(params?: {
    filter?: any;
  }): Observable<Array<PageWithRelations>> {

    return this.findByAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PageWithRelations>>) => r.body as Array<PageWithRelations>)
    );
  }

  /**
   * Path part for operation pageControllerChangeDefaultLayout
   */
  static readonly PageControllerChangeDefaultLayoutPath = '/layouts/change-default/{layoutId}';

  /**
   * This endpoint changes the default layout with provided layoutId in the project id provided by authentication token.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeDefaultLayout()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeDefaultLayout$Response(params: {
    layoutId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerChangeDefaultLayoutPath, 'patch');
    if (params) {
      rb.path('layoutId', params.layoutId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This endpoint changes the default layout with provided layoutId in the project id provided by authentication token.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changeDefaultLayout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeDefaultLayout(params: {
    layoutId: string;
  }): Observable<void> {

    return this.changeDefaultLayout$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation pageControllerAddConditionToContainer
   */
  static readonly PageControllerAddConditionToContainerPath = '/pages/add-condition-to-container/{pageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addConditionToContainer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addConditionToContainer$Response(params: {
    pageId: string;
    body?: AddConditionToContainerRequestDto
  }): Observable<StrictHttpResponse<ContainerConditionDto>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerAddConditionToContainerPath, 'post');
    if (params) {
      rb.path('pageId', params.pageId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContainerConditionDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addConditionToContainer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addConditionToContainer(params: {
    pageId: string;
    body?: AddConditionToContainerRequestDto
  }): Observable<ContainerConditionDto> {

    return this.addConditionToContainer$Response(params).pipe(
      map((r: StrictHttpResponse<ContainerConditionDto>) => r.body as ContainerConditionDto)
    );
  }

  /**
   * Path part for operation pageControllerAddNewPageContainer
   */
  static readonly PageControllerAddNewPageContainerPath = '/pages/add-new-page-container/{pageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addNewPageContainer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNewPageContainer$Response(params: {
    pageId: string;
    body?: AddNewPageContainerDto
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerAddNewPageContainerPath, 'post');
    if (params) {
      rb.path('pageId', params.pageId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addNewPageContainer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNewPageContainer(params: {
    pageId: string;
    body?: AddNewPageContainerDto
  }): Observable<any> {

    return this.addNewPageContainer$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation pageControllerAddNewPageContainerComponent
   */
  static readonly PageControllerAddNewPageContainerComponentPath = '/pages/add-new-page-container-component/{pageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addNewPageContainerComponent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNewPageContainerComponent$Response(params: {
    pageId: string;
    options?: any;
    body?: AddNewPageContainerComponentDto
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerAddNewPageContainerComponentPath, 'post');
    if (params) {
      rb.path('pageId', params.pageId, {});
      rb.query('options', params.options, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addNewPageContainerComponent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNewPageContainerComponent(params: {
    pageId: string;
    options?: any;
    body?: AddNewPageContainerComponentDto
  }): Observable<any> {

    return this.addNewPageContainerComponent$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation pageControllerBulkDelete
   */
  static readonly PageControllerBulkDeletePath = '/pages/bulk-delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkDelete$Response(params?: {
    pageIds?: Array<string>;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerBulkDeletePath, 'delete');
    if (params) {
      rb.query('pageIds', params.pageIds, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkDelete(params?: {
    pageIds?: Array<string>;
  }): Observable<any> {

    return this.bulkDelete$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation pageControllerBulkUpdate
   */
  static readonly PageControllerBulkUpdatePath = '/pages/bulk-update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkUpdate$Response(params?: {
    body?: Array<PageBulkUpdateDto>
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerBulkUpdatePath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkUpdate(params?: {
    body?: Array<PageBulkUpdateDto>
  }): Observable<any> {

    return this.bulkUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation pageControllerCopyPage
   */
  static readonly PageControllerCopyPagePath = '/pages/copy-page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copyPage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyPage$Response(params?: {
    body?: CopyPageDto
  }): Observable<StrictHttpResponse<Page>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerCopyPagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `copyPage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyPage(params?: {
    body?: CopyPageDto
  }): Observable<Page> {

    return this.copyPage$Response(params).pipe(
      map((r: StrictHttpResponse<Page>) => r.body as Page)
    );
  }

  /**
   * Path part for operation pageControllerCopyPageContent
   */
  static readonly PageControllerCopyPageContentPath = '/pages/copy-page-content';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copyPageContent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyPageContent$Response(params?: {
    body?: CopyPageContentRequestDto
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerCopyPageContentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `copyPageContent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyPageContent(params?: {
    body?: CopyPageContentRequestDto
  }): Observable<any> {

    return this.copyPageContent$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation pageControllerCount
   */
  static readonly PageControllerCountPath = '/pages/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params?: {
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params?: {
    where?: any;
  }): Observable<LoopbackCount> {

    return this.count$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation pageControllerDeletePageContainer
   */
  static readonly PageControllerDeletePageContainerPath = '/pages/delete-page-container/{pageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePageContainer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deletePageContainer$Response(params: {
    pageId: string;
    body?: DeletePageContainerDto
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerDeletePageContainerPath, 'delete');
    if (params) {
      rb.path('pageId', params.pageId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePageContainer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deletePageContainer(params: {
    pageId: string;
    body?: DeletePageContainerDto
  }): Observable<any> {

    return this.deletePageContainer$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation pageControllerDeletePageContainerComponent
   */
  static readonly PageControllerDeletePageContainerComponentPath = '/pages/delete-page-container-component/{pageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePageContainerComponent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deletePageContainerComponent$Response(params: {
    pageId: string;
    body?: DeletePageContainerComponentDto
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerDeletePageContainerComponentPath, 'delete');
    if (params) {
      rb.path('pageId', params.pageId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePageContainerComponent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deletePageContainerComponent(params: {
    pageId: string;
    body?: DeletePageContainerComponentDto
  }): Observable<any> {

    return this.deletePageContainerComponent$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation pageControllerFuzzyCreateComponentAndAssignIntoPage
   */
  static readonly PageControllerFuzzyCreateComponentAndAssignIntoPagePath = '/pages/fuzzy-create-component-and-assign-into-page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fuzzyCreateComponentAndAssignIntoPage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fuzzyCreateComponentAndAssignIntoPage$Response(params?: {
    body?: FuzzyCreateComponentAndAssignIntoPageDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerFuzzyCreateComponentAndAssignIntoPagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fuzzyCreateComponentAndAssignIntoPage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fuzzyCreateComponentAndAssignIntoPage(params?: {
    body?: FuzzyCreateComponentAndAssignIntoPageDto
  }): Observable<void> {

    return this.fuzzyCreateComponentAndAssignIntoPage$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation pageControllerGetPageForContentEditor
   */
  static readonly PageControllerGetPageForContentEditorPath = '/pages/get-page-for-content-editor/{pageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPageForContentEditor()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPageForContentEditor$Response(params: {
    pageId: string;
  }): Observable<StrictHttpResponse<Page>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerGetPageForContentEditorPath, 'get');
    if (params) {
      rb.path('pageId', params.pageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPageForContentEditor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPageForContentEditor(params: {
    pageId: string;
  }): Observable<Page> {

    return this.getPageForContentEditor$Response(params).pipe(
      map((r: StrictHttpResponse<Page>) => r.body as Page)
    );
  }

  /**
   * Path part for operation pageControllerFindLatestChangeTimestamps
   */
  static readonly PageControllerFindLatestChangeTimestampsPath = '/pages/latest-change-timestamps';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findLatestChangeTimestamps()` instead.
   *
   * This method doesn't expect any request body.
   */
  findLatestChangeTimestamps$Response(params?: {
  }): Observable<StrictHttpResponse<LastProcessInformation>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerFindLatestChangeTimestampsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LastProcessInformation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findLatestChangeTimestamps$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findLatestChangeTimestamps(params?: {
  }): Observable<LastProcessInformation> {

    return this.findLatestChangeTimestamps$Response(params).pipe(
      map((r: StrictHttpResponse<LastProcessInformation>) => r.body as LastProcessInformation)
    );
  }

  /**
   * Path part for operation pageControllerMoveContentTreeItem
   */
  static readonly PageControllerMoveContentTreeItemPath = '/pages/move-content-tree-item/{pageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moveContentTreeItem()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  moveContentTreeItem$Response(params: {
    pageId: string;
    body?: MoveContentTreeItemDto
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerMoveContentTreeItemPath, 'patch');
    if (params) {
      rb.path('pageId', params.pageId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `moveContentTreeItem$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  moveContentTreeItem(params: {
    pageId: string;
    body?: MoveContentTreeItemDto
  }): Observable<any> {

    return this.moveContentTreeItem$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation pageControllerRemoveContainerCondition
   */
  static readonly PageControllerRemoveContainerConditionPath = '/pages/remove-container-condition/{pageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeContainerCondition()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeContainerCondition$Response(params: {
    pageId: string;
    body?: RemoveContainerConditionRequestDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerRemoveContainerConditionPath, 'patch');
    if (params) {
      rb.path('pageId', params.pageId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeContainerCondition$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeContainerCondition(params: {
    pageId: string;
    body?: RemoveContainerConditionRequestDto
  }): Observable<void> {

    return this.removeContainerCondition$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation pageControllerSetPageAsHomePage
   */
  static readonly PageControllerSetPageAsHomePagePath = '/pages/set-home-page/{pageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setPageAsHomePage()` instead.
   *
   * This method doesn't expect any request body.
   */
  setPageAsHomePage$Response(params: {
    pageId: string;
  }): Observable<StrictHttpResponse<ProjectSetting>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerSetPageAsHomePagePath, 'patch');
    if (params) {
      rb.path('pageId', params.pageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectSetting>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setPageAsHomePage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setPageAsHomePage(params: {
    pageId: string;
  }): Observable<ProjectSetting> {

    return this.setPageAsHomePage$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectSetting>) => r.body as ProjectSetting)
    );
  }

  /**
   * Path part for operation pageControllerFindTemplates
   */
  static readonly PageControllerFindTemplatesPath = '/pages/templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  findTemplates$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<GetPageTemplatesResponseDtoWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerFindTemplatesPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetPageTemplatesResponseDtoWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findTemplates(params?: {
    filter?: any;
  }): Observable<Array<GetPageTemplatesResponseDtoWithRelations>> {

    return this.findTemplates$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GetPageTemplatesResponseDtoWithRelations>>) => r.body as Array<GetPageTemplatesResponseDtoWithRelations>)
    );
  }

  /**
   * Path part for operation pageControllerUpdateContainerCondition
   */
  static readonly PageControllerUpdateContainerConditionPath = '/pages/update-container-condition/{pageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateContainerCondition()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateContainerCondition$Response(params: {
    pageId: string;
    body?: UpdateContainerConditionRequestDto
  }): Observable<StrictHttpResponse<ContainerConditionDto>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerUpdateContainerConditionPath, 'patch');
    if (params) {
      rb.path('pageId', params.pageId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContainerConditionDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateContainerCondition$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateContainerCondition(params: {
    pageId: string;
    body?: UpdateContainerConditionRequestDto
  }): Observable<ContainerConditionDto> {

    return this.updateContainerCondition$Response(params).pipe(
      map((r: StrictHttpResponse<ContainerConditionDto>) => r.body as ContainerConditionDto)
    );
  }

  /**
   * Path part for operation pageControllerUpdateContentTreeByPageId
   */
  static readonly PageControllerUpdateContentTreeByPageIdPath = '/pages/update-content-tree/{pageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateContentTreeByPageId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateContentTreeByPageId$Response(params: {
    pageId: string;
    body?: any
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerUpdateContentTreeByPageIdPath, 'patch');
    if (params) {
      rb.path('pageId', params.pageId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateContentTreeByPageId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateContentTreeByPageId(params: {
    pageId: string;
    body?: any
  }): Observable<void> {

    return this.updateContentTreeByPageId$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation pageControllerUpdatePageContainerProperties
   */
  static readonly PageControllerUpdatePageContainerPropertiesPath = '/pages/update-page-container-properties/{pageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePageContainerProperties()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePageContainerProperties$Response(params: {
    pageId: string;
    body?: UpdateContainerPropertiesDto
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerUpdatePageContainerPropertiesPath, 'patch');
    if (params) {
      rb.path('pageId', params.pageId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePageContainerProperties$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePageContainerProperties(params: {
    pageId: string;
    body?: UpdateContainerPropertiesDto
  }): Observable<any> {

    return this.updatePageContainerProperties$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation pageControllerFindById
   */
  static readonly PageControllerFindByIdPath = '/pages/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<Page>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
    filter?: any;
  }): Observable<Page> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<Page>) => r.body as Page)
    );
  }

  /**
   * Path part for operation pageControllerDeleteById
   */
  static readonly PageControllerDeleteByIdPath = '/pages/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: string;
  }): Observable<void> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation pageControllerUpdateById
   */
  static readonly PageControllerUpdateByIdPath = '/pages/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    body?: PagePartial
  }): Observable<StrictHttpResponse<Page>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    body?: PagePartial
  }): Observable<Page> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<Page>) => r.body as Page)
    );
  }

  /**
   * Path part for operation pageControllerFind
   */
  static readonly PageControllerFindPath = '/pages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<PageWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PageWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<PageWithRelations>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PageWithRelations>>) => r.body as Array<PageWithRelations>)
    );
  }

  /**
   * Path part for operation pageControllerCreate
   */
  static readonly PageControllerCreatePath = '/pages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    body?: NewPage
  }): Observable<StrictHttpResponse<Page>> {

    const rb = new RequestBuilder(this.rootUrl, PageControllerService.PageControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    body?: NewPage
  }): Observable<Page> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<Page>) => r.body as Page)
    );
  }

}
