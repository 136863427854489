import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageInterface, PageApi } from '@rappider/api-sdk';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';

@Component({
  selector: 'rappider-page-detail',
  templateUrl: './page-detail.component.html',
  styleUrls: ['./page-detail.component.scss']
})
export class PageDetailComponent implements OnInit, OnDestroy {

  page: PageInterface;
  pageId: string;
  mainTitle: HeadingComponentConfig;
  title: BreadcrumbOption[] = [];
  subscriptions: Subscription[] = [];
  displayToolbar = false;
  displayToolbarBackButton = false;
  activeProject;

  constructor(
    private pageApi: PageApi,
    private activatedRoute: ActivatedRoute,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private store: Store<any>
    // eslint-disable-next-line no-empty-function
  ) { }

  ngOnInit() {
    this.getIdByUrl();
    this.subscribeToData();
  }

  /**
   * onDestroy
   *
   * @memberof PageDetailComponent
   */
  ngOnDestroy(): void {
    /* Unsubscribes all subscriptions */
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   *  Subscribing all Data
   *
   * @memberof PageDetailComponent
   */
  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToPages()
    ];
  }

  /**
   * Subscribes to active project from state
   *
   * @returns
   * @memberof PageDetailComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe(activeProject => {
      this.activeProject = activeProject;
    });
  }

  subscribeToPages() {
    return this.store.select(state => state.page.data).subscribe(pages => {
      if (pages) {
        this.page = pages?.find(page => page.id === this.pageId);
        this.setTitle();
      }
    });
  }

  /**
   * Get page id from url
   *
   * @memberof PageDetailComponent
   */
  getIdByUrl() {
    this.pageId = this.activatedRoute.snapshot.params.pageId;
  }

  setTitle() {
    this.mainTitle = {
      content: 'Page Detail',
      type: defaultToolbarTitleHeadingSize
    };
    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
      },
      {
        label: PAGE_DEFINITIONS.PAGES.CHILDREN.PAGES_LIST.PAGE_TITLE,
        redirectUrl: PATH_DEFINITIONS.PAGES.PAGE_LIST_PATH
      },
      {
        label: PAGE_DEFINITIONS.PAGES.CHILDREN.PAGES_DETAIL.PAGE_TITLE
      },
      {
        label: this.page?.title
      }
    ];
  }
}
