import { KeyValue } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  CreateAllDataFields,
  DeleteAllDataFields,
  UpdateAllDataFields
} from 'libs/project/src/lib/states/data-schema/data-schema.actions';
import { DataSchemaFieldRowPages } from '@rappider/shared/interfaces';
import { Subscription } from 'rxjs';
import { DataSchemaTypes } from '../../models/data-schema-type.enum';
import { DataSchemaElementRowOutput } from '../data-schema-element-row/models/data-schema-element-row';
import { defaultToolbarTitleHeadingSize, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { DataSchemaField, DataSchemaFieldBulkUpdateDto, DataSchemaFieldCreateDto, DataSchemaFieldUpdateDto, DataSchemaFieldWithRelations, DataSchemaWithRelations, ProjectWithRelations } from '@rappider/rappider-sdk';
import { BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { getDataSchemasWithDetailsSelector } from 'libs/project/src/lib/states/selectors/get-data-schemas-with-details.selector';

@Component({
  selector: 'rappider-data-schema-element-create',
  templateUrl: './data-schema-element-create.component.html',
  styleUrls: ['./data-schema-element-create.component.scss']
})
export class DataSchemaElementCreateComponent implements OnInit, OnDestroy {
  /* main title */
  mainTitle: HeadingComponentConfig = {
    content: 'PROJECT_MODULE.DATA_SCHEMA_ELEMENT_CREATE_COMPONENT.ADD_DATA_SCHEMA_ELEMENT',
    type: defaultToolbarTitleHeadingSize
  };
  /* page breadcrumb */
  title: string[] | BreadcrumbOption[];
  /* subscriptions */
  subscriptions: Subscription[];
  /* selected project model id */
  parentDataSchemaId: string;
  /* active project */
  activeProject: ProjectWithRelations;
  /* data schema options for selectbox */
  dataSchemaOptions: KeyValue<string, string>[];
  /* form data */
  dataSchemaElementData: any;
  /* data schemas */
  dataSchemaFields: DataSchemaFieldWithRelations[];
  /* data schema model type */
  dataSchemaModelType = DataSchemaTypes.Model;
  /* data schema field row page type - will be used in filter */
  DataSchemaPageType = DataSchemaFieldRowPages.ModelField;
  /* state of data schema element row add button visibility */
  isDataSchemaElementRowAddButtonVisible = true;

  displayToolbar = false;
  displayToolbarBackButton = false;
  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getParentDataSchemaIdFromUrl();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToDataSchema()
    ];
  }

  /**
   * subscribe to active project to set the title
   *
   * @returns
   * @memberof ProjectModelDataFieldCreateComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectWithRelations) => {
      this.activeProject = activeProject;
    });
  }

  getParentDataSchemaIdFromUrl() {
    this.parentDataSchemaId = this.activatedRoute.snapshot.params['parentDataSchemaId'];
  }

  subscribeToDataSchema() {
    return this.store.select(<any>getDataSchemasWithDetailsSelector).subscribe((dataSchemas: DataSchemaWithRelations[]) => {
      const parentDataSchema = dataSchemas.find(dataSchema => dataSchema.id === this.parentDataSchemaId);
      if (parentDataSchema) {
        this.dataSchemaFields = parentDataSchema.fields;
        /* set add button visibility state by parent data schema */
        this.isDataSchemaElementRowAddButtonVisible = parentDataSchema.isUpdatable;
        this.title = [
          {
            label: this.activeProject?.name,
            redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
          },
          {
            label: 'PROJECT_MODULE.DATA_SCHEMA_ELEMENT_LIST_COMPONENT.DATA_SCHEMA_ELEMENTS',
            redirectUrl: PATH_DEFINITIONS.PROJECTS.DATA_SCHEMA_LIST
          },
          {
            label: parentDataSchema.name
          },
          {
            label: 'PROJECT_MODULE.DATA_SCHEMA_ELEMENT_CREATE_COMPONENT.ADD_DATA_SCHEMA_ELEMENT'
          }
        ];
      }
    });
  }


  onDataSchemaSave(dataSchemaFieldDifferences: DataSchemaElementRowOutput) {
    if (dataSchemaFieldDifferences?.updated?.length) {
      const updatedDataSchemaFields = dataSchemaFieldDifferences.updated.map(updatedField => {
        const { id, ...data } = updatedField;
        return {
          id,
          data: data as DataSchemaFieldUpdateDto
        };
      }) as DataSchemaFieldBulkUpdateDto[];
      this.store.dispatch(
        new UpdateAllDataFields({ updatedDataSchemaFields: updatedDataSchemaFields, parentDataSchemaId: this.parentDataSchemaId })
      );
    }
    if (dataSchemaFieldDifferences?.created?.length) {
      const createRequestBody = dataSchemaFieldDifferences.created.map(createdField => ({
        ...createdField,
        parentDataSchemaId: this.parentDataSchemaId
      })) as DataSchemaFieldCreateDto[];

      const filteredPayload = createRequestBody.map(obj => {
        // Check all properties except 'typeId', 'parentDataSchemaId', and 'name'
        const { typeId, parentDataSchemaId, name, ...optionalFields } = obj;

        // Create a new object to store the non-null fields
        const filteredObj = { typeId, parentDataSchemaId, name };

        // Check the optional fields and add the non-null ones
        for (const [key, value] of Object.entries(optionalFields)) {
          if (value !== null) {
            filteredObj[key] = value;
          }
        }
        return filteredObj;
      });

      // Dispatch Create Action
      this.store.dispatch(
        new CreateAllDataFields({ dataSchemaFields: filteredPayload, parentDataSchemaId: this.parentDataSchemaId })
      );
    }
    if (dataSchemaFieldDifferences?.deleted?.length) {
      const deleteRequestBody = dataSchemaFieldDifferences.deleted.map((item: DataSchemaField) => item.id);
      this.store.dispatch(
        new DeleteAllDataFields({ dataSchemaFieldIds: deleteRequestBody, parentDataSchemaId: this.parentDataSchemaId }
        ));
    }
  }

}
