/* eslint-disable */
import { UIDataEvent, DataMapping } from '../index';

declare var Object: any;
export interface UIDataUpdateFunctionInterface {
  id?: string;
  code?: string;
  generatedBy?: string;
  uiDataEventId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  event?: UIDataEvent;
  dataMaps?: DataMapping[];
}

export class UIDataUpdateFunction implements UIDataUpdateFunctionInterface {
  'id': string;
  'code': string;
  'generatedBy': string;
  'uiDataEventId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  event: UIDataEvent;
  dataMaps: DataMapping[];
  constructor(data?: UIDataUpdateFunctionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UIDataUpdateFunction`.
   */
  public static getModelName() {
    return 'UIDataUpdateFunction';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of UIDataUpdateFunction for dynamic purposes.
   **/
  public static factory(
    data: UIDataUpdateFunctionInterface
  ): UIDataUpdateFunction {
    return new UIDataUpdateFunction(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'UIDataUpdateFunction',
      plural: 'UIDataUpdateFunctions',
      path: 'ui-data-update-functions',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        code: {
          name: 'code',
          type: 'string',
        },
        generatedBy: {
          name: 'generatedBy',
          type: 'string',
          default: 'rappider',
        },
        uiDataEventId: {
          name: 'uiDataEventId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
      },
      relations: {
        event: {
          name: 'event',
          type: 'UIDataEvent',
          model: 'UIDataEvent',
          relationType: 'belongsTo',
          keyFrom: 'uiDataEventId',
          keyTo: 'id',
        },
        dataMaps: {
          name: 'dataMaps',
          type: 'DataMapping[]',
          model: 'DataMapping',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'uiDataUpdateFunctionId',
        },
      },
    };
  }
}
