import { AlertTypes, HeadingType } from '@rappider/rappider-components/utils';

export const settingsSecondStepModalAlertConfig = {
  type: AlertTypes.Info,
  description: {
    text: 'In this step, choose a layout for your CRUD pages. You can modify the layout later from the Edit Page section.'
  },
  title: {
    type: HeadingType.H6,
    content: 'Applying Layout to CRUD Pages'
  },
  closeable: false,
  showIcon: true
};
