/* eslint-disable */
import { Project, UIDataEvent, DataSchema } from '../index';

declare var Object: any;
export interface UIDataStoreInterface {
  id: string;
  name: string;
  type: string;
  description?: string;
  projectId?: string;
  createdDate?: Date;
  dataSchemaId?: string;
  project?: Project;
  events?: UIDataEvent[];
  schema?: DataSchema;
  isDeletable?: boolean;
  isUpdatable?: boolean;
}

export class UIDataStore implements UIDataStoreInterface {
  'id': string;
  'name': string;
  'type': string;
  'description': string;
  'projectId': string;
  'createdDate': Date;
  'dataSchemaId': string;
  'isDeletable'?: boolean;
  'isUpdatable'?: boolean;
  project: Project;
  events: UIDataEvent[];
  schema: DataSchema;
  constructor(data?: UIDataStoreInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UIDataStore`.
   */
  public static getModelName() {
    return 'UIDataStore';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of UIDataStore for dynamic purposes.
   **/
  public static factory(data: UIDataStoreInterface): UIDataStore {
    return new UIDataStore(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'UIDataStore',
      plural: 'UIDataStores',
      path: 'ui-data-stores',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        type: {
          name: 'type',
          type: 'string',
          default: 'BRANCH',
        },
        description: {
          name: 'description',
          type: 'string',
        },
        projectId: {
          name: 'projectId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        dataSchemaId: {
          name: 'dataSchemaId',
          type: 'string',
        },
        isDeletable: {
          name: 'isDeletable',
          type: 'boolean',
        },
        isUpdatable: {
          name: 'isUpdatable',
          type: 'boolean',
        },
      },
      relations: {
        project: {
          name: 'project',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
          keyFrom: 'projectId',
          keyTo: 'id',
        },
        events: {
          name: 'events',
          type: 'UIDataEvent[]',
          model: 'UIDataEvent',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'uiDataStoreId',
        },
        schema: {
          name: 'schema',
          type: 'DataSchema',
          model: 'DataSchema',
          relationType: 'belongsTo',
          keyFrom: 'dataSchemaId',
          keyTo: 'id',
        },
      },
    };
  }
}
