import { Component, EventEmitter, Input, Output } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { HeadingComponentConfig, HeadingType } from '@rappider/rappider-components/utils';
import { WorkflowEvent, WorkflowEventPartial } from '@rappider/rappider-sdk';
import { EventMode } from '../../utils/event-mode.enum';

@Component({
  selector: 'rappider-workflow-event-wrapper',
  templateUrl: './workflow-event-wrapper.component.html',
  styleUrls: ['./workflow-event-wrapper.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ transform: 'translateX(100%)', opacity: 0 }),
            animate('150ms', style({ transform: 'translateX(0)', opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ transform: 'translateX(0)', opacity: 1 }),
            animate('150ms', style({ transform: 'translateX(100%)', opacity: 0 }))
          ]
        )
      ]
    )
  ]
})

export class WorkflowEventWrapperComponent {
  @Input() eventMode: EventMode;
  @Input() workflowEvent: WorkflowEvent;
  @Input() submitButtonLoading: boolean;

  @Output() createWorkflowEvent = new EventEmitter<WorkflowEventPartial>();
  @Output() editWorkflowEvent = new EventEmitter<WorkflowEventPartial>();
  @Output() eventNameChange = new EventEmitter<string>();

  EventMode = EventMode;
  workflowWrapperHeading: HeadingComponentConfig = {
    content: 'Workflow Event',
    type: HeadingType.H4
  };

  onCreateWorkflowEventFormSubmit(workflowEvent: WorkflowEventPartial) {
    this.createWorkflowEvent.emit(workflowEvent);
  }

  onEditWorkflowEventFormSubmit(workflowEvent: { workflowEventId: string; workflowEventBody?: WorkflowEventPartial }) {
    this.editWorkflowEvent.emit(workflowEvent);
  }

  onEventNameChange(name: string) {
    this.eventNameChange.emit(name);
  }
}
