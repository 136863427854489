<div *ngIf="!isTemplateSelected" class="project-create-wrapper">
  <div class="cards-with-preview">
    <div class="cards-wrapper" >
      <rappider-cards [items]="publicProjectsCardsConfig.items"
                      [topSectionCards]="publicProjectsTopSectionCardsConfig.items"
                      [isLoading]="publicProjectsCardsConfig.publicProjectsIsLoading"
                      [itemCountPerRow]="publicProjectsCardsConfig.itemCountPerRow"
                      [showTitleOnImage]="publicProjectsCardsConfig.showTitleOnImage"
                      [showDescriptionOnImage]="publicProjectsCardsConfig.showDescriptionOnImage"
                      [selectConfig]="publicProjectsCardsConfig.selectConfig"
                      [paginationConfig]="publicProjectsCardsConfig.paginationConfig"
                      [showCheckMarkOnCard]="publicProjectsCardsConfig.showCheckMarkOnCard"
                      [isDynamicPagination]="publicProjectsCardsConfig.isDynamicPagination"
                      [topSectionHeight]="topSectionHeight"
                      [bottomSectionHeight]="bottomSectionHeight"
                      (cardClick)="onCardClick($event)"
                      (pageIndexChange)="onPageIndexChange($event)"
                      (searchTextChange)="onSearchTextChange($event)"></rappider-cards>
    </div>
  </div>
</div>
<section *ngIf="isTemplateSelected" class="project-create-form-wrapper">
  <div class="container">
    <rappider-crud-view-edit-form #projectCreateForm
                                  [style.display]="createProjectProcess ? 'none': 'block'"
                                  [config]="PROJECT_CREATE_FORM_CONFIG"
                                  [data]="projectFormData"
                                  [submitButtonLoading]="isLoading"
                                  [slideMode]="true"
                                  (fieldValueChange)="onFieldValueChange($event)"
                                  (formSubmit)="createProject($event)">
    </rappider-crud-view-edit-form>
  </div>
  <div *ngIf="createProjectProcess"
       class="loading">
    <h5>We are creating your project; this process may take 1-2 minutes..</h5>
    <rappider-lottie-player [options]="lottieConfig.options"
                            [height]="lottieConfig.height"></rappider-lottie-player>
  </div>
</section>
