import { createAction, props } from '@ngrx/store';
import { ProcessInfoWithRelations } from '@rappider/rappider-sdk';

export enum ActionTypes {
  GetProcessInfos = '[ProcessInfo] GetProcessInfos',
  GetProcessInfosSuccessful = '[ProcessInfo] GetProcessInfosSuccessful',
  PollProcessInfo = '[ProcessInfo] PollProcessInfo',
  AddProcessInfo = '[ProcessInfo] AddProcessInfo',
  UpdateLastPollingDateToNow = '[ProcessInfo] UpdateLastPollingDateToNow',
  Error = '[ProcessInfo] Error'
}

export const GetProcessInfos = createAction(
  ActionTypes.GetProcessInfos
);

export const GetProcessInfosSuccessful = createAction(
  ActionTypes.GetProcessInfosSuccessful,
  props<{ payload: { processInfos: ProcessInfoWithRelations[] } }>()
);

export const PollProcessInfo = createAction(
  ActionTypes.PollProcessInfo,
  props<{ pollingKey: string }>()
);

export const UpdateLastPollingDateToNow = createAction(
  ActionTypes.UpdateLastPollingDateToNow
);

export const AddProcessInfo = createAction(
  ActionTypes.AddProcessInfo,
  props<{ payload: { processInfo: ProcessInfoWithRelations } }>()
);

export const Error = createAction(
  ActionTypes.Error,
  props<{ payload: { error: any } }>()
);
