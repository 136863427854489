<nz-modal *ngIf="visible"
          [(nzVisible)]="visible"
          [nzTitle]="title | translate"
          [nzWidth]="width"
          [nzOkText]="okText | translate"
          [nzCancelText]="cancelText | translate"
          [nzOkDisabled]="okDisabled"
          [nzCancelDisabled]="cancelDisabled"
          [nzFooter]="footer"
          [nzOkDanger]="okDanger"
          [nzOkLoading]="okLoading"
          [nzKeyboard]="true"
          [nzMask]="mask"
          [nzMaskClosable]="maskClosable"
          [nzClassName]="(fullScreen ? 'full-screen-modal ' : width ? 'mini-modal ' : 'default-modal ') + className"
          [nzBodyStyle]="bodyStyle"
          [nzClosable]="closeIconVisibility"
          (nzOnCancel)="onCancelClick()"
          (nzOnOk)="onOkClick()"
          (nzAfterOpen)="onAfterOpen()">
  <ng-container *nzModalContent>
    <ng-content></ng-content>
  </ng-container>
</nz-modal>