<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>

<section class="search-collapse-expand">
  <div class="search-area">
    <rappider-input-group [(ngModel)]="searchText"
                          [textbox]="inputGroupConfig.textbox"
                          [suffixIcon]="inputGroupConfig.suffixIcon"
                          (ngModelChange)="onSearchTextChange()">
    </rappider-input-group>
  </div>
  <div class="collapse-expand-buttons">
    <rappider-button [text]="collapseAndExpandButtons.expandButton.text"
                     [type]="collapseAndExpandButtons.expandButton.type"
                     [key]="collapseAndExpandButtons.expandButton.key"
                     [icon]="collapseAndExpandButtons.expandButton.icon"
                     (confirm)="collapseOrExpandAll(collapseAndExpandButtons.expandButton.key)">
    </rappider-button>
    <rappider-button [text]="collapseAndExpandButtons.collapseButton.text"
                     [type]="collapseAndExpandButtons.collapseButton.type"
                     [key]="collapseAndExpandButtons.collapseButton.key"
                     [icon]="collapseAndExpandButtons.collapseButton.icon"
                     (confirm)="collapseOrExpandAll(collapseAndExpandButtons.collapseButton.key)">
    </rappider-button>
  </div>
</section>

<nz-collapse>
  <nz-collapse-panel *ngFor="let data of searchText ? searchResults: uiDataStores"
                     [(nzActive)]="panels[data?.id]"
                     [nzHeader]="data?.name"
                     [nzExtra]="data?.isReadOnly ? null : createButtonTemplate">
    <rappider-data-event-list [uiDataEvents]="data?.events"
                              [loadingState]="loadingState"
                              [config]="data?.events?.length > 0 ? DATA_EVENTS_LIST_CONFIG : DATA_EVENTS_EMPTY_LIST_CONFIG"
                              (deleteEvent)="onUIDataEventDelete($event)"
                              (editEvent)="onUIDataEventEdit($event)">
    </rappider-data-event-list>
    <ng-template #createButtonTemplate>
      <div class="panel-header">
        <rappider-button [icon]="addDataEventButtonConfig.icon"
                         [type]="addDataEventButtonConfig.type"
                         [size]="addDataEventButtonConfig.size"
                         (confirm)="navigateCreateDataEventPage(data?.id)"
                         nz-tooltip
                         nzTooltipTitle="Add UI Data Event"
                         nzType="close"
                         nzTheme="outline">
        </rappider-button>
      </div>
    </ng-template>
  </nz-collapse-panel>
</nz-collapse>
