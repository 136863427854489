import { KeyValue } from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyType, PropertyFormat } from '@rappider/rappider-components/utils';
import { cloneDeep } from 'lodash';
import { AddParameterValue, FORMAT_OPTIONS, TYPE_OPTIONS } from '../../../shared';
import { DEFAULT_ADD_PARAMETER_VALUE_DATA } from './utils/default-add-parameter-value-data';

@Component({
  selector: 'rappider-add-parameter-value',
  templateUrl: './add-parameter-value.component.html',
  styleUrls: ['./add-parameter-value.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => AddParameterValueComponent),
      multi: true
    }
  ]
})

export class AddParameterValueComponent implements ControlValueAccessor {
  @Input() typeSelectOptions: KeyValue<string, string>[] = TYPE_OPTIONS;
  @Input() formatSelectOptions: KeyValue<string, string>[] = FORMAT_OPTIONS;
  @Input() showCodemirrorForObjectAndArray: boolean;

  _value: AddParameterValue;

  PropertyType = PropertyType;
  PropertyFormat = PropertyFormat;

  get value() {
    return this._value;
  }

  set value(value: AddParameterValue) {
    this._value = value;
    this.configureDateFormat();
    this.configureFormatOptionsData();
    this.onChange(value);
    this.onTouched();
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: AddParameterValue): void {
    this._value = value;
    if (value == null) {
      this._value = cloneDeep(DEFAULT_ADD_PARAMETER_VALUE_DATA);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
     * clears the value when new type selected and sets the selected type
     *
     * @param {string} type
     * @memberof AddParameterValueComponent
     */
  onTypeSelect(type: string) {
    this.onFormatSelect(null);
    this.value.type = type;
  }

  /**
   * clears value when new format selected and sets the selected format
   *
   * @param {string} format
   * @memberof AddParameterValueComponent
   */
  onFormatSelect(format: string) {
    this.value.format = format;
  }

  /**
   * configures format of the date depending on type returns
   * timestamp if type is number, returns string if type is string
   *
   * @memberof AddParameterValueComponent
   */
  configureDateFormat() {
    if (this.value.type === this.PropertyType.String &&
      this.value.format === this.PropertyFormat.DateTime) {

      this.value.value = this.value.value?.toString();
    } else if (this.value.type === this.PropertyType.Integer &&
      this.value.format === this.PropertyFormat.DateTime) {

      this.value.value = Date.parse(this.value.value);
    }
  }

  /**
   * configures format select options
   * if type is number filter uuid format
   * if type is string shows all format options
   *
   * @memberof AddParameterValueComponent
   */
  configureFormatOptionsData() {
    if (this.value.type === this.PropertyType.String) {
      this.formatSelectOptions = FORMAT_OPTIONS;
    } else if (this.value.type === this.PropertyType.Integer) {
      const NUMBER_FORMAT_OPTIONS = this.formatSelectOptions.filter(item => item.value !== this.PropertyFormat.UUID);
      this.formatSelectOptions = NUMBER_FORMAT_OPTIONS;
    }
  }

  onInputValueChange() {
    this.value = { ...this.value };
  }

}
