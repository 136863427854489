/* eslint-disable */
import { Project } from '../index';

declare var Object: any;
export interface ProjectVersionInterface {
  id?: string;
  versionType: string;
  versionNumber: string;
  appType: string;
  framework: string;
  description?: string;
  projectId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  project?: Project;
}

export class ProjectVersion implements ProjectVersionInterface {
  'id': string;
  'versionType': string;
  'versionNumber': string;
  'appType': string;
  'framework': string;
  'description': string;
  'projectId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  project: Project;
  constructor(data?: ProjectVersionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ProjectVersion`.
   */
  public static getModelName() {
    return 'ProjectVersion';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ProjectVersion for dynamic purposes.
   **/
  public static factory(data: ProjectVersionInterface): ProjectVersion {
    return new ProjectVersion(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ProjectVersion',
      plural: 'ProjectVersions',
      path: 'project-versions',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        versionType: {
          name: 'versionType',
          type: 'string',
        },
        versionNumber: {
          name: 'versionNumber',
          type: 'string',
        },
        appType: {
          name: 'appType',
          type: 'string',
        },
        framework: {
          name: 'framework',
          type: 'string',
        },
        description: {
          name: 'description',
          type: 'string',
        },
        projectId: {
          name: 'projectId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
      },
      relations: {
        project: {
          name: 'project',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
          keyFrom: 'projectId',
          keyTo: 'id',
        },
      },
    };
  }
}
