<!-- ----------------------------------------------------------------------------------------- -->
<!-- TITLE -->
<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>
<!-- / TITLE -->
<!-- ----------------------------------------------------------------------------------------- -->

<!-- ----------------------------------------------------------------------------------------- -->
<!-- PROJECT EXPORT FORM -->
<section *ngIf="projectVersionWithDetails">
  <h5>{{ 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.EXPORT_INFORMATIONS' | translate }}</h5>
  <nz-divider></nz-divider>
  <div class="col-12 col-lg-6">

    <rappider-crud-view-edit-form [config]="PROJECT_EXPORT_CONFIG"
                                  [data]="projectVersionWithDetails"
                                  [submitButtonLoading]="loading"
                                  (formSubmit)="onProjectExportClick()"></rappider-crud-view-edit-form>

  </div>
</section>
<!-- / PROJECT EXPORT FORM -->
<!-- ----------------------------------------------------------------------------------------- -->