import { Injectable } from '@angular/core';
import { Person, User } from '@rappider/rappider-sdk';
import { PHONE_NUMBER_CLEANSE_REGEX, UsernameType } from '@rappider/shared/definitions';
import * as moment from 'moment-timezone';
import { environment } from '../../../../../../apps/rappider/src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {


  createUserData(data: any, usernameType: UsernameType) {
    /* Set Phone Object
    * TODO: Change phone type. Global import. Dynamic country code
    */
    const phoneInformationData = data.phoneNumber
      ? {
        /* TODO: FIXME: Should verify phone number and verify phone number fields be deleted? */
        phoneNumber: {
          needsVerification: true,
          phoneType: 'MOBILE_PHONE',
          countryCode: data.countryCode,
          phoneNumber: this.cleansePhoneNumber(data.phoneNumber),
          isVerified: false
        }
      }
      : {};

    /* Set PersonData Object for userCreateData*/
    const mappedPersonData = {
      ...data,
      timeZone: moment.tz.guess()
    };

    /* mappedPersonData doesn't need phoneNumber field because we have phoneNumber object */
    delete mappedPersonData.phoneNumber;
    /* mappedPersonData doesn't need password and confirm password field because mappedPersonData has only person data*/
    delete mappedPersonData.password;
    delete mappedPersonData.confirmPassword;

    /* Set User Data for userCreateData*/
    const userData = {
      username: usernameType === UsernameType.PhoneNumber
        ? `${phoneInformationData.phoneNumber.countryCode}${this.cleansePhoneNumber(data.phoneNumber)}`
        : data.email,
      password: data.password,
      usernameType: usernameType
    };

    /* delete email in mappedPersondata & userData obj. because incoming data doesn't have email field */
    if (!data.email) {
      delete mappedPersonData.email;
    }
    /** userCreateData include userData & phone information data & person data  */
    const userCreateData = {
      user: <Partial<User>>userData,
      person: <Partial<Person>>mappedPersonData,
      phoneNumber: <any>phoneInformationData.phoneNumber,
    };
    return userCreateData;
  }

  /**
   * This func. is replace phone number. '(222) 222-2222' => '2222222222'
   *
   * @param {string} phoneNumber
   * @returns
   * @memberof RegisterComponent
   */
  cleansePhoneNumber(phoneNumber: string) {
    if (phoneNumber) {
      return phoneNumber.replace(PHONE_NUMBER_CLEANSE_REGEX, '');
    }
  }

}
