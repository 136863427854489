import { Injectable } from '@angular/core';
import { NzTreeNode } from 'ng-zorro-antd/tree';
import { TreeNode } from '../../components/data-transformation/data-transformation.component';
import { SchemaTreeSelectComponent } from '../../modules/shared-components/schema-tree-select/schema-tree-select.component';

@Injectable({
  providedIn: 'root'
})
export class SchemaTreeService {

  constructor() { }

  /**
   * This fn converts JSON schema to Tree which used in components across data transformation module.
   *
   * @param {*} jsonSchema
   * @return {*}
   * @memberof SchemaTreeService
   */
  buildTreeFromJSONSchema(jsonSchema) {
    const schemaRef = jsonSchema?.$ref?.split('/') || jsonSchema?.items?.$ref?.split('/');
    let tree = [];
    /* if there is schema ref, it means that this schema isn't belongs to a primitive type */
    if (schemaRef) {
      const rootName = schemaRef[schemaRef?.length - 1];
      tree = [
        {
          title: rootName,
          key: rootName,
          type: jsonSchema.type,
          data: jsonSchema.definitions[rootName],
          children: this.buildTreeNodeFromSchema(jsonSchema.definitions[rootName], rootName, jsonSchema) || [],
          expanded: true,
          $ref: rootName
        }
      ];
      /* handling the primitive type schema */
    } else {
      tree = [
        {
          title: jsonSchema.type,
          key: jsonSchema.type,
          type: jsonSchema.type,
          data: jsonSchema,
          isLeaf: true
        }
      ];
    }
    return tree;
  }

  buildTreeNodeFromSchema(definition: any, parentName: string, jsonSchema, addedPropertyKeys?: string[], addedPropertyRefs?: string[]) {
    if (definition?.properties) {
      const properties = <any>Object.entries(definition?.properties).map(([key, value]) => ({
        key: key,
        value: value
      }));
      const nodes: NzTreeNode[] = properties.map(property => {
        const propertyKey = parentName ? (parentName + '.' + property.key) : property.key;
        const propertyRef = property.value?.$ref || property.value?.items?.$ref;
        /* checks if this property added to tree before */
        const isPropertyKeyAddedBefore = addedPropertyKeys?.some(key => key === propertyKey);
        const isPropertyRefAddedBefore = addedPropertyRefs?.some(ref => ref === propertyRef);

        if ((!(property.value?.items && (property.value?.items?.type === 'object')) && !propertyRef) || isPropertyKeyAddedBefore || isPropertyRefAddedBefore) {
          return { title: property.key, key: propertyKey, type: property.value.type, isLeaf: true, data: property.value };
        } else {
          addedPropertyKeys = [...(addedPropertyKeys || []), ...[propertyKey]];
          addedPropertyRefs = [...(addedPropertyRefs || []), ...[propertyRef]];
          return {
            title: property.key,
            key: propertyKey,
            type: property.value.type,
            data: property.value,
            children: this.buildTreeNodeFromSchema(
              this.getDefinitionByRefName(
                property.value?.items?.$ref || property.value.$ref,
                jsonSchema
              ),
              propertyKey,
              jsonSchema,
              addedPropertyKeys,
              addedPropertyRefs
            ),
            expanded: false
          };
        }
      });
      return nodes;
    } else {
      return undefined;
    }
  }

  getDefinitionByElementName(definitionName: string, jsonSchema) {
    return jsonSchema.definitions[definitionName];
  }

  getDefinitionByRefName($ref: string, jsonSchema) {
    const elementName = this.getRefNameBy$ref($ref);
    return this.getDefinitionByElementName(elementName, jsonSchema);
  }

  getRefNameBy$ref($ref) {
    const elementRefSplittedArray = $ref.split('/');
    const refName = elementRefSplittedArray[elementRefSplittedArray.length - 1];
    return refName;
  }

  getFieldTreeFromField(field, schemaTreeComponent: SchemaTreeSelectComponent) {
    const fieldKeys = field.split('.');
    const fieldStringTree = fieldKeys.map((fieldKey, index) => {
      let rootIndex = 0;
      let treeKey = '';
      while (rootIndex <= index) {
        if (treeKey !== '') {
          treeKey = `${treeKey}.${fieldKeys[rootIndex]}`;
        } else {
          treeKey = fieldKeys[rootIndex];
        }
        rootIndex = rootIndex + 1;
      }
      return treeKey;
    });
    const fieldTreeNode = this.getFieldTreeNodeFromFieldStringTree(fieldStringTree, schemaTreeComponent);
    return fieldTreeNode;
  }

  getFieldTreeNodeFromFieldStringTree(fieldStringTree, schemaTreeComponent) {
    return fieldStringTree.map(field => {
      const fieldNode = (<any>schemaTreeComponent.getSelectedPropertyOriginDataByKey(field));
      return {
        name: fieldNode.title,
        type: fieldNode?.type || fieldNode.data?.type,
        format: fieldNode.data?.format
      };
    });
  }
}
