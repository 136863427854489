<div id="monaco-editor-main-container"
     class="editor-main-container">
     <div class="editor-size-wrapper"
          [style.width]="markdownMode === 'markdownPreview' ? '0px':(markdownMode === 'markdownAndPreview' ? '50%' : '100%')">
          <div class="editor-container"
               [style.display]="markdownMode === 'markdownPreview' ? 'none' : 'block'"
               (mousedown)="setFullKeyPathFeature()"
               #editorContainer></div>
     </div>
     <div *ngIf="options?.language === 'markdown' && (markdownMode === 'markdownPreview' || markdownMode === 'markdownAndPreview')"
          class="editor-markdown-preview-container"
          [style.width]="markdownMode === 'markdownPreview' ? '100%' : '50%'">
          <div class="markdown-preview"
               [innerHTML]="markDownPreviewValue"></div>
     </div>
</div>
