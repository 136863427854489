import { UiDataUpdateFunctionWithRelations } from '@rappider/rappider-sdk';
import * as UIDataUpdateFunctionActions from './ui-data-update-function.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import * as UIDataStoreActions from '../ui-data-store/ui-data-store.actions';
import { orderBy } from 'lodash';

export const uiDataUpdateFunctionFeatureKey = 'uiDataUpdateFunction';

export interface UiDataUpdateFunctionState {
  data: UiDataUpdateFunctionWithRelations[];
  loading: boolean;
  error: {
    timestamp?: number;
    key?: string;
    error?: unknown;
  };
}

export const initialState: UiDataUpdateFunctionState = {
  data: [],
  loading: false,
  error: {}
};

export function reducer(
  state: UiDataUpdateFunctionState = initialState,
  action: UIDataUpdateFunctionActions.Actions | AuthenticationActions.Actions | UIDataStoreActions.Actions
): UiDataUpdateFunctionState {
  switch (action.type) {

    case UIDataStoreActions.ActionTypes.SetUIDataStores:
      return {
        ...state,
        loading: true
      };

    case UIDataUpdateFunctionActions.ActionTypes.GetUIDataUpdateFunctionsSuccessful:
      return {
        ...state,
        data: action.payload.uiDataUpdateFunctions,
        loading: false
      };

    case UIDataUpdateFunctionActions.ActionTypes.GetUIDataUpdateFunctionsAccordingToUIDataStoreId:
      return {
        ...state,
        loading: true
      };

    case UIDataUpdateFunctionActions.ActionTypes.GetUIDataUpdateFunctionsAccordingToUIDataStoreIdSuccessful:
      return {
        ...state,
        data: [
          ...state.data,
          ...action.payload.uiDataUpdateFunctions
        ],
        loading: false
      };

    case UIDataUpdateFunctionActions.ActionTypes.GetUIDataUpdateFunctionsAccordingToUIDataStoreIdFailure:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case UIDataUpdateFunctionActions.ActionTypes.CreateUIDataUpdateFunction:
      return {
        ...state,
        loading: true
      };

    case UIDataUpdateFunctionActions.ActionTypes.CreateUIDataUpdateFunctionSuccessful:
      return {
        ...state,
        data: [
          ...state.data,
          action.payload.uiDataUpdateFunction
        ],
        loading: false
      };

    case UIDataUpdateFunctionActions.ActionTypes.UpdateUIDataUpdateFunction:
      return {
        ...state,
        loading: true
      };

    case UIDataUpdateFunctionActions.ActionTypes.UpdateUIDataUpdateFunctionSuccessful: {
      const updatedUIDataUpdateFunction = {
        ...state.data.find(uiDataUpdateFunction => uiDataUpdateFunction.id === action.payload.id),
        ...action.payload.uiDataUpdateFunction
      };
      return {
        ...state,
        loading: false,
        ...action.payload,
        data: [
          ...state.data.filter(uiDataUpdateFunction => uiDataUpdateFunction.id !== action.payload.id),
          updatedUIDataUpdateFunction
        ]
      };
    }

    case UIDataUpdateFunctionActions.ActionTypes.BulkCreateUIDataUpdateFunction:
      return {
        ...state,
        loading: true
      };

    case UIDataUpdateFunctionActions.ActionTypes.BulkCreateUIDataUpdateFunctionSuccessful:
      return {
        ...state,
        data: [
          ...state.data,
          ...action.payload.uiDataUpdateFunctions
        ],
        loading: false
      };

    case UIDataUpdateFunctionActions.ActionTypes.DeleteUIDataUpdateFunction:
      return {
        ...state,
        loading: true
      };

    case UIDataUpdateFunctionActions.ActionTypes.DeleteUIDataUpdateFunctionSuccessful:
      return {
        ...state,
        data: state.data.filter(dataUpdateFunction => dataUpdateFunction.id !== action.payload.id),
        loading: false
      };

    case UIDataUpdateFunctionActions.ActionTypes.GetUIDataUpdateFunctionsFailure:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case UIDataUpdateFunctionActions.ActionTypes.CreateUIDataUpdateFunctionFailure:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case UIDataUpdateFunctionActions.ActionTypes.UpdateUIDataUpdateFunctionFailure:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case UIDataUpdateFunctionActions.ActionTypes.DeleteUIDataUpdateFunctionFailure:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case UIDataUpdateFunctionActions.ActionTypes.BulkCreateUIDataUpdateFunctionFailure:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case AuthenticationActions.ActionTypes.Logout:
      return initialState;

    case AuthenticationActions.ActionTypes.ChangeActiveProjectSuccessful:
      return initialState;

    default:
      return state;
  }
}
