<div class="function-name">
  <span class="form-label">Function Name:</span> &nbsp;
  <div class="name-input">
    <rappider-textbox [(ngModel)]="value.name"
                      placeholder="name"
                      (ngModelChange)="onValueChange()"></rappider-textbox>
  </div>
</div>

<div class="custom-function">
  <div class="border">
    <div class="predefined-text">
      function &nbsp; {{ name }}

      ({{ parametersName }})
      <span [innerHTML]="'{'"></span>
    </div>
    <div class="codemirror border">
      <rappider-code-editor [(ngModel)]="value.code"
                            [settings]="codemirrorJsonSettings"
                            (ngModelChange)="onValueChange()">
      </rappider-code-editor>
    </div>
    <div class="predefined-text">
      <span [innerHTML]="'}'"></span>
    </div>
  </div>
</div>