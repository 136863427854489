import { createSelector } from '@ngrx/store';
import { UiDataEventWithRelations, UiDataStoreWithRelations } from '@rappider/rappider-sdk';

export const uiDataEventListDataSelector = createSelector(
  state => state['uiDataStore']?.data,
  state => state['uiDataEvent']?.data,
  (uiDataStores: UiDataStoreWithRelations[], uiDataEvents: UiDataEventWithRelations[]) => {
    const uiDataEventListData = uiDataStores?.map(uiDataStore => ({
    ...uiDataStore,
    events: uiDataEvents.filter(uiDataEvent => uiDataEvent.uiDataStoreId === uiDataStore.id)
  }));
    return uiDataEventListData;
  }
);
