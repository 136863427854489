import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as RouterActions from './router.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { QueryParam } from '../../definitions/query-param';
import { Location } from '@angular/common';

@Injectable()
export class RouterEffects {

  constructor(
    private store: Store<any>,
    private actions$: Actions,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) { }


  navigate$ = createEffect(() => this.actions$.pipe(
    ofType<RouterActions.Navigate>(RouterActions.ActionTypes.Navigate),
    withLatestFrom(
      this.store.select(state => state.router.queryParams)
    ),
    concatMap(([action, queryParams]) => {
      /* return actions */
      const actions: Action[] = [];
      /* get url */
      const url = action.payload.url;
      /* if url equals to redirect url then remove redirect url query param */
      if (url === queryParams[QueryParam.RedirectUrl]) {
        actions.push(
          new RouterActions.RemoveQueryParam({ key: QueryParam.RedirectUrl })
        );
      }
      /* route to url if url exists */
      if (url) {
        actions.push(
          new RouterActions.RouteToUrl({ url: url })
        );
      }
      return actions.length ? actions : [new RouterActions.NavigateFailure({ error: 'This url could not found', key: 'NavigateFailure', timestamp: Date.now() })];
    })
  ));


  routeToUrl$ = createEffect(() => this.actions$.pipe(
    ofType<RouterActions.RouteToUrl>(RouterActions.ActionTypes.RouteToUrl),
    withLatestFrom(
      this.store.select(state => state.router.queryParams)
    ),
    map(([action, queryParams]) => {
      const url = action.payload.url;
      if (!queryParams) {
        this.router.navigateByUrl(url);
      } else {
        this.router.navigate([url], { queryParams: queryParams || [] });
      }
    })
  ), { dispatch: false });


  queryParamsChange$ = createEffect(() => this.actions$.pipe(
    ofType<RouterActions.AddQueryParam | RouterActions.RemoveQueryParam | RouterActions.ClearQueryParams>
      (
        RouterActions.ActionTypes.AddQueryParam,
        RouterActions.ActionTypes.RemoveQueryParam,
        RouterActions.ActionTypes.ClearQueryParams
      ),
    withLatestFrom(
      this.store.select(state => state.router.queryParams)
    ),
    map(([action, queryParams]) => {
      const url = this.router.createUrlTree([], { relativeTo: this.activatedRoute, queryParams: queryParams }).toString();
      this.location.go(url);
    })
  ), { dispatch: false });

}
