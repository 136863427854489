import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { DividerStyle } from '../../utils/divider';
import { DividerOrientation } from '../../utils/divider/divider-orientation.enum';
import { DividerType } from '../../utils/divider/divider-type.enum';
import { TextComponentConfig, TextMode } from '../../utils/text';

@Component({
  selector: 'rappider-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss']
})
export class RappiderDividerComponent implements OnInit {
  @Input() style: DividerStyle;
  @Input() type: DividerType;
  @Input() text: TextComponentConfig;
  @Input() textPlacement: DividerOrientation;
  @Input() dividerWidth: string;
  @Input() dividerColor: string;

  @HostBinding('style.--border-color') borderColor;
  @HostBinding('style.--border-width') borderWidth;

  DividerType = DividerType;
  TextMode = TextMode;

  ngOnInit(): void {
    this.setConfig();
  }

  setConfig() {
    if (this.dividerColor) {
      this.borderColor = this.dividerColor;
    } else {
      this.borderColor = 'var(--primary-border-color)';
    }

    if (this.dividerWidth) {
      this.borderWidth = this.dividerWidth;
    } else {
      this.borderWidth = 'var(--border-size-x)';
    }
  }
}
