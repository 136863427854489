import { Component, Input } from '@angular/core';
import { ColorConfig, TypographyConfig } from '../../utils/shared';

@Component({
  selector: 'rappider-paragraph',
  templateUrl: './paragraph.component.html',
  styleUrls: ['./paragraph.component.scss']
})
export class RappiderParagraphComponent {
  /* paragraph content */
  @Input() content: string;
  @Input() typography: TypographyConfig;
  @Input() colorSettings: ColorConfig;

}
