import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MessageTemplateApi } from '@rappider/api-sdk';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { NotificationService, PaginationService } from '@rappider/services';
import * as MessageTemplateActions from './message-template.actions';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { SetActiveEnvironment } from '../environment-variables-state/environment-variable.actions';
import { MessageTemplateControllerService, MessageTemplateWithRelations } from '@rappider/rappider-sdk';
import { Navigate } from '@rappider/shared';


@Injectable()
export class MessageTemplateEffects {
  constructor(
    private actions$: Actions,
    private oldMessageTemplateApi: MessageTemplateApi,
    private messageTemplateApi: MessageTemplateControllerService,
    private store: Store<any>,
    private notificationService: NotificationService,
    private paginationService: PaginationService
  ) { }


  loadModule$ = createEffect(() => this.actions$.pipe(
    ofType<MessageTemplateActions.LoadModule>(
      MessageTemplateActions.ActionTypes.LoadModule,
    ),
    concatMap(() => [
      new MessageTemplateActions.GetTotalCount(),
      new MessageTemplateActions.GetMessageTemplates()
    ])
  ));

  /**
   * Get Total Count for Message Templates
   *
   * @memberof MessageTemplateEffects
   */

  getTotalCount$ = createEffect(() => this.actions$.pipe(
    ofType<MessageTemplateActions.GetTotalCount>(
      MessageTemplateActions.ActionTypes.GetTotalCount
    ),
    withLatestFrom(
      this.store.select(state => state.activeProject.data),
      this.store.select(state => state.messageTemplate.pagination)
    ),
    mergeMap(([action, activeProject, pagination]) => {
      const filter = {
        where: { projectId: activeProject?.id }
      };
      return this.oldMessageTemplateApi.count(filter.where).pipe(
        map((res: { count: number }) => new MessageTemplateActions.SetPagination({
          pagination: {
            totalCount: res.count,
            totalPageNumber: this.paginationService.getTotalPageNumber(res.count, pagination.pageSize)
          }
        })),
        catchError(error => [new MessageTemplateActions.GetTotalCountFailure({ error, key: 'GetTotalCountFailure', timestamp: Date.now() })]
        )
      );
    })
  ));

  /**
   * Get Message Template
   *
   * @memberof MessageTemplatesEffects
   */

  getMessageTemplates$ = createEffect(() => this.actions$.pipe(
    ofType<MessageTemplateActions.GetMessageTemplates>(
      MessageTemplateActions.ActionTypes.GetMessageTemplates
    ),
    withLatestFrom(
      this.store.select(state => state.activeProject.data?.id),
      this.store.select(state => state.messageTemplate.pagination),
      this.store.select(state => state.environmentVariable.activeEnvironmentKey)
    ),
    mergeMap(([action, activeProjectId, messageTemplatesPagination, activeEnvironmentKey]) => {
      const filter = {
        where: {
          projectId: activeProjectId,
          environmentKey: activeEnvironmentKey
        },
        skip: this.paginationService.
          getSkippedSizeByPagination(messageTemplatesPagination.currentPageNumber, messageTemplatesPagination.pageSize),
        limit: messageTemplatesPagination.pageSize
      };
      return this.messageTemplateApi.find({ filter }).pipe(
        mergeMap((messageTemplates: MessageTemplateWithRelations[]) => [
          new MessageTemplateActions.GetTotalCount(),
          new MessageTemplateActions.GetMessageTemplatesSuccessful({ messageTemplates })
        ]),
        catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_MESSAGE_TEMPLATE_COULDNOT_LOADED'
          );
          return [new MessageTemplateActions.GetMessageTemplatesFailure({ error, key: 'GetMessageTemplatesFailure', timestamp: Date.now() })];
        })
      );
    })
  ));

  /**
   * Create Message Template
   *
   * @memberof MessageTemplatesEffects
   */

  createMessageTemplate$ = createEffect(() => this.actions$.pipe(
    ofType<MessageTemplateActions.CreateMessageTemplate>(
      MessageTemplateActions.ActionTypes.CreateMessageTemplate
    ),
    withLatestFrom(
      this.store.select(state => state.auth.activePerson),
      this.store.select(state => state.activeProject.data)
    ),
    mergeMap(([action, person, activeProject]) => {
      const messageTemplateData = {
        ...action.payload.messageTemplate,
        projectId: activeProject.id
      };
      return this.oldMessageTemplateApi.create(messageTemplateData).pipe(
        mergeMap(messageTemplate => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFUL',
            'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_MESSAGE_TEMPLATE_CREATED'
          );
          return [
            new MessageTemplateActions.CreateMessageTemplateSuccessful({ messageTemplate }),
            new MessageTemplateActions.SetPagination({ pagination: { currentPageNumber: 1 }, getDataAfter: true }),
            new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_MESSAGE_TEMPLATE_LIST}` })
          ];
        }), catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_MESSAGE_TEMPLATE_COULDNOT_CREATED'
          );
          return [
            new MessageTemplateActions.CreateMessageTemplateFailure({ error, key: 'CreateMessageTemplateFailure', timestamp: Date.now() })
          ];
        }
        )
      );
    })
  ));

  /**
   * Update Message Template
   *
   * @memberof MessageTemplatesEffects
   */

  updateMessageTemplate$ = createEffect(() => this.actions$.pipe(
    ofType<MessageTemplateActions.UpdateMessageTemplate>(
      MessageTemplateActions.ActionTypes.UpdateMessageTemplate
    ),
    withLatestFrom(
      this.store.select(state => state.auth.activePerson)
    ),
    mergeMap(([action, person]) => {
      const messageTemplateData = action.payload.messageTemplate;
      return this.oldMessageTemplateApi.patchAttributes(action.payload.messageTemplateId, messageTemplateData).pipe(
        mergeMap(response => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFUL',
            'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_MESSAGE_TEMPLATE_UPDATED'
          );
          return [
            new MessageTemplateActions.UpdateMessageTemplateSuccessful({ messageTemplateId: action.payload.messageTemplateId, messageTemplate: action.payload.messageTemplate }),
            new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_MESSAGE_TEMPLATE_LIST}` })
          ];
        }), catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_MESSAGE_TEMPLATE_COULDNOT_UPDATED'
          );
          return [
            new MessageTemplateActions.UpdateMessageTemplateFailure({ error, key: 'UpdateMessageTemplateFailure', timestamp: Date.now() })
          ];
        }
        )
      );
    })
  ));

  /**
   * Delete Message Template
   *
   * @memberof MessageTemplatesEffects
   */

  deleteMessageTemplate$ = createEffect(() => this.actions$.pipe(
    ofType<MessageTemplateActions.DeleteMessageTemplate>(
      MessageTemplateActions.ActionTypes.DeleteMessageTemplate
    ),
    mergeMap(action => this.oldMessageTemplateApi.deleteById(action.payload.messageTemplateId).pipe(
      map(() => {
        this.notificationService.createNotification(
          'success',
          'SHARED.SUCCESSFUL',
          'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_MESSAGE_TEMPLATE_DELETED'
        );
        return new MessageTemplateActions.DeleteMessageTemplateSuccessful({ messageTemplateId: action.payload.messageTemplateId });
      }), catchError(error => {
        this.notificationService.createNotification(
          'error',
          'SHARED.ERROR',
          'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_MESSAGE_TEMPLATE_COULDNOT_DELETED'
        );
        return [
          new MessageTemplateActions.DeleteMessageTemplateFailure({ error, key: 'DeleteMessageTemplateFailure', timestamp: Date.now() })
        ];
      }
      )
    )
    )
  ));

  /**
  * On Active Environment Key Change Get Message Templates
  *
  * @memberof MessageTemplatesEffects
  */

  activeEnvironmentKeyChange$ = createEffect(() => this.actions$.pipe(
    ofType<MessageTemplateActions.ActiveEnvironmentChange>(
      MessageTemplateActions.ActionTypes.ActiveEnvironmentChange
    ),
    concatMap(action => {
      if (action.payload.environment) {
        return [
          new SetActiveEnvironment({ environment: action.payload.environment }),
          new MessageTemplateActions.PaginationReset()
        ];
      }
    })
  ));

}
