import { CrudTableViewConfig, CrudViewColumnType, ButtonType, ActionBehavior } from '@rappider/rappider-components/utils';

export const OPENAPI_ENDPOINT_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'name',
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'SHARED.METHOD',
      fieldName: 'method',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.DESCRIPTION',
      fieldName: 'description',
      type: CrudViewColumnType.Text
    }
  ],
  itemActions: [
    {
      text: 'SHARED.DELETE',
      name: 'delete',
      behavior: ActionBehavior.Emit,
      icon: { name: 'far fa-trash' }
    }
  ]
};
