<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton"></rappider-title-toolbar>

<section>
  <rappider-crud-view-edit-form [config]="PROJECT_DOCUMENT_CREATE_CONFIG"
                                [submitButtonLoading]="loading"
                                (formSubmit)="onCreateProjectDocument($event)"></rappider-crud-view-edit-form>
</section>
