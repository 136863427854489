<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"></rappider-title-toolbar>

<section class="admin-dashboard">
  <div class="feature-card-container row">
    <div *ngFor="let feature of projectDetailConfig"
         class="feature-card  col-lg-3 col-md-3 col-sm-6 col-xs-12">
      <div class="feature-card-content">
        <div class="feature-icon">
          <rappider-icon [name]="feature.icon.name"
                         [type]="feature.icon.type"></rappider-icon>
        </div>
        <h5 class="feature-title">
          <a [routerLink]="feature.path">
            {{feature.title}}
          </a>
        </h5>
      </div>
    </div>
  </div>
</section>
