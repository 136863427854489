/* eslint-disable */
import { Page } from '../index';

declare var Object: any;
export interface OutletInterface {
  id?: string;
  key: string;
  description?: string;
  pageId?: string;
  outletId?: string;
  createdDate?: Date;
  page?: Page;
}

export class Outlet implements OutletInterface {
  'id': string;
  'key': string;
  'description': string;
  'pageId': string;
  'outletId': string;
  'createdDate': Date;
  page: Page;
  constructor(data?: OutletInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Outlet`.
   */
  public static getModelName() {
    return 'Outlet';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Outlet for dynamic purposes.
   **/
  public static factory(data: OutletInterface): Outlet {
    return new Outlet(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Outlet',
      plural: 'Outlets',
      path: 'outlets',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        key: {
          name: 'key',
          type: 'string',
        },
        description: {
          name: 'description',
          type: 'string',
        },
        pageId: {
          name: 'pageId',
          type: 'string',
        },
        outletId: {
          name: 'outletId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
      },
      relations: {
        page: {
          name: 'page',
          type: 'Page',
          model: 'Page',
          relationType: 'belongsTo',
          keyFrom: 'pageId',
          keyTo: 'id',
        },
      },
    };
  }
}
