export enum CrudViewFormItemType {
  Alert = 'alert',
  AssetPicker = 'asset-picker',
  BorderSettings = 'border-settings',
  Button = 'button',
  CheckBox = 'checkbox',
  CheckboxList = 'checkbox-list',
  CheckboxTable = 'checkbox-table',
  Code = 'code',
  CodeMirror = 'codemirror',
  ColorPicker = 'color-picker',
  Date = 'date',
  DatePicker = 'date-picker',
  DateTime = 'datetime',
  DateTimePicker = 'datetime-picker',
  DimensionSelect = 'dimension-select',
  DocumentUpload = 'document-upload',
  DynamicArrayForm = 'dynamic-array-form',
  EditFormConfigInput = 'edit-form-config-input',
  FormBuilder = 'form-builder',
  GridBuilder = 'grid-builder',
  HTMLEditor = 'html-editor',
  IconPicker = 'icon-picker',
  IconPickerTwo = 'icon-picker-two',
  IconPickerTwoWrapper = 'icon-picker-two-wrapper',
  Image = 'image',
  InlineRowForm = 'inline-row-form',
  InputGroup = 'input-group',
  InputTemplate = 'input-template',
  InputValidatorInfo = 'input-validator-info',
  JsonArray = 'json-array',
  JsonInput = 'json-input',
  Label = 'label',
  LabelFunction = 'label-function',
  List = 'list',
  ListGridConfigInput = 'list-grid-config-input',
  ListGridDataInput = 'list-grid-data-input',
  MarkDown = 'markdown',
  MonacoCodeEditor = 'monaco-code-editor',
  Number = 'number',
  Object = 'object',
  ObjectButton = 'object-button',
  PhoneNumber = 'phone-number-input',
  Previewer = 'previewer',
  Radio = 'radio',
  RadioGroup = 'radio-group',
  Rate = 'rate',
  RichTextEditor = 'rich-text-editor',
  RowForm = 'row-form',
  RowSelect = 'row-select',
  Select = 'select',
  SelectCard = 'select-card',
  ShadowSettings = 'shadow-settings',
  Slider = 'slider',
  Spacing = 'spacing',
  StringArray = 'string-array',
  Switch = 'switch',
  Table = 'table',
  TagBox = 'tag-box',
  TagInput = 'tag-input',
  TextArea = 'textarea',
  TextBox = 'textbox',
  TimePicker = 'time-picker',
  TreeSelect = 'tree-select'
}
