import { createAction, props } from '@ngrx/store';
import { ChatMessage, ChatSession } from '@rappider/rappider-sdk';
import { UnsynchronizedMessage } from '../utils/unsynchronized-message.interface';

export enum ActionTypes {
  GetChatSessionByActiveProject = '[AIAssistant] GetChatSessionByActiveProject',
  GetChatSessionByActiveProjectSuccessful = '[AIAssistant] GetChatSessionByActiveProjectSuccessful',
  GetChatMessagesByTimestamp = '[AIAssistant] GetChatMessagesByTimestamp',
  GetChatMessagesByTimestampSuccessful = '[AIAssistant] GetChatMessagesByTimestampSuccessful',
  CreateChatMessage = '[AIAssistant] CreateChatMessage',
  CreateChatMessageSuccessful = '[AIAssistant] CreateChatMessageSuccessful',
  CreateChatMessageFailure = '[AIAssistant] CreateChatMessageFailure',
  Error = '[AIAssistant] Error'
}

export const GetChatSessionByActiveProject = createAction(ActionTypes.GetChatSessionByActiveProject);

export const GetChatSessionByActiveProjectSuccessful = createAction(
  ActionTypes.GetChatSessionByActiveProjectSuccessful,
  props<{ payload: { chatSession: ChatSession } }>()
);

export const GetChatMessagesByTimestamp = createAction(
  ActionTypes.GetChatMessagesByTimestamp,
  props<{ pollingKey: string }>()
);

export const GetChatMessagesByTimestampSuccessful = createAction(
  ActionTypes.GetChatMessagesByTimestampSuccessful,
  props<{ payload: { chatSession: ChatSession } }>()
);

export const CreateChatMessage = createAction(
  ActionTypes.CreateChatMessage,
  props<{ payload: { message: UnsynchronizedMessage } }>()
);

export const CreateChatMessageSuccessful = createAction(
  ActionTypes.CreateChatMessageSuccessful,
  props<{ payload: { message: UnsynchronizedMessage } }>()
);

export const CreateChatMessageFailure = createAction(
  ActionTypes.CreateChatMessageFailure,
  props<{ payload: { messageKey: string } }>()
);

export const Error = createAction(
  ActionTypes.Error,
  props<{ payload: { error: { error: any; key: string } } }>()
);
