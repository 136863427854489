export const SCRIPT_LOCATION_OPTIONS = [
  {
    key: 'Head',
    value: 'head'
  },
  {
    key: 'Body Start',
    value: 'body-start'
  },
  {
    key: 'Body End',
    value: 'body-end'
  }
];
