/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { NewUiWorkflowStepFunctionPublishedEventOnFailure } from '../models/new-ui-workflow-step-function-published-event-on-failure';
import { UiWorkflowStepFunctionPublishedEventOnFailure } from '../models/ui-workflow-step-function-published-event-on-failure';
import { UiWorkflowStepFunctionPublishedEventOnFailurePartial } from '../models/ui-workflow-step-function-published-event-on-failure-partial';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class UiWorkflowStepFunctionPublishedEventOnFailureControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation uiWorkflowStepFunctionPublishedEventOnFailureControllerCount
   */
  static readonly UiWorkflowStepFunctionPublishedEventOnFailureControllerCountPath = '/ui-workflow-step-function-published-event-on-failures/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params?: {
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, UiWorkflowStepFunctionPublishedEventOnFailureControllerService.UiWorkflowStepFunctionPublishedEventOnFailureControllerCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params?: {
    where?: any;
  }): Observable<LoopbackCount> {

    return this.count$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation uiWorkflowStepFunctionPublishedEventOnFailureControllerFindById
   */
  static readonly UiWorkflowStepFunctionPublishedEventOnFailureControllerFindByIdPath = '/ui-workflow-step-function-published-event-on-failures/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<UiWorkflowStepFunctionPublishedEventOnFailure>> {

    const rb = new RequestBuilder(this.rootUrl, UiWorkflowStepFunctionPublishedEventOnFailureControllerService.UiWorkflowStepFunctionPublishedEventOnFailureControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UiWorkflowStepFunctionPublishedEventOnFailure>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
    filter?: any;
  }): Observable<UiWorkflowStepFunctionPublishedEventOnFailure> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<UiWorkflowStepFunctionPublishedEventOnFailure>) => r.body as UiWorkflowStepFunctionPublishedEventOnFailure)
    );
  }

  /**
   * Path part for operation uiWorkflowStepFunctionPublishedEventOnFailureControllerDeleteById
   */
  static readonly UiWorkflowStepFunctionPublishedEventOnFailureControllerDeleteByIdPath = '/ui-workflow-step-function-published-event-on-failures/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, UiWorkflowStepFunctionPublishedEventOnFailureControllerService.UiWorkflowStepFunctionPublishedEventOnFailureControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: string;
  }): Observable<any> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation uiWorkflowStepFunctionPublishedEventOnFailureControllerUpdateById
   */
  static readonly UiWorkflowStepFunctionPublishedEventOnFailureControllerUpdateByIdPath = '/ui-workflow-step-function-published-event-on-failures/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    body?: UiWorkflowStepFunctionPublishedEventOnFailurePartial
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, UiWorkflowStepFunctionPublishedEventOnFailureControllerService.UiWorkflowStepFunctionPublishedEventOnFailureControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    body?: UiWorkflowStepFunctionPublishedEventOnFailurePartial
  }): Observable<any> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation uiWorkflowStepFunctionPublishedEventOnFailureControllerFind
   */
  static readonly UiWorkflowStepFunctionPublishedEventOnFailureControllerFindPath = '/ui-workflow-step-function-published-event-on-failures';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<UiWorkflowStepFunctionPublishedEventOnFailure>>> {

    const rb = new RequestBuilder(this.rootUrl, UiWorkflowStepFunctionPublishedEventOnFailureControllerService.UiWorkflowStepFunctionPublishedEventOnFailureControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UiWorkflowStepFunctionPublishedEventOnFailure>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<UiWorkflowStepFunctionPublishedEventOnFailure>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UiWorkflowStepFunctionPublishedEventOnFailure>>) => r.body as Array<UiWorkflowStepFunctionPublishedEventOnFailure>)
    );
  }

  /**
   * Path part for operation uiWorkflowStepFunctionPublishedEventOnFailureControllerCreate
   */
  static readonly UiWorkflowStepFunctionPublishedEventOnFailureControllerCreatePath = '/ui-workflow-step-function-published-event-on-failures';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    body?: NewUiWorkflowStepFunctionPublishedEventOnFailure
  }): Observable<StrictHttpResponse<UiWorkflowStepFunctionPublishedEventOnFailure>> {

    const rb = new RequestBuilder(this.rootUrl, UiWorkflowStepFunctionPublishedEventOnFailureControllerService.UiWorkflowStepFunctionPublishedEventOnFailureControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UiWorkflowStepFunctionPublishedEventOnFailure>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    body?: NewUiWorkflowStepFunctionPublishedEventOnFailure
  }): Observable<UiWorkflowStepFunctionPublishedEventOnFailure> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<UiWorkflowStepFunctionPublishedEventOnFailure>) => r.body as UiWorkflowStepFunctionPublishedEventOnFailure)
    );
  }

}
