/* eslint-disable no-shadow */
import { Action } from '@ngrx/store';
import { UIDataEventInterface } from '@rappider/api-sdk';
import { DataSchemaField, DataSchemaWithRelations, NewUiDataEvent, UiDataEvent, UiDataEventWithRelations } from '@rappider/rappider-sdk';


export enum ActionTypes {
  GetUIDataEvents = '[UIDataEvent] GetDataEvents',
  GetUIDataEventsAccordingToId = '[UIDataEvent] GetUIDataEventsAccordingToId',
  GetUIDataEventsAccordingToIdFailure = '[UIDataEvent] GetUIDataEventsAccordingToIdFailure',
  GetUIDataEventsAccordingToUIDataStoreId = '[UIDataEvent] GetUIDataEventsAccordingToUIDataStoreId',
  GetUIDataEventsAccordingToUIDataStoreIdFailure = '[UIDataEvent] GetUIDataEventsAccordingToUIDataStoreIdFailure',
  SetUIDataEvents = '[UIDataEvent] SetDataEvents',
  CreateUIDataEvent = '[UIDataEvent] CreateDataEvent',
  CreateUIDataEventSuccessful = '[UIDataEvent] CreateUIDataEventSuccessful',
  BulkCreateUIDataEventSuccessful = '[UIDataEvent] BulkCreateUIDataEventSuccessful',
  UpdateUIDataEvent = '[UIDataEvent] UpdateDataEvent',
  UpdateUIDataEventSuccessful = '[UIDataEvent] UpdateUIDataEventSuccessful',
  DeleteUIDataEvent = '[UIDataEvent] DeleteDataEvent',
  DeleteUIDataEventSuccessful = '[UIDataEvent] DeleteUIDataEventSuccessful',
  GetTemplateUIDataEventsSuccessful = '[UIDataEvent] GetTemplateUIDataEventsSuccessful',
  GetTemplateUIDataEventsFailure = '[UIDataEvent] GetTemplateUIDataEventsFailure',
  ErrorAction = '[UIDataEvent] ErrorAction',
  UpdateDataSchemaFieldsOfUIDataEvent = '[UIDataStore] UpdateDataSchemaFieldsOfUIDataEvent',
  CreateDataSchemaFieldsOfUIDataEvent = '[UIDataStore] CreateDataSchemaFieldsOfUIDataEvent',
  DeleteDataSchemaFieldsOfUIDataEvent = '[UIDataStore] DeleteDataSchemaFieldsOfUIDataEvent',
  GetUIDataEventPayload = '[UIDataStore] GetUIDataEventPayload',
  GetUIDataEventPayloadSuccessful = '[UIDataStore] GetUIDataEventPayloadSuccessful',
  GetUIDataEventPayloadFailure = '[UIDataStore] GetUIDataEventPayloadFailure',
}


export class UpdateDataSchemaFieldsOfUIDataEvent implements Action {
  readonly type = ActionTypes.UpdateDataSchemaFieldsOfUIDataEvent;
  constructor(public payload: { updatedDataSchemaFields: DataSchemaField[]; parentDataSchemaId: string }) { }
}

export class CreateDataSchemaFieldsOfUIDataEvent implements Action {
  readonly type = ActionTypes.CreateDataSchemaFieldsOfUIDataEvent;
  constructor(public payload: { createdDataSchemaFields: DataSchemaField[]; parentDataSchemaId: string }) { }
}

export class DeleteDataSchemaFieldsOfUIDataEvent implements Action {
  readonly type = ActionTypes.DeleteDataSchemaFieldsOfUIDataEvent;
  constructor(public payload: { deletedDataSchemaFieldIds: string[]; parentDataSchemaId: string }) { }
}

export class GetUIDataEvents implements Action {
  readonly type = ActionTypes.GetUIDataEvents;
}

export class GetUIDataEventsAccordingToIdFailure implements Action {
  readonly type = ActionTypes.GetUIDataEventsAccordingToIdFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class GetUIDataEventsAccordingToId implements Action {
  readonly type = ActionTypes.GetUIDataEventsAccordingToId;
  constructor(public payload: { uiDataEventsIds: string[] }) { }
}

export class GetUIDataEventsAccordingToUIDataStoreId implements Action {
  readonly type = ActionTypes.GetUIDataEventsAccordingToUIDataStoreId;
  constructor(public payload: { uiDataStoreId: string }) { }
}

export class GetUIDataEventsAccordingToUIDataStoreIdFailure implements Action {
  readonly type = ActionTypes.GetUIDataEventsAccordingToUIDataStoreIdFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class SetUIDataEvents implements Action {
  readonly type = ActionTypes.SetUIDataEvents;
  constructor(public payload: { uiDataEvents: UiDataEventWithRelations[] }) { }
}

export class CreateUIDataEvent implements Action {
  readonly type = ActionTypes.CreateUIDataEvent;
  constructor(public payload: { uiDataStoreId: string; uiDataEvent: UiDataEventWithRelations; navigateAfterCreate: boolean; createSuccessAndFailureEvents: boolean }) { }
}

export class CreateUIDataEventSuccessful implements Action {
  readonly type = ActionTypes.CreateUIDataEventSuccessful;
  constructor(public payload: { uiDataEvent: UiDataEventWithRelations }) { }
}

export class BulkCreateUIDataEventSuccessful implements Action {
  readonly type = ActionTypes.BulkCreateUIDataEventSuccessful;
  constructor(public payload: { uiDataEvents: UiDataEventWithRelations[] }) { }
}

export class UpdateUIDataEvent implements Action {
  readonly type = ActionTypes.UpdateUIDataEvent;
  constructor(public payload: { uiDataEventId: string; uiDataEvent: UiDataEventWithRelations; navigateAfterUpdate: boolean }) { }
}

export class UpdateUIDataEventSuccessful implements Action {
  readonly type = ActionTypes.UpdateUIDataEventSuccessful;
  constructor(public payload: { uiDataEvent: UiDataEventWithRelations }) { }
}

export class DeleteUIDataEvent implements Action {
  readonly type = ActionTypes.DeleteUIDataEvent;
  constructor(public payload: { uiDataEvent: UiDataEvent }) { }
}

export class DeleteUIDataEventSuccessful implements Action {
  readonly type = ActionTypes.DeleteUIDataEventSuccessful;
  constructor(public payload: { deletedUIDataEventId: string }) { }
}

export class ErrorAction implements Action {
  readonly type = ActionTypes.ErrorAction;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class GetTemplateUIDataEventsSuccessful implements Action {
  readonly type = ActionTypes.GetTemplateUIDataEventsSuccessful;
  constructor(public payload: { uiDataEvents: UiDataEventWithRelations[] }) { }
}
export class GetTemplateUIDataEventsFailure implements Action {
  readonly type = ActionTypes.GetTemplateUIDataEventsFailure;
  constructor(public payload: { timestamp?: number; key?: string; error?: unknown }) { }
}

export class GetUIDataEventPayload implements Action {
  readonly type = ActionTypes.GetUIDataEventPayload;
  constructor(public payload: { uiDataEvent: UiDataEventWithRelations }) { }
}
export class GetUIDataEventPayloadSuccessful implements Action {
  readonly type = ActionTypes.GetUIDataEventPayloadSuccessful;

}
export class GetUIDataEventPayloadFailure implements Action {
  readonly type = ActionTypes.GetUIDataEventPayloadFailure;
  constructor(public payload: { timestamp?: number; key?: string; error?: unknown }) { }
}

export type Actions =
  GetUIDataEvents
  | GetUIDataEventsAccordingToId
  | GetUIDataEventsAccordingToIdFailure
  | GetUIDataEventsAccordingToUIDataStoreId
  | GetUIDataEventsAccordingToUIDataStoreIdFailure
  | SetUIDataEvents
  | CreateUIDataEvent
  | UpdateUIDataEvent
  | DeleteUIDataEvent
  | ErrorAction
  | DeleteUIDataEventSuccessful
  | CreateUIDataEventSuccessful
  | BulkCreateUIDataEventSuccessful
  | UpdateUIDataEventSuccessful
  | GetTemplateUIDataEventsSuccessful
  | GetTemplateUIDataEventsFailure
  | CreateDataSchemaFieldsOfUIDataEvent
  | UpdateDataSchemaFieldsOfUIDataEvent
  | DeleteDataSchemaFieldsOfUIDataEvent
  | GetUIDataEventPayload
  | GetUIDataEventPayloadSuccessful
  | GetUIDataEventPayloadFailure
  ;
