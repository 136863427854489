import { ProjectWithRelations } from '@rappider/rappider-sdk';

export const BlankTemplate: ProjectWithRelations = {
  id: 'blank',
  name: 'Blank',
  description: 'Blank template',
  thumbnails: [
    {
      url: 'assets/img/page-templates/profile-template1-thumbnail.png',
      title: 'Welcome to Our School Community',
      description: 'Discover our vibrant school community where students and teachers collaborate to create a dynamic and inclusive learning environment.'
    },
    {
      url: 'assets/img/page-templates/profile-template3-thumbnail.png',
      title: 'About Our School',
      description: 'Learn about our schools mission, values, history, and the dedicated staff who make our school a place of excellence.'
    },
    {
      url: 'assets/img/page-templates/achievement-page-thumbnail.png',
      title: 'Academic Programs and Curriculum',
      description: 'Explore our comprehensive academic programs and curriculum designed to foster intellectual growth and prepare students for future success.'
    },
    {
      url: 'assets/img/page-templates/apply-for-job-thumbnail.png',
      title: 'Meet Our Teachers',
      description: 'Get to know our talented and passionate teachers who are committed to providing high-quality education and personalized support for every student.'
    },
    {
      url: 'assets/img/page-templates/Authentication-Component-1-thumbnail.png',
      title: 'Student Life and Activities',
      description: 'Discover the diverse range of extracurricular activities, clubs, and events that enrich student life and promote personal development.'
    },
    {
      url: 'assets/img/page-templates/Authentication-Component-2-thumbnail.png',
      title: 'Admissions Process and Requirements',
      description: 'Find out about our admissions process, requirements, and how to apply to join our school community.'
    },
    {
      url: 'assets/img/page-templates/Authentication-Component-3-thumbnail.png',
      title: 'Resources for Parents',
      description: 'Access valuable resources, guides, and information to support parents in their role in their childrens education and school experience.'
    },
    {
      url: 'assets/img/page-templates/cc1-thumbnail.png',
      title: 'Get in Touch with Us',
      description: 'Have questions or need more information? Contact us through our contact page for assistance and inquiries.'
    }
  ],
  appType: '',
  backendFramework: '',
  frontendFramework: '',
  key: '',
  roleId: '',
  structureType: '',
  visibility: ''
};
