import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActionResponse, BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { PAGE_DEFINITIONS, PATH_DEFINITIONS, defaultToolbarTitleHeadingSize } from '@rappider/shared/definitions';
import { Subscription } from 'rxjs';
import { DeleteAdminProject, GetAdminProjects, GetPaginationBySearchCount, UpdateProjectByAdmin } from '../../state/admin-projects/admin-projects.actions';
import { ALL_PROJECTS_CONFIG } from './config/all-projects-config';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { getProjectsWithPages } from '../../state/state-selectors/get-projects-with-pages-selector';
import { ProjectsWithFilteredPages } from '../../utils/projects-with-pages.interface';
import { ChangeActiveProject } from 'libs/authentication/src/lib/state/authentication.actions';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { defaultPageIndex, defaultPageSize } from '../../utils/pagination-variables';
import { ProjectVisibility } from 'libs/shared/src/lib/definitions/project-visibility.enum';
import { AllProjectsItemActions } from './config/all-projects-item-actions.enum';
import { ADMIN_PROJECTS_FORM_CONFIG } from './config/admin-project-form-config';
import { ProjectPartial, ProjectWithRelations } from '@rappider/rappider-sdk';
@Component({
  selector: 'rappider-all-projects',
  templateUrl: './all-projects.component.html',
  styleUrls: ['./all-projects.component.scss']
})
export class AllProjectsComponent implements OnInit, OnDestroy {

  ALL_PROJECTS_CONFIG = ALL_PROJECTS_CONFIG;
  ADMIN_PROJECTS_FORM_CONFIG = ADMIN_PROJECTS_FORM_CONFIG;

  /* main title for title toolbar */
  mainTitle: HeadingComponentConfig = {
    content: 'Admin Dashboard',
    type: defaultToolbarTitleHeadingSize
  };

  title: BreadcrumbOption[] = [
    {
      label: 'Admin Dashboard',
      redirectUrl: PAGE_DEFINITIONS.ADMIN_DASHBOARD.URL
    },
    {
      label: 'Projects'
    }
  ];

  /* data */
  allProjects: ProjectsWithFilteredPages[];
  isLoading: boolean;
  activeProjectId: string;
  projectData: ProjectWithRelations;
  updatedFormBody: ProjectPartial;
  isFormValid: boolean;
  isFormSubmit: boolean;

  /* subscriptions */
  subscriptions: Subscription[] = [];
  timer;
  searchText: string;
  pageIndex: number;
  modalVisibility: boolean;
  modalTitle: string;

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.store.dispatch(GetPaginationBySearchCount({ pageIndex: defaultPageIndex, pageSize: defaultPageSize, searchText: '' }));
    this.subscribeToData();
    this.pageIndex = 1;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProjectId(),
      this.subscribeToPagination(),
      this.subscribeToAdminProjects(),
      this.subscribeToLoading()
    ];
  }

  subscribeToActiveProjectId() {
    return this.store.select(state => state.activeProject.data?.id).subscribe((activeProjectId: string) => {
      this.activeProjectId = activeProjectId;
    });
  }

  subscribeToAdminProjects() {
    return this.store.select(<any>getProjectsWithPages).subscribe((adminProjectsWithPages: ProjectsWithFilteredPages[]) => {
      if (adminProjectsWithPages?.length) {
        this.allProjects = adminProjectsWithPages;
        this.setGoToProjectButtonVisibilityFunction();
      }
    });
  }

  subscribeToLoading() {
    return this.store.select(state => state.adminProjects?.isLoading).subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
  }

  subscribeToPagination() {
    return this.store.select(state => state.adminProjects?.projectCount).subscribe((count: number) => {
      if (count != null) {
        this.ALL_PROJECTS_CONFIG.nzTotal = count;
        this.ALL_PROJECTS_CONFIG = { ...this.ALL_PROJECTS_CONFIG };
      }
    });
  }

  onColumnActionClick(actionResponse: ActionResponse) {
    const project = <ProjectsWithFilteredPages>actionResponse.data;
    if (actionResponse.action.name === AllProjectsItemActions.Delete) {
      this.store.dispatch(DeleteAdminProject({ projectId: project.id }));
    } else if (actionResponse.action.name === AllProjectsItemActions.Edit) {
      this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.ADMIN_DASHBOARD.PROJECT_EDIT}/${project.id}` }));
    } else if (actionResponse.action.name === AllProjectsItemActions.Dashboard) {
      if (actionResponse.data.id !== this.activeProjectId) {
        this.store.dispatch(new ChangeActiveProject({ project, navigateToProjectDetail: true, isAdmin: true }));
      } else {
        this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProjectId}` }));
      }
    } else if (actionResponse.action.name === AllProjectsItemActions.ChangeSettings) {
      this.projectData = actionResponse.data;
      this.modalTitle = '<b>' + actionResponse.data.name + '</b>' + ' Settings';
      this.modalVisibility = true;
    }
  }

  paginationChange(paginationData: NzTableQueryParams) {
    this.pageIndex = paginationData.pageIndex;
    this.store.dispatch(GetAdminProjects({ pageIndex: paginationData.pageIndex, pageSize: paginationData.pageSize, searchText: this.searchText }));
  }

  searchTextChange(searchText: string) {
    if (searchText || searchText === '') {
      this.searchText = searchText;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.store.dispatch(GetPaginationBySearchCount({ pageIndex: 1, pageSize: 10, searchText }));
        this.pageIndex = 1;
      }, 1000);
    }
  }

  onColumnItemClick(project) {
    this.store.dispatch(new ChangeActiveProject({ project, navigateToProjectDetail: false, isAdmin: true }));
    this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.ADMIN_DASHBOARD.PROJECT_DETAIL}/${project.id}` }));
  }

  setGoToProjectButtonVisibilityFunction() {
    this.ALL_PROJECTS_CONFIG.handleVisibilityFn = (data, item, action) => {
      if (action.name === AllProjectsItemActions.Edit && item.isUpdatable) {
        return true;
      } else if (action.name === AllProjectsItemActions.Delete && item.isDeletable) {
        return true;
      } else if (action.name === AllProjectsItemActions.Dashboard) {
        return true;
      } else if (action.name === AllProjectsItemActions.ChangeSettings) {
        return true;
      }
    };
    this.ALL_PROJECTS_CONFIG = { ...this.ALL_PROJECTS_CONFIG };
  }

  formSubmit() {
    if (this.isFormValid) {
      this.modalVisibility = false;
      this.store.dispatch(UpdateProjectByAdmin({ publicProject: { id: this.projectData.id, body: this.updatedFormBody } }));
    }
  }

  formCancel() {
    this.modalVisibility = false;
  }

  onFormValueChange(formBody) {
    this.updatedFormBody = formBody;
  }

  onValidityChange(isValid) {
    this.isFormValid = isValid;
  }
}
