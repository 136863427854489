import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ProjectInterface } from '@rappider/api-sdk';
import { BreadcrumbOption, HeadingComponentConfig, HeadingType } from '@rappider/rappider-components/utils';
import {
  ProjectModel,
  ProjectModelControllerService,
  ProjectModelEndpoint,
  ProjectModelEndpointControllerService,
  ProjectModelEndpointUpdateDto
} from '@rappider/rappider-sdk';
import { JsonValidationService, NotificationService } from '@rappider/services';
import { ENDPOINT_DETAIL_CONFIG, MOCK_RESPONSE_FORM_ITEM } from '@rappider/shared/configs';
import { defaultToolbarTitleHeadingSize, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rappider-endpoint-detail',
  templateUrl: './endpoint-detail.component.html',
  styleUrls: ['./endpoint-detail.component.scss']
})
export class EndpointDetailComponent implements OnInit, OnDestroy {
  /* endpoint detail config */
  ENDPOINT_DETAIL_CONFIG = ENDPOINT_DETAIL_CONFIG;
  /* mock response form item */
  MOCK_RESPONSE_FORM_ITEM = MOCK_RESPONSE_FORM_ITEM;
  /* project model endpoint */
  endpoint: ProjectModelEndpoint;
  /* endpoint id */
  endpointId: string;
  /* main title */
  mainTitle: HeadingComponentConfig;
  /* page title */
  title: BreadcrumbOption[];
  /* subscriptions */
  subscriptions: Subscription[];
  /* active project */
  activeProject: ProjectInterface;
  /* project model */
  projectModel: ProjectModel;
  /* project model id */
  projectModelId: string;
  /* heading type */
  PropertyHeadingType = HeadingType;
  displayToolbar = false;
  displayToolbarBackButton = false;
  constructor(
    private projectModelEndpointApi: ProjectModelEndpointControllerService,
    private activatedRoute: ActivatedRoute,
    private projectModelApi: ProjectModelControllerService,
    private store: Store<any>,
    private notificationService: NotificationService,
    private jsonValidationService: JsonValidationService
  ) { }

  ngOnInit(): void {
    this.getEndpointIdFromUrl();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToProjectModelEndpoints()
    ];
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectInterface) => {
      this.activeProject = activeProject;
    });
  }

  subscribeToProjectModelEndpoints() {
    return this.store.select(state => state.projectModelEndpoint?.data).subscribe((endpoints: ProjectModelEndpoint[]) => {
      const endpoint = endpoints?.find(endpoint => endpoint.id === this.endpointId);

      if (endpoint) {
        this.endpoint = {
          ...endpoint,
          mockResponse: JSON.stringify(endpoint?.mockResponse)
        };
        this.projectModelId = endpoint.modelId;
        this.getProjectModelByProjectModelId();
        this.setMockResponseFormItem(endpoint.isMocked);
      }
    });
  }

  getEndpointIdFromUrl() {
    this.endpointId = this.activatedRoute.snapshot.params['id'];
  }

  /**
   * set page title
   *
   * @memberof EndpointDetailComponent
   */
  setTitle() {
    this.mainTitle = {
      content: 'PROJECT_MODULE.ENDPOINT_DETAIL.ENDPOINT_DETAILS',
      type: defaultToolbarTitleHeadingSize
    };
    this.title = [
      {
        label: this.activeProject.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
      },
      {
        label: this.projectModel.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_ENDPOINT_LIST}`
      },
      {
        label: this.endpoint.name
      }
    ];
  }

  onProjectModelEndpointDetailFieldValueChange(data: ProjectModelEndpoint) {
    this.setMockResponseFormItem(data.isMocked);
  }

  /**
   * sets mock response form item on config depending on isMocked property
   *
   * @param {boolean} isMocked
   * @memberof EndpointDetailComponent
   */
  setMockResponseFormItem(isMocked: boolean) {
    if (isMocked) {
      this.MOCK_RESPONSE_FORM_ITEM.visible = true;
    } else if (isMocked === false) {
      this.MOCK_RESPONSE_FORM_ITEM.visible = false;
    }

    this.ENDPOINT_DETAIL_CONFIG = { ...this.ENDPOINT_DETAIL_CONFIG };
  }

  /**
   * gets project models by project model id
   *
   * @memberof EndpointDetailComponent
   */
  getProjectModelByProjectModelId() {
    const params = { id: this.projectModelId };

    this.projectModelApi.findById(params).subscribe((projectModel: ProjectModel) => {
      this.projectModel = projectModel;
      this.setTitle();
    });
  }

  /**
   * navigates to project model data field list page
   *
   * @memberof EndpointDetailComponent
   */
  navigateToListPage() {
    this.store.dispatch(new Navigate(
      { url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_DATA_FIELD_LIST}/${this.projectModelId}` }
    ));
  }

  onProjectModelEndpointUpdate(endpoint: Partial<ProjectModelEndpoint>) {
    if (endpoint.isMocked) {
      /* validate mock response */
      const mockResponse = this.jsonValidationService.validateStringifiedJson(endpoint.mockResponse);
      if (mockResponse.isJsonValid) {
        endpoint.mockResponse = mockResponse.dataAsJson;
        /* update endpoint */
        this.projectModelEndpointUpdate(endpoint);
      } else {
        this.notificationService.createNotification(
          'error',
          endpoint.name,
          'PROJECT_MODULE.CUSTOM_ENDPOINT_CREATE_OR_EDIT_COMPONENT.WRONG_JSON_FORMAT_IN_MOCK_RESPONSE'
        );
      }
    } else {
      endpoint.isMocked = false;
      endpoint.mockResponse = null;
    }

  }

  /**
   * sends edit request
   *
   * @param {ProjectModelEndpointPartial} endpoint
   * @memberof EndpointDetailComponent
   */
  projectModelEndpointUpdate(endpoint: Partial<ProjectModelEndpoint>) {
    const params = {
      id: this.endpointId,
      body: <ProjectModelEndpointUpdateDto>{
        endpoint
      }
    };

    this.projectModelEndpointApi.updateById(params).subscribe(
      () => {
        this.notificationService.createNotification(
          'success',
          this.endpoint.name,
          'SHARED.SUCCESSFULLY_UPDATED'
        );
        this.navigateToListPage();
      }, () => {
        this.notificationService.createNotification(
          'error',
          this.endpoint.name,
          'SHARED.COULDNT_UPDATED'
        );
      }
    );
  }

}
