import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RappiderMonacoEditorModule, RappiderSelectModule, RappiderStringArrayModule, RappiderSwitchModule, RappiderTextboxModule } from '@rappider/rappider-components';
import { NewProjectModelFieldFormComponent } from './new-project-model-field-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';

@NgModule({
  declarations: [
    NewProjectModelFieldFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NzFormModule,
    ReactiveFormsModule,
    RappiderTextboxModule,
    NzCollapseModule,
    RappiderStringArrayModule,
    RappiderSwitchModule,
    RappiderMonacoEditorModule,
    TranslateModule,
    NzButtonModule,
    RappiderSelectModule
  ],
  exports: [
    NewProjectModelFieldFormComponent
  ]
})
export class NewProjectModelFieldFormModule { }
