<div class="info-bar">
  <div class="title"
       joyrideStep="sortFirstStep"
       [title]="'DTSortInfoBarTitle' | translate"
       [text]="'DTSortInfoBarContent' | translate"
       (done)="onDone()">
    <rappider-heading type="h4"
                      [content]="title"></rappider-heading>
  </div>
  <div class="operation-field-button">
    <rappider-button [text]="changeOperationButton.text"
                     [type]="changeOperationButton.type"
                     [icon]="changeOperationButton.icon"
                     [hidden]="isButtonHidden"
                     (click)="onChangeOperationButtonClick()"></rappider-button>
  </div>
</div>

<rappider-modal [(visible)]="isChangeOperationModalFieldVisible"
                title="Change Operation Field"
                [fullScreen]="true"
                (cancelClick)="closeChangeOperationFieldModal()"
                (okClick)="changeOperationField()">
  <rappider-select-operation-field *ngIf="value"
                                   [ngModel]="value"
                                   [tree]="tree"
                                   (ngModelChange)="onOperationFieldChange($event)">
  </rappider-select-operation-field>
</rappider-modal>