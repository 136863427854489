import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { UIDataStoreInterface } from '@rappider/api-sdk';
import { catchError, delay, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { BrowserStorageManagementService, NotificationService } from '@rappider/services';
import * as UIDataStoreActions from './ui-data-store.actions';
import * as ProjectModelActions from '../project-model-state/project-model.actions';
import * as UIDataEventActions from '../ui-data-event-state/ui-data-event.actions';
import * as UIWorkflowStepFunctionActions from '../ui-step-functions/ui-step-function.actions';
import * as UIDataUpdateFunctionActions from '../ui-data-update-functions/ui-data-update-function.actions';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { of, throwError } from 'rxjs';
import { DataSchemaControllerService, UiDataStore, UiDataStoreControllerService, UiDataStoreWithRelations } from '@rappider/rappider-sdk';
import { environment } from '@environment';
import * as DataSchemaActions from '../data-schema/data-schema.actions';
@Injectable()
export class UIDataStoreEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private dataSchemaApi: DataSchemaControllerService,
    private notificationService: NotificationService,
    private uiDataStoreApi: UiDataStoreControllerService,
    private browserStorageManagementService: BrowserStorageManagementService
  ) { }


  getUIDataStores$ = createEffect(() => this.actions$.pipe(
    ofType<UIDataStoreActions.GetUIDataStores>(
      UIDataStoreActions.ActionTypes.GetUIDataStores
    ),
    withLatestFrom(
      this.store.select(state => state.activeProject.data?.id)
    ),
    mergeMap(([action, activeProjectIdByState]) => {
      const activeProjectId = activeProjectIdByState || this.browserStorageManagementService.getLocalStorageItem('activeProjectId');
      if (activeProjectId) {
        const filter = {
          where: {
            projectId: activeProjectId
          },
          order: ['name ASC'],
          include: [
            {
              relation: 'schema',
              scope: {
                include: [
                  {
                    relation: 'fields',
                    scope: { order: 'index ASC' }
                  }
                ]
              }
            },
            {
              relation: 'events'
            }
          ]
        };
        return this.uiDataStoreApi.find({ filter }).pipe(
          mergeMap((uiDataStores: UiDataStoreWithRelations[]) => [
            new UIDataStoreActions.SetUIDataStores({ uiDataStores: uiDataStores }),
            new UIDataStoreActions.SetLoadingState({ loading: false }),
            new UIWorkflowStepFunctionActions.GetUIWorkflowStepFunctions(),
          ])
        );
      } else {
        return [new UIDataStoreActions.GetUIDataStoresFailure({ error: 'Active project could not found', key: 'GetUIDataStoresFailure', timestamp: Date.now() })];
      }
    }), catchError(error => {
      this.notificationService.createNotification(
        'error',
        'UI Data Stores',
        'SHARED.COULDNT_LOAD'
      );
      return [
        new UIDataStoreActions.ErrorAction({
          error: error,
          key: 'GetUIDataStores',
          timestamp: Date.now()
        })
      ];
    })
  ));

  createUIDataStore$ = createEffect(() => this.actions$.pipe(
    ofType<UIDataStoreActions.CreateUIDataStore>(
      UIDataStoreActions.ActionTypes.CreateUIDataStore
    ),
    withLatestFrom(
      this.store.select(state => state.activeProject.data?.id),
    ),
    mergeMap(([action, activeProjectId]) => {
      const uiDataStoreBody = {
        ...action.payload.uiDataStore,
        projectId: activeProjectId
      };
      return this.uiDataStoreApi.create({ body: uiDataStoreBody }).pipe(
        mergeMap((uiDataStore: UiDataStore) => {
          this.notificationService.createNotification(
            'success',
            uiDataStore.name,
            'SHARED.SUCCESSFULLY_CREATED'
          );
          return [
            new Navigate({ url: PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_LIST }),
            new UIDataStoreActions.CreateUIDataStoreSuccessful({ uiDataStore: uiDataStore })
          ];
        }), catchError(error => {
          this.notificationService.createNotification(
            'error',
            uiDataStoreBody.name,
            error.error.error.message
          );
          return [new UIDataStoreActions.ErrorAction({ error: error, key: 'CreateUIDataStore', timestamp: Date.now() })];
        })
      );
    })
  ));

  getUIDataStoreById$ = createEffect(() => this.actions$.pipe(
    ofType<UIDataStoreActions.GetUIDataStore>(
      UIDataStoreActions.ActionTypes.GetUIDataStore
    ),
    mergeMap((action) => this.uiDataStoreApi.findById({ id: action.payload.uiDataStoreId }).pipe(
      mergeMap((uiDataStore: UiDataStoreWithRelations) => {
        const actionsToDispatch: Action[] = [
          new UIDataStoreActions.GetUIDataStoreSuccessful({ uiDataStore: uiDataStore })
        ];
        if (action.payload.navigateAfter) {
          actionsToDispatch.push(new Navigate({ url: PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_LIST }));
        }
        return actionsToDispatch;
      }), catchError(error => {
        this.notificationService.createNotification(
          'error',
          'An error occured.',
          'Could not get UI Data Store. Please try to refresh the page to be able to see the UIDataStores',
        );
        return [new UIDataStoreActions.ErrorAction({ error: error, key: 'CreateUIDataStore', timestamp: Date.now() })];
      })
    ))
  ));

  /**
   * This effect triggers when project model created with flag generateUIDataStore set to true
   *
   * @memberof UIDataStoreEffects
   */

  getUIDataStoreByProjectModelId$ = createEffect(() => this.actions$.pipe(
    ofType<UIDataStoreActions.GetUIDataStoreByProjectModelId>(
      UIDataStoreActions.ActionTypes.GetUIDataStoreByProjectModelId
    ),
    mergeMap((action) => {
      const filter = {
        where: { projectModelId: action.payload.projectModelId },
        limit: 1
      };
      const calculatedDelay = Math.min(environment.delayTimeForRecursiveApiCalls * action.payload.tryCount, environment.maxDelayTimeForRecursiveApiCalls);
      if (!action.payload.tryCount || action.payload.tryCount <= environment.maxTryCountForRecursiveAPICalls) {
        return this.uiDataStoreApi.find({ filter }).pipe(
          delay(calculatedDelay),
          mergeMap((uiDataStores: UiDataStoreWithRelations[]) => {
            if (uiDataStores.length && uiDataStores.some(uiDataStore => !uiDataStore?.isDeleted) && uiDataStores.some(uiDataStore => uiDataStore?.allDependentEntitiesCreated)) {
              return [
                new UIDataStoreActions.CreateUIDataStoresAccordingToIdSuccessful({ uiDataStore: uiDataStores[0] }),
                new UIDataEventActions.GetUIDataEventsAccordingToUIDataStoreId({ uiDataStoreId: uiDataStores[0].id }),
                new UIDataUpdateFunctionActions.GetUIDataUpdateFunctionsAccordingToUIDataStoreId({ uiDataStoreId: uiDataStores[0].id }),
                new UIWorkflowStepFunctionActions.GetUIWorkflowStepFunctionsAccordingToUIDataStoreId({ uiDataStoreId: uiDataStores[0].id }),
                new DataSchemaActions.GetDataSchemasByUIDataStoreId({ uiDataStoreId: uiDataStores[0].id, loadingModelId: action.payload.projectModelId })
              ];
            } else {
              return [
                new UIDataStoreActions.GetUIDataStoreByProjectModelId({ projectModelId: action.payload.projectModelId, tryCount: action.payload.tryCount + 1 })
              ];
            }
          }), catchError((error) => [
            new UIDataStoreActions.ErrorAction({ error: error, key: 'GetUIDataStoreByProjectModelId', timestamp: Date.now() }),
            new UIDataStoreActions.GetUIDataStoreFieldsLoadingByModelId({ projectModelId: action.payload.projectModelId })
          ]
          )
        );
      } else {
        return [
          new UIDataStoreActions.ErrorAction({ error: 'The maximum number of request attempts reached', key: 'GetUIDataStoreByProjectModelId', timestamp: Date.now() }),
          new UIDataStoreActions.GetUIDataStoreFieldsLoadingByModelId({ projectModelId: action.payload.projectModelId })
        ];
      }
    }), catchError(error => {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'Ui Data Store Couldnt Load'
      );
      return [
        new UIDataStoreActions.ErrorAction({
          error: error,
          key: 'GetUIDataStoreByProjectModelId',
          timestamp: Date.now()
        })
      ];
    })
  ));


  updatedUIDataStore$ = createEffect(() => this.actions$.pipe(
    ofType<UIDataStoreActions.UpdateUIDataStore>(
      UIDataStoreActions.ActionTypes.UpdateUIDataStore
    ),
    mergeMap((action) => {
      const updatedUIDataStore = action.payload.uiDataStore;
      const updatedUIDataStoreId = action.payload.id;

      return this.uiDataStoreApi.updateById({ id: updatedUIDataStoreId, body: updatedUIDataStore }).pipe(
        mergeMap((uiDataStore: UiDataStore) => {
          this.notificationService.createNotification(
            'success',
            uiDataStore.name,
            'SHARED.SUCCESSFULLY_UPDATED'
          );
          return [
            new Navigate({ url: PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_LIST }),
            new UIDataStoreActions.UpdateUIDataStoreSuccessful({ id: action.payload.id, uiDataStore: uiDataStore })
          ];
        }), catchError(error => {
          this.notificationService.createNotification(
            'error',
            'Error',
            error.error.error.message
          );
          return [
            new UIDataStoreActions.ErrorAction({ key: 'UpdateUIDataStore', error: error, timestamp: Date.now() })
          ];
        })
      );
    })
  ));


  deleteUIDataStore$ = createEffect(() => this.actions$.pipe(
    ofType<UIDataStoreActions.DeleteUIDataStore>(
      UIDataStoreActions.ActionTypes.DeleteUIDataStore
    ), withLatestFrom(
      this.store.select(state => state.uiDataStore?.data),
    ),
    mergeMap(([action, uiDataStores]) => {
      const uiDataStoreId = action.payload.uiDataStoreId;
      const deletedUIDataStore = uiDataStores.find((uiDataStore: UIDataStoreInterface) => uiDataStore.id === uiDataStoreId);

      return this.uiDataStoreApi.deleteById({ id: uiDataStoreId }).pipe(
        map(() => {
          this.notificationService.createNotification(
            'success',
            deletedUIDataStore.name,
            'SHARED.SUCCESSFULLY_DELETED'
          );
          return new UIDataStoreActions.GetUIDataStores();
        }, catchError(error => {
          this.notificationService.createNotification(
            'error',
            deletedUIDataStore.name,
            'SHARED.COULDNT_DELETED'
          );
          return [new UIDataStoreActions.ErrorAction({ error: error, key: 'DeleteUIDataStore', timestamp: Date.now() })];
        }))
      );
    })
  ));

}
