import { AlertTypes, HeadingType } from '@rappider/rappider-components/utils';

export const settingsFirstStepModalAlertConfig = {
  type: AlertTypes.Info,
  description: {
    text: 'Object for initial inputs data for ui-data-selector. e.g. if you want to initialize a rich-text-editor with { mode: simple } you can use this field to pass this object'
  },
  title: {
    type: HeadingType.H6,
    content: 'Data Schema Field Settings'
  },
  closeable: false,
  showIcon: true
};
