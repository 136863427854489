import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Project } from '@rappider/rappider-sdk';
import { defaultToolbarTitleHeadingSize, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Subscription } from 'rxjs';
import { CreateProjectTheme } from '../../../../../project/src/lib/states/project-theme-state/project-theme.actions';
import { PROJECT_THEME_CREATE_FORM_CONFIG } from './project-theme-create-form-config';
import { DEFAULT_THEME } from '@rappider/shared/data';
import { TitleToolbarComponentConfig } from '@rappider/rappider-components/utils';
import { NotificationService } from '@rappider/services';

@Component({
  selector: 'rappider-project-theme-create',
  templateUrl: './project-theme-create.component.html',
  styleUrls: ['./project-theme-create.component.scss']
})
export class ProjectThemeCreateComponent implements OnInit, OnDestroy {

  loading = false;
  PROJECT_THEME_CREATE_FORM_CONFIG = PROJECT_THEME_CREATE_FORM_CONFIG;
  titleToolbarConfig: TitleToolbarComponentConfig;
  activeProject: Project;
  submitButtonLoading = false;
  themeNames: string[] = [];

  subscriptions: Subscription[];

  constructor(
    private store: Store<any>,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach((subscription) => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToLoading(),
      this.subscribeToProjectThemes()
    ];
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe(activeProject => {
      this.activeProject = activeProject;
      if (this.activeProject) {
        this.setTitleToolbarConfig();
      }
    });
  }

  subscribeToProjectThemes() {
    return this.store.select(state => state.projectTheme?.data).subscribe(projectThemes => {
      if (projectThemes) {
        this.themeNames = projectThemes.map(theme => theme.name);
      }
    });
  }

  subscribeToLoading() {
    return this.store.select(state => state.projectTheme.loading).subscribe(loading => {
      this.submitButtonLoading = loading;
    });
  }

  setTitleToolbarConfig() {
    this.titleToolbarConfig = {
      mainTitle: {
        content: 'Create Project Theme',
        type: defaultToolbarTitleHeadingSize
      },
      options: [
        {
          label: this.activeProject.name,
          redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject.id}`
        },
        {
          label: 'Project Themes',
          redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_THEME_LIST_PATH}`
        },
        {
          label: 'Create Project Theme'
        }
      ]
    };
  }

  createProjectTheme(formData: any) {
    if (this.themeNames.includes(formData.name)) {
      this.notificationService.createNotification('error', 'Error', 'Name Already Exists in Themes');
    } else {
      this.store.dispatch(new CreateProjectTheme({
        projectTheme: {
          ...formData,
          theme: DEFAULT_THEME
        }
      }));
    }
  }

}
