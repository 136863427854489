import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CodeMirrorMode, FormLayout, IconComponentConfig, IconType } from '@rappider/rappider-components/utils';
import { PROGRAMMING_LANGUAGES } from '../../../utils/programming-languages';
import { PACKAGE_COLUMNS } from '../../../utils/package-columns.config';
import { ENVIRONMENT_VARIABLES_COLUMNS } from '../../../utils/environment-variables-columns.config';
import { NPMPackage } from 'libs/project/src/lib/utils/models/npm-package-model';
import { KeyValue } from '@angular/common';
import { ProjectPackageInterface } from '@rappider/api-sdk';
import { addProjectPackageButtonConfig, PASCAL_CASE_REGEX, projectPackageInfoAlertConfig } from '@rappider/shared/definitions';
import { MONACO_EDITOR_JAVASCRIPT_CONFIG, MONACO_EDITOR_JSON_CONFIG } from 'libs/shared/src/lib/configs/monaco-editor-language-and-mode-config';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { CustomFunctionDefinitionWithRelations, EnvironmentVariable } from '@rappider/rappider-sdk';
import { ENVIRONMENT_VARIABLES } from '../../../utils/environment-variables.config';
import { ACTIVE_ENVIRONMENT_VARIABLE } from '../../../utils/active-environment-variable.config';
import { CustomFunctionCreateEditFormItem } from '../../../utils/custom-function-crate-edit-form-item.enum';
import { Store } from '@ngrx/store';
import { NpmPackageSearchService } from 'libs/project/src/lib/utils/services/npm-package-search.service.ts/npm-package-search.service';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '@rappider/services';
import { cloneDeep } from 'lodash';
import { assignTypeAccordingToValue } from '../../../utils/assign-type-according-to-value.function';
import { isJsonValid } from '@rappider/shared/validators';
import { ThemeMode } from '@rappider/models';
import { MonacoEditorTheme } from 'libs/shared/src/lib/configs/monaco-editor-theme';
import { sortingByNumber } from '../../../utils/sorting-by-number';
import { debounceTime, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { updateCodeExampleOnLanguage } from '../../utils/custom-function-language-monaco-config';

@Component({
  selector: 'rappider-custom-function-edit-wrapper',
  templateUrl: './custom-function-edit-wrapper.component.html',
  styleUrls: ['./custom-function-edit-wrapper.component.scss']
})
export class CustomFunctionEditWrapperComponent implements OnInit, OnChanges {

  @Input() updateFormData: any;
  @Input() activeCustomFunctionId: string;

  @Input() visibleFields: string[];
  @Input() customFunction: any;
  @Input() enableSubmitButton: boolean;
  @Input() submitButtonLoading: boolean;

  @Output() updateFormSubmit = new EventEmitter<any>();

  updateCustomFunctionForm: FormGroup;
  formLayout: FormLayout = FormLayout.Horizontal;
  programmingLanguageOptions = PROGRAMMING_LANGUAGES;
  packageColumns = PACKAGE_COLUMNS;
  environmentVariablesColumns = ENVIRONMENT_VARIABLES_COLUMNS;
  isModalOpen = false;
  selectedPackage: NPMPackage;
  npmPackages: KeyValue<string, NPMPackage>[] = [];
  searchChange$ = new Subject<string>();
  projectPackages: ProjectPackageInterface[];
  packageDetails: KeyValue<string, any>[];
  isLoading = false;
  loading = true;
  selectedVersion = null;
  isCustomFunctionLoading = false;
  formIsValid: boolean;

  projectPackageInfoAlertConfig = projectPackageInfoAlertConfig;
  addProjectPackageButtonConfig = addProjectPackageButtonConfig;

  MONACO_EDITOR_JAVASCRIPT_CONFIG = MONACO_EDITOR_JAVASCRIPT_CONFIG;
  MONACO_EDITOR_JSON_CONFIG = MONACO_EDITOR_JSON_CONFIG;

  codeMirrorSettings = {
    mode: CodeMirrorMode.Javascript,
    lineNumbers: true,
    autoCloseBrackets: true
  };

  loadingIcon: IconComponentConfig = {
    name: 'loading',
    type: IconType.NgZorro
  };

  versions = {
    placement: 'bottomRight',
    items: [],
    labelMode: 'static-label',
  };

  options = [
    {
      label: '',
      value: ''
    }
  ];

  subscriptions: Subscription[] = [];
  customFunctions: CustomFunctionDefinitionWithRelations[] = [];
  activeItem?: CustomFunctionDefinitionWithRelations;

  environments = ENVIRONMENT_VARIABLES;
  activeEnvironmentVariable = ACTIVE_ENVIRONMENT_VARIABLE;
  displayedEnvironmentVariables: EnvironmentVariable[];;
  grouppedEnvironmentVariables = {};
  customFunctionCreateEditFormItem = CustomFunctionCreateEditFormItem;
  codeEditorVisible = false;
  requestJSONSchemaVisible = false;
  responseJSONSchemaVisible = false;
  mockResponseVisible = false;
  /* This variable is used to switch the visibility of the Monaco editors on the page as we need to wait Monaco js code to complete the initializing process to switch the visibility*/
  monacoEditorLoadTime = 50;
  editorConfig = MONACO_EDITOR_JAVASCRIPT_CONFIG;

  constructor(
    private store: Store<any>,
    private npmPackageSearchService: NpmPackageSearchService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private httpClient: HttpClient
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
    this.getNpmPackages();
    this.loading = false;
    this.changeActiveCodeEditorTab(CustomFunctionCreateEditFormItem.Code);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customFunction && changes.customFunction.firstChange) {
      this.buildForm();
    }
    if (changes.customFunction && !changes?.customFunction.firstChange) {
      this.updateFunctionName();
    }
    if (changes.visibleFields) {
      this.updateForm();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  updateFunctionName() {
    this.updateCustomFunctionForm.get('functionName').setValue(this.customFunction.functionName);
  }

  getFormControls() {
    const formControls = {
      serviceName: [this.customFunction?.serviceName, [Validators.required, Validators.pattern(PASCAL_CASE_REGEX)]],
      functionName: [this.customFunction?.functionName, [Validators.required]],
      programmingLanguage: [this.customFunction?.programmingLanguage, []],
      packages: [this.customFunction?.packages, []],
      DEV: [this.customFunction?.environmentVariables.filter(o => o.environmentKey === 'dev').map(item => ({
        ...item,
        type: assignTypeAccordingToValue(item.value)
      })) || [], []],
      QA: [this.customFunction?.environmentVariables.filter(o => o.environmentKey === 'qa').map(item => ({
        ...item,
        type: assignTypeAccordingToValue(item.value)
      })) || [], []],
      PROD: [this.customFunction?.environmentVariables.filter(o => o.environmentKey === 'prod').map(item => ({
        ...item,
        type: assignTypeAccordingToValue(item.value)
      })) || [], []],
      environmentVariables: [null, []],
      code: [this.customFunction?.code as string, [Validators.required]],
      requestJSONSample: [this.customFunction?.requestJSONSample as string, [isJsonValid('requestJSONSample', true)]],
      responseJSONSample: [this.customFunction?.responseJSONSample as string, [isJsonValid('responseJSONSample', true)]],
    };

    if (this.visibleFields.includes('mockResponse')) {
      formControls['mockResponse'] = [this.customFunction?.mockResponse as string, [isJsonValid('mockResponse', true)]];
    }

    const controls = {};

    for (const key in formControls) {
      if (this.visibleFields.includes(key)) {
        controls[key] = formControls[key];
      }
    }

    return controls;
  }

  buildForm() {
    const activeItem = this.customFunction;
    const activeItemOfEnvironmentVariables = cloneDeep(activeItem?.environmentVariables) || [];
    this.updateCustomFunctionForm = this.formBuilder.group({
      serviceName: [activeItem?.serviceName, [Validators.required, Validators.pattern(PASCAL_CASE_REGEX)]],
      functionName: [activeItem?.functionName, [Validators.required]],
      programmingLanguage: [activeItem?.programmingLanguage, []],
      packages: [activeItem?.packages, []],
      DEV: [activeItemOfEnvironmentVariables.filter(o => o.environmentKey === 'dev').map(item => ({
        ...item,
        type: assignTypeAccordingToValue(item.value)
      })) || [], []],
      QA: [activeItemOfEnvironmentVariables.filter(o => o.environmentKey === 'qa').map(item => ({
        ...item,
        type: assignTypeAccordingToValue(item.value)
      })) || [], []],
      PROD: [activeItemOfEnvironmentVariables.filter(o => o.environmentKey === 'prod').map(item => ({
        ...item,
        type: assignTypeAccordingToValue(item.value)
      })) || [], []],
      environmentVariables: [null, []],
      code: [activeItem?.code as string, [Validators.required]],
      requestJSONSample: [activeItem?.requestJSONSample as string, [isJsonValid('requestJSONSample', true)]],
      responseJSONSample: [activeItem?.responseJSONSample as string, [isJsonValid('responseJSONSample', true)]],
    });

    if (this.visibleFields.includes('mockResponse')) {
      this.updateCustomFunctionForm.addControl('mockResponse', this.formBuilder.control(activeItem?.mockResponse as string, [isJsonValid('mockResponse', true)]));
    }

    this.grouppedEnvironmentVariables = {
      DEV: this.updateCustomFunctionForm.get('DEV').value,
      QA: this.updateCustomFunctionForm.get('QA').value,
      PROD: this.updateCustomFunctionForm.get('PROD').value
    };

    this.onHandleActiveEnvironmentVariableTabList(this.activeEnvironmentVariable);
  }

  updateForm() {
    const currentValues = this.updateCustomFunctionForm.value;
    const controls = this.getFormControls();

    for (const key in controls) {
      if (this.updateCustomFunctionForm.contains(key)) {
        // Mevcut form kontrolünü güncelle
        this.updateCustomFunctionForm.get(key).setValidators(controls[key][1]);
        this.updateCustomFunctionForm.get(key).updateValueAndValidity();
      } else {
        // Yeni form kontrolünü ekle
        this.updateCustomFunctionForm.addControl(key, this.formBuilder.control(controls[key][0], controls[key][1]));
      }
    }

    for (const key in this.updateCustomFunctionForm.controls) {
      if (!controls[key]) {
        this.updateCustomFunctionForm.removeControl(key);
      }
    }

    this.updateCustomFunctionForm.patchValue(currentValues);

    const initialLanguage = this.updateCustomFunctionForm.get('programmingLanguage').value;
    this.onProgrammingLanguageChange(initialLanguage);

    if (!this.visibleFields.find(visibleField => visibleField === 'mockResponse')) {
      setTimeout(() => {
        this.codeEditorVisible = true;
        this.requestJSONSchemaVisible = false;
        this.responseJSONSchemaVisible = false;
        this.mockResponseVisible = false;
      }, this.monacoEditorLoadTime);
    }
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToProjectPackages(),
      this.subscribeToCustomFunctionLoading(),
      this.subscribeToPreferredThemeAndUpdateMonacoTheme()
    ];
  }

  subscribeToProjectPackages() {
    return this.store.select(state => state?.projectPackage?.data).subscribe((projectPackages: ProjectPackageInterface[]) => {
      if (projectPackages) {
        this.projectPackages = projectPackages;
      }
    });
  }

  subscribeToCustomFunctions() {
    return this.store.select(state => state.customFunction?.data).subscribe((customFunctions: CustomFunctionDefinitionWithRelations[]) => {
      this.customFunctions = customFunctions;
      if (this.activeCustomFunctionId) {
      }
    });
  }

  subscribeToCustomFunctionLoading() {
    return this.store.select(state => state.customFunction?.isLoading).subscribe((isLoading: boolean) => {
      this.isCustomFunctionLoading = isLoading;
    });
  }

  subscribeToPreferredThemeAndUpdateMonacoTheme() {
    return this.store.select(state => state.auth?.activePerson?.preferredTheme).subscribe(preferredTheme => {
      if (preferredTheme === ThemeMode.Light) {
        this.MONACO_EDITOR_JAVASCRIPT_CONFIG.theme = MonacoEditorTheme.Light;
        this.MONACO_EDITOR_JSON_CONFIG.theme = MonacoEditorTheme.Light;
      } else {
        this.MONACO_EDITOR_JAVASCRIPT_CONFIG.theme = MonacoEditorTheme.Dark;
        this.MONACO_EDITOR_JSON_CONFIG.theme = MonacoEditorTheme.Dark;
      }
      this.MONACO_EDITOR_JAVASCRIPT_CONFIG = { ...this.MONACO_EDITOR_JAVASCRIPT_CONFIG };
      this.MONACO_EDITOR_JSON_CONFIG = { ...this.MONACO_EDITOR_JSON_CONFIG };
    });
  }

  onProgrammingLanguageChange(value: string) {
    const language = value.replace(':', '');
    const url = `/assets/code/custom-function-definition/code-examples/${language}.txt`;
    this.httpClient.get(url, { responseType: 'text' }).subscribe((data) => {
      this.updateCustomFunctionForm.get('code').setValue(data);
    });
    const editorConfig = updateCodeExampleOnLanguage(language);
    this.editorConfig = editorConfig;
  }

  setPackageDetailData(selectedPackage: NPMPackage, npmPackageVersions: any) {
    if (selectedPackage && npmPackageVersions) {
      const values = Object.values(npmPackageVersions.versions);

      values.forEach((item, index) => {
        this.options[index] = { label: item['version'], value: item['version'] };
        this.versions.items.push({ label: item['version'] });
      });
      this.packageDetails = [
        {
          key: 'SHARED.NAME',
          value: [selectedPackage.name]
        },
        {
          key: 'SHARED.VERSION',
          value: [this.options.sort((a, b) => sortingByNumber(a.value, b.value))]
        },
        {
          key: 'SHARED.DESCRIPTION',
          value: [selectedPackage.description]
        },
        {
          key: 'SHARED.AUTHOR',
          value: Object.values(selectedPackage.author ?? {})
        },
        {
          key: 'SHARED.PUBLISHER',
          value: Object.values(selectedPackage.publisher ?? {})
        },
        {
          key: 'SHARED.LINKS',
          value: Object.values(selectedPackage.links ?? {})
        },
        {
          key: 'SHARED.KEYWORDS',
          value: selectedPackage.keywords
        }
      ];

      this.selectedVersion = values[values.length - 1]['version'];

    } else {
      this.packageDetails = null;
    }
  }

  getNameFieldErrorsByErrorKey(errorKey: string) {
    const control = this.updateCustomFunctionForm.get(CustomFunctionCreateEditFormItem.ServiceName);
    const isDirty = control?.dirty;
    const errors = control?.errors;
    return isDirty && errors && control.hasError(errorKey);
  }

  openModal() {
    this.isModalOpen = true;
  }

  onModalOk() {
    this.isModalOpen = false;

    if (this.updateCustomFunctionForm.value.packages) {
      const isProjectPackageNameExist = this.updateCustomFunctionForm.value.packages
        .some(projectPackage => projectPackage.packageName === this.selectedPackage.name);
      if (!isProjectPackageNameExist) {
        this.updateCustomFunctionForm.patchValue({
          packages: [
            ...(this.updateCustomFunctionForm?.value?.packages ? <any[]>this.updateCustomFunctionForm?.value?.packages : []),
            { 'packageName': this.selectedPackage.name, 'versionNumber': this.selectedVersion }
          ]
        });
      } else {
        this.notificationService.createNotification(
          'error',
          'Error',
          'This package already exists in this custom function.'
        );
      }
    } else {
      this.updateCustomFunctionForm.patchValue({
        packages: [
          ...(this.updateCustomFunctionForm?.value?.packages ? <any[]>this.updateCustomFunctionForm?.value?.packages : []),
          { 'packageName': this.selectedPackage.name, 'versionNumber': this.selectedVersion }
        ]
      });
    }

    this.selectedPackage = null;
    this.packageDetails = null;
    this.selectedVersion = null;
  }

  selectVersionAlert() {
    this.notificationService.createNotification(
      'warning',
      'Warning',
      'Please select a package version!'
    );
  }

  onModalCancel() {
    this.isModalOpen = false;
    this.selectedPackage = null;
    this.packageDetails = null;
    this.selectedVersion = null;
  }

  onSearchValueChange(searchValue: string) {
    if (searchValue === '') {
      this.isLoading = false;
    } else {
      this.isLoading = true;
    }
    this.searchChange$.next(searchValue);
  }

  async onSelectPackage(selectedPackage: NPMPackage) {
    const npmPackageVersions = await this.npmPackageSearchService.getNpmPackageVersions(selectedPackage.name);
    this.setPackageDetailData(selectedPackage, npmPackageVersions);
  }

  getNpmPackages() {
    const npmPackages$: Observable<any> = this.searchChange$
      .asObservable().pipe(
        debounceTime(1500),
        switchMap((searchValue) => {
          if (searchValue) {
            return this.npmPackageSearchService.getNpmPackages(searchValue);
          } else {
            return of(null);
          }
        })
      );

    this.setNpmPackageSelectOptions(npmPackages$);
  }

  setNpmPackageSelectOptions(packages: Observable<any>) {
    packages.subscribe(packages => {
      this.npmPackages = packages?.results?.map(result => ({
        key: result.package.name,
        value: result.package
      })) ?? [];
      this.isLoading = false;
    });
  }

  onUpdateCustomFunction() {
    try {
      if (this.updateCustomFunctionForm.valid) {
        const updatedFormBeforeSubmit = [
          ...(this.updateCustomFunctionForm.get('DEV').value.map(devValue => ({
            environmentKey: 'dev',
            key: devValue.key,
            value: devValue.value
          })) || []),
          ...(this.updateCustomFunctionForm.get('QA').value.map(qaValue => ({
            environmentKey: 'qa',
            key: qaValue.key,
            value: qaValue.value
          })) || []),
          ...(this.updateCustomFunctionForm.get('PROD').value.map(prodValue => ({
            environmentKey: 'prod',
            key: prodValue.key,
            value: prodValue.value
          })) || []),
        ];
        this.updateCustomFunctionForm.get('environmentVariables').setValue(updatedFormBeforeSubmit);
        const updateForm = this.updateCustomFunctionForm.value;
        delete updateForm.DEV;
        delete updateForm.PROD;
        delete updateForm.QA;
        this.updateFormSubmit.emit({
          ...updateForm,
          requestJSONSample: this.updateCustomFunctionForm.value.requestJSONSample ? JSON.parse(this.updateCustomFunctionForm.value.requestJSONSample) : undefined,
          responseJSONSample: this.updateCustomFunctionForm.value.responseJSONSample ? JSON.parse(this.updateCustomFunctionForm.value.responseJSONSample) : undefined
        });
      }
    } catch (error) {
      this.notificationService.createNotification(
        'error',
        'Error',
        'JSON format is wrong. Please enter a valid JSON'
      );
    }
  }

  onVersionChange(value: any) {
    this.selectedVersion = value;
  }

  onCreateFormSubmit(formData) {
    this.environments.forEach(environment => {
      if (environment === formData.activeEnvironmentVariable) {
        this.updateCustomFunctionForm.get(environment).setValue(
          [...this.updateCustomFunctionForm.get(environment).value, { key: formData.key, type: formData.type, value: formData.value }]
        );
      } else {
        this.updateCustomFunctionForm.get(environment).setValue(
          [...this.updateCustomFunctionForm.get(environment).value, { key: formData.key, type: null, value: null }]
        );
      }
    });
    this.grouppedEnvironmentVariables = {
      DEV: this.updateCustomFunctionForm.get('DEV').value,
      QA: this.updateCustomFunctionForm.get('QA').value,
      PROD: this.updateCustomFunctionForm.get('PROD').value
    };
    this.onHandleActiveEnvironmentVariableTabList(this.activeEnvironmentVariable);
  }

  onEditFormSubmit(formData) {
    const formValue = this.updateCustomFunctionForm.get(this.activeEnvironmentVariable).value;
    const updatedDevValue = formValue.map(item => {
      if (item.key === formData.editableModalData.key) {
        item.key = formData.key;
        item.type = formData.type;
        item.value = formData.value;
      }
      return item;
    });
    this.updateCustomFunctionForm.get(this.activeEnvironmentVariable).setValue(updatedDevValue);
    this.grouppedEnvironmentVariables = {
      ...this.grouppedEnvironmentVariables,
      [this.activeEnvironmentVariable]: this.updateCustomFunctionForm.get(this.activeEnvironmentVariable).value
    };
    this.onHandleActiveEnvironmentVariableTabList(this.activeEnvironmentVariable);
  }

  onDeleteFormItem(formData) {
    const formValue = this.updateCustomFunctionForm.get(this.activeEnvironmentVariable).value;
    const updatedFromValue = formValue.filter(item => item.key !== formData.key);
    this.updateCustomFunctionForm.get(this.activeEnvironmentVariable).setValue(updatedFromValue);
    this.grouppedEnvironmentVariables = {
      ...this.grouppedEnvironmentVariables,
      [this.activeEnvironmentVariable]: this.updateCustomFunctionForm.get(this.activeEnvironmentVariable).value
    };
    this.onHandleActiveEnvironmentVariableTabList(this.activeEnvironmentVariable);
  }

  onHandleActiveEnvironmentVariableTabList(activeEnvironment) {
    this.activeEnvironmentVariable = activeEnvironment;
    this.displayedEnvironmentVariables = this.grouppedEnvironmentVariables[activeEnvironment];
  }

  changeActiveCodeEditorTab(activeTab) {
    if (activeTab === CustomFunctionCreateEditFormItem.Code) {
      setTimeout(() => {
        this.codeEditorVisible = true;
        this.requestJSONSchemaVisible = false;
        this.responseJSONSchemaVisible = false;
      }, this.monacoEditorLoadTime);
    } else if (activeTab === CustomFunctionCreateEditFormItem.RequestJSONSchema) {
      setTimeout(() => {
        this.codeEditorVisible = false;
        this.requestJSONSchemaVisible = true;
        this.responseJSONSchemaVisible = false;
      }, this.monacoEditorLoadTime);
    } else if (activeTab === CustomFunctionCreateEditFormItem.ResponseJSONSchema) {
      setTimeout(() => {
        this.codeEditorVisible = false;
        this.requestJSONSchemaVisible = false;
        this.responseJSONSchemaVisible = true;
      }, this.monacoEditorLoadTime);
    } else if (activeTab === CustomFunctionCreateEditFormItem.MockResponse) {
      setTimeout(() => {
        this.codeEditorVisible = false;
        this.requestJSONSchemaVisible = false;
        this.responseJSONSchemaVisible = false;
        this.mockResponseVisible = true;
      }, this.monacoEditorLoadTime);
    }
  }
}
