import { UiDataEventWithRelations } from '@rappider/rappider-sdk';
import * as UIDataEventActions from './ui-data-event.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import * as AppActions from 'apps/rappider/src/app/state/app.actions';
import { cloneDeep, orderBy } from 'lodash';
export const uiDataEventFeatureKey = 'uiDataEvent';

export interface State {
  data: UiDataEventWithRelations[];
  loading: boolean;
  isLoaded: boolean;
  isTemplateUIDataEventsLoading: boolean;
  templateUIDataEvents: UiDataEventWithRelations[];
  isUIDataEventPayloadLoaded: boolean;
  error: {
    timestamp?: number;
    key?: string;
    error?: unknown;
  };
}

export const initialState: State = {
  data: [],
  loading: true,
  isLoaded: false,
  isTemplateUIDataEventsLoading: true,
  templateUIDataEvents: [],
  isUIDataEventPayloadLoaded: false,
  error: {}
};

export function reducer(
  state: State = initialState,
  action: UIDataEventActions.Actions | AuthenticationActions.Actions | AppActions.Actions
): State {
  switch (action.type) {
    case AppActions.ActionTypes.LoadApp:
      return {
        ...state,
        isTemplateUIDataEventsLoading: true
      };

    case UIDataEventActions.ActionTypes.SetUIDataEvents:
      return {
        ...state,
        data: action.payload.uiDataEvents,
        loading: false,
        isLoaded: true
      };

    case UIDataEventActions.ActionTypes.CreateUIDataEvent:
      return {
        ...state,
        loading: true
      };

    case UIDataEventActions.ActionTypes.CreateUIDataEventSuccessful:
      return {
        ...state,
        data: [
          ...state.data,
          action.payload.uiDataEvent
        ],
        loading: false
      };

    case UIDataEventActions.ActionTypes.BulkCreateUIDataEventSuccessful:
      return {
        ...state,
        data: [
          ...state.data,
          ...action.payload.uiDataEvents
        ],
        loading: false
      };

    case UIDataEventActions.ActionTypes.UpdateUIDataEvent:
      return {
        ...state,
        loading: true
      };

    case UIDataEventActions.ActionTypes.UpdateUIDataEventSuccessful:
      return {
        ...state,
        data: [
          ...state.data.filter(datum => datum.id !== action.payload.uiDataEvent.id),
          action.payload.uiDataEvent
        ],
        loading: false
      };

    case UIDataEventActions.ActionTypes.DeleteUIDataEvent:
      return {
        ...state,
        loading: true
      };

    case UIDataEventActions.ActionTypes.DeleteUIDataEventSuccessful:
      // eslint-disable-next-line no-case-declarations
      const updatedState = state.data.filter(datum => datum.id !== action.payload.deletedUIDataEventId);

      return {
        ...state,
        data: updatedState,
        loading: false
      };

    case UIDataEventActions.ActionTypes.GetTemplateUIDataEventsSuccessful:
      return {
        ...state,
        templateUIDataEvents: action.payload.uiDataEvents,
        isTemplateUIDataEventsLoading: false
      };

    case UIDataEventActions.ActionTypes.GetTemplateUIDataEventsFailure:
      return {
        ...state,
        error: action.payload.error,
        isTemplateUIDataEventsLoading: false
      };

    case UIDataEventActions.ActionTypes.ErrorAction:
      return {
        ...state,
        error: {
          ...state.error,
          timestamp: action.payload.timestamp,
          key: action.payload.key,
          error: action.payload.error
        },
        loading: false
      };

    case AuthenticationActions.ActionTypes.Logout:
      return initialState;

    case AuthenticationActions.ActionTypes.ChangeActiveProjectSuccessful: {
      const templateUIDataEvents = state.templateUIDataEvents;
      return {
        ...initialState, templateUIDataEvents: templateUIDataEvents
      };
    }

    case UIDataEventActions.ActionTypes.CreateDataSchemaFieldsOfUIDataEvent:
      {
        const uiDataEvent = cloneDeep(state.data.find(uiDataEvent => uiDataEvent.dataSchemaId === action.payload.parentDataSchemaId));
        uiDataEvent.payload.fields = orderBy([
          ...uiDataEvent.schema.fields ?? [],
          ...action.payload.createdDataSchemaFields
        ], 'index', 'asc');
        return {
          ...state,
          loading: false,
          data: [
            ...state.data.filter(ds => ds.id !== uiDataEvent.id),
            uiDataEvent
          ]
        };
      }

    case UIDataEventActions.ActionTypes.DeleteDataSchemaFieldsOfUIDataEvent:
      {
        const uiDataEvent = cloneDeep(state.data.find(uiDataEvent => uiDataEvent.dataSchemaId === action.payload.parentDataSchemaId));
        uiDataEvent.payload.fields = uiDataEvent.payload.fields.filter(field => !action.payload.deletedDataSchemaFieldIds.includes(field.id));
        return {
          ...state,
          loading: false,
          data: [
            ...state.data.filter(ds => ds.id !== uiDataEvent.id),
            uiDataEvent
          ]
        };
      }

    case UIDataEventActions.ActionTypes.UpdateDataSchemaFieldsOfUIDataEvent:
      {
        const uiDataEvent = cloneDeep(state.data.find(uiDataEvent => uiDataEvent.dataSchemaId === action.payload.parentDataSchemaId));
        uiDataEvent.payload.fields = uiDataEvent.payload.fields.map(field => {
          const updatedField = action.payload.updatedDataSchemaFields.find(updatedField => updatedField.id === field.id);
          if (updatedField) {
            return {
              ...field,
              ...updatedField
            };
          } else {
            return field;
          }
        });
        return {
          ...state,
          loading: false,
          data: [
            ...state.data.filter(dataEvent => dataEvent.id !== uiDataEvent.id),
            uiDataEvent
          ]
        };
      }

    case UIDataEventActions.ActionTypes.GetUIDataEventPayload:
      {
        return {
          ...state,
          loading: true,
          isUIDataEventPayloadLoaded: false,
        };
      }

    case UIDataEventActions.ActionTypes.GetUIDataEventPayloadSuccessful:
      {
        return {
          ...state,
          loading: false,
          isUIDataEventPayloadLoaded: true,
        };
      }

    case UIDataEventActions.ActionTypes.GetUIDataEventPayloadFailure:
      {
        return {
          ...state,
          loading: false,
          isUIDataEventPayloadLoaded: false,
        };
      }

    default:
      return state;
  }
}
