import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenService } from '../../services/token-service/token.service';
import { environment } from 'apps/rappider/src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private tokenService: TokenService
  ) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /* TODO: (canmustu) delete the api base url control and use this interceptor in rappider-api-sdk only */
    if (request.url.startsWith(environment.apiBaseUrl)) {
      /* get token key */
      const authenticationToken = this.tokenService.getAuthenticationToken();
      if (authenticationToken) {
        /* set authorization header by token key */
        request = request.clone({
          setHeaders: {
            Authorization: authenticationToken
          }
        });
      }
    }
    return next.handle(request);
  }
}
