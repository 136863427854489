import { Validators } from '@angular/forms';
import {
  Action,
  ActionBehavior,
  CrudFormConfig,
  CrudFormTextBoxItem,
  CrudTableViewConfig,
  CrudViewColumnType,
  CrudViewFormItemType,
  FormLayout,
  ButtonType,
  PropertyType,
  InputSize,
  TextBoxType,
  CrudFormSelectItem,
  SelectMode,
  CrudFormSwitchItem,
  TooltipPlacement
} from '@rappider/rappider-components/utils';
import { EMAIL_REGEX } from '../../definitions/regex';
import { InvitationStatus } from 'libs/project/src/lib/components/user-invitations/utils/invitation-status.enum';

export const INVITE_USER_BUTTON_CONFIG: Action = {
  text: 'Invite User',
  name: 'Invite User',
  behavior: ActionBehavior.Emit,
  buttonType: ButtonType.Default,
  icon: { name: 'fas fa-plus' },
};

export const INVITED_USER_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'invitedUsername',
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'SHARED.EMAIL',
      fieldName: 'invitedEmail',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.FIRST_NAME',
      fieldName: 'invitedFirstName',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.LAST_NAME',
      fieldName: 'invitedLastName',
      type: CrudViewColumnType.Text
    },
    {
      title: 'Invited Date',
      fieldName: 'createdDate',
      type: CrudViewColumnType.DateTime
    },
    {
      title: 'SHARED.STATUS',
      fieldName: 'status',
      type: CrudViewColumnType.Text
    }
  ],
  listActions: [
    INVITE_USER_BUTTON_CONFIG
  ],
  itemActions: [
    {
      text: 'Cancel',
      behavior: ActionBehavior.Emit,
      name: 'cancel',
      popconfirmTitle: 'Are you sure you want to cancel this invitation',
      emitWithoutPopconfirm: false
    },
    {
      text: 'Re-send',
      behavior: ActionBehavior.Emit,
      name: 'resend',
      popconfirmTitle: 'Are you sure you want to re-send this invitation ?',
      emitWithoutPopconfirm: false
    }
  ],
  handleVisibilityFn: (data, item, action) =>
    [InvitationStatus.WaitingRegistration, InvitationStatus.PendingResponse].includes(item.status)
};

export const INVITE_USER_VIA_MAIL_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormTextBoxItem>{
      size: InputSize.Default,
      type: CrudViewFormItemType.TextBox,
      textType: TextBoxType.Text,
      title: 'SHARED.FIRST_NAME',
      fieldName: 'invitedFirstName',
      placeholder: 'SHARED.FIRST_NAME',
      section: 'User Details',
    },
    <CrudFormTextBoxItem>{
      title: 'SHARED.LAST_NAME',
      fieldName: 'invitedLastName',
      type: CrudViewFormItemType.TextBox,
      textType: TextBoxType.Text,
      placeholder: 'SHARED.LAST_NAME',
      size: InputSize.Default,
      section: 'User Details',
    },
    <CrudFormTextBoxItem>{
      title: 'SHARED.EMAIL',
      fieldName: 'email',
      type: CrudViewFormItemType.TextBox,
      textType: TextBoxType.Text,
      placeholder: 'SHARED.EMAIL',
      section: 'User Details',
      size: InputSize.Default,
      showRequiredSign: true,
      validators: [
        {
          type: Validators.pattern(EMAIL_REGEX),
          errorKey: 'pattern',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.VALID_EMAIL_MESSAGE'
        },
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'RAPPIDER_COMPONENTS_MODULE.LOGIN_FORM_COMPONENT.REQUIRED_ERROR_TIP'
        }
      ],
    },
    <CrudFormSwitchItem>{
      title: 'Manager',
      fieldName: 'isManager',
      description: 'Assign user as manager',
      type: CrudViewFormItemType.Switch
    }
    // Project Roles Multiple Select
    // <CrudFormSelectItem>{
    //   title: 'Users Roles',
    //   fieldName: 'usersRoles',
    //   type: CrudViewFormItemType.Select,
    //   settings: {
    //     mode: SelectMode.Multiple,
    //   },
    //   placeholder: 'Select Users Roles',
    //   size: InputSize.Default,
    //   section: 'SHARED.ROLES',
    //   description: 'A user can have one or several roles',
    //   validators: [
    //     {
    //       type: Validators.required,
    //       errorKey: 'required',
    //       errorMessage: 'RAPPIDER_COMPONENTS_MODULE.LOGIN_FORM_COMPONENT.REQUIRED_ERROR_TIP'
    //     }
    //   ]
    // },
  ],
  submitButton: { visible: false },
  itemSettings: {
    inputComponentSize: {
      xs: 24
    }
  },
  formLayout: FormLayout.Horizontal
};

export const INVITE_USER_VIA_PHONE_NUMBER_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormTextBoxItem>{
      title: 'Users',
      fieldName: 'invitedUsers',
      type: CrudViewFormItemType.RowForm,
      hasInitialEmptyRow: true,
      addButtonText: 'Add User',
      columns: [
        {
          fieldName: 'username',
          placeholder: 'SHARED.CELL_PHONE',
          unique: true,
          validators: [
            {
              type: Validators.required,
              errorKey: 'required',
              errorMessage: 'RAPPIDER_COMPONENTS_MODULE.LOGIN_FORM_COMPONENT.REQUIRED_ERROR_TIP'
            }
          ]
        },
        {
          fieldName: 'name',
          placeholder: 'Name',
          validators: [
            {
              type: Validators.required,
              errorKey: 'required',
              errorMessage: 'RAPPIDER_COMPONENTS_MODULE.LOGIN_FORM_COMPONENT.REQUIRED_ERROR_TIP'
            }
          ]
        }
      ]
    }
  ],
  submitButton: { visible: false },
  itemSettings: {
    inputComponentSize: {
      xs: 24
    }
  },
  formLayout: FormLayout.Vertical
};
