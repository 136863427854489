import { CrudFormConfig, CrudViewFormItemType } from '@rappider/rappider-components/utils';
import { MOCK_RESPONSE_FORM_ITEM } from './mock-response-form-item.config';

export const ENDPOINT_DETAIL_CONFIG: CrudFormConfig = {
  items: [
    {
      title: 'PROJECT_MODULE.CUSTOM_ENDPOINT_CREATE_OR_EDIT_COMPONENT.IS_MOCKED',
      fieldName: 'isMocked',
      type: CrudViewFormItemType.CheckBox
    },
    MOCK_RESPONSE_FORM_ITEM
  ],
  itemSettings: {
    inputComponentSize: {
      xs: 24
    }
  }
};
