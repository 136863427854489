import { Injectable } from '@angular/core';
import { PersonInterface } from '@rappider/api-sdk';
import { Person } from '@rappider/rappider-sdk';
import { Observable } from 'rxjs';
import { PersonControllerService } from '@rappider/rappider-sdk';

@Injectable({
  providedIn: 'root'
})
export class PersonService {

  constructor(
    private personApi: PersonControllerService
  ) { }

  getPeopleByUserId(userId: string): Observable<Person[]> {
    const filter = {
      where: { userId: userId }
    };
    return this.personApi.find({ filter });
  }

  getFullNameFromPerson(person: PersonInterface) {
    const fullName = `${person.firstName}${person.middleName ? ` ${person.middleName}` : ''} ${person.lastName}`;
    return fullName;
  }

}
