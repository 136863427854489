import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TreeService {

  constructor() { }

  /**
   * this function return node by specified parameters
   *
   * @param {Record<string, any>} root root of tree
   * @param {string} searchingValue unique value of node ex: id: uuid()
   * @param {string} searchField fieldName to search ex: id, key etc.
   * @param {string[]} childrenFieldNames this function allows you to search multiple child nodes ex: ['files', 'folders', 'children']
   * @param {boolean} [includeParentNode=false] when the node is found, it returns its parent along with the node
   * @return {*}
   * @memberof TreeService
   */
  findNodeInTree(root: Record<string, any>, searchingValue: string, fieldName: string, childrenFieldNames: string[], includeParentNode: boolean = false) {
    const stack = [];
    const stackOfParentNodes = [];

    stack.push(root);

    while (stack?.length) {
      const node = stack.pop();
      const parentNode = stackOfParentNodes.pop();
      if (node[fieldName] === searchingValue) {
        if (includeParentNode) {
          return {
            node: node,
            parentNode: parentNode
          };
        } else {
          return node;
        }
      } else {
        childrenFieldNames.forEach(childrenFieldName => {
          if (node[childrenFieldName]?.length) {
            stack.push(...node[childrenFieldName]);
            // push node up to the count of children nodes
            node[childrenFieldName].forEach(() => stackOfParentNodes.push(node));
          }
        });
      }
    }

    return null;
  }

  findNodeToRootPath(root: Record<string, any>, searchingValue: string, fieldName: string, childrenFieldNames: string[], returnFullNode: boolean = false) {
    const existingNode = this.findNodeInTree(root, searchingValue, fieldName, childrenFieldNames, true);
    let parentNode = existingNode?.parentNode;
    const path = [returnFullNode ? existingNode?.node : existingNode?.node?.[fieldName]];
    while (parentNode) {
      path.push(returnFullNode ? parentNode : parentNode[fieldName]);
      parentNode = this.findNodeInTree(root, parentNode.id, fieldName, childrenFieldNames, true)?.parentNode;
    }
    return path.filter(e => e);
  }

}
