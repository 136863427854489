<div class="time-picker">
  <nz-time-picker [(ngModel)]="value"
                  [id]="elementId"
                  [nzFormat]="format"
                  [style.border]="borderSettings?.border"
                  [style.border-top]="borderSettings?.borderTop + ' ' + borderSettings?.borderStyle"
                  [style.border-right]="borderSettings?.borderRight + ' ' + borderSettings?.borderStyle"
                  [style.border-bottom]="borderSettings?.borderBottom + ' ' + borderSettings?.borderStyle"
                  [style.border-left]="borderSettings?.borderLeft + ' ' + borderSettings?.borderStyle"
                  [style.border-radius]="borderSettings?.borderRadius"
                  [style.border-top-left-radius]="borderSettings?.borderTopLeftRadius"
                  [style.border-top-right-radius]="borderSettings?.borderTopRightRadius"
                  [style.border-bottom-left-radius]="borderSettings?.borderBottomLeftRadius"
                  [style.border-bottom-right-radius]="borderSettings?.borderBottomRightRadius"
                  [style.border-color]="borderSettings?.borderColor"
                  [style.border-style]="borderSettings?.borderStyle"
                  [style.width]="customSizeSettings?.width"
                  [style.height]="customSizeSettings?.height"
                  [style.min-width]="customSizeSettings?.minWidth"
                  [style.min-height]="customSizeSettings?.minHeight"
                  [style.max-width]="customSizeSettings?.maxWidth"
                  [style.max-height]="customSizeSettings?.maxHeight"
                  [style.boxShadow]="shadowSettings?.boxShadow"
                  [style.horizontalLength]="shadowSettings?.horizontalLength"
                  [style.verticalLength]="shadowSettings?.verticalLength"
                  [style.boxShadowBlur]="shadowSettings?.boxShadowBlur"
                  [style.boxShadowSpread]="shadowSettings?.boxShadowSpread"
                  [style.shadowColor]="shadowSettings?.shadowColor"
                  [style.inset]="shadowSettings?.inset"
                  [style.padding]="paddingSettings?.all"
                  [style.margin]="marginSettings?.all"
                  [style.background-color]="colorSettings?.backgroundColor"
                  (ngModelChange)="onTimeChange($event)"></nz-time-picker>
</div>
