import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ButtonComponentConfig, ButtonSize, RadioGroupOptions } from '@rappider/rappider-components/utils';
import { NzTreeNode } from 'ng-zorro-antd/tree';
import { SchemaTreeService } from '../../../services/schema-tree/schema-tree.service';
import { JsonSchema } from '../../../shared';
import { QueryBaseOperation } from '../../../shared/interfaces/query-base-operation.interface';
import { cloneDeep, isArray } from 'lodash';
import { defaultFilter } from './definitions/default-filter';
import { JoyrideService } from 'ngx-joyride';

@Component({
  selector: 'rappider-filter-find-row-element',
  templateUrl: './filter-find-row-element.component.html',
  styleUrls: ['./filter-find-row-element.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => FilterFindRowElementComponent),
      multi: true
    }
  ]
})
export class FilterFindRowElementComponent implements OnChanges, ControlValueAccessor {
  @Input() tree: NzTreeNode[];
  @Input() functions: string;
  @Input() jsonSchema: JsonSchema;
  @Input() filterOperationFlag: boolean;

  @Output() showStepsFlag = new EventEmitter<{ flag: boolean; key: string }>();

  logicItemAndFunctionsFlag = false;

  _value: QueryBaseOperation;

  radioGroupConfig: RadioGroupOptions[] = [
    {
      value: 'and',
      label: 'And'
    },
    {
      value: 'or',
      label: 'Or'
    }
  ];

  deleteButton: ButtonComponentConfig = {
    icon: { name: 'far fa-trash' }
  };
  addLogicButton: ButtonComponentConfig = {
    icon: { name: 'fas fa-plus' },
    text: 'Add Logic'
  };
  addFilterButton: ButtonComponentConfig = {
    icon: { name: 'fal fa-sort' },
    text: 'Add Filter'
  };

  constructor(
    private schemaService: SchemaTreeService,
    private readonly joyrideService: JoyrideService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterOperationFlag) {
      this.showFilterSteps();
    }
  }

  showFilterSteps() {
    if (this.filterOperationFlag) {
      this.joyrideService.startTour(
        { steps: ['filterLogicFirstStep', 'filterLogicSecondStep', 'filterLogicThirdStep'] }
      );
    }
  }

  onDone() {
    this.logicItemAndFunctionsFlag = true;
  }

  onShowStepsFlag(value: { flag: boolean; key: string }) {
    if (value.key === 'filter-find') {
      this.logicItemAndFunctionsFlag = value.flag;
    }
    if (!this.logicItemAndFunctionsFlag) {
      this.filterOperationFlag = false;
      this.showStepsFlag.emit({ flag: this.filterOperationFlag, key: 'filter-find' });
    }
  }

  get value() {
    return this._value;
  }

  set value(value: QueryBaseOperation) {
    this._value = this.setValue(value);
    this.onChange(this._value);
    this.onTouched();
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: any) {
    this._value = this.setValue(value);
    if (!this.value.operations?.length) {
      this.onAddFilterClick(this.value);
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onAddLogicClick(value: QueryBaseOperation) {
    if (value.operations) {
      value.operations.push(
        {
          logic: null,
          operations: [cloneDeep(defaultFilter)]
        }
      );
    } else {
      value.operations = [{ logic: null, operations: [cloneDeep(defaultFilter)] }];
    }
    this.onValueChange();
  }

  onAddFilterClick(value: QueryBaseOperation) {
    const newFilterObj = cloneDeep(defaultFilter);
    if (value.operations) {
      value.operations.push(newFilterObj);
    } else {
      value.operations = [newFilterObj];
    }
    this.onValueChange();
  }

  onOperationDelete(operations: any[], index: number) {
    operations.splice(index, 1);
    this.onValueChange();
  }

  onOperationChange(operation, changedOperation) {
    operation = changedOperation;
    this.onValueChange();
  }

  setValue(value) {
    if (value) {
      return {
        ...value,
        operations: value.operations?.map(operation => {
          if (!operation.logic && isArray(operation.sourceField)) {
            return {
              ...operation,
              sourceField: operation?.sourceField?.map(sfItem => sfItem.name)?.join('.') || null
            };
          } else {
            return this.setValue(operation);
          }
        })
      };
    } else {
      return {
        logic: null,
        operations: []
      };
    }
  }

  onValueChange() {
    this.value = { ...this.value };
  }

  onLogicDelete(operations, index) {
    operations.splice(index, 1);
  }

  /* this function avoids re-rendering the list unnecessarily */
  trackItems(index, item) {
    return index;
  }

}
