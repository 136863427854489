import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS, UIWorkflowItemTypeValues } from '@rappider/shared/definitions';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { Subscription } from 'rxjs';
import { ProjectWithRelations, UiDataStoreWithRelations, UiWorkflowStepFunction, UiWorkflowStepFunctionWithRelations } from '@rappider/rappider-sdk';
import { UI_STEP_FUNCTIONS_EMPTY_LIST_CONFIG, UI_STEP_FUNCTIONS_LIST_CONFIG } from '@rappider/shared/configs';
import { BreadcrumbOption, ButtonComponentConfig, ButtonSize, HeadingComponentConfig, IconType, InputGroupComponentConfig } from '@rappider/rappider-components/utils';
import { DeleteUIWorkflowStepFunction, GetPostDataTransformationData, GetPreDataTransformationData, TogglePostDataTransformationModalVisibility, TogglePreDataTransformationModalVisibility } from 'libs/project/src/lib/states/ui-step-functions/ui-step-function.actions';
import { PostDataTransformationData, PreDataTransformationData, uiWorkflowStepFunctionsListData } from '@rappider/shared/interfaces';
import { uiStepFunctionListDataSelector } from '../../../states/selectors/ui-step-function-list-data.selector';
import { toLower } from 'lodash';
import { StepFunctionPanelToggleButtonKey } from '../models/ui-step-function-panel-toggle-button-key.enum';
import { NotificationService } from '@rappider/services';

@Component({
  selector: 'rappider-ui-step-functions',
  templateUrl: './ui-step-functions.component.html',
  styleUrls: ['./ui-step-functions.component.scss']
})
export class UiStepFunctionsComponent implements OnInit, OnDestroy {
  /* ui step functions list config */
  UI_STEP_FUNCTIONS_LIST_CONFIG = UI_STEP_FUNCTIONS_LIST_CONFIG;
  /* ui step functions empty list config */
  UI_STEP_FUNCTIONS_EMPTY_LIST_CONFIG = UI_STEP_FUNCTIONS_EMPTY_LIST_CONFIG;
  /* main title */
  mainTitle: HeadingComponentConfig = {
    content: 'PROJECT_MODULE.UI_STEP_FUNCTIONS_COMPONENT.UI_STEP_FUNCTIONS',
    type: defaultToolbarTitleHeadingSize
  };
  /* page title */
  title: BreadcrumbOption[];
  collapseToggle = true;
  /* subscriptions */
  subscriptions: Subscription[];
  /* project models */
  uiDataStores: UiDataStoreWithRelations[];
  /* ui workflow step function */
  uiWorkflowStepFunctions: UiWorkflowStepFunctionWithRelations[];
  /* ui workflow step function list data displayed in the list */
  uiWorkflowStepFunctionsListData: uiWorkflowStepFunctionsListData[];
  /* data transformations' loading state */
  isDataTransformationsLoading: boolean;
  /* active project */
  activeProject: ProjectWithRelations;
  /* ui step functions loading state */
  loading: boolean;
  searchText: string;
  searchResults: uiWorkflowStepFunctionsListData[] = [];

  displayToolbar = false;
  displayToolbarBackButton = false;

  preSourceJsonSchema;
  preTargetJsonSchema;
  preDataTransformationId: string;
  isPreDataTransformationModalVisible = false;

  postSourceJsonSchema;
  postTargetJsonSchema;
  postDataTransformationId: string;
  isPostDataTransformationModalVisible = false;

  dataTransformationFlag = false;

  inputGroupConfig: InputGroupComponentConfig = {
    textbox: {
      placeholder: 'Search in Step Functions'
    },
    suffixIcon: {
      name: 'fas fa-search',
      type: IconType.FontAwesome
    }
  };
  expandButtonConfig: ButtonComponentConfig = {
    text: 'Expand All',
    key: StepFunctionPanelToggleButtonKey.ExpandAll,
    icon: {
      name: 'fas fa-plus'
    }
  };
  collapseButtonConfig: ButtonComponentConfig = {
    text: 'Collapse All',
    key: StepFunctionPanelToggleButtonKey.CollapseAll,
    icon: {
      name: 'fas fa-minus'
    }
  };
  createUiStepFunctionButton: ButtonComponentConfig = {
    icon: {
      name: 'fas fa-plus'
    },
    size: ButtonSize.ExtraSmall
  };

  constructor(
    private store: Store<any>,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToUIDataStores(),
      this.subscribeToDataTransformationsLoading(),
      this.subscribeToPreDataTransformationModalVisibility(),
      this.subscribeToPostDataTransformationModalVisibility(),
      this.subscribeToPreDataTransformationData(),
      this.subscribeToActiveProject(),
      this.subscribeToPostDataTransformationData(),
      this.subscribeToUiStepFunctionListData(),
      this.subscribeToUiStepFunctionsLoadingState()
    ];
  }

  navigateToUIStepFunctionCreate(uiDataStoreId: string) {
    this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.UI_STEP_FUNCTION_CREATE}/${uiDataStoreId}` }));
  }

  subscribeToUIDataStores() {
    return this.store.select(state => state.uiDataStore.data).subscribe((uiDataStores: UiDataStoreWithRelations[]) => {
      this.uiDataStores = uiDataStores;
    });
  }

  subscribeToUiStepFunctionListData() {
    return this.store.select(<any>uiStepFunctionListDataSelector).subscribe(data => {
      this.uiWorkflowStepFunctions = data.uiStepFunctions;
      this.uiWorkflowStepFunctionsListData = data.uiStepFunctionListData;
    });
  }

  subscribeToUiStepFunctionsLoadingState() {
    return this.store.select(state => state.uiWorkflowStepFunction.loading).subscribe((loading: boolean) => {
      this.loading = loading;
    });
  }

  subscribeToDataTransformationsLoading() {
    return this.store.select(state => state.uiWorkflowStepFunction?.dataTransformationsLoading).subscribe((isDataTransformationsLoading: boolean) => {
      this.isDataTransformationsLoading = isDataTransformationsLoading;
    });
  }

  subscribeToPreDataTransformationModalVisibility() {
    return this.store.select(state => state.uiWorkflowStepFunction?.preDataTransformationModalVisibility).subscribe((visibility: boolean) => {
      this.isPreDataTransformationModalVisible = visibility;
    });
  }

  subscribeToPostDataTransformationModalVisibility() {
    return this.store.select(state => state.uiWorkflowStepFunction?.postDataTransformationModalVisibility).subscribe((visibility: boolean) => {
      this.isPostDataTransformationModalVisible = visibility;
    });
  }

  onUiStepFunctionDeleteClick(uiWorkflowStepFunction: UiWorkflowStepFunction) {
    this.store.dispatch(new DeleteUIWorkflowStepFunction({ uiWorkflowStepFunction }));
  }

  // #region DATA TRANSFORMATION

  // #region PRE DATA TRANSFORMATION

  handlePreDataTransformationModalVisibility() {
    this.store.dispatch(new TogglePreDataTransformationModalVisibility());
  }

  onPreDataTransformationClick(selectedUIStepFunction: UiWorkflowStepFunctionWithRelations) {
    if (selectedUIStepFunction.type === UIWorkflowItemTypeValues.PreDefinedTemplate) {
      this.notificationService.createNotification('warning', 'Warning', 'Data transformation cannot be added to step functions with the type Predefined Template.');
    } else {
      const uiStepFunctionWithRelations = this.uiWorkflowStepFunctions.find(uiStepFunction => uiStepFunction.id === selectedUIStepFunction.id);
      if (uiStepFunctionWithRelations.endpointId
        || uiStepFunctionWithRelations.workflowServiceId
        || uiStepFunctionWithRelations.workflowStepFunctionId
        || uiStepFunctionWithRelations.publishedEventsOnSuccess?.length) {
        this.store.dispatch(new GetPreDataTransformationData({ uiStepFunction: uiStepFunctionWithRelations }));
        this.handlePreDataTransformationModalVisibility();
      } else {
        this.notificationService.createNotification(
          'warning',
          'Warning',
          'UI Step Function must contains one of the following to be able to add Pre Data Transformation; Published Event On Success, Workflow Service, Endpoint or Workflow step function'
        );
      }
    }
  }

  subscribeToPreDataTransformationData() {
    return this.store.select(state => state.uiWorkflowStepFunction?.preDataTransformationData).subscribe((preDTData: PreDataTransformationData) => {
      this.preDataTransformationId = preDTData?.preDataTransformationId;
      this.preSourceJsonSchema = preDTData?.preSourceJsonSchema;
      this.preTargetJsonSchema = preDTData?.preTargetJsonSchema;
    });
  }
  // #endregion PRE DATA TRANSFORMATION

  // #region POST DATA TRANSFORMATION

  handlePostDataTransformationModalVisibility() {
    this.store.dispatch(new TogglePostDataTransformationModalVisibility());
  }

  onPostDataTransformationClick(selectedUIStepFunction: UiWorkflowStepFunctionWithRelations) {
    if (selectedUIStepFunction.type === UIWorkflowItemTypeValues.PreDefinedTemplate) {
      this.notificationService.createNotification('warning', 'Warning', 'Data transformation cannot be added to step functions with the type Predefined Template.');
    } else {
      const uiStepFunctionWithRelations = this.uiWorkflowStepFunctions.find(uiStepFunction => uiStepFunction.id === selectedUIStepFunction.id);
      if (uiStepFunctionWithRelations.endpointId
        || uiStepFunctionWithRelations.workflowServiceId
        || uiStepFunctionWithRelations.workflowStepFunctionId
        || uiStepFunctionWithRelations.publishedEventsOnSuccess?.length) {
        this.store.dispatch(new GetPostDataTransformationData({ uiStepFunction: uiStepFunctionWithRelations }));
        this.handlePostDataTransformationModalVisibility();
      } else {
        this.notificationService.createNotification(
          'warning',
          'Warning',
          'UI Step Function must contains one of the following to be able to add Post Data Transformation; Published Event On Success, Workflow Service, Endpoint or Workflow step function'
        );
      }
    }
  }

  subscribeToPostDataTransformationData() {
    return this.store.select(state => state.uiWorkflowStepFunction?.postDataTransformationData).subscribe((postDTData: PostDataTransformationData) => {
      this.postDataTransformationId = postDTData?.postDataTransformationId;
      this.postSourceJsonSchema = postDTData?.postSourceJsonSchema;
      this.postTargetJsonSchema = postDTData?.postTargetJsonSchema;
    });
  }

  toggleCollapsePanelVisibility(key: string) {
    if (key === StepFunctionPanelToggleButtonKey.ExpandAll) {
      this.collapseToggle = true;
    } else if (key === StepFunctionPanelToggleButtonKey.CollapseAll) {
      this.collapseToggle = false;
    }
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectWithRelations) => {
      this.activeProject = activeProject;
      this.setTitle();
    });
  }

  setTitle() {
    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
      },
      {
        label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_STEP_FUNCTIONS.PAGE_TITLE
      }
    ];
  }

  onSearchTextChange() {
    this.searchResults = this.uiWorkflowStepFunctionsListData.map(uistep => ({
      ...uistep,
      uiStepFunctions: uistep.uiStepFunctions.filter(childItem => toLower(childItem.name).includes(toLower(this.searchText)))
    }));
    this.searchResults.sort(this.sortByEmptyListData);
  }

  sortByEmptyListData(emptyData, data) {
    if (emptyData.uiStepFunctions.length === 0 && data.uiStepFunctions.length > 0) {
      return 1;
    } else if (emptyData.uiStepFunctions.length > 0 && data.uiStepFunctions.length === 0) {
      return -1;
    } else {
      return 0;
    }
  }

  setStepsFlag(key: string) {
    if (key === 'data-transformation') {
      this.dataTransformationFlag = true;
    }
  }

  onShowStepsFlag(value: { flag: boolean; key: string }) {
    if (value.key === 'data-transformation') {
      this.dataTransformationFlag = value.flag;
    }
  }
}
