import * as GuidanceActions from './guidance.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

export const guidanceFeatureKey = 'guidance';

export interface State {
  guidanceKey: string;
  param: string;
  loading: boolean;
}

export const initialState: State = {
  guidanceKey: null,
  param: null,
  loading: false
};

export function reducer(
  state: State = initialState,
  action: GuidanceActions.Actions | AuthenticationActions.Actions
): State {
  switch (action.type) {
    case GuidanceActions.ActionTypes.UpdateGuidanceKey: {
      return {
        ...state,
        guidanceKey: action.payload.guidanceKey,
        loading: false
      };
    }

    case GuidanceActions.ActionTypes.UpdateGuidanceParam: {
      return {
        ...state,
        param: action.payload.param,
        loading: false
      };
    }
    /* TODO: Bunları sıfırlamamız gerekmiyor sanırım. Kontrol edilmeli. */
    case AuthenticationActions.ActionTypes.Logout:
      return initialState;

    case AuthenticationActions.ActionTypes.ChangeActiveProjectSuccessful:
      return initialState;

    default: {
      return state;
    }
  }
}
