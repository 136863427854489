import { KeyValue } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  DataSchemaApi,
  DataSchemaInterface,
  RappiderFunctionApi,
  RappiderFunctionInterface,
  UIDataEventInterface,
  DataMappingApi
} from '@rappider/api-sdk';
import { CodeMirrorMode, CodeMirrorSettings, CodeMirrorTheme } from '@rappider/rappider-components/utils';
import { ApiResult } from '@rappider/models';
import { FormService } from '@rappider/services';
import { DataMapping } from '../../models/data-mapping';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { UIDataUpdateFunctionGeneratedBy, uiDataUpdateFunctionGeneratedByOptions } from '../../models/ui-data-update-function';

@Component({
  selector: 'rappider-ui-data-update-function-create-form',
  templateUrl: './ui-data-update-function-create-form.component.html',
  styleUrls: ['./ui-data-update-function-create-form.component.scss']
})
export class UiDataUpdateFunctionCreateFormComponent implements OnInit, OnDestroy {

  @Input() dataEventSelectable = false;
  @Input() dataEventOptions: KeyValue<string, string>[];
  @Input() dataEventId: string;
  @Input() uiDataStoreId: string;

  sourceDataSchema: DataSchemaInterface;
  targetDataSchema: DataSchemaInterface;
  functions: KeyValue<string, string>[];
  dataEvents: UIDataEventInterface[];

  uiDataUpdateFunctionForm: FormGroup;
  subscriptions: Subscription[];
  generatedByOptions = uiDataUpdateFunctionGeneratedByOptions;
  codemirrorSettings: CodeMirrorSettings = {
    theme: CodeMirrorTheme.Default,
    mode: CodeMirrorMode.Javascript,
    autoCloseBrackets: true
  };
  isCodeVisible = false;
  isDataMappingVisible = true;

  UIDataUpdateFunctionGeneratedBy = UIDataUpdateFunctionGeneratedBy;
  projectId: string;

  constructor(
    private formBuilder: FormBuilder,
    private formService: FormService,
    private store: Store<any>,
    private dataSchemaApi: DataSchemaApi,
    private rappiderFunctionApi: RappiderFunctionApi,
    private dataMappingApi: DataMappingApi
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach((subscription) => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.setValidatorsByGeneratedBy().subscribe(),
      this.subscribeToDataEvents(),
      this.subscribeToDataEventValue().subscribe()
    ];
  }

  buildForm() {
    this.uiDataUpdateFunctionForm = this.formBuilder.group({
      dataEventId: [null, this.dataEventSelectable ? Validators.required : null],
      generatedBy: [UIDataUpdateFunctionGeneratedBy.Rappider, Validators.required],
      dataMappings: [null, Validators.required],
      code: [null]
    });
  }

  subscribeToDataEvents() {
    return this.store.select(state => state.uiDataEvent.data).subscribe((dataEvents: UIDataEventInterface[]) => {
      if (dataEvents?.length) {
        this.dataEvents = dataEvents.filter(dataEvent => dataEvent.uiDataStoreId === this.uiDataStoreId);
        if (!this.dataEventSelectable) {
          const dataEvent = dataEvents.find(item => item.id === this.dataEventId);

          if (dataEvent) {
            this.getDataMappingValues(dataEvent);
          }
        }
      }
    });
  }


  setValidatorsByGeneratedBy() {
    return this.uiDataUpdateFunctionForm.get('generatedBy').valueChanges.pipe(
      map(generatedByValue => {
        const code = this.uiDataUpdateFunctionForm.get('code');
        const dataMappings = this.uiDataUpdateFunctionForm.get('dataMappings');

        if (generatedByValue === UIDataUpdateFunctionGeneratedBy.Rappider) {
          code.setValidators(null);
          dataMappings.setValidators([Validators.required]);
          this.isDataMappingVisible = true;
          this.isCodeVisible = false;
        } else {
          code.setValidators([Validators.required]);
          dataMappings.setValidators(null);
          this.isDataMappingVisible = false;
          this.isCodeVisible = true;
        }
        code.updateValueAndValidity();
        dataMappings.updateValueAndValidity();
      })
    );
  }

  subscribeToDataEventValue() {
    return this.uiDataUpdateFunctionForm.get('dataEventId').valueChanges.pipe(
      map(dataEventId => {
        if (dataEventId) {
          const dataEvent = this.dataEvents.find(item => item.id === dataEventId);
          this.projectId = dataEvent.uiDataStore.projectId;

          if (dataEvent) {
            this.getDataMappingValues(dataEvent);
          }
        }
      })
    );
  }

  getDataMappingValues(dataEvent: UIDataEventInterface) {
    const sourceDataSchemaId = dataEvent.payload.id;
    const targetDataSchemaId = dataEvent.uiDataStore.dataSchemaId;
    /* gets source data schema and its fields (data event payload and its fields) */
    this.dataSchemaApi.getWithDetailsById(sourceDataSchemaId).subscribe((response: ApiResult) => {
      if (response.success) {
        this.sourceDataSchema = response.data;
      }
    });
    /* gets target data schema and its fields (data ui store's schema and its fields) */
    this.dataSchemaApi.getWithDetailsById(targetDataSchemaId).subscribe((response: ApiResult) => {
      if (response.success) {
        this.targetDataSchema = response.data;
      }
    });

    const filter = { where: { projectId: dataEvent.uiDataStore.projectId } };
    /* gets rappider functions which belongs to active project */
    this.rappiderFunctionApi.find(filter).subscribe((rappiderFunctions: RappiderFunctionInterface[]) => {
      if (rappiderFunctions?.length) {
        this.functions = rappiderFunctions.map(rappiderFunction => ({
          key: rappiderFunction.name,
          value: rappiderFunction.id
        }));
      }
    });
  }

  onSubmit() {
    const dataUpdateFunctionFormValue = this.uiDataUpdateFunctionForm.value;
    const type = this.uiDataUpdateFunctionForm.get('generatedBy').value;

    this.formService.checkFormValidation(this.uiDataUpdateFunctionForm);
    if (!this.dataEventSelectable) {
      dataUpdateFunctionFormValue.dataEventId = this.dataEventId;
    }
    if (type === UIDataUpdateFunctionGeneratedBy.User) {
      delete dataUpdateFunctionFormValue.dataMappings;
    } else {
      delete dataUpdateFunctionFormValue.code;

      dataUpdateFunctionFormValue.dataMappings = dataUpdateFunctionFormValue.dataMappings?.map(dataMapping => {
        const dataMappingMappedObject = {
          functionId: dataMapping.functionId,
          sourceTreeFields: dataMapping.sourceFields.map(sourceFieldNodes => sourceFieldNodes.map(sourceField => sourceField.id)),
          targetTreeField: dataMapping.targetField.map(targetFieldNode => targetFieldNode.id)
        };
        return dataMappingMappedObject;
      });
    }
  }

  getSaveButtonVisibilityState() {
    return this.uiDataUpdateFunctionForm.get('generatedBy').value === UIDataUpdateFunctionGeneratedBy.Rappider ? false : true;
  }

  addDataMapping(dataMapping: DataMapping) {
    const dataMappingRequestBody = {
      projectId: this.projectId,
      functionId: dataMapping.functionId,
      dataSchemaFieldIdsForSourceFieldsNodes: dataMapping.sourceFields.map(sourceFieldNodes => sourceFieldNodes.map(sourceField => sourceField.id)),
      dataSchemaFieldIdsForTargetFieldNodes: dataMapping.targetField.map(targetFieldNode => targetFieldNode.id)
    };

    this.dataMappingApi.create(dataMappingRequestBody).subscribe();
  }
}
