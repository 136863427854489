import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { DataSchemaFieldApi, DataSchemaInterface, LoopBackFilter } from '@rappider/api-sdk';
import { catchError, delay, map, mergeMap, retryWhen, takeWhile, tap, withLatestFrom } from 'rxjs/operators';
import { NotificationService } from '@rappider/services';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { DataSchemaTypes } from '../../modules/data-schema/models/data-schema-type.enum';
import { LastProcessedAction } from '@rappider/shared/interfaces';
import { DataSchemaEnumDataControllerService } from 'libs/rappider-sdk/src/lib/services/data-schema-enum-data-controller.service';
import {
  DataSchema,
  DataSchemaControllerService, DataSchemaEnumData,
  DataSchemaField,
  DataSchemaFieldBulkUpdateUiSettingsBodyDto, DataSchemaFieldControllerService, DataSchemaWithRelations,
  EnumDataControllerService,
  NewEnumData,
  UiDataStoreWithRelations
} from '@rappider/rappider-sdk';
import { DataSchemaCategory } from '../../modules/data-schema/models/data-schema-category.enum';

import * as UIDataStoreActions from '../ui-data-store/ui-data-store.actions';
import * as ActiveProjectActions from 'libs/project/src/lib/states/active-project-state/active-project.actions';
import * as DataSchemaActions from './data-schema.actions';
import * as ProjectModelActions from 'libs/project/src/lib/states/project-model-state/project-model.actions';
import { environment } from '@environment';

@Injectable()
export class DataSchemaEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private dataSchemaApi: DataSchemaControllerService,
    private oldDataSchemaFieldApi: DataSchemaFieldApi,
    private dataSchemaFieldApi: DataSchemaFieldControllerService,
    private notificationService: NotificationService,
    private dataSchemaEnumDataControllerService: DataSchemaEnumDataControllerService,
    private enumDataApi: EnumDataControllerService
  ) { }

  getDataSchemas$ = createEffect(() => this.actions$.pipe(
    ofType<DataSchemaActions.GetDataSchemas | ActiveProjectActions.SetActiveProject>(
      DataSchemaActions.ActionTypes.GetDataSchemas,
      ActiveProjectActions.ActionTypes.SetActiveProject
    ),
    withLatestFrom(
      this.store.select(
        state => state.activeProject.data?.id)
    ),
    mergeMap(([action, activeProjectId]) => {
      const filter: LoopBackFilter = {
        where: {
          or: [
            { isPrimitive: true },
            { category: DataSchemaCategory.ComponentConfig },
            { category: DataSchemaCategory.ComponentIOType }
          ],
        },
        include: [
          {
            relation: 'fields',
            scope: {
              order: 'index ASC',
              include: [
                {
                  relation: 'uiDataSelectorEnumData'
                }
              ]
            }
          },
          {
            relation: 'uiDataStore'
          },
          {
            relation: 'enumData'
          }
        ],
      };
      if (activeProjectId) {
        filter.where.or = [
          ...filter.where.or,
          { projectId: activeProjectId }
        ];
      }
      return this.dataSchemaApi.find({ filter }).pipe(
        mergeMap((dataSchemas: DataSchemaWithRelations[]) => [
          new DataSchemaActions.SetDataSchemas({ dataSchemas: dataSchemas }),
          new DataSchemaActions.SetLoadingState({ loading: false })
        ])
      );
    }), catchError(error => {
      this.notificationService.createNotification(
        'error',
        'UI Data Stores',
        'SHARED.COULDNT_LOAD'
      );
      return [
        new DataSchemaActions.ErrorAction({ error: error, key: 'GetDataSchemas', timestamp: Date.now() }),
        new DataSchemaActions.GetDataSchemasFailure()
      ];
    })
  ));


  createUserGeneratedDataSchema$ = createEffect(() => this.actions$.pipe(
    ofType<DataSchemaActions.CreateUserGeneratedDataSchema>(
      DataSchemaActions.ActionTypes.CreateUserGeneratedDataSchema
    ),
    withLatestFrom(
      this.store.select(state => state.activeProject.data?.id)
    ),
    map(([action, activeProjectId]) => {
      const dataSchemaBody = {
        ...action.payload.dataSchema,
        projectId: activeProjectId,
        type: DataSchemaTypes.Model,
        category: DataSchemaCategory.UserGenerated
      };

      return new DataSchemaActions.CreateDataSchema(
        {
          dataSchema: dataSchemaBody,
          navigateToDataSchemaList: action.payload.navigateToDataSchemaList
        }
      );
    })
  ));


  createComponentIOTypeDataSchema$ = createEffect(() => this.actions$.pipe(
    ofType<DataSchemaActions.CreateComponentIOTypeDataSchema>(
      DataSchemaActions.ActionTypes.CreateComponentIOTypeDataSchema
    ),
    map(action => {
      const dataSchemaBody = {
        ...action.payload.dataSchema,
        category: DataSchemaCategory.ComponentIOType,
        type: action.payload.dataSchema?.type || DataSchemaTypes.Model
      };
      return new DataSchemaActions.CreateDataSchema(
        {
          dataSchema: dataSchemaBody,
          navigateToDataSchemaList: action.payload.navigateToDataSchemaList
        }
      );
    })
  ));


  createDataSchema = createEffect(() => this.actions$.pipe(
    ofType<DataSchemaActions.CreateDataSchema>(
      DataSchemaActions.ActionTypes.CreateDataSchema
    ),
    withLatestFrom(
      this.store.select(state => state.dataSchema.data)
    ),
    mergeMap(([action, dataSchemas]) => {
      const createdDataSchema = action.payload.dataSchema;
      const isDataSchemaNameDuplicated = dataSchemas.some(
        item => item.name === createdDataSchema.name &&
          item.category === DataSchemaCategory.UserGenerated
      );

      if (!isDataSchemaNameDuplicated) {
        return this.dataSchemaApi.create({ body: createdDataSchema }).pipe(
          map((dataSchema: DataSchemaWithRelations) => {
            this.notificationService.createNotification(
              'success',
              createdDataSchema.name,
              'SHARED.SUCCESSFULLY_CREATED'
            );
            return new DataSchemaActions.CreateDataSchemaSuccessful(
              {
                createdDataSchema: dataSchema,
                navigateToDataSchemaList: action.payload.navigateToDataSchemaList
              }
            );
          }), catchError(error => {
            this.notificationService.createNotification(
              'error',
              createdDataSchema.name,
              'SHARED.COULDNT_CREATED'
            );
            return [new DataSchemaActions.ErrorAction({ error: error, key: 'CreateDataSchema', timestamp: Date.now() })];
          })
        );
      } else {
        this.notificationService.createNotification(
          'error',
          'SHARED.ERROR',
          'PROJECT_MODULE.DATA_SCHEMA_CREATE_COMPONENT.DATA_SCHEMA_NAME_ALREADY_EXISTS'
        );
        return [
          new DataSchemaActions.ErrorAction(
            {
              error: 'Data schema name already exists',
              key: 'CreateDataSchema',
              timestamp: Date.now()
            }
          )
        ];
      }
    })
  ));


  createDataSchemaSuccessful$ = createEffect(() => this.actions$.pipe(
    ofType<DataSchemaActions.CreateDataSchemaSuccessful>(
      DataSchemaActions.ActionTypes.CreateDataSchemaSuccessful
    ),
    map(action => {
      const lastProcessedAction: LastProcessedAction = {
        action: DataSchemaActions.ActionTypes.CreateDataSchemaSuccessful,
        success: true,
        timestamp: Date.now(),
        data: action.payload.createdDataSchema,
        message: 'Data Schema Created'
      };

      if (action.payload.navigateToDataSchemaList) {
        return new Navigate({ url: PATH_DEFINITIONS.PROJECTS.DATA_SCHEMA_LIST });
      }
      return new DataSchemaActions.ChangeLastProcessedAction({ lastProcessedAction: lastProcessedAction });
    })
  ));

  createAllDataFields$ = createEffect(() => this.actions$.pipe(
    ofType<DataSchemaActions.CreateAllDataFields>(DataSchemaActions.ActionTypes.CreateAllDataFields),
    mergeMap(action => {
      const params = {
        body: action.payload.dataSchemaFields
      };
      return this.dataSchemaFieldApi.createAll(params).pipe(
        mergeMap((createdDataSchemaFields: DataSchemaField[]) => {
          const actions = [];
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFULLY_CREATED',
            'PROJECT_MODULE.DATA_SCHEMA_MODULE.DATA_SCHEMA_ELEMENT_CREATE_COMPONENT.DATA_SCHEMA_FIELDS_SUCCESSFULLY_CREATED'
          );

          actions.push(new DataSchemaActions.CreateAllDataFieldsSuccessful({
            dataSchemaFields: createdDataSchemaFields,
            parentDataSchemaId: action.payload.parentDataSchemaId
          }));

          return actions;
        }),
        catchError(error => {
          this.notificationService.createNotification(
            'error',
            'Error',
            error?.error?.error?.message
          );
          return [new DataSchemaActions.CreateAllDataFieldsFailure({ error, key: 'CreateAllDataFields', timestamp: Date.now() })];
        })
      );
    })
  ));

  updateAllDataFields$ = createEffect(() => this.actions$.pipe(
    ofType<DataSchemaActions.UpdateAllDataFields>(DataSchemaActions.ActionTypes.UpdateAllDataFields),
    mergeMap(action => {
      const params = {
        body: action.payload.updatedDataSchemaFields
      };
      return this.dataSchemaFieldApi.bulkUpdate(params).pipe(
        mergeMap((updatedDataSchemaFields) => {
          const actions = [];
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFULLY_UPDATED',
            'PROJECT_MODULE.DATA_SCHEMA_MODULE.DATA_SCHEMA_ELEMENT_CREATE_COMPONENT.DATA_SCHEMA_FIELDS_SUCCESSFULLY_UPDATED'
          );

          actions.push(new DataSchemaActions.UpdateAllDataFieldsSuccessful({
            updatedDataSchemaFields: action.payload.updatedDataSchemaFields,
            parentDataSchemaId: action.payload.parentDataSchemaId
          }));

          return actions;
        }),
        catchError(error => {
          this.notificationService.createNotification(
            'error',
            'Error',
            error?.error?.error?.message
          );
          return [new DataSchemaActions.UpdateAllDataFieldsFailure({ error, key: 'UpdateAllDataFields', timestamp: Date.now() })];
        })
      );
    })
  ));

  deleteAllDataFields$ = createEffect(() => this.actions$.pipe(
    ofType<DataSchemaActions.DeleteAllDataFields>(DataSchemaActions.ActionTypes.DeleteAllDataFields),
    mergeMap((action) => {
      const params = {
        dataSchemaFieldIds: action.payload.dataSchemaFieldIds
      };
      return this.dataSchemaFieldApi.bulkDelete(params).pipe(
        mergeMap(() => {
          const actions = [];
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFULLY_DELETED',
            ''
          );

          actions.push(new DataSchemaActions.DeleteAllDataFieldsSuccessful({ dataSchemaFieldIds: action.payload.dataSchemaFieldIds, parentDataSchemaId: action.payload.parentDataSchemaId }));

          return actions;
        }),
        catchError(error => {
          this.notificationService.createNotification(
            'error',
            'Error',
            error?.error?.error?.message
          );
          return [new DataSchemaActions.DeleteAllDataFieldsFailure({ error, key: 'DeleteAllDataFields', timestamp: Date.now() })];
        })
      );
    })
  ));

  getDataSchemaOfCreatedUIDataStore$ = createEffect(() => this.actions$.pipe(
    ofType<UIDataStoreActions.CreateUIDataStoreSuccessful | DataSchemaActions.GetDataSchemaOfCreatedUIDataStore>(
      UIDataStoreActions.ActionTypes.CreateUIDataStoreSuccessful,
      DataSchemaActions.ActionTypes.GetDataSchemaOfCreatedUIDataStore
    ),
    mergeMap((action) => {
      const filter = {
        where: {
          uiDataStoreId: action.payload.uiDataStore.id
        },
        limit: 1
      };
      let currentTryCount = (action.payload as { uiDataStore: UiDataStoreWithRelations; tryCount: number }).tryCount ?? 0;
      return this.dataSchemaApi.find({ filter }).pipe(
        retryWhen(errors =>
          errors.pipe(
            tap(() => currentTryCount++),
            takeWhile(() => currentTryCount <= environment.maxTryCountForRecursiveAPICalls),
            delay(1000)
          )
        ),
        map((payload: DataSchemaWithRelations[]) => {
          if (payload[0]) {
            return new DataSchemaActions.CreateDataSchemaSuccessful({ createdDataSchema: payload[0], navigateToDataSchemaList: false });
          } else if (currentTryCount <= environment.maxTryCountForRecursiveAPICalls) {
            return new DataSchemaActions.GetDataSchemaOfCreatedUIDataStore({ uiDataStore: action.payload.uiDataStore, tryCount: currentTryCount });
          }
        }, catchError(err => [
          new DataSchemaActions.ErrorAction(
            {
              error: 'Couldnot get Created DataSchema Of UI Data Store',
              key: DataSchemaActions.ActionTypes.GetDataSchemaOfCreatedUIDataStore,
              timestamp: Date.now()
            }
          )
        ])
        )
      );
    })
  ));

  updateDataSchema$ = createEffect(() => this.actions$.pipe(
    ofType<DataSchemaActions.UpdateDataSchema>(
      DataSchemaActions.ActionTypes.UpdateDataSchema
    ),
    mergeMap((action) => {
      const updatedDataSchema = action.payload.dataSchema;
      const updatedDataSchemaId = action.payload.id;

      return this.dataSchemaApi.updateById({ id: updatedDataSchemaId, body: updatedDataSchema }).pipe(
        mergeMap((dataSchema: DataSchemaWithRelations) => {
          const actions: Action[] = [
            new DataSchemaActions.UpdateDataSchemaSuccessful({ dataSchema: dataSchema })
          ];

          if (action.payload.navigateToDataSchemaList) {
            actions.push(new Navigate({ url: PATH_DEFINITIONS.PROJECTS.DATA_SCHEMA_LIST }));
          }

          this.notificationService.createNotification(
            'success',
            dataSchema.name,
            'SHARED.SUCCESSFULLY_UPDATED'
          );
          return actions;
        }), catchError(error => {
          this.notificationService.createNotification(
            'error',
            updatedDataSchema.name,
            'SHARED.COULDNT_UPDATED'
          );
          return [new DataSchemaActions.ErrorAction({ key: 'UpdateUIDataStore', error: error, timestamp: Date.now() })];
        })
      );
    })
  ));


  deleteDataSchema$ = createEffect(() => this.actions$.pipe(
    ofType<DataSchemaActions.DeleteDataSchema>(
      DataSchemaActions.ActionTypes.DeleteDataSchema
    ), withLatestFrom(
      this.store.select(state => state.dataSchema.data),
    ),
    mergeMap(([action, dataSchemas]) => {
      const dataSchemaId = action.payload.dataSchemaId;
      const deletedDataSchema = dataSchemas.find((dataSchema: DataSchemaInterface) => dataSchema.id === dataSchemaId);

      return this.dataSchemaApi.deleteById({ id: dataSchemaId }).pipe(
        map(() => {
          this.notificationService.createNotification(
            'success',
            deletedDataSchema.name,
            'SHARED.SUCCESSFULLY_DELETED'
          );

          return new DataSchemaActions.DeleteDataSchemaSuccessful({ dataSchemaId });
        }, catchError(error => {
          this.notificationService.createNotification(
            'error',
            deletedDataSchema.name,
            'SHARED.COULDNT_DELETED'
          );
          return [new DataSchemaActions.ErrorAction({ error: error, key: 'DeleteUIDataStore', timestamp: Date.now() })];
        }))
      );
    })
  ));


  createDataSchemaEnumData = createEffect(() => this.actions$.pipe(
    ofType<DataSchemaActions.CreateDataSchemaEnumData>(
      DataSchemaActions.ActionTypes.CreateDataSchemaEnumData
    ), withLatestFrom(
      this.store.select(state => state.dataSchema.data)
    ),
    mergeMap(([action, dataSchemas]) => {
      const dataSchemaId = action.payload.parentDataSchemaId;
      const existingDataSchema = dataSchemas.find(dataSchema => dataSchema.id === dataSchemaId);

      // if data schema already has enumData return updateDataSchemaEnumData action
      if (existingDataSchema?.enumData?.id) {
        return [
          new DataSchemaActions.UpdateDataSchemaEnumData({
            enumDataId: existingDataSchema.enumData.id,
            updatedEnumData: {
              ...action.payload.enumData,
              id: existingDataSchema.enumData.id
            }
          })
        ];
      } else {
        const postBody = {
          body: action.payload.enumData
        };
        return this.dataSchemaEnumDataControllerService.create(postBody).pipe(
          mergeMap((response: DataSchemaEnumData) => {
            const updatingDataSchema = {
              ...existingDataSchema,
              defaultDataSchemaDataDefinitionId: response.id,
            };
            this.notificationService.createNotification(
              'success',
              existingDataSchema.name,
              'PROJECT_MODULE.DATA_SCHEMA_ENUM_DATA.SUCCESSFULLY_CREATED'
            );
            // set data schema enum data in state and update data schema at DB
            return [
              new DataSchemaActions.CreateDataSchemaEnumDataSuccessful({ dataSchemaId: dataSchemaId, createdEnumData: response }),
              new DataSchemaActions.UpdateDataSchema({
                id: dataSchemaId,
                dataSchema: updatingDataSchema,
                navigateToDataSchemaList: action.payload.navigateToDataSchemaList
              })
            ];
          }), catchError(error => {
            this.notificationService.createNotification(
              'error',
              existingDataSchema.name,
              'PROJECT_MODULE.DATA_SCHEMA_ENUM_DATA.COULDNT_CREATED'
            );
            return [new DataSchemaActions.ErrorAction({ error: error, key: 'CreateDataSchemaEnumData', timestamp: Date.now() })];
          })
        );
      }
    })
  ));


  deleteDataSchemaEnumData = createEffect(() => this.actions$.pipe(
    ofType<DataSchemaActions.DeleteDataSchemaEnumData>(
      DataSchemaActions.ActionTypes.DeleteDataSchemaEnumData
    ), withLatestFrom(
      this.store.select(state => state.dataSchema.data)
    ),
    mergeMap(([action, dataSchemas]) => {
      const postBody = {
        id: action.payload.enumDataId
      };
      const dataSchemaId = action.payload.parentDataSchemaId;
      const existingDataSchema = dataSchemas.find(dataSchema => dataSchema.id === dataSchemaId);
      return this.dataSchemaEnumDataControllerService.deleteById(postBody).pipe(
        map(() => {
          const updatingDataSchema = {
            ...existingDataSchema,
            defaultDataSchemaDataDefinitionId: null
          };
          this.notificationService.createNotification(
            'success',
            existingDataSchema.name,
            'PROJECT_MODULE.DATA_SCHEMA_ENUM_DATA.SUCCESSFULLY_DELETED'
          );
          return new DataSchemaActions.UpdateDataSchema({
            id: dataSchemaId,
            dataSchema: updatingDataSchema,
            navigateToDataSchemaList: action.payload.navigateToDataSchemaList
          });
        }, catchError(error => {
          this.notificationService.createNotification(
            'error',
            existingDataSchema.name,
            'PROJECT_MODULE.DATA_SCHEMA_ENUM_DATA.COULDNT_DELETED'
          );
          return [new DataSchemaActions.ErrorAction({ error: error, key: 'DeleteDataSchemaEnumData', timestamp: Date.now() })];
        }))
      );
    })
  ));


  updateDataSchemaEnumData = createEffect(() => this.actions$.pipe(
    ofType<DataSchemaActions.UpdateDataSchemaEnumData>(
      DataSchemaActions.ActionTypes.UpdateDataSchemaEnumData
    ), withLatestFrom(
      this.store.select(state => state.dataSchema.data)
    ),
    mergeMap(([action, dataSchemas]) => {
      const existingDataSchema = dataSchemas.find(dataSchema => dataSchema.id === action.payload.updatedEnumData.dataSchemaId);
      const postBody = {
        id: action.payload.enumDataId,
        body: {
          data: action.payload.updatedEnumData.data,
        }
      };
      return this.dataSchemaEnumDataControllerService.updateById(postBody).pipe(
        map((response: DataSchemaEnumData) => {
          this.notificationService.createNotification(
            'success',
            existingDataSchema.name,
            'PROJECT_MODULE.DATA_SCHEMA_ENUM_DATA.SUCCESSFULLY_UPDATED'
          );
          // update data schema enum data in state
          return new DataSchemaActions.UpdateDataSchemaEnumDataSuccessful(
            {
              dataSchemaId: existingDataSchema.id,
              updatedEnumData: response
            });
        }, catchError(error => {
          this.notificationService.createNotification(
            'error',
            existingDataSchema.name,
            'PROJECT_MODULE.DATA_SCHEMA_ENUM_DATA.COULDNT_UPDATED'
          );
          return [new DataSchemaActions.ErrorAction({ error: error, key: 'UpdateDataSchemaEnumData', timestamp: Date.now() })];
        }))
      );
    })
  ));


  bulkUpdateDataSchemaFieldUISettings$ = createEffect(() => this.actions$.pipe(
    ofType<DataSchemaActions.BulkUpdateDataSchemaFieldUISettings>(
      DataSchemaActions.ActionTypes.BulkUpdateDataSchemaFieldUISettings
    ),
    mergeMap((action) => {
      const dataSchemaFieldBulkUpdateData: DataSchemaFieldBulkUpdateUiSettingsBodyDto[] = action.payload.dataSchemaFieldBulkUpdateData;

      return this.dataSchemaFieldApi.bulkUpdateUISettings({ body: dataSchemaFieldBulkUpdateData }).pipe(
        map((dataSchemaFields) => new DataSchemaActions.BulkUpdateDataSchemaFieldUISettingsSuccessful({
          dataSchemaFields,
          projectModel: action.payload.projectModel,
          generateCrudPagesAfterUpdate: action.payload.generateCrudPagesAfterUpdate
        })), catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.COULDNT_UPDATED',
            'SHARED.COULDNT_UPDATED'
          );
          return [
            new DataSchemaActions.BulkUpdateDataSchemaFieldUISettingsFailure({ error: error, key: 'GetDataSchemaWithDetails', timestamp: Date.now() }),
          ];
        })
      );
    })
  ));


  bulkUpdateDataSchemaFieldUISettingsSuccessful$ = createEffect(() => this.actions$.pipe(
    ofType<DataSchemaActions.BulkUpdateDataSchemaFieldUISettingsSuccessful>(
      DataSchemaActions.ActionTypes.BulkUpdateDataSchemaFieldUISettingsSuccessful
    ),
    mergeMap((action) => {
      this.notificationService.createNotification(
        'success',
        'Success',
        'Data Schema Fields Successfully Saved'
      );

      if (action.payload.generateCrudPagesAfterUpdate) {
        return [new ProjectModelActions.CreateCrudPagesForProjectModel({ projectModel: action.payload.projectModel })];
      } else {
        return [new ProjectModelActions.CreateCrudPagesForProjectModelFailure({ error: 'The command required to generate crud pages could not be found in payload', key: 'CreateCrudPagesForProjectModelFailure', timestamp: Date.now() })];
      }
    })
  ));


  createUIDataSelectorEnumData$ = createEffect(() => this.actions$.pipe(
    ofType<DataSchemaActions.CreateUIDataSelectorEnumData>(
      DataSchemaActions.ActionTypes.CreateUIDataSelectorEnumData
    ),
    mergeMap((action) => {
      /*
      examplePayload: {
        uiDataSelectorEnumData: {
          name: enumDataName,
          dataSchemaFieldId: 'string'
          description?: this is a description,
          options?: [
            {
              key: 'test',
              value: '123'
            }
          ]
        }
      };
      */
      const body: NewEnumData = action.payload.uiDataSelectorEnumData;

      return this.enumDataApi.create({ body }).pipe(
        map((uiDataSelectorEnumData) => new DataSchemaActions.CreateUIDataSelectorEnumDataSuccessful(
          { uiDataSelectorEnumData })), catchError(error => [
            new DataSchemaActions.CreateUIDataSelectorEnumDataFailure({
              error: error,
              key: 'CreateUIDataSelectorEnumData',
              timestamp: Date.now()
            })
          ])
      );
    })
  ));


  updateUIDataSelectorEnumData$ = createEffect(() => this.actions$.pipe(
    ofType<DataSchemaActions.UpdateUIDataSelectorEnumData>(
      DataSchemaActions.ActionTypes.UpdateUIDataSelectorEnumData
    ),
    mergeMap((action) => {
      /*
      examplePayload: {
        enumDataId: 123123,
        uiDataSelectorEnumData: {
          description?: this is a description,
          name?: enumDataName,
          options?: [
            {
              key: 'test',
              value: '123'
            }
          ]
        }
      };
      */
      const body = action.payload.uiDataSelectorEnumData;

      return this.enumDataApi.updateById({ id: action.payload.enumDataId, body }).pipe(
        map((uiDataSelectorEnumData) => new DataSchemaActions.UpdateUIDataSelectorEnumDataSuccessful({ uiDataSelectorEnumData })), catchError(error => [
          new DataSchemaActions.UpdateUIDataSelectorEnumDataFailure({
            error: error,
            key: 'UpdateUIDataSelectorEnumData',
            timestamp: Date.now()
          })
        ])
      );
    })
  ));


  removeDataSchemaFieldByProjectModelField$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectModelActions.DeleteProjectModelField>(
      ProjectModelActions.ActionTypes.DeleteProjectModelField
    ),
    map((action) => new DataSchemaActions.RemoveDataSchemaFieldByProjectModelField({ projectModelField: action.payload.deletedProjectModelField }))
  ));


  getDataSchemaAccordingToId$ = createEffect(() => this.actions$.pipe(
    ofType<DataSchemaActions.GetDataSchemaById>(
      DataSchemaActions.ActionTypes.GetDataSchemaById,
    ),
    mergeMap((action) => {
      const filter: LoopBackFilter = {
        include: [
          {
            relation: 'fields',
            scope: {
              order: 'index ASC',
              include: [
                {
                  relation: 'uiDataSelectorEnumData'
                }
              ]
            }
          },
          {
            relation: 'uiDataStore'
          },
          {
            relation: 'enumData'
          }
        ],
      };
      return this.dataSchemaApi.findById({ id: action.payload.id, filter }).pipe(
        map((dataSchema: DataSchema) =>
          new DataSchemaActions.GetDataSchemaByIdSuccessful({ dataSchema })
        )
      );
    }), catchError(error => {
      this.notificationService.createNotification(
        'error',
        'Error',
        'Data schema could not load'
      );
      return [
        new DataSchemaActions.GetDataSchemaByIdFailure({ error: error, key: 'GetDataSchemaByIdFailure', timestamp: Date.now() })
      ];
    })
  ));

  /**
   * This effect triggers when project model created with flag generateUIDataStore set to true
   *
   * @memberof DataSchemaEffects
   */

  getDataSchemaAccordingAutoGeneratedUIDataStoreId$ = createEffect(() => this.actions$.pipe(
    ofType<DataSchemaActions.GetDataSchemasByUIDataStoreId>(
      DataSchemaActions.ActionTypes.GetDataSchemasByUIDataStoreId,
    ),
    mergeMap((action) => {
      const params = {
        filter: {
          where: {
            uiDataStoreId: action.payload.uiDataStoreId
          },
          include: [
            {
              relation: 'fields',
              scope: {
                order: 'index ASC',
                include: [
                  {
                    relation: 'uiDataSelectorEnumData'
                  }
                ]
              }
            },
            {
              relation: 'uiDataStore'
            },
            {
              relation: 'enumData'
            }
          ],
        }
      };
      return this.dataSchemaApi.find(params).pipe(
        mergeMap((dataSchemas) =>
          [
            new DataSchemaActions.GetDataSchemasByUIDataStoreIdSuccessful({ dataSchemas }),
            new UIDataStoreActions.GetUIDataStoreFieldsLoadingByModelId({ projectModelId: action.payload.loadingModelId })
          ]
        )
      );
    }), catchError(error => {
      this.notificationService.createNotification(
        'error',
        'Error',
        'Data schema could not load'
      );
      return [
        new DataSchemaActions.GetDataSchemasByUIDataStoreIdFailure({ error: error, key: 'GetDataSchemasByUIDataStoreIdFailure', timestamp: Date.now() })
      ];
    })
  ));

}
