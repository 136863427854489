import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ProjectInterface } from '@rappider/api-sdk';
import { AVAILABLE_DATA_FIELD_LIST_CONFIG } from '@rappider/shared/configs';
import { CreateMessageTemplate } from '../../states/message-template-state/message-template.actions';
import { NotificationService, MentionTextConverterService, MessageTemplateService } from '@rappider/services';
import { BreadcrumbOption, CrudFormConfig, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { defaultToolbarTitleHeadingSize, PATH_DEFINITIONS } from '@rappider/shared/definitions';

@Component({
  selector: 'rappider-message-template',
  templateUrl: './message-template.component.html',
  styleUrls: ['./message-template.component.scss']
})
export class MessageTemplateComponent implements OnInit, OnDestroy {

  mainTitle: HeadingComponentConfig;
  title: string | string[] | BreadcrumbOption[];
  subscriptions: Subscription[] = [];
  AVAILABLE_DATA_FIELD_LIST_CONFIG = AVAILABLE_DATA_FIELD_LIST_CONFIG;
  mentionAvailableDataFields: any;
  availableDataFields: Array<object>;
  CREATE_MESSAGE_TEMPLATE_CONFIG: CrudFormConfig = <CrudFormConfig>{};
  activeEnvironment: string;
  messageTemplateFormData = {
    key: null,
    type: null,
    template: null,
    subject: null,
    environmentKey: null
  };
  environments: string[];

  displayToolbar = false;
  displayToolbarBackButton = false;
  submitButtonLoading = false;

  constructor(
    private store: Store<any>,
    private notificationService: NotificationService,
    private mentionTextConverterService: MentionTextConverterService,
    private messageTemplateService: MessageTemplateService
  ) { }

  ngOnInit() {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(subscription => subscription.unsubscribe());
  }

  /**
  *  Subscribing all Data
  *
  * @memberof PageCreateComponent
  */
  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToLoading(),
      this.subscribeToActiveEnvironment(),
      this.subscribeToEnvironments()
    ];
  }

  subscribeToLoading() {
    return this.store.select(state => state.messageTemplate.loading).subscribe(loading => {
      this.submitButtonLoading = loading;
    });
  }

  subscribeToEnvironments() {
    return this.store.select(state => state.environmentVariable.environments).subscribe(environments => {
      this.environments = environments;
      this.setFormConfig();
    });
  }

  subscribeToActiveEnvironment() {
    return this.store.select(state => state.environmentVariable.activeEnvironment).subscribe(activeEnvironment => {
      this.activeEnvironment = activeEnvironment;
      this.messageTemplateFormData = {
        ...this.messageTemplateFormData,
        environmentKey: this.activeEnvironment
      };
    });
  }

  /**
   * Subscribe active project
   *
   * @returns
   * @memberof ProjectEnvironmentVariablesComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectInterface) => {
      if (activeProject) {
        this.mainTitle = {
          content: 'SHARED.NEW_TEMPLATE',
          type: defaultToolbarTitleHeadingSize
        };
        this.title = [
          {
            label: activeProject.name,
            redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${activeProject?.id}`
          },
          {
            label: 'SHARED.TEMPLATES',
            redirectUrl: PATH_DEFINITIONS.PROJECTS.PROJECT_MESSAGE_TEMPLATE_LIST
          },
          {
            label: 'SHARED.NEW_TEMPLATE'
          }];
      }
    });
  }

  /**
   * Set form config
   *
   * @memberof MessageTemplateComponent
   */
  setFormConfig() {
    if (this.environments) {
      if (this.mentionAvailableDataFields) {
        this.CREATE_MESSAGE_TEMPLATE_CONFIG = this.messageTemplateService.
          setMessageTemplateFormConfig(this.environments, this.mentionAvailableDataFields);
      } else {
        this.CREATE_MESSAGE_TEMPLATE_CONFIG = this.messageTemplateService.
          setMessageTemplateFormConfig(this.environments);
      }
    }
    this.CREATE_MESSAGE_TEMPLATE_CONFIG = { ...this.CREATE_MESSAGE_TEMPLATE_CONFIG };
  }

  /**
   * Create or update message template
   *
   * @param {*} data
   * @memberof MessageTemplateComponent
   */
  createMessageTemplate(data: any) {
    const convertedTemplate = this.mentionTextConverterService.convertMentionToTemplate(data.template);
    this.store.dispatch(new CreateMessageTemplate({ messageTemplate: { ...data, template: convertedTemplate } }));
  }

  onSubmitButtonClick(formData: any) {
    if (
      !formData.environmentKey
      || !formData.key
      || !formData.subject
      || !formData.template
      || !formData.type
    ) {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'SHARED.ALL_FIELDS_REQUIRED'
      );
    }
  }

}
