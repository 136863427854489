import { Injectable } from '@angular/core';
import { BrowserStorageManagementService } from '@rappider/services';

const AUTHENTICATION_TOKEN_LOCALSTORAGE_KEY = 'Authorization';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    private browserStorageManagementService: BrowserStorageManagementService
  ) { }

  getAuthenticationToken(): string {
    return this.browserStorageManagementService.getLocalStorageItem(AUTHENTICATION_TOKEN_LOCALSTORAGE_KEY);
  }

  setAuthenticationToken(authenticationToken: string) {
    return this.browserStorageManagementService.setLocalStorageItem(AUTHENTICATION_TOKEN_LOCALSTORAGE_KEY, authenticationToken);
  }

  clearAuthenticationToken() {
    return this.browserStorageManagementService.removeLocalStorageItem(AUTHENTICATION_TOKEN_LOCALSTORAGE_KEY);
  }

}
