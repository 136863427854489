<div class="card-area">
	<div *ngIf="image">
		<rappider-image [width]="image?.width"
										[source]="image?.source"
										[placeholder]="image?.placeholder"
										[height]="image?.height"
										[fallback]="image?.fallback"
										[alternateText]="image?.alternateText"
										[borderSettings]="image?.borderSettings"
										[customSizeSettings]="image?.width"
										[shadowSettings]="image?.shadowSettings"
										[marginSettings]="image?.marginSettings"
										[paddingSettings]="image?.paddingSettings"></rappider-image>
	</div>
	<div *ngIf="title"
			 class="title">
		<rappider-heading [content]="title.content"
											[type]="title.type"></rappider-heading>
	</div>
	<div *ngIf="subtitle"
			 class="subtitle">
		<rappider-heading [content]="subtitle.content"
											[type]="subtitle.type"></rappider-heading>
	</div>
	<div *ngFor="let additionalContent of additionalContents"
			 class="additional-content">
		<div *ngIf="additionalContent"
				 class="additional-subtitle-content">
			<rappider-text [content]="additionalContent.content"
										 [textMode]="additionalContent.textMode"
										 [text]="additionalContent.text"
										 [typography]="additionalContent.typography"
										 [colorSettings]="additionalContent.colorSettings"></rappider-text>
		</div>
	</div>
	<div *ngIf="buttons"
			 class="button-area">
		<rappider-button *ngFor="let button of buttons"
										 [transparent]="button.transparent"
										 [colorType]="button.colorType"
										 [shape]="button.shape"
										 [type]="button.type"
										 [text]="button.text"
										 [size]="button.size"
										 [icon]="button.icon"
										 [loading]="button.loading"
										 [block]="button.block"
										 [disabled]="button.disabled"
										 [icon]="button.icon"
										 [formButtonType]="button?.formButtonType"
										 [borderSettings]="button?.borderSettings"
										 [customSizeSettings]="button?.customSizeSettings"
										 [customColorSettings]="button?.customColorSettings"
										 [shadowSettings]="button?.shadowSettings"
										 [paddingSettings]="button?.paddingSettings"
										 [marginSettings]="button?.marginSettings"
										 (click)="onButtonClick(button)"></rappider-button>
	</div>
</div>
