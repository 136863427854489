import { CardDataMapConfig } from '@rappider/rappider-components';
import { ActionBehavior, CrudTableViewConfig, CrudViewColumnType, HeadingType } from '@rappider/rappider-components/utils';
import { CustomFunctionDefinitionWithRelations } from '@rappider/rappider-sdk';
import * as moment from 'moment';

export const CUSTOM_FUNCTION_LIST_CONFIG: CrudTableViewConfig = {
  searchable: true,
  defaultSearchField: 'functionName',
  sortable: true,
  columns:
    [
      {
        title: 'Service Name',
        type: CrudViewColumnType.Text,
        fieldName: 'serviceName',
        visible: true
      },
      {
        title: 'Function Name',
        type: CrudViewColumnType.Text,
        fieldName: 'functionName',
        visible: true
      },
      {
        title: 'Programming Language',
        type: CrudViewColumnType.Text,
        fieldName: 'programmingLanguage',
        visible: true
      },
      {
        title: 'Created By',
        type: CrudViewColumnType.Text,
        fieldName: 'createdBy',
        visible: true
      }
    ],
  orderable: false,
  selectable: true,
  listActions: [],
  itemActions: []
};

export const LABEL_CARDS_LIST_CONFIG: CardDataMapConfig = {
  titleFieldName: 'functionName',
  cardItemClickBehavior: ActionBehavior.Emit,
  getSubTitlesFunction: (customFunction: CustomFunctionDefinitionWithRelations) => {
    const labelSubtitles = [];
    if (customFunction.serviceName) {
      labelSubtitles.push({ content: customFunction.serviceName, type: HeadingType.H5 });
    }
    if (customFunction.createdDate) {
      labelSubtitles.push(
        { content: `Created on ${moment(customFunction.createdDate).format('MMM D, YY')}`, type: HeadingType.H6 });
    }
    if (customFunction.createdBy) {
      labelSubtitles.push({ content: customFunction.createdBy, type: HeadingType.H6 });
    }
    return labelSubtitles;
  }
};
