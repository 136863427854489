import { Validators } from '@angular/forms';
import { AlertTypes, CrudFormAlertItem, CrudFormConfig, CrudFormSelectItem, CrudViewFormItemType } from '@rappider/rappider-components/utils';
import { PROJECT_MODEL_RELATION_TYPE_OPTIONS } from '@rappider/shared/configs';
import { CAMEL_CASE_REGEX } from '@rappider/shared/definitions';

export const PROJECT_MODEL_RELATION_UPDATE_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormAlertItem>{
      index: 0,
      fieldName: 'infoText',
      type: CrudViewFormItemType.Alert,
      alert: {
        type: AlertTypes.Info,
        showIcon: false,
        closeable: false,
        description: 'Due to dependent processes, you are limited to modifying only the name of this relation. To Alter any other details, please remove the existing relation and re-establish it with the updated information.'
      },
      showColonSign: false
    },
    {
      index: 1,
      title: 'PROJECT_MODULE.PROJECT_MODEL_RELATION_LIST_COMPONENT.RELATION_NAME',
      fieldName: 'name',
      type: CrudViewFormItemType.TextBox,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          type: Validators.pattern(CAMEL_CASE_REGEX),
          errorKey: 'pattern',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.MUST_START_WITH_LOWERCASE_LETTER'
        }
      ]
    },
    <CrudFormSelectItem>{
      index: 2,
      title: 'PROJECT_MODULE.PROJECT_MODEL_RELATION_LIST_COMPONENT.TYPE',
      fieldName: 'type',
      type: CrudViewFormItemType.Select,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ],
      options: PROJECT_MODEL_RELATION_TYPE_OPTIONS,
      disabled: true
    },
    <CrudFormSelectItem>{
      index: 3,
      title: 'Source Model',
      fieldName: 'sourceModelId',
      type: CrudViewFormItemType.Select,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ],
      disabled: true
    },
    <CrudFormSelectItem>
    {
      index: 4,
      title: 'Source Model Field',
      fieldName: 'keyFromId',
      type: CrudViewFormItemType.Select,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ],
      disabled: true
    },
    <CrudFormSelectItem>{
      index: 5,
      title: 'PROJECT_MODULE.PROJECT_MODEL_RELATION_LIST_COMPONENT.TARGET_MODEL',
      fieldName: 'targetModelId',
      type: CrudViewFormItemType.Select,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ],
      disabled: true
    },
    <CrudFormSelectItem>
    {
      index: 6,
      title: 'Target Model Field',
      fieldName: 'keyToId',
      type: CrudViewFormItemType.Select,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ],
      disabled: true
    }
  ]
};
