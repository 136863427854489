/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { WorkflowStepFunction } from '../models/workflow-step-function';
import { WorkflowStepFunctionCreateDto } from '../models/workflow-step-function-create-dto';
import { WorkflowStepFunctionCreateResponseDto } from '../models/workflow-step-function-create-response-dto';
import { WorkflowStepFunctionUpdateDtoPartial } from '../models/workflow-step-function-update-dto-partial';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class WorkflowStepFunctionControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation workflowStepFunctionControllerCount
   */
  static readonly WorkflowStepFunctionControllerCountPath = '/workflow-step-functions/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params?: {
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, WorkflowStepFunctionControllerService.WorkflowStepFunctionControllerCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params?: {
    where?: any;
  }): Observable<LoopbackCount> {

    return this.count$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation workflowStepFunctionControllerFindServices
   */
  static readonly WorkflowStepFunctionControllerFindServicesPath = '/workflow-step-functions/services';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findServices()` instead.
   *
   * This method doesn't expect any request body.
   */
  findServices$Response(params?: {
  }): Observable<StrictHttpResponse<Array<WorkflowStepFunction>>> {

    const rb = new RequestBuilder(this.rootUrl, WorkflowStepFunctionControllerService.WorkflowStepFunctionControllerFindServicesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WorkflowStepFunction>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findServices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findServices(params?: {
  }): Observable<Array<WorkflowStepFunction>> {

    return this.findServices$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WorkflowStepFunction>>) => r.body as Array<WorkflowStepFunction>)
    );
  }

  /**
   * Path part for operation workflowStepFunctionControllerFindById
   */
  static readonly WorkflowStepFunctionControllerFindByIdPath = '/workflow-step-functions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<WorkflowStepFunction>> {

    const rb = new RequestBuilder(this.rootUrl, WorkflowStepFunctionControllerService.WorkflowStepFunctionControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorkflowStepFunction>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
    filter?: any;
  }): Observable<WorkflowStepFunction> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<WorkflowStepFunction>) => r.body as WorkflowStepFunction)
    );
  }

  /**
   * Path part for operation workflowStepFunctionControllerDeleteById
   */
  static readonly WorkflowStepFunctionControllerDeleteByIdPath = '/workflow-step-functions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, WorkflowStepFunctionControllerService.WorkflowStepFunctionControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: string;
  }): Observable<any> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation workflowStepFunctionControllerUpdateById
   */
  static readonly WorkflowStepFunctionControllerUpdateByIdPath = '/workflow-step-functions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    body?: WorkflowStepFunctionUpdateDtoPartial
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, WorkflowStepFunctionControllerService.WorkflowStepFunctionControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    body?: WorkflowStepFunctionUpdateDtoPartial
  }): Observable<any> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation workflowStepFunctionControllerFind
   */
  static readonly WorkflowStepFunctionControllerFindPath = '/workflow-step-functions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<WorkflowStepFunction>>> {

    const rb = new RequestBuilder(this.rootUrl, WorkflowStepFunctionControllerService.WorkflowStepFunctionControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WorkflowStepFunction>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<WorkflowStepFunction>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WorkflowStepFunction>>) => r.body as Array<WorkflowStepFunction>)
    );
  }

  /**
   * Path part for operation workflowStepFunctionControllerCreate
   */
  static readonly WorkflowStepFunctionControllerCreatePath = '/workflow-step-functions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    body?: WorkflowStepFunctionCreateDto
  }): Observable<StrictHttpResponse<WorkflowStepFunctionCreateResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, WorkflowStepFunctionControllerService.WorkflowStepFunctionControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorkflowStepFunctionCreateResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    body?: WorkflowStepFunctionCreateDto
  }): Observable<WorkflowStepFunctionCreateResponseDto> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<WorkflowStepFunctionCreateResponseDto>) => r.body as WorkflowStepFunctionCreateResponseDto)
    );
  }

}
