import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringTransformService {

  /* "newYorkCity" => "NewYorkCity" */
  getCapitalizedText(text: string) {
    if (!text) {
      return null;
    }
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  /* "new-york-city" => "New York City" */
  getSeperatedCapitalizedTextFromKebabCaseText(kebabCaseText: string) {
    if (!kebabCaseText) {
      return null;
    }
    return kebabCaseText.replace(/^[a-z]|[-][a-z]/g, (c) => c.toUpperCase()).replace(/-/g, ' ');
  }

  /* "new-york-city" => "NewYorkCity" */
  getTitleCaseFromKebabCaseText(kebabCaseText: string) {
    if (!kebabCaseText) {
      return null;
    }
    return kebabCaseText.replace(/^[a-z]|[-][a-z]/g, (c) => c.toUpperCase()).replace(/-/g, '');
  }

  /* "NewYorkCity" => "New York City" */
  getSeperatedTextFromCapitalizedCombinedText(capitalizedCamelCaseText: string) {
    if (!capitalizedCamelCaseText) {
      return null;
    }
    return capitalizedCamelCaseText.replace(/[A-Z]/g, (c) => (' ' + c)).trim();
  }

  /* "John Doe" => "J." */
  getFirstLetterWithDot(word: string) {
    if (!word) {
      return null;
    }
    return word[0].toUpperCase() + '.';
  }

  /* "NewYorkCity" => "new-york-city" */
  getKebabCaseTextFromCapitalizedCamelCaseText(capitalizedCamelCaseText: string) {
    if (!capitalizedCamelCaseText) {
      return null;
    }
    return capitalizedCamelCaseText.replace(/\B[A-Z]/g, (c) => ('-' + c)).toLowerCase();
  }

  /* New York City => new-york-city */
  getKebabCaseFromText(text: string) {
    return text.replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(/\s+/g, '-')
      .toLowerCase();
  }

  /* "jetapo" => "Jetapo" */
  /* "jetapo-test" => "Jetapo-test" */
  capitalizeFirstLetter(text: string): string | null {
    if (!text) {
      return null;
    }
    return text.replace(/^[a-z]/g, (c) => c.toUpperCase());
  }

  /* "Jetapo" => "jetapo" */
  /* "Jetapo-test" => "jetapo-test" */
  lowerFirstLetter(text: string): string | null {
    if (!text) {
      return null;
    }
    return text.replace(/^[A-Z]/g, (c) => c.toLowerCase());
  }

}
