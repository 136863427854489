/* source folder path of the html files */
const guidanceHTMLFolderPath = '/docs/guideline/html';

export const GuidanceDefinitions = [
  {
    key: '/project/list',
    guidanceFiles: [
      {
        path: `${guidanceHTMLFolderPath}/step_1.html`
      }
    ]
  },
  {
    key: '/project/create',
    guidanceFiles: [
      {
        path: `${guidanceHTMLFolderPath}/step_1.html`
      }
    ]
  },
  {
    key: '/projects/project-settings',
    guidanceFiles: [
      {
        path: `${guidanceHTMLFolderPath}/step_7.html`
      }
    ]
  },
  {
    key: '/projects/environment-variables',
    guidanceFiles: [
      {
        path: `${guidanceHTMLFolderPath}/step_8.html`
      }
    ]
  },
  {
    key: '/projects/message-template',
    guidanceFiles: [
      {
        path: `${guidanceHTMLFolderPath}/step_9.html`
      }
    ]
  },
  {
    key: '/projects/message-template-list',
    guidanceFiles: [
      {
        path: `${guidanceHTMLFolderPath}/step_9.html`
      }
    ]
  },
  {
    key: '/projects/script-list',
    guidanceFiles: [
      {
        path: `${guidanceHTMLFolderPath}/step_11.html`
      }
    ]
  },
  {
    key: '/projects/add-project-script',
    guidanceFiles: [
      {
        path: `${guidanceHTMLFolderPath}/step_11.html`
      }
    ]
  },
  {
    key: '/projects/edit-project-script',
    guidanceFiles: [
      {
        path: `${guidanceHTMLFolderPath}/step_11.html`
      }
    ]
  },
  {
    key: '/projects/user-invitations',
    guidanceFiles: [
      {
        path: `${guidanceHTMLFolderPath}/step_14.html`
      }
    ]
  },
  /* TODO: data schema field - ui dat aselector secilen component icin uygun bir aciklama lazim */
  {
    key: 'dataSchemaFieldEdit',
    guidanceFiles: [
      {
        path: `${guidanceHTMLFolderPath}/step_14.html`
      }
    ]
  }
];
