export const VERSION_TYPES = {
  PATCH: {
    key: 'Patch',
    value: 'patch'
  },
  MINOR: {
    key: 'Minor',
    value: 'minor'
  },
  MAJOR: {
    key: 'Major',
    value: 'major'
  }
};

export const APP_TYPES = {
  WEB: {
    key: 'Web',
    value: 'web'
  },
  MOBILE: {
    key: 'Mobile',
    value: 'mobile'
  }
};

export const FRONTEND_FRAMEWORK_TYPES = {
  ANGULAR: {
    key: 'Typescript, Angular',
    value: 'angular'
  },
  REACT: {
    key: 'Typescript, React',
    value: 'react'
  },
  VUE: {
    key: 'Vue',
    value: 'vue'
  },
  FLUTTER: {
    key: 'Flutter',
    value: 'flutter'
  },
  SWIFT: {
    key: 'Swift',
    value: 'swift'
  },
  ANDROID: {
    key: 'Android',
    value: 'android'
  },
  REACT_NATIVE: {
    key: 'React Native',
    value: 'react-native'
  }
};

export const BACKEND_FRAMEWORK_TYPES = {
  LOOPBACK4: {
    key: 'Typescript, NodeJS, Loopback',
    value: 'loopback4'
  },
  SQLALCHEMY: {
    key: 'Python, SQLAlchemy',
    value: 'sqlalchemy'
  },
};

export const PROJECT_EXPORT_STATUSES = [
  { key: 'COMPLETED', icon: 'fas fa-check color-green' },
  { key: 'PROCESSING', icon: 'fas fa-spinner fa-spin' },
  { key: 'FAILED', icon: 'fas fa-times color-red' },
];

export const ZERO_VERSION_NUMBER = '0.0.0';

export const WEB_FRAMEWORKS = [
  { key: FRONTEND_FRAMEWORK_TYPES.ANGULAR.key, value: FRONTEND_FRAMEWORK_TYPES.ANGULAR.value },
  { key: FRONTEND_FRAMEWORK_TYPES.REACT.key, value: FRONTEND_FRAMEWORK_TYPES.REACT.value },
  { key: FRONTEND_FRAMEWORK_TYPES.VUE.key, value: FRONTEND_FRAMEWORK_TYPES.VUE.value }
];

export const MOBILE_FRAMEWORKS = [
  { key: FRONTEND_FRAMEWORK_TYPES.FLUTTER.key, value: FRONTEND_FRAMEWORK_TYPES.FLUTTER.key },
  { key: FRONTEND_FRAMEWORK_TYPES.SWIFT.key, value: FRONTEND_FRAMEWORK_TYPES.SWIFT.key },
  { key: FRONTEND_FRAMEWORK_TYPES.ANDROID.key, value: FRONTEND_FRAMEWORK_TYPES.ANDROID.key },
  { key: FRONTEND_FRAMEWORK_TYPES.REACT_NATIVE.key, value: FRONTEND_FRAMEWORK_TYPES.REACT_NATIVE.key }
];
