import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CODEMIRROR_JSON_SETTINGS } from '@rappider/shared/definitions';
import { RappiderCustomFunction } from '../../../shared/interfaces/data-mapping.interface';

@Component({
  selector: 'rappider-custom-function',
  templateUrl: './custom-function.component.html',
  styleUrls: ['./custom-function.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => CustomFunctionComponent),
      multi: true
    }
  ]
})
export class CustomFunctionComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input() sourceFields: string[];

  /**
   * source field parameters is helded because of display purposes.functionX(parameter2,parameter2<sourceFieldParameters>)
   *
   * @type {string}
   * @memberof CustomFunctionComponent
   */
  sourceFieldParameters: string;

  _value: RappiderCustomFunction;

  CODEMIRROR_JSON_SETTINGS = CODEMIRROR_JSON_SETTINGS;

  get value() {
    return this._value;
  }

  set value(value: RappiderCustomFunction) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
  }

  constructor() { }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: RappiderCustomFunction) {
    this._value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    this.setSourceFieldNamesForFunctionDisplay();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sourceFields) {
      this.setSourceFieldNamesForFunctionDisplay();
    }
  }

  /**
   * Sets function names to display function( parameter1,parameter2,parameter3)
   *
   * @memberof RappiderFunctionComponent
   */
  setSourceFieldNamesForFunctionDisplay() {
    /* this mapping iterates through sourceFieldsArray, returns parameter name then converts that array to string */
    this.sourceFieldParameters = this.sourceFields?.map(sourceField => this.getSourceFieldNameFromSourceField(sourceField)).toString();
  }

  /**
   * We hold source field names like variable1.variable2.variable3 but the source field name is variable3.
   * This fn returns variable 3 from variable1.variable2.variable3.
   *
   * @param {string} sourceField
   * @memberof RappiderFunctionComponent
   */
  getSourceFieldNameFromSourceField(sourceField: string) {
    const sourceFieldSplittedArray = sourceField.split('.');
    const sourceFieldName = sourceFieldSplittedArray[sourceFieldSplittedArray.length - 1];

    return sourceFieldName;
  }

}
