export enum ProjectModelRelationCreateOrEditFieldName {
  Name = 'name',
  Type = 'type',
  TargetModelId = 'targetModelId',
  SourceModelId = 'sourceModelId',
  ForeignKey = 'foreignKey',
  ThroughModelId = 'throughModelId',
  KeyToId = 'keyToId',
  KeyFromId = 'keyFromId'
}
