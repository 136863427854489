import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MentionTextConverterService {


  convertMentionToTemplate(mentionBody: string) {
    const content: HTMLElement = document.createElement('p');
    content.innerHTML = mentionBody;

    let html = content.innerHTML;

    const mentions: Element[] = Array.from(
      content.getElementsByClassName('mention')
    );
    for (const mention of mentions) {
      const value = `{{ ${mention.getAttribute('data-value')} }}`;
      html = html.replace(mention.outerHTML, value);
    }
    return html;
  }

  convertMessageTemplateToMention(templateBody: string, fieldNames: string[]) {
    let mentionBody = templateBody;

    const prefix = '{{ ';
    const suffix = ' }}';

    fieldNames.forEach(fieldName => {
      const replaceData = `${prefix}${fieldName}${suffix}`;
      mentionBody = mentionBody.replace(new RegExp(replaceData, 'g'), this.getMentionBodyByFieldName(fieldName));
    });

    return mentionBody;
  }

  getMentionBodyByFieldName(fieldName: string) {
    return `
    <span class="mention" data-index="0" data-denotation-char="##" data-value="${fieldName}">
      <span contenteditable="false">
        <span class="ql-mention-denotation-char">##</span>
        ${fieldName}
      </span>
   </span>`;
  }

}
