/* eslint-disable */
import {
  User,
  Tenant,
  Role,
  PhoneNumber,
  Project,
  ProjectRole,
  Metadata,
} from '../index';

declare var Object: any;
export interface PersonInterface {
  id?: string;
  userId?: any;
  externalId1?: string;
  externalId2?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string;
  isVerified?: boolean;
  isDeleted?: boolean;
  birthDate?: string;
  secondaryEmail?: string;
  gender?: string;
  timeZone?: string;
  notes?: string;
  deletedBy?: string;
  deletedDate?: Date;
  isDefault?: boolean;
  language?: string;
  tenantId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  user?: User;
  tenant?: Tenant;
  authorities?: Role[];
  phoneNumbers?: PhoneNumber[];
  projects?: Project[];
  roles?: ProjectRole[];
  metadata?: Metadata[];
}

export class Person implements PersonInterface {
  'id'?: string;
  'userId': any;
  'externalId1': string;
  'externalId2': string;
  'firstName': string;
  'middleName': string;
  'lastName': string;
  'email': string;
  'isVerified': boolean;
  'isDeleted': boolean;
  'birthDate': string;
  'secondaryEmail': string;
  'gender': string;
  'timeZone': string;
  'notes': string;
  'deletedBy': string;
  'deletedDate': Date;
  'isDefault': boolean;
  'language': string;
  'tenantId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  user: User;
  tenant: Tenant;
  authorities: Role[];
  phoneNumbers: PhoneNumber[];
  projects: Project[];
  roles: ProjectRole[];
  metadata: Metadata[];
  constructor(data?: PersonInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Person`.
   */
  public static getModelName() {
    return 'Person';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Person for dynamic purposes.
   **/
  public static factory(data: PersonInterface): Person {
    return new Person(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Person',
      plural: 'People',
      path: 'people',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        userId: {
          name: 'userId',
          type: 'any',
        },
        externalId1: {
          name: 'externalId1',
          type: 'string',
        },
        externalId2: {
          name: 'externalId2',
          type: 'string',
        },
        firstName: {
          name: 'firstName',
          type: 'string',
        },
        middleName: {
          name: 'middleName',
          type: 'string',
        },
        lastName: {
          name: 'lastName',
          type: 'string',
        },
        email: {
          name: 'email',
          type: 'string',
        },
        isVerified: {
          name: 'isVerified',
          type: 'boolean',
        },
        isDeleted: {
          name: 'isDeleted',
          type: 'boolean',
        },
        birthDate: {
          name: 'birthDate',
          type: 'string',
        },
        secondaryEmail: {
          name: 'secondaryEmail',
          type: 'string',
        },
        gender: {
          name: 'gender',
          type: 'string',
        },
        timeZone: {
          name: 'timeZone',
          type: 'string',
        },
        notes: {
          name: 'notes',
          type: 'string',
        },
        deletedBy: {
          name: 'deletedBy',
          type: 'string',
        },
        deletedDate: {
          name: 'deletedDate',
          type: 'Date',
        },
        isDefault: {
          name: 'isDefault',
          type: 'boolean',
        },
        language: {
          name: 'language',
          type: 'string',
        },
        tenantId: {
          name: 'tenantId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
      },
      relations: {
        user: {
          name: 'user',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
          keyFrom: 'userId',
          keyTo: 'id',
        },
        tenant: {
          name: 'tenant',
          type: 'Tenant',
          model: 'Tenant',
          relationType: 'belongsTo',
          keyFrom: 'tenantId',
          keyTo: 'id',
        },
        authorities: {
          name: 'authorities',
          type: 'Role[]',
          model: 'Role',
          relationType: 'hasMany',
          modelThrough: 'RoleMapping',
          keyThrough: 'roleId',
          keyFrom: 'id',
          keyTo: 'principalId',
        },
        phoneNumbers: {
          name: 'phoneNumbers',
          type: 'PhoneNumber[]',
          model: 'PhoneNumber',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'personId',
        },
        projects: {
          name: 'projects',
          type: 'Project[]',
          model: 'Project',
          relationType: 'hasMany',
          modelThrough: 'PersonProject',
          keyThrough: 'projectId',
          keyFrom: 'id',
          keyTo: 'personId',
        },
        roles: {
          name: 'roles',
          type: 'ProjectRole[]',
          model: 'ProjectRole',
          relationType: 'hasMany',
          modelThrough: 'PersonProjectRole',
          keyThrough: 'projectRoleId',
          keyFrom: 'id',
          keyTo: 'personId',
        },
        metadata: {
          name: 'metadata',
          type: 'Metadata[]',
          model: 'Metadata',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'personId',
        },
      },
    };
  }
}
