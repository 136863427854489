<rappider-page-wrapper [titlebarVisibility]="titlebarVisibility"
                       [titleToolbarConfig]="titleToolbarConfig">
  <rappider-spin [spinning]="!activeProject">
    <section>
      <rappider-code-editor-input-form class="project-css-style-customization-code-editor"
                                       [codemirrorTitle]="activeProject?.name"
                                       [codemirrorSettings]="CSS_CODEMIRROR_SETTINGS"
                                       [button]="buttonConfig"
                                       [codemirrorContent]="activeProject?.cssStyle"
                                       (buttonClick)="onEditProjectCssStyle($event)"></rappider-code-editor-input-form>
    </section>
  </rappider-spin>
</rappider-page-wrapper>