import { Injectable } from '@angular/core';
import { CustomFunctionDefinition, CustomFunctionDefinitionWithRelations, DataSchemaControllerService, DataTransformationControllerService, GetJsonSchemaDto, ProjectModelEndpointControllerService, ProjectModelEndpointWithRelations, UiDataEventWithRelations, UiWorkflowStepFunctionControllerService, UiWorkflowStepFunctionWithRelations, WorkflowEventWithRelations, WorkflowStepFunctionWithRelations } from '@rappider/rappider-sdk';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '../notification/notification.service';

import * as UIWorkflowStepFunctionActions from 'libs/project/src/lib/states/ui-step-functions/ui-step-function.actions';


@Injectable({
  providedIn: 'root'
})
export class DataTransformationService {

  constructor(
    private dataTransformationApi: DataTransformationControllerService,
    private uiWorkflowStepFunctionApi: UiWorkflowStepFunctionControllerService,
    private projectModelEndpointApi: ProjectModelEndpointControllerService,
    private dataSchemaApi: DataSchemaControllerService,
    private notificationService: NotificationService
  ) { }

  /**
   * gets pre data transformation source json schema
   *
   * @param {string} sourceSchemaId
   * @param {UiWorkflowStepFunctionWithRelations} stepFunction
   * @param {string} [targetSchemaId]
   * @return {*}
   * @memberof DataTransformationService
   */
  getJsonSchemaByDataSchemaId(
    sourceSchemaId: string,
    stepFunction: UiWorkflowStepFunctionWithRelations,
    targetSchemaId?: string
  ) {
    if (stepFunction.uiDataStoreSubscriptions?.length > 0) {
      return this.uiWorkflowStepFunctionApi.getJSONSchema({ id: stepFunction.id });
    } else {
      return this.dataSchemaApi.calculateJSONSchema({ id: sourceSchemaId });
    }
  }

  getPostDataTransformationTargetJsonSchema(
    targetSchemaId: string
  ) {
    if (targetSchemaId) {
      return this.dataSchemaApi.calculateJSONSchema({ id: targetSchemaId });
    }
  }

  getDataTransformationItemConfig(
    sourceFieldTree: Record<string, { type: string;[key: string]: any }>,
    targetFieldTree: Record<string, { type: string;[key: string]: any }>,
    targetJsonSchema: any,
    dataTransformationId?: string
  ) {
    if (sourceFieldTree && targetFieldTree && Object.keys(targetFieldTree)?.length > 1 && Object.keys(sourceFieldTree)?.length > 1) {
      return {
        config: {
          operator: 'map',
          operationField: [],
          operations: [
            {
              function: {
                isCustom: false,
                name: 'assign',
                parameters: [
                  {
                    type: 'field',
                    fieldTree: Object.entries(sourceFieldTree).map(([key, value]: any, index) => {
                      if (index !== 0) {
                        return {
                          name: key,
                          type: value.type
                        };
                      }
                    }).filter(e => e)
                  }
                ]
              },
              targetFieldTree: Object.entries(targetFieldTree).map(([key, value]: any) => ({
                name: key,
                type: value.type
              }))
            }
          ],
          targetJsonSchema: JSON.stringify(targetJsonSchema)
        },
        dataTransformationId: dataTransformationId,
        index: 0
      };
    }
  }

  getPreDataTransformationSourceAndTargetSchemas(
    uiStepFunction: UiWorkflowStepFunctionWithRelations,
    projectModelEndpoints: ProjectModelEndpointWithRelations[],
    workflowStepFunctions: WorkflowStepFunctionWithRelations[],
    workflowEvents: WorkflowEventWithRelations[],
    customFunctions: CustomFunctionDefinitionWithRelations[],
    templateWorkflowStepFunctions: WorkflowStepFunctionWithRelations[],
    templateWorkflowFunctions: CustomFunctionDefinition[]
  ): Observable<any> {
    return forkJoin({
      sourceSchema: this.getJsonSchemaByDataSchemaId(uiStepFunction?.subscribedEvents[0].dataSchemaId, uiStepFunction).pipe(
        catchError(error => [new UIWorkflowStepFunctionActions.ErrorAction({ error: error, key: 'preDT Get Source Schema', timestamp: Date.now() })])
      ),
      targetSchema: this.getPreDataTransformationTargetSchema(
        uiStepFunction,
        projectModelEndpoints,
        workflowStepFunctions,
        workflowEvents,
        customFunctions,
        templateWorkflowStepFunctions,
        templateWorkflowFunctions
      ).pipe(

        catchError(error => [new UIWorkflowStepFunctionActions.ErrorAction({ error: error, key: 'preDT Get Target Schema', timestamp: Date.now() })])
      )
    });
  }

  getPreDataTransformationTargetSchema(
    uiStepFunction: UiWorkflowStepFunctionWithRelations,
    projectModelEndpoints: ProjectModelEndpointWithRelations[],
    workflowStepFunctions: WorkflowStepFunctionWithRelations[],
    workflowEvents: WorkflowEventWithRelations[],
    customFunctions: CustomFunctionDefinitionWithRelations[],
    templateWorkflowStepFunctions: WorkflowStepFunctionWithRelations[],
    templateWorkflowFunctions: CustomFunctionDefinition[]
  ) {
    if (uiStepFunction.endpointId) {
      const projectModelEndpoint = (<ProjectModelEndpointWithRelations[]>projectModelEndpoints).find(endpoint => endpoint.id === uiStepFunction.endpointId);
      if (projectModelEndpoint?.isCustomEndpoint) {
        if (projectModelEndpoint?.requestData) {
          const params = {
            body: <GetJsonSchemaDto>{
              dataToCalculate: projectModelEndpoint.requestData,
              schemaName: `${projectModelEndpoint.name} Request`
            }
          };
          return this.dataTransformationApi.getJSONSchema(params);
        } else {
          return of(new UIWorkflowStepFunctionActions.ErrorAction({
            error: 'ResponseDataNotFound',
            key: 'ResponseDataNotFound',
            timestamp: Date.now()
          }));
        }
      } else {
        return this.projectModelEndpointApi.getJSONSchemaOfRequest({ id: projectModelEndpoint.id });
      }

    } else if (uiStepFunction.workflowStepFunctionId) {
      // Find workflow step function and its custom function.
      let workflowStepFunction: WorkflowStepFunctionWithRelations = {
        ...(<WorkflowStepFunctionWithRelations[]>workflowStepFunctions).find(wsf =>
          wsf.id === uiStepFunction.workflowStepFunctionId)
      };
      workflowStepFunction = {
        ...workflowStepFunction,
        customFunctionDefinition: customFunctions.find(customFunction => customFunction.id === workflowStepFunction.customFunctionDefinitionId)
      };
      const params = {
        body: <GetJsonSchemaDto>{
          dataToCalculate: workflowStepFunction.customFunctionDefinition.requestJSONSample,
          schemaName: `${workflowStepFunction.customFunctionDefinition.name} Request Sample`
        }
      };

      return this.dataTransformationApi.getJSONSchema(params);

    } else if (uiStepFunction.workflowServiceId) {
      // Find workflow step function (service function) and its custom function.
      let workflowStepFunction: WorkflowStepFunctionWithRelations = {
        ...(<WorkflowStepFunctionWithRelations[]>templateWorkflowStepFunctions).find(wsf =>
          wsf.id === uiStepFunction.workflowServiceId)
      };
      workflowStepFunction = {
        ...workflowStepFunction,
        customFunctionDefinition: templateWorkflowFunctions.find(customFunction => customFunction.id === workflowStepFunction.customFunctionDefinitionId)
      };
      const params = {
        body: <GetJsonSchemaDto>{
          dataToCalculate: workflowStepFunction?.customFunctionDefinition?.requestJSONSample,
          schemaName: `${workflowStepFunction?.customFunctionDefinition?.serviceName}.${workflowStepFunction?.customFunctionDefinition?.functionName} Request Sample`
        }
      };

      return this.dataTransformationApi.getJSONSchema(params);

    } else if (uiStepFunction.workflowEventId) {
      const uiStepFunctionWithWorkflowEvent: UiWorkflowStepFunctionWithRelations = {
        ...uiStepFunction,
        workflowEvent: workflowEvents.find(workflowEvent => workflowEvent.id === uiStepFunction.workflowEventId)
      };
      const params = {
        body: <GetJsonSchemaDto>{
          dataToCalculate: uiStepFunctionWithWorkflowEvent.workflowEvent.inputDataSample,
          schemaName: `${uiStepFunctionWithWorkflowEvent.workflowEvent.name} Input Data Sample`
        }
      };
      return this.dataTransformationApi.getJSONSchema(params);

    } else if (uiStepFunction?.publishedEventsOnSuccess?.length) {
      const publishedEventOnSuccess = (<UiDataEventWithRelations>uiStepFunction?.publishedEventsOnSuccess[0]);
      return this.dataSchemaApi.calculateJSONSchema({ id: publishedEventOnSuccess.dataSchemaId });

    } else {
      return null;
    }
  }

  getPostDataTransformationSourceAndTargetSchemas(
    uiStepFunction: UiWorkflowStepFunctionWithRelations,
    projectModelEndpoints: ProjectModelEndpointWithRelations[],
    workflowStepFunctions: WorkflowStepFunctionWithRelations[],
    workflowEvents: WorkflowEventWithRelations[],
    customFunctions: CustomFunctionDefinitionWithRelations[],
    templateWorkflowStepFunctions: WorkflowStepFunctionWithRelations[],
    templateWorkflowFunctions: CustomFunctionDefinition[]
  ): Observable<any> {
    return forkJoin({
      sourceSchema: this.getPostDataTransformationSourceSchema(
        uiStepFunction,
        projectModelEndpoints,
        workflowStepFunctions,
        customFunctions,
        workflowEvents,
        templateWorkflowStepFunctions,
        templateWorkflowFunctions
      ).pipe(
        catchError(error => [new UIWorkflowStepFunctionActions.ErrorAction({ error: error, key: 'getPostDataTransformationSourceSchema Failure', timestamp: Date.now() })])
      ),
      targetSchema: this.getPostDataTransformationTargetJsonSchema(uiStepFunction?.publishedEventsOnSuccess[0].dataSchemaId).pipe(
        catchError(error => {
          this.notificationService.createNotification(
            'error',
            'DATA_TRANSFORMATION_MODULE.DATA_TRANSFORMATION_COMPONENT.TARGET_SCHEMA_COULD_NOT_FOUND',
            'DATA_TRANSFORMATION_MODULE.DATA_TRANSFORMATION_COMPONENT.TARGET_SCHEMA_COULD_NOT_FOUND'
          );
          return [new UIWorkflowStepFunctionActions.ErrorAction({ error: error, key: 'postDataTransformationTargetJsonSchema Failure', timestamp: Date.now() })];
        })
      )
    });
  }

  getPostDataTransformationSourceSchema(
    uiStepFunction: UiWorkflowStepFunctionWithRelations,
    projectModelEndpoints: ProjectModelEndpointWithRelations[],
    workflowStepFunctions: WorkflowStepFunctionWithRelations[],
    customFunctions: CustomFunctionDefinitionWithRelations[],
    workflowEvents: WorkflowEventWithRelations[],
    templateWorkflowStepFunctions: WorkflowStepFunctionWithRelations[],
    templateWorkflowFunctions: CustomFunctionDefinition[]
  ) {
    if (uiStepFunction.endpointId) {
      const projectModelEndpoint = (<ProjectModelEndpointWithRelations[]>projectModelEndpoints).find(endpoint => endpoint.id === uiStepFunction.endpointId);
      if (projectModelEndpoint.isCustomEndpoint) {
        if (projectModelEndpoint.responseData) {
          const params = {
            body: <GetJsonSchemaDto>{
              dataToCalculate: projectModelEndpoint.responseData,
              schemaName: `${projectModelEndpoint.name} Response`
            }
          };
          return this.dataTransformationApi.getJSONSchema(params);
        } else {
          return of(new UIWorkflowStepFunctionActions.ErrorAction({
            error: 'ResponseDataNotFound',
            key: 'ResponseDataNotFound',
            timestamp: Date.now()
          }));
        }
      } else {
        return this.projectModelEndpointApi.getJSONSchemaOfResponse({ id: projectModelEndpoint.id });
      }
    } else if (uiStepFunction.workflowStepFunctionId) {
      // Find workflow step function and its custom function.
      let workflowStepFunction: WorkflowStepFunctionWithRelations = {
        ...(<WorkflowStepFunctionWithRelations[]>workflowStepFunctions).find(wsf =>
          wsf.id === uiStepFunction.workflowStepFunctionId)
      };
      workflowStepFunction = {
        ...workflowStepFunction,
        customFunctionDefinition: customFunctions.find(customFunction => customFunction.id === workflowStepFunction.customFunctionDefinitionId)
      };
      const params = {
        body: <GetJsonSchemaDto>{
          dataToCalculate: workflowStepFunction.customFunctionDefinition.responseJSONSample,
          schemaName: `${workflowStepFunction.customFunctionDefinition.name} Response Sample`
        }
      };

      return this.dataTransformationApi.getJSONSchema(params);

    } else if (uiStepFunction.workflowServiceId) {
      // Find workflow step function (service function) and its custom function.
      let workflowStepFunction: WorkflowStepFunctionWithRelations = {
        ...(<WorkflowStepFunctionWithRelations[]>templateWorkflowStepFunctions).find(wsf =>
          wsf.id === uiStepFunction.workflowServiceId)
      };
      workflowStepFunction = {
        ...workflowStepFunction,
        customFunctionDefinition: templateWorkflowFunctions.find(customFunction => customFunction.id === workflowStepFunction.customFunctionDefinitionId)
      };
      const params = {
        body: <GetJsonSchemaDto>{
          dataToCalculate: workflowStepFunction.customFunctionDefinition.responseJSONSample,
          schemaName: `${workflowStepFunction.customFunctionDefinition.serviceName}.${workflowStepFunction.customFunctionDefinition.functionName} Response Sample`
        }
      };

      return this.dataTransformationApi.getJSONSchema(params);

    } else if (uiStepFunction.workflowEventId) {
      return this.getJsonSchemaByDataSchemaId(uiStepFunction?.subscribedEvents[0].dataSchemaId, uiStepFunction);
    } else {
      return null;
    }
  }

}
