import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjectInterface, ProjectVersionInterface } from '@rappider/api-sdk';
import { BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { PROJECT_VERSION_LIST_CONFIG } from '@rappider/shared/configs';
import { defaultToolbarTitleHeadingSize, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Subscription } from 'rxjs';
import { LoadModule } from '../../states/project-versions-state/project-version.actions';

@Component({
  selector: 'rappider-project-version-list',
  templateUrl: './project-version-list.component.html',
  styleUrls: ['./project-version-list.component.scss']
})
export class ProjectVersionListComponent implements OnInit, OnDestroy {

  /* loading status */
  loading: boolean;
  /* main title */
  mainTitle: HeadingComponentConfig;
  /* component title */
  title: string | string[] | BreadcrumbOption[];
  /* Latest version list grid config */
  PROJECT_VERSION_LIST_CONFIG = PROJECT_VERSION_LIST_CONFIG;
  /* project version list */
  projectVersions: ProjectVersionInterface[];
  /* active project from state */
  activeProject: ProjectInterface;
  /* Subscriptions */
  subscriptions: Subscription[];
  displayToolbar = false;
  displayToolbarBackButton = false;
  constructor(
    private store: Store<any>
  ) { }

  ngOnInit() {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Subscribe to all data
   *
   * @memberof ProjectVersionListComponent
   */
  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToProjectVersions(),
    ];
  }

  /**
   * get project versions
   *
   * @param {string} projectId
   * @memberof ProjectVersionListComponent
   */
  subscribeToProjectVersions() {
    return this.store.select(state => state.projectVersion).subscribe(state => {
      this.projectVersions = state.data || null;
      this.loading = state.loading;
    });
  }

  /**
   * get active project from state and get project versions by active project id
   *
   * @return {*}
   * @memberof ProjectVersionListComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe(activeProject => {
      if (activeProject) {
        this.activeProject = activeProject;
        this.mainTitle = {
          content: 'SHARED.VERSIONS',
          type: defaultToolbarTitleHeadingSize
        };
        this.title = [
          {
            label: this.activeProject.name,
            redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${activeProject?.id}`
          },
          {
            label: 'SHARED.VERSIONS'
          }
        ];
        this.getProjectVersions();
      }
    });
  }

  /**
   * Get Project Versions
   *
   * @memberof ProjectVersionListComponent
   */
  getProjectVersions() {
    this.store.dispatch(new LoadModule());
  }

}
