/* eslint-disable */
import { ProjectModel } from '../index';

declare var Object: any;
export interface ProjectModelRelationInterface {
  id?: string;
  name: string;
  type: string;
  foreignKey?: string;
  sourceModelId?: string;
  targetModelId?: string;
  throughModelId?: string;
  throughModelKeyToRelationId?: string;
  throughModelKeyFromRelationId?: string;
  sourceModel?: ProjectModel;
  targetModel?: ProjectModel;
  throughModel?: ProjectModel;
}

export class ProjectModelRelation implements ProjectModelRelationInterface {
  'id': string;
  'name': string;
  'type': string;
  'foreignKey': string;
  'sourceModelId': string;
  'targetModelId': string;
  'throughModelId': string;
  'throughModelKeyToRelationId': string;
  'throughModelKeyFromRelationId': string;
  sourceModel: ProjectModel;
  targetModel: ProjectModel;
  throughModel: ProjectModel;
  constructor(data?: ProjectModelRelationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ProjectModelRelation`.
   */
  public static getModelName() {
    return 'ProjectModelRelation';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of ProjectModelRelation for dynamic purposes.
   **/
  public static factory(
    data: ProjectModelRelationInterface
  ): ProjectModelRelation {
    return new ProjectModelRelation(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'ProjectModelRelation',
      plural: 'ProjectModelRelations',
      path: 'project-model-relations',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        type: {
          name: 'type',
          type: 'string',
        },
        foreignKey: {
          name: 'foreignKey',
          type: 'string',
        },
        sourceModelId: {
          name: 'sourceModelId',
          type: 'string',
        },
        targetModelId: {
          name: 'targetModelId',
          type: 'string',
        },
        throughModelId: {
          name: 'throughModelId',
          type: 'string',
        },
        throughModelKeyFromRelationId: {
          name: 'throughModelKeyFromRelationId',
          type: 'string',
        },
        throughModelKeyToRelationId: {
          name: 'throughModelKeyToRelationId',
          type: 'string',
        },
      },
      relations: {
        sourceModel: {
          name: 'sourceModel',
          type: 'ProjectModel',
          model: 'ProjectModel',
          relationType: 'belongsTo',
          keyFrom: 'sourceModelId',
          keyTo: 'id',
        },
        targetModel: {
          name: 'targetModel',
          type: 'ProjectModel',
          model: 'ProjectModel',
          relationType: 'belongsTo',
          keyFrom: 'targetModelId',
          keyTo: 'id',
        },
        throughModel: {
          name: 'throughModel',
          type: 'ProjectModel',
          model: 'ProjectModel',
          relationType: 'belongsTo',
          keyFrom: 'throughModelId',
          keyTo: 'id',
        },
      },
    };
  }
}
