import { HttpMethods } from './http-methods';

export const HTTP_METHOD_KEY_VALUES = [
  {
    key: 'GET',
    value: HttpMethods.GET
  },
  {
    key: 'POST',
    value: HttpMethods.POST
  },
  {
    key: 'DELETE',
    value: HttpMethods.DELETE
  },
  {
    key: 'PATCH',
    value: HttpMethods.PATCH
  },
  {
    key: 'PUT',
    value: HttpMethods.PUT
  }
];
