import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  CreateProjectSetting,
  DeleteProjectSetting,
  UpdateProjectSetting,
} from '../../states/project-settings-state/project-setting.actions';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Subscription } from 'rxjs';
import { ProjectSetting, ProjectSettingWithRelations } from '@rappider/rappider-sdk';
import { PROJECT_SETTINGS_FORM_CONFIG, addProjectSettingsListAction, editSystemSettingsListAction, systemSettingsItemActions } from '@rappider/shared/configs';
import { PROJECT_SETTINGS_USER_CONFIG, PROJECT_SETTINGS_MENU_CONFIG } from '@rappider/shared/configs';
import { ActionResponse, AlertTypes, BreadcrumbOption, DropdownMenuItem, HeadingComponentConfig, HeadingType, Menu, PropertyType, TextComponentConfig } from '@rappider/rappider-components/utils';
import { InputTemplateService, JsonValidationService, NotificationService } from '@rappider/services';
import { cloneDeep, isArray, isObject } from 'lodash';
import { DEFAULT_PROJECT_SETTINGS_MODAL } from './utils/default-project-settings-modal';
import { DEFAULT_PROJECT_SETTING_DATA } from './utils/default-project-setting-data';
import { ProjectSettingsCreateOrEditMode } from './utils/project-settings-mode.enum';
import { ProjectSettingsDropdownItems } from './utils/project-settings-dropdown-items.enum';
import { getProjectSettingsWithModulesAndPages } from './utils/project-settings-pages-modules-selector';

@Component({
  selector: 'rappider-project-settings',
  templateUrl: './project-settings.component.html',
  styleUrls: ['./project-settings.component.scss']
})
export class ProjectSettingsComponent implements OnInit, OnDestroy {
  /* main title of the page */
  mainTitle: HeadingComponentConfig;
  /* breadcrumb title of the page */
  title: string | string[] | BreadcrumbOption[];
  /* subscriptions */
  subscriptions: Subscription[];
  /* active project id */
  projectId: string;
  /* selected type for the value */
  selectedType: PropertyType;
  /* project setting to display in list grid */
  projectSettings: ProjectSettingWithRelations[];
  /* loading for crud process */
  loading: boolean;
  /* loading for initial list values */
  isLoaded: boolean;
  /* editing project setting */
  editingProjectSetting: ProjectSettingWithRelations;
  /* displayed project settings data in list grid */
  tempProjectSettings: ProjectSettingWithRelations[];

  projectSettingTabs = ['System Settings', 'Custom Settings'];

  /* project setting menu config */
  PROJECT_SETTINGS_MENU_CONFIG = PROJECT_SETTINGS_MENU_CONFIG;
  /* project settings list config */
  PROJECT_SETTINGS_LIST_CONFIG = cloneDeep(PROJECT_SETTINGS_USER_CONFIG);
  /* project settings form config */
  PROJECT_SETTINGS_FORM_CONFIG = PROJECT_SETTINGS_FORM_CONFIG;
  /* modal for creating project setting */
  createProjectSettingModal = cloneDeep(DEFAULT_PROJECT_SETTINGS_MODAL);
  /* edit project setting modal */
  editProjectSettingModal = {
    ...DEFAULT_PROJECT_SETTINGS_MODAL,
    editingProjectSettingId: null
  };

  systemSettingsListActions = editSystemSettingsListAction;

  /* type and format of the value */
  typeAndFormat = {
    type: PropertyType.String,
    format: null
  };
  ProjectSettingsCreateOrEditMode = ProjectSettingsCreateOrEditMode;
  MAIN_PROJECT_SETTINGS_LIST_CONFIG = this.PROJECT_SETTINGS_LIST_CONFIG.listActions;
  MAIN_PROJECT_SETTINGS_FORM_CONFIG = this.PROJECT_SETTINGS_FORM_CONFIG;
  displayToolbar = false;
  displayToolbarBackButton = false;
  isEditModal = false;
  editingProjectSettingId: string;

  projectSettingsModal: {
    title: string;
    visible: boolean;
    isSubmitted: boolean;
    isValid: boolean;
    data: any;
  } = {
      title: 'Project Settings',
      visible: false,
      isSubmitted: false,
      isValid: false,
      data: { key: null, type: null, value: null, exposeToFrontend: true, exposeToBackend: true }
    };
  systemProjectSettings: ProjectSetting[];
  customProjectSettings: ProjectSetting[];
  isExposeValid = true;

  projectSettingsFormExposeFieldAlertConfig = {
    type: AlertTypes.Error,
    title: <HeadingComponentConfig>{
      content: 'Field Selection Required',
      type: HeadingType.H6
    },
    description: <TextComponentConfig>{
      text: 'You cant create a project setting without exposing it to UI or Backend'
    },
    closeable: false,
    showIcon: true
  };

  constructor(
    private store: Store<any>,
    private notificationService: NotificationService,
    private jsonValidationService: JsonValidationService,
    private inputTemplateService: InputTemplateService
  ) { }

  ngOnInit() {
    this.PROJECT_SETTINGS_FORM_CONFIG = { ...this.PROJECT_SETTINGS_FORM_CONFIG };
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(subscription => subscription.unsubscribe());
  }

  /**
   *  Subscribing all Data
   *
   * @memberof PageCreateComponent
   */
  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToProjectSettingsWithModulesAndPages(),
      this.subscribeToLoadingState(),
      this.subscribeToProjectSettingsLoading(),
    ];
  }

  subscribeToProjectSettingsWithModulesAndPages() {
    return this.store.select(<any>getProjectSettingsWithModulesAndPages).subscribe((projectSettingsWithModulesAndPages) => {
      if (projectSettingsWithModulesAndPages.isLoaded) {
        this.isLoaded = projectSettingsWithModulesAndPages.isLoaded;
        this.customProjectSettings = projectSettingsWithModulesAndPages.projectSettings?.filter(setting => !setting.isSystemGenerated);
        this.systemProjectSettings = projectSettingsWithModulesAndPages.projectSettings?.filter(setting => setting.isSystemGenerated);
        const dropdownMenuValues = this.systemProjectSettings.map(item => ({
          key: item.key,
          value: item.value,
          visible: true
        }));
        this.projectSettings = projectSettingsWithModulesAndPages.projectSettings;

        dropdownMenuValues.forEach(menuValues => {
          this.handleDropdownValuesVisible(menuValues);
        });
      }
    });

  }

  subscribeToProjectSettingsLoading() {
    return this.store.select(state => state.projectSetting.loading).subscribe(isLoading => {
      this.loading = isLoading;
    });
  }


  /**
   * Subscribe active project and project settings
   *
   * @returns
   * @memberof ProjectSettingsComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject) => {
      if (activeProject) {
        this.projectId = activeProject.id;
        this.mainTitle = {
          content: 'PROJECT_MODULE.PROJECT_SETTINGS_COMPONENT.PROJECT_SETTINGS',
          type: defaultToolbarTitleHeadingSize
        };
        this.title = [
          {
            label: activeProject.name,
            redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${activeProject?.id}`
          },
          {
            label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_SETTINGS.PAGE_TITLE
          }
        ];
      }
    });
  }


  /**
   * checks and disables current project settings in ProjectSettingsMenuConfigs
   *
   * @memberof ProjectSettingsComponent
   */
  checkExistingProjectSettings() {
    const projectSettingsMenuConfigs = this.findProjectSettingsMenuConfigs();
    projectSettingsMenuConfigs.forEach(projectSettingsMenuConfig => {
      const isExisting = this.projectSettings?.some(projectSetting => projectSetting.key === projectSettingsMenuConfig?.data?.key);
      projectSettingsMenuConfig.disabled = isExisting;
    });
  }

  /**
   * finds all settings in projectSettingsMenuConfigs
   *
   * @memberof ProjectSettingsComponent
   */
  findProjectSettingsMenuConfigs() {
    const projectSettingsMenuConfigs = this.PROJECT_SETTINGS_MENU_CONFIG.items.map(item => item.items).flat();
    return this.findAllChildrenInProjectSettingsMenuConfig(projectSettingsMenuConfigs);
  }

  /**
   * recursively scans and returns all children of the menu item
   *
   * @param {Menu[]} items
   * @memberof ProjectSettingsComponent
   */
  findAllChildrenInProjectSettingsMenuConfig(items: Menu[]) {
    return items.map(item => {
      if (item.children) {
        const children = this.findAllChildrenInProjectSettingsMenuConfig(item.children).flat();
        return [
          item,
          ...children
        ];
      } else {
        return item;
      }
    });
  }

  setProjectSettingsData(projectSettings: ProjectSettingWithRelations[]) {
    return projectSettings.map(projectSetting => {
      if (isObject(projectSetting.value) && !isArray(projectSetting.value)) {
        return projectSetting = {
          ...projectSetting,
          value: JSON.stringify(projectSetting.value)
        };
      } else {
        return projectSetting;
      }
    });
  }

  /**
   * Delete project setting by project setting id
   *
   * @param {*} projectSettings
   * @memberof ProjectSettingsComponent
   */
  deleteProjectSetting(projectSettingId: string, projectSettingKey: string) {
    if (projectSettingId) {
      this.store.dispatch(new DeleteProjectSetting({ projectSettingId: projectSettingId }));
    } else {
      this.notificationService.createNotification(
        'error',
        projectSettingKey,
        'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_SETTING_COULDNOT_DELETED'
      );
    }
  }

  /**
   * Open Create Project Setting Modal
   *
   * @param {*} data
   * @memberof ProjectSettingsComponent
   */
  openProjectSettingsModal() {
    this.projectSettingsModal.visible = true;
  }

  resetProjectSettingValue(type: PropertyType, mode) {
    if (mode === ProjectSettingsCreateOrEditMode.Edit) {
      this.editProjectSettingModal.data = {
        ...this.editProjectSettingModal.data,
        value: null
      };
    } else if (mode === ProjectSettingsCreateOrEditMode.Create) {
      this.createProjectSettingModal.data = {
        ...this.createProjectSettingModal.data,
        value: null
      };
    }
    this.changeProjectSettingType(type);
  }

  changeProjectSettingType(type: PropertyType) {
    this.typeAndFormat = {
      ...this.typeAndFormat,
      type: type
    };
  }

  /**
   * checks creating project setting key is already exists and if the creating
   * value type is object validates and parses the data before submitting
   *
   * @memberof ProjectSettingsComponent
   */
  onSaveCreateProjectSetting() {
    this.projectSettingsModal.isSubmitted = true;
    if (this.projectSettingsModal.isSubmitted && this.projectSettingsModal.isValid && (this.projectSettingsModal.data.exposeToBackend || this.projectSettingsModal.data.exposeToFrontend)) {
      if (this.projectSettingsModal.data.key) {
        if (this.isProjectKeyDuplicated(this.projectSettingsModal.data.key) && !this.isEditModal) {
          this.notificationService.createNotification(
            'error',
            `${this.projectSettingsModal.data.key}`,
            'PROJECT_MODULE.PROJECT_SETTINGS_COMPONENT.KEY_ALREADY_EXISTS'
          );
        } else {
          if (this.selectedType === PropertyType.Object) {
            if (this.validateObjectData(this.projectSettingsModal.data).isJsonValid) {
              /* parse project setting value */
              this.projectSettingsModal.data = {
                ...this.projectSettingsModal.data,
                value: this.validateObjectData(this.projectSettingsModal.data).dataAsJson
              };

              if (this.isEditModal) {
                this.dispatchUpdateAction();
              } else {
                this.dispatchCreateAction();
              }
            } else {
              this.notificationService.createNotification(
                'error',
                'SHARED.COULDNT_CREATED',
                'ERRORS.WRONG_JSON_FORMAT'
              );
            }
          } else {
            if (this.isEditModal) {
              this.dispatchUpdateAction();
            } else {
              this.dispatchCreateAction();
            }
          }
        }
      } else {
        this.notificationService.createNotification(
          'error',
          'SHARED.ERROR',
          'SHARED.COULDNT_CREATED'
        );
      }
    } else if (this.projectSettingsModal.isSubmitted && !(this.projectSettingsModal.data.exposeToBackend || this.projectSettingsModal.data.exposeToFrontend)) {
      this.isExposeValid = false;
    }
  }

  onProjectSettingsModalClose() {
    this.projectSettingsModal.visible = false;
    this.projectSettingsModal.isValid = true;
    this.projectSettingsModal.isSubmitted = false;
    this.isEditModal = false;
    this.projectSettingsModal.data = {
      key: null, type: null, value: null, exposeToFrontend: true, exposeToBackend: true
    };
    this.typeAndFormat = {
      ...this.typeAndFormat,
      type: PropertyType.String
    };
    this.changeValueType();
    this.PROJECT_SETTINGS_FORM_CONFIG = this.MAIN_PROJECT_SETTINGS_FORM_CONFIG;
    this.PROJECT_SETTINGS_FORM_CONFIG = { ...this.PROJECT_SETTINGS_FORM_CONFIG };
    this.isExposeValid = true;
  }

  /**
   * open edit user modal
   *
   * @param {ActionResponse} actionResponse
   * @memberof ProjectSettingsComponent
   */
  onColumnActionClick(actionResponse: ActionResponse) {
    const projectSetting = actionResponse.data;
    if (actionResponse.action.name === 'EDIT_PROJECT_SETTING') {
      this.projectSettingsModal.data = {
        key: actionResponse.data?.key,
        type: actionResponse.data?.type,
        value: actionResponse.data?.value,
        exposeToFrontend: actionResponse.data?.exposeToFrontend,
        exposeToBackend: actionResponse.data?.exposeToBackend
      };
      this.editingProjectSettingId = actionResponse.data.id;
      this.isEditModal = true;
      if (actionResponse.data?.type) {
        this.typeAndFormat = {
          ...this.typeAndFormat,
          type: actionResponse.data?.type,
        };
      }
      if (this.projectSettingsModal.data?.key === ProjectSettingsDropdownItems.Payment && this.projectSettingsModal.data?.type === 'string') {
        this.changeFormDisabledForString();
      }
      if (this.projectSettingsModal.data?.key === ProjectSettingsDropdownItems.Authentication || this.projectSettingsModal.data?.key === ProjectSettingsDropdownItems.BootStrap) {
        this.changeFormDisabledForNotString();
      }
      this.changeValueType();
      this.openProjectSettingsModal();
    }
    if (actionResponse.action.name === 'DELETE_ITEM') {
      this.deleteProjectSetting(projectSetting.id, projectSetting.key);
    }
  }

  /**
   * Reset edit project setting user modal and close edit project setting user modal
   *
   *
   * @memberof ProjectSettingsComponent
   */
  closeEditProjectSettingModal() {
    this.selectedType = PropertyType.String;
    this.editProjectSettingModal = {
      ...this.editProjectSettingModal,
      visible: false,
      data: cloneDeep(DEFAULT_PROJECT_SETTING_DATA),
      editingProjectSettingId: null
    };
  }

  /**
   * checks if editing project setting key already exists and if the editing value type
   * is object validates and parses the value before submitting the form
   *
   * @memberof ProjectSettingsComponent
   */

  isProjectKeyDuplicated(key: string) {
    return this.projectSettings.some(projectSetting => projectSetting.key === key);
  }

  validateObjectData(projectSettingData: ProjectSettingWithRelations) {
    return this.jsonValidationService.validateStringifiedJson(projectSettingData.value);
  }

  dispatchCreateAction() {
    this.store.dispatch(new CreateProjectSetting({ projectSetting: this.projectSettingsModal.data }));
    this.onProjectSettingsModalClose();
  }

  dispatchUpdateAction() {
    this.store.dispatch(new UpdateProjectSetting(
      {
        projectSettingId: this.editingProjectSettingId,
        projectSetting: this.projectSettingsModal.data
      }
    ));
    this.onProjectSettingsModalClose();
  }

  /**
   * sets type of the editing project setting's value according to its type
   *
   * @param {*} projectSettingValue
   * @memberof ProjectSettingsComponent
   */
  setTypeOfProjectSettingValue(projectSettingValue: any) {
    this.selectedType = this.inputTemplateService.setInputTypeByValue(projectSettingValue);
    this.typeAndFormat.type = this.selectedType;
  }

  subscribeToLoadingState() {
    return this.store.select(state => state.projectSetting.loading).subscribe((loading: boolean) => {
      this.loading = loading;
    });
  }

  onProjectSettingsModalDataChange(projectSettingsData) {
    if (projectSettingsData.type !== this.projectSettingsModal.data.type) {
      this.typeAndFormat = {
        ...this.typeAndFormat,
        type: projectSettingsData.type
      };
      this.changeValueType();
      projectSettingsData.value = null;
    }

    this.projectSettingsModal.data = projectSettingsData;
    if (this.projectSettingsModal.data.value === null) {
      if (this.typeAndFormat.type === 'boolean') {
        this.projectSettingsModal.data.value = false;
      } else if (this.typeAndFormat.type === 'integer') {
        this.projectSettingsModal.data.value = 0;
      } else {
        this.projectSettingsModal.data.value = null;
      }
    }

  }

  onProjectSettingsModalIsValid(isValid: boolean) {
    this.projectSettingsModal.isValid = isValid;
  }

  changeFormDisabledForString() {
    this.PROJECT_SETTINGS_FORM_CONFIG.items = this.PROJECT_SETTINGS_FORM_CONFIG.items.map(item => {
      if (item.fieldName === 'value') {
        return {
          ...item,
          isInputOptionsVisible: false,
          config: {
            disabled: true
          }
        };
      } else if (item.fieldName !== 'value') {
        return {
          ...item,
          disabled: true
        };
      } else {
        return item;
      }
    });
    this.PROJECT_SETTINGS_FORM_CONFIG = { ...this.PROJECT_SETTINGS_FORM_CONFIG };
  }

  changeFormDisabledForNotString() {
    this.PROJECT_SETTINGS_FORM_CONFIG.items = this.PROJECT_SETTINGS_FORM_CONFIG.items.map(item => {
      if (item.fieldName === 'value') {
        return {
          ...item,
          isInputOptionsVisible: false,
        };
      } else if (item.fieldName !== 'value') {
        return {
          ...item,
          disabled: true
        };
      } else {
        return item;
      }
    });
    this.PROJECT_SETTINGS_FORM_CONFIG = { ...this.PROJECT_SETTINGS_FORM_CONFIG };
  }

  changeValueType() {
    this.PROJECT_SETTINGS_FORM_CONFIG.items = this.PROJECT_SETTINGS_FORM_CONFIG.items.map(item => {
      if (item.fieldName === 'value') {
        return {
          ...item,
          typeAndFormat: this.typeAndFormat
        };
      } else {
        return item;
      }
    });
    this.PROJECT_SETTINGS_FORM_CONFIG = { ...this.PROJECT_SETTINGS_FORM_CONFIG };
  }

  onListActionDropdownItemClick(action: DropdownMenuItem) {
    const updatedSetting = this.systemProjectSettings.find(setting => setting.key === action.data.key);
    if (updatedSetting?.value !== action.data.value && action.data.key !== ProjectSettingsDropdownItems.BootStrap) {
      this.store.dispatch(new UpdateProjectSetting({ projectSetting: { value: action.data.value }, projectSettingId: updatedSetting.id }));
    } else if (updatedSetting?.value === action.data.value && action.data.key !== ProjectSettingsDropdownItems.BootStrap) {
      this.notificationService.createNotification(
        'warning',
        'Error',
        'This project setting already exists'
      );
    }
    if (updatedSetting?.value !== action.data.value && action.data.key === ProjectSettingsDropdownItems.BootStrap && action.data.value === true) {
      this.store.dispatch(new CreateProjectSetting({ projectSetting: { ...action.data, isSystemGenerated: true, exposeToBackend: true, exposeToFrontend: true } }));
    } else if (updatedSetting?.value === action.data.value && action.data.key === ProjectSettingsDropdownItems.BootStrap && action.data.value === true) {
      this.notificationService.createNotification(
        'warning',
        'Error',
        'This project setting already exists'
      );
    }
    if (updatedSetting?.value === true && action.data.key === ProjectSettingsDropdownItems.BootStrap && action.data.value === false) {
      this.store.dispatch(new DeleteProjectSetting({ projectSettingId: updatedSetting.id }));
    } else if (updatedSetting?.value !== true && action.data.key === ProjectSettingsDropdownItems.BootStrap && action.data.value === false) {
      this.notificationService.createNotification(
        'warning',
        'Error',
        'This project setting does not already exist'
      );
    }
  }

  onTabChange(tab) {
    const tabTitle = tab.tab.nzTitle;

    if (tabTitle === 'System Settings') {
      this.PROJECT_SETTINGS_LIST_CONFIG = {
        ...this.PROJECT_SETTINGS_LIST_CONFIG,
        listActions: [this.systemSettingsListActions],
        itemActions: []
      };
      this.systemProjectSettings = [...this.systemProjectSettings];
    }
    if (tabTitle === 'Custom Settings') {
      this.PROJECT_SETTINGS_LIST_CONFIG = {
        ...this.PROJECT_SETTINGS_LIST_CONFIG,
        listActions: [addProjectSettingsListAction],
        itemActions: systemSettingsItemActions.itemActions
      };
      this.customProjectSettings = [...this.customProjectSettings];
    }
  }

  onSystemSettingsMenuClicked(label, data: any) {
    const updatedSetting = this.systemProjectSettings.find(setting => setting.key === data.key);

    // Update Project Setting
    if (updatedSetting?.value !== data.value && data.key !== ProjectSettingsDropdownItems.BootStrap) {
      this.store.dispatch(new UpdateProjectSetting({ projectSetting: { key: data.key, value: data.value }, projectSettingId: updatedSetting.id }));
    }

    // Create Project Settings
    if (updatedSetting?.value !== data.value && data.key === ProjectSettingsDropdownItems.BootStrap && data.value === true) {
      const body = {
        ...data,
        type: data.key
      };
      this.store.dispatch(new CreateProjectSetting({ projectSetting: { ...body, isSystemGenerated: true, exposeToBackend: true, exposeToFrontend: true } }));
    }

    // Delete Project Settings
    if (updatedSetting?.value === true && data.key === ProjectSettingsDropdownItems.BootStrap && data.value === false) {
      this.store.dispatch(new DeleteProjectSetting({ projectSettingId: updatedSetting.id }));
    }
    setTimeout(() => {
      const selectedValue = {
        ...data,
        visible: true
      };
      this.handleDropdownValuesVisible(selectedValue);
    }, 1000);
  }

  handleDropdownValuesVisible(selectedValue) {
    this.updateVisibilityRecursive(this.systemSettingsListActions.dropdownConfig.items, selectedValue.key, selectedValue.value, selectedValue.visible);
  }

  updateVisibilityRecursive(items, key: string, value: any, visible: boolean) {
    for (const item of items) {
      if (item.data && item.data.key === key && item.data.value !== value) {
        item.data.visible = visible;
      } else if (item.data && item.data.key === key && item.data.value === value) {
        item.data.visible = !visible;
      } else if (item.items) {
        this.updateVisibilityRecursive(item.items, key, value, visible);
      }
    }
  }

  hasVisibleItemsInDepthSecond(depthSecondItems): boolean {
    return depthSecondItems.some(item => item.data.visible === true);
  }

  hasVisibleItemsInDepthFirst(depthFirstItems): boolean {
    for (const item of depthFirstItems) {
      if (item.items) {
        for (const nestedItem of item.items) {
          if (nestedItem.data && nestedItem.data.visible) {
            return true;
          }
        }
      } else {
        if (item.data && item.data.visible) {
          return true;
        }
      }
    }
    return false;
  }

}

