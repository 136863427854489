import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { IconComponentConfig } from '../../utils/icon';
import { BorderConfig, SizeConfig, BoxShadowConfig, SpacingConfig } from '../../utils/shared';
import { TagType } from '../../utils/tag/tag-type.enum';
import { TextComponentConfig } from '../../utils/text';
import { TooltipPlacement } from '../../utils/button';
import { computeBorderStyles } from '../../utils/set-border-and-radius';

@Component({
  selector: 'rappider-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class RappiderTagComponent implements OnInit {

  /* Mode of tag */
  @Input() mode = TagType.Default;
  /* Checked status of Tag, double binding, only works when nzMode="checkable" */
  @Input() checked = false;
  /* Color of the Tag */
  @Input() color: string;
  /* text */
  @Input() text: TextComponentConfig;
  @Input() icon: IconComponentConfig;

  @Input() borderSettings: BorderConfig;
  @Input() sizeSettings: SizeConfig;
  @Input() boxShadowSettings: BoxShadowConfig;
  @Input() paddingSettings: SpacingConfig;
  @Input() marginSettings: SpacingConfig;
  @Input() tooltipText?: string;
  @Input() tooltipPlacement?: TooltipPlacement;

  /* Checked status change call back, only works when nzMode="checkable" */
  @Output() checkedChange = new EventEmitter();
  /* 	Callback executed when tag is closed, only works when nzMode="closable" */
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter();

  borderStyles: any = {};

  ngOnInit(): void {
    this.setBorderStyles();
  }

  onCheckChange() {
    this.checkedChange.emit();
  }

  onTagClose() {
    this.close.emit();
  }

  setBorderStyles(): any {
    this.borderStyles = computeBorderStyles({
      border: this.borderSettings?.border || null,
      borderRadius: this.borderSettings?.borderRadius || null
    });
  }

}
