/* angular */
import { createAction, createReducer, on } from '@ngrx/store';
import { NotificationStatus } from '../../definitions/notification/notification-status.enum';
import { PersonNotificationWithRelations } from '@rappider/rappider-sdk';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

/* actions */
import * as NotificationActions from './notification.actions';
import { orderBy } from 'lodash';

/* state key */
export const featureKey = 'notification';

function compareNotifications(
  initialNotifications: PersonNotificationWithRelations[],
  currentNotifications: PersonNotificationWithRelations[]
): PersonNotificationWithRelations[] {
  const initialNotificationIds = new Set(initialNotifications.map(notification => notification.id));
  const isNotificationsEqual = currentNotifications.every(currentNotification => initialNotificationIds.has(currentNotification.id));
  if (isNotificationsEqual) {
    return [];
  } else {
    return currentNotifications.filter(currentNotification => !initialNotificationIds.has(currentNotification.id)) ?? [];
  }
}

const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

/* state interface */
export interface State {
  data: PersonNotificationWithRelations[] | null;
  error: {
    error: any;
    key: string;
    time: any;
  } | null;
  isLoading: boolean;
  changeActiveProjectWithNotification: { pageId: string; commentId: string; projectId: string; isProjectChange: boolean; isProjectChangeWithoutRefresh: boolean; activeProjectLoadedThroughNotification: boolean };
}

/* initial values */
export const initialState: State = {
  data: [],
  error: null,
  isLoading: false,
  changeActiveProjectWithNotification: { pageId: null, commentId: null, projectId: null, isProjectChange: false, isProjectChangeWithoutRefresh: false, activeProjectLoadedThroughNotification: false }
};

export const reducer = createReducer(
  initialState,
  on(NotificationActions.GetNotifications, (state) => ({
    ...state,
    isLoading: true
  })),
  on(NotificationActions.GetNotificationsSuccessful, (state, action) => ({
    ...state,
    data: action.notifications,
    isLoading: false
  })),
  on(NotificationActions.GetNotificationsFailure, (state, action) => ({
    ...state,
    error: {
      error: action.error,
      key: action.key,
      time: action.time
    },
    isLoading: false
  })),
  on(NotificationActions.SetNotificationStatusAsSeenSuccessful, (state, action) => {
    const seenSelectedNotification = {
      ...state.data.find(notification => notification.id === action.notificationId),
      status: NotificationStatus.Read
    };
    return {
      ...state,
      data: orderBy([
        seenSelectedNotification,
        ...state.data.filter(notification => notification.id !== action.notificationId)
      ], 'createdDate', 'desc'),
    };
  }),
  on(NotificationActions.ChangeActiveProjectWithNotification, (state, action) => ({
    ...state,
    changeActiveProjectWithNotification: {
      pageId: action.changeActiveProjectWithNotification.pageId,
      commentId: action.changeActiveProjectWithNotification.commentId,
      projectId: action.changeActiveProjectWithNotification.projectId,
      isProjectChange: action.changeActiveProjectWithNotification.isProjectChange,
      isProjectChangeWithoutRefresh: action.changeActiveProjectWithNotification.isProjectChangeWithoutRefresh,
      activeProjectLoadedThroughNotification: action.changeActiveProjectWithNotification.activeProjectLoadedThroughNotification
    }
  })),
  on(Logout, () => (initialState))
);
