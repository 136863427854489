/* eslint-disable no-shadow */
import { Action } from '@ngrx/store';
import { BreadcrumbOption } from '@rappider/rappider-components/utils';
import {
  ComponentDefinitionWithRelations,
  ComponentInputDefinitionWithRelations,
  ComponentOutputDefinition,
  ComponentOutputDefinitionWithRelations,
  Component, ComponentDefinition, NewComponentDefinition, ComponentOutputDefinitionPartial
} from '@rappider/rappider-sdk';

export enum ActionTypes {
  LoadModule = '[ComponentDefinition] LoadModule',
  SetComponentBrowserTitle = '[ComponentDefinition] SetComponentBrowserTitle',
  /* component definition */
  LoadComponentDefinitions = '[ComponentDefinition] LoadComponentDefinitions',
  LoadComponentDefinitionsSuccess = '[ComponentDefinition] LoadComponentDefinitionsSuccess',
  LoadComponentDefinitionsFailure = '[ComponentDefinition] LoadComponentDefinitionsFailure',
  LoadComponentDefinitionsAfterGetCategories = '[ComponentDefinition] LoadComponentDefinitionsAfterGetCategories',
  CreateComponentDefinition = '[ComponentDefinition] CreateComponentDefinition',
  CreateComponentDefinitionSuccessful = '[ComponentDefinition] CreateComponentDefinitionSuccessful',
  CreateComponentDefinitionFailure = '[ComponentDefinition] CreateComponentDefinitionFailure',
  UpdateComponentDefinition = '[ComponentDefinition] UpdateComponentDefinition',
  UpdateComponentDefinitionSuccessful = '[ComponentDefinition] UpdateComponentDefinitionSuccessful',
  UpdateComponentDefinitionFailure = '[ComponentDefinition] UpdateComponentDefinitionFailure',
  DeleteComponentDefinition = '[ComponentDefinition] DeleteComponentDefinition',
  DeleteComponentDefinitionSuccessful = '[ComponentDefinition] DeleteComponentDefinitionSuccessful',
  DeleteComponentDefinitionFailure = '[ComponentDefinition] DeleteComponentDefinitionFailure',
  DeprecateComponentDefinition = '[ComponentDefinition] DeprecateComponentDefinition',
  DeprecateComponentDefinitionSuccessful = '[ComponentDefinition] DeprecateComponentDefinitionSuccessful',
  /* input definition */
  CreateComponentInputDefinitions = '[ComponentDefinition] CreateComponentInputDefinitions',
  CreateComponentInputDefinitionSuccess = '[ComponentDefinition] CreateComponentInputDefinitionSuccess',
  CreateComponentInputDefinitionFailure = '[ComponentDefinition] CreateComponentInputDefinitionFailure',
  UpdateInputDefinitions = '[ComponentDefinition] UpdateInputDefinitions',
  DeleteInputDefinitions = '[ComponentDefinition] DeleteInputDefinitions',
  /* output definition */
  CreateComponentOutputDefinitions = '[ComponentDefinition] CreateComponentOutputDefinitions',
  CreateComponentOutputDefinitionsSuccess = '[ComponentDefinition] CreateComponentOutputDefinitionsSuccess',
  CreateComponentOutputDefinitionsFailure = '[ComponentDefinition] CreateComponentOutputDefinitionsFailure',
  UpdateOutputDefinitions = '[ComponentDefinition] UpdateOutputDefinition',
  UpdateComponentOutputDefinitionsSuccess = '[ComponentDefinition] UpdateComponentOutputDefinitionsSuccess',
  UpdateComponentOutputDefinitionsFailure = '[ComponentDefinition] UpdateComponentOutputDefinitionsFailure',
  DeleteOutputDefinitions = '[ComponentDefinition] DeleteOutputDefinitions',
  DeleteComponentOutputDefinitionsSuccess = '[ComponentDefinition] DeleteComponentOutputDefinitionsSuccess',
  DeleteComponentOutputDefinitionsFailure = '[ComponentDefinition] DeleteComponentOutputDefinitionsFailure',
  DeleteOutputDefinition = '[ComponentDefinition] DeleteOutputDefinition',
  /* sample input */
  CreateSampleInput = '[ComponentDefinition] CreateSampleInput',
  CreateSampleInputSuccessful = '[ComponentDefinition] CreateSampleInputSuccessful',
  CreateSampleInputFailure = '[ComponentDefinition] CreateSampleInputFailure',
  DeleteSampleInput = '[ComponentDefinition] DeleteSampleInput',
  DeleteSampleInputSuccessful = '[ComponentDefinition] DeleteSampleInputSuccessful',
  DeleteSampleInputFailure = '[ComponentDefinition] DeleteSampleInputFailure',
  /* slot definition */
  CreateSlotDefinition = '[ComponentDefinition] New SlotDefinition',
  UpdateSlotDefinition = '[ComponentDefinition] UpdateSlotDefinition',
  DeleteSlotDefinition = '[ComponentDefinition] DeleteSlotDefinition',
  /* Container to Component Definition */
  SaveContainerAsComponent = '[ComponentDefinition] SaveContainerAsComponent',
  SaveContainerAsComponentSuccessful = '[ComponentDefinition] SaveContainerAsComponentSuccessful',
  /* error */
  ComponentDefinitionError = '[ComponentDefinition] ComponentDefinitionError'
}

export class LoadModule implements Action {
  readonly type = ActionTypes.LoadModule;
}

export class ComponentDefinitionError implements Action {
  readonly type = ActionTypes.ComponentDefinitionError;
  constructor(public payload: { errorOn: string; error: any }) { }
}

/* component definition */

export class LoadComponentDefinitions implements Action {
  readonly type = ActionTypes.LoadComponentDefinitions;
}

export class LoadComponentDefinitionsSuccess implements Action {
  readonly type = ActionTypes.LoadComponentDefinitionsSuccess;
  constructor(public payload: { componentDefinitions: ComponentDefinitionWithRelations[] }) { }
}

export class LoadComponentDefinitionsFailure implements Action {
  readonly type = ActionTypes.LoadComponentDefinitionsFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class LoadComponentDefinitionsAfterGetCategories implements Action {
  readonly type = ActionTypes.LoadComponentDefinitionsAfterGetCategories;
  constructor(public payload: { navigateToLoadComponentDefinitions: boolean }) { }
}

export class CreateComponentDefinition implements Action {
  readonly type = ActionTypes.CreateComponentDefinition;
  constructor(public payload: { componentDefinition: NewComponentDefinition; navigateToDetailPage: boolean }) { }
}

export class CreateComponentDefinitionSuccessful implements Action {
  readonly type = ActionTypes.CreateComponentDefinitionSuccessful;
  constructor(public payload: { createdComponentDefinition: ComponentDefinitionWithRelations; navigateToDetailPage: boolean }) { }
}

export class CreateComponentDefinitionFailure implements Action {
  readonly type = ActionTypes.CreateComponentDefinitionFailure;
}

export class DeleteComponentDefinition implements Action {
  readonly type = ActionTypes.DeleteComponentDefinition;
  constructor(public payload: { componentDefinitionId: string; navigateToComponentBrowser: boolean }) { };
}

export class DeleteComponentDefinitionSuccessful implements Action {
  readonly type = ActionTypes.DeleteComponentDefinitionSuccessful;
  constructor(public payload: { componentDefinitionId: string }) { };
}

export class DeleteComponentDefinitionFailure implements Action {
  readonly type = ActionTypes.DeleteComponentDefinitionFailure;
}

export class UpdateComponentDefinition implements Action {
  readonly type = ActionTypes.UpdateComponentDefinition;
  constructor(public payload: { componentDefinitionId: string; updatedComponentDefinition: any; navigateToDetailPage: boolean }) { }
}

export class UpdateComponentDefinitionSuccessful implements Action {
  readonly type = ActionTypes.UpdateComponentDefinitionSuccessful;
  constructor(public payload: { componentDefinitionId: string; updatedComponentDefinition: any; navigateToDetailPage: boolean }) { }
}

export class UpdateComponentDefinitionFailure implements Action {
  readonly type = ActionTypes.UpdateComponentDefinitionFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

/* input definition */

export class CreateComponentInputDefinitions implements Action {
  readonly type = ActionTypes.CreateComponentInputDefinitions;
  constructor(public payload: { inputDefinitions: ComponentInputDefinitionWithRelations[]; componentDefinitionId: string }) { }
}

export class CreateComponentInputDefinitionSuccess implements Action {
  readonly type = ActionTypes.CreateComponentInputDefinitionSuccess;
  constructor(public payload: { createdComponentInputDefinitions: ComponentInputDefinitionWithRelations[]; componentDefinitionId: string }) { }
}

export class CreateComponentInputDefinitionFailure implements Action {
  readonly type = ActionTypes.CreateComponentInputDefinitionFailure;
}

export class UpdateInputDefinitions implements Action {
  readonly type = ActionTypes.UpdateInputDefinitions;
  constructor(public payload: { inputDefinitions: ComponentInputDefinitionWithRelations[]; componentDefinitionId: string }) { }
}

export class DeleteInputDefinitions implements Action {
  readonly type = ActionTypes.DeleteInputDefinitions;
  constructor(public payload: { componentDefinitionId: string; inputDefinitionIds: string[] }) { }
}

/* output definition */
export class CreateComponentOutputDefinitions implements Action {
  readonly type = ActionTypes.CreateComponentOutputDefinitions;
  constructor(public payload: { outputDefinitions: ComponentOutputDefinition[]; componentDefinitionId: string }) { }
}

export class CreateComponentOutputDefinitionsSuccess implements Action {
  readonly type = ActionTypes.CreateComponentOutputDefinitionsSuccess;
  constructor(public payload: { componentOutputDefinitions: ComponentOutputDefinitionWithRelations[]; componentDefinitionId: string }) { }
}

export class CreateComponentOutputDefinitionsFailure implements Action {
  readonly type = ActionTypes.CreateComponentOutputDefinitionsFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class UpdateOutputDefinitions implements Action {
  readonly type = ActionTypes.UpdateOutputDefinitions;
  constructor(
    public payload: { updatedComponentOutputDefinitions: ComponentOutputDefinitionPartial[]; componentDefinitionId: string }
  ) { }
}

export class UpdateComponentOutputDefinitionsSuccess implements Action {
  readonly type = ActionTypes.UpdateComponentOutputDefinitionsSuccess;
  constructor(public payload: { componentOutputDefinitions: ComponentOutputDefinitionWithRelations[]; componentDefinitionId: string }) { }
}

export class UpdateComponentOutputDefinitionsFailure implements Action {
  readonly type = ActionTypes.UpdateComponentOutputDefinitionsFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class DeleteOutputDefinition implements Action {
  readonly type = ActionTypes.DeleteOutputDefinition;
  constructor(public payload: { componentDefinitionId: string; outputDefinitionId: string }) { }
}

export class DeleteOutputDefinitions implements Action {
  readonly type = ActionTypes.DeleteOutputDefinitions;
  constructor(public payload: { componentDefinitionId: string; deletedOutputDefinitionIds: string[] }) { }
}

export class DeleteComponentOutputDefinitionsSuccess implements Action {
  readonly type = ActionTypes.DeleteComponentOutputDefinitionsSuccess;
  constructor(public payload: { deletedComponentOutputDefinitionIds: string[]; componentDefinitionId: string }) { }
}

export class DeleteComponentOutputDefinitionsFailure implements Action {
  readonly type = ActionTypes.DeleteComponentOutputDefinitionsFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

/* sample input */

export class CreateSampleInput implements Action {
  readonly type = ActionTypes.CreateSampleInput;
  constructor(public payload: { sampleInput; componentDefinitionId: string }) { }
}

export class CreateSampleInputSuccessful implements Action {
  readonly type = ActionTypes.CreateSampleInputSuccessful;
  constructor(public payload: { sampleInput; componentDefinitionId: string }) { }
}

export class CreateSampleInputFailure implements Action {
  readonly type = ActionTypes.CreateSampleInputFailure;
}

export class DeleteSampleInput implements Action {
  readonly type = ActionTypes.DeleteSampleInput;
  constructor(public payload: { sampleInputId: string }) { }
}

export class DeleteSampleInputSuccessful implements Action {
  readonly type = ActionTypes.DeleteSampleInputSuccessful;
  constructor(public payload: { sampleInputId: string }) { }
}

export class DeleteSampleInputFailure implements Action {
  readonly type = ActionTypes.DeleteSampleInputFailure;
}

export class SetComponentBrowserTitle implements Action {
  readonly type = ActionTypes.SetComponentBrowserTitle;
  constructor(public payload: { title: BreadcrumbOption[] }) { }
}

export class DeprecateComponentDefinition implements Action {
  readonly type = ActionTypes.DeprecateComponentDefinition;
  constructor(public payload: { componentDefinitionId: string }) { };
}

export class DeprecateComponentDefinitionSuccessful implements Action {
  readonly type = ActionTypes.DeprecateComponentDefinitionSuccessful;
  constructor(public payload: { componentDefinitionId: string }) { };
}


export type Actions =
  LoadModule
  | ComponentDefinitionError
  | LoadComponentDefinitions
  | LoadComponentDefinitionsSuccess
  | LoadComponentDefinitionsFailure
  | LoadComponentDefinitionsAfterGetCategories
  | CreateComponentDefinition
  | CreateComponentDefinitionSuccessful
  | CreateComponentDefinitionFailure
  | DeleteComponentDefinition
  | DeleteComponentDefinitionSuccessful
  | DeleteComponentDefinitionFailure
  | CreateComponentInputDefinitions
  | CreateComponentOutputDefinitions
  | CreateSampleInput
  | CreateSampleInputSuccessful
  | CreateSampleInputFailure
  | DeleteOutputDefinition
  | DeleteOutputDefinitions
  | DeleteInputDefinitions
  | DeleteSampleInput
  | DeleteSampleInputSuccessful
  | DeleteSampleInputFailure
  | UpdateInputDefinitions
  | UpdateOutputDefinitions
  | UpdateComponentDefinition
  | UpdateComponentDefinitionSuccessful
  | UpdateComponentDefinitionFailure
  | CreateComponentOutputDefinitionsSuccess
  | CreateComponentOutputDefinitionsFailure
  | UpdateComponentOutputDefinitionsSuccess
  | UpdateComponentOutputDefinitionsFailure
  | DeleteComponentOutputDefinitionsSuccess
  | DeleteComponentOutputDefinitionsFailure
  | SetComponentBrowserTitle
  | CreateComponentInputDefinitionSuccess
  | CreateComponentInputDefinitionFailure
  | DeprecateComponentDefinition
  | DeprecateComponentDefinitionSuccessful
  ;
