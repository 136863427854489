import { Action } from '@ngrx/store';

export enum ActionTypes {
  UpdateGuidanceKey = '[Guidance] UpdateGuidanceKey',
  UpdateGuidanceParam = '[Guidance] UpdateGuidanceParam'
}

export class UpdateGuidanceKey implements Action {
  readonly type = ActionTypes.UpdateGuidanceKey;
  constructor(public payload: { guidanceKey: string }) { }
}

export class UpdateGuidanceParam implements Action {
  readonly type = ActionTypes.UpdateGuidanceParam;
  constructor(public payload: { param: any }) { }
}

export type Actions =
  UpdateGuidanceKey
  | UpdateGuidanceParam;
