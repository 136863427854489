import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjectDocumentWithRelations, ProjectWithRelations } from '@rappider/rappider-sdk';
import { PROJECT_DOCUMENT_CREATE_OR_EDIT_CONFIG } from '@rappider/shared/configs';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Subscription } from 'rxjs';
import { BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { GetProjectDocuments, UpdateProjectDocument } from 'libs/project/src/lib/states/project-document-state/project-document.actions';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'rappider-project-document-edit',
  templateUrl: './project-document-edit.component.html',
  styleUrls: ['./project-document-edit.component.scss']
})
export class ProjectDocumentEditComponent implements OnInit, OnDestroy {
  /* edit form config */
  PROJECT_DOCUMENT_EDIT_CONFIG = PROJECT_DOCUMENT_CREATE_OR_EDIT_CONFIG;

  /* main title */
  mainTitle: HeadingComponentConfig;
  /* page title */
  title: BreadcrumbOption[];
  /* subscriptions */
  subscriptions: Subscription[];
  /* active project */
  activeProject: ProjectWithRelations;
  /* editing project document id */
  projectDocumentId: string;
  /* editing project document */
  projectDocument: ProjectDocumentWithRelations;
  /* loading state */
  loading: boolean;

  displayToolbar = false;
  displayToolbarBackButton = false;

  constructor(
    private store: Store<any>,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getProjectDocumentIdFromUrl();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  getProjectDocumentIdFromUrl() {
    this.projectDocumentId = this.route.snapshot.params['id'];
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToProjectDocumentLoading(),
      this.subscribeToProjectDocuments()
    ];
  }

  setTitle() {
    this.mainTitle = {
      content: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_DOCUMENT_EDIT.PAGE_TITLE,
      type: defaultToolbarTitleHeadingSize
    };

    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
      },
      {
        label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_DOCUMENT_EDIT.PAGE_TITLE
      },
      {
        label: this.projectDocument?.title
      }
    ];
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((project: ProjectWithRelations) => {
      if (project) {
        this.activeProject = project;
        this.store.dispatch(GetProjectDocuments());
      } else {
        this.activeProject = null;
      }
    });
  }

  subscribeToProjectDocuments() {
    return this.store.select(state => state.projectDocument?.data).subscribe((projectDocuments: ProjectDocumentWithRelations[]) => {
      this.projectDocument = projectDocuments?.find(document => document.id === this.projectDocumentId);
      this.setTitle();
    });
  }

  subscribeToProjectDocumentLoading() {
    return this.store.select(state => state.projectDocument?.loading).subscribe(loading => {
      this.loading = loading;
    });
  }

  onUpdateProjectDocument(projectDocument) {
    // this.store.dispatch(UpdateProjectDocument({ payload: { projectDocument, projectDocumentId: this.projectDocumentId } }));
  }
}
