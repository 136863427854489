<section>
  <rappider-list-grid [config]="DATA_MAPPINGS_CONFIG"
                      [data]="listGridData"
                      (listActionClick)="addDataMapping()"
                      (columnActionClick)="deleteDataMapping($event)"></rappider-list-grid>
</section>

<rappider-modal *ngIf="addDataMappingModalVisible"
                [(visible)]="addDataMappingModalVisible"
                [title]="uiDataEvent?.name"
                [fullScreen]="true"
                (cancelClick)="handleAddDataMappingModalVisibility(false)"
                (okClick)="onAddDataMapping()">
  <rappider-data-mapping-create [(ngModel)]="dataMappingValue"
                                [sourceDataSchema]="sourceDataSchema"
                                [targetDataSchema]="targetDataSchema"
                                [functions]="functions"></rappider-data-mapping-create>
  <small class="error"
         *ngIf="!isDataMappingValid">
    *{{
    'PROJECT_MODULE.DATA_MAPPINGS_COMPONENT.CANT_BE_EMPTY' | translate
    }}
  </small>
</rappider-modal>