export enum UIStepFunctionCreateEditFormItem {
    Name = 'name',
    IntegrationOperatorType = 'integrationOperatorType',
    SubscribedEvents = 'subscribedEvents',
    PublishedEventsOnSuccess = 'publishedEventsOnSuccess',
    PublishedEventsOnFailure = 'publishedEventsOnFailure',
    CustomOrEndpoint = 'customOrEndpoint',
    ProjectModel = 'projectModel',
    EndpointId = 'endpointId',
    Code = 'code',
    WorkflowEventId = 'workflowEventId',
    WorkflowStepFunctionId = 'workflowStepFunctionId'
}
