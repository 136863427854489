export enum ProjectModelDataFieldType {
  Any = 'any',
  Array = 'Array',
  Boolean = 'boolean',
  Buffer = 'buffer',
  Date = 'Date',
  GeoPoint = 'GeoPoint',
  DateString = 'DateString',
  Number = 'number',
  Object = 'Object',
  String = 'string'
}
