import { failureLottieAnimation } from '../lottie-animations/failure-lottie-animation';
import { AngularProjectExportProcesses } from './framework-processes/angular-project-export-processes';
import { Loopback4ProjectExportProcesses } from './framework-processes/loopback4-project-export-processes';
import { SQLAlchemyProjectExportProcesses } from './framework-processes/sqlalchemy-project-export-processes';
import { ProjectExportEventType } from './project-export-event-type.enum';
import { ProjectExportProcessStatus } from './project-export-process-status.enum';

export const ProjectExportProcesses = [
  {
    status: ProjectExportProcessStatus.Processing,
    key: ProjectExportEventType.StartProjectExportation,
    animation: {
      options: {
        path: 'assets/animations/frontend-exporting.json'
      },
      height: '100%',
      width: '100%'
    }
  },

  {
    status: ProjectExportProcessStatus.Successful,
    key: ProjectExportEventType.ProjectExportationSuccessful,
    animation: {
      options: {
        path: 'assets/animations/project-exportation-successful.json'
      },
      height: '100%',
      width: '100%'
    }
  },

  {
    status: ProjectExportProcessStatus.Failure,
    key: ProjectExportEventType.ProjectExportationFailure,
    animation: failureLottieAnimation
  },

  ...AngularProjectExportProcesses,

  ...Loopback4ProjectExportProcesses,

  ...SQLAlchemyProjectExportProcesses
];
