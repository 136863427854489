/* eslint-disable @typescript-eslint/no-use-before-define */
import { KeyValue } from '@angular/common';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DataSchemaFieldInterface, DataSchemaInterface, UIDataEventInterface } from '@rappider/api-sdk';
import { ActionResponse } from '@rappider/rappider-components/utils';
import { DataMapping } from '../../models/data-mapping';
import { ASSIGN_FUNCTION } from '../create-data-mapping-page/config/assign-function';
import { DATA_MAPPINGS_CONFIG } from './config/data-mappings';

@Component({
  selector: 'rappider-data-mappings',
  templateUrl: './data-mappings.component.html',
  styleUrls: ['./data-mappings.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DataMappingsComponent),
      multi: true
    }
  ]
})
export class DataMappingsComponent implements OnInit {

  _value: DataMapping[] = [];
  deneme: DataMapping = {};
  listGridData;

  @Input() functions: KeyValue<string, string>[];
  @Input() targetDataSchema: DataSchemaInterface;
  @Input() sourceDataSchema: DataSchemaInterface;
  @Input() uiDataEvent: UIDataEventInterface;

  @Output() dataMappingAdd = new EventEmitter<DataMapping>();
  @Output() dataMappingDelete = new EventEmitter<string>();

  DATA_MAPPINGS_CONFIG = DATA_MAPPINGS_CONFIG;
  addDataMappingModalVisible = false;
  dataMappingValue: DataMapping = { functionId: 'value', sourceFields: [], targetField: {} };
  isDataMappingValid = true;

  get value() {
    return this._value;
  }

  set value(value: DataMapping[]) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
  }


  ngOnInit(): void {
    this.setListGridData();
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: DataMapping[]) {
    this._value = value;
    this.setListGridData();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  addDataMapping() {
    this.handleAddDataMappingModalVisibility(true);
  }

  handleAddDataMappingModalVisibility(visibility: boolean) {
    this.addDataMappingModalVisible = visibility;
  }

  onAddDataMapping() {
    if (this.validateDataMapping()) {
      if (this.value?.length) {
        this.value = [...this.value, ...[this.dataMappingValue]];
      } else {
        this.value = [this.dataMappingValue];
      }
      this.dataMappingAdd.emit(this.dataMappingValue);
      this.setListGridData();
      this.dataMappingValue = { sourceFields: null, functionId: ASSIGN_FUNCTION.id, targetField: null };
      this.handleAddDataMappingModalVisibility(false);
      this.isDataMappingValid = true;
    } else {
      this.isDataMappingValid = false;
    }
  }

  validateDataMapping() {
    return this.dataMappingValue?.targetField?.length;
  }

  setListGridData() {
    this.listGridData = this.value?.map(item => ({
      sourceFields: item.sourceFields?.map((sourceField: DataSchemaFieldInterface[]) => sourceField?.map(source => source?.name).join('.')).join(','),
      function: this.getFunctionName(item),
      targetField: item.targetField?.map((targetFieldItem: DataSchemaFieldInterface) => targetFieldItem.name).join('.')
    }));
  }

  getFunctionName(item: DataMapping) {
    if (item.customFunction) {
      return 'CUSTOM_FUNCTION';
    } else {
      return this.functions?.find(
        functionItem => functionItem.value === item.functionId
      )?.key || ASSIGN_FUNCTION.title;
    }
  }

  deleteDataMapping(actionResponse: ActionResponse) {
    const deletedIndex = actionResponse.rowIndex;
    let deletedDataMapping: DataMapping;
    this.value = this.value.filter((item, index) => {
      if (index !== deletedIndex) {
        return true;
      } else {
        deletedDataMapping = item;
        return false;
      }
    });
    this.listGridData = this.listGridData.filter((item, index) => index !== deletedIndex);
    if (deletedDataMapping.id) {
      this.dataMappingDelete.emit(deletedDataMapping.id);
    }
  }
}
