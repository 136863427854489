<ng-container *ngIf="activeProject; else noActiveProjectTempl">
  <div class="rappider-ai-assistant">
    <rappider-chat [messages]="displayedMessages"
                   [receiverAvatar]="receiverAvatar"
                   [senderAvatar]="senderAvatar"
                   [isTyping]="isTyping"
                   [sendButtonDisabled]="isTyping && !activeProject"
                   [displaySkeleton]="!isMessagesLoaded"
                   [fullScreenButtonVisibility]="isComponentInSidebar"
                   [quickAnswerOptions]="quickAnswerOptions"
                   (messageSent)="onMessageSent($event)"
                   (messageButtonClick)="onMessageButtonClick($event)"
                   (fullScreenButtonClick)="onFullScreenButtonClick()"
                   (quickAnswerButtonClick)="onQuickAnswerButtonClick($event)"></rappider-chat>
  </div>
</ng-container>

<ng-template #noActiveProjectTempl>
  <div class="rappider-ai-assistant no-active-project">
    <h5>
      No active project. Please select a project for ai assistant.
    </h5>
  </div>
</ng-template>
