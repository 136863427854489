import { Action, ActionBehavior } from '@rappider/rappider-components/utils';

export const POP_CONFIRM_DELETE_ACTION: Action = {
  text: 'SHARED.DELETE',
  name: 'DELETE_ITEM',
  popconfirmTitle: 'Are you sure you want to delete these item ?',
  emitWithoutPopconfirm: false,
  popConfirmCancelText: 'Cancel',
  popConfirmOkText: 'Delete',
  popConfirmOkDanger: true,
  icon: { name: 'far fa-trash' },
  behavior: ActionBehavior.Emit
};

export const projectSettingsPopConfirmTitle = 'This setting will be deleted. Are you sure?';
export const environmentVariablesPopConfirmTitle = 'This environment variable will be deleted. Are you sure?';
