import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Page, ProjectWithRelations } from '@rappider/rappider-sdk';
import { PAGE_DEFINITIONS, PATH_DEFINITIONS, defaultToolbarTitleHeadingSize } from '@rappider/shared/definitions';
import { BreadcrumbOption } from 'libs/components/src/lib/utils/breadcrumb';
import { HeadingComponentConfig } from 'libs/components/src/lib/utils/heading';
import { Subscription } from 'rxjs';
import { PROJECT_PAGES_CONFIG } from './config/project-pages-config';
import { ModalComponentConfig } from '@rappider/rappider-components/utils';
import { UpdatePage } from '@rappider/pages';
import { PageDevelopmentStatus } from '../../utils/page-development-status.enum';
import { PageListButtonsType } from './config/project-pages-list-page-buttons-config';

@Component({
  selector: 'rappider-project-pages',
  templateUrl: './project-pages.component.html',
  styleUrls: ['./project-pages.component.scss']
})
export class ProjectPagesComponent implements OnInit {

  PROJECT_PAGES_CONFIG = PROJECT_PAGES_CONFIG;

  mainTitle: HeadingComponentConfig = {
    content: 'Admin Dashboard',
    type: defaultToolbarTitleHeadingSize
  };
  title: BreadcrumbOption[] = [];
  pageStatusChangeModal: ModalComponentConfig = {
    title: 'Change Page Status',
    okText: 'Save',
    cancelText: 'Cancel',
    fullScreen: false,
    width: '40%'
  };
  pageStatusChangeModalVisibility = false;
  pageStatusConfig: any = [
    {
      key: 'In Review',
      value: PageDevelopmentStatus.InReview
    },
    {
      key: 'Reviewed',
      value: PageDevelopmentStatus.Reviewed
    },
    {
      key: 'Draft',
      value: PageDevelopmentStatus.Draft
    }
  ];

  subscriptions: Subscription[] = [];
  projectId: string;
  selectedProject: ProjectWithRelations;
  pages: Page[];
  updatedPage: Page;
  pageStatus: PageDevelopmentStatus;
  loading = false;

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getProjectId();
    this.subscribeToData();
  }

  getProjectId() {
    this.projectId = this.activatedRoute.snapshot.params['id'];
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToSelectedProject(),
      this.subscribeToProjectPages(),
      this.subscribeToPageLoading()
    ];
  }

  subscribeToSelectedProject() {
    return this.store.select(state => state.adminProjects?.data).subscribe((projects: ProjectWithRelations[]) => {
      if (projects && this.projectId) {
        this.selectedProject = projects.find(module => module.id === this.projectId);
        this.setTitle();
      }
    });
  }

  subscribeToProjectPages() {
    return this.store.select(state => state.page?.data).subscribe(pages => {
      this.pages = pages;
    });
  }

  subscribeToPageLoading() {
    return this.store.select(state => state.page?.loading).subscribe(loading => {
      this.loading = loading;
    });
  }

  setTitle() {
    this.title = [
      {
        label: 'Admin Dashboard',
        redirectUrl: PAGE_DEFINITIONS.ADMIN_DASHBOARD.URL
      },
      {
        label: 'Projects',
        redirectUrl: PATH_DEFINITIONS.ADMIN_DASHBOARD.PROJECTS
      },
      {
        label: 'Project Detail',
        redirectUrl: `${PATH_DEFINITIONS.ADMIN_DASHBOARD.PROJECT_DETAIL}/${this.selectedProject?.id}`
      },
      {
        label: this.selectedProject?.name
      },
      {
        label: 'Project Pages'
      }
    ];
  }

  onColumnActionClick(event) {
    if (event?.action?.name === PageListButtonsType.EditStatus) {
      this.pageStatusChangeModalVisibilityChange();
      this.updatedPage = event?.data;
      this.pageStatus = event?.data?.developmentStatus;
    } else if (event?.action?.name === PageListButtonsType.OpenPage) {
      this.openPageNewTab(event?.data?.id);
    }
  }

  pageStatusChangeModalVisibilityChange() {
    this.pageStatusChangeModalVisibility = !this.pageStatusChangeModalVisibility;
    if (!this.pageStatusChangeModalVisibility) {
      this.updatedPage = null;
      this.pageStatus = null;
    }
  }

  statusChange(pageStatus: PageDevelopmentStatus) {
    this.pageStatus = pageStatus;
  }

  onSavePageDevelopmentStatus() {
    this.store.dispatch(new UpdatePage({ pageId: this.updatedPage.id, page: { developmentStatus: this.pageStatus } }));
    this.pageStatusChangeModalVisibilityChange();
  }

  openPageNewTab(pageId: string) {
    window.open(`${PATH_DEFINITIONS.CONTENT_EDITOR.CONTENT_EDITOR_PATH}/${pageId}`, '_blank').focus();
  }
}
