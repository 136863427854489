/* eslint-disable */
import { DataSchemaEnumData } from '@rappider/rappider-sdk';
import {
  Project,
  UIDataStore,
  UIDataEvent,
  DataSchemaField,
  DataSchemaDataDefinition,
} from '../index';

declare var Object: any;
export interface DataSchemaInterface {
  id?: string;
  name: string;
  isPrimitive?: boolean;
  type?: string;
  description?: string;
  defaultUIDataSelector?: string;
  projectId?: string;
  uiDataStoreId?: string;
  uiDataEventId?: string;
  createdDate?: Date;
  createdBy?: string;
  createdById?: string;
  updatedDate?: Date;
  updatedBy?: string;
  updatedById?: string;
  defaultDataSchemaDataDefinitionId?: string;
  category?: string;
  enumData?: DataSchemaEnumData;
  project?: Project;
  uiDataStore?: UIDataStore;
  uiDataEvent?: UIDataEvent;
  fields?: DataSchemaField[];
  defaultDataDefinition?: DataSchemaDataDefinition;
  dataDefinitions?: DataSchemaDataDefinition[];
  isDeletable?: boolean;
  isUpdatable?: boolean;
}

export class DataSchema implements DataSchemaInterface {
  'id': string;
  'name': string;
  'isPrimitive': boolean;
  'type': string;
  'description': string;
  'defaultUIDataSelector': string;
  'projectId': string;
  'uiDataStoreId': string;
  'uiDataEventId': string;
  'createdDate': Date;
  'createdBy': string;
  'createdById': string;
  'updatedDate': Date;
  'updatedBy': string;
  'updatedById': string;
  'defaultDataSchemaDataDefinitionId': string;
  'category'?: string;
  'isDeletable': boolean;
  'isUpdatable': boolean;
  enumData?: DataSchemaEnumData;
  project: Project;
  uiDataStore: UIDataStore;
  uiDataEvent: UIDataEvent;
  fields: DataSchemaField[];
  defaultDataDefinition: DataSchemaDataDefinition;
  dataDefinitions: DataSchemaDataDefinition[];
  constructor(data?: DataSchemaInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `DataSchema`.
   */
  public static getModelName() {
    return 'DataSchema';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of DataSchema for dynamic purposes.
   **/
  public static factory(data: DataSchemaInterface): DataSchema {
    return new DataSchema(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'DataSchema',
      plural: 'DataSchemas',
      path: 'data-schemas',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        name: {
          name: 'name',
          type: 'string',
        },
        isPrimitive: {
          name: 'isPrimitive',
          type: 'boolean',
        },
        type: {
          name: 'type',
          type: 'string',
        },
        description: {
          name: 'description',
          type: 'string',
        },
        defaultUIDataSelector: {
          name: 'defaultUIDataSelector',
          type: 'string',
        },
        projectId: {
          name: 'projectId',
          type: 'string',
        },
        uiDataStoreId: {
          name: 'uiDataStoreId',
          type: 'string',
        },
        uiDataEventId: {
          name: 'uiDataEventId',
          type: 'string',
        },
        createdDate: {
          name: 'createdDate',
          type: 'Date',
        },
        createdBy: {
          name: 'createdBy',
          type: 'string',
        },
        createdById: {
          name: 'createdById',
          type: 'string',
        },
        updatedDate: {
          name: 'updatedDate',
          type: 'Date',
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'string',
        },
        updatedById: {
          name: 'updatedById',
          type: 'string',
        },
        defaultDataSchemaDataDefinitionId: {
          name: 'defaultDataSchemaDataDefinitionId',
          type: 'string',
        },
        category: {
          name: 'category',
          type: 'string',
        },
        isDeletable: {
          name: 'isDeletable',
          type: 'boolean',
        },
        isUpdatable: {
          name: 'isUpdatable',
          type: 'boolean',
        },
      },
      relations: {
        project: {
          name: 'project',
          type: 'Project',
          model: 'Project',
          relationType: 'belongsTo',
          keyFrom: 'projectId',
          keyTo: 'id',
        },
        uiDataStore: {
          name: 'uiDataStore',
          type: 'UIDataStore',
          model: 'UIDataStore',
          relationType: 'belongsTo',
          keyFrom: 'uiDataStoreId',
          keyTo: 'id',
        },
        uiDataEvent: {
          name: 'uiDataEvent',
          type: 'UIDataEvent',
          model: 'UIDataEvent',
          relationType: 'belongsTo',
          keyFrom: 'uiDataEventId',
          keyTo: 'id',
        },
        fields: {
          name: 'fields',
          type: 'DataSchemaField[]',
          model: 'DataSchemaField',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'parentDataSchemaId',
        },
        defaultDataDefinition: {
          name: 'defaultDataDefinition',
          type: 'DataSchemaDataDefinition',
          model: 'DataSchemaDataDefinition',
          relationType: 'belongsTo',
          keyFrom: 'defaultDataSchemaDataDefinitionId',
          keyTo: 'id',
        },
        dataDefinitions: {
          name: 'dataDefinitions',
          type: 'DataSchemaDataDefinition[]',
          model: 'DataSchemaDataDefinition',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'dataSchemaId',
        },
        enumData: {
          name: 'enumData',
          type: 'DataSchemaEnumData[]',
          model: 'DataSchemaEnumData',
          relationType: 'hasOne',
          keyFrom: 'defaultDataSchemaDataDefinitionId',
          keyTo: 'dataSchemaId',
        },
      },
    };
  }
}
