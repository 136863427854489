import { failureLottieAnimation } from '../../lottie-animations/failure-lottie-animation';
import { successfulLottieAnimation } from '../../lottie-animations/successful-lottie-animation';
import { ProjectExportEventType } from '../project-export-event-type.enum';
import { ProjectExportProcessStatus } from '../project-export-process-status.enum';

export const Loopback4ProjectExportProcesses = [

  // #region Export Loopback4

  {
    status: ProjectExportProcessStatus.Processing,
    key: ProjectExportEventType.ExportLoopback4,
    animation: {
      options: {
        path: 'assets/animations/backend-exporting.json'
      },
      height: '100%',
      width: '100%'
    }
  },
  {
    status: ProjectExportProcessStatus.Successful,
    key: ProjectExportEventType.ExportLoopback4Successful,
    animation: successfulLottieAnimation
  },
  {
    status: ProjectExportProcessStatus.Processing,
    key: ProjectExportEventType.ExportLoopback4Failure,
    animation: failureLottieAnimation
  },

  // #region Loopback4 Install Project Dependencies

  {
    status: ProjectExportProcessStatus.Processing,
    key: ProjectExportEventType.Loopback4InstallProjectDependencies,
    animation: {
      options: {
        path: 'assets/animations/project-dependencies-installing.json'
      },
      height: '100%',
      width: '100%'
    }
  },
  {
    status: ProjectExportProcessStatus.Successful,
    key: ProjectExportEventType.Loopback4InstallProjectDependenciesSuccessful,
    animation: successfulLottieAnimation
  },
  {
    status: ProjectExportProcessStatus.Processing,
    key: ProjectExportEventType.Loopback4InstallProjectDependenciesFailure,
    animation: failureLottieAnimation
  },

  // #endregion

  // #region Format Exported Projects

  {
    status: ProjectExportProcessStatus.Processing,
    key: ProjectExportEventType.FormatExportedProjects,
    animation: {
      options: {
        path: 'assets/animations/prettier-command-running.json'
      },
      height: '100%',
      width: '100%'
    }
  },
  {
    status: ProjectExportProcessStatus.Successful,
    key: ProjectExportEventType.FormatExportedProjectsSuccessful,
    animation: successfulLottieAnimation
  },
  {
    status: ProjectExportProcessStatus.Processing,
    key: ProjectExportEventType.FormatExportedProjectsFailure,
    animation: failureLottieAnimation
  },

  // #endregion

  // #region Loopback4 Generate OpenAPI Spec File

  {
    status: ProjectExportProcessStatus.Processing,
    key: ProjectExportEventType.Loopback4GenerateOpenApiSpecFile,
    animation: {
      options: {
        path: 'assets/animations/spec-file-generating.json'
      },
      height: '100%',
      width: '100%'
    }
  },
  {
    status: ProjectExportProcessStatus.Successful,
    key: ProjectExportEventType.Loopback4GenerateOpenApiSpecFileSuccessful,
    animation: successfulLottieAnimation
  },
  {
    status: ProjectExportProcessStatus.Processing,
    key: ProjectExportEventType.Loopback4GenerateOpenApiSpecFileFailure,
    animation: failureLottieAnimation
  },

  // #endregion

  // #region Loopback4 Uninstall Project Dependencies

  {
    status: ProjectExportProcessStatus.Processing,
    key: ProjectExportEventType.Loopback4UninstallProjectDependencies,
    animation: {
      options: {
        path: 'assets/animations/project-dependencies-uninstalling.json'
      },
      height: '100%',
      width: '100%'
    }
  },
  {
    status: ProjectExportProcessStatus.Successful,
    key: ProjectExportEventType.Loopback4UninstallProjectDependenciesSuccessful,
    animation: successfulLottieAnimation
  },
  {
    status: ProjectExportProcessStatus.Processing,
    key: ProjectExportEventType.Loopback4UninstallProjectDependenciesFailure,
    animation: failureLottieAnimation
  },

  // #endregion

  // #region Loopback4 Delete dist Folder

  {
    status: ProjectExportProcessStatus.Processing,
    key: ProjectExportEventType.Loopback4DeleteDistFolder,
    animation: {
      options: {
        path: 'assets/animations/dist-folder-removing.json'
      },
      height: '100%',
      width: '100%'
    }
  },
  {
    status: ProjectExportProcessStatus.Successful,
    key: ProjectExportEventType.Loopback4DeleteDistFolderSuccessful,
    animation: successfulLottieAnimation
  },
  {
    status: ProjectExportProcessStatus.Processing,
    key: ProjectExportEventType.Loopback4DeleteDistFolderFailure,
    animation: failureLottieAnimation
  },

  // #endregion

  // #region Loopback4 Generate Angular SDK

  {
    status: ProjectExportProcessStatus.Processing,
    key: ProjectExportEventType.Loopback4GenerateAngularSDK,
    animation: {
      options: {
        path: 'assets/animations/angular-sdk-generating.json'
      },
      height: '100%',
      width: '100%'
    }
  },
  {
    status: ProjectExportProcessStatus.Successful,
    key: ProjectExportEventType.Loopback4GenerateAngularSDKSuccessful,
    animation: successfulLottieAnimation
  },
  {
    status: ProjectExportProcessStatus.Processing,
    key: ProjectExportEventType.Loopback4GenerateAngularSDKFailure,
    animation: failureLottieAnimation
  },

  // #endregion

  // #endregion

];
