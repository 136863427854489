<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>

<section>
  <rappider-spin [spinning]="!projectModelSelectOption">
    <rappider-crud-view-edit-form *ngIf="!!projectModelSelectOption"
                                  [config]="PROJECT_MODEL_RELATION_UPDATE_CONFIG"
                                  [data]="projectModelRelation"
                                  [submitButtonLoading]="isLoading"
                                  (fieldValueChange)="onFieldValueChange($event)"
                                  (formSubmit)="onEditModelRelationFormSubmit($event)"></rappider-crud-view-edit-form>
  </rappider-spin>
</section>
