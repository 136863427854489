import { CrudFormConfig, CrudFormMonacoCodeEditorItem, CrudFormSelectItem, CrudViewFormItemType } from '@rappider/rappider-components/utils';
export const ADMIN_COMMENT_EDIT_FORM_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormMonacoCodeEditorItem>{
      title: 'Content',
      fieldName: 'content',
      type: CrudViewFormItemType.MonacoCodeEditor,
      editorOptions:
      {
        theme: 'vs',
        language: 'markdown',
        lineNumbers: { renderType: 0 },
        minimap: {
          enabled: false,
        },
      },
      sizeSettings: {
        minHeight: '200px'
      }
    },
    <CrudFormSelectItem>{
      title: 'Status',
      fieldName: 'status',
      type: CrudViewFormItemType.Select,
      options: [
        {
          key: 'todo',
          value: 'todo'
        },
        {
          key: 'completed',
          value: 'completed'
        },
        {
          key: 'TODO',
          value: 'TODO'
        },
        {
          key: 'COMPLETED',
          value: 'COMPLETED'
        }
      ]
    }
  ]
};
